import React from 'react';

import { HeadCell, Pagination, RowCell, Selection, Table } from '../../../../components/Table';
import { Unit, UnitSearchResults } from '../../../../shared/domain';

interface UnitsTableProps {
  selection: Selection;
  unitsData?: UnitSearchResults | null;
  handleNextButton: () => Promise<void>;
  handlePrevButton: () => Promise<void>;
}

export const UnitsTable = ({
  selection,
  unitsData,
  handleNextButton,
  handlePrevButton
}: UnitsTableProps) => {
  const headCells: HeadCell[] = [
    {
      id: 'name',
      label: 'name'
    }
  ];
  const rowCells = unitsData?.units.map((unit: Unit) => {
    const rowCell: RowCell = {
      name: {
        value: unit.name
      }
    };

    return Object.assign(rowCell, { content: unit });
  });

  const pagination: Pagination = {
    contentName: 'Unit',
    page: unitsData?.page ?? 0,
    pageSize: unitsData?.pageSize ?? 0,
    total: unitsData?.total ?? 0,
    handleNextButton,
    handlePrevButton
  };

  return (
    <Table
      data={unitsData?.units}
      headCells={headCells}
      hideHead
      rowCells={rowCells}
      pagination={pagination}
      selection={selection}
    />
  );
};
