import { Box, Radio, RadioGroup, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { TitleInfo } from './TitleInfo';

interface ScaleQuestionProps {
  title: string;
  scaleMax: number;
  color: string;
  handleInput: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  responseValue?: number;
  clinicianInfo?: string;
  patientInfo?: string;
  index: any
}

interface ComponentProps {
  customColor: string;
}

export const ScaleQuestion: React.FC<ScaleQuestionProps> = ({
  title,
  scaleMax,
  color,
  handleInput,
  responseValue,
  clinicianInfo,
  patientInfo,
  index
}) => {
  const props = { customColor: color };
  const classes = useStyles(props);

  const renderScale = (scaleMax: number) => {
    const scale = [];
    for (let i = 0; i < scaleMax; i++) {
      const value = i;
      const checked = value === responseValue;
      scale.push(
        <Radio
          onChange={handleInput}
          key={i}
          value={value}
          checkedIcon={<span className={classes.checkedIcon} />}
          icon={
            <Typography
              color={checked ? 'secondary' : 'textPrimary'}
              className={checked ? classes.selectedIcon : classes.icon}
            >
              {value as number}
            </Typography>
          }
        />
      );
    }
    return scale;
  };
  return (
    <>
      <Box p={1}>
        <TitleInfo patientInfo={patientInfo} clinicianInfo={clinicianInfo} title={title} index={index} />
      </Box>
      <Box py={2}>
        <RadioGroup row>{renderScale(scaleMax + 1)}</RadioGroup>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  selectedIcon: (props: ComponentProps) => ({
    'borderRadius': '50%',
    'border': '1px solid grey',
    'width': '3rem',
    'height': '3rem',
    'fontSize': '1.2rem',
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'boxShadow': 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    'backgroundColor': props.customColor,
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2
    }
  }),
  selectRoot: {
    padding: '.6rem'
  },
  icon: {
    'borderRadius': '50%',
    'border': '1px solid grey',
    'width': '3rem',
    'height': '3rem',
    'fontSize': '1.2rem',
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',
    'boxShadow': 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    'backgroundColor': '#f5f8fa',
    'backgroundImage': 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)'
    }
  },
  checkedIcon: {
    'backgroundColor': '#137cbd',
    'backgroundImage': 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3'
    }
  }
}));
