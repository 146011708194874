import React, { useEffect, useState } from 'react';
import { Box, Theme, Typography, makeStyles, Grid, Divider, Chip, TextField } from '@material-ui/core';
import { ArrowBack, GetApp, Search, StarOutline, Star } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { UuidType } from '../../../shared/domain';
import { RootState } from '../../../redux/redux-store';
import { getResourceFilesThunk, addFavoriteResourceThunk, removeFavoriteResourceThunk, getResourceStatusCount } from '../../../redux/thunk/thunks';
import { LoadingButton } from '../../../components/LoadingButton';
import { ResourcesFilesTable } from './ResourceFilesTable';
import { url } from '../../../redux/url';
import { ROLES } from '../../../shared/globals';
import { Patients } from '../AssignPatient/Patients';
import { assignmentInfoAction } from '../../../redux/data/data-actions';
import { Downloads } from '../../../components/IFrame';

var axios = require("axios");

interface ViewRecourceProps {
    handleChangeFavorite: (value: boolean) => void;
    handleClose: () => void;
    editResource: (info: any) => void;
    editInfo: any;
}

export const ViewRecource = ({ handleChangeFavorite, handleClose, editResource, editInfo }: ViewRecourceProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [selectedResourceFiles, setSelectedResourceFiles] = useState<UuidType[]>([]);
    const [openPatient, setOpenPatient] = useState(false);
    const logedUser: any = localStorage.getItem('formFlash');
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const loggedInUser = JSON.parse(logedUser);
    const [loading, setLoading] = useState(false);
    const resourceFiles: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.resourceFiles
    );
    useEffect(() => {
        if (editInfo)
            dispatch(getResourceFilesThunk({ resourceId: editInfo?.id }))
    }, [])

    const handleSearch = (value: string) => {
        if (editInfo) {
            dispatch(
                getResourceFilesThunk({
                    resourceId: editInfo?.id,
                    order: 'DESC',
                    page: 0,
                    pageSize: 10,
                    search: value
                })
            );
        }
    };

    const handleNextButton = async () => {
        if (
            resourceFiles &&
            resourceFiles.page >= 0 &&
            resourceFiles.page < resourceFiles.totalCount / resourceFiles.pageSize - 1
        ) {
            dispatch(
                getResourceFilesThunk({
                    resourceId: editInfo?.id,
                    order: 'DESC',
                    page: resourceFiles.page + 1,
                    pageSize: 10,
                    search: ''
                })
            );
        }
    };
    const handlePrevButton = async () => {
        if (resourceFiles && resourceFiles.page > 0) {
            dispatch(
                getResourceFilesThunk({
                    resourceId: editInfo?.id,
                    order: 'DESC',
                    page: resourceFiles.page - 1,
                    pageSize: 10,
                    search: ''
                })
            );
        }
    };

    const _getHeaders = (inputHeaders?: { [key: string]: string }) => {
        let headers = inputHeaders || {};
        let token = localStorage.getItem('token');
        headers["Accept"] = "application/json";
        const headersWithAuth = token
            ? {
                ...headers,
                Authorization: `Bearer ${token}`,
            }
            : headers;
        return headersWithAuth;
    };
    const viewURL = async () => {
        const array: any = [];
        for (let i = 0; i < selectedResourceFiles.length; i++) {
            const urls = await resourceFiles && resourceFiles.data.filter((item: any) => item.id === selectedResourceFiles[i])
            const data1 = {
                input_files: [urls[0].file_url]
            }
            const config1 = {
                method: 'post',
                url: `${url}/user/auth/getSignedDownloadUrls`,
                headers: _getHeaders({ "Content-Type": "application/json" }),
                data: data1,
            };

            await axios(config1)
                .then(async function (res: any) {
                    // await array.push({ name: urls[0].file_name, url: res.data.url[0] })
                    window.open(res.data.url && res.data.url.length > 0 && res.data.url[0], '_blank');
                })
        }
        // if (array?.length > 0) {
        //     setData(array);
        //     setOpen(true);
        // }
    }

    const handelFavoriteResources = async (value: boolean) => {
        setLoading(true);
        if (value) {
            await dispatch(addFavoriteResourceThunk(editInfo?.id));
            handleChangeFavorite(value);
        } else {
            await dispatch(removeFavoriteResourceThunk([editInfo?.id]));
            handleChangeFavorite(value);
        }
        setLoading(false);
    }

    const assignResourceToPatient = (value: any) => {
        setOpenPatient(true);
        const arr: any = [editInfo]
        dispatch(assignmentInfoAction(arr));
    }
    const assignResourceToPatientClose = () => {
        setOpenPatient(false);
    }

    const handelIsFavourite = async (value: boolean) => {
        !loading && await handelFavoriteResources(value);
    }

    const handleCheckStatus = () => {
        const data = { resource_ids: [editInfo?.id] }
        dispatch(getResourceStatusCount(data));
    }

    return (
        <Box display="flex" flexDirection="column">
            <Box pt={3} px={3}>
                <Grid container>
                    <Grid item xs={12} sm={10} md={10} style={{ display: 'flex' }}>
                        <Grid container>
                            <Grid item xs={2} sm={1} md={1} style={{ display: 'flex' }}>
                                <ArrowBack style={{ fontSize: '30px', color: 'gray', cursor: 'pointer', marginTop: '10px' }} onClick={handleClose} />
                                <Divider orientation="vertical" style={{ margin: '3px 40px 0px 15px', height: '40px' }} />
                            </Grid>
                            <Grid item xs={10} sm={11} md={11}>
                                <Box pb={2.5}>
                                    <Box pb={0.75}>
                                        <Typography style={{ color: 'gray' }} variant="subtitle2">Resource</Typography>
                                        <Box display='flex' alignItems="center" mt={0.3}>
                                            <Typography variant="h2">{editInfo?.title}</Typography>
                                            {editInfo && editInfo?.isFavourite ? <Star color='primary' style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => handelIsFavourite(false)} /> : <StarOutline color='primary' style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => handelIsFavourite(true)} />}
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box pb={2}>
                                    <Box pt={1}>
                                        <Typography color="textSecondary" variant="subtitle2">
                                            {editInfo?.description !== '' ? editInfo?.description : 'NA'}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} style={{ textAlign: 'right' }}>
                        <Box>
                            {loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role) && editInfo?.organization_id && <LoadingButton
                                className={classes.primaryButton}
                                color="primary"
                                variant="contained"
                                onClick={(e) => { editResource(resourceFiles); handleCheckStatus() }}
                            >
                                Edit
                            </LoadingButton>}
                            {loggedInUser && ![ROLES.SITE_ADMIN, ROLES.BUILDER, ROLES.CLINICIAN, ROLES.PATIENT, ROLES.PROXY, ROLES.RATER].includes(loggedInUser.role) && <LoadingButton
                                className={classes.primaryButton}
                                color="primary"
                                variant="contained"
                                onClick={(e) => { editResource(resourceFiles); handleCheckStatus() }}
                            >
                                Edit
                            </LoadingButton>}
                            {loggedInUser && [ROLES.CLINICIAN].includes(loggedInUser.role) && <LoadingButton
                                className={classes.primaryButton}
                                color="primary"
                                variant="contained"
                                onClick={(e) => assignResourceToPatient(resourceFiles)}
                            >
                                Assign to Patient
                            </LoadingButton>}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {loggedInUser && ![ROLES.PATIENT, ROLES.PROXY, ROLES.RATER].includes(loggedInUser.role) && <>
                <Divider variant="middle" />
                <Box
                    className={classes.topBox}
                    px={3}
                    py={2.5}
                >
                    <Typography variant="h4" style={{ fontWeight: 500 }}>Primary Domains</Typography>

                    <Box pt={2} display='flex'>
                        <div className={classes.otherActions}>
                            {editInfo && editInfo?.domains.map((item: any) => {
                                return (
                                    <Chip
                                        key={''}
                                        classes={{ root: classes.collectionChip }
                                        }
                                        size="small"
                                        // onClick={() => unassignCollection()}
                                        label={
                                            < div className={classes.chipAlign}>
                                                {item.domain_name}
                                            </div>
                                        }
                                    />)
                            })}
                        </div>
                    </Box>
                </Box>
                <Divider variant="middle" />
                <Box
                    className={classes.bottomBox}
                    px={3}
                    py={2}
                >
                    <Typography variant="h4" style={{ fontWeight: 500 }}>Tags</Typography>
                    <Box pt={2} display='flex'>
                        <div className={classes.otherActions}>
                            {editInfo && editInfo?.tags.map((item: any) => {
                                return (
                                    <Chip
                                        key={''}
                                        classes={{ root: classes.collectionChip }
                                        }
                                        size="small"
                                        label={
                                            < div className={classes.chipAlign}>
                                                {item.tag_name}
                                            </div>
                                        }
                                    />)
                            })}
                        </div>
                    </Box>
                </Box>
            </>}
            <Divider variant="middle" />
            <Box display="flex" justifyContent="space-between" alignItems='center' px={3} py={2}>
                <Box display="flex" alignItems='end' className={classes.contentContainer}>
                    <Box mr={5}>
                        <Typography variant="h4" style={{ fontWeight: 500, color: 'gray', marginBottom: '6px' }}>FILES IN</Typography>
                        <Typography variant="h2">{editInfo?.title}</Typography>
                    </Box>
                    <Box mt={1}>
                        <TextField className={classes.searchTextField}
                            inputProps={{
                                maxLength: 150,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <Box ml={-0.5} mr={1} mt={0.5}>
                                        <Search className={classes.searchIcon} color="disabled" />
                                    </Box>
                                )
                            }} size='small' variant='outlined' placeholder='Search...' onChange={(e) => handleSearch(e.target.value)} />
                    </Box>
                </Box>
                <Box display='flex' alignItems='center'>
                    {selectedResourceFiles && selectedResourceFiles.length > 0 &&
                        <> <Typography style={{ color: '#575757', fontWeight: 400, fontSize: '13px' }}>{selectedResourceFiles.length} Selected</Typography>
                            <Divider orientation="vertical" style={{ margin: '0px 20px 0px 20px', height: '30px' }} />
                            <GetApp style={{ color: '#0c84c0', cursor: 'pointer' }} onClick={(e) => viewURL()} />
                        </>
                    }
                </Box>
            </Box>
            <Box pb={2} px={4}>
                <ResourcesFilesTable resourceFiles={resourceFiles} selectedResourceFiles={selectedResourceFiles} setSelectedResourceFiles={setSelectedResourceFiles} isView={false} handleNextButton={handleNextButton} handlePrevButton={handlePrevButton} />
            </Box>
            {openPatient && <Patients handelClose={assignResourceToPatientClose} />}
            {open && <Downloads urlData={data} handleClose={setOpen} />}
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    topBox: {
        borderRadius: '0.625rem 0.625rem 0 0'
    },
    bottomBox: {
        borderRadius: '0 0 0.625rem 0.625rem'
    },
    textField: {
        width: '100%'
    },
    primaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#fff',
        minWidth: '120px',
        marginBottom: '20px'
    },
    secondaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#0060B2',
        marginBottom: '20px',
        minWidth: '120px'
    },
    deleteButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: 'red',
        minWidth: '120px'
    },
    createCollectionTextField: {
        maxWidth: '40rem',
        width: '80vw'
    },
    chipAlign: {
        display: 'flex',
        alignItems: 'center'
    },
    otherActions: {
        marginLeft: '.6rem',
        alignItems: 'center'
    },
    collectionChip: {
        marginRight: theme.spacing(1),
        backgroundColor: theme.background.main
    },
    searchTextField: {
        backgroundColor: theme.palette.secondary.main,
        fontSize: theme.typography.h5.fontSize,
        height: '2rem',
        width: '14rem'
    },
    searchIcon: {
        fontSize: 17
    },
    contentContainer: {
        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap'
        }
    }
}));
