import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorNotice } from '../../../../components/ErrorNotice';
import { RootState } from '../../../../redux/redux-store';
import { deleteExitPageLogicThunk, updateExitPageLogicThunk } from '../../../../redux/thunk/thunks';
import {
  ExitPageFieldLogic,
  ExitPageFieldLogicUpdate,
  FormField,
  UuidType
} from '../../../../shared/domain';
import { FIELD_TYPES, LOGIC_OPERATOR_TYPE } from '../../../../shared/globals';
import { allowedLogicalOperators, logicalOperatorText } from '../InputUtilities';

interface ExitPageLogicBlockProps {
  logicRule: ExitPageFieldLogic;
  fieldsThatAllowLogic: FormField[];
  ruleNumber: number;
}
export const ExitPageLogicBlock: React.FC<ExitPageLogicBlockProps> = ({
  logicRule,
  fieldsThatAllowLogic,
  ruleNumber
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [editingLogicRule, setEditingLogicRule] = useState<ExitPageFieldLogic>(logicRule);

  const editingExitPage = useSelector((state: RootState) => state.DATA_REDUCER.editingExitPage);
  const updateExitPageLogicRuleId = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.updateExitPageLogicRuleId
  );

  const deleteFieldLogic = () => {
    //delete field logic
    if (editingExitPage) {
      dispatch(deleteExitPageLogicThunk(logicRule.id, editingExitPage.id));
    }
  };

  const invalidScaleError = () => {
    const fieldScaleMax = selectedField?.option_scale_max;
    const logicRuleValue = editingLogicRule.comparison_value;
    if (fieldScaleMax && logicRuleValue) {
      //check if comparison value is valid
      return fieldScaleMax < logicRuleValue || logicRuleValue < 1;
    }
  };

  const invalidOptionError = () => {
    const fieldOptions = selectedField?.form_field_options;
    const optionId = editingLogicRule.comparison_option_id;

    if (fieldOptions && optionId) {
      const optionIds = fieldOptions.map((ffo) => ffo.id);
      return !optionIds.includes(optionId);
    }
  };

  const selectedField = fieldsThatAllowLogic.find(
    (field: FormField) => field.id === editingLogicRule.target_form_field_id
  );

  const handleChangeTargetField = (event: React.ChangeEvent<{ value: unknown }>) => {
    checkAndUpdateFieldLogic({
      ...editingLogicRule,
      target_form_field_id: event.target.value as UuidType,
      operator_type: LOGIC_OPERATOR_TYPE.EQUAL_TO,
      comparison_option_id: undefined,
      comparison_value: undefined
    });
  };
  const handleChangeScaleOption = (event: React.ChangeEvent<{ value: unknown }>) => {
    checkAndUpdateFieldLogic({
      ...editingLogicRule,
      comparison_option_id: undefined,
      comparison_value: event.target.value as number
    });
  };
  const handleChangeLogicalOperator = (event: React.ChangeEvent<{ value: unknown }>) => {
    checkAndUpdateFieldLogic({
      ...editingLogicRule,
      operator_type: event.target.value as LOGIC_OPERATOR_TYPE
    });
  };
  const handleChangeFieldOption = (event: React.ChangeEvent<{ value: unknown }>) => {
    //Sets the chosen option id and sets the comparison value to undefined
    //since it is incompatable with comparison option
    checkAndUpdateFieldLogic({
      ...editingLogicRule,
      comparison_value: undefined,
      comparison_option_id: event.target.value as UuidType
    });
  };

  const checkAndUpdateFieldLogic = (logicUpdate: ExitPageFieldLogic) => {
    //if all options have a valid input then
    //update the fieldLogicRule
    if (
      editingExitPage &&
      selectedField &&
      logicUpdate.operator_type &&
      (logicUpdate.comparison_value || logicUpdate.comparison_option_id)
    ) {
      dispatch(updateExitPageLogicThunk(editingLogicRule.id, logicUpdate, editingExitPage.id));
    } else {
      //if all criteria is not met for the logic to be update set pending changes in state
      setEditingLogicRule(logicUpdate);
    }
  };

  const isLoading = updateExitPageLogicRuleId === logicRule.id;

  const renderScaleOptions = (scaleMax?: number) => {
    let scaleOptions = [];
    if (scaleMax) {
      for (let i = 0; i < scaleMax; i++) {
        scaleOptions.push(
          <MenuItem key={i} value={i + 1}>
            {i + 1}
          </MenuItem>
        );
      }
    }
    return scaleOptions;
  };

  return (
    <Box>
      <Box className={classes.blockHeader}>
        <Box>
          <Typography>Rule #{ruleNumber + 1}</Typography>
        </Box>
        {isLoading ? (
          <CircularProgress className={classes.updatingIcon} size={20} />
        ) : (
          <Button disabled={isLoading} onClick={deleteFieldLogic} aria-label={'remove logic'}>
            <Typography variant={'subtitle2'} color={'error'}>
              Remove
            </Typography>
          </Button>
        )}
      </Box>
      <Box mb={1}>
        <Typography variant={'subtitle2'} color={'textSecondary'}>
          If the logic for:
        </Typography>
      </Box>
      <Box mb={1}>
        <FormControl fullWidth>
          <Select
            IconComponent={UnfoldMoreIcon}
            labelId="selected-target-field"
            classes={{ root: classes.selectRoot }}
            displayEmpty
            value={editingLogicRule.target_form_field_id}
            onChange={handleChangeTargetField}
            variant={'outlined'}
          >
            <MenuItem value={undefined} disabled>
              Select a Field
            </MenuItem>
            {fieldsThatAllowLogic.map((field, i) => (
              <MenuItem key={field.id} value={field.id}>
                {field.field_prompt}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box mb={1}>
        <FormControl fullWidth>
          <Select
            IconComponent={UnfoldMoreIcon}
            labelId="selected-target-field"
            classes={{ root: classes.selectRoot }}
            displayEmpty
            value={editingLogicRule.operator_type}
            onChange={handleChangeLogicalOperator}
            variant={'outlined'}
          >
            <MenuItem value={undefined} disabled>
              Select an operator
            </MenuItem>
            {allowedLogicalOperators(selectedField?.field_type).map(
              (operator: LOGIC_OPERATOR_TYPE, i: number) => (
                <MenuItem key={i} value={operator}>
                  {logicalOperatorText(operator)}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </Box>
      <Box mb={1}>
        <FormControl fullWidth>
          {selectedField?.field_type === FIELD_TYPES.SCALE ? (
            <Select
              IconComponent={UnfoldMoreIcon}
              classes={{ root: classes.selectRoot }}
              onChange={handleChangeScaleOption}
              displayEmpty
              value={editingLogicRule.comparison_value}
              variant={'outlined'}
            >
              <MenuItem value={undefined} disabled>
                Select a value
              </MenuItem>
              {renderScaleOptions(selectedField?.option_scale_max)}
            </Select>
          ) : (
            <Select
              IconComponent={UnfoldMoreIcon}
              labelId="selected-target-field"
              classes={{ root: classes.selectRoot }}
              displayEmpty
              value={editingLogicRule.comparison_option_id}
              onChange={handleChangeFieldOption}
              variant={'outlined'}
            >
              <MenuItem value={undefined} disabled>
                Select an option
              </MenuItem>
              {selectedField?.form_field_options?.map((option, i) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.option_text}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      </Box>
      {invalidScaleError() ? (
        <ErrorNotice error={'This rule has an invalid comparison value'} />
      ) : null}
      {invalidOptionError() ? (
        <ErrorNotice error={'This rule has an invalid comparison option'} />
      ) : null}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  mainContainer: {},
  blockHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  selectRoot: {
    padding: '.6rem'
  },
  dropdownRow: {
    display: 'flex'
  },
  dropdownLeft: {
    marginRight: theme.spacing(1)
  },
  updatingIcon: {
    margin: '.4rem'
  }
}));
