import {
  CatalogueType, getResourceStatusCountAction,
  createStaticResourcesAction,
  getCataloguesAction,
  getStaticResourcesAction,
  getTagsAction,
  setStaticResourceTagsAction, usersAssignmentAction, getSharingUserAction,
  StaticResourceType, getPrimaryDomainAction, getAllTagsAction, getResourceFileAction, addNewTag
} from './../data/data-actions';
import {
  setErrorAction,
  setLoadingAction,
  setSuccessAction,
  snackbarAlertAction
} from './../display/display-actions';
import { getHttp, postHttp, putHttp } from './../api';
import { RootState, FlowproThunkDispatch } from './../redux-store';
import {
  CatalogueInput,
  StaticResourceUploadResponse,
  Tag,
  UuidType,
  TagsSearchResults
} from './../../shared/domain.d';
import { push } from 'connected-react-router';
import { TAG_TYPES } from '../../shared/globals';
export const staticResourcePlaceholderThunk = () => { };

export interface GetStaticResourcesProps {
  catalogueId?: UuidType;
  staticResourceType?: StaticResourceType;
  resource_type?: string;
  order?: string;
  page?: number;
  pageSize?: number;
  search?: string;
  sort?: string;
  favourites?: boolean;
}
export const getStaticResourcesThunk = ({
  catalogueId = '',
  staticResourceType = 'approved',
  resource_type = 'ALL',
  order = 'DESC',
  page = 0,
  pageSize = 10,
  search = '',
  sort = 'name',
  favourites = true,
  organization_id = null,
}: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
    try {
      const logedUser: any = localStorage.getItem('formFlash');
      const userRoleId = JSON.parse(logedUser);
      dispatch(setSuccessAction('uploadStaticResourceSuccess', false));
      const staticResources: any = await postHttp(`/resource/getResourcesList`, {
        "organization_id": organization_id ? organization_id : userRoleId.organization_id,
        "resource_type": userRoleId && (userRoleId.role === 'SUPER_ADMIN' || userRoleId.role === 'ADMIN') ? 'BASE' : resource_type,
        "domain_id": catalogueId,
        "search": search,
        "skip": page * pageSize,
        "limit": pageSize,
        "name_sequence": sort == 'name' ? order : '',
        "type_sequence": sort == 'type' ? order : '',
        "date_sequence": sort == 'created_at' ? order : '',
        "favourites": favourites
      });
      dispatch(addNewTag(null));
      dispatch(setSuccessAction('uploadStaticResourceSuccess', false));
      dispatch(getResourceFileAction([]));
      if (staticResources) {
        staticResources.resource_type = userRoleId && (userRoleId.role === 'SUPER_ADMIN' || userRoleId.role === 'ADMIN') ? 'BASE' : resource_type;
        staticResources.catalogueId = catalogueId;
        staticResources.page = page;
        staticResources.pageSize = pageSize;
        staticResources.favourites = favourites;
        staticResources.order = order;
        staticResources.sort = sort;
        staticResources.search = search;
        dispatch(getStaticResourcesAction(staticResourceType, staticResources));
      }
    } catch (e) {
      if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
        dispatch(push('/login'));
      } else {
        dispatch(setSuccessAction('uploadStaticResourceSuccess', false));
        dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
      }
    }
  };

export const getResourceFilesThunk = ({
  resourceId = '',
  order = 'DESC',
  page = 0,
  pageSize = 10,
  search = '',
  sort = 'created_at'
}: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
    try {
      const ResourceFiles: any = await postHttp(`/resource/getResourceFiles`, {
        "resource_id": resourceId,
        "search": search,
        "skip": 0,
        "limit": 10
      });
      if (ResourceFiles) {
        ResourceFiles.page = page;
        ResourceFiles.pageSize = pageSize;
        dispatch(getResourceFileAction(ResourceFiles));
      }
    } catch (e) {
      if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
        dispatch(push('/login'));
      } else {
        dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
      }
    }
  };

export const createStaticResourcesThunk = (staticResourceInput: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const logedUser: any = localStorage.getItem('formFlash');
    const userRoleId = JSON.parse(logedUser);
    staticResourceInput.uploader_user_id = userRoleId.id;
    staticResourceInput.organization_id = userRoleId.organization_id;
    const results: StaticResourceUploadResponse = await postHttp('/resource/createResource', staticResourceInput);
    dispatch(createStaticResourcesAction(results));
    dispatch(setSuccessAction('uploadStaticResourceSuccess', true));
    dispatch(addNewTag(null));
    dispatch(snackbarAlertAction(`Resource saved successfully`, 'success'));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
      dispatch(setErrorAction('createStaticResourceError', e.message));
    }
  }
};

export const updateStaticResourcesThunk = (staticResourceInput: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const results: any = await postHttp('/resource/updateResource', staticResourceInput);
    dispatch(addNewTag(null));
    dispatch(setSuccessAction('uploadStaticResourceSuccess', true));
    dispatch(snackbarAlertAction(`Updated resource successfully`, 'success'));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
      dispatch(setErrorAction('createStaticResourceError', e.message));
    }
  }
};

export const deleteResourceThunk = (resources_id: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const results: StaticResourceUploadResponse = await postHttp('/resource/deleteResources', resources_id);
    dispatch(setSuccessAction('uploadStaticResourceSuccess', true));
    dispatch(snackbarAlertAction(`Delete resource successfully`, 'success'));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
      dispatch(setErrorAction('createStaticResourceError', e.message));
    }
  }
};

export interface GetCataloguesProps {
  catalogueType: CatalogueType;
  order?: string;
  page?: number;
  pageSize?: number;
  search?: string;
  sort?: string;
}
export const getCataloguesThunk = ({
  catalogueType,
  order = 'DESC',
  page = 0,
  pageSize = 10,
  search = '',
  sort = 'created_at'
}: GetCataloguesProps) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    const catalogues: any = await getHttp(
      `/domain/getAllDomains`
    );

    if (catalogues) {
      dispatch(getCataloguesAction(catalogueType, catalogues.domains));
    } else {
      dispatch(snackbarAlertAction(`Error loading Resources`, 'error'));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error loading Resources: ${e.message}`, 'error'));
    }
  }
};

export const getTagsByResourceThunk = (resourceId: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    dispatch(setLoadingAction('getStaticResourceTagsLoading', true, 'getStaticResourceTagsError'));
    const tags: Tag[] = await getHttp(`/tag/resource/${resourceId}`);
    if (tags) {
      dispatch(setStaticResourceTagsAction(tags));
    } else {
      dispatch(snackbarAlertAction(`Error loading tags`, 'error'));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error loading tags: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('getStaticResourceTagsLoading', false, 'getStaticResourceTagsError'));
  }
};

export interface SearchTagsProps {
  order?: string;
  page?: number;
  pageSize?: number;
  search?: string;
  tagType: TAG_TYPES;
  parentTagId?: UuidType;
}

export const searchTagsThunk = ({
  order = 'DESC',
  page = 0,
  pageSize = 10,
  search = '',
  tagType,
  parentTagId = ''
}: SearchTagsProps) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    dispatch(setLoadingAction('searchTagsLoading', true, 'searchTagsError'));
    const tagsResults: TagsSearchResults = await getHttp(
      `/tag?page=${page}&pageSize=${pageSize}&tagType=${tagType}&parentTagId=${parentTagId}&search=${search}&order=${order}`
    );
    if (tagsResults) {
      dispatch(getTagsAction(tagsResults));
    } else {
      dispatch(snackbarAlertAction(`Error loading tags`, 'error'));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error loading tags: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('searchTagsLoading', false, 'searchTagsError'));
  }
};

export const createCatalogueThunk = (catalogueInput: CatalogueInput) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const results: StaticResourceUploadResponse = await postHttp(
      '/resource/catalogue',
      catalogueInput
    );
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};
export const changeCatalogueThunk = (catalogueId: UuidType, resourceIds: UuidType[]) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const results: StaticResourceUploadResponse = await putHttp(
      `/resource/catalogue/${catalogueId}`,
      { staticReosurcesIds: resourceIds }
    );
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error creating resources: ${e.message}`, 'error'));
    }
  }
};

export const getAllPrimaryDomain = () => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const domain: any = await getHttp(`/domain/getAllDomains`);
    if (domain) {
      dispatch(getPrimaryDomainAction(domain.domains));
    } else {
      dispatch(snackbarAlertAction(`Error loading tags`, 'error'));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error loading tags: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('getStaticResourceTagsLoading', false, 'getStaticResourceTagsError'));
  }
};

export const getAllTags = () => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const tags: any = await getHttp(`/tag/getAllTags`);
    if (tags) {
      dispatch(getAllTagsAction(tags.tags));
    } else {
      dispatch(snackbarAlertAction(`Error loading tags`, 'error'));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error loading tags: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('getStaticResourceTagsLoading', false, 'getStaticResourceTagsError'));
  }
};

export const createTagThunk = (tag: string) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const results: any = await postHttp('/tag', {
      title: tag
    });
    await dispatch(getAllTags());
    await dispatch(addNewTag(results));
    dispatch(snackbarAlertAction(`Tag saved successfully`, 'success'));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
      dispatch(setErrorAction('createStaticResourceError', e.message));
    }
  }
};

export const addFavoriteResourceThunk = (id: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const sendInvite = await getHttp(`/resource/${id}/mark-as-favourite`);
    if (sendInvite) {
      dispatch(snackbarAlertAction(`Favorite added successfully`, 'success'));
      // dispatch(setSuccessAction('uploadStaticResourceSuccess', true));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const removeFavoriteResourceThunk = (ids: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const data = await postHttp(`/resource/removeFromFavourites`, {
      resource_ids: ids
    });
    if (data) {
      dispatch(setSuccessAction('uploadStaticResourceSuccess', true));
      dispatch(snackbarAlertAction(`Favorite removed successfully`, 'success'));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const duplicateResourcesThunk = (base_resource_ids: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    await postHttp(`/resource/duplicateBaseResource`, {
      "base_resource_ids": base_resource_ids,
      "organization_id": loggedInUser && loggedInUser.organization_id
    });
    dispatch(setSuccessAction('uploadStaticResourceSuccess', true));
    dispatch(snackbarAlertAction(`Resources add successfully`, 'success'));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('duplicateFormLoading', false, 'duplicateFormError'));
  }
};

export const checkUserResourceAssignmentThunk = (data: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const res: any = await postHttp(`/patient/checkUserResourceAssignment`, {
      "resource_id": data.resource_id,
      "user_role_ids": data.user_role_ids
    });
    dispatch(usersAssignmentAction(res.data));
    if (res) {
      return res.data;
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('duplicateFormLoading', false, 'duplicateFormError'));
  }
};

export const assignResourceThunk = (data: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const role: any = sessionStorage.getItem('role');
    const res: any = await postHttp(`/patient/assignResourceToUsers`, {
      "resource_id": data.resource_id,
      "user_role_ids": data.user_role_ids
    });
    if (role === 'PATIENT' || role === 'SHARED_USER') {
      dispatch(setSuccessAction('saveReferralSuccess', true));
      dispatch(setSuccessAction('uploadStaticResourceSuccess', true));
    } else {
      dispatch(setSuccessAction('saveDataSuccess', true));
    }
    dispatch(snackbarAlertAction(`Sharing settings updated successfully`, 'success'));
    dispatch(getSharingUserAction(null));
    if (res) {
      return res;
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('duplicateFormLoading', false, 'duplicateFormError'));
  }
};

/** Patient, proxy & rater API's */
export const getAssignedResourcesThunk = ({
  catalogueId = '',
  staticResourceType = 'approved',
  order = 'DESC',
  page = 0,
  pageSize = 10,
  search = '',
  sort = 'title',
  favourites = true,
  user_role_id = null,
  organization_id = null
}: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
    try {
      const logedUser: any = localStorage.getItem('formFlash');
      const userRoleId = JSON.parse(logedUser);
      dispatch(setSuccessAction('uploadStaticResourceSuccess', false));
      const staticResources: any = await getHttp(`/resource/getAssignedResources?favourites=${favourites}&organization_id=${organization_id ? organization_id : userRoleId.organization_id}&search=${search}&domain_id=${catalogueId}&skip=${page * pageSize}&limit=${pageSize}&sortBy=${sort}&order=${order}&user_role_id=${user_role_id ? user_role_id : userRoleId.id}`);
      dispatch(addNewTag(null));
      dispatch(setSuccessAction('uploadStaticResourceSuccess', false));
      dispatch(getResourceFileAction([]));
      if (staticResources) {
        staticResources.catalogueId = catalogueId;
        staticResources.page = page;
        staticResources.pageSize = pageSize;
        staticResources.favourites = favourites;
        staticResources.search = search;
        staticResources.sort = sort;
        staticResources.order = order;
        dispatch(getStaticResourcesAction(staticResourceType, staticResources));
      } else {
        dispatch(snackbarAlertAction(`Error loading Resources`, 'error'));
      }
    } catch (e) {
      if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
        dispatch(push('/login'));
      }
    }
  };

export const assignResourceToUsersThunk = (data: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const res: any = await putHttp(`/resource/updateSharingSettings`, {
      "resource_id": data.id,
      "user_role_ids": data.user_role_ids,
      "patient_id": data.patient_id,
      "clinician_id": data.clinician_id
    });
    dispatch(setSuccessAction('uploadStaticResourceSuccess', true));
    dispatch(snackbarAlertAction(`Sharing settings updated successfully`, 'success'));
    if (res) {
      return res;
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: Failed to assigned resource`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('duplicateFormLoading', false, 'duplicateFormError'));
  }
};

/**Unassign patient to resource API's */
export const unassignResourceToUsersThunk = (data: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const res: any = await postHttp(`/resource/${data.id}/unassignResource`, {
      "user_role_id": data.user_role_id,
    });
    dispatch(setSuccessAction('uploadStaticResourceSuccess', true));
    dispatch(snackbarAlertAction(`Resource unassigned successfully`, 'success'));
    if (res) {
      return res;
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('duplicateFormLoading', false, 'duplicateFormError'));
  }
};

/**Download files */
export const downloadFiles = (getUrl: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const res: any = await postHttp(`/user/auth/getSignedDownloadUrls`, {
      input_files: [getUrl]
    });
    if (res) {
      var link = document.createElement("a");
      link.download = 'download';
      link.target = "_blank";
      link.href = res.url && res.url.length > 0 && res.url[0];
      link.click();
    }
  } catch (e) { }
};

/**Get resource status count data */
export const getResourceStatusCount = (data: any) => async (dispatch: FlowproThunkDispatch) => {
  try {
    const res: any = await postHttp(`/patient/getResourceStatusCountData`, data);
    dispatch(getResourceStatusCountAction(res.data));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};