import { Box, makeStyles, Paper, Theme, Typography, Dialog, Grid, CircularProgress, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/redux-store';
import { deleteReferralProfileThunk, getReferralsFilterThunk, getReferralInfoThunk, updateReferralThunk, validatePasswordThunk } from '../../../redux/thunk/referral-thunks';
import { colors } from '../../../styling/colors';
import { ReferralDetails } from './components/ReferralDetails';
import { ReferralTopBar } from './components/ReferralTopBar';
import { LoadingButton } from '../../../components/LoadingButton';
import { NewReferralDialog } from '../components/NewReferralDialogue';
import { ConfirmationDialog } from '../../../components/ConfirmationDialog';
import { ReferralTypesDialog } from '../components/ReferralTypeDialog';
import { setSuccessAction } from '../../../redux/display/display-actions';
import { TopBar } from '../../../components/TopBar';

export const ReferralProfile = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isEdit, setIsEdit] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [pageLoading, setPageLoading] = React.useState(true);
  const [isDelete, setDeleteOpen] = React.useState(false);
  const [isType, setType] = React.useState(null);;
  const [referralDialogueOpen, setReferralDialogueOpen] = React.useState(false);
  const [referral, setReferral] = useState<any>({ about: 'about' });
  const loginUser: any = localStorage.getItem('formFlash')
  const referralId = JSON.parse(loginUser);
  const [input, setInput] = useState('');

  const referralFilterList: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.referralFilterListResults
  );
  const editReferral: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.referralInfo
  );

  useEffect(() => {
    if (!loginUser) {
      history.push('/login');
    }
  }, [])

  useEffect(() => {
    // if (referralId && referralId.referral_id) {
    // dispatch(getReferralInfoThunk('bbd1bd66-95db-4577-97c2-bef29aa56d74'));
    dispatch(getReferralInfoThunk(referralId.referral_id));
    dispatch(getReferralsFilterThunk({ type: 'ALL' }));
    // }
  }, []);

  const success: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.saveReferralSuccess)
  useEffect(() => {
    if (success) {
      setIsEdit(false);
      dispatch(setSuccessAction('saveReferralSuccess', false));
    }
  })

  const edit = (open: boolean) => {
    setIsEdit(open)
  }

  const handleReferralDialog = () => {
    setReferralDialogueOpen(true)
  }

  const handleReferralDialogClose = () => {
    setReferralDialogueOpen(false)
  }

  const deleteReferral = async () => {
    setLoading(true)
    const info = {
      "user_role_id": referralId.id,
      "password": input
    }
    const result: any = await dispatch(validatePasswordThunk(info));
    if (result) {
      const data = [{
        "referral_id": editReferral.id,
        "email": editReferral.email,
        "org_id": null
      }]
      await dispatch(deleteReferralProfileThunk(data));
    }
    setLoading(false)
  }

  const handelCloseDelete = () => {
    setDeleteOpen(false)
  }

  const handleReferralTypeDialog = (type: any) => {
    setType(type);
  }

  const handleSaveChanges = async () => {
    setLoading(true)
    const specialities: any = [];
    if (editReferral.specialities) {
      for (let i = 0; i < editReferral.specialities.length; i++) {
        specialities.push(editReferral.specialities[i]?.id ? editReferral.specialities[i].id : editReferral.specialities[i]);
      }
    }
    editReferral.specialities = specialities;
    editReferral.referral_id = editReferral.id;
    await dispatch(updateReferralThunk(editReferral));
    setLoading(false);
  }

  const handelEdit = (open: boolean) => {
    setIsEdit(open);
    if (!open) {
      dispatch(getReferralInfoThunk(referralId.referral_id));
    }
  }

  return (
    loginUser && <>
      {editReferral ? <>
        <Box className={classes.main} display="flex" flexDirection="column" p={4}>
          <Box mb={4}>
            <TopBar />
          </Box>

          <Box className={classes.contentContainer} display="flex">
            <Grid container className={classes.body}>
              <Grid item xs={12} style={{ padding: '30px', background: '#f9fafb', borderRadius: '10px 10px 0px 0px', display: 'flex', justifyContent: 'space-between' }}>
                <Typography className={classes.typography}>Referral Profile</Typography>
                <Box display="flex" justifyContent="space-between">
                  {isEdit && <LoadingButton
                    className={classes.secondaryButton}
                    color="secondary"
                    variant="contained"
                    onClick={(e) => handelEdit(false)}
                  >
                    Cancel
                  </LoadingButton>
                  }
                  {isEdit ? <LoadingButton loading={loading} disabled={loading}
                    className={classes.primaryButton}
                    color="primary"
                    variant="contained"
                    onClick={handleSaveChanges}
                  >
                    Save
                  </LoadingButton> :
                    <LoadingButton
                      className={classes.primaryButton}
                      color="primary"
                      variant="contained"
                      onClick={(e) => handelEdit(true)}
                    >
                      Edit
                    </LoadingButton>}
                </Box>
              </Grid>
              <Grid item xs={12} sm={5} md={3} className={classes.contentContainer}>
                <ReferralDetails isEdit={isEdit} />
              </Grid>
              <Grid item xs={12} sm={7} md={9} className={classes.contentContainer}>
                <Paper style={{ width: '100%', borderRadius: '0px 0px 10px 0px' }}>
                  <Box px={2.5} pt={3}>
                    <Typography noWrap variant="h2" className={classes.heading}>
                      About {isEdit && <img src={process.env.PUBLIC_URL + '/icons/EditIcon.svg'} alt="Edit Icon" onClick={() => handleReferralDialog()} style={{ marginLeft: '20px', cursor: 'pointer' }} />}
                    </Typography>
                    <Typography>{editReferral.about}</Typography>
                  </Box>
                  <Box px={2.5} pt={3}>
                    <Typography noWrap variant="h2" className={classes.heading}>
                      Insurance {isEdit && <img src={process.env.PUBLIC_URL + '/icons/EditIcon.svg'} alt="Edit Icon" onClick={() => handleReferralTypeDialog('INSURANCE')} style={{ marginLeft: '20px', cursor: 'pointer' }} />}
                    </Typography>
                    <Grid container>
                      {editReferral.insurances && editReferral.insurances.map((id: any) => {
                        const checked = referralFilterList && referralFilterList.filter((item: any) => item.id === id);
                        return (
                          <Grid item xs={12} md={6}>
                            <li><Typography style={{ display: 'contents' }}>{checked && checked.length > 0 && checked[0].title}</Typography></li>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Box>
                  <Box px={2.5} pt={3}>
                    <Typography noWrap variant="h2" className={classes.heading}>
                      Types of Therapy {isEdit && <img src={process.env.PUBLIC_URL + '/icons/EditIcon.svg'} alt="Edit Icon" onClick={() => handleReferralTypeDialog('THERAPY_TYPE')} style={{ marginLeft: '20px', cursor: 'pointer' }} />}
                    </Typography>
                    <Grid container>
                      {editReferral.therapy_types && editReferral.therapy_types.map((id: any) => {
                        const checked = referralFilterList && referralFilterList.filter((item: any) => item.id === id);
                        return (
                          <Grid item xs={12} md={6}>
                            <li><Typography style={{ display: 'contents' }}>{checked && checked.length > 0 && checked[0].title}</Typography></li>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Box>
                  <Box px={2.5} pt={3}>
                    <Typography noWrap variant="h2" className={classes.heading}>
                      Issue {isEdit && <img src={process.env.PUBLIC_URL + '/icons/EditIcon.svg'} alt="Edit Icon" onClick={() => handleReferralTypeDialog('ISSUE')} style={{ marginLeft: '20px', cursor: 'pointer' }} />}
                    </Typography>
                    <Grid container>
                      {editReferral.issues && editReferral.issues.map((id: any) => {
                        const checked = referralFilterList && referralFilterList.filter((item: any) => item.id === id);
                        return (
                          <Grid item xs={12} md={6}>
                            <li><Typography style={{ display: 'contents' }}>{checked && checked.length > 0 && checked[0].title}</Typography></li>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Box>
                  <Box px={2.5} pt={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Typography noWrap variant="h2" className={classes.heading}>
                          Age {isEdit && <img src={process.env.PUBLIC_URL + '/icons/EditIcon.svg'} alt="Edit Icon" onClick={() => handleReferralTypeDialog('AGE')} style={{ marginLeft: '20px', cursor: 'pointer' }} />}
                        </Typography>
                        <Grid container>
                          {editReferral.ages_served && editReferral.ages_served.map((id: any) => {
                            const checked = referralFilterList && referralFilterList.filter((item: any) => item.id === id);
                            return (
                              <Grid item xs={12}>
                                <li><Typography style={{ display: 'contents' }}>{checked && checked.length > 0 && checked[0].title}</Typography></li>
                              </Grid>
                            )
                          })}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography noWrap variant="h2" className={classes.heading}>
                          Language {isEdit && <img src={process.env.PUBLIC_URL + '/icons/EditIcon.svg'} alt="Edit Icon" onClick={() => handleReferralTypeDialog('LANGUAGE')} style={{ marginLeft: '20px', cursor: 'pointer' }} />}
                        </Typography>
                        <Grid container>
                          {editReferral.languages && editReferral.languages.map((id: any) => {
                            const checked = referralFilterList && referralFilterList.filter((item: any) => item.id === id);
                            return (
                              <Grid item xs={12}>
                                <li><Typography style={{ display: 'contents' }}>{checked && checked.length > 0 && checked[0].title}</Typography></li>
                              </Grid>
                            )
                          })}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box px={2.5} pt={3} pb={3}>
                    <Typography noWrap variant="h2" className={classes.heading}>
                      Sexuality {isEdit && <img src={process.env.PUBLIC_URL + '/icons/EditIcon.svg'} alt="Edit Icon" onClick={() => handleReferralTypeDialog('SEXUALITY')} style={{ marginLeft: '20px', cursor: 'pointer' }} />}
                    </Typography>
                    <Grid container>
                      {editReferral.sexuality && editReferral.sexuality.map((id: any) => {
                        const checked = referralFilterList && referralFilterList.filter((item: any) => item.id === id);
                        return (
                          <Grid item xs={12} md={6}>
                            <li><Typography style={{ display: 'contents' }}>{checked && checked.length > 0 && checked[0].title}</Typography></li>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Box>
                  {isEdit && <Box pt={3} pb={3} px={2.5} display='flex' justifyContent='flex-end'>
                    <LoadingButton
                      className={classes.deleteButton}
                      color="secondary"
                      variant="contained"
                      onClick={() => setDeleteOpen(true)}
                    >
                      Delete Profile
                    </LoadingButton>
                  </Box>
                  }
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {referralDialogueOpen && (
          <NewReferralDialog open={referralDialogueOpen} handleClose={handleReferralDialogClose}
            info={referral} edit={true} />
        )}
        {isType &&
          <ReferralTypesDialog open={isType ? true : false} handleClose={handleReferralTypeDialog} edit={isType} />
        }
        {isDelete &&
          <Dialog open={isDelete}>
            <Box style={{ width: '500px' }}>
              <Box pb={3} pt={2} px={4} mb={2} display="flex" justifyContent="space-between" style={{ background: '#F9FAFB', borderRadius: '10px' }}>
                <Typography color="textSecondary" variant="h3">Are you sure you want to delete your profile? This cannot be undone.</Typography>
              </Box>
              <Box px={4} pb={2}>
                <Box flexGrow="1">
                  <Box pb={0.75}>
                    <Typography color="textSecondary" variant="subtitle2">
                      Enter password to delete
                    </Typography>
                  </Box>
                  <TextField fullWidth name="input" variant='outlined' autoComplete='off' size='small' value={input} onChange={(e) => setInput(e.target.value)} />
                </Box>
              </Box>
              <Box
                className={classes.bottomBox}
                display="flex"
                justifyContent="space-between"
                px={3}
                py={2}
              >
                <LoadingButton
                  className={classes.secondaryButton}
                  color="secondary"
                  onClick={handelCloseDelete}
                  variant="contained"
                  aria-label="cancel"
                >
                  Cancel
                </LoadingButton>

                <LoadingButton
                  className={classes.deleteButton}
                  color="secondary"
                  onClick={deleteReferral}
                  variant="contained"
                  aria-label="confirm"
                >
                  Delete
                </LoadingButton>
              </Box>
            </Box>
          </Dialog>
        }
      </> :
        (pageLoading ? <Box display='flex' justifyContent='center' alignItems='center' style={{ margin: '40px' }}><CircularProgress /></Box> :
          <Box display='flex' justifyContent='center'>
            <Typography style={{ fontSize: '12px' }}>Data not font</Typography>
            <Typography style={{ fontSize: '12px' }}><b>“Something Went Wrong Try Again?”</b></Typography>
          </Box>)
      }
    </>
  );
};

const useStyle = makeStyles((theme: Theme) => ({
  main: {
    backgroundColor: colors.background1,
    minHeight: 'auto',
    boxSizing: 'border-box'
  },
  bottomBox: {
    borderRadius: '0 0 0.625rem 0.625rem',
    background: '#F9FAFB'
  },
  body: {
    margin: '30px 0',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap'
  },
  referralInfo: {
    [theme.breakpoints.down('xs')]: {
      paddingRight: '0',
      width: '100%'
    }
  },
  contentContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  contentContainer1: {
    [theme.breakpoints.down('xs')]: {
      flexGrow: 0,
      flexBasis: '100%',
      maxWidth: '100%',
      display: 'flex',
      flexWrap: 'wrap'
    },
    [theme.breakpoints.down('sm')]: {
      flexGrow: 0,
      flexBasis: '50%',
      maxWidth: '50%',
      display: 'flex',
      flexWrap: 'wrap'
    },
    [theme.breakpoints.down('md')]: {
      flexGrow: 0,
      flexBasis: '60%',
      maxWidth: '60%',
      display: 'flex',
      flexWrap: 'wrap'
    },
    [theme.breakpoints.down('lg')]: {
      flexGrow: 0,
      flexBasis: '70%',
      maxWidth: '70%',
      display: 'flex',
      flexWrap: 'wrap'
    }
  },
  heading: {
    marginBottom: '1rem'
  },
  editIcon: {
    marginLeft: '10px',
    fontSize: '12px',
    border: '1px solid #c1c1c1',
    color: '#c1c1c1',
    borderRadius: '3px',
    cursor: 'pointer'
  },
  deleteButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: 'red',
    minWidth: '120px'
  },
  typography: {
    fontWeight: 800,
    fontSize: '25px',
    color: '#4f4f4f'
  },
  primaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: '#fff',
    minWidth: '120px'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    marginRight: '1rem',
    color: 'blue',
    minWidth: '120px'
  }
}));
