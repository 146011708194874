import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/redux-store';
import { LogicList } from './LogicList';
interface FieldLogicProps {}

export const FieldLogic: React.FC<FieldLogicProps> = () => {
  const classes = useStyles();
  const getFieldLogicRulesLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.getFieldLogicRulesLoading
  );
  const editingFormField = useSelector((state: RootState) => state.DATA_REDUCER.editingFormField);
  return (
    <Box p={3}>
      {getFieldLogicRulesLoading && !editingFormField ? (
        <Box className={classes.loading}>
          <Typography>Loading Logic rules...</Typography>
        </Box>
      ) : (
        <LogicList />
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
