import React, { useEffect } from 'react';
import { Box, Grid, makeStyles, Theme } from '@material-ui/core';
import { ViewOrganization } from './OrganizationProfile';
import { SideBar } from '../../../components/SideBar';
import { TopBar } from '../../../components/TopBar';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/redux-store';
import { colors } from '../../../styling/colors';
import { getOrganizationThunk } from '../../../redux/thunk/thunks';

export const OrganizationProfilePage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);

    const orgInfo: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.organizationProfile
    );
    useEffect(() => {
        loggedInUser && dispatch(getOrganizationThunk(loggedInUser.organization_id));
    }, [])

    return (
        <Box className={classes.main} display="flex" flexDirection="column" p={4}>
            <Box mb={4}>
                <TopBar />
            </Box>

            <Grid container>
                <Grid item xs={12} sm={2} md={2}>
                    <SideBar />
                </Grid>
                <Grid item xs={12} sm={10} md={10}>
                    <ViewOrganization viewInfo={orgInfo} />
                </Grid>
            </Grid>
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    main: {
        [theme.breakpoints.down('xs')]: {
            padding: '1rem'
        },
        backgroundColor: colors.background1,
        minHeight: '100vh'
    },
    contentContainer: {
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap'
        }
    }
}));
