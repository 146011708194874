import { Chip, List, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DragIndicator } from '@material-ui/icons';
import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { UuidType } from '../../../shared/domain';
import { FIELD_TYPES } from '../../../shared/globals';
interface FieldsSideBarProps {
  formId: UuidType;
}

const fieldTypes = [
  { type: FIELD_TYPES.SHORT_TEXT, name: 'Short Answer' },
  { type: FIELD_TYPES.LONG_TEXT, name: 'Long Answer' },
  { type: FIELD_TYPES.DROPDOWN, name: 'Dropdown' },
  { type: FIELD_TYPES.SINGLE_CHOICE, name: 'Single Choice' },
  { type: FIELD_TYPES.MULTIPLE_CHOICE, name: 'Multiple Choice' },
  { type: FIELD_TYPES.SCALE, name: 'Scale' },
  { type: FIELD_TYPES.RESOURCE, name: 'Resource' },
  { type: FIELD_TYPES.INFO_TEXT, name: 'Text Block' }
];

export const FieldsSideBar: React.FC<FieldsSideBarProps> = ({ formId }) => {
  const classes = useStyles();

  return (
    <div className={classes.drawerContent}>
      <Typography className={classes.sidebarTitle} variant="subtitle2">
        FORM FIELDS
      </Typography>
      <Droppable isDropDisabled={true} droppableId={'sideBarDroppable'}>
        {(provided) => (
          <List ref={provided.innerRef} {...provided.droppableProps}>
            {fieldTypes.map((field, index) => (
              <Draggable key={index} draggableId={field.type} index={index}>
                {(provided) => (
                  <Chip
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    icon={<DragIndicator className={classes.dragIcon} />}
                    className={classes.draggableField}
                    key={field.name}
                    label={field.name}
                    classes={{ label: classes.label }}
                  />
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  drawerContent: {
    paddingLeft: '.5rem',
    paddingRight: '.5rem',
    flex: 1,
    maxWidth: '20rem'
  },
  sidebarTitle: {
    opacity: 0.5
  },
  dragIcon: {
    color: theme.palette.action.disabledBackground
  },
  draggableField: {
    backgroundColor: '#ffffff',
    width: '100%',
    marginBottom: '.8rem',
    justifyContent: 'left',
    boxShadow: theme.shadows[1]
  },
  label: {
    fontSize: '.8rem'
  }
}));
