import React from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';

export const SwitchRoleScreen = () => {

    return (
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" style={{ height: '100vh' }}>
            <Typography variant="h3">Switching Role</Typography>
            <span style={{ margin: '0 0px 20px' }}>Please wait...</span>
            <CircularProgress
                size={50}
                style={{}}
            />
        </Box>
    );
};
