import { getSharedUsersAction } from '../data/data-actions';
import { snackbarAlertAction, setSuccessAction, setErrorAction } from '../display/display-actions';
import { postHttp, putHttp, getHttp } from '../api';
import { RootState, FlowproThunkDispatch } from '../redux-store';
import { push } from 'connected-react-router';

export const getSharedUsersThunk = ({
    order = 'DESC',
    page = 0,
    pageSize = 10,
    search = '',
    sort = 'first_name',
    giveOnlyActiveUsers = false,
    user_role_id = null,
    organization_id = null,
}) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
    try {
        const logedUser: any = localStorage.getItem('formFlash');
        const loggedInUser = JSON.parse(logedUser);
        const sharingUsers: any = await getHttp(
            `/patient/shared-users/${user_role_id ? user_role_id : loggedInUser.id}?search=${search}&skip=${page * pageSize}&limit=${pageSize}&sortBy=${sort}&order=${order}&organization_id=${organization_id ? organization_id : loggedInUser.organization_id}&giveOnlyActiveUsers=${giveOnlyActiveUsers}`
        );
        if (sharingUsers) {
            sharingUsers.order = order;
            sharingUsers.page = page;
            sharingUsers.pageSize = pageSize;
            sharingUsers.search = search;
            sharingUsers.sort = sort;
            sharingUsers.giveOnlyActiveUsers = giveOnlyActiveUsers;
            dispatch(getSharedUsersAction(sharingUsers));
        }
    } catch (e) {
        if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
            dispatch(push('/login'));
        } else {
            dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
        }
    }
};

export const saveSharedUserThunk = (data: any) => async (
    dispatch: FlowproThunkDispatch, getState: () => RootState
) => {
    try {
        const logedUser: any = localStorage.getItem('formFlash');
        const loggedInUser = JSON.parse(logedUser);
        const result = await postHttp(`/patient/createSharedUser`, {
            "first_name": data.first_name,
            "last_name": data.last_name,
            "email": data.email,
            "role": data.role,
            "birth_date": data.birth_date,
            "organization_id": data?.organization_id ? data.organization_id : loggedInUser.organization_id,
            "associated_patient_id": data?.associated_patient_id ? data.associated_patient_id : loggedInUser.id
        });
        if (result) {
            // dispatch(setSuccessAction('saveDataSuccess', true));
            dispatch(setSuccessAction('deleteUserSuccess', true))
            dispatch(snackbarAlertAction(`Shared user saved successfully`, 'success'));
        }
    } catch (e) {
        if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
            dispatch(push('/login'));
        } else {
            dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
        }
    }
};
