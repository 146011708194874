import { Button, Card, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DragIndicator } from '@material-ui/icons';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/redux-store';
import { FIELD_TYPES } from '../../../../shared/globals';
import { DropdownFieldBlock } from './DropdownFieldBlock';
import { InfoTextFieldBlock } from './InfoTextBlock';
import { ResourceBlock } from './ResourceBlock';
import { LongTextFieldBlock } from './LongTextFieldBlock';
import { MultipleChoiceFieldBlock } from './MultipleChoiceFieldBlock.tsx';
import { ScaleFieldBlock } from './ScaleFieldBlock';
import { ShortTextFieldBlock } from './ShortTextFieldBlock';
import { SingleChoiceFieldBlock } from './SingleChoiceFieldBlock';
interface FieldDisplayBlockProps { }
//this componenet only displays the editingFormField obj in redux
export const FieldDisplayBlock: React.FC<FieldDisplayBlockProps> = ({ }) => {
  const classes = useStyles();
  const formField: any = useSelector((state: RootState) => state.DATA_REDUCER.editingFormField);

  const fieldType = (fieldType?: string) => {
    if (formField) {
      //make sure options, prompt, and scaleMax are not undefined
      const structuredOptions =
        formField?.form_field_options?.map((ffo: any) => ({
          option_text: ffo.option_text || ''
        })) || [];
      const fieldTitle = formField?.field_prompt.split("\n") || null;
      const scaleMax = formField?.option_scale_max || 0;
      switch (fieldType) {
        case FIELD_TYPES.SHORT_TEXT:
          return (
            <>
              <Title fieldTitle="SHORT ANSWER" />
              <ShortTextFieldBlock fieldTitle={fieldTitle} />
            </>
          );
        case FIELD_TYPES.LONG_TEXT:
          return (
            <>
              <Title fieldTitle="LONG ANSWER" />
              <LongTextFieldBlock fieldTitle={fieldTitle} />
            </>
          );
        case FIELD_TYPES.DROPDOWN:
          return (
            <>
              <Title fieldTitle="DROPDOWN" />
              <DropdownFieldBlock fieldTitle={fieldTitle} options={structuredOptions} />
            </>
          );
        case FIELD_TYPES.SINGLE_CHOICE:
          return (
            <>
              <Title fieldTitle="SINGLE CHOICE" />
              <SingleChoiceFieldBlock fieldTitle={fieldTitle} options={structuredOptions} />
            </>
          );
        case FIELD_TYPES.MULTIPLE_CHOICE:
          return (
            <>
              <Title fieldTitle="MULTIPLE CHOICE" />
              <MultipleChoiceFieldBlock
                fieldTitle={fieldTitle}
                options={structuredOptions}
              />
            </>
          );
        case FIELD_TYPES.SCALE:
          return (
            <>
              <Title fieldTitle="SCALE" />
              <ScaleFieldBlock fieldTitle={fieldTitle} scaleMax={scaleMax} />
            </>
          );
        case FIELD_TYPES.INFO_TEXT:
          return (
            <>
              <Title fieldTitle="TEXT BLOCK" />
              <InfoTextFieldBlock infoText={fieldTitle} />
            </>
          );
        case FIELD_TYPES.RESOURCE:
          return (
            <>
              <Title fieldTitle="RESOURCE" />
              <ResourceBlock infoText={fieldTitle} files={formField?.resources || []} index={null} />
            </>
          );
        default:
          break;
      }
    }
  };

  const Title = ({ fieldTitle }: { fieldTitle: string }) => (
    <div className={classes.header}>
      <Typography color={'textSecondary'} variant="subtitle2">
        {fieldTitle} FIELD
      </Typography>
    </div>
  );
  return (
    <Card variant={'outlined'}>
      <div className={classes.cardContent}>{fieldType(formField?.field_type)}</div>
      <div className={classes.disabledActions}>
        <IconButton
          disabled={true}
          className={classes.bottomBtn}
          size={'small'}
          aria-label="drag field"
        >
          <DragIndicator />
        </IconButton>
        <Button disabled={true} className={classes.bottomBtn} aria-label="duplicate field">
          Duplicate
        </Button>
        <Button disabled={true} className={classes.bottomBtn} aria-label="edit field">
          Edit
        </Button>
        <Button disabled={true} className={classes.bottomBtn} aria-label="remove field">
          <Typography variant={'subtitle2'} color={'error'}>
            Remove
          </Typography>
        </Button>
      </div>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  disabledActions: {
    opacity: 0.5,
    display: 'flex',
    borderTop: `1px solid ${theme.palette.divider}`
  },
  cardBottom: {
    display: 'flex',
    borderTop: `1px solid ${theme.palette.divider}`
  },
  cardContent: {
    display: 'grid',
    gridGap: '.4rem',
    padding: '1rem'
  },
  bottomBtn: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRadius: 0,
    marginLeft: -1,
    paddingLeft: '1rem',
    paddingRight: '1rem'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));
