import { Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorNotice } from '../../../../components/ErrorNotice';
import { LoadingButton } from '../../../../components/LoadingButton';
import {
  setEditingFormFieldAction,
  setFormFieldOptionsToDeleteAction
} from '../../../../redux/data/data-actions';
import { RootState } from '../../../../redux/redux-store';
import { updateFormFieldThunk, putResourceFilesFormFieldThunk } from '../../../../redux/thunk/thunks';
import { FieldOptionUpdate, UuidType } from '../../../../shared/domain';
import { FIELD_TYPES } from '../../../../shared/globals';
import { ClinicianInfoSettings } from './ClinicianInfoSettings';
import { PatientInfoSettings } from './PatientInfoSettings';
import { OptionsSettings } from './OptionsSettings';
import { ScaleSettings } from './ScaleSettings';
import { ResourceFilesSettings } from './ResourceFiles';
import { TitleSettings } from './TitleSettings';
import { url } from '../../../../redux/url';
import { snackbarAlertAction } from '../../../../redux/display/display-actions';

var axios = require("axios");
var FormData = require("form-data");

interface FieldTypeSettingsProps { }

export const FieldTypeSettings: React.FC<FieldTypeSettingsProps> = ({ }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const editingFormField: any = useSelector((state: RootState) => state.DATA_REDUCER.editingFormField);
  const editingForm = useSelector((state: RootState) => state.DATA_REDUCER.editingForm);
  const fieldOptionsToDelete = useSelector(
    (state: RootState) => state.DATA_REDUCER.formFieldOptionsToDelete
  );
  const updateFormFieldLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.updateFormFieldLoading
  );
  const updateFormFieldError = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayErrors.updateFormFieldError
  );
  const updateFormFieldId = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.updateFormFieldId
  );

  //.....................resource...........

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [files, setFiles] = useState<any>(editingFormField?.resources && editingFormField?.resources.length > 0 ? editingFormField?.resources : []);
  const [isMsg, setIsMsg] = useState<any>('');

  // useEffect(() => {
  //   if ((files && files.length === 0) && (editingFormField?.resources && editingFormField?.resources.length > 0)) {
  //     setFiles(editingFormField.resources);
  //   }
  // })

  const _getHeaders = (inputHeaders?: { [key: string]: string }) => {
    let headers = inputHeaders || {};
    let token = localStorage.getItem('token');
    headers["Accept"] = "application/json";
    const headersWithAuth = token
      ? {
        ...headers,
        Authorization: `Bearer ${token}`,
      }
      : headers;
    return headersWithAuth;
  };
  const addMultiFiles = async (e: any) => {
    const array: any = [];
    for (let i = 0; i < e.length; i++) {
      const size = e[i].size / 1024 / 1024;
      const convertSize: any = parseInt(size.toFixed(0));
      if (!e[i].name.match(/\.(jpg|jpeg|png|gif|ppt|doc|docx|pdf|tiff|pptx|txt)$/)) {
        setError(true);
        setIsMsg('Please check file type')
      } else {
        if (convertSize > 100) { setError(true); setIsMsg('Please check file size'); }
        else {
          array.push(e[i])
        }
      }
    }
    if (array && array.length > 0) {
      setError(false);
      setLoading(true);
      var data = new FormData();
      for (let i = 0; i < array.length; i++) {
        data.append("input_files", array[i]);
      }

      var config = {
        method: 'post',
        url: `${url}/user/auth/uploadMultipleFilesOnPublicS3/${array.length}`,
        headers: _getHeaders({ "Content-Type": "multipart/form-data" }),
        data: data,
      };

      await axios(config)
        .then(function (response: any) {
          setLoading(false);
          for (let i = 0; i < response.data.url.length; i++) {
            let info: any = {
              resource_link: response.data.url[i],
              file_name: array[i].name
            }
            files.push(info)
          }
          setIsAdd(isAdd ? false : true)
        })
        .catch(function (error: any) {
          setLoading(false);
          dispatch(snackbarAlertAction(`${error?.message}`, 'error'));
        });
    }
  }

  const removeFile = (index: any) => {
    const file = files.filter((e: any, i: number) => i !== index);
    setFiles(file);
    setIsAdd(isAdd ? false : true)
  }

  //...........................................

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (editingFormField) {
      dispatch(
        setEditingFormFieldAction({
          ...editingFormField,
          field_prompt: event.target.value
        })
      );
    }
  };
  const handleClinicianInfoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (editingFormField) {
      dispatch(
        setEditingFormFieldAction({
          ...editingFormField,
          info_for_clinician: event.target.value
        })
      );
    }
  };
  const handlePatientInfoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (editingFormField) {
      dispatch(
        setEditingFormFieldAction({
          ...editingFormField,
          info_for_patient: event.target.value
        })
      );
    }
  };
  const handleOptionsChange = (options: FieldOptionUpdate[]) => {
    if (editingFormField) {
      dispatch(
        setEditingFormFieldAction({
          ...editingFormField,
          form_field_options: options
        })
      );
    }
  };
  const handleOptionsToDelete = (optionIds: UuidType[]) => {
    const newArray = optionIds?.filter((id) => id !== '1');
    const array = newArray?.length === 0 ? null : newArray;
    dispatch(setFormFieldOptionsToDeleteAction(array));
  };
  const handleScaleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (editingFormField) {
      dispatch(
        setEditingFormFieldAction({
          ...editingFormField,
          option_scale_max: event.target.value as number
        })
      );
    }
  };
  const handleUploadFileChange = () => {

  }
  const handleUploadFileToDelete = () => {

  }
  const fieldTypeSettings = (fieldType?: string) => {
    switch (fieldType) {
      case FIELD_TYPES.SHORT_TEXT:
        return;
      case FIELD_TYPES.LONG_TEXT:
        return;
      case FIELD_TYPES.DROPDOWN:
        return (
          <>
            <OptionsSettings
              options={editingFormField?.form_field_options || []}
              handleOptionsChange={handleOptionsChange}
              handleOptionsToDelete={handleOptionsToDelete}
              titleClass={classes.title}
            />
            <Divider className={classes.divider} />
          </>
        );
      case FIELD_TYPES.SINGLE_CHOICE:
        return (
          <>
            <OptionsSettings
              options={editingFormField?.form_field_options || []}
              handleOptionsChange={handleOptionsChange}
              handleOptionsToDelete={handleOptionsToDelete}
              titleClass={classes.title}
            />
            <Divider className={classes.divider} />
          </>
        );
      case FIELD_TYPES.MULTIPLE_CHOICE:
        return (
          <>
            <OptionsSettings
              options={editingFormField?.form_field_options || []}
              handleOptionsChange={handleOptionsChange}
              handleOptionsToDelete={handleOptionsToDelete}
              titleClass={classes.title}
            />
            <Divider className={classes.divider} />
          </>
        );
      case FIELD_TYPES.SCALE:
        return (
          <>
            <ScaleSettings
              handleScaleChange={handleScaleChange}
              titleClass={classes.title}
              scale={editingFormField?.option_scale_max}
            />
            <Divider className={classes.divider} />
          </>
        );
      case FIELD_TYPES.RESOURCE:
        return (
          <>
            <ResourceFilesSettings
              files={files}
              handleUploadFileChange={addMultiFiles}
              handleUploadFileToDelete={removeFile}
              titleClass={classes.title}
              loading={loading}
              error={error}
              isMsg={isMsg}
            />
            <Divider className={classes.divider} />
          </>
        );
      default:
        break;
    }
  };
  const saveChanges = () => {
    //Seperate formfieldoptions into 2 arrays. one for creations and one for updates
    //formfieldoptions that do not yet exist will not have ids

    if (editingFormField && editingForm && editingFormField?.id) {
      //filter options with ids that are '1'
      let fieldOptionsToCreate = editingFormField?.form_field_options?.filter(
        (option: any) => option.id === '1'
      );
      //filter options with regular ids
      let fieldOptionsToUpdate = editingFormField?.form_field_options?.filter(
        (option: any) => option.id !== '1'
      );
      if (editingFormField?.field_type === "RESOURCE") {
        dispatch(putResourceFilesFormFieldThunk({
          "form_id": editingFormField?.form_id,
          "form_field_id": editingFormField?.id,
          "files": files
        }))
      }
      dispatch(
        updateFormFieldThunk(
          editingFormField,
          editingForm?.id,
          fieldOptionsToUpdate as FieldOptionUpdate[],
          fieldOptionsToCreate,
          fieldOptionsToDelete
        )
      );
    }
  };
  return (
    <Box p={3} className={classes.mainContainer}>
      <TitleSettings
        handleTitleChange={handleTitleChange}
        fieldTitle={editingFormField?.field_prompt || ''}
        titleClass={classes.title}
      />
      <Divider className={classes.divider} />
      <ClinicianInfoSettings
        handleClinicianInfoChange={handleClinicianInfoChange}
        clinicianInfo={editingFormField?.info_for_clinician || ''}
        titleClass={classes.title}
      />
      <Divider className={classes.divider} />
      <PatientInfoSettings
        handlePatientInfoChange={handlePatientInfoChange}
        patientInfo={editingFormField?.info_for_patient || ''}
        titleClass={classes.title}
      />
      <Divider className={classes.divider} />
      {
        //TODO: add description and placeholder fields to formfield model
        // <DescriptionSettings titleClass={classes.title} />
        // <Divider className={classes.divider} />
        // <PlaceholderSettings titleClass={classes.title} />
        // <Divider className={classes.divider} />
      }
      {fieldTypeSettings(editingFormField?.field_type)}
      <div>
        <LoadingButton
          onClick={saveChanges}
          variant="contained"
          fullWidth
          disabled={updateFormFieldLoading}
          color={'primary'}
          loading={updateFormFieldLoading}
          aria-label="save changes"
        >
          Save Changes
        </LoadingButton>
        <Box className={classes.errorContainer}>
          {updateFormFieldError ? <ErrorNotice error={updateFormFieldError} /> : null}
        </Box>
      </div>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  mainContainer: {},
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  errorContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '.5rem'
  }
}));
