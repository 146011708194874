import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, Theme, Typography, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { Dialog } from '../../../../components/Dialog';
import { editCollectionThunk } from '../../../../redux/thunk/thunks';
import { LoadingButton } from '../../../../components/LoadingButton';
import { TitledTextInput } from '../../../../components/TitledTextInput';
import { Collection } from '../../../../shared/domain';
import { RootState } from '../../../../redux/redux-store';
import { setSuccessAction } from '../../../../redux/display/display-actions';

interface EditCollectionDialogProps {
  collection: Collection;
  library?: boolean;
  open: boolean;
  handleClose: () => void;
}

export const EditCollectionDialog = ({
  collection,
  library,
  open,
  handleClose
}: EditCollectionDialogProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const editCollectionLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.editCollectionLoading
  );
  const editCollectionSuccess = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.editCollectionSuccess
  );

  const [collectionName, setCollectionName] = useState(collection.name ?? 'Untitled Collection');
  const [collectionDescription, setCollectionDescription] = useState(collection.description ?? '');

  const handleCollectionNameChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCollectionName(e.target?.value);
  };

  const handleCollectionDescriptionChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setCollectionDescription(e.target?.value);
  };

  const handleEditCollection = async () => {
    // dispatch(
    //   editCollectionThunk(
    //     collection.id,
    //     collectionName.trim().length > 0
    //       ? collectionName.trim()
    //       : `Untitled ${library ? 'Library' : 'Collection'}`,
    //     collectionDescription.trim()
    //   )
    // );
  };

  useEffect(() => {
    if (editCollectionSuccess) {
      dispatch(setSuccessAction('editCollectionSuccess', false));

      setCollectionName('');
      handleClose();
    }
  }, [editCollectionSuccess]);

  return (
    <Dialog handleClose={handleClose} open={open}>
      <Box display="flex" flexDirection="column">
        <Box
          alignItems="center"
          className={classes.topBox}
          display="flex"
          justifyContent="space-between"
          px={3}
          py={2.5}
        >
          <Typography variant="h3">{`Editing ${library ? 'library' : 'collection'}`}</Typography>
        </Box>

        <Box px={3} py={2.5}>
          <TitledTextInput
            className={classes.editCollectionTextField}
            name="name"
            noValidation
            onChange={handleCollectionNameChange}
            placeholder={`Untitled ${library ? 'Library' : 'Collection'}`}
            size="small"
            title={`${library ? 'Library' : 'Collection'} Name`}
            value={collectionName}
            variant="outlined"
          />

          <TitledTextInput
            className={classes.editCollectionTextField}
            multiline
            name="name"
            noValidation
            onChange={handleCollectionDescriptionChange}
            size="small"
            title={`${library ? 'Library' : 'Collection'} Description`}
            value={collectionDescription}
            variant="outlined"
          />
        </Box>

        <Box
          className={classes.bottomBox}
          display="flex"
          justifyContent="space-between"
          px={3}
          py={2}
        >
          <LoadingButton
            className={classes.secondaryButton}
            color="secondary"
            onClick={handleClose}
            variant="contained"
            aria-label={'cancel'}
          >
            Cancel
          </LoadingButton>

          <LoadingButton
            className={classes.primaryButton}
            color="primary"
            disabled={editCollectionLoading}
            loading={editCollectionLoading}
            onClick={handleEditCollection}
            variant="contained"
            aria-label={'edit collection'}
          >
            {`Edit ${library ? 'Library' : 'Collection'}`}
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  topBox: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '0.625rem 0.625rem 0 0'
  },
  bottomBox: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '0 0 0.625rem 0.625rem'
  },
  primaryButton: {
    boxShadow:
      '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)'
  },
  editCollectionTextField: {
    maxWidth: '28rem',
    width: '80vw'
  }
}));
