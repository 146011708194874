import { makeStyles, TextField, Typography } from '@material-ui/core';
import React from 'react';
interface ShortTextBlockProps {
  fieldTitle: any;
}

export const ShortTextFieldBlock: React.FC<ShortTextBlockProps> = ({ fieldTitle }) => {
  const classes = useStyles();
  //TODO: Fix the alignment of the label
  return (
    <>
      {fieldTitle && fieldTitle.map((queName: any) => {
        return <Typography variant="subtitle2"><b>{queName ? queName : ''}</b></Typography>
      })}

      <TextField
        classes={{ root: classes.root }}
        id="outlined-basic"
        label="Insert Answer Here"
        variant="outlined"
        size={'small'}
        fullWidth
        disabled={true}
      />
    </>
  );
};
const useStyles = makeStyles((theme) => ({
  root: {}
}));
