import React, { useEffect, useState } from 'react';
import {
    Box, Theme, Typography, makeStyles, Grid, Divider, Select,
    MenuItem,
    DialogContent,
    TextField,
    DialogActions,
    DialogContentText,
    DialogTitle,
    FormControl
} from '@material-ui/core';
import { ArrowBack, Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import MUIDialog from '@material-ui/core/Dialog'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { Dialog } from '../../../../components/Dialog';
import { RootState } from '../../../../redux/redux-store';
import { ROLES } from '../../../../shared/globals';
import { getUnitThunk, getAccountRolesThunk, getStateThunk, getDepartmentThunk, getAccountsThunk, updateProfileThunk, sendInviteToUser, createUnitThunk, getSiteAdminAccountsThunk } from '../../../../redux/thunk/thunks';
import { LoadingButton } from '../../../../components/LoadingButton';
import { User } from '../../../../shared/domain';

interface ViewAccountsProps {
    handleClose: () => void;
    editOrganization: () => void;
    viewInfo: any,
    handleDeleteUserButton: (user: User) => void;
    isEditInfo: boolean;
    setEditAccount: () => void
}

interface FilmOptionType {
    name: string;
    id: string;
}

const filter = createFilterOptions<FilmOptionType>();

export const ViewAccounts = ({ handleClose, editOrganization, viewInfo, handleDeleteUserButton, isEditInfo, setEditAccount }: ViewAccountsProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const [disDate, setDisabledDate] = useState<any>();
    const [zip, setZip] = useState<any>(viewInfo.zip ? viewInfo.zip : '');
    const [isEdit, setIsEdit] = useState(true);
    const [isActive, setIsActive] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isError, setIsError] = useState(false);
    const [inputs, setInputs] = useState(null);
    const [unit, setUnit] = useState<any>('');
    const [unitId, setUnitId] = useState<any>(viewInfo.unit_id ? viewInfo.unit_id : '');
    const [unitValue, setUnitValue] = useState('');
    const [isAdd, setIsAdd] = useState(false);
    const [openToggle, toggleOpen] = useState(false);
    const [dialogValue, setDialogValue] = useState({
        name: '',
        id: '',
    });

    const newlyAddedUnit: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.unit
    );
    useEffect(() => {
        if (newlyAddedUnit) {
            const isCheckedTag = unitList.filter((item: any) => item === newlyAddedUnit.id);
            if (isCheckedTag && isCheckedTag.length === 0) {
                unitList.push(newlyAddedUnit.id);
                setUnit(newlyAddedUnit.name);
                setUnitId(newlyAddedUnit.id);
            }
        }
        if (unitList && unitList.length > 0 && unit === '') {
            const isChecked = unitList.filter((item: any) => item.id === viewInfo.unit_id);
            isChecked && isChecked.length > 0 && setUnit(isChecked[0].name);
            isChecked && isChecked.length > 0 && setIsAdd(isAdd ? false : true);
        }
    })

    const handleSetUnit = (e: any) => {
        // const value = tagList.filter((item: any) => item.title === e)
        if (e && e.name) {
            setUnit(e.name)
            setUnitId(e.id)
        } else {
            setUnit('')
        }
    }
    const addNewUnit = async () => {
        await dispatch(createUnitThunk(unitValue))
        handelCloseDialog();
        setUnitValue('');
    }

    const handleCloseView = () => {
        handleClose();
        setUnitValue('');
        setUnit('');
        setUnitId('');
        setDialogValue({
            name: '',
            id: '',
        })
    }

    const handelCloseDialog = () => {
        toggleOpen(false);
    }


    const viewData: any = {
        role: viewInfo?.role || '',
        role_id: viewInfo?.role_id || '',
        email: viewInfo?.email || '',
        first_name: viewInfo?.first_name || '',
        last_name: viewInfo?.last_name || '',
        mobile_phone: viewInfo?.mobile_phone || '',
        birth_date: viewInfo?.birth_date || '',
        home_address: viewInfo?.home_address || '',
        city: viewInfo?.city || '',
        state: viewInfo?.state || '',
        zip: viewInfo?.zip || zip,
        id: viewInfo?.id || '',
        department_id: viewInfo?.department_id || null,
        status: viewInfo.status ? viewInfo.status.replace(/_/g, " ") : '',
        notes: viewInfo?.notes || '',
        user_role_id: viewInfo?.user_role_id || null,
        userId: viewInfo?.user_id || null
    };


    const [initialValues, setInitialValues] = useState({
        role: viewInfo?.role || '',
        role_id: viewInfo?.role_id || '',
        email: viewInfo?.email || '',
        first_name: viewInfo?.first_name || '',
        last_name: viewInfo?.last_name || '',
        mobile_phone: viewInfo?.mobile_phone || '',
        birth_date: viewInfo?.birth_date || '',
        home_address: viewInfo?.home_address || '',
        city: viewInfo?.city || '',
        state: viewInfo?.state || '',
        zip: viewInfo?.zip || zip,
        id: viewInfo?.id || '',
        department_id: viewInfo?.department_id || null,
        status: viewInfo.status ? viewInfo.status.replace(/_/g, " ") : '',
        notes: viewInfo?.notes || '',
        user_role_id: viewInfo?.user_role_id || null,
        userId: viewInfo?.user_id || null
    });

    const departmentList: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.departmentList
    );
    const stateList: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.stateList
    );
    const roleList: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.roleList
    );
    const unitList: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.unitList
    );
    const success: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.createCollectionSuccess)

    useEffect(() => {
        dispatch(getUnitThunk());
        dispatch(getStateThunk());
        dispatch(getAccountRolesThunk());
        dispatch(getDepartmentThunk());

        isEditInfo && setIsEdit(true);
        let newDate: any = Date.now();
        setDisabledDate(new Date(newDate).getFullYear() + '-' + (("0" + (new Date(newDate).getMonth() + 1)).slice(-2)) + '-' + ("0" + (new Date(newDate).getDate() - 1)).slice(-2));
    }, [])
    useEffect(() => {
        if (success) {
            setIsEdit(false);
            if (loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role)) {
                dispatch(getSiteAdminAccountsThunk({ order: 'ASC', sortBy: "first_name", page: 0, pageSize: 10, search: '' }));
            } else {
                dispatch(getAccountsThunk({ id: loggedInUser ? loggedInUser.id : '', page: 0, pageSize: 10, search: '', nameSequence: 'ASC', lastLoginSequence: 'DESC' }));
            }
        }
    })

    const handleSubmit = async (values: any, actions: any) => {
        if (isActive) {
            setIsActive(false);
        } else {
            actions.setSubmitting(true);
            const role = roleList.filter((e: any) => e.id === values.role_id)
            const birth = values.birth_date !== '' ? (new Date(values.birth_date).getFullYear() + '-' + (("0" + (new Date(values.birth_date).getMonth() + 1)).slice(-2)) + '-' + ("0" + (new Date(values.birth_date).getDate())).slice(-2)) : ''
            const data = values;
            data.birth_date = birth;
            data.zip = zip ? zip : '';
            data.role = role[0].role;
            data.unit_id = unitId !== '' ? unitId : null;
            await dispatch(updateProfileThunk(data));
            actions.setSubmitting(false);
        }
    }

    const handelEdit = (value: boolean) => {
        setIsEdit(value);
        setIsActive(true);
        setEditAccount()
    }

    const handleDeleteUser = () => {
        handleDeleteUserButton(viewInfo);
    }

    const profileSchema = Yup.object().shape({
        first_name: Yup.string().required("Required")
            .max(50, ''),
        last_name: Yup.string().required("Required")
            .max(50, ''),
        city: Yup.string()
            .max(50, ''),
        mobile_phone: Yup.string().min(10, "Too Short!")
            .max(12, ''),
        home_address: Yup.string()
            .max(100, ''),
        email: Yup.string().email("Enter Valid Email").required("Please enter Email"),
        notes: Yup.string().max(250, ''),
    });

    const handelChange = (value: any) => {
        const re = /^[0-9\b]+$/;
        if (re.test(value) && value.length < 6) {
            setZip(value)
        } else {
            value.length > 6 || value.length == 0 && setZip('')
        }
    }

    const isnumber = (value: any) => {
        const test = parseInt(value);
        if (test) {
            return Math.max(0, parseInt(value)).toString().slice(0, 12)
        } else {
            return null
        }
    }

    const sendInvite = () => {
        dispatch(sendInviteToUser(viewInfo.user_role_id));
        setIsOpen(true)
    }
    const handleCloseDialog = () => {
        setIsOpen(false)
    }

    const handleChange = (value: any, no: any) => {
        if (no === 0 && value.length > 50) {
            setIsError(true); setInputs(no);
        } else if (no === 1 && value.length > 50) {
            setIsError(true); setInputs(no);
        } else if (no === 2 && value.length > 50) {
            setIsError(true); setInputs(no);
        } else if (no === 3 && value.length > 100) {
            setIsError(true); setInputs(no);
        } else if (no === 4 && value.length > 250) {
            setIsError(true); setInputs(no);
        } else {
            setIsError(false); setInputs(no);
        }
    }

    return (
        <>
            <Box display="flex" flexDirection="column">
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validateOnBlur={false}
                    validateOnChange={false}
                    validationSchema={profileSchema}
                >
                    {({ errors, isSubmitting, setFieldValue, setSubmitting, touched, values }) => {
                        if (isSubmitting) {
                            setSubmitting(false);
                        }

                        return (
                            <Form className={classes.formCont}>
                                <Box
                                    className={classes.topBox}
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="space-between"
                                    py={2.5}
                                >
                                    <Box alignItems="center" display="flex" justifyContent="space-between">
                                        <Box display='flex' alignItems="center">
                                            <ArrowBack style={{ fontSize: '30px', color: 'gray', cursor: 'pointer', marginTop: '10px' }} onClick={handleCloseView} />
                                            <Divider orientation="vertical" style={{ margin: '3px 25px 0px 15px', height: '40px' }} />
                                            <Typography variant="h2">Account User - {viewInfo.status ? viewInfo.status.replace(/_/g, " ") : ''}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between">
                                            {isEdit && <LoadingButton
                                                className={classes.secondaryButton}
                                                color="secondary"
                                                variant="contained"
                                                onClick={(e) => handelEdit(false)}
                                            >
                                                Cancel
                                            </LoadingButton>}
                                            {isEdit ? <LoadingButton
                                                className={classes.primaryButton}
                                                color="primary"
                                                variant="contained"
                                                type='submit'
                                                disabled={isSubmitting}
                                            >
                                                Save
                                            </LoadingButton> :
                                                <LoadingButton
                                                    className={classes.primaryButton}
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={(e) => handelEdit(true)}
                                                >
                                                    Edit
                                                </LoadingButton>}
                                        </Box>
                                    </Box>
                                </Box>
                                {/* <Box py={3} px={3} style={{ background: '#f6f7f9' }}>
                                    <Grid container>
                                        <Grid item xs={9} style={{ display: 'flex' }}>
                                            <Grid container>
                                                <Grid item xs={1} style={{ display: 'flex' }}>
                                                    <ArrowBack style={{ fontSize: '30px', color: 'gray', cursor: 'pointer' }} onClick={handleClose} />
                                                    <Divider orientation="vertical" style={{ margin: '0px 40px 0px 10px', height: '80%' }} />
                                                </Grid>
                                                <Grid item xs={11}>
                                                    <Box pb={0.75}>
                                                        <Typography variant="h2">Account User - {viewInfo.status}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={3} style={{ textAlign: 'right' }}>
                                            <Box display="flex" justifyContent="space-between">
                                                {isEdit ? <LoadingButton
                                                    className={classes.secondaryButton}
                                                    color="secondary"
                                                    variant="contained"
                                                    onClick={(e) => handelEdit(false)}
                                                >
                                                    Cancel
                                                </LoadingButton> : <LoadingButton></LoadingButton>
                                                }
                                                {isEdit ? <LoadingButton
                                                    className={classes.primaryButton}
                                                    color="primary"
                                                    variant="contained"
                                                    type='submit'
                                                    disabled={isSubmitting}
                                                >
                                                    Save
                                                </LoadingButton> :
                                                    <LoadingButton id='edit' name='edit'
                                                        className={classes.primaryButton}
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={(e) => handelEdit(true)}
                                                    >
                                                        Edit
                                                    </LoadingButton>}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Divider /> */}

                                <Box px={3} py={2.5} >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Box pb={1.0}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        First Name*
                                                    </Typography>
                                                </Box>

                                                <Field disabled={isEdit ? false : true} style={{ background: isError && inputs === 0 ? '#fff1f1' : 'unset' }}
                                                    onInput={(e: any) => handleChange(e.target.value, 0)}
                                                    as={TextField}
                                                    inputProps={{ maxLength: 51 }}
                                                    className={classes.textField}
                                                    name="first_name"
                                                    size="small"
                                                    variant="outlined"
                                                />
                                                <Box display='flex' justifyContent='space-between'>
                                                    <Typography color={"error"} variant="subtitle2">
                                                        {errors.first_name && touched.first_name && errors.first_name}
                                                    </Typography>
                                                    <Typography style={{ color: values.first_name?.length > 50 ? '#911717' : '#0000008a' }} variant="subtitle2">
                                                        {values.first_name?.length === 51 ? 50 : values.first_name?.length}/50
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Box pb={1.0}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Last Name*
                                                    </Typography>
                                                </Box>

                                                <Field disabled={isEdit ? false : true} style={{ background: isError && inputs === 1 ? '#fff1f1' : 'unset' }}
                                                    onInput={(e: any) => handleChange(e.target.value, 1)}
                                                    as={TextField}
                                                    inputProps={{ maxLength: 51 }}
                                                    className={classes.textField}
                                                    name="last_name"
                                                    size="small"
                                                    variant="outlined"
                                                />
                                                <Box display='flex' justifyContent='space-between'>
                                                    <Typography color={"error"} variant="subtitle2">
                                                        {errors.last_name && touched.last_name && errors.last_name}
                                                    </Typography>
                                                    <Typography style={{ color: values.last_name?.length > 50 ? '#911717' : '#0000008a' }} variant="subtitle2">
                                                        {values.last_name?.length === 51 ? 50 : values.last_name?.length}/50
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Box pb={1.0}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Role*
                                                    </Typography>
                                                </Box>

                                                <Field disabled={true} as={Select} name="role_id" variant="outlined" className={classes.textField1}>
                                                    <MenuItem value={''}>Select</MenuItem>
                                                    {roleList && roleList.length > 0 && roleList.map((item: any) => {
                                                        return <MenuItem value={item.id}>{item.role}</MenuItem>
                                                    })}
                                                </Field>

                                                {errors.role_id && touched.role_id ? (
                                                    <Typography color="error" variant="subtitle2">
                                                        {errors.role_id}
                                                    </Typography>
                                                ) : null}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Box pb={1.0}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Phone Number
                                                    </Typography>
                                                </Box>

                                                <Field disabled={isEdit ? false : true}
                                                    as={TextField}
                                                    onInput={(e: any) => {
                                                        e.target.value = e.target.value.length === 0 ? '' : isnumber(e.target.value)
                                                    }}
                                                    className={classes.textField}
                                                    name="mobile_phone"
                                                    size="small"
                                                    type='tel'
                                                    variant="outlined"
                                                />

                                                {errors.mobile_phone && touched.mobile_phone ? (
                                                    <Typography color="error" variant="subtitle2">
                                                        {errors.mobile_phone}
                                                    </Typography>
                                                ) : null}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Box pb={1.0}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Email Address*
                                                    </Typography>
                                                </Box>

                                                <Field disabled={true} fullWidth
                                                    as={TextField}
                                                    className={classes.textField}
                                                    name="email"
                                                    size="small"
                                                    variant="outlined"
                                                />

                                                {errors.email && touched.email ? (
                                                    <Typography color="error" variant="subtitle2">
                                                        {errors.email}
                                                    </Typography>
                                                ) : null}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Box pb={1.0}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Date of Birth
                                                    </Typography>
                                                </Box>

                                                <Field disabled={isEdit ? false : true} fullWidth
                                                    as={TextField}
                                                    className={classes.textField}
                                                    name="birth_date"
                                                    size="small"
                                                    format="MM/dd/yyyy"
                                                    type='date'
                                                    InputProps={{ inputProps: { max: disDate } }}
                                                    variant="outlined"
                                                />

                                                {errors.birth_date && touched.birth_date ? (
                                                    <Typography color="error" variant="subtitle2">
                                                        {errors.birth_date}
                                                    </Typography>
                                                ) : null}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Box pb={1.0}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Address
                                                    </Typography>
                                                </Box>

                                                <Field disabled={isEdit ? false : true} style={{ background: isError && inputs === 3 ? '#fff1f1' : 'unset' }}
                                                    onInput={(e: any) => handleChange(e.target.value, 3)}
                                                    as={TextField}
                                                    inputProps={{ maxLength: 101 }}
                                                    className={classes.textField}
                                                    name="home_address"
                                                    size="small"
                                                    variant="outlined"
                                                />
                                                <Box display='flex' justifyContent='space-between'>
                                                    <Typography color={"error"} variant="subtitle2">
                                                        {errors.home_address && touched.home_address && errors.home_address}
                                                    </Typography>
                                                    <Typography style={{ color: values.home_address?.length > 100 ? '#911717' : '#0000008a' }} variant="subtitle2">
                                                        {values.home_address?.length === 101 ? 100 : values.home_address?.length}/100
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Box pb={1.0}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        City
                                                    </Typography>
                                                </Box>

                                                <Field disabled={isEdit ? false : true} style={{ background: isError && inputs === 2 ? '#fff1f1' : 'unset' }}
                                                    onInput={(e: any) => handleChange(e.target.value, 2)}
                                                    as={TextField}
                                                    inputProps={{ maxLength: 51 }}
                                                    className={classes.textField}
                                                    name="city"
                                                    size="small"
                                                    variant="outlined"
                                                />
                                                <Box display='flex' justifyContent='space-between'>
                                                    <Typography color={"error"} variant="subtitle2">
                                                        {''}
                                                    </Typography>
                                                    <Typography style={{ color: values.city?.length > 50 ? '#911717' : '#0000008a' }} variant="subtitle2">
                                                        {values.city?.length === 51 ? 50 : values.city?.length}/50
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Box pb={1.0}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        State
                                                    </Typography>
                                                </Box>

                                                <Field disabled={isEdit ? false : true} as={Select} name="state" variant="outlined" className={classes.textField1}>
                                                    <MenuItem value={''}>Select</MenuItem>
                                                    {stateList && stateList.length > 0 && stateList.map((item: any) => {
                                                        return <MenuItem value={item.state}>{item.state}</MenuItem> //changed id  to name
                                                    })}
                                                </Field>

                                                {errors.state && touched.state ? (
                                                    <Typography color="error" variant="subtitle2">
                                                        {errors.state}
                                                    </Typography>
                                                ) : null}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Box pb={1.0}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Zip Code
                                                    </Typography>
                                                </Box>

                                                <TextField disabled={isEdit ? false : true}
                                                    value={zip} onChange={(e: any) => handelChange(e.target.value)}
                                                    className={classes.textField}
                                                    name="zip"
                                                    size="small"
                                                    variant="outlined"
                                                // onInput={(e: any) => {
                                                //     e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 5)
                                                // }}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}></Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Box pb={1.0}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Status
                                                    </Typography>
                                                </Box>

                                                <Field disabled={true}
                                                    as={TextField}
                                                    className={classes.textField}
                                                    name="status"
                                                    size="small"
                                                    variant="outlined"
                                                />

                                                {errors.status && touched.status ? (
                                                    <Typography color="error" variant="subtitle2">
                                                        {errors.status}
                                                    </Typography>
                                                ) : null}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box pb={1.0}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Department
                                                    </Typography>
                                                </Box>

                                                <Field disabled={isEdit ? false : true} as={Select} name="department_id" variant="outlined" className={classes.textField1}>
                                                    <MenuItem value={''}>Select</MenuItem>
                                                    {departmentList && departmentList.length > 0 && departmentList.map((item: any) => {
                                                        return <MenuItem value={item.id}>{item.title}</MenuItem>
                                                    })}
                                                </Field>

                                                {errors.department_id && touched.department_id ? (
                                                    <Typography color="error" variant="subtitle2">
                                                        {errors.department_id}
                                                    </Typography>
                                                ) : null}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box pb={1.0}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Unit
                                                    </Typography>
                                                </Box>

                                                <FormControl fullWidth size="small">
                                                    <Autocomplete fullWidth disabled={isEdit ? false : true}
                                                        value={unit}
                                                        onChange={(event, newValue) => {
                                                            if (typeof newValue === 'string') {
                                                                // timeout to avoid instant validation of the dialog's form.
                                                                setTimeout(() => {
                                                                    toggleOpen(true);
                                                                    setDialogValue({
                                                                        name: newValue,
                                                                        id: '',
                                                                    });
                                                                });
                                                            } else if (newValue && newValue.id === 'true') {
                                                                toggleOpen(true);
                                                                setDialogValue({
                                                                    name: newValue.inputValue,
                                                                    id: '',
                                                                });
                                                            } else {
                                                                handleSetUnit(newValue);
                                                            }
                                                        }}
                                                        filterOptions={(options, params: any) => {
                                                            const filtered = filter(options, params) as FilmOptionType[];

                                                            if (params.inputValue !== '') {
                                                                filtered.push({
                                                                    id: 'true',
                                                                    name: `Add "${params.inputValue}"`,
                                                                });
                                                                setUnitValue(params.inputValue)
                                                            }

                                                            return filtered;
                                                        }}
                                                        id="free-solo-dialog-demo"
                                                        options={unitList}
                                                        getOptionLabel={(option) => {
                                                            // e.g value selected with enter, right from the input
                                                            if (typeof option === 'string') {
                                                                return option;
                                                            }
                                                            if (option.name) {
                                                                return option.name;
                                                            }
                                                            return option.name;
                                                        }}
                                                        selectOnFocus
                                                        clearOnBlur
                                                        handleHomeEndKeys
                                                        renderOption={(option) => option.name}
                                                        freeSolo
                                                        renderInput={(params) => (
                                                            <TextField {...params} variant="outlined" size="small" fullWidth />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box pb={2}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Notes
                                                    </Typography>
                                                </Box>
                                                <Field disabled={isEdit ? false : true} fullWidth multiline rows={4}
                                                    style={{ background: isError && inputs === 4 ? '#fff1f1' : 'unset' }}
                                                    onInput={(e: any) => handleChange(e.target.value, 4)}
                                                    as={TextField}
                                                    inputProps={{ maxLength: 251 }}
                                                    className={classes.textField}
                                                    name="notes"
                                                    size="small"
                                                    variant="outlined"
                                                />
                                                <Box display='flex' justifyContent='space-between'>
                                                    <Typography color={"error"} variant="subtitle2">
                                                        {''}
                                                    </Typography>
                                                    <Typography style={{ color: values.notes?.length > 250 ? '#911717' : '#0000008a' }} variant="subtitle2">
                                                        {values.notes?.length === 251 ? 250 : values.notes?.length}/250
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            {isEdit ? <LoadingButton
                                                className={classes.deleteButton}
                                                color="secondary"
                                                variant="contained"
                                                onClick={(e) => handleDeleteUser()}
                                            >
                                                Delete
                                            </LoadingButton> :
                                                ((viewInfo.status === "INVITE_NOT_SENT" || viewInfo.status === "INVITE_EXPIRED" || viewInfo.status === "INVITE_CANCELLED" || viewInfo.status === "DISABLED") &&
                                                    < LoadingButton style={{ marginBottom: '20px', marginRight: '26px' }}
                                                        className={classes.primaryButton}
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={() => sendInvite()}
                                                    >
                                                        Invite
                                                    </LoadingButton>
                                                )}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Form>
                        );
                    }}
                </Formik>
            </Box>
            {
                <MUIDialog open={openToggle}>
                    <DialogTitle id="form-dialog-title">Add a new unit</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Did you miss any unit in unit list? Please, add it!
                        </DialogContentText>
                        <Box pb={2.5}>
                            <Box pb={0.75}>
                                <Typography color="textSecondary" variant="subtitle2">
                                    Unit
                                </Typography>
                            </Box>
                            <TextField fullWidth variant='outlined' size='small'
                                autoFocus
                                value={unitValue}
                                onChange={(event) => setUnitValue(event.target.value)}
                                type="text"
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'space-around' }}>
                        <LoadingButton
                            className={classes.secondaryButton}
                            variant="contained"
                            style={{ marginBottom: '0px' }}
                            onClick={handelCloseDialog}
                        >
                            Cancel
                        </LoadingButton>
                        <LoadingButton color="primary"
                            className={classes.primaryButton}
                            variant="contained"
                            style={{ marginBottom: '0px' }}
                            onClick={(e) => addNewUnit()}
                        >
                            Add
                        </LoadingButton>
                    </DialogActions>
                </MUIDialog>
            }
            {
                isOpen &&
                <Dialog handleClose={handleCloseDialog} open={isOpen}>
                    <Box style={{ width: '400px' }}>
                        <Box pb={3} pt={2} px={4} display="flex" justifyContent="space-between">
                            <Typography color="textSecondary" variant="h2">Invitation Success</Typography>
                            <Close onClick={() => setIsOpen(false)} />
                        </Box>
                        <Box pb={6} pt={2} display="flex" justifyContent="center">
                            <Typography color="textSecondary" variant="h2">Invitation Sent</Typography>
                        </Box>
                    </Box>
                </Dialog>
            }
        </>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    topBox: {
        backgroundColor: theme.palette.secondary.light,
        borderBottom: '0.0625rem solid #D1D6DC',
        borderRadius: '0.625rem 0.625rem 0 0',
        paddingLeft: '32px',
        paddingRight: '32px',
        marginBottom: '30px',
        paddingTop: '22px',
        width: '-webkit-fill-available'
    },
    bottomBox: {
        borderRadius: '0 0 0.625rem 0.625rem'
    },
    textField: {
        width: '100%'
    },
    formCont: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%'
    },
    primaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#fff',
        minWidth: '120px'
    },
    secondaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        height: '2rem',
        color: 'blue',
        minWidth: '120px',
        marginRight: '20px'
    },
    deleteButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        height: '2rem',
        color: 'red',
        minWidth: '120px'
    },
    createCollectionTextField: {
        maxWidth: '40rem',
        width: '80vw'
    },
    textField1: {
        width: '100%',
        height: '35px',
        padding: '0px'
    }
}));
