import { Box, makeStyles, Modal, Grid, Typography, TextField, Paper } from '@material-ui/core';
import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '../../../components/LoadingButton';
import { useLocation } from 'react-router-dom';
import { saveCalculationThunk, editCalculationThunk } from '../../../redux/thunk/thunks';
import { RootState } from '../../../redux/redux-store';

export const AddCalculation: any = ({ formId, handleClose, editInfo }: any) => {
    const location: any = useLocation();
    const isEdit = location.state;
    const [deleteDialog, setDeleteDialog] = useState(false);
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isError, setError] = useState(false);
    const [input, setInput] = useState(null);

    const [initialValues, setInitialValues] = useState<any>({
        metric_name: editInfo ? editInfo.code : '',
        description: editInfo ? editInfo.description : '',
    })

    const createMetricError = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displayErrors.createMetricError);

    const calculationSchema = Yup.object().shape({
        metric_name: Yup.string().required('Required').min(1, "Too Short!")
            .max(50, ''),
        description: Yup.string().max(2000, '')
    });

    const handleSubmit = async (values: any, actions: any) => {
        const data = {
            "metric_name": values.metric_name,
            "description": values.description,
            "form_id": formId
        }
        editInfo ? await dispatch(editCalculationThunk(editInfo?.id, data)) : dispatch(saveCalculationThunk(data));
        actions.setSubmitting(false);
    }

    const handleChange = (value: any, no: any) => {
        if (no === 0 && value.length > 50) {
            setError(true); setInput(no);
        } else if (no === 1 && value.length > 2000) {
            setError(true); setInput(no);
        } else {
            setError(false); setInput(no);
        }
    }

    return (
        <Modal
            open={true}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Box alignItems="center" className={classes.main} display="flex" justifyContent="center">
                <Paper className={(classes.dialogPaper)}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validateOnBlur={false}
                        validateOnChange={false}
                        validationSchema={calculationSchema}
                    >
                        {({ errors, isSubmitting, setSubmitting, touched, values }) => {
                            return (
                                <Form style={{ width: '500px' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography color="textSecondary" variant="h2">{editInfo ? 'Edit' : 'New'} Calculation</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box pb={1.0}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Metric Name*
                                                    </Typography>
                                                </Box>

                                                <Field style={{ background: isError && input === 0 ? '#fff1f1' : 'unset' }}
                                                    onInput={(e: any) => handleChange(e.target.value, 0)}
                                                    as={TextField}
                                                    inputProps={{ maxLength: 51 }}
                                                    className={classes.textField}
                                                    name="metric_name"
                                                    size="small"
                                                    variant="outlined"
                                                />
                                                <Box display='flex' justifyContent='space-between'>
                                                    <Typography color={"error"} variant="subtitle2">
                                                        {errors.metric_name && touched.metric_name && errors.metric_name}
                                                    </Typography>
                                                    <Typography style={{ color: values.metric_name?.length > 50 ? '#911717' : '#0000008a' }} variant="subtitle2">
                                                        {values.metric_name?.length === 51 ? 50 : values.metric_name?.length}/50
                                                    </Typography>
                                                </Box>
                                                {createMetricError && <Typography color="error" variant="subtitle2">
                                                    Cannot update! This metric name is already in use!
                                                </Typography>}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box pb={1.0}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Description
                                                    </Typography>
                                                </Box>

                                                <Field multiline rows={4} style={{ background: isError && input === 1 ? '#fff1f1' : 'unset' }}
                                                    onInput={(e: any) => handleChange(e.target.value, 1)}
                                                    as={TextField}
                                                    inputProps={{ maxLength: 2001 }}
                                                    className={classes.textField}
                                                    name="description"
                                                    size="small"
                                                    variant="outlined"
                                                />

                                                <Box display='flex' justifyContent='space-between'>
                                                    <Typography color="error" variant="subtitle2">
                                                        {errors.description && touched.description && errors.description}
                                                    </Typography>
                                                    <Typography style={{ color: values.description?.length > 2000 ? '#911717' : '#0000008a' }} variant="subtitle2">
                                                        {values.description?.length === 2001 ? 2000 : values.description?.length}/2000
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box display="flex" justifyContent="space-between">
                                                <LoadingButton
                                                    className={classes.secondaryButton}
                                                    onClick={handleClose}
                                                    variant="contained"
                                                >
                                                    Cancel
                                                </LoadingButton>
                                                <LoadingButton
                                                    className={classes.primaryButton}
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    loading={isSubmitting}
                                                    type="submit"
                                                    variant="contained"
                                                    aria-label="sign in"
                                                >
                                                    Save
                                                </LoadingButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                </Paper>
            </Box>
        </Modal>
    );
};

const useStyles = makeStyles((theme) => ({
    main: {
        minHeight: '100vh',
    },
    primaryButton: {
        boxShadow:
            '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)'
    },
    secondaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)'
    },
    textField: {
        width: '100%'
    },
    textField1: {
        width: '100%',
        height: '35px',
        padding: '0px'
    },
    dialogPaper: {
        borderRadius: '0.625rem',
        padding: '20px'
    }
}));
