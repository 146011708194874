import { Radio, RadioGroup, RadioProps, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface ScaleFieldBlockProps {
  fieldTitle: any;
  scaleMax: number;
}

export const ScaleFieldBlock: React.FC<ScaleFieldBlockProps> = ({ fieldTitle, scaleMax }) => {
  const renderScale = (scaleMax: number) => {
    let scale = [];
    for (let i = 0; i < scaleMax; i++) {
      scale.push(<StyledRadio key={i} value={i} color={'primary'} />);
    }
    return scale;
  };
  return (
    <>
      {fieldTitle && fieldTitle.map((queName: any) => {
        return <Typography variant="subtitle2"><b>{queName ? queName : ''}</b></Typography>
      })}
      <RadioGroup row>{renderScale(scaleMax + 1)}</RadioGroup>
    </>
  );
};
const StyledRadio = (props: RadioProps) => {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={classes.icon} />}
      icon={<div className={classes.icon}>{props.value as number}</div>}
      {...props}
    />
  );
};
const useStyles = makeStyles((theme) => ({
  selectRoot: {
    padding: '.6rem'
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  icon: {
    borderRadius: '50%',
    width: '3rem',
    height: '3rem',
    fontSize: '1.2rem',
    color: theme.palette.text.hint,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)'
    }
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3'
    }
  }
}));
