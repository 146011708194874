import { Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '../../../../components/LoadingButton';
import { TitledTextInput } from '../../../../components/TitledTextInput';
import { setEditingFormAction } from '../../../../redux/data/data-actions';
import { RootState } from '../../../../redux/redux-store';
import { formUpdateThunk } from '../../../../redux/thunk/form-thunks';

export const EntryPageSettings: React.FC<{}> = ({ }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const editingForm: any = useSelector((state: RootState) => state.DATA_REDUCER.editingForm);
  const updateFormLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.updateFormLoading
  );

  const saveChanges = async () => {
    if (editingForm) {
      const domains = [];
      const tags = [];
      const collections = [];
      for (let i = 0; i < editingForm.domains.length; i++) {
        domains.push(editingForm.domains[i].id);
      }
      for (let i = 0; i < editingForm.tags.length; i++) {
        tags.push(editingForm.tags[i].id);
      }
      for (let i = 0; i < editingForm.collections.length; i++) {
        collections.push(editingForm.collections[i].id);
      }
      editingForm.domains = domains;
      editingForm.tags = tags;
      editingForm.collections = collections;
      await dispatch(formUpdateThunk(editingForm));
    }
  };
  const handleHeaderChange = (event: React.ChangeEvent<{ value: string }>) => {
    if (editingForm) {
      const headerValue = event.currentTarget.value;
      dispatch(setEditingFormAction({ ...editingForm, entry_page_header: headerValue }));
      sessionStorage.setItem('formTab', 'entry');
      sessionStorage.setItem('onChange', 'true');
    }
  };
  const handleBodyChange = (event: React.ChangeEvent<{ value: string }>) => {
    if (editingForm) {
      const bodyValue = event.currentTarget.value;
      dispatch(setEditingFormAction({ ...editingForm, entry_page_body: bodyValue }));
      sessionStorage.setItem('formTab', 'entry');
      sessionStorage.setItem('onChange', 'true');
    }
  };
  const handleVideoUrlChange = (event: React.ChangeEvent<{ value: string }>) => {
    if (editingForm) {
      const urlValue = event.currentTarget.value;
      dispatch(setEditingFormAction({ ...editingForm, entry_page_video_url: urlValue }));
      sessionStorage.setItem('formTab', 'entry');
      sessionStorage.setItem('onChange', 'true');
    }
  };

  return (
    <Box p={3}>
      <TitledTextInput
        noValidation
        variant="outlined"
        size={'small'}
        title={'Header'}
        value={editingForm?.entry_page_header || ''}
        fullWidth
        onChange={handleHeaderChange}
      />
      <Divider className={classes.divider} />
      <TitledTextInput
        noValidation
        variant="outlined"
        size={'small'}
        multiline
        rows={6}
        fullWidth
        title={'Body'}
        onChange={handleBodyChange}
        value={editingForm?.entry_page_body || ''}
      />
      <Divider className={classes.divider} />
      <TitledTextInput
        noValidation
        variant="outlined"
        size={'small'}
        fullWidth
        title={'Video URL'}
        onChange={handleVideoUrlChange}
        value={editingForm?.entry_page_video_url || ''}
      />
      {/* <Divider className={classes.divider} /> */}
      {/* <LoadingButton
        onClick={saveChanges}
        variant="contained"
        fullWidth
        disabled={updateFormLoading}
        loading={updateFormLoading}
        color={'primary'}
        aria-label={'save changes'}
      >
        Save Changes
      </LoadingButton> */}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  errorContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '.5rem'
  },
  hintText: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.hint
  },
  fieldTitle: {
    marginBottom: theme.spacing(1)
  }
}));
