import * as Yup from 'yup';
import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Theme,
  Typography,
  makeStyles,
  Select,
  MenuItem,
  Grid,
  DialogContent,
  TextField,
  DialogActions,
  DialogContentText,
  DialogTitle,
  FormControl
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import MUIDialog from '@material-ui/core/Dialog'
import { Dialog } from '../../../../components/Dialog';
import { LoadingButton } from '../../../../components/LoadingButton';
import { ROLES } from '../../../../shared/globals';
import { RootState } from '../../../../redux/redux-store';
import { saveAccountInfoThunk, getUnitThunk, getStateThunk, getDepartmentThunk, getAccountsThunk, getAccountRolesThunk, createUnitThunk, getSiteAdminAccountsThunk, getReachCareOrgIDThunk } from '../../../../redux/thunk/thunks';
import { setSuccessAction } from '../../../../redux/display/display-actions';

interface InviteUserDialogProps {
  open: boolean;
  handleClose: () => void;
}

interface FilmOptionType {
  name: string;
  id: string;
}

const filter = createFilterOptions<FilmOptionType>();

export const InviteUserDialog = ({ open, handleClose }: InviteUserDialogProps) => {
  const classes = useStyles();
  const ref: any = useRef(null);
  const dispatch = useDispatch();
  const [zip, setZip] = useState<any>();
  const [isSet, setIsSet] = useState(false);
  const [selectedRole, setSelectedRole] = useState<any>(null);
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);
  const [unit, setUnit] = useState<any>('');
  const [disDate, setDisabledDate] = useState<any>();
  const [unitId, setUnitId] = useState<any>('');
  const [unitValue, setUnitValue] = useState('');
  const [isAdd, setIsAdd] = useState(false);
  const [openToggle, toggleOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [inputs, setInputs] = useState(null);
  const [isFieldShow, setIsFieldShow] = useState(false);
  const [dialogValue, setDialogValue] = useState({
    name: '',
    id: '',
  });

  const newlyAddedUnit: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.unit
  );
  useEffect(() => {
    if (newlyAddedUnit) {
      const isCheckedTag = unitList.filter((item: any) => item === newlyAddedUnit.id);
      if (isCheckedTag && isCheckedTag.length === 0) {
        unitList.push(newlyAddedUnit.id);
        setIsAdd(isAdd ? false : true);
        setUnit(newlyAddedUnit.name);
        setUnitId(newlyAddedUnit.id);
      }
    }
  })

  const handleSetUnit = (e: any) => {
    // const value = tagList.filter((item: any) => item.title === e)
    if (e && e.name) {
      setUnit(e.name)
      setUnitId(e.id)
    } else {
      setUnit('')
    }
  }
  const addNewUnit = async () => {
    await dispatch(createUnitThunk(unitValue))
    handelCloseDialog();
    setUnitValue('');
  }

  const handelCloseDialog = () => {
    toggleOpen(false);
  }

  const [initialValues, setInitialValues] = useState<any>({
    first_name: '',
    last_name: '',
    email: '',
    role: selectedRole ? selectedRole.role : '',
    birth_date: '',
    mobile_phone: '',
    home_address: '',
    zip: '',
    city: '',
    state: '',
    department_id: null,
    role_id: selectedRole ? selectedRole.id : null
  });

  const roles: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.roleList
  );
  const reachCareOrgId = useSelector(
    (state: RootState) => state.DATA_REDUCER.reachCareOrgId
  );

  useEffect(() => {
    let newDate: any = Date.now();
    setDisabledDate(new Date(newDate).getFullYear() + '-' + (("0" + (new Date(newDate).getMonth() + 1)).slice(-2)) + '-' + ("0" + (new Date(newDate).getDate() - 1)).slice(-2));
  }, []);

  useEffect(() => {
    dispatch(getReachCareOrgIDThunk());
  }, []);

  useEffect(() => {
    if (reachCareOrgId) {
      if (loggedInUser?.organization_id === reachCareOrgId) { setIsFieldShow(true); }
    }
  }, [reachCareOrgId]);

  useEffect(() => {
    if (roles && roles.length > 0 && selectedRole === null) {
      const role = roles.filter((e: any) => e.role === 'ADMIN')
      role.length > 0 && setSelectedRole(role[0]);
      setInitialValues({
        role_id: role[0].id,
        role: role[0].role,
        first_name: '',
        last_name: '',
        email: '',
        birth_date: '',
        mobile_phone: '',
        home_address: '',
        zip: '',
        city: '',
        state: '',
        department_id: null,
      });
      setIsSet(isSet ? false : true)
    }
  })

  const departmentList: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.departmentList
  );
  useEffect(() => {
    dispatch(getDepartmentThunk())
    dispatch(getAccountRolesThunk())
    dispatch(getStateThunk())
    dispatch(getUnitThunk())
  }, [])
  const stateList: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.stateList
  );
  const unitList: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.unitList
  );

  const success: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.createCollectionSuccess)
  useEffect(() => {
    if (success) {
      handleClose();
      if (loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role)) {
        dispatch(getSiteAdminAccountsThunk({ order: 'ASC', sortBy: "first_name", page: 0, pageSize: 10, search: '' }));
      } else {
        dispatch(getAccountsThunk({ id: loggedInUser ? loggedInUser.id : '', page: 0, pageSize: 10, search: '', nameSequence: 'ASC', lastLoginSequence: 'DESC' }));
      }
    }
  })

  const inviteUserError = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayErrors.inviteUserError
  );
  const inviteUserSuccess = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.inviteUserSuccess
  );

  const handleInviteUser = async (values: any) => {
    const role = roles.filter((e: any) => e.id === values.role_id);
    const data = values;
    data.zip = zip ? zip : '';
    data.unit_id = unitId !== '' ? unitId : null;
    data.role = role[0].role;
    await dispatch(saveAccountInfoThunk(data));
  };

  const inviteUserSchema = Yup.object().shape({
    first_name: Yup.string().required('Required').min(1, "Too Short!")
      .max(50, ''),
    last_name: Yup.string().required('Required').min(1, "Too Short!")
      .max(50, ''),
    mobile_phone: Yup.string().min(10, "Too Short!")
      .max(12, ''),
    city: Yup.string().min(1, "Too Short!")
      .max(50, ''),
    email: Yup.string().email('Email is invalid').required('Required'),
    role_id: Yup.string().required('Required'),
    home_address: Yup.string().min(10, "Too Short!").max(100, ''),
    birth_date: isFieldShow ? Yup.string().required("Required") : Yup.string(),
  });

  const isnumber = (value: any) => {
    const test = parseInt(value);
    if (test) {
      return Math.max(0, parseInt(value)).toString().slice(0, 12)
    } else {
      return null
    }
  }

  useEffect(() => {
    if (inviteUserSuccess) {
      dispatch(setSuccessAction('inviteUserSuccess', false));
      handleClose();
    }
  }, [inviteUserSuccess]);

  const handelChange = (value: any) => {
    const re = /^[0-9\b]+$/;
    if (re.test(value) && value.length < 6) {
      setZip(value)
    } else {
      value.length > 6 || value.length == 0 && setZip('')
    }
  }

  const handleChange = (value: any, no: any) => {
    if (no === 0 && value.length > 50) {
      setIsError(true); setInputs(no);
    } else if (no === 1 && value.length > 50) {
      setIsError(true); setInputs(no);
    } else if (no === 2 && value.length > 50) {
      setIsError(true); setInputs(no);
    } else if (no === 3 && value.length > 100) {
      setIsError(true); setInputs(no);
    } else {
      setIsError(false); setInputs(no);
    }
  }

  return (
    <>
      <Dialog handleClose={handleClose} open={open}>
        <Box display="flex" flexDirection="column">
          <Box alignItems="center" display="flex" justifyContent="space-between" p={3} style={{ background: '#f9fafb', borderRadius: '10px 10px 0px 0px' }}>
            <Typography variant="h2">Add New User</Typography>
          </Box>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={handleInviteUser}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={inviteUserSchema}
          >
            {({ errors, isSubmitting, setSubmitting, touched, values }) => {
              if (isSubmitting && inviteUserError) {
                setSubmitting(false);
              }

              return (
                <Form>
                  <Box pt={3} px={3} style={{ width: '500px', overflow: 'auto', height: '500px', overflowX: 'hidden' }}>
                    <Grid container spacing={2}>
                      {loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role) ? <Grid item xs={12}>
                        <Box pb={1.0}>
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              Role*
                            </Typography>
                          </Box>

                          <Field as={Select} name="role_id" variant="outlined" className={classes.textField1}>
                            {roles && roles.map((role: any) => {
                              return (role.role === ROLES.SITE_ADMIN || role.role === ROLES.CLINICIAN || role.role === ROLES.BUILDER) && <MenuItem value={role.id}>{role?.role?.replace(/_/g, " ")}</MenuItem>
                            })}
                          </Field>

                          {errors.role_id && touched.role_id ? (
                            <Typography color="error" variant="subtitle2">
                              {errors.role_id}
                            </Typography>
                          ) : null}
                        </Box>
                      </Grid> :
                        <Grid item xs={12}>
                          <Box pb={1.0}>
                            <Box pb={0.75}>
                              <Typography color="textSecondary" variant="subtitle2">
                                Role*
                              </Typography>
                            </Box>

                            <Field as={Select} name="role_id" variant="outlined" className={classes.textField1} disabled>
                              {roles && roles.map((role: any) => {
                                return <MenuItem value={role.id}>{role.role}</MenuItem>
                              })}
                            </Field>

                            {errors.role_id && touched.role_id ? (
                              <Typography color="error" variant="subtitle2">
                                {errors.role_id}
                              </Typography>
                            ) : null}
                          </Box>
                        </Grid>
                      }
                      <Grid item xs={6}>
                        <Box pb={1.0}>
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              First Name*
                            </Typography>
                          </Box>

                          <Field style={{ background: isError && inputs === 0 ? '#fff1f1' : 'unset' }}
                            onInput={(e: any) => handleChange(e.target.value, 0)}
                            as={TextField}
                            inputProps={{ maxLength: 51 }}
                            className={classes.textField}
                            name="first_name"
                            size="small"
                            variant="outlined"
                          />
                          <Box display='flex' justifyContent='space-between'>
                            <Typography color={"error"} variant="subtitle2">
                              {errors.first_name && touched.first_name && errors.first_name}
                            </Typography>
                            <Typography style={{ color: values.first_name?.length > 50 ? '#911717' : '#0000008a' }} variant="subtitle2">
                              {values.first_name?.length === 51 ? 50 : values.first_name?.length}/50
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box pb={1.0}>
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              Last Name*
                            </Typography>
                          </Box>

                          <Field style={{ background: isError && inputs === 1 ? '#fff1f1' : 'unset' }}
                            onInput={(e: any) => handleChange(e.target.value, 1)}
                            as={TextField}
                            inputProps={{ maxLength: 51 }}
                            className={classes.textField}
                            name="last_name"
                            size="small"
                            variant="outlined"
                          />
                          <Box display='flex' justifyContent='space-between'>
                            <Typography color={"error"} variant="subtitle2">
                              {errors.last_name && touched.last_name && errors.last_name}
                            </Typography>
                            <Typography style={{ color: values.last_name?.length > 50 ? '#911717' : '#0000008a' }} variant="subtitle2">
                              {values.last_name?.length === 51 ? 50 : values.last_name?.length}/50
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box pb={1.0}>
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              Email Address*
                            </Typography>
                          </Box>

                          <Field fullWidth
                            as={TextField}
                            className={classes.textField}
                            name="email"
                            size="small"
                            variant="outlined"
                          />

                          {errors.email && touched.email ? (
                            <Typography color="error" variant="subtitle2">
                              {errors.email}
                            </Typography>
                          ) : null}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box pb={1.0}>
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              Department
                            </Typography>
                          </Box>

                          <Field as={Select} name="department_id" variant="outlined" className={classes.textField1}>
                            <MenuItem value={''}>Select</MenuItem>
                            {departmentList && departmentList.length > 0 && departmentList.map((item: any) => {
                              return <MenuItem value={item.id}>{item.title}</MenuItem>
                            })}
                          </Field>

                          {errors.department_id && touched.department_id ? (
                            <Typography color="error" variant="subtitle2">
                              {errors.department_id}
                            </Typography>
                          ) : null}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box pb={1.0}>
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              Unit
                            </Typography>
                          </Box>

                          <FormControl fullWidth size="small">
                            <Autocomplete fullWidth
                              value={unit}
                              onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                  // timeout to avoid instant validation of the dialog's form.
                                  setTimeout(() => {
                                    toggleOpen(true);
                                    setDialogValue({
                                      name: newValue,
                                      id: '',
                                    });
                                  });
                                } else if (newValue && newValue.id === 'true') {
                                  toggleOpen(true);
                                  setDialogValue({
                                    name: newValue.inputValue,
                                    id: '',
                                  });
                                } else {
                                  handleSetUnit(newValue);
                                }
                              }}
                              filterOptions={(options, params: any) => {
                                const filtered = filter(options, params) as FilmOptionType[];

                                if (params.inputValue !== '') {
                                  filtered.push({
                                    id: 'true',
                                    name: `Add "${params.inputValue}"`,
                                  });
                                  setUnitValue(params.inputValue)
                                }

                                return filtered;
                              }}
                              id="free-solo-dialog-demo"
                              options={unitList}
                              getOptionLabel={(option) => {
                                // e.g value selected with enter, right from the input
                                if (typeof option === 'string') {
                                  return option;
                                }
                                if (option.name) {
                                  return option.name;
                                }
                                return option.name;
                              }}
                              selectOnFocus
                              clearOnBlur
                              handleHomeEndKeys
                              renderOption={(option) => option.name}
                              freeSolo
                              renderInput={(params) => (
                                <TextField {...params} variant="outlined" size="small" fullWidth />
                              )}
                            />
                          </FormControl>

                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box pb={1.0}>
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              Phone Number
                            </Typography>
                          </Box>

                          <Field
                            as={TextField}
                            onInput={(e: any) => {
                              e.target.value = e.target.value.length === 0 ? '' : isnumber(e.target.value)
                            }}
                            className={classes.textField}
                            name="mobile_phone"
                            size="small"
                            type="tel"
                            variant="outlined"
                          />

                          {errors.mobile_phone && touched.mobile_phone ? (
                            <Typography color="error" variant="subtitle2">
                              {errors.mobile_phone}
                            </Typography>
                          ) : null}
                        </Box>
                      </Grid>
                      {isFieldShow &&
                        <Grid item xs={12}>
                          <Box pb={1}>
                            <Box flexGrow="1">
                              <Box pb={0.75}>
                                <Typography color="textSecondary" variant="subtitle2">
                                  Date of Birth
                                </Typography>
                              </Box>

                              <Field
                                as={TextField}
                                name="birth_date"
                                size="small"
                                type="date"
                                format="MM/dd/yyyy"
                                variant="outlined"
                                style={{ width: '100%' }}
                                InputProps={{ inputProps: { max: disDate } }}
                              />
                              {errors.birth_date && touched.birth_date ? (
                                <Typography color="error" variant="subtitle2">
                                  {errors.birth_date}
                                </Typography>
                              ) : null}
                            </Box>
                          </Box>
                        </Grid>
                      }
                      <Grid item xs={12}>
                        <Box pb={1.0}>
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              Address
                            </Typography>
                          </Box>

                          <Field style={{ background: isError && inputs === 3 ? '#fff1f1' : 'unset' }}
                            onInput={(e: any) => handleChange(e.target.value, 3)}
                            as={TextField}
                            inputProps={{ maxLength: 101 }}
                            className={classes.textField}
                            name="home_address"
                            size="small"
                            variant="outlined"
                          />
                          <Box display='flex' justifyContent='space-between'>
                            <Typography color={"error"} variant="subtitle2">
                              {errors.home_address && touched.home_address && errors.home_address}
                            </Typography>
                            <Typography style={{ color: values.home_address?.length > 100 ? '#911717' : '#0000008a' }} variant="subtitle2">
                              {values.home_address?.length === 101 ? 100 : values.home_address?.length}/100
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box pb={1.0}>
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              City
                            </Typography>
                          </Box>

                          <Field style={{ background: isError && inputs === 2 ? '#fff1f1' : 'unset' }}
                            onInput={(e: any) => handleChange(e.target.value, 2)}
                            as={TextField}
                            inputProps={{ maxLength: 51 }}
                            className={classes.textField}
                            name="city"
                            size="small"
                            variant="outlined"
                          />
                          <Box display='flex' justifyContent='space-between'>
                            <Typography color={"error"} variant="subtitle2">
                              {''}
                            </Typography>
                            <Typography style={{ color: values.city?.length > 50 ? '#911717' : '#0000008a' }} variant="subtitle2">
                              {values.city?.length === 51 ? 50 : values.city?.length}/50
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box pb={1.0}>
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              State
                            </Typography>
                          </Box>

                          <Field as={Select} name="state" variant="outlined" className={classes.textField1}>
                            <MenuItem value={''}>Select</MenuItem>
                            {stateList && stateList.length > 0 && stateList.map((item: any) => {
                              return <MenuItem value={item.state}>{item.state}</MenuItem>
                            })}
                          </Field>

                          {errors.state && touched.state ? (
                            <Typography color="error" variant="subtitle2">
                              {errors.state}
                            </Typography>
                          ) : null}
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box pb={1.0}>
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              Zip Code
                            </Typography>
                          </Box>

                          <Field className={classes.textField1}
                            as={TextField}
                            value={zip} onChange={(e: any) => handelChange(e.target.value)}
                            name="zip"
                            size="small"
                            variant="outlined"
                            style={{ width: '100%' }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <DialogActions style={{ display: "flex", justifyContent: "space-between", background: '#f9fafb', borderRadius: '0px 0px 10px 10px', padding: '20px' }}>
                    <LoadingButton
                      className={classes.secondaryButton}
                      onClick={handleClose}
                      variant="contained"
                    >
                      Cancel
                    </LoadingButton>
                    <LoadingButton
                      className={classes.primaryButton}
                      color="primary"
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      type="submit"
                      variant="contained"
                      aria-label="sign in"
                    >
                      Save
                    </LoadingButton>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Dialog>
      {
        <MUIDialog open={openToggle}>
          <DialogTitle id="form-dialog-title">Add a new unit</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Did you miss any unit in unit list? Please, add it!
            </DialogContentText>
            <Box pb={2.5}>
              <Box pb={0.75}>
                <Typography color="textSecondary" variant="subtitle2">
                  Unit
                </Typography>
              </Box>
              <TextField fullWidth variant='outlined' size='small'
                autoFocus
                value={unitValue}
                onChange={(event) => setUnitValue(event.target.value)}
                type="text"
              />
            </Box>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-around' }}>
            <LoadingButton
              className={classes.secondaryButton}
              variant="contained"
              style={{ marginBottom: '0px' }}
              onClick={handelCloseDialog}
            >
              Cancel
            </LoadingButton>
            <LoadingButton color="primary"
              className={classes.primaryButton}
              variant="contained"
              style={{ marginBottom: '0px' }}
              onClick={(e) => addNewUnit()}
            >
              Add
            </LoadingButton>
          </DialogActions>
        </MUIDialog>
      }
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  topBox: {
    borderRadius: '0.625rem 0.625rem 0 0'
  },
  bottomBox: {
    borderRadius: '0 0 0.625rem 0.625rem'
  },
  primaryButton: {
    boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)',
    width: '120px'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    width: '120px'
  },
  textField: {
    width: '100%'
  },
  textField1: {
    width: '100%',
    height: '35px',
    padding: '0px'
  }
}));
