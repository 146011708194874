import { Button, debounce, makeStyles, Theme } from '@material-ui/core';
import React, { ChangeEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from '../../../components/LegacyDialog';
import { HeadCell, Pagination, RowCell, Table } from '../../../components/Table';
import { getTagsAction } from '../../../redux/data/data-actions';
import { RootState } from '../../../redux/redux-store';
import { searchTagsThunk } from '../../../redux/thunk/thunks';
import { Tag, TagsSearchResults } from '../../../shared/domain';
import { TAG_TYPES } from '../../../shared/globals';

export type selectedTags = {
  primaryTag?: Tag;
  secondaryTag?: Tag;
};

interface SearchTagsDialogProps {
  open: boolean;
  handleClose: () => void;
  tagSearchType: TAG_TYPES;
  handleTagSelect: (tagType: TAG_TYPES, tag: Tag) => void;
  selectedTags: selectedTags;
}

export const SearchTagsDialog = ({
  open,
  handleClose,
  tagSearchType,
  selectedTags,
  handleTagSelect
}: SearchTagsDialogProps) => {
  const dispatch = useDispatch();

  const tagsSearchResults = useSelector((state: RootState) => state.DATA_REDUCER.tagsSearchResults);
  // if searching for primary tag omit the parent tag Id Field
  const parentTagId = tagSearchType === TAG_TYPES.PRIMARY ? undefined : selectedTags.primaryTag?.id;

  useEffect(() => {
    dispatch(searchTagsThunk({ tagType: tagSearchType, parentTagId }));
    //cleanup
    return () => {
      dispatch(getTagsAction(null));
    };
  }, []);

  const headCells: HeadCell[] = [
    {
      id: 'title',
      label: 'TITLE'
    },
    {
      id: 'action',
      label: 'ACTION'
    }
  ];
  const rowCells = tagsSearchResults?.tags.map((tag) => {
    const SelectTagBtn = (
      <Button
        onClick={() => {
          dispatch(getTagsAction(null));
          handleTagSelect(tagSearchType, tag);
        }}
        aria-label={'select tag'}
        color={'primary'}
      >
        Select
      </Button>
    );
    const rowCell: RowCell = {
      title: { value: tag.title },
      action: {
        component: SelectTagBtn
      }
    };

    return Object.assign(rowCell, { content: tag });
  });

  const handleNextButton = async () => {
    if (
      tagsSearchResults &&
      tagsSearchResults.page >= 0 &&
      tagsSearchResults.page < tagsSearchResults.total / tagsSearchResults.pageSize - 1
    ) {
      //get next page of tags
      await dispatch(
        searchTagsThunk({
          tagType: tagSearchType,
          parentTagId,
          search: tagsSearchResults.search,
          page: tagsSearchResults.page + 1,
          pageSize: tagsSearchResults.pageSize,
          order: tagsSearchResults.order
        })
      );
    }
  };
  const handlePrevButton = async () => {
    if (tagsSearchResults && tagsSearchResults.page > 0) {
      //get prev page of tags
      await dispatch(
        searchTagsThunk({
          tagType: tagSearchType,
          parentTagId,
          search: tagsSearchResults.search,
          page: tagsSearchResults.page - 1,
          pageSize: tagsSearchResults.pageSize,
          order: tagsSearchResults.order
        })
      );
    }
  };

  const pagination: Pagination | null = tagsSearchResults
    ? {
        contentName: 'Tag',
        page: tagsSearchResults.page,
        pageSize: tagsSearchResults.pageSize,
        total: tagsSearchResults.total,
        handleNextButton,
        handlePrevButton
      }
    : null;

  const searchTags = (tagsData: TagsSearchResults, value: string) =>
    debounce(() => {
      dispatch(
        searchTagsThunk({
          search: value,
          tagType: tagSearchType,
          pageSize: 1
        })
      );
      //get tags thunk
    }, 500);

  const handleSearch = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (tagsSearchResults && e.target) {
      searchTags(tagsSearchResults, e.target.value)();
    }
  };

  return (
    <Dialog
      open={open}
      secondaryButton={{ text: 'Cancel', handleClick: handleClose }}
      title={`Select a ${tagSearchType === TAG_TYPES.PRIMARY ? 'Primary' : 'Secondary'} Tag`}
      handleClose={handleClose}
      handleSearch={handleSearch}
    >
      <Table
        data={tagsSearchResults?.tags}
        headCells={headCells}
        hideHead
        rowCells={rowCells}
        pagination={pagination}
      />
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  topBox: {
    borderRadius: '0.625rem 0.625rem 0 0'
  },

  bottomBox: {
    borderRadius: '0 0 0.625rem 0.625rem'
  },
  primaryButton: {
    boxShadow:
      '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)'
  },
  inputField: {
    maxWidth: '28rem',
    width: '80vw',
    paddingBottom: '0.5rem'
  },
  noDataContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
