import { Box, Paper, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Clear from '@material-ui/icons/Clear';
import ArrowLeft from '@material-ui/icons/ArrowBack';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormPageDisplay } from '../../components/FormPageDisplay';
import { LoadingOrError } from '../../components/LoadingOrError';
import { RootState } from '../../redux/redux-store';
import { QuestionBody } from './QuestionBody';
import { LoadingButton } from '../../components/LoadingButton';
import { getPreviousQuestionThunk } from '../../redux/thunk/thunks';
import {
  setDisplayExitPageAction,
  setDisplayFormAction,
  setDisplayFormInstanceAction
} from '../../redux/data/data-actions';
import { url } from '../../redux/url';
import { setSuccessAction } from '../../redux/display/display-actions';

var axios = require("axios");

interface DisplayCardProps {
  themeColor: string;
  formStyle: any
}

export const DisplayCard: React.FC<DisplayCardProps> = ({ themeColor, formStyle }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [entryPageViewed, setEntryPageViewed] = useState(false);
  const [logo, setLogo] = useState('');
  const [count, setCount] = useState(null);
  const props = { customColor: formStyle ? formStyle.main_color : themeColor };
  const classes = useStyles(props);
  const displayExitPage: any = useSelector((state: RootState) => state.DATA_REDUCER.displayExitPage);
  const displayForm: any = useSelector((state: RootState) => state.DATA_REDUCER.displayForm);
  const displayFormFieldPayload: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.displayFormFieldPayload
  );
  const getExitPageLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.getExitPageLoading
  );
  const getExitPageError = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayErrors.getExitPageError
  );
  const _getHeaders = (inputHeaders?: { [key: string]: string }) => {
    let headers = inputHeaders || {};
    let token = localStorage.getItem('token');
    headers["Accept"] = "application/json";
    const headersWithAuth = token
      ? {
        ...headers,
        Authorization: `Bearer ${token}`,
      }
      : headers;
    return headersWithAuth;
  };
  useEffect(() => {
    if (formStyle && logo === '') {
      const data1 = {
        input_files: [formStyle.logo]
      }
      var config1 = {
        method: 'post',
        url: `${url}/user/auth/getSignedDownloadUrls`,
        headers: _getHeaders({ "Content-Type": "application/json" }),
        data: data1,
      };

      axios(config1)
        .then(function (res: any) {
          setLogo(res.data.url && res.data.url.length > 0 && res.data.url[0]);
        })
    }
  })

  const exitPreview = () => {
    if (history?.location?.state) {
      sessionStorage.setItem('edit', 'true')
    } else {
      sessionStorage.removeItem('edit')
    }
    history.goBack();
    dispatch(setSuccessAction('getForms', true));
    dispatch(setDisplayExitPageAction(null));
    dispatch(setDisplayFormAction(null));
    dispatch(setDisplayFormInstanceAction(null));
  };

  const ExitPageBody = () => {
    return displayExitPage ? (
      <Box pb={3}>
        <FormPageDisplay
          header={displayExitPage.title || null}
          body={displayExitPage.description || null}
          buttonText={displayExitPage.button_text}
          buttonLink={displayExitPage.button_url}
          videoUrl={displayExitPage.exit_page_video_url}
          showReferralButton={displayExitPage.show_referral_btn}
          themeColor={formStyle ? formStyle.main_color : themeColor}
        />
      </Box>
    ) : (
      <Box py={3}>
        <LoadingOrError
          noElevation
          loadingMsg={'Loading Page...'}
          loading={getExitPageLoading}
          errorMsg={getExitPageError}
        />
      </Box>
    );
  };

  const EntryPageBody = () => {
    return displayForm ? (
      <Box pb={3}>
        <FormPageDisplay
          header={displayForm?.entry_page_header}
          body={displayForm?.entry_page_body}
          buttonCallback={() => setEntryPageViewed(true)}
          buttonText={'Start Form'}
          videoUrl={displayForm?.entry_page_video_url}
          themeColor={formStyle ? formStyle.main_color : themeColor}
        />
      </Box>
    ) : null;
  };

  const showEntryPage =
    !entryPageViewed &&
    displayForm?.entry_page_header &&
    displayForm?.entry_page_body;

  const showExitPage = displayExitPage || getExitPageLoading || getExitPageError;

  const handelPreviousQue = async () => {
    const backCount = displayFormFieldPayload.total;
    setCount(displayFormFieldPayload && (displayFormFieldPayload.total));
    dispatch(setDisplayExitPageAction(null));
    await dispatch(getPreviousQuestionThunk(displayExitPage && displayExitPage.formInstanceId, backCount));
  }

  const handelCount = () => {
    setCount(null)
  }

  return (
    <Box className={classes.mainContainer}>
      <Paper elevation={3} classes={{ root: classes.root }} className={classes.card}>
        <Box p={2} className={classes.cardHeader}>
          <Box pl={3} pr={3}>
          </Box>
          <Box>
            <img src={logo} alt='Clinic Logo' className={classes.avatar} />
          </Box>
          <Box>
            <IconButton onClick={exitPreview} style={{ padding: 0 }}>
              <Clear style={{ color: '#d3d3d3', fontSize: '50px' }} />
            </IconButton>
          </Box>
        </Box>
        <Box p={3}>
          {showEntryPage ? (
            <EntryPageBody />
          ) : showExitPage ? <>
            <ExitPageBody />
            <Box mt={2} className={classes.bottomButtons}>
              {displayFormFieldPayload && <LoadingButton
                className={classes.nextBtn}
                startIcon={<ArrowLeft />}
                variant="contained"
                color={'primary'}
                onClick={handelPreviousQue}
                aria-label="back"
              >
                Back
              </LoadingButton>
              }
              <LoadingButton
                className={classes.nextBtn}
                variant="contained"
                color={'primary'}
                onClick={exitPreview}
                aria-label="back"
              >
                Submit
              </LoadingButton>
            </Box>
          </> : (
            <QuestionBody themeColor={formStyle ? formStyle.main_color : themeColor} count={count} setCount={handelCount} />
          )}
        </Box>
      </Paper>
    </Box>
  );
};
interface ComponentsProps {
  customColor: string;
}

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  cardHeader: (props: ComponentsProps) => ({
    backgroundColor: props.customColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: "space-between"
  }),
  nextBtn: (props: ComponentsProps) => ({
    backgroundColor: props.customColor,
    minWidth: '8rem',
    padding: '20px',
    fontSize: '15px',
    '&:hover': {
      backgroundColor: props.customColor,
    }
  }),
  card: {
    minWidth: '65rem',
    maxWidth: '65rem'
  },
  root: {
    overflow: 'hidden'
  },
  avatar: {
    height: '40px',
    borderRadius: 'unset',
    backgroundColor: 'transparent'
  },
  bottomButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
}));
