import {
  AppBar, Box, Button, Popover, CircularProgress, IconButton, Tab, Toolbar, Typography, Divider, Tooltip, withStyles, Theme, Backdrop, DialogActions
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add, Star, StarOutline } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import SaveAltOutlined from '@material-ui/icons/SaveAltOutlined';
import DeleteOutlineOutlined from '@material-ui/icons/DeleteOutlineOutlined';
import { TabContext, TabList } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { FormTitleDialog } from '../../../Forms/components/FromTitleDialog';
import { RootState } from '../../../../redux/redux-store';
import { Dialog } from '../../../../components/Dialog';
import { LoadingButton } from '../../../../components/LoadingButton';
import { assignFormToCollectionsThunk } from '../../../../redux/thunk/collection-thunks';
import {
  getAllPrimaryDomain, getAllTags, getAllCollectionListThunk, markFavoriteFormThunk, removeFavoriteFormThunk, deleteFormThunk, getFormVersionsThunk, getAllCollectionsThunk,
  cancelEditFormThunk, checkEditFormPermissionThunk, getFormFieldsByFormIdThunk, getExitPagesByFormIdThunk, formUpdateThunk, downloadFormsThunk, deleteNewFormThunk,
  updateExitPageThunk, editCalculationThunk, editMultiMetricQuestionThunk, fetchFormToEditByIdThunk, allowEditWithNewTokenFormThunk, getFormStatusCount
} from '../../../../redux/thunk/thunks';
import { setEditingFormFieldsAction } from '../../../../redux/data/data-actions';
import { Form, FormUpdate, UuidType } from '../../../../shared/domain';
import { AddFormToCollectionsDialog } from './components/AddFormToCollectionsDialog';
import { TagDomain } from './components/AddPrimaryDomains';
import './formBuilder.css';
import { colors } from '../../../../styling/colors';
import { ROLES } from '../../../../shared/globals';
import { setSuccessAction, snackbarAlertAction } from '../../../../redux/display/display-actions';
import { ConfirmationDelete } from '../../../../components/DeleteConfirmation';

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    padding: '0px 10px',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

interface FormBuilderHeaderProps {
  isEdit: boolean;
  form: Form | null;
  pdfSave: () => void;
  handelUpdateSaveChanges: () => void;
  handelSaveChangesToNewVersion: () => void;
  isDisabled: boolean;
  setIsEdit: (value: any) => void;
}

export const FormBuilderHeader: React.FC<FormBuilderHeaderProps> = ({ isEdit, form, pdfSave, handelUpdateSaveChanges, handelSaveChangesToNewVersion, isDisabled, setIsEdit }: any) => {
  let { url } = useRouteMatch();
  const split = url.split('/');
  const formId: any = split && split.length > 1 && split[2];
  const classes = useStyles();
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);
  const isCreate = sessionStorage.getItem('new');
  const dispatch = useDispatch();
  const [addToCollectionDialogOpen, setAddFormToCollectionsDialogOpen] = useState(false);
  const [primaryDomainDialogOpen, setPrimaryDomainDialogOpen] = useState(false);
  const [deleteFormsConfirmationDialogOpen, setDeleteFormsConfirmationDialogOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [tagDialogOpen, setTagDialogOpen] = useState(false);
  const [collectionDialogOpen, setCollectionDialogOpen] = useState(false);
  const location: any = useLocation();
  const [isConfirm, setIsConfirmOpen] = useState(false);
  const [openTitle, setOpenTitle] = useState(false);
  const [isDeny, setOpenDeny] = useState(false);
  const [isBackdrop, setIsBackdrop] = useState(false);
  const [isNew, setOpenNew] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [formsTab, setFormsTab] = useState(location.pathname);
  const [editing, setEditing] = useState(false);
  const [saveConfirm, setSaveConfirm] = useState(false);
  const [formUpdate, setFormUpdate] = useState<FormUpdate>();
  const styles = useStyles();
  let history = useHistory();
  const getSession: any = sessionStorage.getItem('item');
  const formType: any = sessionStorage.getItem('type_id');
  const sessionData = JSON.parse(getSession);
  const version_id = sessionStorage.getItem('version');

  const editingFormFields = useSelector((state: RootState) => state.DATA_REDUCER.editingFormFields);
  const formVersions: any = useSelector((state: RootState) => state.DATA_REDUCER.formVersions);
  const editingForm: any = useSelector((state: RootState) => state.DATA_REDUCER.editingForm);
  const editingExitPage: any = useSelector((state: RootState) => state.DATA_REDUCER.editingExitPage);
  const deleteData: any = useSelector((state: RootState) => state.DATA_REDUCER.formStatusCount);

  const updateFormLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.updateFormLoading
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorEl_1, setAnchorEl_1] = useState<HTMLButtonElement | null>(null);
  const [version, setVersion] = useState(version_id ? version_id : '');

  useEffect(() => {
    dispatch(getAllPrimaryDomain());
    dispatch(getAllTags());
    if (loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role)) {
      dispatch(getAllCollectionsThunk());
    } else if ((loggedInUser && ![ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role))) {
      dispatch(getAllCollectionListThunk());
    }
  }, [])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, info: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl_1(null);
  };
  const handleClickPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl_1(event.currentTarget);
  };

  const handleLinkTab = async (event: any, value: any) => {
    setFormsTab(value);
    if (event.target.innerText === 'Preview') {
      editingFormFields && editingFormFields.length > 0 ? history.push({ pathname: value, state: isEdit }) :
        dispatch(snackbarAlertAction(`Please add some questions to the form to proceed with the Preview!`, 'warning'));
    } else {
      history.push({ pathname: value, state: isEdit });
    }
    const tab: any = sessionStorage.getItem('formTab');
    const check = sessionStorage.getItem('onChange');
    const checkExit: any = sessionStorage.getItem('onChangeExit');
    const form_version_id = sessionStorage.getItem('version');
    if (tab === 'entry' && check) {
      if (editingForm) {
        const domains = [];
        const tags = [];
        const collections = [];
        for (let i = 0; i < editingForm.domains.length; i++) {
          domains.push(editingForm.domains[i].id);
        }
        for (let i = 0; i < editingForm.tags.length; i++) {
          tags.push(editingForm.tags[i].id);
        }
        for (let i = 0; i < editingForm.collections.length; i++) {
          collections.push(editingForm.collections[i].id);
        }
        editingForm.domains = domains;
        editingForm.tags = tags;
        editingForm.collections = collections;
        await dispatch(formUpdateThunk(editingForm));
      }
    } else if (tab === 'exit' && checkExit) {
      await dispatch(updateExitPageThunk(editingExitPage, editingForm?.id));
    } else if (tab === 'calculation' && check) {
      const getData: any = sessionStorage.getItem('calculation');
      const metric_id: any = sessionStorage.getItem('metric_id');
      const change: any = sessionStorage.getItem('onChange');
      const getChange = JSON.parse(change);
      if (getChange && getChange?.id) {
        const data = {
          "metric_name": getChange.code,
          "description": getChange.description,
          "form_id": formId
        }
        const result: any = await dispatch(editCalculationThunk(getChange?.id, data))
      }
      const convertData = JSON.parse(getData);
      const calculation_data: any = [];
      if (convertData && convertData.length > 0) {
        for (let i = 0; i < convertData.length; i++) {
          const array: any = [];
          for (let j = 0; j < convertData[i].fields.length; j++) {
            let regexPattern = /^-?[0-9]+$/;
            let result = regexPattern.test(convertData[i]?.fields[j]?.calculation_point_amount);
            const info = {
              "comparison_option_id": convertData[i]?.fields[j]?.comparison_option_id,
              "comparison_value": convertData[i]?.fields[j]?.comparison_value,
              "operator_type": convertData[i]?.fields[j]?.operator_type,
              "calculation_operator": convertData[i]?.fields[j]?.calculation_operator,
              "calculation_point_amount": convertData[i]?.fields[j]?.calculation_point_amount
            }
            array.push(info);
          }
          const data = {
            "form_field_id": convertData[i]?.id,
            "metric_id": metric_id,
            "calc_data": array,
            "form_version_id": form_version_id
          }
          calculation_data.push(data);
        }
        await dispatch(editMultiMetricQuestionThunk(editingForm?.id, calculation_data, metric_id));
      }
    }
    sessionStorage.removeItem('onChange');
    sessionStorage.removeItem('onChangeExit');
    sessionStorage.removeItem('formTab');
  };

  useEffect(() => {
    if (!formVersions && form && form?.id) {
      dispatch(getFormVersionsThunk(formId));
    }
  })

  const success: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.getForms);
  useEffect(() => {
    if (success) {
      const version_id: any = sessionStorage.getItem('version');
      setVersion(version_id);
      dispatch(setSuccessAction('getForms', false));
    }
  }, [success]);
  const autoSaveSuccess = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.autoSaveSuccess);
  useEffect(() => {
    if (autoSaveSuccess) {
      const tab = sessionStorage.getItem('formTab');
      // !isCreate && setIsEdit(false);
      if (tab === 'calculation') {
        setFormsTab(`${url}/calculation`);
        history.push({ pathname: `/form-builder/${formId}/calculation`, state: false });
      } else if (tab === 'entry') {
        setFormsTab(`${url}/entryPage`);
        history.push({ pathname: `/form-builder/${formId}/entryPage`, state: false });
      } else if (tab === 'exit') {
        setFormsTab(`${url}/exitPages`);
        history.push({ pathname: `/form-builder/${formId}/exitPages`, state: false });
      } else {
        setFormsTab(`${url}/fields`);
        history.push({ pathname: `/form-builder/${formId}/fields`, state: false });
      }
      sessionStorage.removeItem('formTab');
    }
  }, [autoSaveSuccess])

  useEffect(() => {
    if (form) {
      setFormUpdate(form);
    }
  }, [form]);

  const handleAssignFormtoCollections = (selectedCollections: UuidType[]) => {
    if (!editingForm) {
      return;
    }
    dispatch(assignFormToCollectionsThunk(selectedCollections, editingForm?.id, version));
  };

  const toggleEditing = () => {
    setEditing(!editing);
  };

  const handleAddFormToCollectionsDialogClose = () => {
    setAddFormToCollectionsDialogOpen(false);
  };
  const handleAddPrimaryDomainDialogOpen = async () => {
    setPrimaryDomainDialogOpen(true);
  }
  const handleAddTagDialogOpen = async () => {
    setTagDialogOpen(true)
  }
  const handleAddCollectionDialogOpen = async () => {
    setCollectionDialogOpen(true)
  }
  const handelClosePrimaryDomainDialog = async () => {
    setPrimaryDomainDialogOpen(false);
  }

  const handelCloseTagDialog = async () => {
    setTagDialogOpen(false);
    setCollectionDialogOpen(false);
  }

  const editTitle = (open: boolean) => {
    setOpenTitle(open)
  }

  const handelClose = () => {
    setOpenTitle(false)
  }

  const handelConfirmed = async () => {
    setIsConfirmOpen(false);
    setSaveConfirm(false);
    const checkData: any = await dispatch(checkEditFormPermissionThunk(formId, version));
    if (checkData.canEdit == 'ALLOW') {
      dispatch(getAllPrimaryDomain());
      dispatch(getAllTags());
      if (loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role)) {
        dispatch(getAllCollectionsThunk());
      } else if ((loggedInUser && ![ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role))) {
        dispatch(getAllCollectionListThunk());
      }
      setIsEdit(true);
      setFormsTab(`${url}/fields`);
      history.push({ pathname: `/form-builder/${formId}/fields`, state: true });
      sessionStorage.setItem('id', formId);
    } else if (checkData.canEdit == 'DENY') {
      setOpenDeny(true);
    } else if (checkData.canEdit == 'NEW_TOKEN_ALLOW') {
      setOpenNew(true);
    }
    sessionStorage.removeItem('new');
  }

  const handelCancelEdit = async () => {
    const isNew = sessionStorage.getItem('new');
    if (isNew) {
      setIsBackdrop(true);
      setDisabled(true);
      sessionStorage.removeItem('new');
      const deleteForm = {
        form_ids: [formId],
        form_version_id: version
      }
      await dispatch(deleteNewFormThunk(deleteForm));
      setDisabled(false);
      setIsBackdrop(false);
    } else {
      setIsBackdrop(true);
      setDisabled(true);
      sessionStorage.removeItem('new');
      await dispatch(cancelEditFormThunk(formId, version));
      const tab: any = sessionStorage.getItem('formTab');
      if (tab === 'field' || tab === 'entry') {
        await dispatch(getFormFieldsByFormIdThunk(formId));
        await dispatch(fetchFormToEditByIdThunk(formId));
      } else if (tab === 'exit') {
        await dispatch(getExitPagesByFormIdThunk(formId, version));
      }
      setIsEdit(false);
      setFormsTab(`${url}/fields`);
      history.push({ pathname: `/form-builder/${formId}/fields`, state: false });
      setDisabled(false);
      setIsBackdrop(false);
    }
  }

  const cancelSaveChanges = async () => {
    await dispatch(allowEditWithNewTokenFormThunk(formId, version));
    await dispatch(getAllPrimaryDomain());
    await dispatch(getAllTags());
    if (loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role)) {
      dispatch(getAllCollectionsThunk());
    } else if ((loggedInUser && ![ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role))) {
      dispatch(getAllCollectionListThunk());
    }
    setIsEdit(true);
    setFormsTab(`${url}/fields`);
    history.push({ pathname: `/form-builder/${formId}/fields`, state: true });
    setOpenNew(false);
  }

  const handelFavoriteForm = async (value: boolean) => {
    setLoading(true);
    value ? await dispatch(removeFavoriteFormThunk(formId, version)) : await dispatch(markFavoriteFormThunk(formId, version));
    setLoading(false);
  }
  const handelFavoriteCall = () => { }

  const handleDeleteForm = async () => {
    const data = { form_ids: [formId] }
    await dispatch(getFormStatusCount(data));
    await setDeleteFormsConfirmationDialogOpen(true);
  }

  const handleCheckUserCountEditForm = async () => {
    const data = { form_ids: [formId] }
    await dispatch(getFormStatusCount(data));
    await setIsConfirmOpen(true);
  }

  const handelDelete = async () => {
    setDeleteLoading(true);
    const deleteForm = {
      form_ids: [formId],
      form_version_id: version
    }
    await dispatch(deleteFormThunk(deleteForm));
    setDeleteLoading(false);
  }

  const handleDeleteFormsConfirmationDialogClose = () => {
    setDeleteFormsConfirmationDialogOpen(false);
  }

  const removeSession = () => {
    sessionStorage.removeItem('calculation');
    sessionStorage.removeItem('metric_id');
    sessionStorage.removeItem('type_id');
    sessionStorage.removeItem('version');
    sessionStorage.removeItem('new');
  }

  const viewVersion = () => {
    if (formVersions && version_id) {
      const version = formVersions && formVersions.filter((item: any) => item.id === version_id);
      return version && version?.length > 0 ? version[0].version_no : '';
    } else {
      return ''
    }
  }

  const handleSetVersion = async (id: any) => {
    if (version !== id) {
      await sessionStorage.setItem('version', id);
      setVersion(id);
      dispatch(fetchFormToEditByIdThunk(formId));
      dispatch(getFormFieldsByFormIdThunk(formId));
      dispatch(fetchFormToEditByIdThunk(formId));
      setFormsTab(`${url}/fields`);
      history.push({ pathname: `/form-builder/${formId}/fields`, state: false });
    }
    setAnchorEl(null);
  }

  const downloadForms = async () => {
    const data = {
      "form_id": formId,
      "form_version_id": version_id
    }
    const links: any = await dispatch(downloadFormsThunk([data]));
    if (links) {
      for (let i = 0; i < links.length; i++) {
        window.open(links[i]?.file_url, "_blank");
      }
    }
  }

  const handelChangePath = () => {
    sessionStorage.removeItem('new');
    const checkPath = sessionStorage.getItem('route');
    const nestedPath = sessionStorage.getItem('nested_route');
    if (checkPath) {
      history.push(checkPath);
      sessionStorage.removeItem('route');
    } else if (nestedPath) {
      history.push(nestedPath);
      sessionStorage.removeItem('nested_route');
    } else {
      setIsEdit(false);
      if (loggedInUser && loggedInUser.role === ROLES.CLINICIAN) {
        const check = sessionStorage.getItem('collection');
        if (check) {
          history.push('/view-collection');
        } else {
          history.push('/forms-collections');
        }
        removeSession();
      } else if (sessionData) {
        history.push('/collections');
        removeSession();
      } else {
        history.push('/forms');
        removeSession();
        dispatch(setSuccessAction('getForms', true));
      }
    }
    dispatch(setEditingFormFieldsAction([]));
  }

  const handelUpdateSaveChange = async () => {
    setIsBackdrop(true);
    await handelUpdateSaveChanges();
    setIsBackdrop(false);
  }

  const handleSaveNewVersion = async () => {
    setIsBackdrop(true);
    await handelSaveChangesToNewVersion();
    setIsBackdrop(false);
  }

  return (
    <>
      {isBackdrop && <Backdrop className={classes.backdrop} open={isBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>}
      {addToCollectionDialogOpen && (
        <AddFormToCollectionsDialog
          open={addToCollectionDialogOpen}
          handleAssignFormtoCollections={handleAssignFormtoCollections}
          handleClose={handleAddFormToCollectionsDialogClose}
          formId={editingForm?.id}
        />
      )}
      {primaryDomainDialogOpen && (
        <TagDomain
          form={editingForm}
          openTagDomain={primaryDomainDialogOpen}
          handleClose={handelClosePrimaryDomainDialog}
          info={1}
        />
      )}
      {tagDialogOpen && (
        <TagDomain
          form={editingForm}
          openTagDomain={tagDialogOpen}
          handleClose={handelCloseTagDialog}
          info={2}
        />
      )}
      {collectionDialogOpen && (
        <TagDomain
          form={editingForm}
          openTagDomain={collectionDialogOpen}
          handleClose={handelCloseTagDialog}
          info={3}
        />
      )}
      {openTitle && (
        <FormTitleDialog
          formId={editingForm}
          open={openTitle}
          handleClose={handelClose}
        />
      )}
      <AppBar square elevation={0} classes={{ root: styles.headerRoot }}>
        <Toolbar classes={{ root: styles.headerToolbar }}>
          <Box className={styles.left}>
            {!isEdit && <Button
              classes={{ text: styles.closeBtnText }}
              startIcon={<CloseIcon style={{ fontSize: '30px' }} />}
              onClick={() => handelChangePath()}
            >
            </Button>}
            <Box className={isEdit ? styles.midAction : styles.midActions} display="flex">
              <Box style={{ maxWidth: '85%', minWidth: '85%' }}>
                <Box display="flex" justifyContent="space-between" style={{ minWidth: '85%' }}>
                  <Typography variant="h2" style={{ maxWidth: '85%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{formUpdate?.title}</Typography>
                  {isEdit ? <IconButton onClick={toggleEditing} style={{ padding: '0px', marginLeft: '20px' }}>
                    {updateFormLoading ? (
                      <CircularProgress size={16} />
                    ) : (
                      <img src={process.env.PUBLIC_URL + '/icons/EditIcon.svg'} alt="Edit Icon" onClick={() => editTitle(true)} />
                    )}
                  </IconButton> : <IconButton></IconButton>
                  }
                </Box>
                <Box style={{ paddingTop: '6px' }}>
                  <span className="description-ellipsis">{formUpdate?.description !== '' ? formUpdate?.description : 'NA'}</span>
                </Box>
              </Box>
              {isEdit ?
                <Box mr={1} display="flex" flexDirection='column' alignItems='flex-end' style={{ textAlign: 'right', maxWidth: '15%', width: '-webkit-fill-available' }}>
                  {loggedInUser && ![ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(loggedInUser.role) && !isCreate ?
                    <LoadingButton
                      className={classes.primaryButton}
                      color="primary"
                      variant="contained"
                      onClick={(e) => handleClickPopup(e)}
                    >
                      Save
                    </LoadingButton> :
                    <LoadingButton disabled={isDisabled} loading={isDisabled}
                      className={classes.primaryButton}
                      color="primary"
                      variant="contained"
                      onClick={() => handelUpdateSaveChange()}
                    >
                      Save
                    </LoadingButton>
                  }
                  <Popover
                    open={Boolean(anchorEl_1)}
                    anchorEl={anchorEl_1}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Typography onClick={() => { handelUpdateSaveChange(); setAnchorEl_1(null); }} style={{ color: 'black', cursor: 'pointer', width: '150px', padding: '5px 10px', borderBottom: '1px solid #00000026' }}><b>Save to Current Version</b></Typography>
                    <Typography onClick={() => { setSaveConfirm(true); setAnchorEl_1(null); }} style={{ color: 'black', cursor: 'pointer', width: '150px', padding: '5px 10px' }}><b>Save as a New Version</b></Typography>
                  </Popover>
                  <LoadingButton disabled={disabled} loading={disabled}
                    className={classes.secondaryButton}
                    color="secondary"
                    variant="contained"
                    onClick={() => handelCancelEdit()}
                  >
                    Cancel
                  </LoadingButton>
                </Box> :
                <Box display="flex" flexDirection='column' alignItems='flex-end' style={{ textAlign: 'right', minWidth: '15%', maxWidth: '15%', width: '-webkit-fill-available' }}>
                  {loggedInUser && ![ROLES.SITE_ADMIN, ROLES.BUILDER, ROLES.CLINICIAN, ROLES.PATIENT, ROLES.PROXY, ROLES.RATER, ROLES.REFERRAL].includes(loggedInUser.role) && <LoadingButton
                    className={classes.primaryButton}
                    color="primary"
                    variant="contained"
                    onClick={() => handleCheckUserCountEditForm()}
                  >
                    Edit
                  </LoadingButton>}
                  {loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role) && formType && formType === 'CUSTOM' && formVersions && formVersions.length > 0 && formVersions[0].id === version && <LoadingButton
                    className={classes.primaryButton}
                    color="primary"
                    variant="contained"
                    onClick={() => handleCheckUserCountEditForm()}
                  >
                    Edit
                  </LoadingButton>}
                  {loggedInUser && ![ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(loggedInUser.role) &&
                    <LoadingButton style={{ color: '#3061b2', fontWeight: 600, width: '120px', marginTop: '10px', fontSize: '13.6px' }}
                      color="secondary" onClick={(e: any) => handleClick(e, '')}
                    >
                      Version {viewVersion()}
                    </LoadingButton>
                  }
                  <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    {formVersions && formVersions.map((item: any, i: number) => {
                      return (
                        <Typography onClick={() => handleSetVersion(item.id)} style={{ color: version === item.id ? '#3061b2' : 'black', cursor: 'pointer', width: '70px', padding: '5px', borderBottom: formVersions.length === (i + 1) ? 'unset' : '1px solid #00000026', textAlign: 'center' }}>Version {item.version_no}</Typography>
                      )
                    })}
                  </Popover>
                </Box>
              }
            </Box>
          </Box>
        </Toolbar>
        <Divider />
        <Box display="flex" justifyContent="space-between" pl={2} pr={2}>
          <Box className={styles.bottomActions}>
            <Box className={styles.collections}>
              {editingForm && editingForm.isFavourite ? <Star color='primary' style={{ cursor: 'pointer' }} onClick={() => { loading ? handelFavoriteCall() : handelFavoriteForm(true) }} /> : <StarOutline color='primary' style={{ cursor: 'pointer' }} onClick={() => { loading ? handelFavoriteCall() : handelFavoriteForm(false) }} />}
            </Box>
            <Box className={styles.collections}>
              <HtmlTooltip
                title={<React.Fragment>
                  {editingForm && editingForm.domains.map((domain: any, i: number) => {
                    return (
                      <Typography style={{ padding: '5px 0px', borderBottom: (i + 1) === form.domains.length ? 'unset' : '1px solid #00000026' }}>{domain && domain.title}</Typography>
                    )
                  })}
                </React.Fragment>}
              >
                <Typography style={{ color: isEdit ? 'gray' : '#3061b2' }}>Primary Domains - </Typography>
              </HtmlTooltip>
              {isEdit ? <><Typography style={{ color: 'gray' }}>&nbsp;&nbsp;{editingForm && editingForm.domains.length}</Typography><IconButton
                className={styles.addBtn}
                size="small"
                onClick={handleAddPrimaryDomainDialogOpen}
              >
                <Add fontSize={'small'} />
              </IconButton></> : <Typography style={{ color: isEdit ? 'gray' : '#3061b2' }}>&nbsp;&nbsp;{editingForm && editingForm.domains.length}</Typography>}
            </Box>
            <Box className={styles.collections}>
              <HtmlTooltip
                title={<React.Fragment>
                  {editingForm && editingForm.tags.map((tag: any, i: number) => {
                    return (
                      <Typography style={{ padding: '5px 0px', borderBottom: (i + 1) === form.tags.length ? 'unset' : '1px solid #00000026' }}>{tag && tag.title}</Typography>
                    )
                  })}
                </React.Fragment>}
              >
                <Typography style={{ color: isEdit ? 'gray' : '#3061b2' }}>Tags - </Typography>
              </HtmlTooltip>
              {isEdit ? <><Typography style={{ color: 'gray' }}>&nbsp;&nbsp;{editingForm && editingForm.tags.length}</Typography><IconButton
                className={styles.addBtn}
                size="small"
                onClick={handleAddTagDialogOpen}
              >
                <Add fontSize={'small'} />
              </IconButton></> : <Typography style={{ color: '#3061b2' }}>&nbsp;&nbsp;{editingForm && editingForm.tags.length}</Typography>}
            </Box>
            <Box className={styles.collectionsLeft}>
              <HtmlTooltip
                title={<React.Fragment>
                  {editingForm && editingForm.collections.map((item: any, i: number) => {
                    return (
                      <Typography style={{ padding: '5px 0px', borderBottom: (i + 1) === form.collections.length ? 'unset' : '1px solid #00000026' }}>{item && item.name}</Typography>
                    )
                  })}
                </React.Fragment>}
              >
                <Typography style={{ color: isEdit ? 'gray' : '#3061b2' }}>Collections -  </Typography>
              </HtmlTooltip>
              {isEdit ? <><Typography style={{ color: 'gray' }}>&nbsp;&nbsp;{editingForm && editingForm.collections.length}</Typography>
                <IconButton
                  className={styles.addBtn}
                  size="small"
                  onClick={handleAddCollectionDialogOpen}
                >
                  <Add fontSize={'small'} />
                </IconButton></> : <Typography style={{ color: '#3061b2' }}>&nbsp;&nbsp;{editingForm && editingForm.collections.length}</Typography>}
            </Box>
          </Box>
          <Box className={styles.right}>
            <Box display="flex" alignItems="center">
              <TabContext value={formsTab}>
                <TabList
                  indicatorColor="primary"
                  onChange={handleLinkTab}
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  classes={{ root: styles.tabListRoot, scrollButtons: styles.scrollBtns }}
                >
                  <Tab label="Entry Page" value={`${url}/entryPage`} />
                  <Tab label="Form Fields" value={`${url}/fields`} />
                  <Tab label="Exit Page" value={`${url}/exitPages`} />
                  <Tab label="Calculations" value={`${url}/calculation`} />
                  <Tab label="Preview" value={`/form-preview/${formId}`} />
                  {/* disabled={!form} */}
                </TabList>
              </TabContext>
              {!isEdit && <SaveAltOutlined className={classes.downloadIcon} onClick={() => downloadForms()} />}
              {isEdit && <DeleteOutlineOutlined className={classes.deleteIcon} onClick={() => handleDeleteForm()} />}
            </Box>
          </Box>
        </Box>
      </AppBar>
      {
        deleteFormsConfirmationDialogOpen &&
        <ConfirmationDelete
          loading={deleteLoading}
          data={deleteData}
          customData={[formUpdate]}
          title="form"
          handleClose={handleDeleteFormsConfirmationDialogClose}
          handleConfirmClick={() => handelDelete()}
        />
      }
      {isDeny &&
        <Dialog open={isDeny}>
          <Box display="flex" flexDirection="column" style={{ textAlign: 'center' }} width={500}>
            <Box alignItems="center" bgcolor={colors.background2} className={classes.topBox} px={3} py={2.5} >
              <Typography variant="h3">Some other user is currently editing this form. Please come back later.</Typography>
            </Box>
            <Box bgcolor={colors.background2} className={classes.bottomBox} display="flex" justifyContent="space-between" px={3} py={2} >
              <LoadingButton className={classes.secondaryButton} color="secondary" onClick={() => setOpenDeny(false)} variant="contained">
                Cancel
              </LoadingButton>
            </Box>
          </Box>
        </Dialog>
      }
      {isNew &&
        <Dialog open={isNew}>
          <Box display="flex" flexDirection="column" style={{ textAlign: 'center' }} width={500}>
            <Box alignItems="center" bgcolor={colors.background2} className={classes.topBox} px={3} py={2.5} >
              <Typography variant="h3">You are currently editing this form on some other device/browser. Do you want to revert to old changes ?</Typography>
            </Box>
            <Box bgcolor={colors.background2} className={classes.bottomBox} display="flex" justifyContent="space-between" px={3} py={2} >
              <LoadingButton className={classes.secondaryButton} style={{ marginTop: 0 }} color="secondary" onClick={() => setOpenNew(false)} variant="contained">
                No
              </LoadingButton>
              <LoadingButton className={classes.primaryButton} color="primary" variant="contained" onClick={(e) => cancelSaveChanges()}>
                Yes
              </LoadingButton>
            </Box>
          </Box>
        </Dialog>
      }
      {saveConfirm && isEdit &&
        <Dialog open={saveConfirm}>
          <Box display="flex" flexDirection="column" style={{ textAlign: 'center' }} width={500}>
            <Box alignItems="center" bgcolor={colors.background2} className={classes.topBox} px={3} py={2.5} >
              <Typography variant="h3">Saving as a New Version</Typography>
            </Box>
            <Box alignItems="center" px={3} py={2.5}>
              <Typography variant="h4" style={{ textAlign: 'justify', fontWeight: 500 }} >Please keep in mind, once you save your updates as a new version, you will no longer be able to make edits to the previous version.</Typography>
            </Box>
            <Box bgcolor={colors.background2} className={classes.bottomBox} display="flex" justifyContent="space-between" px={3} py={2} >
              <LoadingButton className={classes.secondaryButton} style={{ marginTop: 0 }} color="secondary" onClick={() => setSaveConfirm(false)} variant="contained">
                Cancel
              </LoadingButton>
              <LoadingButton className={classes.primaryButton} color="primary" variant="contained" onClick={(e) => handleSaveNewVersion()}>
                Save
              </LoadingButton>
            </Box>
          </Box>
        </Dialog>
      }
      {isConfirm &&
        <Dialog open={isConfirm}>
          <Box style={{ width: '500px', background: '#f9fafb', borderRadius: '10px 10px 0px 0px' }}>
            <Box p={3} display="flex" justifyContent="space-between">
              <Typography variant="h3">Are you sure you want to edit this form?</Typography>
            </Box>
          </Box>
          <Box p={3}>
            <Typography>This form is currently assigned to {deleteData && deleteData.length > 0 && (deleteData[0].assigned_to_count + ' ' + (deleteData[0].assigned_to_count === '1' ? 'user' : 'users'))}.</Typography>
            <Typography>This form is currently in progress by {deleteData && deleteData.length > 0 && (deleteData[0].in_progress_count + ' ' + (deleteData[0].in_progress_count === '1' ? 'user' : 'users'))}.</Typography>
            <Typography>This form has been completed by {deleteData && deleteData.length > 0 && (deleteData[0].completed_count + ' ' + (deleteData[0].completed_count === '1' ? 'user' : 'users'))}.</Typography>
          </Box>
          <DialogActions style={{ justifyContent: 'space-between', background: '#f9fafb', borderRadius: '0px 0px 10px 10px', padding: '20px 24px' }}>
            <LoadingButton style={{ border: '1px solid rgba(0, 0, 0, 0.15)', width: '120px', color: '#0060B2' }}
              variant="contained" color="secondary"
              onClick={() => setIsConfirmOpen(false)}
            >
              Cancel
            </LoadingButton>
            <LoadingButton color="primary"
              variant="contained"
              style={{ boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)', width: '120px' }}
              onClick={(e) => handelConfirmed()}
            >
              Edit
            </LoadingButton>
          </DialogActions>
        </Dialog>
      }
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  bottomBox: {
    borderRadius: '0 0 0.625rem 0.625rem'
  },
  topBox: {
    borderRadius: '0.625rem 0.625rem 0 0'
  },
  clearIcon: {
    fontSize: 14,
    marginLeft: '0.25rem'
  },
  tabListRoot: {
    display: 'flex'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loadingIcon: {
    marginRight: '1rem'
  },
  left: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: theme.spacing(1.5)
  },
  right: {},
  chipAlign: {
    display: 'flex',
    alignItems: 'center'
  },
  otherActions: {
    marginLeft: '.6rem',
    display: 'flex',
    alignItems: 'center'
  },
  primaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: '#fff',
    minWidth: '120px'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    height: '2rem',
    color: '#0060B2',
    marginTop: '15px',
    minWidth: '120px'
  },
  collectionChip: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.background.main
  },
  closeBtnText: {
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: 'unset'
    }
  },
  midActions: {
    color: theme.palette.text.secondary,
    borderLeft: `2px solid ${theme.palette.divider}`,
    paddingLeft: '1rem',
    width: '100%'
  },
  midAction: {
    color: theme.palette.text.secondary,
    paddingLeft: '1rem',
    width: '100%'
  },
  toolbar: theme.mixins.toolbar,
  headerRoot: {
    backgroundColor: 'white',
    borderBottom: `2px solid ${theme.palette.divider}`,
    padding: 0
  },
  headerToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 0
  },
  formTitle: {
    padding: 0
  },
  editIcon: {
    color: theme.palette.text.disabled
  },
  bottomActions: {
    display: 'flex',
    alignItems: 'center'
  },
  collections: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '.6rem',
    paddingLeft: '.6rem',
    borderRight: `2px solid ${theme.palette.divider}`
  },
  downloadIcon: {
    color: "gray",
    cursor: 'pointer',
    padding: '0px 20px'
  },
  deleteIcon: {
    color: "red",
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: '0px 20px',
    cursor: 'pointer',
    marginLeft: '20px'
  },
  collectionsLeft: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '.6rem',
  },
  addBtn: {
    padding: 0,
    marginLeft: '.6rem',
    borderRadius: '1rem',
    paddingLeft: '.2rem',
    paddingRight: '.2rem',
    backgroundColor: theme.background.main,
    color: theme.palette.text.primary
  },
  topActions: {
    display: 'flex',
    alignItems: 'center'
  },
  scrollBtns: {
    '&.Mui-disabled': {
      display: 'none'
    },
    '& .MuiSvgIcon-root': {
      fill: 'black',
      marginBottom: '1rem'
    }
  }
}));
