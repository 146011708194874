import React, { useState, useEffect } from 'react';
import { Popover, Typography, Divider, Box, makeStyles, Theme, Paper } from '@material-ui/core';
import { Dialog } from '../../../../components/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetNonPatientsProps, sendInviteToUser, cancelInviteToUser, deleteUsersThunk,
  cancelInviteToSiteUser, sendInviteToSiteUser
} from '../../../../redux/thunk/thunks';
import { HeadCell, LastSorted, Pagination, RowCell, Table } from '../../../../components/Table';
import { MoreHoriz, Close } from '@material-ui/icons';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { UserSearchResults, UuidType } from '../../../../shared/domain';
import { concatName } from '../../../../shared/utils';
import { RootState } from '../../../../redux/redux-store';
import { ROLES } from '../../../../shared/globals';

interface AccountUsersTableProps {
  usersData: UserSearchResults | null;
  loadUsers: (getNonPatientProps: GetNonPatientsProps) => void;
  setSelectedForms?: (selectedForms: UuidType[]) => void;
  viewAccountsOpen: (info: any, isOpen: boolean) => void;
}

export const AccountUsersTable = ({
  usersData,
  loadUsers,
  setSelectedForms,
  viewAccountsOpen
}: AccountUsersTableProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null)
  const [lastSorted, setLastSorted] = useState<LastSorted>({
    column: 'name',
    order: 'desc'
  });
  const [popuerInfo, setPopuerInfo] = useState<any>(null);
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);

  const refActions = (event: any, userInfo: any) => {
    setAnchorEl(event.currentTarget);
    setPopuerInfo(userInfo)
  }

  const deleteUserLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.deleteUserLoading
  );
  const deleteUserSuccess = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.deleteUserSuccess
  );

  useEffect(() => {
    if (deleteUserSuccess) {
      setIsDelete(false);
      setPopuerInfo(null);
    }
  }, [deleteUserSuccess]);

  const handleClose = () => {
    setAnchorEl(null)
  }

  const headCells: HeadCell[] = [
    {
      id: 'first_name',
      label: 'NAME'
    },
    {
      id: 'role',
      label: 'ROLE'
    },
    {
      id: 'status',
      label: 'STATUS'
    },
    {
      id: 'last_login',
      label: 'LAST LOGIN/INVITE'
    },
    {
      align: 'right',
      id: 'action',
      label: '',
      isSortDisabled: true
    }
  ];

  const rowCells = usersData?.users.map((user: any) => {
    const TitleLink = (
      // <Link className={classes.titleLink} to={`/form-builder/${form.id}/fields`}>
      <Typography color="primary" variant="h5" style={{ cursor: 'pointer' }} onClick={() => viewAccountsOpen(user, false)}>
        {concatName({
          firstName: user.first_name,
          lastName: user.last_name,
          fallback: user.email ?? '<No Name Available>'
        })}
      </Typography>
      // </Link>
    );
    var options: any = { weekday: 'long' };
    const rowCell: RowCell = {
      first_name: {
        component: TitleLink,
        value: concatName({
          firstName: user.first_name,
          lastName: user.last_name,
          fallback: user.email ?? '<No Name Available>'
        })
      },
      role: {
        value: user.role.replace(/_/g, " ")
      },
      status: {
        value: user.status ? user.status.replace(/_/g, " ") : '-'
      },
      last_login: {
        value: user.last_login ? new Intl.DateTimeFormat('en-US', options).format(new Date(user.last_login)) + ', ' + (("0" + (new Date(user.last_login).getMonth() + 1)).slice(-2)) + '/' + ("0" + new Date(user.last_login).getDate()).slice(-2) + '/' + new Date(user.last_login).getFullYear() : '-'
      },
      action: {
        align: 'right',
        component: <><MoreHoriz className={classes.launchIcon} onClick={(e) => refActions(e, user)} />
          <Popover className='paper'
            id={'simple-popover'}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Paper style={{ boxShadow: 'unset' }}>
              <Typography className={classes.actionTypography} onClick={(e) => viewAccountsOpen(popuerInfo, true)}>Edit User</Typography>
              {/* <Divider />
              <Typography className={classes.actionTypography}>Cancel Invite</Typography> */}
              <Divider />
              {popuerInfo && popuerInfo.status === "AWAITING_ACCEPTANCE" && <Typography className={classes.actionTypography} onClick={(e) => cancelInvite(popuerInfo)}>Cancel Invite</Typography>}
              {popuerInfo && (popuerInfo.status === "INVITE_NOT_SENT" || popuerInfo.status === "INVITE_EXPIRED" || popuerInfo.status === "INVITE_CANCELLED") &&
                <Typography className={classes.actionTypography} onClick={(e) => sendInvite(popuerInfo)}>Send Invite</Typography>}
              <Divider />
              <Typography color='error' className={classes.actionTypography} onClick={(e) => deleteAccount(popuerInfo)}>Delete User</Typography>
            </Paper>
          </Popover>
        </>
      }
    };

    return Object.assign(rowCell, { content: user });
  });

  const deleteAccount = (user: any) => {
    setIsDelete(true)
    setAnchorEl(null)
  }
  const sendInvite = (user: any) => {
    if (loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role)) {
      dispatch(sendInviteToSiteUser(user.user_role_id, user.role));
    } else {
      dispatch(sendInviteToUser(user.user_role_id));
    }
    setIsOpen(true)
    setAnchorEl(null)
  }
  const cancelInvite = (user: any) => {
    if (loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role)) {
      dispatch(cancelInviteToSiteUser(user.user_role_id, user.role));
    } else {
      dispatch(cancelInviteToUser(user.user_role_id));
    }
    setAnchorEl(null)
  }

  const handleCloseDialog = () => {
    setIsOpen(false)
  }

  const handleNextButton = async () => {
    if (
      usersData &&
      usersData.page >= 0 &&
      usersData.page < usersData.total / usersData.pageSize - 1
    ) {
      await loadUsers({
        usersType: 'accountUsers',
        page: usersData.page + 1,
        pageSize: usersData.pageSize,
        search: usersData.search,
        sort: usersData.sort,
        order: usersData.order
      });
    }
  };
  const handlePrevButton = async () => {
    if (usersData && usersData.page > 0) {
      await loadUsers({
        usersType: 'accountUsers',
        page: usersData.page - 1,
        pageSize: usersData.pageSize,
        search: usersData.search,
        sort: usersData.sort,
        order: usersData.order
      });
    }
  };

  const handlePageSize = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    const pageSize: number = e.target.value as number;

    if (usersData && pageSize > 0) {
      loadUsers({
        usersType: 'accountUsers',
        page: 0,
        pageSize,
        search: usersData.search,
        sort: usersData.sort,
        order: usersData.order
      });
    }
  };

  const pagination: Pagination | undefined = usersData
    ? {
      contentName: 'Account Users',
      page: usersData.page,
      pageSize: usersData.pageSize,
      total: usersData.total,
      handleNextButton: handleNextButton,
      handlePageSize: handlePageSize,
      handlePrevButton: handlePrevButton
    }
    : undefined;

  const handleSort = (sort: string) => {
    if (usersData) {
      const nextDirection = lastSorted.order === 'desc' ? 'asc' : 'desc';

      loadUsers({
        usersType: 'accountUsers',
        page: 0,
        pageSize: usersData.pageSize,
        search: usersData.search,
        sort,
        order: sort === lastSorted.column ? nextDirection : 'desc'
      });

      setLastSorted(
        sort === lastSorted.column
          ? { column: sort, order: nextDirection }
          : { column: sort, order: 'desc' }
      );
    }
  };

  const handleDeleteUser = () => {
    if (popuerInfo) {
      dispatch(deleteUsersThunk(popuerInfo.id, null));
    }
  };

  return (
    <>
      <Table
        data={usersData?.users}
        headCells={headCells}
        loading={false}
        pagination={pagination}
        rowCells={rowCells}
        // selection={
        //   selectedForms && setSelectedForms
        //     ? {
        //       selectedContents: selectedForms,
        //       setSelectedContents: (selected: UuidType[]) => setSelectedForms(selected)
        //     }
        //     : undefined
        // }
        sort={{
          lastSorted: lastSorted,
          handleSort: handleSort
        }}
      />
      {isOpen &&
        <Dialog handleClose={handleCloseDialog} open={isOpen}>
          <Box style={{ width: '400px' }}>
            <Box pb={3} pt={2} px={4} display="flex" justifyContent="space-between">
              <Typography color="textSecondary" variant="h2">Invitation Success</Typography>
              <Close onClick={() => setIsOpen(false)} />
            </Box>
            <Box pb={6} pt={2} display="flex" justifyContent="center">
              <Typography color="textSecondary" variant="h2">Invitation Sent</Typography>
            </Box>
          </Box>
        </Dialog>
      }
      {isDelete &&
        <ConfirmationDialog
          loading={deleteUserLoading}
          message={`Are you sure you want to delete? Please type "DELETE" to confirm.`}
          open={isDelete}
          title=""
          handleClose={() => setIsDelete(false)}
          handleConfirmClick={handleDeleteUser}
        />
      }
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  nameLink: {
    textDecoration: 'none'
  },
  referralName: {
    display: 'flex'
  },
  launchIcon: {
    fontSize: '2rem',
    cursor: 'pointer'
  },
  InfoIcon: {
    fontSize: 16,
    marginLeft: 6
  },
  color: {
    color: '#0060B2'
  },
  tableBox: {
    padding: '2px 8px',
    marginRight: '5px',
    minWidth: '80px',
    background: '#f1f5f9',
    borderRadius: '10px'
  },
  actionTypography: {
    padding: '10px',
    fontWeight: 'bold',
    cursor: 'pointer'
  },
}));
