import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Menu,
  MenuItem,
  Paper,
  Theme,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Link, Redirect, useParams, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AddFormsToCollectionDialog } from './AddFormsToCollectionDialog';
import { AssignToCollectionDialog } from './AssignToCollectionDialog';
import { CardHeader } from '../../../../components/LegacyCardHeader';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { DuplicateCustomFormDialog } from '../../../Forms/components/DuplicateCustomFormDialog';
import { EditCollectionDialog } from './EditCollectionDialog';
import { FormsTable } from '../../../Forms/components/FormsTable';
import {
  GetFormsProps,
  assignCollectionToFormsThunk,
  deleteCollectionThunk,
  deleteFormsThunk,
  getCollectionAssignmentsThunk,
  getCollectionThunk,
  getFormsThunk,
  unassignCollectionFromFormsThunk,
  unassignUnitFromCollectionThunk,
  unassignUserFromCollectionThunk
} from '../../../../redux/thunk/thunks';
import { LoadingOrError } from '../../../../components/LoadingOrError';
import { NewFormDialog } from '../../../Forms/components/NewFormDialog';
import { RootState } from '../../../../redux/redux-store';
import { Unit, User, UuidType } from '../../../../shared/domain';
import { colors } from '../../../../styling/colors';
import { setSuccessAction } from '../../../../redux/display/display-actions';
import { concatName } from '../../../../shared/utils';

type AssignmentType = 'unit' | 'user';
interface Assignment {
  abbr: string;
  id: UuidType;
  name: string;
  type: AssignmentType;
}

interface CollectionDetailParams {
  collectionId: UuidType;
}

export const CollectionDetail = withRouter(() => {
  const { collectionId } = useParams<CollectionDetailParams>();

  const classes = useStyles();
  const dispatch = useDispatch();

  const collectionFormsData = useSelector(
    (state: RootState) => state.DATA_REDUCER.formSearchResults.collection
  );
  const collectionData = useSelector((state: RootState) => state.DATA_REDUCER.collection);
  const collectionAssignments = useSelector(
    (state: RootState) => state.DATA_REDUCER.collectionAssignments
  );
  const unassignCollectionFromFormsLoading = useSelector(
    (state: RootState) =>
      state.DISPLAY_STATE_REDUCER.displayLoading.unassignCollectionFromFormsLoading
  );
  const deleteFormsLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.deleteFormsLoading
  );
  const unassignUnitFromCollectionLoading = useSelector(
    (state: RootState) =>
      state.DISPLAY_STATE_REDUCER.displayLoading.unassignUnitFromCollectionLoading
  );
  const unassignUserFromCollectionLoading = useSelector(
    (state: RootState) =>
      state.DISPLAY_STATE_REDUCER.displayLoading.unassignUserFromCollectionLoading
  );
  const updateCollectionsSuccess = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.updateCollectionsSuccess
  );
  const updateFormsSuccess = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.updateFormsSuccess
  );
  const assignCollectionToFormsSuccess = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.assignCollectionToFormsSuccess
  );
  const unassignCollectionFromFormsSuccess = useSelector(
    (state: RootState) =>
      state.DISPLAY_STATE_REDUCER.displaySuccess['unassignCollectionFromFormsSuccess']
  );
  const deleteFormsSuccess = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.deleteFormsSuccess
  );
  const deleteCollectionLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.deleteCollectionSuccess
  );
  const deleteCollectionSuccess = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.deleteCollectionSuccess
  );
  const assignCollectionSuccess = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.assignCollectionSuccess
  );
  const unassignUnitFromCollectionSuccess = useSelector(
    (state: RootState) =>
      state.DISPLAY_STATE_REDUCER.displaySuccess.unassignUnitFromCollectionSuccess
  );
  const unassignUserFromCollectionSuccess = useSelector(
    (state: RootState) =>
      state.DISPLAY_STATE_REDUCER.displaySuccess.unassignUserFromCollectionSuccess
  );

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [menuContent, setMenuContent] = useState<Assignment | null>(null);
  const [redirect, setRedirect] = useState(false);
  const [selectedCollectionForms, setSelectedCollectionForms] = useState<UuidType[]>([]);

  const [addFormsToCollectionDialogOpen, setAddFormsToCollectionDialogOpen] = useState(false);
  const [
    deleteCollectionConfirmationDialogOpen,
    setDeleteCollectionConfirmationDialogOpen
  ] = useState(false);
  const [deleteFormsConfirmationDialogOpen, setDeleteFormsConfirmationDialogOpen] = useState(false);
  const [
    unassignUnitFromCollectionConfirmationDialogOpen,
    setUnassignUnitFromCollectionConfirmationDialogOpen
  ] = useState(false);
  const [
    unassignUserFromCollectionConfirmationDialogOpen,
    setUnassignUserFromCollectionConfirmationDialogOpen
  ] = useState(false);
  const [editCollectionDialogOpen, setEditCollectionDialogOpen] = useState(false);
  const [newFormDialogOpen, setNewFormDialogOpen] = useState(false);
  const [assignToCollectionDialogOpen, setAssignToCollectionDialogOpen] = useState(false);
  const [duplicateCustomFormDialogOpen, setDuplicateCustomFormDialogOpen] = useState(false);
  const [duplicateLibraryFormDialogOpen, setDuplicateLibraryFormDialogOpen] = useState(false);

  const library = collectionData?.is_library;

  // condenses users and units into a single array of type Assignment
  const assignments: Assignment[] = (
    collectionAssignments?.units.map((unit: Unit) => ({
      abbr: (
        (unit.name?.split(' ')[0]?.charAt(0) ?? '') + (unit.name?.split(' ')[1]?.charAt(0) ?? '')
      ).toUpperCase(),
      id: unit.id,
      name: unit.name,
      type: 'unit' as AssignmentType
    })) ?? []
  ).concat(
    collectionAssignments?.users.map((user: User) => {
      const name = concatName({
        firstName: user.first_name,
        lastName: user.last_name,
        fallback: user.email ?? '<No Patient Name Available>'
      });
      return {
        abbr: (
          (name.split(' ')[0]?.charAt(0) ?? '') + (name.split(' ')[1]?.charAt(0) ?? '')
        ).toUpperCase(),
        id: user.id,
        name,
        type: 'user' as AssignmentType
      };
    }) ?? []
  );

  const loadForms = async (getFormsProps: GetFormsProps) => {
    await dispatch(getFormsThunk(getFormsProps));
  };

  const handleAssignForms = (selectedForms: UuidType[]) => {
    dispatch(assignCollectionToFormsThunk(collectionId, selectedForms));
  };
  const handleUnassignForms = () => {
    dispatch(unassignCollectionFromFormsThunk(collectionId, selectedCollectionForms));
  };

  const handleDeleteForms = () => {
    dispatch(deleteFormsThunk(selectedCollectionForms));
  };

  const handleDeleteCollection = () => {
    dispatch(deleteCollectionThunk(collectionId));
  };

  const handleMenuClick = (e: React.MouseEvent<HTMLElement>, content: Assignment) => {
    setAnchorEl(e.currentTarget);
    setMenuContent(content);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleMenuRemoveClick = () => {
    if (menuContent) {
      if (menuContent.type === 'unit') {
        setUnassignUnitFromCollectionConfirmationDialogOpen(true);
      } else {
        setUnassignUserFromCollectionConfirmationDialogOpen(true);
      }
    }

    setAnchorEl(null);
  };
  const handleUnassignUnit = () => {
    if (menuContent && menuContent.type === 'unit') {
      dispatch(unassignUnitFromCollectionThunk(collectionId, menuContent.id));
    }
  };
  const handleUnassignUser = () => {
    if (menuContent && menuContent.type === 'user') {
      dispatch(unassignUserFromCollectionThunk(collectionId, menuContent.id));
    }
  };

  const handleAddFormsToCollectionDialogOpen = () => {
    setAddFormsToCollectionDialogOpen(true);
  };
  const handleAddFormsToCollectionDialogClose = () => {
    setAddFormsToCollectionDialogOpen(false);
  };
  const handleDeleteCollectionConfirmationDialogOpen = () => {
    setDeleteCollectionConfirmationDialogOpen(true);
  };
  const handleDeleteCollectionConfirmationDialogClose = () => {
    setDeleteCollectionConfirmationDialogOpen(false);
  };
  const handleDeleteFormsConfirmationDialogOpen = () => {
    setDeleteFormsConfirmationDialogOpen(true);
  };
  const handleDeleteFormsConfirmationDialogClose = () => {
    setDeleteFormsConfirmationDialogOpen(false);
  };
  const handleUnassignUnitFromCollectionConfirmationDialogOpen = () => {
    setUnassignUnitFromCollectionConfirmationDialogOpen(true);
  };
  const handleUnassignUnitFromCollectionConfirmationDialogClose = () => {
    setUnassignUnitFromCollectionConfirmationDialogOpen(false);
  };
  const handleUnassignUserFromCollectionConfirmationDialogOpen = () => {
    setUnassignUserFromCollectionConfirmationDialogOpen(true);
  };
  const handleUnassignUserFromCollectionConfirmationDialogClose = () => {
    setUnassignUserFromCollectionConfirmationDialogOpen(false);
  };
  const handleEditCollectionDialogOpen = () => {
    setEditCollectionDialogOpen(true);
  };
  const handleEditCollectionDialogClose = () => {
    setEditCollectionDialogOpen(false);
  };
  const handleAssignToCollectionDialogOpen = () => {
    setAssignToCollectionDialogOpen(true);
  };
  const handleAssignToCollectionDialogClose = () => {
    setAssignToCollectionDialogOpen(false);
  };
  const handleNewFormDialogOpen = () => {
    setNewFormDialogOpen(true);
  };
  const handleNewFormDialogClose = () => {
    setNewFormDialogOpen(false);
  };
  const handleDisplayDuplicateCustomFormDialogOpen = () => {
    setNewFormDialogOpen(false);
    setDuplicateCustomFormDialogOpen(true);
  };
  const handleDisplayDuplicateCustomFormDialogClose = () => {
    setDuplicateCustomFormDialogOpen(false);
  };
  const handleDisplayDuplicateLibraryFormDialogOpen = () => {
    setNewFormDialogOpen(false);
    setDuplicateLibraryFormDialogOpen(true);
  };
  const handleDisplayDuplicateLibraryFormDialogClose = () => {
    setDuplicateLibraryFormDialogOpen(false);
  };

  const handleCollectionFormsSearch = (value: string) => {
    if (collectionFormsData) {
      dispatch(
        getFormsThunk({
          collectionId,
          formsType: 'collection',
          library,
          page: 0,
          pageSize: collectionFormsData.pageSize,
          search: value
        })
      );
    }
  };

  const CollectionForms = (
    <Box pb={4} px={4}>
      <FormsTable
        collectionId={collectionId}
        library={library}
        formsData={collectionFormsData}
        formsType="collection"
        selectedForms={selectedCollectionForms}
        loadForms={loadForms}
        setSelectedForms={(selectedForms: UuidType[]) => setSelectedCollectionForms(selectedForms)}
      />
    </Box>
  );

  useEffect(() => {
    if (assignCollectionToFormsSuccess && updateCollectionsSuccess && updateFormsSuccess) {
      dispatch(setSuccessAction('assignCollectionToFormsSuccess', false));
      dispatch(setSuccessAction('updateCollectionsSuccess', false));
      dispatch(setSuccessAction('updateFormsSuccess', false));

      setSelectedCollectionForms([]);
      setAddFormsToCollectionDialogOpen(false);
    }
  }, [assignCollectionToFormsSuccess, updateCollectionsSuccess, updateFormsSuccess]);

  useEffect(() => {
    if (unassignCollectionFromFormsSuccess && updateCollectionsSuccess && updateFormsSuccess) {
      dispatch(setSuccessAction('unassignCollectionFromFormsSuccess', false));
      dispatch(setSuccessAction('updateCollectionsSuccess', false));
      dispatch(setSuccessAction('updateFormsSuccess', false));

      setSelectedCollectionForms([]);
    }
  }, [unassignCollectionFromFormsSuccess, updateCollectionsSuccess, updateFormsSuccess]);

  useEffect(() => {
    if (deleteFormsSuccess && updateCollectionsSuccess && updateFormsSuccess) {
      dispatch(setSuccessAction('deleteFormsSuccess', false));
      dispatch(setSuccessAction('updateCollectionsSuccess', false));
      dispatch(setSuccessAction('updateFormsSuccess', false));

      setSelectedCollectionForms([]);
      setDeleteFormsConfirmationDialogOpen(false);
    }
  }, [deleteFormsSuccess, updateCollectionsSuccess, updateFormsSuccess]);

  useEffect(() => {
    if (deleteCollectionSuccess && updateCollectionsSuccess && updateFormsSuccess) {
      dispatch(setSuccessAction('deleteCollectionSuccess', false));
      dispatch(setSuccessAction('updateCollectionsSuccess', false));
      dispatch(setSuccessAction('updateFormsSuccess', false));

      setDeleteCollectionConfirmationDialogOpen(false);
      setRedirect(true);
    }
  }, [deleteCollectionSuccess, updateCollectionsSuccess, updateFormsSuccess]);

  useEffect(() => {
    if (assignCollectionSuccess && updateCollectionsSuccess) {
      dispatch(setSuccessAction('assignCollectionSuccess', false));
      dispatch(setSuccessAction('updateCollectionsSuccess', false));

      setAssignToCollectionDialogOpen(false);
    }
  }, [assignCollectionSuccess, updateCollectionsSuccess]);

  useEffect(() => {
    if (unassignUnitFromCollectionSuccess) {
      dispatch(setSuccessAction('unassignUnitFromCollectionSuccess', false));

      setUnassignUnitFromCollectionConfirmationDialogOpen(false);
    }
  }, [unassignUnitFromCollectionSuccess]);

  useEffect(() => {
    if (unassignUserFromCollectionSuccess && updateCollectionsSuccess) {
      dispatch(setSuccessAction('unassignUserFromCollectionSuccess', false));
      dispatch(setSuccessAction('updateCollectionsSuccess', false));

      setUnassignUserFromCollectionConfirmationDialogOpen(false);
    }
  }, [unassignUserFromCollectionSuccess, updateCollectionsSuccess]);

  useEffect(() => {
    if (collectionData === null || (collectionData && collectionData.id !== collectionId)) {
      dispatch(getCollectionThunk(collectionId));
      dispatch(getCollectionAssignmentsThunk(collectionId));
    }
  }, [collectionData, collectionId]);

  useEffect(() => {
    dispatch(getFormsThunk({ collectionId, formsType: 'collection', library }));
  }, []);

  return !redirect ? (
    collectionData &&
    collectionData.id === collectionId &&
    collectionFormsData &&
    collectionFormsData.collectionId === collectionId ? (
      <>
        {addFormsToCollectionDialogOpen && (
          <AddFormsToCollectionDialog
            open={addFormsToCollectionDialogOpen}
            library={library}
            handleAssignForms={handleAssignForms}
            handleClose={handleAddFormsToCollectionDialogClose}
          />
        )}

        {deleteFormsConfirmationDialogOpen &&
          collectionFormsData &&
          selectedCollectionForms.length > 0 && (
            <ConfirmationDialog
              loading={deleteFormsLoading}
              message="Are you sure you want to delete the selected forms?"
              open={deleteFormsConfirmationDialogOpen}
              title="Delete Form(s)"
              handleClose={handleDeleteFormsConfirmationDialogClose}
              handleConfirmClick={handleDeleteForms}
            />
          )}

        {deleteCollectionConfirmationDialogOpen && (
          <ConfirmationDialog
            loading={deleteCollectionLoading}
            message={`Are you sure you want to delete this ${library ? 'library' : 'collection'}?`}
            open={deleteCollectionConfirmationDialogOpen}
            title={`Delete ${library ? 'Library' : 'Collection'}`}
            handleClose={handleDeleteCollectionConfirmationDialogClose}
            handleConfirmClick={handleDeleteCollection}
          />
        )}

        {editCollectionDialogOpen && (
          <EditCollectionDialog
            collection={collectionData}
            library={library}
            open={editCollectionDialogOpen}
            handleClose={handleEditCollectionDialogClose}
          />
        )}

        {assignToCollectionDialogOpen && (
          <AssignToCollectionDialog
            collectionId={collectionId}
            library={library}
            open={assignToCollectionDialogOpen}
            handleClose={handleAssignToCollectionDialogClose}
          />
        )}

        {unassignUnitFromCollectionConfirmationDialogOpen && (
          <ConfirmationDialog
            loading={unassignUnitFromCollectionLoading}
            message={`Are you sure you want to unassign this unit from the ${
              library ? 'library' : 'collection'
            }?`}
            open={unassignUnitFromCollectionConfirmationDialogOpen}
            title="Unassign Unit"
            handleClose={handleUnassignUnitFromCollectionConfirmationDialogClose}
            handleConfirmClick={handleUnassignUnit}
          />
        )}

        {unassignUserFromCollectionConfirmationDialogOpen && (
          <ConfirmationDialog
            loading={unassignUserFromCollectionLoading}
            message={`Are you sure you want to unassign this user from the ${
              library ? 'library' : 'collection'
            }?`}
            open={unassignUserFromCollectionConfirmationDialogOpen}
            title="Unassign User"
            handleClose={handleUnassignUserFromCollectionConfirmationDialogClose}
            handleConfirmClick={handleUnassignUser}
          />
        )}

        {/* todo: pass collection into form builder */}
        {newFormDialogOpen && (
          <NewFormDialog
            open={newFormDialogOpen}
            handleClose={handleNewFormDialogClose}
            handleDisplayDuplicateCustomFormDialog={handleDisplayDuplicateCustomFormDialogOpen}
            handleDisplayDuplicateLibraryFormDialog={handleDisplayDuplicateLibraryFormDialogOpen}
          />
        )}

        {duplicateCustomFormDialogOpen && (
          <DuplicateCustomFormDialog
            open={duplicateCustomFormDialogOpen}
            handleClose={handleDisplayDuplicateCustomFormDialogClose}
          />
        )}

        {duplicateLibraryFormDialogOpen && (
          <DuplicateCustomFormDialog
            library={true}
            open={duplicateLibraryFormDialogOpen}
            handleClose={handleDisplayDuplicateLibraryFormDialogClose}
          />
        )}

        <Paper>
          <Box display="flex" flexDirection="column">
            {collectionData.id === collectionId ? (
              <>
                <Box alignItems="center" display="flex" pl={4}>
                  <Link className={classes.backArrowLink} to="/collections">
                    <Box alignItems="center" display="flex" pr={2}>
                      <ArrowBackIcon className={classes.backArrowIcon} />
                    </Box>
                  </Link>

                  <Box alignSelf="stretch" display="flex" py={3}>
                    <Divider flexItem orientation="vertical" />
                  </Box>

                  <Box width="100%">
                    <CardHeader
                      tabComponents={[
                        {
                          label: 'header',
                          primaryButton: {
                            text: `Delete ${library ? 'Library' : 'Collection'}`,
                            handleClick: handleDeleteCollectionConfirmationDialogOpen
                          },
                          secondaryButton: {
                            text: `Edit ${library ? 'Library' : 'Collection'}`,
                            handleClick: handleEditCollectionDialogOpen
                          },
                          title: collectionData.name,
                          style: {
                            spacing: { pl: 2, pr: 4, py: 3 }
                          },
                          subtitle: library ? 'LIBRARY' : 'COLLECTION'
                        }
                      ]}
                    />
                  </Box>
                </Box>

                <Box px={4}>
                  <Divider />
                </Box>

                <Box display="flex" flexDirection="column">
                  <CardHeader
                    tabComponents={[
                      {
                        label: 'header',
                        primaryButton: {
                          text: 'Assign Users',
                          handleClick: handleAssignToCollectionDialogOpen
                        },
                        title: collectionData.name,
                        style: {
                          spacing: { px: 4, pt: 3 }
                        },
                        subtitle: 'USERS ASSIGNED TO'
                      }
                    ]}
                  />

                  <Box display="flex" flexWrap="wrap" px={4} py={2}>
                    {assignments.map((assignment: Assignment) => (
                      <Box
                        alignItems="center"
                        display="flex"
                        key={`unit-${assignment.id}`}
                        mb={1}
                        mr={3}
                      >
                        <Box
                          alignItems="center"
                          className={classes.userIcon}
                          display="flex"
                          justifyContent="center"
                        >
                          <Typography className={classes.userIconText}>
                            {assignment.abbr}
                          </Typography>
                        </Box>

                        <Box ml={1} mr={0.5}>
                          <Typography color="textSecondary" variant="subtitle2">
                            {assignment.name}
                          </Typography>
                        </Box>

                        <Box
                          alignItems="center"
                          display="flex"
                          onClick={(e) => handleMenuClick(e, assignment)}
                        >
                          <MoreHorizIcon />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>

                <Box px={4}>
                  <Divider />
                </Box>

                <Box display="flex" flexDirection="column">
                  <CardHeader
                    tabComponents={[
                      {
                        component: CollectionForms,
                        label: 'Collection Forms',
                        primaryButton: {
                          text: 'Create New',
                          handleClick: handleNewFormDialogOpen
                        },
                        secondaryButton: {
                          text: 'Add Existing',
                          handleClick: handleAddFormsToCollectionDialogOpen
                        },
                        selected: {
                          primaryButton: {
                            loading: unassignCollectionFromFormsLoading,
                            text: `Remove from ${library ? 'library' : 'collection'}`,
                            handleClick: handleUnassignForms
                          },
                          selectedContents: selectedCollectionForms,
                          handleSelectedDelete: handleDeleteFormsConfirmationDialogOpen
                        },
                        style: {
                          spacing: { px: 4, py: 3 }
                        },
                        title: collectionData.name,
                        subtitle: 'FORMS IN',
                        handleSearch: handleCollectionFormsSearch
                      }
                    ]}
                  />
                </Box>
              </>
            ) : (
              <LoadingOrError errorMsg={null} loading={true} loadingMsg="" noElevation />
            )}
          </Box>
        </Paper>

        {Boolean(anchorEl) && (
          <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
            <MenuItem onClick={handleMenuRemoveClick}>Remove</MenuItem>
          </Menu>
        )}
      </>
    ) : (
      <Paper>
        <LoadingOrError loadingMsg="" loading errorMsg={null} noElevation />
      </Paper>
    )
  ) : (
    <Redirect to="/forms" />
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  backArrowLink: {
    color: colors.text3,
    textDecoration: 'none'
  },
  backArrowIcon: {
    fontSize: '1.5rem'
  },
  userIcon: {
    borderRadius: '0.375rem',
    backgroundColor: colors.background5,
    height: '1.625rem',
    width: '1.625rem'
  },
  userIconText: {
    color: theme.palette.secondary.main,
    fontSize: 10,
    fontWeight: 600,
    textShadow: '0 1px 1px rgba(0, 0, 0, 0.25)'
  }
}));
