import { Box, makeStyles, Theme, Typography, TextField, Grid, FormControl, Select, MenuItem } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { RootState } from '../../../redux/redux-store';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from '../../../components/Dialog';
import { LoadingButton } from '../../../components/LoadingButton';
import { createReferralThunk, updateReferralThunk } from '../../../redux/thunk/referral-thunks';
import { setEditReferralAction, getReferralInfoAction } from '../../../redux/data/data-actions';

interface NewReferralDialogProps {
  open: boolean;
  handleClose: () => void;
  info: any;
  edit: boolean
}

interface appointmentFormValues {
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  about: string;
}

export const NewReferralDialog = ({ open, handleClose, info, edit }: NewReferralDialogProps) => {
  const classes = useStyles();
  const [isError, setIsError] = useState(false);
  const [inputs, setInputs] = useState(null);
  const [isSave, setIsSave] = useState(false);
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState<appointmentFormValues>({
    lastName: '',
    firstName: '',
    email: '',
    title: '',
    about: ''
  });
  const loginUser: any = localStorage.getItem('formFlash')
  const referralId = JSON.parse(loginUser);

  const editReferral: any = useSelector(
    (state: RootState) => referralId.role === 'REFERRAL' ? state.DATA_REDUCER.referralInfo : state.DATA_REDUCER.editReferral
  );

  const message = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.snackbarAlertMessage
  );

  useEffect(() => {
    if (edit) {
      setInitialValues({
        lastName: editReferral && editReferral.last_name,
        firstName: editReferral && editReferral.first_name,
        email: editReferral && editReferral.email,
        title: editReferral && editReferral.title ? editReferral.title : '',
        about: editReferral && editReferral.about ? editReferral.about : ''
      });
    }
  }, [edit])

  useEffect(() => {
    if (message && message.length > 0) {
      if (message == 'Error creating referral: Internal Server Error') {
        setIsSave(false);
      } else {
        setIsSave(false);
        handleClose();
      }
    }
  }, [message]);

  const submitReferral = async (values: appointmentFormValues) => {
    setIsSave(true);
    let value = {
      firstName: values.firstName,
      email: values.email,
      lastName: values.lastName,
    }
    if (edit) {
      if (referralId.role === 'REFERRAL' && editReferral && editReferral.specialities) {
        const specialities: any = [];
        for (let i = 0; i < editReferral.specialities.length; i++) {
          specialities.push(editReferral.specialities[i]?.id ? editReferral.specialities[i].id : editReferral.specialities[i]);
        }
        editReferral.specialities = specialities;
      } else if (editReferral && editReferral.specialities) {
        const specialities: any = [];
        for (let i = 0; i < editReferral.specialities.length; i++) {
          specialities.push(editReferral.specialities[i]?.id ? editReferral.specialities[i].id : editReferral.specialities[i]);
        }
        editReferral.specialities = specialities;
      }
    }
    edit && (editReferral.first_name = values.firstName);
    edit && (editReferral.last_name = values.lastName);
    edit && (editReferral.title = values.title);
    edit && (editReferral.referral_id = referralId.role === 'REFERRAL' ? editReferral.id : editReferral.referral_id);
    edit && info.about == 'about' && (editReferral.about = values.about);
    edit && referralId.role === 'REFERRAL' ? await dispatch(getReferralInfoAction(editReferral)) : await dispatch(setEditReferralAction(editReferral));
    edit ? handleClose() : await dispatch(createReferralThunk(value));
    setIsSave(false);
  };

  const referralInfoSchema = Yup.object().shape({
    firstName: Yup.string().required('Required').max(50, ''),
    lastName: Yup.string().required('Required').max(50, ''),
    email: Yup.string().email('Email is invalid').required('Required'),
    title: Yup.string().max(20, ''),
    about: Yup.string().max(2000, ''),
  });

  const handleChange = (value: any, no: any) => {
    if (no === 0 && value.length > 50) {
      setIsError(true); setInputs(no);
    } else if (no === 1 && value.length > 50) {
      setIsError(true); setInputs(no);
    } else if (no === 2 && value.length > 20) {
      setIsError(true); setInputs(no);
    } else if (no === 3 && value.length > 2000) {
      setIsError(true); setInputs(no);
    } else {
      setIsError(false); setInputs(no);
    }
  }

  return (
    <Dialog handleClose={handleClose} open={open}>
      <Box display="flex" flexDirection="column" className={classes.box}>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={submitReferral}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={referralInfoSchema}
        >
          {({ errors, isSubmitting, setSubmitting, touched, values }) => {

            return (
              <Form>
                <Box pb={4} className={classes.topBox}>
                  <Box pb={0.5} p={3}>
                    <Typography color="textSecondary" variant="h2">
                      {edit ? (info.about == 'about' ? 'About' : 'Name & Title') : 'Add a New Referral'}
                    </Typography>
                  </Box>
                </Box>
                {edit && info.about == 'about' ?
                  <Box p={3}>
                    <Box pb={1}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              About
                            </Typography>
                          </Box>

                          <Field fullWidth multiline rows={8}
                            onInput={(e: any) => handleChange(e.target.value, 3)}
                            inputProps={{ maxLength: 2001 }}
                            as={TextField} style={{ background: isError && inputs === 3 ? '#fff1f1' : 'unset' }}
                            className={classes.textField}
                            name="about"
                            size="small"
                            type=""
                            variant="outlined"
                          />
                          <Box display='flex' justifyContent='space-between'>
                            <Typography color={"error"} variant="subtitle2">
                              {''}
                            </Typography>
                            <Typography style={{ color: values.about?.length > 2000 ? '#911717' : '#0000008a' }} variant="subtitle2">
                              {values.about?.length === 2001 ? 2000 : values.about?.length}/2000
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box> :
                  <Box p={3}>
                    <Box pb={1}>
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              First Name*
                            </Typography>
                          </Box>

                          <Field style={{ background: isError && inputs === 0 ? '#fff1f1' : 'unset' }}
                            onInput={(e: any) => handleChange(e.target.value, 0)}
                            as={TextField}
                            inputProps={{ maxLength: 51 }}
                            className={classes.textField}
                            name="firstName"
                            size="small"
                            type=""
                            variant="outlined"
                          />
                          <Box display='flex' justifyContent='space-between'>
                            <Typography color={"error"} variant="subtitle2">
                              {errors.firstName && touched.firstName && errors.firstName}
                            </Typography>
                            <Typography style={{ color: values.firstName?.length > 50 ? '#911717' : '#0000008a' }} variant="subtitle2">
                              {values.firstName?.length === 51 ? 50 : values.firstName?.length}/50
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              Last Name*
                            </Typography>
                          </Box>

                          <Field
                            style={{ background: isError && inputs === 1 ? '#fff1f1' : 'unset' }}
                            onInput={(e: any) => handleChange(e.target.value, 1)}
                            as={TextField}
                            inputProps={{ maxLength: 51 }}
                            className={classes.textField}
                            name="lastName"
                            size="small"
                            type=""
                            variant="outlined"
                          />
                          <Box display='flex' justifyContent='space-between'>
                            <Typography color={"error"} variant="subtitle2">
                              {errors.lastName && touched.lastName && errors.lastName}
                            </Typography>
                            <Typography style={{ color: values.lastName?.length > 50 ? '#911717' : '#0000008a' }} variant="subtitle2">
                              {values.lastName?.length === 51 ? 50 : values.lastName?.length}/50
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>

                    <Box pb={1}>
                      <Grid alignItems="center" container justify="center" spacing={3}>
                        {edit ? <Grid item xs={12}>
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              Title
                            </Typography>
                          </Box>

                          <Field style={{ background: isError && inputs === 2 ? '#fff1f1' : 'unset' }}
                            onInput={(e: any) => handleChange(e.target.value, 2)}
                            as={TextField}
                            inputProps={{ maxLength: 21 }}
                            className={classes.textField}
                            name="title"
                            size="small"
                            type=""
                            variant="outlined"
                          />
                          <Box display='flex' justifyContent='space-between'>
                            <Typography color={"error"} variant="subtitle2">
                              {errors.title && touched.title && errors.title}
                            </Typography>
                            <Typography style={{ color: values.title?.length > 20 ? '#911717' : '#0000008a' }} variant="subtitle2">
                              {values.title?.length === 21 ? 20 : values.title?.length}/20
                            </Typography>
                          </Box>
                        </Grid> :
                          <Grid item xs={12}>
                            <Box pb={0.75}>
                              <Typography color="textSecondary" variant="subtitle2">
                                Email Address*
                              </Typography>
                            </Box>

                            <Field
                              as={TextField}
                              className={classes.textField}
                              name="email"
                              size="small"
                              type=""
                              variant="outlined"
                            />

                            {errors.email && touched.email ? (
                              <Typography color="error" variant="subtitle2">
                                {errors.email}
                              </Typography>
                            ) : null}
                          </Grid>}
                      </Grid>
                    </Box>
                  </Box>
                }
                <Box p={3} className={classes.topBox} style={{ borderRadius: '0 0px 10px 10px' }} display='flex' justifyContent='space-between'>
                  <LoadingButton
                    className={classes.secondaryButton}
                    variant="contained"
                    onClick={(e: any) => handleClose()}
                  >
                    Cancel
                  </LoadingButton>
                  <LoadingButton color="primary"
                    disabled={isSave} loading={isSave}
                    className={classes.primaryButton}
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </LoadingButton>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  topBox: {
    borderRadius: '0.625rem 0.625rem 0 0',
    background: '#f9fafb'
  },

  selectRoot: {
    padding: '.6rem'
  },
  textField: {
    width: '100%'
  },
  box: {
    width: '700px'
  },
  bottomBox: {
    borderRadius: '0 0 0.625rem 0.625rem'
  },
  primaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: '#fff',
    minWidth: '120px'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: '#0060B2',
    minWidth: '120px',
    background: '#fff'
  },
  inputField: {
    maxWidth: '18rem',
    width: '80vw',
    paddingBottom: '0.5rem'
  }
}));
