import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { CalculationPageDisplay } from './CalculationPageDisplay';

export const CalculationPageViewer = ({ calculationPage }: any) => {
    const classes = useStyles();

    if (!calculationPage) {
        return (
            <Box p={2} className={classes.placeholder}>
                <Typography variant={'h3'}>Add a metric to get started</Typography>
            </Box>
        );
    }

    if (!calculationPage.code) {
        return (
            <Box p={2} className={classes.placeholder}>
                <Typography variant={'h3'}>Add a metric to get started</Typography>
            </Box>
        );
    } else {
        return (
            <Box p={1}>
                <CalculationPageDisplay calculationPage={calculationPage} selectedCalPageId={calculationPage?.id} />
            </Box>
        );
    }
};

const useStyles = makeStyles((theme) => ({
    placeholder: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));
