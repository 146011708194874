import { saveAs } from 'file-saver';
import { url } from './url';

export class HttpError extends Error {
  message: any;
  code: any;
  info: any;
  constructor(message: string, code: any, info: any) {
    super(message);
    this.name = 'HttpError';
    this.message = message;
    this.code = code;
    this.info = info;
  }
}

const _getHost = () => {
  return window.location.hostname === url //'https://www.formflash.com'
    ? url
    : url;
  // return 'http://116.75.136.52:8000'
};

const _getHeaders = (inputHeaders?: { [key: string]: string }) => {
  let headers = inputHeaders || {};
  headers['Accept'] = 'application/json';
  const headersWithAuth = _getToken()
    ? {
      ...headers,
      Authorization: `Bearer ${_getToken()}`
    }
    : headers;
  return headersWithAuth;
};

export const getHttp = async (url_path: string) => {
  const fullUrl = `${_getHost()}${url_path}`;
  const result = await fetch(fullUrl, {
    method: 'GET',
    headers: _getHeaders()
  });
  if (!result.ok) {
    const errorResponse = await result.text();
    const errorMessage = errorResponse ? JSON.parse(errorResponse).message : result.statusText;
    const code = result.status;
    throw new HttpError(errorMessage, code, result);
  } else {
    return await result.json();
  }
};

export const deleteHttp = async (url_path: string) => {
  const fullUrl = `${_getHost()}${url_path}`;
  const result = await fetch(fullUrl, {
    method: 'DELETE',
    headers: _getHeaders()
  });
  if (!result.ok) {
    const errorResponse = await result.text();
    const errorMessage = errorResponse ? JSON.parse(errorResponse).message : result.statusText;
    throw new HttpError(errorMessage, result.status, result);
  }
  return await result;
};

export const patchHttp = async (url_path: string, body: any) => {
  const fullUrl = `${_getHost()}${url_path}`;
  const result = await fetch(fullUrl, {
    method: 'DELETE',
    headers: _getHeaders({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(body)
  });
  if (!result.ok) {
    const errorResponse = await result.text();
    const errorMessage = errorResponse ? JSON.parse(errorResponse).message : result.statusText;
    throw new HttpError(errorMessage, result.status, result);
  }
  return await result;
};

export const postHttp = async (url_path: string, body: object) => {
  const fullUrl = `${_getHost()}${url_path}`;
  const result = await fetch(fullUrl, {
    method: 'POST',
    headers: _getHeaders({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(body)
  });
  if (!result.ok) {
    const errorResponse = await result.text();
    const errorMessage = errorResponse ? JSON.parse(errorResponse).message : result.statusText;
    throw new HttpError(errorMessage, result.status, result);
  }
  return await result.json();
};

export const putHttp = async (url_path: string, body: object) => {
  const fullUrl = `${_getHost()}${url_path}`;
  const result = await fetch(fullUrl, {
    method: 'PUT',
    headers: _getHeaders({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(body)
  });
  if (!result.ok) {
    const errorResponse = await result.text();
    const errorMessage = errorResponse ? JSON.parse(errorResponse).message : result.statusText;
    throw new HttpError(errorMessage, result.status, result);
  }
  return await result.json();
};

export const downloadFileHttp = async (url_path: string, fileName: string = 'file') => {
  const fullUrl = `${_getHost()}${url_path}`;
  const result = await fetch(fullUrl, {
    method: 'GET',
    headers: _getHeaders()
  });
  if (!result.ok) {
    throw new HttpError(result.statusText, result.status, result.json());
  }
  const myBlob = await result.blob();
  saveAs(myBlob, fileName);
};
export const downloadFileHttp1 = async (url_path: string, body: object, fileName: string = 'file') => {
  const fullUrl = `${_getHost()}${url_path}`;
  const result: any = await fetch(fullUrl, {
    method: 'post',
    headers: _getHeaders({ 'Content-Type': 'application/json' }),
    body: JSON.stringify(body)
  });
  if (!result.ok) {
  }
  if (result.ok) {
    const fullUrl1 = await result.json();
    const newURL = fullUrl1.url && fullUrl1.url.length > 0 && fullUrl1.url[0];
    const data = await fetch(newURL, {
      method: 'GET',
      headers: _getHeaders()
    });
    if (!data.ok) {
      throw new HttpError(data.statusText, data.status, data.json());
    }
    const myBlob = await data.blob();
    saveAs(myBlob, fileName);
  }
  // const myBlob = await result.blob();
  // var link: any = document.createElement("a");
  // link.download = 'download.xlsx';
  // link.target = "_blank";
  // link.href = result.url && result.url.length > 0 ? result.url[0] : '';
  // link.click();
  // saveAs(link, fileName);
};
export const putObjectToS3 = async (signed_url: string, file: File) => {
  const result = await fetch(signed_url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/pdf', 'Accept': 'application/json' },
    body: file
  });
  if (!result.ok) {
    throw new HttpError(result.statusText, result.status, result.json());
  }
  return result;
};

// Auth token
let token: string | null = localStorage.getItem('token');
const _getToken = () => {
  return token;
};
export const updateToken = (newToken: string) => {
  localStorage.setItem('token', newToken);
  token = newToken;
};
export const isLoggedIn = () => {
  return token !== null;
};

// Validate when file first loads
if (token) {
  getHttp('/user/auth/validate').catch((e) => {
    localStorage.removeItem('token');
    token = null;
  });
}
