import { makeStyles, Theme, Typography, Paper, Popover, Divider, Box } from '@material-ui/core';
import { MoreHoriz, Close } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { HeadCell, LastSorted, Pagination, RowCell, Table } from '../../../components/Table';
import { getPatientInfoThunk, deletePatientThunk, sendInviteToSiteUser, cancelInviteToSiteUser, sendInviteToReachCareUser, getReachCareOrgIDThunk } from '../../../redux/thunk/thunks';
import { ConfirmationDialog } from '../../../components/ConfirmationDialog';
import { concatName } from '../../../shared/utils';
import { colors } from '../../../styling/colors';
import { ROLES } from '../../../shared/globals';
import { Dialog } from '../../../components/Dialog';
import { RootState } from '../../../redux/redux-store';

interface PatientsTableProps {
  isTab: number | null;
  patientsData: any | null;
  selectedPatients?: any[];
  setSelectedPatients?: (selectedPatients: any[]) => void;
  loadPatients: (getPatientsProps: any) => void;
  setPatientInfo: (info: any) => void;
  setView: (value: boolean) => void;
}

export const PatientsTable = ({ isTab, patientsData, selectedPatients, setSelectedPatients, loadPatients, setPatientInfo, setView }: PatientsTableProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [popuerInfo, setPopuerInfo] = useState<any>(null);
  const [isDelete, setIsDelete] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isFieldShow, setIsFieldShow] = useState(false);
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);
  const [lastSorted, setLastSorted] = useState<LastSorted>({
    column: 'first_name',
    order: 'desc'
  });

  const deleteUserLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.deleteUserLoading
  );

  const reachCareOrgId = useSelector(
    (state: RootState) => state.DATA_REDUCER.reachCareOrgId
  );

  useEffect(() => {
    dispatch(getReachCareOrgIDThunk());
  }, []);

  useEffect(() => {
    if (reachCareOrgId) {
      if (loggedInUser?.organization_id === reachCareOrgId) { setIsFieldShow(true); }
    }
  }, [reachCareOrgId]);

  const handleCloseDialog = () => {
    setIsOpen(false)
  }

  const refActions = (event: any, userInfo: any) => {
    setAnchorEl(event.currentTarget);
    setPopuerInfo(userInfo)
  }

  const handleClose = () => {
    setAnchorEl(null);
    setView(false);
  }

  const patientDelete = () => {
    setIsDelete(true)
    setAnchorEl(null)
  }

  const handleDeleteUser = async () => {
    if (popuerInfo) {
      await dispatch(deletePatientThunk([popuerInfo.id]));
    }
    setIsDelete(false);
  };

  const headCells: HeadCell[] = [
    {
      id: 'first_name',
      label: 'PATIENT NAME'
    },
    {
      align: 'left',
      id: 'patient_id',
      label: 'PATIENT ID'
    },
    {
      align: 'left',
      id: 'status',
      label: 'STATUS'
    },
    {
      align: 'left',
      id: 'last_login',
      label: 'LAST LOGIN/INVITE'
    },
    {
      align: 'right',
      id: 'action',
      label: '',
      isSortDisabled: true
    }
  ];
  const headCells1: HeadCell[] = [
    {
      id: 'first_name',
      label: 'PATIENT NAME'
    },
    {
      align: 'left',
      id: 'patient_id',
      label: 'PATIENT ID'
    },
    {
      align: 'left',
      id: 'status',
      label: 'STATUS'
    },
    {
      align: 'left',
      id: 'last_login',
      label: 'LAST ITEM COMPLETED ON'
    },
    {
      align: 'right',
      id: 'action',
      label: '',
      isSortDisabled: true
    }
  ];

  const patientSection = async (patient: any) => {
    await dispatch(getPatientInfoThunk(patient?.user_role_id));
    sessionStorage.setItem('pat_org_id', patient?.organization_id);
    sessionStorage.setItem('role', 'PATIENT');
    history.push({ pathname: `/patient/${patient?.user_role_id}/profile`, state: patient });
  }

  const rowCells = patientsData?.data.map((patient: any) => {
    const NameLink = (loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role) ?
      <Typography className={classes.titleLink} color="primary" variant="h5" onClick={(e) => editPatients(patient)}>
        {concatName({
          firstName: patient?.first_name,
          lastName: patient?.last_name,
          fallback: patient?.email ?? '<No Patient Name Available>'
        })}
      </Typography> :
      loggedInUser && [ROLES.CLINICIAN].includes(loggedInUser.role) ?
        <Typography className={classes.titleLink} color="primary" variant="h5" onClick={() => patientSection(patient)}>
          {concatName({
            firstName: patient?.first_name,
            lastName: patient?.last_name,
            fallback: patient?.email ?? '<No Patient Name Available>'
          })}
        </Typography> :
        <Link className={classes.titleLink} to={{ pathname: `/patient-info`, state: { patient } }}>
          <Typography color="primary" variant="h5">
            {concatName({
              firstName: patient?.first_name,
              lastName: patient?.last_name,
              fallback: patient?.email ?? '<No Patient Name Available>'
            })}
          </Typography>
        </Link>
    );
    var options: any = { weekday: 'long' };
    let newDate = patient?.last_login ? new Date(patient?.last_login) : null;
    const rowCell: RowCell = {
      first_name: {
        component: NameLink
      },
      patient_id: {
        align: 'left',
        value: patient?.patient_id
      },
      status: {
        align: 'left',
        value: patient?.status?.replace(/_/g, " ")
      },
      last_login: {
        align: 'left',
        value: newDate ? new Intl.DateTimeFormat('en-US', options).format(newDate) + ', ' + (("0" + (newDate.getMonth() + 1)).slice(-2)) + '/' + ("0" + newDate.getDate()).slice(-2) + '/' + newDate.getFullYear() : '-'
      },
      action: {
        align: 'right',
        component: <><MoreHoriz className={classes.launchIcon} onClick={(e) => refActions(e, patient)} />
          <Popover
            id={patient?.patient_id}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            style={{ boxShadow: 'unset' }}
          >
            <Paper style={{ boxShadow: 'unset' }}>
              <Typography className={classes.actionTypography} onClick={() => editPatient()}>Edit Patient</Typography>
              <Divider />
              {popuerInfo && popuerInfo.status === "AWAITING_ACCEPTANCE" &&
                <><Typography className={classes.actionTypography} onClick={(e) => cancelInvite()}>Cancel Invite</Typography>
                  <Divider /></>}
              {popuerInfo && (popuerInfo.status === "INVITE_NOT_SENT" || popuerInfo.status === "INVITE_EXPIRED" || popuerInfo.status === "INVITE_CANCELLED") &&
                <><Typography className={classes.actionTypography} onClick={(e) => sendInvite()}>Send Invite</Typography>
                  <Divider /></>}
              <Typography color='error' className={classes.actionTypography} onClick={(e) => patientDelete()}>Delete Patient</Typography>

            </Paper>
          </Popover>
        </>
      }
    };

    return Object.assign(rowCell, { content: patient });
  });

  const handleNextButton = async () => {
    if (
      patientsData &&
      patientsData.page >= 0 &&
      patientsData.page < patientsData.totalCount / patientsData.pageSize - 1
    ) {
      await loadPatients({
        order: patientsData.order,
        page: patientsData.page + 1,
        pageSize: patientsData.pageSize,
        search: patientsData.search,
        sort: patientsData.sort,
        favourites: patientsData.favourites,
      });
    }
  };
  const handlePrevButton = async () => {
    if (patientsData && patientsData.page > 0) {
      await loadPatients({
        order: patientsData.order,
        page: patientsData.page - 1,
        pageSize: patientsData.pageSize,
        search: patientsData.search,
        sort: patientsData.sort,
        favourites: patientsData.favourites,
      });
    }
  };

  const handlePageSize = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    const pageSize: number = e.target.value as number;

    if (patientsData && pageSize > 0) {
      loadPatients({
        order: patientsData.order,
        page: 0,
        pageSize,
        search: patientsData.search,
        sort: patientsData.sort,
        favourites: patientsData.favourites,
      });
    }
  };

  const pagination: Pagination | undefined = patientsData
    ? {
      contentName: 'Patients',
      page: patientsData.page,
      pageSize: patientsData.pageSize,
      total: patientsData.totalCount,
      handleNextButton: handleNextButton,
      handlePageSize: handlePageSize,
      handlePrevButton: handlePrevButton
    }
    : undefined;

  const handleSort = (sort: string) => {
    if (patientsData) {
      const nextDirection = lastSorted.order === 'desc' ? 'asc' : 'desc';

      loadPatients({
        order: sort === lastSorted.column ? nextDirection : 'desc',
        page: 0,
        pageSize: patientsData.pageSize,
        search: patientsData.search,
        favourites: patientsData.favourites,
        sort
      });

      setLastSorted(
        sort === lastSorted.column
          ? { column: sort, order: nextDirection }
          : { column: sort, order: 'desc' }
      );
    }
  };

  const editPatients = (info: any) => {
    setView(true);
    setPatientInfo(info);
  }
  const editPatient = () => {
    setView(true);
    setPatientInfo(popuerInfo);
  }
  const sendInvite = () => {
    if (loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER, ROLES.CLINICIAN].includes(loggedInUser.role)) {
      if (isFieldShow) {
        dispatch(sendInviteToReachCareUser(popuerInfo.user_role_id));
      } else { dispatch(sendInviteToSiteUser(popuerInfo.user_role_id, popuerInfo.role)); }
    }
    setIsOpen(true);
    setAnchorEl(null);
  }
  const cancelInvite = () => {
    if (loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER, ROLES.CLINICIAN].includes(loggedInUser.role)) {
      dispatch(cancelInviteToSiteUser(popuerInfo.user_role_id, popuerInfo.role));
    }
    setAnchorEl(null)
  }

  return (
    <>
      {loggedInUser && [ROLES.SITE_ADMIN, ROLES.CLINICIAN].includes(loggedInUser.role) ?
        isTab === 0 ? <Table
          data={patientsData?.data}
          headCells={headCells1}
          loading={false}
          pagination={pagination}
          rowCells={rowCells}
          selection={
            selectedPatients && setSelectedPatients
              ? {
                selectedContents: selectedPatients,
                setSelectedContents: (selected: any[]) => setSelectedPatients(selected)
              }
              : undefined
          }
          sort={{
            lastSorted: lastSorted,
            handleSort: handleSort
          }}
        /> : <Table
          data={patientsData?.data}
          headCells={headCells1}
          loading={false}
          pagination={pagination}
          rowCells={rowCells}
          sort={{
            lastSorted: lastSorted,
            handleSort: handleSort
          }}
        /> :
        <Table
          data={patientsData?.data}
          headCells={headCells}
          loading={false}
          pagination={pagination}
          rowCells={rowCells}
          selection={
            selectedPatients && setSelectedPatients
              ? {
                selectedContents: selectedPatients,
                setSelectedContents: (selected: any[]) => setSelectedPatients(selected)
              }
              : undefined
          }
          sort={{
            lastSorted: lastSorted,
            handleSort: handleSort
          }}
        />}
      {isOpen &&
        <Dialog handleClose={handleCloseDialog} open={isOpen}>
          <Box style={{ width: '400px' }}>
            <Box pb={3} pt={2} px={4} display="flex" justifyContent="space-between">
              <Typography color="textSecondary" variant="h2">Invitation Success</Typography>
              <Close onClick={() => setIsOpen(false)} />
            </Box>
            <Box pb={6} pt={2} display="flex" justifyContent="center">
              <Typography color="textSecondary" variant="h2">Invitation Sent</Typography>
            </Box>
          </Box>
        </Dialog>
      }
      {isDelete &&
        <ConfirmationDialog
          loading={deleteUserLoading}
          message={`Are you sure you want to delete? Please type "DELETE" to confirm.`}
          open={isDelete}
          title=""
          handleClose={() => setIsDelete(false)}
          handleConfirmClick={handleDeleteUser}
        />
      }
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  collectionBubble: {
    borderRadius: '0.75rem'
  },
  clearIcon: {
    color: colors.text6,
    fontSize: 14,
    marginLeft: '0.25rem'
  },
  titleLink: {
    textDecoration: 'none',
    cursor: 'pointer'
  },
  launchIcon: {
    fontSize: '2rem',
    cursor: 'pointer'
  },
  actionTypography: {
    padding: '10px',
    fontWeight: 'bold',
    cursor: 'pointer'
  },
}));
