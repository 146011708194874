import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import './App.css';
import { Alert } from './components/Alert';
import SessionTimeout from './components/SessionTimeout';
import { FlowRouter } from './router/FlowRouter';
import { reduxStore } from './redux/redux-store';
import { theme } from './styling/ThemeConfig';

function App() {

  window.onbeforeunload = (e: any) => {
    const remember = localStorage.getItem('remember');
    if (!remember) {
      // localStorage.removeItem('formFlash');
      // localStorage.removeItem('token');
    }
  }


  return (
    <Provider store={reduxStore}>
      <ThemeProvider theme={theme}>
        <FlowRouter />
        <Alert />
        <SessionTimeout />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
