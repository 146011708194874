import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { Link, matchPath, withRouter, useHistory } from 'react-router-dom';
import { RootState } from '../redux/redux-store';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { colors } from '../styling/colors';

interface MatchParams {
  id: string;
}

export const PatientSideBar = withRouter(({ match }) => {
  const classes = useStyles();
  const history = useHistory();
  const patientInfo: any = history.location && history.location.state;
  const patient = patientInfo && patientInfo.patient;
  const locationPath = match.path;
  const location = matchPath<MatchParams>(match.path, {
    path: '/patient/:id'
  })?.params.id;
  const loggedInUser = useSelector((state: RootState) => state.DATA_REDUCER.loggedInUser);

  return (
    <Box className={classes.sideBar} display="flex" flexDirection="column" pr={4}>

      <Box mb={3}>
        <Link
          className={locationPath === '/patient-info' ? classes.activeSideBarText : classes.sideBarText}
          to={{ pathname: `/patient-info`, state: { patient } }}
        >
          <Box alignItems="center" display="flex">
            {locationPath === '/patient-info' && (
              <Box alignItems="center" display="flex" pr={0.5}>
                <ArrowForwardIosIcon className={classes.arrowIcon} />
              </Box>
            )}

            <Typography variant="subtitle2">Patient Information</Typography>
          </Box>
        </Link>
      </Box>

      <Box mb={3}>
        <Link
          className={locationPath === '/patient-appointments' ? classes.activeSideBarText : classes.sideBarText}
          to={{ pathname: `/patient-appointments`, state: { patient } }}
        >
          <Box alignItems="center" display="flex">
            {locationPath === '/patient-appointments' && (
              <Box alignItems="center" display="flex" pr={0.5}>
                <ArrowForwardIosIcon className={classes.arrowIcon} />
              </Box>
            )}

            <Typography variant="subtitle2">Appointments</Typography>
          </Box>
        </Link>
      </Box>

      <Box mb={3}>
        <Link
          className={
            locationPath === `/patients/:patientId` ? classes.activeSideBarText : classes.sideBarText
          }
          to={{ pathname: patient && `/patients/${patient.id}`, state: { patient } }}
        >
          <Box alignItems="center" display="flex">
            {locationPath === `/patients/:patientId` && (
              <Box alignItems="center" display="flex" pr={0.5}>
                <ArrowForwardIosIcon className={classes.arrowIcon} />
              </Box>
            )}

            <Typography variant="subtitle2">Forms</Typography>
          </Box>
        </Link>
      </Box>

    </Box>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    [theme.breakpoints.down('xs')]: {
      padding: '1rem'
    },
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh'
  },
  userIcon: {
    borderRadius: '6px',
    backgroundColor: colors.background5,
    height: '34px',
    width: '34px'
  },
  userIconText: {
    color: theme.palette.secondary.main,
    textShadow: '0 1px 1px rgba(0, 0, 0, 0.25)'
  },
  sideBar: {
    minWidth: '11rem'
  },
  sideBarText: {
    color: colors.text4,
    textDecoration: 'none'
  },
  activeSideBarText: {
    color: colors.blue1,
    textDecoration: 'none'
  },
  arrowIcon: {
    fontSize: 14
  },
  contentContainer: {
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  }
}));
