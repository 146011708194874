interface ConcatNameProps {
  firstName?: string;
  lastName?: string;
  fallback: string;
}
export const concatName = ({ firstName, lastName, fallback }: ConcatNameProps) => {
  const name = `${firstName ?? ''}${lastName && lastName.length > 0 ? ' ' + lastName : ''}`;
  return name.length > 0 ? name : fallback;
};

export interface DateDiff {
  [key: string]: number; // Needed to handle Typescript "index signature" error
  years: number;
  months: number;
  weeks: number;
  days: number;
  hours: number;
  minutes: number;
  // seconds: number;  // Can be used when required
}
export const getDateDiff = (startDate: Date, endDate: Date) => {
  const startDateInMiliSeconds = startDate.getTime();
  const endDateInMiliSeconds = endDate.getTime();

  let diff = (endDateInMiliSeconds - startDateInMiliSeconds) / 1000; // Diff between Both Dates In Miliseconds
  if (diff > 0) {
    var s: DateDiff = {
      years: 31536000,
      months: 2592000,
      weeks: 604800,
      days: 86400,
      hours: 3600,
      minutes: 60
      // seconds: 1
    };

    return Object.keys(s).reduce((acc, curr) => {
      acc[curr] = Math.floor(diff / s[curr]);
      diff -= acc[curr] * s[curr];
      return acc;
    }, {} as DateDiff);
  } else {
    // Means end date is smaller then start date
    return -1;
  }
};
