import { TextField, Typography } from '@material-ui/core';
import React from 'react';
interface LongTextBlockProps {
  fieldTitle: any;
}

export const LongTextFieldBlock: React.FC<LongTextBlockProps> = ({ fieldTitle }) => {
  return (
    <>
      {fieldTitle && fieldTitle.map((queName: any) => {
        return <Typography variant="subtitle2"><b>{queName ? queName : ''}</b></Typography>
      })}
      <TextField
        label="Insert Answer Here"
        variant="outlined"
        size={'small'}
        multiline
        fullWidth
        rows={2}
        disabled={true}
      />
    </>
  );
};
