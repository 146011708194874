import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, DialogActions } from '@material-ui/core';
import { Dialog } from '../../../components/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { NewCollectionDialog } from './NewCollectionDialog';
import { ViewCollection } from './ViewCollection';
import { CardHeader } from '../../../components/LegacyCardHeader';
import { UuidType } from '../../../shared/domain';
import { ConfirmationDelete } from '../../../components/DeleteConfirmation';
import { RootState } from '../../../redux/redux-store';
import { CollectionTable } from './CollectionTable';
import { LoadingButton } from '../../../components/LoadingButton';
import { ROLES } from '../../../shared/globals';
import {
  GetCollectionProps, duplicateCollectionsThunk, getCollectionsThunk, deleteCollectionThunk, getDomainsThunk, removeFevCollectionThunk, getCollectionStatusCount
} from '../../../redux/thunk/thunks';
import { TabBar, TabComponent } from '../../../components/TabBar';
import { getAllCollectionForms, getCollectionForms } from '../../../redux/data/data-actions';
import { setSuccessAction } from '../../../redux/display/display-actions';

export const Collections = () => {
  const dispatch = useDispatch();
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);
  const [searchPattern, setSearchPattern] = useState('');
  const [selectedFormsDetails, setSelectedFormsDetails] = useState<any>([]);
  const [selectedCustomCollections, setSelectedCustomCollections] = useState<UuidType[]>([]);
  const [tab, setTab] = useState<any>(0);
  const [createCollectionOpen, setCreateCollectionOpen] = useState(false);
  const [addDuplicate, setAddDuplicateOpen] = useState(false);
  const [viewCollection, setViewCollection] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [editInfo, setEditInfo] = useState<any>();
  const [isCustom, setIsCustom] = useState(false);
  const [isBaseDelete, setIsBaseDelete] = useState(false);
  const [addToCollectionDialogOpen, setAddToCollectionDialogOpen] = useState(false);
  const [deleteFormsConfirmationDialogOpen, setDeleteFormsConfirmationDialogOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const getSession: any = sessionStorage.getItem('item');
  const sessionData = JSON.parse(getSession);

  const deleteData: any = useSelector((state: RootState) => state.DATA_REDUCER.collectionStatusCount);

  const domainsData: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.collectionSearchResults.libraryForms
  );
  const customCollectionData: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.collectionSearchResults.collections
  );

  const success: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.createCollectionSuccess)
  useEffect(() => {
    if (success) {
      setViewCollection(false);
      setEditInfo(null);
      setEdit(false);
      setCreateCollectionOpen(false);
      setSelectedCustomCollections([]);
      dispatch(setSuccessAction('createCollectionSuccess', false));
      dispatch(getCollectionsThunk({
        domainId: customCollectionData?.domainId,
        collectionType: 'collections',
        search: customCollectionData?.search,
        page: customCollectionData?.page || 0,
        pageSize: customCollectionData?.pageSize || 10,
        favourites: customCollectionData?.favourites,
        order: customCollectionData?.order,
        sort: customCollectionData?.sort
      }));
    } else if (!customCollectionData) {
      const tabValue = sessionStorage.getItem('tab');
      dispatch(getCollectionsThunk({ collectionType: 'collections', search: searchPattern, favourites: (!tabValue || tabValue === 'Favorites') ? true : false }));
    } else if (sessionData) {
      sessionStorage.removeItem('item');
      if (sessionData.session == 0) {
        setViewCollection(true);
        setEditInfo(sessionData);
      } else {
        setCreateCollectionOpen(true);
        setEdit(true);
        setEditInfo(sessionData);
      }
    }
  })

  const handelSet = () => {
    editInfo.session = 0;
    sessionStorage.setItem('item', JSON.stringify(editInfo));
    sessionStorage.setItem('version', editInfo.form_version_id);
  }

  const handelSet1 = () => {
    editInfo.session = 1;
    sessionStorage.setItem('item', JSON.stringify(editInfo));
  }

  const handleChangeFavorite = (value: boolean) => {
    editInfo.isFavourite = value;
    setEditInfo(editInfo);
  }

  const viewCollectionOpen = (info: any) => {
    dispatch(getAllCollectionForms(null));
    setViewCollection(true);
    setEditInfo(info);
  };
  const viewCollectionClose = async () => {
    await dispatch(getCollectionsThunk({
      domainId: customCollectionData?.domainId,
      collectionType: 'collections',
      search: customCollectionData?.search,
      page: customCollectionData?.page || 0,
      pageSize: customCollectionData?.pageSize || 10,
      favourites: customCollectionData?.favourites,
      order: customCollectionData?.order,
      sort: customCollectionData?.sort
    }));
    sessionStorage.removeItem('item');
    setViewCollection(false);
  };
  const handleCreateCollectionOpen = () => {
    dispatch(getAllCollectionForms(null));
    dispatch(getCollectionForms(null));
    setCreateCollectionOpen(true);
    setEdit(false);
  };
  const handleAddToOrgOpen = () => {
    let checkCustomId: any = [];
    for (let i = 0; i < customCollectionData?.collections.length; i++) {
      if (customCollectionData?.collections[i].collectionType === 'CUSTOM') {
        const res = selectedCustomCollections.filter((id: any) => id === customCollectionData?.collections[i].id);
        res && res.length > 0 && checkCustomId.push(res);
      }
    }
    if (checkCustomId.length === 0) {
      setAddDuplicateOpen(true);
    } else {
      setIsCustom(true);
    }
  };
  const handleAddToOrgClose = () => {
    setAddDuplicateOpen(false);
  };
  const handleEditCollectionOpen = () => {
    setCreateCollectionOpen(true);
    setEdit(true);
  };
  const handelClose = () => {
    setCreateCollectionOpen(false);
  };
  const handleDeleteCollectionConfirmationDialog = async () => {
    if (loggedInUser && [ROLES.ADMIN, ROLES.SUPER_ADMIN].includes(loggedInUser.role)) {
      const data = { collection_ids: selectedCustomCollections }
      await dispatch(getCollectionStatusCount(data));
      setDeleteFormsConfirmationDialogOpen(true);
    } else {
      let checkCustomId: any = [];
      for (let i = 0; i < selectedFormsDetails.length; i++) {
        if (selectedFormsDetails[i]?.collectionType !== 'CUSTOM') {
          checkCustomId.push(selectedFormsDetails[i]);
        }
      }
      if (checkCustomId.length > 0) {
        setIsBaseDelete(true);
      } else if (checkCustomId.length === 0) {
        const data = { collection_ids: selectedCustomCollections }
        await dispatch(getCollectionStatusCount(data));
        setDeleteFormsConfirmationDialogOpen(true);
      }
    }
  };
  const handleDeleteFormsConfirmationDialogClose = () => {
    setDeleteFormsConfirmationDialogOpen(false);
  };

  const handelSetFormDetails = (selectedForms: any) => {
    let checkCustomId: any = [];
    for (let i = 0; i < selectedForms.length; i++) {
      const res = customCollectionData?.collections.filter((id: any) => id.id === selectedForms[i]);
      res && res.length > 0 && checkCustomId.push({ id: res[0]?.id, title: res[0]?.name, collectionType: res[0]?.collectionType });
    }
    setSelectedFormsDetails(checkCustomId);
  }

  const onFavoritesTabChange = async () => {
    if (tab === 0) {
      setTab(1); selectedCustomCollections.length > 0 && setSelectedCustomCollections([]);
      dispatch(getCollectionsThunk({ collectionType: 'collections', search: searchPattern, favourites: true }));
    }
  }

  const onCollectionTabChange = async () => {
    if (tab === 1) {
      setTab(0); selectedCustomCollections.length > 0 && setSelectedCustomCollections([]);
      dispatch(getCollectionsThunk({ collectionType: 'collections', search: searchPattern, favourites: false }));
    }
  }

  const handleCustomSearch = (value: string) => {
    setSearchPattern(value);

    if (customCollectionData) {
      dispatch(
        getCollectionsThunk({
          collectionType: 'collections',
          page: 0,
          pageSize: customCollectionData?.pageSize,
          search: value,
          favourites: customCollectionData?.favourites,
          order: customCollectionData?.order,
          sort: customCollectionData?.sort
        })
      );
    }
  };

  const handleDeleteCollections = async (library?: boolean) => {
    setDeleteLoading(true);

    const delete_ids = { collection_ids: selectedCustomCollections };

    await dispatch(deleteCollectionThunk(delete_ids));
    dispatch(getCollectionsThunk({
      domainId: customCollectionData?.domainId,
      collectionType: 'collections',
      search: customCollectionData?.search,
      page: customCollectionData?.page,
      pageSize: customCollectionData?.pageSize,
      order: customCollectionData?.order,
      favourites: customCollectionData?.favourites,
      sort: customCollectionData?.sort
    }));
    setSelectedCustomCollections([]);
    setDeleteLoading(false);
    setDeleteFormsConfirmationDialogOpen(false);
    setTab(0);
  };

  const handleRemoveFevCollections = async () => {
    const remove_ids = { collection_ids: selectedCustomCollections };
    await dispatch(removeFevCollectionThunk(remove_ids));
    setSelectedCustomCollections([]);
  };

  const AddDuplicate = async () => {
    setDeleteLoading(true);
    await dispatch(duplicateCollectionsThunk(selectedCustomCollections));
    dispatch(getCollectionsThunk({
      domainId: customCollectionData?.domainId,
      collectionType: 'collections',
      search: customCollectionData?.search,
      page: customCollectionData?.page,
      pageSize: customCollectionData?.pageSize,
      favourites: customCollectionData?.favourites,
      order: customCollectionData?.order,
      sort: customCollectionData?.sort
    }));
    setSelectedCustomCollections([]);
    setDeleteLoading(false);
    setAddDuplicateOpen(false);
    setTab(0);
  }

  const handleCustomTabChange = (id: string) => {
    dispatch(
      getCollectionsThunk({
        domainId: id === 'ALL_COLLECTIONS' ? '' : id,
        collectionType: 'collections',
        library: true,
        search: searchPattern
      })
    );
    setSelectedCustomCollections([]);
  };

  const loadCollection = async (getCollectionsProps: GetCollectionProps) => {
    await dispatch(getCollectionsThunk({ ...getCollectionsProps }));
  };

  useEffect(() => {
    dispatch(getDomainsThunk({ collectionsType: 'libraryForms' }));
  }, []);

  const CustomForms = (
    <>
      {addToCollectionDialogOpen && customCollectionData && (
        <NewCollectionDialog
          handleClose={handelClose} collectionForms={[]} isEdit={isEdit} editInfo={editInfo} handelSet={handelSet1}
        />
      )}

      {deleteFormsConfirmationDialogOpen && customCollectionData && selectedCustomCollections.length > 0 && (
        <ConfirmationDelete
          loading={deleteLoading}
          data={deleteData}
          customData={selectedFormsDetails}
          title="collection"
          handleClose={handleDeleteFormsConfirmationDialogClose}
          handleConfirmClick={() => handleDeleteCollections(false)}
        />
      )}

      <Box pb={4} pt={1} px={4}>
        <CollectionTable
          library={false}
          collectionData={customCollectionData}
          selectedCollections={selectedCustomCollections}
          loadCollection={loadCollection}
          setSelectedCollections={(selectedCollections: UuidType[]) => { setSelectedCustomCollections(selectedCollections); handelSetFormDetails(selectedCollections) }}
          viewCollectionOpen={(value: any) => viewCollectionOpen(value)}
        />
      </Box>
    </>
  );
  const customTabComponents: TabComponent[] = [
    {
      component: CustomForms,
      id: 'ALL_COLLECTIONS',
      label: 'All',
      style: {
        background: true,
        spacing: { px: 4, py: 0 }
      }
    },

  ].concat(
    domainsData
      ? domainsData.collections.map((collection: any) => ({
        component: CustomForms,
        id: collection.id,
        label: collection.title,
        style: {
          background: true,
          spacing: { px: 4, py: 0 },
        }
      }))
      : []
  );
  const customDoaminTabs = (
    <TabBar
      style={{
        tabBackground: true,
        spacing: { px: 4, py: 0 },
        maxWidth: '100%'
      }}
      tabComponents={customTabComponents}
      onTabChange={handleCustomTabChange}
    />
  );

  return (
    <>
      {createCollectionOpen ? <>
        <Paper>
          <NewCollectionDialog
            handleClose={handelClose} collectionForms={[]} isEdit={isEdit} editInfo={editInfo} handelSet={handelSet1}
          />
        </Paper>
      </>
        : <>
          {viewCollection ? <Paper>
            <ViewCollection handleChangeFavorite={handleChangeFavorite} handleClose={viewCollectionClose} editCollection={handleEditCollectionOpen} editInfo={editInfo} handelSet={handelSet} />
          </Paper>
            :
            <Paper>
              <Box display="flex" flexDirection="column">
                {loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role) ?
                  <CardHeader
                    tabComponents={[
                      {
                        component: customDoaminTabs,
                        label: 'Favorites',
                        primaryButton: {
                          text: 'Create',
                          handleClick: handleCreateCollectionOpen
                        },
                        selected: {
                          primaryButton: {
                            text: 'Remove',
                            handleClick: handleRemoveFevCollections
                          },
                          selectedContents: selectedCustomCollections,
                        },
                        style: {
                          background: true,
                          spacing: { pb: 0, pt: 3, px: 4 }
                        },
                        title: 'Favorites Collections',
                        handleSearch: handleCustomSearch,
                        onTabChange: onFavoritesTabChange
                      },
                      {
                        component: customDoaminTabs,
                        label: 'Collections',
                        primaryButton: {
                          text: 'Create',
                          handleClick: handleCreateCollectionOpen
                        },
                        selected: {
                          primaryButton: {
                            text: 'Make Copy',
                            handleClick: handleAddToOrgOpen
                          },
                          selectedContents: selectedCustomCollections,
                          handleSelectedDelete: handleDeleteCollectionConfirmationDialog
                        },
                        style: {
                          background: true,
                          spacing: { pb: 0, pt: 3, px: 4 }
                        },
                        title: 'Collections',
                        handleSearch: handleCustomSearch,
                        onTabChange: onCollectionTabChange
                      },
                    ]}
                  /> :
                  <CardHeader
                    tabComponents={[
                      {
                        component: customDoaminTabs,
                        label: 'Favorites',
                        primaryButton: {
                          text: 'Create',
                          handleClick: handleCreateCollectionOpen
                        },
                        selected: {
                          primaryButton: {
                            text: 'Remove',
                            handleClick: handleRemoveFevCollections
                          },
                          selectedContents: selectedCustomCollections,
                        },
                        style: {
                          background: true,
                          spacing: { pb: 0, pt: 3, px: 4 }
                        },
                        title: 'Favorites Collections',
                        handleSearch: handleCustomSearch,
                        onTabChange: onFavoritesTabChange
                      },
                      {
                        component: customDoaminTabs,
                        label: 'Collections',
                        primaryButton: {
                          text: 'Create',
                          handleClick: handleCreateCollectionOpen
                        },
                        selected: {
                          selectedContents: selectedCustomCollections,
                          handleSelectedDelete: handleDeleteCollectionConfirmationDialog
                        },
                        style: {
                          background: true,
                          spacing: { pb: 0, pt: 3, px: 4 }
                        },
                        title: 'Collections',
                        handleSearch: handleCustomSearch,
                        onTabChange: onCollectionTabChange
                      },
                    ]}
                  />}
              </Box>
            </Paper>
          }
        </>
      }
      {addDuplicate &&
        <Dialog open={addDuplicate}>
          <Box display='flex' justifyContent='center' style={{ borderRadius: '0.625rem 0.625rem 0 0', backgroundColor: '#f9fafb' }}
            alignItems="center"
            px={3}
            py={2.5}
          >
            <Typography variant="h3">Make Copy</Typography>
          </Box>
          <Box style={{ width: '500px' }}>
            <Box pb={3} pt={2} px={4} display="flex" justifyContent="space-between">
              <Typography>Are you sure you would like to make a copy of the item(s) for your organization?</Typography>
            </Box>
          </Box>
          <DialogActions style={{ justifyContent: 'space-between', backgroundColor: '#f9fafb', padding: '15px 20px 5px', borderRadius: '0 0px 10px 10px' }}>
            <LoadingButton style={{ border: '1px solid rgba(0, 0, 0, 0.15)', width: '120px', marginBottom: '10px', color: '#0060B2' }}
              variant="contained"
              onClick={handleAddToOrgClose}
            >
              Cancel
            </LoadingButton>
            <LoadingButton color="primary" disabled={deleteLoading}
              variant="contained"
              style={{ marginBottom: '10px', boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)', width: '120px' }}
              onClick={(e) => AddDuplicate()}
            >
              Confirm
            </LoadingButton>
          </DialogActions>
        </Dialog>
      }
      {isCustom &&
        <Dialog open={isCustom}>
          <Box style={{ width: '400px' }}>
            <Box pb={3} pt={2} px={4} display="flex" justifyContent="center">
              <Typography variant="h3">Custom collection already exists in the organization, it cannot be added again</Typography>
            </Box>
          </Box>
          <DialogActions style={{ justifyContent: 'center' }}>
            <LoadingButton style={{ border: '1px solid rgba(0, 0, 0, 0.15)', width: '120px', marginBottom: '10px' }}
              variant="contained"
              onClick={() => setIsCustom(false)}
            >
              Close
            </LoadingButton>
          </DialogActions>
        </Dialog>
      }
      {isBaseDelete &&
        <Dialog open={isBaseDelete}>
          <Box style={{ width: '400px', textAlign: 'center' }}>
            <Box pb={3} pt={2} px={4} display="flex">
              <Typography variant="h3">You have selected base collections, it cannot be deleted</Typography>
            </Box>
          </Box>
          <DialogActions style={{ justifyContent: 'center' }}>
            <LoadingButton style={{ border: '1px solid rgba(0, 0, 0, 0.15)', width: '120px', marginBottom: '10px' }}
              variant="contained"
              onClick={() => setIsBaseDelete(false)}
            >
              Close
            </LoadingButton>
          </DialogActions>
        </Dialog>
      }
    </>
  );
};
