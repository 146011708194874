import { Box, makeStyles, Paper, IconButton, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Add, MoreHoriz } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmationDialog } from '../../../components/ConfirmationDialog';
import Popover from '@material-ui/core/Popover';
import { NavTab, NavTabs } from '../../../components/NavTabs';
import { RootState } from '../../../redux/redux-store';
import { useLocation } from 'react-router-dom';
import { deleteCalculationsThunk } from '../../../redux/thunk/thunks';
import { AddCalculation } from './AddCalculation'
import { setErrorAction } from '../../../redux/display/display-actions';

interface CalculationPageSubHeaderProps {
    handleSelectPage: (event: React.ChangeEvent<{}>, value: any) => void;
    selectedCalPageId?: string | null;
    formId: any
}

export const CalculationPageSubHeader: React.FC<CalculationPageSubHeaderProps> = ({
    selectedCalPageId,
    handleSelectPage,
    formId
}) => {
    const location: any = useLocation();
    const isEdit = location.state;
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [openNew, setOpenNew] = useState(false);
    const [editInfo, setEditInfo] = useState(null);
    const classes = useStyles();
    const dispatch = useDispatch();

    const editingCalculationPages = useSelector((state: RootState) => state.DATA_REDUCER.calculationPages);
    const deleteCalculationPageLoading = useSelector(
        (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.deleteExitPageLoading
    );
    const editingForm = useSelector((state: RootState) => state.DATA_REDUCER.editingForm);

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const getExitPagesLoading = useSelector(
        (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.getExitPagesLoading
    );
    useEffect(() => {
        if (getExitPagesLoading) {
            setOpenNew(false);
            setEditInfo(null);
        }
    })

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>, info: any) => {
        setAnchorEl(event.currentTarget);
        handleSelectPage(event, info.id)
        setEditInfo(info);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpenNew(false);
        setEditInfo(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const addCalculationPage = () => {
        dispatch(setErrorAction('createMetricError', null));
        setOpenNew(true);
        setAnchorEl(null);
    };

    const showDeleteDialog = () => {
        setDeleteDialog(true);
        handleClose()
    };
    const closeDeleteDialog = () => {
        setDeleteDialog(false);
    };
    const deleteCalculationPage = () => {
        if (editingForm && selectedCalPageId) {
            closeDeleteDialog();
            dispatch(deleteCalculationsThunk(selectedCalPageId, editingForm?.id));
        }
    };

    return (
        <Paper className={classes.container} square elevation={2}>
            {deleteDialog && (
                <ConfirmationDialog
                    loading={deleteCalculationPageLoading}
                    message='Are you sure you want to delete the currently selected calculation page?'
                    open={deleteDialog}
                    title="Calculation"
                    handleClose={closeDeleteDialog}
                    handleConfirmClick={deleteCalculationPage}
                />
            )}
            <Box display="flex" alignItems="center">
                <NavTabs value={selectedCalPageId}>
                    {editingCalculationPages?.map((item: any, i: number) => {
                        return (<Box display="flex" alignItems="center" mr={2} onClick={(e: any) => handleSelectPage(e, item.id)} style={{ borderBottom: selectedCalPageId === item.id ? '3px solid #0062b2' : 'unset' }}>
                            <NavTab key={i} label={<span style={{ color: selectedCalPageId === item.id ? '#01227e' : 'unset' }}>{item.code}</span>} value={item.id} />
                            {isEdit && <MoreHoriz onClick={(e: any) => handleClick(e, item)} className={classes.moreIcon} />}
                        </Box>
                        )
                    })}
                    {isEdit && <IconButton
                        className={classes.addBtn}
                        size="small"
                        onClick={addCalculationPage}
                    >
                        <Add fontSize={'small'} /> {editingCalculationPages && editingCalculationPages.length === 0 && <span style={{ padding: '0 5px' }}>Metric</span>}
                    </IconButton>
                    }
                </NavTabs>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    {/* <Typography style={{ color: '#0060B2', cursor: 'pointer', width: '70px', padding: '5px', textAlign: 'center' }} onClick={addCalculationPage}>Edit</Typography> */}
                    <Typography style={{ color: 'red', cursor: 'pointer', width: '70px', padding: '5px', textAlign: 'center' }} onClick={showDeleteDialog}>Delete</Typography>
                </Popover>
            </Box>
            {openNew &&
                <AddCalculation formId={formId} handleClose={handleClose} editInfo={editInfo} />
            }
        </Paper>
    );
};

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: '#ffffff',
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(4),
        paddingBottom: 0
    },
    leftButton: {
        marginRight: '1rem'
    },
    addBtn: {
        padding: 0,
        marginTop: '.6rem',
        marginLeft: '.6rem',
        borderRadius: '1rem',
        paddingLeft: '.2rem',
        paddingRight: '.2rem',
        fontSize: '14px',
        height: '30px',
        backgroundColor: theme.background.main,
        color: theme.palette.text.primary
    },
    moreIcon: {
        marginLeft: '-10px',
        marginTop: '5px',
        cursor: 'pointer'
    }
}));
