import React, { useEffect, useState } from 'react';
import { Box, Paper, Theme, Typography, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { AssignCollectionFormToPatientDialog } from './AssignCollectionFormToPatientDialog';
import { AssignFormDialog } from './AssignFormDialog';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { LoadingButton } from '../../../../components/LoadingButton';
import { FormInstanceResultRow, Patient, UuidType } from '../../../../shared/domain';
import {
  getPatientFormInstancesAction,
  PatientFormInstancesType
} from '../../../../redux/data/data-actions';
import { PatientFormsTable } from './PatientFormsTable';
import { RootState } from '../../../../redux/redux-store';
import { SearchBar } from '../../../../components/SearchBar';
import { TabBar, TabComponent } from '../../../../components/TabBar';
import {
  assignFormToPatientThunk,
  deleteFormInstanceThunk,
  getFormInstancesByPatientThunk
} from '../../../../redux/thunk/thunks';
import { colors } from '../../../../styling/colors';
import { concatName } from '../../../../shared/utils';
import { setSuccessAction } from '../../../../redux/display/display-actions';
import { ViewFormResponsesDialogue } from './ViewFormResponsesDialogue';
import { DatepickerDialog } from '../../../../components/DatepickerDialog';

export enum FORM_TAB {
  IN_PERSON = 'IN_PERSON',
  ONE_TIME = 'ONE_TIME'
}

interface PatientTasksProps {
  patient: Patient;
}

export const PatientTasks = ({ patient }: PatientTasksProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const inPersonPatientFormsData = useSelector(
    (state: RootState) => state.DATA_REDUCER.patientFormInstanceSearchResults.inPerson
  );
  const oneTimePatientFormsData = useSelector(
    (state: RootState) => state.DATA_REDUCER.patientFormInstanceSearchResults.oneTime
  );
  const getFormInstancesByPatientLoading = useSelector(
    (state: RootState) =>
      state.DISPLAY_STATE_REDUCER.displayLoading.getFormInstancesByPatientLoading
  );
  const deleteFormInstanceLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.deleteFormInstanceLoading
  );
  const getFormInstancesByPatientSuccess = useSelector(
    (state: RootState) =>
      state.DISPLAY_STATE_REDUCER.displaySuccess.getFormInstancesByPatientSuccess
  );
  const assignFormToPatientSuccess = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.assignFormToPatientSuccess
  );
  const deleteFormInstanceSuccess = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.deleteFormInstanceSuccess
  );

  const [selectedTab, setSelectedTab] = useState(FORM_TAB.IN_PERSON);

  const [formToUnassign, setFormToUnassign] = useState<FormInstanceResultRow | null>(null);
  const [formToAssign, setFormToAssign] = useState<UuidType | null>(null);

  const [formsSearch, setFormsSearch] = useState<{ [x in PatientFormInstancesType]: string }>({
    inPerson: '',
    oneTime: ''
  });

  const [
    assignCollectionFormToPatientDialogOpen,
    setAssignCollectionFormToPatientDialogOpen
  ] = useState(false);
  const [assignLibraryFormToPatientDialogOpen, setAssignLibraryFormToPatientDialogOpen] = useState(
    false
  );
  const [dueDateDialogOpen, setDueDateDialogOpen] = useState(false);
  const [assignFormDialogOpen, setAssignFormDialogOpen] = useState(false);
  const [unassignFormConfirmationDialogOpen, setUnassignFormConfirmationDialogOpen] = useState(
    false
  );
  const [viewFormResponsesDialogueOpen, setViewFormResponsesDialogueOpen] = useState(false);
  const [
    formInstanceForResponsesDialogue,
    setFormInstanceForResponsesDialogue
  ] = useState<FormInstanceResultRow | null>(null);

  const currentFormData =
    selectedTab === FORM_TAB.IN_PERSON ? inPersonPatientFormsData : oneTimePatientFormsData;
  const currentFormType: PatientFormInstancesType =
    selectedTab === FORM_TAB.IN_PERSON ? 'inPerson' : 'oneTime';

  const handleTabChange = (id: string) => {
    setSelectedTab(id as FORM_TAB);

    // initialize one-time forms
    if (id === FORM_TAB.ONE_TIME && !oneTimePatientFormsData) {
      dispatch(
        getFormInstancesByPatientThunk({
          patientFormInstancesType: 'oneTime',
          patientId: patient?.id
        })
      );
    }
  };

  const handleAssignForm = (formId: UuidType | null, dueDate: Date | null) => {
    if (formId) {
      dispatch(
        assignFormToPatientThunk(selectedTab === FORM_TAB.ONE_TIME, formId, patient?.id, dueDate)
      );
    }
  };
  const handleUnassignForm = (formInstance: FormInstanceResultRow) => {
    setFormToUnassign(formInstance);
    setUnassignFormConfirmationDialogOpen(true);
  };
  const handleDeleteFormInstance = () => {
    if (formToUnassign) {
      dispatch(deleteFormInstanceThunk(formToUnassign.id));
    }
  };

  const handleSetDueDate = (formId: UuidType) => {
    setFormToAssign(formId);
    setDueDateDialogOpen(true);
  };

  const handleConfirmDueDate = (selectedDate: Date) => {
    setDueDateDialogOpen(false);
    handleAssignForm(formToAssign, selectedDate);
  };

  const handleSort = (sort: string, order: string) => {
    if (currentFormData) {
      dispatch(
        getFormInstancesByPatientThunk({
          patientFormInstancesType: currentFormType,
          patientId: patient?.id,
          page: 0,
          pageSize: currentFormData.pageSize,
          search: currentFormData.search,
          sort,
          order
        })
      );
    }
  };
  const handleSearch = (value: string) => {
    if (currentFormData) {
      dispatch(
        getFormInstancesByPatientThunk({
          patientFormInstancesType: currentFormType,
          patientId: patient?.id,
          page: 0,
          pageSize: currentFormData.pageSize,
          search: value,
          sort: currentFormData.sort,
          order: currentFormData.order
        })
      );
    }
  };
  const handleNextButton = async () => {
    if (
      currentFormData &&
      currentFormData.page < currentFormData.total / currentFormData.pageSize
    ) {
      await dispatch(
        getFormInstancesByPatientThunk({
          patientFormInstancesType: currentFormType,
          patientId: patient?.id,
          page: currentFormData.page + 1,
          pageSize: currentFormData.pageSize,
          search: currentFormData.search,
          sort: currentFormData.sort,
          order: currentFormData.order
        })
      );
    }
  };
  const handlePrevButton = async () => {
    if (currentFormData && currentFormData.page > 0) {
      await dispatch(
        getFormInstancesByPatientThunk({
          patientFormInstancesType: currentFormType,
          patientId: patient?.id,
          page: currentFormData.page - 1,
          pageSize: currentFormData.pageSize,
          search: currentFormData.search,
          sort: currentFormData.sort,
          order: currentFormData.order
        })
      );
    }
  };
  const handlePageSize = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    const pageSize: number = e.target.value as number;

    if (currentFormData && pageSize > 0) {
      dispatch(
        getFormInstancesByPatientThunk({
          patientFormInstancesType: currentFormType,
          patientId: patient?.id,
          page: 0,
          pageSize,
          search: currentFormData.search,
          sort: currentFormData.sort,
          order: currentFormData.order
        })
      );
    }
  };

  const handleViewFormResponsesDialogueOpen = (form: FormInstanceResultRow) => {
    setFormInstanceForResponsesDialogue(form);
    setViewFormResponsesDialogueOpen(true);
  };

  const tabComponents: TabComponent[] = [
    {
      component: (
        <Box pb={4} pt={1} px={4}>
          <PatientFormsTable
            formsData={inPersonPatientFormsData}
            inPerson
            handleDelete={handleUnassignForm}
            handlePageSize={handlePageSize}
            handleNextButton={handleNextButton}
            handlePrevButton={handlePrevButton}
            handleSort={handleSort}
            handleFormResponsesDialogue={handleViewFormResponsesDialogueOpen}
            selectedTab={selectedTab}
          />
        </Box>
      ),
      id: FORM_TAB.IN_PERSON,
      label: 'In-person Forms'
    },
    {
      component: (
        <Box pb={4} pt={1} px={4}>
          <PatientFormsTable
            formsData={oneTimePatientFormsData}
            handleDelete={handleUnassignForm}
            handlePageSize={handlePageSize}
            handleNextButton={handleNextButton}
            handlePrevButton={handlePrevButton}
            handleSort={handleSort}
            handleFormResponsesDialogue={handleViewFormResponsesDialogueOpen}
            selectedTab={selectedTab}
          />
        </Box>
      ),
      id: FORM_TAB.ONE_TIME,
      label: 'One-time Forms'
    }
  ];

  useEffect(() => {
    if (assignFormToPatientSuccess) {
      if (getFormInstancesByPatientSuccess) {
        // reset success states and close modal once update finishes
        dispatch(setSuccessAction('assignFormToPatientSuccess', false));
        dispatch(setSuccessAction('getFormInstancesByPatientSuccess', false));

        setAssignCollectionFormToPatientDialogOpen(false);
        setAssignLibraryFormToPatientDialogOpen(false);
      } else if (currentFormData) {
        // update forms list
        dispatch(
          getFormInstancesByPatientThunk({
            patientFormInstancesType: currentFormType,
            patientId: patient?.id,
            page: currentFormData.page,
            pageSize: currentFormData.pageSize,
            search: currentFormData.search,
            sort: currentFormData.sort,
            order: currentFormData.order
          })
        );
      }
    }
  }, [assignFormToPatientSuccess, getFormInstancesByPatientSuccess]);

  useEffect(() => {
    if (deleteFormInstanceSuccess) {
      if (getFormInstancesByPatientSuccess) {
        // reset success states and close modal once update finishes
        dispatch(setSuccessAction('deleteFormInstanceSuccess', false));
        dispatch(setSuccessAction('getFormInstancesByPatientSuccess', false));

        setUnassignFormConfirmationDialogOpen(false);
      } else if (currentFormData) {
        // update forms list
        dispatch(
          getFormInstancesByPatientThunk({
            patientFormInstancesType: currentFormType,
            patientId: patient?.id,
            page: currentFormData.page,
            pageSize: currentFormData.pageSize,
            search: currentFormData.search,
            sort: currentFormData.sort,
            order: currentFormData.order
          })
        );
      }
    }
  }, [deleteFormInstanceSuccess, getFormInstancesByPatientSuccess]);

  // initialize in-person form instances table
  useEffect(() => {
    dispatch(
      getFormInstancesByPatientThunk({
        patientFormInstancesType: 'inPerson',
        patientId: patient?.id
      })
    );
    // clearing out Store Data on Component Destroy
    return () => {
      dispatch(getPatientFormInstancesAction('inPerson', null));
      dispatch(getPatientFormInstancesAction('oneTime', null));
    };
  }, []);

  return (
    <>
      {assignFormDialogOpen && (
        <AssignFormDialog
          open={assignFormDialogOpen}
          handleAssignCollectionFormDialogOpen={() => {
            setAssignFormDialogOpen(false);
            setAssignCollectionFormToPatientDialogOpen(true);
          }}
          handleAssignLibraryFormDialogOpen={() => {
            setAssignFormDialogOpen(false);
            setAssignLibraryFormToPatientDialogOpen(true);
          }}
          handleClose={() => setAssignFormDialogOpen(false)}
        />
      )}

      {assignCollectionFormToPatientDialogOpen && (
        <AssignCollectionFormToPatientDialog
          open={assignCollectionFormToPatientDialogOpen}
          type={selectedTab}
          handleAssignForm={handleAssignForm}
          handleSetDueDate={handleSetDueDate}
          handleClose={() => setAssignCollectionFormToPatientDialogOpen(false)}
        />
      )}

      {assignLibraryFormToPatientDialogOpen && (
        <AssignCollectionFormToPatientDialog
          open={assignLibraryFormToPatientDialogOpen}
          library
          type={selectedTab}
          handleAssignForm={handleAssignForm}
          handleSetDueDate={handleSetDueDate}
          handleClose={() => setAssignLibraryFormToPatientDialogOpen(false)}
        />
      )}

      {dueDateDialogOpen && (
        <DatepickerDialog
          title="Due Date"
          open={dueDateDialogOpen}
          disablePastDates={true}
          handleConfirmClick={handleConfirmDueDate}
          handleClose={() => setDueDateDialogOpen(false)}
        />
      )}

      {unassignFormConfirmationDialogOpen && formToUnassign && (
        <ConfirmationDialog
          open={unassignFormConfirmationDialogOpen}
          handleClose={() => setUnassignFormConfirmationDialogOpen(false)}
          loading={deleteFormInstanceLoading || getFormInstancesByPatientLoading}
          message={`Are you sure you want to unassign ${formToUnassign.title} from ${concatName({
            firstName: patient?.first_name,
            fallback: patient?.email ?? '<No Patient Name Available>'
          })}?`}
          title="Unassign Form"
          handleConfirmClick={handleDeleteFormInstance}
        />
      )}
      {viewFormResponsesDialogueOpen && (
        <ViewFormResponsesDialogue
          open={viewFormResponsesDialogueOpen}
          handleClose={() => setViewFormResponsesDialogueOpen(false)}
          formInstanceResultRow={formInstanceForResponsesDialogue}
        />
      )}

      <Paper>
        <Box
          bgcolor={colors.background2}
          borderRadius="0.5rem"
          display="flex"
          flexDirection="column"
        >
          <Box
            className={classes.topBox}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            pt={3}
          >
            <Box alignItems="flex-start" display="flex" justifyContent="space-between" px={4}>
              <Typography variant="h2">{`Create tasks for ${concatName({
                firstName: patient?.first_name,
                fallback: patient?.email ?? '<No Patient Name Available>'
              })}`}</Typography>

              <Box alignItems="center" display="flex">
                <Box pr={2}>
                  <SearchBar
                    key={selectedTab}
                    initialValue={formsSearch[currentFormType]}
                    handleSearch={handleSearch}
                    setInitialValue={(value: string) =>
                      setFormsSearch({ ...formsSearch, [currentFormType]: value })
                    }
                  />
                </Box>

                <LoadingButton
                  className={classes.primaryButton}
                  color="primary"
                  onClick={() => setAssignFormDialogOpen(true)}
                  variant="contained"
                  aria-label={
                    selectedTab === FORM_TAB.IN_PERSON ? 'New In-Person Form' : 'New One-Time Form'
                  }
                >
                  {selectedTab === FORM_TAB.IN_PERSON ? 'New In-Person Form' : 'New One-Time Form'}
                </LoadingButton>
              </Box>
            </Box>
          </Box>

          <TabBar
            style={{
              tabBackground: true,
              spacing: {
                px: 4
              }
            }}
            tabComponents={tabComponents}
            onTabChange={handleTabChange}
          />
        </Box>
      </Paper>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  topBox: {
    borderRadius: '0.625rem 0.625rem 0 0'
  },
  selectedText: {
    color: colors.text7,
    fontWeight: 400
  },
  primaryButton: {
    boxShadow:
      '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)',
    height: '2rem'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)'
  }
}));
