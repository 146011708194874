import React, { useState } from 'react';
import { Box, Button, makeStyles, Tab, Theme } from '@material-ui/core';
import { SpacingProps } from '@material-ui/system';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';

import { colors } from '../styling/colors';

interface TabBarStyle extends React.CSSProperties {
  tabBackground?: boolean;
  spacing?: SpacingProps;
}

export interface TabComponent {
  component?: React.ReactNode;
  id: string;
  label: string;
}

interface TabBarProps {
  style?: TabBarStyle;
  tabComponents: TabComponent[];
  addNew?: boolean;
  addNewHandler?: () => void;
  onTabChange?: (id: string) => void;
}

export const TabBar = ({
  style,
  tabComponents,
  addNew,
  addNewHandler,
  onTabChange
}: TabBarProps) => {
  const classes = useStyles();

  const [activeTab, setActiveTab] = useState<TabComponent | null>(
    tabComponents ? tabComponents[0] : null
  );

  const handleTabChange = (e: React.ChangeEvent<{}>, value: string) => {
    const nextActiveTab = tabComponents.find((v) => v.id === value) ?? tabComponents[0];

    if (activeTab && nextActiveTab.id !== activeTab.id) {
      setActiveTab(nextActiveTab);

      if (onTabChange) {
        onTabChange(nextActiveTab.id);
      }
    }
  };

  return activeTab ? (
    <TabContext value={activeTab.id}>
      <Box
        bgcolor={style?.tabBackground ? colors.background2 : style?.background ?? 'none'}
        borderBottom={
          style?.tabBackground ? '0.0625rem solid #D1D6DC' : style?.borderBottom ?? 'inherit'
        }
        borderRadius="0.5rem 0.5rem 0 0"
        display="flex"
        flexDirection="column"
        maxWidth={style?.maxWidth ?? '80vw'}
        {...style?.spacing}
      >
        <Box display="flex">
          {addNew && (
            <Box mr="2rem" display="flex" alignItems="center">
              <Button onClick={addNewHandler} className={classes.addNewBtn}>
                <Box mr={0.4}>
                  <img src={'/icons/PlusIcon.svg'} alt="Add Icon" />
                </Box>
                New
              </Button>
            </Box>
          )}
          <TabList
            indicatorColor="primary"
            onChange={handleTabChange}
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{ scrollButtons: classes.scrollBtns }}
          >
            {tabComponents.map((v: TabComponent, i) => (
              <Tab key={`${v.id}-${i}`} label={v.label} value={v.id} style={{ maxWidth: '300px' }} />
            ))}
          </TabList>
        </Box>
      </Box>

      <Box bgcolor={colors.background3}>
        {tabComponents.map(
          (v: TabComponent, i) =>
            v.component && (
              <TabPanel key={`${v.id}-${i}`} value={v.id}>
                {v.component}
              </TabPanel>
            )
        )}
      </Box>
    </TabContext>
  ) : (
    <div />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  scrollBtns: {
    '&.Mui-disabled': {
      display: 'none'
    }
  },
  addNewBtn: {
    borderRadius: '1rem',
    height: '1.6rem',
    backgroundColor: colors.background6
  }
}));
