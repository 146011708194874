import React, { useEffect, useState } from 'react';
import { colors } from '../../../styling/colors';
import { Box, Theme, Typography, makeStyles, Grid, Divider, Chip, DialogActions, Paper, AppBar, Toolbar, Button } from '@material-ui/core';
import { Dialog } from '../../../components/Dialog';
import { ArrowBack, GetApp, Star, StarOutline, Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { UuidType } from '../../../shared/domain';
import { RootState } from '../../../redux/redux-store';
import { getCollectionFormsThunk, removeFavoriteCollectionThunk, markFavoriteCollectionThunk, downloadFormsThunk, addFavoritePatientThunk, removeFavoritePatientThunk } from '../../../redux/thunk/thunks';
import { CollectionFormTable } from '../../Collections/components/CollectionFormTable';
import { LoadingButton } from '../../../components/LoadingButton';
import { ROLES } from '../../../shared/globals';
import { useHistory } from 'react-router-dom';
import { SideBar } from '../../../components/ClinicianPatientSidebar';

export const ViewNestedCollection = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    let history = useHistory();
    const collectionProp: any = sessionStorage.getItem('collection');
    const collectionProps: any = JSON.parse(collectionProp);
    const [selectedCollectionForms, setSelectedCollectionForms] = useState<UuidType[]>([]);
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const [isConfirm, setIsConfirmOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const user: any = useSelector((state: RootState) => state.DATA_REDUCER.patientInfo);
    const patient: any = useSelector((state: RootState) => state.DATA_REDUCER.patientInfo);

    const collectionForms: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.collectionForms
    );
    useEffect(() => {
        if (collectionProps) {
            dispatch(getCollectionFormsThunk({ collectionId: collectionProps.id }))
        }
    }, [])

    const setEditCollection = async () => {
        if (loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role) && collectionProps.type === 'CUSTOM') {
            setIsConfirmOpen(true);
        } else {
            await dispatch(getCollectionFormsThunk({ collectionId: collectionProps.id }));
        }
    }

    const handelConfirmed = async () => {
        setIsConfirmOpen(false);
        await dispatch(getCollectionFormsThunk({ collectionId: collectionProps.id }));
    }

    const loadMore = async (getCollectionFormsProps: any) => {
        await dispatch(getCollectionFormsThunk({ collectionId: collectionProps.id, domain_id: getCollectionFormsProps.domainId, sort_by: getCollectionFormsProps.sort, order: getCollectionFormsProps.order }))
    }

    const handelFavoriteForm = async (value: boolean) => {
        if (collectionProps) {
            setLoading(true);
            if (value) {
                await dispatch(removeFavoriteCollectionThunk(collectionProps.id));
                collectionProps.isFavourite = false;
                sessionStorage.setItem('collection', JSON.stringify(collectionProps));
            } else {
                await dispatch(markFavoriteCollectionThunk(collectionProps.id));
                collectionProps.isFavourite = true;
                sessionStorage.setItem('collection', JSON.stringify(collectionProps));
            }
            setLoading(false);
            // const nestedPath: any = sessionStorage.getItem('nested_route');
            // history.push(nestedPath);
            // sessionStorage.removeItem('nested_route');
            // sessionStorage.removeItem('collection');
        }
    }
    const handelFavoriteCall = () => { }

    const downloadForms = async () => {
        const data: any = []
        for (let i = 0; i < selectedCollectionForms.length; i++) {
            const version_id = collectionForms.filter((item: any) => item.id === selectedCollectionForms[i])
            const info = {
                "form_id": selectedCollectionForms[i],
                "form_version_id": version_id[0].form_version_id
            }
            data.push(info);
        }
        const links: any = await dispatch(downloadFormsThunk(data));
        if (links) {
            for (let i = 0; i < links.length; i++) {
                window.open(links[i].file_url, "_blank");
            }
        }
    }

    const handelSet = () => {
        collectionProps.session = 0;
        sessionStorage.setItem('item', JSON.stringify(collectionProps));
    }

    const handelCloseView = () => {
        const nestedPath: any = sessionStorage.getItem('nested_route');
        history.push(nestedPath);
        sessionStorage.removeItem('nested_route');
        sessionStorage.removeItem('collection');
    }

    const handleClick = () => {
        sessionStorage.removeItem('role');
        sessionStorage.removeItem('pat_org_id');
        history.push('/patients')
    }

    const handelFavoriteResources = async (value: boolean) => {
        setLoading(true);
        value ? await dispatch(addFavoritePatientThunk([user.user_role_id])) : await dispatch(removeFavoritePatientThunk([user.user_role_id]));
        setLoading(false);
    }

    const handelIsFavourite = async (value: boolean) => {
        !loading && await handelFavoriteResources(value);
    }

    return (
        <Box style={{ height: '100vh', background: '#F1F5F9' }}>
            <Box style={{ top: 0, position: 'fixed', height: '100px', background: '#fff', display: 'flex', alignItems: 'center', width: '100%', boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)', zIndex: 10 }}>
                <Box style={{ display: 'flex', alignItems: 'center', paddingLeft: '30px' }}>
                    <Close onClick={handleClick} style={{ height: '26px', width: '26px', cursor: 'pointer' }} /> <Typography style={{ fontSize: '18px', marginLeft: '10px', cursor: 'pointer' }} onClick={handleClick}>Close</Typography>
                    <Divider orientation="vertical" variant="middle" flexItem style={{ height: '50px' }} />
                    <Typography style={{ fontSize: '22px', fontWeight: 600 }}>{user && (user?.first_name + ' ' + user?.last_name)}</Typography>
                    {user && user?.isPatientFavourite ? <Star color='primary' style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => handelIsFavourite(false)} /> : <StarOutline color='primary' style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => handelIsFavourite(true)} />}
                </Box>
            </Box>
            <AppBar square elevation={0} classes={{ root: classes.headerRoot }}>
                <Toolbar classes={{ root: classes.headerToolbar }}>
                    <Button classes={{ text: classes.closeBtnText }} startIcon={<ArrowBack style={{ color: 'gray', fontSize: '25px' }} />}
                        onClick={() => handleClick()}>
                        Return
                    </Button>
                    <Box alignSelf="stretch" display="flex" pr={3} py={2}>
                        <Divider flexItem orientation="vertical" />
                    </Box>
                    <Box display="flex" alignItems='center' justifyContent="flex-start" minHeight="5rem" width="100%">
                        <Box>
                            <Typography className={classes.subtitle}>
                                {`PATIENT > ${patient && (patient?.first_name + ' ' + patient?.last_name)} >`}
                            </Typography>
                            <Typography color="textSecondary" variant="h2" style={{ marginTop: '6px', fontSize: '20px' }}>
                                <span style={{ color: 'gray' }}>Shared User: </span>{user && (' ' + user?.first_name + ' ' + user?.last_name)}
                            </Typography>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box style={{ marginTop: '100px' }}>
                <Box className={classes.main} display="flex" flexDirection="column" p={4}>
                    <Grid container>
                        <Grid item xs={12} sm={2} md={2} style={{ paddingLeft: '10px' }}>
                            <SideBar />
                        </Grid>
                        <Grid item xs={12} sm={10} md={10}>
                            <Paper>
                                <Box display="flex" flexDirection="column">
                                    <Box pt={3} px={3}>
                                        <Grid container>
                                            <Grid item xs={12} sm={10} md={10} style={{ display: 'flex' }}>
                                                <Grid container>
                                                    <Grid item xs={2} sm={1} md={1} style={{ display: 'flex' }}>
                                                        <ArrowBack style={{ fontSize: '30px', color: 'gray', cursor: 'pointer', marginTop: '10px' }} onClick={() => handelCloseView()} />
                                                        <Divider orientation="vertical" style={{ margin: '3px 40px 0px 15px', height: '40px' }} />
                                                    </Grid>
                                                    <Grid item xs={10} sm={11} md={11}>
                                                        <Box pb={2.5}>
                                                            <Box pb={0.75}>
                                                                <Typography style={{ color: 'gray' }} variant="subtitle2">COLLECTION</Typography>
                                                                <Box display='flex' alignItems="center" mt={0.3}>
                                                                    <Typography variant="h2">{collectionProps.title}</Typography>
                                                                    {collectionProps && collectionProps.isFavourite ? <Star color='primary' style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => { loading ? handelFavoriteCall() : handelFavoriteForm(true) }} /> : <StarOutline color='primary' style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => { loading ? handelFavoriteCall() : handelFavoriteForm(false) }} />}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Box pb={2}>
                                                            <Box pt={1}>
                                                                <Typography color="textSecondary" variant="subtitle2">
                                                                    {collectionProps.description}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} sm={2} md={2} style={{ textAlign: 'right' }}>
                                                {!collectionProps && <Box>
                                                    {(loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role) && collectionProps.type === 'CUSTOM') ? <LoadingButton
                                                        className={classes.primaryButton}
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={(e) => setEditCollection()}
                                                    >
                                                        Edit
                                                    </LoadingButton> :
                                                        (loggedInUser && ![ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role)) && <LoadingButton
                                                            className={classes.primaryButton}
                                                            color="primary"
                                                            variant="contained"
                                                            onClick={(e) => setEditCollection()}
                                                        >
                                                            Edit
                                                        </LoadingButton>}
                                                </Box>}
                                            </Grid>
                                        </Grid>

                                    </Box>

                                    <Divider variant="middle" />

                                    <Box
                                        className={classes.topBox}
                                        px={3}
                                        py={2.5}
                                    >
                                        <Typography variant="h4" style={{ fontWeight: 500 }}>Primary Domains</Typography>

                                        <Box pt={2} display='flex'>
                                            <div className={classes.otherActions}>
                                                {collectionProps && collectionProps.domains.map((item: any) => {
                                                    return (
                                                        <Chip
                                                            key={''}
                                                            classes={{ root: classes.collectionChip }
                                                            }
                                                            size="small"
                                                            // onClick={() => unassignCollection()}
                                                            label={
                                                                < div className={classes.chipAlign}>
                                                                    {item.title}
                                                                </div>
                                                            }
                                                        />)
                                                })}
                                            </div>
                                        </Box>
                                    </Box>

                                    <Divider variant="middle" />

                                    <Box
                                        className={classes.bottomBox}
                                        px={3}
                                        py={2}
                                    >
                                        <Typography variant="h4" style={{ fontWeight: 500 }}>Tags</Typography>
                                        <Box pt={2} display='flex'>
                                            <div className={classes.otherActions}>
                                                {collectionProps && collectionProps.tags.map((item: any) => {
                                                    return (
                                                        <Chip
                                                            key={''}
                                                            classes={{ root: classes.collectionChip }
                                                            }
                                                            size="small"
                                                            label={
                                                                < div className={classes.chipAlign}>
                                                                    {item.title}
                                                                </div>
                                                            }
                                                        />)
                                                })}
                                            </div>
                                        </Box>
                                    </Box>

                                    <Divider variant="middle" />
                                    <Box display="flex" justifyContent="space-between" alignItems='center' px={3} py={2}>
                                        <Box display="flex" alignItems='end' className={classes.contentContainer}>
                                            <Box mr={5}>
                                                <Typography variant="h4" style={{ fontWeight: 500, color: 'gray', marginBottom: '6px' }}>FORMS IN</Typography>
                                                <Typography variant="h2">{collectionProps.title}</Typography>
                                            </Box>
                                        </Box>
                                        <Box display='flex' alignItems='center'>
                                            {selectedCollectionForms && selectedCollectionForms.length > 0 &&
                                                <> <Typography style={{ color: '#575757', fontWeight: 400, fontSize: '13px' }}>{selectedCollectionForms.length} Selected</Typography>
                                                    <Divider orientation="vertical" style={{ margin: '0px 20px 0px 20px', height: '30px' }} />
                                                    <GetApp style={{ color: '#0c84c0', cursor: 'pointer' }} onClick={(e) => downloadForms()} />
                                                </>
                                            }
                                        </Box>
                                    </Box>
                                    <Box pb={2} px={4}>
                                        <CollectionFormTable collectionForms={collectionForms} selectedFormFiles={selectedCollectionForms} setSelectedFormFiles={setSelectedCollectionForms} isView={false} loadMore={loadMore} handelSet={handelSet} />
                                    </Box>
                                    {isConfirm &&
                                        <Dialog open={isConfirm}>
                                            <Box style={{ width: '500px', background: '#f9fafb', borderRadius: '10px 10px 0px 0px' }}>
                                                <Box p={3} display="flex" justifyContent="space-between">
                                                    <Typography variant="h3">Are you sure you want to edit this collection?</Typography>
                                                </Box>
                                            </Box>
                                            <Box p={3}>
                                                <Typography>This collection is currently assigned to {'__'} users.</Typography>
                                                <Typography>This collection is currently in progress by {'__'} users.</Typography>
                                                <Typography>This collection has been completed by {'__'} users.</Typography>
                                            </Box>
                                            <DialogActions style={{ justifyContent: 'space-between', background: '#f9fafb', borderRadius: '0px 0px 10px 10px', padding: '20px 24px' }}>
                                                <LoadingButton style={{ border: '1px solid rgba(0, 0, 0, 0.15)', width: '120px' }}
                                                    variant="contained"
                                                    onClick={() => setIsConfirmOpen(false)}
                                                >
                                                    Cancel
                                                </LoadingButton>
                                                <LoadingButton color="primary"
                                                    variant="contained"
                                                    style={{ boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)', width: '120px' }}
                                                    onClick={(e) => handelConfirmed()}
                                                >
                                                    Edit
                                                </LoadingButton>
                                            </DialogActions>
                                        </Dialog>
                                    }
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>

            </Box>
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    topBox: {
        borderRadius: '0.625rem 0.625rem 0 0'
    },
    bottomBox: {
        borderRadius: '0 0 0.625rem 0.625rem'
    },
    textField: {
        width: '100%'
    },
    primaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#fff',
        minWidth: '120px',
        marginBottom: '20px'
    },
    secondaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#0060B2',
        marginBottom: '20px',
        minWidth: '120px'
    },
    deleteButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: 'red',
        minWidth: '120px'
    },
    createCollectionTextField: {
        maxWidth: '40rem',
        width: '80vw'
    },
    chipAlign: {
        display: 'flex',
        alignItems: 'center'
    },
    otherActions: {
        marginLeft: '.6rem',
        alignItems: 'center'
    },
    collectionChip: {
        marginRight: theme.spacing(1),
        backgroundColor: theme.background.main
    },
    searchTextField: {
        backgroundColor: theme.palette.secondary.main,
        fontSize: theme.typography.h5.fontSize,
        height: '2rem',
        width: '14rem'
    },
    searchIcon: {
        fontSize: 17
    },
    contentContainer: {
        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap'
        }
    },
    main: {
        [theme.breakpoints.down('xs')]: {
            padding: '1rem'
        },
        backgroundColor: colors.background1,
        minHeight: '100vh'
    },
    headerRoot: {
        backgroundColor: 'white',
        borderBottom: `2px solid ${theme.palette.divider}`,
        padding: 0
    },
    headerToolbar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    closeBtnText: {
        color: 'gray',
        marginRight: 18,
        fontSize: '18px',
        '&:hover': {
            backgroundColor: 'unset'
        }
    },
    subtitle: {
        color: colors.text6,
        fontWeight: 600,
        fontSize: '16px'
    },
}));
