import { Box, Divider, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavTab, NavTabs } from '../../../components/NavTabs';
import { RootState } from '../../../redux/redux-store';
import {
  getExitPageCalculationsThunk,
  getExitPageLogicRulesThunk
} from '../../../redux/thunk/thunks';
import { ExitPageCalculation } from './exitPageSidebarSettings/ExitPageCalculation';
import { ExitPageLogic } from './exitPageSidebarSettings/ExitPageLogic';
import { ExitPageSettings } from './exitPageSidebarSettings/ExitPageSettings';

enum NavEnums {
  Settings = 0,
  Logic = 1,
  Calculation = 2
}
export const ExitPageSettingsView: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [nav, setNav] = useState<NavEnums>(NavEnums.Settings);

  const handleNavChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setNav(newValue);
  };

  const editingExitPage = useSelector((state: RootState) => state.DATA_REDUCER.editingExitPage);

  //fetch the logic and calculations for the exitpage
  useEffect(() => {
    if (editingExitPage) {
      dispatch(getExitPageLogicRulesThunk(editingExitPage.id));
      dispatch(getExitPageCalculationsThunk(editingExitPage.id));
    }
  }, []);

  return (
    <Box>
      <div className={classes.drawerHeaderContainer}>
        <div className={classes.drawerHeader}>
          <div className={classes.headLeft}>
            <Typography color={'textPrimary'} variant={'h6'}>
              Exit Page
            </Typography>
          </div>
        </div>
        <NavTabs value={nav} onChange={handleNavChange}>
          <NavTab label="Settings" />
          <NavTab label="Logic" />
          {/* <NavTab label="Calculation" /> */}
        </NavTabs>
      </div>
      <Divider />
      {nav === NavEnums.Settings ? <ExitPageSettings /> : null}
      {nav === NavEnums.Logic ? <ExitPageLogic /> : null}
      {/* {nav === NavEnums.Calculation ? <ExitPageCalculation /> : null} */}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    borderRight: `2px solid ${theme.palette.divider}`
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  settingsDrawer: {
    height: '100vh',
    backgroundColor: theme.palette.background.default
  },
  drawerHeaderContainer: {
    padding: theme.spacing(3),
    paddingBottom: 0
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  headLeft: {},
  headRight: {},
  fieldView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4)
  },
  fieldContainer: {
    width: '100%'
  }
}));
