import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as MuiLink, Theme, Typography, makeStyles, Popover, Divider, Paper, Box } from '@material-ui/core';
import { MoreHoriz, Close } from '@material-ui/icons';
import { Dialog } from '../../../components/Dialog';
import { UuidType } from '../../../shared/domain';
import { GetOrganizationProps, inviteOrganizationThunk, cancelInviteOrganizationThunk, activateOrganizationThunk, deActivateOrganizationThunk } from '../../../redux/thunk/thunks';
import {
    HeadCell,
    LastSorted,
    Pagination,
    RowCell,
    Table
} from '../../../components/Table';
import { colors } from '../../../styling/colors';

interface OrganizationTableProps {
    dataList: any | null;
    selectedForms?: UuidType[];
    handleDeleteUserButton: (user: any) => void;
    loadOrganization: (getOrganizationProps: GetOrganizationProps) => void;
    setSelectedForms?: (selectedForms: UuidType[]) => void;
    viewOrganizationOpen: (info: any, isOpen: boolean) => void;
}

export const OrganizationTable = ({
    dataList, selectedForms, handleDeleteUserButton,
    loadOrganization, setSelectedForms, viewOrganizationOpen
}: OrganizationTableProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null)
    const [isNumber, setNumber] = useState(null)
    const [popuerInfo, setPopuerInfo] = useState<any>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [lastSorted, setLastSorted] = useState<LastSorted>({
        column: 'name',
        order: 'desc'
    });

    const refActions = (event: any, userInfo: any) => {
        setAnchorEl(event.currentTarget);
        setPopuerInfo(userInfo)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const headCells: HeadCell[] = [
        {
            id: 'name',
            label: 'ORGANIZATION NAME'
        },
        {
            id: 'contact_name',
            label: 'CONTACT NAME'
        },
        {
            id: 'contact_email',
            label: 'CONTACT EMAIL'
        },
        {
            id: 'status',
            label: 'STATUS'
        },
        {
            id: 'created_at',
            label: 'CREATED AT'
        },
        {
            align: 'right',
            id: 'action',
            label: '',
            isSortDisabled: true
        }
    ];
    const rowCells = dataList && dataList?.data.map((form: any, index: any) => {
        const contactName = (
            <Typography>
                {form.point_of_contact_first_name + ' ' + form.point_of_contact_last_name}
            </Typography>
        );
        const TitleLink = (
            <Typography color="primary" variant="h5" style={{ cursor: 'pointer' }} onClick={(e) => viewOrganizationOpen(form, true)}>
                {form.organization_name}
            </Typography>
        );
        const contactEmail = (
            <Typography variant="h5">
                {form.point_of_contact_email}
            </Typography>
        );
        const strReplace = (
            <Typography variant="h5">{form.status.replace(/_/g, " ")}</Typography>
        )
        var options: any = { weekday: 'long' };
        let createdDate = new Date(form?.created_at);
        const rowCell: RowCell = {
            name: { component: TitleLink, value: form.organization_name },
            contact_name: {
                component: contactName
            },
            contact_email: {
                component: contactEmail
            },
            status: {
                align: 'left',
                component: strReplace
            },
            created_at: {
                value: new Intl.DateTimeFormat('en-US', options).format(createdDate) + ', ' + (("0" + (createdDate.getMonth() + 1)).slice(-2)) + '/' + ("0" + createdDate.getDate()).slice(-2) + '/' + createdDate.getFullYear()
            },
            action: {
                align: 'right',
                component: <><MoreHoriz className={classes.launchIcon} onClick={(e) => refActions(e, form)} />
                    <Popover className='paper'
                        id={'simple-popover'}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Paper style={{ boxShadow: 'unset' }}>
                            <Typography className={classes.actionTypography} onClick={(e) => viewOrganizationOpen(popuerInfo, true)}>Edit Organization</Typography>
                            <Divider />
                            {popuerInfo && popuerInfo.status === "AWAITING_ACCEPTANCE" &&
                                <><Typography className={classes.actionTypography} onClick={(e) => cancelInvite(popuerInfo)}>Cancel Invite</Typography>
                                    <Divider /></>}
                            {popuerInfo && (popuerInfo.status === "INVITE_NOT_SENT" || popuerInfo.status === "INVITE_EXPIRED" || popuerInfo.status === "INVITE_CANCELLED") &&
                                <><Typography className={classes.actionTypography} onClick={(e) => sendInvite(popuerInfo)}>Send Invite</Typography>
                                    <Divider /></>}
                            {popuerInfo && (popuerInfo.status === "INVITE_NOT_SENT" || popuerInfo.status === "AWAITING_ACCEPTANCE" || popuerInfo.status === "INVITE_EXPIRED" || popuerInfo.status === "INVITE_CANCELLED" || popuerInfo.status === "ACTIVE") &&
                                <Typography color='error' className={classes.actionTypography} onClick={(e) => deactiveOrganization(popuerInfo)}>De-Activate</Typography>
                            }
                            {popuerInfo && popuerInfo.status === "DISABLED" &&
                                <Typography color='primary' className={classes.actionTypography} onClick={(e) => activeOrganization(popuerInfo)}>Activate</Typography>
                            }
                        </Paper>
                    </Popover>
                </>
            }
        };

        return Object.assign(rowCell, { content: form });
    });

    const handleNextButton = async () => {
        if (
            dataList &&
            dataList?.page >= 0 &&
            dataList?.page < dataList?.totalCount / dataList?.pageSize - 1
        ) {
            await loadOrganization({
                search: dataList?.search,
                page: dataList?.page + 1,
                pageSize: dataList?.pageSize,
                sort: dataList?.sort,
                order: dataList?.order
            });
        }
    };
    const handlePrevButton = async () => {
        if (dataList && dataList?.page > 0) {
            await loadOrganization({
                search: dataList?.search,
                page: dataList?.page - 1,
                pageSize: dataList?.pageSize,
                sort: dataList?.sort,
                order: dataList?.order
            });
        }
    };

    const handlePageSize = (
        e: React.ChangeEvent<{
            name?: string;
            value: unknown;
        }>
    ) => {
        const pageSize: number = e.target.value as number;

        if (dataList && pageSize > 0) {
            loadOrganization({
                page: 0,
                pageSize: pageSize,
                search: dataList?.search,
                sort: dataList?.sort,
                order: dataList?.order
            });
        }
    };

    const pagination: Pagination | undefined = dataList
        ? {
            contentName: 'Organizations',
            page: dataList?.page,
            pageSize: dataList?.pageSize,
            total: dataList?.totalCount,
            handleNextButton: handleNextButton,
            handlePageSize: handlePageSize,
            handlePrevButton: handlePrevButton
        }
        : undefined;

    const handleSort = (sort: string) => {
        const nextDirection = lastSorted.order === 'desc' ? 'asc' : 'desc';

        loadOrganization({
            order: sort === lastSorted.column ? nextDirection : 'desc',
            page: 0,
            pageSize: dataList?.pageSize,
            search: dataList?.search,
            sort
        });

        setLastSorted(
            sort === lastSorted.column
                ? { column: sort, order: nextDirection }
                : { column: sort, order: 'desc' }
        );
    };

    const sendInvite = (organization: any) => {
        dispatch(inviteOrganizationThunk(organization.id, dataList?.page, dataList?.pageSize));
        setIsOpen(true)
        setAnchorEl(null)
    }
    const cancelInvite = (organization: any) => {
        dispatch(cancelInviteOrganizationThunk(organization.id, dataList?.page, dataList?.pageSize));
        setAnchorEl(null)
    }

    const deactiveOrganization = (organization: any) => {
        dispatch(deActivateOrganizationThunk(organization.id, dataList?.page, dataList?.pageSize));
        setAnchorEl(null)
    }

    const activeOrganization = (organization: any) => {
        dispatch(activateOrganizationThunk(organization.id, dataList?.page, dataList?.pageSize));
        setAnchorEl(null)
    }

    const handleCloseDialog = () => {
        setIsOpen(false)
    }

    return (
        <>
            <Table
                data={dataList}
                headCells={headCells}
                // loading={dataList?.collectionId !== collectionId}
                pagination={pagination}
                rowCells={rowCells}
                // selection={
                //     selectedForms && setSelectedForms
                //         ? {
                //             selectedContents: selectedForms,
                //             setSelectedContents: (selected: UuidType[]) => setSelectedForms(selected)
                //         }
                //         : undefined
                // }
                sort={{
                    lastSorted: lastSorted,
                    handleSort: handleSort
                }}
            />
            {isOpen &&
                <Dialog handleClose={handleCloseDialog} open={isOpen}>
                    <Box style={{ width: '400px' }}>
                        <Box pb={3} pt={2} px={4} display="flex" justifyContent="space-between">
                            <Typography color="textSecondary" variant="h2">Invitation Success</Typography>
                            <Close onClick={() => setIsOpen(false)} />
                        </Box>
                        <Box pb={6} pt={2} display="flex" justifyContent="center">
                            <Typography color="textSecondary" variant="h2">Invitation Sent</Typography>
                        </Box>
                    </Box>
                </Dialog>
            }
        </>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    collectionBubble: {
        borderRadius: '0.75rem'
    },
    clearIcon: {
        color: colors.text6,
        fontSize: 14,
        marginLeft: '0.25rem'
    },
    launchIcon: {
        fontSize: '2rem',
        cursor: 'pointer'
    },
    actionTypography: {
        padding: '10px',
        fontWeight: 'bold',
        cursor: 'pointer'
    },
}));
