import { Box, Grid, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardHeader } from '../../../components/LegacyCardHeader';
import { RootState } from '../../../redux/redux-store';
import { GetReferralsProps, getReferralsThunk, getReferralsFilterThunk, getPatientReferralsThunk } from '../../../redux/thunk/referral-thunks';
import { UuidType } from '../../../shared/domain';
import { ReferralFiltersDialog } from './ReferralFilters';
import { ReferralsTable } from './ReferralsTable';
import { setSuccessAction } from '../../../redux/display/display-actions';
import { ROLES } from '../../../shared/globals';

export const Referrals = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchPattern, setSearchPattern] = useState('');
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);
  const referrals: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.referralsSearchResults.referrals
  );
  const [openFilter, setFilterDialog] = useState(false);
  const [onTab, setOnTab] = useState('');
  const [viewReferralDialogueOpen, setViewReferralDialogueOpen] = useState(false);

  const success: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.saveReferralSuccess)
  useEffect(() => {
    if (success) {
      setViewReferralDialogueOpen(false);
      dispatch(setSuccessAction('saveReferralSuccess', false));
      (onTab === 'Referral Library' || onTab === "Favorites") ?
        dispatch(getReferralsThunk({ search: searchPattern, page: referrals?.page, pageSize: referrals?.pageSize, sort: referrals?.sort, order: referrals?.order, favourites: referrals?.favourites, filters: referrals?.filters, referral_type: referrals?.referral_type })) :
        dispatch(getPatientReferralsThunk({ search: searchPattern, page: referrals?.page, pageSize: referrals?.pageSize, sort: referrals?.sort, order: referrals?.order, favourites: referrals?.favourites, filters: referrals?.filters, referral_type: referrals?.referral_type }));
    }
  })

  const viewReferralOpen = (open: any) => {
    setViewReferralDialogueOpen(open);
  };

  const filterReferralOpen = () => {
    setFilterDialog(true);
  };
  const filterReferralClose = () => {
    setFilterDialog(false);
  };

  const handleSearch = (value: string) => {
    setSearchPattern(value);
    if (referrals) {
      (onTab === 'Referral Library' || onTab === "Favorites") ?
        dispatch(
          getReferralsThunk({
            favourites: referrals?.favourites,
            order: referrals?.order,
            pageSize: referrals?.pageSize,
            search: value,
            sort: referrals?.sort,
            filters: referrals?.filters,
            referral_type: referrals?.referral_type
          })
        ) :
        dispatch(
          getPatientReferralsThunk({
            favourites: referrals?.favourites,
            order: referrals?.order,
            page: referrals?.page,
            pageSize: referrals?.pageSize,
            search: value,
            sort: referrals?.sort,
            filters: referrals?.filters,
            referral_type: referrals?.referral_type
          })
        );
    }
  };

  // We need async/await over here as it is used for some state loading task
  const loadReferrals = async (getReferralsProps: GetReferralsProps) => {
    if (onTab === 'Referral Library' || onTab === "Favorites") {
      await dispatch(getReferralsThunk(getReferralsProps));
    } else {
      await dispatch(getPatientReferralsThunk(getReferralsProps));
    }
  };

  useEffect(() => {
    dispatch(getReferralsFilterThunk({ type: 'ALL' }));
  }, []);

  const onFavoritesTabChange = async (e: any) => {
    if (e === 'Favorites' && (onTab !== e || onTab === '')) {
      setOnTab(e);
      await dispatch(getReferralsThunk({ search: searchPattern, favourites: true, filters: referrals?.filters, referral_type: referrals?.referral_type }));
    }
  }

  const onReferralTabChange = async (e: any) => {
    if (e === 'Referral Library' && (onTab !== e || onTab === '')) {
      setOnTab(e);
      await dispatch(getReferralsThunk({ search: searchPattern, favourites: false, filters: referrals?.filters, referral_type: referrals?.referral_type }));
    }
  }

  const patientReferralTabChange = async (e: any) => {
    if ((e === 'Assigned Referrals' || e === 'Shared Referrals') && (onTab !== e || onTab === '')) {
      setOnTab(e);
      await dispatch(getPatientReferralsThunk({ search: searchPattern, favourites: false, filters: referrals?.filters, referral_type: referrals?.referral_type }));
    }
  }

  const ReferralsTableContainer = (
    <Box pb={4} pt={1} px={4}>
      <ReferralsTable
        referralsData={referrals}
        loadReferrals={loadReferrals}
        viewReferralOpen={viewReferralOpen}
        viewReferralDialogueOpen={viewReferralDialogueOpen}
        tab={onTab}
      />
    </Box>
  );

  return (
    <>
      {openFilter &&
        <ReferralFiltersDialog open={openFilter} handleClose={filterReferralClose} loadReferrals={loadReferrals} />
      }

      <Grid container>
        <Grid item xs={12}>
          <Paper>
            <Box display="flex" flexDirection="column">
              <CardHeader
                tabComponents={[
                  {
                    component: ReferralsTableContainer,
                    label: [ROLES.PATIENT].includes(loggedInUser.role) ? 'Assigned Referrals' : 'Shared Referrals',
                    style: {
                      background: true,
                      spacing: { pb: 0, pt: 3, px: 4 }
                    },
                    title: 'All Referral',
                    handleSearch: handleSearch,
                    onTabChange: patientReferralTabChange,
                    filter: filterReferralOpen
                  },
                  {
                    component: ReferralsTableContainer,
                    label: 'Referral Library',
                    style: {
                      background: true,
                      spacing: { pb: 0, pt: 3, px: 4 }
                    },
                    title: 'All Referral',
                    handleSearch: handleSearch,
                    onTabChange: onReferralTabChange,
                    filter: filterReferralOpen
                  },
                  {
                    component: ReferralsTableContainer,
                    label: 'Favorites',
                    style: {
                      background: true,
                      spacing: { py: 3, px: 4, pb: 0 }
                    },
                    title: 'All Referral',
                    handleSearch: handleSearch,
                    onTabChange: onFavoritesTabChange,
                    filter: filterReferralOpen
                  },
                ]}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  filterSection: {
    overflowY: 'auto',
    maxHeight: '35rem'
  }
});
