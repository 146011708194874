import { Box, makeStyles, Typography, Divider } from '@material-ui/core';
import { StarOutline, Star } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, RouteComponentProps, Switch, useRouteMatch, withRouter, useHistory } from 'react-router-dom';
import { RootState } from '../../redux/redux-store';
import { UuidType } from '../../shared/domain';
import { Close } from '@material-ui/icons';
import { ResultPage } from './Result/ResultPage';
import { FormPage } from './Forms/FormPage';
import { ReferralPage } from './Referrals/ReferralPage';
import { ResourcePage } from './Resources/ResourcePage';
import { AppointmentPage } from './Appointments/AppointmentPage';
import { SharedUserPage } from './SharedUser/SharedUserPage';
import { ProfilePage } from './PatientProfile/ProfilePage';
import { addFavoritePatientThunk, removeFavoritePatientThunk, getPatientInfoThunk } from '../../redux/thunk/thunks';
import { setSuccessAction } from '../../redux/display/display-actions';

interface RouterProps {
    formId: UuidType;
}

interface PatientRoutesProps extends RouteComponentProps<RouterProps> { }

const Routes: React.FC<PatientRoutesProps> = ({ match }) => {
    let { url } = useRouteMatch();
    const classes = useStyles();
    const dispatch = useDispatch();
    const history: any = useHistory();
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const [loading, setLoading] = useState(false);

    const user: any = useSelector((state: RootState) => state.DATA_REDUCER.patientInfo);
    const success: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.saveDataSuccess);
    useEffect(() => {
        if (success) {
            dispatch(setSuccessAction('saveDataSuccess', false));
            history.push('/patients');
        }
    })

    //On mount fetch and set form and fields data
    useEffect(() => {
        return function CleanUp() {
            //clear data from redux
        };
    }, []);

    const handleClick = () => {
        sessionStorage.removeItem('role');
        sessionStorage.removeItem('pat_org_id');
        history.push('/patients')
    }

    const handelFavoriteResources = async (value: boolean) => {
        setLoading(true);
        if (value) {
            await dispatch(addFavoritePatientThunk([user.user_role_id]));
            dispatch(getPatientInfoThunk(user.user_role_id));
        } else {
            await dispatch(removeFavoritePatientThunk([user.user_role_id]));
            dispatch(getPatientInfoThunk(user.user_role_id));
        }
        setLoading(false);
    }

    const handelIsFavourite = async (value: boolean) => {
        !loading && await handelFavoriteResources(value);
    }

    return (
        <Box style={{ height: '100vh', background: '#F1F5F9' }}>
            <Box style={{ top: 0, position: 'fixed', height: '100px', background: '#fff', display: 'flex', alignItems: 'center', width: '100%', boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)', zIndex: 10 }}>
                <Box style={{ display: 'flex', alignItems: 'center', paddingLeft: '30px' }}>
                    <Close onClick={handleClick} style={{ height: '26px', width: '26px', cursor: 'pointer' }} /> <Typography style={{ fontSize: '18px', marginLeft: '10px', cursor: 'pointer' }} onClick={handleClick}>Close</Typography>
                    <Divider orientation="vertical" variant="middle" flexItem style={{ height: '50px' }} />
                    <Typography style={{ fontSize: '22px', fontWeight: 600 }}>{user && (user?.first_name + ' ' + user?.last_name)}</Typography>
                    {user && user?.isPatientFavourite ? <Star color='primary' style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => handelIsFavourite(false)} /> : <StarOutline color='primary' style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => handelIsFavourite(true)} />}
                </Box>
            </Box>
            <Box style={{ marginTop: '100px' }}>
                <Switch>
                    <Route path={`${url}/result`}>
                        <ResultPage />
                    </Route>
                    <Route path={`${url}/forms`}>
                        <FormPage />
                    </Route>
                    <Route path={`${url}/resource`}>
                        <ResourcePage />
                    </Route>
                    <Route path={`${url}/referral`}>
                        <ReferralPage />
                    </Route>
                    <Route path={`${url}/appointment`}>
                        <AppointmentPage />
                    </Route>
                    <Route path={`${url}/sharedUser`}>
                        <SharedUserPage />
                    </Route>
                    <Route path={`${url}/profile`}>
                        <ProfilePage />
                    </Route>
                </Switch>
            </Box>
        </Box>
    );
};

export const PatientRoutes = withRouter(Routes);

const useStyles = makeStyles((theme) => ({
    main: {
        backgroundColor: theme.background.main,
        minHeight: '100vh'
    }
}));
