import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Tab,
  Badge,
  Theme,
  Typography,
  makeStyles,
  Link,
  Button
} from '@material-ui/core';
import { SpacingProps } from '@material-ui/system';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { ButtonProps } from '../styling/types';
import { LoadingOrError } from './LoadingOrError';
import { UuidType } from '../shared/domain';
import { colors } from '../styling/colors';
import { LoadingButton } from './LoadingButton';
import { SearchBar } from './SearchBar';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/redux-store';

interface Selected {
  primaryButton?: ButtonProps;
  secondaryButton?: ButtonProps;
  deleteButton?: ButtonProps;
  selectedContents: UuidType[];
  handleSelectedDelete?: () => void;
}

interface TabStyle {
  background?: boolean;
  spacing?: SpacingProps;
}

interface TabComponent {
  component?: React.ReactNode;
  label: string;
  primaryButton?: ButtonProps;
  secondaryButton?: ButtonProps;
  selected?: Selected;
  subtitle?: string;
  style?: TabStyle;
  title?: string;
  handleSearch?: (value: string) => void;
  onTabChange?: (value: any) => void;
  filter?: () => void;
}

interface CardHeaderProps {
  tabComponents?: TabComponent[];
}

export const CardHeader = ({ tabComponents }: CardHeaderProps) => {
  const classes = useStyles();
  const tabValue = sessionStorage.getItem('tab');
  const nextTab: any = tabComponents && tabComponents.find((v: TabComponent) => v.label === tabValue);
  const [activeTab, setActiveTab] = useState<TabComponent | null>(
    nextTab ? nextTab : tabComponents ? tabComponents[0] : null
  );

  const referrals: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.referralsSearchResults.referrals
  );
  const filtersCount = sessionStorage.getItem('key');
  const filterCount = referrals && referrals.filters && referrals.filters.gender ? (filtersCount ? parseInt(filtersCount) : 0) : 0;

  const handleTabChange = (e: React.ChangeEvent<{}>, value: string) => {
    if (tabComponents) {
      sessionStorage.setItem('tab', value);
      setActiveTab(tabComponents.find((v) => v.label === value) ?? tabComponents[0]);
    }
  };

  useEffect(() => {
    if (activeTab && tabComponents) {
      const nextTab = tabComponents.find((v: TabComponent) => v.label === activeTab.label) ?? tabComponents[0];

      setActiveTab(nextTab);

      if (nextTab.onTabChange) {
        nextTab.onTabChange(activeTab.label);
      }
    }
  }, [activeTab, tabComponents]);

  return activeTab && tabComponents ? (
    <TabContext value={activeTab.label}>
      <Box
        bgcolor={activeTab.style?.background ? colors.background2 : colors.background3}
        borderBottom={activeTab.style?.background ? '0.0625rem solid #D1D6DC' : 'inherit'}
        borderRadius="0.5rem 0.5rem 0 0"
        display="flex"
        flexDirection="column"
        {...activeTab.style?.spacing}
      >
        <Box display="flex" justifyContent="space-between" className={classes.contentContainer}>
          <Box style={{ marginBottom: '10px' }}>
            {activeTab.handleSearch && (<SearchBar handleSearch={activeTab.handleSearch} />)}
            {activeTab.filter &&
              (filterCount > 0 ? <Badge badgeContent={filterCount} color="primary"><Button onClick={activeTab.filter} style={{ marginLeft: '20px', border: '1px solid #c4c4c4', background: '#fff' }}>Filters</Button></Badge> :
                <Button onClick={activeTab.filter} style={{ marginLeft: '20px', border: '1px solid #c4c4c4', background: '#fff' }}>Filters</Button>)
            }
            {!activeTab.handleSearch && !activeTab.filter && <Typography variant="h2">{activeTab.title}</Typography>}
          </Box>

          <Box>
            {activeTab.selected?.selectedContents &&
              activeTab.selected.selectedContents.length > 0 ? (
              <Box alignItems="center" display="flex">
                <Typography
                  className={classes.selectedText}
                  variant="h5"
                >{`${activeTab.selected.selectedContents.length} selected`}</Typography>

                <Box alignSelf="stretch" display="flex" px={2}>
                  <Divider flexItem orientation="vertical" />
                </Box>

                {activeTab.selected.primaryButton && (
                  <Box pr={2}>
                    <LoadingButton
                      className={classes.primaryButton}
                      color="primary"
                      disabled={activeTab.selected.primaryButton.disabled}
                      loading={activeTab.selected.primaryButton.loading}
                      onClick={activeTab.selected.primaryButton.handleClick}
                      variant="contained"
                      aria-label={activeTab.selected.primaryButton.text}
                    >
                      {activeTab.selected.primaryButton.text}
                    </LoadingButton>
                  </Box>
                )}

                {activeTab.selected.secondaryButton && !activeTab.selected.selectedContents && (
                  <Box pr={2}>
                    <LoadingButton
                      className={classes.secondaryButton}
                      color="secondary"
                      disabled={activeTab.selected.secondaryButton.disabled}
                      loading={activeTab.selected.secondaryButton.loading}
                      onClick={activeTab.selected.secondaryButton.handleClick}
                      variant="contained"
                      aria-label={activeTab.selected.secondaryButton.text}
                    >
                      {activeTab.selected.secondaryButton.text}
                    </LoadingButton>
                  </Box>
                )}

                {activeTab.selected.deleteButton && (
                  <>
                    {/* {activeTab.label === 'Forms' ? <Box alignSelf="stretch" display="flex" mr={2}>
                      <Divider flexItem orientation="vertical" />
                    </Box> : ''} */}
                    <Box pr={2}>
                      <LoadingButton
                        className={classes.deleteButton}
                        color="secondary"
                        disabled={activeTab.selected.deleteButton.disabled}
                        loading={activeTab.selected.deleteButton.loading}
                        onClick={activeTab.selected.deleteButton.handleClick}
                        variant="contained"
                        aria-label={activeTab.selected.deleteButton.text}
                      >
                        {activeTab.selected.deleteButton.text}
                      </LoadingButton>
                    </Box>
                  </>
                )}

                {activeTab.label === 'Favorites' && activeTab.title !== 'Favorites Resources' && activeTab.title !== 'Forms Collection' && activeTab.title !== 'Favorites Referral' && activeTab.title !== 'Favorites Collections' && activeTab.title !== 'Favorites Forms' ? <Box alignSelf="stretch" display="flex" mr={2}>
                  <Divider flexItem orientation="vertical" />
                </Box> : ''}

                {activeTab.selected.selectedContents && activeTab.selected.primaryButton && activeTab.selected.primaryButton.text === 'Make Copy' && (<Box alignSelf="stretch" display="flex" pr={2}>
                  <Divider flexItem orientation="vertical" />
                </Box>)}

                {activeTab.selected.deleteButton ? null : activeTab.selected.selectedContents && (
                  activeTab.selected.primaryButton && (activeTab.label === 'Favorites' || activeTab.title === 'Favorites Resources' || activeTab.title === 'Favorites Referral' || activeTab.title === 'Forms Collection' || activeTab.title === 'Favorites Collections' || activeTab.title === 'Favorites Forms' || activeTab.selected.primaryButton.text === 'Assign to Patient' || activeTab.selected.primaryButton.text === 'Download' || activeTab.selected.primaryButton.text === 'Assign Resource') ? null : <Box pr={2}>
                    <LoadingButton
                      className={classes.deleteButton}
                      color="secondary"
                      onClick={activeTab.selected.handleSelectedDelete}
                      variant="contained"
                    >
                      Delete
                    </LoadingButton>
                  </Box>
                )}
                {activeTab.selected.selectedContents && activeTab.selected.secondaryButton && (<Box alignSelf="stretch" display="flex" pr={2}>
                  <Divider flexItem orientation="vertical" />
                </Box>)}
                {activeTab.selected.selectedContents && activeTab.selected.secondaryButton && (
                  <Box pr={2}>
                    <LoadingButton
                      className={classes.primaryButton}
                      color="primary"
                      disabled={activeTab.selected.secondaryButton.disabled}
                      loading={activeTab.selected.secondaryButton.loading}
                      onClick={activeTab.selected.secondaryButton.handleClick}
                      variant="contained"
                      aria-label={activeTab.selected.secondaryButton.text}
                    >
                      {activeTab.selected.secondaryButton.text}
                    </LoadingButton>
                  </Box>
                )}

                {/* {activeTab.selected.handleSelectedDelete && (
                  <Link
                    className={classes.secondaryButton}
                    color="error"
                    component="button"
                    onClick={activeTab.selected.handleSelectedDelete}
                    variant="h5"
                  >
                    Delete
                  </Link>
                )} */}
              </Box>
            ) : (
              <>
                {activeTab.secondaryButton && (
                  <Box pr={2}>
                    <LoadingButton
                      className={classes.secondaryButton}
                      color="secondary"
                      disabled={activeTab.secondaryButton.disabled}
                      loading={activeTab.secondaryButton.loading}
                      onClick={activeTab.secondaryButton.handleClick}
                      variant="contained"
                      aria-label={activeTab.secondaryButton.text}
                    >
                      {activeTab.secondaryButton.text}
                    </LoadingButton>
                  </Box>
                )}

                {activeTab.primaryButton && (
                  <Box>
                    <LoadingButton
                      className={classes.primaryButton}
                      color="primary"
                      disabled={activeTab.primaryButton.disabled}
                      loading={activeTab.primaryButton.loading}
                      onClick={activeTab.primaryButton.handleClick}
                      variant="contained"
                      aria-label={activeTab.primaryButton.text}
                    >
                      {activeTab.primaryButton.text}
                    </LoadingButton>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>

        {tabComponents.length > 1 && (
          <Box>
            <TabList
              indicatorColor="primary"
              onChange={handleTabChange}
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              classes={{ scrollButtons: classes.scrollBtns }}
            >
              {tabComponents.map((v: TabComponent, i) => (
                <Tab key={`${v.label}-${i}`} label={v.label} value={v.label} />
              ))}
            </TabList>
          </Box>
        )}
      </Box>

      <Box>
        {tabComponents.map(
          (v: TabComponent, i) =>
            v.component && (
              <TabPanel key={`${v.label}-${i}`} value={v.label}>
                {v.component}
              </TabPanel>
            )
        )}
      </Box>
    </TabContext>
  ) : (
    <LoadingOrError errorMsg={null} loading={true} loadingMsg="" noElevation />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  subtitle: {
    color: colors.text6,
    fontWeight: 600
  },
  searchTextField: {
    backgroundColor: colors.background3,
    fontSize: '13px',
    height: '2rem',
    width: '12rem'
  },
  searchIcon: {
    fontSize: 17
  },
  filterIcon: {
    padding: '0px 10px',
    fontSize: '30px',
    color: '#3061b2'
  },
  primaryButton: {
    boxShadow:
      '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)',
    height: '2rem',
    minWidth: '100px'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    height: '2rem',
    color: 'blue',
    minWidth: '100px'
  },
  deleteButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    height: '2rem',
    color: 'red',
    minWidth: '100px'
  },
  selectedText: {
    color: colors.text7,
    fontWeight: 400
  },
  deleteLink: {
    'textDecoration': 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  scrollBtns: {
    '&.Mui-disabled': {
      display: 'none'
    }
  },
  contentContainer: {
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  }
}));
