import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Login } from '../pages/Login/Login';
import { ChooseOrganization } from '../pages/Login/SSOLogin';
import { SSODisable } from '../pages/Login/SsoDisable';
import { RedirectPage } from '../pages/Login/Redirect';
import { RolePage } from '../pages/Login/role';
import { FormsPage } from '../pages/Forms/FormsPage';
import { FormsCollectionsPage } from '../pages/Forms-Collections/FormsPage';
import { CollectionsPage } from '../pages/Collections/CollectionsPage';
import { ResourcesPage } from '../pages/Resources/ResourcesPage';
import { PatientsPage } from '../pages/Patients/PatientsPage';
import { PatientForms } from '../pages/Patients/PatientForms/PatientFormPage';
import { PatientInformation } from '../pages/Patients/components/PatientInformation';
import { Signup } from '../pages/Signup/Signup';
import { history } from '../redux/redux-store';
import { CollectionDetailPage } from '../pages/Collections/CollectionDetail/CollectionDetailPage';
import { FormBuilderRoutes } from '../pages/formBuilder/FormBuilderRoutes';
import { PatientRoutes } from '../pages/Clinitian_Patient/PatientPage';
import { SharedUserRoutes } from '../pages/Clinitian_Patient/SharedUserPage';
import { PatientSharedUserRoutes } from '../pages/Patient_SharedUser/SharedUserPage';
import { ViewResultPage } from '../pages/Clinitian_Patient/Result/ViewResult/ViewResultPage';
import { PrivateRoute } from './PrivateRoute';
import { TakeForm } from '../pages/FormPreview/TakeForm';
import { ViewForm } from '../pages/FormPreview/ViewForm';
import { PatientDashboard } from '../pages/PatientDashboard/PatientDashboard';
import { AccountUsersPage } from '../pages/Profile/AccountUsers/AccountUsersPage';
import { RoleUsersPage } from '../pages/Profile/Roles/RoleUsersPage';
import { OrganizationProfilePage } from '../pages/Profile/Organization/OrganizationInfo';
import { MyProfilePage } from '../pages/Profile/MyProfile/MyProfilePage';
import { ErrorPage } from '../pages/ErrorPage/ErrorPage';
import { ForgotPassword } from '../pages/ForgotPassword/ForgotPassword';
import { SendLink } from '../pages/ForgotPassword/SendLink';
import { ResetPasswordByID } from '../pages/ForgotPassword/ResetPassword';
import { OrganizationsPage } from '../pages/Organizations/OrganizationsPage';
import { ReferralsPage } from '../pages/Referrals/ReferralsPage';
import { ReferralsPatient } from '../pages/ReferralsPatient/ReferralsPage';
import { ReferralLibrary } from '../pages/ReferralLibrary/ReferralLibrary';
import { ReferralProfile } from '../pages/Referrals/Referral_Role/ReferralProfilePage';
import { SupportPage } from '../pages/Referrals/Referral_Role_Support/MyProfile/MyProfilePage';
import { ReferralRolePage } from '../pages/Referrals/Referral_Role_Support/Roles/RoleUsersPage';
import { StylePage } from '../pages/FlowSheet/StylePage';
import { AppointmentPage } from '../pages/Appointments/AppointmentPage';
import { PatientAppointment } from '../pages/Patients/Appointments/PatientAppointment';
import { SwitchRoleScreen } from '../pages/Profile/Roles/components/SwitchRoleScreen';
import { SharedUserPage } from '../pages/SharedUsers/SharedUserPage';
import { ViewCollection } from '../pages/Forms-Collections/components/ViewCollection';
import { ViewNestedCollection } from '../pages/Clinitian_Patient/Collections/ViewCollection';
import { LoginDisable } from '../pages/Login/LoginDisable';
import Policy from '../pages/TermsAndPolicy/Policy';
import Terms from '../pages/TermsAndPolicy/Terms';
import { SessionPopup } from '../components/SessionPopup';

export const FlowRouter = () => {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        <Route path="/view-form/:formId/:instance_id/:version_id">
          <ViewForm />
        </Route>
        <PrivateRoute path="/forms" adminSuperAdmin>
          <FormsPage />
        </PrivateRoute>
        <PrivateRoute path="/forms-collections" clinicianOnly>
          <FormsCollectionsPage />
        </PrivateRoute>
        <PrivateRoute path="/collections" adminSuperAdmin>
          <CollectionsPage />
        </PrivateRoute>
        <PrivateRoute path="/collections/:collectionId" adminSuperAdmin>
          <CollectionDetailPage />
        </PrivateRoute>
        <PrivateRoute path="/patients/:patientId" adminSuperAdmin>
          <PatientForms />
        </PrivateRoute>
        <PrivateRoute path="/resources" adminSuperAdmin>
          <ResourcesPage />
        </PrivateRoute>
        <PrivateRoute path="/referrals" adminSuperAdmin>
          <ReferralsPage />
        </PrivateRoute>
        <PrivateRoute path="/referral-list" adminSuperAdmin>
          <ReferralsPatient />
        </PrivateRoute>
        <PrivateRoute path="/organizations" adminSuperAdmin>
          <OrganizationsPage />
        </PrivateRoute>
        <PrivateRoute path="/select-provider" adminSuperAdmin>
          <ReferralLibrary />
        </PrivateRoute>
        <PrivateRoute path="/patient-dashboard" patientOnly>
          <PatientDashboard />
        </PrivateRoute>
        <PrivateRoute path="/form-builder/:formId" adminSuperAdmin>
          <FormBuilderRoutes />
        </PrivateRoute>
        <PrivateRoute path="/form-preview/:formId" adminSuperAdmin>
          <TakeForm preview />
        </PrivateRoute>
        <PrivateRoute path="/form/:formInstanceId" adminSuperAdmin>
          <TakeForm preview={false} />
        </PrivateRoute>
        <PrivateRoute exact path="/profile" adminSuperAdmin>
          <MyProfilePage />
        </PrivateRoute>
        <PrivateRoute path="/account-users" adminSuperAdmin>
          <AccountUsersPage />
        </PrivateRoute>
        <PrivateRoute path="/roles" adminSuperAdmin>
          <RoleUsersPage />
        </PrivateRoute>
        <PrivateRoute path="/profile/styling" adminSuperAdmin>
          <AccountUsersPage />
        </PrivateRoute>
        <PrivateRoute path="/organization-profile" adminSuperAdmin>
          <OrganizationProfilePage />
        </PrivateRoute>
        <PrivateRoute path="/flowsheet" adminSuperAdmin>
          <StylePage />
        </PrivateRoute>
        <PrivateRoute path="/patients" adminSuperAdmin>
          <PatientsPage />
        </PrivateRoute>
        <PrivateRoute path="/patient-info" adminSuperAdmin>
          <PatientInformation />
        </PrivateRoute>
        <PrivateRoute path="/patient-appointments" adminSuperAdmin>
          <PatientAppointment />
        </PrivateRoute>
        <PrivateRoute path="/appointments" adminSuperAdmin>
          <AppointmentPage />
        </PrivateRoute>
        <PrivateRoute path="/shared-user" adminSuperAdmin>
          <SharedUserPage />
        </PrivateRoute>
        <PrivateRoute path="/referral-profile" referralOnly>
          <ReferralProfile />
        </PrivateRoute>
        <PrivateRoute path="/user-roles" referralOnly>
          <ReferralRolePage />
        </PrivateRoute>
        <PrivateRoute path="/contacts" siteAdminOrBuilderOnly>
          <SupportPage />
        </PrivateRoute>
        <PrivateRoute path="/view-collection" clinicianOnly>
          <ViewCollection />
        </PrivateRoute>
        <PrivateRoute path="/view/:id/:instance/:user_id?/:type?" adminSuperAdmin>
          <ViewResultPage />
        </PrivateRoute>
        <PrivateRoute path="/patient/:id/patient-view-collection" clinicianOnly>
          <ViewNestedCollection />
        </PrivateRoute>
        <PrivateRoute path="/patient/:id" adminSuperAdmin>
          <PatientRoutes />
        </PrivateRoute>
        <PrivateRoute path="/patient/:id/:page?" adminSuperAdmin>
          <PatientRoutes />
        </PrivateRoute>
        <PrivateRoute path="/patient-/:id/:page/:user_id" adminSuperAdmin>
          <SharedUserRoutes />
        </PrivateRoute>
        <PrivateRoute path="/patient-/:id/:page/:user_id/:user_page?" adminSuperAdmin>
          <SharedUserRoutes />
        </PrivateRoute>
        <PrivateRoute path="/patient-shared-user/:id" adminSuperAdmin>
          <PatientSharedUserRoutes />
        </PrivateRoute>
        <PrivateRoute path="/patient-shared-user/:id/:page?" adminSuperAdmin>
          <PatientSharedUserRoutes />
        </PrivateRoute>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/invite-notSent">
          <SSODisable />
        </Route>
        <Route path="/choose-organization">
          <ChooseOrganization />
        </Route>
        <Route path="/account-status">
          <LoginDisable />
        </Route>
        <Route path="/RolePage">
          <RolePage />
        </Route>
        <Route path="/switch-role">
          <SwitchRoleScreen />
        </Route>
        <Route path="/invite/:id">
          <Signup />
        </Route>
        <Route path="/signup">
          <Signup />
        </Route>
        <Route path="/privacy-policy" exact>
          <Policy />
        </Route>
        <Route path="/terms-of-service" exact>
          <Terms />
        </Route>
        <Route path="/forgot-password" exact>
          <ForgotPassword />
        </Route>
        <Route path="/send-link" exact>
          <SendLink />
        </Route>
        <Route path="/forgot-password/:changePasswordId">
          <ForgotPassword />
        </Route>
        <Route path="/reset-password/:resetPasswordId">
          <ResetPasswordByID />
        </Route>
        <Route path="/sso/ssoLoginDetails/:token">
          <RedirectPage />
        </Route>
        <Route path="/session-expired" exact>
          <SessionPopup />
        </Route>
        <Route path="/422">
          <ErrorPage statusCode="422" />
        </Route>
        <Route>
          <ErrorPage statusCode="404" />
        </Route>
      </Switch>
    </ConnectedRouter>
  );
};
