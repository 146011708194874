import React from 'react'
import './Terms.css'
import flowpro from './flowpro.png'
const Terms = () => {
    return (
        <>
            <nav className="nav-container fixed-top portfolio-scroll">
                <img src={flowpro} className="navbar-images" alt="Logo" height={40} />
            </nav>
            <div className='terms-container'>
                <h3 className='terms-heading'>TERMS AND CONDITION OF USE</h3>
                <h3 className='terms-heading1'>PLEASE READ THE FOLLOWING TERMS AND CONDITIONS ("Terms")</h3>
                <h3 className='terms-heading1'>CAREFULLY BEFORE CONTINUING.</h3>
                <p className='para'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;These Terms govern the terms of use of the “FormFlash” application (as defined below)
                    and any relationship between the University of Massachusetts Chan Medical School
                    ("University"), institutions of higher education of the Commonwealth of Massachusetts and you
                    the User (the "User").</p>

                <p className='para '>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    The University is granting the User the right to use the FormFlash software reference
                    application ("Software") only upon the condition that User accepts all of the terms and
                    conditions contained in these Terms.  By clicking on the "Accept" button, or otherwise accessing
                    or using the Software, User accepts and agrees to be bound by all of the terms and conditions
                    contained herein.  If the User does not accept the Terms, User is not permitted to use the
                    Software.</p>
                <ol style={{ padding: '0 0 0 20px' }}>
                    <li className='registration para'>
                        <span className='order-list'>
                            Informational Purpose Only.</span>
                        <span className="ml-3">This Software is provided solely and strictly for reference
                            and informational purposes only.  No use or information provided or returned by this Software
                            shall constitute, be construed as, or understood to be medical advice, care, treatment, or
                            instruction.  Users shall only use the Software for reference and informational purposes Users
                            should ALWAYS check with a doctor if they have any concerns about their condition or
                            treatment and before taking, or not taking, any action on the basis of the content on this
                            Software. The Software is NOT a substitute or an alternative for any decision or any part of
                            standard of care.</span>
                    </li>
                    <li className='registration para'>
                        <span className='order-list'>
                            Ownership.</span>
                        <span className="ml-3">As between User and the University, all right, title, and interest in and to the
                            Software remains with the University including without limitation any rights afforded under any
                            available United States and/or international laws protecting intellectual property rights in the
                            Software, including without limitation United States patent and copyright law.  Nothing in these
                            Terms limits any rights of the University to the Software, including use, modification,
                            adaptation, or enhancement of the Software, or the right to make derivative works based on the
                            Software.  No assignment or transfer of rights to the Software is implied or granted by University
                            to User by User’s use of the Software
                            .</span>
                    </li>
                    <li className='registration para mb-40'>
                        <span className='order-list'>
                            Termination and Modification.</span>
                        <span className="ml-3">
                            User recognizes that the University may in its sole
                            discretion and without any notice (prior or otherwise), discontinue, temporarily or permanently,
                            providing and or supporting the Software (or any portion, feature, or functionality thereof) or
                            User access.</span>
                    </li>
                    <p className='para'>User agrees that all such terminations shall be made at our sole discretion, and in no way shall
                        the University or our associated entities be liable to User or any third party for any such
                        termination.</p>
                    <li className='registration para'>
                        <span className='order-list'>
                            No Warranties.</span>
                        <span className="ml-3">
                            THE UNIVERSITY MAKES NO REPRESENTATIONS OR
                            WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, CONCERNING THE
                            SOFTWARE, AND THE INTELLECTUAL PROPERTY RIGHTS AND THE RIGHTS
                            GRANTED RELATED THERETO, INCLUDING, WITHOUT LIMITATION, WARRANTIES
                            OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                            NONINFRINGEMENT, AND/OR THE ABSENCE OF LATENT OR OTHER DEFECTS,
                            WHETHER OR NOT DISCOVERABLE, AND HEREBY DISCLAIMS THE SAME.
                            SPECIFICALLY, AND NOT TO LIMIT THE FOREGOING, UNIVERSITY MAKES NO
                            WARRANTY OR REPRESENTATION THAT THE EXPLOITATION OF THE SOFTWARE
                            AND THE INTELLECTUAL PROPERTY RIGHTS RELATED THERETO OR ANY
                            PRODUCT WILL NOT INFRINGE ANY PATENTS OR OTHER INTELLECTUAL
                            PROPERTY RIGHTS OF THE UNIVERSITY OR OF ANY THIRD PARTY..</span>
                    </li>
                    <li className='registration para'>
                        <span className='order-list'>
                            Limitation of Liability.</span>
                        <span className="ml-3">
                            IN NO EVENT SHALL THE UNIVERSITY OR ANY OF ITS
                            AFFILIATES OR ANY OF ITS RESPECTIVE TRUSTEES, DIRECTORS, OFFICERS,
                            MEDICAL OR PROFESSIONAL STAFF, EMPLOYEES AND AGENTS BE LIABLE TO
                            USER OR ANY OF ITS AFFILIATES, SUBLICENSEES OR DISTRIBUTORS FOR
                            INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES OF ANY KIND
                            ARISING IN ANY WAY OUT OF THESE TERMS OR THE USE OF THE SOFTWARE,
                            HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, INCLUDING WITHOUT
                            LIMITATION ECONOMIC DAMAGES OR INJURY TO PROPERTY OR LOST PROFITS,
                            REGARDLESS OF WHETHER UNIVERSITY SHALL BE ADVISED, SHALL HAVE
                            OTHER REASON TO KNOW, OR IN FACT SHALL KNOW OF THE POSSIBILITY OF
                            THE FOREGOING.</span>
                    </li>
                    <li className='registration para'>
                        <span className='order-list'>
                            Indemnification.</span>
                        <span className="ml-3">
                            User agrees to defend, indemnify, and hold us and our officers,
                            directors, employees, agents, licensors, and suppliers, harmless from and against any claims,
                            actions or demands, liabilities and settlements including without limitation, reasonable legal and
                            accounting fees, resulting from, or alleged to result from, Users use of the Software and/or any
                            and all violations of these Terms.</span>
                    </li>
                    <li className='registration para'>
                        <span className='order-list'>
                            Miscellaneous.</span>
                        <span className="ml-3">
                            User may not assign these Terms.  If any provision of these Terms is held
                            invalid or unenforceable for any reason, that provision shall be modified only to the extent
                            necessary to make it enforceable and the Parties intend that the invalidity or unenforceability
                            shall not affect any other provision of these Terms.  The waiver of any rights or failure to act in a
                            specific instance relates only to that instance and is not an agreement to waive any rights or fail
                            to act in any other instance.  These Terms are governed by, construed, and interpreted in
                            accordance with the laws of the Commonwealth of Massachusetts irrespective of any choice of
                            law principles that would propose or require the application of the law of another jurisdiction.
                            The Parties agree to submit to the exclusive jurisdiction of the State Superior Courts of Suffolk
                            County located in the Commonwealth of Massachusetts and waive any defense of forum non
                            conveniens or inconvenient forum to the maintenance of any action or proceeding in such courts.
                            These Terms constitutes the entire agreement between the Parties with respect to the subject
                            matter hereof, and no amendments to the Terms shall be effective unless made in writing and
                            signed by authorized representatives of both parties.  All headings are for convenience only and
                            shall not affect the meaning of any provision of these Terms. The University reserves the right to
                            modify the terms and conditions of these Terms at any time and for any or no reason without
                            notice to User.
                        </span>
                    </li>
                </ol>

            </div>
        </>
    )
}

export default Terms