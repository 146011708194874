import { Box, Paper, TextField, Avatar, Checkbox, Typography, Select, FormControl, MenuItem, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveAltOutlined from '@material-ui/icons/SaveAltOutlined';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/redux-store';

interface ViewFormBuilderProps {
    formId: any;
}

const array = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const ViewFormBuilder: React.FC<ViewFormBuilderProps> = ({ formId }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const editingFormFields = useSelector((state: RootState) => state.DATA_REDUCER.editingFormFields);

    const singleChoice = (formField: any) => {
        const structuredOptions: [] = formField?.form_field_options?.sort(function (a: any, b: any) { return a.option_value - b.option_value });

        return structuredOptions;
    }

    const multipleChoice = (formField: any) => {
        const structuredOptions: [] = formField?.form_field_options?.sort(function (a: any, b: any) { return a.option_value - b.option_value });

        return structuredOptions;
    }

    return (
        <Box className={classes.main} display="flex" flexDirection="column" p={4} m={4}>
            <Paper className={classes.paper}>
                {editingFormFields && editingFormFields?.length > 0 ? editingFormFields.map((item: any) => {
                    return (
                        <Paper className={classes.paper} style={{ boxShadow: 'unset', padding: '15px 30px' }}>
                            <Typography className={classes.title}>{item.field_type.replace(/_/g, " ")}</Typography>
                            <Typography className={classes.title1}>{item.field_prompt}</Typography>
                            {item.field_type === "SHORT_TEXT" && <TextField style={{ marginBottom: '10px', color: '#a7a7a7' }} size="small" fullWidth disabled variant='outlined' placeholder='Insert answer here' />}
                            {item.field_type === "LONG_TEXT" && <TextField style={{ marginBottom: '10px', color: '#a7a7a7' }} size="small" fullWidth disabled multiline rows={2} variant='outlined' placeholder='Insert answer here' />}
                            {item.field_type === "DROPDOWN" && <FormControl fullWidth size="small"><Select style={{ marginBottom: '10px' }} fullWidth disabled variant='outlined' displayEmpty inputProps={{ 'aria-label': 'Without label' }} value={''}><MenuItem disabled value={''}>Select answer here</MenuItem></Select></FormControl>}
                            {item.field_type === "SINGLE_CHOICE" &&
                                <RadioGroup aria-label="quiz">
                                    {singleChoice(item)?.map((element: any) =>
                                        <Paper style={{ padding: '0px 10px', boxShadow: 'unset', border: '1px solid #a7a7a7', marginBottom: '10px' }}>
                                            <FormControlLabel disabled value="I agree" control={<Radio />} label={<span style={{ color: '#a7a7a7' }}>{element.option_text}</span>} />
                                        </Paper>
                                    )}
                                </RadioGroup>}
                            {item.field_type === "MULTIPLE_CHOICE" &&
                                <RadioGroup aria-label="quiz">
                                    {multipleChoice(item)?.map((element: any) =>
                                        <Paper style={{ padding: '0px 10px', boxShadow: 'unset', border: '1px solid #a7a7a7', marginBottom: '10px' }}>
                                            <FormControlLabel value={element.option_value} control={<Checkbox disabled />} label={<span style={{ color: '#a7a7a7' }}>{element.option_text}</span>} />
                                        </Paper>
                                    )}
                                </RadioGroup>}
                            {item.field_type === "SCALE" &&
                                <RadioGroup row>
                                    {array.map((id, i: any) => {
                                        return (item.option_scale_max) < i ? '' : <Avatar style={{ margin: '10px', background: 'transparent', border: '1px solid #a7a7a7' }}><Typography style={{ color: '#a7a7a7' }}>{id}</Typography></Avatar>
                                    })}
                                </RadioGroup>
                            }
                            {item.field_type === "RESOURCE" &&
                                <Box mt={2}>
                                    {item.resources.map((item: any) => (
                                        <Paper style={{ boxShadow: 'unset', border: '1px solid #cbcbcb', padding: '5px 15px', width: '50%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                            <Typography>{item.file_name}</Typography>
                                            <a href={item.resource_link} target='_blank'><SaveAltOutlined style={{ color: "gray", padding: '0px 20px' }} /></a>
                                        </Paper>
                                    ))
                                    }
                                </Box>}
                            <Typography className={classes.title}>Instructions to clinician: {item.info_for_clinician}</Typography>
                            <Typography className={classes.title}>Instructions to patient: {item.info_for_patient}</Typography>
                        </Paper>
                    )
                }) :
                    <Box display='flex' justifyContent='center'><Typography variant={'h3'}>Add form fields to get started</Typography></Box>
                }
            </Paper>
        </Box>
    );
};

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    content: {
        display: 'flex',
        justifyContent: 'center',

        padding: theme.spacing(3)
    },
    main: {
        padding: '60px 0px'
    },
    paper: {
        padding: '30px',
        border: '1px solid #cfcfcf',
        marginBottom: '30px',
        boxShadow: 'unset'
    },
    title: {
        color: '#848484',
        textTransform: 'uppercase',
        marginBottom: '10px'
    },
    title1: {
        color: 'black',
        fontWeight: 'bold',
        margin: '0px 0px 10px',
        fontSize: '15px'
    },
}));


