import {
  FormInstanceResponses,
  FormResponseChangeLogs,
  PatientTasksResults,
  StaticResourceSearchResults,
  User,
  NextQuestionPayload,
  StaticResourceUploadResponse,
  CataloguesSearchResults,
  BundleSearchResults,
  AppointmentSearchResults,
  ReferralFilterListResults,
  PatientFamilyInfo,
  Tag,
  TagsSearchResults,
  ReferralSearchResults,
  Referral
} from './../../shared/domain.d';
import {
  Collection,
  CollectionSearchResults,
  Form,
  FormField,
  FormFieldLogic,
  FormFieldUpdate,
  FormSearchResults,
  UuidType,
  Calculation,
  Metric,
  ExitPage,
  Patient,
  PatientSearchResults,
  ExitPageFieldLogic,
  ExitPageMetricLogic,
  CollectionAssignments,
  UserSearchResults,
  UnitSearchResults,
  FormInstanceSearchResults,
  FormInstance
} from '../../shared/domain';
import {
  DataAction,
  SetEditingFormAction,
  SetEditingFormFieldAction,
  SetEditingFormFieldsAction,
  SetFormFieldOptionsToDeleteAction,
  SET_EDITING_FORM_A,
  SET_DISPLAY_FORM_A,
  SET_EDITING_FORM_FIELDS_A,
  SET_EDITING_FORM_FIELD_A,
  SET_FORM_FIELD_OPTIONS_TO_DELETE_A,
  API_GET_COLLECTIONS_A,
  GetCollectionsAction,
  API_GET_COLLECTION_A,
  GetCollectionAction,
  FormsType,
  API_GET_FORMS_A,
  GetFormsAction,
  CollectionsType,
  SET_EDITING_FIELD_LOGIC_RULES_A,
  SetEditingFieldLogicRulesAction,
  SET_CALCULATION_METRICS_A,
  SetCalculationMetricsAction,
  SetEditingCalculationsAction,
  SET_EDITING_CALCULATIONS_A,
  SET_EDITING_EXIT_PAGES_A,
  SetEditingExitPagesAction,
  GetDuplicatedFormAction,
  GET_DUPLICATED_FORM_A,
  GetPatientsAction,
  GET_PATIENTS_A,
  GetPatientAction,
  GET_PATIENT_A,
  SET_EDITING_EXIT_PAGE_A,
  SetEditingExitPageAction,
  SetEditingExitPageFieldLogicRulesAction,
  SET_EDITING_EXIT_PAGE_FIELD_LOGIC_RULES_A,
  SetEditingExitPageCalculationsAction,
  SET_EDITING_EXIT_PAGE_CALCULATIONS_A,
  GET_COLLECTION_ASSIGNMENTS_A,
  GetCollectionAssignmentsAction,
  UnitsType,
  API_GET_UNITS_A,
  GetUnitsAction,
  UsersType,
  API_GET_USERS_A,
  GetUsersAction,
  SET_DISPLAY_FORM_INSTANCE_A,
  SET_DISPLAY_FORM_FIELD_PAYLOAD_A,
  SetDisplayFormInstanceAction,
  SetDisplayFormFieldPayloadAction,
  GetPatientFormInstancesAction,
  API_GET_PATIENT_FORM_INSTANCES_A,
  PatientFormInstancesType,
  SetDisplayExitPageAction,
  SET_DISPLAY_EXIT_PAGE_A,
  SET_FORM_RESPONSES_A,
  SET_FORM_RESP_CHANGE_LOGS_A,
  SetFormResponsesAction,
  SetFormRespChangeLogsAction,
  SET_PATIENT_FORM_INSTANCES_A,
  SetPatientFormInstancesAction,
  FormsInstanceCompleteness,
  API_GET_STATIC_RESOURCES_A,
  GetStaticResourcesAction,
  StaticResourceType,
  SetLoggedInUserAction,
  SET_LOGGED_IN_USER_A,
  SET_INVITED_USER_A,
  SetInvitedUserAction,
  API_CREATE_STATIC_RESOURCES_A,
  CreateStaticResourcesAction,
  GetCataloguesAction,
  API_GET_CATALOGUES_A,
  CatalogueType,
  API_GET_BUNDLES_A,
  GetBundlesAction,
  BundlesType,
  API_GET_APPOINTMENTS_A,
  AppointmentsType,
  GetAppointmentsAction,
  GetReferralFilterAction,
  GetPatientFamilyAction,
  GET_REFERRAL_FILTER_A,
  GET_PATIENT_FAMILY_A,
  SET_STATIC_RESOURCE_TAGS_A,
  SetStaticResourceTagsAction,
  GetTagsAction,
  API_GET_TAGS_A,
  SetDisplayFormAction,
  ReferralsType,
  API_GET_REFERRALS_A,
  GetReferralsAction,
  GetProfileInfoAction,
  GET_PROFILE_INFO,
  GetAccountUsersAction,
  API_GET_ACCOUNT_USERS,
  GET_DEPARTMENT_LIST,
  GET_STATE_LIST,
  GET_UNIT_LIST,
  GetStateAction,
  GetUnitAction,
  GetDepartmentAction,
  GetFormStyleAction,
  GET_FORM_STYLE,
  GetRoleAction,
  GET_ROLES,
  GetAccountRoleAction,
  GET_ACCOUNT_ROLES,
  getPrimaryDomain,
  GET_PRIMARY_DOMAINS,
  getAlltags,
  GET_ALL_TAGS,
  GET_RESOURCE_FILES,
  GetResourceFileAction,
  GET_ORGANIZATION_LIST,
  GetOrganizationsAction,
  GetVerifyUserInfoAction,
  GET_VERIFYED_USER_INFO,
  AddNewTagAction,
  SAVE_NEW_TAG,
  SAVE_NEW_UNIT,
  AddNewUnitAction,
  GetAllCollectionListAction,
  GET_ALL_COLLECTION_LIST,
  GetCollectionFormsAction,
  GET_COLLECION_FORMS,
  GET_NOT_IN_COLLECION_FORMS,
  GetAllCollectionFormsAction,
  GET_TAGS,
  GetSettingTagsAction,
  SetCalculationPagesAction,
  SET_EDITING_CALCULATION_PAGES,
  SetCalculationMetricFieldsAction, GET_METRIC_FIELDS,
  CalculationMetricFieldsAction, GET_ADDED_METRIC_FIELDS,
  SET_EDIT_REFERRAL, SetEditReferral,
  GetReferralInfoAction, GET_REFERRAL_INFO,
  GET_REFERRAL_FILTER_LIB, GetReferralFilterLibAction,
  GetOrganizationProfileAction, GET_ORGANIZATION_PROFILE,
  SetTabsAction, SET_TABS,
  GetSecondarySiteAdminAction, GET_SECONDARY_SITE_ADMIN,
  SetLogoAction, SET_LOGO,
  GET_ALL_PATIENT_FAMILY, GetAllPatientFamilyAction,
  GET_ALL_ADMINS, GetAllAdmins,
  GetSharingUserAction, GET_SHARING_USER,
  UsersAssignmentAction, CHECK_USERS_ASSIGNMENT,
  AssignmentInfoAction, SET_ASSIGNMENT_LIST,
  GetSharedUsersAction, GET_SHARED_USERS,
  GET_FORM_VERSIONS, GetFormVersionsAction,
  GetFormsCollectionsAction, API_GET_FORMS_COLLECTIONS,
  GetLoginSSOPageAction, GET_SSO_LOGIN_PAGE,
  SetPatientInfoAction, SET_PATIENT_INFO,
  GetFormInstanceDetailsAction, GET_FORM_INSTANCE_DETAILS,
  GET_FORM_RESPONSE_DETAILS, GetFormResponseDetailsAction,
  SetSharedUserInfoAction, SET_SHARED_USER_INFO,
  GET_FORM_RECURRENCE_INFO, GetFormRecurrenceInfoAction,
  GET_METRIC_GRAPHS, GetFormMetricGraphsAction,
  GetFormResultsAction, GET_FORM_RESULTS,
  GetCompletedCollectionResultsAction, GET_COMPLETED_COLLECTION_RESULTS,
  GET_COMPLETED_FORM_RESULTS, GetCompletedFormResultsAction,
  GetResourceStatusCountAction, GET_RESOURCE_STATUS_COUNT,
  GetCollectionStatusCountAction, GET_COLLECTION_STATUS_COUNT,
  GetFormStatusCountAction, GET_FORM_STATUS_COUNT,
  OTPVerification, OTP_VERIFICATION,
  GetReachCareOrgId, GET_REACH_CARE_ORG_ID
} from './data-actions';

// manage stateful data fetched from the backend
interface DataReduxState {
  loggedInUser: User | null;
  invitedUser: User | null;
  forms: Form[] | null;
  formInstanceResponses: FormInstanceResponses | null;
  formRespChangeLogs: FormResponseChangeLogs | null;
  displayFormInstance: FormInstance | null;
  displayForm: Form | null;
  displayFormFieldPayload: NextQuestionPayload | null;
  displayExitPage: ExitPage | null;
  editingForm: Form | null;
  editingFormFields: FormField[] | null;
  editingFormField: FormFieldUpdate | null;
  editingExitPages: ExitPage[] | null;
  editingExitPage: ExitPage | null;
  formFieldOptionsToDelete: UuidType[] | null;
  collection: Collection | null;
  collectionSearchResults: {
    [x in CollectionsType]: CollectionSearchResults | null;
  };
  formSearchResults: { [x in FormsType]: FormSearchResults | null };
  getCollection: Collection | null;
  getCollections: CollectionSearchResults | null;
  getForms: FormSearchResults | null;
  editingFieldLogicRules: FormFieldLogic[] | null;
  editingExitPageFieldLogicRules: ExitPageFieldLogic[] | null;
  editingCalculations: Calculation[] | null;
  editingExitPageCalculations: ExitPageMetricLogic[] | null;
  calculationMetrics: Metric[] | null;
  duplicatedForm: Form | null;
  patientSearchResults: PatientSearchResults | null;
  patient: Patient | null;
  referral: Referral | null;
  collectionAssignments: CollectionAssignments | null;
  unitSearchResults: { [x in UnitsType]: UnitSearchResults | null };
  userSearchResults: { [x in UsersType]: UserSearchResults | null };
  patientFormInstanceSearchResults: {
    [x in PatientFormInstancesType]: FormInstanceSearchResults | null;
  };
  patientFormInstances: {
    [x in FormsInstanceCompleteness]: PatientTasksResults | null;
  };
  staticResourcesSearchResults: { [x in StaticResourceType]: StaticResourceSearchResults | null };
  bundlesSearchResults: { [x in BundlesType]: BundleSearchResults | null };
  appointmentSearchResults: { [x in AppointmentsType]: AppointmentSearchResults | null };
  referralFilterListResults: ReferralFilterListResults | null;
  patientFamilyInfo: PatientFamilyInfo | null;
  referralsSearchResults: { [x in ReferralsType]: ReferralSearchResults | null };
  getStaticResources: StaticResourceSearchResults | null;
  getStaticResourceTags: Tag[] | null;
  createStaticResourcesResult: StaticResourceUploadResponse | null;
  cataloguesSearchResults: {
    [x in CatalogueType]: CataloguesSearchResults | null;
  };
  tagsSearchResults: TagsSearchResults | null;
  profileInfo: {} | null;
  accountUserList: {} | null;
  departmentList: [] | null;
  stateList: [] | null;
  unitList: [] | null;
  formStyle: {} | null;
  roles: [] | null;
  roleList: [] | null;
  primaryDomains: [] | null;
  tagList: [] | null
  resourceFiles: [] | null,
  organizationList: [] | null,
  verifyUser: {} | null,
  tag: {} | null,
  unit: {} | null,
  collectionList: [] | null,
  collectionForms: [] | null,
  allCollectionForms: {} | null,
  settingTags: {} | null,
  calculationPages: [] | null,
  metricFields: [] | null,
  addedMetricFields: [] | null,
  editReferral: {} | null,
  referralInfo: {} | null,
  referralFilterLists: {} | null,
  organizationProfile: {} | null,
  tab: any | null,
  secondarySiteAdmins: [] | null
  org_logo: '' | null,
  patientFamilies: [] | null,
  adminList: [] | null,
  sharingUser: [] | null,
  checkUsersAssignment: [] | null,
  assignmentList: [] | null,
  sharedUsers: [] | null,
  formVersions: [] | null,
  getFormsCollections: [] | null,
  showSSOLoginPage: {} | null,
  patientInfo: {} | null,
  formInstanceDetails: {} | null,
  formResponseDetails: [] | null,
  sharedUser: {} | null,
  formRecurrenceInfo: {} | null,
  formMetricGraphs: [] | null,
  formResults: {} | null,
  completedCollectionResults: {} | null,
  completedFormResults: {} | null,
  resourceStatusCount: [] | null,
  collectionStatusCount: [] | null,
  formStatusCount: [] | null,
  otpVerification: {} | null,
  reachCareOrgId: "" | null
}
const defaultDataState = {
  loggedInUser: null,
  invitedUser: null,
  forms: null,
  stateList: null,
  unitList: null,
  departmentList: null,
  formInstanceResponses: null,
  formRespChangeLogs: null,
  displayForm: null,
  displayFormInstance: null,
  displayFormFieldPayload: null,
  displayExitPage: null,
  editingForm: null,
  editingFormFields: null,
  editingFormField: null,
  editingExitPages: null,
  editingExitPage: null,
  formFieldOptionsToDelete: null,
  collection: null,
  collectionSearchResults: {
    assignToPatient: null,
    collections: null,
    librariesCard: null,
    addDialog: null,
    duplicateCustom: null,
    addFormToCollections: null,
    libraryForms: null
  },
  formSearchResults: {
    assignToPatient: null,
    collection: null,
    collectionDetailAdd: null,
    custom: null,
    duplicateCustom: null,
    library: null
  },
  editingFieldLogicRules: null,
  editingExitPageFieldLogicRules: null,
  getCollection: null,
  getCollections: null,
  getForms: null,
  editingCalculations: null,
  editingExitPageCalculations: null,
  calculationMetrics: null,
  duplicatedForm: null,
  patientSearchResults: null,
  patient: null,
  referral: null,
  collectionAssignments: null,
  unitSearchResults: {
    assignCollection: null,
    main: null
  },
  userSearchResults: {
    accountUsers: null,
    assignCollection: null
  },
  patientFormInstanceSearchResults: {
    inPerson: null,
    oneTime: null
  },
  patientFormInstances: {
    complete: null,
    incomplete: null
  },
  staticResourcesSearchResults: {
    approved: null
  },
  bundlesSearchResults: {
    resourceBundles: null
  },
  appointmentSearchResults: {
    appointments: null
  },
  referralFilterListResults: null,
  patientFamilyInfo: null,
  referralsSearchResults: {
    referrals: null
  },
  getStaticResources: null,
  getStaticResourceTags: null,
  createStaticResourcesResult: null,
  cataloguesSearchResults: {
    completeList: null,
    changeCatalogue: null
  },
  tagsSearchResults: null,
  profileInfo: null,
  accountUserList: null,
  formStyle: null,
  roles: null,
  roleList: null,
  primaryDomains: null,
  tagList: null,
  resourceFiles: null,
  organizationList: null,
  verifyUser: null,
  tag: null,
  unit: null,
  collectionList: null,
  collectionForms: null,
  allCollectionForms: null,
  settingTags: null,
  calculationPages: null,
  metricFields: null,
  addedMetricFields: null,
  editReferral: null,
  referralInfo: null,
  referralFilterLists: null,
  organizationProfile: null,
  tab: 0,
  secondarySiteAdmins: null,
  org_logo: null,
  patientFamilies: null,
  adminList: null,
  sharingUser: null,
  checkUsersAssignment: null,
  assignmentList: null,
  sharedUsers: null,
  formVersions: null,
  getFormsCollections: null,
  showSSOLoginPage: null,
  patientInfo: null,
  formInstanceDetails: null,
  formResponseDetails: null,
  sharedUser: null,
  formRecurrenceInfo: null,
  formMetricGraphs: null,
  formResults: null,
  completedCollectionResults: null,
  completedFormResults: null,
  resourceStatusCount: null,
  collectionStatusCount: null,
  formStatusCount: null,
  otpVerification: null,
  reachCareOrgId: null,
};

export const dataReducer = (
  state: DataReduxState = defaultDataState,
  action: DataAction
): DataReduxState => {
  switch (action.type) {
    case SET_LOGO:
      return {
        ...state,
        org_logo: (action as SetLogoAction).org_logo
      };
    case SET_LOGGED_IN_USER_A:
      return {
        ...state,
        loggedInUser: (action as SetLoggedInUserAction).user
      };
    case GET_REACH_CARE_ORG_ID:
      return {
        ...state,
        reachCareOrgId: (action as GetReachCareOrgId).reachCareOrgId
      };
    case OTP_VERIFICATION:
      return {
        ...state,
        otpVerification: (action as OTPVerification).otpVerification
      };
    case GET_RESOURCE_STATUS_COUNT:
      return {
        ...state,
        resourceStatusCount: (action as GetResourceStatusCountAction).resourceStatusCount
      };
    case GET_COLLECTION_STATUS_COUNT:
      return {
        ...state,
        collectionStatusCount: (action as GetCollectionStatusCountAction).collectionStatusCount
      };
    case GET_FORM_STATUS_COUNT:
      return {
        ...state,
        formStatusCount: (action as GetFormStatusCountAction).formStatusCount
      };
    case SET_PATIENT_INFO:
      return {
        ...state, patientInfo: (action as SetPatientInfoAction).patient
      };
    case GET_COMPLETED_COLLECTION_RESULTS:
      return {
        ...state, completedCollectionResults: (action as GetCompletedCollectionResultsAction).completedCollectionResults
      };
    case GET_COMPLETED_FORM_RESULTS:
      return {
        ...state, completedFormResults: (action as GetCompletedFormResultsAction).completedFormResults
      };
    case GET_FORM_RESULTS:
      return {
        ...state, formResults: (action as GetFormResultsAction).formResults
      };
    case GET_METRIC_GRAPHS:
      return {
        ...state, formMetricGraphs: (action as GetFormMetricGraphsAction).formMetricGraphs
      };
    case SET_INVITED_USER_A:
      return {
        ...state,
        invitedUser: (action as SetInvitedUserAction).invitedUser
      };
    case SET_DISPLAY_FORM_A:
      return {
        ...state,
        displayForm: (action as SetDisplayFormAction).form
      };
    case SET_DISPLAY_FORM_INSTANCE_A:
      return {
        ...state,
        displayFormInstance: (action as SetDisplayFormInstanceAction).formInstance
      };
    case GET_FORM_RECURRENCE_INFO:
      return {
        ...state,
        formRecurrenceInfo: (action as GetFormRecurrenceInfoAction).formRecurrenceInfo
      };
    case SET_DISPLAY_FORM_FIELD_PAYLOAD_A:
      return {
        ...state,
        displayFormFieldPayload: (action as SetDisplayFormFieldPayloadAction).formFieldPayload
      };
    case SET_DISPLAY_EXIT_PAGE_A:
      return {
        ...state,
        displayExitPage: (action as SetDisplayExitPageAction).exitPage
      };
    case SET_FORM_RESPONSES_A:
      return {
        ...state,
        formInstanceResponses: (action as SetFormResponsesAction).formResponses
      };
    case SET_FORM_RESP_CHANGE_LOGS_A:
      return {
        ...state,
        formRespChangeLogs: (action as SetFormRespChangeLogsAction).formRespChangeLogs
      };
    case SET_EDITING_FORM_A:
      return { ...state, editingForm: (action as SetEditingFormAction).form };
    case SET_EDITING_FORM_FIELDS_A:
      return {
        ...state,
        editingFormFields: (action as SetEditingFormFieldsAction).formFields
      };
    case SET_EDITING_FORM_FIELD_A:
      return {
        ...state,
        editingFormField: (action as SetEditingFormFieldAction).formField
      };
    case SET_FORM_FIELD_OPTIONS_TO_DELETE_A:
      return {
        ...state,
        formFieldOptionsToDelete: (action as SetFormFieldOptionsToDeleteAction).ids
      };
    case SET_EDITING_EXIT_PAGES_A:
      return {
        ...state,
        editingExitPages: (action as SetEditingExitPagesAction).exitPages
      };
    case API_GET_COLLECTION_A:
      return {
        ...state,
        collection: (action as GetCollectionAction).getCollection
      };
    case API_GET_COLLECTIONS_A:
      return {
        ...state,
        collectionSearchResults: {
          ...state.collectionSearchResults,
          [(action as GetCollectionsAction).collectionsType]: (action as GetCollectionsAction)
            .getCollections
        }
      };
    case API_GET_FORMS_A:
      return {
        ...state,
        formSearchResults: {
          ...state.formSearchResults,
          [(action as GetFormsAction).formsType]: (action as GetFormsAction).getForms
        }
      };
    case SET_EDITING_FIELD_LOGIC_RULES_A:
      return {
        ...state,
        editingFieldLogicRules: (action as SetEditingFieldLogicRulesAction).fieldLogicRules
      };
    case SET_EDITING_EXIT_PAGE_FIELD_LOGIC_RULES_A:
      return {
        ...state,
        editingExitPageFieldLogicRules: (action as SetEditingExitPageFieldLogicRulesAction)
          .exitPageFieldLogicRules
      };
    case SET_CALCULATION_METRICS_A:
      return {
        ...state,
        calculationMetrics: (action as SetCalculationMetricsAction).calculationMetrics
      };
    case SET_EDITING_CALCULATIONS_A:
      return {
        ...state,
        editingCalculations: (action as SetEditingCalculationsAction).calculations
      };
    case GET_DUPLICATED_FORM_A:
      return {
        ...state,
        duplicatedForm: (action as GetDuplicatedFormAction).duplicatedForm
      };
    case GET_PATIENTS_A:
      return {
        ...state,
        patientSearchResults: (action as GetPatientsAction).patientSearchResults
      };
    case API_GET_TAGS_A:
      return {
        ...state,
        tagsSearchResults: (action as GetTagsAction).tagsSearchResults
      };
    case GET_PATIENT_A:
      return {
        ...state,
        patient: (action as GetPatientAction).patient
      };
    case SET_EDITING_EXIT_PAGE_A:
      return {
        ...state,
        editingExitPage: (action as SetEditingExitPageAction).exitPage
      };
    case SET_EDITING_EXIT_PAGE_CALCULATIONS_A:
      return {
        ...state,
        editingExitPageCalculations: (action as SetEditingExitPageCalculationsAction).calculations
      };
    case GET_COLLECTION_ASSIGNMENTS_A:
      return {
        ...state,
        collectionAssignments: (action as GetCollectionAssignmentsAction).assignments
      };
    case API_GET_UNITS_A:
      return {
        ...state,
        unitSearchResults: {
          ...state.unitSearchResults,
          [(action as GetUnitsAction).unitsType]: (action as GetUnitsAction).unitSearchResults
        }
      };
    case API_GET_USERS_A:
      return {
        ...state,
        userSearchResults: {
          ...state.userSearchResults,
          [(action as GetUsersAction).usersType]: (action as GetUsersAction).userSearchResults
        }
      };
    case API_GET_PATIENT_FORM_INSTANCES_A:
      return {
        ...state,
        patientFormInstanceSearchResults: {
          ...state.patientFormInstanceSearchResults,
          [(action as GetPatientFormInstancesAction)
            .patientFormInstancesType]: (action as GetPatientFormInstancesAction)
              .patientFormInstanceSearchResults
        }
      };
    case SET_PATIENT_FORM_INSTANCES_A:
      return {
        ...state,
        patientFormInstances: {
          ...state.patientFormInstances,
          [(action as SetPatientFormInstancesAction)
            .formInstanceCompleteness]: (action as SetPatientFormInstancesAction).taskResults
        }
      };
    case SET_STATIC_RESOURCE_TAGS_A:
      return {
        ...state,
        getStaticResourceTags: (action as SetStaticResourceTagsAction).tags
      };
    case API_GET_STATIC_RESOURCES_A:
      return {
        ...state,
        staticResourcesSearchResults: {
          ...state.staticResourcesSearchResults,
          [(action as GetStaticResourcesAction)
            .staticResourceType]: (action as GetStaticResourcesAction).getResources
        }
      };
    case API_GET_BUNDLES_A:
      return {
        ...state,
        bundlesSearchResults: {
          ...state.bundlesSearchResults,
          [(action as GetBundlesAction).bundlesType]: (action as GetBundlesAction).getBundles
        }
      };
    case API_GET_APPOINTMENTS_A:
      return {
        ...state,
        appointmentSearchResults: {
          ...state.appointmentSearchResults,
          [(action as GetAppointmentsAction).appointmentType]: (action as GetAppointmentsAction).getAppointments
        }
      };
    case GET_REFERRAL_FILTER_A:
      return {
        ...state,
        referralFilterListResults: (action as GetReferralFilterAction).referralFilterList
      };
    case GET_PATIENT_FAMILY_A:
      return {
        ...state,
        patientFamilyInfo: (action as GetPatientFamilyAction).patientFamilyInfo
      };
    case API_GET_REFERRALS_A:
      return {
        ...state,
        referralsSearchResults: {
          ...state.referralsSearchResults,
          [(action as GetReferralsAction).referralsType]: (action as GetReferralsAction)
            .getReferrals
        }
      };
    case API_CREATE_STATIC_RESOURCES_A:
      return {
        ...state,
        createStaticResourcesResult: (action as CreateStaticResourcesAction)
          .createStaticResourcesResult
      };
    case API_GET_CATALOGUES_A:
      return {
        ...state,
        cataloguesSearchResults: {
          ...state.cataloguesSearchResults,
          [(action as GetCataloguesAction).catalogueType]: (action as GetCataloguesAction)
            .getCatalogues
        }
      };
    case GET_PROFILE_INFO:
      return {
        ...state,
        profileInfo: (action as GetProfileInfoAction).profileInfo
      };
    case API_GET_ACCOUNT_USERS:
      return {
        ...state,
        accountUserList: (action as GetAccountUsersAction).accountUserList
      };
    case GET_STATE_LIST:
      return {
        ...state,
        stateList: (action as GetStateAction).stateList
      };
    case GET_UNIT_LIST:
      return {
        ...state,
        unitList: (action as GetUnitAction).unitList
      };
    case GET_DEPARTMENT_LIST:
      return {
        ...state,
        departmentList: (action as GetDepartmentAction).departmentList
      };
    case GET_FORM_STYLE:
      return {
        ...state,
        formStyle: (action as GetFormStyleAction).formStyle
      };
    case GET_ROLES:
      return {
        ...state,
        roles: (action as GetRoleAction).roles
      };
    case GET_ACCOUNT_ROLES:
      return {
        ...state,
        roleList: (action as GetAccountRoleAction).roleList
      };
    case GET_PRIMARY_DOMAINS:
      return {
        ...state,
        primaryDomains: (action as getPrimaryDomain).primaryDomains
      };
    case GET_ALL_TAGS:
      return {
        ...state,
        tagList: (action as getAlltags).tagList
      };
    case GET_RESOURCE_FILES:
      return {
        ...state,
        resourceFiles: (action as GetResourceFileAction).resourceFiles
      };
    case GET_ORGANIZATION_LIST:
      return {
        ...state,
        organizationList: (action as GetOrganizationsAction).organizationList
      };
    case GET_VERIFYED_USER_INFO:
      return {
        ...state,
        verifyUser: (action as GetVerifyUserInfoAction).verifyUser
      };
    case SAVE_NEW_TAG:
      return {
        ...state,
        tag: (action as AddNewTagAction).tag
      };
    case SAVE_NEW_UNIT:
      return {
        ...state,
        unit: (action as AddNewUnitAction).unit
      };
    case GET_ALL_COLLECTION_LIST:
      return {
        ...state,
        collectionList: (action as GetAllCollectionListAction).collectionList
      };
    case GET_COLLECION_FORMS:
      return {
        ...state,
        collectionForms: (action as GetCollectionFormsAction).collectionForms
      };
    case GET_NOT_IN_COLLECION_FORMS:
      return {
        ...state,
        allCollectionForms: (action as GetAllCollectionFormsAction).allCollectionForms
      };
    case GET_TAGS:
      return {
        ...state,
        settingTags: (action as GetSettingTagsAction).settingTags
      };
    case SET_EDITING_CALCULATION_PAGES:
      return {
        ...state,
        calculationPages: (action as SetCalculationPagesAction).calculationPages
      };
    case GET_METRIC_FIELDS:
      return {
        ...state,
        metricFields: (action as SetCalculationMetricFieldsAction).metricFields
      };
    case GET_ADDED_METRIC_FIELDS:
      return {
        ...state,
        addedMetricFields: (action as CalculationMetricFieldsAction).addedMetricFields
      };
    case SET_EDIT_REFERRAL:
      return {
        ...state,
        editReferral: (action as SetEditReferral).editReferral
      };
    case GET_REFERRAL_INFO:
      return {
        ...state,
        referralInfo: (action as GetReferralInfoAction).referralInfo
      };
    case GET_REFERRAL_FILTER_LIB:
      return {
        ...state,
        referralFilterLists: (action as GetReferralFilterLibAction).referralFilterLists
      };
    case GET_ORGANIZATION_PROFILE:
      return {
        ...state,
        organizationProfile: (action as GetOrganizationProfileAction).organizationProfile
      };
    case SET_TABS:
      return {
        ...state,
        tab: (action as SetTabsAction).tab
      };
    case GET_SECONDARY_SITE_ADMIN:
      return {
        ...state,
        secondarySiteAdmins: (action as GetSecondarySiteAdminAction).secondarySiteAdmins
      };
    case GET_ALL_PATIENT_FAMILY:
      return {
        ...state,
        patientFamilies: (action as GetAllPatientFamilyAction).patientFamilies
      };
    case GET_ALL_ADMINS:
      return {
        ...state,
        adminList: (action as GetAllAdmins).adminList
      };
    case GET_SHARING_USER:
      return {
        ...state,
        sharingUser: (action as GetSharingUserAction).sharingUser
      };
    case CHECK_USERS_ASSIGNMENT:
      return {
        ...state,
        checkUsersAssignment: (action as UsersAssignmentAction).checkUsersAssignment
      };
    case SET_ASSIGNMENT_LIST:
      return {
        ...state,
        assignmentList: (action as AssignmentInfoAction).assignmentList
      };
    case GET_SHARED_USERS:
      return {
        ...state,
        sharedUsers: (action as GetSharedUsersAction).sharedUsers
      };
    case GET_FORM_VERSIONS:
      return {
        ...state,
        formVersions: (action as GetFormVersionsAction).formVersions
      };
    case API_GET_FORMS_COLLECTIONS:
      return {
        ...state,
        getFormsCollections: (action as GetFormsCollectionsAction).getFormsCollections
      };
    case GET_SSO_LOGIN_PAGE:
      return {
        ...state, showSSOLoginPage: (action as GetLoginSSOPageAction).showSSOLoginPage
      };
    case GET_FORM_INSTANCE_DETAILS:
      return {
        ...state, formInstanceDetails: (action as GetFormInstanceDetailsAction).formInstanceDetails
      };
    case GET_FORM_RESPONSE_DETAILS:
      return {
        ...state, formResponseDetails: (action as GetFormResponseDetailsAction).formResponseDetails
      };
    case SET_SHARED_USER_INFO:
      return {
        ...state, sharedUser: (action as SetSharedUserInfoAction).sharedUser
      };
    default:
      return state;
  }
};
