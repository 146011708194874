import { Box, TextField } from '@material-ui/core';
import React from 'react';
import { TitleInfo } from './TitleInfo';
interface LongTextQuestionProps {
  title: string;
  clinicianInfo?: string;
  patientInfo?: string;
  handleInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  responseValue?: string;
  index: any;
}

export const LongTextQuestion: React.FC<LongTextQuestionProps> = ({
  title,
  handleInput,
  responseValue,
  clinicianInfo,
  patientInfo,
  index
}) => {
  return (
    <>
      <TitleInfo title={title} patientInfo={patientInfo} clinicianInfo={clinicianInfo} index={index} />
      <Box py={2}>
        <TextField
          label="Input your answer here"
          variant="outlined"
          fullWidth
          multiline
          inputProps={{ maxLength: 1400 }}
          onChange={handleInput}
          value={responseValue}
          rows={4}
        />
      </Box>
    </>
  );
};
