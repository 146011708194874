import { FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import React from 'react';

interface DropdownFieldBlockProps {
  fieldTitle: any;
  options: { option_text: string | null }[];
}

export const DropdownFieldBlock: React.FC<DropdownFieldBlockProps> = ({ fieldTitle, options }) => {
  const classes = useStyles();

  return (
    <>
      {fieldTitle && fieldTitle.map((queName: any) => {
        return <Typography variant="subtitle2"><b>{queName ? queName : ''}</b></Typography>
      })}
      <FormControl variant="outlined">
        <InputLabel margin={'dense'}>Select Answer</InputLabel>
        <Select
          label="Select Answer"
          IconComponent={UnfoldMoreIcon}
          classes={{ root: classes.selectRoot }}
        >
          {options && options.length > 0 && options.map((ffo, i) => (
            <MenuItem key={i}>{ffo.option_text}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  selectRoot: {
    padding: '.6rem'
  }
}));
