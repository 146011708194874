import React, { useState, useEffect } from 'react';
import { Box, Theme, Typography, makeStyles, Grid, TextField, DialogActions, MenuItem, Select } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ROLES } from '../../../shared/globals';
import { RootState } from '../../../redux/redux-store';
import { Dialog } from '../../../components/Dialog';
import { LoadingButton } from '../../../components/LoadingButton';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { saveAccountInfoThunk, getAccountRolesThunk, getStateThunk, getReachCareOrgIDThunk } from '../../../redux/thunk/thunks';
import moment from 'moment';

interface NewPatientDialogProps {
  open: boolean;
  handleClose: () => void;
}

interface PatientFormValues {
  role_id: string;
  first_name: string;
  last_name: string;
  email: string;
  city: string;
  state: string;
  mobile_phone: string;
  home_address: string;
  birth_date: string;
}

export const NewPatient = ({ open, handleClose }: NewPatientDialogProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);
  const [isSet, setIsSet] = useState(false);
  const [disDate, setDisabledDate] = useState<any>();
  const [birth, setBirth] = useState<any>('');
  const [isError, setIsError] = useState(false);
  const [inputs, setInputs] = useState(null);
  const [selectedRole, setSelectedRole] = useState<any>(null);
  const [zip, setZip] = useState<any>();
  const [error, setError] = useState(false);
  const [patient_id, setMasterId] = useState('');
  const [isFieldShow, setIsFieldShow] = useState(false);
  const [initialValues, setInitialValues] = useState<PatientFormValues>({
    role_id: '',
    first_name: '',
    last_name: '',
    email: '',
    city: '',
    state: '',
    mobile_phone: '',
    home_address: '',
    birth_date: '',
  });

  const roles: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.roleList
  );
  const stateList: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.stateList
  );
  const reachCareOrgId = useSelector(
    (state: RootState) => state.DATA_REDUCER.reachCareOrgId
  );

  useEffect(() => {
    let newDate: any = Date.now();
    setDisabledDate(new Date(newDate).getFullYear() + '-' + (("0" + (new Date(newDate).getMonth() + 1)).slice(-2)) + '-' + ("0" + (new Date(newDate).getDate() - 1)).slice(-2));
  }, []);

  useEffect(() => {
    dispatch(getReachCareOrgIDThunk());
  }, []);

  useEffect(() => {
    if (reachCareOrgId) {
      if (loggedInUser?.organization_id === reachCareOrgId) { setIsFieldShow(true); }
    }
  }, [reachCareOrgId]);

  useEffect(() => {
    dispatch(getAccountRolesThunk())
    dispatch(getStateThunk())
  }, [])
  useEffect(() => {
    if (roles && roles.length > 0 && selectedRole === null) {
      const role = roles.filter((e: any) => e.role === ROLES.PATIENT)
      role.length > 0 && setSelectedRole(role[0]);
      setInitialValues({
        role_id: role[0].id,
        first_name: '',
        last_name: '',
        email: '',
        mobile_phone: '',
        home_address: '',
        city: '',
        state: '',
        birth_date: '',
      });
      setIsSet(isSet ? false : true)
    }
  })

  const submitData = async (values: any) => {
    if (patient_id === '') {
      setError(true);
    } else {
      // const birth = values.birth_date !== '' ? (new Date(values.birth_date).getFullYear() + '-' + (("0" + (new Date(values.birth_date).getMonth() + 1)).slice(-2)) + '-' + ("0" + (new Date(values.birth_date).getDate())).slice(-2)) : ''
      const role = roles.filter((e: any) => e.id === values.role_id);
      const data = values;
      data.birth_date = values.birth_date;
      data.zip = zip ? zip : '';
      data.patient_id = patient_id ? patient_id : '';
      data.role = role[0].role;
      await dispatch(saveAccountInfoThunk(data));
    }
  };

  const patientInfoSchema = Yup.object().shape({
    first_name: Yup.string().required('Required')
      .max(50, ''),
    last_name: Yup.string().required('Required')
      .max(50, ''),
    mobile_phone: Yup.string().min(10, "Too Short!")
      .max(12, ''),
    city: Yup.string()
      .max(50, ''),
    email: Yup.string().email('Email is invalid').required('Required'),
    role_id: Yup.string().required('Required'),
    home_address: Yup.string().min(10, "Too Short!").max(100, ''),
    birth_date: isFieldShow ? Yup.string().required("Required") : Yup.string(),
  });

  const isnumber = (value: any) => {
    const test = parseInt(value);
    if (test) {
      return Math.max(0, parseInt(value)).toString().slice(0, 12)
    } else {
      return null
    }
  }
  const handelChange = (value: any) => {
    const re = /^[0-9\b]+$/;
    if (re.test(value) && value.length < 6) {
      setZip(value)
    } else {
      value.length > 6 || value.length == 0 && setZip('')
    }
  }
  const handelChange1 = (value: any) => {
    const re = /^[0-9\b]+$/;
    if (re.test(value) && value.length < 11) {
      setError(false);
      setMasterId(value);
    } else {
      value.length > 11 || value.length == 0 && setMasterId('')
    }
  }

  const handleChange = (value: any, no: any) => {
    if (no === 0 && value.length > 50) {
      setIsError(true); setInputs(no);
    } else if (no === 1 && value.length > 50) {
      setIsError(true); setInputs(no);
    } else if (no === 2 && value.length > 50) {
      setIsError(true); setInputs(no);
    } else if (no === 3 && value.length > 100) {
      setIsError(true); setInputs(no);
    } else {
      setIsError(false); setInputs(no);
    }
  }

  return (
    <Dialog handleClose={handleClose} open={open}>
      <Box display="flex" flexDirection="column">
        <Box alignItems="center" display="flex" justifyContent="space-between" p={3} style={{ background: '#f9fafb', borderRadius: '10px 10px 0px 0px' }}>
          <Typography variant="h2">Add New User</Typography>
        </Box>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={submitData}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={patientInfoSchema}
        >
          {({ errors, isSubmitting, setSubmitting, touched, values }) => {

            return (
              <Form>
                <Box pt={3} px={3} style={{ width: '500px', overflow: 'auto', height: '500px', overflowX: 'hidden' }}>
                  <Box pb={2.5}>
                    <Box pb={0.75}>
                      <Typography color="textSecondary" variant="subtitle2">
                        Role*
                      </Typography>
                    </Box>

                    <Field as={Select} name="role_id" variant="outlined" className={classes.textField1} disabled>
                      {roles && roles.map((role: any) => {
                        return <MenuItem value={role.id}>{role?.role?.replace(/_/g, " ")}</MenuItem>
                      })}
                    </Field>

                    {errors.role_id && touched.role_id ? (
                      <Typography color="error" variant="subtitle2">
                        {errors.role_id}
                      </Typography>
                    ) : null}
                  </Box>
                  <Box pb={2.5}>
                    <Grid alignItems="center" container justify="center" spacing={3}>
                      <Grid item xs={6}>
                        <Box pb={0.75}>
                          <Typography color="textSecondary" variant="subtitle2">
                            First Name*
                          </Typography>
                        </Box>

                        <Field
                          style={{ background: isError && inputs === 0 ? '#fff1f1' : 'unset' }}
                          onInput={(e: any) => handleChange(e.target.value, 0)}
                          as={TextField}
                          inputProps={{ maxLength: 51 }}
                          className={classes.textField}
                          name="first_name"
                          size="small"
                          type="text"
                          variant="outlined"
                        />
                        <Box display='flex' justifyContent='space-between'>
                          <Typography color={"error"} variant="subtitle2">
                            {errors.first_name && touched.first_name && errors.first_name}
                          </Typography>
                          <Typography style={{ color: values.first_name?.length > 50 ? '#911717' : '#0000008a' }} variant="subtitle2">
                            {values.first_name?.length === 51 ? 50 : values.first_name?.length}/50
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box pb={0.75}>
                          <Typography color="textSecondary" variant="subtitle2">
                            Last Name*
                          </Typography>
                        </Box>

                        <Field
                          style={{ background: isError && inputs === 1 ? '#fff1f1' : 'unset' }}
                          onInput={(e: any) => handleChange(e.target.value, 1)}
                          as={TextField}
                          inputProps={{ maxLength: 51 }}
                          className={classes.textField}
                          name="last_name"
                          size="small"
                          variant="outlined"
                        />
                        <Box display='flex' justifyContent='space-between'>
                          <Typography color={"error"} variant="subtitle2">
                            {errors.last_name && touched.last_name && errors.last_name}
                          </Typography>
                          <Typography style={{ color: values.last_name?.length > 50 ? '#911717' : '#0000008a' }} variant="subtitle2">
                            {values.last_name?.length === 51 ? 50 : values.last_name?.length}/50
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box pb={2.5}>
                    <Box pb={0.75}>
                      <Typography color="textSecondary" variant="subtitle2">
                        Email Address*
                      </Typography>
                    </Box>

                    <Field
                      as={TextField}
                      className={classes.textField}
                      name="email"
                      size="small"
                      type="text"
                      variant="outlined"
                    />

                    {errors.email && touched.email ? (
                      <Typography color="error" variant="subtitle2">
                        {errors.email}
                      </Typography>
                    ) : null}
                  </Box>
                  <Box pb={2.5}>
                    <Box pb={0.75}>
                      <Typography color="textSecondary" variant="subtitle2">
                        Master Patient ID*
                      </Typography>
                    </Box>

                    <Field
                      as={TextField} value={patient_id}
                      onChange={(e: any) => handelChange1(e.target.value)}
                      className={classes.textField}
                      name="patient_id"
                      size="small"
                      variant="outlined"
                    />

                    {error && patient_id === '' ? (
                      <Typography color="error" variant="subtitle2">
                        {'Required'}
                      </Typography>
                    ) : null}
                  </Box>
                  <Box pb={2.5}>
                    <Box pb={0.75}>
                      <Typography color="textSecondary" variant="subtitle2">
                        Phone Number
                      </Typography>
                    </Box>

                    <Field
                      as={TextField}
                      onInput={(e: any) => {
                        e.target.value = e.target.value.length === 0 ? '' : isnumber(e.target.value)
                      }}
                      className={classes.textField}
                      name="mobile_phone"
                      size="small"
                      type="tel"
                      variant="outlined"
                    />

                    {errors.mobile_phone && touched.mobile_phone ? (
                      <Typography color="error" variant="subtitle2">
                        {errors.mobile_phone}
                      </Typography>
                    ) : null}
                  </Box>
                  {isFieldShow &&
                    <Box pb={2.5}>
                      <Box flexGrow="1">
                        <Box pb={0.75}>
                          <Typography color="textSecondary" variant="subtitle2">
                            Date of Birth *
                          </Typography>
                        </Box>

                        <Field
                          as={TextField}
                          name="birth_date"
                          size="small"
                          format="MM/dd/yyyy"
                          type="date"
                          variant="outlined"
                          style={{ width: '100%' }}
                          InputProps={{ inputProps: { max: disDate } }}
                        />
                        {errors.birth_date && touched.birth_date ? (
                          <Typography color="error" variant="subtitle2">
                            {errors.birth_date}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                  }
                  <Box pb={1}>
                    <Box pb={0.75}>
                      <Typography color="textSecondary" variant="subtitle2">
                        Address
                      </Typography>
                    </Box>

                    <Field
                      style={{ background: isError && inputs === 3 ? '#fff1f1' : 'unset' }}
                      onInput={(e: any) => handleChange(e.target.value, 3)}
                      as={TextField}
                      inputProps={{ maxLength: 101 }}
                      className={classes.textField}
                      name="home_address"
                      size="small"
                      variant="outlined"
                    />
                    <Box display='flex' justifyContent='space-between'>
                      <Typography color={"error"} variant="subtitle2">
                        {errors.home_address && touched.home_address && errors.home_address}
                      </Typography>
                      <Typography style={{ color: values.home_address?.length > 100 ? '#911717' : '#0000008a' }} variant="subtitle2">
                        {values.home_address?.length === 101 ? 100 : values.home_address?.length}/100
                      </Typography>
                    </Box>
                  </Box>
                  <Box pb={1}>
                    <Grid alignItems="center" container justify="center" spacing={3}>
                      <Grid item xs={6}>
                        <Box>
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              City
                            </Typography>
                          </Box>

                          <Field
                            style={{ background: isError && inputs === 2 ? '#fff1f1' : 'unset' }}
                            onInput={(e: any) => handleChange(e.target.value, 2)}
                            as={TextField}
                            inputProps={{ maxLength: 51 }}
                            className={classes.textField}
                            name="city"
                            size="small"
                            variant="outlined"
                          />
                          <Box display='flex' justifyContent='space-between'>
                            <Typography color={"error"} variant="subtitle2">
                              {''}
                            </Typography>
                            <Typography style={{ color: values.city?.length > 50 ? '#911717' : '#0000008a' }} variant="subtitle2">
                              {values.city?.length === 51 ? 50 : values.city?.length}/50
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={6} style={{ marginTop: '-18px' }}>
                        <Box>
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              State
                            </Typography>
                          </Box>

                          <Field as={Select} name="state" variant="outlined" className={classes.textField1}>
                            <MenuItem value={''}>Select</MenuItem>
                            {stateList && stateList.length > 0 && stateList.map((item: any) => {
                              return <MenuItem value={item.state}>{item.state}</MenuItem>
                            })}
                          </Field>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box pb={1.0}>
                    <Box pb={0.75}>
                      <Typography color="textSecondary" variant="subtitle2">
                        Zip Code
                      </Typography>
                    </Box>

                    <Field className={classes.textField1}
                      as={TextField}
                      value={zip} onChange={(e: any) => handelChange(e.target.value)}
                      name="zip"
                      size="small"
                      variant="outlined"
                      style={{ width: '100%' }}
                    />
                  </Box>
                </Box>
                <DialogActions style={{ display: "flex", justifyContent: "space-between", background: '#f9fafb', borderRadius: '0px 0px 10px 10px', padding: '20px' }}>
                  <LoadingButton
                    className={classes.secondaryButton}
                    onClick={handleClose}
                    variant="contained"
                  >
                    Cancel
                  </LoadingButton>
                  <LoadingButton
                    className={classes.primaryButton}
                    color="primary"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    type="submit"
                    variant="contained"
                    aria-label="sign in"
                  >
                    Save
                  </LoadingButton>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  topBox: {
    borderRadius: '0.625rem 0.625rem 0 0'
  },

  bottomBox: {
    borderRadius: '0 0 0.625rem 0.625rem'
  },
  primaryButton: {
    boxShadow:
      '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)'
  },
  inputField: {
    maxWidth: '28rem',
    width: '80vw',
    paddingBottom: '0.5rem'
  },
  textField: {
    width: '100%'
  },
  textAlign: {
    textAlign: 'center'
  },
  textField1: {
    width: '100%',
    height: '35px',
    padding: '0px'
  },
  box: {
    padding: '30px'
  },
}));
