import React from 'react';
import { Box } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { PatientSideBar } from '../../../components/PatientSideBar';
import { PatientTopBar } from '../components/PatientTopBar';
import { colors } from '../../../styling/colors';
import { useSelector } from 'react-redux';
import { concatName } from '../../../shared/utils';
import { RootState } from '../../../redux/redux-store';
import { PatientTasks } from '../PatientProfile/components/PatientTasks';
import { PatientReferrals } from '../PatientProfile/components/PatientReferrals';
import { useHistory } from 'react-router-dom';

export const PatientForms = () => {
  const classes = useStyles();
  const history = useHistory();
  const patientInfo: any = history.location && history.location.state;
  const loggedInUser = useSelector((state: RootState) => state.DATA_REDUCER.loggedInUser);

  return (
    <Box className={classes.main} display="flex" flexDirection="column" p={4}>
      <Box pb={5}>
        <PatientTopBar patientName={
          patientInfo && patientInfo.patient
            ? concatName({
              firstName: patientInfo.patient.first_name,
              lastName: patientInfo.patient.last_name,
              fallback: patientInfo.patient.email ?? '<No Patient Name Available>'
            })
            : ''
        } />
      </Box>
      <Box className={classes.body} display="flex">
        <Box className={classes.contentContainer} display="flex">
          <PatientSideBar />
          <Box display="flex" flexDirection="column" width="100%">
            <Box pb={5}>
              <PatientTasks patient={patientInfo && patientInfo.patient} />
            </Box>
            <Box pb={5}>
              <PatientReferrals />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    backgroundColor: colors.background1,
    minHeight: '100vh',
    boxSizing: 'border-box'
  },
  body: {
    marginTop: 40,
    display: 'flow-root'
  },
  contentContainer: {
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  }
}));