import { Box, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '../../../../components/LoadingButton';
import { RootState } from '../../../../redux/redux-store';
import { createExitPageLogicThunk } from '../../../../redux/thunk/thunks';
import { FormField } from '../../../../shared/domain';
import { ALLOWED_OPERATORS } from '../../../../shared/globals';
import { ExitPageLogicIsAllSelect } from '../exitPageSidebarSettings/ExitPageLogicIsAllSelect';
import { ExitPageLogicBlock } from './ExitPageLogicBlock';
import { ExitPageLogicUnavailable } from './ExitPageLogicUnavailable';

const _fieldsThatAllowLogic = (formFields: FormField[]) => {
  let fieldArr: FormField[] = [];
  if (formFields) {
    //filters all formfields that don't allow logic
    fieldArr = formFields?.filter(
      (field: FormField, i) =>
        ALLOWED_OPERATORS[field.field_type] && ALLOWED_OPERATORS[field.field_type].length > 0
    );
  }
  return fieldArr;
};

export const ExitPageLogicList: React.FC<{}> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const editingExitPage = useSelector((state: RootState) => state.DATA_REDUCER.editingExitPage);

  const editingFormFields = useSelector((state: RootState) => state.DATA_REDUCER.editingFormFields);

  const editingExitPageFieldLogicRules = useSelector(
    (state: RootState) => state.DATA_REDUCER.editingExitPageFieldLogicRules
  );
  const getExitPageFieldLogicRulesLoading = useSelector(
    (state: RootState) =>
      state.DISPLAY_STATE_REDUCER.displayLoading.getExitPageFieldLogicRulesLoading
  );
  const createExitPageFieldLogicLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.createExitPageFieldLogicLoading
  );

  if (!editingExitPage) {
    return null;
  }

  const addNewExitPageLogicRule = () => {
    //create new logic rule
    if (editingExitPage) {
      dispatch(createExitPageLogicThunk(editingExitPage.id));
    }
  };

  //if the selected exit page is the default or if editingFormFields are not set disable logic settings
  if (editingExitPage?.is_default || !editingFormFields) {
    return <ExitPageLogicUnavailable />;
  }

  return (
    <>
      <Box className={classes.header}>
        {editingExitPageFieldLogicRules?.length === 0 ? (
          <Typography variant={'subtitle2'} color={'textSecondary'}>
            You haven’t added any Logic rules to this field yet.
          </Typography>
        ) : (
          <ExitPageLogicIsAllSelect />
        )}
      </Box>
      <Box>
        {getExitPageFieldLogicRulesLoading
          ? 'loading...'
          : editingExitPageFieldLogicRules?.map((logicRule, i) => (
              <Box key={i}>
                {i > 0 ? <Divider className={classes.blockDivider} /> : null}
                <ExitPageLogicBlock
                  ruleNumber={i}
                  logicRule={logicRule}
                  fieldsThatAllowLogic={_fieldsThatAllowLogic(editingFormFields)}
                />
              </Box>
            ))}
      </Box>
      <Box mt={2}>
        <LoadingButton
          onClick={addNewExitPageLogicRule}
          loading={createExitPageFieldLogicLoading}
          variant="contained"
          fullWidth
          color={'primary'}
          aria-label={'add logic rule'}
        >
          Add Another Rule
        </LoadingButton>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '.6rem'
  },
  blockDivider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  }
}));
