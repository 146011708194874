import {
  Box,
  ButtonBase,
  CircularProgress,
  Divider,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from '../../../components/LegacyDialog';
import { RootState } from '../../../redux/redux-store';
import { formCreationThunk, updateFormsThunk } from '../../../redux/thunk/thunks';
import { colors } from '../../../styling/colors';

interface NewFormDialogProps {
  open: boolean;
  handleClose: () => void;
  handleDisplayDuplicateCustomFormDialog: () => void;
  handleDisplayDuplicateLibraryFormDialog: () => void;
}

export const NewFormDialog = ({
  open,
  handleClose,
  handleDisplayDuplicateCustomFormDialog,
  handleDisplayDuplicateLibraryFormDialog
}: NewFormDialogProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const creatingFormLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.createFormLoading
  );
  const createFormError = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayErrors.createFormError
  );

  const createForm = () => {
    dispatch(formCreationThunk());
  };

  return (
    <Dialog
      handleClose={handleClose}
      open={open}
      secondaryButton={{ text: 'Cancel', handleClick: handleClose }}
      title="Creating a new form"
    >
      <Box className={classes.main} display="flex" flexDirection="column">
        <ButtonBase
          className={classes.buttonRoot}
          component="div"
          onClick={handleDisplayDuplicateLibraryFormDialog}
          aria-label="pick from library"
        >
          <Box alignItems="center" display="flex" width="100%">
            <Box display="flex" flexDirection="column" width="100%">
              <Typography color="textSecondary" variant="subtitle1">
                Pick a Form from a Library
              </Typography>

              <Typography className={classes.descText} variant="body1">
                Start building a Form from one of FlowPRO's in-house curated Form library
              </Typography>
            </Box>

            <Box ml={8}>
              <ArrowForwardIcon />
            </Box>
          </Box>
        </ButtonBase>

        <Box py={3}>
          <Divider />
        </Box>

        <ButtonBase
          className={classes.buttonRoot}
          component="div"
          onClick={handleDisplayDuplicateCustomFormDialog}
          aria-label="duplicate form"
        >
          <Box alignItems="center" display="flex" width="100%">
            <Box display="flex" flexDirection="column" width="100%">
              <Typography color="textSecondary" variant="subtitle1">
                Duplicate an existing Form
              </Typography>

              <Typography className={classes.descText} variant="body1">
                Start building a Form from one of FlowPRO's in-house curated Form library
              </Typography>
            </Box>

            <Box ml={8}>
              <ArrowForwardIcon />
            </Box>
          </Box>
        </ButtonBase>

        <Box py={3}>
          <Divider />
        </Box>

        <ButtonBase
          className={classes.buttonRoot}
          component="div"
          onClick={createForm}
          aria-label="start from scratch"
        >
          <Box alignItems="center" display="flex" width="100%">
            <Box display="flex" flexDirection="column" width="100%">
              <Typography color="textSecondary" variant="subtitle1">
                Start from scratch
              </Typography>

              <Typography className={classes.descText} variant="body1">
                Start building a Form from scratch
              </Typography>
            </Box>

            <Box ml={8}>
              {creatingFormLoading ? <CircularProgress size={20} /> : <ArrowForwardIcon />}
            </Box>
          </Box>
        </ButtonBase>

        <Box pb={1} pt={3}>
          <Divider />
        </Box>
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    maxWidth: '32rem',
    width: '80vw'
  },
  descText: {
    color: colors.text5
  },
  buttonRoot: {
    display: 'flex',
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0
  }
}));
