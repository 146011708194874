import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../styling/colors';
import { Logo } from '../../components/Logo';

export const SSODisable = () => {
    const classes = useStyles();

    return (
        <Grid alignItems="center" className={classes.main} container justify="center" spacing={0}>
            <Grid>
                <Box mb={4} ml={4}>
                    <Logo />
                </Box>
                <Paper className={classes.signupPaper}>
                    <Box>
                        <Box pb={3} pt={2} px={4} display="flex" justifyContent="center">
                            <Typography color="textSecondary" variant="h2" style={{ textAlign: 'center', fontSize: '30px' }}>We're Sorry</Typography>
                        </Box>
                        <Box pb={4} pt={2} display="flex" justifyContent="center">
                            <Typography color="textSecondary" variant="h4" style={{ textAlign: 'center' }}>
                                It seems your account has not been invited. You must be invited in order to use this platform. If you feel this was a mistake, Please contact the
                                <a target="_blank" href={`mailto:${''}`}> administrator</a> for further information.</Typography>
                        </Box>
                        <Box pb={4} pt={2} display="flex" justifyContent="center">
                            <Link className={classes.link} to="/login"><Typography color="primary" variant="h4" style={{ textAlign: 'center', cursor: 'pointer' }}>Click here to return to FormFlash</Typography></Link>
                        </Box>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    main: {
        backgroundColor: colors.background1,
        minHeight: '100vh'
    },
    signupPaper: {
        margin: '0 2rem',
        maxWidth: '30rem',
        width: '80vw'
    },
    textField: {
        width: '100%'
    },
    link: {
        textDecoration: 'none'
    },
    agreeLabel: {
        color: colors.text4
    },
    submitButton: {
        width: '100%'
    }
}));
