import { Box, makeStyles, Paper, TextField, Theme, Typography, MenuItem, Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getPatientInfoThunk, updateProfile, getStateThunk, getUnitThunk, getDepartmentThunk, deleteUsersThunk, getSecondarySiteAdmin, newSiteAdminThunk } from '../../../redux/thunk/thunks';
import { RootState } from '../../../redux/redux-store';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { Dialog } from '../../../components/Dialog';
import * as Yup from "yup";
import { LoadingButton } from '../../../components/LoadingButton';
import { colors } from '../../../styling/colors';
import { LoadingOrError } from '../../../components/LoadingOrError';
import { ROLES } from '../../../shared/globals';
import { setSuccessAction } from '../../../redux/display/display-actions';

export const MyProfile = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { path } = useRouteMatch();
    const split = path.split('/');
    const patient_role_id = split && split.length > 1 && split[2];
    const [isEdit, setIsEdit] = useState(false);
    const [isCancel, setIsCancel] = useState(false);
    const [disDate, setDisabledDate] = useState<any>();
    const user: any = useSelector((state: RootState) => state.DATA_REDUCER.patientInfo);
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const [isOpen, setIsOpen] = useState(false);
    const [input, setInput] = useState('');
    const [siteAdmin, setSiteAdmin] = useState<any>('');
    const [isError, setError] = useState(false);
    const [inputs, setInputs] = useState(null);
    const [siteAdminPopup, setOpenSiteAdminPopup] = useState(false);

    const viewData: any = {
        role: user ? user.role.replace(/_/g, " ") : '',
        emails: user?.email || '',
        patient_id: user?.patient_id || '',
        firstName: user?.first_name || '',
        lastName: user?.last_name || '',
        mobilePhone: user?.mobile_phone || '',
        birthDate: user?.birth_date || '',
        homeAddress: user?.home_address || '',
        citys: user?.city || '',
        state: user?.state || '',
        zips: user?.zip || '',
        id: user?.id || '',
        notes: user?.notes || '',
        unit_id: user?.unit_id || null,
        department_id: user?.department_id || null,
    }
    const [zip, setZip] = useState<any>(user?.zip || '');

    const loadingUser = useSelector(
        (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.getDataLoading
    );
    const loadingUserError = useSelector(
        (state: RootState) => state.DISPLAY_STATE_REDUCER.displayErrors.getLoggedInUserError
    );
    const newPrimarySiteAdmin = useSelector(
        (state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.newPrimarySiteAdmin
    );

    useEffect(() => {
        !user && dispatch(getPatientInfoThunk(patient_role_id));
        dispatch(getStateThunk());
    }, [])

    useEffect(() => {
        if (newPrimarySiteAdmin) {
            dispatch(setSuccessAction('newPrimarySiteAdmin', false));
            setOpenSiteAdminPopup(false);
            setIsEdit(false);
            setSiteAdmin('');
            setInput('');
        }
    }, [newPrimarySiteAdmin])

    const initialValues: any = {
        role: user ? user.role.replace(/_/g, " ") : '',
        email: user?.email || '',
        patient_id: user?.patient_id || '',
        first_name: user?.first_name || '',
        last_name: user?.last_name || '',
        mobile_phone: user?.mobile_phone || '',
        birth_date: user?.birth_date || '',
        home_address: user?.home_address || '',
        city: user?.city || '',
        state: user?.state || '',
        zip: user?.zip || '',
        id: user?.id || '',
        userId: user?.user_id || '',
        user_role_id: user?.user_role_id || '',
        notes: user?.notes || '',
        unit_id: user?.unit_id || null,
        department_id: user?.department_id || null,
    }
    const stateList: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.stateList
    );

    useEffect(() => {
        let newDate: any = Date.now();
        setDisabledDate(new Date(newDate).getFullYear() + '-' + (("0" + (new Date(newDate).getMonth() + 1)).slice(-2)) + '-' + ("0" + (new Date(newDate).getDate() - 1)).slice(-2));
    }, []);

    const handleSubmit = async (values: any, actions: any) => {
        actions.setSubmitting(true);
        const data = values;
        data.zip = zip ? zip : '';
        data.zip = zip ? zip : '';
        await dispatch(updateProfile(data));
        dispatch(getPatientInfoThunk(patient_role_id))
        actions.setSubmitting(false);
        setIsEdit(false);
    };

    const profileSchema = Yup.object().shape({
        first_name: Yup.string().required("Required")
            .max(50, ''),
        last_name: Yup.string().required("Required").min(1, "Too Short!")
            .max(50, ''),
        city: Yup.string().max(50, ''),
        state: Yup.string().max(50, ''),
        zip: Yup.string().min(5, "Too Short!")
            .max(5, ''),
        mobile_phone: Yup.string().min(10, "Too Short!")
            .max(12, ''),
        home_address: Yup.string()
            .max(100, ''),
        notes: Yup.string()
            .max(250, ''),
        email: Yup.string().email("Enter Valid Email").required("Please enter Email"),
    });

    const handelCancel = () => {
        setIsEdit(false)
        setIsCancel(isCancel ? false : true)
    }

    const handelEdit = (value: boolean) => {
        setIsEdit(value);
        loggedInUser && loggedInUser.organization_id && dispatch(getSecondarySiteAdmin());
    }

    const deletePopup = () => {
        setIsOpen(true);
    }

    const handleClose = () => {
        setIsOpen(false);
        setOpenSiteAdminPopup(false);
    }

    const deleteProfile = async () => {
        await dispatch(deleteUsersThunk(user?.user_role_id, null));
    }

    const isnumber = (value: any) => {
        const test = parseInt(value);
        if (test) {
            return Math.max(0, parseInt(value)).toString().slice(0, 12)
        } else {
            return null
        }
    }

    const handelChange = (value: any) => {
        const re = /^[0-9\b]+$/;
        if (re.test(value) && value.length < 6) {
            setZip(value)
        } else {
            value.length > 6 || value.length == 0 && setZip('')
        }
    }

    const handleChange = (value: any, no: any) => {
        if (no === 0 && value.length > 50) {
            setError(true); setInputs(no);
        } else if (no === 1 && value.length > 50) {
            setError(true); setInputs(no);
        } else if (no === 2 && value.length > 50) {
            setError(true); setInputs(no);
        } else if (no === 3 && value.length > 100) {
            setError(true); setInputs(no);
        } else if (no === 4 && value.length > 500) {
            setError(true); setInputs(no);
        } else {
            setError(false); setInputs(no);
        }
    }

    return (
        <>
            <Paper>
                {isEdit === false && <Box
                    className={classes.topBox1}
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    py={2.5}
                >
                    <Box alignItems="center" display="flex" justifyContent="space-between" px={4}>
                        <Typography variant="h2">My Profile</Typography>
                        <Box display="flex" justifyContent="space-between">
                            <LoadingButton
                                className={classes.primaryButton}
                                color='primary'
                                variant="contained"
                                onClick={(e) => handelEdit(true)}
                            >
                                Edit
                            </LoadingButton>
                        </Box>
                    </Box>
                </Box>
                }
                {loadingUser ? (
                    <LoadingOrError
                        errorMsg={loadingUserError}
                        loading={true}
                        loadingMsg="Loading User Profile"
                        noElevation
                    />
                ) : (isEdit ?
                    <Box pb={4} pt={3} px={4} display="flex">
                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                            validateOnBlur={false}
                            validateOnChange={false}
                            validationSchema={profileSchema}
                        >
                            {({ errors, touched, isSubmitting, values }) => {
                                return (
                                    <Box>
                                        <Form className={classes.formCont}>
                                            {isEdit && <Box
                                                className={classes.topBox}
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="space-between"
                                                py={2.5}
                                            >
                                                <Box alignItems="center" display="flex" justifyContent="space-between">
                                                    <Typography variant="h2">My Profile</Typography>
                                                    <Box display="flex" justifyContent="space-between">
                                                        <LoadingButton
                                                            className={classes.secondaryButton}
                                                            color="secondary"
                                                            variant="contained"
                                                            onClick={(e) => handelCancel()}
                                                        >
                                                            Cancel
                                                        </LoadingButton>
                                                        <LoadingButton color='primary'
                                                            className={classes.primaryButton}
                                                            variant="contained"
                                                            type='submit'
                                                            disabled={isSubmitting}
                                                        >
                                                            Save
                                                        </LoadingButton>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            }
                                            <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                                                <Box flexGrow="1">
                                                    <Box pb={0.75}>
                                                        <Typography color="textSecondary" variant="subtitle2">
                                                            First Name*
                                                        </Typography>
                                                    </Box>

                                                    <Field style={{ background: isError && inputs === 0 ? '#fff1f1' : 'unset' }}
                                                        onInput={(e: any) => handleChange(e.target.value, 0)}
                                                        as={TextField}
                                                        inputProps={{ maxLength: 51 }}
                                                        disabled={isEdit ? false : true}
                                                        name="first_name"
                                                        size="small"
                                                        type="text"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                    <Box display='flex' justifyContent='space-between'>
                                                        <Typography color={"error"} variant="subtitle2">
                                                            {errors.first_name && touched.first_name && errors.first_name}
                                                        </Typography>
                                                        <Typography style={{ color: values.first_name?.length > 50 ? '#911717' : '#0000008a' }} variant="subtitle2">
                                                            {values.first_name?.length === 51 ? 50 : values.first_name?.length}/50
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>

                                            <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                                                <Box flexGrow="1">
                                                    <Box pb={0.75}>
                                                        <Typography color="textSecondary" variant="subtitle2">
                                                            Last Name*
                                                        </Typography>
                                                    </Box>

                                                    <Field style={{ background: isError && inputs === 1 ? '#fff1f1' : 'unset' }}
                                                        onInput={(e: any) => handleChange(e.target.value, 1)}
                                                        as={TextField}
                                                        inputProps={{ maxLength: 51 }}
                                                        disabled={isEdit ? false : true}
                                                        name="last_name"
                                                        size="small"
                                                        type="text"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                    <Box display='flex' justifyContent='space-between'>
                                                        <Typography color={"error"} variant="subtitle2">
                                                            {errors.last_name && touched.last_name && errors.last_name}
                                                        </Typography>
                                                        <Typography style={{ color: values.last_name?.length > 50 ? '#911717' : '#0000008a' }} variant="subtitle2">
                                                            {values.last_name?.length === 51 ? 50 : values.last_name?.length}/50
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                                                <Box flexGrow="1">
                                                    <Box pb={0.75}>
                                                        <Typography color="textSecondary" variant="subtitle2">
                                                            Patient ID*
                                                        </Typography>
                                                    </Box>

                                                    <Field
                                                        as={TextField}
                                                        disabled
                                                        name="patient_id"
                                                        size="small"
                                                        type="text"
                                                        variant="outlined"
                                                        style={{ width: '100%' }}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                                                <Box flexGrow="1">
                                                    <Box pb={0.75}>
                                                        <Typography color="textSecondary" variant="subtitle2">
                                                            Phone Number
                                                        </Typography>
                                                    </Box>

                                                    <Field
                                                        as={TextField}
                                                        disabled={isEdit ? false : true}
                                                        name="mobile_phone"
                                                        size="small"
                                                        onInput={(e: any) => {
                                                            e.target.value = e.target.value.length === 0 ? '' : isnumber(e.target.value)
                                                        }}
                                                        type="tel"
                                                        variant="outlined"
                                                        style={{ width: '100%' }}
                                                    />
                                                    {errors.mobile_phone && touched.mobile_phone ? (
                                                        <Typography color="error" variant="subtitle2">
                                                            {errors.mobile_phone}
                                                        </Typography>
                                                    ) : null}
                                                </Box>
                                            </Box>
                                            <Box pb={2.5} pr={4} className={classes.textFieldContainer}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Email Address*
                                                    </Typography>
                                                </Box>

                                                <Field
                                                    as={TextField}
                                                    disabled={true}
                                                    className={classes.textField}
                                                    name="email"
                                                    size="small"
                                                    variant="outlined"
                                                />
                                                {errors.email && touched.email ? (
                                                    <Typography color="error" variant="subtitle2">
                                                        {errors.email}
                                                    </Typography>
                                                ) : null}
                                            </Box>
                                            <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                                                <Box flexGrow="1">
                                                    <Box pb={0.75}>
                                                        <Typography color="textSecondary" variant="subtitle2">
                                                            Date of Birth
                                                        </Typography>
                                                    </Box>

                                                    <Field
                                                        as={TextField}
                                                        disabled={isEdit ? false : true}
                                                        name="birth_date"
                                                        size="small"
                                                        type="date"
                                                        variant="outlined"
                                                        style={{ width: '100%' }}
                                                        format="MM/dd/yyyy"
                                                        InputProps={{ inputProps: { max: disDate } }}
                                                    />
                                                    {errors.birth_date && touched.birth_date ? (
                                                        <Typography color="error" variant="subtitle2">
                                                            {errors.birth_date}
                                                        </Typography>
                                                    ) : null}
                                                </Box>
                                            </Box>

                                            <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                                                <Box flexGrow="1">
                                                    <Box pb={0.75}>
                                                        <Typography color="textSecondary" variant="subtitle2">
                                                            Address
                                                        </Typography>
                                                    </Box>

                                                    <Field fullWidth style={{ background: isError && inputs === 3 ? '#fff1f1' : 'unset' }}
                                                        onInput={(e: any) => handleChange(e.target.value, 3)}
                                                        as={TextField}
                                                        inputProps={{ maxLength: 101 }}
                                                        disabled={isEdit ? false : true}
                                                        name="home_address"
                                                        size="small"
                                                        type="text"
                                                        variant="outlined"
                                                    />
                                                    <Box display='flex' justifyContent='space-between'>
                                                        <Typography color={"error"} variant="subtitle2">
                                                            {errors.home_address && touched.home_address && errors.home_address}
                                                        </Typography>
                                                        <Typography style={{ color: values.home_address?.length > 100 ? '#911717' : '#0000008a' }} variant="subtitle2">
                                                            {values.home_address?.length === 101 ? 100 : values.home_address?.length}/100
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                                                <Box flexGrow="1">
                                                    <Box pb={0.75}>
                                                        <Typography color="textSecondary" variant="subtitle2">
                                                            City
                                                        </Typography>
                                                    </Box>

                                                    <Field fullWidth style={{ background: isError && inputs === 2 ? '#fff1f1' : 'unset' }}
                                                        onInput={(e: any) => handleChange(e.target.value, 2)}
                                                        as={TextField}
                                                        inputProps={{ maxLength: 51 }}
                                                        disabled={isEdit ? false : true}
                                                        name="city"
                                                        size="small"
                                                        type="text"
                                                        variant="outlined"
                                                    />
                                                    <Box display='flex' justifyContent='space-between'>
                                                        <Typography color={"error"} variant="subtitle2">
                                                            {''}
                                                        </Typography>
                                                        <Typography style={{ color: values.city?.length > 50 ? '#911717' : '#0000008a' }} variant="subtitle2">
                                                            {values.city?.length === 51 ? 50 : values.city?.length}/50
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>

                                            <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                                                <Box flexGrow="1">
                                                    <Box pb={0.75}>
                                                        <Typography color="textSecondary" variant="subtitle2">
                                                            State
                                                        </Typography>
                                                    </Box>

                                                    <Field disabled={isEdit ? false : true} as={Select} name="state" variant="outlined" className={classes.textField1}>
                                                        <MenuItem value={''}>Select</MenuItem>
                                                        {stateList && stateList.length > 0 && stateList.map((item: any) => {
                                                            return <MenuItem value={item.state}>{item.state}</MenuItem>
                                                        })}
                                                    </Field>

                                                    {errors.state && touched.state ? (
                                                        <Typography color="error" variant="subtitle2">
                                                            {errors.state}
                                                        </Typography>
                                                    ) : null}
                                                </Box>
                                            </Box>
                                            <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                                                <Box flexGrow="1">
                                                    <Box pb={0.75}>
                                                        <Typography color="textSecondary" variant="subtitle2">
                                                            Zip Code
                                                        </Typography>
                                                    </Box>

                                                    <Field
                                                        as={TextField}
                                                        value={zip}
                                                        onChange={(e: any) => handelChange(e.target.value)}
                                                        disabled={isEdit ? false : true}
                                                        name="zip"
                                                        size="small"
                                                        variant="outlined"
                                                        style={{ width: '100%' }}
                                                    />
                                                    {errors.zip && touched.zip ? (
                                                        <Typography color="error" variant="subtitle2">
                                                            {errors.zip}
                                                        </Typography>
                                                    ) : null}
                                                </Box>
                                            </Box>
                                            <Box pb={2.5} pr={4} style={{ width: '100%' }} display="flex">
                                                <Box flexGrow="1" style={{ paddingRight: '50px' }}>
                                                    <Box pb={0.75}>
                                                        <Typography color="textSecondary" variant="subtitle2">
                                                            Notes
                                                        </Typography>
                                                    </Box>

                                                    <Field fullWidth multiline rows={3} style={{ background: isError && inputs === 4 ? '#fff1f1' : 'unset' }}
                                                        onInput={(e: any) => handleChange(e.target.value, 4)}
                                                        as={TextField}
                                                        inputProps={{ maxLength: 501 }}
                                                        disabled={isEdit ? false : true}
                                                        name="notes"
                                                        size="small"
                                                        type="text"
                                                        variant="outlined"
                                                    />
                                                    <Box display='flex' justifyContent='space-between'>
                                                        <Typography color={"error"} variant="subtitle2">
                                                            {''}
                                                        </Typography>
                                                        <Typography style={{ color: values.notes?.length > 500 ? '#911717' : '#0000008a' }} variant="subtitle2">
                                                            {values.notes?.length === 501 ? 500 : values.notes?.length}/500
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            {isEdit &&
                                                <Box style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', flexDirection: 'column', alignItems: 'flex-end' }}>
                                                    {loggedInUser && (loggedInUser.primary_site_admin === null || loggedInUser.primary_site_admin === false) && ![ROLES.REFERRAL, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role) && <LoadingButton
                                                        className={classes.deleteButton}
                                                        color="secondary"
                                                        variant="contained"
                                                        onClick={() => deletePopup()}
                                                    >
                                                        Delete
                                                    </LoadingButton>}
                                                    {loggedInUser && loggedInUser.role === ROLES.SITE_ADMIN && loggedInUser.primary_site_admin && <Typography style={{ color: '#0060B2', marginTop: '20px', cursor: 'pointer' }} onClick={(e) => setOpenSiteAdminPopup(true)}>Designate new Primary Site Admin</Typography>}
                                                </Box>
                                            }
                                        </Form>
                                    </Box>
                                );
                            }}
                        </Formik>
                    </Box> :
                    <Box pb={4} pt={3} px={4} display="flex">
                        <Formik
                            enableReinitialize={true}
                            initialValues={viewData}
                            onSubmit={handleSubmit}
                            validateOnBlur={false}
                            validateOnChange={false}
                            validationSchema={profileSchema}
                        >
                            <Box>
                                <Form className={classes.formCont}>
                                    <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                                        <Box flexGrow="1">
                                            <Box pb={0.75}>
                                                <Typography color="textSecondary" variant="subtitle2">
                                                    First Name*
                                                </Typography>
                                            </Box>

                                            <Field
                                                as={TextField}
                                                disabled={true}
                                                name="firstName"
                                                size="small"
                                                type="text"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                            />
                                        </Box>
                                    </Box>

                                    <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                                        <Box flexGrow="1">
                                            <Box pb={0.75}>
                                                <Typography color="textSecondary" variant="subtitle2">
                                                    Last Name*
                                                </Typography>
                                            </Box>

                                            <Field
                                                as={TextField}
                                                disabled={true}
                                                name="lastName"
                                                size="small"
                                                type="text"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                                        <Box flexGrow="1">
                                            <Box pb={0.75}>
                                                <Typography color="textSecondary" variant="subtitle2">
                                                    Patient ID*
                                                </Typography>
                                            </Box>

                                            <Field
                                                as={TextField}
                                                disabled
                                                name="patient_id"
                                                size="small"
                                                type="text"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                            />
                                        </Box>
                                    </Box>

                                    <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                                        <Box flexGrow="1">
                                            <Box pb={0.75}>
                                                <Typography color="textSecondary" variant="subtitle2">
                                                    Phone Number
                                                </Typography>
                                            </Box>

                                            <Field
                                                as={TextField}
                                                disabled={true}
                                                name="mobilePhone"
                                                size="small"
                                                onInput={(e: any) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 12)
                                                }}
                                                type="number"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box pb={2.5} pr={4} className={classes.textFieldContainer}>
                                        <Box pb={0.75}>
                                            <Typography color="textSecondary" variant="subtitle2">
                                                Email Address*
                                            </Typography>
                                        </Box>

                                        <Field
                                            as={TextField}
                                            disabled={true}
                                            className={classes.textField}
                                            name="emails"
                                            size="small"
                                            variant="outlined"
                                        />
                                    </Box>
                                    <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                                        <Box flexGrow="1">
                                            <Box pb={0.75}>
                                                <Typography color="textSecondary" variant="subtitle2">
                                                    Date of Birth
                                                </Typography>
                                            </Box>

                                            <Field
                                                as={TextField}
                                                disabled={true}
                                                name="birthDate"
                                                size="small"
                                                type="date"
                                                format="MM/dd/yyyy"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                InputProps={{ inputProps: { max: disDate } }}
                                            />
                                        </Box>
                                    </Box>

                                    <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                                        <Box flexGrow="1">
                                            <Box pb={0.75}>
                                                <Typography color="textSecondary" variant="subtitle2">
                                                    Address
                                                </Typography>
                                            </Box>

                                            <Field
                                                as={TextField}
                                                disabled={true}
                                                name="homeAddress"
                                                size="small"
                                                type="text"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                                        <Box flexGrow="1">
                                            <Box pb={0.75}>
                                                <Typography color="textSecondary" variant="subtitle2">
                                                    City
                                                </Typography>
                                            </Box>

                                            <Field
                                                as={TextField}
                                                disabled={true}
                                                name="citys"
                                                size="small"
                                                type="text"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                            />
                                        </Box>
                                    </Box>

                                    <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                                        <Box flexGrow="1">
                                            <Box pb={0.75}>
                                                <Typography color="textSecondary" variant="subtitle2">
                                                    State
                                                </Typography>
                                            </Box>

                                            <Field disabled={true} as={Select} name="state" variant="outlined" className={classes.textField1}>
                                                <MenuItem value={''}>Select</MenuItem>
                                                {stateList && stateList.length > 0 && stateList.map((item: any) => {
                                                    return <MenuItem value={item.state}>{item.state}</MenuItem>
                                                })}
                                            </Field>
                                        </Box>
                                    </Box>
                                    <Box pb={2.5} pr={4} className={classes.textFieldContainer} display="flex">
                                        <Box flexGrow="1">
                                            <Box pb={0.75}>
                                                <Typography color="textSecondary" variant="subtitle2">
                                                    Zip Code
                                                </Typography>
                                            </Box>

                                            <Field
                                                as={TextField}
                                                // value={zip} onChange={(e: any) => handelChange(e.target.value)}
                                                disabled={true}
                                                name="zips"
                                                size="small"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box pb={2.5} pr={4} display="flex" style={{ width: '68vw', maxWidth: '6865rem' }}>
                                        <Box flexGrow="1">
                                            <Box pb={0.75}>
                                                <Typography color="textSecondary" variant="subtitle2">
                                                    Notes
                                                </Typography>
                                            </Box>

                                            <Field multiline rows={3}
                                                as={TextField}
                                                disabled={true}
                                                name="notes"
                                                size="small"
                                                type="text"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                            />
                                        </Box>
                                    </Box>
                                </Form>
                            </Box>
                        </Formik>
                    </Box>

                )}
            </Paper>
            {isOpen &&
                <Dialog handleClose={handleClose} open={isOpen}>
                    <Box style={{ width: '500px' }}>
                        <Box pb={3} pt={2} px={4} mb={2} display="flex" justifyContent="space-between" style={{ background: '#F9FAFB', borderRadius: '10px' }}>
                            <Typography color="textSecondary" variant="h3">Are you sure you want to delete your account? This cannot be undone.</Typography>
                        </Box>
                        <Box px={4} pb={2}>
                            <Box flexGrow="1">
                                <Box pb={0.75}>
                                    <Typography color="textSecondary" variant="subtitle2">
                                        Enter password to delete
                                    </Typography>
                                </Box>
                                <TextField fullWidth name="input" variant='outlined' size='small' autoComplete='off' value={input} onChange={(e) => setInput(e.target.value)} />
                            </Box>
                        </Box>
                        <Box
                            className={classes.bottomBox}
                            display="flex"
                            justifyContent="space-between"
                            px={3}
                            py={2}
                        >
                            <LoadingButton
                                className={classes.secondaryButton}
                                color="secondary"
                                onClick={handleClose}
                                variant="contained"
                                aria-label="cancel"
                            >
                                Cancel
                            </LoadingButton>

                            <LoadingButton
                                className={classes.deleteButton}
                                color="secondary"
                                onClick={deleteProfile}
                                variant="contained"
                                aria-label="confirm"
                            >
                                Delete
                            </LoadingButton>
                        </Box>
                    </Box>
                </Dialog>
            }
        </>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    topBox: {
        backgroundColor: theme.palette.secondary.light,
        borderBottom: '0.0625rem solid #D1D6DC',
        borderRadius: '0.625rem 0.625rem 0 0',
        paddingLeft: '32px',
        paddingRight: '32px',
        marginBottom: '30px',
        marginLeft: '-32px',
        marginRight: '-32px',
        marginTop: '-25px',
        paddingTop: '22px',
        width: '-webkit-fill-available'
    },
    bottomBox: {
        borderRadius: '0 0 0.625rem 0.625rem',
        background: '#F9FAFB'
    },
    topBox1: {
        backgroundColor: theme.palette.secondary.light,
        borderBottom: '0.0625rem solid #D1D6DC',
        borderRadius: '0.625rem 0.625rem 0 0'
    },
    primaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#fff',
        minWidth: '120px',
        background: '#0060B2'
    },
    deleteButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        height: '2rem',
        color: 'red',
        minWidth: '120px'
    },
    secondaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        height: '2rem',
        color: '#0060B2',
        minWidth: '120px',
        marginRight: '20px'
    },
    textFieldContainer: {
        maxWidth: '18rem',
        width: '65vw'
    },
    textField: {
        width: '100%'
    },
    link: {
        textDecoration: 'none'
    },
    rememberLabel: {
        color: colors.text4
    },
    openDialogoeBtn: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        marginLeft: '1rem'
    },
    formCont: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%'
    },
    boldFont: {
        fontWeight: 600
    },
    textField1: {
        width: '100%',
        height: '35px',
        padding: '0px'
    },
    textField2: {
        width: '100%',
        height: '55px',
        padding: '0px'
    }
}));
