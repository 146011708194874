import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { Logo } from '../../components/Logo';
import { ROLES } from '../../shared/globals';
import { useHistory } from 'react-router-dom';

interface ErrorPageProps {
  statusCode: STATUS_CODE;
}

type STATUS_CODE = '422' | '404';

export const ErrorPage: React.FC<ErrorPageProps> = ({ statusCode }) => {
  const classes = useStyles();
  const history = useHistory();
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);

  const message = (statusCode: STATUS_CODE) => {
    switch (statusCode) {
      case '422':
        return '422 UNAUTHORIZED ACCESS';
      case '404':
        return '404 NOT FOUND';
      default:
        return null;
    }
  };

  const backHome = () => {
    const token: any = localStorage.getItem('token');
    if (loggedInUser && token) {
      if ([ROLES.PATIENT, ROLES.BUILDER].includes(loggedInUser.role)) {
        history.push(`/forms`);
      } else if (loggedInUser.role === ROLES.CLINICIAN) {
        history.push(`/forms-collections`);
      } else if (loggedInUser.role === ROLES.REFERRAL) {
        history.push(`/referral-profile`);
      } else {
        history.push(`/forms`);
      }
    }
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.title}>{statusCode}</Box>
      <Box>{message(statusCode)}</Box>
      <Box pt={2}>
        <Logo />
      </Box>
      <Box pt={2}>
        <Typography style={{ cursor: 'pointer' }} onClick={() => backHome()}>{'< Back'}</Typography>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: theme.background.main
  },
  title: {
    fontSize: 124
  }
}));
