import { Box, FormGroup, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckboxOne from '@material-ui/core/Checkbox';
import React, { useState } from 'react';
import { Checkbox } from '../../../components/Checkbox';
import { FieldOption, UuidType } from '../../../shared/domain';
import { TitleInfo } from './TitleInfo';

interface MultipleChoiceQuestionProps {
  fieldTitle: string;
  options: FieldOption[];
  color: string;
  handleInput: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  handleMultipleChangeInput: (value: string) => void;
  handleCustomTextInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  textInput?: string;
  responseValue?: UuidType[];
  clinicianInfo?: string;
  patientInfo?: string;
  index: any;
}

interface ComponentProps {
  customColor: string;
}
export const MultipleChoiceQuestion: React.FC<MultipleChoiceQuestionProps> = ({
  fieldTitle: title,
  options,
  color,
  handleInput,
  handleMultipleChangeInput,
  handleCustomTextInput,
  textInput,
  responseValue,
  clinicianInfo,
  patientInfo,
  index
}) => {
  const props = { customColor: color };
  const classes = useStyles(props);
  return (
    <>
      <Box p={1}>
        <TitleInfo patientInfo={patientInfo} clinicianInfo={clinicianInfo} title={title} index={index} />
      </Box>
      <FormGroup className={classes.checkboxGroup}>
        {options?.map((option, i) => {
          if (option.option_text) {
            const checked = responseValue?.includes(option.id);
            return (
              <Box key={i} className={checked ? classes.selected : classes.root} onClick={() => handleMultipleChangeInput(option.id)}>
                {checked ? <Checkbox checked={checked}
                  name={option.option_text}
                  onChange={handleInput}
                  value={option.id}
                  bgColor={color}
                /> :
                  <CheckboxOne checked={false}
                    name={option.option_text}
                    onChange={handleInput}
                    value={option.id}
                  />
                }
                <Typography color={checked ? 'secondary' : 'textPrimary'} variant={'subtitle1'}>
                  {option.option_text}
                </Typography>
                {option.allow_user_input && (
                  <TextField
                    color={checked ? 'secondary' : 'primary'}
                    inputProps={{ className: classes.customInputText }}
                    disabled={!checked}
                    className={checked ? classes.selectedCustomInput : classes.customInput}
                    label="Input your answer here"
                    variant="outlined"
                    size="small"
                    onChange={handleCustomTextInput}
                    value={textInput}
                    fullWidth
                  />
                )}
              </Box>
            );
          }
        })}
      </FormGroup>
    </>
  );
};

//TODO: Make border of checkbox white when selected
const useStyles = makeStyles((theme) => ({
  customInput: {
    marginLeft: '2rem'
  },
  selectedCustomInput: {
    'marginLeft': '2rem',
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white'
    },
    '& .MuiOutlinedInput-input': {
      color: 'white'
    },
    '& .MuiInputLabel-outlined': {
      color: 'white'
    }
  },
  customInputText: {
    color: 'white',
    fontColor: 'white',
    borderColor: 'white'
  },
  test: {
    backgroundColor: 'red'
  },
  checkboxGroup: {
    width: '100%'
  },
  selectRoot: {
    padding: '.6rem'
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '2rem',
    margin: '.4rem',
    padding: '.3rem',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[300]}`,
    cursor: 'pointer'
  },
  selected: (props: ComponentProps) => ({
    backgroundColor: props.customColor,
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    height: '2rem',
    margin: '.4rem',
    padding: '.3rem',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[300]}`,
    cursor: 'pointer'
  })
}));
