import {
  Backdrop,
  createStyles,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Modal,
  Tab,
  Tabs,
  Theme,
  Typography,
  withStyles
} from '@material-ui/core';
import React, { useState } from 'react';
import { theme } from '../../../styling/ThemeConfig';
import { FieldDisplayBlock } from './fieldBlocks/FieldDisplayBlock';
import { FieldTypeSettings } from './fieldSidebarSettings/FieldTypeSettings';
import { FieldLogic } from './fieldSidebarLogic/FieldLogic';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/redux-store';
import { FIELD_TYPES } from '../../../shared/globals';
import { FieldCalculations } from './fieldSidebarCalculations/FieldCalculations';

interface FieldSettingsViewProps {
  open: boolean;
  handleClose: () => void;
}
enum NavEnums {
  Settings = 0,
  Logic = 1,
  Calculations = 2
}
export const FieldSettingsView: React.FC<FieldSettingsViewProps> = ({ open, handleClose }) => {
  const classes = useStyles();
  const [nav, setNav] = useState<NavEnums>(0);

  const editingFormField = useSelector((state: RootState) => state.DATA_REDUCER.editingFormField);

  const handleNavChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setNav(newValue);
  };

  const fieldType = (fieldType: string | undefined) => {
    if (fieldType) {
      switch (fieldType) {
        case FIELD_TYPES.SHORT_TEXT:
          return 'Short Answer';
        case FIELD_TYPES.LONG_TEXT:
          return 'Long Answer';
        case FIELD_TYPES.DROPDOWN:
          return 'Dropdown';
        case FIELD_TYPES.SINGLE_CHOICE:
          return 'Single Choice';
        case FIELD_TYPES.MULTIPLE_CHOICE:
          return 'Multiple Choice';
        case FIELD_TYPES.SCALE:
          return 'Scale';
        case FIELD_TYPES.RESOURCE:
          return 'Resource';
        default:
          break;
      }
    }
  };
  return (
    <Modal open={open} onClose={handleClose} className={classes.modal} BackdropComponent={Backdrop}>
      <Grid container>
        <Grid className={classes.settingsDrawer} item xs={3}>
          <div className={classes.drawerHeaderContainer}>
            <div className={classes.drawerHeader}>
              <div className={classes.headLeft}>
                <Typography color={'textSecondary'}>FIELD SETTINGS</Typography>
                <Typography color={'textPrimary'} variant={'h6'}>
                  {fieldType(editingFormField?.field_type)}
                </Typography>
              </div>
              <div className={classes.headRight}>
                <IconButton onClick={handleClose} aria-label={'cancel'}>
                  <img src={process.env.PUBLIC_URL + '/icons/CancelIcon.svg'} />
                </IconButton>
              </div>
            </div>
            <NavTabs value={nav} onChange={handleNavChange}>
              <NavTab label="Settings" />
              <NavTab label="Logic" />
              {/* <NavTab label="Calculations" /> */}
            </NavTabs>
          </div>
          <Divider light />
          {nav === NavEnums.Settings ? <FieldTypeSettings /> : null}
          {nav === NavEnums.Logic ? <FieldLogic /> : null}
          {/* {nav === NavEnums.Calculations ? <FieldCalculations /> : null} */}
        </Grid>
        <Grid className={classes.fieldView} item xs={9} onClick={handleClose}>
          <div className={classes.fieldContainer}>
            <FieldDisplayBlock />
          </div>
        </Grid>
      </Grid>
    </Modal>
  );
};
const NavTabs = withStyles({
  root: {
    marginBottom: '-1px'
  },
  indicator: {
    backgroundColor: theme.palette.primary.main
  }
})(Tabs);

interface NavTabProps {
  label: string;
}
const NavTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'textTransform': 'none',
      'minWidth': '4rem',
      'color': theme.palette.text.secondary,
      'fontSize': '.9rem',
      'marginRight': theme.spacing(2),
      '&:hover': {
        color: theme.palette.primary.light,
        opacity: 1
      },
      '&$selected': {
        color: theme.palette.primary.main
      },
      '&:focus': {
        color: theme.palette.primary.main
      }
    },
    selected: {}
  })
)((props: NavTabProps) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  settingsDrawer: {
    height: '100vh',
    backgroundColor: theme.palette.background.default,
    overflowY: 'scroll'
  },
  drawerHeaderContainer: {
    padding: theme.spacing(3),
    paddingBottom: 0
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  headLeft: {},
  headRight: {},
  fieldView: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4)
  },
  fieldContainer: {
    width: '100%'
  }
}));
