import React, { useEffect, useState } from 'react';
import { withStyles, Theme, makeStyles } from '@material-ui/core/styles';
import {
    Typography, Box, Tab, Divider, Paper, Tabs, Tooltip, Table, TableBody, TableCell, TableHead, TableRow,
    TableSortLabel,
} from '@material-ui/core';
import { UnfoldMore, ExpandMore, DragIndicator } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/redux-store';
import { Link } from 'react-router-dom';
import { HeadCell, LastSorted } from '../../../components/Table';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Checkbox } from '../../../components/Checkbox';

const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        padding: '0px 10px',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

interface CollectionFormTableProps {
    collectionForms: any | null;
    selectedCollectionForms: any | [];
    setSelectedCollectionForm: (selectedCollections: any) => void;
    setCollectionForms: (selectedCollections: any) => void;
    setIsAdd: (isAdd: boolean) => void;
    isAdd: boolean | null;
    handelSet: () => void;
}

const a11yProps = (index: any) => {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

export const CollectionFormTable = ({
    collectionForms,
    selectedCollectionForms,
    setSelectedCollectionForm,
    setCollectionForms,
    setIsAdd,
    isAdd,
    handelSet
}: CollectionFormTableProps) => {
    const classes = useStyles();
    const setData = collectionForms ? collectionForms : []
    const [items, setItems] = useState(setData);
    const [viewItems, setViewItems] = useState<any>([]);
    const [selectedForm, setSelectedForm] = useState<any>([]);
    const [allChecked, setAllChecked] = useState(false);
    const [id, setId] = useState('')
    const [update, setUpdate] = useState(false);
    const [lastSorted, setLastSorted] = useState<LastSorted>({
        column: 'created_at',
        order: 'desc'
    });

    useEffect(() => {
        if (collectionForms.length !== items.length) {
            setItems(setData);
        }
    })

    const domainsData: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.collectionSearchResults.libraryForms
    );
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const onDragEnd = (result: any) => {
        const newItems = Array.from(items);
        const [removed] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, removed);
        setItems(newItems);
        setCollectionForms(newItems);
    };

    const RowCells = ({ item, provided, snapshot, index }: any) => {
        const isChecked = selectedCollectionForms.filter((val: any) => val === item.id);
        const nameLink = (
            <Typography color="primary" variant="h5">
                {item.title}
            </Typography>
        );
        const tagsComponent =
            <HtmlTooltip
                title={<React.Fragment>
                    {item.tag_names && item.tag_names.map((tag: any, i: number) => {
                        return (
                            <Typography style={{ padding: '5px 0px', borderBottom: (i + 1) === (item.tag_names && item.tag_names.length) ? 'unset' : '1px solid #00000026' }}>{tag}</Typography>
                        )
                    })}
                </React.Fragment>}
            >
                <div>
                    <Typography color="primary" variant="h5" >
                        {item.tag_names && item.tag_names.length}
                    </Typography>
                    <Typography color="primary" variant="h5">
                        Tags
                    </Typography>
                </div>
            </HtmlTooltip>;
        const domainComponent = <HtmlTooltip
            title={<React.Fragment>
                {item.domain_names && item.domain_names.map((domain: any, i: number) => {
                    return (
                        <Typography style={{ padding: '5px 0px', borderBottom: (i + 1) === (item.domain_names && item.domain_names.length) ? 'unset' : '1px solid #00000026' }}>{domain}</Typography>
                    )
                })}
            </React.Fragment>}
        ><div>
                <Typography color="primary" variant="h5" >
                    {item.domain_names && item.domain_names.length}
                </Typography>
                <Typography color="primary" variant="h5">
                    Domains
                </Typography>
            </div>
        </HtmlTooltip>;
        return (
            <TableRow ref={provided.innerRef}
                snapshot={snapshot}
                {...provided.draggableProps}
                {...provided.dragHandleProps}>
                <TableCell padding="checkbox">
                    <Checkbox name={item.title} checked={isChecked && isChecked.length > 0 ? true : false}
                        onChange={(e: any) => handelChecked(e, item.id)}
                    />
                </TableCell>
                <TableCell align="left">{nameLink}</TableCell>
                <TableCell align="center">{domainComponent}</TableCell>
                <TableCell align="center">{tagsComponent}</TableCell>
                <TableCell align="right">
                    <Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <Typography variant="h5">{index}</Typography><DragIndicator style={{ color: 'gray', marginLeft: '20px' }} />
                    </Box>
                </TableCell>
            </TableRow>
        );
    };

    const handelSetVersion = (item: any) => {
        handelSet();
        sessionStorage.setItem('version', item.form_version_id);
    }

    const ViewRowCells = ({ item, index }: any) => {
        const isChecked = selectedCollectionForms.filter((val: any) => val === item.id);
        const nameLink = (
            <Link className={classes.titleLink} to={{ pathname: `/form-builder/${item.id}/fields`, state: false }}>
                <Typography onClick={() => handelSetVersion(item)} color="primary" variant="h5" style={{ maxWidth: '500px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    {item.title}
                </Typography>
            </Link>
        );
        const tagsComponent =
            <HtmlTooltip
                title={<React.Fragment>
                    {item.tag_names && item.tag_names.map((tag: any, i: number) => {
                        return (
                            <Typography style={{ padding: '5px 0px', borderBottom: (i + 1) === (item.tag_names && item.tag_names.length) ? 'unset' : '1px solid #00000026' }}>{tag}</Typography>
                        )
                    })}
                </React.Fragment>}
            >
                <div>
                    <Typography color="primary" variant="h5" >
                        {item.tag_names && item.tag_names.length}
                    </Typography>
                    <Typography color="primary" variant="h5">
                        Tags
                    </Typography>
                </div>
            </HtmlTooltip>;
        const domainComponent = <HtmlTooltip
            title={<React.Fragment>
                {item.domain_names && item.domain_names.map((domain: any, i: number) => {
                    return (
                        <Typography style={{ padding: '5px 0px', borderBottom: (i + 1) === (item.domain_names && item.domain_names.length) ? 'unset' : '1px solid #00000026' }}>{domain}</Typography>
                    )
                })}
            </React.Fragment>}
        ><div>
                <Typography color="primary" variant="h5" >
                    {item.domain_names && item.domain_names.length}
                </Typography>
                <Typography color="primary" variant="h5">
                    Domains
                </Typography>
            </div>
        </HtmlTooltip>;
        return (
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox name={item.title} checked={isChecked && isChecked.length > 0 ? true : false}
                        onChange={(e: any) => handelChecked(e, item.id)}
                    />
                </TableCell>
                <TableCell align="left">{nameLink}</TableCell>
                <TableCell align="center">{domainComponent}</TableCell>
                <TableCell align="center">{tagsComponent}</TableCell>
                <TableCell align="right">
                    <Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <Typography variant="h5">{index}</Typography><Tooltip title='Disabled'><DragIndicator style={{ color: '#c3c3c3', marginLeft: '20px' }} /></Tooltip>
                    </Box>
                </TableCell>
            </TableRow>
        );
    };

    const headCells: HeadCell[] = [
        {
            id: 'title',
            label: 'FORM NAME',
            isSortDisabled: true
        },
        {
            align: 'center',
            id: 'domains',
            label: 'DOMAINS',
            isSortDisabled: false
        },
        {
            align: 'center',
            id: 'tags',
            label: 'TAGS',
            isSortDisabled: false
        },
        {
            align: 'right',
            id: 'sequence',
            label: 'SEQUENCE',
            isSortDisabled: true
        }
    ];

    const handleSort = (sort: string) => {
        const nextDirection = lastSorted.order === 'desc' ? 'asc' : 'desc';

        // loadResources({
        //     catalogueId,
        //     staticResourceType,
        //     order: sort === lastSorted.column ? nextDirection : 'desc',
        //     page: 0,
        //     pageSize: collectionForms?.pageSize,
        //     search: collectionForms?.search,
        //     sort
        // });

        setLastSorted(
            sort === lastSorted.column
                ? { column: sort, order: nextDirection }
                : { column: sort, order: 'desc' }
        );
    };

    const handleSelectAllClick = (e: any) => {
        if (e.target.checked) {
            const array = [];
            for (let i = 0; i < collectionForms.length; i++) {
                array.push(collectionForms[i].id);
            }
            setSelectedCollectionForm(array);
            setSelectedForm(array);
            setAllChecked(true);
        } else {
            setAllChecked(false);
            setSelectedCollectionForm([]);
            setSelectedForm([]);
        }
    }

    const handelChecked = (e: any, id: string) => {
        const getValue = selectedForm;
        if (e.target.checked) {
            getValue.push(id);
            setSelectedCollectionForm(getValue);
            setIsAdd(!isAdd);
        } else {
            const isChecked = getValue.filter((val: any) => val !== id);
            setSelectedForm(isChecked);
            setSelectedCollectionForm(isChecked);
            setIsAdd(!isAdd);
        }
        setUpdate(!update);
    }

    const getFilterData = async (id: any) => {
        const array = [];
        for (let i = 0; i < items.length; i++) {
            const check = items[i].domain_names && items[i].domain_names.length > 0 && items[i].domain_names.includes(id)
            check && array.push(items[i]);
        }
        setViewItems(array);
        setId(id);
    }

    return (
        <>
            {domainsData && domainsData.collections.length > 1 && (
                <Box>
                    <Paper className={classes.paper}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            classes={{ scrollButtons: classes.scrollBtns }}
                        >
                            <Tab style={{ maxWidth: '100%' }} label={'All'} onClick={() => getFilterData('')} value={0} {...a11yProps(0)} />
                            {domainsData && domainsData?.collections.map((v: any, i: any) => (
                                <Tab key={`${v.title}-${i + 1}`} style={{ maxWidth: '100%' }} label={v.title} onClick={() => getFilterData(v.title)} value={i + 1} {...a11yProps(i + 1)} />
                            ))}
                        </Tabs>
                    </Paper>
                </Box>
            )}
            <Divider style={{ margin: '15px 0 0' }} variant="middle" />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell padding="checkbox">
                            <Checkbox
                                checked={selectedForm.length > 0 &&
                                    collectionForms.length === selectedForm.length ? true : false
                                }
                                onChange={handleSelectAllClick}
                            />
                        </TableCell>
                        {headCells.map((headCell) => (
                            <TableCell
                                key={headCell.id}
                                align={headCell.align}
                            >
                                {headCell.isSortDisabled ? <TableSortLabel
                                    active={lastSorted.column === headCell.id}
                                    direction={lastSorted.order}
                                    hideSortIcon={true}
                                    // onClick={() => sort && handleSort(v.id)}
                                    IconComponent={ExpandMore}
                                >
                                    <Typography color="textSecondary" variant="button">
                                        {headCell.label}
                                    </Typography>
                                    {lastSorted.column !== headCell.id && (
                                        <UnfoldMore fontSize="small" />
                                    )}
                                </TableSortLabel> :
                                    <Typography variant="button">{headCell.label}</Typography>}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                {id === '' ?
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided) => (
                                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                                    {items.map((form: any, index: number) => {
                                        return (
                                            <Draggable key={form.id} draggableId={form.id} index={index}>
                                                {(provided, snapshot) => (
                                                    <RowCells
                                                        provided={provided}
                                                        snapshot={snapshot}
                                                        item={form}
                                                        index={index + 1}
                                                    />
                                                )}
                                            </Draggable>
                                        )
                                    })}
                                </TableBody>
                            )}
                        </Droppable>
                    </DragDropContext>
                    :
                    <TableBody>
                        {viewItems.map((form: any, index: number) => {
                            return (
                                <ViewRowCells
                                    item={form}
                                    index={index + 1}
                                />
                            )
                        })}
                    </TableBody>
                }
            </Table>
            <Box mt={2}><Typography>{collectionForms ? collectionForms.length : 0} total Forms</Typography></Box>
        </>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    nameLink: {
        textDecoration: 'none'
    },
    infoIcon: {
        color: theme.palette.text.secondary,
        fontSize: '1rem',
        marginRight: '.2rem'
    },
    paper: {
        boxShadow: 'unset',
        border: 'unset',
        margin: '0 -20px -15px 0'
    },
    scrollBtns: {
        '&.Mui-disabled': {
            display: 'none'
        }
    },
    titleLink: {
        textDecoration: 'none'
    }
}));
