import React, { useState } from 'react';
import { Box, Theme, Typography, makeStyles, Divider } from '@material-ui/core';
import { Dialog } from './Dialog';
import { LoadingButton } from './LoadingButton';
import { colors } from '../styling/colors';

interface ConfirmationDialogProps {
    loading: boolean;
    data: Array<any>;
    customData: Array<any>;
    title: string;
    handleClose: () => void;
    handleConfirmClick: () => void;
}

export const ConfirmationDelete = ({
    loading,
    data,
    customData,
    title,
    handleClose,
    handleConfirmClick
}: ConfirmationDialogProps) => {
    const classes = useStyles();

    const onDelete = () => {
        handleConfirmClick()
    }

    return (
        <Dialog open={true} handleClose={handleClose}>
            <Box display="flex" flexDirection="column" style={{ textAlign: 'center' }} width={500}>
                <Box
                    alignItems="center"
                    bgcolor={colors.background2}
                    className={classes.topBox}
                    px={3}
                    py={2.5}
                >
                    <Typography variant="h3">Are you sure you want to delete this {title}</Typography>
                </Box>
                <Box px={3} py={2.5} style={{ maxHeight: '500px', overflowY: 'auto' }}>
                    {data && data.length > 0 && data.map((item: any, index: number) => {
                        // const filterInfo: any = customData && customData.filter((info: any) => info.id === item.form_id);
                        return (
                            <Box>
                                <Typography style={{ textAlign: 'left', marginBottom: '5px' }} variant="h4">{(index + 1) + ': ' + (item?.title)}</Typography>
                                <Typography style={{ textAlign: 'left', marginLeft: '16px' }} variant="h5">This {title} is currently assigned to {item.assigned_to_count + ' ' + (item.assigned_to_count === '1' ? 'user' : 'users')}.</Typography>
                                <Typography style={{ textAlign: 'left', marginLeft: '16px' }} variant="h5">This {title} is currently in progress by {item.in_progress_count + ' ' + (item.in_progress_count === '1' ? 'user' : 'users')}.</Typography>
                                <Typography style={{ textAlign: 'left', marginLeft: '16px' }} variant="h5">This {title} has been completed by {item.completed_count + ' ' + (item.completed_count === '1' ? 'user' : 'users')}.</Typography>
                                {(index + 1) !== data.length && <Divider style={{ margin: '10px 2px' }} />}
                            </Box>
                        )
                    })}
                </Box>
                <Box
                    bgcolor={colors.background2}
                    className={classes.bottomBox}
                    display="flex"
                    justifyContent="space-between"
                    px={3}
                    py={2}
                >
                    <LoadingButton
                        className={classes.secondaryButton}
                        color="secondary"
                        onClick={handleClose}
                        variant="contained"
                        aria-label="cancel"
                    >
                        {'Cancel'}
                    </LoadingButton>

                    <LoadingButton
                        className={classes.primaryButton}
                        color="secondary"
                        disabled={loading}
                        loading={loading}
                        onClick={onDelete}
                        variant="contained"
                        aria-label="confirm"
                    >
                        {'Delete'}
                    </LoadingButton>
                </Box>
            </Box>
        </Dialog>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    main: {
        minHeight: '100vh'
    },
    dialogPaper: {
        borderRadius: '0.625rem'
    },
    topBox: {
        borderRadius: '0.625rem 0.625rem 0 0'
    },
    searchTextField: {
        backgroundColor: colors.background3,
        fontSize: '13px',
        height: '2rem',
        width: '8rem'
    },
    searchIcon: {
        fontSize: 17
    },
    bottomBox: {
        borderRadius: '0 0 0.625rem 0.625rem'
    },
    primaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: 'red',
        minWidth: '100px'
    },
    secondaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#0060B2',
        minWidth: '100px'
    }
}));
