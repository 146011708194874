import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';
import { Avatar, Link as MuiLink, withStyles, Theme, Typography, makeStyles, Tooltip } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { Form, UuidType } from '../../../../shared/domain';
import { FormsType } from '../../../../redux/data/data-actions';
import { GetFormsProps, getFormVersionsThunk } from '../../../../redux/thunk/thunks';
import {
  HeadCell,
  LastSorted,
  MenuItem,
  Pagination,
  RowCell,
  Table
} from '../../../../components/Table';
import { colors } from '../../../../styling/colors';

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    width: 'auto',
    padding: '0px 10px',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

interface FormsTableProps {
  collectionId?: UuidType;
  library?: boolean;
  formsData: any | null;
  formsType: FormsType;
  selectedForms?: UuidType[];
  loadForms: (getFormsProps: GetFormsProps) => void;
  setSelectedForms?: (selectedForms: UuidType[]) => void;
  unassignCollection?: (collectionId: UuidType, formId: UuidType) => void;
}

export const FormTable = ({
  collectionId = '',
  library = false,
  formsData,
  formsType,
  selectedForms,
  loadForms,
  setSelectedForms,
  unassignCollection
}: FormsTableProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  let history = useHistory();
  const location: any = window.location;
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);
  const [lastSorted, setLastSorted] = useState<LastSorted>({
    column: 'title',
    order: 'desc'
  });

  const headCells: HeadCell[] = [
    {
      id: 'title',
      label: 'NAME'
    },
    {
      id: 'domains',
      label: 'DOMAINS',
      align: 'center',
      isSortDisabled: true
    },
    {
      id: 'tags',
      label: 'TAGS',
      align: 'center',
      isSortDisabled: true
    },
    {
      id: 'type',
      label: 'TYPE'
    },
    {
      align: 'center',
      id: 'item_type',
      label: 'ITEM TYPE'
    }
  ];

  const setSession = async (value: any, formId: any, form: any) => {
    sessionStorage.removeItem('edit')
    sessionStorage.setItem('nested_route', location.pathname);
    if (form.item_type === 'FORM') {
      sessionStorage.removeItem('version');
      sessionStorage.removeItem('new');
      await dispatch(getFormVersionsThunk(formId));
      sessionStorage.setItem('type_id', value);
      history.push({ pathname: `/form-builder/${formId}/fields`, state: false });
    } else {
      history.push({ pathname: `${location.pathname?.slice(0, location.pathname.lastIndexOf('/'))}/patient-view-collection` });
      sessionStorage.setItem('collection', JSON.stringify(form));
    }
  }
  const rowCells = formsData?.data.map((form: any) => {
    const TitleLink = (
      // <Link className={classes.titleLink} to={{ pathname: `/form-builder/${form.id}/fields`, state: false }}>
      <Typography onClick={() => setSession(form.type, form.id, form)} color="primary" variant="h5" style={{ maxWidth: '500px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', cursor: 'pointer' }}>
        {form.title}
      </Typography>
      // </Link>
    );
    const tagsComponent =
      <HtmlTooltip
        title={<React.Fragment>
          {form.tags.map((tag: any, i: number) => {
            return (
              <Typography style={{ padding: '5px 0px', borderBottom: (i + 1) === form.tags.length ? 'unset' : '1px solid #00000026' }}>{tag.title}</Typography>
            )
          })}
        </React.Fragment>}
      >
        <div>
          <Typography color="primary" variant="h5" >
            {form.tags && form.tags.length}
          </Typography>
          <Typography color="primary" variant="h5">
            Tags
          </Typography>
        </div>
      </HtmlTooltip>;
    const domainComponent = <HtmlTooltip
      title={<React.Fragment>
        {form.domains.map((domain: any, i: number) => {
          return (
            <Typography style={{ padding: '5px 0px', borderBottom: (i + 1) === form.domains.length ? 'unset' : '1px solid #00000026' }}>{domain.title}</Typography>
          )
        })}
      </React.Fragment>}
    ><div>
        <Typography color="primary" variant="h5" >
          {form.domains && form.domains.length}
        </Typography>
        <Typography color="primary" variant="h5">
          Domains
        </Typography>
      </div>
    </HtmlTooltip>;
    const type = form.type === 'CUSTOM' ?
      <HtmlTooltip
        title={<React.Fragment>
          <Typography style={{ padding: '5px 0px', color: 'black' }}>Custom</Typography>
        </React.Fragment>}
      ><Avatar style={{ width: 32, height: 32 }}>C</Avatar></HtmlTooltip> :
      <HtmlTooltip
        title={<React.Fragment>
          <Typography style={{ padding: '5px 0px', color: 'black' }}>Base (Provided as part of FormFlash)</Typography>
        </React.Fragment>}
      ><Avatar style={{ width: 32, height: 32, background: '#0060b2' }}>B</Avatar></HtmlTooltip>;
    const rowCell: RowCell = {
      title: { component: TitleLink, value: form.title },
      domains: {
        align: 'center',
        component: domainComponent,
        value: form.tag_count?.toString()
      },
      tags: {
        align: 'center',
        component: tagsComponent,
        value: form.tag_count?.toString()
      },
      type: { component: type, value: '-' },
      item_type: {
        align: 'center',
        value: form.item_type
      }
    }
    return Object.assign(rowCell, { content: form });
  });

  const handleAddToCollection = (menuContent: Form | null) => {
    //todo: implement add collection dialog
    console.log(menuContent);
  };
  const handleDeleteForm = (menuContent: Form | null) => {
    //todo: implement form delete dialog
    console.log(menuContent);
  };

  const menuItems: MenuItem<Form>[] = [
    {
      label: 'Add to Collection',
      handleMenuClick: handleAddToCollection
    },
    {
      label: 'Delete Form',
      handleMenuClick: handleDeleteForm
    }
  ];

  const handleNextButton = async () => {
    if (
      formsData &&
      formsData.page >= 0 &&
      formsData.page < formsData.totalCount / formsData.pageSize - 1
    ) {
      await loadForms({
        collectionId: formsData.collectionId,
        formsType,
        library,
        search: formsData.search,
        page: formsData.page + 1,
        pageSize: formsData.pageSize,
        sort: formsData.sort,
        order: formsData.order,
        favourites: formsData.favourites
      });
    }
  };
  const handlePrevButton = async () => {
    if (formsData && formsData.page > 0) {
      await loadForms({
        collectionId: formsData.collectionId,
        formsType,
        library,
        search: formsData.search,
        page: formsData.page - 1,
        pageSize: formsData.pageSize,
        sort: formsData.sort,
        order: formsData.order,
        favourites: formsData.favourites
      });
    }
  };

  const handlePageSize = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    const pageSize: number = e.target.value as number;

    if (formsData && pageSize > 0) {
      loadForms({
        collectionId: formsData.collectionId,
        formsType,
        library,
        page: 0,
        pageSize: pageSize,
        search: formsData.search,
        sort: formsData.sort,
        order: formsData.order,
        favourites: formsData.favourites
      });
    }
  };

  const pagination: Pagination | undefined = formsData
    ? {
      contentName: 'Forms',
      page: formsData.page,
      pageSize: formsData.pageSize,
      total: formsData.totalCount,
      handleNextButton: handleNextButton,
      handlePageSize: handlePageSize,
      handlePrevButton: handlePrevButton
    }
    : undefined;

  const handleSort = (sort: string) => {
    const nextDirection = lastSorted.order === 'desc' ? 'asc' : 'desc';

    loadForms({
      collectionId: formsData?.collectionId,
      formsType,
      library,
      order: sort === lastSorted.column ? nextDirection : 'desc',
      page: 0,
      pageSize: formsData?.pageSize,
      search: formsData?.search,
      favourites: formsData.favourites,
      sort
    });

    setLastSorted(
      sort === lastSorted.column
        ? { column: sort, order: nextDirection }
        : { column: sort, order: 'desc' }
    );
  };

  return (
    <Table
      data={formsData?.data}
      headCells={headCells}
      // loading={formsData?.collectionId !== collectionId}
      pagination={pagination}
      rowCells={rowCells}
      selection={
        selectedForms && setSelectedForms
          ? {
            selectedContents: selectedForms,
            setSelectedContents: (selected: UuidType[]) => setSelectedForms(selected)
          }
          : undefined
      }
      sort={{
        lastSorted: lastSorted,
        handleSort: handleSort
      }}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  collectionBubble: {
    borderRadius: '0.75rem'
  },
  clearIcon: {
    color: colors.text6,
    fontSize: 14,
    marginLeft: '0.25rem'
  },
  titleLink: {
    textDecoration: 'none'
  }
}));
