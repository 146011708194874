import { Box, Grid, Paper, ListItem, ListItemAvatar, ListItemText, Avatar, CircularProgress, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import WorkIcon from '@material-ui/icons/Work';
import { Logo } from '../../components/Logo';
import img from '../../styling/umassmedical.png';
import img1 from '../../styling/umassmemorial.png';
import { RootState } from '../../redux/redux-store';
import { showSSOLoginPageThunk } from '../../redux/thunk/thunks';
import { colors } from '../../styling/colors';
import { url } from '../../redux/url';

interface FormValues {
    email: string;
    password: string;
    remember: boolean;
}

export const ChooseOrganization = () => {
    const getInfo: any = localStorage.getItem('remember');
    const getLoginInfo = JSON.parse(getInfo);
    const user: any = localStorage.getItem('formFlash');
    const token: any = localStorage.getItem('token');
    const loggedInUser = JSON.parse(user);
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [check, setCheck] = useState(false);
    const location = window.location;

    useEffect(() => {
        const newDate: any = new Date();
        if (getLoginInfo && loggedInUser && token) {
            const date2: any = new Date(getLoginInfo);
            const diffTime = Math.abs(date2 - newDate);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            if (diffDays > 7) {
                setCheck(true);
            } else {
                history.push('/forms');
            }
        } else {
            setCheck(true);
        }
    }, [])

    const loginError = useSelector(
        (state: RootState) => state.DISPLAY_STATE_REDUCER.displayErrors.loginError
    );

    const handleSubmit = async (value: any) => {
        window.location.replace(`${url}/sso/showSSOLoginPage/${value}`);
        await dispatch(showSSOLoginPageThunk(value));
    };

    const initialValues: FormValues = {
        email: '',
        password: '',
        remember: false
    };

    const loginSchema = Yup.object().shape({
        email: Yup.string().email('Email is invalid').required('Required'),
    });

    return (
        <Grid alignItems="center" className={classes.main} container justify="center" spacing={0}>
            {check ?
                <Grid>
                    <Paper className={classes.loginPaper}>
                        <Box p={4.5} display='flex' alignItems='center' flexDirection='column'>
                            <Box mb={4}>
                                <Logo />
                            </Box>
                            <Box mb={2}>
                                <Typography style={{ fontSize: '20px', fontWeight: 'bold' }}>Choose your organization</Typography>
                            </Box>
                            <Box mb={4}>
                                <ListItem style={{ cursor: 'pointer' }} onClick={(e) => handleSubmit('umassmed')}>
                                    <ListItemAvatar>
                                        <Avatar style={{ borderRadius: 'unset', background: '#fff' }}>
                                            <img src={img} alt='icon' style={{ height: '-webkit-fill-available' }} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="UMass Medical" />
                                </ListItem>
                                <ListItem style={{ width: '400px', cursor: 'pointer' }} onClick={(e) => handleSubmit('umassmemorial')}>
                                    <ListItemAvatar>
                                        <Avatar style={{ borderRadius: 'unset', background: '#fff' }}>
                                            <img src={img1} alt='icon' style={{ height: '60px' }} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="UMass Memorial" />
                                </ListItem>
                            </Box>
                        </Box>
                    </Paper>
                </Grid> :
                <Grid><CircularProgress /></Grid>
            }
        </Grid>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    main: {
        backgroundColor: colors.background1,
        minHeight: '100vh'
    },
    loginPaper: {
        margin: '0 2rem',
        maxWidth: '30rem',
        width: '80vw',
    },
    textField: {
        width: '100%'
    },
    link: {
        textDecoration: 'none'
    },
    rememberLabel: {
        color: colors.text4
    },
    submitButton: {
        width: '100%',
        '&:hover': {
            background: '#0060B2',
            color: '#fff',
        }
    },
    ssoButton: {
        width: '100%',
        background: '#374151',
        color: '#fff',
        padding: '0px 10px',
        '&:hover': {
            background: '#374151',
            color: '#fff',
        }
    },
    buttonDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        width: 'inherit'
    },
    buttonDiv1: {
        display: 'flex',
        borderRight: '1px solid #fff',
        paddingRight: '10px',
    }
}));
