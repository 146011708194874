import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React, { useState } from 'react';
import {
  Box,
  Menu,
  MenuItem as MuiMenuItem,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
  Typography,
  makeStyles
} from '@material-ui/core';

import { Checkbox } from './Checkbox';
import { DbModel, UuidType } from '../shared/domain';
import { LoadingOrError } from './LoadingOrError';
import { PaginationBar } from './PaginationBar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import { colors } from '../styling/colors';

export interface HeadCell {
  align?: 'left' | 'center' | 'right';
  id: string;
  label: string;
  width?: string;
  isSortDisabled?: boolean;
}

export interface MenuItem<T> {
  label: string;
  handleMenuClick: (menuContent: T | null) => void;
}

export interface Pagination {
  contentName: string;
  page: number;
  pageSize: number;
  total: number;
  handlePageSize?: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
  handlePrevButton: () => Promise<void>;
  handleNextButton: () => Promise<void>;
}

export interface RowCell {
  [x: string]: {
    align?: 'left' | 'center' | 'right';
    component?: React.ReactNode;
    value?: string;
    width?: string;
  };
}

type RowCellContent<T> = RowCell & { content: T };

export interface Selection {
  selectedContents: UuidType[];
  setSelectedContents: (selectedForms: UuidType[]) => void;
}

export interface LastSorted {
  column: string;
  order: 'asc' | 'desc';
}

export interface Sort {
  lastSorted: LastSorted;
  handleSort: (sort: string) => void;
}

interface TableProps<T> {
  data?: T[];
  headCells?: HeadCell[];
  hideHead?: boolean;
  loading?: boolean;
  menuItems?: MenuItem<T>[];
  pagination?: Pagination | null;
  rowCells?: RowCellContent<T>[];
  selection?: Selection;
  sort?: Sort;
}

export function Table<T extends DbModel>({
  data,
  headCells,
  hideHead,
  loading,
  menuItems,
  pagination,
  rowCells,
  selection,
  sort
}: TableProps<T>) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [menuContent, setMenuContent] = useState<T | null>(null);

  // todo: refactor function using es6
  const handleSelectClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    boolean: boolean,
    id: UuidType
  ) => {
    if (selection) {
      const selectedIndex = selection.selectedContents.indexOf(id);

      let newSelected: string[] = [];

      if (selectedIndex === -1) {
        selection.setSelectedContents(newSelected.concat(selection.selectedContents, id));
      } else if (selectedIndex === 0) {
        selection.setSelectedContents(newSelected.concat(selection.selectedContents.slice(1)));
      } else if (selectedIndex === selection.selectedContents.length - 1) {
        selection.setSelectedContents(newSelected.concat(selection.selectedContents.slice(0, -1)));
      } else if (selectedIndex > 0) {
        selection.setSelectedContents(
          newSelected.concat(
            selection.selectedContents.slice(0, selectedIndex),
            selection.selectedContents.slice(selectedIndex + 1)
          )
        );
      }
    }
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selection) {
      if (event.target.checked && rowCells) {
        selection.setSelectedContents(rowCells.map((v) => v.content['id']));
      } else {
        selection.setSelectedContents([]);
      }
    }
  };

  const handleMenuClick = (e: React.MouseEvent<HTMLElement>, content: T) => {
    setMenuContent(content);
    setAnchorEl(e.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return data && !loading ? (
    <Box width="100%">
      <Box>
        <TableContainer className={classes.tableContainer}>
          <MuiTable stickyHeader>
            {!hideHead && (
              <TableHead>
                <TableRow>
                  {selection && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          rowCells &&
                          rowCells.length > 0 &&
                          selection.selectedContents.length === rowCells.length
                        }
                        onChange={handleSelectAllClick}
                      />
                    </TableCell>
                  )}

                  {headCells?.map((v: HeadCell) => (
                    <TableCell align={v.align} key={v.id} width={v.width}>
                      {sort && !v.isSortDisabled ? (
                        <TableSortLabel
                          active={sort.lastSorted.column === v.id}
                          direction={sort.lastSorted.order}
                          hideSortIcon={true}
                          onClick={() => sort && sort.handleSort(v.id)}
                          IconComponent={ExpandMoreIcon}
                        >
                          <Typography color="textSecondary" variant="button">
                            {v.label}
                          </Typography>
                          {sort.lastSorted.column !== v.id && (
                            <UnfoldMoreIcon htmlColor={colors.text6} fontSize="small" />
                          )}
                        </TableSortLabel>
                      ) : (
                        <Typography variant="button">{v.label}</Typography>
                      )}
                    </TableCell>
                  ))}

                  {menuItems && <TableCell align="right" padding="checkbox" />}
                </TableRow>
              </TableHead>
            )}

            <TableBody>
              {rowCells &&
                headCells &&
                rowCells.map((row: RowCellContent<T>, i) => (
                  <TableRow key={`${row[headCells[0].id]}-${i}`}>
                    {selection && row.content && (
                      <TableCell
                        className={i === rowCells.length - 1 ? classes.rowCell : ''}
                        padding="checkbox"
                      >
                        <Checkbox
                          checked={selection.selectedContents.indexOf(row.content.id) !== -1}
                          onChange={(e, checked) => handleSelectClick(e, checked, row.content.id)}
                        />
                      </TableCell>
                    )}

                    {headCells?.map((head: HeadCell, j) => (
                      <TableCell
                        align={row[head.id]?.align ?? 'left'}
                        className={i === rowCells.length - 1 ? classes.rowCell : ''}
                        key={`${head.id}-${j}`}
                        width={row[head.id]?.width}
                      >
                        {row[head.id] &&
                          (row[head.id].component
                            ? row[head.id].component
                            : row[head.id].value && (
                              <Typography color="textSecondary" variant="h5">
                                {row[head.id].value}
                              </Typography>
                            ))}
                      </TableCell>
                    ))}

                    {menuItems && (
                      <TableCell
                        align="right"
                        className={i === rowCells.length - 1 ? classes.rowCell : ''}
                        padding="checkbox"
                      >
                        <Box onClick={(e) => handleMenuClick(e, row.content)}>
                          <MoreHorizIcon />
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </MuiTable>
        </TableContainer>
      </Box>

      {pagination && <PaginationBar {...pagination} />}

      {menuItems && (
        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
          {menuItems.map((menuItem: MenuItem<T>, i) => (
            <MuiMenuItem
              key={`${menuItem.label}-${i}`}
              onClick={() => {
                if (menuContent) {
                  menuItem.handleMenuClick(menuContent);
                }

                setAnchorEl(null);
              }}
            >
              {menuItem.label}
            </MuiMenuItem>
          ))}
        </Menu>
      )}
    </Box>
  ) : (
    <LoadingOrError errorMsg={null} loading={true} loadingMsg="" noElevation />
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  rowCell: {
    borderBottom: '0'
  },
  tableContainer: {
    maxHeight: '45rem'
  }
}));
