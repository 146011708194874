import React, { useState } from 'react';
import { withStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Typography, Box, Tab, Divider, Paper, Tabs, Tooltip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/redux-store';
import { UuidType } from '../../../shared/domain';
import { HeadCell, LastSorted, RowCell, Table } from '../../../components/Table';

const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        padding: '0px 10px',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

interface CollectionFormTableProps {
    collectionForms: any | null;
    selectedFormFiles?: UuidType[];
    setSelectedFormFiles?: (selectedForm: UuidType[]) => void;
    isView: boolean;
    loadMore: (getCollectionFormsProps: any) => void;
    handelSet: () => void;
}

const a11yProps = (index: any) => {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

export const CollectionFormTable = ({
    collectionForms,
    selectedFormFiles,
    setSelectedFormFiles,
    isView,
    loadMore,
    handelSet
}: CollectionFormTableProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [id, setId] = useState('')
    const [lastSorted, setLastSorted] = useState<LastSorted>({
        column: 'title',
        order: 'desc'
    });

    const domainsData: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.collectionSearchResults.libraryForms
    );
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const getFilterData = (id: any) => {
        loadMore({ domainId: id, sort: lastSorted.column, order: lastSorted.order });
        setId(id);
    }

    const headCells: HeadCell[] = [
        {
            id: 'title',
            label: 'FORM NAME'
        },
        {
            align: 'center',
            id: 'domains',
            label: 'DOMAINS',
            isSortDisabled: true
        },
        {
            align: 'center',
            id: 'tags',
            label: 'TAGS',
            isSortDisabled: true
        },
        {
            align: 'right',
            id: 'sequence',
            label: 'SEQUENCE'
        }
    ];

    const handelSetVersion = (item: any) => {
        handelSet();
        sessionStorage.setItem('version', item.form_version_id);
    }

    const rowCells = collectionForms && collectionForms.map((item: any, index: any) => {

        const nameLink = (
            <Link className={classes.titleLink} to={{ pathname: `/form-builder/${item.id}/fields`, state: false }}>
                <Typography onClick={() => handelSetVersion(item)} color="primary" variant="h5" style={{ maxWidth: '500px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    {item.title}
                </Typography>
            </Link>
        );
        const tagsComponent =
            <HtmlTooltip
                title={<React.Fragment>
                    {item.tag_names && item.tag_names.map((tag: any, i: number) => {
                        return (
                            <Typography style={{ padding: '5px 0px', borderBottom: (i + 1) === (item.tag_names && item.tag_names.length) ? 'unset' : '1px solid #00000026' }}>{tag}</Typography>
                        )
                    })}
                </React.Fragment>}
            >
                <div>
                    <Typography color="primary" variant="h5" >
                        {item.tag_names && item.tag_names.length}
                    </Typography>
                    <Typography color="primary" variant="h5">
                        Tags
                    </Typography>
                </div>
            </HtmlTooltip>;
        const domainComponent = <HtmlTooltip
            title={<React.Fragment>
                {item.domain_names && item.domain_names.map((domain: any, i: number) => {
                    return (
                        <Typography style={{ padding: '5px 0px', borderBottom: (i + 1) === (item.domain_names && item.domain_names.length) ? 'unset' : '1px solid #00000026' }}>{domain}</Typography>
                    )
                })}
            </React.Fragment>}
        ><div>
                <Typography color="primary" variant="h5" >
                    {item.domain_names && item.domain_names.length}
                </Typography>
                <Typography color="primary" variant="h5">
                    Domains
                </Typography>
            </div>
        </HtmlTooltip>;
        var options: any = { weekday: 'long' };
        const rowCell: RowCell = {
            title: { component: nameLink, value: item.title },
            domains: {
                align: 'center',
                component: domainComponent,
                value: item.domain_names?.toString()
            },
            tags: {
                align: 'center',
                component: tagsComponent,
                value: item.tag_names?.toString()
            },
            sequence: {
                align: 'right',
                value: item.sequence
            }
        };

        return Object.assign(rowCell, { content: item });
    });

    const handleSort = (sort: string) => {
        const nextDirection = lastSorted.order === 'desc' ? 'asc' : 'desc';

        loadMore({ domainId: id, sort: sort, order: sort === lastSorted.column ? nextDirection : 'desc' });

        setLastSorted(
            sort === lastSorted.column
                ? { column: sort, order: nextDirection }
                : { column: sort, order: 'desc' }
        );
    };

    return (
        <>
            {domainsData && domainsData.collections.length > 1 && (
                <Box>
                    <Paper className={classes.paper}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            classes={{ scrollButtons: classes.scrollBtns }}
                        >
                            <Tab style={{ maxWidth: '100%' }} label={'All'} value={0} onClick={() => getFilterData('')} {...a11yProps(0)} />
                            {domainsData && domainsData?.collections.map((v: any, i: any) => (
                                <Tab key={`${v.title}-${i + 1}`} style={{ maxWidth: '100%' }} onClick={() => getFilterData(v.id)} label={v.title} value={i + 1} {...a11yProps(i + 1)} />
                            ))}
                        </Tabs>
                    </Paper>
                </Box>
            )}
            <Divider style={{ margin: '15px 0 0' }} variant="middle" />
            <Table
                data={collectionForms || []}
                headCells={headCells}
                loading={isView ? false : (collectionForms ? false : false)}
                // pagination={pagination}
                rowCells={rowCells}
                selection={
                    selectedFormFiles && setSelectedFormFiles
                        ? {
                            selectedContents: selectedFormFiles,
                            setSelectedContents: (selected: UuidType[]) => setSelectedFormFiles(selected)
                        }
                        : undefined
                }
                sort={{
                    lastSorted: lastSorted,
                    handleSort: handleSort
                }}
            />
            <Divider style={{ margin: '0 0 15px' }} variant="middle" />
            <Box><Typography>{collectionForms ? collectionForms.length : 0} total Forms</Typography></Box>
        </>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    nameLink: {
        textDecoration: 'none'
    },
    infoIcon: {
        color: theme.palette.text.secondary,
        fontSize: '1rem',
        marginRight: '.2rem'
    },
    paper: {
        boxShadow: 'unset',
        border: 'unset',
        margin: '0 -20px -15px 0'
    },
    scrollBtns: {
        '&.Mui-disabled': {
            display: 'none'
        }
    },
    titleLink: {
        textDecoration: 'none'
    }
}));
