import React, { useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { UuidType } from '../../../shared/domain';
import { HeadCell, LastSorted, RowCell, Table } from '../../../components/Table';
import { useDispatch } from 'react-redux';
import { ROLES } from '../../../shared/globals';
import { url } from '../../../redux/url';
import { Downloads } from '../../../components/IFrame';

var axios = require("axios");

interface ResourcesFilesTableProps {
    resourceFiles: any | null;
    selectedResourceFiles?: UuidType[];
    setSelectedResourceFiles?: (selectedResources: UuidType[]) => void;
    isView: boolean;
    handleNextButton: () => void;
    handlePrevButton: () => void;
}

export const ResourcesFilesTable = ({
    resourceFiles,
    selectedResourceFiles,
    setSelectedResourceFiles,
    isView,
    handleNextButton,
    handlePrevButton
}: ResourcesFilesTableProps) => {
    const dispatch = useDispatch();
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [lastSorted, setLastSorted] = useState<LastSorted>({
        column: 'created_at',
        order: 'desc'
    });

    const headCells: HeadCell[] = [
        {
            id: 'file_name',
            label: 'FILE'
        },
        {
            align: 'right',
            id: 'url',
            label: '',
            isSortDisabled: true
        },
        {
            align: 'center',
            id: 'download',
            label: '',
            isSortDisabled: true
        },
        {
            align: 'center',
            id: 'created_at',
            label: 'UPLOADED ON'
        }
    ];

    const headCells1: HeadCell[] = [
        {
            id: 'file_name',
            label: 'FILE'
        },
        {
            align: 'right',
            id: 'url',
            label: '',
            isSortDisabled: true
        },
        {
            align: 'right',
            id: 'download',
            label: '',
            isSortDisabled: true
        },
    ];

    const _getHeaders = (inputHeaders?: { [key: string]: string }) => {
        let headers = inputHeaders || {};
        let token = localStorage.getItem('token');
        headers["Accept"] = "application/json";
        const headersWithAuth = token
            ? {
                ...headers,
                Authorization: `Bearer ${token}`,
            }
            : headers;
        return headersWithAuth;
    };

    const viewURL1 = (getUrl: any) => {
        const data1 = {
            input_files: [getUrl]
        }
        const config1 = {
            method: 'post',
            url: `${url}/user/auth/getSignedDownloadUrls`,
            headers: _getHeaders({ "Content-Type": "application/json" }),
            data: data1,
        };

        axios(config1)
            .then(function (res: any) {
                window.open(res.data.url && res.data.url.length > 0 && res.data.url[0], '_blank');
                const url: string = res.data.url && res.data.url.length > 0 && res.data.url[0]
                const load = <a href={url} download />
            })
    }

    const viewURL = async (getUrl: any, name: any) => {
        const data1 = {
            input_files: [getUrl]
        }
        const config1 = {
            method: 'post',
            url: `${url}/user/auth/getSignedDownloadUrls`,
            headers: _getHeaders({ "Content-Type": "application/json" }),
            data: data1,
        };

        await axios(config1)
            .then(function (res: any) {
                if (res.data.url && res.data.url.length > 0) {
                    window.open(res.data.url && res.data.url.length > 0 && res.data.url[0], '_blank');
                    // const info: any = [{ name: name, url: res.data.url[0] }]
                    // setData(info);
                    // setOpen(true);
                }
            })
    }

    const rowCells = resourceFiles?.data && resourceFiles?.data.map((item: any, index: any) => {

        const nameLink = (
            <Typography color="primary" variant="h5">
                {item.file_name}
            </Typography>
        );
        const tagsComponent = (<Typography color="primary" variant="h5" style={{ cursor: 'pointer' }} onClick={() => viewURL1(item.file_url)}>
            Open in New Tab
        </Typography>);
        const domainComponent = (<Typography color="primary" variant="h5" style={{ cursor: 'pointer' }} onClick={() => viewURL(item.file_url, item.file_name)}>
            Download
        </Typography>);
        var options: any = { weekday: 'long' };
        const rowCell: RowCell = [ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role) ?
            {
                file_name: { component: nameLink, value: item.file_name },
                url: {
                    align: 'right',
                    component: domainComponent,
                    value: 'Open in New Tab'
                },
                download: {
                    align: 'right',
                    component: tagsComponent,
                    value: 'Download'
                }
            } :
            {
                file_name: { component: nameLink, value: item.file_name },
                url: {
                    align: 'right',
                    component: domainComponent,
                    value: 'Open in New Tab'
                },
                download: {
                    align: 'center',
                    component: tagsComponent,
                    value: 'Download'
                },
                created_at: {
                    align: 'center',
                    value: item.created_at ? new Intl.DateTimeFormat('en-US', options).format(new Date(item.created_at)) + ', ' + (("0" + (new Date(item.created_at).getMonth() + 1)).slice(-2)) + '/' + ("0" + new Date(item.created_at).getDate()).slice(-2) + '/' + new Date(item.created_at).getFullYear() : ''
                }
            };

        return Object.assign(rowCell, { content: item });
    });

    const handlePageSize = (
        e: React.ChangeEvent<{
            name?: string;
            value: unknown;
        }>
    ) => {
        const pageSize = e.target.value as number;

        if (resourceFiles && pageSize > 0) {
            // loadResources({
            //     catalogueId,
            //     staticResourceType,
            //     page: 0,
            //     pageSize: pageSize,
            //     search: resourceFiles.search,
            //     sort: resourceFiles.sort,
            //     order: resourceFiles.order
            // });
        }
    };

    const pagination: any | undefined = resourceFiles
        ? {
            contentName: 'ResourcesFiles',
            page: resourceFiles.page,
            pageSize: resourceFiles.pageSize,
            total: resourceFiles.totalCount,
            handleNextButton: handleNextButton,
            handlePageSize: handlePageSize,
            handlePrevButton: handlePrevButton
        }
        : undefined;

    const handleSort = (sort: string) => {
        const nextDirection = lastSorted.order === 'desc' ? 'asc' : 'desc';

        setLastSorted(
            sort === lastSorted.column
                ? { column: sort, order: nextDirection }
                : { column: sort, order: 'desc' }
        );
    };

    return (
        <>
            <Table
                data={isView ? [] : resourceFiles?.data}
                headCells={[ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role) ? headCells1 : headCells}
                loading={isView ? false : !resourceFiles?.data}
                pagination={pagination}
                rowCells={rowCells}
                selection={
                    selectedResourceFiles && setSelectedResourceFiles
                        ? {
                            selectedContents: selectedResourceFiles,
                            setSelectedContents: (selected: UuidType[]) => setSelectedResourceFiles(selected)
                        }
                        : undefined
                }
                sort={{
                    lastSorted: lastSorted,
                    handleSort: handleSort
                }}
            />
            {open && <Downloads urlData={data} handleClose={setOpen} />}
        </>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    nameLink: {
        textDecoration: 'none'
    },
    infoIcon: {
        color: theme.palette.text.secondary,
        fontSize: '1rem',
        marginRight: '.2rem'
    }
}));
