import React, { useEffect, useState } from 'react';
import { Box, makeStyles, Paper, Theme, Typography, ListItem, Divider, Card, TextField, ListItemText, Tabs, Tab, FormControlLabel, Checkbox, DialogActions } from '@material-ui/core';
import MUIDialog from '@material-ui/core/Dialog'
import { Search, Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientsThunk, getSiteAdminPatientsThunk, getSharingUserThunk, checkUserResourceAssignmentThunk, assignResourceThunk, assignReferralThunk, checkUserReferralAssignmentThunk } from '../../../redux/thunk/thunks';
import { RootState } from '../../../redux/redux-store';
import { LoadingButton } from '../../../components/LoadingButton';
import { ROLES } from '../../../shared/globals';
import { Patients } from './Patients';

export const AssignForms = ({ openDialog, handelClose }: any) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [patientDialogueOpen, setPatientDialogueOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const location = window.location.pathname;

    const info: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.assignmentList
    );

    const assignFormToPatientClose = () => {
        setPatientDialogueOpen(false);
    }

    return (
        <>
            {openDialog && <MUIDialog open={openDialog} style={{ overflow: 'hidden' }}>
                <Paper className={classes.paper}>
                    <Box display="flex" flexDirection="column">
                        <Card>
                            <Box p={2} style={{ background: '#f9fafb', borderBottom: '0.0625rem solid #d1d6dc' }} >
                                <Box display='flex' justifyContent='space-between' alignItems="center">
                                    <Typography style={{ fontSize: '18px', fontWeight: 600 }}>Assign Forms</Typography>
                                    <Typography style={{ fontSize: '15px', fontWeight: 600, color: '#0060B2' }}>Set Recurrence</Typography>
                                </Box>
                            </Box>
                            <Box pb={4} pt={1} px={4} style={{ maxHeight: '500px', overflow: 'auto' }}>
                                {info && info.map((item: any, i: number) => {
                                    return (
                                        <ListItem style={{ borderBottom: info.length === (i + 1) ? 'unset' : '2px solid #dddddd', padding: '4px 0px' }}>
                                            <Box style={{ width: '-webkit-fill-available' }}>
                                                <Box display='flex' style={{ width: '-webkit-fill-available' }}>
                                                    <Box style={{ width: '60%' }}><Typography style={{ fontSize: '16px' }}>{item.title}</Typography>
                                                        <Typography style={{ fontSize: '12px', color: 'gray' }}>{item.description}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </ListItem>
                                    )
                                })}
                            </Box>
                        </Card>
                    </Box>
                </Paper>
                <DialogActions style={{ padding: '15px 20px', background: '#f9fafb', display: 'unset', justifyContent: 'unset' }}>
                    <Box display='flex' justifyContent='space-between'>
                        <LoadingButton
                            className={classes.secondaryButton}
                            variant="contained"
                            onClick={() => handelClose()}
                        >
                            Cancel
                        </LoadingButton>
                        <LoadingButton disabled={loading}
                            className={classes.primaryButton}
                            variant="contained"
                            onClick={() => setPatientDialogueOpen(true)}
                        >
                            Next
                        </LoadingButton>
                    </Box>
                </DialogActions>
            </MUIDialog>
            }
            {patientDialogueOpen && <Patients handelClose={assignFormToPatientClose} />}
        </>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    topBox: {
        borderRadius: '0.625rem 0.625rem 0 0'
    },
    bottomBox: {
        borderRadius: '0 0 0.625rem 0.625rem'
    },
    primaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#fff',
        minWidth: '120px',
        background: '#0060B2',
        '&:hover': {
            backgroundColor: '#0060B2',
        }
    },
    secondaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#0060B2',
        minWidth: '120px',
        background: '#fff'
    },
    paper: {
        width: '580px',
        overflow: 'hidden'
    },
    searchTextField: {
        backgroundColor: theme.palette.secondary.main,
        fontSize: theme.typography.h5.fontSize,
        height: '2rem',
        width: '11rem',
        zIndex: 1
    },
    searchIcon: {
        fontSize: 17
    },
    paginationButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: 'black',
        marginLeft: '20px',
        minWidth: '120px',
        background: '#fff'
    },
}));
