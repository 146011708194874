import React, { useEffect, useState } from 'react';
import { Box, Theme, Typography, makeStyles, Grid, Select, TextField, MenuItem } from '@material-ui/core';
import { Dialog } from '../../../../components/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { saveSharedUserThunk, getAccountRolesThunk, getReachCareOrgIDThunk } from '../../../../redux/thunk/thunks';
import { LoadingButton } from '../../../../components/LoadingButton';
import { RootState } from '../../../../redux/redux-store';
import { ROLES } from '../../../../shared/globals';
import { useRouteMatch } from 'react-router-dom';

interface NewSharedUserDialogProps {
    isOpen: boolean;
    handleClose: () => void;
}

export const NewSharedUserDialog = ({ isOpen, handleClose }: NewSharedUserDialogProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const organization_id: any = sessionStorage.getItem('pat_org_id');
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const { path } = useRouteMatch();
    const split = path.split('/');
    const patient_role_id: any = split && split.length > 1 && split[2];
    const [disDate, setDisabledDate] = useState<any>();
    const [isError, setError] = useState(false);
    const [isFieldShow, setIsFieldShow] = useState(false);
    const [input, setInput] = useState(null);
    const [initialValues, setInitialValues] = useState({
        first_name: '',
        last_name: '',
        role: '',
        email: '',
        birth_date: '',
    });

    const reachCareOrgId = useSelector(
        (state: RootState) => state.DATA_REDUCER.reachCareOrgId
    );

    useEffect(() => {
        let newDate: any = Date.now();
        setDisabledDate(new Date(newDate).getFullYear() + '-' + (("0" + (new Date(newDate).getMonth() + 1)).slice(-2)) + '-' + ("0" + (new Date(newDate).getDate() - 1)).slice(-2));
    }, []);

    useEffect(() => {
        dispatch(getReachCareOrgIDThunk());
    }, []);

    useEffect(() => {
        if (reachCareOrgId) {
            if (loggedInUser?.organization_id === reachCareOrgId) { setIsFieldShow(true); }
        }
    }, [reachCareOrgId]);

    useEffect(() => {
        let newDate: any = Date.now();
        setDisabledDate(new Date(newDate).getFullYear() + '-' + (("0" + (new Date(newDate).getMonth() + 1)).slice(-2)) + '-' + ("0" + (new Date(newDate).getDate() - 1)).slice(-2));
    }, []);

    const roles: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.roleList
    );
    useEffect(() => {
        dispatch(getAccountRolesThunk())
    }, [])

    const organizationSchema = Yup.object().shape({
        first_name: Yup.string().required('Required').max(50, ''),
        last_name: Yup.string().required('Required').max(50, ''),
        role: Yup.string().required('Required'),
        email: Yup.string().email('Email is invalid').required('Required'),
        birth_date: isFieldShow ? Yup.string().required("Required") : Yup.string(),
    });

    const handleSubmit = async (values: any, actions: any) => {
        const data = {
            first_name: values.first_name,
            last_name: values.last_name,
            role: values.role,
            email: values.email,
            organization_id: organization_id,
            associated_patient_id: patient_role_id,
            birth_date: values.birth_date
        }
        actions.setSubmitting(true);
        await dispatch(saveSharedUserThunk(data));
        actions.setSubmitting(false);
    }

    const handleChange = (value: any, no: any) => {
        if (no === 0 && value.length > 50) {
            setError(true); setInput(no);
        } else if (no === 1 && value.length > 50) {
            setError(true); setInput(no);
        } else {
            setError(false); setInput(no);
        }
    }

    return (
        <Dialog handleClose={handleClose} open={isOpen}>
            <Box display="flex" flexDirection="column">
                <Box alignItems="center" display="flex" justifyContent="space-between" p={3} style={{ background: '#f9fafb', borderRadius: '10px 10px 0px 0px' }}>
                    <Typography variant="h2">Add a Shared User</Typography>
                </Box>
                <Box pt={3} px={3}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validateOnBlur={false}
                        validateOnChange={false}
                        validationSchema={organizationSchema}
                    >
                        {({ errors, isSubmitting, setFieldValue, setSubmitting, touched, values }) => {

                            return (
                                <Form style={{ width: '500px' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Box pb={1.0}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        First Name*
                                                    </Typography>
                                                </Box>

                                                <Field fullWidth style={{ background: isError && input === 0 ? '#fff1f1' : 'unset' }}
                                                    onInput={(e: any) => handleChange(e.target.value, 0)}
                                                    as={TextField}
                                                    inputProps={{ maxLength: 51 }}
                                                    className={classes.textField}
                                                    name="first_name"
                                                    size="small"
                                                    variant="outlined"
                                                />

                                                <Box display='flex' justifyContent='space-between'>
                                                    <Typography color={"error"} variant="subtitle2">
                                                        {errors.first_name && touched.first_name && errors.first_name}
                                                    </Typography>
                                                    <Typography style={{ color: values.first_name?.length > 50 ? '#911717' : '#0000008a' }} variant="subtitle2">
                                                        {values.first_name?.length === 51 ? 50 : values.first_name?.length}/50
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box pb={1.0}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Last Name*
                                                    </Typography>
                                                </Box>

                                                <Field fullWidth style={{ background: isError && input === 1 ? '#fff1f1' : 'unset' }}
                                                    onInput={(e: any) => handleChange(e.target.value, 1)}
                                                    as={TextField}
                                                    inputProps={{ maxLength: 51 }}
                                                    className={classes.textField}
                                                    name="last_name"
                                                    size="small"
                                                    variant="outlined"
                                                />

                                                <Box display='flex' justifyContent='space-between'>
                                                    <Typography color={"error"} variant="subtitle2">
                                                        {errors.last_name && touched.last_name && errors.last_name}
                                                    </Typography>
                                                    <Typography style={{ color: values.last_name?.length > 50 ? '#911717' : '#0000008a' }} variant="subtitle2">
                                                        {values.last_name?.length === 51 ? 50 : values.last_name?.length}/50
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        {isFieldShow &&
                                            <Grid item xs={12}>
                                                <Box pb={1}>
                                                    <Box flexGrow="1">
                                                        <Box pb={0.75}>
                                                            <Typography color="textSecondary" variant="subtitle2">
                                                                Date of Birth
                                                            </Typography>
                                                        </Box>

                                                        <Field
                                                            as={TextField}
                                                            name="birth_date"
                                                            size="small"
                                                            type="date"
                                                            format="MM/dd/yyyy"
                                                            variant="outlined"
                                                            style={{ width: '100%' }}
                                                            InputProps={{ inputProps: { max: disDate } }}
                                                        />
                                                        {errors.birth_date && touched.birth_date ? (
                                                            <Typography color="error" variant="subtitle2">
                                                                {errors.birth_date}
                                                            </Typography>
                                                        ) : null}
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <Box pb={1.0}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Email Address*
                                                    </Typography>
                                                </Box>

                                                <Field fullWidth
                                                    as={TextField}
                                                    className={classes.textField}
                                                    name="email"
                                                    size="small"
                                                    variant="outlined"
                                                />

                                                {errors.email && touched.email ? (
                                                    <Typography color="error" variant="subtitle2">
                                                        {errors.email}
                                                    </Typography>
                                                ) : null}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box pb={1.0}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Role*
                                                    </Typography>
                                                </Box>

                                                <Field as={Select} name="role" variant="outlined" className={classes.textField1}>
                                                    {roles && roles.map((role: any) => {
                                                        return (role.role === ROLES.PROXY || role.role === ROLES.RATER) && <MenuItem value={role.role}>{role.role}</MenuItem>
                                                    })}
                                                </Field>
                                                {errors.role && touched.role ? (
                                                    <Typography color="error" variant="subtitle2">
                                                        {errors.role}
                                                    </Typography>
                                                ) : null}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box display="flex" justifyContent="space-between">
                                                <LoadingButton
                                                    className={classes.secondaryButton}
                                                    onClick={handleClose}
                                                    variant="contained"
                                                >
                                                    Cancel
                                                </LoadingButton>
                                                <LoadingButton
                                                    className={classes.primaryButton}
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    loading={isSubmitting}
                                                    type="submit"
                                                    variant="contained"
                                                >
                                                    Save
                                                </LoadingButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                </Box>
            </Box>
        </Dialog >
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    topBox: {
        borderRadius: '0.625rem 0.625rem 0 0'
    },
    bottomBox: {
        borderRadius: '0 0 0.625rem 0.625rem'
    },
    textField: {
        width: '100%'
    },
    primaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#fff',
        minWidth: '120px',
        marginBottom: '20px'
    },
    secondaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#0060B2',
        background: '#fff',
        marginBottom: '20px',
        minWidth: '120px'
    },
    deleteButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: 'red',
        minWidth: '120px'
    },
    createCollectionTextField: {
        maxWidth: '40rem',
        width: '80vw'
    },
    textField1: {
        width: '100%',
        height: '35px',
        padding: '0px'
    }
}));
