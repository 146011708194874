import React, { useState } from 'react';
import { Theme, Typography, makeStyles, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { NewAppointment } from './NewAppointment';
import { AppointmentSearchResults, UuidType } from '../../../../shared/domain';
import { GetAppointmentsProps } from '../../../../redux/thunk/thunks';
import { HeadCell, LastSorted, Pagination, RowCell, Table } from '../../../../components/Table';

interface AppointmentsTableProps {
  appointmentId?: UuidType;
  appointmentData: AppointmentSearchResults | null;
  selectedAppointment?: UuidType[];
  loadAppointments: (aetAppointmentsProps: GetAppointmentsProps) => void;
  setSelectedAppointment?: (selectedResources: UuidType[]) => void;
}

export const AppointmentsTable = ({
  appointmentData,
  selectedAppointment,
  loadAppointments,
  setSelectedAppointment
}: AppointmentsTableProps) => {
  const classes = useStyles();
  const [newAppointmentDialogueOpen, setNewAppointmentDialogueOpen] = useState(false);
  const [appointmentInfo, setAppointmentInfo] = useState();

  const [lastSorted, setLastSorted] = useState<LastSorted>({
    column: 'created_at',
    order: 'desc'
  });

  const headCells: HeadCell[] = [
    {
      id: 'name',
      label: 'NAME'
    },
    {
      align: 'left',
      id: 'date',
      label: 'Date'
    },
    {
      align: 'left',
      id: 'time',
      label: 'Time'
    }
  ];

  const rowCells = appointmentData?.appointments && appointmentData?.appointments.map((appointment: any) => {
    const nameLink = (
      <Typography className={classes.nameLink} style={{ cursor: 'pointer' }} color='primary' variant='h5' onClick={(e) => handleNewAppointmentDialogueOpen(appointment)}>
        {appointment.clinican_first_name + ' ' + appointment.clinican_last_name}
      </Typography>
    );

    const appDate = new Date(appointment.startDate);
    const appDate2 = new Date(appointment.endDate);
    let startTime = (("0" + appDate.getHours()).slice(-2)) + ':' + (("0" + appDate.getMinutes()).slice(-2));
    let endTime = (("0" + appDate2.getHours()).slice(-2)) + ':' + (("0" + appDate2.getMinutes()).slice(-2));
    var options: any = { weekday: 'long' };
    const rowCell: RowCell = {
      name: {
        component: nameLink
      },
      date: {
        align: 'left',
        // value: new Date(appDate).toDateString()
         value: new Intl.DateTimeFormat('en-US', options).format(appDate) + ', ' + (("0" + (appDate.getMonth() + 1)).slice(-2)) + '/' + ("0" + appDate.getDate()).slice(-2) + '/' +  appDate.getFullYear() 
      },
      time: {
        align: 'left',
        value: startTime + ' to ' + endTime
      }
    };

    return Object.assign(rowCell, { content: appointment });
  });

  const handleNextButton = async () => {
    if (
      appointmentData &&
      appointmentData.page >= 0 &&
      appointmentData.page < appointmentData.total / appointmentData.pageSize - 1
    ) {
      await loadAppointments({
        search: appointmentData.search,
        page: appointmentData.page + 1,
        pageSize: appointmentData.pageSize,
        sort: appointmentData.sort,
        order: appointmentData.order
      });
    }
  };
  const handlePrevButton = async () => {
    if (appointmentData && appointmentData.page > 0) {
      await loadAppointments({
        search: appointmentData.search,
        page: appointmentData.page - 1,
        pageSize: appointmentData.pageSize,
        sort: appointmentData.sort,
        order: appointmentData.order
      });
    }
  };

  const handlePageSize = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    const pageSize = e.target.value as number;

    if (appointmentData && pageSize > 0) {
      loadAppointments({
        page: 0,
        pageSize: pageSize,
        search: appointmentData.search,
        sort: appointmentData.sort,
        order: appointmentData.order
      });
    }
  };

  const pagination: Pagination | undefined = appointmentData
    ? {
      contentName: 'Appointment',
      page: appointmentData.page,
      pageSize: appointmentData.pageSize,
      total: appointmentData.total,
      handleNextButton: handleNextButton,
      handlePageSize: handlePageSize,
      handlePrevButton: handlePrevButton
    }
    : undefined;

  const handleSort = (sort: string) => {
    const nextDirection = lastSorted.order === 'desc' ? 'asc' : 'desc';

    loadAppointments({
      order: sort === lastSorted.column ? nextDirection : 'desc',
      page: 0,
      pageSize: appointmentData?.pageSize,
      search: appointmentData?.search,
      sort
    });

    setLastSorted(
      sort === lastSorted.column
        ? { column: sort, order: nextDirection }
        : { column: sort, order: 'desc' }
    );
  };

  const handleNewAppointmentDialogueClose = () => {
    setNewAppointmentDialogueOpen(false);
  };

  const handleNewAppointmentDialogueOpen = (appointment: any) => {
    setNewAppointmentDialogueOpen(true);
    setAppointmentInfo(appointment);
  };

  return (
    <>
      {newAppointmentDialogueOpen && (
        <NewAppointment open={newAppointmentDialogueOpen} handleClose={handleNewAppointmentDialogueClose}
          patient={null} appointmentInfo={appointmentInfo} edit={true} />
      )}
      <Table
        data={appointmentData?.appointments}
        headCells={headCells}
        loading={!appointmentData?.appointments}
        pagination={pagination}
        rowCells={rowCells}
        selection={
          selectedAppointment && setSelectedAppointment
            ? {
              selectedContents: selectedAppointment,
              setSelectedContents: (selected: UuidType[]) => setSelectedAppointment(selected)
            }
            : undefined
        }
        sort={{
          lastSorted: lastSorted,
          handleSort: handleSort
        }}
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  nameLink: {
    textDecoration: 'none'
  }
}));
