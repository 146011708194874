import { Box, CircularProgress, makeStyles, Toolbar } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, RouteComponentProps, Switch, useRouteMatch, withRouter, useLocation } from 'react-router-dom';
import { setDisplayFormAction, setEditingExitPageAction, setEditingFormAction } from '../../redux/data/data-actions';
import { setSuccessAction } from '../../redux/display/display-actions';
import { RootState } from '../../redux/redux-store';
import {
  fetchFormToEditByIdThunk, formUpdateThunk, editCalculationThunk, updateExitPageThunk, newVersionFormThunk,
  getFormFieldsByFormIdThunk, editMultiMetricQuestionThunk, saveFormInfoThunk
} from '../../redux/thunk/thunks';
import { UuidType } from '../../shared/domain';
import { FormBuilderHeader } from './components/formBuilderHeader/FormBuilderHeader';
import { EntryPageBuilder } from './EntryPageBuilder';
import { ExitPageBuilder } from './ExitPageBuilder';
import { CalculationPageBuilder } from './CalculationPageBuilder';
import { FormBuilder } from './FormBuilder';
import { ViewFormBuilder } from './ViewBuilder';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { ROLES } from '../../shared/globals';

interface RouterProps {
  formId: UuidType;
}

interface FormBuilderRoutesProps extends RouteComponentProps<RouterProps> { }

const Routes: React.FC<FormBuilderRoutesProps> = ({ match }) => {
  let { path, url } = useRouteMatch();
  const split = url.split('/');
  const formId: any = split && split.length > 1 && split[2];
  const classes = useStyles();
  const dispatch = useDispatch();
  const location: any = useLocation();
  const isCreate = sessionStorage.getItem('new');
  const sessionEdit = sessionStorage.getItem('edit');
  const edit = isCreate ? true : sessionEdit === 'true' ? true : location.state;
  const [isEdit, setIsEdit] = useState(edit || false);
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);
  const [disabled, setDisabled] = useState(false);
  const editingForm: any = useSelector((state: RootState) => state.DATA_REDUCER.editingForm);
  const editingExitPage: any = useSelector((state: RootState) => state.DATA_REDUCER.editingExitPage);
  const loadingData: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.getFormFieldsLoading);
  //On mount fetch and set form and fields data
  useEffect(() => {
    dispatch(fetchFormToEditByIdThunk(formId));
    dispatch(getFormFieldsByFormIdThunk(formId));
    dispatch(setEditingFormAction(null));
    // dispatch(getCalculationMetricsThunk());

    return function CleanUp() {
      //clear data from redux
      dispatch(setEditingExitPageAction(null));
      dispatch(setDisplayFormAction(null));
    };
  }, []);
  /**Save form as a old version */
  const handelUpdateSaveChanges = async () => {
    setDisabled(true);
    sessionStorage.setItem('check', 'SAVE');
    sessionStorage.removeItem('new');
    const tab: any = sessionStorage.getItem('formTab');
    const form_version_id = sessionStorage.getItem('version');
    if (tab === 'entry') {
      if (editingForm) {
        const domains = [];
        const tags = [];
        const collections = [];
        for (let i = 0; i < editingForm?.domains.length; i++) {
          domains.push(editingForm?.domains[i]?.id);
        }
        for (let i = 0; i < editingForm?.tags.length; i++) {
          tags.push(editingForm?.tags[i]?.id);
        }
        for (let i = 0; i < editingForm?.collections.length; i++) {
          collections.push(editingForm?.collections[i]?.id);
        }
        editingForm.domains = domains;
        editingForm.tags = tags;
        editingForm.collections = collections;
        await dispatch(formUpdateThunk(editingForm));
      }
      await dispatch(saveFormInfoThunk(editingForm?.id));
      // if (!editingForm?.is_form_replicated && [ROLES.ADMIN, ROLES.SUPER_ADMIN].includes(loggedInUser.role)) {
      //   await dispatch(saveOnFlagTrueFormThunk(editingForm?.id));
      // }
    } else if (tab === 'exit') {
      await dispatch(updateExitPageThunk(editingExitPage, editingForm?.id));
      await dispatch(saveFormInfoThunk(editingForm?.id));
      // if (!editingForm?.is_form_replicated && [ROLES.ADMIN, ROLES.SUPER_ADMIN].includes(loggedInUser.role)) {
      //   await dispatch(saveOnFlagTrueFormThunk(editingForm?.id));
      // }
    } else if (tab === 'calculation') {
      const getData: any = sessionStorage.getItem('calculation');
      const metric_id: any = sessionStorage.getItem('metric_id');
      const change: any = sessionStorage.getItem('onChange');
      const getChange = JSON.parse(change);
      if (getChange && getChange.id) {
        const data = {
          "metric_name": getChange.code,
          "description": getChange.description,
          "form_id": formId
        }
        const result: any = await dispatch(editCalculationThunk(getChange?.id, data))
      }
      const convertData = JSON.parse(getData);
      const calculation_data: any = [];
      if (convertData && convertData.length > 0) {
        for (let i = 0; i < convertData.length; i++) {
          const array: any = [];
          for (let j = 0; j < convertData[i].fields.length; j++) {
            let regexPattern = /^-?[0-9]+$/;
            let result = regexPattern.test(convertData[i].fields[j].calculation_point_amount);
            const info = {
              "comparison_option_id": convertData[i].fields[j]?.comparison_option_id,
              "comparison_value": convertData[i].fields[j]?.comparison_value,
              "operator_type": convertData[i].fields[j]?.operator_type,
              "calculation_operator": convertData[i].fields[j]?.calculation_operator,
              "calculation_point_amount": convertData[i].fields[j]?.calculation_point_amount
            }
            array.push(info);
          }
          const data = {
            "form_field_id": convertData[i]?.id,
            "metric_id": metric_id,
            "calc_data": array,
            "form_version_id": form_version_id
          }
          calculation_data.push(data);
        }
        await dispatch(editMultiMetricQuestionThunk(editingForm?.id, calculation_data, metric_id));
      }
      await dispatch(saveFormInfoThunk(editingForm?.id));
      // if (!editingForm?.is_form_replicated && [ROLES.ADMIN, ROLES.SUPER_ADMIN].includes(loggedInUser.role)) {
      //   await dispatch(saveOnFlagTrueFormThunk(editingForm?.id));
      // }
    } else if (tab === 'field') {
      await dispatch(saveFormInfoThunk(editingForm?.id));
      await dispatch(setSuccessAction('autoSaveSuccess', true));
      // if (!editingForm?.is_form_replicated && [ROLES.ADMIN, ROLES.SUPER_ADMIN].includes(loggedInUser.role)) {
      //   await dispatch(saveOnFlagTrueFormThunk(editingForm?.id));
      // }
    } else {
      // dispatch(snackbarAlertAction(`Updated form successfully`, 'success'));
      await dispatch(saveFormInfoThunk(editingForm?.id));
      await dispatch(setSuccessAction('autoSaveSuccess', true));
      // if (!editingForm?.is_form_replicated && [ROLES.ADMIN, ROLES.SUPER_ADMIN].includes(loggedInUser.role)) {
      //   await dispatch(saveOnFlagTrueFormThunk(editingForm?.id));
      // }
    }
    setDisabled(false);
    setIsEdit(false);
  }
  /**Save form as new version */
  const handelSaveChangesToNewVersion = async () => {
    sessionStorage.setItem('check', 'SAVE');
    const tab: any = sessionStorage.getItem('formTab');
    const form_version_id = sessionStorage.getItem('version');
    setIsEdit(false);
    if (tab === 'entry') {
      if (editingForm) {
        const domains = [];
        const tags = [];
        const collections = [];
        for (let i = 0; i < editingForm?.domains.length; i++) {
          domains.push(editingForm?.domains[i].id);
        }
        for (let i = 0; i < editingForm?.tags.length; i++) {
          tags.push(editingForm?.tags[i].id);
        }
        for (let i = 0; i < editingForm?.collections.length; i++) {
          collections.push(editingForm?.collections[i].id);
        }
        editingForm.domains = domains;
        editingForm.tags = tags;
        editingForm.collections = collections;
        await dispatch(formUpdateThunk(editingForm));
      }
      dispatch(newVersionFormThunk(editingForm?.id));
      // if (!editingForm?.is_form_replicated && [ROLES.ADMIN, ROLES.SUPER_ADMIN].includes(loggedInUser.role)) {
      //   dispatch(saveOnFlagTrueFormThunk(editingForm?.id));
      // }
    } else if (tab === 'exit') {
      await dispatch(updateExitPageThunk(editingExitPage, editingForm?.id));
      dispatch(newVersionFormThunk(editingForm?.id));
      // if (!editingForm?.is_form_replicated && [ROLES.ADMIN, ROLES.SUPER_ADMIN].includes(loggedInUser.role)) {
      //   dispatch(saveOnFlagTrueFormThunk(editingForm?.id));
      // }
    } else if (tab === 'calculation') {
      const getData: any = sessionStorage.getItem('calculation');
      const metric_id: any = sessionStorage.getItem('metric_id');
      const change: any = sessionStorage.getItem('onChange');
      const getChange = JSON.parse(change);
      if (getChange && getChange.id) {
        const data = {
          "metric_name": getChange.code,
          "description": getChange.description,
          "form_id": formId
        }
        const result: any = await dispatch(editCalculationThunk(getChange?.id, data))
      }
      const convertData = JSON.parse(getData);
      const calculation_data: any = [];
      if (convertData && convertData.length > 0) {
        for (let i = 0; i < convertData.length; i++) {
          const array: any = [];
          for (let j = 0; j < convertData[i].fields.length; j++) {
            let regexPattern = /^-?[0-9]+$/;
            let result = regexPattern.test(convertData[i]?.fields[j]?.calculation_point_amount);
            const info = {
              "comparison_option_id": convertData[i]?.fields[j]?.comparison_option_id,
              "comparison_value": convertData[i]?.fields[j]?.comparison_value,
              "operator_type": convertData[i]?.fields[j]?.operator_type,
              "calculation_operator": convertData[i]?.fields[j]?.calculation_operator,
              "calculation_point_amount": convertData[i]?.fields[j]?.calculation_point_amount
            }
            array.push(info);
          }
          const data = {
            "form_field_id": convertData[i].id,
            "metric_id": metric_id,
            "calc_data": array,
            "form_version_id": form_version_id
          }
          calculation_data.push(data);
        }
        await dispatch(editMultiMetricQuestionThunk(editingForm?.id, calculation_data, metric_id));
      }
      dispatch(newVersionFormThunk(editingForm?.id));
      // if (!editingForm?.is_form_replicated && [ROLES.ADMIN, ROLES.SUPER_ADMIN].includes(loggedInUser.role)) {
      //   dispatch(saveOnFlagTrueFormThunk(editingForm?.id));
      // }
    } else if (tab === 'field') {
      await dispatch(newVersionFormThunk(editingForm?.id));
      await dispatch(setSuccessAction('autoSaveSuccess', true));
      // if (!editingForm?.is_form_replicated && [ROLES.ADMIN, ROLES.SUPER_ADMIN].includes(loggedInUser.role)) {
      //   dispatch(saveOnFlagTrueFormThunk(editingForm?.id));
      // }
    } else {
      await dispatch(newVersionFormThunk(editingForm?.id));
      // dispatch(snackbarAlertAction(`Updated form successfully`, 'success'));
      await dispatch(setSuccessAction('autoSaveSuccess', true));
      // if (!editingForm?.is_form_replicated && [ROLES.ADMIN, ROLES.SUPER_ADMIN].includes(loggedInUser.role)) {
      //   dispatch(saveOnFlagTrueFormThunk(editingForm?.id));
      // }
    }
  }

  const printRef = React.useRef(null);

  const handleDownloadPdf = async () => {
    const divToPrint: any = document.querySelector('#element-to-print');
    html2canvas(divToPrint).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = 190;
      const pageHeight = 290;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      const doc = new jsPDF("p", "mm", "a4");
      let position = 0;
      doc.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight + 25);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight + 25);
        heightLeft -= pageHeight;
      }
      doc.save('download.pdf');
    });
  };

  const handleEdit = (value: any) => {
    setIsEdit(value);
  }

  return (
    loadingData ? <Box display='flex' justifyContent='center' alignItems='center' style={{ height: '90vh' }}><CircularProgress /></Box> : <Box className={classes.main}>
      <FormBuilderHeader isEdit={isEdit} form={editingForm} pdfSave={handleDownloadPdf} handelUpdateSaveChanges={handelUpdateSaveChanges} handelSaveChangesToNewVersion={handelSaveChangesToNewVersion} isDisabled={disabled} setIsEdit={handleEdit} />
      <Toolbar />
      <div ref={printRef} id="element-to-print">
        <Switch>
          <Route path={`${url}/EntryPage`}>
            <EntryPageBuilder formId={formId} />
          </Route>
          <Route path={`${url}/Fields`}>
            {isEdit ? <FormBuilder /> : <ViewFormBuilder formId={formId} />}
          </Route>
          <Route path={`${url}/ExitPages`}>
            <ExitPageBuilder formId={formId} />
          </Route>
          <Route path={`${url}/Calculation`}>
            <CalculationPageBuilder formId={formId} />
          </Route>
        </Switch>
      </div>
    </Box>
  );
};

export const FormBuilderRoutes = withRouter(Routes);

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: theme.background.main,
    minHeight: '100vh'
  }
}));
