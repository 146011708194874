import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { ROLES } from '../shared/globals';
import { colors } from '../styling/colors';

export const Logo = () => {
  const classes = useStyles();
  const loggedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(loggedUser);
  const location: any = window.location;

  return (
    <Grid alignItems="center" container>
      <img alt="flowpro-logo" height={35} src="/flowpro.png" />
      {location.pathname !== '/login' && loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER, ROLES.CLINICIAN, ROLES.FAMILY, ROLES.PATIENT, ROLES.PROXY].includes(loggedInUser.role) && <span style={{ marginLeft: '10px', padding: '2px 5px', borderRadius: '5px', background: 'black', color: '#F1F5F9' }}>{loggedInUser.organization_name}</span>}
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  clinicBubble: {
    backgroundColor: colors.background4,
    borderRadius: '0.375rem',
    height: '1.3125rem',
    width: '6.875rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  clinicText: {
    color: colors.background3
  }
}));
