import React, { useEffect, useState } from 'react';
import pluralize from 'pluralize';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { Dialog } from '../../../../components/Dialog';
import { LoadingButton } from '../../../../components/LoadingButton';
import { RootState } from '../../../../redux/redux-store';
import { SearchBar } from '../../../../components/SearchBar';
import { TabBar, TabComponent } from '../../../../components/TabBar';
import { UnitsTable } from './UnitsTable';
import { UsersTable } from './UsersTable';
import { Selection } from '../../../../components/Table';
import { UuidType } from '../../../../shared/domain';
import {
  assignToCollectionThunk,
  getNonPatientsThunk,
  getUnitsThunk
} from '../../../../redux/thunk/thunks';

interface AssignToCollectionDialogProps {
  collectionId: UuidType;
  library?: boolean;
  open: boolean;
  handleClose: () => void;
}

export const AssignToCollectionDialog = ({
  collectionId,
  library,
  open,
  handleClose
}: AssignToCollectionDialogProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const unitsData = useSelector(
    (state: RootState) => state.DATA_REDUCER.unitSearchResults.assignCollection
  );
  const usersData = useSelector(
    (state: RootState) => state.DATA_REDUCER.userSearchResults.assignCollection
  );
  const assignCollectionLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.assignCollectionLoading
  );

  const [selectedTab, setSelectedTab] = useState('all');
  const [selectedUnits, setSelectedUnits] = useState<UuidType[]>([]);
  const [selectedUnitsUsersCount, setSelectedUnitsUsersCount] = useState<number>(0);
  const [selectedUsers, setSelectedUsers] = useState<UuidType[]>([]);

  const [unitsSearch, setUnitsSearch] = useState('');
  const [usersSearch, setUsersSearch] = useState('');

  const unitsSelection: Selection = {
    selectedContents: selectedUnits,
    setSelectedContents: (selected: UuidType[]) => setSelectedUnits(selected)
  };
  const usersSelection: Selection = {
    selectedContents: selectedUsers,
    setSelectedContents: (selected: UuidType[]) => setSelectedUsers(selected)
  };

  const handleTabChange = (id: string) => {
    setSelectedTab(id);

    if (id === 'units' && !unitsData) {
      dispatch(getUnitsThunk({ unitsType: 'assignCollection', pageSize: 8 }));
    }
  };

  const handleUnitsSearch = (value: string) => {
    if (unitsData) {
      dispatch(
        getUnitsThunk({
          unitsType: 'assignCollection',
          page: 0,
          pageSize: 8,
          search: value
        })
      );
    }
  };
  const handleUnitsNextButton = async () => {
    if (unitsData && unitsData.page < unitsData.total / unitsData.pageSize) {
      await dispatch(
        getUnitsThunk({
          unitsType: 'assignCollection',
          page: unitsData.page + 1,
          pageSize: 8,
          search: unitsSearch
        })
      );
    }
  };
  const handleUnitsPrevButton = async () => {
    if (unitsData && unitsData.page > 0) {
      await dispatch(
        getUnitsThunk({
          unitsType: 'assignCollection',
          page: unitsData.page - 1,
          pageSize: 8,
          search: unitsSearch
        })
      );
    }
  };
  const handleUsersSearch = (value: string) => {
    if (usersData) {
      dispatch(
        getNonPatientsThunk({
          usersType: 'assignCollection',
          page: 0,
          pageSize: 8,
          search: value
        })
      );
    }
  };
  const handleUsersNextButton = async () => {
    if (usersData && usersData.page < usersData.total / usersData.pageSize) {
      await dispatch(
        getNonPatientsThunk({
          usersType: 'assignCollection',
          page: usersData.page + 1,
          pageSize: 8,
          search: usersSearch
        })
      );
    }
  };
  const handleUsersPrevButton = async () => {
    if (usersData && usersData.page > 0) {
      await dispatch(
        getNonPatientsThunk({
          usersType: 'assignCollection',
          page: usersData.page - 1,
          pageSize: 8,
          search: usersSearch
        })
      );
    }
  };

  const tabComponents: TabComponent[] = [
    {
      component: (
        <Box className={classes.tableContainer} px={3} py={1}>
          <UsersTable
            selection={usersSelection}
            usersData={usersData}
            handleNextButton={handleUsersNextButton}
            handlePrevButton={handleUsersPrevButton}
          />
        </Box>
      ),
      id: 'all',
      label: 'All'
    },
    {
      component: (
        <Box className={classes.tableContainer} px={3} py={1}>
          <UnitsTable
            selection={unitsSelection}
            unitsData={unitsData}
            handleNextButton={handleUnitsNextButton}
            handlePrevButton={handleUnitsPrevButton}
          />
        </Box>
      ),
      id: 'units',
      label: 'Units'
    }
  ];

  const handleAssignButton = () => {
    dispatch(assignToCollectionThunk(collectionId, selectedUnits, selectedUsers));
  };

  useEffect(() => {
    dispatch(getNonPatientsThunk({ usersType: 'assignCollection', pageSize: 8 }));
  }, []);

  useEffect(() => {
    let selectedUnitsUsers = 0;
    selectedUnits.forEach((selectedUnitId) => {
      const usersCount = unitsData?.units.find((unit) => unit.id === selectedUnitId)?.users_count;
      selectedUnitsUsers += usersCount ? usersCount : 0;
    });
    setSelectedUnitsUsersCount(selectedUnitsUsers);
  }, [selectedUnits]);

  return (
    <Dialog open={open} handleClose={handleClose}>
      <Box className={classes.topBox} display="flex" flexDirection="column" pt={2.5}>
        <Box alignItems="center" display="flex" justifyContent="space-between" px={3}>
          <Typography variant="h3">{`Assign Users to this ${
            library ? 'Library' : 'Collection'
          }`}</Typography>

          <Box pl={2}>
            {selectedTab === 'all' ? (
              <SearchBar
                key="users"
                initialValue={usersSearch}
                handleSearch={handleUsersSearch}
                setInitialValue={(value: string) => setUsersSearch(value)}
              />
            ) : (
              <SearchBar
                key="units"
                initialValue={unitsSearch}
                handleSearch={handleUnitsSearch}
                setInitialValue={(value: string) => setUnitsSearch(value)}
              />
            )}
          </Box>
        </Box>

        <TabBar
          style={{
            spacing: {
              px: 3
            }
          }}
          tabComponents={tabComponents}
          onTabChange={handleTabChange}
        />
      </Box>

      <Box
        className={classes.bottomBox}
        display="flex"
        justifyContent="space-between"
        px={3}
        py={2}
      >
        <LoadingButton
          className={classes.secondaryButton}
          color="secondary"
          onClick={handleClose}
          variant="contained"
          aria-label={'cancel'}
        >
          Cancel
        </LoadingButton>

        <LoadingButton
          className={classes.primaryButton}
          color="primary"
          disabled={selectedUnits.length + selectedUsers.length === 0 || assignCollectionLoading}
          loading={assignCollectionLoading}
          onClick={handleAssignButton}
          variant="contained"
          aria-label={'Invite'}
        >
          {`Invite ${selectedUnitsUsersCount + selectedUsers.length} ${pluralize(
            'User',
            selectedUnitsUsersCount + selectedUsers.length
          )}`}
        </LoadingButton>
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  topBox: {
    backgroundColor: theme.palette.background.default,
    borderRadius: '0.625rem 0.625rem 0 0'
  },
  bottomBox: {
    backgroundColor: theme.palette.background.default,
    borderRadius: '0 0 0.625rem 0.625rem'
  },
  tableContainer: {
    backgroundColor: theme.palette.secondary.main
  },
  primaryButton: {
    boxShadow:
      '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)'
  }
}));
