import { Box, Button, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { Logo } from './Logo';
import ReactPlayer from 'react-player/youtube';
import { useHistory } from 'react-router-dom';
import { ROLES } from '../shared/globals';

interface FormPageProps {
  header: string | null;
  body: string | null;
  buttonLink?: string;
  buttonText?: string;
  videoUrl?: string | null;
  buttonCallback?: () => void;
  showReferralButton?: boolean;
  themeColor: string
}

export const FormPageDisplay: React.FC<FormPageProps> = ({
  header,
  body,
  buttonLink,
  buttonText,
  videoUrl,
  buttonCallback,
  showReferralButton,
  themeColor
}) => {
  const classes = useStyles();
  const history = useHistory();
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);

  const searchReferrals = () => {
    if ([ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role)) {
      history.push('/referral-list')
    } else if ([ROLES.CLINICIAN].includes(loggedInUser.role)) {
      history.push('/referrals')
    }
  };

  const validButton = buttonText && (buttonLink || buttonCallback);

  return (
    <Box m={5} maxWidth="50rem">
      <Box>
        <Logo />
      </Box>
      <Box my={2}>
        <Typography variant={'h1'}>{header}</Typography>
      </Box>
      <Box my={2}>
        <Typography variant={'subtitle1'}>{body}</Typography>
      </Box>
      {videoUrl && (
        <Box my={2} className={classes.reactPlayerWrapper}>
          <ReactPlayer
            controls
            className={classes.reactPlayer}
            url={videoUrl}
            width="100%"
            height="100%"
          />
        </Box>
      )}

      <Box my={2} className={classes.buttonArea}>
        {validButton ? (
          <Button style={{ background: themeColor }}
            variant="contained"
            color={'primary'}
            aria-label="resource"
            href={buttonLink}
            onClick={buttonCallback}
          >
            {buttonText}
          </Button>
        ) : null}
        {showReferralButton && (
          <Button style={{ background: themeColor }}
            variant="contained"
            color={'primary'}
            aria-label="referral search"
            onClick={searchReferrals}
          >
            Search Referrals
          </Button>
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  reactPlayerWrapper: {
    position: 'relative',
    paddingTop: '56.25%'
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0
  },
  buttonArea: {
    marginTop: '2rem',
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'row-reverse'
  }
}));
