import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import React from 'react';

interface ErrorNoticeProps {
  error: string;
}
export const ErrorNotice: React.FC<ErrorNoticeProps> = ({ error }) => {
  const classes = useStyles();
  return (
    <Box className={classes.mainContainer}>
      <ErrorIcon className={classes.errorIcon} color={'error'} fontSize={'small'} />
      <Typography color={'textSecondary'} variant="subtitle2">
        {error}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  errorIcon: {
    marginRight: '.8rem'
  },
  mainContainer: {
    display: 'flex',
    alignItems: 'center'
  }
}));
