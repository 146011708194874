import * as Yup from 'yup';
import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, TextField, Typography, InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Formik, Form, Field } from 'formik';
import { Link, useParams } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '../../components/LoadingButton';
import { Logo } from '../../components/Logo';
import { RootState } from '../../redux/redux-store';
import { colors } from '../../styling/colors';
import {
  changePasswordByChangePasswordIdThunk, forgotPasswordInviteThunk
} from '../../redux/thunk/thunks';
import { setErrorAction } from '../../redux/display/display-actions';

interface ChangePasswordFormValues {
  password: string;
  confirmNewPassword: string;
}
interface ResetPasswordEmailFormValues {
  email: string;
}

interface ForgotPasswordParams {
  changePasswordId: string;
}

export const ForgotPassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

  const { changePasswordId } = useParams<ForgotPasswordParams>();

  const changePasswordError = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayErrors.changePasswordError
  );

  const changePasswordSuccess = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.changePasswordSuccess
  );

  useEffect(() => {
    return () => {
      dispatch(setErrorAction('changePasswordError', ''));
    };
  }, []);

  const handleChangePassword = (values: ChangePasswordFormValues) => {
    dispatch(changePasswordByChangePasswordIdThunk(changePasswordId, values.password));
  };

  const changePasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .max(64, 'Password must be less than 64 characters')
      .required('Required'),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords do not match')
      .required('Required')
  });

  const initialValues: ChangePasswordFormValues = {
    password: '',
    confirmNewPassword: ''
  };

  const resetPasswordEmailSchema = Yup.object().shape({
    email: Yup.string().email('Email is invalid').required('Required')
  });

  const initialValuesResetPassword: ResetPasswordEmailFormValues = {
    email: ''
  };

  const handleResetPasswordEmail = async (values: ResetPasswordEmailFormValues, actions: any) => {
    actions.setSubmitting(true);
    // dispatch(forgotPasswordThunk(values.email));
    await dispatch(forgotPasswordInviteThunk(values.email));
    actions.setSubmitting(false);
    // history.push('/login');
  };

  return (
    <Grid alignItems="center" className={classes.main} container justify="center" spacing={0}>
      <Grid>
        <Box mb={4} ml={4}>
          <Logo />
        </Box>

        <Paper className={classes.loginPaper}>
          <Box p={4.5}>
            {changePasswordId ? (
              <Formik
                initialValues={initialValues}
                onSubmit={handleChangePassword}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={changePasswordSchema}
              >
                {({ errors, isSubmitting, setSubmitting, touched }) => {
                  if (isSubmitting && changePasswordError) {
                    setSubmitting(false);
                  }

                  return (
                    <Form>
                      <Box pb={2.5}>
                        <Typography color="textSecondary" variant="h1">
                          Reset your password
                        </Typography>
                      </Box>

                      <Box pb={2.5}>
                        <Box pb={0.75}>
                          <Typography color="textSecondary" variant="subtitle2">
                            New Password
                          </Typography>
                        </Box>

                        <Field
                          as={TextField}
                          className={classes.textField}
                          name="password"
                          size="small"
                          variant="outlined"
                          autoComplete='off'
                          type={showPassword ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  edge="end"
                                >
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />

                        {errors.password && touched.password ? (
                          <Typography color="error" variant="subtitle2">
                            {errors.password}
                          </Typography>
                        ) : null}
                      </Box>

                      <Box pb={2.5}>
                        <Box pb={0.75}>
                          <Typography color="textSecondary" variant="subtitle2">
                            Repeat Password
                          </Typography>
                        </Box>

                        <Field
                          as={TextField}
                          className={classes.textField}
                          name="confirmNewPassword"
                          size="small"
                          variant="outlined"
                          autoComplete='off'
                          type={showPassword1 ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword1}
                                  edge="end"
                                >
                                  {showPassword1 ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />

                        {errors.confirmNewPassword && touched.confirmNewPassword ? (
                          <Typography color="error" variant="subtitle2">
                            {errors.confirmNewPassword}
                          </Typography>
                        ) : null}
                      </Box>

                      <Box pb={0.5}>
                        {changePasswordError ? (
                          <Typography color="error" variant="subtitle2">
                            {changePasswordError}
                          </Typography>
                        ) : null}

                        <LoadingButton
                          className={classes.submitButton}
                          color="primary"
                          disabled={isSubmitting}
                          loading={isSubmitting}
                          type="submit"
                          variant="contained"
                          aria-label="change password"
                        >
                          Change Password
                        </LoadingButton>
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <Formik
                initialValues={initialValuesResetPassword}
                onSubmit={handleResetPasswordEmail}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={resetPasswordEmailSchema}
              >
                {({ errors, isSubmitting, setSubmitting, touched }) => {
                  if (isSubmitting && changePasswordError) {
                    setSubmitting(false);
                  }

                  return (
                    <Form>
                      <Box pb={0.5}>
                        <Typography color="textSecondary" variant="h1">
                          Reset your password
                        </Typography>
                      </Box>
                      <Box pb={2.5}>
                        <Typography color="textSecondary" variant="subtitle2">
                          Enter your email address for a link to reset your password
                        </Typography>
                      </Box>

                      <Box pb={2.5}>
                        <Box pb={0.75}>
                          <Typography color="textSecondary" variant="subtitle1">
                            Email Address
                          </Typography>
                        </Box>

                        <Field
                          as={TextField}
                          className={classes.textField}
                          name="email"
                          placeholder="your@email.com"
                          size="small"
                          type="text"
                          variant="outlined"
                        />

                        {errors.email && touched.email ? (
                          <Typography color="error" variant="subtitle2">
                            {errors.email}
                          </Typography>
                        ) : null}
                      </Box>

                      <Box pb={0.5}>
                        <LoadingButton
                          className={classes.submitButton}
                          color="primary"
                          disabled={isSubmitting}
                          loading={isSubmitting}
                          type="submit"
                          variant="contained"
                          aria-label="Reset Password"
                        >
                          Reset Password
                        </LoadingButton>
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            )}
          </Box>
        </Paper>
        <Box pt={2}>
          <Grid alignItems="flex-end" justify={'center'} container>
            <Link className={classes.link} to="/login">
              <Typography color="primary" variant="subtitle1">
                I remember my password
              </Typography>
            </Link>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    backgroundColor: colors.background1,
    minHeight: '100vh'
  },
  loginPaper: {
    margin: '0 2rem',
    maxWidth: '30rem',
    width: '80vw'
  },
  textField: {
    width: '100%'
  },
  link: {
    textDecoration: 'none'
  },
  rememberLabel: {
    color: colors.text4
  },
  submitButton: {
    width: '100%'
  }
}));
