import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from '../../styling/colors';
import { RootState } from '../../redux/redux-store';

export const LoginDisable = () => {
    const classes = useStyles();
    const verifyUser: any = useSelector((state: RootState) => state.DATA_REDUCER.loggedInUser);

    return (
        <Grid
            alignItems="center"
            className={classes.main}
            container
            justify="center"
            spacing={0}
            style={{ minHeight: '100vh' }}
        >
            <Paper className={classes.signupPaper}>
                <Box>
                    <Box pb={3} pt={2} px={4} display="flex" justifyContent="center">
                        <Typography color="textSecondary" variant="h2" style={{ textAlign: 'center', fontSize: '30px' }}>{verifyUser && verifyUser.status === "DISABLED" ? 'This account is disabled' : 'This account has been deleted'}</Typography>
                    </Box>
                    <Box pb={4} pt={2} display="flex" justifyContent="center">
                        <Typography color="textSecondary" variant="h4" style={{ textAlign: 'center' }}>
                            We're sorry, your account {verifyUser && verifyUser.status === "DISABLED" ? 'is currently disabled' : 'has been deleted'}. Please contact your
                            <a target="_blank" href={`mailto:${''}`}> administrator</a> for further information.</Typography>
                    </Box>
                    <Box pb={2} pt={2} display="flex" justifyContent="center">
                        <Typography color="textSecondary" variant="h4" style={{ textAlign: 'center' }}>Made a mistake?</Typography>
                    </Box>
                    <Box pb={4} pt={2} display="flex" justifyContent="center">
                        <Link className={classes.link} to="/login"><Typography color="primary" variant="h4" style={{ textAlign: 'center', cursor: 'pointer' }}>Click here to return to FormFlash</Typography></Link>
                    </Box>
                </Box>
            </Paper>
        </Grid>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    main: {
        backgroundColor: colors.background1,
        minHeight: '100vh'
    },
    signupPaper: {
        margin: '0 2rem',
        maxWidth: '30rem',
        width: '80vw'
    },
    textField: {
        width: '100%'
    },
    link: {
        textDecoration: 'none'
    },
    agreeLabel: {
        color: colors.text4
    },
    submitButton: {
        width: '100%'
    }
}));
