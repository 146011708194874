import { Box, makeStyles, Paper, Theme, Typography, DialogActions } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardHeader } from '../../../../components/LegacyCardHeader';
import { assignmentInfoAction, getStaticResourcesAction } from '../../../../redux/data/data-actions';
import { RootState } from '../../../../redux/redux-store';
import {
  getPatientInfoThunk, getAssignedResourcesThunk, getStaticResourcesThunk
} from '../../../../redux/thunk/thunks';
import { ROLES } from '../../../../shared/globals';
import { setSuccessAction } from '../../../../redux/display/display-actions';
import { Tag, UuidType } from '../../../../shared/domain';
import { ViewRecource } from './viewResource';
import { ResourcesTable } from './ResourcesTable';
import { Patients } from '../../../Resources/AssignPatient/Patients';
import { useRouteMatch } from 'react-router-dom';

export const Resources = () => {
  const dispatch = useDispatch();
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);
  const role: any = sessionStorage.getItem('role');
  const organization_id: any = sessionStorage.getItem('pat_org_id');
  const { path } = useRouteMatch();
  const split = path.split('/');
  const patient_role_id: any = split && split.length > 1 && split[2];
  const shared_role_id: any = split && split.length > 3 && split[4];
  const [selectedResources, setSelectedResources] = useState<UuidType[]>([]);
  const [newStaticReourceDialogOpen, setNewStaticReourceDialogOpen] = useState(false);
  const [viewCollection, setViewCollection] = useState(false);
  const [resourceFiles, setResourceFiles] = useState<any>();
  const [isEdit, setEdit] = useState(false);
  const [editInfo, setEditInfo] = useState<any>();
  const [onTab, setOnTab] = useState('');
  const [searchPattern, setSearchPattern] = useState('');
  const [openPatient, setOpenPatient] = useState(false);
  const [info, setResourceInfo] = useState<any>(null);

  const resourcesData: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.staticResourcesSearchResults.approved
  );
  const user: any = useSelector((state: RootState) => state.DATA_REDUCER.patientInfo);
  const success: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.uploadStaticResourceSuccess)
  useEffect(() => {
    if (success) {
      setViewCollection(false); setEditInfo(null);
      setEdit(false);
      setNewStaticReourceDialogOpen(false);
      dispatch(setSuccessAction('uploadStaticResourceSuccess', false));
      onTab === "Resource Library" ?
        dispatch(getStaticResourcesThunk({ search: searchPattern, page: resourcesData?.page, pageSize: resourcesData?.pageSize, order: resourcesData?.order, sort: resourcesData?.sort, favourites: false, resource_type: resourcesData?.resource_type, organization_id: organization_id })) :
        dispatch(getAssignedResourcesThunk({ search: searchPattern, page: resourcesData?.page, pageSize: resourcesData?.pageSize, order: resourcesData?.order, sort: resourcesData?.sort, favourites: resourcesData?.favourites, organization_id: organization_id, user_role_id: role === 'PATIENT' ? patient_role_id : shared_role_id }));
    }
  });

  useEffect(() => {
    !user && dispatch(getPatientInfoThunk(patient_role_id));

    return function CleanUp() {
      dispatch(getStaticResourcesAction('approved', null));
    };
  }, []);

  const viewResourceOpen = (info: any) => {
    setViewCollection(true);
    setEditInfo(info);
  };
  const viewResourceClose = () => {
    setViewCollection(false);
    setSelectedResources([]);
    setEdit(false);
    setEditInfo(null);
  };
  const handleEditResourceOpen = (files: any) => {
    setNewStaticReourceDialogOpen(true);
    setEdit(true);
    setResourceFiles(files)
  };

  const handleSearch = (value: string) => {
    setSearchPattern(value);
    if (resourcesData) {
      onTab === "Resource Library" ?
        dispatch(getStaticResourcesThunk({
          search: value,
          favourites: false,
          resource_type: resourcesData?.resource_type,
          organization_id: organization_id,
          order: resourcesData?.order,
          sort: resourcesData?.sort,
          pageSize: resourcesData?.pageSize,
        }))
        :
        dispatch(getAssignedResourcesThunk({
          catalogueId: resourcesData?.catalogueId,
          order: resourcesData?.order,
          sort: resourcesData?.sort,
          pageSize: resourcesData?.pageSize,
          search: value,
          favourites: resourcesData?.favourites,
          organization_id: organization_id,
          user_role_id: role === 'PATIENT' ? patient_role_id : shared_role_id
        }));
    }
    setSelectedResources([]);
  };

  // We need async/await over here as it is used for some state loading task
  const loadResources = async (getStaticResourcesProps: any) => {
    getStaticResourcesProps.organization_id = organization_id; getStaticResourcesProps.user_role_id = role === 'PATIENT' ? patient_role_id : shared_role_id;
    onTab === "Resource Library" ?
      await dispatch(getStaticResourcesThunk(getStaticResourcesProps)) :
      await dispatch(getAssignedResourcesThunk(getStaticResourcesProps));
  };

  const onFavoritesTabChange = async (e: any) => {
    if ((e === 'Assigned Resources' || e === 'Shared Resources') && (onTab !== e || onTab === '')) {
      setOnTab(e);
      selectedResources.length > 0 && setSelectedResources([]);
      await dispatch(getAssignedResourcesThunk({ search: searchPattern, favourites: false, organization_id: organization_id, user_role_id: role === 'PATIENT' ? patient_role_id : shared_role_id }));
    }
  }
  const onResourcesTabChange = async (e: any) => {
    if (e === 'Resource Library' && (onTab !== e || onTab === '')) {
      setOnTab(e);
      selectedResources.length > 0 && setSelectedResources([]);
      await dispatch(getStaticResourcesThunk({ search: searchPattern, favourites: false, resource_type: resourcesData?.resource_type, organization_id: organization_id }));
    }
  }

  const ResourcesTableContainer =
    <Box pb={4} pt={1} px={2}>
      <ResourcesTable
        resourcesData={resourcesData}
        selectedResources={selectedResources}
        loadResources={loadResources}
        setSelectedResources={(selectedResources: UuidType[]) =>
          setSelectedResources(selectedResources)
        }
        staticResourceType={'approved'}
        handelResourceView={viewResourceOpen}
        tab={onTab}
      />
    </Box>

  const assignResourceToPatient = async () => {
    setOpenPatient(true);
    const arr: any = [];
    for (let i = 0; i < selectedResources.length; i++) {
      const check = resourcesData && resourcesData?.data.filter((item: any) => item.id === selectedResources[i]);
      check && check.length > 0 && arr.push(check[0]);
    }
    setResourceInfo(arr);
    dispatch(assignmentInfoAction(arr));
  }
  const assignResourceToPatientClose = () => {
    setOpenPatient(false);
    setSelectedResources([]);
  }

  return (
    <> {viewCollection ? <Paper>
      <ViewRecource handleClose={viewResourceClose} editResource={handleEditResourceOpen} editInfo={editInfo} onTab={onTab} />
    </Paper>
      :
      <Paper>
        <Box display="flex" flexDirection="column">
          <CardHeader
            tabComponents={[
              {
                component: ResourcesTableContainer,
                label: [ROLES.PATIENT].includes(role) ? 'Assigned Resources' : 'Shared Resources',
                style: {
                  background: true,
                  spacing: { py: 3, px: 4, pb: 0 }
                },
                title: 'All Resources',
                handleSearch: handleSearch,
                onTabChange: onFavoritesTabChange
              },
              {
                component: ResourcesTableContainer,
                label: 'Resource Library',
                selected: {
                  primaryButton: {
                    text: 'Assign Resource',
                    handleClick: assignResourceToPatient
                  },
                  selectedContents: selectedResources
                },
                style: {
                  background: true,
                  spacing: { pb: 0, pt: 3, px: 4 }
                },
                title: 'All Resources',
                handleSearch: handleSearch,
                onTabChange: onResourcesTabChange,
              }
            ]}
          />
        </Box>
      </Paper>
    }
      {openPatient && <Patients handelClose={assignResourceToPatientClose} />}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  topBox: {
    borderRadius: '0.625rem 0.625rem 0 0'
  },
  bottomBox: {
    borderRadius: '0 0 0.625rem 0.625rem'
  },
  primaryButton: {
    boxShadow:
      '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)'
  }
}));
