import MuiAlert from '@material-ui/lab/Alert';
import React, { useState } from 'react';
import { RootState } from '../redux/redux-store';
import { Snackbar } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { snackbarAlertAction } from '../redux/display/display-actions';
import { useDispatch, useSelector } from 'react-redux';

export const Alert = () => {
  const [open, setOpen] = useState<boolean>(false);

  const dispatch = useDispatch();

  const classes = useStyles();

  const message = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.snackbarAlertMessage
  );
  const severity = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.snackbarAlertSeverity
  );
  const autoHideDuration = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.snackbarAutoHideDuration
  );

  const clearMessage = () => {
    dispatch(snackbarAlertAction('', 'info', null));
  };
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setTimeout(() => clearMessage(), 200);

    setOpen(false);
  };

  React.useEffect(() => {
    if (message && message.length > 0) {
      setOpen(true);
    }
  }, [message]);

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={autoHideDuration || 3000}
        onClose={handleClose}
        open={open}
      >
        <MuiAlert elevation={6} onClose={handleClose} severity={severity} variant="filled">
          {message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    'width': '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));
