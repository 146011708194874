import { Box, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardHeader } from '../../../../components/LegacyCardHeader';
import { RootState } from '../../../../redux/redux-store';
import { GetReferralsProps, getReferralsThunk } from '../../../../redux/thunk/referral-thunks';
import { ReferralsTable } from '../../../Referrals/components/ReferralsTable';

export const PatientReferrals = () => {
  const dispatch = useDispatch();

  const referrals = useSelector(
    (state: RootState) => state.DATA_REDUCER.referralsSearchResults.referrals
  );

  const handleSearch = (value: string) => {
    if (referrals) {
      dispatch(
        getReferralsThunk({
          order: referrals.order,
          page: referrals.page,
          pageSize: referrals.pageSize,
          search: value,
          sort: referrals.sort
        })
      );
    }
  };

  // We need async/await over here as it is used for some state loading task
  const loadReferrals = async (getReferralsProps: GetReferralsProps) => {
    await dispatch(getReferralsThunk(getReferralsProps));
  };

  useEffect(() => {
    dispatch(getReferralsThunk({}));
  }, []);

  const handleDeleteReferral = () => { }

  const ReferralsTableContainer = (
    <Box pb={4} pt={1} px={4}>
      <ReferralsTable
        referralsData={referrals}
        loadReferrals={loadReferrals}
        isReferralLibrary={true}
        filtersList={null}
        handleDeleteReferral={() => handleDeleteReferral}
        viewReferralOpen={() => handleDeleteReferral}
        viewReferralDialogueOpen={false}
      />
    </Box>
  );

  return (
    <Paper>
      <Box display="flex" flexDirection="column">
        <CardHeader
          tabComponents={[
            {
              component: ReferralsTableContainer,
              label: 'Referrals',
              style: {
                background: true,
                spacing: { py: 3, px: 4 }
              },
              title: 'Referrals',
              handleSearch: handleSearch
            }
          ]}
        />
      </Box>
    </Paper>
  );
};
