import { FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface SingleChoiceFieldBlockProps {
  fieldTitle: any;
  options: { option_text: string | null }[];
}

export const SingleChoiceFieldBlock: React.FC<SingleChoiceFieldBlockProps> = ({
  fieldTitle,
  options
}) => {
  const classes = useStyles();
  return (
    <>
      {fieldTitle && fieldTitle.map((queName: any) => {
        return <Typography variant="subtitle2"><b>{queName ? queName : ''}</b></Typography>
      })}
      <RadioGroup>
        {options && options.length > 0 && options.map((option, i) => (
          <FormControlLabel
            key={i}
            classes={{ root: classes.root }}
            control={<Radio className={classes.radio} />}
            label={option.option_text}
            value={option.option_text}
          />
        ))}
      </RadioGroup>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  selectRoot: {
    padding: '.6rem'
  },
  root: {
    height: '2rem'
  },
  radio: {
    color: theme.palette.grey[500]
  }
}));
