import React, { useEffect, useState } from 'react';
import { Box, Paper, DialogActions, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AddToCollectionDialog } from '../../../Forms-Collections/components/AddToCollectionDialog';
import { CardHeader } from '../../../../components/LegacyCardHeader';
import { UuidType } from '../../../../shared/domain';
import { LoadingButton } from '../../../../components/LoadingButton';
import { Dialog } from '../../../../components/Dialog';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { DuplicateCustomFormDialog } from '../../../Forms-Collections/components/DuplicateCustomFormDialog';
import { FlowproThunkDispatch, RootState } from '../../../../redux/redux-store';
import { FormTable } from './FormTable';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  GetFormsProps, duplicateFormsThunk, removeFevFormCollectionThunk,
  assignCollectionToFormsThunk,
  getFormsCollectionThunk,
  unassignCollectionFromFormsThunk,
  updateFormsThunk,
  updateCollectionsThunk,
  deleteFormThunk,
  getDomainsThunk,
  getPatientInfoThunk
} from '../../../../redux/thunk/thunks';
import { NewFormDialog } from '../../../Forms-Collections/components/NewFormDialog';
import { TabBar, TabComponent } from '../../../../components/TabBar';
import { setSuccessAction } from '../../../../redux/display/display-actions';
import { assignmentInfoAction, getFormsCollectionsAction } from '../../../../redux/data/data-actions';
import { AssignForms } from '../../../Forms-Collections/AssignPatient/AssignForms';

export const Forms = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const customFormsData: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.getFormsCollections
  );
  const collectionsData: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.collectionSearchResults.libraryForms
  );
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);
  const { path } = useRouteMatch();
  const split = path.split('/');
  const patient_role_id: any = split && split.length > 1 && split[2];
  const shared_role_id: any = split && split.length > 3 && split[4];
  const [addDuplicate, setAddDuplicateOpen] = useState(false);
  const [searchPattern, setSearchPattern] = useState('');
  const [selectedCustomForms, setSelectedCustomForms] = useState<UuidType[]>([]);
  const [selectedLibraryForms, setSelectedLibraryForms] = useState<UuidType[]>([]);
  const [tab, setTab] = useState(0);
  const [newFormDialogOpen, setNewFormDialogOpen] = useState(false);
  const [addToCollectionDialogOpen, setAddToCollectionDialogOpen] = useState(false);
  const [addToLibraryDialogOpen, setAddToLibraryDialogOpen] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [isBaseDelete, setIsBaseDelete] = useState(false);
  const [deleteFormsConfirmationDialogOpen, setDeleteFormsConfirmationDialogOpen] = useState(false);
  const [duplicateCustomFormDialogOpen, setDuplicateCustomFormDialogOpen] = useState(false);
  const [duplicateLibraryFormDialogOpen, setDuplicateLibraryFormDialogOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openPatient, setOpenPatient] = useState(false);
  const [info, setFormInfo] = useState<any>(null);

  const success: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.getForms);
  const user: any = useSelector((state: RootState) => state.DATA_REDUCER.patientInfo);

  useEffect(() => {
    if (success) {
      dispatch(setSuccessAction('getForms', false));
      dispatch(getFormsCollectionThunk({
        collectionId: customFormsData?.collectionId,
        page: customFormsData?.page,
        pageSize: customFormsData?.pageSize,
        search: customFormsData?.search,
        favourites: customFormsData?.favourites,
        order: customFormsData?.order,
        sort: customFormsData?.sort
      }));
    }
  })

  const handleNewFormDialogClose = () => {
    setNewFormDialogOpen(false);
  };
  const handleAddToCollectionDialogClose = () => {
    setAddToCollectionDialogOpen(false);
  };
  const handleDeleteFormsConfirmationDialogClose = () => {
    setDeleteFormsConfirmationDialogOpen(false);
  };
  const handleDisplayDuplicateCustomFormDialogOpen = () => {
    setNewFormDialogOpen(false);
    setDuplicateCustomFormDialogOpen(true);
  };
  const handleDisplayDuplicateCustomFormDialogClose = () => {
    setDuplicateCustomFormDialogOpen(false);
  };
  const handleDisplayDuplicateLibraryFormDialogOpen = () => {
    setNewFormDialogOpen(false);
    setDuplicateLibraryFormDialogOpen(true);
  };
  const handleDisplayDuplicateLibraryFormDialogClose = () => {
    setDuplicateLibraryFormDialogOpen(false);
  };

  const handleCustomSearch = (value: string) => {
    setSearchPattern(value);

    if (customFormsData) {
      dispatch(
        getFormsCollectionThunk({
          collectionId: customFormsData?.collectionId,
          order: customFormsData?.order,
          pageSize: customFormsData?.pageSize,
          search: value,
          favourites: customFormsData?.favourites,
          sort: customFormsData?.sort
        })
      );
    }
  };

  const handleAssignCollections = async (
    selectedCollections: UuidType[],
    selectedForms: UuidType[]
  ) => {
    const dispatchPromises: ((
      dispatch: FlowproThunkDispatch,
      getState: () => RootState
    ) => Promise<void>)[] = [];

    selectedCollections.forEach((collectionId: UuidType) => {
      dispatchPromises.push(dispatch(assignCollectionToFormsThunk(collectionId, selectedForms)));
    });

    await Promise.all(dispatchPromises);

    dispatch(updateCollectionsThunk());
    dispatch(updateFormsThunk());

    setAddToCollectionDialogOpen(false);
    setAddToLibraryDialogOpen(false);
  };
  const handleUnassignCollection = async (collectionId: UuidType, formId: UuidType) => {
    await dispatch(unassignCollectionFromFormsThunk(collectionId, [formId]));
  };

  const handleDeleteForms = async (library?: boolean) => {
    setDeleteLoading(true);
    const selectedForms = library ? selectedLibraryForms : selectedCustomForms;
    const deleteForm = {
      form_ids: selectedForms
    }
    await dispatch(deleteFormThunk(deleteForm));
    dispatch(getFormsCollectionThunk({
      collectionId: customFormsData?.collectionId,
      page: customFormsData?.page,
      pageSize: customFormsData?.pageSize,
      search: customFormsData?.search,
      favourites: customFormsData?.favourites,
      order: customFormsData?.order,
      sort: customFormsData?.sort
    }));
    setSelectedCustomForms([]);
    setSelectedLibraryForms([]);
    setDeleteLoading(false);
    setDeleteFormsConfirmationDialogOpen(false);
  };

  const AddDuplicate = async () => {
    setDeleteLoading(true);
    await dispatch(duplicateFormsThunk(selectedCustomForms));
    dispatch(getFormsCollectionThunk({
      collectionId: customFormsData?.collectionId,
      page: customFormsData?.page,
      pageSize: customFormsData?.pageSize,
      search: customFormsData?.search,
      favourites: customFormsData?.favourites,
      order: customFormsData?.order,
      sort: customFormsData?.sort
    }));
    setSelectedCustomForms([]);
    setSelectedLibraryForms([]);
    setDeleteLoading(false);
    setAddDuplicateOpen(false);
  }

  const handleCustomTabChange = (id: string) => {
    dispatch(
      getFormsCollectionThunk({
        collectionId: id === 'ALL_COLLECTIONS' ? '' : id,
        allForms: id === 'ALL_COLLECTIONS',
        library: true,
        search: searchPattern,
        favourites: customFormsData?.favourites,
      })
    );
    setSelectedCustomForms([]);
  };

  const loadForms = async (getFormsProps: GetFormsProps) => {
    await dispatch(
      getFormsCollectionThunk({
        ...getFormsProps,
        allForms: getFormsProps.library && getFormsProps.collectionId === ''
      })
    );
  };

  const handleAddToOrgClose = () => {
    setAddDuplicateOpen(false);
  };

  const assignFormToPatient = async () => {
    setOpenPatient(true);
    const arr: any = [];
    for (let i = 0; i < selectedCustomForms.length; i++) {
      const check = customFormsData && customFormsData?.data.filter((item: any) => item.id === selectedCustomForms[i]);
      check && check.length > 0 && arr.push(check[0]);
    }
    setFormInfo(arr);
    dispatch(assignmentInfoAction(arr));
  }
  const assignFormToPatientClose = () => {
    setOpenPatient(false);
    setSelectedCustomForms([]);
  }

  const removeFevForm = async () => {
    const arr: any = [];
    for (let i = 0; i < selectedCustomForms.length; i++) {
      const check = customFormsData && customFormsData?.data.filter((item: any) => item.id === selectedCustomForms[i]);
      if (check && check.length > 0) {
        const info = {
          "item_type": check[0].item_type,
          "id": selectedCustomForms[i]
        }
        arr.push(info);
      }
    }
    const removeForm = {
      obj_array: arr
    }
    await dispatch(removeFevFormCollectionThunk(removeForm));
    setSelectedCustomForms([]);
  }

  useEffect(() => {
    !user && dispatch(getPatientInfoThunk(patient_role_id));
    dispatch(getDomainsThunk({ collectionsType: 'libraryForms' }));

    return function CleanUp() {
      dispatch(getFormsCollectionsAction(null));
      setTab(0);
      sessionStorage.removeItem('tab');
    };
  }, []);

  const onFavoritesTabChange = async () => {
    if (tab === 0) {
      setTab(1); selectedCustomForms.length > 0 && setSelectedCustomForms([]);
      await dispatch(getFormsCollectionThunk({ search: searchPattern, favourites: true }));
    }
  }

  const onFormTabChange = async () => {
    if (tab === 1) {
      setTab(0); selectedCustomForms.length > 0 && setSelectedCustomForms([]);
      await dispatch(getFormsCollectionThunk({ search: searchPattern, favourites: false }));
    }
  }

  const CustomForms = (
    <>
      {addToCollectionDialogOpen && customFormsData && (
        <AddToCollectionDialog
          open={addToCollectionDialogOpen}
          selectedForms={selectedCustomForms}
          handleAssignCollections={handleAssignCollections}
          handleClose={handleAddToCollectionDialogClose}
        />
      )}

      {deleteFormsConfirmationDialogOpen && customFormsData && selectedCustomForms.length > 0 && (
        <ConfirmationDialog
          loading={deleteLoading}
          message='Are you sure you want to delete? Please type "DELETE" to confirm..'
          open={deleteFormsConfirmationDialogOpen}
          title=""
          handleClose={handleDeleteFormsConfirmationDialogClose}
          handleConfirmClick={() => handleDeleteForms(false)}
        />
      )}

      <Box pb={4} pt={1} px={4}>
        <FormTable
          formsData={customFormsData}
          formsType="custom"
          selectedForms={selectedCustomForms}
          loadForms={loadForms}
          setSelectedForms={(selectedForms: UuidType[]) => setSelectedCustomForms(selectedForms)}
          unassignCollection={handleUnassignCollection}
        />
      </Box>
    </>
  );
  const customTabComponents: TabComponent[] = [
    {
      component: CustomForms,
      id: 'ALL_COLLECTIONS',
      label: 'All',
      style: {
        background: true,
        spacing: { px: 4, py: 0 }
      }
    },

  ].concat(
    collectionsData
      ? collectionsData.collections.map((collection: any) => ({
        component: CustomForms,
        id: collection.id,
        label: collection.title,
        style: {
          background: true,
          spacing: { px: 4, py: 0 },
        }
      }))
      : []
  );
  const customFormTabs = (
    <TabBar
      style={{
        tabBackground: true,
        spacing: { px: 4, py: 0 },
        maxWidth: '100%'
      }}
      tabComponents={customTabComponents}
      onTabChange={handleCustomTabChange}
    />
  );

  return (
    <>
      {newFormDialogOpen && (
        <NewFormDialog
          open={newFormDialogOpen}
          handleClose={handleNewFormDialogClose}
          handleDisplayDuplicateCustomFormDialog={handleDisplayDuplicateCustomFormDialogOpen}
          handleDisplayDuplicateLibraryFormDialog={handleDisplayDuplicateLibraryFormDialogOpen}
        />
      )}

      {duplicateCustomFormDialogOpen && (
        <DuplicateCustomFormDialog
          open={duplicateCustomFormDialogOpen}
          handleClose={handleDisplayDuplicateCustomFormDialogClose}
        />
      )}

      {duplicateLibraryFormDialogOpen && (
        <DuplicateCustomFormDialog
          library={true}
          open={duplicateLibraryFormDialogOpen}
          handleClose={handleDisplayDuplicateLibraryFormDialogClose}
        />
      )}

      <Paper>
        <Box display="flex" flexDirection="column">
          <CardHeader
            tabComponents={[
              {
                component: customFormTabs,
                label: 'Favorites',
                selected: {
                  primaryButton: {
                    text: 'Remove',
                    handleClick: removeFevForm
                  },
                  selectedContents: selectedCustomForms,
                },
                style: {
                  background: true,
                  spacing: { pb: 0, pt: 3, px: 4 }
                },
                title: 'Favorites Forms',
                handleSearch: handleCustomSearch,
                onTabChange: onFavoritesTabChange
              },
              {
                component: customFormTabs,
                label: 'Forms & Collections',
                selected: {
                  primaryButton: {
                    text: 'Assign to Patient',
                    handleClick: assignFormToPatient
                  },
                  selectedContents: selectedCustomForms,
                },
                style: {
                  background: true,
                  spacing: { pb: 0, pt: 3, px: 4 }
                },
                title: 'Forms',
                handleSearch: handleCustomSearch,
                onTabChange: onFormTabChange
              },
            ]} />
        </Box>
      </Paper>
      {addDuplicate &&
        <Dialog open={addDuplicate}>
          <Box style={{ width: '400px' }}>
            <Box pb={3} pt={2} px={4} display="flex" justifyContent="space-between">
              <Typography variant="h3">Are you sure you want to add duplicate forms?</Typography>
            </Box>
          </Box>
          <DialogActions style={{ justifyContent: 'space-around' }}>
            <LoadingButton style={{ border: '1px solid rgba(0, 0, 0, 0.15)', width: '120px', marginBottom: '10px' }}
              variant="contained"
              onClick={handleAddToOrgClose}
            >
              No
            </LoadingButton>
            <LoadingButton color="primary" disabled={deleteLoading}
              variant="contained"
              style={{ marginBottom: '10px', boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)', width: '120px' }}
              onClick={(e: any) => AddDuplicate()}
            >
              Yes
            </LoadingButton>
          </DialogActions>
        </Dialog>
      }
      {isCustom &&
        <Dialog open={isCustom}>
          <Box style={{ width: '400px' }}>
            <Box pb={3} pt={2} px={4} display="flex" justifyContent="center">
              <Typography variant="h3">Custom form already exists in the organization, it cannot be added again</Typography>
            </Box>
          </Box>
          <DialogActions style={{ justifyContent: 'center' }}>
            <LoadingButton style={{ border: '1px solid rgba(0, 0, 0, 0.15)', width: '120px', marginBottom: '10px' }}
              variant="contained"
              onClick={() => setIsCustom(false)}
            >
              Close
            </LoadingButton>
          </DialogActions>
        </Dialog>
      }
      {isBaseDelete &&
        <Dialog open={isBaseDelete}>
          <Box style={{ width: '400px', textAlign: 'center' }}>
            <Box pb={3} pt={2} px={4} display="flex">
              <Typography variant="h3">You have selected base forms, it cannot be deleted</Typography>
            </Box>
          </Box>
          <DialogActions style={{ justifyContent: 'center' }}>
            <LoadingButton style={{ border: '1px solid rgba(0, 0, 0, 0.15)', width: '120px', marginBottom: '10px' }}
              variant="contained"
              onClick={() => setIsBaseDelete(false)}
            >
              Close
            </LoadingButton>
          </DialogActions>
        </Dialog>
      }
      {openPatient && <AssignForms openDialog={openPatient} handelClose={assignFormToPatientClose} />}
    </>
  );
};
