import { Box, makeStyles, TextField, TextFieldProps, Typography } from '@material-ui/core';
import { Field } from 'formik';
import React from 'react';

interface FieldAndTitleProps {
  title?: string;
  hintText?: string;
  noValidation?: boolean;
  format?: string;
}
export const TitledTextInput = (props: TextFieldProps & FieldAndTitleProps) => {
  const { noValidation, title, hintText, ...textFieldProps } = props;
  const classes = useStyles();
  return (
    <Box>
      {title && (
        <Typography color={'textSecondary'} variant="subtitle2" className={classes.fieldTitle}>
          {title}
        </Typography>
      )}
      {noValidation ? (
        <TextField {...textFieldProps} />
      ) : (
        <Field as={TextField} {...textFieldProps} />
      )}
      {hintText && (
        <Typography color={'textSecondary'} variant="subtitle2" className={classes.hintText}>
          {hintText}
        </Typography>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  hintText: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.hint
  },
  fieldTitle: {
    marginBottom: theme.spacing(1)
  }
}));
