import { Box, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { RolesUsersTable } from './RoleUsersTable';
import {
  deleteUserThunk,
  GetNonPatientsProps,
  getRolesThunk,
  updateUserRoleThunk
} from '../../../../../redux/thunk/thunks';
import { LoadingButton } from '../../../../../components/LoadingButton';
import { ROLES } from '../../../../../shared/globals';
import { RootState } from '../../../../../redux/redux-store';
import { SearchBar } from '../../../../../components/SearchBar';
import { User, UuidType } from '../../../../../shared/domain';
import { useDispatch, useSelector } from 'react-redux';
import { setSuccessAction } from '../../../../../redux/display/display-actions';

export const RoleUsers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const logedUser: any = localStorage.getItem('formFlash');
  const userRoleId = JSON.parse(logedUser);

  const roles: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.roles
  );

  const loadUsers = async (userRoleProps: any) => {
    await dispatch(getRolesThunk({ user_role_id: userRoleId.id, page: userRoleProps.page, pageSize: userRoleProps.pageSize, sort: userRoleProps.sort, order: userRoleProps.order }));
  };

  useEffect(() => {
    dispatch(getRolesThunk({ user_role_id: userRoleId.id, page: 0, pageSize: 10, sort: 'role', order: 'DESC' }));
  }, []);

  return (
    <>
      <Paper>
        <Box
          className={classes.topBox}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          py={2.5}
        >
          <Box alignItems="center" display="flex" justifyContent="space-between" px={4}>
            <Typography variant="h2">Roles</Typography>

            <Box alignItems="center" display="flex">
              {/* <Box px={2}>
                <SearchBar handleSearch={handleSearch} />
              </Box>

              <LoadingButton
                className={classes.primaryButton}
                color="primary"
                onClick={handleInviteUserButton}
                variant="contained"
                aria-label="Invite New User"
              >
                Invite New User
              </LoadingButton> */}
            </Box>
          </Box>
        </Box>

        <Box pb={4} pt={1} px={4}>
          <RolesUsersTable
            usersData={roles}
            loadUsers={loadUsers}
          />
        </Box>
      </Paper>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  topBox: {
    backgroundColor: theme.palette.secondary.light,
    borderBottom: '0.0625rem solid #D1D6DC',
    borderRadius: '0.625rem 0.625rem 0 0'
  },
  primaryButton: {
    boxShadow:
      '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)'
  }
}));
