import {
  ALLOWED_OPERATORS,
  CALCULATION_OPERATORS,
  FIELD_TYPES,
  LOGIC_OPERATOR_TYPE
} from '../../../shared/globals';

export const allowedLogicalOperators = (fieldType?: FIELD_TYPES) => {
  if (fieldType) {
    return ALLOWED_OPERATORS[fieldType];
  } else {
    return [];
  }
};

export const logicalOperatorText = (logicalOperator: LOGIC_OPERATOR_TYPE) => {
  switch (logicalOperator) {
    case LOGIC_OPERATOR_TYPE.EQUAL_TO:
      return 'is';
    case LOGIC_OPERATOR_TYPE.EXCLUDES:
      return "didn't select";
    case LOGIC_OPERATOR_TYPE.GREATER_THAN:
      return 'is greater than';
    case LOGIC_OPERATOR_TYPE.INCLUDES:
      return 'selected';
    case LOGIC_OPERATOR_TYPE.LESS_THAN:
      return 'is less than';
    case LOGIC_OPERATOR_TYPE.NOT_EQUAL_TO:
      return 'is not';
    default:
      break;
  }
};

export const calculationOperatorText = (calcOperator: CALCULATION_OPERATORS) => {
  switch (calcOperator) {
    case CALCULATION_OPERATORS.ADD:
      return 'add';
    case CALCULATION_OPERATORS.SUBTRACT:
      return 'subtract';
    default:
      break;
  }
};
