import React from 'react';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { colors } from '../styling/colors';

interface LoadingButtonProps {
  children?: React.ReactNode;
  spinnerColor?: string;
  loading?: boolean;
}

export const LoadingButton = (props: ButtonProps & LoadingButtonProps) => {
  const { children, loading, spinnerColor, ...buttonProps } = props;

  const classes = useStyles();

  return (
    <Button {...buttonProps}>
      {children}

      {loading && (
        <CircularProgress
          className={classes.loadingCircle}
          size={16}
          style={{ color: spinnerColor ?? colors.background3 }}
        />
      )}
    </Button>
  );
};

const useStyles = makeStyles({
  loadingCircle: {
    marginLeft: '0.5rem'
  }
});
