import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { GetReferralsProps } from '../../../redux/thunk/referral-thunks';

const useStyles = makeStyles({
  title: {
    paddingLeft: '0.5rem',
    fontSize: '0.9rem',
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: '0.00938em'
  },
  subTitle: {
    fontSize: '0.8rem',
    paddingLeft: '1.5rem',
    lineHeight: 1.7,
    letterSpacing: '0.00938em',
    color: '#6B7281',
    fontFamily: 'Inter,sans-serif',
    cursor: 'pointer'
  },
  active: {
    color: '#0060B2',
    fontWeight: 500
  }
});

interface ReferralsFilterProps {
  filtersList: any;
  loadReferrals: (getReferralsProps: GetReferralsProps) => void;
}

const ReferralsFilter = ({ filtersList, loadReferrals }: ReferralsFilterProps) => {
  const classes = useStyles();

  let deafultAppliedFilters: Array<string> = [];
  const [appliedFilters, setAppliedFilters] = useState(deafultAppliedFilters);

  const filterList = [
    {
      filterTitle: 'Insurance',
      filters: [
        'Aetna',
        'AllWays Health Partners',
        'Beacon',
        'Blue Cross',
        'Blue Shield',
        'BlueCross and BlueShield',
        'Cigna',
        'ComPsych',
        'Fallon Health',
        'Harvard Pilgrim',
        'Health Net',
        'Health New England (HNE)',
        'Humana',
        'Magellan',
        'Massachusetts Behavioral Health Partnership (MBHP)',
        'Network Health',
        'Optum',
        'TRICARE',
        'Tufts',
        'UniCare',
        'UnitedHealthcare',
        'Self-pay',
        'Out-of-network'
      ]
    },
    {
      filterTitle: 'Issues',
      filters: [
        'ADHD',
        'Addiction',
        'Anger Management',
        'Anxiety',
        'Bipolar Disorder',
        'Borderline Personality',
        'Child or Adolescent',
        'Depression',
        'Eating Disorders',
        'Family Conflict',
        'Grief',
        'Marital and Premarital',
        'Obsessive-Compulsive (OCD)',
        'Psychosis',
        'Relationship Issues',
        'Self Esteem',
        'Sex Therapy',
        'Sexual Abuse',
        'Stress',
        'Substance Use',
        'Suicidal ideation ',
        'Self-harming',
        'Transgender',
        'Trauma and PTSD'
      ]
    },
    {
      filterTitle: 'Sexuality',
      filters: ['Gay', 'Lesbian', 'Bisexual']
    },
    {
      filterTitle: 'Gender',
      filters: ['Show Me Women', 'Show Me Men']
    },
    {
      filterTitle: 'Age',
      filters: ['Adolescents / Teenagers (14 to 19)', 'Adults', 'Elders (65+)']
    },
    {
      filterTitle: 'Types of Therapy',
      filters: [
        'Acceptance and Commitment (ACT)',
        'Attachment-based',
        'Christian Counseling',
        'Cognitive Behavioral (CBT)',
        'Dialectical (DBT)',
        'EMDR',
        'Emotionally Focused',
        'Family / Marital',
        'Hypnotherapy',
        'Internal Family Systems (IFS)',
        'Mindfulness-Based (MBCT)',
        'Play Therapy',
        'Psychoanalytic',
        'Psychodynamic',
        'Somatic',
        'Trauma Focused'
      ]
    },
    {
      filterTitle: 'Specialty',
      filters: [
        'ADHD',
        'Addiction',
        'Anger Management',
        'Anxiety',
        'Bipolar Disorder',
        'Borderline Personality',
        'Child or Adolescent',
        'Depression',
        'Eating Disorders',
        'Family Conflict',
        'Grief',
        'Marital and Premarital',
        'Obsessive-Compulsive (OCD)',
        'Psychosis',
        'Relationship Issues',
        'Self Esteem',
        'Sex Therapy',
        'Sexual Abuse',
        'Stress',
        'Substance Use',
        'Suicidal ideation ',
        'Self-harming',
        'Transgender',
        'Trauma and PTSD'
      ]
    }
  ];

  const getFilter = async (filter: string) => {
    deafultAppliedFilters = [...appliedFilters];
    const index = deafultAppliedFilters.indexOf(filter);
    if (index === -1) {
      deafultAppliedFilters.push(filter);
    } else {
      if (filter === deafultAppliedFilters[index]) {
        deafultAppliedFilters.splice(index, 1);
      }
    }
    setAppliedFilters(deafultAppliedFilters);

    await loadReferrals({
      search: '',
      page: 0,
      pageSize: 0,
      sort: '',
      order: '',
    });
  };

  return (
    <>
      {filtersList && filtersList.length > 0 && filtersList.map((ele: any, index: number) => {
        return (
          <>
            <Typography
              key={`${ele.category}${index}`}
              className={classes.title}
              variant="body1"
            >
              {ele.category}
            </Typography>
            {ele.filters.map((filter: any, j: number) => {
              return (
                <Typography
                  key={`${filter.title}${j}`}
                  className={`${classes.subTitle} ${appliedFilters.indexOf(filter.id) !== -1 ? classes.active : ''
                    }`}
                  variant="body2"
                  onClick={() => getFilter(filter.id)}
                >
                  {filter.title}
                </Typography>
              );
            })}
          </>
        );
      })}
    </>
  );
};

export default ReferralsFilter;
