import { Box, Button, Divider, FormControl, MenuItem, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/redux-store';
import { createFieldLogicRuleThunk, updateFormFieldThunk } from '../../../../redux/thunk/thunks';
import { FormField, FormFieldUpdate, UuidType } from '../../../../shared/domain';
import { ALLOWED_OPERATORS } from '../../../../shared/globals';
import { LogicBlock } from './LogicBlock';
import { LogicUnavailable } from './LogicUnavailable';

const _fieldsThatAllowLogic = (formFields: FormField[], currentFormFieldId: UuidType) => {
  let fieldArr: FormField[] = [];
  if (formFields) {
    //filters all formfields that don't allow logic and have Id matching editingFormField
    fieldArr = formFields?.filter((field: FormField, i) => {
      const fieldTypeIsAllowed =
        ALLOWED_OPERATORS[field.field_type] && ALLOWED_OPERATORS[field.field_type].length > 0;
      if (fieldTypeIsAllowed) {
        return currentFormFieldId !== field.id;
      }
    });
  }
  return fieldArr;
};
const _showHideToBool = (logic_is_show?: null | boolean) => {
  if (typeof logic_is_show === 'boolean') {
    return logic_is_show ? 'show' : 'hide';
  }
};
const _anyAllToBool = (logic_is_all?: null | boolean) => {
  if (typeof logic_is_all === 'boolean') {
    return logic_is_all ? 'all' : 'any';
  }
};
export const LogicList: React.FC = () => {
  type LogicIsShow = 'show' | 'hide' | undefined;
  type LogicIsAll = 'any' | 'all' | undefined;

  const classes = useStyles();
  const dispatch = useDispatch();
  const getFieldLogicRulesLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.getFieldLogicRulesLoading
  );
  const editingForm = useSelector((state: RootState) => state.DATA_REDUCER.editingForm);
  const editingFormFields = useSelector((state: RootState) => state.DATA_REDUCER.editingFormFields);
  const editingFormField = useSelector((state: RootState) => state.DATA_REDUCER.editingFormField);
  const editingFieldLogicRules = useSelector(
    (state: RootState) => state.DATA_REDUCER.editingFieldLogicRules
  );
  const [showHide, setShowHide] = useState<LogicIsShow>(
    _showHideToBool(editingFormField?.logic_is_show)
  );
  const [anyAll, setAnyAll] = useState<LogicIsAll>(_anyAllToBool(editingFormField?.logic_is_all));

  const checkandUpdateLogicSettings = (logicIsShow: LogicIsShow, logicIsAll: LogicIsAll) => {
    //if anyAll or showHide change AND they are different than what is already on the field
    //update the field
    if (editingForm && editingFormField && showHide && anyAll) {
      const fieldUpdate: FormFieldUpdate = {
        id: editingFormField.id,
        logic_is_show: logicIsShow === 'show' ? true : false,
        logic_is_all: logicIsAll === 'all' ? true : false
      };
      dispatch(updateFormFieldThunk(fieldUpdate, editingForm.id));
    }
    setShowHide(logicIsShow);
    setAnyAll(logicIsAll);
  };

  if (!editingFormFields || !editingFormField) {
    return null;
  }

  const addNewLogicRule = () => {
    if (editingFormField) {
      const allowedFields = _fieldsThatAllowLogic(editingFormFields, editingFormField.id);
      dispatch(createFieldLogicRuleThunk(editingFormField.id, allowedFields[0].id));
    }
  };

  const changeShowHide = (
    event: React.ChangeEvent<{
      value: unknown;
    }>
  ) => {
    checkandUpdateLogicSettings(event.target.value as LogicIsShow, anyAll);
  };
  const changeAnyAll = (
    event: React.ChangeEvent<{
      value: unknown;
    }>
  ) => {
    checkandUpdateLogicSettings(showHide, event.target.value as LogicIsAll);
  };

  if (_fieldsThatAllowLogic(editingFormFields, editingFormField.id).length > 0) {
    return (
      <>
        <Box className={classes.header}>
          {editingFieldLogicRules?.length === 0 ? (
            <Typography variant={'subtitle2'} color={'textSecondary'}>
              You haven’t added any Logic rules to this field yet.
            </Typography>
          ) : (
            <Box>
              <Box className={classes.topOptions}>
                <FormControl>
                  <Select
                    value={showHide}
                    onChange={changeShowHide}
                    IconComponent={UnfoldMoreIcon}
                    className={classes.option}
                  >
                    <MenuItem value={undefined} disabled>
                      Select An Option
                    </MenuItem>
                    <MenuItem value="show">Show</MenuItem>
                    <MenuItem value="hide">Hide</MenuItem>
                  </Select>
                </FormControl>
                <Box mx={1}>
                  <Typography
                    className={classes.fieldInstruction}
                    variant={'subtitle2'}
                    color={'textSecondary'}
                  >
                    this field when
                  </Typography>
                </Box>
                <FormControl>
                  <Select
                    value={anyAll}
                    onChange={changeAnyAll}
                    IconComponent={UnfoldMoreIcon}
                    className={classes.option}
                  >
                    <MenuItem value={undefined} disabled>
                      Select An Option
                    </MenuItem>
                    <MenuItem value="any">Any</MenuItem>
                    <MenuItem value="all">All</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <Typography variant={'subtitle2'} color={'textSecondary'}>
                  of the following rules match:
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        <Box>
          {getFieldLogicRulesLoading
            ? 'loading...'
            : editingFieldLogicRules?.map((logicRule, i) => (
                <Box key={i}>
                  {i > 0 ? <Divider className={classes.blockDivider} /> : null}
                  <LogicBlock
                    ruleNumber={i}
                    logicRule={logicRule}
                    fieldsThatAllowLogic={_fieldsThatAllowLogic(
                      editingFormFields,
                      editingFormField.id
                    )}
                  />
                </Box>
              ))}
        </Box>
        <Box mt={2}>
          <Button
            onClick={addNewLogicRule}
            variant="contained"
            fullWidth
            color={'primary'}
            aria-label="add logic field"
          >
            Add Logic Rule
          </Button>
        </Box>
      </>
    );
  } else {
    return <LogicUnavailable />;
  }
};

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '.6rem'
  },
  icon: {
    marginRight: '.6rem'
  },
  topOptions: {
    display: 'flex',
    alignItems: 'center'
  },
  option: {
    maxHeight: '1.5rem',
    border: 'none'
  },
  fieldInstruction: {
    whiteSpace: 'nowrap'
  },
  blockDivider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  }
}));
