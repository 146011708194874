import { FormInstanceResponses, FormResponseChangeLogs } from './../../shared/domain.d';
import { FlowproThunkDispatch, RootState } from '../redux-store';
import {
  PatientFormInstancesType,
  getPatientFormInstancesAction,
  setDisplayExitPageAction,
  setDisplayFormFieldPayloadAction,
  setDisplayFormInstanceAction,
  setFormResponsesAction,
  setFormRespChangeLogsAction,
  setDisplayFormAction
} from '../data/data-actions';
import { push } from 'connected-react-router';
import {
  FormFieldResponseInput,
  FormInstance,
  FormInstanceSearchResults,
  UuidType
} from '../../shared/domain';
import { deleteHttp, getHttp, postHttp, putHttp, downloadFileHttp } from '../api';
import {
  sendReminderFormInstanceIdAction,
  setErrorAction,
  setLoadingAction,
  setSuccessAction,
  snackbarAlertAction
} from '../display/display-actions';

export interface GetFormResponsesProps {
  formInstanceId: UuidType;
}
var safety = [
  {
    id: "f7666c64-aaea-49a7-b196-9c1d5a9e4510",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "User selected intro video?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null,
      },
    ],
  },
  {
    id: "c84c08a8-db67-4a08-ace4-bb8dc38ea2b0",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "Welcome! A safety plan can help the patient to identify the warning signs of a suicidal crisis and work through steps to manage that crisis.",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null,
      },
    ],
  },
  {
    id: "cbfe9715-9b66-48d7-8b6a-000f96bc00e7",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "Before starting the safety plan, the clinician should obtain an accurate account of the events that transpired before, during, and after the most recent suicidal crisis. This allows the patient to tell their story, builds a therapeutic alliance, and identifies warning signs that will be relevant to the safety plan.",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null,
      },
    ],
  },
  {
    id: "73aac3b3-a9fe-4d1e-97c5-75c0163d95e4",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "The safety plan itself should be as personalized, specific, and complete as possible. This form will allow you to document each safety plan step in collaboration with the patient. ",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null,
      },
    ],
  },
  {
    id: "b39f711a-9a97-46c8-af54-86db9e1ebeef",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "When you save it, the safety plan will be pushed to the patient's mobile app so they can access their safety plan easily wherever they are. The first step is to identify the patient's warning signs.",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "b5bf2e9e-bb8f-4371-854a-a15a9545abbe",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "These warning signs can include personal situations, thoughts, images, thinking styles, mood, or behavior. One of the most effective ways of averting a suicidal crisis is to address the problem before it emerges fully. \n\nIn order to do this, patients should be helped to identify and, just as importantly, to pay attention to their warning signs. The clinician may ask: “How will you know when the safety plan should be used?” Alternatively, patients may be asked to identify what they experience when they start to think about suicide or encounter extreme distress. ",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "733ab7d1-5ff7-494f-9519-a8c5d65497fc",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "The specific warning signs will vary from patient to patient and may include one or more of the following domains:  thoughts, images, thinking processes, mood, or behavior. These warning signs are then listed on the safety plan using the patient’s own words.",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "a834bcae-9af4-4ba6-a98d-655bc55bd2d9",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "Ready to start?",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "4e7cda51-e488-4618-9867-a16e40d6fe94",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "What is the first warning sign?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "6d36d844-58f8-482f-b402-f4dbb2885294",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "What is the second warning sign?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "8fad831a-cf1a-4d96-ac92-f61318e80627",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "What is the third warning sign?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "8f2a2ffd-6029-4bd3-a59b-9492031f90b0",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "For coping strategies, patients are asked to list some activities that they could employ without needing to contact other people. Such activities function as a way to help patients take their minds off their problems and prevent suicide ideation from escalating. The patient should be an active participant in generating these strategies. ",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "74723f19-2a74-4f67-bf4c-0e71a6f1298c",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "The clinician should address (a) the identification of coping strategies, (b) the likelihood of using such strategies, and (c) the identification of barriers and problem solving. These activities should be as specific as possible, e.g. \"taking a walk in Elm Park\" as opposed to \"taking a walk\" ",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "7e34e0d1-7538-4814-8ccf-492dd26dc9c8",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "What is the first coping strategy?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "2e337956-4fff-4006-bc0c-da3b50854efa",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "What is the second coping strategy?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "cfe25035-edfd-4bf8-b6f8-1b420836ef6c",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "What is the third coping strategy?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "03ffe5e2-fc07-4872-bdd0-56791bbb11c9",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "Next, patients should identify key social settings and people in their natural social environment who may help take them outside themselves and distract them from their suicidal thoughts and urges.  \n\nThis step is not for reaching out to others for specific help with the suicidal crisis. Socializing with friends or family members without explicitly informing them of their suicidal state may assist in distracting patients from their problems.",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "827da1c4-fce3-490c-bbd0-62afb5108e7c",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "What is the name of the first person for distraction? ",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "de5968c5-9a09-43bc-a59c-9015d9b17ec4",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "What is the number of first person for distraction? (If not known, leave blank for now)",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "b4bc5a7b-fe4c-4abb-b3b6-c43ad7c977eb",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "What is the name of second person for distraction?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "db163cf0-8bc5-4c09-84ed-ec5e7c87ecc0",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "What is the number of second person for distraction?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "00e02e3a-eb17-465b-968c-41ae7bb7c5fd",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "Next, ask the patient about some healthy social settings in which socialization/distraction occurs naturally. Examples could include a certain coffee shop, place of worship, or the movie theater.",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "78c717e7-f7a1-4892-ba4d-f4f988c3f5cd",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "What is the name of first place for distraction?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "621973b2-5e8d-4c03-92fc-4526cde2e7ff",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "What is the address of first place for distraction? (If not known, leave blank for now)",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "5331e16c-fde7-4a25-9fb7-8880908be82e",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "What is the name of second place for distraction?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "4d284340-6e86-4bda-851a-b82af0862682",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "What is the address of second place for distraction? (If not known, leave blank for now)",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "8e8cfc7c-0808-4ad8-b480-ba8c5f3c8566",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "Great, you're halfway there!",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "1d504c60-2011-459d-9ca2-c07541138954",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "If the internal coping strategies or social contacts for purposes of distraction and connection offer little benefit to alleviating the crisis, patients may choose to inform family members or friends that they are experiencing a suicidal crisis. \n\nThis step is distinguished from the previous step in that patients explicit identify that they are in crisis and need support and help. The clinician may ask: “Among your family or friends, who do you think you could contact for help during a crisis?” or “Who is supportive of you and who do you feel that you can talk with when you’re under stress?",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "52bc51af-907d-4132-8bc9-2c2f1d246def",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "What is the name of the first person who can help?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "45678e4f-fcad-4460-a893-b5ff5b7ecd8b",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "What is the number of the first person who can help?  (If not known, leave blank for now)",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "f6f78905-69c7-45c5-8d00-0a7e2521ac9b",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "What is the name of the second person who can help?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "fa6d9bb9-b013-4961-93c4-54c047055709",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "What is the number of the second person who can help?  (If not known, leave blank for now)",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "fff9f0a7-a1fb-4cdc-a9aa-32641db92502",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "What is the name of the third person who can help?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "f636af55-678f-4795-9f1f-bdec9570d9ff",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "What is the number of the third person who can help?  (If not known, leave blank for now)",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "6ce7457d-c080-444e-a7ca-0c0c833ca35b",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "The fifth step consists of professionals or other clinicians who could assist patients in a time of crisis and the corresponding telephone numbers and/or locations. Patients are instructed to contact a professional or agency if the previous strategies have not been effective for resolving the crisis. \n\nIf the patient already has a provider, include that person. However, the safety plan should also include other professionals who may be reached especially during non-business hours. The safety plan emphasizes that appropriate professional help is accessible in a crisis and, when necessary, indicates how these services may be obtained.",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "ac5d1d88-eb34-48df-b4cc-212f5a772be2",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "What is the name of the first professional who can help?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "f98cb7f3-7bb9-47a6-82a1-4fb28d7ad1e2",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "What is the number of the first professional who can help?  (If not known, leave blank for now)",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "72505601-9129-47cb-8ae8-40820ce484dd",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "What is the name of the second professional who can help?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "61f67c53-d5dd-4969-b571-68420f7e667a",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "What is the number of the second professional who can help?  (If not known, leave blank for now)",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "779d1306-cb94-40ac-9f2e-62bfbe892c50",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "What is the local crisis line?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "c168f45c-3120-4662-877a-bb4899b95ac9",
        option_text: "Community Health Link 1-800-977-5555",
      },
      {
        id: "98fca694-7e19-4db1-b45e-a2e2fa67b4ed",
        option_text: "NA",
      },
    ],
  },
  {
    id: "be4812c5-92b0-4b48-8251-169ccd0bb344",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "What is the suicide helpline? ",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "9cc0b743-84d1-4d14-bbd5-b5794c7e99c1",
        option_text: "National Suicide Prevention Lifeline 1-800-273-8255",
      },
      {
        id: "8651590e-1674-44ee-a49f-f84192e023f1",
        option_text: "NA",
      },
    ],
  },
  {
    id: "830fe637-7148-4733-87f3-eebf242c663f",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "The final step is about reducing access to lethal means. The risk for suicide is amplified when patients report a specific plan to kill themselves that involves a readily available lethal method. Even if no specific plan is identified by the patient, a key component in a safety plan involves eliminating or limiting access to any potential lethal means in the patient’s environment. This may include safely storing medication, implementing gun safety procedures, or restricting access to knives or other lethal means. The clinician should ask patient which means they would consider using during a suicidal crisis and collaboratively identify ways to secure or limit access to these means. The clinician should ROUTINELY ask whether the patient has access to a firearm (such as a handgun, rifle or shotgun), whether or not it is considered a “method of choice” and make arrangements for securing the weapon. For methods with lower lethality (such as drugs or medication with a low level of toxicity), the clinician may ask patients to remove or restrict their access to these methods themselves before they are in crisis.",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "0ba928c5-fd23-4894-8400-25530ec3a3fd",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "The urgency and importance of restricting access to a lethal method is more pronounced for highly lethal methods. For methods of high lethality, such as a firearm, an optimal plan would be to restrict the patients’ access to a highly lethal method by having it safely removed and stored by a designated, responsible person—usually a family member or close friend, or even the police. \n\nThe clinician can ask patients, “What means do you have access to and are likely to use to make a suicide attempt or to kill yourself?” and “How can we go about developing a plan to limit your access to these means?”",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "e24091d2-6922-4df7-a1dd-391afa412b13",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "What is the first step to making the environment safe?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "5a67966e-c851-418f-acc8-57b01ddbbf45",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "What is the second step to making the environment safe?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "4bfcf409-2f55-48d9-a5a0-38523e20c9b0",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "After the safety plan has been completed, the clinician should assess the patients’ reactions to it and the likelihood they will use the safety plan in general. The clinician may ask: “How likely is it that you will use the safety plan when you notice the warning signs that we have discussed?\"",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "7a39eb34-0bf4-40b8-acbe-9bc2a60ebea6",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "If patients report or the clinician determines that they are reluctant or ambivalent to use it, then the clinician should collaborate with them to identify and problem solve potential obstacles and difficulties to using the safety plan. The clinician may ask: “What might get in the way or serve as a barrier to your using the safety plan?” \n\nFor specific barriers that are identified, the clinician may say: “Let’s discuss some ways to deal with this problem(s) so that you will be able to use the safety plan when it would be the most helpful for you. The clinician should also discusses where patients will keep the safety plan and how it will be retrieved during a crisis",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "dbf318ae-db03-40e6-ae25-5bcca7eb86e1",
    form_id: "06b2ad2e-0076-401e-a56e-dbbd2bed53ce",
    field_prompt: "Nice job! When you save the safety plan, it will push to the patient app: they can access it and edit under My Plans whenever they like. \n\nIf you are worried that the patient will not download the app in a timely way, you can also email the PDF of the safety plan to the patient directly.",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  }
]
var bhap = [
  {
    id: "0cc6a1b7-e8fa-4dd1-b313-103f9198ff78",
    form_id: "78be93ff-ae5c-437d-ab75-f7ce04594963",
    field_prompt: "What is the most important action you are going to take to manage your mental health after you go home?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "44dc2868-bd7e-49eb-a4dd-8fd9f6ebc6cd",
    form_id: "78be93ff-ae5c-437d-ab75-f7ce04594963",
    field_prompt: "What is the status of the most important action?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "e952188b-7ef1-4335-a4a8-6f9014eb148a",
        option_text: "Not Started",
      },
      {
        id: "6d7e3837-3e13-4bef-b924-26949bf6b86f",
        option_text: "In Progress",
      },
      {
        id: "9837f393-00b4-44a9-9522-e1b60a39be37",
        option_text: "Completed",
      },
    ],
  },
  {
    id: "1398c8bf-e53c-47a6-a3b2-b633e775b438",
    form_id: "78be93ff-ae5c-437d-ab75-f7ce04594963",
    field_prompt: "What is the second most important action you are going to take to manage your mental health after you go home?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "85a915e8-54ce-40bc-aff7-2336be9cad75",
    form_id: "78be93ff-ae5c-437d-ab75-f7ce04594963",
    field_prompt: "What is the status of the second most important action?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "8150b7f2-1548-4167-a152-7b243bddc342",
        option_text: "Not Started",
      },
      {
        id: "2cc54ec2-9b96-4ae5-aae5-15aeb9bc4539",
        option_text: "In Progress",
      },
      {
        id: "4227ad35-cf19-407a-b529-7b107720d109",
        option_text: "Completed",
      },
    ],
  },
  {
    id: "6902f624-fba7-427b-9940-081fce6a2796",
    form_id: "78be93ff-ae5c-437d-ab75-f7ce04594963",
    field_prompt: "What is the third most important action you are going to take to manage your mental health after you go home?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "6648ff2b-5de8-4aaa-8a21-40edff9cd98a",
    form_id: "78be93ff-ae5c-437d-ab75-f7ce04594963",
    field_prompt: "What is the status of the third most important action?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "62fc88c0-ba03-48b4-9219-f48c9e39a965",
        option_text: "Not Started",
      },
      {
        id: "a618e859-8e10-4a0b-b76d-a02569ee1488",
        option_text: "In Progress",
      },
      {
        id: "79831047-4288-4daa-8add-166122902c4f",
        option_text: "Completed",
      },
    ],
  },
]
var life = [
  {
    id: "99ff2791-c856-4b6c-bc00-50502e26554f",
    form_id: "4de617f2-5d7d-4339-9e75-d974bdf5301e",
    field_prompt: "What is the patient’s first most important value?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "96197ac7-4d69-4f14-9a68-e9a6b15b4e2f",
    form_id: "4de617f2-5d7d-4339-9e75-d974bdf5301e",
    field_prompt: "What is the Goal associated with the patient’s first most important value?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "b2c1430b-a224-42a4-b4c9-8b408f16308c",
    form_id: "4de617f2-5d7d-4339-9e75-d974bdf5301e",
    field_prompt: "What is an Action/Step associated with that first Value and Goal?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "f0d96dbb-3449-4b6c-9de5-b87135000c63",
    form_id: "4de617f2-5d7d-4339-9e75-d974bdf5301e",
    field_prompt: "What is the status for the first Action/Step associated with that first Value and Goal?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "1a38826f-6f89-4e73-8d02-ae079a4db13f",
        option_text: "Not Started",
      },
      {
        id: "a6bb4a22-53f3-4dd4-b7f6-6c457a0ce5cf",
        option_text: "In Progress",
      },
      {
        id: "08487ea2-f27c-4ac6-80ba-5ab9c454b41a",
        option_text: "Completed",
      },
    ],
  },
  {
    id: "f8d2e802-ccad-463b-98e5-22e4c327b1e5",
    form_id: "4de617f2-5d7d-4339-9e75-d974bdf5301e",
    field_prompt: "What is a second Action/Step associated with that first Value and Goal? ",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "fcdc8cfb-c381-4720-aff1-f6ec07c3dbe0",
    form_id: "4de617f2-5d7d-4339-9e75-d974bdf5301e",
    field_prompt: "What is the status for the second Action/Step associated with that first Value and Goal?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "b87dea67-218f-4df2-b32e-ed24cb79d454",
        option_text: "Not Started",
      },
      {
        id: "57a3d767-d73e-4c5e-8b4c-4096f07adb5d",
        option_text: "In Progress",
      },
      {
        id: "49557928-641b-4b58-b4a3-586e98822c4c",
        option_text: "Completed",
      },
    ],
  },
  {
    id: "f738f061-41de-4da7-85f6-9e40f9012a59",
    form_id: "4de617f2-5d7d-4339-9e75-d974bdf5301e",
    field_prompt: "What is a third Action/Step associated with that first Value and Goal?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "3de5ec90-d8c2-4259-bf2d-a3f410ca21d6",
    form_id: "4de617f2-5d7d-4339-9e75-d974bdf5301e",
    field_prompt: "What is the status for the third Action/Step associated with that first Value and Goal?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "c03ba96f-1867-432e-ba66-822b7d2223fc",
        option_text: "Not Started",
      },
      {
        id: "60c42ec9-5dd6-4736-a02c-cf41566ef5e1",
        option_text: "In Progress",
      },
      {
        id: "c134360a-7654-4222-8d4e-1a2154df8453",
        option_text: "Completed",
      },
    ],
  },
  {
    id: "f3f49acf-0573-4cf3-a1e2-0df69087eedf",
    form_id: "4de617f2-5d7d-4339-9e75-d974bdf5301e",
    field_prompt: "What is the patient’s second most important Value?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "a2161f71-8f70-448e-a28b-f2f6763934b4",
    form_id: "4de617f2-5d7d-4339-9e75-d974bdf5301e",
    field_prompt: "What is the Goal associated with the patient’s second most important Value?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "dc997e1f-80c8-41a8-960f-1fb1e878ad52",
    form_id: "4de617f2-5d7d-4339-9e75-d974bdf5301e",
    field_prompt: "What is an Action/Step associated with that second Value and Goal?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "2089a1c4-5fab-49be-bcdb-d6b8f101893d",
    form_id: "4de617f2-5d7d-4339-9e75-d974bdf5301e",
    field_prompt: "What is the status for the first Action/Step associated with that second Value and Goal?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "89ec897c-f838-427c-b16f-61e8f7f5988a",
        option_text: "Not Started",
      },
      {
        id: "352118dd-bb2e-4ba2-8ea8-5751d155b0f0",
        option_text: "In Progress",
      },
      {
        id: "9f7eef29-200c-443b-97ce-4d67398cafd4",
        option_text: "Completed",
      },
    ],
  },
  {
    id: "2cb4228c-6899-4a5c-a369-ede20c9850f4",
    form_id: "4de617f2-5d7d-4339-9e75-d974bdf5301e",
    field_prompt: " What is a second Action/Step associated with that second Value and Goal?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "649802d0-e8b0-4e97-a377-d1e5fa2b72e0",
    form_id: "4de617f2-5d7d-4339-9e75-d974bdf5301e",
    field_prompt: "What is the status for the second Action/Step associated with that second Value and Goal?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "4056b30d-ad88-4dea-875e-533694fd671d",
        option_text: "Not Started",
      },
      {
        id: "d4a58ff9-7c53-4266-8219-639988405221",
        option_text: "In Progress",
      },
      {
        id: "3976522d-81b6-4845-915b-ef34417a503b",
        option_text: "Completed",
      },
    ],
  },
  {
    id: "0aae4e7e-edf7-4204-8242-a75226a56b68",
    form_id: "4de617f2-5d7d-4339-9e75-d974bdf5301e",
    field_prompt: "What is a third Action/Step associated with that second Value and Goal?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "2c0104b2-dfb5-4648-b591-d56e8640714a",
    form_id: "4de617f2-5d7d-4339-9e75-d974bdf5301e",
    field_prompt: "What is the status for the third Action/Step associated with that second Value and Goal?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "c78bae75-a3b1-42ca-90fa-90c31316b4b2",
        option_text: "Not Started",
      },
      {
        id: "9bb0a3f8-b029-4280-bd74-bbf10c5bd802",
        option_text: "In Progress",
      },
      {
        id: "61394e90-776e-470d-b1fb-f8d95e4d2ff6",
        option_text: "Completed",
      },
    ],
  },
  {
    id: "9a42ffbe-50cb-44b1-93f0-3224c01140a9",
    form_id: "4de617f2-5d7d-4339-9e75-d974bdf5301e",
    field_prompt: "What is the patient’s third most important Value?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "ac23e019-2def-4a5f-8741-21effb7a1ab7",
    form_id: "4de617f2-5d7d-4339-9e75-d974bdf5301e",
    field_prompt: "What is the Goal associated with the patient’s third most important Value?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "67ab8a1a-169f-45c9-b7bf-1573d38995b9",
    form_id: "4de617f2-5d7d-4339-9e75-d974bdf5301e",
    field_prompt: "What is an Action/Step associated with that third Value and Goal?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "e414b7cb-f03b-48d2-b2c5-ac27c3cd515d",
    form_id: "4de617f2-5d7d-4339-9e75-d974bdf5301e",
    field_prompt: "What is the status for the first Action/Step associated with that third Value and Goal?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "48344b18-3441-432a-a077-0c38bf8fb4d9",
        option_text: "Not Started",
      },
      {
        id: "532935b7-31fc-4c51-98ec-4000269cb13f",
        option_text: "In Progress",
      },
      {
        id: "8f7fac78-0ffd-481d-847e-0a525434cf01",
        option_text: "Completed",
      },
    ],
  },
  {
    id: "558b34c1-be80-463d-9bec-9f6c83f5af4d",
    form_id: "4de617f2-5d7d-4339-9e75-d974bdf5301e",
    field_prompt: "What is a second Action/Step associated with that third Value and Goal?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "d9f0671f-97dd-455f-be0d-a7cfab7fc4a2",
    form_id: "4de617f2-5d7d-4339-9e75-d974bdf5301e",
    field_prompt: "What is the status for the second Action/Step associated with that third Value and Goal?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "a1b5a926-c2b3-457c-a9dc-09d23c4f5d36",
        option_text: "Not Started",
      },
      {
        id: "0937783e-d747-4c8d-bbee-36ef40a92fd9",
        option_text: "In Progress",
      },
      {
        id: "e8d65b72-c89c-4607-ae17-aa3df771ecb3",
        option_text: "Completed",
      },
    ],
  },
  {
    id: "616e9220-c31d-4208-8591-f7533877ac50",
    form_id: "4de617f2-5d7d-4339-9e75-d974bdf5301e",
    field_prompt: "What is a third Action/Step associated with that third Value and Goal?",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "b7a8e634-444e-4da6-9e33-9ce895562b7a",
    form_id: "4de617f2-5d7d-4339-9e75-d974bdf5301e",
    field_prompt: "What is the status for the third Action/Step associated with that third Value and Goal?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "be1b5cba-1c4f-4e97-a4b0-0f3f602231c1",
        option_text: "Not Started",
      },
      {
        id: "5193813c-1015-4800-8ebb-7dbbd7a8ae89",
        option_text: "In Progress",
      },
      {
        id: "b25f62ee-09d1-4309-af16-21aaea119f96",
        option_text: "Completed",
      },
    ],
  },
]
var call1 = [
  {
    id: "1d655d1a-2943-4b47-a2a7-efa2b289b39f",
    form_id: "67978837-caeb-47e0-9668-d732ae22bcc2",
    field_prompt: "PATIENT CALL #1 (2 WEEKS AFTER DISCHARGE)\nIntro: Before the call, set up a time to speak to the patient where you know they’ll have privacy about 30 minutes to talk. Text the day before to remind them of the call and to let them reschedule if needed. The purpose of this first call is to learn about the patient’s history and current state and to review the safety plan in detail. ",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "af5008ab-f618-411d-9f68-9542fa8431c0",
    form_id: "67978837-caeb-47e0-9668-d732ae22bcc2",
    field_prompt: "Please use this space to document call attempts, refusals, and incomplete calls",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "ebf68f6a-ea6c-43da-beb4-652fd357ef2b",
    form_id: "67978837-caeb-47e0-9668-d732ae22bcc2",
    field_prompt: "Date of Call #1 (MM/DD/YYYY): \n",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "4d908d0e-3126-477f-81f6-68a4768fe643",
    form_id: "67978837-caeb-47e0-9668-d732ae22bcc2",
    field_prompt: "Exact start time of Call #1 (HH:MM)",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "e1ccbd9c-7fe4-43a5-b7dd-bca4e64aae66",
    form_id: "67978837-caeb-47e0-9668-d732ae22bcc2",
    field_prompt: "Step 1: Establish rapport  \nINSTRUCTIONS\nOne of the most important therapeutic aspects of the program is the establishment of a collaborative relationship with the patient.  Pay particular attention to using good clinical skills (empathy, reflection, etc.) and spend some of the initial session “getting to know” the patient. Be especially sensitive to these issues during the first session.  Some patients may have a more difficult time feeling comfortable and establishing rapport over the telephone. In these cases, it is often useful to:  \n(a)\tacknowledge the difficulties of telephone contact and\n(b)\tshare somewhat more personal information than might be typical in individual psychotherapy.",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "b5defe4a-5a60-44fe-8321-445eaf96797d",
    form_id: "67978837-caeb-47e0-9668-d732ae22bcc2",
    field_prompt: "Step 2: Explain the ReachCare program \nINSTRUCTIONS\nFollowing personal introductions, reiterate the purpose, aims and structure of the program. Assess patient’s expectations regarding the program. The app, patient calls and family calls should all support and reinforce each other. The patient can expect:\n(1) Three calls over three months to see how they’re doing and to link them to available resources \n(2) Two family/SO calls during that time for additional support and coordination\n(3) Access to the ReachCare app, which includes their safety plan, helplines, self-care education resources, outpatient provider contact details, and distractions. \n(4) Access to the ReachCare app for their family/SO: After the patient downloads the app, they can click on the top-left profile icon to Share Access and invite one family member to download the app to access Plans and resources  \n\nPause frequently to allow questions and confirm understanding.  Be sure to mention family member calls and sharing of information with family members and limits of confidentiality.",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "9e1e02ca-4a15-4e7f-b9b1-e0e1f6ca63da",
    form_id: "67978837-caeb-47e0-9668-d732ae22bcc2",
    field_prompt: "2.1. Did you coach the patient on accessing the app, identifying barriers and giving encouragement?  ",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "8c8cde1d-391a-4a0e-9b55-ad1dc3de7b8c",
        option_text: "Yes",
      },
      {
        id: "d543e26e-2133-4536-a51f-718a7107a920",
        option_text: "No",
      },
      {
        id: "885bdf8e-ecde-4729-81d8-3f294689b805",
        option_text: "System default",
      },
    ],
  },
  {
    id: "5691e0f7-f1cc-4060-8c6e-41e37476e2d9",
    form_id: "67978837-caeb-47e0-9668-d732ae22bcc2",
    field_prompt: "2.2. Did you refer the patient to the first (“What is ReachCare?”) handout in Self-Care Resources in the app? ",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "4ec1f7bf-4f5a-41af-8782-3caa758d4d81",
        option_text: "Yes",
      },
      {
        id: "aff6d081-57ab-4d31-a160-a4e39b4db214",
        option_text: "No",
      },
      {
        id: "8bd5263b-6cf0-42f4-8c8b-950fb4dd4247",
        option_text: "System default",
      },
    ],
  },
  {
    id: "69610d6b-3e85-4b2f-9d28-9b3f250847bb",
    form_id: "67978837-caeb-47e0-9668-d732ae22bcc2",
    field_prompt: "Step 3: Psychoeducation regarding suicide\nINSTRUCTIONS\n In order to normalize the patient’s suicidal thinking and/or behavior, review basic psychoeducational information about suicide, its frequency and the need to talk openly about suicidal thinking.",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "968c0f4e-8335-41c9-95b7-2cf23e76136c",
    form_id: "67978837-caeb-47e0-9668-d732ae22bcc2",
    field_prompt: "3.1. Did you refer the patient to the second (“What is Suicidality?”) handout in Self-Care Resources in the app? ",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "aa4ee37e-b080-4395-b997-91ab3d3fc179",
        option_text: "Yes",
      },
      {
        id: "f6e274c5-e8f5-41be-bd55-728204ff7dfd",
        option_text: "No",
      },
      {
        id: "3773eea7-175f-4a5b-8f70-e13ee5c7decc",
        option_text: "System default",
      },
    ],
  },
  {
    id: "0e25637c-4ec2-4826-a580-832e845bdd15",
    form_id: "67978837-caeb-47e0-9668-d732ae22bcc2",
    field_prompt: "Step 4: Obtain history\nINSTRUCTIONS\nStarting with obtaining a hisTory allows the patient to tell their story, which facilitates rapport in a less threatening way than current symptoms. It gives you a sense of the patient and potential risk factors. Given the time constraints and other goals of the initial call, this history need not be exhaustive, but rather than overview of the patient’s history. “I’d like to get to know you better, can you tell me a little about yourself?”",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "c3df5314-1852-450b-951d-caa38d66d278",
    form_id: "67978837-caeb-47e0-9668-d732ae22bcc2",
    field_prompt: "4.1. What is patient’s mental health history?",
    response: '',
    field_type: "LONG_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "37f46c4e-915f-4066-98d9-0adeb216b055",
    form_id: "67978837-caeb-47e0-9668-d732ae22bcc2",
    field_prompt: "4.2. What is patient’s suicidality history?",
    response: '',
    field_type: "LONG_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "d964fab4-278f-4cd1-9822-207907f1c746",
    form_id: "67978837-caeb-47e0-9668-d732ae22bcc2",
    field_prompt: "Step 5: Evaluate current status \nINSTRUCTIONS\nFollowing the brief history, move to a discussion of the patient’s more recent problems. “So, what led up to your recent visit to the emergency department?” or if you met the patient in the ED, “how have things been for you since your visit to the ED?” This discussion should be specific enough to allow you to make a judgment regarding the patient’s current level of suicidal risk. ",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "e7ba4aa6-ddf0-4d99-a67e-f71578786628",
    form_id: "67978837-caeb-47e0-9668-d732ae22bcc2",
    field_prompt: "5.1. What was the nature of the patient’s recent suicidality? What is their current suicide status?",
    response: '',
    field_type: "LONG_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "2c64c92b-b78a-4f6e-b638-c29dcb70827d",
    form_id: "67978837-caeb-47e0-9668-d732ae22bcc2",
    field_prompt: "Step 6: Develop/review safety plan \nINSTRUCTIONS\nA primary goal of the first contact is to ensure that the patient has an adequate safety/crisis plan to help them deal with potential increased suicidal thoughts/desires. The patient should have had a safety plan developed in the emergency department as part of ReachCare. Review the safety plan content with the patient and make modifications if appropriate, especially if they have incomplete responses in the Safety Plan form.\n•\tDoes the patient know where to find the plan?  (Under My Plans in the ReachCare app)\n•\tDo they want to make any changes? For example: \n    -> -> “For warning signs, you have […]. Does that still work for you?”\n    -> ->  You don’t have a professional listed in your safety plan. Is there a physician or counselor you would like to add now?”\n•\tHave they tried to use the safety plan? \nAny changes you make to the safety plan in FormFlash on your side will appear in their app also. ",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "840bb02e-4c9f-4d90-8d47-5e89643a2597",
    form_id: "67978837-caeb-47e0-9668-d732ae22bcc2",
    field_prompt: "6.1. Did you look at (and edit as needed) the patient’s existing safety plan in FormFlash? ",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "ccefaf8f-9f9f-4e28-af39-8b3711cfcc82",
        option_text: "Yes",
      },
      {
        id: "c927c12a-891c-4436-b940-450276ec7362",
        option_text: "No",
      },
      {
        id: "d91678f0-6034-4ac9-ae61-9f9d00c74ffd",
        option_text: "System default",
      },
    ],
  },
  {
    id: "f61a2af6-7075-4ad3-a4ed-7ce519288289",
    form_id: "67978837-caeb-47e0-9668-d732ae22bcc2",
    field_prompt: "6.2. Did you refer the patient to the third (“How can I use my safety plan?”) handout in Self-Care Resources in the app?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "a587b080-2d51-4b78-97c5-b340992c3928",
        option_text: "Yes",
      },
      {
        id: "67082ed1-7d97-4040-ab21-754de4901aff",
        option_text: "No",
      },
      {
        id: "6a0ee6d8-e561-404a-a4e2-6d58ab7700a7",
        option_text: "System default",
      },
    ],
  },
  {
    id: "918003f2-d506-42b2-bc97-fe660258d367",
    form_id: "67978837-caeb-47e0-9668-d732ae22bcc2",
    field_prompt: "Step 7: Assessment of outpatient treatment plan and providers\nINSTRUCTIONS\nDetermine if the patient has outpatient mental health providers and, if so, assess the degree of adherence to these plans. If the patient does not have providers or plans, then a goal of this and subsequent contacts should be to help the patient and significant other establish these contacts. The patient’s next steps for behavioral health self-management are listed on their Behavioral Health Action Plan: any changes you make to the BHAP on your side in FormFlash will appear in their app also. ",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "dd062839-e35a-41fb-8277-b9e680425800",
    form_id: "67978837-caeb-47e0-9668-d732ae22bcc2",
    field_prompt: "7.1. What is patient’s current behavioral health treatment engagement?",
    response: '',
    field_type: "LONG_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "53ca21fd-a334-4956-97a6-61795df18905",
    form_id: "67978837-caeb-47e0-9668-d732ae22bcc2",
    field_prompt: "7.2. Did you review/create the patient’s Behavioral Health Action Plan?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "0c83dac5-2be8-4418-9179-b385a0ea85d6",
        option_text: "Yes",
      },
      {
        id: "49ebf57b-769b-413b-b85f-4452b8267806",
        option_text: "No",
      },
      {
        id: "ad1fd193-cf72-4bae-b72b-184dfffae0b4",
        option_text: "System default",
      },
    ],
  },
  {
    id: "b594dbee-7ab3-4453-a3b9-4ac9da17150b",
    form_id: "67978837-caeb-47e0-9668-d732ae22bcc2",
    field_prompt: "7.3. Did you use the outpatient referral engine or refer the patient to it? ",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "1833e9c8-9e5e-4590-b7dd-6c17396642dc",
        option_text: "Yes",
      },
      {
        id: "730f69de-4d9a-45bd-9564-7d44137ce8f8",
        option_text: "No",
      },
      {
        id: "b17bf5ea-3b1f-44a6-b475-49bc0aa419f9",
        option_text: "System default",
      },
    ],
  },
  {
    id: "76973c1a-1016-4e48-bd34-95a7f3d09073",
    form_id: "67978837-caeb-47e0-9668-d732ae22bcc2",
    field_prompt: "7.4. Did you offer to make a call to a BH provider to connect the patient? ",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "cdca0816-df2d-41c1-9fd5-b1ca89398f19",
        option_text: "Yes",
      },
      {
        id: "effec80e-c42a-49c0-bcc1-09026e738437",
        option_text: "No",
      },
      {
        id: "7bab256f-f4cb-4389-bd60-362a2829929a",
        option_text: "System default",
      },
    ],
  },
  {
    id: "22905c15-46e1-497e-b468-6a1dfc0c4922",
    form_id: "67978837-caeb-47e0-9668-d732ae22bcc2",
    field_prompt: "Step 8: Wrap-up\nINSTRUCTIONS\n(a)\tAsk the patient if they have any other questions or concerns\n(b)\tSchedule the next call and enter details of appointment into formflash\n(c)\tObtain family contact details for family call. The specific individual chosen should represent the patient’s “most significant” social relationship: spouse/partner for married/romantically linked patients, other family members or close friend otherwise. \n(d)\tRemind the patient of any app resources you would like them to use",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "15538101-eaa1-47da-8688-36023d0844b0",
    form_id: "67978837-caeb-47e0-9668-d732ae22bcc2",
    field_prompt: "8.1. What are the family member’s contact details? ",
    response: '',
    field_type: "LONG_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "bfc00cf7-9ef3-4760-a2b9-fcb1026d782d",
    form_id: "67978837-caeb-47e0-9668-d732ae22bcc2",
    field_prompt: "8.2. Did you enter the next patient appointment into formflash? ",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "cd7a3240-3a49-4dd1-ac27-43e703101bde",
        option_text: "Yes",
      },
      {
        id: "f3af2da3-f7c8-4125-a341-22f0bca8b4b3",
        option_text: "No",
      },
      {
        id: "2baa2ac0-ae14-4dea-bfcd-62478af42b7b",
        option_text: "System default",
      },
    ],
  },
  {
    id: "28d75391-d02c-45d7-a784-2979d5b626ab",
    form_id: "67978837-caeb-47e0-9668-d732ae22bcc2",
    field_prompt: "8.3. Any notes or reflections not covered above?",
    response: '',
    field_type: "LONG_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "08a40fbe-55fe-45d0-b97b-3cfe6dcd99c0",
    form_id: "67978837-caeb-47e0-9668-d732ae22bcc2",
    field_prompt: "8.4. Exact end time for Patient Call #1",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
]
var call2 = [
  {
    id: "cb0035fc-b7ad-4a3a-a6d6-57e90ad0a4b5",
    form_id: "71f6c2ba-60ab-4ea0-84c4-35cec63f5b3c",
    field_prompt: "FAMILY CALL #1 (ED + 4WKS)\nIntro: Before the call, set up a time to speak to the family member where you know they'll have privacy and have about 20 minutes to talk. Text the day before to remind them of the call and to let them reschedule if needed. If feasible, it is very useful to have both the patient and the family member on the phone together during this first family call. This allows a shared information base and encourages patient-family communication. If not feasible, the family call can be done with the family alone.\n\nThe overall goals of the family members contact will be to:\n1) Provide consistent psychoeducational information regarding suicide to the patient's family member\n2) Enlist patient's family member in monitoring the patient's level of hopelessness and suicidal ideation\n3) To enlist the family member to support the patient's treatment \n4) To identify sources of social support (including the family member) for the patient ",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null,
      },
    ],
  },
  {
    id: "0cdb1594-77ed-43b5-9c39-4b5e62b26d69",
    form_id: "71f6c2ba-60ab-4ea0-84c4-35cec63f5b3c",
    field_prompt: "Please use this space to  document call attempts, refusals, and incomplete calls",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null,
      },
    ],
  },
  {
    id: "b47df6f5-baff-4a34-b689-a84e55b17622",
    form_id: "71f6c2ba-60ab-4ea0-84c4-35cec63f5b3c",
    field_prompt: "Date of Family Call #1 (MM/DD/YYYY)",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null,
      },
    ],
  },
  {
    id: "7ca6357b-3720-499c-8460-0f166e24e54d",
    form_id: "71f6c2ba-60ab-4ea0-84c4-35cec63f5b3c",
    field_prompt: "Start Time of Call (HH:MM)",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null,
      },
    ],
  },
  {
    id: "a9801d79-ee7c-4888-9822-4a1ff98adef4",
    form_id: "71f6c2ba-60ab-4ea0-84c4-35cec63f5b3c",
    field_prompt: "Step 1: Establish rapport\nINSTRUCTIONS\nAs with the initial patient calls, it is critical that the clinician work to establish a good rapport and working, collaborative arrangement with the patient’s family member. Pay particular attention to using good clinical skills (empathy, reflection, etc.) and spend some of the initial session “getting to know” the family member. Be especially sensitive to these issues during the first session. Some individuals may have a more difficult time feeling comfortable and establishing rapport over the telephone. In these cases, it is often useful to:\n(a)acknowledge the difficulties of telephone contact and\n(b) share somewhat more personal information than might be typical in individual psychotherapy",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null,
      },
    ],
  },
  {
    id: "cbf41c73-4b0d-4635-aa88-7ed0dc563689",
    form_id: "71f6c2ba-60ab-4ea0-84c4-35cec63f5b3c",
    field_prompt: "Step 2: Explain the ReachCare program \nINSTRUCTIONS\nFollowing personal introductions, explain the purpose, aims and structure of the program. \n•\tAssess family member’s expectations regarding the program\n•\tThe app, patient calls and family calls should all support and reinforce each other. The family member can expect:\n-> -> Two family calls: this one and one additional call four weeks from now\n-> -> Access to the ReachCare app: It is up to the patient whether they choose to grant additional permission to family to view their safety plan, life plan and behavioral health action plan. But inviting the family member to the base app will give them to access to a host of self-care education and helplines that may be useful, as well as allowing family members to see upcoming appointments. \n•\tPause frequently to allow questions and confirm understanding. ",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null,
      },
    ],
  },
  {
    id: "91dbc70b-608f-4743-b66c-80ba0a92af12",
    form_id: "71f6c2ba-60ab-4ea0-84c4-35cec63f5b3c",
    field_prompt: "2.1. Did you recommend the family member ask the patient to invite them to the ReachCare app? (Under Profile -> Share Access in the patient app) ",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "729e4327-2061-442e-b62d-dc5279a08e42",
        option_text: "Yes",
      },
      {
        id: "0cf6de42-26b7-41d4-8d43-b4c5c5c7c10f",
        option_text: "No",
      },
      {
        id: "d5e2c975-4f5c-4c39-b002-5f03739dd951",
        option_text: "System default",
      },
    ],
  },
  {
    id: "94fd4cd1-0565-44e9-b537-ae4652bd92d2",
    form_id: "71f6c2ba-60ab-4ea0-84c4-35cec63f5b3c",
    field_prompt: "Step 3: Psychoeducation regarding suicide\nINSTRUCTIONS\nThe third goal is to provide basic psychoeducational information regarding suicide to the family member. This information serves to begin discussion in relatively non-threatening manner and also serves to de-catastrophize the issue of suicide.",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null,
      },
    ],
  },
  {
    id: "ac5bf4ca-38f9-4945-86e4-2ded403628a3",
    form_id: "71f6c2ba-60ab-4ea0-84c4-35cec63f5b3c",
    field_prompt: "3.1. Did you refer the family member to the second (“What is Suicidality?”) handout in Self-Care Resources in the app? ",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "30a4c25b-d175-4422-a0f0-b53df503b773",
        option_text: "Yes",
      },
      {
        id: "4562c410-2682-45cb-92e8-a77dd20acae0",
        option_text: "No",
      },
      {
        id: "20a89c69-f057-4d3f-98cd-921067a6041e",
        option_text: "System default",
      },
    ],
  },
  {
    id: "e61e1b1c-a865-4927-b375-d4ed6a14ec26",
    form_id: "71f6c2ba-60ab-4ea0-84c4-35cec63f5b3c",
    field_prompt: "Step 4: Review family member’s concerns about patient\nINSTRUCTIONS\nFamily members will often have significant concerns or issues regarding the patient. Elicit and encourage voicing of these concerns. This process both enables you obtain a different perspective on the patient and his/her issues, but also helps establish that you value and are concerned with the family member as well as the patient. Prompts include: \n•\tCan you tell me a little about how ___[patient]_____has been doing?                                                    \n•\tWhat are your main concerns about him/her?\n•\tWhat kind of supports or resources would you like him/her to have?\n•\tHow have you been coping?”",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null,
      },
    ],
  },
  {
    id: "d3c8c0f4-cbde-4079-a086-e017d7fd4ac4",
    form_id: "71f6c2ba-60ab-4ea0-84c4-35cec63f5b3c",
    field_prompt: "4.1. What are the family member’s main concerns about the patient? ",
    response: '',
    field_type: "LONG_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null,
      },
    ],
  },
  {
    id: "a6e059cd-33de-40d1-b863-2407f7a414fa",
    form_id: "71f6c2ba-60ab-4ea0-84c4-35cec63f5b3c",
    field_prompt: "Step 5: Discussion of the patient’s suicidality\nINSTRUCTIONS\nIf it was not discussed earlier, we think that it is important to be sure to discuss the patient’s suicidality with significant others. Often significant others have major and unanswered questions about the precipitants of a crisis. Almost universally, the patient’s crisis elicits strong emotions from significant others which are often not expressed clearly and directly to the patient. Discussion of the precipitants and consequences (to patient and significant others) of the patient’s attempt serves to remove the “elephant in the room” and allows for subsequent future planning. Prompts may include: \no\tHow did you find out about ___[patient]___’s last suicidal crisis?\no\tWhat happened?\no\tHow did you feel about that?",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null,
      },
    ],
  },
  {
    id: "99ab64a2-edbc-4d48-8d7f-cd87a1758cd0",
    form_id: "71f6c2ba-60ab-4ea0-84c4-35cec63f5b3c",
    field_prompt: "5.1. What was the family member’s understanding of patient’s suicidality? ",
    response: '',
    field_type: "LONG_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null,
      },
    ],
  },
  {
    id: "f06b884a-fb58-418c-80af-702d93589872",
    form_id: "71f6c2ba-60ab-4ea0-84c4-35cec63f5b3c",
    field_prompt: "5.2. Did you refer the family member to the fourth (“How can I support someone who is suicidal?”) handout in Self-Care Resources in the app? ",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "502e4f4c-9681-42ba-a83b-8e68ea6f7803",
        option_text: "Yes",
      },
      {
        id: "8908c48c-098a-44bb-b6b1-729654681b25",
        option_text: "No",
      },
      {
        id: "1ac3a4df-552c-4a6a-bdd1-e8eb05353039",
        option_text: "System default",
      },
    ],
  },
  {
    id: "e8966f2a-2ce0-4c61-80bf-8e0db9b71791",
    form_id: "71f6c2ba-60ab-4ea0-84c4-35cec63f5b3c",
    field_prompt: "Step 6: Discussion of the patient’s Safety Plan\nINSTRUCTIONS\nNow discuss the previously developed Safety Plan. The goals of this discussion are to be certain that the family member understands and agrees to the plan. Given the possibility of subsequent suicidal ideation, risk and/or attempts, it will be important for both patient and family member to understand and agree with crisis protocol for dealing with subsequent increased suicidal behavior. This discussion will include topics including: \n•\treduction of access to means for suicide, especially firearms \n•\tearly identification of increased suicidal risk (Warning Signs), and \n•\tresponse options (contact treatment providers, hospitalization, police, etc).\n•\tsharing the safety plan through the ReachCare app",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null,
      },
    ],
  },
  {
    id: "8371aff7-e2a2-4de7-bea3-6a34510f39ab",
    form_id: "71f6c2ba-60ab-4ea0-84c4-35cec63f5b3c",
    field_prompt: "6.1.  Did you look at (and edit if needed) the patient’s existing safety plan?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "4717257f-7300-4a23-9732-a74c0441b37d",
        option_text: "Yes",
      },
      {
        id: "899bc702-afe1-4f7d-ba5a-f05c64cfbd09",
        option_text: "No",
      },
      {
        id: "72b8ea76-b808-4269-82a8-f62531521194",
        option_text: "System default",
      },
    ],
  },
  {
    id: "f9b65252-f66b-4833-bbf2-80bf34591c77",
    form_id: "71f6c2ba-60ab-4ea0-84c4-35cec63f5b3c",
    field_prompt: "6.2. Did you refer the family member to the third (“How can I use my safety plan?”) handout in Self-Care Resources in the app?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "a4368c74-e241-4cf3-bb25-6dae1f4aa75f",
        option_text: "Yes",
      },
      {
        id: "b1131eb8-041a-489b-9513-caca5b43775c",
        option_text: "No",
      },
      {
        id: "67d263eb-eed5-4772-ae3a-f960a1f3ede9",
        option_text: "System default",
      },
    ],
  },
  {
    id: "c875793d-09a5-4914-bee0-f385117ea85e",
    form_id: "71f6c2ba-60ab-4ea0-84c4-35cec63f5b3c",
    field_prompt: "Step 7: Review of the patient’s treatment engagement\nINSTRUCTIONS\nNow discuss the patient’s current treatment supports and engagement. This discussion will include topics such as: \n•\twhether the patient has established an outpatient provider \n•\twhether they are adherent/satisfied with current care\n•\twhether there are supports that the study clinician or the family member can provide to ease access to outpatient support (such as transport, encouragement, referral, etc.)",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null,
      },
    ],
  },
  {
    id: "0f758050-d2e2-4701-be48-67f7ce894b90",
    form_id: "71f6c2ba-60ab-4ea0-84c4-35cec63f5b3c",
    field_prompt: "7.1. Did you look at the patient’s behavioral health action plan?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "f820b56c-e4b3-4b0e-b73b-042d0ee6458d",
        option_text: "Yes",
      },
      {
        id: "646424a4-bc74-4152-8da7-a1551122c04b",
        option_text: "No",
      },
      {
        id: "7d42d076-bb68-47d2-919f-72354006fa2f",
        option_text: "System default",
      },
    ],
  },
  {
    id: "6f36cfe1-b5c1-49d2-91e3-6e3c49e460f1",
    form_id: "71f6c2ba-60ab-4ea0-84c4-35cec63f5b3c",
    field_prompt: "7.2. Did you refer the family member to the eighth (“Where can I find more support?”) handout in Self-Care Resources in the app? ",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "4c1f1390-fe98-42b7-a50d-2db9c2709005",
        option_text: "Yes",
      },
      {
        id: "80759b37-187a-45e2-9f7f-0be759c602ef",
        option_text: "No",
      },
      {
        id: "3517372c-b57e-48ed-b97e-90c541d2e931",
        option_text: "System default",
      },
    ],
  },
  {
    id: "4b02eab0-691b-4d3e-8e12-f47adb0f622d",
    form_id: "71f6c2ba-60ab-4ea0-84c4-35cec63f5b3c",
    field_prompt: "Step 8: Wrap-up\nINSTRUCTIONS\n(a)\tAsk the family member if they have any other questions or concerns\n(b)\tSchedule the next call and enter details into formflash\n(c)\tRemind the family member to ask the patient for an invite to the of app and highlight any resources you would like them to use",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null,
      },
    ],
  },
  {
    id: "febf9a46-e9b7-403c-be08-b31e08e3e0e7",
    form_id: "71f6c2ba-60ab-4ea0-84c4-35cec63f5b3c",
    field_prompt: "8.1. Did you enter the next family appointment into formflash? ",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "617a3a61-cfce-4ff1-aaf4-d82ba9eaa9c1",
        option_text: "Yes",
      },
      {
        id: "d44b8ed8-9473-4ef7-b7d9-6982177b68ad",
        option_text: "No",
      },
      {
        id: "42c6c307-c0b8-4e41-8d85-92137dbfb0f2",
        option_text: "System default",
      },
    ],
  },
  {
    id: "37f76e59-e08b-438e-b5c0-dd35f31f1dab",
    form_id: "71f6c2ba-60ab-4ea0-84c4-35cec63f5b3c",
    field_prompt: "8.2. Any notes or reflections not covered above?",
    response: '',
    field_type: "LONG_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null,
      },
    ],
  },
  {
    id: "485943ac-17cb-4d07-86bc-868f6ae0509a",
    form_id: "71f6c2ba-60ab-4ea0-84c4-35cec63f5b3c",
    field_prompt: "8.3. Exact end time for Family Call #1 (HH:MM)",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null,
      },
    ],
  }
]
var call3 = [
  {
    id: "7fa1a93c-aba9-422a-9259-86da8ce1e535",
    form_id: "4780b556-094f-485d-8b33-a9b9c7af7e02",
    field_prompt: "PATIENT CALL #2 (6 WEEKS AFTER DISCHARGE)\n\nIntro: Before the call, set up a time to speak to the patient where you know they’ll have privacy about 30 minutes to talk. Text the day before to remind them of the call and to let them reschedule if needed. The purpose of this second call is to check in briefly on the safety plan and behavioral health action plan, but the bulk of the time will be spent on a discussion about Values and the Life Plan.",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "aae91f86-a7b9-419d-9250-93db6ed49af2",
    form_id: "4780b556-094f-485d-8b33-a9b9c7af7e02",
    field_prompt: "Please use this space to document call attempts, refusals, and incomplete calls",
    response: '',
    field_type: "LONG_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "50179f88-8884-4d5d-a4a7-8b9424e6b24c",
    form_id: "4780b556-094f-485d-8b33-a9b9c7af7e02",
    field_prompt: "Date of Call #2 (MM/DD/YYYY): \n",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "38ee99ab-e169-4d3b-98cf-23dc55e059c0",
    form_id: "4780b556-094f-485d-8b33-a9b9c7af7e02",
    field_prompt: "Exact start time of Call #2 (HH:MM)",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "ae21778e-6593-4731-ab0a-d787b1743234",
    form_id: "4780b556-094f-485d-8b33-a9b9c7af7e02",
    field_prompt: "Step 1: Evaluate current status \nINSTRUCTIONS\nIn each call, the clinician should begin by briefly assessing the patient’s current level of suicide risk, psychiatric symptoms and treatment, as well as recent history of ideation, attempts, ED visits or hospitalizations",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "49ee9470-29b0-496a-a7ef-df253645e5bd",
    form_id: "4780b556-094f-485d-8b33-a9b9c7af7e02",
    field_prompt: "1.1. What is the patient's current status?",
    response: '',
    field_type: "LONG_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "24c2634a-160f-426d-8647-0a7047e1bb6b",
    form_id: "4780b556-094f-485d-8b33-a9b9c7af7e02",
    field_prompt: "Step 2: Accessing the app \nINSTRUCTIONS\nIf the patient still hasn't done so, coach them download the ReachCare app and to invite their family member to the app using the share Access under their profile.",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "f1fcf447-48ca-49ab-97fb-5ca731abb0a3",
    form_id: "4780b556-094f-485d-8b33-a9b9c7af7e02",
    field_prompt: "2.1 Did you coach the patient on accessing the app, identifying barriers and giving encouragement?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "1cb764e9-2698-4b53-8b71-e6ee74bb975c",
        option_text: "Yes",
      },
      {
        id: "d3055b69-7e77-4dbc-8e26-3313f6206862",
        option_text: "No",
      },
      {
        id: "704aea0c-51db-4d83-9495-8b8cc2d3e2f9",
        option_text: "System Default",
      },
    ],
  },
  {
    id: "66d1b976-57c7-4d7e-a800-2039a8254d28",
    form_id: "4780b556-094f-485d-8b33-a9b9c7af7e02",
    field_prompt: "2.2 Did you walk the patient through the process of adding a family member if they have not already done so? ",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "b1e9a745-0bfe-41ae-97c9-8711e7716e45",
        option_text: "Yes",
      },
      {
        id: "3a771694-db81-44ef-b245-a6e1ad09511e",
        option_text: "No",
      },
      {
        id: "7c9c2912-0a42-4bcc-8ed2-c7abcb58df51",
        option_text: "System Default",
      },
    ],
  },
  {
    id: "3709ddb5-12de-49e4-8a2a-6735bc16ec59",
    form_id: "4780b556-094f-485d-8b33-a9b9c7af7e02",
    field_prompt: "Step 3: Review safety plan \nINSTRUCTIONS\nThe second call represents another opportunity to check in on the patient’s safety plan and make modifications as appropriate. \n-> -> Does the patient know where to find the plan?  (Under My Plans in the ReachCare app)\n-> -> Do they want to make any changes? For example: \n-> -> “For warning signs, you have […]. Does that still work for you?”\n-> -> “You don’t have a professional listed in your safety plan. Is there a physician or counselor you would like to add now?”\n•\tHave they tried to use the safety plan? \nAny changes you make to the safety plan on your side in FormFlash will appear in their app also. ",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "127c913e-718b-4f97-968d-1aa8383efbee",
    form_id: "4780b556-094f-485d-8b33-a9b9c7af7e02",
    field_prompt: "3.1. Did you look at (and edit if needed) the patient’s existing safety plan in FormFlash? ",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "19639c23-b581-40f6-93a7-b5230f9578e6",
        option_text: "Yes",
      },
      {
        id: "db249b2d-3426-4b2d-8b27-63f1bb614325",
        option_text: "No",
      },
      {
        id: "05b4f385-d79d-45a7-8792-b8fe604542a8",
        option_text: "System default",
      },
    ],
  },
  {
    id: "16097cfc-234b-4159-817a-cd82b670dad0",
    form_id: "4780b556-094f-485d-8b33-a9b9c7af7e02",
    field_prompt: "3.2. Did you refer the patient to the third (“How can I use my safety plan?”) handout in Self-Care Resources in the app?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "66b160e3-98a6-49ef-a5d7-fd97673e2951",
        option_text: "Yes",
      },
      {
        id: "8e8793c4-6586-47fd-b37c-63fc802d8f81",
        option_text: "No",
      },
      {
        id: "97ef6515-4902-4315-8c97-9bc0395b5f81",
        option_text: "System default",
      },
    ],
  },
  {
    id: "305f8b7a-d8d0-4a03-83f2-b16328b01e42",
    form_id: "4780b556-094f-485d-8b33-a9b9c7af7e02",
    field_prompt: "Step 4: Assessment of outpatient treatment plan and providers \nINSTRUCTIONS\nDetermine if the patient has outpatient mental health providers and, if so, assess the degree of adherence to these plans. If the patient does not have providers or plans, then a goal of this and subsequent contacts should be to help the patient and significant other establish these contacts. The patient’s next steps for behavioral health self-management are listed on their Behavioral Health Action Plan: any changes you make to the BHAP on your side in FormFlash will appear in their app also. ",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "6a66104a-bcf7-4fa1-86e0-79551a4f3d55",
    form_id: "4780b556-094f-485d-8b33-a9b9c7af7e02",
    field_prompt: "4.1. What is patient’s current behavioral health treatment engagement?",
    response: '',
    field_type: "LONG_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "f6f14432-d676-41ec-9c48-fad78d388d1f",
    form_id: "4780b556-094f-485d-8b33-a9b9c7af7e02",
    field_prompt: "4.2. Did you review/create the patient’s Behavioral Health Action Plan?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "3a41c6f8-5738-42f1-8348-c6555bf18013",
        option_text: "Yes",
      },
      {
        id: "bdffeac3-a216-4fcc-a2b4-4e00ed5f4292",
        option_text: "No",
      },
      {
        id: "d3bf2bff-a917-4cc9-b20f-6dde517ec300",
        option_text: "System default",
      },
    ],
  },
  {
    id: "f638a86d-554a-4b24-8353-51bfc49d0d32",
    form_id: "4780b556-094f-485d-8b33-a9b9c7af7e02",
    field_prompt: "4.3. Did you use the outpatient referral engine or refer the patient to it? ",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "3eeb2806-44b5-41a1-8571-383deaf73ffb",
        option_text: "Yes",
      },
      {
        id: "11c6465d-18b5-4a76-a039-977b815e72aa",
        option_text: "No",
      },
      {
        id: "ed0d453d-aef4-4757-9130-1d3313784c74",
        option_text: "System default",
      },
    ],
  },
  {
    id: "2e16d9ff-ad73-4be4-be95-887fb4a9b682",
    form_id: "4780b556-094f-485d-8b33-a9b9c7af7e02",
    field_prompt: "4.4. Did you offer to make a call to a BH provider to connect the patient? ",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "25927d6a-bc77-484c-b803-8a4bbf83b442",
        option_text: "Yes",
      },
      {
        id: "9b029612-3b19-48f1-b597-2c527c96d8b0",
        option_text: "No",
      },
      {
        id: "818a1882-dbed-4720-8f5b-3616ad4d519b",
        option_text: "System default",
      },
    ],
  },
  {
    id: "111a041d-2a3f-4897-8997-f026fd47b7c2",
    form_id: "4780b556-094f-485d-8b33-a9b9c7af7e02",
    field_prompt: "Step 5: Values discussion  \nINSTRUCTIONS\nThe clinician should introduce concept of values. This will help the patient to identify their own chosen life directions, differentiate between values and goals, and identify discrepancies between their values and current behavior which may be leading to increased distress. The first step is to find out what the patient’s core values are. Once you’ve established up to three core values with the patient, you can move on to the Life Plan. ",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "d6a3332e-ac0a-4c69-8560-8ad61fb1f390",
    form_id: "4780b556-094f-485d-8b33-a9b9c7af7e02",
    field_prompt: "5.1. On a scale of 1 to 10, how important is each of the following to you, 10 being very important? Intimate Relationships; Parenting; Family Relations; Social Relations; Employment; Education and training; Recreation; Spirituality; Citizenship/Community; Health/Physical Well-being? Then, on a scale of 1-10, over the past week, how consistent your actions have been with each value; 1 being complete inconsistent and 10 being completely consistent? ",
    response: '',
    field_type: "LONG_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "c6cfd10d-1b70-41ba-8a51-fe6edbb170af",
    form_id: "4780b556-094f-485d-8b33-a9b9c7af7e02",
    field_prompt: "Step 6: Creating the Life Plan  \nINSTRUCTIONS\nThe Life Plan provides a positive action plan based on the patient’s values and goals. Based on the patient’s core values, it is a framework for positive life changes and values-based problem solving. The Life Plan is available to the patient in the ReachCare app, with supportive explanatory materials in the Self-Care Education section of the app. It can be initiated by the clinician and responses pushed to the patient app once saved the call. Like the BHAP, the Life Plan has space to mark each action as “Not Started”, “In Progress”, and “Completed” to encourage the patient to engage with actions and create new ones as needed.  ",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "6c2bc1fa-e46a-4cf2-a90d-c44e5a7b9bf6",
    form_id: "4780b556-094f-485d-8b33-a9b9c7af7e02",
    field_prompt: "6.1. Did you create the patient’s Life Plan? ",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "12fbf2cd-8e55-4115-bca7-6719613f94d3",
        option_text: "Yes",
      },
      {
        id: "aff67633-b588-47cf-903e-26cd0e8e3413",
        option_text: "No",
      },
      {
        id: "20c5e90d-54f6-4c64-b783-99813ea5b51b",
        option_text: "System default",
      },
    ],
  },
  {
    id: "0b711e63-e854-4197-b983-c49a90fa30b7",
    form_id: "4780b556-094f-485d-8b33-a9b9c7af7e02",
    field_prompt: "6.2. Did you refer the patient to the fifth (“How does the Life Plan work?”) handout in Self-Care Resources in the app?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "94208877-a8eb-4c89-bc21-30217ed8e74a",
        option_text: "Yes",
      },
      {
        id: "f8f73eed-32e5-4c22-a571-7217aded11a1",
        option_text: "No",
      },
      {
        id: "a0566a4e-26d7-4ff5-8dc1-3cd7341c2d55",
        option_text: "System default",
      },
    ],
  },
  {
    id: "bf2168fa-b06b-4a3d-a6e5-e5d9b1f67920",
    form_id: "4780b556-094f-485d-8b33-a9b9c7af7e02",
    field_prompt: "Step 7: Wrap-up\nInstructions:\n(a)\tAsk the patient if they have any other questions or concerns\n(b)\tSchedule the next call and enter details of appointment into formflash\n(c)\tRemind the patient of any app resources you would like them to use",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "ce65f55f-7adb-4f5f-a21e-5c2826ad0606",
    form_id: "4780b556-094f-485d-8b33-a9b9c7af7e02",
    field_prompt: "7.1. Any changes to the family member’s contact details? ",
    response: '',
    field_type: "LONG_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "d0898e3d-3cb9-4ccb-aa07-73636a4821f9",
    form_id: "4780b556-094f-485d-8b33-a9b9c7af7e02",
    field_prompt: "7.2. Did you enter the next patient appointment into formflash? ",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "f2966b7f-d920-4977-9280-46fc2db99766",
        option_text: "Yes",
      },
      {
        id: "088f414d-dc23-4bdc-9299-a7d50469ba45",
        option_text: "No",
      },
      {
        id: "4174b4f3-68b0-4732-88a2-28538477821d",
        option_text: "System default",
      },
    ],
  },
  {
    id: "9c8a19f4-91ce-4970-95f7-97c216adb839",
    form_id: "4780b556-094f-485d-8b33-a9b9c7af7e02",
    field_prompt: "7.3. Any notes or reflections not covered above?",
    response: '',
    field_type: "LONG_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "d447989b-6c7c-4630-bc6c-114880967d92",
    form_id: "4780b556-094f-485d-8b33-a9b9c7af7e02",
    field_prompt: "7.4. Exact end time for Patient Call #2 (HH:MM)",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
]
var call4 = [
  {
    id: "f972f78c-62e8-4d34-b8e4-9fb6baf3f273",
    form_id: "5fc9995c-ff3d-4cbf-9a53-6b08708aab31",
    field_prompt: "FAMILY CALL #2 (8 WEEKS AFTER DISCHARGE)\nIntro: Before the call, set up a time to speak to the family member where you know they’ll have privacy and have about 20 minutes to talk. Text the day before to remind them of the call and to let them reschedule if needed. If feasible, it is very useful to have both the patient and the family member on the phone together during this first family call. This allows a shared information base and encourages patient-family communication. If not feasible, the family call can be done with the family alone ",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "dd1e764a-bc9b-40f4-b09d-3d019a610808",
    form_id: "5fc9995c-ff3d-4cbf-9a53-6b08708aab31",
    field_prompt: "Please use this space to document call attempts, refusals, and incomplete calls",
    response: '',
    field_type: "LONG_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "14017e9d-0c7d-4ab7-b196-6b477547ddb8",
    form_id: "5fc9995c-ff3d-4cbf-9a53-6b08708aab31",
    field_prompt: "Date of Family Call #2 (MM/DD/YYYY)",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "c0bcf9d1-3879-4158-ae22-bc945001fced",
    form_id: "5fc9995c-ff3d-4cbf-9a53-6b08708aab31",
    field_prompt: "Exact Start Time of Family Call #2 (HH:MM)",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "b19f975c-0340-4c82-a5ff-15c0ee840c2c",
    form_id: "5fc9995c-ff3d-4cbf-9a53-6b08708aab31",
    field_prompt: "Step 1: Check in about the ReachCare program \nINSTRUCTIONS\nBy now, the patient should have given the family member access to the patient app. It is up to the patient to decide if they want to grant access to their Safety Plan, Life Plan and Behavioral Health Action Plan: they are not obliged to grant access. But inviting the family member to the app will give the family member access to a host of self-care education and helplines that may be useful, as well as allowing them to see upcoming appointments. ",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "0b2aeb37-9030-4ef0-b0f4-4ed0f09c9149",
    form_id: "5fc9995c-ff3d-4cbf-9a53-6b08708aab31",
    field_prompt: "1.1. Was the family member invited by the patient to download the ReachCare app?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "e8780dbe-be8e-4bca-8388-0a3897e91bca",
        option_text: "Yes",
      },
      {
        id: "1a3a94aa-1ab9-436d-af6c-fcab295b8bb4",
        option_text: "No",
      },
      {
        id: "04be44ac-5391-4f63-957b-9f52b3d57442",
        option_text: "System default",
      },
    ],
  },
  {
    id: "392865f3-efba-45b9-b3d5-316b362bb2a2",
    form_id: "5fc9995c-ff3d-4cbf-9a53-6b08708aab31",
    field_prompt: "1.2. Did the family member invited download the ReachCare app? ",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "e7ba2518-fa6b-4bde-abc8-db09e69a0792",
        option_text: "Yes",
      },
      {
        id: "d39a7a07-a4a4-4264-b5cc-2d9221de5398",
        option_text: "No",
      },
      {
        id: "ed0d8044-8fc9-4022-969f-1e1ceb16b655",
        option_text: "System default",
      },
    ],
  },
  {
    id: "9560dd12-0911-409a-8d65-1bb6d32d94c1",
    form_id: "5fc9995c-ff3d-4cbf-9a53-6b08708aab31",
    field_prompt: "Step 2: Review family member’s current concerns about patient\nINSTRUCTIONS\nFamily members will often have significant concerns or issues regarding the patient. Elicit and encourage voicing of these concerns. This process both enables you obtain a different perspective on the patient and his/her issues, but also helps establish that you value and are concerned with the family member as well as the patient. Prompts include: \n•\tCan you tell me a little about how ___[patient]_____has been doing?                                                    \n•\tWhat are your main concerns about him/her?\n•\tWhat kind of supports or resources would you like him/her to have?\n•\tHow have you been coping?”",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "6d1820cc-c9c2-4648-b6a6-46aff2f937fc",
    form_id: "5fc9995c-ff3d-4cbf-9a53-6b08708aab31",
    field_prompt: "2.1. What are the family member’s main concerns about the patient? ",
    response: '',
    field_type: "LONG_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "49f1ce36-9c4c-40f0-a302-550054d1e35f",
    form_id: "5fc9995c-ff3d-4cbf-9a53-6b08708aab31",
    field_prompt: "2.2. Did you refer the family member to the fourth (“How can I support someone who is suicidal?”) handout in Self-Care Resources in the app? ",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "8eabbf1c-e799-4aae-be30-03ae4b40ab6f",
        option_text: "Yes",
      },
      {
        id: "2f580c82-01a5-487b-b013-18850d0cd669",
        option_text: "No",
      },
      {
        id: "d70dd3ba-c0c1-4d51-97d0-e3d55422826b",
        option_text: "System default",
      },
    ],
  },
  {
    id: "53a15559-c38c-4214-9869-3622b36e606d",
    form_id: "5fc9995c-ff3d-4cbf-9a53-6b08708aab31",
    field_prompt: "Step 3: Review of the patient’s Safety Plan\nINSTRUCTIONS\nNow discuss the previously developed Safety Plan. The goals of this discussion are to be certain that the family member still agrees to the plan. Given the possibility of subsequent suicidal ideation, risk and/or attempts, it will be important for both patient and family member to understand and agree with crisis protocol for dealing with subsequent increased suicidal behavior. This discussion will include topics including: \n•\treduction of access to means for suicide, especially firearms \n•\tearly identification of increased suicidal risk (Warning Signs), and \n•\tresponse options (contact treatment providers, hospitalization, police, etc).\n•\tsharing the safety plan through the ReachCare app",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "11ad2414-775c-4dfb-94c9-b884b5a71b0f",
    form_id: "5fc9995c-ff3d-4cbf-9a53-6b08708aab31",
    field_prompt: "3.1.  Did you look at (and edit if needed) the patient’s existing safety plan?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "21721051-9388-4ec2-b32f-8e6957670907",
        option_text: "Yes",
      },
      {
        id: "c79608df-cd0a-4c2b-8911-58787592bb42",
        option_text: "No",
      },
      {
        id: "3f1c348a-702d-43a1-9f7a-0f7fe8160902",
        option_text: "System default",
      },
    ],
  },
  {
    id: "750416ce-1eb0-4334-ae09-dee63afdf371",
    form_id: "5fc9995c-ff3d-4cbf-9a53-6b08708aab31",
    field_prompt: "3.2. Did you refer the family member to the third (“How can I use my safety plan?”) handout in Self-Care Resources in the app?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "34ac6b1c-a6da-4bf4-a423-7bf53438380e",
        option_text: "Yes",
      },
      {
        id: "a6a964b0-390f-4d2a-95f2-9065e4ad049b",
        option_text: "No",
      },
      {
        id: "98128825-e307-4978-9b9f-714ee1dcb24d",
        option_text: "System default",
      },
    ],
  },
  {
    id: "31793548-68e2-4420-83e8-e262effcb5d6",
    form_id: "5fc9995c-ff3d-4cbf-9a53-6b08708aab31",
    field_prompt: "Step 4: Review of the patient’s treatment engagement\nINSTRUCTIONS\nNow discuss the patient’s current treatment supports and engagement. This discussion will include topics such as: \n•\twhether the patient has established an outpatient provider \n•\twhether they are adherent/satisfied with current care\n•\twhether there are supports that the study clinician or the family member can provide to ease access to outpatient support (such as transport, encouragement, referral, etc.)",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "4841cd59-c0da-4806-a801-b5adb92e6e8a",
    form_id: "5fc9995c-ff3d-4cbf-9a53-6b08708aab31",
    field_prompt: "4.1. Did you look at the patient’s behavioral health action plan?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "9df8204c-251f-4780-a9f4-4fd2cfc933ff",
        option_text: "Yes",
      },
      {
        id: "8b90f325-cdf7-4daa-a157-4bbc89ab9307",
        option_text: "No",
      },
      {
        id: "ee62e349-4e34-472e-924f-aaaa86bd4435",
        option_text: "System default",
      },
    ],
  },
  {
    id: "d514b791-1722-4bbb-bb74-6e44aa182aee",
    form_id: "5fc9995c-ff3d-4cbf-9a53-6b08708aab31",
    field_prompt: "4.2. Did you refer the family member to the eighth (“Where can I find more support?”) handout in Self-Care Resources in the app? ",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "b683f7a2-0814-43c4-9da9-f20e96346507",
        option_text: "Yes",
      },
      {
        id: "30e7aabd-2bb4-4593-bb6d-52a18a9af106",
        option_text: "No",
      },
      {
        id: "ac513462-dcbc-4ded-baa4-4110e0d973aa",
        option_text: "System default",
      },
    ],
  },
  {
    id: "e57a12f0-f579-4732-aa71-3d0bf8632364",
    form_id: "5fc9995c-ff3d-4cbf-9a53-6b08708aab31",
    field_prompt: "Step 5: Review of the patient’s core values\nINSTRUCTIONS\nThe goal of this discussion is to help the family member to understand the patient’s core values and how they can support action towards those core values. Bear in mind that the patient may not have elected to share their Life Plan with the family and it is not advisable for you to discuss the actual content of that plan as it may be sensitive. It is sufficient for you to say that you have had a conversation with the patient about what their core values are and documented a plan for values-based living in the patient’s Life Plan. ",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "9cb63eec-807f-4314-9571-afdbed09911a",
    form_id: "5fc9995c-ff3d-4cbf-9a53-6b08708aab31",
    field_prompt: "5.1. Did you refer the family member to the fifth (“How does the Life Plan work?”) handout in Self-Care Resources in the app?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "55cce776-a68f-4c50-942d-718c706c6d26",
        option_text: "Yes",
      },
      {
        id: "9e19bf38-e23d-4716-82d6-7f7d94e0d247",
        option_text: "No",
      },
      {
        id: "89406daa-5cc2-41af-83ed-1b98e1197ca7",
        option_text: "System default",
      },
    ],
  },
  {
    id: "81c682af-471c-40f9-bf8d-72d0704098ab",
    form_id: "5fc9995c-ff3d-4cbf-9a53-6b08708aab31",
    field_prompt: "Step 6: Wrap-up\nINSTRUCTIONS\n(a)\tAsk the family member if they have any other questions or concerns \n(b)\tRemind the family member to ask the patient for an invite to the app and highlight any resources you would like them to use",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "3e2b66b0-003a-4dbb-82b5-58bbe327f68e",
    form_id: "5fc9995c-ff3d-4cbf-9a53-6b08708aab31",
    field_prompt: "6.1. Any notes or reflections not covered above?",
    response: '',
    field_type: "LONG_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "87c9872a-31d8-44b8-89ba-99adf94eaf22",
    form_id: "5fc9995c-ff3d-4cbf-9a53-6b08708aab31",
    field_prompt: "Exact end time for Family Call #2 (HH:MM)",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  }
]
var call5 = [
  {
    id: "642014a2-6699-480c-a090-32985d4fd916",
    form_id: "9fd413b7-52a2-48e6-bacd-2a14d925038d",
    field_prompt: "PATIENT CALL #3 (10 WEEKS AFTER DISCHARGE) \nIntro: Before the call, set up a time to speak to the patient where you know they’ll have privacy about 30 minutes to talk. Text the day before to remind them of the call and to let them reschedule if needed. The purpose of this second call is to check in briefly on each of the patient’s Plans, and thereby address the patient’s safety, treatment engagement, and value-based living. Being the last call, it's an important opportunity to ensure the patient is satisfied with their safety plan and their treatment situation.",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "e0da631f-6832-47a8-aa3e-9f5f10bd81d8",
    form_id: "9fd413b7-52a2-48e6-bacd-2a14d925038d",
    field_prompt: "Please use this space to document call attempts, refusals, and incomplete calls",
    response: '',
    field_type: "LONG_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "da72042f-55ea-4ead-8983-92e734a42420",
    form_id: "9fd413b7-52a2-48e6-bacd-2a14d925038d",
    field_prompt: "Date of Patient Call #3 (MM/DD/YYYY): \n",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "4a48bf4c-f197-43c4-92bb-3cb6a15910f9",
    form_id: "9fd413b7-52a2-48e6-bacd-2a14d925038d",
    field_prompt: "Exact start time of Call #3 (HH:MM)",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "283887ca-1701-42bd-be9d-b19097ff38e8",
    form_id: "9fd413b7-52a2-48e6-bacd-2a14d925038d",
    field_prompt: "Step 1: Evaluate current status \nINSTRUCTIONS\nIn each call, the clinician should begin by briefly assessing the patient’s current level of suicide risk, psychiatric symptoms and treatment, as well as recent history of ideation, attempts, ED visits or hospitalizations",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "f649f187-38b6-4add-b08c-1c60f3f9bbfa",
    form_id: "9fd413b7-52a2-48e6-bacd-2a14d925038d",
    field_prompt: "1.1. What is the patient's current status?",
    response: '',
    field_type: "LONG_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "25ff25f2-b80c-4340-a408-c207c1b08d6d",
    form_id: "9fd413b7-52a2-48e6-bacd-2a14d925038d",
    field_prompt: "Step 2: Review safety plan \nINSTRUCTIONS\nThis call represents a final opportunity to check in on the patient’s safety plan and make modifications as appropriate. \n-> -> Does the patient know where to find the plan?  (Under My Plans in the ReachCare app)\n-> -> Do they want to make any changes? For example: \n-> -> “For warning signs, you have […]. Does that still work for you?”\n-> -> “You don’t have a professional listed in your safety plan. Is there a physician or counselor you would like to add now?”\n•\tHave they tried to use the safety plan? \nAny changes you make to the safety plan on your side in FormFlash will appear in their app also. ",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "ee3e0013-251b-4e8e-9fda-b09354456bb6",
    form_id: "9fd413b7-52a2-48e6-bacd-2a14d925038d",
    field_prompt: "2.1. Did you look at (and edit if needed) the patient’s existing safety plan in FormFlash? ",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "39758de7-82fe-48b0-aa5d-c18c2778cec9",
        option_text: "Yes",
      },
      {
        id: "3e4dcb2b-97bc-452e-bb8a-0f57d04b53cd",
        option_text: "No",
      },
      {
        id: "a32d4e92-585e-4797-bd23-6e4c5aa1bc74",
        option_text: "System default",
      },
    ],
  },
  {
    id: "8a0f8534-c04f-4d23-a0bd-13b02d59c0fc",
    form_id: "9fd413b7-52a2-48e6-bacd-2a14d925038d",
    field_prompt: "2.2. Did you refer the patient to the third (“How can I use my safety plan?”) handout in Self-Care Resources in the app?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "f9bba8f8-b144-4366-b00b-a34068ea8911",
        option_text: "Yes",
      },
      {
        id: "bc00d242-7aea-41d3-b67a-ed346f90d0df",
        option_text: "No",
      },
      {
        id: "0e265fd5-4e06-4f92-8b38-d1d9f582cd62",
        option_text: "System default",
      },
    ],
  },
  {
    id: "dd17ae39-f7d8-433e-8013-975d92281a52",
    form_id: "9fd413b7-52a2-48e6-bacd-2a14d925038d",
    field_prompt: "Step 3: Review of outpatient treatment plan and providers \n\nINSTRUCTIONS\nBy now, the patient should have ongoing outpatient behavioral support if they need it. If the patient does not have providers or a plan, then a goal should be to help the patient establish these contacts. This being the final TASCS call, please ensure that the patient has the opportunity to connect with behavioral supports if they want or need them. The patient’s next steps for behavioral health self-management can be listed on their Behavioral Health Action Plan: any changes you make to the BHAP on your side in FormFlash will appear in their app also. ",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "cac447c5-9ee3-4359-a470-41f37ef493c5",
    form_id: "9fd413b7-52a2-48e6-bacd-2a14d925038d",
    field_prompt: "3.1. What is patient’s current behavioral health treatment engagement?",
    response: '',
    field_type: "LONG_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "beef4d95-d885-4a5c-8cdb-4ce7aba020f6",
    form_id: "9fd413b7-52a2-48e6-bacd-2a14d925038d",
    field_prompt: "3.2. Did you refer to the patient’s Behavioral Health Action Plan?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "3084ed0b-8717-43ef-8f48-e4ea55ef8e3d",
        option_text: "Yes",
      },
      {
        id: "dffc41d9-cdf4-4c73-8a5f-ae0d0295740e",
        option_text: "No",
      },
      {
        id: "1724feb9-aff4-494f-b5ca-a79a89814351",
        option_text: "System default",
      },
    ],
  },
  {
    id: "eda94955-9385-4d62-9c3e-c3d69b50fa14",
    form_id: "9fd413b7-52a2-48e6-bacd-2a14d925038d",
    field_prompt: "3.3. Did you use the outpatient referral engine or refer the patient to it? ",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "5d644728-4176-4f22-aae7-0627f10ab8d4",
        option_text: "Yes",
      },
      {
        id: "efd48b29-f2b9-433b-86fc-9bdbaa9bc3ec",
        option_text: "No",
      },
      {
        id: "04792f92-0b5e-4eb5-9f67-1de2a8521da2",
        option_text: "System default",
      },
    ],
  },
  {
    id: "1c0eaeca-3666-47e7-b6fb-f885ff030501",
    form_id: "9fd413b7-52a2-48e6-bacd-2a14d925038d",
    field_prompt: "3.4. Did you offer to make a call to a BH provider to connect the patient? ",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "36826eb6-a80e-484c-97a0-59582cdaedde",
        option_text: "Yes",
      },
      {
        id: "6bcf9976-8515-424c-bc8a-17c89ed0bd70",
        option_text: "No",
      },
      {
        id: "bfcecb75-71c4-48f6-8df4-7c3f1ced56f7",
        option_text: "System default",
      },
    ],
  },
  {
    id: "6ecb8671-53c0-4e61-9c1c-1b2780f60d42",
    form_id: "9fd413b7-52a2-48e6-bacd-2a14d925038d",
    field_prompt: "Step 4: Review of the patient’s Life Plan  \nINSTRUCTIONS\nThe Life Plan provides a positive action plan based on the patient’s values and goals. The Life Plan is available to the patient in the ReachCare app, with supportive explanatory materials in the Self-Care Education section of the app. ",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "4f9dc7ae-da2e-499d-a51b-1417450d97ab",
    form_id: "9fd413b7-52a2-48e6-bacd-2a14d925038d",
    field_prompt: "4.1. Did you look at (and edit if needed) the patient’s Life Plan?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "6d59aafb-8fe1-4901-9a13-b9679193801b",
        option_text: "Yes",
      },
      {
        id: "e138c886-5606-47dc-9751-1eef87123706",
        option_text: "No",
      },
      {
        id: "a374de6c-f0dc-4f31-8227-a10e158e4eec",
        option_text: "System default",
      },
    ],
  },
  {
    id: "42a12535-6b26-480a-a459-a156efeca44a",
    form_id: "9fd413b7-52a2-48e6-bacd-2a14d925038d",
    field_prompt: "4.2. Did you refer the patient to the fifth (“How does the Life Plan work?”) handout in Self-Care Resources in the app?",
    response: '',
    field_type: "SINGLE_CHOICE",
    form_field_options: [
      {
        id: "a5a3ba08-fce2-4244-abca-c09bbf74b9ee",
        option_text: "Yes",
      },
      {
        id: "7a1973ce-c4dc-4f61-8002-d487f75825f1",
        option_text: "No",
      },
      {
        id: "dc835d41-ed78-40aa-b51b-3955deefdf62",
        option_text: "System default",
      },
    ],
  },
  {
    id: "d6070acd-3c40-4c7d-9556-fc3807e32959",
    form_id: "9fd413b7-52a2-48e6-bacd-2a14d925038d",
    field_prompt: "Step 5: Wrap-up\nINSTRUCTIONS\nThis third patient call is intended to be the final TASCS call, so there is a not a need to schedule a follow-on call unless there is a clinical need. If there is a clinical need for another call, the goal should be to link the patient to ongoing behavioral health support in the community. \n(a)\tAsk the patient if they have any other questions or concerns\n(b)\tRemind the patient of any app resources you would like them to us",
    response: '',
    field_type: "INFO_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "9f0f8924-d100-4901-a65a-2c61751b3c43",
    form_id: "9fd413b7-52a2-48e6-bacd-2a14d925038d",
    field_prompt: "5.1. Any notes or reflections not covered above?",
    response: '',
    field_type: "LONG_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
  {
    id: "44beeb9a-5612-4a5b-bc2a-45efd3884a7b",
    form_id: "9fd413b7-52a2-48e6-bacd-2a14d925038d",
    field_prompt: "5.2. Exact end time for Patient Call #3",
    response: '',
    field_type: "SHORT_TEXT",
    form_field_options: [
      {
        id: null,
        option_text: null
      },
    ],
  },
]

var formName = [
  { name: 'Safety Plan - TASCS' },
  { name: 'Life Plan - TASCS' },
  { name: 'BHAP-TASCS' },
  { name: '1. Patient Call #1 form (ED + 2WKS)' },
  { name: '2. Family Call #1 Form (ED + 4 WKS)' },
  { name: '3. Patient Call #2 form (ED + 6 WKS)' },
  { name: '4. Family Call #2 Form (ED + 8 WKS)' },
  { name: '5. Patient Call #3 form (ED +10 WKS)' }
]

export const getFormResponsesThunk = ({ formInstanceId = '', plan }: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    dispatch(
      setLoadingAction('getFormInstanceResponsesLoading', true, 'getFormInstanceResponsesError')
    );
    const form_version_id = sessionStorage.getItem('version');
    const formResponses: any = await getHttp(
      `/response/form/instance/${formInstanceId}/get-form-responses?form_version_id=${form_version_id}`
    );
    var result = [];
    if (plan == "BHAP-TASCS") {
      for (let i = 0; i < bhap.length; i++) {
        let info = formResponses.responses.filter((obj: any) => obj.id === bhap[i].id);
        if (info.length > 0) {
          bhap[i].response = info[0].response
          result.push(bhap[i])
        }
      }
      formResponses.responses = result;
    } else if (plan == "Life Plan - TASCS") {
      for (let i = 0; i < life.length; i++) {
        let info = formResponses.responses.filter((obj: any) => obj.id === life[i].id);
        if (info.length > 0) {
          life[i].response = info[0].response
          result.push(life[i])
        }
      }
      formResponses.responses = result;
    } else if (plan == "1. Patient Call #1 form (ED + 2WKS)") {
      for (let i = 0; i < call1.length; i++) {
        let info = formResponses.responses.filter((obj: any) => obj.id === call1[i].id);
        if (info.length > 0) {
          call1[i].response = info[0].response
          result.push(call1[i])
        }
      }
      formResponses.responses = result;
    } else if (plan == "2. Family Call #1 Form (ED + 4 WKS)") {
      for (let i = 0; i < call2.length; i++) {
        let info = formResponses.responses.filter((obj: any) => obj.id === call2[i].id);
        if (info.length > 0) {
          call2[i].response = info[0].response
          result.push(call2[i])
        }
      }
      formResponses.responses = result;
    } else if (plan == "3. Patient Call #2 form (ED + 6 WKS)") {
      for (let i = 0; i < call3.length; i++) {
        let info = formResponses.responses.filter((obj: any) => obj.id === call3[i].id);
        if (info.length > 0) {
          call3[i].response = info[0].response
          result.push(call3[i])
        }
      }
      formResponses.responses = result;
    } else if (plan == "4. Family Call #2 Form (ED + 8 WKS)") {
      for (let i = 0; i < call4.length; i++) {
        let info = formResponses.responses.filter((obj: any) => obj.id === call4[i].id);
        if (info.length > 0) {
          call4[i].response = info[0].response
          result.push(call4[i])
        }
      }
      formResponses.responses = result;
    } else if (plan == "5. Patient Call #3 form (ED +10 WKS)") {
      for (let i = 0; i < call5.length; i++) {
        let info = formResponses.responses.filter((obj: any) => obj.id === call5[i].id);
        if (info.length > 0) {
          call5[i].response = info[0].response
          result.push(call5[i])
        }
      }
      formResponses.responses = result;
    } else if (plan == "Safety Plan - TASCS") {
      for (let i = 0; i < safety.length; i++) {
        let info = formResponses.responses.filter((obj: any) => obj.id === safety[i].id);
        if (info.length > 0) {
          safety[i].response = info[0].response
          result.push(safety[i])
        }
      }
      formResponses.responses = result;
    }
    await dispatch(setFormResponsesAction(formResponses));
    dispatch(
      setLoadingAction('getFormInstanceResponsesLoading', false, 'getFormInstanceResponsesError')
    );
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('getFormInstanceResponsesError', e.message));
      dispatch(snackbarAlertAction(`Error loading Form Responses: ${e.message}`, 'error'));
    }
  }
};

export const getChangeLogsThunk = ({ formInstanceId = '', patientId = '' }: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    const formRespChangeLogs: FormResponseChangeLogs = await getHttp(
      `/user/change-logs?patientId=${patientId}&formInstanceId=${formInstanceId}&search&page=${0}&pageSize=${50}&sort&order&form_version_id=${form_version_id}`
    );
    dispatch(setFormRespChangeLogsAction(formRespChangeLogs));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('getFormInstanceResponsesError', e.message));
    }
  }
};

export interface GetFormInstancesByPatientProps {
  patientFormInstancesType: PatientFormInstancesType;
  patientId: UuidType;
  search?: string;
  page?: number;
  pageSize?: number;
  sort?: string;
  order?: string;
}
export const getFormInstancesByPatientThunk = ({
  patientFormInstancesType,
  patientId,
  search = '',
  page = 0,
  pageSize = 10,
  sort = 'created_at',
  order = 'DESC'
}: GetFormInstancesByPatientProps) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
    try {
      dispatch(
        setLoadingAction('getFormInstancesByPatientLoading', true, 'getFormInstancesByPatientError')
      );
      const form_version_id = sessionStorage.getItem('version');
      const patientForms: FormInstanceSearchResults = await getHttp(
        `/response/form/instance/patient/${patientId}?search=${search}&page=${page}&pageSize=${pageSize}&completedByPatient=${patientFormInstancesType === 'oneTime'
        }&sort=${sort}&order=${order}&form_version_id=${form_version_id}`
      );

      var result = [];

      if (patientForms) {
        for (let i = 0; i < formName.length; i++) {
          let info = patientForms.formInstanceResults.filter((obj: any) => obj.title == formName[i].name);
          if (info.length > 0) {
            result.push(info[0])
          }
        }

        patientForms.formInstanceResults = result;

      }

      if (patientForms) {
        dispatch(getPatientFormInstancesAction(patientFormInstancesType, patientForms));
        dispatch(setSuccessAction('getFormInstancesByPatientSuccess', true));
      } else {
        dispatch(snackbarAlertAction(`Error loading patient forms`, 'error'));
      }
    } catch (e) {
      if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
        dispatch(push('/login'));
      } else {
        dispatch(snackbarAlertAction(`Error loading patient forms: ${e.message}`, 'error'));
      }
    } finally {
      dispatch(
        setLoadingAction('getFormInstancesByPatientLoading', false, 'getFormInstancesByPatientError')
      );
    }
  };

export const getFormInstanceByIdThunk = (formInstanceId: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    dispatch(setLoadingAction('getFormInstanceLoading', true, 'getFormInstanceError'));
    const form_version_id = sessionStorage.getItem('version');
    const formInstance: FormInstance = await getHttp(`/response/form/instance/${formInstanceId}?form_version_id=${form_version_id}`);
    if (formInstance) {
      //get and set initial question for preview instance
      dispatch(setDisplayFormInstanceAction(formInstance));
      dispatch(getNextQuestionThunk(formInstance.id, 1));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('getFormInstanceError', e.message));
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const sendIncompleteFormReminderThunk = (formInstanceId: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  //setId of formInstance in redux
  dispatch(sendReminderFormInstanceIdAction(formInstanceId));
  try {
    dispatch(
      setLoadingAction('sendIncompleteFormReminderLoading', true, 'sendIncompleteFormReminderError')
    );
    const form_version_id = sessionStorage.getItem('version');
    await postHttp(`/response/form/${formInstanceId}/send-reminder`, { form_version_id: form_version_id });
    dispatch(snackbarAlertAction(`Successfully sent email reminder`, 'success'));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('sendIncompleteFormReminderError', e.message));
      dispatch(snackbarAlertAction(`Error sending reminder: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(sendReminderFormInstanceIdAction(null));
    dispatch(
      setLoadingAction(
        'sendIncompleteFormReminderLoading',
        false,
        'sendIncompleteFormReminderError'
      )
    );
  }
};

export const deleteFormInstanceThunk = (formInstanceId: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    // reset forms list update success state if it's set to true
    if (getState().DISPLAY_STATE_REDUCER.displaySuccess.getFormInstancesByPatientSuccess) {
      dispatch(setSuccessAction('getFormInstancesByPatientSuccess', false));
    }

    dispatch(setLoadingAction('deleteFormInstanceLoading', true, 'deleteFormInstanceError'));
    await deleteHttp(`/response/form/instance/${formInstanceId}?form_version_id=${form_version_id}`);
    dispatch(setSuccessAction('deleteFormInstanceSuccess', true));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error unassigning form to patient: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('deleteFormInstanceLoading', false, 'deleteFormInstanceError'));
  }
};

export const createFormPreviewThunk = (formId: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  //clear any preexisting display questions or exit pages in state
  dispatch(setDisplayFormFieldPayloadAction(null));
  dispatch(setDisplayExitPageAction(null));
  try {
    const form_version_id = sessionStorage.getItem('version');
    dispatch(setLoadingAction('createFormPreviewLoading', true, 'createFormPreviewError'));
    const formPreview: FormInstance = await postHttp(
      `/response/form/${formId}/preview-instance`,
      { form_version_id: form_version_id }
    );
    if (formPreview) {
      //get and set initial question for preview instance
      dispatch(setDisplayFormInstanceAction(formPreview));
      dispatch(getNextQuestionThunk(formPreview.id, 1));
      dispatch(setLoadingAction('createFormPreviewLoading', false, 'createFormPreviewError'));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('createFormPreviewError', e.message));
    }
  }
};

/**Patient portal form preview instance */
export const patientFormPreviewThunk = (instanceId: any, formId: any) => async (
  dispatch: FlowproThunkDispatch
) => {
  //clear any preexisting display questions or exit pages in state
  dispatch(setDisplayFormFieldPayloadAction(null));
  dispatch(setDisplayExitPageAction(null));
  try {
    dispatch(setLoadingAction('createFormPreviewLoading', true, 'createFormPreviewError'));
    const formPreview: any = await getHttp(
      `/response/form/instance/${instanceId}/getCurrentQuestionIndex`);
    if (formPreview) {
      //get and set initial question for preview instance
      formPreview.id = formPreview.form_instance_id;
      formPreview.form_id = formId;
      sessionStorage.setItem('index', formPreview.currentCompletedQuestionIndex === 0 ? 1 : formPreview.currentCompletedQuestionIndex)
      dispatch(setDisplayFormInstanceAction(formPreview));
      dispatch(getNextQuestionThunk(formPreview.form_instance_id, formPreview.currentCompletedQuestionIndex === 0 ? 1 : formPreview.currentCompletedQuestionIndex));
      dispatch(setLoadingAction('createFormPreviewLoading', false, 'createFormPreviewError'));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('createFormPreviewError', e.message));
    }
  }
};

export const backFetchFormToDisplayByIdThunk = (formId: UuidType, instanceId: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    dispatch(setLoadingAction('getFormToDisplayLoading', true, 'getFormToDisplayError'));
    const form = await getHttp(`/form/${formId}?form_version_id=${form_version_id}`);
    if (form) {
      dispatch(setLoadingAction('getFormToDisplayLoading', false, 'getFormToDisplayError'));
      dispatch(setDisplayFormAction(form));
      dispatch(getNextQuestionThunk(instanceId, 1));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error retrieving form: ${e.message}`, 'error'));
      dispatch(setErrorAction('getFormToDisplayError', e.message));
    }
  }
};

export const getNextQuestionThunk = (formInstanceId: UuidType, index: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    dispatch(setLoadingAction('getNextQuestionLoading', true, 'getNextQuestionError'));
    const form_version_id = sessionStorage.getItem('version');
    const nextQuestion = await getHttp(`/response/form/instance/${formInstanceId}/next-question/${index}?form_version_id=${form_version_id}`);
    if (nextQuestion) {
      //if the form has been completed fetch exitpage
      if (nextQuestion.done) {
        dispatch(getExitPageThunk(formInstanceId));
      } else {
        nextQuestion.queNo = index;
        dispatch(setDisplayFormFieldPayloadAction(nextQuestion));
      }
      dispatch(setLoadingAction('getNextQuestionLoading', false, 'getNextQuestionError'));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('getNextQuestionError', e.message));
    }
  }
};

export const getPreviousQuestionThunk = (formInstanceId: any, queNo: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    dispatch(setLoadingAction('getNextQuestionLoading', true, 'getNextQuestionError'));
    const previousQuestion = await getHttp(`/response/form/instance/${formInstanceId}/previous-question/${queNo}?form_version_id=${form_version_id}`);
    if (previousQuestion) {
      const data = {
        completed: previousQuestion.completed,
        done: previousQuestion.done,
        nextQuestion: previousQuestion.previousQuestion,
        total: previousQuestion.total,
        form_response: previousQuestion.form_response,
        queNo: queNo
      }
      dispatch(setDisplayFormFieldPayloadAction(data));
      dispatch(setLoadingAction('getNextQuestionLoading', false, 'getNextQuestionError'));
      return data;
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('getNextQuestionError', e.message));
    }
  }
};

export const getExitPageThunk = (formInstanceId: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    dispatch(setLoadingAction('getExitPageLoading', true, 'getExitPageError'));
    const exitPageResp = await getHttp(`/response/form/instance/${formInstanceId}/exit-page?form_version_id=${form_version_id}`);
    if (exitPageResp) {
      exitPageResp.exitPage.formInstanceId = exitPageResp.form_instance_id;
      dispatch(setDisplayExitPageAction(exitPageResp.exitPage));
    }
    dispatch(setLoadingAction('getExitPageLoading', false, 'getExitPageError'));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(e.message, 'error'));
      dispatch(setErrorAction('getExitPageError', e.message));
    }
  }
};

export const submitFieldResponseThunk = (
  formInstanceId: UuidType,
  formFieldId: UuidType,
  formFieldResponse: any,
  index: any
) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    formFieldResponse.form_version_id = form_version_id;
    dispatch(setLoadingAction('submitFieldResponseLoading', true, 'submitFieldResponseError'));
    const fieldResponse = await putHttp(
      `/response/form/instance/${formInstanceId}/field/${formFieldId}`,
      { ...formFieldResponse }
    );
    if (fieldResponse) {
      dispatch(getNextQuestionThunk(formInstanceId, index));
      dispatch(setLoadingAction('submitFieldResponseLoading', false, 'submitFieldResponseError'));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('submitFieldResponseError', e.message));
    }
  }
};

export const assignFormToPatientThunk = (
  completedByPatient: boolean,
  formId: UuidType,
  patientId: UuidType,
  dueDate: Date | null
) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    // reset forms list update success state if it's set to true
    if (getState().DISPLAY_STATE_REDUCER.displaySuccess.getFormInstancesByPatientSuccess) {
      dispatch(setSuccessAction('getFormInstancesByPatientSuccess', false));
    }

    dispatch(setLoadingAction('assignFormToPatientLoading', true, 'assignFormToPatientError'));
    await postHttp(`/response/form/${formId}/instance`, {
      completed_by_patient: completedByPatient,
      patient_id: patientId,
      due_date: dueDate,
      form_version_id: form_version_id
    });
    dispatch(setSuccessAction('assignFormToPatientSuccess', true));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error assigning form to patient: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('assignFormToPatientLoading', false, 'assignFormToPatientError'));
  }
};

export const downloadFormResponsePDFThunk = (formInstanceId: UuidType, fileName?: string) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    dispatch(
      setLoadingAction('downloadingFormResponsePdfLoading', true, 'downloadingFormResponsePdfError')
    );
    const form_version_id = sessionStorage.getItem('version');
    await downloadFileHttp(`/response/form/instance/${formInstanceId}/results-pdf`, fileName);
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error downloading pdf: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(
      setLoadingAction(
        'downloadingFormResponsePdfLoading',
        false,
        'downloadingFormResponsePdfError'
      )
    );
  }
};

/**Download form data & form PDF */
export const downloadFormPDFThunk = (form_instance_id: string) => async (
  dispatch: FlowproThunkDispatch, getState: () => RootState
) => {
  try {
    const res: any = await postHttp(`/response/downloadFormLatestAnswers`, {
      form_instance_id: form_instance_id,
    });
    if (res) {
      const response: any = await postHttp(`/user/auth/getSignedDownloadUrls`, {
        input_files: [res.file_url],
      });
      dispatch(snackbarAlertAction(`${res.message}`, 'success'));
      if (response) {
        var link = document.createElement("a");
        link.download = 'downloadForm.xlsx';
        link.href = response.url[0];
        document.body.appendChild(link);
        link.click();
      }
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error downloading pdf: ${e.message}`, 'error'));
    }
  }
};

export const downloadFormDataThunk = (form_instance_id: string) => async (
  dispatch: FlowproThunkDispatch, getState: () => RootState
) => {
  try {
    const res: any = await postHttp(`/response/downloadFormLog`, {
      form_instance_id: form_instance_id,
    });
    if (res) {
      const response: any = await postHttp(`/user/auth/getSignedDownloadUrls`, {
        input_files: [res.file_url],
      });
      dispatch(snackbarAlertAction(`${res.message}`, 'success'));
      if (response) {
        var link = document.createElement("a");
        link.download = 'downloadData.xlsx';
        link.href = response.url[0];
        document.body.appendChild(link);
        link.click();
      }
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error downloading data: ${e.message}`, 'error'));
    }
  }
};