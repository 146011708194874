import { push } from 'connected-react-router';
import { getSettingTagsAction } from '../data/data-actions';
import { setSuccessAction, setErrorAction } from './../display/display-actions';
import { postHttp, getHttp, deleteHttp, putHttp } from './../api';
import { FlowproThunkDispatch, RootState } from '../redux-store';
import {
  StyleSheetInput,
  StyleInput,
} from '../../shared/domain';
import { snackbarAlertAction } from '../display/display-actions';
import { ROLES } from '../../shared/globals';

export const saveFlowsheetStyle = (StyleSheetInput: StyleSheetInput) => async (
  dispatch: FlowproThunkDispatch
) => {
  try {
    const res = await postHttp('/user/auth/flowsheet', {
      StyleSheetInput
    });
    dispatch(setSuccessAction('changePasswordSuccess', true));
    dispatch(snackbarAlertAction(`FlowSheet style saved successfully`, 'success'));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`FlowSheet Style: ${e.message}`, 'error'));
      dispatch(setErrorAction('changePasswordError', e.message));
    }
  }
};

/** Tags APIs */
export interface GetTagsProps {
  order?: string;
  skip?: number;
  limit?: number;
  search?: string;
  sort?: string;
}
export const getSettingTagsThunk = ({
  order = 'desc',
  skip = 0,
  limit = 10,
  search = '',
  sort = 'title'
}: GetTagsProps) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    const tags: any = await getHttp(
      `/tag/getAllTagsWithFilter?search=${search}&skip=${skip * limit}&limit=${limit}&sort=${sort}&order=${order}`
    );
    if (tags) {
      tags.search = search;
      tags.skip = skip;
      tags.limit = limit;
      tags.sort = sort;
      tags.order = order;
      dispatch(getSettingTagsAction(tags));
    } else {
      dispatch(snackbarAlertAction(`Error loading forms`, 'error'));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error loading forms: ${e.message}`, 'error'));
    }
  }
};

export const deleteTagThunk = (tagId: string) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    await deleteHttp(`/tag/${tagId}`);
    dispatch(getSettingTagsThunk({}));
    dispatch(snackbarAlertAction(`Delete tag Successfully`, 'success'));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error deleting tag: Tag is already in use!`, 'warning'));
    }
  }
};

export const editTagThunk = (data: any) => async (
  dispatch: FlowproThunkDispatch
) => {
  try {
    const res = await putHttp(`/tag/${data.id}`, {
      new_tag_name: data.tag
    });
    dispatch(snackbarAlertAction(`Updated tag successfully`, 'success'));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error updating tag: ${e.message}`, 'error'));
    }
  }
};
