import { getProfileInfoAction, getAllAdmins, getReachCareOrgId } from '../data/data-actions';
import { snackbarAlertAction, setSuccessAction, setErrorAction } from '../display/display-actions';
import { postHttp, putHttp, getHttp } from '../api';
import { RootState, FlowproThunkDispatch } from '../redux-store';
import { push } from 'connected-react-router';

export const updateProfileInfoThunk = (appointmentId: string, patientInput: any, timezone: any) => async (
    dispatch: FlowproThunkDispatch,
    getState: () => RootState
) => {
    try {
        let newStr = patientInput.date + ' ' + patientInput.startTime
        const startDate = new Date(newStr);
        const newStartDate = startDate.toISOString()
        let newStr1 = patientInput.date + ' ' + patientInput.endTime
        const endDate = new Date(newStr1);
        const newEndDate = endDate.toISOString()
        const saveAppointment = await putHttp(`/appointment/${appointmentId}`, {
            startDate: newStartDate,
            endDate: newEndDate,
            notes: patientInput.description,
            timezone: timezone
        });
        if (saveAppointment) {
            dispatch(setSuccessAction('editProfileSuccess', true));
            dispatch(snackbarAlertAction(`Updated appointment successfully`, 'success'));
        }
    } catch (e) {
        if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
            dispatch(push('/login'));
        } else {
            dispatch(snackbarAlertAction(`Error creating resources: ${e.message}`, 'error'));
        }
    }
};

export const getProfileInfoThunk = (patientId: string) => async (
    dispatch: FlowproThunkDispatch,
    getState: () => RootState
) => {
    try {
        dispatch(setErrorAction('getProfileError', null));
        const patientFamily: any = await postHttp(`/user/patient/family-member-details`, {
            "patientId": patientId
        });
        dispatch(getProfileInfoAction(patientFamily));
    } catch (e) {
        if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
            dispatch(push('/login'));
        } else {
            dispatch(getProfileInfoAction(null));
            dispatch(setErrorAction('getProfileError', e.message));
        }
    }
};

export const sendCommentThunk = (comments: string) => async (
    dispatch: FlowproThunkDispatch,
    getState: () => RootState
) => {
    try {
        const result: any = await postHttp(`/contact-us/sendEmailToSuperAdmin`, {
            "comments": comments
        });
        dispatch(setSuccessAction('saveDataSuccess', true));
        dispatch(snackbarAlertAction(`Email sent to super-admin successfully`, 'success'));
    } catch (e) {
        if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
            dispatch(push('/login'));
        } else {
            dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
        }
    }
};

export const getAdminListThunk = () => async (
    dispatch: FlowproThunkDispatch, getState: () => RootState
) => {
    try {
        const result: any = await getHttp(`/contact-us/getAdminList`);
        dispatch(getAllAdmins(result.data));
    } catch (e) {
        if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
            dispatch(push('/login'));
        } else {
            dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
        }
    }
};

export const requestSupportThunk = (data: any) => async (
    dispatch: FlowproThunkDispatch, getState: () => RootState
) => {
    try {
        const result: any = await postHttp(`/contact-us/giveAccountAccessToAdmin`, data);
        dispatch(setSuccessAction('saveDataSuccess', true));
        dispatch(snackbarAlertAction(`Account access given to admin`, 'success'));
    } catch (e) {
        if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
            dispatch(push('/login'));
        } else {
            dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
        }
    }
};

export const getReachCareOrgIDThunk = () => async (
    dispatch: FlowproThunkDispatch, getState: () => RootState
) => {
    try {
        const result: any = await getHttp(`/reachcare/getOrgID`);
        dispatch(getReachCareOrgId(result.orgID));
    } catch (e) {
        if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
            dispatch(push('/login'));
        } else {
            dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
        }
    }
};