import {
  FormInstanceResponses,
  FormResponseChangeLogs,
  PatientTasksResults,
  StaticResourceSearchResults,
  User,
  NextQuestionPayload,
  StaticResourceUploadResponse,
  BundleSearchResults,
  AppointmentSearchResults,
  Tag,
  TagsSearchResults,
  ReferralSearchResults,
  Referral,
  ReferralFilterListResults,
  PatientFamilyInfo
} from './../../shared/domain.d';
import { Action } from 'redux';
import {
  Calculation,
  Collection,
  CollectionAssignments,
  CollectionSearchResults,
  ExitPage,
  ExitPageFieldLogic,
  ExitPageMetricLogic,
  ExitPageUpdate,
  Form,
  FormField,
  FormFieldLogic,
  FormFieldUpdate,
  FormInstance,
  FormInstanceSearchResults,
  FormSearchResults,
  Metric,
  Patient,
  PatientSearchResults,
  UnitSearchResults,
  UserSearchResults,
  UuidType
} from '../../shared/domain';

export const SET_LOGGED_IN_USER_A = 'SET_LOGGED_IN_USER_A';
export interface SetLoggedInUserAction extends Action<typeof SET_LOGGED_IN_USER_A> {
  user: User | null;
}
export const setLoggedInUser = (user: User | null) => {
  return {
    type: SET_LOGGED_IN_USER_A,
    user
  };
};

export const SET_INVITED_USER_A = 'SET_INVITED_USER_A';
export interface SetInvitedUserAction extends Action<typeof SET_INVITED_USER_A> {
  invitedUser: User | null;
}
export const setInvitedUser = (invitedUser: User | null) => {
  return {
    type: SET_INVITED_USER_A,
    invitedUser
  };
};

/**Get patient info */
export const SET_PATIENT_INFO = 'SET_PATIENT_INFO';
export interface SetPatientInfoAction extends Action<typeof SET_PATIENT_INFO> {
  patient: {} | null;
}
export const setPatientInfo = (patient: {} | null) => {
  return {
    type: SET_PATIENT_INFO,
    patient
  };
};


/**Get patient info */
export const SET_SHARED_USER_INFO = 'SET_SHARED_USER_INFO';
export interface SetSharedUserInfoAction extends Action<typeof SET_SHARED_USER_INFO> {
  sharedUser: {} | null;
}
export const setSharedUserInfo = (sharedUser: {} | null) => {
  return {
    type: SET_SHARED_USER_INFO,
    sharedUser
  };
};

/**Get verifyed user Info */
export const GET_VERIFYED_USER_INFO = 'GET_VERIFYED_USER_INFO';
export interface GetVerifyUserInfoAction extends Action<typeof GET_VERIFYED_USER_INFO> {
  verifyUser: {} | null;
}
export const getVerifyUserInfo = (verifyUser: {} | null) => {
  return {
    type: GET_VERIFYED_USER_INFO,
    verifyUser
  };
};

/**Get sso login page */
export const GET_SSO_LOGIN_PAGE = 'GET_SSO_LOGIN_PAGE';
export interface GetLoginSSOPageAction extends Action<typeof GET_SSO_LOGIN_PAGE> {
  showSSOLoginPage: {} | null;
}
export const getLoginSSOPageAction = (showSSOLoginPage: {} | null) => {
  return {
    type: GET_SSO_LOGIN_PAGE,
    showSSOLoginPage
  };
};

export const FETCH_FORMS_A = 'FETCH_FORMS_A';
export type FetchFormsAction = Action<typeof FETCH_FORMS_A>;
export const fetchFormsAction = () => {
  return {
    type: FETCH_FORMS_A
  };
};

export const SET_DISPLAY_FORM_INSTANCE_A = 'SET_DISPLAY_FORM_INSTANCE_A';
export interface SetDisplayFormInstanceAction extends Action<typeof SET_DISPLAY_FORM_INSTANCE_A> {
  formInstance: FormInstance | null;
}
export const setDisplayFormInstanceAction = (formInstance: FormInstance | null) => {
  return {
    type: SET_DISPLAY_FORM_INSTANCE_A,
    formInstance
  };
};

export const SET_DISPLAY_FORM_FIELD_PAYLOAD_A = 'SET_DISPLAY_FORM_FIELD_PAYLOAD_A';
export interface SetDisplayFormFieldPayloadAction
  extends Action<typeof SET_DISPLAY_FORM_FIELD_PAYLOAD_A> {
  formFieldPayload: NextQuestionPayload | null;
}
export const setDisplayFormFieldPayloadAction = (formFieldPayload: NextQuestionPayload | null) => {
  return {
    type: SET_DISPLAY_FORM_FIELD_PAYLOAD_A,
    formFieldPayload
  };
};

export const SET_DISPLAY_EXIT_PAGE_A = 'SET_DISPLAY_EXIT_PAGE_A';
export interface SetDisplayExitPageAction extends Action<typeof SET_DISPLAY_EXIT_PAGE_A> {
  exitPage: ExitPage | null;
}
export const setDisplayExitPageAction = (exitPage: ExitPage | null) => {
  return {
    type: SET_DISPLAY_EXIT_PAGE_A,
    exitPage
  };
};

export const SET_FORM_RESPONSES_A = 'SET_FORM_RESPONSES_A';
export interface SetFormResponsesAction extends Action<typeof SET_FORM_RESPONSES_A> {
  formResponses: any;
}
export const setFormResponsesAction = (formResponses: FormInstanceResponses | null) => {
  return {
    type: SET_FORM_RESPONSES_A,
    formResponses
  };
};

export const SET_FORM_RESP_CHANGE_LOGS_A = 'SET_FORM_RESP_CHANGE_LOGS_A';
export interface SetFormRespChangeLogsAction extends Action<typeof SET_FORM_RESP_CHANGE_LOGS_A> {
  formRespChangeLogs: any;
}
export const setFormRespChangeLogsAction = (formRespChangeLogs: FormResponseChangeLogs | null) => {
  return {
    type: SET_FORM_RESP_CHANGE_LOGS_A,
    formRespChangeLogs
  };
};

export const SET_EDITING_FORM_A = 'SET_EDITING_FORM_A';
export interface SetEditingFormAction extends Action<typeof SET_EDITING_FORM_A> {
  form: Form;
}
export const setEditingFormAction = (form: any) => {
  return {
    type: SET_EDITING_FORM_A,
    form
  };
};

export const SET_DISPLAY_FORM_A = 'SET_DISPLAY_FORM_A';
export interface SetDisplayFormAction extends Action<typeof SET_DISPLAY_FORM_A> {
  form: Form | null;
}
export const setDisplayFormAction = (form: Form | null) => {
  return {
    type: SET_DISPLAY_FORM_A,
    form
  };
};

export const SET_EDITING_FORM_FIELDS_A = 'SET_EDITING_FORM_FIELDS_A';
export interface SetEditingFormFieldsAction extends Action<typeof SET_EDITING_FORM_FIELDS_A> {
  formFields: FormField[];
}
export const setEditingFormFieldsAction = (formFields: FormField[]) => {
  return {
    type: SET_EDITING_FORM_FIELDS_A,
    formFields
  };
};

export const SET_EDITING_FORM_FIELD_A = 'SET_EDITING_FORM_FIELD_A';
export interface SetEditingFormFieldAction extends Action<typeof SET_EDITING_FORM_FIELD_A> {
  formField: FormFieldUpdate | null;
}
export const setEditingFormFieldAction = (formField: FormFieldUpdate | null) => {
  return {
    type: SET_EDITING_FORM_FIELD_A,
    formField
  };
};

/**Get exist pages by form id */
export const SET_EDITING_EXIT_PAGES_A = 'SET_EDITING_EXIT_PAGES_A';
export interface SetEditingExitPagesAction extends Action<typeof SET_EDITING_EXIT_PAGES_A> {
  exitPages: ExitPage[] | null;
}
export const setEditingExitPagesAction = (exitPages: ExitPage[] | null) => {
  return {
    type: SET_EDITING_EXIT_PAGES_A,
    exitPages
  };
};

/**Get calculation by form id in forms */
export const SET_EDITING_CALCULATION_PAGES = 'SET_EDITING_CALCULATION_PAGES';
export interface SetCalculationPagesAction extends Action<typeof SET_EDITING_CALCULATION_PAGES> {
  calculationPages: [] | null;
}
export const setCalculationPagesAction = (calculationPages: [] | null) => {
  return {
    type: SET_EDITING_CALCULATION_PAGES,
    calculationPages
  };
};

/**Get all reamaining metric Field by formID & metricID */
export const GET_METRIC_FIELDS = 'GET_METRIC_FIELDS';
export interface SetCalculationMetricFieldsAction extends Action<typeof GET_METRIC_FIELDS> {
  metricFields: [] | null;
}
export const setCalculationMetricFieldsAction = (metricFields: [] | null) => {
  return {
    type: GET_METRIC_FIELDS,
    metricFields
  };
};

/**Get added metric Field by formID & metricID */
export const GET_ADDED_METRIC_FIELDS = 'GET_ADDED_METRIC_FIELDS';
export interface CalculationMetricFieldsAction extends Action<typeof GET_ADDED_METRIC_FIELDS> {
  addedMetricFields: [] | null;
}
export const calculationMetricFieldsAction = (addedMetricFields: [] | null) => {
  return {
    type: GET_ADDED_METRIC_FIELDS,
    addedMetricFields
  };
};

export const SET_FORM_FIELD_OPTIONS_TO_DELETE_A = 'SET_FORM_FIELD_OPTIONS_TO_DELETE_A';
export interface SetFormFieldOptionsToDeleteAction
  extends Action<typeof SET_FORM_FIELD_OPTIONS_TO_DELETE_A> {
  ids: UuidType[] | null;
}
export const setFormFieldOptionsToDeleteAction = (ids: UuidType[] | null) => {
  return {
    type: SET_FORM_FIELD_OPTIONS_TO_DELETE_A,
    ids
  };
};

export const SET_EDITING_FIELD_LOGIC_RULES_A = 'SET_EDITING_FIELD_LOGIC_RULES_A';
export interface SetEditingFieldLogicRulesAction
  extends Action<typeof SET_EDITING_FIELD_LOGIC_RULES_A> {
  fieldLogicRules: FormFieldLogic[] | null;
}
export const setEditingFieldLogicRulesAction = (fieldLogicRules: FormFieldLogic[] | null) => {
  return {
    type: SET_EDITING_FIELD_LOGIC_RULES_A,
    fieldLogicRules
  };
};

export const SET_EDITING_EXIT_PAGE_FIELD_LOGIC_RULES_A =
  'SET_EDITING_EXIT_PAGE_FIELD_LOGIC_RULES_A';
export interface SetEditingExitPageFieldLogicRulesAction
  extends Action<typeof SET_EDITING_EXIT_PAGE_FIELD_LOGIC_RULES_A> {
  exitPageFieldLogicRules: ExitPageFieldLogic[] | null;
}
export const setEditingExitPageFieldLogicRulesAction = (
  exitPageFieldLogicRules: ExitPageFieldLogic[] | null
) => {
  return {
    type: SET_EDITING_EXIT_PAGE_FIELD_LOGIC_RULES_A,
    exitPageFieldLogicRules
  };
};

export const API_GET_COLLECTION_A = 'API_GET_COLLECTION_A';
export interface GetCollectionAction extends Action<typeof API_GET_COLLECTION_A> {
  getCollection: Collection;
}
export const getCollectionAction = (getCollection: Collection) => {
  return {
    type: API_GET_COLLECTION_A,
    getCollection
  };
};

export type CollectionsType =
  | 'assignToPatient'
  | 'collections'
  | 'librariesCard'
  | 'addDialog'
  | 'duplicateCustom'
  | 'addFormToCollections'
  | 'libraryForms';
export const API_GET_COLLECTIONS_A = 'API_GET_COLLECTIONS_A';
export interface GetCollectionsAction extends Action<typeof API_GET_COLLECTIONS_A> {
  collectionsType: CollectionsType;
  getCollections: any | null;
}
export const getCollectionsAction = (
  collectionsType: CollectionsType,
  getCollections: any | null
) => {
  return {
    type: API_GET_COLLECTIONS_A,
    collectionsType,
    getCollections
  };
};

export type FormsType =
  | 'assignToPatient'
  | 'collection'
  | 'collectionDetailAdd'
  | 'custom'
  | 'duplicateCustom'
  | 'library';
export const API_GET_FORMS_A = 'API_GET_FORMS_A';
export interface GetFormsAction extends Action<typeof API_GET_FORMS_A> {
  formsType: FormsType;
  getForms: FormSearchResults | null;
}
export const getFormsAction = (formsType: FormsType, getForms: FormSearchResults | null) => {
  return {
    type: API_GET_FORMS_A,
    formsType,
    getForms
  };
};

export const API_GET_FORMS_COLLECTIONS = 'API_GET_FORMS_COLLECTIONS';
export interface GetFormsCollectionsAction extends Action<typeof API_GET_FORMS_COLLECTIONS> {
  getFormsCollections: any | null;
}
export const getFormsCollectionsAction = (getFormsCollections: any | null) => {
  return {
    type: API_GET_FORMS_COLLECTIONS,
    getFormsCollections
  };
};

export const SET_CALCULATION_METRICS_A = 'SET_CALCULATION_METRICS_A';
export interface SetCalculationMetricsAction extends Action<typeof SET_CALCULATION_METRICS_A> {
  calculationMetrics: Metric[];
}
export const setCalculationMetricsAction = (calculationMetrics: Metric[]) => {
  return {
    type: SET_CALCULATION_METRICS_A,
    calculationMetrics
  };
};
export const SET_EDITING_CALCULATIONS_A = 'SET_EDITING_CALCULATIONS_A';
export interface SetEditingCalculationsAction extends Action<typeof SET_EDITING_CALCULATIONS_A> {
  calculations: Calculation[] | null;
}
export const setEditingCalculationsAction = (calculations: Calculation[] | null) => {
  return {
    type: SET_EDITING_CALCULATIONS_A,
    calculations
  };
};
export const GET_DUPLICATED_FORM_A = 'GET_DUPLICATED_FORM';
export interface GetDuplicatedFormAction extends Action<typeof GET_DUPLICATED_FORM_A> {
  duplicatedForm: Form | null;
}
export const getDuplicatedFormAction = (duplicatedForm: Form | null) => {
  return {
    type: GET_DUPLICATED_FORM_A,
    duplicatedForm
  };
};

export const SET_STATIC_RESOURCE_TAGS_A = 'SET_STATIC_RESOURCE_TAGS_A';
export interface SetStaticResourceTagsAction extends Action<typeof SET_STATIC_RESOURCE_TAGS_A> {
  tags: Tag[] | null;
}
export const setStaticResourceTagsAction = (tags: Tag[] | null) => {
  return {
    type: SET_STATIC_RESOURCE_TAGS_A,
    tags
  };
};

export const GET_PRIMARY_DOMAINS = 'GET_PRIMARY_DOMAINS';
export interface getPrimaryDomain extends Action<typeof GET_PRIMARY_DOMAINS> {
  primaryDomains: [] | null;
}
export const getPrimaryDomainAction = (primaryDomains: [] | null) => {
  return {
    type: GET_PRIMARY_DOMAINS,
    primaryDomains
  };
};

export const GET_ALL_TAGS = 'GET_ALL_TAGS';
export interface getAlltags extends Action<typeof GET_ALL_TAGS> {
  tagList: [] | null;
}
export const getAllTagsAction = (tagList: [] | null) => {
  return {
    type: GET_ALL_TAGS,
    tagList
  };
};

export const GET_PATIENTS_A = 'GET_PATIENTS_A';
export interface GetPatientsAction extends Action<typeof GET_PATIENTS_A> {
  patientSearchResults: PatientSearchResults | null;
}
export const getPatientsAction = (patientSearchResults: PatientSearchResults | null) => {
  return {
    type: GET_PATIENTS_A,
    patientSearchResults
  };
};

/**Get sharing users */
export const GET_SHARING_USER = 'GET_SHARING_USER';
export interface GetSharingUserAction extends Action<typeof GET_SHARING_USER> {
  sharingUser: [] | null;
}
export const getSharingUserAction = (sharingUser: [] | null) => {
  return {
    type: GET_SHARING_USER,
    sharingUser
  };
};

/**OTP verification */
export const OTP_VERIFICATION = 'OTP_VERIFICATION';
export interface OTPVerification extends Action<typeof OTP_VERIFICATION> {
  otpVerification: {} | null;
}
export const otpVerificationAction = (otpVerification: {} | null) => {
  return {
    type: OTP_VERIFICATION,
    otpVerification
  };
};

/**Get shared users list */
export const GET_SHARED_USERS = 'GET_SHARED_USERS';
export interface GetSharedUsersAction extends Action<typeof GET_SHARED_USERS> {
  sharedUsers: [] | null;
}
export const getSharedUsersAction = (sharedUsers: [] | null) => {
  return {
    type: GET_SHARED_USERS,
    sharedUsers
  };
};

/**Get form versions by formID */
export const GET_FORM_VERSIONS = 'GET_FORM_VERSIONS';
export interface GetFormVersionsAction extends Action<typeof GET_FORM_VERSIONS> {
  formVersions: [] | null;
}
export const getFormVersionsAction = (formVersions: [] | null) => {
  return {
    type: GET_FORM_VERSIONS,
    formVersions
  };
};

/**Get form instance details */
export const GET_FORM_INSTANCE_DETAILS = 'GET_FORM_INSTANCE_DETAILS';
export interface GetFormInstanceDetailsAction extends Action<typeof GET_FORM_INSTANCE_DETAILS> {
  formInstanceDetails: {} | null;
}
export const getFormInstanceDetailsAction = (formInstanceDetails: {} | null) => {
  return {
    type: GET_FORM_INSTANCE_DETAILS,
    formInstanceDetails
  };
};

/**Get form response details */
export const GET_FORM_RESPONSE_DETAILS = 'GET_FORM_RESPONSE_DETAILS';
export interface GetFormResponseDetailsAction extends Action<typeof GET_FORM_RESPONSE_DETAILS> {
  formResponseDetails: [] | null;
}
export const getFormResponseDetailsAction = (formResponseDetails: [] | null) => {
  return {
    type: GET_FORM_RESPONSE_DETAILS,
    formResponseDetails
  };
};

/**Get form recurrence details */
export const GET_FORM_RECURRENCE_INFO = 'GET_FORM_RECURRENCE_INFO';
export interface GetFormRecurrenceInfoAction extends Action<typeof GET_FORM_RECURRENCE_INFO> {
  formRecurrenceInfo: {} | null;
}
export const getFormRecurrenceInfoAction = (formRecurrenceInfo: {} | null) => {
  return {
    type: GET_FORM_RECURRENCE_INFO,
    formRecurrenceInfo
  };
};

/**Get form metric graph details */
export const GET_METRIC_GRAPHS = 'GET_METRIC_GRAPHS';
export interface GetFormMetricGraphsAction extends Action<typeof GET_METRIC_GRAPHS> {
  formMetricGraphs: [] | null;
}
export const getFormMetricGraphsAction = (formMetricGraphs: [] | null) => {
  return {
    type: GET_METRIC_GRAPHS,
    formMetricGraphs
  };
};

/**Get form results for clinician portal - nested section */
export const GET_FORM_RESULTS = 'GET_FORM_RESULTS';
export interface GetFormResultsAction extends Action<typeof GET_FORM_RESULTS> {
  formResults: {} | null;
}
export const getFormResultsAction = (formResults: {} | null) => {
  return {
    type: GET_FORM_RESULTS,
    formResults
  };
};

/**Get Completed form results for clinician portal - nested section */
export const GET_COMPLETED_FORM_RESULTS = 'GET_COMPLETED_FORM_RESULTS';
export interface GetCompletedFormResultsAction extends Action<typeof GET_COMPLETED_FORM_RESULTS> {
  completedFormResults: {} | null;
}
export const getCompletedFormResultsAction = (completedFormResults: {} | null) => {
  return {
    type: GET_COMPLETED_FORM_RESULTS,
    completedFormResults
  };
};

/**Get Completed collection results for clinician portal - nested section */
export const GET_COMPLETED_COLLECTION_RESULTS = 'GET_COMPLETED_COLLECTION_RESULTS';
export interface GetCompletedCollectionResultsAction extends Action<typeof GET_COMPLETED_COLLECTION_RESULTS> {
  completedCollectionResults: {} | null;
}
export const getCompletedCollectionResultsAction = (completedCollectionResults: {} | null) => {
  return {
    type: GET_COMPLETED_COLLECTION_RESULTS,
    completedCollectionResults
  };
};

/**check users assignment to resource */
export const CHECK_USERS_ASSIGNMENT = 'CHECK_USERS_ASSIGNMENT';
export interface UsersAssignmentAction extends Action<typeof CHECK_USERS_ASSIGNMENT> {
  checkUsersAssignment: [] | null;
}
export const usersAssignmentAction = (checkUsersAssignment: [] | null) => {
  return {
    type: CHECK_USERS_ASSIGNMENT,
    checkUsersAssignment
  };
};

/**Set resource/referral assignment list */
export const SET_ASSIGNMENT_LIST = 'SET_ASSIGNMENT_LIST';
export interface AssignmentInfoAction extends Action<typeof SET_ASSIGNMENT_LIST> {
  assignmentList: [] | null;
}
export const assignmentInfoAction = (assignmentList: [] | null) => {
  return {
    type: SET_ASSIGNMENT_LIST,
    assignmentList
  };
};

export const GET_PATIENT_A = 'GET_PATIENT_A';
export interface GetPatientAction extends Action<typeof GET_PATIENT_A> {
  patient: Patient | null;
}
export const getPatientAction = (patient: Patient | null) => {
  return {
    type: GET_PATIENT_A,
    patient
  };
};
/**Get secondary site admin list by org_id*/
export const GET_SECONDARY_SITE_ADMIN = 'GET_SECONDARY_SITE_ADMIN';
export interface GetSecondarySiteAdminAction extends Action<typeof GET_SECONDARY_SITE_ADMIN> {
  secondarySiteAdmins: [] | null;
}
export const getSecondarySiteAdminAction = (secondarySiteAdmins: [] | null) => {
  return {
    type: GET_SECONDARY_SITE_ADMIN,
    secondarySiteAdmins
  };
};
/**Get department list */
export const GET_DEPARTMENT_LIST = 'GET_DEPARTMENT_LIST';
export interface GetDepartmentAction extends Action<typeof GET_DEPARTMENT_LIST> {
  departmentList: [] | null;
}
export const getDepartmentListAction = (departmentList: [] | null) => {
  return {
    type: GET_DEPARTMENT_LIST,
    departmentList
  };
};

export const GET_STATE_LIST = 'GET_STATE_LIST';
export interface GetStateAction extends Action<typeof GET_STATE_LIST> {
  stateList: [] | null;
}
export const getStateAction = (stateList: [] | null) => {
  return {
    type: GET_STATE_LIST,
    stateList
  };
};

export const GET_UNIT_LIST = 'GET_UNIT_LIST';
export interface GetUnitAction extends Action<typeof GET_UNIT_LIST> {
  unitList: [] | null;
}
export const getUnitAction = (unitList: [] | null) => {
  return {
    type: GET_UNIT_LIST,
    unitList
  };
};

export const GET_FORM_STYLE = 'GET_FORM_STYLE';
export interface GetFormStyleAction extends Action<typeof GET_FORM_STYLE> {
  formStyle: [] | null;
}
export const getFormStyleAction = (formStyle: [] | null) => {
  return {
    type: GET_FORM_STYLE,
    formStyle
  };
};

export const SET_LOGO = 'SET_LOGO';
export interface SetLogoAction extends Action<typeof SET_LOGO> {
  org_logo: '' | null;
}
export const setLogoAction = (org_logo: '' | null) => {
  return {
    type: SET_LOGO,
    org_logo
  };
};

export const GET_ROLES = 'GET_ROLES';
export interface GetRoleAction extends Action<typeof GET_ROLES> {
  roles: [] | null;
}
export const getRoleAction = (roles: [] | null) => {
  return {
    type: GET_ROLES,
    roles
  };
};

export const GET_ACCOUNT_ROLES = 'GET_ACCOUNT_ROLES';
export interface GetAccountRoleAction extends Action<typeof GET_ACCOUNT_ROLES> {
  roleList: [] | null;
}
export const getAccountRoleAction = (roleList: [] | null) => {
  return {
    type: GET_ACCOUNT_ROLES,
    roleList
  };
};

export const GET_RESOURCE_FILES = 'GET_RESOURCE_FILES';
export interface GetResourceFileAction extends Action<typeof GET_RESOURCE_FILES> {
  resourceFiles: [] | null;
}
export const getResourceFileAction = (resourceFiles: [] | null) => {
  return {
    type: GET_RESOURCE_FILES,
    resourceFiles
  };
};

export const SET_EDITING_EXIT_PAGE_A = 'SET_EDITING_EXIT_PAGE_A';
export interface SetEditingExitPageAction extends Action<typeof SET_EDITING_EXIT_PAGE_A> {
  exitPage: ExitPage | null;
}
export const setEditingExitPageAction = (exitPage: ExitPage | null) => {
  return {
    type: SET_EDITING_EXIT_PAGE_A,
    exitPage
  };
};

export const SET_EDITING_EXIT_PAGE_CALCULATIONS_A = 'SET_EDITING_EXIT_PAGE_CALCULATIONS_A';
export interface SetEditingExitPageCalculationsAction
  extends Action<typeof SET_EDITING_EXIT_PAGE_CALCULATIONS_A> {
  calculations: ExitPageMetricLogic[] | null;
}
export const setEditingExitPageCalculationsAction = (
  calculations: ExitPageMetricLogic[] | null
) => {
  return {
    type: SET_EDITING_EXIT_PAGE_CALCULATIONS_A,
    calculations
  };
};

export const GET_COLLECTION_ASSIGNMENTS_A = 'GET_COLLECTION_ASSIGNMENTS_A';
export interface GetCollectionAssignmentsAction
  extends Action<typeof GET_COLLECTION_ASSIGNMENTS_A> {
  assignments: CollectionAssignments | null;
}
export const getCollectionAssignmentsAction = (assignments: CollectionAssignments) => {
  return {
    type: GET_COLLECTION_ASSIGNMENTS_A,
    assignments
  };
};

export type UnitsType = 'main' | 'assignCollection';
export const API_GET_UNITS_A = 'API_GET_UNITS_A';
export interface GetUnitsAction extends Action<typeof API_GET_UNITS_A> {
  unitsType: UnitsType;
  unitSearchResults: UnitSearchResults | null;
}
export const getUnitsAction = (
  unitsType: UnitsType,
  unitSearchResults: UnitSearchResults | null
) => {
  return {
    type: API_GET_UNITS_A,
    unitsType,
    unitSearchResults
  };
};

export type UsersType = 'accountUsers' | 'assignCollection';
export const API_GET_USERS_A = 'API_GET_USERS_A';
export interface GetUsersAction extends Action<typeof API_GET_USERS_A> {
  usersType: UsersType;
  userSearchResults: UserSearchResults | null;
}
export const getUsersAction = (
  usersType: UsersType,
  userSearchResults: UserSearchResults | null
) => {
  return {
    type: API_GET_USERS_A,
    usersType,
    userSearchResults
  };
};

export const API_GET_ACCOUNT_USERS = 'API_GET_ACCOUNT_USERS';
export interface GetAccountUsersAction extends Action<typeof API_GET_ACCOUNT_USERS> {
  accountUserList: {} | null;
}
export const getAccountUsersAction = (
  accountUserList: {} | null
) => {
  return {
    type: API_GET_ACCOUNT_USERS,
    accountUserList
  };
};

export type PatientFormInstancesType = 'inPerson' | 'oneTime';
export const API_GET_PATIENT_FORM_INSTANCES_A = 'API_GET_PATIENT_FORM_INSTANCES_A';
export interface GetPatientFormInstancesAction
  extends Action<typeof API_GET_PATIENT_FORM_INSTANCES_A> {
  patientFormInstancesType: PatientFormInstancesType;
  patientFormInstanceSearchResults: FormInstanceSearchResults | null;
}
export const getPatientFormInstancesAction = (
  patientFormInstancesType: PatientFormInstancesType,
  patientFormInstanceSearchResults: FormInstanceSearchResults | null
) => {
  return {
    type: API_GET_PATIENT_FORM_INSTANCES_A,
    patientFormInstancesType,
    patientFormInstanceSearchResults
  };
};

export type FormsInstanceCompleteness = 'incomplete' | 'complete';
export const SET_PATIENT_FORM_INSTANCES_A = 'SET_PATIENT_FORM_INSTANCES_A';
export interface SetPatientFormInstancesAction extends Action<typeof SET_PATIENT_FORM_INSTANCES_A> {
  taskResults: PatientTasksResults | null;
  formInstanceCompleteness: FormsInstanceCompleteness;
}
export const setPatientFormInstancesAction = (
  taskResults: PatientTasksResults | null,
  formInstanceCompleteness: FormsInstanceCompleteness
) => {
  return {
    type: SET_PATIENT_FORM_INSTANCES_A,
    formInstanceCompleteness,
    taskResults
  };
};

export type StaticResourceType = 'approved';
export const API_GET_STATIC_RESOURCES_A = 'API_GET_STATIC_RESOURCES_A';
export interface GetStaticResourcesAction extends Action<typeof API_GET_STATIC_RESOURCES_A> {
  staticResourceType: StaticResourceType;
  getResources: StaticResourceSearchResults | null;
}
export const getStaticResourcesAction = (
  staticResourceType: StaticResourceType,
  getResources: StaticResourceSearchResults | null
) => {
  return {
    type: API_GET_STATIC_RESOURCES_A,
    staticResourceType,
    getResources
  };
};

export type BundlesType = 'resourceBundles';
export const API_GET_BUNDLES_A = 'API_GET_BUNDLES_A';
export interface GetBundlesAction extends Action<typeof API_GET_BUNDLES_A> {
  bundlesType: BundlesType;
  getBundles: BundleSearchResults | null;
}
export const getBundlesAction = (
  bundlesType: BundlesType,
  getBundles: BundleSearchResults | null
) => {
  return {
    type: API_GET_BUNDLES_A,
    bundlesType,
    getBundles
  };
};

//Appointments
export type AppointmentsType = 'appointments';
export const API_GET_APPOINTMENTS_A = 'API_GET_APPOINTMENTS_A';
export interface GetAppointmentsAction extends Action<typeof API_GET_APPOINTMENTS_A> {
  appointmentType: AppointmentsType;
  getAppointments: AppointmentSearchResults | null;
}
export const getAppointmentsAction = (
  appointmentType: AppointmentsType,
  getAppointments: AppointmentSearchResults | null
) => {
  return {
    type: API_GET_APPOINTMENTS_A,
    appointmentType,
    getAppointments
  };
};

export type ReferralsType = 'referrals';
export const API_GET_REFERRALS_A = 'API_GET_REFERRALS_A';
export interface GetReferralsAction extends Action<typeof API_GET_REFERRALS_A> {
  referralsType: ReferralsType;
  getReferrals: ReferralSearchResults | null;
}
export const getReferralsAction = (
  referralsType: ReferralsType,
  getReferrals: ReferralSearchResults | null
) => {
  return {
    type: API_GET_REFERRALS_A,
    referralsType,
    getReferrals
  };
};

export const GET_REFERRAL_A = 'GET_REFERRAL_A';
export interface GetReferralAction extends Action<typeof GET_REFERRAL_A> {
  referral: Referral | null;
}
export const getReferralAction = (referral: Referral | null) => {
  return {
    type: GET_REFERRAL_A,
    referral
  };
};
/**Get all referral filters lib */
export const GET_REFERRAL_FILTER_LIB = 'GET_REFERRAL_FILTER_LIB';
export interface GetReferralFilterLibAction extends Action<typeof GET_REFERRAL_FILTER_LIB> {
  referralFilterLists: ReferralFilterListResults | null;
}
export const getReferralFilterLibAction = (referralFilterLists: ReferralFilterListResults | null) => {
  return {
    type: GET_REFERRAL_FILTER_LIB,
    referralFilterLists
  };
};
/**Get all referral types */
export const GET_REFERRAL_FILTER_A = 'GET_REFERRAL_FILTER_A';
export interface GetReferralFilterAction extends Action<typeof GET_REFERRAL_FILTER_A> {
  referralFilterList: ReferralFilterListResults | null;
}
export const getReferralFilterAction = (referralFilterList: ReferralFilterListResults | null) => {
  return {
    type: GET_REFERRAL_FILTER_A,
    referralFilterList
  };
};
/**Get Referral info */
export const GET_REFERRAL_INFO = 'GET_REFERRAL_INFO';
export interface GetReferralInfoAction extends Action<typeof GET_REFERRAL_INFO> {
  referralInfo: {} | null;
}
export const getReferralInfoAction = (referralInfo: {} | null) => {
  return {
    type: GET_REFERRAL_INFO,
    referralInfo
  };
};

export const SET_EDIT_REFERRAL = 'SET_EDIT_REFERRAL';
export interface SetEditReferral extends Action<typeof SET_EDIT_REFERRAL> {
  editReferral: {} | null;
}
export const setEditReferralAction = (editReferral: {} | null) => {
  return {
    type: SET_EDIT_REFERRAL,
    editReferral
  };
};

export const GET_PATIENT_FAMILY_A = 'GET_PATIENT_FAMILY_A';
export interface GetPatientFamilyAction extends Action<typeof GET_PATIENT_FAMILY_A> {
  patientFamilyInfo: PatientFamilyInfo | null;
}
export const getPatientFamilyAction = (patientFamilyInfo: PatientFamilyInfo | null) => {
  return {
    type: GET_PATIENT_FAMILY_A,
    patientFamilyInfo
  };
};

/**Get all patients & family */
export const GET_ALL_PATIENT_FAMILY = 'GET_ALL_PATIENT_FAMILY';
export interface GetAllPatientFamilyAction extends Action<typeof GET_ALL_PATIENT_FAMILY> {
  patientFamilies: [] | null;
}
export const getAllPatientFamilyAction = (patientFamilies: [] | null) => {
  return {
    type: GET_ALL_PATIENT_FAMILY,
    patientFamilies
  };
};
/**Get all admin list */
export const GET_ALL_ADMINS = 'GET_ALL_ADMINS';
export interface GetAllAdmins extends Action<typeof GET_ALL_ADMINS> {
  adminList: [] | null;
}
export const getAllAdmins = (adminList: [] | null) => {
  return {
    type: GET_ALL_ADMINS,
    adminList
  };
};

/**Get reach care organization ID */
export const GET_REACH_CARE_ORG_ID = 'GET_REACH_CARE_ORG_ID';
export interface GetReachCareOrgId extends Action<typeof GET_REACH_CARE_ORG_ID> {
  reachCareOrgId: "" | null;
}
export const getReachCareOrgId = (reachCareOrgId: "" | null) => {
  return {
    type: GET_REACH_CARE_ORG_ID,
    reachCareOrgId
  };
};

/** Get all orgnization list */
export const GET_ORGANIZATION_LIST = 'GET_ORGANIZATION_LIST';
export interface GetOrganizationsAction extends Action<typeof GET_ORGANIZATION_LIST> {
  organizationList: [] | null;
}
export const getOrganizationsAction = (organizationList: [] | null) => {
  return {
    type: GET_ORGANIZATION_LIST,
    organizationList
  };
};

/** Get orgnization profile */
export const GET_ORGANIZATION_PROFILE = 'GET_ORGANIZATION_PROFILE';
export interface GetOrganizationProfileAction extends Action<typeof GET_ORGANIZATION_PROFILE> {
  organizationProfile: {} | null;
}
export const getOrganizationProfileAction = (organizationProfile: {} | null) => {
  return {
    type: GET_ORGANIZATION_PROFILE,
    organizationProfile
  };
};

/**Get Profile Info */
export const GET_PROFILE_INFO = 'GET_PROFILE_INFO';
export interface GetProfileInfoAction extends Action<typeof GET_PROFILE_INFO> {
  profileInfo: {} | null;
}
export const getProfileInfoAction = (profileInfo: {} | null) => {
  return {
    type: GET_PROFILE_INFO,
    profileInfo
  };
};

export const API_CREATE_STATIC_RESOURCES_A = 'API_CREATE_STATIC_RESOURCES_A';
export interface CreateStaticResourcesAction extends Action<typeof API_CREATE_STATIC_RESOURCES_A> {
  createStaticResourcesResult: StaticResourceUploadResponse | null;
}
export const createStaticResourcesAction = (
  createStaticResourcesResult: StaticResourceUploadResponse | null
) => {
  return {
    type: API_CREATE_STATIC_RESOURCES_A,
    createStaticResourcesResult
  };
};

export const API_GET_TAGS_A = 'API_SEARCH_TAGS_A';
export interface GetTagsAction extends Action<typeof API_GET_TAGS_A> {
  tagsSearchResults: TagsSearchResults | null;
}
export const getTagsAction = (tagsSearchResults: TagsSearchResults | null) => {
  return {
    type: API_GET_TAGS_A,
    tagsSearchResults
  };
};

/**Save new tag */
export const SAVE_NEW_TAG = 'SAVE_NEW_TAG';
export interface AddNewTagAction extends Action<typeof SAVE_NEW_TAG> {
  tag: {} | null;
}
export const addNewTag = (tag: {} | null) => {
  return {
    type: SAVE_NEW_TAG,
    tag
  };
};

/**Set tabs */
export const SET_TABS = 'SET_TABS';
export interface SetTabsAction extends Action<typeof SET_TABS> {
  tab: any | null;
}
export const setTab = (tab: any | null) => {
  return {
    type: SET_TABS,
    tab
  };
};

/**Get all collection list in forms */
export const GET_ALL_COLLECTION_LIST = 'GET_ALL_COLLECTION_LIST';
export interface GetAllCollectionListAction extends Action<typeof GET_ALL_COLLECTION_LIST> {
  collectionList: [] | null;
}
export const getAllCollectionListAction = (collectionList: [] | null) => {
  return {
    type: GET_ALL_COLLECTION_LIST,
    collectionList
  };
};

/**Add new unit data */
export const SAVE_NEW_UNIT = 'SAVE_NEW_UNIT';
export interface AddNewUnitAction extends Action<typeof SAVE_NEW_UNIT> {
  unit: {} | null;
}
export const addNewUnit = (unit: {} | null) => {
  return {
    type: SAVE_NEW_UNIT,
    unit
  };
};

/**Get collection forms by collectionId */
export const GET_COLLECION_FORMS = 'GET_COLLECION_FORMS';
export interface GetCollectionFormsAction extends Action<typeof GET_COLLECION_FORMS> {
  collectionForms: [] | null;
}
export const getCollectionForms = (collectionForms: [] | null) => {
  return {
    type: GET_COLLECION_FORMS,
    collectionForms
  };
};

/**Get not in collection forms by collectionId */
export const GET_NOT_IN_COLLECION_FORMS = 'GET_NOT_IN_COLLECION_FORMS';
export interface GetAllCollectionFormsAction extends Action<typeof GET_NOT_IN_COLLECION_FORMS> {
  allCollectionForms: {} | null;
}
export const getAllCollectionForms = (allCollectionForms: {} | null) => {
  return {
    type: GET_NOT_IN_COLLECION_FORMS,
    allCollectionForms
  };
};

/**Get tags with pagination in settings */
export const GET_TAGS = 'GET_TAGS';
export interface GetSettingTagsAction extends Action<typeof GET_TAGS> {
  settingTags: {} | null;
}
export const getSettingTagsAction = (settingTags: {} | null) => {
  return {
    type: GET_TAGS,
    settingTags
  };
};

export type CatalogueType = 'completeList' | 'changeCatalogue';

export const API_GET_CATALOGUES_A = 'API_GET_CATALOGUES_A';
export interface GetCataloguesAction extends Action<typeof API_GET_CATALOGUES_A> {
  catalogueType: CatalogueType;
  getCatalogues: any | null;
}
export const getCataloguesAction = (
  catalogueType: CatalogueType,
  getCatalogues: any | null
) => {
  return {
    type: API_GET_CATALOGUES_A,
    catalogueType,
    getCatalogues
  };
};

/**Get form status count */
export const GET_FORM_STATUS_COUNT = 'GET_FORM_STATUS_COUNT';
export interface GetFormStatusCountAction extends Action<typeof GET_FORM_STATUS_COUNT> {
  formStatusCount: [] | null;
}
export const getFormStatusCountAction = (formStatusCount: [] | null) => {
  return {
    type: GET_FORM_STATUS_COUNT,
    formStatusCount
  };
};

/**Get collection status count */
export const GET_COLLECTION_STATUS_COUNT = 'GET_COLLECTION_STATUS_COUNT';
export interface GetCollectionStatusCountAction extends Action<typeof GET_COLLECTION_STATUS_COUNT> {
  collectionStatusCount: [] | null;
}
export const getCollectionStatusCountAction = (collectionStatusCount: [] | null) => {
  return {
    type: GET_COLLECTION_STATUS_COUNT,
    collectionStatusCount
  };
};

/**Get resource status count */
export const GET_RESOURCE_STATUS_COUNT = 'GET_RESOURCE_STATUS_COUNT';
export interface GetResourceStatusCountAction extends Action<typeof GET_RESOURCE_STATUS_COUNT> {
  resourceStatusCount: [] | null;
}
export const getResourceStatusCountAction = (resourceStatusCount: [] | null) => {
  return {
    type: GET_RESOURCE_STATUS_COUNT,
    resourceStatusCount
  };
};

export type DataAction =
  | OTPVerification
  | GetResourceStatusCountAction
  | GetCollectionStatusCountAction
  | GetFormStatusCountAction
  | GetFormMetricGraphsAction
  | GetFormInstanceDetailsAction
  | GetFormResponseDetailsAction
  | GetCompletedFormResultsAction
  | GetCompletedCollectionResultsAction
  | GetFormResultsAction
  | SetPatientInfoAction
  | SetSharedUserInfoAction
  | SetLogoAction
  | SetLoggedInUserAction
  | SetInvitedUserAction
  | FetchFormsAction
  | SetDisplayFormAction
  | SetDisplayFormFieldPayloadAction
  | SetDisplayFormInstanceAction
  | SetDisplayExitPageAction
  | SetEditingFormAction
  | SetFormResponsesAction
  | SetFormRespChangeLogsAction
  | SetEditingFormFieldsAction
  | SetEditingFormFieldAction
  | SetEditingExitPagesAction
  | SetEditingExitPageAction
  | SetEditingExitPageFieldLogicRulesAction
  | SetFormFieldOptionsToDeleteAction
  | GetCollectionAction
  | GetCollectionsAction
  | GetFormsAction
  | SetEditingFieldLogicRulesAction
  | SetEditingCalculationsAction
  | SetCalculationMetricsAction
  | GetDuplicatedFormAction
  | GetPatientsAction
  | GetPatientAction
  | GetDuplicatedFormAction
  | SetEditingExitPageCalculationsAction
  | GetCollectionAssignmentsAction
  | GetUnitsAction
  | GetUsersAction
  | GetReachCareOrgId
  | GetPatientFormInstancesAction
  | SetPatientFormInstancesAction
  | GetStaticResourcesAction
  | GetBundlesAction
  | GetAppointmentsAction
  | GetReferralFilterAction
  | GetPatientFamilyAction
  | GetReferralsAction
  | CreateStaticResourcesAction
  | GetCataloguesAction
  | SetStaticResourceTagsAction
  | GetTagsAction
  | GetProfileInfoAction
  | GetAccountUsersAction
  | GetUnitAction
  | GetStateAction
  | GetDepartmentAction
  | GetFormStyleAction
  | GetRoleAction
  | GetAccountRoleAction
  | getPrimaryDomain
  | getAlltags
  | GetResourceFileAction
  | GetOrganizationsAction
  | GetVerifyUserInfoAction
  | AddNewTagAction
  | AddNewUnitAction
  | GetAllCollectionListAction
  | GetCollectionFormsAction
  | GetAllCollectionFormsAction
  | GetSettingTagsAction
  | SetCalculationPagesAction
  | SetCalculationMetricFieldsAction
  | CalculationMetricFieldsAction
  | SetEditReferral
  | GetReferralInfoAction
  | GetReferralFilterLibAction
  | GetOrganizationProfileAction
  | SetTabsAction
  | GetSecondarySiteAdminAction
  | GetAllPatientFamilyAction
  | GetAllAdmins
  | GetSharingUserAction
  | UsersAssignmentAction
  | AssignmentInfoAction
  | GetSharedUsersAction
  | GetFormVersionsAction
  | GetFormsCollectionsAction
  | GetLoginSSOPageAction
  | GetFormRecurrenceInfoAction