import React, { useEffect, useState } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "save-as";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export const Downloads = ({ urlData, handleClose }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        downloadZip();
    }, [])

    const downloadZip = async () => {
        setOpen(true);
        let zip = new JSZip();
        let count = 0;
        let zipFilename = "resource.zip";
        let urls = urlData;

        await urls.forEach(async function (url) {
            let filename = url.name;
            // loading a file and add it in a zip file
            await JSZipUtils.getBinaryContent(url.url, async function (err, data) {
                if (err) {
                    throw err;
                }
                zip.file(filename, data, { binary: true });
                count++;
                if (count === urls.length) {
                    await zip.generateAsync({ type: "blob" }).then(function (content) {
                        saveAs(content, zipFilename);
                    });
                }
            });
        });
        handleClose();
        setOpen(false);
    }

    return (
        <div>
            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}