import React, { useEffect, useState } from 'react';
import { Box, Theme, Typography, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { Collection, Form, UuidType } from '../../../../shared/domain';
import { Dialog } from '../../../../components/Dialog';
import { FormsRadioList } from './../../../Forms/components/FormsRadioList';
import { LoadingButton } from '../../../../components/LoadingButton';
import { LoadingOrError } from '../../../../components/LoadingOrError';
import { PaginationBar } from '../../../../components/PaginationBar';
import { RootState } from '../../../../redux/redux-store';
import { SearchBar } from '../../../../components/SearchBar';
import { TabBar, TabComponent } from '../../../../components/TabBar';
import { colors } from '../../../../styling/colors';
import { getCollectionsThunk, getFormsThunk } from '../../../../redux/thunk/thunks';
import { getFormsAction } from '../../../../redux/data/data-actions';
import { FORM_TAB } from './PatientTasks';

interface AssignCollectionFormToPatientDialogProps {
  open: boolean;
  library?: boolean;
  type: FORM_TAB;
  handleAssignForm: (formId: UuidType | null, dueDate: Date | null) => void;
  handleSetDueDate: (formId: UuidType) => void;
  handleClose: () => void;
}

export const AssignCollectionFormToPatientDialog = ({
  open,
  library,
  type,
  handleAssignForm,
  handleSetDueDate,
  handleClose
}: AssignCollectionFormToPatientDialogProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const collectionsData = useSelector(
    (state: RootState) => state.DATA_REDUCER.collectionSearchResults.assignToPatient
  );
  const formsData = useSelector(
    (state: RootState) => state.DATA_REDUCER.formSearchResults.assignToPatient
  );
  const getFormInstancesByPatientLoading = useSelector(
    (state: RootState) =>
      state.DISPLAY_STATE_REDUCER.displayLoading.getFormInstancesByPatientLoading
  );
  const assignFormToPatientLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.assignFormToPatientLoading
  );

  const [formSearch, setFormSearch] = useState<{ [x in string]: string }>({ ALL_COLLECTIONS: '' });
  const [selectedForm, setSelectedForm] = useState<UuidType | null>(null);

  const handleRadioSelect = (id: UuidType) => {
    setSelectedForm(id);
  };

  const handleTabChange = async (id: string) => {
    setSelectedForm(null);

    dispatch(getFormsAction('assignToPatient', null));

    dispatch(
      getFormsThunk({
        collectionId: id === 'ALL_COLLECTIONS' ? '' : id,
        formsType: 'assignToPatient',
        library,
        page: 0,
        pageSize: 4,
        search: formSearch[id] ?? ''
      })
    );
  };

  const handleNextPage = async () => {
    if (formsData) {
      await dispatch(
        getFormsThunk({
          collectionId: formsData.collectionId,
          formsType: 'assignToPatient',
          library,
          page: formsData.page + 1,
          pageSize: formsData.pageSize,
          search: formsData.search
        })
      );
    }
  };

  const handlePrevPage = async () => {
    if (formsData) {
      await dispatch(
        getFormsThunk({
          collectionId: formsData.collectionId,
          formsType: 'assignToPatient',
          library,
          page: formsData.page - 1,
          pageSize: formsData.pageSize,
          search: formsData.search
        })
      );
    }
  };

  const handleSearch = (value: string) => {
    if (formsData) {
      dispatch(
        getFormsThunk({
          collectionId: formsData.collectionId,
          formsType: 'assignToPatient',
          library,
          page: formsData.page,
          pageSize: formsData.pageSize,
          search: value
        })
      );
    }
  };

  const FormList = formsData ? (
    <Box bgcolor={colors.background3}>
      <FormsRadioList
        library={library}
        listData={formsData.forms.map((form: Form) => ({
          name: form.title,
          ...form
        }))}
        selected={selectedForm}
        handleRadioSelect={handleRadioSelect}
      />

      <Box pb={2} px={3}>
        <PaginationBar
          contentName="Form"
          page={formsData.page}
          pageSize={formsData.pageSize}
          total={formsData.total}
          handleNextButton={handleNextPage}
          handlePrevButton={handlePrevPage}
        />
      </Box>
    </Box>
  ) : (
    <LoadingOrError errorMsg={null} loading={true} loadingMsg="" noElevation />
  );

  const tabComponents: TabComponent[] = [
    {
      component: FormList,
      id: 'ALL_COLLECTIONS',
      label: 'All'
    }
  ].concat(
    collectionsData
      ? collectionsData.collections.map((collection: Collection) => ({
          component: FormList,
          id: collection.id,
          label: collection.name
        }))
      : []
  );

  // initializes object that stores search values for each individual collection
  useEffect(() => {
    if (collectionsData) {
      const collectionIds = collectionsData.collections.map(
        (collection: Collection) => collection.id
      );
      const search = collectionIds.reduce(
        (result: { [x in string]: string }, item: UuidType) => {
          result[item] = '';
          return result;
        },
        { ALL_COLLECTIONS: '' }
      );

      setFormSearch(search);
    }
  }, [collectionsData]);

  useEffect(() => {
    dispatch(getCollectionsThunk({ collectionsType: 'assignToPatient', library, pageSize: 0 }));
    dispatch(getFormsThunk({ formsType: 'assignToPatient', library, pageSize: 4 }));
  }, []);

  return (
    <Dialog open={open} handleClose={handleClose}>
      <Box className={classes.main} display="flex" flexDirection="column">
        <Box
          className={classes.topBox}
          display="flex"
          bgcolor={colors.background2}
          flexDirection="column"
          pt={2.5}
        >
          <Box alignItems="center" display="flex" justifyContent="space-between" px={3}>
            <Typography variant="h3">{`Picking a form from a ${
              library ? 'library' : 'collection'
            }`}</Typography>

            {formsData && (
              <Box pl={2}>
                <SearchBar
                  initialValue={formSearch[formsData.collectionId] ?? ''}
                  handleSearch={handleSearch}
                  setInitialValue={(value: string) =>
                    setFormSearch({ ...formSearch, [formsData.collectionId]: value })
                  }
                />
              </Box>
            )}
          </Box>

          <TabBar
            style={{
              spacing: {
                px: 3
              }
            }}
            tabComponents={tabComponents}
            onTabChange={handleTabChange}
          />
        </Box>

        <Box
          bgcolor={colors.background2}
          className={classes.bottomBox}
          display="flex"
          justifyContent="space-between"
          px={3}
          py={2}
        >
          <LoadingButton
            className={classes.secondaryButton}
            color="secondary"
            onClick={handleClose}
            variant="contained"
            aria-label="cancel"
          >
            Cancel
          </LoadingButton>

          <LoadingButton
            className={classes.primaryButton}
            color="primary"
            disabled={
              !selectedForm || assignFormToPatientLoading || getFormInstancesByPatientLoading
            }
            loading={assignFormToPatientLoading || getFormInstancesByPatientLoading}
            onClick={() => {
              if (selectedForm) {
                if (type === FORM_TAB.IN_PERSON) {
                  handleAssignForm(selectedForm, null);
                } else {
                  handleSetDueDate(selectedForm);
                }
              }
            }}
            variant="contained"
            aria-label="assign form"
          >
            {type === FORM_TAB.IN_PERSON ? 'Assign Form' : 'Select Due Date'}
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '80vw',
    maxWidth: '40rem'
  },
  topBox: {
    borderRadius: '0.625rem 0.625rem 0 0'
  },
  bottomBox: {
    borderRadius: '0 0 0.625rem 0.625rem'
  },
  primaryButton: {
    boxShadow:
      '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)'
  }
}));
