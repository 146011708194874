import { Box, makeStyles, TextField, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import InfoIcon from '@material-ui/icons/Info';

interface PatientInfoSettingsProps {
  titleClass: string;
  patientInfo: string;
  handlePatientInfoChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export const PatientInfoSettings: React.FC<PatientInfoSettingsProps> = ({
  titleClass,
  patientInfo,
  handlePatientInfoChange
}) => {
  const classes = useStyles();
  return (
    <>
      <Box className={titleClass}>
        <Box flex className={classes.titleContainer}>
          <Typography color={'textSecondary'} variant="subtitle2">
            Instructions for Patients
          </Typography>
          <Tooltip
            title={'This description will only be visible to patients'}
            aria-label="more-info"
            placement="top"
          >
            <InfoIcon className={classes.icon} />
          </Tooltip>
        </Box>
      </Box>
      <TextField
        variant="outlined"
        size={'small'}
        value={patientInfo}
        inputProps={{ maxLength: 280 }}
        multiline
        fullWidth
        rows={2}
        onChange={handlePatientInfoChange}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: { marginLeft: '.4rem', color: theme.palette.grey[500], fontSize: '1rem' },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
}));
