import { Box, makeStyles, Modal, Grid, Typography, Select, Paper, MenuItem } from '@material-ui/core';
import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '../../../components/LoadingButton';
import { RootState } from '../../../redux/redux-store';
import { addMetricQuestionThunk } from '../../../redux/thunk/thunks';

export const AddNewQuestion = ({ metric_id, handleClose }: any) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const initialValues: any = {
        question: ''
    }
    const editingForm = useSelector((state: RootState) => state.DATA_REDUCER.editingForm);
    const remainingMetricFields = useSelector((state: RootState) => state.DATA_REDUCER.metricFields);

    const handleSubmit = async (values: any, actions: any) => {
        actions.setSubmitting(true);
        const array = [];
        const selectedQue: any = remainingMetricFields?.filter((item: any) => item.id === values.question);
        if (selectedQue[0].field_type === 'SCALE') {
            const length = selectedQue[0]?.option_scale_max + 1;
            for (let i = 0; i < length; i++) {
                const info = {
                    "comparison_option_id": null,
                    "comparison_value": i,
                    "operator_type": "EQUAL_TO",
                    "calculation_operator": "ADD",
                    "calculation_point_amount": 0
                }
                array.push(info);
            }
        } else {
            for (let i = 0; i < selectedQue[0].form_field_options.length; i++) {
                const info = {
                    "comparison_option_id": selectedQue[0].form_field_options[i].id,
                    "comparison_value": null,
                    "operator_type": "EQUAL_TO",
                    "calculation_operator": "ADD",
                    "calculation_point_amount": 0
                }
                array.push(info);
            }
        }
        const data = {
            "form_field_id": values.question,
            "metric_id": metric_id,
            "calc_data": array
        }
        await dispatch(addMetricQuestionThunk(editingForm?.id, data));
        actions.setSubmitting(false);
    };

    const QuestionSchema = Yup.object().shape({
        question: Yup.string().required('Required')
    });

    return (
        <Modal
            open={true}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Box alignItems="center" className={classes.main} display="flex" justifyContent="center">
                <Paper className={(classes.dialogPaper)}>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validateOnBlur={false}
                        validateOnChange={false}
                        validationSchema={QuestionSchema}
                    >
                        {({ errors, isSubmitting, setSubmitting, touched, values }) => {
                            return (
                                <Form style={{ width: '500px' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography color="textSecondary" variant="h2">Add New Question</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box pb={1.0}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        New Question*
                                                    </Typography>
                                                </Box>

                                                <Field as={Select} name="question" variant="outlined" className={classes.textField1}>
                                                    <MenuItem value={''}>Select</MenuItem>
                                                    {remainingMetricFields && remainingMetricFields.length > 0 && remainingMetricFields.map((item: any) => {
                                                        return <MenuItem value={item.id}>{item.field_prompt}</MenuItem>
                                                    })}
                                                </Field>
                                                {errors.question && touched.question ? (
                                                    <Typography color="error" variant="subtitle2">
                                                        {errors.question}
                                                    </Typography>
                                                ) : null}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box display="flex" justifyContent="space-between">
                                                <LoadingButton
                                                    className={classes.secondaryButton}
                                                    onClick={handleClose}
                                                    variant="contained"
                                                >
                                                    Cancel
                                                </LoadingButton>
                                                <LoadingButton
                                                    className={classes.primaryButton}
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    loading={isSubmitting}
                                                    type="submit"
                                                    variant="contained"
                                                    aria-label="sign in"
                                                >
                                                    Save
                                                </LoadingButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                </Paper>
            </Box>
        </Modal>
    );
};

const useStyles = makeStyles((theme) => ({
    main: {
        minHeight: '100vh',
    },
    primaryButton: {
        boxShadow:
            '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)'
    },
    secondaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)'
    },
    textField: {
        width: '100%'
    },
    textField1: {
        width: '100%',
        height: '35px',
        padding: '0px'
    },
    dialogPaper: {
        borderRadius: '0.625rem',
        padding: '20px'
    }
}));
