import { Typography } from '@material-ui/core';
import React from 'react';
interface LongTextBlockProps {
  infoText: any;
}

export const InfoTextFieldBlock: React.FC<LongTextBlockProps> = ({ infoText }) => {
  return (infoText && infoText.map((queName: any) => {
    return <Typography variant="subtitle2"><b>{queName ? queName : ''}</b></Typography>
  }))
};
