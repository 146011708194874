import React, { useEffect, useState } from 'react';
import { Box, Paper, DialogActions, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AddToCollectionDialog } from './AddToCollectionDialog';
import { CardHeader } from '../../../components/LegacyCardHeader';
import { UuidType } from '../../../shared/domain';
import { LoadingButton } from '../../../components/LoadingButton';
import { Dialog } from '../../../components/Dialog';
import { DuplicateCustomFormDialog } from './DuplicateCustomFormDialog';
import { FlowproThunkDispatch, RootState } from '../../../redux/redux-store';
import { FormsTable } from './FormsTable';
import { ROLES } from '../../../shared/globals';
import {
  GetFormsProps, duplicateFormsThunk, getPatientFormsThunk, getFormStatusCount,
  assignCollectionToFormsThunk,
  getFormsThunk,
  removeFevFormThunk,
  updateFormsThunk,
  updateCollectionsThunk,
  deleteFormThunk,
  getDomainsThunk,
  formCreationThunk
} from '../../../redux/thunk/thunks';
import { NewFormDialog } from './NewFormDialog';
import { TabBar, TabComponent } from '../../../components/TabBar';
import { setSuccessAction } from '../../../redux/display/display-actions';
import { assignmentInfoAction, setEditingFormAction, getFormsAction } from '../../../redux/data/data-actions';
import { AssignForms } from '../../Resources/AssignPatient/AssignForms';
import { ConfirmationDelete } from '../../../components/DeleteConfirmation';

export const Forms = () => {
  const dispatch = useDispatch();

  const customFormsData: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.formSearchResults.custom
  );
  const collectionsData: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.collectionSearchResults.libraryForms
  );

  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);
  const user = useSelector((state: RootState) => state.DATA_REDUCER.loggedInUser);
  const [addDuplicate, setAddDuplicateOpen] = useState(false);
  const [searchPattern, setSearchPattern] = useState('');
  const [selectedFormsDetails, setSelectedFormsDetails] = useState<any>([]);
  const [selectedCustomForms, setSelectedCustomForms] = useState<UuidType[]>([]);
  const [selectedLibraryForms, setSelectedLibraryForms] = useState<UuidType[]>([]);
  const [tab, setTab] = useState(0);
  const [onTab, setOnTab] = useState('To Be Completed');
  const [newFormDialogOpen, setNewFormDialogOpen] = useState(false);
  const [addToCollectionDialogOpen, setAddToCollectionDialogOpen] = useState(false);
  const [addToLibraryDialogOpen, setAddToLibraryDialogOpen] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [isBaseDelete, setIsBaseDelete] = useState(false);
  const [deleteFormsConfirmationDialogOpen, setDeleteFormsConfirmationDialogOpen] = useState(false);
  const [duplicateCustomFormDialogOpen, setDuplicateCustomFormDialogOpen] = useState(false);
  const [duplicateLibraryFormDialogOpen, setDuplicateLibraryFormDialogOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openPatient, setOpenPatient] = useState(false);
  const [info, setFormInfo] = useState<any>(null);

  const deleteData: any = useSelector((state: RootState) => state.DATA_REDUCER.formStatusCount);

  const success: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.getForms);
  useEffect(() => {
    if (success) {
      dispatch(setSuccessAction('getForms', false));
      if ([ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role)) {
        dispatch(getPatientFormsThunk({ search: searchPattern, access_type: customFormsData?.access_type || 'TAKE', status_type: customFormsData?.status_type || 'IN_PROGRESS', page: customFormsData?.page, pageSize: customFormsData?.pageSize, order: customFormsData?.order, sort: customFormsData?.sort }));
      } else {
        dispatch(getFormsThunk({
          formsType: 'custom',
          collectionId: customFormsData?.collectionId,
          page: customFormsData?.page,
          pageSize: customFormsData?.pageSize,
          search: customFormsData?.search,
          favourites: customFormsData?.favourites,
          sort: customFormsData?.sort
        }));
      }
    } else if (!customFormsData && ![ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role)) {
      const tabValue = sessionStorage.getItem('tab');
      dispatch(getFormsThunk({
        formsType: 'custom',
        favourites: tabValue === 'Favorites' ? true : !tabValue ? true : false
      }));
    } else if (!customFormsData && [ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role)) {
      dispatch(getPatientFormsThunk({ search: searchPattern, access_type: onTab === 'View-Only Forms' ? 'VIEW' : 'TAKE', status_type: 'IN_PROGRESS' }));
    }
  })

  const handleNewFormDialogOpen = async () => {
    sessionStorage.setItem('new', 'true');
    await dispatch(formCreationThunk());
    dispatch(setEditingFormAction(null));
  };
  const handleNewFormDialogClose = () => {
    setNewFormDialogOpen(false);
  };
  const handleAddToCollectionDialogClose = () => {
    setAddToCollectionDialogOpen(false);
  };
  const handleDeleteFormsConfirmationDialogOpen = async () => {
    if (loggedInUser && [ROLES.ADMIN, ROLES.SUPER_ADMIN].includes(loggedInUser.role)) {
      const data = { form_ids: selectedCustomForms }
      await dispatch(getFormStatusCount(data));
      setDeleteFormsConfirmationDialogOpen(true);
    } else {
      let checkCustomId: any = [];
      for (let i = 0; i < selectedFormsDetails.length; i++) {
        if (selectedFormsDetails[i]?.formType !== 'CUSTOM') {
          checkCustomId.push(selectedFormsDetails[i]);
        }
      }
      if (checkCustomId.length > 0) {
        setIsBaseDelete(true);
      } else if (checkCustomId.length === 0) {
        const data = { form_ids: selectedCustomForms }
        await dispatch(getFormStatusCount(data));
        setDeleteFormsConfirmationDialogOpen(true);
      }
    }
  };
  const handleDeleteFormsConfirmationDialogClose = () => {
    setDeleteFormsConfirmationDialogOpen(false);
  };
  const handleDisplayDuplicateCustomFormDialogOpen = () => {
    setNewFormDialogOpen(false);
    setDuplicateCustomFormDialogOpen(true);
  };
  const handleDisplayDuplicateCustomFormDialogClose = () => {
    setDuplicateCustomFormDialogOpen(false);
  };
  const handleDisplayDuplicateLibraryFormDialogOpen = () => {
    setNewFormDialogOpen(false);
    setDuplicateLibraryFormDialogOpen(true);
  };
  const handleDisplayDuplicateLibraryFormDialogClose = () => {
    setDuplicateLibraryFormDialogOpen(false);
  };

  const handleCustomSearch = async (value: string) => {
    setSearchPattern(value);
    if ([ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role) && customFormsData) {
      await dispatch(getPatientFormsThunk({ search: value, pageSize: customFormsData.pageSize, order: customFormsData?.order, access_type: customFormsData.access_type, status_type: customFormsData.status_type, sort: customFormsData?.sort, }));
    } else {
      if (customFormsData) {
        dispatch(
          getFormsThunk({
            collectionId: customFormsData.collectionId,
            formsType: 'custom',
            pageSize: customFormsData.pageSize,
            search: value,
            favourites: customFormsData.favourites,
            sort: customFormsData?.sort,
            order: customFormsData?.order
          })
        );
      }
    }
  };

  const handleAssignCollections = async (
    selectedCollections: UuidType[],
    selectedForms: UuidType[]
  ) => {
    const dispatchPromises: ((
      dispatch: FlowproThunkDispatch,
      getState: () => RootState
    ) => Promise<void>)[] = [];

    selectedCollections.forEach((collectionId: UuidType) => {
      dispatchPromises.push(dispatch(assignCollectionToFormsThunk(collectionId, selectedForms)));
    });

    await Promise.all(dispatchPromises);

    dispatch(updateCollectionsThunk());
    dispatch(updateFormsThunk());

    setAddToCollectionDialogOpen(false);
    setAddToLibraryDialogOpen(false);
  };

  const handleDeleteForms = async (library?: boolean) => {
    setDeleteLoading(true);
    const selectedForms = library ? selectedLibraryForms : selectedCustomForms;
    const deleteForm = {
      form_ids: selectedForms
    }
    await dispatch(deleteFormThunk(deleteForm));
    dispatch(getFormsThunk({
      formsType: 'custom',
      collectionId: customFormsData.collectionId,
      page: customFormsData.page,
      pageSize: customFormsData.pageSize,
      search: customFormsData.search,
      favourites: customFormsData.favourites,
      sort: customFormsData?.sort,
      order: customFormsData?.order
    }));
    setSelectedCustomForms([]);
    setSelectedLibraryForms([]);
    setDeleteLoading(false);
    setDeleteFormsConfirmationDialogOpen(false);
  };

  const handleRemoveForms = async () => {
    const selectedForms = selectedCustomForms;
    const removeForm = {
      form_ids: selectedForms
    }
    await dispatch(removeFevFormThunk(removeForm));
    setSelectedCustomForms([]);
    setSelectedLibraryForms([]);
  };

  const AddDuplicate = async () => {
    setDeleteLoading(true);
    await dispatch(duplicateFormsThunk(selectedCustomForms));
    dispatch(getFormsThunk({
      formsType: 'custom',
      collectionId: customFormsData.collectionId,
      page: customFormsData.page,
      pageSize: customFormsData.pageSize,
      search: customFormsData.search,
      favourites: customFormsData.favourites,
      sort: customFormsData?.sort,
      order: customFormsData?.order
    }));
    setSelectedCustomForms([]);
    setSelectedLibraryForms([]);
    setDeleteLoading(false);
    setAddDuplicateOpen(false);
  }

  const handleCustomTabChange = (id: string) => {
    dispatch(
      getFormsThunk({
        collectionId: id === 'ALL_COLLECTIONS' ? '' : id,
        formsType: 'custom',
        allForms: id === 'ALL_COLLECTIONS',
        library: true,
        search: searchPattern,
        favourites: customFormsData.favourites
      })
    );
    setSelectedCustomForms([]);
  };

  const handelSetFormDetails = (selectedForms: any) => {
    let checkCustomId: any = [];
    for (let i = 0; i < selectedForms.length; i++) {
      const res = customFormsData.forms.filter((id: any) => id.id === selectedForms[i]);
      res && res.length > 0 && checkCustomId.push({ id: res[0]?.id, title: res[0]?.title, formType: res[0]?.form_type });
      // selectedFormsDetails.push({ id: res[0]?.id, title: res[0]?.title, formType: res[0]?.form_type });
    }
    setSelectedFormsDetails(checkCustomId);
  }

  const loadForms = async (getFormsProps: GetFormsProps) => {
    if ([ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role)) {
      await dispatch(getPatientFormsThunk({ ...getFormsProps, access_type: customFormsData.access_type, status_type: customFormsData.status_type }));
    } else {
      await dispatch(
        getFormsThunk({
          ...getFormsProps,
          allForms: getFormsProps.library && getFormsProps.collectionId === ''
        })
      );
    }
  };

  const handleAddToOrgOpen = () => {
    let checkCustomId: any = [];
    for (let i = 0; i < customFormsData.forms.length; i++) {
      if (customFormsData.forms[i].formType === 'CUSTOM') {
        const res = selectedCustomForms.filter((id: any) => id === customFormsData.forms[i].id);
        res && res.length > 0 && checkCustomId.push(res);
      }
    }
    if (checkCustomId.length === 0) {
      setAddDuplicateOpen(true);
    } else {
      setIsCustom(true);
      setAddDuplicateOpen(false);
    }
  };
  const handleAddToOrgClose = () => {
    setAddDuplicateOpen(false);
  };

  const assignFormToPatient = async () => {
    setOpenPatient(true);
    const arr: any = [];
    for (let i = 0; i < selectedCustomForms.length; i++) {
      const check = customFormsData && customFormsData.forms.filter((item: any) => item.id === selectedCustomForms[i]);
      check && check.length > 0 && arr.push(check[0]);
    }
    setFormInfo(arr);
    dispatch(assignmentInfoAction(arr));
  }
  const assignFormToPatientClose = () => {
    setOpenPatient(false);
    setSelectedCustomForms([]);
  }

  useEffect(() => {
    if (![ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role)) {
      dispatch(getDomainsThunk({ collectionsType: 'libraryForms' }));
    } else if ([ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role)) {
      dispatch(getPatientFormsThunk({ search: searchPattern, access_type: 'TAKE', status_type: 'IN_PROGRESS' }));
    }

    return function CleanUp() {
      dispatch(getFormsAction('custom', null));
      setTab(0);
      sessionStorage.removeItem('tab');
    };
  }, []);

  const onFavoritesTabChange = async () => {
    if (tab === 0) {
      await setTab(1); selectedCustomForms.length > 0 && setSelectedCustomForms([]);
      await dispatch(getFormsThunk({ formsType: 'custom', search: searchPattern, favourites: true }));
    }
  }

  const onFormTabChange = async () => {
    if (tab === 1) {
      await setTab(0); selectedCustomForms.length > 0 && setSelectedCustomForms([]);
      await dispatch(getFormsThunk({ formsType: 'custom', search: searchPattern, favourites: false }));
    }
  }

  /**Patient portal */
  const onTabThreeChange = async (e: any) => {
    if (e === 'View-Only Forms' && (onTab !== e || onTab === '')) {
      await setOnTab(e);
      await dispatch(getPatientFormsThunk({ search: searchPattern, access_type: 'VIEW', status_type: 'COMPLETE' }));
    }
  }

  const onTabOneChange = async (e: any) => {
    if (e === 'To Be Completed' && (onTab !== e || onTab === '')) {
      await setOnTab(e);
      await dispatch(getPatientFormsThunk({ search: searchPattern, access_type: 'TAKE', status_type: 'IN_PROGRESS' }));
    }
  }

  const onTabTwoChange = async (e: any) => {
    if ((e === 'Completed Forms') && (onTab !== e || onTab === '')) {
      await setOnTab(e);
      await dispatch(getPatientFormsThunk({ search: searchPattern, access_type: 'TAKE', status_type: 'COMPLETE' }));
    }
  }

  useEffect(() => {
    if (!customFormsData) {
      if ([ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role)) {
        setOnTab('To Be Completed');
        dispatch(getPatientFormsThunk({ search: searchPattern, access_type: 'TAKE', status_type: 'IN_PROGRESS' }));
      }
    }
  }, [])

  const CustomForms = (
    <>
      {addToCollectionDialogOpen && customFormsData && (
        <AddToCollectionDialog
          open={addToCollectionDialogOpen}
          selectedForms={selectedCustomForms}
          handleAssignCollections={handleAssignCollections}
          handleClose={handleAddToCollectionDialogClose}
        />
      )}

      {deleteFormsConfirmationDialogOpen && customFormsData && selectedCustomForms.length > 0 && (
        <ConfirmationDelete
          loading={deleteLoading}
          data={deleteData}
          customData={selectedFormsDetails}
          title="form"
          handleClose={handleDeleteFormsConfirmationDialogClose}
          handleConfirmClick={() => handleDeleteForms(false)}
        />
      )}

      <Box pb={4} pt={1} px={4}>
        <FormsTable
          formsData={customFormsData}
          formsType="custom"
          selectedForms={selectedCustomForms}
          loadForms={loadForms}
          setSelectedForms={(selectedForms: UuidType[]) => { setSelectedCustomForms(selectedForms); handelSetFormDetails(selectedForms) }}
          tab={onTab}
        />
      </Box>
    </>
  );
  const customTabComponents: TabComponent[] = [
    {
      component: CustomForms,
      id: 'ALL_COLLECTIONS',
      label: 'All',
      style: {
        background: true,
        spacing: { px: 4, py: 0 }
      }
    },

  ].concat(
    collectionsData
      ? collectionsData.collections.map((collection: any) => ({
        component: CustomForms,
        id: collection.id,
        label: collection.title,
        style: {
          background: true,
          spacing: { px: 4, py: 0 },
        }
      }))
      : []
  );
  const customFormTabs = (
    <TabBar
      style={{
        tabBackground: true,
        spacing: { px: 4, py: 0 },
        maxWidth: '100%'
      }}
      tabComponents={customTabComponents}
      onTabChange={handleCustomTabChange}
    />
  );

  return (
    <>
      {newFormDialogOpen && (
        <NewFormDialog
          open={newFormDialogOpen}
          handleClose={handleNewFormDialogClose}
          handleDisplayDuplicateCustomFormDialog={handleDisplayDuplicateCustomFormDialogOpen}
          handleDisplayDuplicateLibraryFormDialog={handleDisplayDuplicateLibraryFormDialogOpen}
        />
      )}

      {duplicateCustomFormDialogOpen && (
        <DuplicateCustomFormDialog
          open={duplicateCustomFormDialogOpen}
          handleClose={handleDisplayDuplicateCustomFormDialogClose}
        />
      )}

      {duplicateLibraryFormDialogOpen && (
        <DuplicateCustomFormDialog
          library={true}
          open={duplicateLibraryFormDialogOpen}
          handleClose={handleDisplayDuplicateLibraryFormDialogClose}
        />
      )}

      <Paper>
        <Box display="flex" flexDirection="column">
          {loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role) ?
            <CardHeader
              tabComponents={[
                {
                  component: customFormTabs,
                  label: 'Favorites',
                  primaryButton: {
                    text: 'Create',
                    handleClick: handleNewFormDialogOpen
                  },
                  selected: {
                    primaryButton: {
                      text: 'Remove',
                      handleClick: handleRemoveForms
                    },
                    selectedContents: selectedCustomForms,
                  },
                  style: {
                    background: true,
                    spacing: { pb: 0, pt: 3, px: 4 }
                  },
                  title: 'Favorites Forms',
                  handleSearch: handleCustomSearch,
                  onTabChange: onFavoritesTabChange
                },
                {
                  component: customFormTabs,
                  label: 'Forms',
                  primaryButton: {
                    text: 'Create',
                    handleClick: handleNewFormDialogOpen
                  },
                  selected: {
                    primaryButton: {
                      text: 'Make Copy',
                      handleClick: handleAddToOrgOpen
                    },
                    selectedContents: selectedCustomForms,
                    handleSelectedDelete: handleDeleteFormsConfirmationDialogOpen
                  },
                  style: {
                    background: true,
                    spacing: { pb: 0, pt: 3, px: 4 }
                  },
                  title: 'Forms',
                  handleSearch: handleCustomSearch,
                  onTabChange: onFormTabChange
                },
              ]}
            /> : (loggedInUser && [ROLES.CLINICIAN].includes(loggedInUser.role) ?
              <CardHeader
                tabComponents={[
                  {
                    component: customFormTabs,
                    label: 'Favorites',
                    selected: {
                      primaryButton: {
                        text: 'Remove',
                        handleClick: handleRemoveForms
                      },
                      secondaryButton: {
                        text: 'Assign to Patient',
                        handleClick: assignFormToPatient
                      },
                      selectedContents: selectedCustomForms,
                    },
                    style: {
                      background: true,
                      spacing: { pb: 0, pt: 3, px: 4 }
                    },
                    title: 'Favorites Forms',
                    handleSearch: handleCustomSearch,
                    onTabChange: onFavoritesTabChange
                  },
                  {
                    component: customFormTabs,
                    label: 'Forms',
                    selected: {
                      primaryButton: {
                        text: 'Assign to Patient',
                        handleClick: assignFormToPatient
                      },
                      selectedContents: selectedCustomForms,
                    },
                    style: {
                      background: true,
                      spacing: { pb: 0, pt: 3, px: 4 }
                    },
                    title: 'Forms',
                    handleSearch: handleCustomSearch,
                    onTabChange: onFormTabChange
                  },
                ]} />
              : loggedInUser && [ROLES.PATIENT, ROLES.PROXY, ROLES.RATER].includes(loggedInUser.role) ?
                <CardHeader
                  tabComponents={[
                    {
                      component: CustomForms,
                      label: 'To Be Completed',
                      style: {
                        background: true,
                        spacing: { pb: 0, pt: 3, px: 4 }
                      },
                      title: 'Forms',
                      handleSearch: handleCustomSearch,
                      onTabChange: onTabOneChange
                    },
                    {
                      component: CustomForms,
                      label: 'Completed Forms',
                      style: {
                        background: true,
                        spacing: { pb: 0, pt: 3, px: 4 }
                      },
                      title: 'Forms',
                      handleSearch: handleCustomSearch,
                      onTabChange: onTabTwoChange
                    },
                    {
                      component: CustomForms,
                      label: 'View-Only Forms',
                      style: {
                        background: true,
                        spacing: { pb: 0, pt: 3, px: 4 }
                      },
                      title: 'Forms',
                      handleSearch: handleCustomSearch,
                      onTabChange: onTabThreeChange
                    },
                  ]}
                /> :
                <CardHeader
                  tabComponents={[
                    {
                      component: customFormTabs,
                      label: 'Favorites',
                      primaryButton: {
                        text: 'Create',
                        handleClick: handleNewFormDialogOpen
                      },
                      selected: {
                        primaryButton: {
                          text: 'Remove',
                          handleClick: handleRemoveForms
                        },
                        selectedContents: selectedCustomForms
                      },
                      style: {
                        background: true,
                        spacing: { pb: 0, pt: 3, px: 4 }
                      },
                      title: 'Favorites Forms',
                      handleSearch: handleCustomSearch,
                      onTabChange: onFavoritesTabChange
                    },
                    {
                      component: customFormTabs,
                      label: 'Forms',
                      primaryButton: {
                        text: 'Create',
                        handleClick: handleNewFormDialogOpen
                      },
                      selected: {
                        selectedContents: selectedCustomForms,
                        handleSelectedDelete: handleDeleteFormsConfirmationDialogOpen
                      },
                      style: {
                        background: true,
                        spacing: { pb: 0, pt: 3, px: 4 }
                      },
                      title: 'Forms',
                      handleSearch: handleCustomSearch,
                      onTabChange: onFormTabChange
                    },
                  ]}
                />)
          }
        </Box>
      </Paper>
      {addDuplicate &&
        <Dialog open={addDuplicate}>
          <Box display='flex' justifyContent='center' style={{ borderRadius: '0.625rem 0.625rem 0 0', backgroundColor: '#f9fafb' }}
            alignItems="center"
            px={3}
            py={2.5}
          >
            <Typography variant="h3">Make Copy</Typography>
          </Box>
          <Box style={{ width: '500px' }}>
            <Box pb={3} pt={2} px={4} display="flex" justifyContent="space-between">
              <Typography>Are you sure you would like to make a copy of the item(s) for your organization?</Typography>
            </Box>
          </Box>
          <DialogActions style={{ justifyContent: 'space-between', backgroundColor: '#f9fafb', padding: '15px 20px 5px', borderRadius: '0 0px 10px 10px' }}>
            <LoadingButton style={{ border: '1px solid rgba(0, 0, 0, 0.15)', width: '120px', marginBottom: '10px', color: '#0060B2' }}
              variant="contained"
              onClick={handleAddToOrgClose}
            >
              Cancel
            </LoadingButton>
            <LoadingButton color="primary" disabled={deleteLoading}
              variant="contained"
              style={{ marginBottom: '10px', boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)', width: '120px' }}
              onClick={(e) => AddDuplicate()}
            >
              Confirm
            </LoadingButton>
          </DialogActions>
        </Dialog>
      }
      {isCustom &&
        <Dialog open={isCustom}>
          <Box style={{ width: '400px' }}>
            <Box pb={3} pt={2} px={4} display="flex" justifyContent="center">
              <Typography variant="h3">Custom form already exists in the organization, it cannot be added again</Typography>
            </Box>
          </Box>
          <DialogActions style={{ justifyContent: 'center' }}>
            <LoadingButton style={{ border: '1px solid rgba(0, 0, 0, 0.15)', width: '120px', marginBottom: '10px' }}
              variant="contained"
              onClick={() => setIsCustom(false)}
            >
              Close
            </LoadingButton>
          </DialogActions>
        </Dialog>
      }
      {isBaseDelete &&
        <Dialog open={isBaseDelete}>
          <Box style={{ width: '400px', textAlign: 'center' }}>
            <Box pb={3} pt={2} px={4} display="flex">
              <Typography variant="h3">You have selected base forms, it cannot be deleted</Typography>
            </Box>
          </Box>
          <DialogActions style={{ justifyContent: 'center' }}>
            <LoadingButton style={{ border: '1px solid rgba(0, 0, 0, 0.15)', width: '120px', marginBottom: '10px' }}
              variant="contained"
              onClick={() => setIsBaseDelete(false)}
            >
              Close
            </LoadingButton>
          </DialogActions>
        </Dialog>
      }
      {openPatient && <AssignForms openDialog={openPatient} handelClose={assignFormToPatientClose} />}
    </>
  );
};
