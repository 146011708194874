import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import {
  AppBar,
  Box,
  Button,
  Divider,
  makeStyles,
  Theme,
  Toolbar,
  Typography
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { colors } from '../../../styling/colors';

interface PatientTopBarProps {
  patientName: string;
}

export const PatientTopBar = ({ patientName }: PatientTopBarProps) => {
  const classes = useStyles();
  let history = useHistory();

  return (
    <AppBar square elevation={0} classes={{ root: classes.headerRoot }}>
      <Toolbar classes={{ root: classes.headerToolbar }}>
        <Button
          classes={{ text: classes.closeBtnText }}
          startIcon={<CloseIcon />}
          onClick={() => history.push('/patients')}
        >
          Close
        </Button>
        <Box alignSelf="stretch" display="flex" pr={3} py={2.75}>
          <Divider flexItem orientation="vertical" />
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          minHeight="5rem"
          width="100%"
        >
          <Typography color="textSecondary" variant="h2">
            {patientName}
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  headerRoot: {
    backgroundColor: 'white',
    borderBottom: `2px solid ${theme.palette.divider}`,
    padding: 0
  },
  headerToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 0
  },
  closeBtnText: {
    color: theme.palette.text.secondary,
    marginRight: 18
  },
  subtitle: {
    color: colors.text6,
    fontWeight: 600
  }
}));
