import { Box, Card, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { LoadingOrError } from '../../components/LoadingOrError';
import { setEditingExitPageAction } from '../../redux/data/data-actions';
import { setSuccessAction } from '../../redux/display/display-actions';
import { RootState } from '../../redux/redux-store';
import { getExitPagesByFormIdThunk, updateExitPageThunk } from '../../redux/thunk/thunks';
import { UuidType } from '../../shared/domain';
import { ExitPageSettingsView } from './components/ExitPageSettingsView';
import { ExitPageSubHeader } from './components/ExitPageSubHeader';
import { ExitPageViewer } from './components/ExitPageViewer';
interface ExitPageBuilderProps {
  formId: UuidType;
}

export const ExitPageBuilder: React.FC<ExitPageBuilderProps> = ({ formId }) => {
  const classes = useStyles();
  const [selectedExitPageId, setSelectedExitPageId] = useState<UuidType | null>();
  const dispatch = useDispatch();
  const location: any = useLocation();
  const isEdit = location.state;
  const form_version_id = sessionStorage.getItem('version');
  const editingForm: any = useSelector((state: RootState) => state.DATA_REDUCER.editingForm);
  const editingExitPages: any = useSelector((state: RootState) => state.DATA_REDUCER.editingExitPages);
  const editingExitPage: any = useSelector((state: RootState) => state.DATA_REDUCER.editingExitPage);
  const saveExitPage: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.saveExitPage);

  const handleSelectPage = async (event: React.ChangeEvent<{}>, value: string) => {
    //if editing pages exist set the nav value and set the page data as the EditingExitPage
    const change: any = sessionStorage.getItem('onChangeExit');
    const getChange = JSON.parse(change);
    if (getChange) {
      const result: any = await dispatch(updateExitPageThunk(editingExitPage, editingForm.id));
      if (result === 200) {
        if (editingExitPages) {
          let exitPage = null;
          for (var i = 0; i < editingExitPages.length; i++) {
            if (editingExitPages[i].id === value) {
              exitPage = editingExitPages[i];
            }
          }
          setSelectedExitPageId(value);
          dispatch(setEditingExitPageAction(exitPage));
        }
      }
    } else {
      if (editingExitPages) {
        let exitPage = null;
        for (var i = 0; i < editingExitPages.length; i++) {
          if (editingExitPages[i].id === value) {
            exitPage = editingExitPages[i];
          }
        }
        setSelectedExitPageId(value);
        dispatch(setEditingExitPageAction(exitPage));
      }
    }
  };
  const getExitPagesLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.getExitPagesLoading
  );

  const getExitPagesError = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayErrors.getExitPagesError
  );

  //fetch the exit pages and on mount
  useEffect(() => {
    // if (!editingExitPages) {
    dispatch(getExitPagesByFormIdThunk(formId, form_version_id));
    // }
  }, []);

  useEffect(() => {
    //when pages are saved and refetched make sure the nav is on the correct page tab
    if (editingExitPage) {
      setSelectedExitPageId(editingExitPage.id);
    }
  }, [editingExitPage]);

  useEffect(() => {
    if (saveExitPage) {
      dispatch(setSuccessAction('saveExitPage', false));
      setSelectedExitPageId(editingExitPage.id);
    }
  }, [saveExitPage]);

  useEffect(() => {
    if (editingExitPages && !selectedExitPageId) {
      const defaultExitPage = editingExitPages.find(
        (exitPage: any) => exitPage.is_default === true
      );
      dispatch(setEditingExitPageAction(defaultExitPage));
    }
  }, [editingExitPages])

  return (
    <Box className={classes.main} display="flex" flexDirection="column">
      <ExitPageSubHeader
        selectedExitPageId={selectedExitPageId}
        handleSelectPage={handleSelectPage}
      />
      <Container style={{ padding: '0px' }}>
        {(getExitPagesLoading || getExitPagesError) && !editingExitPages ? (
          <Box m={6} className={classes.placeHolder}>
            <Box className={classes.loadingContainer}>
              <LoadingOrError
                loading={getExitPagesLoading}
                loadingMsg={'Loading Exit Page...'}
                errorMsg={getExitPagesError}
              />
            </Box>
          </Box>
        ) : (
          <Card className={classes.content}>
            {isEdit ? <Grid container>
              <Grid item xs={4} style={{ borderRight: '2px solid rgba(0, 0, 0, 0.12)' }}>
                <ExitPageSettingsView />
              </Grid>
              <Grid item xs={8}>
                <ExitPageViewer />
              </Grid>
            </Grid> :
              <Grid container>
                <Grid item xs={12} style={{ padding: '0 10%' }}>
                  <ExitPageViewer />
                </Grid>
              </Grid>}
          </Card>
        )}
      </Container>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    padding: '35px 0px 20px'
  },
  content: {
    display: 'flex',
    backgroundColor: '#ffffff',
    marginTop: '30px'
  },
  loadingContainer: {
    maxWidth: '30rem'
  },
  placeHolder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));
