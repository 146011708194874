import React, { useState } from 'react';
import { Box, Link as MuiLink, withStyles, Theme, Typography, makeStyles, Tooltip, Avatar } from '@material-ui/core';
import { ROLES } from '../../../shared/globals';
import { CollectionSearchResults, UuidType } from '../../../shared/domain';
import { GetCollectionProps } from '../../../redux/thunk/thunks';
import {
    HeadCell,
    LastSorted,
    Pagination,
    RowCell,
    Table
} from '../../../components/Table';
import { colors } from '../../../styling/colors';

const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        padding: '0px 10px',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

interface CollectionTableProps {
    library?: boolean;
    collectionData: any | null;
    selectedCollections?: UuidType[];
    loadCollection: (getCollectionsProps: any) => void;
    setSelectedCollections?: (selectedCollections: UuidType[]) => void;
    viewCollectionOpen: (value: any) => void;
}

export const CollectionTable = ({
    library = false,
    collectionData,
    selectedCollections,
    loadCollection,
    setSelectedCollections,
    viewCollectionOpen
}: CollectionTableProps) => {
    const classes = useStyles();
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const [lastSorted, setLastSorted] = useState<LastSorted>({
        column: 'updated_at',
        order: 'desc'
    });

    const headCells: HeadCell[] = [
        {
            id: 'name',
            label: 'COLLECTION NAME'
        },
        {
            id: 'domains',
            label: 'DOMAINS',
            align: 'center',
            isSortDisabled: true
        },
        {
            id: 'forms',
            label: 'FORMS',
            align: 'center',
            isSortDisabled: true
        },
        {
            align: 'right',
            id: 'updated_at',
            label: 'LAST UPDATED'
        }
    ];
    const headCells1: HeadCell[] = [
        {
            id: 'name',
            label: 'COLLECTION NAME'
        },
        {
            id: 'domains',
            label: 'DOMAINS',
            align: 'center',
            isSortDisabled: true
        },
        {
            id: 'forms',
            label: 'FORMS',
            align: 'center',
            isSortDisabled: true
        },
        {
            id: 'collection_type',
            label: 'TYPE'
        },
        {
            align: 'right',
            id: 'updated_at',
            label: 'LAST UPDATED'
        }
    ];
    const rowCells = collectionData?.collections.map((collection: any) => {
        const TitleLink = (
            <Typography onClick={() => viewCollectionOpen(collection)} color="primary" variant="h5" style={{ maxWidth: '500px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', cursor: 'pointer' }}>
                {collection.name}
            </Typography>
        );
        const formsComponent =
            <HtmlTooltip
                title={<React.Fragment>
                    {collection.form_names && collection.form_names.map((form: any, i: number) => {
                        return (
                            <Typography style={{ padding: '5px 0px', borderBottom: (i + 1) === (collection.form_names && collection.form_names.length) ? 'unset' : '1px solid #00000026' }}>{form}</Typography>
                        )
                    })}
                </React.Fragment>}
            >
                <div>
                    <Typography color="primary" variant="h5" >
                        {collection.form_names && collection.form_names.length}
                    </Typography>
                    <Typography color="primary" variant="h5">
                        Forms
                    </Typography>
                </div>
            </HtmlTooltip>;
        const domainComponent = <HtmlTooltip
            title={<React.Fragment>
                {collection.domains && collection.domains.map((domain: any, i: number) => {
                    return (
                        <Typography style={{ padding: '5px 0px', borderBottom: (i + 1) === (collection.domains && collection.domains.length) ? 'unset' : '1px solid #00000026' }}>{domain.title}</Typography>
                    )
                })}
            </React.Fragment>}
        ><div>
                <Typography color="primary" variant="h5" >
                    {collection.domains && collection.domains.length}
                </Typography>
                <Typography color="primary" variant="h5">
                    Domains
                </Typography>
            </div>
        </HtmlTooltip>;
        var options: any = { weekday: 'long' };
        let newDate = new Date(collection.updated_at);
        const type = collection.collectionType === 'CUSTOM' ? <Avatar style={{ width: 32, height: 32 }}>C</Avatar> : <Avatar style={{ width: 32, height: 32, background: '#0060b2' }}>B</Avatar>;
        const rowCell: RowCell = [ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role) ? {
            name: { component: TitleLink, value: collection.name },
            domains: {
                align: 'center',
                component: domainComponent,
                value: collection.domains && collection.domains.length
            },
            forms: {
                align: 'center',
                component: formsComponent,
                value: collection.form_names && collection.form_names.length
            },
            collection_type: { component: type, value: '-' },
            updated_at: {
                align: 'right',
                value: new Intl.DateTimeFormat('en-US', options).format(newDate) + ', ' + (("0" + (newDate.getMonth() + 1)).slice(-2)) + '/' + ("0" + newDate.getDate()).slice(-2) + '/' + newDate.getFullYear()
            }
        } : {
            name: { component: TitleLink, value: collection.name },
            domains: {
                align: 'center',
                component: domainComponent,
                value: collection.domains && collection.domains.length
            },
            forms: {
                align: 'center',
                component: formsComponent,
                value: collection.form_names && collection.form_names.length
            },
            updated_at: {
                align: 'right',
                value: new Intl.DateTimeFormat('en-US', options).format(newDate) + ', ' + (("0" + (newDate.getMonth() + 1)).slice(-2)) + '/' + ("0" + newDate.getDate()).slice(-2) + '/' + newDate.getFullYear()
            }
        };

        return Object.assign(rowCell, { content: collection });
    });

    const handleNextButton = async () => {
        if (
            collectionData &&
            collectionData.page >= 0 &&
            collectionData.page < collectionData.total / collectionData.pageSize - 1
        ) {
            await loadCollection({
                domainId: collectionData?.domainId,
                collectionType: 'collections',
                library,
                search: collectionData.search,
                page: collectionData.page + 1,
                pageSize: collectionData.pageSize,
                sort: collectionData.sort,
                order: collectionData.order,
                favourites: collectionData.favourites,
            });
        }
    };
    const handlePrevButton = async () => {
        if (collectionData && collectionData.page > 0) {
            await loadCollection({
                domainId: collectionData?.domainId,
                collectionType: 'collections',
                library,
                search: collectionData.search,
                page: collectionData.page - 1,
                pageSize: collectionData.pageSize,
                sort: collectionData.sort,
                order: collectionData.order,
                favourites: collectionData.favourites,
            });
        }
    };

    const handlePageSize = (
        e: React.ChangeEvent<{
            name?: string;
            value: unknown;
        }>
    ) => {
        const pageSize: number = e.target.value as number;

        if (collectionData && pageSize > 0) {
            loadCollection({
                domainId: collectionData?.domainId,
                collectionType: 'collections',
                library,
                page: 0,
                pageSize: pageSize,
                search: collectionData.search,
                sort: collectionData.sort,
                order: collectionData.order,
                favourites: collectionData.favourites,
            });
        }
    };

    const pagination: Pagination | undefined = collectionData
        ? {
            contentName: 'Collections',
            page: collectionData.page,
            pageSize: collectionData.pageSize,
            total: collectionData.total,
            handleNextButton: handleNextButton,
            handlePageSize: handlePageSize,
            handlePrevButton: handlePrevButton
        }
        : undefined;

    const handleSort = (sort: string) => {
        const nextDirection = lastSorted.order === 'desc' ? 'asc' : 'desc';

        loadCollection({
            domainId: collectionData?.domainId,
            collectionType: 'collections',
            library,
            order: sort === lastSorted.column ? nextDirection : 'desc',
            page: 0,
            pageSize: collectionData?.pageSize,
            search: collectionData?.search,
            favourites: collectionData.favourites,
            sort
        });

        setLastSorted(
            sort === lastSorted.column
                ? { column: sort, order: nextDirection }
                : { column: sort, order: 'desc' }
        );
    };

    return (
        <Table
            data={collectionData?.collections}
            headCells={[ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role) ? headCells1 : headCells}
            // loading={collectionData?.domainId !== domainId}
            pagination={pagination}
            rowCells={rowCells}
            selection={
                selectedCollections && setSelectedCollections
                    ? {
                        selectedContents: selectedCollections,
                        setSelectedContents: (selected: UuidType[]) => setSelectedCollections(selected)
                    }
                    : undefined
            }
            sort={{
                lastSorted: lastSorted,
                handleSort: handleSort
            }}
        />
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    collectionBubble: {
        borderRadius: '0.75rem'
    },
    clearIcon: {
        color: colors.text6,
        fontSize: 14,
        marginLeft: '0.25rem'
    },
    titleLink: {
        textDecoration: 'none'
    }
}));
