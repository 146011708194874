import { Box, makeStyles, Theme, Typography, CircularProgress, Avatar } from '@material-ui/core';
import React, { useState } from 'react';
import { HeadCell, LastSorted, Pagination, RowCell, Table } from '../../../../components/Table';
import { GetReferralsProps, getSharedUsersThunk } from '../../../../redux/thunk/thunks';
import { UuidType } from '../../../../shared/domain';
import { ROLES } from '../../../../shared/globals';
import { ReferralProfile } from '../ReferralProfile/ReferralProfilePage';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/redux-store';
import { ShareToUser } from '../../../Resources/AssignPatient/ShareToUser';
import { useRouteMatch } from 'react-router-dom';

interface ReferralTableProps {
  ReferralId?: UuidType;
  referralsData: any | null;
  loadReferrals: (getBundlesProps: GetReferralsProps) => void;
  viewReferralOpen: (open: any) => void;
  viewReferralDialogueOpen: boolean | false;
  tab: string;
  selectedReferrals?: UuidType[];
  setSelectedReferrals?: (selectedReferral: UuidType[]) => void;
}

export const ReferralsTable = ({
  referralsData,
  loadReferrals,
  viewReferralOpen,
  viewReferralDialogueOpen,
  tab,
  setSelectedReferrals,
  selectedReferrals
}: ReferralTableProps) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [referralInfo, setReferralInfo] = useState<any>();
  const [lastSorted, setLastSorted] = useState<LastSorted>({
    column: 'city',
    order: 'desc'
  });
  const [openSharing, setOpenSharing] = useState(false);
  const [data, setData] = useState<any>(null);
  const organization_id: any = sessionStorage.getItem('pat_org_id');
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);
  const { path } = useRouteMatch();
  const split = path.split('/');
  const patient_role_id: any = split && split.length > 1 && split[2];

  const loading: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.getDataLoading);

  const headCells: HeadCell[] = [
    {
      id: 'first_name',
      label: 'REFERRAL NAME'
    },
    {
      id: 'specialities',
      label: 'SPECIALITIES',
      isSortDisabled: true
    },
    {
      id: 'email',
      label: 'CONTACT',
    },
    {
      id: 'city',
      label: 'LOCATION',
      isSortDisabled: true
    },
    {
      id: 'action',
      label: 'SHARING',
      isSortDisabled: true
    }
  ];
  const headCells1: HeadCell[] = [
    {
      id: 'first_name',
      label: 'REFERRAL NAME'
    },
    {
      id: 'specialities',
      label: 'SPECIALITIES',
      isSortDisabled: true
    },
    {
      id: 'email',
      label: 'CONTACT',
    },
    {
      id: 'city',
      label: 'LOCATION',
    },
    {
      id: 'org_id',
      label: 'TYPE'
    },
  ];

  const handelShareUser = async (referral: any) => {
    await dispatch(getSharedUsersThunk({ giveOnlyActiveUsers: true, user_role_id: patient_role_id, organization_id: organization_id }));
    setData(referral);
    setOpenSharing(true);
  }

  const handelClose = () => {
    setOpenSharing(false);
    setData(null);
  }

  const rowCells = referralsData?.data.map((referral: any) => {
    const nameLink = (<Box>
      <Typography color="primary" variant="h5" style={{ cursor: 'pointer' }} className={classes.referralName} onClick={(e) => { handleViewReferralDialogueOpen(referral); }}>
        {referral.first_name + ' ' + referral.last_name}
      </Typography>
      <Typography color={'textSecondary'} variant="h5" style={{ color: 'gray' }}>
        {referral.title}
      </Typography>
    </Box>
    );
    const specialties = <Box>
      {referral.specialities?.map((specialty: any, index: number) => {
        return (
          <>
            <Typography color={'textSecondary'} variant="h5" className={classes.tableBox}>
              {specialty.title}
            </Typography>
          </>
        );
      })
      }
    </Box>
    const contactDetails = (
      <>
        <Typography color={'textSecondary'} variant="h5" style={{ color: 'gray' }}>
          {referral.email}
        </Typography>
        <Typography color={'textSecondary'} variant="h5" style={{ color: 'gray' }}>
          {referral.mobile_phone}
        </Typography>
      </>
    );
    const location = (
      <>
        <Typography color={'textSecondary'} variant="h5" style={{ color: 'gray' }}>
          {referral.city && referral.state ? ((referral.city ? (referral.city + ', ') : '') + (referral?.state)) : '-'}
        </Typography>
        <Typography color={'textSecondary'} variant="h5" style={{ color: 'gray' }}>
          {referral.zip}
        </Typography>
      </>
    );
    const sharedUser = (
      <Typography color="primary" variant="h5" style={{ cursor: 'pointer' }} onClick={() => handelShareUser(referral)}>
        {referral?.sharedUsers?.length + ' Users'}
      </Typography>
    );
    const type = referral.org_id ? <Avatar style={{ width: 32, height: 32 }}>C</Avatar> : <Avatar style={{ width: 32, height: 32, background: '#0060b2' }}>B</Avatar>;
    let rowCell: RowCell = tab !== 'Referral Library' ? {
      first_name: { component: nameLink, value: (referral.first_name + ' ' + referral.last_name) },
      specialities: {
        component: specialties,
        value: '-'
      },
      email: {
        component: contactDetails,
        value: Object.create({ email: referral.email, phone: referral.mobile_phone })
      },
      city: { component: location, value: Object.create({ city: referral.city, state: referral.state }) },
      action: {
        component: sharedUser,
        value: referral?.sharedUsers?.length + ' Users'
      }
    } :
      {
        first_name: { component: nameLink, value: (referral.first_name + ' ' + referral.last_name) },
        specialities: {
          component: specialties,
          value: '-'
        },
        email: {
          component: contactDetails,
          value: Object.create({ email: referral.email, phone: referral.mobile_phone })
        },
        city: { component: location, value: Object.create({ city: referral.city, state: referral.state }) },
        org_id: { component: type, value: '-' },
      }

    return Object.assign(rowCell, { content: referral });
  });

  const handleNextButton = async () => {
    if (
      referralsData &&
      referralsData.page >= 0 &&
      referralsData.page < referralsData.totalCount / referralsData.pageSize - 1
    ) {
      await loadReferrals({
        search: referralsData.search,
        page: referralsData.page + 1,
        pageSize: referralsData.pageSize,
        sort: referralsData.sort,
        order: referralsData.order,
        favourites: referralsData?.favourites,
        filters: referralsData?.filters,
      });
    }
  };
  const handlePrevButton = async () => {
    if (referralsData && referralsData.page > 0) {
      await loadReferrals({
        search: referralsData.search,
        page: referralsData.page - 1,
        pageSize: referralsData.pageSize,
        sort: referralsData.sort,
        order: referralsData.order,
        favourites: referralsData?.favourites,
        filters: referralsData?.filters,
      });
    }
  };

  const handlePageSize = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    const pageSize = e.target.value as number;

    if (referralsData && pageSize > 0) {
      loadReferrals({
        page: 0,
        pageSize: pageSize,
        search: referralsData.search,
        sort: referralsData.sort,
        order: referralsData.order,
        favourites: referralsData?.favourites,
        filters: referralsData?.filters,
      });
    }
  };

  const pagination: Pagination | undefined = referralsData
    ? {
      contentName: 'Referrals',
      page: referralsData.page,
      pageSize: referralsData.pageSize,
      total: referralsData.totalCount,
      handleNextButton: handleNextButton,
      handlePageSize: handlePageSize,
      handlePrevButton: handlePrevButton
    }
    : undefined;

  const handleSort = (sort: string) => {
    const nextDirection = lastSorted.order === 'desc' ? 'asc' : 'desc';

    loadReferrals({
      order: sort === lastSorted.column ? nextDirection : 'desc',
      page: 0,
      pageSize: referralsData?.pageSize,
      search: referralsData?.search,
      sort,
      favourites: referralsData?.favourites,
      filters: referralsData?.filters,
    });

    setLastSorted(
      sort === lastSorted.column
        ? { column: sort, order: nextDirection }
        : { column: sort, order: 'desc' }
    );
  };

  const handleViewReferralDialogueClose = () => {
    viewReferralOpen(false);
  };

  const handleViewReferralDialogueOpen = (referralInfo: any) => {
    viewReferralOpen(true);
    setReferralInfo(referralInfo);
  };

  return (
    loading ? <Box display='flex' justifyContent='center'><CircularProgress /></Box> : <>
      {viewReferralDialogueOpen && (
        <ReferralProfile open={viewReferralDialogueOpen} handleClose={handleViewReferralDialogueClose} viewInfo={referralInfo} tab={tab} />
      )}
      {tab === 'Referral Library' ?
        <Table
          data={referralsData?.data}
          headCells={headCells1}
          loading={!referralsData?.data}
          pagination={pagination}
          rowCells={rowCells}
          selection={
            selectedReferrals && setSelectedReferrals
              ? {
                selectedContents: selectedReferrals,
                setSelectedContents: (selected: UuidType[]) => setSelectedReferrals(selected)
              }
              : undefined
          }
          sort={{
            lastSorted: lastSorted,
            handleSort: handleSort
          }}
        />
        :
        <Table
          data={referralsData?.data}
          headCells={headCells}
          loading={!referralsData?.data}
          pagination={pagination}
          rowCells={rowCells}
          sort={{
            lastSorted: lastSorted,
            handleSort: handleSort
          }}
        />
      }
      {openSharing && <ShareToUser handelClose={handelClose} data={data} section={'referral'} />}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  nameLink: {
    textDecoration: 'none'
  },
  referralName: {
    display: 'flex'
  },
  launchIcon: {
    fontSize: '2rem',
    cursor: 'pointer'
  },
  InfoIcon: {
    fontSize: 16,
    marginLeft: 6
  },
  color: {
    color: '#0060B2'
  },
  tableBox: {
    padding: '2px 8px',
    marginRight: '5px',
    minWidth: '80px',
  },
  actionTypography: {
    padding: '10px',
    cursor: 'pointer',
    fontWeight: 'bold'
  },
}));
