import React, { ChangeEvent, useEffect, useState } from 'react';
import { debounce } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { CataloguesSearchResults, UuidType } from '../../../shared/domain';
import { Dialog } from '../../../components/LegacyDialog';
import { HeadCell, Pagination, RowCell, Table } from '../../../components/Table';
import { RootState } from '../../../redux/redux-store';
import { getCataloguesThunk } from '../../../redux/thunk/thunks';

interface ChangeCatalogueDialogueProps {
  open: boolean;
  selectedResources: UuidType[];
  handleChangeCatalogue: (selectedCatalogue: UuidType, selectedResources: UuidType[]) => void;
  handleClose: () => void;
}

export const ChangeCatalogueDialogue = ({
  open,
  selectedResources,
  handleChangeCatalogue,
  handleClose
}: ChangeCatalogueDialogueProps) => {
  const dispatch = useDispatch();

  const catalogueData = useSelector(
    (state: RootState) => state.DATA_REDUCER.cataloguesSearchResults.changeCatalogue
  );

  const [selectedCatalogue, setselectedCatalogue] = useState<UuidType | null>(null);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  const headCells: HeadCell[] = [
    {
      id: 'name',
      label: 'name'
    }
  ];
  const rowCells = catalogueData?.catalogues.map((catalogue: any) => {
    const rowCell: RowCell = {
      name: { value: catalogue.title }
    };

    return Object.assign(rowCell, { content: catalogue });
  });

  const handleNextButton = async () => {
    if (
      catalogueData &&
      catalogueData.page >= 0 &&
      catalogueData.page < catalogueData.total / catalogueData.pageSize - 1
    ) {
      await dispatch(
        getCataloguesThunk({
          catalogueType: 'changeCatalogue',
          page: catalogueData.page + 1,
          pageSize: catalogueData.pageSize,
          search: catalogueData.search
        })
      );
    }
  };
  const handlePrevButton = async () => {
    if (catalogueData && catalogueData.page > 0) {
      await dispatch(
        getCataloguesThunk({
          catalogueType: 'changeCatalogue',
          page: catalogueData.page - 1,
          pageSize: catalogueData.pageSize,
          search: catalogueData.search
        })
      );
    }
  };

  const pagination: Pagination | undefined = catalogueData
    ? {
      contentName: 'Catalogues',
      page: catalogueData.page,
      pageSize: catalogueData.pageSize,
      total: catalogueData.total,
      handleNextButton: handleNextButton,
      handlePrevButton: handlePrevButton
    }
    : undefined;

  const searchCatalogues = (catalogueData: CataloguesSearchResults, value: string) =>
    debounce(
      () =>
        dispatch(
          getCataloguesThunk({
            catalogueType: 'changeCatalogue',
            page: 0,
            pageSize: catalogueData.pageSize,
            search: value
          })
        ),
      500
    );
  const handleSearch = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (catalogueData && e.target) {
      searchCatalogues(catalogueData, e.target.value)();
    }
  };

  useEffect(() => {
    if (catalogueData === null) {
      // dispatch(getCataloguesThunk({ catalogueType: 'changeCatalogue', pageSize: 8 }));
    }
  }, [catalogueData]);

  return (
    <Dialog
      open={open}
      primaryButton={{
        disabled: !selectedCatalogue,
        text: 'Change Catalogue',
        loading: showLoading,
        handleClick: () => {
          setShowLoading(true);
          handleChangeCatalogue(selectedCatalogue as UuidType, selectedResources);
        }
      }}
      secondaryButton={{ text: 'Cancel', handleClick: handleClose }}
      title="Change Catalogue"
      handleClose={handleClose}
      handleSearch={handleSearch}
    >
      <Table
        data={catalogueData?.catalogues}
        headCells={headCells}
        hideHead
        rowCells={rowCells}
        pagination={pagination}
        selection={{
          selectedContents: selectedCatalogue ? [selectedCatalogue] : [],
          setSelectedContents: (selected: UuidType[]) => {
            setselectedCatalogue(selected[1] ? selected[1] : selected[0]);
          }
        }}
      />
    </Dialog>
  );
};
