import React, { useEffect, useState } from 'react';
import { Box, Paper } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { OrganizationDialog } from './NewOrganizationDialog';
import { ViewOrganization } from './ViewOrganization';
import { CardHeader } from '../../../components/LegacyCardHeader';
import { UuidType } from '../../../shared/domain';
import { ConfirmationDialog } from '../../../components/ConfirmationDialog';
import { RootState } from '../../../redux/redux-store';
import { OrganizationTable } from './OrganizationTable';
import {
  GetOrganizationProps,
  deleteOrganizationThunk,
  getOrgnizationsThunk
} from '../../../redux/thunk/thunks';

export const Organizations = () => {
  const dispatch = useDispatch();
  const [searchPattern, setSearchPattern] = useState('');
  const [selectedCustomForms, setSelectedCustomForms] = useState<UuidType[]>([]);
  const [selectedLibraryForms, setSelectedLibraryForms] = useState<UuidType[]>([]);
  const [deleteUserDialogOpen, setDeleteUserDialogOpen] = useState<{
    open: boolean;
    user: any | null;
  }>({ open: false, user: null });
  const [createOrganizationOpen, setCreateOrganizationOpen] = useState(false);
  const [viewOrganization, setViewOrganization] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [editInfo, setEditInfo] = useState<any>();
  const [addToCollectionDialogOpen, setAddToCollectionDialogOpen] = useState(false);
  const [deleteFormsConfirmationDialogOpen, setDeleteFormsConfirmationDialogOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const organizationList: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.organizationList
  );

  const success: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.organizationSuccess)
  useEffect(() => {
    if (success) {
      setEditInfo(null);
      setEdit(false);
      setViewOrganization(false)
      setCreateOrganizationOpen(false);
    }
  })

  const viewOrganizationOpen = (info: any) => {
    setViewOrganization(true);
    setEditInfo(info);
  };
  const viewOrganizationClose = () => {
    setViewOrganization(false);
  };
  const handleCreateCollectionClose = () => {
    setCreateOrganizationOpen(false);
  };
  const handleCreateOrganizationOpen = () => {
    setCreateOrganizationOpen(true);
    setEdit(false);
  };
  const handleEditOrganizationOpen = () => {
    setCreateOrganizationOpen(true);
    setEdit(true);
  };
  const handleAddToCollectionDialogClose = () => {
    setAddToCollectionDialogOpen(false);
  };
  const handleDeleteFormsConfirmationDialogOpen = () => {
    setDeleteFormsConfirmationDialogOpen(true);
  };
  const handleDeleteFormsConfirmationDialogClose = () => {
    setDeleteFormsConfirmationDialogOpen(false);
  };

  const handleCustomSearch = (value: string) => {
    setSearchPattern(value);

    if (organizationList) {
      dispatch(
        getOrgnizationsThunk({
          page: 0,
          pageSize: organizationList.pageSize,
          search: value,
          order: organizationList.order,
          sort: organizationList.sort,
        })
      );
    }
  };

  const handleDeleteForms = async (library?: boolean) => {
    setDeleteLoading(true);

    const selectedForms = library ? selectedLibraryForms : selectedCustomForms;

    await Promise.all(
      selectedForms.map((form: UuidType) => {
        dispatch(deleteOrganizationThunk(form, null));
      })
    );

    setSelectedCustomForms([]);
    setSelectedLibraryForms([]);
    setDeleteLoading(false);
    setDeleteFormsConfirmationDialogOpen(false);
  };

  const loadOrganization = async (getFormsProps: GetOrganizationProps) => {
    await dispatch(
      getOrgnizationsThunk(getFormsProps)
    );
  };

  useEffect(() => {
    dispatch(getOrgnizationsThunk({}));
  }, []);

  const CustomForms = (
    <>
      {addToCollectionDialogOpen && organizationList && (
        <OrganizationDialog
          isOpen={addToCollectionDialogOpen}
          handleClose={handleAddToCollectionDialogClose}
        />
      )}

      {deleteFormsConfirmationDialogOpen && selectedCustomForms.length > 0 && (
        <ConfirmationDialog
          loading={deleteLoading}
          message='type "DELETE" to confirm.'
          open={deleteFormsConfirmationDialogOpen}
          title='Are you sure you want to delete? Please'
          handleClose={handleDeleteFormsConfirmationDialogClose}
          handleConfirmClick={() => handleDeleteForms(false)}
        />
      )}

      <Box pb={4} pt={1} px={4}>
        <OrganizationTable
          dataList={organizationList}
          selectedForms={selectedCustomForms}
          handleDeleteUserButton={(user: any) => setDeleteUserDialogOpen({ open: true, user })}
          loadOrganization={loadOrganization}
          setSelectedForms={(selectedForms: UuidType[]) => setSelectedCustomForms(selectedForms)}
          viewOrganizationOpen={viewOrganizationOpen}
        />
      </Box>
    </>
  );

  return (
    <>
      {createOrganizationOpen &&
        <OrganizationDialog isOpen={createOrganizationOpen}
          handleClose={handleCreateCollectionClose}
        />}
      <>
        {viewOrganization ? <Paper>
          <ViewOrganization handleClose={viewOrganizationClose} editOrganization={handleEditOrganizationOpen} viewInfo={editInfo} />
        </Paper>
          :
          <Paper>
            <Box display="flex" flexDirection="column">
              <CardHeader
                tabComponents={[
                  {
                    component: CustomForms,
                    label: '',
                    primaryButton: {
                      text: 'Add',
                      handleClick: handleCreateOrganizationOpen
                    },
                    selected: {
                      primaryButton: {
                        text: 'Invite',
                        handleClick: handleDeleteFormsConfirmationDialogOpen
                      },
                      secondaryButton: {
                        text: 'Cancel Invite',
                        handleClick: handleDeleteFormsConfirmationDialogOpen
                      },
                      deleteButton: {
                        text: 'De-Activate',
                        handleClick: handleDeleteFormsConfirmationDialogOpen
                      },
                      selectedContents: selectedCustomForms,
                      handleSelectedDelete: handleDeleteFormsConfirmationDialogOpen
                    },
                    style: {
                      background: true,
                      spacing: { py: 3, px: 4 }
                    },
                    handleSearch: handleCustomSearch
                  }
                ]}
              />
            </Box>
          </Paper>
        }
      </>
    </>
  );
};
