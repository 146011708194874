import React, { useEffect, useState } from 'react';
import { Box, Paper } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { NewSharedUserDialog } from './NewSharedUser';
import { CardHeader } from '../../../components/LegacyCardHeader';
import { UuidType } from '../../../shared/domain';
import { ConfirmationDialog } from '../../../components/ConfirmationDialog';
import { RootState } from '../../../redux/redux-store';
import { SharedUserTable } from './SharedUserTable';
import {
    deleteOrganizationThunk, getSharedUsersThunk
} from '../../../redux/thunk/thunks';
import { setSuccessAction } from '../../../redux/display/display-actions';
import { getSharedUsersAction } from '../../../redux/data/data-actions';

export const SharedUsers = () => {
    const dispatch = useDispatch();
    const [searchPattern, setSearchPattern] = useState('');
    const [selectedUsers, setSelectedUsers] = useState<UuidType[]>([]);
    const [selectedLibraryForms, setSelectedLibraryForms] = useState<UuidType[]>([]);
    const [createSharedUserOpen, setCreateSharedUserOpen] = useState(false);
    const [viewOrganization, setViewOrganization] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [editInfo, setEditInfo] = useState<any>();
    const [addToCollectionDialogOpen, setAddToCollectionDialogOpen] = useState(false);
    const [deleteFormsConfirmationDialogOpen, setDeleteFormsConfirmationDialogOpen] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const sharedUsers: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.sharedUsers
    );

    const success: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.deleteUserSuccess)
    useEffect(() => {
        if (success) {
            setEditInfo(null);
            setEdit(false);
            setCreateSharedUserOpen(false);
            dispatch(setSuccessAction('deleteUserSuccess', false));
            dispatch(getSharedUsersThunk({ pageSize: sharedUsers?.pageSize, page: sharedUsers?.page, search: searchPattern, order: sharedUsers?.order, sort: sharedUsers?.sort, }));
        }
    })

    const handleCreateSharedUserClose = () => {
        setCreateSharedUserOpen(false);
    };
    const handleCreateSharedUserOpen = () => {
        setCreateSharedUserOpen(true);
        setEdit(false);
    };
    const handleAddToCollectionDialogClose = () => {
        setAddToCollectionDialogOpen(false);
    };
    const handleDeleteUserConfirmationDialogOpen = () => {
        setDeleteFormsConfirmationDialogOpen(true);
    };
    const handleDeleteFormsConfirmationDialogClose = () => {
        setDeleteFormsConfirmationDialogOpen(false);
    };

    const handleCustomSearch = (value: string) => {
        setSearchPattern(value);

        if (sharedUsers) {
            dispatch(
                getSharedUsersThunk({
                    page: 0,
                    pageSize: sharedUsers?.pageSize,
                    order: sharedUsers?.order,
                    sort: sharedUsers?.sort,
                    search: value
                })
            );
        }
    };

    const handleDeleteForms = async (library?: boolean) => {
        setDeleteLoading(true);

        const selectedForms = library ? selectedLibraryForms : selectedUsers;

        await Promise.all(
            selectedForms.map((form: UuidType) => {
                dispatch(deleteOrganizationThunk(form, null));
            })
        );

        setSelectedUsers([]);
        setSelectedLibraryForms([]);
        setDeleteLoading(false);
        setDeleteFormsConfirmationDialogOpen(false);
    };

    const loadSharedUsers = async (getFormsProps: any) => {
        await dispatch(
            getSharedUsersThunk(getFormsProps)
        );
    };

    useEffect(() => {
        dispatch(getSharedUsersThunk({}));

        return function CleanUp() {
            dispatch(getSharedUsersAction(null));
        };
    }, []);

    const CustomForms = (
        <>
            {addToCollectionDialogOpen && sharedUsers && (
                <NewSharedUserDialog
                    isOpen={addToCollectionDialogOpen}
                    handleClose={handleAddToCollectionDialogClose}
                />
            )}

            {deleteFormsConfirmationDialogOpen && selectedUsers.length > 0 && (
                <ConfirmationDialog
                    loading={deleteLoading}
                    message='type "DELETE" to confirm.'
                    open={deleteFormsConfirmationDialogOpen}
                    title='Are you sure you want to delete? Please'
                    handleClose={handleDeleteFormsConfirmationDialogClose}
                    handleConfirmClick={() => handleDeleteForms(false)}
                />
            )}

            <Box pb={4} pt={1} px={4}>
                <SharedUserTable
                    dataList={sharedUsers}
                    loadSharedUsers={loadSharedUsers}
                />
            </Box>
        </>
    );

    return (
        <>
            {createSharedUserOpen &&
                <NewSharedUserDialog isOpen={createSharedUserOpen}
                    handleClose={handleCreateSharedUserClose}
                />}
            <>
                <Paper>
                    <Box display="flex" flexDirection="column">
                        <CardHeader
                            tabComponents={[
                                {
                                    component: CustomForms,
                                    label: '',
                                    primaryButton: {
                                        text: 'Add Shared User',
                                        handleClick: handleCreateSharedUserOpen
                                    },
                                    selected: {
                                        selectedContents: selectedUsers,
                                        handleSelectedDelete: handleDeleteUserConfirmationDialogOpen
                                    },
                                    style: {
                                        background: true,
                                        spacing: { py: 3, px: 4 }
                                    },
                                    handleSearch: handleCustomSearch
                                }
                            ]}
                        />
                    </Box>
                </Paper>
            </>
        </>
    );
};
