import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DragIndicator } from '@material-ui/icons';
import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorNotice } from '../../../../components/ErrorNotice';
import {
  setEditingCalculationsAction,
  setEditingFieldLogicRulesAction,
  setEditingFormFieldAction,
  setFormFieldOptionsToDeleteAction
} from '../../../../redux/data/data-actions';
import { RootState } from '../../../../redux/redux-store';
import {
  deleteFormFieldThunk,
  getCalculationsByFieldIdThunk,
  getFieldLogicRulesThunk
} from '../../../../redux/thunk/thunks';
import { FieldOptionInput, FormField, UuidType } from '../../../../shared/domain';
import { FIELD_TYPES } from '../../../../shared/globals';
import { FieldSettingsView } from '../FieldSettingsView';
import { DropdownFieldBlock } from './DropdownFieldBlock';
import { InfoTextFieldBlock } from './InfoTextBlock';
import { ResourceBlock } from './ResourceBlock';
import { LongTextFieldBlock } from './LongTextFieldBlock';
import { MultipleChoiceFieldBlock } from './MultipleChoiceFieldBlock.tsx';
import { ScaleFieldBlock } from './ScaleFieldBlock';
import { ShortTextFieldBlock } from './ShortTextFieldBlock';
import { SingleChoiceFieldBlock } from './SingleChoiceFieldBlock';

interface FieldBlockContainerProps {
  loading: boolean;
  loadingError: null | string;
  index: number;
  formId: UuidType;
  formField: FormField | any;
  createNewFieldObject: (
    fieldType: string,
    nextFormField: UuidType | null,
    fieldPrompt?: string,
    fieldOptions?: FieldOptionInput[],
    fieldScaleMax?: number,
    formField?: any
  ) => void;
}

export const FieldBlockContainer: React.FC<FieldBlockContainerProps> = ({
  loadingError,
  index,
  formId,
  formField,
  createNewFieldObject
}) => {
  const classes = useStyles();
  const deleteFormFieldError = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayErrors.deleteFormFieldError
  );
  const editingFormFields = useSelector((state: RootState) => state.DATA_REDUCER.editingFormFields);
  const deleteFormFieldLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.deleteFormFieldLoading
  );
  const updateFormFieldLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.updateFormFieldLoading
  );
  const updateFormFieldError = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayErrors.updateFormFieldError
  );
  const updateFormFieldId = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.updateFormFieldId
  );
  const deleteFormFieldId = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.deleteFormFieldId
  );
  const [error, setError] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();

  const deleteField = () => {
    hideDialog();
    dispatch(deleteFormFieldThunk(formField.id, formId));
    sessionStorage.setItem('formTab', 'field');
  };
  const duplicateField = () => {
    if (editingFormFields) {
      createNewFieldObject(
        formField?.field_type,
        editingFormFields[index].id,
        formField?.field_prompt,
        formField?.form_field_options,
        formField?.option_scale_max,
        formField
      );
    }
  };
  const showDialog = () => {
    setDeleteModal(true);
  };
  const hideDialog = () => {
    setDeleteModal(false);
  };
  const openFieldSettings = () => {
    //fetches and sets data in
    dispatch(getCalculationsByFieldIdThunk(formField.id));
    dispatch(getFieldLogicRulesThunk(formField.id));
    dispatch(setEditingFormFieldAction(formField));
    setEditMode(true);
    sessionStorage.setItem('formTab', 'field');
  };
  const closeFieldSettings = () => {
    //handles cleanup of editing data in redux
    dispatch(setEditingFormFieldAction(null));
    dispatch(setFormFieldOptionsToDeleteAction(null));
    dispatch(setEditingFieldLogicRulesAction(null));
    dispatch(setEditingCalculationsAction(null));
    setEditMode(false);
  };
  const loading =
    (updateFormFieldLoading && updateFormFieldId === formField.id) ||
    (deleteFormFieldLoading && deleteFormFieldId === formField.id);

  const fieldType = (fieldType: string) => {
    const structuredOptions = formField?.form_field_options?.sort(function (a: any, b: any) { return a?.option_value - b?.option_value });
    // const structuredOptions: any = formField.form_field_options?.map((ffo: any) => ({
    //   option_text: ffo.option_text || ''
    // })) || [];

    const fieldTitle = formField?.field_prompt?.split("\n") || null;
    const scaleMax = formField?.option_scale_max || 0;
    switch (fieldType) {
      case FIELD_TYPES.SHORT_TEXT:
        return (
          <>
            <Title fieldTitle="SHORT ANSWER" />
            <ShortTextFieldBlock fieldTitle={fieldTitle} />
          </>
        );
      case FIELD_TYPES.LONG_TEXT:
        return (
          <>
            <Title fieldTitle="LONG ANSWER" />
            <LongTextFieldBlock fieldTitle={fieldTitle} />
          </>
        );
      case FIELD_TYPES.DROPDOWN:
        return (
          <>
            <Title fieldTitle="DROPDOWN" />
            <DropdownFieldBlock fieldTitle={fieldTitle} options={structuredOptions} />
          </>
        );
      case FIELD_TYPES.SINGLE_CHOICE:
        return (
          <>
            <Title fieldTitle="SINGLE CHOICE" />
            <SingleChoiceFieldBlock fieldTitle={fieldTitle} options={structuredOptions} />
          </>
        );
      case FIELD_TYPES.MULTIPLE_CHOICE:
        return (
          <>
            <Title fieldTitle="MULTIPLE CHOICE" />
            <MultipleChoiceFieldBlock
              fieldTitle={fieldTitle}
              options={structuredOptions}
            />
          </>
        );
      case FIELD_TYPES.SCALE:
        return (
          <>
            <Title fieldTitle="SCALE" />
            <ScaleFieldBlock fieldTitle={fieldTitle} scaleMax={scaleMax} />
          </>
        );
      case FIELD_TYPES.INFO_TEXT:
        return (
          <>
            <Title fieldTitle="TEXT BLOCK" />
            <InfoTextFieldBlock infoText={fieldTitle} />
          </>
        );
      case FIELD_TYPES.RESOURCE:
        return (
          <>
            <Title fieldTitle="RESOURCE" />
            <ResourceBlock infoText={fieldTitle} files={formField?.resources || []} index={null} />
          </>
        );
      default:
        break;
    }
  };

  const Title = ({ fieldTitle }: { fieldTitle: string }) => (
    <div className={classes.header}>
      <Typography color={'textSecondary'} variant="subtitle2">
        {fieldTitle} FIELD
      </Typography>
      <div className={classes.errorNotice}>
        {updateFormFieldError && updateFormFieldId === formField?.id ? (
          <ErrorNotice error={updateFormFieldError} />
        ) : null}
        {deleteFormFieldError && deleteFormFieldId === formField?.id ? (
          <ErrorNotice error={deleteFormFieldError} />
        ) : null}
      </div>
    </div>
  );
  return (
    <Draggable
      isDragDisabled={loading || error}
      draggableId={formField.id}
      index={index}
      key={formField.id}
    >
      {(provided, snapshot) => (
        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          <FieldSettingsView open={editMode} handleClose={closeFieldSettings} />
          <Dialog open={showDeleteModal} onClose={showDialog}>
            <DialogTitle>Delete Field</DialogTitle>
            <DialogContent dividers>Are you sure you want to delete this field?</DialogContent>
            <DialogActions>
              <Button autoFocus onClick={hideDialog} color={'primary'} aria-label={'cancel'}>
                Cancel
              </Button>
              <Button autoFocus onClick={deleteField}>
                <Typography variant={'subtitle2'} color={'error'} aria-label={'confirm delete'}>
                  Yes, Delete
                </Typography>
              </Button>
            </DialogActions>
          </Dialog>
          <Card
            variant={'outlined'}
            className={snapshot.isDragging ? classes.shadowBlockCard : classes.blockCard}
            classes={loading ? { root: classes.loading } : {}}
          >
            <div className={classes.cardContent}>{fieldType(formField.field_type)}</div>
            <div className={classes.cardBottom}>
              <IconButton className={classes.bottomBtn} size={'small'} aria-label={'drag field'}>
                <DragIndicator />
              </IconButton>
              <Button
                onClick={duplicateField}
                disabled={loading}
                className={classes.bottomBtn}
                aria-label={'duplicate field'}
              >
                Duplicate
              </Button>
              <Button
                disabled={loading}
                onClick={openFieldSettings}
                className={classes.bottomBtn}
                aria-label={'previous'}
              >
                Edit
              </Button>
              <Button
                disabled={loading}
                className={classes.bottomBtn}
                onClick={showDialog}
                aria-label={'remove field'}
              >
                <Typography variant={'subtitle2'} color={'error'}>
                  Remove
                </Typography>
              </Button>
            </div>
          </Card>
        </div>
      )}
    </Draggable>
  );
};

const useStyles = makeStyles((theme) => ({
  blockCard: {},
  loading: {
    opacity: 0.8
  },
  disabledActions: {
    opacity: 0.5,
    display: 'flex',
    borderTop: `1px solid ${theme.palette.divider}`
  },
  shadowBlockCard: {
    boxShadow: theme.shadows[5]
  },
  cardBottom: {
    display: 'flex',
    borderTop: `1px solid ${theme.palette.divider}`
  },
  cardContent: {
    display: 'grid',
    gridGap: '.4rem',
    padding: '1rem'
  },
  bottomBtn: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRadius: 0,
    marginLeft: -1,
    paddingLeft: '1rem',
    paddingRight: '1rem'
  },
  errorNotice: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  errorIcon: {
    marginRight: '.2rem'
  }
}));
