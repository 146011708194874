import React, { ChangeEvent, useState } from 'react';
import { Box, Theme, Typography, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Dialog } from '../../../components/Dialog';
import { LoadingButton } from '../../../components/LoadingButton';
import { TitledTextInput } from '../../../components/TitledTextInput';
import { colors } from '../../../styling/colors';
import { createCatalogueThunk, getCataloguesThunk } from '../../../redux/thunk/thunks';

interface NewCatalogueDialogProps {
  open: boolean;
  handleClose: () => void;
}

export const NewCatalogueDialog = ({ open, handleClose }: NewCatalogueDialogProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [catalogueName, setCatalogueName] = useState('');

  const handleCatalogueNameChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setCatalogueName(e.target?.value);
  };

  const handleCreateCatalogue = async () => {
    setLoading(true);
    await dispatch(createCatalogueThunk({ name: catalogueName }));
    await dispatch(getCataloguesThunk({ catalogueType: 'completeList', pageSize: 0 }));
    setLoading(false);
    handleClose();
  };

  return (
    <Dialog handleClose={handleClose} open={open}>
      <Box display="flex" flexDirection="column">
        <Box
          alignItems="center"
          bgcolor={colors.background2}
          className={classes.topBox}
          display="flex"
          justifyContent="space-between"
          px={3}
          py={2.5}
        >
          <Typography variant="h3">Creating New Catalogue </Typography>
        </Box>
        <Box px={3} py={2.5}>
          <TitledTextInput
            className={classes.inputField}
            name="name"
            noValidation
            onChange={handleCatalogueNameChange}
            size="small"
            title="Catalogue Name"
            variant="outlined"
          />
        </Box>

        <Box
          bgcolor={colors.background2}
          className={classes.bottomBox}
          display="flex"
          justifyContent="space-between"
          px={3}
          py={2}
        >
          <LoadingButton
            className={classes.secondaryButton}
            color="secondary"
            onClick={handleClose}
            variant="contained"
            aria-label={'cancel'}
          >
            Cancel
          </LoadingButton>

          <LoadingButton
            className={classes.primaryButton}
            color="primary"
            loading={loading}
            onClick={handleCreateCatalogue}
            variant="contained"
            aria-label={'create new Resource'}
            disabled={catalogueName.length === 0}
          >
            Create Catalogue
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  topBox: {
    borderRadius: '0.625rem 0.625rem 0 0'
  },

  bottomBox: {
    borderRadius: '0 0 0.625rem 0.625rem'
  },
  primaryButton: {
    boxShadow:
      '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)'
  },
  inputField: {
    maxWidth: '28rem',
    width: '80vw',
    paddingBottom: '0.5rem'
  }
}));
