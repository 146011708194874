import React, { useState, useEffect } from 'react';
import { Box, Theme, Typography, makeStyles, Grid, TextField, Select, InputBase, DialogActions, MenuItem, ListItemAvatar, ListItem, ListItemText, Avatar } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from '../../../../components/Dialog';
import { LoadingButton } from '../../../../components/LoadingButton';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { RootState } from '../../../../redux/redux-store';
import { createAppointmentThunk, updateAppointmentThunk, deleteAppointmentThunk } from '../../../../redux/thunk/thunks';
import { timezoneNames } from "@progress/kendo-date-math";
import "@progress/kendo-date-math/tz/all";
import { withStyles } from '@material-ui/core/styles';
import { ROLES } from '../../../../shared/globals';
import { useRouteMatch } from 'react-router-dom';
const moment = require('moment-timezone');

const timeArray = [
  { time: '12:00am', no: 1, time1: '00:00' }, { time: '12:15am', no: 2, time1: '00:15' }, { time: '12:30am', no: 3, time1: '00:30' }, { time: '12:45am', no: 4, time1: '00:45' },
  { time: '01:00am', no: 5, time1: '01:00' }, { time: '01:15am', no: 6, time1: '01:15' }, { time: '01:30am', no: 7, time1: '01:30' }, { time: '01:45am', no: 8, time1: '01:45' },
  { time: '02:00am', no: 9, time1: '02:00' }, { time: '02:15am', no: 10, time1: '02:15' }, { time: '02:30am', no: 11, time1: '02:30' }, { time: '02:45am', no: 12, time1: '02:45' },
  { time: '03:00am', no: 13, time1: '03:00' }, { time: '03:15am', no: 14, time1: '03:15' }, { time: '03:30am', no: 15, time1: '03:30' }, { time: '03:45am', no: 16, time1: '03:45' },
  { time: '04:00am', no: 17, time1: '04:00' }, { time: '04:15am', no: 18, time1: '04:15' }, { time: '04:30am', no: 19, time1: '04:30' }, { time: '04:45am', no: 20, time1: '04:45' },
  { time: '05:00am', no: 21, time1: '05:00' }, { time: '05:15am', no: 22, time1: '05:15' }, { time: '05:30am', no: 23, time1: '05:30' }, { time: '05:45am', no: 24, time1: '05:45' },
  { time: '06:00am', no: 25, time1: '06:00' }, { time: '06:15am', no: 26, time1: '06:15' }, { time: '06:30am', no: 27, time1: '06:30' }, { time: '06:45am', no: 28, time1: '06:45' },
  { time: '07:00am', no: 29, time1: '07:00' }, { time: '07:15am', no: 30, time1: '07:15' }, { time: '07:30am', no: 31, time1: '07:30' }, { time: '07:45am', no: 32, time1: '07:45' },
  { time: '08:00am', no: 33, time1: '08:00' }, { time: '08:15am', no: 34, time1: '08:15' }, { time: '08:30am', no: 35, time1: '08:30' }, { time: '08:45am', no: 36, time1: '08:45' },
  { time: '09:00am', no: 37, time1: '09:00' }, { time: '09:15am', no: 38, time1: '09:15' }, { time: '09:30am', no: 39, time1: '09:30' }, { time: '09:45am', no: 40, time1: '09:45' },
  { time: '10:00am', no: 41, time1: '10:00' }, { time: '10:15am', no: 42, time1: '10:15' }, { time: '10:30am', no: 43, time1: '10:30' }, { time: '10:45am', no: 44, time1: '10:45' },
  { time: '11:00am', no: 45, time1: '11:00' }, { time: '11:15am', no: 46, time1: '11:15' }, { time: '11:30am', no: 47, time1: '11:30' }, { time: '11:45am', no: 48, time1: '11:45' },
  { time: '12:00pm', no: 49, time1: '12:00' }, { time: '12:15pm', no: 50, time1: '12:15' }, { time: '12:30pm', no: 51, time1: '12:30' }, { time: '12:45pm', no: 52, time1: '12:45' },
  { time: '01:00pm', no: 53, time1: '13:00' }, { time: '01:15pm', no: 54, time1: '13:15' }, { time: '01:30pm', no: 55, time1: '13:30' }, { time: '01:45pm', no: 56, time1: '13:45' },
  { time: '02:00pm', no: 57, time1: '14:00' }, { time: '02:15pm', no: 58, time1: '14:15' }, { time: '02:30pm', no: 59, time1: '14:30' }, { time: '02:45pm', no: 60, time1: '14:45' },
  { time: '03:00pm', no: 61, time1: '15:00' }, { time: '03:15pm', no: 62, time1: '15:15' }, { time: '03:30pm', no: 63, time1: '15:30' }, { time: '03:45pm', no: 64, time1: '15:45' },
  { time: '04:00pm', no: 65, time1: '16:00' }, { time: '04:15pm', no: 66, time1: '16:15' }, { time: '04:30pm', no: 67, time1: '16:30' }, { time: '04:45pm', no: 68, time1: '16:45' },
  { time: '05:00pm', no: 69, time1: '17:00' }, { time: '05:15pm', no: 70, time1: '17:15' }, { time: '05:30pm', no: 71, time1: '17:30' }, { time: '05:45pm', no: 72, time1: '17:45' },
  { time: '06:00pm', no: 73, time1: '18:00' }, { time: '06:15pm', no: 74, time1: '18:15' }, { time: '06:30pm', no: 75, time1: '18:30' }, { time: '06:45pm', no: 76, time1: '18:45' },
  { time: '07:00pm', no: 77, time1: '19:00' }, { time: '07:15pm', no: 78, time1: '19:15' }, { time: '07:30pm', no: 79, time1: '19:30' }, { time: '07:45pm', no: 80, time1: '19:45' },
  { time: '08:00pm', no: 81, time1: '20:00' }, { time: '08:15pm', no: 82, time1: '20:15' }, { time: '08:30pm', no: 83, time1: '20:30' }, { time: '08:45pm', no: 84, time1: '20:45' },
  { time: '09:00pm', no: 85, time1: '21:00' }, { time: '09:15pm', no: 86, time1: '21:15' }, { time: '09:30pm', no: 87, time1: '21:30' }, { time: '09:45pm', no: 88, time1: '21:45' },
  { time: '10:00pm', no: 89, time1: '22:00' }, { time: '10:15pm', no: 90, time1: '22:15' }, { time: '10:30pm', no: 91, time1: '22:30' }, { time: '10:45pm', no: 92, time1: '22:45' },
  { time: '11:00pm', no: 93, time1: '23:00' }, { time: '11:15pm', no: 94, time1: '23:15' }, { time: '11:30pm', no: 95, time1: '23:30' }, { time: '11:45pm', no: 96, time1: '23:45' },
];

const timezones = timezoneNames()
  .filter((l) => l.indexOf("/") > -1)
  .sort((a, b) => a.localeCompare(b));

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '6px 26px 6px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);


interface NewAppointmentDialogProps {
  open: boolean;
  handleClose: () => void;
  patient: any;
  appointmentInfo: any;
  edit: boolean
}

interface appointmentFormValues {
  patientId: string;
  date: string;
  startTime: string;
  endTime: string;
  description: string;
  id: string;
  timezone: string;
  patientName: string;
}

export const NewAppointment = ({ open, handleClose, patient, appointmentInfo, edit }: NewAppointmentDialogProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);
  const role: any = sessionStorage.getItem('role');
  const { path } = useRouteMatch();
  const split = path.split('/');
  const patient_role_id: any = split && split.length > 1 && split[2];
  const shared_role_id: any = split && split.length > 3 && split[4];
  const [disDate, setDisabledDate] = useState<any>();
  const [isSave, setIsSave] = useState(false);
  const [fieldDis, setFieldDis] = useState(false);
  const [isError, setError] = useState(false);
  const [input, setInput] = useState(null);
  const user: any = useSelector((state: RootState) => state.DATA_REDUCER.patientInfo);
  const sharedUser: any = useSelector((state: RootState) => state.DATA_REDUCER.sharedUser);
  const [IsDisable, setDisable] = useState<any>();
  const [initialValues, setInitialValues] = useState<appointmentFormValues>({
    startTime: '',
    patientId: role === 'PATIENT' ? patient_role_id : shared_role_id,
    patientName: role === 'PATIENT' ? (user?.first_name + ' ' + user?.last_name) : (sharedUser?.first_name + ' ' + sharedUser?.last_name),
    id: '',
    date: '',
    endTime: '',
    description: '',
    timezone: 'America/New_York'
  });

  useEffect(() => {
    if ([ROLES.PATIENT, ROLES.PROXY, ROLES.RATER].includes(loggedInUser.role)) {
      setFieldDis(true);
    }
  }, [])
  const message = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.snackbarAlertMessage
  );

  useEffect(() => {
    let newDate: any = Date.now();
    setDisabledDate(new Date(newDate).getFullYear() + '-' + (("0" + (new Date(newDate).getMonth() + 1)).slice(-2)) + '-' + ("0" + new Date(newDate).getDate()).slice(-2));
    if (edit && initialValues.description === "") {
      const conStartDate = moment(appointmentInfo.startDate);  // Create a moment object from the specific date
      const startDateZone = conStartDate.tz(appointmentInfo?.timezone).format('YYYY-MM-DD HH:mm'); // Convert to a different time zone
      const [date, startTime] = startDateZone.split(' ');
      const conEndDate = moment(appointmentInfo.endDate);  // Create a moment object from the specific date
      const endDateZone = conEndDate.tz(appointmentInfo?.timezone).format('YYYY-MM-DD HH:mm'); // Convert to a different time zone
      const [endDate, endTime] = endDateZone.split(' ');

      setInitialValues({
        patientId: appointmentInfo.patientId,
        id: appointmentInfo.id,
        date: date,
        startTime: startTime,
        endTime: endTime,
        description: appointmentInfo.notes,
        timezone: appointmentInfo.timezone,
        // patientName: user?.first_name + ' ' + user?.last_name,
        patientName: appointmentInfo?.patient_first_name + ' ' + appointmentInfo?.patient_last_name,
      });
      const filter = timeArray.filter((item: any) => item.time1 === startTime);
      filter && filter.length > 0 && setDisable(filter[0].no);
    }
  }, [edit]);

  useEffect(() => {
    if (message && message.length > 0) {
      setIsSave(false);
      handleClose();
    }
  }, [message]);

  const submitAppointment = async (values: appointmentFormValues, actions: any) => {
    actions.setSubmitting(true);
    await dispatch(createAppointmentThunk(values));
    actions.setSubmitting(false);
  };

  const editAppointment = (values: appointmentFormValues, actions: any) => {
    actions.setSubmitting(true);
    dispatch(updateAppointmentThunk(appointmentInfo.id, values));
    actions.setSubmitting(false);
  };

  const patientInfoSchema = Yup.object().shape({
    patientId: Yup.string().required('Required'),
    date: Yup.string().required('Required'),
    startTime: Yup.string().required('Required'),
    endTime: Yup.string().required('Required'),
    timezone: Yup.string().required('Required'),
    description: Yup.string().max(500, ''),
  });

  const handleChange = (value: any, no: any) => {
    if (no === 1 && value.length > 500) {
      setError(true); setInput(no);
    } else {
      setError(false); setInput(no);
    }
  }

  const handelSetEndTime = (value: any) => {
    const filter = timeArray.filter((item: any) => item.time === value);
    filter && filter.length > 0 && setDisable(filter[0].no);
  };

  return (
    <Dialog handleClose={handleClose} open={open} >
      <Box px={3} pt={3} pb={3} style={{ background: '#f9fafb', borderRadius: '10px 10px 0px 0px' }}>
        <Box>
          <Typography color="textSecondary" variant="h1">
            {edit ? 'Appointment' : 'Add an Appointment'}
          </Typography>
        </Box>
      </Box>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={edit ? editAppointment : submitAppointment}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={patientInfoSchema}
      >
        {({ errors, isSubmitting, setSubmitting, touched, values }) => {

          return (
            <Form style={{ width: '450px' }}>
              <Box display='flex' flexDirection='column' className={classes.box}>
                <Box pb={2.5}>
                  <Box pb={0.75}>
                    <Typography color="textSecondary" variant="subtitle2">
                      User*
                    </Typography>
                  </Box>
                  <Field disabled as={TextField} name="patientName" variant="outlined" size="small" className={classes.textField} />

                  {errors.patientId && touched.patientId ? (
                    <Typography color="error" variant="subtitle2">
                      {errors.patientId}
                    </Typography>
                  ) : null}
                </Box>
                <Box pb={2.5}>
                  <Box pb={0.75}>
                    <Typography color="textSecondary" variant="subtitle2">
                      Time Zone*
                    </Typography>
                  </Box>
                  <Field disabled={fieldDis} as={Select} name="timezone" variant="outlined" size="small" className={classes.textField1}>
                    {timezones && timezones.map((zone: any) => {
                      return (
                        <MenuItem value={zone} style={{ padding: '0px' }}>{zone}</MenuItem>
                      )
                    })}
                  </Field>
                  {errors.timezone && touched.timezone ? (
                    <Typography color="error" variant="subtitle2">
                      {errors.timezone}
                    </Typography>
                  ) : null}
                </Box>
                <Box pb={2.5}>
                  <Box pb={0.75}>
                    <Typography color="textSecondary" variant="subtitle2">
                      Date*
                    </Typography>
                  </Box>

                  <Field disabled={fieldDis}
                    as={TextField}
                    className={classes.textField}
                    name="date"
                    size="small"
                    type="date"
                    variant="outlined"
                    InputProps={{ inputProps: { min: disDate } }}
                  />

                  {errors.date && touched.date ? (
                    <Typography color="error" variant="subtitle2">
                      {errors.date}
                    </Typography>
                  ) : null}
                </Box>

                <Box pb={2.5}>
                  <Grid alignItems="center" container justify="center" spacing={3}>
                    <Grid item xs={6}>
                      <Box pb={0.75}>
                        <Typography color="textSecondary" variant="subtitle2">
                          Start Time*
                        </Typography>
                      </Box>
                      <Field disabled={fieldDis} as={Select} name="startTime" variant="outlined" size="small" className={classes.textField1}>
                        {timeArray && timeArray.map((zone: any) => {
                          return (
                            <MenuItem value={zone.time1} style={{ padding: '5px' }} onClick={(e: any) => handelSetEndTime(zone.time)}>{zone.time}</MenuItem>
                          )
                        })}
                      </Field>

                      {errors.startTime && touched.startTime ? (
                        <Typography color="error" variant="subtitle2">
                          {errors.startTime}
                        </Typography>
                      ) : null}
                    </Grid>
                    <Grid item xs={6}>
                      <Box pb={0.75}>
                        <Typography color="textSecondary" variant="subtitle2">
                          End Time*
                        </Typography>
                      </Box>
                      <Field disabled={fieldDis} as={Select} name="endTime" variant="outlined" size="small" className={classes.textField1}>
                        {timeArray && timeArray.map((zone: any) => {
                          return IsDisable < zone.no && (
                            <MenuItem value={zone.time1} style={{ padding: '5px' }}>{zone.time}</MenuItem>
                          )
                        })}
                      </Field>

                      {errors.endTime && touched.endTime ? (
                        <Typography color="error" variant="subtitle2">
                          {errors.endTime}
                        </Typography>
                      ) : null}
                    </Grid>
                  </Grid>
                </Box>

                <Box pb={2.5}>
                  <Box pb={0.75}>
                    <Typography color="textSecondary" variant="subtitle2">
                      Description
                    </Typography>
                  </Box>

                  <Field disabled={fieldDis} multiline rows={5} style={{ background: isError && input === 1 ? '#fff1f1' : 'unset' }}
                    onInput={(e: any) => handleChange(e.target.value, 1)}
                    as={TextField}
                    inputProps={{ maxLength: 501 }}
                    className={classes.textField}
                    name="description"
                    size="small"
                    type="text"
                    variant="outlined"
                  />

                  <Box display='flex' justifyContent='space-between'>
                    <Typography color="error" variant="subtitle2">
                      {errors.description && touched.description && errors.description}
                    </Typography>
                    <Typography style={{ color: values.description?.length > 500 ? '#911717' : '#0000008a' }} variant="subtitle2">
                      {values.description?.length === 501 ? 500 : values.description?.length}/500
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {![ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role) ?
                <DialogActions style={{ display: "flex", justifyContent: "space-between", background: '#f9fafb', borderRadius: '0px 0px 10px 10px', padding: '20px' }}>
                  <Box>
                    <LoadingButton
                      className={classes.secondaryButton}
                      variant="contained"
                      aria-label="Submit"
                      onClick={() => handleClose()}
                    >
                      Cancel
                    </LoadingButton>
                  </Box>
                  <Box>
                    {edit ?
                      <React.Fragment>
                        <LoadingButton color="primary"
                          disabled={isSubmitting}
                          className={classes.primaryButton}
                          type="submit"
                          variant="contained"
                          aria-label="sign in"
                        >
                          Save
                        </LoadingButton></React.Fragment> :
                      <LoadingButton color="primary"
                        disabled={isSubmitting}
                        className={classes.primaryButton}
                        type="submit"
                        variant="contained"
                        aria-label="sign in"
                      >
                        Save
                      </LoadingButton>}
                  </Box>
                </DialogActions> :
                <DialogActions style={{ display: "flex", justifyContent: "flex-end", background: '#f9fafb', borderRadius: '0px 0px 10px 10px', padding: '20px' }}>
                  <Box>
                    <LoadingButton color="primary"
                      className={classes.primaryButton}
                      variant="contained"
                      aria-label="Submit"
                      onClick={() => handleClose()}
                    >
                      Close
                    </LoadingButton>
                  </Box>
                </DialogActions>
              }
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  topBox: {
    borderRadius: '0.625rem 0.625rem 0 0'
  },

  bottomBox: {
    borderRadius: '0 0 0.625rem 0.625rem'
  },
  primaryButton: {
    boxShadow:
      '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)',
    '& .hover': {
      background: '#0060B2'
    }
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: '#0060B2'
  },
  deleteButton: {
    marginRight: '10px',
    background: '#ce3a19',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#ce3a19'
    }
  },
  inputField: {
    maxWidth: '28rem',
    width: '80vw',
    paddingBottom: '0.5rem'
  },
  textField: {
    width: '100%'
  },
  textAlign: {
    textAlign: 'center'
  },
  box: {
    padding: '30px 30px 0'
  },
  textField2: {
    width: '100%',
    height: '55px',
    padding: '0px'
  },
  textField1: {
    width: '100%',
    height: '35px',
    padding: '0px'
  },
}));