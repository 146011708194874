import React, { useState } from 'react';
import { withStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Typography, Avatar, Tooltip } from '@material-ui/core';
import { StaticResource, UuidType } from '../../../shared/domain';
import { ROLES } from '../../../shared/globals';
import { StaticResourceType } from '../../../redux/data/data-actions';
import { GetStaticResourcesProps, getSharedUsersThunk } from '../../../redux/thunk/thunks';
import { HeadCell, LastSorted, Pagination, RowCell, Table } from '../../../components/Table';
import { ResourceTagsDialog } from './ResourceTagsDialog';
import { ShareToUser } from '../AssignPatient/ShareToUser';
import { useDispatch } from 'react-redux';

interface ResourcesTableProps {
  catalogueId?: UuidType;
  resourcesData: any | null;
  selectedResources?: UuidType[];
  staticResourceType: StaticResourceType;
  loadResources: (getStaticResourcesProps: GetStaticResourcesProps) => void;
  setSelectedResources?: (selectedResources: UuidType[]) => void;
  handelResourceView: (info: any) => void;
}

export const ResourcesTable = ({
  catalogueId = '',
  resourcesData,
  staticResourceType,
  selectedResources,
  loadResources,
  setSelectedResources,
  handelResourceView
}: ResourcesTableProps) => {
  const dispatch = useDispatch();
  const [selectedResource, setSelectedResource] = useState<StaticResource>();
  const [tagsModalOpen, setTagsModalOpen] = useState<boolean>(false);
  const [openSharing, setOpenSharing] = useState(false);
  const [data, setData] = useState<any>(null);
  const [lastSorted, setLastSorted] = useState<LastSorted>({
    column: 'created_at',
    order: 'desc'
  });
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);

  const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      padding: '0px 10px',
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

  const headCells: HeadCell[] = [
    {
      id: 'name',
      label: 'RESOURCE NAME'
    },
    {
      align: 'center',
      id: 'catalogue_name',
      label: 'DOMAIN',
      isSortDisabled: true
    },
    {
      align: 'center',
      id: 'tag_count',
      label: 'TAGS',
      isSortDisabled: true
    },
    {
      align: 'right',
      id: 'created_at',
      label: 'LAST UPDATED'
    }
  ];
  const headCells1: HeadCell[] = [
    {
      id: 'name',
      label: 'RESOURCE NAME'
    },
    {
      align: 'center',
      id: 'catalogue_name',
      label: 'DOMAIN',
      isSortDisabled: true
    },
    {
      align: 'center',
      id: 'tag_count',
      label: 'TAGS',
      isSortDisabled: true
    },
    {
      id: 'type',
      label: 'TYPE'
    },
    {
      align: 'right',
      id: 'created_at',
      label: 'LAST UPDATED'
    }
  ];
  const headCells2: HeadCell[] = [
    {
      id: 'title',
      label: 'RESOURCE NAME'
    },
    {
      align: 'left',
      id: 'assigned_by_name',
      label: 'ASSIGNED BY',
    },
    {
      align: 'left',
      id: 'assigned_on',
      label: 'ASSIGNED ON',
    },
    {
      id: 'action',
      label: 'SHARING',
      isSortDisabled: true
    }
  ];
  const headCells3: HeadCell[] = [
    {
      id: 'title',
      label: 'RESOURCE NAME'
    },
    {
      align: 'left',
      id: 'assigned_by_name',
      label: 'SHARED BY',
    },
    {
      align: 'left',
      id: 'assigned_on',
      label: 'SHARED ON',
    }
  ];

  const handleCloseTagsModal = () => {
    setSelectedResource(undefined);
    setTagsModalOpen(false);
  };

  const handelShareUser = async (data: any) => {
    await dispatch(getSharedUsersThunk({ giveOnlyActiveUsers: true }));
    setData(data);
    setOpenSharing(true);
  }

  const rowCells = resourcesData?.data && resourcesData?.data.map((staticResource: any, index: any) => {
    const nameLink = (
      <Typography color="primary" variant="h5" style={{ cursor: 'pointer' }} onClick={() => handelResourceView(staticResource)}>
        {staticResource.title}
      </Typography>
    );
    const sharedUser = (
      <Typography color="primary" variant="h5" style={{ cursor: 'pointer' }} onClick={() => handelShareUser(staticResource)}>
        {staticResource?.sharedUsers?.length + ' Users'}
      </Typography>
    );
    const tagsComponent =
      <HtmlTooltip
        title={<React.Fragment>
          {staticResource.tags.map((tag: any, i: number) => {
            return (
              <Typography style={{ padding: '5px 0px', borderBottom: (i + 1) === staticResource.tags.length ? 'unset' : '1px solid #00000026' }}>{tag.tag_name}</Typography>
            )
          })}
        </React.Fragment>}
      >
        <div>
          <Typography color="primary" variant="h5" >
            {staticResource.tags && staticResource.tags.length}
          </Typography>
          <Typography color="primary" variant="h5">
            Tags
          </Typography>
        </div>
      </HtmlTooltip>;
    const domainComponent = <HtmlTooltip
      title={<React.Fragment>
        {staticResource.domains.map((domain: any, i: number) => {
          return (
            <Typography style={{ padding: '5px 0px', borderBottom: (i + 1) === staticResource.domains.length ? 'unset' : '1px solid #00000026' }}>{domain.domain_name}</Typography>
          )
        })}
      </React.Fragment>}
    ><div>
        <Typography color="primary" variant="h5" >
          {staticResource.domains && staticResource.domains.length}
        </Typography>
        <Typography color="primary" variant="h5">
          Domains
        </Typography>
      </div>
    </HtmlTooltip>;
    const type = staticResource.organization_id ? <Avatar style={{ width: 32, height: 32 }}>C</Avatar> : <Avatar style={{ width: 32, height: 32, background: '#0060b2' }}>B</Avatar>;
    var options: any = { weekday: 'long' };
    const rowCell: RowCell = [ROLES.SITE_ADMIN, ROLES.BUILDER, ROLES.CLINICIAN].includes(loggedInUser.role) ? {
      name: { component: nameLink, value: staticResource.title },
      catalogue_name: {
        align: 'center',
        component: domainComponent,
        value: staticResource.tag_count?.toString()
      },
      tag_count: {
        align: 'center',
        component: tagsComponent,
        value: staticResource.tag_count?.toString()
      },
      type: { component: type, value: '-' },
      created_at: {
        align: 'right',
        value: staticResource.updated_at && new Intl.DateTimeFormat('en-US', options).format(new Date(staticResource.updated_at)) + ', ' + (("0" + (new Date(staticResource.updated_at).getMonth() + 1)).slice(-2)) + '/' + ("0" + new Date(staticResource.updated_at).getDate()).slice(-2) + '/' + new Date(staticResource.updated_at).getFullYear()
      }
    } : [ROLES.PATIENT].includes(loggedInUser.role) ? {
      title: { component: nameLink, value: staticResource.title },
      assigned_by_name: {
        align: 'left',
        value: staticResource.assigned_by_name
      },
      assigned_on: {
        align: 'left',
        value: staticResource?.assigned_on ? (new Intl.DateTimeFormat('en-US', options).format(new Date(staticResource.assigned_on)) + ', ' + (("0" + (new Date(staticResource.assigned_on).getMonth() + 1)).slice(-2)) + '/' + ("0" + new Date(staticResource.assigned_on).getDate()).slice(-2) + '/' + new Date(staticResource.assigned_on).getFullYear()) : ''
      },
      action: {
        align: 'left',
        component: sharedUser,
        value: staticResource?.sharedUsers?.length + ' Users'
      }
    } : [ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role) ? {
      title: { component: nameLink, value: staticResource.title },
      assigned_by_name: {
        align: 'left',
        value: staticResource.assigned_by_name
      },
      assigned_on: {
        align: 'left',
        value: staticResource.assigned_on && new Intl.DateTimeFormat('en-US', options).format(new Date(staticResource.assigned_on)) + ', ' + (("0" + (new Date(staticResource.assigned_on).getMonth() + 1)).slice(-2)) + '/' + ("0" + new Date(staticResource.assigned_on).getDate()).slice(-2) + '/' + new Date(staticResource.assigned_on).getFullYear()
      }
    } : {
      name: { component: nameLink, value: staticResource.title },
      catalogue_name: {
        align: 'center',
        component: domainComponent,
        value: staticResource.tag_count?.toString()
      },
      tag_count: {
        align: 'center',
        component: tagsComponent,
        value: staticResource.tag_count?.toString()
      },
      created_at: {
        align: 'right',
        value: staticResource.updated_at && new Intl.DateTimeFormat('en-US', options).format(new Date(staticResource.updated_at)) + ', ' + (("0" + (new Date(staticResource.updated_at).getMonth() + 1)).slice(-2)) + '/' + ("0" + new Date(staticResource.updated_at).getDate()).slice(-2) + '/' + new Date(staticResource.updated_at).getFullYear()
      }
    };

    return Object.assign(rowCell, { content: staticResource });
  });

  const handleNextButton = async () => {
    if (
      resourcesData &&
      resourcesData?.page >= 0 &&
      resourcesData?.page < resourcesData?.totalCount / resourcesData?.pageSize - 1
    ) {
      await loadResources({
        catalogueId,
        staticResourceType,
        search: resourcesData?.search,
        page: resourcesData?.page + 1,
        pageSize: resourcesData?.pageSize,
        sort: resourcesData?.sort,
        order: resourcesData?.order,
        favourites: resourcesData?.favourites,
        resource_type: resourcesData?.resource_type
      });
    }
  };
  const handlePrevButton = async () => {
    if (resourcesData && resourcesData?.page > 0) {
      await loadResources({
        catalogueId,
        staticResourceType,
        search: resourcesData?.search,
        page: resourcesData?.page - 1,
        pageSize: resourcesData?.pageSize,
        sort: resourcesData?.sort,
        order: resourcesData?.order,
        favourites: resourcesData?.favourites,
        resource_type: resourcesData?.resource_type
      });
    }
  };

  const handlePageSize = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    const pageSize = e.target.value as number;

    if (resourcesData && pageSize > 0) {
      loadResources({
        catalogueId,
        staticResourceType,
        page: 0,
        pageSize: pageSize,
        search: resourcesData?.search,
        sort: resourcesData?.sort,
        order: resourcesData?.order,
        favourites: resourcesData?.favourites,
        resource_type: resourcesData?.resource_type
      });
    }
  };

  const pagination: any | undefined = resourcesData
    ? {
      contentName: 'Resources',
      page: resourcesData?.page,
      pageSize: resourcesData?.pageSize,
      total: resourcesData?.totalCount,
      handleNextButton: handleNextButton,
      handlePageSize: handlePageSize,
      handlePrevButton: handlePrevButton
    }
    : undefined;

  const handleSort = (sort: string) => {
    const nextDirection = lastSorted.order === 'desc' ? 'asc' : 'desc';

    loadResources({
      catalogueId,
      staticResourceType,
      order: sort === lastSorted.column ? nextDirection : 'desc',
      page: 0,
      pageSize: resourcesData?.pageSize,
      search: resourcesData?.search,
      sort,
      favourites: resourcesData?.favourites,
      resource_type: resourcesData?.resource_type
    });

    setLastSorted(
      sort === lastSorted.column
        ? { column: sort, order: nextDirection }
        : { column: sort, order: 'desc' }
    );
  };

  const handelClose = () => {
    loadResources({
      catalogueId,
      staticResourceType,
      page: resourcesData?.page,
      pageSize: resourcesData?.pageSize,
      search: resourcesData?.search,
      sort: resourcesData?.sort,
      order: resourcesData?.order,
      favourites: resourcesData?.favourites,
      resource_type: resourcesData?.resource_type
    });
    setOpenSharing(false);
    setData(null);
  }

  return (
    <>
      {tagsModalOpen && selectedResource && (
        <ResourceTagsDialog
          open={tagsModalOpen}
          handleClose={handleCloseTagsModal}
          resource={selectedResource}
        />
      )}
      {[ROLES.SITE_ADMIN, ROLES.BUILDER, ROLES.CLINICIAN, ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(loggedInUser.role) ?
        <Table
          data={resourcesData?.data}
          headCells={[ROLES.SITE_ADMIN, ROLES.BUILDER, ROLES.CLINICIAN].includes(loggedInUser.role) ? headCells1 : headCells}
          loading={!resourcesData?.data}
          pagination={pagination}
          rowCells={rowCells}
          selection={
            selectedResources && setSelectedResources
              ? {
                selectedContents: selectedResources,
                setSelectedContents: (selected: UuidType[]) => setSelectedResources(selected)
              }
              : undefined
          }
          sort={{
            lastSorted: lastSorted,
            handleSort: handleSort
          }}
        /> :
        <Table
          data={resourcesData?.data}
          headCells={[ROLES.PATIENT].includes(loggedInUser.role) ? headCells2 : [ROLES.PROXY, ROLES.RATER].includes(loggedInUser.role) ? headCells3 : headCells}
          loading={!resourcesData?.data}
          pagination={pagination}
          rowCells={rowCells}
          sort={{
            lastSorted: lastSorted,
            handleSort: handleSort
          }}
        />}
      {openSharing && <ShareToUser handelClose={handelClose} data={data} section={'resource'} />}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  nameLink: {
    textDecoration: 'none'
  },
  infoIcon: {
    color: theme.palette.text.secondary,
    fontSize: '1rem',
    marginRight: '.2rem'
  }
}));
