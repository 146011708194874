import { CalculationUpdate, UuidType } from '../../shared/domain';
import { FlowproThunkDispatch, RootState } from '../redux-store';
import { deleteHttp, getHttp, postHttp, putHttp } from '../api';
import {
  setErrorAction,
  setLoadingAction,
  snackbarAlertAction,
  updatingCalculationAction,
  updatingCalculationFinishedAction
} from '../display/display-actions';
import { setCalculationMetricsAction, setEditingCalculationsAction } from '../data/data-actions';
import { push } from 'connected-react-router';

export const getCalculationsByFieldIdThunk = (formFieldId: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    dispatch(setLoadingAction('getCalculationsLoading', true, 'getCalculationsError'));
    const calculations = await getHttp(`/calculation/field/${formFieldId}?form_version_id=${form_version_id}`);
    if (calculations) {
      dispatch(setEditingCalculationsAction(calculations.calculations));
      dispatch(setLoadingAction('getCalculationsLoading', false, 'getCalculationsError'));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(e.message, 'error'));
      dispatch(setErrorAction('getCalculationsError', e.message));
    }
  }
};

export const getCalculationMetricsThunk = () => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    dispatch(setLoadingAction('getCalculationMetricsLoading', true, 'getCalculationMetricsError'));
    const calculationMetrics = await getHttp('/calculation/metrics?form_version_id=${form_version_id}');
    if (calculationMetrics) {
      dispatch(setCalculationMetricsAction(calculationMetrics.metrics));
      dispatch(
        setLoadingAction('getCalculationMetricsLoading', false, 'getCalculationMetricsError')
      );
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('getCalculationMetricsError', e.message));
    }
  }
};

export const createCalculationThunk = (formFieldId: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    dispatch(setLoadingAction('createCalculationLoading', true, 'createCalculationError'));
    const calculation = await postHttp(`/calculation/field/${formFieldId}`, { form_version_id: form_version_id });
    if (calculation) {
      dispatch(setLoadingAction('createCalculationLoading', false, 'createCalculationError'));
      dispatch(getCalculationsByFieldIdThunk(formFieldId));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setLoadingAction('createCalculationLoading', false, 'createCalculationError'));
      dispatch(snackbarAlertAction(e.message, 'error'));
      dispatch(setErrorAction('createCalculationError', e.message));
    }
  }
};

export const updateCalculationThunk = (
  calculationId: UuidType,
  formFieldId: UuidType,
  calculationUpdate: any
) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  dispatch(updatingCalculationAction(calculationId));
  try {
    const form_version_id = sessionStorage.getItem('version');
    calculationUpdate.form_version_id = form_version_id;
    dispatch(setLoadingAction('updateCalculationLoading', true, 'updateCalculationError'));
    const calculation = await putHttp(`/calculation/${calculationId}`, calculationUpdate);
    if (calculation) {
      dispatch(updatingCalculationFinishedAction());
      dispatch(setLoadingAction('updateCalculationLoading', false, 'updateCalculationError'));
      dispatch(getCalculationsByFieldIdThunk(formFieldId));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(e.message, 'error'));
      dispatch(setErrorAction('updateCalculationError', e.message));
      dispatch(updatingCalculationFinishedAction());
    }
  }
};

export const deleteCalculationThunk = (calculationId: UuidType, formFieldId: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    dispatch(setLoadingAction('deleteCalculationLoading', true, 'deleteCalculationError'));
    const calculation = await deleteHttp(`/calculation/${calculationId}?form_version_id=${form_version_id}`);
    if (calculation) {
      dispatch(setLoadingAction('deleteCalculationLoading', false, 'deleteCalculationError'));
      dispatch(getCalculationsByFieldIdThunk(formFieldId));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(e.message, 'error'));
      dispatch(setErrorAction('deleteCalculationError', e.message));
    }
  }
};
