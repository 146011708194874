import { FormControl, MenuItem, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import React from 'react';

interface ScaleSettingsProps {
  titleClass: string;
  handleScaleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  scale?: number;
}

export const ScaleSettings: React.FC<ScaleSettingsProps> = ({
  titleClass,
  handleScaleChange,
  scale
}) => {
  const classes = useStyles();

  const renderOptions = (max: number) => {
    let options = [];
    {
      for (let i = 0; i < max; i++) {
        options.push(<MenuItem value={i}>{i}</MenuItem>);
      }
    }
    return options;
  };
  return (
    <>
      <Typography color={'textSecondary'} variant="subtitle2" className={titleClass}>
        Scale Options
      </Typography>
      <FormControl fullWidth variant="outlined" className={classes.formControl}>
        <Select
          IconComponent={UnfoldMoreIcon}
          classes={{ root: classes.selectRoot }}
          value={scale}
          fullWidth
          onChange={handleScaleChange}
        >
          {renderOptions(11)}
        </Select>
      </FormControl>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  selectRoot: {
    padding: '.6rem'
  },
  formControl: {}
}));
