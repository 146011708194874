import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import React from 'react';

interface WarningNoticeProps {
  warning: string;
}
export const WarningNotice: React.FC<WarningNoticeProps> = ({ warning }) => {
  const classes = useStyles();
  return (
    <Box className={classes.mainContainer}>
      <WarningOutlinedIcon className={classes.errorIcon} fontSize={'small'} />
      <Typography color={'textSecondary'} variant="subtitle2">
        {warning}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  errorIcon: {
    marginRight: '.8rem',
    color: theme.palette.warning.main
  },
  mainContainer: {
    display: 'flex',
    alignItems: 'center'
  }
}));
