import React, { useEffect, useState } from 'react';
import { Box, Theme, Typography, makeStyles } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Dialog } from '../../../components/Dialog';
import { LoadingButton } from '../../../components/LoadingButton';
import { FormsRadioList } from './FormsRadioList';
import { RootState } from '../../../redux/redux-store';
import { SearchBar } from '../../../components/SearchBar';
import { TabBar, TabComponent } from '../../../components/TabBar';
import { colors } from '../../../styling/colors';
import {
  duplicateFormThunk,
  getCollectionsThunk,
  getFormsThunk,
  updateCollectionsThunk,
  updateFormsThunk
} from '../../../redux/thunk/thunks';
import { Collection, Form, UuidType } from '../../../shared/domain';
import { LoadingOrError } from '../../../components/LoadingOrError';
import { PaginationBar } from '../../../components/PaginationBar';
import { getDuplicatedFormAction, getFormsAction } from '../../../redux/data/data-actions';

interface DuplicateCustomFormDialogProps {
  library?: boolean;
  open: boolean;
  handleClose: () => void;
}

export const DuplicateCustomFormDialog = ({
  library,
  open,
  handleClose
}: DuplicateCustomFormDialogProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const collectionsData = useSelector(
    (state: RootState) => state.DATA_REDUCER.collectionSearchResults.duplicateCustom
  );
  const formsData = useSelector(
    (state: RootState) => state.DATA_REDUCER.formSearchResults.duplicateCustom
  );
  const duplicateFormLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.duplicateFormLoading
  );
  const duplicatedForm = useSelector((state: RootState) => state.DATA_REDUCER.duplicatedForm);

  const [selected, setSelected] = useState<UuidType>('');

  const handleDuplicateForm = async () => {
    if (selected && !duplicateFormLoading) {
      await dispatch(duplicateFormThunk(selected));

      dispatch(updateCollectionsThunk);
      dispatch(updateFormsThunk);

      handleClose();
    }
  };

  const handleRadioSelect = (id: UuidType) => {
    setSelected(id);
  };

  const handleTabChange = async (id: string) => {
    setSelected('');

    await dispatch(getFormsAction('duplicateCustom', null));

    dispatch(
      getFormsThunk({
        collectionId: id,
        formsType: 'duplicateCustom',
        library,
        pageSize: 4
      })
    );
  };

  const handleNextPage = async () => {
    if (formsData) {
      await dispatch(
        getFormsThunk({
          collectionId: formsData.collectionId,
          formsType: 'duplicateCustom',
          library,
          page: formsData.page + 1,
          pageSize: formsData.pageSize,
          search: formsData.search
        })
      );
    }
  };

  const handlePrevPage = async () => {
    if (formsData) {
      await dispatch(
        getFormsThunk({
          collectionId: formsData.collectionId,
          formsType: 'duplicateCustom',
          library,
          page: formsData.page - 1,
          pageSize: formsData.pageSize,
          search: formsData.search
        })
      );
    }
  };

  const handleSearch = async (value: string) => {
    if (formsData) {
      getFormsThunk({
        collectionId: formsData.collectionId,
        formsType: 'duplicateCustom',
        library,
        page: formsData.page,
        pageSize: formsData.pageSize,
        search: value
      });
    }
  };

  const FormList = (
    <>
      {formsData ? (
        <Box bgcolor={colors.background3}>
          <FormsRadioList
            listData={formsData.forms.map((form: Form) => ({
              name: form.title,
              ...form
            }))}
            selected={selected}
            handleRadioSelect={handleRadioSelect}
          />

          <Box pb={2} px={3}>
            <PaginationBar
              contentName="Form"
              page={formsData.page}
              pageSize={formsData.pageSize}
              total={formsData.total}
              handleNextButton={handleNextPage}
              handlePrevButton={handlePrevPage}
            />
          </Box>
        </Box>
      ) : (
        <LoadingOrError errorMsg={null} loading={true} loadingMsg="" noElevation />
      )}
    </>
  );

  const tabComponents: TabComponent[] = [
    {
      component: FormList,
      id: '',
      label: 'All'
    }
  ].concat(
    collectionsData
      ? collectionsData.collections.map((collection: Collection) => ({
          component: FormList,
          id: collection.id,
          label: collection.name
        }))
      : []
  );

  useEffect(() => {
    dispatch(getCollectionsThunk({ collectionsType: 'duplicateCustom', library, pageSize: 0 }));
    dispatch(getFormsThunk({ formsType: 'duplicateCustom', library, pageSize: 4 }));
  }, []);

  useEffect(() => {
    if (duplicatedForm) {
      dispatch(getDuplicatedFormAction(null));
    }
  }, [duplicatedForm]);

  return (
    <>
      {duplicatedForm ? (
        <Redirect to={`/form-builder/${duplicatedForm.id}/fields`} />
      ) : (
        <Dialog open={open} handleClose={handleClose}>
          <Box
            className={classes.topBox}
            display="flex"
            bgcolor={colors.background2}
            flexDirection="column"
            pt={2.5}
          >
            <Box alignItems="center" display="flex" justifyContent="space-between" px={3}>
              <Typography variant="h3">
                {library ? 'Pick a Form from a Library' : 'Duplicate an existing Form'}
              </Typography>

              <Box pl={2}>
                <SearchBar handleSearch={handleSearch} />
              </Box>
            </Box>

            <TabBar
              style={{
                spacing: {
                  px: 3
                }
              }}
              tabComponents={tabComponents}
              onTabChange={handleTabChange}
            />
          </Box>

          <Box
            bgcolor={colors.background2}
            className={classes.bottomBox}
            display="flex"
            justifyContent="space-between"
            px={3}
            py={2}
          >
            <LoadingButton
              className={classes.secondaryButton}
              color="secondary"
              onClick={handleClose}
              variant="contained"
              aria-label="cancel"
            >
              Cancel
            </LoadingButton>

            <LoadingButton
              className={classes.primaryButton}
              color="primary"
              disabled={selected.length === 0}
              loading={duplicateFormLoading}
              onClick={handleDuplicateForm}
              variant="contained"
              aria-label="create new form"
            >
              Create New Form
            </LoadingButton>
          </Box>
        </Dialog>
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  topBox: {
    borderRadius: '0.625rem 0.625rem 0 0'
  },
  bottomBox: {
    borderRadius: '0 0 0.625rem 0.625rem'
  },
  primaryButton: {
    boxShadow:
      '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)'
  }
}));
