import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { LoadingOrError } from '../../components/LoadingOrError';
import { RootState } from '../../redux/redux-store';
import {
    createFormPreviewThunk, patientFormPreviewThunk,
    fetchFormToDisplayByIdThunk,
    getFormInstanceByIdThunk,
    getFormStyleThunk
} from '../../redux/thunk/thunks';
import { theme } from '../../styling/ThemeConfig';
import { DisplayCard } from './DisplayCard';
import { ROLES } from '../../shared/globals';

export const ViewForm = () => {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const form_instance_id: any = sessionStorage.getItem('instance');
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    //This color is currently hardcoded, but in the future this color may be user defined and pulled from the db.
    const formThemeColor = theme.palette.primary.main;
    const split = history?.location?.pathname.split('/');
    const path = split && split.length > 0 && split[1];
    const form_id = split && split.length > 1 && split[2];
    const instance_id = split && split.length > 2 && split[3];
    const version_id: any = split && split.length > 3 && split[4];

    useEffect(() => {
        version_id && sessionStorage.setItem('version', version_id);
        if (loggedInUser && (path === 'view-form') && [ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser?.role)) {

        } else if (loggedInUser && path === 'view-form' && ![ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser?.role)) {
            history.push('/422');
        } else if (!loggedInUser && path === 'view-form') {
            history.push('/login');
            sessionStorage.setItem('form_path', `/${path}/${form_id}/${instance_id}/${version_id}`)
        }
    }, []);

    const createFormPreviewLoading = useSelector(
        (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.createFormPreviewLoading
    );
    const getFormInstanceLoading = useSelector(
        (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.getFormInstanceLoading
    );
    const getFormInstanceError = useSelector(
        (state: RootState) => state.DISPLAY_STATE_REDUCER.displayErrors.getFormInstanceError
    );
    const createFormPreviewError = useSelector(
        (state: RootState) => state.DISPLAY_STATE_REDUCER.displayErrors.createFormPreviewError
    );
    const getFormToDisplayLoading = useSelector(
        (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.getFormToDisplayLoading
    );
    const getFormToDisplayError = useSelector(
        (state: RootState) => state.DISPLAY_STATE_REDUCER.displayErrors.getFormToDisplayError
    );
    const displayFormInstance = useSelector(
        (state: RootState) => state.DATA_REDUCER.displayFormInstance
    );
    const formStyle: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.formStyle
    );
    //On mount fetch and set form and fields data into displayForm
    useEffect(() => {
        //generate preview and get first question
        if (loggedInUser) {
            if (true) {
                if ([ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser?.role)) {
                    dispatch(patientFormPreviewThunk(instance_id, form_id));
                } else {
                    dispatch(createFormPreviewThunk(form_id));
                }
                //fetch the form data
                dispatch(fetchFormToDisplayByIdThunk(form_id));
            } else if (path === 'view-form' && form_id) {
                dispatch(getFormInstanceByIdThunk(form_id));
            }
            if (!formStyle) {
                dispatch(getFormStyleThunk(loggedInUser ? loggedInUser?.id : ''));
            }
        }
    }, []);

    useEffect(() => {
        if (loggedInUser && displayFormInstance) {
            !form_instance_id && dispatch(fetchFormToDisplayByIdThunk(displayFormInstance?.form_id));
        }
    }, [displayFormInstance]);

    const isLoading = getFormInstanceLoading || createFormPreviewLoading || getFormToDisplayLoading;

    const isError = createFormPreviewError || getFormInstanceError || getFormToDisplayError;
    return (
        <Box className={classes.main}>
            {loggedInUser ? <Box className={classes.cardContainer}>
                {isLoading || isError ? (
                    <Box p={4}>
                        <LoadingOrError
                            loading={isLoading}
                            loadingMsg={'Loading Questions...'}
                            errorMsg={isError}
                        />
                    </Box>
                ) : (
                    <DisplayCard themeColor={formThemeColor} formStyle={formStyle} />
                )}
            </Box> : <Box display='flex' justifyContent='center' alignItems='center'><CircularProgress /></Box>}
        </Box>
    );
};

const useStyles = makeStyles((theme) => ({
    main: {
        backgroundColor: theme.background.main,
        minHeight: '100vh'
    },
    topButtons: {
        display: 'flex',
        flexDirection: 'row-reverse',
        padding: theme.spacing(3)
    },
    cardContainer: {
        padding: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        flexDirection: 'column',
        flexGrow: 1
    },
    exitArea: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    exitText: {
        marginRight: theme.spacing(1)
    }
}));
