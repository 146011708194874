import React, { useEffect, useState } from 'react';
import { Box, makeStyles, Paper, Theme, Typography, ListItem, Select, Card, TextField, MenuItem, FormControl, RadioGroup, FormControlLabel, Radio, DialogActions } from '@material-ui/core';
import MUIDialog from '@material-ui/core/Dialog'
import { Search, Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientsThunk, getSiteAdminPatientsThunk, getSharingUserThunk, checkUserResourceAssignmentThunk, assignResourceThunk, assignReferralThunk, checkUserReferralAssignmentThunk } from '../../../redux/thunk/thunks';
import { RootState } from '../../../redux/redux-store';
import { LoadingButton } from '../../../components/LoadingButton';
import { ROLES } from '../../../shared/globals';
import { Patients } from './Patients';

export const AssignForms = ({ openDialog, handelClose }: any) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [disDate, setDisabledDate] = useState<any>();
    const [disDate1, setDisabledDate1] = useState<any>();
    const [patientDialogueOpen, setPatientDialogueOpen] = useState(false);
    const [recurrence, setRecurrence] = useState('Date');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [noRecurrence, setNoRecurrence] = useState('');
    const [remEmails, setRemEmails] = useState('');
    const [recurrenceSchedule, setRecurrenceSchedule] = useState('');
    const [openRecurrence, setOpenRecurrence] = useState(false);
    const [recData, setRecData] = useState(null);
    const [dateDiff, setDateDiff] = useState<any>(null);
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const location = window.location.pathname;

    const info: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.assignmentList
    );

    useEffect(() => {
        let newDate: any = Date.now();
        setDisabledDate(new Date(newDate).getFullYear() + '-' + (("0" + (new Date(newDate).getMonth() + 1)).slice(-2)) + '-' + ("0" + (new Date(newDate).getDate())).slice(-2));
        setStartDate(new Date(newDate).getFullYear() + '-' + (("0" + (new Date(newDate).getMonth() + 1)).slice(-2)) + '-' + ("0" + (new Date(newDate).getDate())).slice(-2));
        let newDate1: any = new Date(newDate);
        newDate1.setDate(newDate1.getDate() + 1);
        setDisabledDate1(new Date(newDate1).getFullYear() + '-' + (("0" + (new Date(newDate1).getMonth() + 1)).slice(-2)) + '-' + ("0" + (new Date(newDate1).getDate())).slice(-2));
    }, []);

    const assignFormToPatientClose = () => {
        setPatientDialogueOpen(false);
        handelClose();
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRecurrence((event.target as HTMLInputElement).value);
    };

    const openRecurrenceDialog = (open: boolean) => {
        setOpenRecurrence(open);
        if (!open) {
            let newDate: any = Date.now();
            setStartDate(new Date(newDate).getFullYear() + '-' + (("0" + (new Date(newDate).getMonth() + 1)).slice(-2)) + '-' + ("0" + (new Date(newDate).getDate())).slice(-2)); setEndDate(''); setNoRecurrence(''); setRemEmails(''); setRecurrenceSchedule(''); setRecData(null);
        }
    }

    const removeRecurrence = () => {
        let newDate: any = Date.now();
        setStartDate(new Date(newDate).getFullYear() + '-' + (("0" + (new Date(newDate).getMonth() + 1)).slice(-2)) + '-' + ("0" + (new Date(newDate).getDate())).slice(-2)); setEndDate(''); setNoRecurrence(''); setRemEmails(''); setRecurrenceSchedule('');
    }

    const handelNext = () => {
        setPatientDialogueOpen(true);
        const date1 = new Date(startDate);
        const date2 = endDate !== '' ? new Date(endDate) : null;
        const recurrence: any = {
            "start_date": date1.toISOString(),
            "end_date": date2 ? date2.toISOString() : date2,
            "no_of_recurrences": noRecurrence !== '' ? parseInt(noRecurrence) : null,
            "recurrence_schedule": recurrenceSchedule !== '' ? recurrenceSchedule : null,
            "days_for_reminder": remEmails !== '' ? parseInt(remEmails) : null
        }
        setRecData(recurrence)
    }

    const handelSave = () => {
        setOpenRecurrence(false);
        const date1 = new Date(startDate);
        const date2 = endDate !== '' ? new Date(endDate) : null;
        const recurrence: any = {
            "start_date": date1.toISOString(),
            "end_date": date2 ? date2.toISOString() : date2,
            "no_of_recurrences": noRecurrence !== '' ? parseInt(noRecurrence) : null,
            "recurrence_schedule": recurrenceSchedule !== '' ? recurrenceSchedule : null,
            "days_for_reminder": remEmails !== '' ? parseInt(remEmails) : null
        }
        setRecData(recurrence)
    }

    const handelNoRecurrence = (value: any) => {
        const re = /^[0-9\b]+$/;
        if (parseInt(value) !== 0 && re.test(value) && value.length < 3) {
            setNoRecurrence(value)
        } else {
            value.length > 3 || value.length == 0 && setNoRecurrence('')
        }
    }

    const handelRemEmails = (value: any) => {
        const re = /^[0-9\b]+$/;
        if (parseInt(value) !== 0 && re.test(value) && value.length < 6) {
            setRemEmails(value)
        } else {
            value.length > 6 || value.length == 0 && setRemEmails('')
        }
    }

    const handelStartDate = (value: any) => {
        setStartDate(value);
        let newDate: any = new Date(value);
        newDate.setDate(newDate.getDate() + 1)
        setDisabledDate1(new Date(newDate).getFullYear() + '-' + (("0" + (new Date(newDate).getMonth() + 1)).slice(-2)) + '-' + ("0" + (new Date(newDate).getDate())).slice(-2));
        setEndDate('');
        if (endDate !== '') {
            const date1: any = new Date(value);
            const date2: any = new Date(endDate);
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            setDateDiff(diffDays);
        }
    }

    const handelEndDate = (value: any) => {
        setEndDate(value);
        if (startDate !== '') {
            const date1: any = new Date(startDate);
            const date2: any = new Date(value);
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            setDateDiff(diffDays);
        }
    }

    return (
        <>
            {openDialog && <MUIDialog open={openDialog} style={{ overflow: 'hidden' }}>
                <Paper className={classes.paper}>
                    <Box display="flex" flexDirection="column">
                        <Card>
                            <Box p={2} style={{ background: '#f9fafb', borderBottom: '0.0625rem solid #d1d6dc' }} >
                                <Box display='flex' justifyContent='space-between' alignItems="center">
                                    <Typography style={{ fontSize: '18px', fontWeight: 600 }}>Assign Forms & Collections</Typography>
                                    <Typography style={{ fontSize: '15px', fontWeight: 600, color: '#0060B2', cursor: 'pointer' }} onClick={(e) => openRecurrenceDialog(true)}>Set Recurrence</Typography>
                                </Box>
                            </Box>
                            <Box pb={4} pt={1} px={4} style={{ maxHeight: '500px', overflow: 'auto' }}>
                                {info && info.map((item: any, i: number) => {
                                    return (
                                        <ListItem style={{ borderBottom: info.length === (i + 1) ? 'unset' : '2px solid #dddddd', padding: '4px 0px' }}>
                                            <Box style={{ width: '-webkit-fill-available' }}>
                                                <Box display='flex' style={{ width: '-webkit-fill-available' }}>
                                                    <Box style={{ width: '60%' }}><Typography style={{ fontSize: '16px' }}>{item.title}</Typography>
                                                        <Typography style={{ fontSize: '12px', color: 'gray', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.description}</Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </ListItem>
                                    )
                                })}
                            </Box>
                        </Card>
                    </Box>
                </Paper>
                <DialogActions style={{ padding: '15px 20px', background: '#f9fafb', display: 'unset', justifyContent: 'unset' }}>
                    <Box display='flex' justifyContent='space-between'>
                        <LoadingButton
                            className={classes.secondaryButton}
                            variant="contained"
                            onClick={() => handelClose()}
                        >
                            Cancel
                        </LoadingButton>
                        <LoadingButton disabled={startDate === '' ? true : false}
                            className={classes.primaryButton}
                            variant="contained"
                            onClick={() => handelNext()}
                        >
                            Next
                        </LoadingButton>
                    </Box>
                </DialogActions>
            </MUIDialog>
            }
            {openRecurrence && <MUIDialog open={openRecurrence} fullWidth maxWidth={'sm'}>
                <Box p={3} style={{ background: '#f9fafb', borderBottom: '0.0625rem solid #d1d6dc' }} >
                    <Box display='flex' justifyContent='space-between' alignItems="center">
                        <Typography style={{ fontSize: '18px', fontWeight: 600 }}>Settings</Typography>
                        <Typography style={{ fontSize: '15px', fontWeight: 600, color: '#d20b0b', cursor: 'pointer' }} onClick={(e) => removeRecurrence()}>Remove</Typography>
                    </Box>
                </Box>
                <Paper className={classes.paper1}>
                    <Box pb={2.5}>
                        <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                                Start Date
                            </Typography>
                        </Box>
                        <TextField fullWidth variant='outlined' size='small' autoFocus InputProps={{ inputProps: { min: disDate } }}
                            value={startDate} onChange={(e: any) => handelStartDate(e.target.value)} type="date" />
                    </Box>
                    <Box pb={2.5} pl={2} pr={2} display='flex' alignItems='center' justifyContent='space-between'>
                        <FormControlLabel onChange={(e: any) => { setRecurrence('Date'); setNoRecurrence(''); setRecurrenceSchedule(''); }} checked={recurrence === 'Date' ? true : false} control={<Radio color="primary" checked={recurrence === 'Date' ? true : false} />} label="Set End Date" />
                        <Typography color="textSecondary" variant="subtitle2">- OR -</Typography>
                        <FormControlLabel onChange={(e: any) => { setRecurrence('Recurrence'); setEndDate(''); setRecurrenceSchedule(''); }} checked={recurrence === 'Recurrence' ? true : false} control={<Radio color="primary" checked={recurrence === 'Recurrence' ? true : false} />} label="Set Recurrences" />
                    </Box>
                    <Box pb={2.5}>
                        <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                                End Date
                            </Typography>
                        </Box>
                        <TextField fullWidth variant='outlined' size='small' disabled={recurrence === 'Date' && startDate !== '' ? false : true}
                            value={endDate} onChange={(e: any) => handelEndDate(e.target.value)} type="date" InputProps={{ inputProps: { min: disDate1 } }} />
                    </Box>
                    <Box pb={2.5}>
                        <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                                Number of Recurrences
                            </Typography>
                        </Box>
                        <TextField fullWidth variant='outlined' size='small' type='tel' disabled={recurrence === 'Recurrence' ? false : true}
                            value={noRecurrence} onChange={(e: any) => handelNoRecurrence(e.target.value)} style={{ background: recurrence !== 'Recurrence' ? '#8080801c' : 'transparent' }} />
                    </Box>
                    <Box pb={2.5}>
                        <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                                Recurrence Schedule
                            </Typography>
                        </Box>
                        <FormControl size="small" fullWidth>
                            {endDate === '' ? <Select variant={'outlined'} value={recurrenceSchedule} disabled={noRecurrence === '' ? true : false}
                                onChange={(e: any) => setRecurrenceSchedule(e.target.value)} style={{ background: noRecurrence === '' ? '#8080801c' : 'transparent' }}
                            >
                                <MenuItem value={'DAILY'}>DAILY</MenuItem>
                                <MenuItem value={'WEEKLY'}>WEEKLY</MenuItem>
                                <MenuItem value={'MONTHLY'}>MONTHLY</MenuItem>
                                <MenuItem value={'YEARLY'}>YEARLY</MenuItem>
                            </Select> :
                                <Select variant={'outlined'} value={recurrenceSchedule}
                                    onChange={(e: any) => setRecurrenceSchedule(e.target.value)}
                                >
                                    {<MenuItem value={'DAILY'}>DAILY</MenuItem>}
                                    {dateDiff > 7 && <MenuItem value={'WEEKLY'}>WEEKLY</MenuItem>}
                                    {dateDiff > 29 && <MenuItem value={'MONTHLY'}>MONTHLY</MenuItem>}
                                    {dateDiff > 365 && <MenuItem value={'YEARLY'}>YEARLY</MenuItem>}
                                </Select>}
                        </FormControl>
                    </Box>
                    <Box pb={2.5}>
                        <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                                Reminder Emails - Number of days after due date
                            </Typography>
                        </Box>
                        <TextField fullWidth variant='outlined' size='small' type='tel'
                            value={remEmails} onChange={(e: any) => handelRemEmails(e.target.value)} />
                    </Box>
                </Paper>
                <DialogActions style={{ padding: '15px 20px', background: '#f9fafb', display: 'unset', justifyContent: 'unset' }}>
                    <Box display='flex' justifyContent='space-between'>
                        <LoadingButton
                            className={classes.secondaryButton}
                            variant="contained"
                            onClick={() => openRecurrenceDialog(false)}
                        >
                            Cancel
                        </LoadingButton>
                        <LoadingButton disabled={startDate === '' ? true : false}
                            className={classes.primaryButton}
                            variant="contained"
                            onClick={() => handelSave()}
                        >
                            Save
                        </LoadingButton>
                    </Box>
                </DialogActions>
            </MUIDialog >
            }
            {patientDialogueOpen && <Patients handelClose={assignFormToPatientClose} recurrence={recData} />}
        </>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    topBox: {
        borderRadius: '0.625rem 0.625rem 0 0'
    },
    bottomBox: {
        borderRadius: '0 0 0.625rem 0.625rem'
    },
    primaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#fff',
        minWidth: '120px',
        background: '#0060B2',
        '&:hover': {
            backgroundColor: '#0060B2',
        }
    },
    secondaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#0060B2',
        minWidth: '120px',
        background: '#fff'
    },
    paper: {
        width: '580px',
        overflow: 'hidden'
    },
    paper1: {
        padding: '30px',
        overflow: 'auto'
    },
    searchTextField: {
        backgroundColor: theme.palette.secondary.main,
        fontSize: theme.typography.h5.fontSize,
        height: '2rem',
        width: '11rem',
        zIndex: 1
    },
    searchIcon: {
        fontSize: 17
    },
    paginationButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: 'black',
        marginLeft: '20px',
        minWidth: '120px',
        background: '#fff'
    },
}));
