import React, { useEffect, useState } from 'react';
import {
    Box, Theme, makeStyles, Grid, Divider, AppBar, Toolbar, Button, Dialog, Backdrop, CircularProgress,
    Paper, TextField, Avatar, Checkbox, Typography, Select, FormControl, MenuItem, RadioGroup, FormControlLabel, Radio
} from '@material-ui/core';
import { ArrowBack, SaveAltOutlined } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { unassignFormToUserThunk, getFormVersionsThunk, downloadFormPDFThunk, downloadFormDataThunk } from '../../../redux/thunk/thunks';
import { RootState } from '../../../redux/redux-store';
import { LoadingButton } from '../../../components/LoadingButton';
import { colors } from '../../../styling/colors';
import { useHistory, useLocation } from 'react-router-dom';
import { ConfirmationDialog } from '../../../components/ConfirmationDialog';
import { ROLES } from '../../../shared/globals';

interface Props {
    handleClose: () => void;
    instance_id: any;
}

const array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const ViewResponse = ({ handleClose, instance_id }: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const location: any = useLocation();
    let history = useHistory();
    const role: any = sessionStorage.getItem('role');
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const [loading, setLoading] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [isBackdrop, setIsBackdrop] = useState(false);
    const split = location?.pathname.split('/');
    const patient_role_id: any = split && split.length > 1 && split[2];
    const shared_role_id: any = split && split.length > 3 && split[4];
    const form_instance_id: any = split && split.length > 2 && split[3];

    const user: any = useSelector((state: RootState) => state.DATA_REDUCER.patientInfo);
    const formInstanceDetails: any = useSelector((state: RootState) => state.DATA_REDUCER.formInstanceDetails);
    const formResponseDetails: any = useSelector((state: RootState) => state.DATA_REDUCER.formResponseDetails);

    const handleUnassign = async () => {
        const data = {
            "user_role_id": role === 'PATIENT' ? patient_role_id : shared_role_id ? shared_role_id : loggedInUser?.id,
            "form_instance_id": formInstanceDetails?.id ? formInstanceDetails?.id : instance_id ? instance_id : form_instance_id
        }
        await dispatch(unassignFormToUserThunk(data));
        setConfirm(false);
        handleClose();
    }

    const handleCloseConfirm = () => {
        setConfirm(false)
    }

    const handleViewForm = async () => {
        sessionStorage.setItem('route', location.pathname);
        sessionStorage.removeItem('version');
        sessionStorage.removeItem('new');
        await dispatch(getFormVersionsThunk(formInstanceDetails?.form_id));
        // sessionStorage.setItem('type_id', formInstanceDetails?.form_type); //form type
        history.push({ pathname: `/form-builder/${formInstanceDetails?.form_id}/fields`, state: false });
    }

    const handleDownloadData = async () => {
        setIsBackdrop(true);
        await dispatch(downloadFormDataThunk(formInstanceDetails?.id ? formInstanceDetails?.id : instance_id ? instance_id : form_instance_id));
        setIsBackdrop(false);
    }

    const handleDownloadPDF = async () => {
        setIsBackdrop(true);
        await dispatch(downloadFormPDFThunk(formInstanceDetails?.id ? formInstanceDetails?.id : instance_id ? instance_id : form_instance_id));
        setIsBackdrop(false);
    }

    const singleChoice = (formField: any) => {
        const structuredOptions: [] = formField?.form_field_options?.sort(function (a: any, b: any) { return a.option_value - b.option_value });

        return structuredOptions;
    }

    const multipleChoice = (formField: any) => {
        const structuredOptions: [] = formField?.form_field_options?.sort(function (a: any, b: any) { return a.option_value - b.option_value });

        return structuredOptions;
    }

    return (
        <>
            {isBackdrop && <Backdrop className={classes.backdrop} open={isBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>}
            <Dialog open={true} fullScreen className={classes.dialog}>
                <AppBar square elevation={0} classes={{ root: classes.headerRoot }}>
                    <Toolbar classes={{ root: classes.headerToolbar }}>
                        <Button
                            classes={{ text: classes.closeBtnText }}
                            startIcon={<ArrowBack style={{ color: 'gray', fontSize: '25px' }} />}
                            onClick={handleClose}
                        >
                            Return
                        </Button>
                        <Box alignSelf="stretch" display="flex" pr={3} py={2.75}>
                            <Divider flexItem orientation="vertical" />
                        </Box>

                        <Box
                            display="flex"
                            alignItems='center'
                            justifyContent="flex-start"
                            minHeight="5rem"
                            width="100%"
                        >
                            <Box>
                                <Typography className={classes.subtitle}>
                                    {`${loggedInUser.role} > ${loggedInUser?.first_name + ' ' + loggedInUser?.last_name} >`}
                                </Typography>
                                <Typography color="textSecondary" variant="h2" style={{ fontSize: '20px' }}>
                                    <span style={{ color: 'gray' }}>Form:</span> {formInstanceDetails?.title}
                                </Typography>
                            </Box>
                        </Box>
                        {![ROLES.PATIENT, ROLES.PROXY, ROLES.RATER].includes(loggedInUser.role) && <LoadingButton loading={loading} disabled={loading}
                            className={classes.primaryButton} onClick={() => setConfirm(true)}
                            color="primary" variant="contained"
                        >
                            Unassign
                        </LoadingButton>}
                    </Toolbar>
                </AppBar>
                <Box m={4} style={{ background: '#F1F5F9', marginTop: '120px' }}>
                    <Box p={4} display="flex" flexDirection="column" style={{ background: '#fff', borderRadius: '10px' }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography color="textSecondary" variant="h1" style={{ color: '#0060B2', marginBottom: '10px', fontSize: '20px', cursor: 'pointer' }} onClick={handleViewForm}>{formInstanceDetails?.title}</Typography>
                                <Typography style={{ color: 'gray', fontSize: '16px' }}>{formInstanceDetails?.description}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Box m={4} mt={0} style={{ background: '#F1F5F9' }}>
                    <Box p={4} display="flex" flexDirection="column" style={{ background: '#fff', borderRadius: '10px' }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography color="textSecondary" variant="h1" style={{ marginBottom: '10px', fontSize: '20px' }}>Interpretation</Typography>
                            </Grid>
                            {formInstanceDetails?.metrics.map((item: any) => {
                                return (
                                    <Box mt={2}>
                                        <Typography color="textSecondary" variant="h3" style={{ color: '#0060B2', marginBottom: '8px' }}>{item?.code}</Typography>
                                        <Typography style={{ color: 'gray', fontSize: '16px' }}>{item?.description}</Typography>
                                    </Box>
                                )
                            })}
                        </Grid>
                    </Box>
                </Box>
                <Box m={4} mt={0} display="flex" flexDirection="column" style={{ background: '#f9fafb', borderRadius: '10px' }}>
                    <Box p={4} pb={0}>
                        <Box display="flex" justifyContent="space-between">
                            <Box>
                                <Typography className={classes.subtitle}>
                                    {'ANSWERS'}
                                </Typography>
                                <Typography color="textSecondary" variant="h1" style={{ fontSize: '20px', marginTop: '5px' }}>
                                    Responses for {formInstanceDetails?.title}
                                </Typography>
                            </Box>
                            <Box style={{ textAlign: 'right' }}>
                                <Typography color="textSecondary" variant="h1" style={{ fontSize: '20px', marginTop: '5px' }}>
                                    Status: <span style={{ fontSize: '16px', fontWeight: 100 }}>{formInstanceDetails?.status === 'COMPLETE' ? 'COMPLETED' : formInstanceDetails?.status?.replace(/_/g, " ")}</span>
                                </Typography>
                                <Typography color="textSecondary" variant="h1" style={{ fontSize: '20px', marginTop: '5px' }}>
                                    Score: {formInstanceDetails?.status === 'COMPLETE' ? (formInstanceDetails?.metrics?.length > 0 ? formInstanceDetails?.metrics.map((item: any) => <span style={{ fontSize: '16px', fontWeight: 100, textTransform: 'uppercase', marginRight: '10px' }}>{item?.code}: {item?.metricScore};</span>) : <span style={{ fontSize: '16px', fontWeight: 100 }}>N/A</span>) : <span style={{ fontSize: '16px', fontWeight: 100 }}>N/A</span>}
                                </Typography>
                            </Box>
                        </Box>
                        <Box display="flex" mt={2} mb={3}>
                            <Typography color="textSecondary" variant="h3" style={{ color: '#0060B2', marginRight: '50px', cursor: 'pointer' }} onClick={handleDownloadData}>Download Data</Typography>
                            <Typography color="textSecondary" variant="h3" style={{ color: '#0060B2', cursor: 'pointer' }} onClick={handleDownloadPDF}>Download PDF</Typography>
                        </Box>
                    </Box>
                    <Box p={4} style={{ background: '#fff' }}>
                        {formResponseDetails && formResponseDetails.map((item: any, i: number) => {
                            return (
                                <Box mt={2} pl={4} pr={4}>
                                    <Typography color="textSecondary" variant="h3" style={{ marginBottom: '8px', color: 'gray' }}>{i + 1}.</Typography>
                                    <Typography color="textSecondary" variant="h3" style={{ marginBottom: '8px' }}>{item?.field_prompt}</Typography>
                                    {item?.field_type === "SHORT_TEXT" && <TextField style={{ marginBottom: '10px', color: '#a7a7a7' }} size="small" fullWidth disabled variant='outlined' value={item?.response} placeholder='Insert answer here' />}
                                    {item?.field_type === "LONG_TEXT" && <TextField style={{ marginBottom: '10px', color: '#a7a7a7' }} size="small" fullWidth disabled multiline rows={2} value={item?.response} variant='outlined' placeholder='Insert answer here' />}
                                    {item?.field_type === "DROPDOWN" && <FormControl fullWidth size="small">
                                        <Select style={{ marginBottom: '10px' }} fullWidth disabled variant='outlined' displayEmpty inputProps={{ 'aria-label': 'Without label' }} value={item?.response_option || ''}>
                                            <MenuItem disabled value={''}>Select answer here</MenuItem>
                                            {item?.form_field_options.map((value: any) => <MenuItem disabled value={value.id}>{value.option_text}</MenuItem>)}
                                        </Select></FormControl>}
                                    {item?.field_type === "SINGLE_CHOICE" &&
                                        <RadioGroup aria-label="quiz">
                                            {singleChoice(item)?.map((element: any) =>
                                                <Paper style={{ padding: '0px 10px', boxShadow: 'unset', border: '1px solid #a7a7a7', marginBottom: '10px' }}>
                                                    <FormControlLabel disabled value="I agree" control={<Radio checked={element?.id === item?.response_option ? true : false} />} label={<span style={{ color: '#a7a7a7' }}>{element?.option_text}</span>} />
                                                </Paper>
                                            )}
                                        </RadioGroup>}
                                    {item?.field_type === "MULTIPLE_CHOICE" &&
                                        <RadioGroup aria-label="quiz">
                                            {multipleChoice(item)?.map((element: any) => {
                                                const check = item?.response_multiselect?.filter((value: any) => value === element?.id);
                                                return (<Paper style={{ padding: '0px 10px', boxShadow: 'unset', border: '1px solid #a7a7a7', marginBottom: '10px' }}>
                                                    <FormControlLabel value={element?.option_value} control={<Checkbox disabled checked={check && check.length > 0 ? true : false} />} label={<span style={{ color: '#a7a7a7' }}>{element?.option_text}</span>} />
                                                </Paper>)
                                            }
                                            )}
                                        </RadioGroup>}
                                    {item?.field_type === "SCALE" &&
                                        <RadioGroup row>
                                            {array.map((value: any, i: any) => {
                                                return <Avatar style={{ margin: '10px', background: parseInt(item?.response) === value ? '#dfdfdf' : 'transparent', border: '1px solid #a7a7a7' }}><Typography style={{ color: '#a7a7a7' }}>{value}</Typography></Avatar>
                                            })}
                                        </RadioGroup>
                                    }
                                    {item?.field_type === "RESOURCE" &&
                                        <Box mt={2}>
                                            {item?.resources.map((item: any) => (
                                                <Paper style={{ boxShadow: 'unset', border: '1px solid #cbcbcb', padding: '5px 15px', width: '50%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                                    <Typography style={{ color: 'gray' }}>{item?.file_name}</Typography>
                                                    <a href={item?.resource_link} target='_blank'><SaveAltOutlined style={{ color: "gray", padding: '0px 20px' }} /></a>
                                                </Paper>
                                            ))
                                            }
                                        </Box>}
                                    {![ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role) && <Typography style={{ color: 'gray', fontSize: '14px', textTransform: 'uppercase', margin: '10px 0' }}>Instructions to clinician: {item?.info_for_clinician}</Typography>}
                                    <Typography style={{ color: 'gray', fontSize: '14px', textTransform: 'uppercase', margin: '10px 0 50px' }}>Instructions to patient: {item?.info_for_patient}</Typography>
                                </Box>
                            )
                        })}
                    </Box>
                </Box>
                {confirm && <ConfirmationDialog
                    loading={loading}
                    message='Are you sure you want to unassign form?'
                    open={confirm}
                    title="Calculation"
                    handleClose={handleCloseConfirm}
                    handleConfirmClick={() => handleUnassign()}
                />}
            </Dialog>
        </>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    dialog: {
        '& .MuiDialog-paper': {
            background: '#F1F5F9'
        }
    },
    headerRoot: {
        backgroundColor: 'white',
        borderBottom: `2px solid ${theme.palette.divider}`,
        padding: 0
    },
    headerToolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: 0
    },
    closeBtnText: {
        color: 'gray',
        marginRight: 18,
        fontSize: '16px',
        '&:hover': {
            backgroundColor: 'unset'
        }
    },
    subtitle: {
        color: colors.text6,
        fontWeight: 500
    },
    topBox: {
        borderRadius: '0.625rem 0.625rem 0 0'
    },
    bottomBox: {
        borderRadius: '0 0 0.625rem 0.625rem'
    },
    textField: {
        width: '100%'
    },
    primaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#fff',
        minWidth: '120px',
        marginBottom: '20px'
    },
    secondaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#0060B2',
        marginBottom: '20px',
        minWidth: '120px'
    },
    deleteButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: 'red',
        minWidth: '120px'
    },
    createCollectionTextField: {
        maxWidth: '40rem',
        width: '80vw'
    },
    chipAlign: {
        display: 'flex',
        alignItems: 'center'
    },
    otherActions: {
        marginLeft: '.6rem',
        alignItems: 'center'
    },
    collectionChip: {
        marginRight: theme.spacing(1),
        backgroundColor: theme.background.main
    },
    searchTextField: {
        backgroundColor: theme.palette.secondary.main,
        fontSize: theme.typography.h5.fontSize,
        height: '2rem',
        width: '14rem'
    },
    searchIcon: {
        fontSize: 17
    },
    contentContainer: {
        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap'
        }
    }
}));
