import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from '../../../components/Dialog';
import { LoadingButton } from '../../../components/LoadingButton';
import { HeadCell, RowCell, Table } from '../../../components/Table';
import { RootState } from '../../../redux/redux-store';
import { getTagsByResourceThunk } from '../../../redux/thunk/thunks';
import { StaticResource, Tag } from '../../../shared/domain';
import { colors } from '../../../styling/colors';

interface ResourceTagsDialogProps {
  open: boolean;
  handleClose: () => void;
  resource: StaticResource;
}

export const ResourceTagsDialog = ({ open, handleClose, resource }: ResourceTagsDialogProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const getStaticResourceTags = useSelector(
    (state: RootState) => state.DATA_REDUCER.getStaticResourceTags
  );
  const getStaticResourceTagsLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.getStaticResourceTagsLoading
  );

  useEffect(() => {
    dispatch(getTagsByResourceThunk(resource.id));
  }, []);

  const headCells: HeadCell[] = [
    {
      id: 'title',
      label: 'TITLE'
    },
    {
      id: 'primary_tag',
      label: 'PRIMARY TAG'
    },
    {
      align: 'left',
      id: 'created_at',
      label: 'CREATED ON'
    }
  ];

  const rowCells = getStaticResourceTags?.map((tag: Tag) => {
    const rowCell: RowCell = {
      title: { align: 'left', value: tag.title },
      primary_tag: {
        align: 'left',
        value: tag.primary_tag ? 'Yes' : 'No'
      },
      created_at: {
        align: 'left',
        value: new Date(tag.created_at).toDateString()
      }
    };
    return Object.assign(rowCell, { content: tag });
  });

  return (
    <Dialog handleClose={handleClose} open={open}>
      <Box display="flex" flexDirection="column">
        <Box
          alignItems="center"
          bgcolor={colors.background2}
          className={classes.topBox}
          display="flex"
          justifyContent="space-between"
          px={3}
          py={2.5}
        >
          <Typography variant="h3">Tags For {resource.name}</Typography>
        </Box>
        <Box px={3} py={2.5}>
          {!getStaticResourceTagsLoading && getStaticResourceTags?.length === 0 ? (
            <Box className={classes.noDataContainer}>
              <Typography>No Tags</Typography>
            </Box>
          ) : (
            <Table
              data={getStaticResourceTags || undefined}
              headCells={headCells}
              loading={getStaticResourceTagsLoading}
              rowCells={rowCells}
            />
          )}
        </Box>
        <Box
          bgcolor={colors.background2}
          className={classes.bottomBox}
          display="flex"
          justifyContent="space-between"
          px={3}
          py={2}
        >
          <LoadingButton
            className={classes.secondaryButton}
            color="secondary"
            onClick={handleClose}
            variant="contained"
            aria-label={'cancel'}
          >
            Close
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  topBox: {
    borderRadius: '0.625rem 0.625rem 0 0'
  },

  bottomBox: {
    borderRadius: '0 0 0.625rem 0.625rem'
  },
  primaryButton: {
    boxShadow:
      '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)'
  },
  inputField: {
    maxWidth: '28rem',
    width: '80vw',
    paddingBottom: '0.5rem'
  },
  noDataContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
