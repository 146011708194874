import { setLoggedInUser, getVerifyUserInfo, getLoginSSOPageAction, getFormsAction } from './../data/data-actions';
import { userLoggedOutAction } from './../root/root-actions';
import { FlowproThunkDispatch, RootState } from '../redux-store';
import { ROLES } from '../../shared/globals';
import { User, UserInput, UuidType } from '../../shared/domain';
import { deleteHttp, getHttp, postHttp, updateToken } from '../api';
import { push } from 'connected-react-router';
import {
  setErrorAction,
  setLoadingAction,
  setSuccessAction,
  snackbarAlertAction
} from '../display/display-actions';
import { getNonPatientsThunk } from './thunks';

export const loginThunk = (email: string, password: string) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    //get auth token from fusion
    const patient_role_path: any = sessionStorage.getItem('form_path');
    const tokenResp = await postHttp('/user/auth/login', {
      loginId: email,
      password: password
    });
    if (tokenResp.status === "DISABLED" || tokenResp.status === "DELETED") {
      dispatch(push(`/account-status`));
      dispatch(setLoggedInUser(tokenResp));
    } else {

      updateToken(tokenResp.token);
      tokenResp.user_role.first_name = tokenResp.user.first_name;
      tokenResp.user_role.last_name = tokenResp.user.last_name;
      tokenResp.user_role.is_sso_user = tokenResp.user.is_sso_user;
      tokenResp.user_role.referral_id = tokenResp.referral_id;
      tokenResp.user_role.organization_name = tokenResp.organization_name;
      localStorage.setItem('formFlash', JSON.stringify(tokenResp.user_role))
      dispatch(setLoggedInUser(tokenResp.user_role));

      if ([ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(tokenResp.user_role.role)) {
        if (patient_role_path && patient_role_path !== '/login') {
          dispatch(push(`${patient_role_path}`));
        } else {
          dispatch(push(`/forms`));
        }
      } else if (tokenResp.user_role.role === ROLES.CLINICIAN) {
        dispatch(push(`/forms-collections`));
      } else if (tokenResp.user_role.role === ROLES.REFERRAL) {
        dispatch(push(`/referral-profile`));
      } else {
        dispatch(push(`/forms`));
      }
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error logging in: Invalid Credentials!`, 'error'));
      dispatch(setErrorAction('loginError', 'Invalid Credentials!'));
    }
  }
};
export const logoutThunk = () => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const loggedUser: any = localStorage.getItem('formFlash');
    const userRoleId = JSON.parse(loggedUser);
    await postHttp('/user/auth/logout', {
      user_id: userRoleId.user_id,
      user_role_id: userRoleId.id
    });

    await localStorage.removeItem('token');
    await localStorage.removeItem('formFlash');
    await localStorage.removeItem('remember');
    await sessionStorage.removeItem('userRoleID');
    //navigate to login screen
    await dispatch(push(`/login`));
    dispatch(getFormsAction('custom', null));
    sessionStorage.removeItem('form_path')
    sessionStorage.removeItem('key');
    sessionStorage.removeItem('tab')
  } catch (e) {
    dispatch(setErrorAction('signupError', e.message));
    //clear redux  
    dispatch(userLoggedOutAction());
    //navigate to login screen
    dispatch(push(`/login`));
    //clear local sorage jwt
    localStorage.removeItem('token');
    localStorage.removeItem('formFlash');
    localStorage.removeItem('remember');
    sessionStorage.removeItem('form_path')
  }
};

export const signupThunk = (password: string, user: User | UserInput) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    await postHttp('/user/auth/register', {
      password: password,
      user: user
    });
    dispatch(push('/login'));
    dispatch(setSuccessAction('signupSuccess', true));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('signupError', e.message));
      dispatch(snackbarAlertAction(`Error signing up: ${e.message}`, 'error'));
    }
  }
};

export const inviteUserThunk = (
  firstName: string,
  lastName: string,
  email: string,
  role: ROLES
) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    dispatch(setLoadingAction('inviteUserLoading', true, 'inviteUserError'));
    await postHttp('/user/auth/invite', {
      firstName,
      lastName,
      email,
      role
    });

    // Update account users table
    const userSearchResults = getState().DATA_REDUCER.userSearchResults.accountUsers;
    if (userSearchResults) {
      dispatch(
        getNonPatientsThunk({
          usersType: 'accountUsers',
          page: userSearchResults.page,
          pageSize: userSearchResults.pageSize,
          search: userSearchResults.search,
          sort: userSearchResults.sort,
          order: userSearchResults.order
        })
      );
    }

    dispatch(setSuccessAction('inviteUserSuccess', true));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('inviteUserError', `Error inviting user: ${e.message}`));
      dispatch(snackbarAlertAction(`Error inviting user: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('inviteUserLoading', false, 'inviteUserError'));
  }
};

export const deleteUserThunk = (userId: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    dispatch(setLoadingAction('deleteUserLoading', true, 'deleteUserError'));
    const deleteUserRes = await deleteHttp(`/user/auth/delete/${userId}`);

    // Update account users table
    if (deleteUserRes) {
      const userSearchResults = getState().DATA_REDUCER.userSearchResults.accountUsers;
      if (userSearchResults) {
        dispatch(
          getNonPatientsThunk({
            usersType: 'accountUsers',
            page: userSearchResults.page,
            pageSize: userSearchResults.pageSize,
            search: userSearchResults.search,
            sort: userSearchResults.sort,
            order: userSearchResults.order
          })
        );
      }
    }

    dispatch(setSuccessAction('deleteUserSuccess', true));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error deleting user: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('deleteUserLoading', false, 'deleteUserError'));
  }
};

export const forgotPasswordThunk = (email: string) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    await postHttp('/user/auth/forgot-password', {
      loginId: email,
      redirectUrl: `${window.location.origin}/forgot-password`
    });
    dispatch(
      snackbarAlertAction(
        `If the email exists in our system a reset password link will be sent to the email`,
        'info'
      )
    );
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error sending forgot password email: ${e.message}`, 'error'));
    }
  }
};

export const changePasswordByChangePasswordIdThunk = (
  changePasswordId: string,
  password: string
) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    await postHttp('/user/auth/change-password-by-change-passord-id', {
      changePasswordId,
      password
    });
    dispatch(snackbarAlertAction(`Password changed`, 'success'));
    dispatch(push('/login'));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error changing password : ${e.message}`, 'error'));
      dispatch(setErrorAction('changePasswordError', e.message));
    }
  }
};

export const verifyUserThunk = () => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const words = window.location.pathname.split('/');
    const verifyUser = await getHttp(`/user/auth/verifyUser/${words[2]}`);
    dispatch(getVerifyUserInfo(verifyUser));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error logging in: ${e.message}`, 'error'));
      dispatch(setErrorAction('loginError', e.message));
    }
  }
};

export const setPasswordThunk = (password: string, userInfo: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    await postHttp('/user/auth/setPasswordAndSignup', {
      "email": userInfo.email,
      "userId": userInfo.userId,
      "password": password,
      "user_role_id": userInfo.user_role_id,
    });
    dispatch(snackbarAlertAction(`Password set successfully`, 'success'));
    dispatch(loginThunk(userInfo.email, password));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const forgotPasswordInviteThunk = (email: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    await postHttp('/user/auth/forgot-password-with-reset-link', {
      "email": email,
    });
    dispatch(setSuccessAction('changePasswordSuccess', true));
    dispatch(push('/send-link'));
    dispatch(snackbarAlertAction(`Reset password link sent to the enter email address`, 'success'));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const verifyForgotPasswordUserThunk = () => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const words = window.location.pathname.split('/');
    const verifyUser = await getHttp(`/user/auth/forgotPasswordVerifyUser/${words[2]}`);
    dispatch(getVerifyUserInfo(verifyUser));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error logging in: ${e.message}`, 'error'));
      dispatch(setErrorAction('loginError', e.message));
    }
  }
};

export const setUserPasswordThunk = (password: string, userInfo: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const tokenResp = await postHttp('/user/auth/resetPasswordAndLogin', {
      "email": userInfo.email,
      "userId": userInfo.userId,
      "password": password,
    });
    dispatch(snackbarAlertAction(`Password set successfully`, 'success'));
    if (tokenResp.status === "DISABLED" || tokenResp.status === "DELETED") {
      dispatch(push(`/account-status`));
      dispatch(setLoggedInUser(tokenResp));
    } else {

      updateToken(tokenResp.token);
      tokenResp.user_role.first_name = tokenResp.user.first_name;
      tokenResp.user_role.last_name = tokenResp.user.last_name;
      tokenResp.user_role.is_sso_user = tokenResp.user.is_sso_user;
      tokenResp.user_role.referral_id = null;
      tokenResp.user_role.organization_name = null;
      localStorage.setItem('formFlash', JSON.stringify(tokenResp.user_role))
      dispatch(setLoggedInUser(tokenResp.user_role));

      if ([ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(tokenResp.user_role.role)) {
        dispatch(push(`/forms`));
      } else if (tokenResp.user_role.role === ROLES.CLINICIAN) {
        dispatch(push(`/forms-collections`));
      } else if (tokenResp.user_role.role === ROLES.REFERRAL) {
        dispatch(push(`/referral-profile`));
      } else {
        dispatch(push(`/forms`));
      }
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: Please try again later!`, 'error'));
    }
  }
};

export const showSSOLoginPageThunk = (orgId: any) => async (dispatch: FlowproThunkDispatch) => {
  try {
    const result = await getHttp(`/sso/showSSOLoginPage/${orgId}`);
    dispatch(getLoginSSOPageAction(result));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('loginError', e.message));
    }
  }
};

export const ssoSignupThunk = (data: any) => async (
  dispatch: FlowproThunkDispatch
) => {
  try {
    const result = await postHttp('/sso/signup', {
      "token": data.token,
      "user_role_id": data.user_role_id
    });
    if (result) {
      dispatch(userAuthLoginSSOUserThunk(data.token));
    }
  } catch (e) {
  }
};

export const userAuthLoginSSOUserThunk = (token: any) => async (
  dispatch: FlowproThunkDispatch
) => {
  try {
    const tokenResp = await postHttp('/user/auth/loginSSOUser', {
      "token": token
    });
    if (tokenResp.status === "DISABLED" || tokenResp.status === "DELETED") {
      dispatch(push(`/account-status`));
      dispatch(setLoggedInUser(tokenResp));
    } else {

      updateToken(tokenResp.token);
      tokenResp.user_role.first_name = tokenResp.user.first_name;
      tokenResp.user_role.last_name = tokenResp.user.last_name;
      tokenResp.user_role.is_sso_user = tokenResp.user.is_sso_user;
      tokenResp.user_role.referral_id = tokenResp.referral_id;
      tokenResp.user_role.organization_name = tokenResp.organization_name;
      localStorage.setItem('formFlash', JSON.stringify(tokenResp.user_role))
      dispatch(setLoggedInUser(tokenResp.user_role));

      if ([ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(tokenResp.user_role.role)) {
        dispatch(push(`/forms`));
      } else if (tokenResp.user_role.role === ROLES.CLINICIAN) {
        dispatch(push(`/forms-collections`));
      } else if (tokenResp.user_role.role === ROLES.REFERRAL) {
        dispatch(push(`/referral-profile`));
      } else {
        dispatch(push(`/forms`));
      }
    }
  } catch (e) {
    dispatch(push(`/login`));
  }
};