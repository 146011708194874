import { Box, makeStyles, Paper, Theme } from '@material-ui/core';
import React from 'react';
import { CardHeader } from '../../../components/LegacyCardHeader';
import { OutstandingTasksTable } from './OutstandingTasksTable';
import { CompletedTasksTable } from './CompletedTasksTable';

export const TasksCard = () => {
  return (
    <Paper>
      <Box display="flex" flexDirection="column">
        <CardHeader
          tabComponents={[
            {
              component: <OutstandingTasksTable />,
              label: 'Outstanding Tasks',
              style: {
                background: true,
                spacing: { pb: 0, pt: 3, px: 4 }
              },
              title: 'Your Tasks'
            },
            {
              component: <CompletedTasksTable />,
              label: 'Completed Tasks',
              style: {
                background: true,
                spacing: { pb: 0, pt: 3, px: 4 }
              },
              title: 'Your Tasks'
            }
          ]}
        />
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) => ({}));
