import React, { useEffect, useState } from 'react';
import { Box, Paper } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { CardHeader } from '../../../../components/LegacyCardHeader';
import { deleteAppointmentsThunk } from '../../../../redux/thunk/thunks';
import { RootState } from '../../../../redux/redux-store';
import { AppointmentsTable } from './AppointmentsTable';
import { UuidType } from '../../../../shared/domain';
import { NewAppointment } from './NewAppointment';
import { getPatientAppointmentsThunk, getPatientInfoThunk } from '../../../../redux/thunk/thunks';
import { setSuccessAction } from '../../../../redux/display/display-actions';
import { getAppointmentsAction } from '../../../../redux/data/data-actions';
import { useRouteMatch } from 'react-router-dom';

export const Appointments = () => {
  const dispatch = useDispatch();
  const organization_id: any = sessionStorage.getItem('pat_org_id');
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);
  const role: any = sessionStorage.getItem('role');
  const { path } = useRouteMatch();
  const split = path.split('/');
  const patient_role_id: any = split && split.length > 1 && split[2];
  const shared_role_id: any = split && split.length > 3 && split[4];
  const [selectedAppointments, setSelectedAppointment] = useState<UuidType[]>([]);
  const [newAppointmentDialogueOpen, setNewAppointmentDialogueOpen] = useState(false);

  const appointments: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.appointmentSearchResults.appointments
  );
  const user: any = useSelector((state: RootState) => state.DATA_REDUCER.patientInfo);
  const success: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.appointmentSuccess);
  useEffect(() => {
    if (success) {
      dispatch(setSuccessAction('appointmentSuccess', false));
      dispatch(getPatientAppointmentsThunk({
        order: appointments.order,
        page: appointments.page,
        pageSize: appointments.pageSize,
        search: appointments.search,
        sort: appointments.sort,
        patient_role_id: role === 'PATIENT' ? patient_role_id : shared_role_id,
        organization_id: organization_id,
        givePatientAppointments: role === 'PATIENT' ? false : false
      }))
    }
  })

  // We need async/await over here as it is used for some state loading task
  const loadAppointments = async (GetAppointmentsProps: any) => {
    GetAppointmentsProps.patient_role_id = role === 'PATIENT' ? patient_role_id : shared_role_id;
    GetAppointmentsProps.givePatientAppointments = role === 'PATIENT' ? false : false;
    GetAppointmentsProps.organization_id = organization_id;
    await dispatch(getPatientAppointmentsThunk(GetAppointmentsProps));
  };

  const handleNewAppointmentButton = () => {
    setNewAppointmentDialogueOpen(true);
  };
  const handleNewAppointmentDialogueClose = () => {
    setNewAppointmentDialogueOpen(false);
  };

  useEffect(() => {
    !user && dispatch(getPatientInfoThunk(patient_role_id));
    dispatch(getPatientAppointmentsThunk({ givePatientAppointments: role === 'PATIENT' ? false : false, patient_role_id: role === 'PATIENT' ? patient_role_id : shared_role_id, organization_id: organization_id }));
    return () => {
      dispatch(getAppointmentsAction('appointments', null));
    };
  }, []);

  const AppointmentsTableContainer = (
    <Box pb={4} pt={1} px={4}>
      <AppointmentsTable
        appointmentsData={appointments}
        selectedAppointments={selectedAppointments}
        loadAppointments={loadAppointments}
        setSelectedAppointment={(selectedAppointments: UuidType[]) => setSelectedAppointment(selectedAppointments)}
      />
    </Box>
  );

  return (
    <>
      {newAppointmentDialogueOpen && (
        <NewAppointment open={newAppointmentDialogueOpen} handleClose={handleNewAppointmentDialogueClose}
          patient={null} appointmentInfo={null} edit={false} />
      )}

      <Paper>
        <Box display='flex' flexDirection='column'>
          <CardHeader
            tabComponents={[
              {
                component: AppointmentsTableContainer,
                label: 'Appointments..',
                primaryButton: {
                  text: 'Add',
                  handleClick: handleNewAppointmentButton
                },
                style: {
                  background: true,
                  spacing: { py: 3, px: 4 }
                },
                title: 'Appointments..',
                // handleSearch: handleSearch
              }
            ]}
          />
        </Box>
      </Paper>
    </>
  );
};
