import React, { useEffect, useState } from 'react';
import { Box, makeStyles, Paper, Theme, Typography, ListItem, Divider, Card, FormControlLabel, Checkbox, DialogActions, TextField, FormControl, Select, MenuItem } from '@material-ui/core';
import MUIDialog from '@material-ui/core/Dialog';
import { Search } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getSharedUsersThunk, updateFormSharingSettingThunk, updateCollectionSharingSettingThunk } from '../../../redux/thunk/thunks';
import { RootState } from '../../../redux/redux-store';
import { LoadingButton } from '../../../components/LoadingButton';
import { useRouteMatch } from 'react-router-dom';

interface UserProps {
    handleClose: () => void;
    data?: any | null;
}

export const ShareToUser = ({ handleClose, data }: UserProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { path } = useRouteMatch();
    const role: any = sessionStorage.getItem('role');
    const split = path.split('/');
    const organization_id: any = sessionStorage.getItem('pat_org_id');
    const patient_role_id: any = split && split.length > 1 && split[2];
    const shared_role_id: any = split && split.length > 3 && split[4];
    const [selectedUsers, setSelectedUsers] = useState<any[]>(data?.sharedUsers || []);
    const [selectedSharedUser, setSelectedSharedUser] = useState<any[]>([]);
    const [isAdd, setIsAdd] = useState(false);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [check, setCheck] = useState(false);
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const [patientInfo, setPatientInfo] = useState<any>(null);
    const [selectedPatientsAccess, setSelectedPatientsAccess] = useState<any>([]);
    const [selectedPatients, setSelectedPatients] = useState<any[]>([]);

    const sharedUsers: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.sharedUsers
    );

    useEffect(() => {
        setPatientInfo(data);
        !sharedUsers && dispatch(getSharedUsersThunk({ giveOnlyActiveUsers: true, user_role_id: role ? patient_role_id : null, organization_id: organization_id ? organization_id : null }));
        if (data?.sharedUsers && data?.sharedUsers?.length > 0) {
            for (let i = 0; i < data.sharedUsers.length; i++) {
                selectedSharedUser.push(data.sharedUsers[i].user_role_id);
                selectedPatientsAccess.push({ id: data.sharedUsers[i].user_role_id, access_type: data.sharedUsers[i].access_type })
            }
        }
        const checked = selectedUsers.filter((id: any) => id.user_role_id === loggedInUser.id);
        checked && checked.length > 0 && setCheck(true);
    }, [])

    const handlePrevButton = () => {
        dispatch(getSharedUsersThunk({
            page: sharedUsers.page - 1, giveOnlyActiveUsers: true, user_role_id: role ? patient_role_id : null, organization_id: organization_id ? organization_id : null
        }))
    }

    const handleNextButton = () => {
        dispatch(getSharedUsersThunk({
            page: sharedUsers.page + 1, giveOnlyActiveUsers: true, user_role_id: role ? patient_role_id : null, organization_id: organization_id ? organization_id : null
        }))
    }

    const handleSearch = (value: string) => {
        setSearch(value);
        if (value.length > 2) {
            dispatch(getSharedUsersThunk({ search: value, giveOnlyActiveUsers: true, user_role_id: role ? patient_role_id : null, organization_id: organization_id ? organization_id : null }));
        }
    };

    const handleChangePatient = (value: any) => {
        patientInfo.patientFormAccess = value;
        setIsAdd(!isAdd);
        const index = selectedPatientsAccess.map((e: any) => e.id).indexOf(patientInfo.patient_id);
        if (index !== -1) {
            selectedPatientsAccess[index]['access_type'] = value;
            setSelectedPatientsAccess(selectedPatientsAccess);
        }
    }
    const handleChangeUserAccess = (value: any, id: any) => {
        const check = selectedPatientsAccess.filter((item: any) => item.id === id);
        const check1 = selectedPatients.filter((item: any) => item === id);
        if (check && check.length === 0) {
            check1 && check1.length > 0 && selectedPatientsAccess.push({ id: id, access_type: value });
            check1 && check1.length > 0 && setIsAdd(!isAdd);
        } else {
            const index = selectedPatientsAccess.map((e: any) => e.id).indexOf(id);
            if (index !== -1) {
                selectedPatientsAccess[index]['access_type'] = value;
                setSelectedPatientsAccess(selectedPatientsAccess);
            }
            setIsAdd(!isAdd);
        }
    }

    const handleChangeChecked1 = (checked: boolean, info: any) => {
        if (!checked) {
            selectedPatientsAccess.push({ id: info.user_role_id, access_type: info.access_type });
            selectedUsers.push(info);
            patientInfo.checked = true;
            patientInfo.access_type = info.access_type;
            setPatientInfo(info);
            setIsAdd(!isAdd);
        } else {
            const id = info.user_role_id ? info.user_role_id : info.patient_id;
            const users = selectedUsers.filter((e: any) => e.user_role_id !== id);
            setSelectedUsers(users);
            const userAccess = selectedPatientsAccess.filter((e: any) => e.id !== id);
            setSelectedPatientsAccess(userAccess);
            patientInfo.checked = false;
            patientInfo.patientFormAccess = info.patientFormAccess;
            setPatientInfo(info);
            setIsAdd(!isAdd)
        }
    }
    const handleSharedChecked = (checked: boolean, info: any, access: any) => {
        if (!checked) {
            selectedPatientsAccess.push({ id: info.user_role_id, access_type: access && access.length > 0 ? access[0].access_type : 'VIEW' });
            selectedUsers.push(info);
            setIsAdd(!isAdd)
        } else {
            if (!selectedSharedUser.includes(info.user_role_id)) {
                const users = selectedUsers.filter((e: any) => e.user_role_id !== info.user_role_id);
                setSelectedUsers(users);
                const userAccess = selectedPatientsAccess.filter((e: any) => e.id !== info.user_role_id);
                setSelectedPatientsAccess(userAccess);
                selectedSharedUser.push(info.user_role_id);
                setCheck(true);
            } else {
                const users = selectedUsers.filter((e: any) => e.user_role_id !== info.user_role_id);
                setSelectedUsers(users);
                const userAccess = selectedPatientsAccess.filter((e: any) => e.id !== info.user_role_id);
                setSelectedPatientsAccess(userAccess);
            }
            setIsAdd(!isAdd)
        }
    }

    const handelCloseialog = async () => {
        handleClose();
        setSelectedSharedUser([]);
        setSelectedUsers([]);
        setSelectedPatientsAccess([]);
        setSelectedPatients([]);
        setIsAdd(!isAdd);
    }

    const addSharedUser = async () => {
        setLoading(true);
        if (data.item_type === 'COLLECTION') {
            const info = {
                "collection_id": role ? data.item_id : data.id,
                "recurrence_id": data?.recurrence_id,
                "patient_id": patient_role_id ? patient_role_id : data.patient_id,
                "user_role_ids": selectedPatientsAccess
            }
            await dispatch(updateCollectionSharingSettingThunk(info));
        } else {
            const info = {
                "form_instance_id": role ? data.item_id : data.id,
                "user_role_ids": selectedPatientsAccess
            }
            await dispatch(updateFormSharingSettingThunk(info));
        }
        handleClose();
        setSelectedSharedUser([]);
        setSelectedUsers([]);
        setLoading(false);
    }

    return (
        <>
            <MUIDialog open={true} style={{ overflow: 'hidden' }}>
                <Paper className={classes.paper}>
                    <Box display="flex" flexDirection="column">
                        <Card>
                            <Box p={2} style={{ background: '#f9fafb', borderBottom: '0.0625rem solid #d1d6dc' }} >
                                <Box display='flex' justifyContent='space-between' alignItems="center">
                                    <Typography style={{ fontSize: '18px', fontWeight: 600 }}>Share</Typography>
                                    <TextField className={classes.searchTextField} value={search}
                                        inputProps={{
                                            maxLength: 150,
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <Box ml={-0.5} mr={1} mt={0.5}>
                                                    <Search className={classes.searchIcon} color="disabled" />
                                                </Box>
                                            )
                                        }} size='small' variant='outlined' placeholder='Search...' onChange={(e) => handleSearch(e.target.value)} />
                                </Box>
                            </Box>
                            <Box pb={4} pt={1} px={4}>
                                <ListItem style={{ borderBottom: '2px solid #dddddd', padding: '4px 0px', background: '#f9fafb' }}>
                                    <FormControlLabel style={{ minWidth: '60%' }}
                                        control={
                                            <Checkbox size='medium' disabled={(loggedInUser?.id === patientInfo?.patient_id) ? true : false} checked={patientInfo?.checked} onChange={(e) => handleChangeChecked1(patientInfo?.checked, patientInfo)} name={patientInfo?.id} color="primary" />
                                        }
                                        label={<Box><Typography style={{ fontSize: '16px' }}>{patientInfo?.patient_name}</Typography>
                                            <Typography style={{ fontSize: '12px', color: 'gray' }}>{patientInfo?.role ? patientInfo?.role : loggedInUser?.role}</Typography></Box>} />
                                    <FormControl size="small" fullWidth>
                                        <Select variant={'outlined'} disabled={(loggedInUser?.id === patientInfo?.patient_id) ? true : false}
                                            value={patientInfo?.patientFormAccess}
                                            onChange={(e: any) => handleChangePatient(e.target.value)}
                                        >
                                            <MenuItem value={'VIEW'}>VIEW FORM</MenuItem>
                                            <MenuItem value={'TAKE'}>TAKE FORM</MenuItem>
                                        </Select>
                                    </FormControl>
                                </ListItem>
                                {sharedUsers && sharedUsers.data.length > 0 && sharedUsers.data.map((item: any, i: number) => {
                                    const checked = selectedUsers.filter((id: any) => id.user_role_id === item.user_role_id);
                                    const checkAccess = selectedPatientsAccess.filter((access: any) => access.id === item.user_role_id);
                                    return (
                                        <ListItem style={{ borderBottom: '2px solid #dddddd', padding: '4px 0px' }}>
                                            <FormControlLabel style={{ minWidth: '60%' }}
                                                control={
                                                    <Checkbox size='medium' checked={checked && checked.length === 0 ? false : true} onChange={(e) => handleSharedChecked(checked && checked.length === 0 ? false : true, item, checkAccess)} name={item.id} color="primary" />
                                                }
                                                label={<Box><Typography style={{ fontSize: '16px' }}>{item.first_name + ' ' + item.last_name}</Typography>
                                                    <Typography style={{ fontSize: '12px', color: 'gray' }}>{item.role}</Typography></Box>} />
                                            <FormControl size="small" fullWidth>
                                                <Select variant={'outlined'}
                                                    value={checkAccess && checkAccess.length > 0 ? checkAccess[0].access_type : 'VIEW'}
                                                    onChange={(e: any) => handleChangeUserAccess(e.target.value, item.user_role_id)}
                                                >
                                                    <MenuItem value={'VIEW'}>VIEW FORM</MenuItem>
                                                    <MenuItem value={'TAKE'}>TAKE FORM</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </ListItem>
                                    )
                                })
                                }
                                <Box mt={2} mb={1} display="flex" justifyContent="space-between" alignItems="center">
                                    <Box><Typography>{sharedUsers ? (sharedUsers.totalCount + 1) : 0} Total Shared Users</Typography></Box>
                                    <Box>
                                        <LoadingButton disabled={sharedUsers && sharedUsers.page > 0 ? false : true}
                                            className={classes.paginationButton}
                                            variant="contained"
                                            style={{ marginBottom: '0px' }}
                                            onClick={handlePrevButton}
                                        >
                                            Previous
                                        </LoadingButton>
                                        <LoadingButton disabled={sharedUsers && sharedUsers.page >= 0 &&
                                            sharedUsers.page > sharedUsers.totalCount / sharedUsers.pageSize - 1}
                                            className={classes.paginationButton}
                                            variant="contained"
                                            style={{ marginBottom: '0px' }}
                                            onClick={handleNextButton}
                                        >
                                            Next
                                        </LoadingButton>
                                    </Box>
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                </Paper>
                <DialogActions style={{ justifyContent: 'space-between', padding: '15px 20px', background: '#f9fafb' }}>
                    <LoadingButton
                        className={classes.secondaryButton}
                        variant="contained"
                        onClick={handelCloseialog}
                    >
                        Cancel
                    </LoadingButton>
                    <Box display='flex' alignItems='center'>
                        {selectedUsers.length > 0 && <><span>{selectedUsers.length} selected</span><Divider orientation="vertical" flexItem style={{ margin: '0 20px' }} /></>}
                        <LoadingButton disabled={loading}
                            className={classes.primaryButton}
                            variant="contained"
                            onClick={addSharedUser}
                        >
                            Save
                        </LoadingButton>
                    </Box>
                </DialogActions>
            </MUIDialog>
        </>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    topBox: {
        borderRadius: '0.625rem 0.625rem 0 0'
    },
    bottomBox: {
        borderRadius: '0 0 0.625rem 0.625rem'
    },
    primaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#fff',
        minWidth: '120px',
        background: '#0060B2',
        '&:hover': {
            backgroundColor: '#0060B2',
        }
    },
    secondaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#0060B2',
        minWidth: '120px',
        background: '#fff'
    },
    paper: {
        width: '580px',
        overflow: 'hidden'
    },
    searchTextField: {
        backgroundColor: theme.palette.secondary.main,
        fontSize: theme.typography.h5.fontSize,
        height: '2rem',
        width: '11rem',
        zIndex: 1
    },
    searchIcon: {
        fontSize: 17
    },
    paginationButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: 'black',
        marginLeft: '20px',
        minWidth: '120px',
        background: '#fff'
    },
}));
