import { Box, makeStyles, TextField, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import InfoIcon from '@material-ui/icons/Info';

interface ClinicianInfoSettingsProps {
  titleClass: string;
  clinicianInfo: string;
  handleClinicianInfoChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export const ClinicianInfoSettings: React.FC<ClinicianInfoSettingsProps> = ({
  titleClass,
  clinicianInfo,
  handleClinicianInfoChange
}) => {
  const classes = useStyles();
  return (
    <>
      <Box className={titleClass}>
        <Box flex className={classes.titleContainer}>
          <Typography color={'textSecondary'} variant="subtitle2">
            Instructions for Clinicians
          </Typography>
          <Tooltip
            title={'This description will only be visible to clinicians'}
            aria-label="more-info"
            placement="top"
          >
            <InfoIcon className={classes.icon} />
          </Tooltip>
        </Box>
      </Box>
      <TextField
        variant="outlined"
        size={'small'}
        //if edit mode is true show the field_prop from the editingFormField obj in redux
        value={clinicianInfo}
        inputProps={{ maxLength: 280 }}
        multiline
        fullWidth
        rows={2}
        onChange={handleClinicianInfoChange}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {},
  icon: { marginLeft: '.4rem', color: theme.palette.grey[500], fontSize: '1rem' },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
}));
