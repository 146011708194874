import React, { useEffect, useState } from 'react';
import { Box, Theme, Typography, makeStyles, Grid, Divider, Chip, TextField, AppBar, Toolbar, Button, Dialog } from '@material-ui/core';
import { ArrowBack, GetApp, Search } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { UuidType } from '../../../../shared/domain';
import { RootState } from '../../../../redux/redux-store';
import { getResourceFilesThunk, unassignResourceToUsersThunk } from '../../../../redux/thunk/thunks';
import { LoadingButton } from '../../../../components/LoadingButton';
import { ResourcesFilesTable } from './ResourceFilesTable';
import { url } from '../../../../redux/url';
import { ROLES } from '../../../../shared/globals';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { colors } from '../../../../styling/colors';
import { useRouteMatch } from 'react-router-dom';

var axios = require("axios");

interface ViewRecourceProps {
    handleClose: () => void;
    editResource: (info: any) => void;
    editInfo: any;
    onTab: any
}

export const ViewRecource = ({ handleClose, editResource, editInfo, onTab }: ViewRecourceProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [selectedResourceFiles, setSelectedResourceFiles] = useState<UuidType[]>([]);
    const [openPatient, setOpenPatient] = useState(false);
    const role: any = sessionStorage.getItem('role');
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const { path } = useRouteMatch();
    const split = path.split('/');
    const patient_role_id: any = split && split.length > 1 && split[2];
    const shared_role_id: any = split && split.length > 3 && split[4];
    const [confirm, setConfirm] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const user: any = useSelector((state: RootState) => state.DATA_REDUCER.patientInfo);
    const sharedUser: any = useSelector((state: RootState) => state.DATA_REDUCER.sharedUser);
    const resourceFiles: any = useSelector((state: RootState) => state.DATA_REDUCER.resourceFiles);
    useEffect(() => {
        if (editInfo)
            dispatch(getResourceFilesThunk({ resourceId: editInfo.id }))
    }, [])

    const handleSearch = (value: string) => {
        if (editInfo) {
            dispatch(
                getResourceFilesThunk({
                    resourceId: editInfo.id,
                    order: 'DESC',
                    page: 0,
                    pageSize: 10,
                    search: value
                })
            );
        }
    };

    const handleNextButton = async () => {
        if (
            resourceFiles &&
            resourceFiles.page >= 0 &&
            resourceFiles.page < resourceFiles.totalCount / resourceFiles.pageSize - 1
        ) {
            dispatch(
                getResourceFilesThunk({
                    resourceId: editInfo.id,
                    order: 'DESC',
                    page: resourceFiles.page + 1,
                    pageSize: 10,
                    search: ''
                })
            );
        }
    };
    const handlePrevButton = async () => {
        if (resourceFiles && resourceFiles.page > 0) {
            dispatch(
                getResourceFilesThunk({
                    resourceId: editInfo.id,
                    order: 'DESC',
                    page: resourceFiles.page - 1,
                    pageSize: 10,
                    search: ''
                })
            );
        }
    };

    const _getHeaders = (inputHeaders?: { [key: string]: string }) => {
        let headers = inputHeaders || {};
        let token = localStorage.getItem('token');
        headers["Accept"] = "application/json";
        const headersWithAuth = token
            ? {
                ...headers,
                Authorization: `Bearer ${token}`,
            }
            : headers;
        return headersWithAuth;
    };
    const viewURL = async () => {
        for (let i = 0; i < selectedResourceFiles.length; i++) {
            const urls = await resourceFiles && resourceFiles.data.filter((item: any) => item.id === selectedResourceFiles[i])
            const data1 = {
                input_files: [urls[0].file_url]
            }
            const config1 = {
                method: 'post',
                url: `${url}/user/auth/getSignedDownloadUrls`,
                headers: _getHeaders({ "Content-Type": "application/json" }),
                data: data1,
            };

            await axios(config1)
                .then(function (res: any) {
                    window.open(res.data.url && res.data.url.length > 0 && res.data.url[0], '_blank');
                })
        }
    }

    const handleUnassign = async () => {
        setLoading(true);
        const data = {
            id: editInfo.id,
            user_role_id: role === 'PATIENT' ? patient_role_id : shared_role_id
        }
        await dispatch(unassignResourceToUsersThunk(data))
        setLoading(false);
        setConfirm(false);
    }

    const handelClose = () => {
        setConfirm(false);
    }

    return (
        <Dialog open={true} fullScreen className={classes.dialog}>
            <AppBar square elevation={0} classes={{ root: classes.headerRoot }}>
                <Toolbar classes={{ root: classes.headerToolbar }}>
                    <Button
                        classes={{ text: classes.closeBtnText }}
                        startIcon={<ArrowBack style={{ color: 'gray', fontSize: '25px' }} />}
                        onClick={handleClose}
                    >
                        Return
                    </Button>
                    <Box alignSelf="stretch" display="flex" pr={3} py={2.75}>
                        <Divider flexItem orientation="vertical" />
                    </Box>

                    <Box
                        display="flex"
                        alignItems='center'
                        justifyContent="flex-start"
                        minHeight="5rem"
                        width="100%"
                    >
                        <Box>
                            <Typography className={classes.subtitle}>
                                {`${role} > ${role !== 'PATIENT' ? (sharedUser?.first_name + ' ' + sharedUser?.last_name) : user?.first_name + ' ' + user?.last_name} >`}
                            </Typography>
                            <Typography color="textSecondary" variant="h2">
                                <span style={{ color: 'gray' }}>Resources:</span> {editInfo.title}
                            </Typography>
                        </Box>
                        {/* {(editReferral && editReferral.isFavourite ? <Star color='primary' style={{ marginLeft: '30px', cursor: 'pointer' }} onClick={() => { loadings ? handelFavoriteCall() : handelRemoveFavorite() }} /> : <StarOutline color='primary' style={{ marginLeft: '30px', cursor: 'pointer' }} onClick={() => { loadings ? handelFavoriteCall() : handelAddFavorite() }} />)} */}
                    </Box>
                    {onTab !== 'Resource Library' && <LoadingButton disabled={loading} onClick={() => setConfirm(true)}
                        className={classes.primaryButton}
                        color="primary"
                        variant="contained"
                    >
                        Unassign
                    </LoadingButton>}
                </Toolbar>
            </AppBar>
            <Box m={4} style={{ background: '#F1F5F9', marginTop: '120px' }}>
                <Box display="flex" flexDirection="column" style={{ background: '#fff', borderRadius: '10px' }}>
                    <Box pt={3} px={3}>
                        <Grid container>
                            <Grid item xs={12} sm={10} md={10} style={{ display: 'flex' }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Box pb={2.5}>
                                            <Box pb={0.75}>
                                                <Typography style={{ color: 'gray' }} variant="subtitle2">Resource</Typography>
                                                <Box display='flex' alignItems="center" mt={0.3}>
                                                    <Typography variant="h2">{editInfo.title}</Typography>
                                                    {/* {editInfo && editInfo.isFavourite ? <Star color='primary' style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => handelIsFavourite(false)} /> : <StarOutline color='primary' style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => handelIsFavourite(true)} />} */}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box pb={2}>
                                            <Box pt={1}>
                                                <Typography color="textSecondary" variant="subtitle2">
                                                    {editInfo.description}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    {loggedInUser && ![ROLES.PATIENT, ROLES.PROXY, ROLES.RATER].includes(loggedInUser.role) && <>
                        <Divider variant="middle" />
                        <Box
                            className={classes.topBox}
                            px={3}
                            py={2.5}
                        >
                            <Typography variant="h4" style={{ fontWeight: 500 }}>Primary Domains</Typography>

                            <Box pt={2} display='flex'>
                                <div className={classes.otherActions}>
                                    {editInfo && editInfo.domains.map((item: any) => {
                                        return (
                                            <Chip
                                                key={''}
                                                classes={{ root: classes.collectionChip }
                                                }
                                                size="small"
                                                // onClick={() => unassignCollection()}
                                                label={
                                                    < div className={classes.chipAlign}>
                                                        {item.domain_name}
                                                    </div>
                                                }
                                            />)
                                    })}
                                </div>
                            </Box>
                        </Box>
                        <Divider variant="middle" />
                        <Box
                            className={classes.bottomBox}
                            px={3}
                            py={2}
                        >
                            <Typography variant="h4" style={{ fontWeight: 500 }}>Tags</Typography>
                            <Box pt={2} display='flex'>
                                <div className={classes.otherActions}>
                                    {editInfo && editInfo.tags.map((item: any) => {
                                        return (
                                            <Chip
                                                key={''}
                                                classes={{ root: classes.collectionChip }
                                                }
                                                size="small"
                                                label={
                                                    < div className={classes.chipAlign}>
                                                        {item.tag_name}
                                                    </div>
                                                }
                                            />)
                                    })}
                                </div>
                            </Box>
                        </Box>
                    </>}
                    <Divider variant="middle" />
                    <Box display="flex" justifyContent="space-between" alignItems='center' px={3} py={2}>
                        <Box display="flex" alignItems='end' className={classes.contentContainer}>
                            <Box mr={5}>
                                <Typography variant="h4" style={{ fontWeight: 500, color: 'gray', marginBottom: '6px' }}>FILES IN</Typography>
                                <Typography variant="h2">{editInfo.title}</Typography>
                            </Box>
                            <Box mt={1}>
                                <TextField className={classes.searchTextField}
                                    inputProps={{
                                        maxLength: 150,
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <Box ml={-0.5} mr={1} mt={0.5}>
                                                <Search className={classes.searchIcon} color="disabled" />
                                            </Box>
                                        )
                                    }} size='small' variant='outlined' placeholder='Search...' onChange={(e) => handleSearch(e.target.value)} />
                            </Box>
                        </Box>
                        <Box display='flex' alignItems='center'>
                            {selectedResourceFiles && selectedResourceFiles.length > 0 &&
                                <> <Typography style={{ color: '#575757', fontWeight: 400, fontSize: '13px' }}>{selectedResourceFiles.length} Selected</Typography>
                                    <Divider orientation="vertical" style={{ margin: '0px 20px 0px 20px', height: '30px' }} />
                                    <GetApp style={{ color: '#0c84c0', cursor: 'pointer' }} onClick={(e) => viewURL()} />
                                </>
                            }
                        </Box>
                    </Box>
                    <Box pb={2} px={4}>
                        <ResourcesFilesTable resourceFiles={resourceFiles} selectedResourceFiles={selectedResourceFiles} setSelectedResourceFiles={setSelectedResourceFiles} isView={false} handleNextButton={handleNextButton} handlePrevButton={handlePrevButton} />
                    </Box>
                </Box>
            </Box>
            {<ConfirmationDialog
                loading={loading}
                message='Are you sure you want to unassign resource?'
                open={confirm}
                title="Calculation"
                handleClose={handelClose}
                handleConfirmClick={() => handleUnassign()}
            />}
        </Dialog>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            background: '#F1F5F9'
        }
    },
    headerRoot: {
        backgroundColor: 'white',
        borderBottom: `2px solid ${theme.palette.divider}`,
        padding: 0
    },
    headerToolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: 0
    },
    closeBtnText: {
        color: 'gray',
        marginRight: 18,
        fontSize: '16px',
        '&:hover': {
            backgroundColor: 'unset'
        }
    },
    subtitle: {
        color: colors.text6,
        fontWeight: 400
    },
    topBox: {
        borderRadius: '0.625rem 0.625rem 0 0'
    },
    bottomBox: {
        borderRadius: '0 0 0.625rem 0.625rem'
    },
    textField: {
        width: '100%'
    },
    primaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#fff',
        minWidth: '120px',
        marginBottom: '20px'
    },
    secondaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#0060B2',
        marginBottom: '20px',
        minWidth: '120px'
    },
    deleteButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: 'red',
        minWidth: '120px'
    },
    createCollectionTextField: {
        maxWidth: '40rem',
        width: '80vw'
    },
    chipAlign: {
        display: 'flex',
        alignItems: 'center'
    },
    otherActions: {
        marginLeft: '.6rem',
        alignItems: 'center'
    },
    collectionChip: {
        marginRight: theme.spacing(1),
        backgroundColor: theme.background.main
    },
    searchTextField: {
        backgroundColor: theme.palette.secondary.main,
        fontSize: theme.typography.h5.fontSize,
        height: '2rem',
        width: '14rem'
    },
    searchIcon: {
        fontSize: 17
    },
    contentContainer: {
        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap'
        }
    }
}));
