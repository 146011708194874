import { Box, makeStyles, Theme, Typography, CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';
import { HeadCell, LastSorted, Pagination, RowCell, Table } from '../../../components/Table';
import { GetReferralsProps, getSharedUsersThunk } from '../../../redux/thunk/thunks';
import { UuidType } from '../../../shared/domain';
import { ROLES } from '../../../shared/globals';
import { ReferralProfile } from '../ReferralProfile/ReferralProfilePage';
import { useDispatch, useSelector } from 'react-redux';
import { ShareToUser } from '../../Resources/AssignPatient/ShareToUser';
import { RootState } from '../../../redux/redux-store';

interface ReferralTableProps {
  ReferralId?: UuidType;
  referralsData: any | null;
  loadReferrals: (getBundlesProps: GetReferralsProps) => void;
  viewReferralOpen: (open: any) => void;
  viewReferralDialogueOpen: boolean | false;
  tab: string;
}

export const ReferralsTable = ({
  referralsData,
  loadReferrals,
  viewReferralOpen,
  viewReferralDialogueOpen,
  tab
}: ReferralTableProps) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [referralInfo, setReferralInfo] = useState<any>();
  const [lastSorted, setLastSorted] = useState<LastSorted>({
    column: 'city',
    order: 'desc'
  });
  const [openSharing, setOpenSharing] = useState(false);
  const [data, setData] = useState<any>(null);
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);

  const loading: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.getDataLoading);

  const headCells: HeadCell[] = [
    {
      id: 'first_name',
      label: 'REFERRAL NAME'
    },
    {
      id: 'specialities',
      label: 'SPECIALITIES',
      isSortDisabled: true
    },
    {
      id: 'email',
      label: 'CONTACT INFO',
    },
    {
      id: 'city',
      label: 'LOCATION',
      isSortDisabled: true
    }
  ];
  const headCells2: HeadCell[] = [
    {
      id: 'first_name',
      label: 'REFERRAL NAME'
    },
    {
      id: 'specialities',
      label: 'SPECIALITIES',
      isSortDisabled: true
    },
    {
      id: 'email',
      label: 'CONTACT INFORMATION',
      isSortDisabled: true
    },
    {
      id: 'city',
      label: 'LOCATION',
      isSortDisabled: true
    }
  ];
  const headCells1: HeadCell[] = [
    {
      id: 'first_name',
      label: 'REFERRAL NAME'
    },
    {
      id: 'specialities',
      label: 'SPECIALITIES',
      isSortDisabled: true
    },
    {
      id: 'email',
      label: 'CONTACT INFO'
    },
    {
      id: 'city',
      label: 'LOCATION',
      isSortDisabled: true
    },
    {
      id: 'action',
      label: 'SHARING',
      isSortDisabled: true
    }
  ];

  const headCells3: HeadCell[] = [
    {
      id: 'first_name',
      label: 'REFERRAL NAME'
    },
    {
      id: 'specialities',
      label: 'SPECIALITIES',
      isSortDisabled: true
    },
    {
      id: 'email',
      label: 'CONTACT INFORMATION',
      isSortDisabled: true
    },
    {
      id: 'city',
      label: 'LOCATION',
      isSortDisabled: true
    },
  ];

  const handelShareUser = async (referral: any) => {
    await dispatch(getSharedUsersThunk({ giveOnlyActiveUsers: true }));
    setData(referral);
    setOpenSharing(true);
  }

  const handelClose = () => {
    loadReferrals({
      search: referralsData?.search,
      page: referralsData?.page,
      pageSize: referralsData?.pageSize,
      sort: referralsData?.sort,
      order: referralsData?.order,
      favourites: referralsData?.favourites,
      filters: referralsData?.filters,
    });
    setOpenSharing(false);
    setData(null);
  }

  const rowCells = referralsData?.data.map((referral: any) => {
    const nameLink = (<Box>
      <Typography color="primary" variant="h5" style={{ cursor: 'pointer' }} className={classes.referralName} onClick={(e) => { handleViewReferralDialogueOpen(referral); }}>
        {referral.first_name + ' ' + referral.last_name}
      </Typography>
      <Typography color={'textSecondary'} variant="h5" style={{ color: 'gray' }}>
        {referral.title}
      </Typography>
    </Box>
    );
    const specialties = <Box>
      {referral.specialities?.map((specialty: any, index: number) => {
        return (
          <>
            <Typography color={'textSecondary'} variant="h5" className={classes.tableBox}>
              {specialty.title}
            </Typography>
          </>
        );
      })
      }
    </Box>
    const contactDetails = (
      <>
        <Typography color={'textSecondary'} variant="h5" style={{ color: 'gray' }}>
          {referral.email}
        </Typography>
        <Typography color={'textSecondary'} variant="h5" style={{ color: 'gray' }}>
          {referral.mobile_phone}
        </Typography>
      </>
    );
    const location = (
      <>
        <Typography color={'textSecondary'} variant="h5" style={{ color: 'gray' }}>
          {referral.city && referral.state ? ((referral.city ? (referral.city + ', ') : '') + (referral?.state)) : '-'}
        </Typography>
        <Typography color={'textSecondary'} variant="h5" style={{ color: 'gray' }}>
          {referral.zip}
        </Typography>
      </>
    );
    const sharedUser = (
      <Typography color="primary" variant="h5" style={{ cursor: 'pointer' }} onClick={() => handelShareUser(referral)}>
        {(referral?.sharedUsers?.length) + ' Users'}
      </Typography>
    );

    let rowCell: RowCell = {};
    rowCell = [ROLES.PATIENT].includes(loggedInUser.role) ? tab !== 'Referral Library' ? {
      first_name: { component: nameLink, value: (referral.first_name + ' ' + referral.last_name) },
      specialities: {
        component: specialties,
        value: '-'
      },
      email: {
        component: contactDetails,
        value: Object.create({ email: referral.email, phone: referral.mobile_phone })
      },
      city: { component: location, value: Object.create({ city: referral.city, state: referral.state }) },
      action: {
        component: sharedUser,
        value: referral?.sharedUsers?.length + ' Users'
      }
    } : {
      first_name: { component: nameLink, value: (referral.first_name + ' ' + referral.last_name) },
      specialities: {
        component: specialties,
        value: '-'
      },
      email: {
        component: contactDetails,
        value: Object.create({ email: referral.email, phone: referral.mobile_phone })
      },
      city: { component: location, value: Object.create({ city: referral.city, state: referral.state }) },
    } :
      tab === 'Shared Referrals' ? {
        first_name: { component: nameLink, value: (referral.first_name + ' ' + referral.last_name) },
        specialities: {
          component: specialties,
          value: '-'
        },
        email: {
          component: contactDetails,
          value: Object.create({ email: referral.email, phone: referral.mobile_phone })
        },
        city: { component: location, value: Object.create({ city: referral.city, state: referral.state }) }
      } :
        {
          first_name: { component: nameLink, value: (referral.first_name + ' ' + referral.last_name) },
          specialities: {
            component: specialties,
            value: '-'
          },
          email: {
            component: contactDetails,
            value: Object.create({ email: referral.email, phone: referral.mobile_phone })
          },
          city: { component: location, value: Object.create({ city: referral.city, state: referral.state }) }
        };

    return Object.assign(rowCell, { content: referral });
  });

  const handleNextButton = async () => {
    if (
      referralsData &&
      referralsData?.page >= 0 &&
      referralsData?.page < referralsData?.totalCount / referralsData?.pageSize - 1
    ) {
      await loadReferrals({
        search: referralsData?.search,
        page: referralsData?.page + 1,
        pageSize: referralsData?.pageSize,
        sort: referralsData?.sort,
        order: referralsData?.order,
        favourites: referralsData?.favourites,
        filters: referralsData?.filters,
      });
    }
  };
  const handlePrevButton = async () => {
    if (referralsData && referralsData?.page > 0) {
      await loadReferrals({
        search: referralsData?.search,
        page: referralsData?.page - 1,
        pageSize: referralsData?.pageSize,
        sort: referralsData?.sort,
        order: referralsData?.order,
        favourites: referralsData?.favourites,
        filters: referralsData?.filters,
      });
    }
  };

  const handlePageSize = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    const pageSize = e.target.value as number;

    if (referralsData && pageSize > 0) {
      loadReferrals({
        page: 0,
        pageSize: pageSize,
        search: referralsData?.search,
        sort: referralsData?.sort,
        order: referralsData?.order,
        favourites: referralsData?.favourites,
        filters: referralsData?.filters,
      });
    }
  };

  const pagination: Pagination | undefined = referralsData
    ? {
      contentName: 'Referrals',
      page: referralsData?.page,
      pageSize: referralsData?.pageSize,
      total: referralsData?.totalCount,
      handleNextButton: handleNextButton,
      handlePageSize: handlePageSize,
      handlePrevButton: handlePrevButton
    }
    : undefined;

  const handleSort = (sort: string) => {
    const nextDirection = lastSorted.order === 'desc' ? 'asc' : 'desc';

    loadReferrals({
      order: sort === lastSorted.column ? nextDirection : 'desc',
      page: 0,
      pageSize: referralsData?.pageSize,
      search: referralsData?.search,
      sort,
      favourites: referralsData?.favourites,
      filters: referralsData?.filters,
    });

    setLastSorted(
      sort === lastSorted.column
        ? { column: sort, order: nextDirection }
        : { column: sort, order: 'desc' }
    );
  };

  const handleViewReferralDialogueClose = async () => {
    await loadReferrals({
      page: referralsData?.page,
      pageSize: referralsData?.pageSize,
      search: referralsData?.search,
      sort: referralsData?.sort,
      order: referralsData?.order,
      favourites: referralsData?.favourites,
      filters: referralsData?.filters,
    });
    viewReferralOpen(false);
  };

  const handleViewReferralDialogueOpen = (referralInfo: any) => {
    viewReferralOpen(true);
    setReferralInfo(referralInfo);
  };

  return (
    loading ? <Box display='flex' justifyContent='center'><CircularProgress /></Box> : <>
      {viewReferralDialogueOpen && (
        <ReferralProfile open={viewReferralDialogueOpen} handleClose={handleViewReferralDialogueClose} viewInfo={referralInfo} />
      )}
      <Table
        data={referralsData?.data}
        headCells={[ROLES.PATIENT].includes(loggedInUser.role) ? tab === 'Assigned Referrals' ? headCells1 : headCells : (tab === 'Shared Referrals') ? headCells2 : headCells3}
        loading={!referralsData?.data}
        pagination={pagination}
        rowCells={rowCells}
        sort={{
          lastSorted: lastSorted,
          handleSort: handleSort
        }}
      />
      {openSharing && <ShareToUser handelClose={handelClose} data={data} section={'referral'} />}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  nameLink: {
    textDecoration: 'none'
  },
  referralName: {
    display: 'flex'
  },
  launchIcon: {
    fontSize: '2rem',
    cursor: 'pointer'
  },
  InfoIcon: {
    fontSize: 16,
    marginLeft: 6
  },
  color: {
    color: '#0060B2'
  },
  tableBox: {
    padding: '2px 8px',
    marginRight: '5px',
    minWidth: '80px',
  },
  actionTypography: {
    padding: '10px',
    cursor: 'pointer',
    fontWeight: 'bold'
  },
}));
