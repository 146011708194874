import { Box, Divider, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { NavTab, NavTabs } from '../../../components/NavTabs';
import { EntryPageSettings } from './entryPageSidebarSettings/entryPageSettings';

enum NavEnums {
  Settings = 0
}

export const EntryPageSettingsView: React.FC = () => {
  const classes = useStyles();
  const [nav, setNav] = useState<NavEnums>(NavEnums.Settings);

  const handleNavChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setNav(newValue);
  };

  return (
    <Box className={classes.container}>
      <div className={classes.drawerHeaderContainer}>
        <div className={classes.drawerHeader}>
          <div>
            <Typography color={'textPrimary'} variant={'h6'}>
              Entry Page
            </Typography>
          </div>
        </div>
        <NavTabs value={nav} onChange={handleNavChange}>
          <NavTab label="Settings" />
        </NavTabs>
      </div>
      <Divider />
      {nav === NavEnums.Settings ? <EntryPageSettings /> : null}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    borderRight: `2px solid ${theme.palette.divider}`
  },
  drawerHeaderContainer: {
    padding: theme.spacing(3),
    paddingBottom: 0
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  }
}));
