import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Grid, Divider, TextField, Chip, DialogTitle, ListItemIcon, ListItemText,
  DialogContentText, DialogContent, DialogActions, FormControlLabel, Checkbox, ListItem, Fade, Paper
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MUIDialog from '@material-ui/core/Dialog'
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { ArrowBack, Add, Clear, Search, Star, StarOutline } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import {
  getAllPrimaryDomain, getAllTags, createCollectionThunk, deleteCollectionThunk, createTagThunk,
  editCollectionThunk, getAllCollectionFormsThunk, markFavoriteCollectionThunk, removeFavoriteCollectionThunk
} from '../../../redux/thunk/thunks';
import { ConfirmationDialog } from '../../../components/ConfirmationDialog';
import { LoadingButton } from '../../../components/LoadingButton';
import Popper, { PopperPlacementType } from '@material-ui/core/Popper';
import { RootState } from '../../../redux/redux-store';
import { CollectionFormTable } from './EditCollectionFormTable'
import { ConfirmationDelete } from '../../../components/DeleteConfirmation';
import { addNewTag } from '../../../redux/data/data-actions';

interface NewCollectionDialogProps {
  handleClose: () => void;
  collectionForms: any,
  isEdit: boolean,
  editInfo: any,
  handelSet: () => void;
}

interface FilmOptionType {
  title: string;
  id: string;
}

const filter = createFilterOptions<FilmOptionType>();

export const NewCollectionDialog = ({ handleClose, collectionForms, isEdit, editInfo, handelSet }: NewCollectionDialogProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const getCollectionForm: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.collectionForms
  );
  const loggedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(loggedUser);
  const [selectedCollectionForms, setSelectedCollectionForms] = useState<any>([]);
  const [domains, setDomains] = useState<any>([]);
  const [domain, setDomain] = useState<string[]>([]);
  const [tags, setTags] = useState<any>([]);
  const [tag, setTag] = useState<any>('');
  const [tagId, setTagId] = useState<any>('');
  const [tagIds, setTagIds] = useState<any>([]);
  const [checkedForms, setCheckedForms] = useState<any>([]);
  const [isTagDomain, setIsTagDomain] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isFileDelete, setIsFileDelete] = useState(false);
  const [isError, setIsError] = useState<any>(null);
  const [collectionsForm, setCollectionForms] = useState<any>(getCollectionForm ? getCollectionForm : []);
  const [isNumber, setIsNumber] = useState<any>();
  const [open, toggleOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkedMsg, setCheckedMsg] = useState('');
  const [openUpload, setOpenUpload] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [checkedError, setCheckedError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [primaryDomains, setPrimaryDomainList] = useState<any>(isEdit ? editInfo?.domains : []);
  const [tagList, setTagList] = useState<any>([]);
  const [error, setError] = useState(false);
  const [input, setInput] = useState(null);
  const [deleteFormsConfirmationDialogOpen, setDeleteFormsConfirmationDialogOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState({
    title: '',
    id: '',
  });
  const [tagValue, setTagValue] = useState('');
  const initialValues: any = {
    name: isEdit ? editInfo?.name : '',
    description: isEdit ? editInfo?.description : '',
  };
  const [tempDomain, setTempDomain] = useState<any>([]);
  const [isCheck, setIsCheck] = useState(false);
  const [anchorEl1, setAnchorEl1] = React.useState<HTMLButtonElement | null>(null);
  const [open1, setOpen1] = React.useState(false);
  const [placement, setPlacement] = React.useState<PopperPlacementType>();
  const [anchorEl2, setAnchorEl2] = React.useState<HTMLButtonElement | null>(null);
  const [open2, setOpen2] = React.useState(false);
  const [placement2, setPlacement2] = React.useState<PopperPlacementType>();
  const [searchTag, setSearchTag] = useState('');
  const [sortTagList, setSortTagList] = useState<any>();

  const handleClickOpen = (newPlacement: PopperPlacementType) => (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl1(event.currentTarget);
    setOpen1((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const deleteData: any = useSelector((state: RootState) => state.DATA_REDUCER.collectionStatusCount);

  const newlyAddedTag: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.tag
  );
  useEffect(() => {
    if (newlyAddedTag) {
      const isCheckedTag = tags.filter((item: any) => item === newlyAddedTag.id);
      if (isCheckedTag && isCheckedTag.length === 0) {
        tags.push(newlyAddedTag.id);
        setTags(tags);
        setIsAdd(isAdd ? false : true);
      }
    }
  })
  useEffect(() => {
    if (newlyAddedTag) {
      tagIds.push(newlyAddedTag?.id);
      setTagIds(tagIds);
      setSortTagList(tagLists);
      setTagList(tagLists);
      setIsAdd(!isAdd);
      dispatch(addNewTag(null));
      setAnchorEl2(null);
      setOpen2(false);
      setSearchTag('');
    }
  })
  const deleteUserLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.deleteUserLoading
  );
  const allCollectionForms: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.allCollectionForms
  );

  const primaryDomainList = useSelector(
    (state: RootState) => state.DATA_REDUCER.primaryDomains
  );
  useEffect(() => {
    editInfo && setIsFavorite(editInfo?.isFavourite);
    dispatch(getAllPrimaryDomain());
    dispatch(getAllTags());
  }, [])

  useEffect(() => {
    if (primaryDomainList) {
      setPrimaryDomainList(primaryDomainList);
    }
  }, [primaryDomainList]);

  useEffect(() => {
    if (isEdit) {
      for (let i = 0; i < editInfo?.domains?.length; i++) {
        const id: any = editInfo?.domains[i]?.id
        domains.push(id);
        domain.push(id);
        tempDomain.push(id);
      }
      setDomains(domains)
      for (let i = 0; i < editInfo?.tags?.length; i++) {
        const tag = editInfo?.tags[i]?.id
        tags.push(tag)
      }
      setTags(tags);
      for (let i = 0; i < editInfo?.tags?.length; i++) {
        const tag = editInfo?.tags[i]?.id
        tagIds.push(tag)
      }
      setTagIds(tagIds)
    }
  }, [isEdit]);

  const tagLists: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.tagList
  );
  useEffect(() => {
    if (tagLists) {
      setTagList(tagLists);
    }
  })

  const handleDeleteFormsConfirmationDialogOpen = () => {
    setDeleteFormsConfirmationDialogOpen(true);
  };
  const handleDeleteFormsConfirmationDialogClose = () => {
    setDeleteFormsConfirmationDialogOpen(false);
  };

  const handleDeleteFilesClose = () => {
    setIsFileDelete(false);
  };

  const handleDeleteForms = async () => {
    const data = {
      collection_ids: [editInfo?.id]
    }
    await dispatch(deleteCollectionThunk(data));

    setDeleteFormsConfirmationDialogOpen(false);
    handleClose();
  };

  const handelDeleteFiles = () => {
    const arr = collectionsForm && collectionsForm.filter((item: any) => !selectedCollectionForms?.includes(item.id));
    setCollectionForms(arr);
    const forms = checkedForms && checkedForms.filter((e: any) => !selectedCollectionForms?.includes(e.id));
    setCheckedForms(forms);
    setIsAdd(!isAdd);
    setSelectedCollectionForms([]);
  }

  const loginSchema = Yup.object().shape({
    name: Yup.string().required('Required').max(100, ''),
    description: Yup.string().required('Required').max(250, ''),
  });

  const handleSubmit = async (values: any, actions: any) => {
    setDisabled(true);
    const formIds: any = [];
    for (let i = 0; i < collectionsForm.length; i++) {
      formIds.push(collectionsForm[i].id);
    }
    const data: any = {
      "library": false,
      "formIds": formIds,
      "name": values.name,
      "description": values.description,
      "domains": domains,
      "tags": tags,
      "organization_id": loggedInUser ? loggedInUser.organization_id : null
    }
    await isEdit ? dispatch(editCollectionThunk(editInfo?.id, data)) : dispatch(createCollectionThunk(data))
    if (isEdit) {
      if (editInfo && editInfo?.isFavourite === isFavorite) {
      } else {
        isFavorite ? dispatch(markFavoriteCollectionThunk(editInfo?.id)) : isEdit && dispatch(removeFavoriteCollectionThunk(editInfo?.id));
      }
    }
  }

  const handelFavoriteForm = (value: boolean) => {
    setIsFavorite(value);
  }

  const handelOpenTagDomain = (value: number) => {
    setIsTagDomain(true);
    setIsNumber(value);
    value === 1 && setDomain(domains);
  }

  const handelClose = async () => {
    const arr = tempDomain;
    isNumber === 1 && await setDomains(arr);
    setIsTagDomain(false);
    setIsNumber(null);
    setIsError(null);
    setAnchorEl1(null);
    setOpen1(false);
    setTag('')
    setTagId('')
    setDomain([]);
  }

  const handelCloseDialog = () => {
    setDialogValue({
      title: '',
      id: '',
    });
    toggleOpen(false);
  }

  const handleChanges = async (value: any, check: boolean) => {
    if (domain?.length > 2 && !check) { setIsError(3) } else if (!check) {
      domain.push(value);
      setIsCheck(!isCheck);
      setIsError(null);
    } else {
      const array = await domain.filter((id: any) => id !== value);
      setDomain(array);
      const array1 = await domains.filter((id: any) => id !== value);
      setDomains(array1);
      setIsCheck(!isCheck);
      setIsError(null);
    }
  };

  const addDomain = () => {
    for (let i = 0; i < domain.length; i++) {
      const arr = domains && domains.filter((item: any) => item === domain[i]);
      if (arr.length === 0) {
        domains.push(domain[i])
      }
    }
    setIsError(null)
    setIsTagDomain(false);
    setDomain([]);
    setAnchorEl1(null);
    setOpen1(false);
  }

  const addTag = () => {
    if (tagIds && tagIds.length === 11) {
      setIsError(3)
    } else {
      setTags(tagIds);
    }
    setAnchorEl2(null);
    setOpen2(false);
    setIsTagDomain(false);
  }

  const handleClickTag = (event: React.MouseEvent<HTMLButtonElement>, newPlacement: PopperPlacementType) => {
    setSortTagList(tagLists);
    setAnchorEl2(event.currentTarget);
    setOpen2((prev) => placement2 !== newPlacement || !prev);
    setPlacement2(newPlacement);
  };

  const handleChangeTag = async (e: any) => {
    setSearchTag(e.target.value);
    const filtered = await tagList.filter((str: any) => str?.title.toLowerCase().includes(e.target.value.toLowerCase()));
    setSortTagList(filtered);
    if (e?.target?.value[e?.target?.value?.length - 1] === " ") {
      setOpen2(true);
    }
    if (!open1) {
      setOpen2(true);
    }
    setIsAdd(!isAdd);
  }
  const handleCheckTag = async (value: any, check: boolean) => {
    if (tagIds?.length > 10 && !check) { setIsError(3) } else if (!check) {
      tagIds.push(value);
      setTagIds(tagIds);
      setIsCheck(!isCheck);
      setIsError(null);
    } else {
      const array = await tagIds.filter((id: any) => id !== value);
      setTagIds(array);
      setIsCheck(!isCheck);
      setIsError(null);
    }
  };

  const saveNewTag = async () => {
    if (tagValue.trim() === '') {

    } else {
      setIsLoading(true);
      await dispatch(createTagThunk(tagValue))
      handelCloseDialog();
      setTagValue('');
      setIsLoading(false);
    }
  }

  const removeDomains = (id: any) => {
    const ids = domains.filter((e: any) => e !== id);
    const ids1 = domain.filter((e: any) => e !== id);
    setDomains(ids)
    setDomain(ids1)
    setIsAdd(!isAdd);
    setIsError(null);
  }

  const removeTag = (id: any) => {
    const tag = tags.filter((e: any) => e !== id);
    setTags(tag)
    setIsAdd(!isAdd);
    setIsDelete(true);
    setIsError(null);
  }

  const handleSearch = (value: any) => {
    // if (editInfo) {
    dispatch(
      getAllCollectionFormsThunk({
        collectionId: editInfo ? editInfo?.id : '',
        skip: 0,
        limit: 10,
        search: value
      })
    );
    // }
  };

  const handleNextButton = async () => {
    if (
      allCollectionForms &&
      allCollectionForms.skip >= 0 &&
      allCollectionForms.skip < allCollectionForms.total_count / allCollectionForms.limit - 1
    ) {
      dispatch(
        getAllCollectionFormsThunk({
          collectionId: editInfo ? editInfo?.id : '',
          skip: allCollectionForms.skip + 1,
          limit: 10,
          search: allCollectionForms.search
        })
      );
    }
  };
  const handlePrevButton = async () => {
    if (allCollectionForms && allCollectionForms.skip > 0) {
      dispatch(
        getAllCollectionFormsThunk({
          collectionId: editInfo ? editInfo?.id : '',
          skip: allCollectionForms.skip - 1,
          limit: 10,
          search: allCollectionForms.search
        })
      );
    }
  };

  const openFormDialog = () => {
    setOpenUpload(true);
    dispatch(getAllCollectionFormsThunk({ collectionId: editInfo ? editInfo?.id : '' }))
  }

  const closeFormDialog = () => {
    setOpenUpload(false);
  }

  const handleChangeChecked = (checked: boolean, info: any) => {
    if (!checked) {
      checkedForms.push(info);
      setIsAdd(!isAdd)
    } else {
      const forms = checkedForms.filter((e: any) => e.id !== info.id);
      setCheckedForms(forms);
      setIsAdd(!isAdd)
    }
  }

  const addInTableView = async () => {
    setCheckedError(false);
    if (checkedForms && checkedForms.length > 0) {
      for (let i = 0; i < checkedForms.length; i++) {
        const isAvailable = collectionsForm.filter((item: any) => item.id === checkedForms[i].id);
        if (isAvailable && isAvailable.length === 0) {
          collectionsForm.push(checkedForms[i]);
        }
      }
      setOpenUpload(false);
    }
  }

  const handleChange = (value: any, no: any) => {
    if (no === 0 && value.length > 100) {
      setError(true); setInput(no);
    } else if (no === 1 && value.length > 250) {
      setError(true); setInput(no);
    } else {
      setError(false); setInput(no);
    }
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box pt={3} px={3}>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={loginSchema}
        >
          {({ errors, touched, values }: any) => {
            return (
              <Form>
                <Grid container>
                  <Grid item xs={12} sm={9} md={10} style={{ display: 'flex' }}>
                    <Grid container style={{ marginRight: '15px' }}>
                      <Grid item xs={2} sm={1} md={1} style={{ display: 'flex' }}>
                        <ArrowBack style={{ fontSize: '30px', color: 'gray', cursor: 'pointer' }} onClick={handleClose} />
                        <Divider orientation="vertical" style={{ margin: '0px 40px 0px 10px', height: '35px' }} />
                      </Grid>
                      <Grid item xs={10} sm={11} md={11}>
                        <Box pb={2.5}>
                          <Box display='flex' alignItems="center" pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              Collection Title
                            </Typography>
                            {isEdit && (isFavorite ? <Star color='primary' style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => handelFavoriteForm(false)} /> : <StarOutline color='primary' style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => handelFavoriteForm(true)} />)}
                          </Box>

                          <Field fullWidth style={{ background: error && input === 0 ? '#fff1f1' : 'unset' }}
                            onInput={(e: any) => handleChange(e.target.value, 0)}
                            as={TextField}
                            inputProps={{ maxLength: 101 }}
                            className={classes.textField}
                            name="name"
                            size="small"
                            variant="outlined"
                          />

                          <Box display='flex' justifyContent='space-between'>
                            <Typography color={"error"} variant="subtitle2">
                              {errors.name && touched.name && errors.name}
                            </Typography>
                            <Typography style={{ color: values.name?.length > 100 ? '#911717' : '#0000008a' }} variant="subtitle2">
                              {values.name?.length === 101 ? 100 : values.name?.length}/100
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box pb={2.5}>
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              Collection Description
                            </Typography>
                          </Box>

                          <Field fullWidth multiline rows={4} style={{ background: error && input === 1 ? '#fff1f1' : 'unset' }}
                            onInput={(e: any) => handleChange(e.target.value, 1)}
                            as={TextField}
                            inputProps={{ maxLength: 251 }}
                            className={classes.textField}
                            name="description"
                            size="small"
                            variant="outlined"
                          />

                          <Box display='flex' justifyContent='space-between'>
                            <Typography color="error" variant="subtitle2">
                              {errors.description && touched.description && errors.description}
                            </Typography>
                            <Typography style={{ color: values.description?.length > 250 ? '#911717' : '#0000008a' }} variant="subtitle2">
                              {values.description?.length === 251 ? 250 : values.description?.length}/250
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={3} md={2} style={{ textAlign: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                    <Box mt={4}>
                      <LoadingButton
                        className={classes.primaryButton}
                        color="primary"
                        disabled={isDisabled}
                        loading={isDisabled}
                        type="submit"
                        variant="contained"
                        aria-label="sign in"
                      >
                        Save
                      </LoadingButton>
                    </Box>
                    <Box>
                      <LoadingButton
                        className={classes.secondaryButton}
                        onClick={handleClose}
                        variant="contained"
                      >
                        Cancel
                      </LoadingButton>
                    </Box>
                    {isEdit && <Box pb={1} mb={1} display='flex' justifyContent='flex-end'>
                      <Divider style={{ width: '120px' }} />
                    </Box>}
                    {isEdit && <Box>
                      <LoadingButton
                        className={classes.deleteButton}
                        variant="contained"
                        onClick={(e) => handleDeleteFormsConfirmationDialogOpen()}
                      >
                        Delete
                      </LoadingButton>
                    </Box>}
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Box>
      <Divider variant="middle" />
      <Box
        className={classes.topBox}
        px={3}
        py={2.5}
      >
        <Typography variant="h4" style={{ fontWeight: 500 }}>Primary Domains</Typography>

        <Box pt={2} display='flex'>
          <div className={classes.otherActions}>
            {domains && domains.map((item: any) => {
              let info = primaryDomains.filter((e: any) => e.id === item);
              return info.length > 0 ?
                <Chip
                  key={''}
                  classes={{ root: classes.collectionChip }
                  }
                  size="small"
                  label={
                    < div className={classes.chipAlign}>
                      {info[0].title ? info[0].title : info[0].title}
                      <Clear className={classes.clearIcon} onClick={(e) => removeDomains(info[0].id)} />
                    </div>
                  }
                /> : ''
            })}
          </div>
          <Add style={{ background: '#f1f5f9', padding: '2px 5px', borderRadius: '10px', cursor: 'pointer' }} onClick={(e) => handelOpenTagDomain(1)} />
        </Box>
      </Box>
      <Divider variant="middle" />
      <Box
        className={classes.bottomBox}
        px={3}
        py={2}
      >
        <Typography variant="h4" style={{ fontWeight: 500 }}>Tags</Typography>
        <Box pt={2} display='flex'>
          <div className={classes.otherActions}>
            {tags && tags.map((item: any) => {
              let info = tagList.filter((e: any) => e.id === item);
              return info.length > 0 ?
                <Chip
                  key={''}
                  classes={{ root: classes.collectionChip }
                  }
                  size="small"
                  // onClick={() => unassignCollection()}
                  label={
                    <div className={classes.chipAlign}>
                      {info[0].title ? info[0].title : info[0].tag_name}
                      <Clear className={classes.clearIcon} onClick={(e) => removeTag(info[0].id)} />
                    </div>
                  }
                /> : ''
            })}
          </div>
          <Add style={{ background: '#f1f5f9', padding: '2px 5px', borderRadius: '10px', cursor: 'pointer' }} onClick={(e) => handelOpenTagDomain(0)} />
        </Box>
      </Box>
      <Divider variant="middle" />
      <Box display="flex" justifyContent="space-between" alignItems='center' px={3} py={2} className={classes.contentContainer}>
        <Box display="flex" alignItems='end' className={classes.contentContainer}>
          <Box mr={5}>
            <Typography variant="h4" style={{ fontWeight: 500, color: 'gray', marginBottom: '6px' }}>FORMS IN</Typography>
            <Typography variant="h2">{editInfo?.name}</Typography>
          </Box>
        </Box>
        <Box display='flex' alignItems='center' mt={1}>
          {selectedCollectionForms && selectedCollectionForms?.length > 0 &&
            <> <Typography style={{ color: '#575757', fontWeight: 400, fontSize: '13px' }}>{selectedCollectionForms?.length} Selected</Typography>
              <Divider orientation="vertical" style={{ margin: '0px 20px 0px 20px', height: '30px' }} />
              <LoadingButton style={{ marginBottom: '0px' }}
                className={classes.primaryButton}
                variant="contained" color="primary"
                onClick={(e) => handelDeleteFiles()}
              >
                Remove
              </LoadingButton>
            </>
          }
        </Box>
        {selectedCollectionForms && selectedCollectionForms?.length === 0 &&
          <Box mt={1}>
            <LoadingButton style={{ marginBottom: '0px' }}
              className={classes.primaryButton}
              color="primary"
              variant="contained"
              onClick={(e) => openFormDialog()}
            >
              Add Forms
            </LoadingButton>
          </Box>
        }
      </Box>
      <Box pb={2} px={4}>
        <CollectionFormTable collectionForms={collectionsForm} selectedCollectionForms={selectedCollectionForms} setSelectedCollectionForm={setSelectedCollectionForms} setCollectionForms={setCollectionForms} setIsAdd={setIsAdd} isAdd={isAdd} handelSet={handelSet} />
      </Box>

      {isTagDomain && <MUIDialog open={isTagDomain} classes={{ paper: classes.paper }}>
        <Box style={{ background: '#F9FAFB', borderRadius: '0.625rem 0.625rem 0 0' }}>
          <Box px={4} py={3}>
            <Typography color="textSecondary" variant="h2">
              {isNumber === 1 ? 'Select Primary Domain' : 'Select Tags'}
            </Typography>
          </Box>
        </Box>
        <Grid container className={classes.box} spacing={2}>
          <Grid item xs={12} md={6}>
            {isNumber === 1 ?
              <Box pb={2.5}>
                <Box pb={0.75}>
                  <Typography color="textSecondary" variant="subtitle2">
                    Select Primary Domains
                  </Typography>
                </Box>
                <LoadingButton onClick={handleClickOpen('bottom')} style={{ display: 'flex', justifyContent: 'flex-start', overflow: 'hidden', width: '-webkit-fill-available', height: '35px', border: '1px solid #c1c1c1' }}>
                  {domain && domain.map((id: any) => {
                    const info = primaryDomains.filter((item: any) => item.id === id);
                    return (
                      <Chip key={info[0]?.id} label={info[0]?.title} className={classes.chip} />
                    )
                  })}
                </LoadingButton>
                <Popper open={open1} anchorEl={anchorEl1} placement={'bottom'} transition style={{ zIndex: 2000 }}>
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper style={{ maxHeight: '300px', minHeight: '200px', minWidth: '300px', maxWidth: '330px', overflow: 'auto' }}>
                        {primaryDomains && primaryDomains.map((item: any) => {
                          const checked = domain.filter((id: any) => id === item.id);
                          return (
                            <ListItem key={item.id} role={undefined} dense button onClick={(e) => handleChanges(item.id, checked?.length > 0 ? true : false)}>
                              <ListItemIcon style={{ minWidth: 'auto' }}>
                                <Checkbox color='primary'
                                  edge="start"
                                  checked={checked?.length > 0 ? true : false}
                                />
                              </ListItemIcon>
                              <ListItemText id={item.id} primary={<Typography color="textSecondary" variant="subtitle2">{item.title}</Typography>} />
                            </ListItem>
                          )
                        })}
                      </Paper>
                    </Fade>
                  )}
                </Popper>
                {isError ? (
                  <Typography color="error" variant="subtitle2">
                    {isError === 1 ? 'Domain already selected' : (isError === 3 ? 'Add only 3 domains' : 'Please select domain')}
                  </Typography>
                ) : null}
              </Box>
              :
              <Box pb={2.5} mr={2}>
                <Box pb={0.75}>
                  <Typography color="textSecondary" variant="subtitle2">
                    Select Tag / Add Tag
                  </Typography>
                </Box>
                <button style={{ background: 'transparent', border: 'unset', width: '100%', padding: '0px', margin: '0px' }} onClick={(e: any) => handleClickTag(e, 'bottom')}><TextField id="searchTag" variant="outlined" size='small' value={searchTag} fullWidth onInput={(e) => handleChangeTag(e)} /></button>
                <Popper open={open2} anchorEl={anchorEl2} placement={'bottom'} transition style={{ zIndex: 2000 }}>
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper style={{ maxHeight: '300px', minHeight: '200px', minWidth: '370px', maxWidth: '370px', overflow: 'auto' }}>
                        {sortTagList && sortTagList.length > 0 ? sortTagList.map((item: any) => {
                          const checked = tagIds.filter((id: any) => id === item.id);
                          return (
                            <ListItem key={item.id} role={undefined} dense button onClick={() => handleCheckTag(item.id, checked?.length > 0 ? true : false)}>
                              <ListItemIcon style={{ minWidth: 'auto' }}>
                                <Checkbox color='primary'
                                  edge="start"
                                  checked={checked?.length > 0 ? true : false}
                                />
                              </ListItemIcon>
                              <ListItemText id={item.id} primary={<Typography color="textSecondary" variant="subtitle2">{item.title}</Typography>} />
                            </ListItem>
                          )
                        }) :
                          <LoadingButton
                            className={classes.secondaryButton}
                            variant="contained"
                            style={{ margin: '10px' }}
                            onClick={() => { toggleOpen(true); setOpen2(false); setAnchorEl2(null); setTagValue(searchTag) }}
                          >
                            Add New Tag: {searchTag}
                          </LoadingButton>
                        }
                      </Paper>
                    </Fade>
                  )}
                </Popper>
                {isError ? (
                  <Typography color="error" variant="subtitle2">
                    {isError === 1 ? 'Tag already selected' : (isError === 3 ? 'Add only 10 tags' : 'Please select tag')}
                  </Typography>
                ) : null}
              </Box>
            }
          </Grid>
          <Grid item xs={12} md={6}>
            <Box pb={0.5}>
              <Typography color="textSecondary" variant="h4">
                {isNumber === 1 ? 'Current Primary Domain' : 'Current Tags'}
              </Typography>
            </Box>
            {isNumber === 1 ?
              <div className={classes.otherActions}>
                {domains && domains.map((item: any) => {
                  let info = primaryDomains.filter((e: any) => e.id === item);
                  return info.length > 0 ?
                    <Chip
                      key={''}
                      classes={{ root: classes.collectionChip }
                      }
                      size="small"
                      label={
                        < div className={classes.chipAlign}>
                          {info[0].title ? info[0].title : info[0].domain_name}
                          <Clear className={classes.clearIcon} onClick={(e) => removeDomains(info[0].id)} />
                        </div>
                      }
                    /> : ''
                })}
              </div> :
              <div className={classes.otherActions}>
                {tags && tags.map((item: any) => {
                  let info = tagList.filter((e: any) => e.id === item);
                  return info.length > 0 ?
                    <Chip
                      key={''}
                      classes={{ root: classes.collectionChip }
                      }
                      size="small"
                      // onClick={() => unassignCollection()}
                      label={
                        <div className={classes.chipAlign}>
                          {info[0].title ? info[0].title : info[0].tag_name}
                          <Clear className={classes.clearIcon} onClick={(e) => removeTag(info[0].id)} />
                        </div>
                      }
                    /> : ''
                })}
              </div>
            }
          </Grid>
        </Grid>
        <Box mt={2} px={4} py={2} style={{ background: '#F9FAFB', borderRadius: '0 0 0.625rem 0.625rem' }}>
          <Grid container>
            <Grid item xs={6} style={{ textAlign: 'left' }}>
              <LoadingButton
                className={classes.secondaryButton}
                variant="contained"
                style={{ marginBottom: '0px' }}
                onClick={handelClose}
              >
                Cancel
              </LoadingButton>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <LoadingButton color="primary"
                className={classes.primaryButton}
                onClick={(e) => { isNumber === 1 ? addDomain() : addTag() }}
                variant="contained"
                style={{ marginBottom: '0px' }}
              >
                Save
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </MUIDialog>
      }
      {
        <MUIDialog open={open}>
          <DialogTitle style={{ background: '#f9fafb' }}>Add a new tag</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Did you miss any tag in tag list? Please, add it!
            </DialogContentText>
            <Box pb={2.5}>
              <Box pb={0.75}>
                <Typography color="textSecondary" variant="subtitle2">
                  Tag
                </Typography>
              </Box>
              <TextField fullWidth variant='outlined' size='small'
                autoFocus
                value={tagValue}
                onChange={(event) => setTagValue(event.target.value)}
                type="text"
              />
            </Box>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-around', background: '#f9fafb' }}>
            <LoadingButton
              className={classes.secondaryButton}
              variant="contained"
              style={{ marginBottom: '0px' }}
              onClick={handelCloseDialog}
            >
              Cancel
            </LoadingButton>
            <LoadingButton color="primary" disabled={isLoading}
              className={classes.primaryButton}
              variant="contained"
              style={{ marginBottom: '0px' }}
              onClick={(e) => saveNewTag()}
            >
              Add
            </LoadingButton>
          </DialogActions>
        </MUIDialog>
      }
      {openUpload &&
        <MUIDialog open={openUpload} fullWidth maxWidth={'sm'}>
          <DialogTitle style={{ background: '#f9fafb' }}>
            <Grid container spacing={0}>
              <Grid item xs={12} md={7}>
                <Typography style={{ fontSize: '20px' }}><b>Add Forms to this collection</b></Typography>
                {/* <Typography style={{ fontWeight: 500 }}>Maximum 10 forms</Typography> */}
              </Grid>
              <Grid item xs={12} md={5} style={{ textAlign: 'center' }}>
                <TextField className={classes.searchTextField}
                  inputProps={{
                    maxLength: 150,
                  }}
                  InputProps={{
                    startAdornment: (
                      <Box ml={-0.5} mr={1} mt={0.5}>
                        <Search className={classes.searchIcon} color="disabled" />
                      </Box>
                    )
                  }} size='small' variant='outlined' placeholder='Search...' onChange={(e) => handleSearch(e.target.value)} />
              </Grid>
              {checkedError && <Grid item xs={12}>
                <Typography color="error" variant="subtitle2">
                  {checkedMsg}
                </Typography>
              </Grid>}
            </Grid>
          </DialogTitle>
          <DialogContent>
            {allCollectionForms && allCollectionForms.data.map((item: any, i: number) => {
              const checked = checkedForms.filter((id: any) => id.id === item.id);
              return (
                <ListItem style={{ borderBottom: '2px solid #dddddd', padding: '4px 0px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox size='medium' checked={checked && checked.length === 0 ? false : true} onChange={(e) => handleChangeChecked(checked && checked.length === 0 ? false : true, item)} name={item.id} color="primary" />
                    }
                    label={<Typography style={{ fontSize: '16px' }}>{item.title}</Typography>} />
                </ListItem>
              )
            })}
            <Box mt={2} mb={1} display="flex" justifyContent="space-between" alignItems="center">
              <Box><Typography>{allCollectionForms ? allCollectionForms.total_count : 0} total Forms</Typography></Box>
              <Box>
                <LoadingButton disabled={allCollectionForms && allCollectionForms.skip > 0 ? false : true}
                  className={classes.paginationButton}
                  variant="contained"
                  style={{ marginBottom: '0px' }}
                  onClick={handlePrevButton}
                >
                  Previous
                </LoadingButton>
                <LoadingButton disabled={allCollectionForms && allCollectionForms.skip >= 0 &&
                  allCollectionForms.skip > allCollectionForms.total_count / allCollectionForms.limit - 1}
                  className={classes.paginationButton}
                  variant="contained"
                  style={{ marginBottom: '0px' }}
                  onClick={handleNextButton}
                >
                  Next
                </LoadingButton>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between', padding: '15px 20px', background: '#f9fafb' }}>
            <LoadingButton
              className={classes.secondaryButton}
              variant="contained"
              style={{ marginBottom: '0px' }}
              onClick={closeFormDialog}
            >
              Cancel
            </LoadingButton>
            <LoadingButton color="primary"
              className={classes.primaryButton}
              variant="contained"
              disabled={loading}
              loading={loading}
              style={{ marginBottom: '0px' }}
              onClick={(e) => addInTableView()}
            >
              Add
            </LoadingButton>
          </DialogActions>
        </MUIDialog>
      }
      {deleteFormsConfirmationDialogOpen &&
        <ConfirmationDelete
          loading={deleteUserLoading}
          data={deleteData}
          customData={[]}
          title="collection"
          handleClose={handleDeleteFormsConfirmationDialogClose}
          handleConfirmClick={() => handleDeleteForms()}
        />
      }
      {isFileDelete &&
        <ConfirmationDialog
          loading={deleteUserLoading}
          message={`Are you sure you want to delete? Please type "DELETE" to confirm.`}
          open={isFileDelete}
          title=""
          handleClose={handleDeleteFilesClose}
          handleConfirmClick={() => handelDeleteFiles()}
        />
      }
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  paperBox: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    height: '180px',
    width: '100%',
    borderRadius: '10px',
    background: '#F1F5F9',
    overflow: 'auto'
  },
  paper: { minWidth: "850px", overflowX: 'hidden' },
  topBox: {
    borderRadius: '0.625rem 0.625rem 0 0'
  },
  bottomBox: {
    borderRadius: '0 0 0.625rem 0.625rem'
  },
  textField: {
    width: '100%'
  },
  primaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: '#fff',
    minWidth: '120px',
    marginBottom: '20px'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: '#0060B2',
    marginBottom: '20px',
    minWidth: '120px',
    background: '#fff'
  },
  paginationButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: 'black',
    marginLeft: '20px',
    minWidth: '120px',
    background: '#fff'
  },
  deleteButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: 'red',
    minWidth: '120px',
    background: '#fff'
  },
  createCollectionTextField: {
    maxWidth: '40rem',
    width: '80vw'
  },
  box: {
    padding: '30px',
    width: '-webkit-fill-available'
  },
  chipAlign: {
    display: 'flex',
    alignItems: 'center'
  },
  otherActions: {
    marginLeft: '.6rem',
    alignItems: 'center'
  },
  collectionChip: {
    marginRight: theme.spacing(1),
    marginBottom: '4px',
    backgroundColor: theme.background.main
  },
  closeBtnText: {
    color: theme.palette.text.secondary
  },
  clearIcon: {
    fontSize: 14,
    marginLeft: '0.25rem',
    cursor: 'pointer'
  },
  textField1: {
    width: '100%',
    height: '35px',
    padding: '0px'
  },
  input: {
    display: 'none'
  },
  searchTextField: {
    backgroundColor: theme.palette.secondary.main,
    fontSize: theme.typography.h5.fontSize,
    height: '2rem',
    width: '14rem'
  },
  searchIcon: {
    fontSize: 17
  },
  chips: {
    textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden',
  },
  chip: {
    margin: 2, height: '25px', backgroundColor: theme.background.main
  },
  contentContainer: {
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  }
}));
