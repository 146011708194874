import { debounce } from '@material-ui/core';
import pluralize from 'pluralize';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from '../../../../../components/LegacyDialog';
import { HeadCell, Pagination, RowCell, Table } from '../../../../../components/Table';
import { getCollectionsAction } from '../../../../../redux/data/data-actions';
import { setSuccessAction } from '../../../../../redux/display/display-actions';
import { RootState } from '../../../../../redux/redux-store';
import { fetchFormToEditByIdThunk, getCollectionsThunk } from '../../../../../redux/thunk/thunks';
import { CollectionSearchResults, UuidType } from '../../../../../shared/domain';

interface AddFormToCollectionsDialogProps {
  open: boolean;
  handleAssignFormtoCollections: (selectedCollections: UuidType[]) => void;
  handleClose: () => void;
  formId: UuidType;
}

export const AddFormToCollectionsDialog = ({
  open,
  handleAssignFormtoCollections,
  handleClose,
  formId
}: AddFormToCollectionsDialogProps) => {
  const dispatch = useDispatch();
  const form_version_id = sessionStorage.getItem('version');
  const collectionsData = useSelector(
    (state: RootState) => state.DATA_REDUCER.collectionSearchResults.addFormToCollections
  );
  const assignFormToCollectionsLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.assignFormToCollectionsLoading
  );
  const assignFormToCollectionsSuccess = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.assignFormToCollectionsSuccess
  );

  const [selectedCollections, setSelectedCollections] = useState<UuidType[]>([]);

  useEffect(() => {
    if (assignFormToCollectionsSuccess) {
      dispatch(setSuccessAction('assignFormToCollectionsSuccess', true));
      setSelectedCollections([]);
      handleClose();
      dispatch(fetchFormToEditByIdThunk(formId));
    }
  }, [assignFormToCollectionsSuccess]);

  const headCells: HeadCell[] = [
    {
      id: 'name',
      label: 'name'
    }
  ];
  const rowCells = collectionsData?.collections.map((collection) => {
    const rowCell: RowCell = {
      name: { value: collection.name }
    };

    return Object.assign(rowCell, { content: collection });
  });

  const handleNextButton = async () => {
    if (
      collectionsData &&
      collectionsData.page >= 0 &&
      collectionsData.page < collectionsData.total / collectionsData.pageSize - 1
    ) {
      await dispatch(
        getCollectionsThunk({
          collectionsType: 'addFormToCollections',
          page: collectionsData.page + 1,
          pageSize: collectionsData.pageSize,
          search: collectionsData.search
        })
      );
    }
  };
  const handlePrevButton = async () => {
    if (collectionsData && collectionsData.page > 0) {
      await dispatch(
        getCollectionsThunk({
          collectionsType: 'addFormToCollections',
          page: collectionsData.page - 1,
          pageSize: collectionsData.pageSize,
          search: collectionsData.search
        })
      );
    }
  };

  const pagination: Pagination | null = collectionsData
    ? {
      contentName: 'Form',
      page: collectionsData.page,
      pageSize: collectionsData.pageSize,
      total: collectionsData.total,
      handleNextButton,
      handlePrevButton
    }
    : null;

  const searchCollections = (collectionsData: CollectionSearchResults, value: string) =>
    debounce(
      () =>
        dispatch(
          getCollectionsThunk({
            collectionsType: 'addFormToCollections',
            page: 0,
            pageSize: collectionsData.pageSize,
            search: value
          })
        ),
      500
    );
  const handleSearch = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (collectionsData && e.target) {
      searchCollections(collectionsData, e.target.value)();
    }
  };

  useEffect(() => {
    if (collectionsData === null) {
      dispatch(getCollectionsThunk({ collectionsType: 'addFormToCollections', pageSize: 8 }));
    }
  }, [collectionsData]);

  useEffect(() => {
    return () => {
      dispatch(getCollectionsAction('addFormToCollections', null));
    };
  }, []);

  return (
    <Dialog
      open={open}
      primaryButton={{
        disabled: selectedCollections.length === 0,
        loading: assignFormToCollectionsLoading,
        text:
          selectedCollections.length === 0
            ? 'Add collection(s)'
            : `Assign form to ${selectedCollections.length} ${pluralize(
              'collection',
              selectedCollections.length
            )}`,
        handleClick: () => handleAssignFormtoCollections(selectedCollections)
      }}
      secondaryButton={{ text: 'Cancel', handleClick: handleClose }}
      title="Add form to collection(s)"
      handleClose={handleClose}
      handleSearch={handleSearch}
    >
      <Table
        data={collectionsData?.collections}
        headCells={headCells}
        hideHead
        rowCells={rowCells}
        pagination={pagination}
        selection={{
          selectedContents: selectedCollections,
          setSelectedContents: (selected: UuidType[]) => setSelectedCollections(selected)
        }}
      />
    </Dialog>
  );
};
