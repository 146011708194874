import { Box, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Close } from '@material-ui/icons';
import { AccountUsersTable } from './AccountUsersTable';
import {
  deleteUsersThunk, GetNonPatientsProps, getAccountsThunk, deleteMultiUserThunk,
  getSiteAdminAccountsThunk
} from '../../../../redux/thunk/thunks';
import { Dialog } from '../../../../components/Dialog';
import { InviteUserDialog } from './InviteUserDialog';
import { ROLES } from '../../../../shared/globals';
import { RootState } from '../../../../redux/redux-store';
import { CardHeader } from '../../../../components/LegacyCardHeader';
import { UuidType } from '../../../../shared/domain';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { setSuccessAction } from '../../../../redux/display/display-actions';
import { ViewAccounts } from './viewAccounts';
import { ViewAccount } from './viewAccount';

export const AccountUsers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedCustomForms, setSelectedCustomForms] = useState<UuidType[]>([]);
  const [selectedLibraryForms, setSelectedLibraryForms] = useState<UuidType[]>([]);
  const [deleteFormsConfirmationDialogOpen, setDeleteFormsConfirmationDialogOpen] = useState(false);
  const [searchPattern, setSearchPattern] = useState('');
  const [createCollectionOpen, setCreateCollectionOpen] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [editAccount, setEditAccount] = useState(false);
  const [viewAccounts, setViewAccounts] = useState(false);
  const [editInfo, setEditInfo] = useState<any>();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);

  const userSearchResults: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.accountUserList
  );
  const deleteUserLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.deleteUserLoading
  );
  const deleteUserSuccess = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.deleteUserSuccess
  );

  const [isEditInfo, setIsEditInfo] = useState(false);
  const [inviteUserDialogOpen, setInviteUserDialogOpen] = useState(false);
  const [deleteUserDialogOpen, setDeleteUserDialogOpen] = useState<any>(null);

  const viewAccountsOpen = (info: any, isOpen: boolean) => {
    setViewAccounts(true);
    setEditInfo(info);
    setIsEditInfo(isOpen);
    setEditAccount(false);
  };
  const viewAccountsClose = () => {
    setViewAccounts(false);
  };
  const handleEditAccountsOpen = () => {
    setCreateCollectionOpen(true);
    setEdit(true);
  };

  const handleCreateCollectionOpen = () => {
    setInviteUserDialogOpen(true);
    setEdit(false);
  };

  const handleDeleteUser = () => {
    if (deleteUserDialogOpen) {
      dispatch(deleteUsersThunk(deleteUserDialogOpen.id, null));
    }
  };

  const handleDeleteForms = async (library?: boolean) => {
    setDeleteLoading(true);

    const selectedForms = library ? selectedLibraryForms : selectedCustomForms;

    await Promise.all(
      selectedForms.map((form: UuidType) => {
        dispatch(deleteMultiUserThunk(form));
      })
    );
    if (loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role)) {
      dispatch(getSiteAdminAccountsThunk({ order: userSearchResults.order, sortBy: userSearchResults.sortBy, page: userSearchResults.page, pageSize: userSearchResults.pageSize, search: userSearchResults.search }));
    } else {
      dispatch(getAccountsThunk({ id: loggedInUser ? loggedInUser.id : '', page: userSearchResults.page, pageSize: userSearchResults.pageSize, search: userSearchResults.search, nameSequence: userSearchResults.order, lastLoginSequence: '', roleSequence: '', statusSequence: '' }));
    }
    setSelectedCustomForms([]);
    setSelectedLibraryForms([]);
    setDeleteLoading(false);
    setDeleteFormsConfirmationDialogOpen(false);
  };

  const loadUsers = async (getNonPatientsProps: GetNonPatientsProps) => {
    if (loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role)) {
      await dispatch(getSiteAdminAccountsThunk({ order: getNonPatientsProps.order, sortBy: getNonPatientsProps.sort, page: getNonPatientsProps.page, pageSize: getNonPatientsProps.pageSize, search: getNonPatientsProps.search }));
    } else {
      await dispatch(getAccountsThunk({
        id: loggedInUser ? loggedInUser.id : '', page: getNonPatientsProps.page, pageSize: getNonPatientsProps.pageSize,
        search: getNonPatientsProps.search,
        nameSequence: getNonPatientsProps.sort === 'first_name' ? getNonPatientsProps.order : '',
        lastLoginSequence: getNonPatientsProps.sort === 'last_login' ? getNonPatientsProps.order : '',
        roleSequence: getNonPatientsProps.sort === 'role' ? getNonPatientsProps.order : '',
        statusSequence: getNonPatientsProps.sort === 'status' ? getNonPatientsProps.order : '',
      }));
    }
  };

  useEffect(() => {
    if (deleteUserSuccess) {
      dispatch(setSuccessAction('deleteUserSuccess', false));
      setDeleteUserDialogOpen(null);
      setViewAccounts(false);
      if (loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role)) {
        dispatch(getSiteAdminAccountsThunk({ order: userSearchResults.order, sortBy: userSearchResults.sortBy, page: userSearchResults.page, pageSize: userSearchResults.pageSize, search: userSearchResults.search }));
      } else {
        dispatch(getAccountsThunk({ id: loggedInUser ? loggedInUser.id : '', page: userSearchResults.page, pageSize: userSearchResults.pageSize, search: userSearchResults.search, nameSequence: userSearchResults.order, lastLoginSequence: '', roleSequence: '', statusSequence: '' }));
      }
    }
  }, [deleteUserSuccess]);

  useEffect(() => {
    if (loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role)) {
      dispatch(getSiteAdminAccountsThunk({ order: 'ASC', sortBy: "first_name", page: 0, pageSize: 10, search: '' }));
    } else {
      dispatch(getAccountsThunk({ id: loggedInUser ? loggedInUser.id : '', page: 0, pageSize: 10, search: '', nameSequence: 'ASC', lastLoginSequence: '', roleSequence: '', statusSequence: '' }));
    }
  }, []);

  const handleDeleteFormsConfirmationDialogClose = () => {
    setDeleteFormsConfirmationDialogOpen(false);
  };

  const handleCustomSearch = (value: string) => {
    setSearchPattern(value);
    if (loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role)) {
      dispatch(getSiteAdminAccountsThunk({ order: 'ASC', sortBy: "first_name", page: 0, pageSize: 10, search: value }));
    } else {
      dispatch(getAccountsThunk({ id: loggedInUser ? loggedInUser.id : '', page: 0, pageSize: 10, search: value, nameSequence: 'ASC', lastLoginSequence: '', roleSequence: '', statusSequence: '' }));
    }
  };

  const handleCloseDialog = () => {
    setIsOpen(false)
  }

  const handelSetDeleteUser = (user: any) => {
    setDeleteUserDialogOpen(user)
  }

  const CustomForms = (
    <Box pb={4} pt={1} px={4}>
      <AccountUsersTable
        usersData={userSearchResults}
        loadUsers={loadUsers}
        setSelectedForms={(selectedForms: UuidType[]) => setSelectedCustomForms(selectedForms)}
        viewAccountsOpen={viewAccountsOpen}
      />
    </Box>
  );

  const handelEdit = () => {
    setEditAccount(false);
  }
  const handelEdit1 = () => {
    setEditAccount(true);
  }

  return (
    <>
      {inviteUserDialogOpen && (
        <InviteUserDialog
          open={inviteUserDialogOpen}
          handleClose={() => setInviteUserDialogOpen(false)}
        />
      )}

      {deleteFormsConfirmationDialogOpen && selectedCustomForms.length > 0 && (
        <ConfirmationDialog
          loading={deleteUserLoading}
          message={`Are you sure you want to delete? Please type "DELETE" to confirm..`}
          open={deleteFormsConfirmationDialogOpen}
          title=""
          handleClose={handleDeleteFormsConfirmationDialogClose}
          handleConfirmClick={() => handleDeleteForms(false)}
        />
      )}

      {deleteUserDialogOpen && deleteUserDialogOpen.id &&
        <ConfirmationDialog
          loading={deleteUserLoading}
          message={`Are you sure you want to delete? Please type "DELETE" to confirm.`}
          open={deleteUserDialogOpen.id ? true : false}
          title=""
          handleClose={() => setDeleteUserDialogOpen(null)}
          handleConfirmClick={handleDeleteUser}
        />
      }
      {/* {viewAccounts &&
        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <LoadingButton style={{ marginBottom: '20px', marginRight: '26px' }}
            className={classes.primaryButton}
            color="primary"
            variant="contained"
            onClick={() => sendInvite()}
          >
            Invite
          </LoadingButton>
        </Box>
      } */}
      {viewAccounts ? <Paper>
        {editAccount ? <ViewAccounts handleClose={viewAccountsClose} editOrganization={handleEditAccountsOpen} viewInfo={editInfo} handleDeleteUserButton={handelSetDeleteUser} isEditInfo={isEditInfo} setEditAccount={handelEdit} /> :
          <ViewAccount handleClose={viewAccountsClose} editOrganization={handleEditAccountsOpen} viewInfo={editInfo} handleDeleteUserButton={handelSetDeleteUser} isEditInfo={isEditInfo} setEditAccount={handelEdit1} />}
      </Paper>
        :
        <Paper>
          <Box display="flex" flexDirection="column">
            <CardHeader
              tabComponents={[
                {
                  component: CustomForms,
                  label: '',
                  primaryButton: {
                    text: 'Add',
                    handleClick: handleCreateCollectionOpen
                  },
                  // selected: {
                  //   primaryButton: {
                  //     text: 'Invite',
                  //     handleClick: sendInvite
                  //   },
                  //   // secondaryButton: {
                  //   //   text: 'Cancel Invite',
                  //   //   handleClick: handleDeleteFormsConfirmationDialogOpen
                  //   // },
                  //   deleteButton: {
                  //     text: 'Delete',
                  //     handleClick: handleDeleteFormsConfirmationDialogOpen
                  //   },
                  //   selectedContents: selectedCustomForms,
                  //   handleSelectedDelete: handleDeleteFormsConfirmationDialogOpen
                  // },
                  style: {
                    background: true,
                    spacing: { pb: 0, pt: 3, px: 4 }
                  },
                  handleSearch: handleCustomSearch
                }
              ]}
            />
          </Box>
        </Paper>
      }
      {isOpen &&
        <Dialog handleClose={handleCloseDialog} open={isOpen}>
          <Box style={{ width: '400px' }}>
            <Box pb={3} pt={2} px={4} display="flex" justifyContent="space-between">
              <Typography color="textSecondary" variant="h2">Invitation Success</Typography>
              <Close onClick={() => setIsOpen(false)} />
            </Box>
            <Box pb={6} pt={2} display="flex" justifyContent="center">
              <Typography color="textSecondary" variant="h2">Invitation Sent</Typography>
            </Box>
          </Box>
        </Dialog>
      }
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  topBox: {
    backgroundColor: theme.palette.secondary.light,
    borderBottom: '0.0625rem solid #D1D6DC',
    borderRadius: '0.625rem 0.625rem 0 0'
  },
  primaryButton: {
    boxShadow:
      '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)',
    width: '100px'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    width: '100px'
  }
}));
