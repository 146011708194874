import { Box, Grid, makeStyles, Paper, DialogActions, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardHeader } from '../../../components/LegacyCardHeader';
import { RootState } from '../../../redux/redux-store';
import { ConfirmationDialog } from '../../../components/ConfirmationDialog';
import { GetReferralsProps, getReferralsThunk, getReferralsFilterThunk, deleteReferralThunk, removeFevReferralThunk } from '../../../redux/thunk/referral-thunks';
import { UuidType } from '../../../shared/domain';
import { NewReferralDialog } from './NewReferralDialogue';
import { LoadingButton } from '../../../components/LoadingButton';
import { Dialog } from '../../../components/Dialog';
import { ReferralFiltersDialog } from './ReferralFilters';
import { ReferralsTable } from './ReferralsTable';
import { setSuccessAction } from '../../../redux/display/display-actions';
import { ROLES } from '../../../shared/globals';
import { Patients } from '../../Resources/AssignPatient/Patients';
import { assignmentInfoAction, getReferralsAction } from '../../../redux/data/data-actions';

export const Referrals = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedReferrals, setSelectedReferrals] = useState<UuidType[]>([]);
  const [newReferralDialogueOpen, setNewReferralDialogueOpen] = useState(false);
  const [tab, setTab] = useState<any>(0);
  const [searchPattern, setSearchPattern] = useState('');
  const [isBaseDelete, setIsBaseDelete] = useState(false);
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);
  const referrals: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.referralsSearchResults.referrals
  );
  const referralFilterList = useSelector(
    (state: RootState) => state.DATA_REDUCER.referralFilterListResults
  );
  const [openFilter, setFilterDialog] = useState(false);
  const [referralFilters, setReferralList] = useState(referralFilterList);
  const [deleteReferralConfirmationDialogOpen, setDeleteReferralConfirmationDialogOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [viewReferralDialogueOpen, setViewReferralDialogueOpen] = useState(false);
  const [openPatient, setOpenPatient] = useState(false);
  const [info, setReferralInfo] = useState<any>(null);

  const success: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.saveReferralSuccess)
  useEffect(() => {
    if (success) {
      setViewReferralDialogueOpen(false);
      dispatch(setSuccessAction('saveReferralSuccess', false));
      dispatch(getReferralsThunk({ search: searchPattern, pageSize: referrals?.pageSize, page: referrals?.page, order: referrals?.order, sort: referrals?.sort, favourites: referrals?.favourites, filters: referrals?.filters, referral_type: referrals?.referral_type }));
    } else if (!referrals) {
      const tabValue = sessionStorage.getItem('tab');
      dispatch(getReferralsThunk({ favourites: (!tabValue || tabValue === 'Favorites') ? true : false }));
    }
  })

  const viewReferralOpen = (open: any) => {
    setViewReferralDialogueOpen(open);
  };

  const filterReferralOpen = () => {
    setFilterDialog(true);
  };
  const filterReferralClose = () => {
    setFilterDialog(false);
  };

  const handleSearch = (value: string) => {
    setSearchPattern(value);
    if (referrals) {
      dispatch(
        getReferralsThunk({
          favourites: referrals?.favourites,
          order: referrals?.order,
          pageSize: referrals?.pageSize,
          search: value,
          sort: referrals?.sort,
          filters: referrals?.filters,
          referral_type: referrals?.referral_type
        })
      );
    }
  };

  // We need async/await over here as it is used for some state loading task
  const loadReferrals = async (getReferralsProps: GetReferralsProps) => {
    await dispatch(getReferralsThunk(getReferralsProps));
  };

  const handleNewReferralButton = () => {
    setNewReferralDialogueOpen(true);
  };
  const handleNewReferralDialogueClose = () => {
    setNewReferralDialogueOpen(false);
  };

  const handleDeleteReferralConfirmationDialogOpen = () => {
    let checkCustomId: any = [];
    for (let i = 0; i < referrals?.data.length; i++) {
      if (!referrals?.data[i].org_id) {
        const res = selectedReferrals?.filter((id: any) => id === referrals?.data[i].id);
        res && res.length > 0 && checkCustomId.push(res);
      }
    }
    if (loggedInUser && loggedInUser.role === ROLES.ADMIN || loggedInUser.role === ROLES.SUPER_ADMIN) {
      setDeleteReferralConfirmationDialogOpen(true);
    } else if (checkCustomId.length === 0) {
      setDeleteReferralConfirmationDialogOpen(true);
    } else {
      setIsBaseDelete(true);
    }
  };
  const handleDeleteReferralConfirmationDialogClose = () => {
    setDeleteReferralConfirmationDialogOpen(false);
  };

  const handleDeleteReferral = async (info: any) => {
    setDeleteLoading(true);
    const array = info ? info : selectedReferrals;
    const deletedArray = [];
    for (let i = 0; i < array.length; i++) {
      const selectReferral = referrals?.data.filter((item: any) => item.id === array[i]);
      if (selectReferral && selectReferral.length > 0) {
        const info = {
          "referral_id": selectReferral[0].referral_id,
          "email": selectReferral[0].email,
          "org_id": loggedInUser.organization_id
        }
        deletedArray.push(info);
      }
    }
    await dispatch(deleteReferralThunk(deletedArray));
    setSelectedReferrals([]);
    setDeleteLoading(false);
    setDeleteReferralConfirmationDialogOpen(false);
  };

  const handleRemoveFevReferral = async () => {
    const deletedArray = [];
    for (let i = 0; i < selectedReferrals?.length; i++) {
      const selectReferral = referrals?.data.filter((item: any) => item.id === selectedReferrals[i]);
      if (selectReferral && selectReferral.length > 0) {
        deletedArray.push(selectReferral[0].referral_id);
      }
    }
    const data = { referral_ids: deletedArray };
    await dispatch(removeFevReferralThunk(data));
    setSelectedReferrals([]);
  };

  useEffect(() => {
    dispatch(getReferralsFilterThunk({ type: 'ALL' }));

    return function CleanUp() {
      dispatch(getReferralsAction('referrals', null));
    };
  }, []);

  const onFavoritesTabChange = async () => {
    if (tab === 0) {
      setTab(1); selectedReferrals?.length > 0 && setSelectedReferrals([])
      dispatch(getReferralsThunk({ search: searchPattern, favourites: true, filters: referrals?.filters, referral_type: referrals?.referral_type }));
    }
  }

  const onReferralTabChange = async () => {
    if (tab === 1) {
      setTab(0); selectedReferrals?.length > 0 && setSelectedReferrals([])
      dispatch(getReferralsThunk({ search: searchPattern, favourites: false, filters: referrals?.filters, referral_type: referrals?.referral_type }));
    }
  }

  const ReferralsTableContainer = (
    <Box pb={4} pt={1} px={4}>
      <ReferralsTable
        referralsData={referrals}
        selectedReferrals={selectedReferrals}
        loadReferrals={loadReferrals}
        setSelectedReferrals={(selectedReferrals: UuidType[]) =>
          setSelectedReferrals(selectedReferrals)
        }
        isReferralLibrary={false}
        filtersList={referralFilters}
        handleDeleteReferral={handleDeleteReferral}
        viewReferralOpen={viewReferralOpen}
        viewReferralDialogueOpen={viewReferralDialogueOpen}
      />
    </Box>
  );

  const assignReferralToPatient = async () => {
    setOpenPatient(true);
    const arr: any = [];
    for (let i = 0; i < selectedReferrals?.length; i++) {
      const check = referrals && referrals?.data.filter((item: any) => item.id === selectedReferrals[i]);
      check && check.length > 0 && arr.push(check[0]);
    }
    setReferralInfo(arr);
    dispatch(assignmentInfoAction(arr));
  }
  const assignReferralToPatientClose = () => {
    setOpenPatient(false);
    setSelectedReferrals([]);
  }

  return (
    <>
      {newReferralDialogueOpen && (
        <NewReferralDialog
          open={newReferralDialogueOpen}
          handleClose={handleNewReferralDialogueClose}
          info={null}
          edit={false}
        />
      )}
      {openFilter &&
        <ReferralFiltersDialog open={openFilter} handleClose={filterReferralClose} loadReferrals={loadReferrals} />
      }
      {deleteReferralConfirmationDialogOpen &&
        <ConfirmationDialog
          loading={deleteLoading}
          message='type "DELETE" to confirm.'
          open={deleteReferralConfirmationDialogOpen}
          title='Are you sure you want to delete? Please'
          handleClose={handleDeleteReferralConfirmationDialogClose}
          handleConfirmClick={() => handleDeleteReferral(null)}
        />
      }

      <Grid container>
        <Grid item xs={12}>
          <Paper>
            <Box display="flex" flexDirection="column">
              {loggedInUser && [ROLES.CLINICIAN].includes(loggedInUser.role) ?
                <CardHeader
                  tabComponents={[
                    {
                      component: ReferralsTableContainer,
                      label: 'Favorites',
                      selected: {
                        primaryButton: {
                          text: 'Remove',
                          handleClick: handleRemoveFevReferral
                        },
                        secondaryButton: {
                          text: 'Assign to Patient',
                          handleClick: assignReferralToPatient
                        },
                        selectedContents: selectedReferrals
                      },
                      style: {
                        background: true,
                        spacing: { py: 3, px: 4, pb: 0 }
                      },
                      title: 'Favorites Referral',
                      handleSearch: handleSearch,
                      onTabChange: onFavoritesTabChange,
                      filter: filterReferralOpen
                    },
                    {
                      component: ReferralsTableContainer,
                      label: 'Referrals',
                      selected: {
                        primaryButton: {
                          text: 'Assign to Patient',
                          handleClick: assignReferralToPatient
                        },
                        selectedContents: selectedReferrals,
                      },
                      style: {
                        background: true,
                        spacing: { pb: 0, pt: 3, px: 4 }
                      },
                      title: 'All Referral',
                      handleSearch: handleSearch,
                      onTabChange: onReferralTabChange,
                      filter: filterReferralOpen
                    },
                  ]}
                /> :
                <CardHeader
                  tabComponents={[
                    {
                      component: ReferralsTableContainer,
                      label: 'Favorites',
                      primaryButton: {
                        text: 'Add',
                        handleClick: handleNewReferralButton
                      },
                      selected: {
                        primaryButton: {
                          text: 'Remove',
                          handleClick: handleRemoveFevReferral
                        },
                        selectedContents: selectedReferrals,
                      },
                      style: {
                        background: true,
                        spacing: { py: 3, px: 4, pb: 0 }
                      },
                      title: 'Favorites Referral',
                      handleSearch: handleSearch,
                      onTabChange: onFavoritesTabChange,
                      filter: filterReferralOpen
                    },
                    {
                      component: ReferralsTableContainer,
                      label: 'Referrals',
                      primaryButton: {
                        text: 'Add',
                        handleClick: handleNewReferralButton
                      },
                      selected: {
                        selectedContents: selectedReferrals,
                        handleSelectedDelete: handleDeleteReferralConfirmationDialogOpen
                      },
                      style: {
                        background: true,
                        spacing: { pb: 0, pt: 3, px: 4 }
                      },
                      title: 'All Referral',
                      handleSearch: handleSearch,
                      onTabChange: onReferralTabChange,
                      filter: filterReferralOpen
                    },
                  ]}
                />
              }
            </Box>
          </Paper>
        </Grid>
        {isBaseDelete &&
          <Dialog open={isBaseDelete}>
            <Box style={{ width: '400px', textAlign: 'center' }}>
              <Box pb={3} pt={2} px={4} display="flex">
                <Typography variant="h3">You have selected base referral, it cannot be deleted</Typography>
              </Box>
            </Box>
            <DialogActions style={{ justifyContent: 'center' }}>
              <LoadingButton style={{ border: '1px solid rgba(0, 0, 0, 0.15)', width: '120px', marginBottom: '10px' }}
                variant="contained"
                onClick={() => setIsBaseDelete(false)}
              >
                Close
              </LoadingButton>
            </DialogActions>
          </Dialog>
        }
        {openPatient && <Patients handelClose={assignReferralToPatientClose} />}
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  filterSection: {
    overflowY: 'auto',
    maxHeight: '35rem'
  }
});
