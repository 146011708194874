import { Box, makeStyles, Paper, Theme, Typography, DialogActions } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardHeader } from '../../../components/LegacyCardHeader';
import { TabBar, TabComponent } from '../../../components/TabBar';
import { getResourceFileAction, assignmentInfoAction } from '../../../redux/data/data-actions';
import { LoadingButton } from '../../../components/LoadingButton';
import { Dialog } from '../../../components/Dialog';
import { RootState } from '../../../redux/redux-store';
import {
  changeCatalogueThunk, removeFavoriteResourceThunk, duplicateResourcesThunk, getAssignedResourcesThunk,
  getCataloguesThunk, getResourceStatusCount,
  GetStaticResourcesProps,
  getStaticResourcesThunk,
  deleteResourceThunk
} from '../../../redux/thunk/thunks';
import { ROLES } from '../../../shared/globals';
import { setSuccessAction } from '../../../redux/display/display-actions';
import { getStaticResourcesAction } from '../../../redux/data/data-actions';
import { Tag, UuidType } from '../../../shared/domain';
import { TAG_TYPES } from '../../../shared/globals';
import { ViewRecource } from './viewResource';
import { ChangeCatalogueDialogue } from './ChangeCatalogueDialogue';
import { NewCatalogueDialog } from './NewCatalogueDialog';
import { NewResourceDialog } from './NewStaticResourceDialog';
import { ResourcesTable } from './ResourcesTable';
import { SearchTagsDialog, selectedTags } from './SearchTagsDialog';
import { ConfirmationDelete } from '../../../components/DeleteResource';
import { ConfirmationDialog } from '../../../components/ConfirmationDialog';
import { Patients } from '../AssignPatient/Patients';

export const Resources = () => {
  const dispatch = useDispatch();
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);
  const [selectedFormsDetails, setSelectedFormsDetails] = useState<any>([]);
  const [selectedResources, setSelectedResources] = useState<UuidType[]>([]);
  const [newStaticReourceDialogOpen, setNewStaticReourceDialogOpen] = useState(false);
  const [changeCatalogueDialogueOpen, setChangeCatalogueDialogueOpen] = useState(false);
  const [deleteFormsConfirmationDialogOpen, setDeleteFormsConfirmationDialogOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState<selectedTags>({});
  const [viewCollection, setViewCollection] = useState(false);
  const [resourceFiles, setResourceFiles] = useState<any>();
  const [addDuplicate, setAddDuplicateOpen] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [isBaseDelete, setIsBaseDelete] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [newCatalogueDialogOpen, setNewCatalogueDialogOpen] = useState(false);
  const [tagSearchType, setTagSearchType] = useState<TAG_TYPES>(TAG_TYPES.PRIMARY);
  const [searchTagsDialogOpen, setSearchTagsDialogOpen] = useState(false);
  const [editInfo, setEditInfo] = useState<any>();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [tab, setTab] = useState<any>(0);
  const [searchPattern, setSearchPattern] = useState('');
  const [openPatient, setOpenPatient] = useState(false);
  const [info, setResourceInfo] = useState<any>(null);

  const deleteData: any = useSelector((state: RootState) => state.DATA_REDUCER.resourceStatusCount);

  const resourcesData: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.staticResourcesSearchResults.approved
  );

  const cataloguesSearchResult: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.cataloguesSearchResults.completeList
  );

  const success: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.uploadStaticResourceSuccess)
  useEffect(() => {
    if (success) {
      setViewCollection(false); setEditInfo(null);
      setEdit(false);
      setNewStaticReourceDialogOpen(false);
      dispatch(setSuccessAction('uploadStaticResourceSuccess', false));
      if ([ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role)) {
        dispatch(getAssignedResourcesThunk({ search: searchPattern, sort: resourcesData.sort, order: resourcesData.order, favourites: resourcesData?.favourites, page: resourcesData.page, pageSize: resourcesData.pageSize }));
      } else {
        dispatch(getStaticResourcesThunk({ search: searchPattern, sort: resourcesData.sort, order: resourcesData.order, favourites: resourcesData?.favourites, resource_type: resourcesData?.resource_type, page: resourcesData.page, pageSize: resourcesData.pageSize }));
      }
    } else if (!resourcesData) {
      const tabValue = sessionStorage.getItem('tab');
      if ([ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role)) {
        dispatch(getAssignedResourcesThunk({ favourites: (!tabValue || tabValue === 'Favorites') ? true : false }));
      } else {
        dispatch(getStaticResourcesThunk({ favourites: (!tabValue || tabValue === 'Favorites') ? true : false }));
      }
    }
  });

  const handleChangeFavorite = (value: boolean) => {
    editInfo.isFavourite = value;
    setEditInfo(editInfo);
  }
  const viewResourceOpen = (info: any) => {
    setViewCollection(true);
    setEditInfo(info);
  };
  const viewResourceClose = async () => {
    if ([ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role)) {
      await dispatch(getAssignedResourcesThunk({ search: searchPattern, sort: resourcesData.sort, order: resourcesData.order, favourites: resourcesData?.favourites, page: resourcesData.page, pageSize: resourcesData.pageSize }));
    } else {
      await dispatch(getStaticResourcesThunk({ search: searchPattern, sort: resourcesData.sort, order: resourcesData.order, favourites: resourcesData?.favourites, resource_type: resourcesData?.resource_type, page: resourcesData.page, pageSize: resourcesData.pageSize }));
    }
    setViewCollection(false);
    setSelectedResources([]);
    setEdit(false);
    setEditInfo(null);
  };
  const handleEditResourceOpen = (files: any) => {
    setNewStaticReourceDialogOpen(true);
    setEdit(true);
    setResourceFiles(files)
  };

  const handleSearch = (value: string) => {
    setSearchPattern(value);
    if (resourcesData) {
      if ([ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role)) {
        dispatch(getAssignedResourcesThunk({
          catalogueId: resourcesData.catalogueId,
          order: resourcesData.order,
          pageSize: resourcesData.pageSize,
          search: value,
          sort: resourcesData.sort,
          favourites: resourcesData?.favourites
        }));
      } else {
        dispatch(
          getStaticResourcesThunk({
            catalogueId: resourcesData.catalogueId,
            order: resourcesData.order,
            pageSize: resourcesData.pageSize,
            search: value,
            sort: resourcesData.sort,
            favourites: resourcesData?.favourites,
            resource_type: resourcesData?.resource_type
          })
        );
      }
    }
  };

  // We need async/await over here as it is used for some state loading task
  const loadResources = async (getStaticResourcesProps: GetStaticResourcesProps) => {
    if ([ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role)) {
      await dispatch(getAssignedResourcesThunk(getStaticResourcesProps));
    } else {
      await dispatch(getStaticResourcesThunk(getStaticResourcesProps));
    }
  };

  const handleNewCatalogueDialogClose = () => {
    setNewCatalogueDialogOpen(false);
  };

  const handleNewStaticResourceButton = () => {
    dispatch(getResourceFileAction([]))
    setNewStaticReourceDialogOpen(true);
  };
  const handleNewStaticResourceDialogClose = () => {
    setNewStaticReourceDialogOpen(false);
    //clear selected tags if any
    setSelectedTags({});
  };

  const handleChangeCatalogueDialogueClose = () => {
    setChangeCatalogueDialogueOpen(false);
  };

  const handleSearchTagsDialogClose = () => {
    setNewStaticReourceDialogOpen(true);
    setSearchTagsDialogOpen(false);
  };
  const handleTagSelect = (tagType: TAG_TYPES, tag: Tag) => {
    if (tagType === TAG_TYPES.PRIMARY) {
      setSelectedTags({ primaryTag: tag });
    }
    if (tagType === TAG_TYPES.SECONDARY) {
      setSelectedTags({ ...selectedTags, secondaryTag: tag });
    }
    setNewStaticReourceDialogOpen(true);
    setSearchTagsDialogOpen(false);
    //go back to create new resource modal
  };

  useEffect(() => {
    dispatch(getCataloguesThunk({ catalogueType: 'completeList', pageSize: 0 }));

    return function CleanUp() {
      dispatch(getStaticResourcesAction('approved', null));
    };
  }, []);

  const onFavoritesTabChange = async () => {
    if (tab === 0) {
      setTab(1);
      if ([ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role)) {
        selectedResources.length > 0 && setSelectedResources([]);
        dispatch(getAssignedResourcesThunk({ search: searchPattern, favourites: true }));
      } else {
        selectedResources.length > 0 && setSelectedResources([]);
        dispatch(getStaticResourcesThunk({ search: searchPattern, favourites: true, resource_type: resourcesData?.resource_type }));
      }
    }
  }
  const onResourcesTabChange = async () => {
    if (tab === 1) {
      setTab(0);
      if ([ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role)) {
        selectedResources.length > 0 && setSelectedResources([]);
        tab === 1 && dispatch(getAssignedResourcesThunk({ search: searchPattern, favourites: false }));
      } else {
        selectedResources.length > 0 && setSelectedResources([]);
        tab === 1 && dispatch(getStaticResourcesThunk({ search: searchPattern, favourites: false, resource_type: resourcesData?.resource_type }));
      }
    }
  }

  const ResourcesTableContainer = (
    <Box pb={4} pt={1} px={2}>
      <ResourcesTable
        resourcesData={resourcesData}
        selectedResources={selectedResources}
        loadResources={loadResources}
        setSelectedResources={(selectedResources: UuidType[]) => { setSelectedResources(selectedResources); handelSetFormDetails(selectedResources) }
        }
        staticResourceType={'approved'}
        handelResourceView={viewResourceOpen}
      />
    </Box>
  );

  const handleAddToOrgOpen = () => {
    let checkCustomId: any = [];
    for (let i = 0; i < resourcesData.data.length; i++) {
      if (resourcesData.data[i].organization_id) {
        const res = selectedResources.filter((id: any) => id === resourcesData.data[i].id);
        res && res.length > 0 && checkCustomId.push(res);
      }
    }
    if (checkCustomId.length === 0) {
      setAddDuplicateOpen(true);
    } else {
      setIsCustom(true);
    }
  };
  const handleAddToOrgClose = () => {
    setAddDuplicateOpen(false);
  };

  const handleChangeCatalogue = async (catalogueId: UuidType, resourceIds: UuidType[]) => {
    if ([ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role)) {
      await dispatch(getAssignedResourcesThunk(catalogueId));
      dispatch(getStaticResourcesThunk({}));
      setSelectedResources([]);
      setChangeCatalogueDialogueOpen(false);
    } else {
      await dispatch(changeCatalogueThunk(catalogueId, resourceIds));
      dispatch(getStaticResourcesThunk({}));
      setSelectedResources([]);
      setChangeCatalogueDialogueOpen(false);
    }
  };
  const handleCustomTabChange = (id: string) => {
    if ([ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role)) {
      dispatch(getAssignedResourcesThunk({ catalogueId: id, favourites: resourcesData?.favourites }));
      setSelectedResources([]);
    } else {
      dispatch(getStaticResourcesThunk({ catalogueId: id, favourites: resourcesData?.favourites, resource_type: resourcesData?.resource_type }));
      setSelectedResources([]);
    }
  };
  const customTabComponents: TabComponent[] = [
    {
      component: ResourcesTableContainer,
      label: 'All',
      id: '',
      style: {
        background: true,
        spacing: { px: 4, py: 0 }
      }
    },

  ].concat(
    cataloguesSearchResult
      ? cataloguesSearchResult.map((catalogue: any) => {
        return {
          id: catalogue.id,
          component: ResourcesTableContainer,
          label: catalogue.title,
          style: {
            background: true,
            spacing: { px: 4, py: 0 }
          }
        };
      })
      : []
  );
  const ApprovedResources = (
    <TabBar
      style={{
        tabBackground: true,
        spacing: { px: 4, py: 0 },
        maxWidth: '100%'
      }}
      tabComponents={customTabComponents}
      onTabChange={handleCustomTabChange}
    />
  );

  const handleDeleteResourceConfirmationDialogOpen = async () => {
    if (loggedInUser && [ROLES.ADMIN, ROLES.SUPER_ADMIN].includes(loggedInUser.role)) {
      const data = { resource_ids: selectedResources }
      await dispatch(getResourceStatusCount(data));
      setDeleteFormsConfirmationDialogOpen(true);
    } else {
      let checkCustomId: any = [];
      for (let i = 0; i < selectedFormsDetails.length; i++) {
        if (selectedFormsDetails[i]?.resourceType !== 'CUSTOM') {
          checkCustomId.push(selectedFormsDetails[i]);
        }
      }
      if (checkCustomId.length > 0) {
        setIsBaseDelete(true);
      } else if (checkCustomId.length === 0) {
        const data = { resource_ids: selectedResources }
        await dispatch(getResourceStatusCount(data));
        setDeleteFormsConfirmationDialogOpen(true);
      }
    }
  };
  const handleRemoveResourceConfirmationDialogOpen = () => {
    handleRemoveFevResource();
  };

  const handleDeleteResourceConfirmationDialogClose = () => {
    setDeleteFormsConfirmationDialogOpen(false);
  };

  const handelSetFormDetails = (selectedForms: any) => {
    let checkCustomId: any = [];
    for (let i = 0; i < selectedForms.length; i++) {
      const res = resourcesData.data.filter((id: any) => id.id === selectedForms[i]);
      res && res.length > 0 && checkCustomId.push({ id: res[0]?.id, title: res[0]?.title, resourceType: res[0]?.organization_id ? 'CUSTOM' : 'BASE' });
    }
    setSelectedFormsDetails(checkCustomId);
  }

  const handleDeleteResource = async (library?: boolean) => {
    setDeleteLoading(true);
    const delete_ids = { resourceIds: selectedResources };
    await dispatch(deleteResourceThunk(delete_ids));
    setSelectedResources([]);
    setDeleteLoading(false);
    setDeleteFormsConfirmationDialogOpen(false);
  };

  const AddDuplicate = async () => {
    setDeleteLoading(true);
    await dispatch(duplicateResourcesThunk(selectedResources));
    setSelectedResources([]);
    setDeleteLoading(false);
    setAddDuplicateOpen(false);
  }

  const handleRemoveFevResource = async (library?: boolean) => {
    setDeleteLoading(true);

    dispatch(removeFavoriteResourceThunk(selectedResources));

    setSelectedResources([]);
    setDeleteLoading(false);
  };

  const assignResourceToPatient = async () => {
    setOpenPatient(true);
    const arr: any = [];
    for (let i = 0; i < selectedResources.length; i++) {
      const check = resourcesData && resourcesData.data.filter((item: any) => item.id === selectedResources[i]);
      check && check.length > 0 && arr.push(check[0]);
    }
    setResourceInfo(arr);
    dispatch(assignmentInfoAction(arr));
  }
  const assignResourceToPatientClose = () => {
    setOpenPatient(false);
    setSelectedResources([]);
  }

  const downloadResource = () => {

  }

  return (
    <>
      {searchTagsDialogOpen && (
        <SearchTagsDialog
          open={searchTagsDialogOpen}
          handleClose={handleSearchTagsDialogClose}
          tagSearchType={tagSearchType}
          handleTagSelect={handleTagSelect}
          selectedTags={selectedTags}
        />
      )}
      {changeCatalogueDialogueOpen &&
        <ChangeCatalogueDialogue
          selectedResources={selectedResources}
          handleChangeCatalogue={handleChangeCatalogue}
          open={changeCatalogueDialogueOpen}
          handleClose={handleChangeCatalogueDialogueClose}
        />}
      {newCatalogueDialogOpen && <NewCatalogueDialog
        open={newCatalogueDialogOpen}
        handleClose={handleNewCatalogueDialogClose}
      />}

      {newStaticReourceDialogOpen ?
        <Paper>
          <NewResourceDialog
            handleClose={handleNewStaticResourceDialogClose} resourceFiles={resourceFiles} isEdit={isEdit} editInfo={editInfo}
          />
        </Paper>
        : <>
          {viewCollection ? <Paper>
            <ViewRecource handleChangeFavorite={handleChangeFavorite} handleClose={viewResourceClose} editResource={handleEditResourceOpen} editInfo={editInfo} />
          </Paper>
            :
            <Paper>
              <Box display="flex" flexDirection="column">
                {loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role) ?
                  <CardHeader
                    tabComponents={[
                      {
                        component: ApprovedResources,
                        label: 'Favorites',
                        primaryButton: {
                          text: 'Add',
                          handleClick: handleNewStaticResourceButton
                        },
                        selected: {
                          primaryButton: {
                            text: 'Remove',
                            handleClick: handleRemoveResourceConfirmationDialogOpen
                          },
                          selectedContents: selectedResources
                        },
                        style: {
                          background: true,
                          spacing: { py: 3, px: 4, pb: 0 }
                        },
                        title: 'Favorites Resources',
                        handleSearch: handleSearch,
                        onTabChange: onFavoritesTabChange
                      },
                      {
                        component: ApprovedResources,
                        label: 'Resources',
                        primaryButton: {
                          text: 'Add',
                          handleClick: handleNewStaticResourceButton
                        },
                        selected: {
                          primaryButton: {
                            text: 'Make Copy',
                            handleClick: handleAddToOrgOpen
                          },
                          selectedContents: selectedResources,
                          handleSelectedDelete: handleDeleteResourceConfirmationDialogOpen
                        },
                        style: {
                          background: true,
                          spacing: { pb: 0, pt: 3, px: 4 }
                        },
                        title: 'All Resources',
                        handleSearch: handleSearch,
                        onTabChange: onResourcesTabChange,
                      }
                    ]}
                  /> : loggedInUser && [ROLES.CLINICIAN].includes(loggedInUser.role) ?
                    <CardHeader
                      tabComponents={[
                        {
                          component: ApprovedResources,
                          label: 'Favorites',
                          selected: {
                            primaryButton: {
                              text: 'Remove',
                              handleClick: handleRemoveResourceConfirmationDialogOpen
                            },
                            secondaryButton: {
                              text: 'Assign to Patient',
                              handleClick: assignResourceToPatient
                            },
                            selectedContents: selectedResources
                          },
                          style: {
                            background: true,
                            spacing: { py: 3, px: 4, pb: 0 }
                          },
                          title: 'Favorites Resources',
                          handleSearch: handleSearch,
                          onTabChange: onFavoritesTabChange
                        },
                        {
                          component: ApprovedResources,
                          label: 'Resources',
                          selected: {
                            primaryButton: {
                              text: 'Assign to Patient',
                              handleClick: assignResourceToPatient
                            },
                            selectedContents: selectedResources
                          },
                          style: {
                            background: true,
                            spacing: { pb: 0, pt: 3, px: 4 }
                          },
                          title: 'All Resources',
                          handleSearch: handleSearch,
                          onTabChange: onResourcesTabChange,
                        }
                      ]}
                    /> : [ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role) ?
                      <CardHeader
                        tabComponents={[
                          {
                            component: ResourcesTableContainer,
                            label: 'Favorites',
                            selected: {
                              primaryButton: {
                                text: 'Remove',
                                handleClick: handleRemoveResourceConfirmationDialogOpen
                              },
                              selectedContents: selectedResources
                            },
                            style: {
                              background: true,
                              spacing: { py: 3, px: 4, pb: 0 }
                            },
                            title: 'Favorites Resources',
                            handleSearch: handleSearch,
                            onTabChange: onFavoritesTabChange
                          },
                          {
                            component: ResourcesTableContainer,
                            label: 'Resources',
                            selected: {
                              primaryButton: {
                                text: 'Download',
                                handleClick: downloadResource
                              },
                              selectedContents: selectedResources
                            },
                            style: {
                              background: true,
                              spacing: { pb: 0, pt: 3, px: 4 }
                            },
                            title: 'All Resources',
                            handleSearch: handleSearch,
                            onTabChange: onResourcesTabChange,
                          }
                        ]} /> :
                      <CardHeader
                        tabComponents={[
                          {
                            component: ApprovedResources,
                            label: 'Favorites',
                            primaryButton: {
                              text: 'Add',
                              handleClick: handleNewStaticResourceButton
                            },
                            selected: {
                              primaryButton: {
                                text: 'Remove',
                                handleClick: handleRemoveResourceConfirmationDialogOpen
                              },
                              selectedContents: selectedResources
                            },
                            style: {
                              background: true,
                              spacing: { py: 3, px: 4, pb: 0 }
                            },
                            title: 'Favorites Resources',
                            handleSearch: handleSearch,
                            onTabChange: onFavoritesTabChange
                          },
                          {
                            component: ApprovedResources,
                            label: 'Resources',
                            primaryButton: {
                              text: 'Add',
                              handleClick: handleNewStaticResourceButton
                            },
                            selected: {
                              deleteButton: {
                                text: 'Delete',
                                handleClick: handleDeleteResourceConfirmationDialogOpen
                              },
                              selectedContents: selectedResources
                            },
                            style: {
                              background: true,
                              spacing: { pb: 0, pt: 3, px: 4 }
                            },
                            title: 'All Resources',
                            handleSearch: handleSearch,
                            onTabChange: onResourcesTabChange,
                          }
                        ]}
                      />
                }
              </Box>
            </Paper>
          }
        </>
      }
      {deleteFormsConfirmationDialogOpen &&
        <ConfirmationDelete
          loading={deleteLoading}
          data={deleteData}
          handleClose={handleDeleteResourceConfirmationDialogClose}
          handleConfirmClick={() => handleDeleteResource()}
        />
      }
      {addDuplicate &&
        <Dialog open={addDuplicate}>
          <Box display='flex' justifyContent='center' style={{ borderRadius: '0.625rem 0.625rem 0 0', backgroundColor: '#f9fafb' }}
            alignItems="center"
            px={3}
            py={2.5}
          >
            <Typography variant="h3">Make Copy</Typography>
          </Box>
          <Box style={{ width: '500px' }}>
            <Box pb={3} pt={2} px={4} display="flex" justifyContent="space-between">
              <Typography>Are you sure you would like to make a copy of the item(s) for your organization?</Typography>
            </Box>
          </Box>
          <DialogActions style={{ justifyContent: 'space-between', backgroundColor: '#f9fafb', padding: '15px 20px 5px', borderRadius: '0 0px 10px 10px' }}>
            <LoadingButton style={{ border: '1px solid rgba(0, 0, 0, 0.15)', width: '120px', marginBottom: '10px', color: '#0060B2' }}
              variant="contained"
              onClick={handleAddToOrgClose}
            >
              Cancel
            </LoadingButton>
            <LoadingButton color="primary" disabled={deleteLoading}
              variant="contained"
              style={{ marginBottom: '10px', boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)', width: '120px' }}
              onClick={(e) => AddDuplicate()}
            >
              Confirm
            </LoadingButton>
          </DialogActions>
        </Dialog>
      }
      {isCustom &&
        <Dialog open={isCustom}>
          <Box style={{ width: '400px', textAlign: 'center' }}>
            <Box pb={3} pt={2} px={4} display="flex">
              <Typography variant="h3">Custom resource already exists in the organization, it cannot be added again</Typography>
            </Box>
          </Box>
          <DialogActions style={{ justifyContent: 'center' }}>
            <LoadingButton style={{ border: '1px solid rgba(0, 0, 0, 0.15)', width: '120px', marginBottom: '10px' }}
              variant="contained"
              onClick={() => setIsCustom(false)}
            >
              Close
            </LoadingButton>
          </DialogActions>
        </Dialog>
      }
      {isBaseDelete &&
        <Dialog open={isBaseDelete}>
          <Box style={{ width: '400px', textAlign: 'center' }}>
            <Box pb={3} pt={2} px={4} display="flex">
              <Typography variant="h3">You have selected base resources, it cannot be deleted</Typography>
            </Box>
          </Box>
          <DialogActions style={{ justifyContent: 'center' }}>
            <LoadingButton style={{ border: '1px solid rgba(0, 0, 0, 0.15)', width: '120px', marginBottom: '10px' }}
              variant="contained"
              onClick={() => setIsBaseDelete(false)}
            >
              Close
            </LoadingButton>
          </DialogActions>
        </Dialog>
      }
      {openPatient && <Patients handelClose={assignResourceToPatientClose} />}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  topBox: {
    borderRadius: '0.625rem 0.625rem 0 0'
  },
  bottomBox: {
    borderRadius: '0 0 0.625rem 0.625rem'
  },
  primaryButton: {
    boxShadow:
      '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)'
  }
}));
