import { Box, makeStyles, Theme, Typography, CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';
import { HeadCell, LastSorted, Pagination, RowCell, Table } from '../../../../components/Table';
import { getSharedUsersThunk, getFormInstanceMetricDetailsThunk, getFormInstanceResponseThunk } from '../../../../redux/thunk/thunks';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/redux-store';
import moment from 'moment';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { ShareToUser } from '../../../Forms/components/AssignSharedUsers';
import { getFormResponseDetailsAction } from '../../../../redux/data/data-actions';

interface TableProps {
    tableData: any | null;
    loadData: (getBundlesProps: any) => void;
    viewComponentOpen: (open: any) => void;
    viewCompDialogueOpen: boolean | false;
    tab: string;
}

export const ResultTable = ({
    tableData,
    loadData,
    viewComponentOpen,
    viewCompDialogueOpen,
    tab
}: TableProps) => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch()
    const organization_id: any = sessionStorage.getItem('pat_org_id');
    const [resultInfo, setDataInfo] = useState<any>();
    const [lastSorted, setLastSorted] = useState<LastSorted>({
        column: 'item_name',
        order: 'desc'
    });
    const [openForm, setOpenForm] = useState(false);
    const [openSharing, setOpenSharing] = useState(false);
    const [data, setData] = useState<any>(null);
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const role: any = sessionStorage.getItem('role');
    const { path } = useRouteMatch();
    const split = path.split('/');
    const patient_role_id: any = split && split.length > 1 && split[2];
    const shared_role_id: any = split && split.length > 3 && split[4];

    const loading: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.getDataLoading);

    const headCells: HeadCell[] = [
        {
            id: 'item_name',
            label: 'NAME'
        },
        {
            id: 'completed_on',
            label: 'COMPLETED ON',
        },
        {
            id: 'completed_by_name',
            label: 'ANSWERED BY',
        },
        {
            id: 'item_type',
            label: 'ITEM TYPE',
        },
        {
            id: 'action',
            label: 'SHARING',
            isSortDisabled: true
        }
    ];

    const headCells1: HeadCell[] = [
        {
            id: 'item_name',
            label: 'NAME'
        },
        {
            id: 'status',
            label: 'STATUS',
        },
        {
            id: 'item_type',
            label: 'ITEM TYPE',
        },
        {
            id: 'action',
            label: 'SHARING',
            isSortDisabled: true
        }
    ];

    const handelShareUser = async (result: any) => {
        await dispatch(getSharedUsersThunk({ giveOnlyActiveUsers: true, user_role_id: role ? patient_role_id : null, organization_id: organization_id ? organization_id : null }));
        const filter = result?.sharedUsers?.filter((item: any) => item.user_role_id === (patient_role_id))
        result.role = 'PATIENT';
        result.patientFormAccess = filter?.length > 0 && filter[0]?.access_type;
        filter?.length > 0 ? (result.checked = true) : (result.checked = null);
        result.patient_id = patient_role_id;
        setData(result);
        setOpenSharing(true);
    }

    const handleClose = () => {
        setOpenSharing(false);
        setData(null);
        setOpenForm(false);
        dispatch(getFormResponseDetailsAction(null));
    }

    const rowCells = tableData?.data?.map((item: any) => {
        const nameLink = (<Typography color="primary" variant="h5" style={{ cursor: 'pointer' }} className={classes.itemName} onClick={(e) => { handleViewComponentDialogueOpen(item); }}>
            {item.item_name}
        </Typography>
        );
        const sharedUser = (
            <Typography color="primary" variant="h5" style={{ cursor: 'pointer' }} onClick={() => handelShareUser(item)}>
                {(item?.sharedUsers?.length || 0) + ' Users'}
            </Typography>
        );
        const status = (
            <Typography variant="h5">
                {item?.status === 'COMPLETE' ? 'COMPLETED' : item?.status.replace(/_/g, " ")}
            </Typography>
        );
        const Completed_On = (
            <Typography variant="h5">
                {item?.completed_on ? moment(item.completed_on).format('DD MMMM YYYY') : '-'}
            </Typography>
        );

        let rowCell: RowCell = {};
        rowCell = tab === 'Outstanding Items' ? {
            item_name: { component: nameLink, value: (item.item_name) },
            status: {
                component: status,
                value: '-'
            },
            item_type: { value: item.item_type },
            action: {
                component: sharedUser,
                value: item?.sharedUsers?.length + ' Users'
            }
        } :
            {
                item_name: { component: nameLink, value: (item.item_name) },
                completed_on: {
                    component: Completed_On,
                    value: '-'
                },
                completed_by_name: {
                    value: item.completed_by_name,
                },
                item_type: { value: item.item_type },
                action: {
                    component: sharedUser,
                    value: item?.sharedUsers?.length + ' Users'
                }
            }
        return Object.assign(rowCell, { content: item });
    });

    const handleNextButton = async () => {
        if (
            tableData &&
            tableData?.page >= 0 &&
            tableData?.page < tableData?.totalCount / tableData?.pageSize - 1
        ) {
            await loadData({
                search: tableData?.search,
                page: tableData?.page + 1,
                pageSize: tableData?.pageSize,
                sort: tableData?.sort,
                order: tableData?.order,
                favourites: tableData?.favourites,
                filters: tableData?.filters,
                status: tab === 'Outstanding Items' ? 'IN_PROGRESS' : 'COMPLETE',
            });
        }
    };
    const handlePrevButton = async () => {
        if (tableData && tableData?.page > 0) {
            await loadData({
                search: tableData?.search,
                page: tableData?.page - 1,
                pageSize: tableData?.pageSize,
                sort: tableData?.sort,
                order: tableData?.order,
                favourites: tableData?.favourites,
                filters: tableData?.filters,
                status: tab === 'Outstanding Items' ? 'IN_PROGRESS' : 'COMPLETE',
            });
        }
    };

    const handlePageSize = (
        e: React.ChangeEvent<{
            name?: string;
            value: unknown;
        }>
    ) => {
        const pageSize = e.target.value as number;

        if (tableData && pageSize > 0) {
            loadData({
                page: 0,
                pageSize: pageSize,
                search: tableData?.search,
                sort: tableData?.sort,
                order: tableData?.order,
                favourites: tableData?.favourites,
                filters: tableData?.filters,
                status: tab === 'Outstanding Items' ? 'IN_PROGRESS' : 'COMPLETE',
            });
        }
    };

    const pagination: Pagination | undefined = tableData
        ? {
            contentName: 'Recurring Forms',
            page: tableData?.page,
            pageSize: tableData?.pageSize,
            total: tableData?.totalCount,
            handleNextButton: handleNextButton,
            handlePageSize: handlePageSize,
            handlePrevButton: handlePrevButton
        }
        : undefined;

    const handleSort = (sort: string) => {
        const nextDirection = lastSorted.order === 'desc' ? 'asc' : 'desc';

        loadData({
            order: sort === lastSorted.column ? nextDirection : 'desc',
            page: 0,
            pageSize: tableData?.pageSize,
            search: tableData?.search,
            sort,
            favourites: tableData?.favourites,
            filters: tableData?.filters,
            status: tab === 'Outstanding Items' ? 'IN_PROGRESS' : 'COMPLETE',
        });

        setLastSorted(
            sort === lastSorted.column
                ? { column: sort, order: nextDirection }
                : { column: sort, order: 'desc' }
        );
    };

    const handleViewComponentDialogueOpen = async (info: any) => {
        info?.item_type === 'FORM' && dispatch(getFormInstanceMetricDetailsThunk(info?.item_id, role === 'SHARED_USER' ? shared_role_id : patient_role_id));
        viewComponentOpen(true);
        sessionStorage.setItem('res_item', JSON.stringify(info));
        setDataInfo(info);
        if (role === 'SHARED_USER') {
            history.push(`/view/${patient_role_id}/${info?.item_id}/${shared_role_id}/${info?.item_type}`);
        } else {
            history.push(`/view/${patient_role_id}/${info?.item_id}/${info?.item_type}`);
        }
    };

    return (
        loading ? <Box display='flex' justifyContent='center'><CircularProgress /></Box> : <>
            <Table
                data={tableData?.data}
                headCells={tab === 'Outstanding Items' ? headCells1 : headCells}
                loading={!tableData?.data}
                pagination={pagination}
                rowCells={rowCells}
                sort={{
                    lastSorted: lastSorted,
                    handleSort: handleSort
                }}
            />
            {openSharing && <ShareToUser handleClose={handleClose} data={data} />}
        </>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    nameLink: {
        textDecoration: 'none'
    },
    itemName: {
        display: 'flex'
    },
    launchIcon: {
        fontSize: '2rem',
        cursor: 'pointer'
    },
    InfoIcon: {
        fontSize: 16,
        marginLeft: 6
    },
    color: {
        color: '#0060B2'
    },
    tableBox: {
        padding: '2px 8px',
        marginRight: '5px',
        minWidth: '80px',
    },
    actionTypography: {
        padding: '10px',
        cursor: 'pointer',
        fontWeight: 'bold'
    },
}));
