import { CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface LoadingOrErrorProps {
  loadingMsg: string;
  loading: boolean;
  errorMsg: null | string;
  noElevation?: boolean;
}
export const LoadingOrError: React.FC<LoadingOrErrorProps> = ({
  loadingMsg,
  loading,
  errorMsg,
  noElevation
}) => {
  const classes = useStyles();
  if (errorMsg !== null) {
    return (
      <div className={noElevation ? classes.noShadow : classes.container}>
        <Typography className={classes.errorTitle} variant="subtitle1" id="loading-modal-title">
          Error:
        </Typography>
        <Typography variant="subtitle2" id="loading-modal-title">
          {'This form does not contain any fields.'}
        </Typography>
      </div>
    );
  } else {
    return (
      <div className={noElevation ? classes.noShadow : classes.container}>
        <CircularProgress size={30} className={classes.loadingIcon} />
        <Typography variant="subtitle1" id="loading-modal-title">
          {loadingMsg}
        </Typography>
      </div>
    );
  }
};

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2)
  },
  noShadow: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2)
  },
  loadingIcon: {
    marginRight: '1rem'
  },
  errorTitle: {
    color: theme.palette.error.main,
    marginRight: '.6rem'
  }
}));
