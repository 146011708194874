import React, { useState } from 'react';
import { ButtonBase, CircularProgress, Typography } from '@material-ui/core';
import {
  CalculationResult,
  FormInstanceResultRow,
  FormInstanceSearchResults,
  UuidType
} from '../../../../shared/domain';
import { HeadCell, LastSorted, Pagination, RowCell, Table } from '../../../../components/Table';
import { getDateDiff, DateDiff } from '../../../../shared/utils';
import { useHistory } from 'react-router-dom';
import { FORM_TAB } from './PatientTasks';
import { useDispatch, useSelector } from 'react-redux';
import { sendIncompleteFormReminderThunk } from '../../../../redux/thunk/thunks';
import { RootState } from '../../../../redux/redux-store';
interface PatientFormsTableProps {
  formsData?: FormInstanceSearchResults | null;
  inPerson?: boolean;
  handleDelete: (formInstanceId: FormInstanceResultRow) => void;
  handlePageSize: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
  handleNextButton: () => Promise<void>;
  handlePrevButton: () => Promise<void>;
  handleSort: (sort: string, order: string) => void;
  handleFormResponsesDialogue: (form: FormInstanceResultRow) => void;
  selectedTab: FORM_TAB;
}

export const PatientFormsTable = ({
  formsData,
  inPerson,
  handleDelete,
  handlePageSize,
  handleNextButton,
  handlePrevButton,
  handleSort,
  handleFormResponsesDialogue,
  selectedTab
}: PatientFormsTableProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const sendReminderFormInstanceId = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.sendReminderFormInstanceId
  );
  const [lastSorted, setLastSorted] = useState<LastSorted>({
    column: 'created_at',
    order: 'desc'
  });

  const getDueIn = (dueDate: Date) => {
    let dueIn: string = '';
    const result = getDateDiff(new Date(), new Date(dueDate));

    if (result !== -1) {
      const dateDiff = result as DateDiff;
      Object.keys(dateDiff).some((key) => {
        if (dateDiff[key]) {
          dueIn = `${dateDiff[key]} ${key}`;
          return true;
        }
      });
    } else {
      dueIn = 'Due Date Passed';
    }
    return dueIn;
  };

  const headCells: HeadCell[] = inPerson
    ? [
        {
          id: 'title',
          label: 'NAME'
        },
        {
          id: 'score',
          label: 'SCORE',
          isSortDisabled: true
        },
        {
          id: 'action',
          label: 'ACTION',
          isSortDisabled: true
        },
        {
          id: 'delete',
          label: '',
          isSortDisabled: true
        }
      ]
    : [
        {
          id: 'title',
          label: 'NAME'
        },
        {
          id: 'due_date',
          label: 'DUE IN'
        },
        {
          id: 'score',
          label: 'SCORE',
          isSortDisabled: true
        },
        {
          id: 'action',
          label: 'ACTION',
          isSortDisabled: true
        },
        {
          id: 'delete',
          label: '',
          isSortDisabled: true
        }
      ];

  interface ActionButtonProps {
    selectedTab: FORM_TAB;
    formInstanceId: UuidType;
  }

  const ActionButton: React.FC<ActionButtonProps> = ({ selectedTab, formInstanceId }) => {
    switch (selectedTab) {
      case FORM_TAB.ONE_TIME: {
        return (
          <ButtonBase
            aria-label="open form"
            onClick={() => {
              dispatch(sendIncompleteFormReminderThunk(formInstanceId));
            }}
            disabled={sendReminderFormInstanceId !== null}
          >
            {formInstanceId === sendReminderFormInstanceId && (
              <CircularProgress size={16} style={{ marginRight: 6 }} />
            )}
            <Typography color="primary" variant="h5">
              Send Reminder
            </Typography>
          </ButtonBase>
        );
      }
      case FORM_TAB.IN_PERSON: {
        return (
          <ButtonBase
            aria-label="open form"
            onClick={() => {
              history.push(`/form/${formInstanceId}`);
            }}
          >
            <Typography color="primary" variant="h5">
              Open Form
            </Typography>
          </ButtonBase>
        );
      }
      default: {
        return null;
      }
    }
  };

  const rowCells = formsData?.formInstanceResults.map((formInstance: FormInstanceResultRow) => {
    const actionCell = formInstance.completed_at
      ? {
          component: (
            <ButtonBase
              onClick={() => handleFormResponsesDialogue(formInstance)}
              aria-label="view responses"
            >
              <Typography color="primary" variant="h5">
                View Responses
              </Typography>
            </ButtonBase>
          )
        }
      : {
          component: <ActionButton selectedTab={selectedTab} formInstanceId={formInstance.id} />
        };

    const rowCell: RowCell = {
      title: {
        value: formInstance.title
      },
      due_date: {
        value: formInstance.due_date ? getDueIn(formInstance.due_date) : 'No due date'
      },

      score: {
        value: formInstance.completed_at
          ? formInstance.calculation_results
              ?.map(
                (result: CalculationResult) =>
                  result.metric_code.substring(0, 3) + ': ' + result.value
              )
              .join(', ') ?? 'No score'
          : 'Not completed'
      },

      action: actionCell,
      delete: {
        component: (
          <ButtonBase onClick={() => handleDelete(formInstance)} aria-label="delete form">
            <Typography color="error" variant="h5">
              Delete
            </Typography>
          </ButtonBase>
        )
      }
    };

    return Object.assign(rowCell, { content: formInstance });
  });

  const pagination: Pagination = {
    contentName: 'Form',
    page: formsData?.page ?? 0,
    pageSize: formsData?.pageSize ?? 0,
    total: formsData?.total ?? 0,
    handlePageSize,
    handleNextButton,
    handlePrevButton
  };

  const handleTableSort = (sort: string) => {
    const nextDirection = lastSorted.order === 'desc' ? 'asc' : 'desc';
    handleSort(sort, sort === lastSorted.column ? nextDirection : 'desc');
    setLastSorted(
      sort === lastSorted.column
        ? { column: sort, order: nextDirection }
        : { column: sort, order: 'desc' }
    );
  };

  return (
    <Table
      data={formsData?.formInstanceResults}
      headCells={headCells}
      rowCells={rowCells}
      pagination={pagination}
      sort={{
        lastSorted: lastSorted,
        handleSort: handleTableSort
      }}
    />
  );
};
