import { createStyles, Tab, Tabs, Theme, withStyles } from '@material-ui/core';
import React from 'react';
import { theme } from '../styling/ThemeConfig';

export const NavTabs = withStyles({
  root: {
    marginBottom: '-1px'
  },
  indicator: {
    backgroundColor: theme.palette.primary.main
  }
})(Tabs);

interface NavTabProps {
  label: any;
  value?: string;
}
export const NavTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'textTransform': 'none',
      'minWidth': '4rem',
      'color': theme.palette.text.secondary,
      'fontSize': '.9rem',
      'marginRight': theme.spacing(2),
      '&:hover': {
        color: theme.palette.primary.light,
        opacity: 1
      },
      '&$selected': {
        color: theme.palette.primary.main
      },
      '&:focus': {
        color: theme.palette.primary.main
      }
    },
    selected: {}
  })
)((props: NavTabProps) => <Tab disableRipple {...props} />);
