import { Box, Divider, LinearProgress, Typography, Tooltip } from '@material-ui/core';
import { makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import ArrowRightAltIcon from '@material-ui/icons/ArrowForward';
import ArrowLeft from '@material-ui/icons/ArrowBack';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '../../components/LoadingButton';
import { LoadingOrError } from '../../components/LoadingOrError';
import { RootState } from '../../redux/redux-store';
import { submitFieldResponseThunk, getPreviousQuestionThunk, backFetchFormToDisplayByIdThunk } from '../../redux/thunk/thunks';
import { FormField, UuidType } from '../../shared/domain';
import { FIELD_TYPES, ROLES } from '../../shared/globals';
import { DropdownQuestion } from './components/DropdownQuestion';
import { InfoTextBlock } from './components/InfoTextBlock';
import { ResourceBlock } from '../formBuilder/components/fieldBlocks/ResourceBlock';
import { LongTextQuestion } from './components/LongTextQuestion';
import { MultipleChoiceQuestion } from './components/MultipleChoiceQuestion';
import { ScaleQuestion } from './components/ScaleQuestion';
import { ShortTextQuestion } from './components/ShortTextQuestion';
import { SingleChoiceQuestion } from './components/SingleChoiceQuestion';

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    fontSize: '.8rem',
    border: '1px solid #dadde9'
  }
}))(Tooltip);

interface QuestionBodyProps {
  themeColor: string;
  count: any,
  setCount: () => void;
}

export const QuestionBody: React.FC<QuestionBodyProps> = ({ themeColor, count, setCount }) => {
  const props = { customColor: themeColor };
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const newIndex: any = sessionStorage.getItem('index');
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);
  const displayFormFieldPayload: any = useSelector((state: RootState) => state.DATA_REDUCER.displayFormFieldPayload);
  const [index, setIndex] = useState([ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role) && newIndex ? parseInt(newIndex) : 1);
  const [answer, setAnswer] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [response, setResponse] = useState<any>();

  const getNextQuestionLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.getNextQuestionLoading
  );
  const getNextQuestionError = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayErrors.getNextQuestionError
  );
  const displayFormInstance: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.displayFormInstance
  );
  const submitFieldResponseLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.submitFieldResponseLoading
  );

  //Input Handlers for different field types
  const handleTextInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setResponse({ response_text: event.target.value as string });
    setIsUpdate(!isUpdate);
  };

  //this handler is specifically for handling user text input for
  //multiple choice, single choice, and dropdown fields, that allow a user defined option for "other"
  const handleCustomTextInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setResponse({ ...response, response_text: event.target.value as string });
    setIsUpdate(!isUpdate);
  };

  const handleSingleOptionInput = (event: React.ChangeEvent<{ value: unknown }>) => {
    setResponse({ response_option: event.target.value as UuidType });
    setIsUpdate(!isUpdate);
  };

  const handleSingleChangeInput = (value: string) => {
    setResponse({ response_option: value });
    setIsUpdate(!isUpdate);
  }

  const handleMultipleOptionInput = (event: React.ChangeEvent<{ value: UuidType }>) => {
    const value = event.target.value;
    setIsUpdate(!isUpdate);
    if (response?.response_multiselect) {
      const newArr = response.response_multiselect;
      if (newArr.includes(value)) {
        //if the id is already in the array remove it
        let filteredArr = newArr.filter((id: any) => id !== value);
        //if all options are removed from the array set as undefined
        if (filteredArr.length === 0) {
          setResponse(undefined);
        } else {
          setResponse({ ...response, response_multiselect: filteredArr });
        }
      } else {
        setResponse({ ...response, response_multiselect: [...newArr, value] });
      }
    } else {
      //if response multiselect is undefined set the selected option id
      setResponse({ ...response, response_multiselect: [value] });
    }
  };

  const handleMultipleChangeInput = (value: string) => {
    setIsUpdate(!isUpdate);
    if (response?.response_multiselect) {
      const newArr = response.response_multiselect;
      if (newArr.includes(value)) {
        //if the id is already in the array remove it
        let filteredArr = newArr.filter((id: any) => id !== value);
        //if all options are removed from the array set as undefined
        if (filteredArr.length === 0) {
          setResponse(undefined);
        } else {
          setResponse({ ...response, response_multiselect: filteredArr });
        }
      } else {
        setResponse({ ...response, response_multiselect: [...newArr, value] });
      }
    } else {
      //if response multiselect is undefined set the selected option id
      setResponse({ ...response, response_multiselect: [value] });
    }
  };

  const handleScaleInput = (event: React.ChangeEvent<{ value: string }>) => {
    const val = parseInt(event.target.value, 10);
    setResponse({ response_scale: val as number });
    setIsUpdate(!isUpdate);
  };

  const handelPreviousQue = async () => {
    const checkQue = displayFormFieldPayload?.queNo;
    if (((checkQue - 1) === 0) || ((checkQue - 1) === -1)) {
      await dispatch(backFetchFormToDisplayByIdThunk(displayFormInstance && displayFormInstance.form_id, displayFormInstance && displayFormInstance.id))
    } else {
      var res_data: any = await dispatch(getPreviousQuestionThunk(displayFormInstance && displayFormInstance.id, checkQue - 1));
    }
    setIndex(checkQue - 1);
    if (res_data?.nextQuestion?.field_type === FIELD_TYPES.SINGLE_CHOICE) {
      setResponse({ response_option: res_data?.form_response?.response_option });
    } else if (res_data?.nextQuestion?.field_type === FIELD_TYPES.LONG_TEXT) {
      setResponse({ response_text: res_data?.form_response?.response_text });
    } else if (res_data?.nextQuestion?.field_type === FIELD_TYPES.SHORT_TEXT) {
      setResponse({ response_text: res_data?.form_response?.response_text });
    } else if (res_data?.nextQuestion?.field_type === FIELD_TYPES.DROPDOWN) {
      setResponse({ response_option: res_data?.form_response?.response_option });
    } else if (res_data?.nextQuestion?.field_type === FIELD_TYPES.MULTIPLE_CHOICE) {
      setResponse({ response_multiselect: res_data?.form_response?.response_multiselect });
    } else if (res_data?.nextQuestion?.field_type === FIELD_TYPES.SCALE) {
      setResponse({ response_scale: res_data?.form_response?.response_scale });
    }
    setAnswer(false);
    setCount();
  }

  // const setPreviousAnswer = () => {
  //   if (displayFormFieldPayload && displayFormFieldPayload.form_response.id && response) {
  //     setAnswer(true);
  //     if (displayFormFieldPayload?.nextQuestion?.field_type === FIELD_TYPES.SINGLE_CHOICE) {
  //       setResponse({ response_option: displayFormFieldPayload?.form_response?.response_option });
  //     } else if (displayFormFieldPayload?.nextQuestion?.field_type === FIELD_TYPES.LONG_TEXT) {
  //       setResponse({ response_text: displayFormFieldPayload?.form_response?.response_text });
  //     } else if (displayFormFieldPayload?.nextQuestion?.field_type === FIELD_TYPES.SHORT_TEXT) {
  //       setResponse({ response_text: displayFormFieldPayload?.form_response?.response_text });
  //     } else if (displayFormFieldPayload?.nextQuestion?.field_type === FIELD_TYPES.DROPDOWN) {
  //       setResponse({ response_option: displayFormFieldPayload?.form_response?.response_option });
  //     } else if (displayFormFieldPayload?.nextQuestion?.field_type === FIELD_TYPES.MULTIPLE_CHOICE) {
  //       setResponse({ response_multiselect: displayFormFieldPayload?.form_response?.response_multiselect });
  //     } else if (displayFormFieldPayload?.nextQuestion?.field_type === FIELD_TYPES.SCALE) {
  //       setResponse({ response_scale: displayFormFieldPayload?.form_response?.response_scale });
  //     }
  //   }
  // }

  const submitAnswer = async () => {
    const checkQue = displayFormFieldPayload?.queNo;
    //if the field type requires no user input submit empty answer
    if (displayFormInstance && displayFormFieldPayload?.nextQuestion?.field_type === FIELD_TYPES.INFO_TEXT) {
      await dispatch(
        submitFieldResponseThunk(
          displayFormInstance.id,
          displayFormFieldPayload.nextQuestion.id,
          {},
          checkQue + 1
        )
      );
      setResponse(undefined);
      setIndex(checkQue + 1);
      setAnswer(false);
      setCount();
    }
    //if all required info exists submit answer

    if (displayFormFieldPayload?.nextQuestion && displayFormInstance) {
      // const responces = displayFormFieldPayload?.form_response.id ? displayFormFieldPayload?.form_response : response;
      const responces = response !== '' ? (response !== undefined ? response : displayFormFieldPayload?.form_response) : displayFormFieldPayload?.form_response;
      await dispatch(
        submitFieldResponseThunk(
          displayFormInstance.id,
          displayFormFieldPayload.nextQuestion.id,
          responces,
          checkQue + 1
        )
      );
      //clear response for next question
      setResponse(undefined);
      setIndex(checkQue + 1);
      setAnswer(false);
      setCount();
    }
  };

  const renderQuestionType = (formField: FormField | any, form_response: any | null) => {
    const checkQue = displayFormFieldPayload?.queNo;
    const structuredOptions = formField?.form_field_options?.sort(function (a: any, b: any) { return a.option_value - b.option_value });
    //if displayformfield is empty return null
    if (!formField) {
      return null;
    }
    //if formfield exists return the appropriate question UI
    switch (formField.field_type) {
      case FIELD_TYPES.SINGLE_CHOICE:
        return (
          <SingleChoiceQuestion
            color={themeColor}
            fieldTitle={formField.field_prompt}
            options={structuredOptions ?? []}
            handleInput={handleSingleOptionInput}
            handleChangeInput={handleSingleChangeInput}
            clinicianInfo={formField?.info_for_clinician}
            patientInfo={formField?.info_for_patient}
            responseValue={response?.response_option || form_response?.response_option}
            handleCustomTextInput={handleCustomTextInput}
            textInput={response?.response_text || form_response?.response_option}
            index={checkQue ? checkQue : index}
          />
        );
      case FIELD_TYPES.LONG_TEXT:
        return (
          <LongTextQuestion
            title={formField.field_prompt}
            handleInput={handleTextInput}
            clinicianInfo={formField?.info_for_clinician}
            patientInfo={formField?.info_for_patient}
            responseValue={response?.response_text || form_response?.response_text}
            index={checkQue ? checkQue : index}
          />
        );
      case FIELD_TYPES.SHORT_TEXT:
        return (
          <ShortTextQuestion
            title={formField.field_prompt}
            handleInput={handleTextInput}
            clinicianInfo={formField?.info_for_clinician}
            patientInfo={formField?.info_for_patient}
            responseValue={response?.response_text || form_response?.response_text}
            index={checkQue ? checkQue : index}
          />
        );
      case FIELD_TYPES.DROPDOWN:
        return (
          <DropdownQuestion
            options={structuredOptions ?? []}
            title={formField.field_prompt}
            handleInput={handleSingleOptionInput}
            clinicianInfo={formField?.info_for_clinician}
            patientInfo={formField?.info_for_patient}
            responseValue={response?.response_option || form_response?.response_option}
            handleCustomTextInput={handleCustomTextInput}
            textInput={response?.response_text || form_response?.response_text}
            index={checkQue ? checkQue : index}
          />
        );
      case FIELD_TYPES.MULTIPLE_CHOICE:
        return (
          <MultipleChoiceQuestion
            color={themeColor}
            fieldTitle={formField.field_prompt}
            options={structuredOptions ?? []}
            handleInput={handleMultipleOptionInput}
            handleMultipleChangeInput={handleMultipleChangeInput}
            clinicianInfo={formField?.info_for_clinician}
            patientInfo={formField?.info_for_patient}
            responseValue={response?.response_multiselect || form_response?.response_multiselect}
            handleCustomTextInput={handleCustomTextInput}
            textInput={response?.response_text || form_response?.response_text}
            index={checkQue ? checkQue : index}
          />
        );
      case FIELD_TYPES.SCALE:
        return (
          <ScaleQuestion
            title={formField.field_prompt}
            scaleMax={formField.option_scale_max ?? 0}
            color={themeColor}
            handleInput={handleScaleInput}
            clinicianInfo={formField?.info_for_clinician}
            patientInfo={formField?.info_for_patient}
            responseValue={form_response?.response_scale || response?.response_scale}
            index={checkQue ? checkQue : index}
          />
        );
      case FIELD_TYPES.RESOURCE:
        return (
          <ResourceBlock
            infoText={formField.field_prompt}
            files={formField?.resources || []}
            index={checkQue ? checkQue : index}
          />
        );
      case FIELD_TYPES.INFO_TEXT:
        return (
          <InfoTextBlock
            infoText={formField.field_prompt}
            clinicianInfo={formField?.info_for_clinician}
            patientInfo={formField?.info_for_patient}
            index={checkQue ? checkQue : index}
          />
        );
      default:
        break;
    }
  };

  //Progressbar can only give a vauge indication of how much progress the user has actually made
  // this is due to th fact that questions can be added or removed from the form depending on the users input
  const ProgressBar = () => {
    const progressValue = () => {
      if (displayFormFieldPayload?.completed && displayFormFieldPayload?.total) {
        return Math.round(
          (displayFormFieldPayload?.completed / displayFormFieldPayload?.total) * 100
        );
      } else {
        return 0;
      }
    };
    return (
      <Box py={3}>
        <Box className={classes.progress}>
          <Typography variant={'subtitle2'}>
            {displayFormFieldPayload?.completed} of {displayFormFieldPayload?.total} questions completed
          </Typography>
          <Box pb={3} pt={1}>
            <LinearProgress
              classes={{
                barColorPrimary: classes.barColor,
                root: classes.barRoot
              }}
              variant={'determinate'}
              color={'primary'}
              value={progressValue()}
            />
          </Box>
        </Box>
        <Divider />
        {displayFormFieldPayload && displayFormFieldPayload.nextQuestion && displayFormFieldPayload.nextQuestion.info_for_patient &&
          <HtmlTooltip title={displayFormFieldPayload && displayFormFieldPayload.nextQuestion ? displayFormFieldPayload.nextQuestion.info_for_patient : ''} aria-label="more-info" placement="top">
            <InfoIcon className={classes.icon} />
          </HtmlTooltip>}
      </Box>
    );
  };

  const responseRequired = (response || displayFormFieldPayload?.form_response.id) || displayFormFieldPayload?.nextQuestion?.field_type === FIELD_TYPES.RESOURCE || displayFormFieldPayload?.nextQuestion?.field_type === FIELD_TYPES.INFO_TEXT;

  return getNextQuestionLoading || getNextQuestionError ? (
    <Box py={3}>
      <LoadingOrError
        noElevation
        loadingMsg={'Loading Question...'}
        loading={getNextQuestionLoading}
        errorMsg={getNextQuestionError}
      />
    </Box>
  ) : (
    <>
      <ProgressBar />
      <Box>{renderQuestionType(displayFormFieldPayload?.nextQuestion || null, displayFormFieldPayload?.form_response || null)}</Box>
      <Box mt={2} className={classes.bottomButtons}>
        <LoadingButton
          className={classes.nextBtn}
          startIcon={<ArrowLeft />}
          variant="contained"
          color={'primary'}
          onClick={handelPreviousQue}
          aria-label="back"
        >
          Back
        </LoadingButton>
        <LoadingButton
          className={classes.nextBtn}
          endIcon={<ArrowRightAltIcon />}
          variant="contained"
          color={'primary'}
          disabled={getNextQuestionLoading || submitFieldResponseLoading || !responseRequired}
          onClick={submitAnswer}
          aria-label="next"
          loading={submitFieldResponseLoading}
        >
          Next
        </LoadingButton>
      </Box>
    </>
  );
};
interface ComponentsProps {
  customColor: string;
}

const useStyles = makeStyles((theme) => ({
  bottomButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  nextBtn: (props: ComponentsProps) => ({
    backgroundColor: props.customColor,
    minWidth: '8rem',
    padding: '20px',
    fontSize: '15px',
    '&:hover': {
      backgroundColor: props.customColor,
    }
  }),
  text: (props: ComponentsProps) => ({
    color: props.customColor,
    cursor: 'pointer'
  }),
  returnBtn: (props: ComponentsProps) => ({
    color: props.customColor,
    borderColor: props.customColor,
    minWidth: '8rem'
  }),
  progress: {
    textAlign: 'center'
  },
  barColor: (props: ComponentsProps) => ({
    backgroundColor: props.customColor
  }),
  barRoot: {
    backgroundColor: theme.palette.grey[300],
    height: 10,
    borderRadius: theme.shape.borderRadius
  },
  icon: { margin: '5px', fontSize: '30px' },
}));
