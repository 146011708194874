import React, { useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ROLES } from '../shared/globals';
import { RootState } from '../redux/redux-store';
import { isLoggedIn } from '../redux/api';
import { getLoggedInUserThunk } from '../redux/thunk/thunks';
import { ErrorPage } from '../pages/ErrorPage/ErrorPage';

interface PrivateRouteProps extends RouteProps {
  adminOnly?: boolean;
  superAdminOnly?: boolean;
  adminSuperAdmin?: boolean;
  adminOrBuilderOnly?: boolean;
  clinicianOnly?: boolean;
  patientOnly?: boolean;
  referralOnly?: boolean;
  siteAdminOrBuilderOnly?: boolean;
}

export const PrivateRoute = (props: PrivateRouteProps) => {
  // Add your own authentication on the below line.
  const dispatch = useDispatch();
  const loggedIn = isLoggedIn();
  const loggedInUser = useSelector((state: RootState) => state.DATA_REDUCER.loggedInUser);

  const canAccessRoute = () => {
    if (loggedIn && loggedInUser) {
      if (props.adminOnly) {
        return loggedInUser.role === ROLES.ADMIN;
      } else if (props.superAdminOnly) {
        return loggedInUser.role === ROLES.SUPER_ADMIN;
      } else if (props.adminSuperAdmin) {
        return (loggedInUser.role === ROLES.SUPER_ADMIN || loggedInUser.role === ROLES.ADMIN || loggedInUser.role === ROLES.SITE_ADMIN || loggedInUser.role === ROLES.BUILDER || loggedInUser.role === ROLES.CLINICIAN || loggedInUser.role === ROLES.REFERRAL || loggedInUser.role === ROLES.PATIENT || loggedInUser.role === ROLES.PROXY || loggedInUser.role === ROLES.RATER);
      } else if (props.adminOrBuilderOnly && props.clinicianOnly) {
        return (
          loggedInUser.role === ROLES.ADMIN ||
          loggedInUser.role === ROLES.BUILDER ||
          loggedInUser.role === ROLES.CLINICIAN
        );
      } else if (props.siteAdminOrBuilderOnly) {
        return loggedInUser.role === ROLES.SITE_ADMIN || loggedInUser.role === ROLES.BUILDER || loggedInUser.role === ROLES.REFERRAL || loggedInUser.role === ROLES.CLINICIAN || loggedInUser.role === ROLES.PATIENT || loggedInUser.role === ROLES.PROXY || loggedInUser.role === ROLES.RATER;
      } else if (props.clinicianOnly) {
        return loggedInUser.role === ROLES.CLINICIAN;
      } else if (props.patientOnly) {
        return loggedInUser.role === ROLES.PATIENT;
      } else if (props.referralOnly) {
        return loggedInUser.role === ROLES.REFERRAL;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (loggedIn && !loggedInUser) {
      props?.path !== '/login' && dispatch(getLoggedInUserThunk());
    }
  }, []);

  return (
    <Route path={props.path}>
      {!loggedIn ? (
        <Redirect to={{ pathname: '/login' }} />
      ) : !loggedInUser ? (
        <div />
      ) : canAccessRoute() ? (
        props.children || null
      ) : (
        <ErrorPage statusCode="422" />
      )}
    </Route>
  );
};
