import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';

export const LogicUnavailable = () => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.title}>
        <InfoIcon fontSize={'small'} color={'primary'} className={classes.icon} />
        <Typography variant={'subtitle2'} color={'textSecondary'}>
          You cannot add Logic yet
        </Typography>
      </Box>
      <Box>
        <Typography variant={'subtitle2'} className={classes.bodyText}>
          Conditional Logic can only be used when a quantifiable field (like a single choice,
          multiple choice or scale field) is present on the form.
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant={'subtitle2'} className={classes.bodyText}>
          If you want to add logic to a quantifiable field, another quantifiable field must be
          present.
        </Typography>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '.6rem'
  },
  icon: {
    marginRight: '.6rem'
  },
  bodyText: {
    color: theme.palette.grey[600]
  }
}));
