import * as Yup from 'yup';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import {
    Box, Grid, Paper, Typography, CardHeader, LinearProgress, FormControlLabel, Card, Stepper, StepLabel,
    Step, Button, Divider, Avatar, CardContent, FormControl, RadioGroup, Radio, FormLabel
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Color, ColorPicker, createColor } from "material-ui-color";
import { LoadingButton } from '../../../components/LoadingButton';
import { StyleInput, StyleSheetInput } from '../../../shared/domain';
import { TitledTextInput } from '../../../components/TitledTextInput';
import { RootState } from '../../../redux/redux-store';
import { getFormStyleThunk, updateFormStyleThunk, getLoggedInUserThunk } from '../../../redux/thunk/thunks';
import { url } from '../../../redux/url';

var axios = require("axios");
var FormData = require("form-data");

var cardColor = '#5D6AC4';

// interface StyleInfoProps {
//     styleInfo: StyleInput | null;
// }

export const StyleInfo = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    const [color, setColor] = useState<any>(createColor("#5D6AC4"));
    const [editing, setEditing] = useState(true);
    const [logo, setLogo] = useState('');
    const [ansOne, setAnsOne] = React.useState('');
    const [hexColor, setHexColor] = React.useState('#5D6AC4');
    const [ansTwo, setAnsTwo] = React.useState('');
    const [ansThree, setAnsThree] = React.useState('');
    const [ansFour, setAnsFour] = React.useState('');
    const [ansFive, setAnsFive] = React.useState('');
    const [ansSix, setAnsSix] = React.useState('');
    const [ansSeven, setAnsSeven] = React.useState('');
    const [ansEight, setAnsEight] = React.useState('');
    const [ansNaine, setAnsNaine] = React.useState('');
    const [ansTen, setAnsTen] = React.useState('');
    const [ansEleven, setAnsEleven] = React.useState('');
    const [ansTwelve, setAnsTwelve] = React.useState('');
    const [ansThirteen, setAnsThirteen] = React.useState('');
    const [ansFourteen, setAnsFourteen] = React.useState('');
    const [submitting, setSubmitting] = React.useState(false);
    const [error, setIsError] = useState(false);
    const [input, setInput] = useState(null);
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
    const [initialValues, setInitialValues] = useState<StyleSheetInput>({
        clinic_name: '',
        main_color: '',
        setMainColorAsContrast: false,
        logo: null,
    });
    const props = { customColor: cardColor };
    const classes = useStyles(props);
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);

    const success: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.createCollectionSuccess)
    useEffect(() => {
        if (success) {
            setSubmitting(false);
        }
    })
    useEffect(() => {
        if (!loggedInUser) {
            dispatch(getLoggedInUserThunk());
        }
    }, []);

    const formStyle: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.formStyle
    );
    useEffect(() => {
        if (!formStyle) {
            loggedInUser && dispatch(getFormStyleThunk(loggedInUser ? loggedInUser.id : ''));
        }
    })

    const [profile, setProfile] = useState(formStyle ? formStyle.logo : '');

    const handleSaveChanges = async (values: any, actions: any) => {
        actions.setSubmitting(true);
        const data = {
            "user_role_id": loggedInUser ? loggedInUser.id : null,
            "clinic_name": values.clinic_name,
            "main_color": hexColor,
            "setMainColorAsContrast": values.setMainColorAsContrast,
            "logo": logo,
            "updateDefaultFormStyle": (loggedInUser.role === "SUPER_ADMIN" || loggedInUser.role === "ADMIN") ? true : false
        }
        await dispatch(updateFormStyleThunk(data));
        actions.setSubmitting(false);
    };

    const handleChangeColor = (newValue: any) => {
        cardColor = newValue.css ? newValue.css.backgroundColor : '#5D6AC4'
        setHexColor(cardColor);
        setColor(cardColor);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInitialValues({ setMainColorAsContrast: event.target.checked });
    }

    const handleChangeLogo = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInitialValues({ logo: event.target.files });
    }

    const removeLogo = () => {
        setProfile('');
        setLogo('');
        setLoading(loading ? false : true)
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const progressValue = () => {
        return Math.round(
            (activeStep / 14) * 100
        );
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const getStepContent = (stepIndex: number) => {
        switch (stepIndex) {
            case 0:
                return (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl component="fieldset" style={{ display: 'contents' }}>
                                <FormLabel component="legend" className={classes.label}>In my last session, we spoke about things that truly matterd to me.</FormLabel>
                                <RadioGroup aria-label="quiz" name="quiz" value={ansOne} onChange={e => setAnsOne(e.target.value)}>
                                    <Paper className={classes.paper} style={{ background: ansOne === "I agree" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I agree" control={<Radio />} label="I agree" />
                                    </Paper>
                                    <Paper className={classes.paper} style={{ background: ansOne === "I believe so" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I believe so" control={<Radio />} label="I believe so" />
                                    </Paper>
                                    <Paper className={classes.paper} style={{ background: ansOne === "I disagree" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I disagree" control={<Radio />} label="I disagree" />
                                    </Paper>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            case 1:
                return (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl component="fieldset" style={{ display: 'contents' }}>
                                <FormLabel component="legend" className={classes.label}>In my last session, we spoke about things that truly matterd to me.</FormLabel>
                                <RadioGroup aria-label="quiz" name="quiz" value={ansTwo} onChange={e => setAnsTwo(e.target.value)}>
                                    <Paper className={classes.paper} style={{ background: ansTwo === "I agree" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I agree" control={<Radio />} label="I agree" />
                                    </Paper>
                                    <Paper className={classes.paper} style={{ background: ansTwo === "I believe so" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I believe so" control={<Radio />} label="I believe so" />
                                    </Paper>
                                    <Paper className={classes.paper} style={{ background: ansTwo === "I disagree" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I disagree" control={<Radio />} label="I disagree" />
                                    </Paper>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            case 2:
                return (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl component="fieldset" style={{ display: 'contents' }}>
                                <FormLabel component="legend" className={classes.label}>In my last session, we spoke about things that truly matterd to me.</FormLabel>
                                <RadioGroup aria-label="quiz" name="quiz" value={ansThree} onChange={e => setAnsThree(e.target.value)}>
                                    <Paper className={classes.paper} style={{ background: ansThree === "I agree" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I agree" control={<Radio />} label="I agree" />
                                    </Paper>
                                    <Paper className={classes.paper} style={{ background: ansThree === "I believe so" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I believe so" control={<Radio />} label="I believe so" />
                                    </Paper>
                                    <Paper className={classes.paper} style={{ background: ansThree === "I disagree" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I disagree" control={<Radio />} label="I disagree" />
                                    </Paper>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            case 3:
                return (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl component="fieldset" style={{ display: 'contents' }}>
                                <FormLabel component="legend" className={classes.label}>In my last session, we spoke about things that truly matterd to me.</FormLabel>
                                <RadioGroup aria-label="quiz" name="quiz" value={ansFour} onChange={e => setAnsFour(e.target.value)}>
                                    <Paper className={classes.paper} style={{ background: ansFour === "I agree" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I agree" control={<Radio />} label="I agree" />
                                    </Paper>
                                    <Paper className={classes.paper} style={{ background: ansFour === "I believe so" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I believe so" control={<Radio />} label="I believe so" />
                                    </Paper>
                                    <Paper className={classes.paper} style={{ background: ansFour === "I disagree" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I disagree" control={<Radio />} label="I disagree" />
                                    </Paper>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            case 4:
                return (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl component="fieldset" style={{ display: 'contents' }}>
                                <FormLabel component="legend" className={classes.label}>In my last session, we spoke about things that truly matterd to me.</FormLabel>
                                <RadioGroup aria-label="quiz" name="quiz" value={ansFive} onChange={e => setAnsFive(e.target.value)}>
                                    <Paper className={classes.paper} style={{ background: ansFive === "I agree" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I agree" control={<Radio />} label="I agree" />
                                    </Paper>
                                    <Paper className={classes.paper} style={{ background: ansFive === "I believe so" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I believe so" control={<Radio />} label="I believe so" />
                                    </Paper>
                                    <Paper className={classes.paper} style={{ background: ansFive === "I disagree" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I disagree" control={<Radio />} label="I disagree" />
                                    </Paper>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            case 5:
                return (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl component="fieldset" style={{ display: 'contents' }}>
                                <FormLabel component="legend" className={classes.label}>In my last session, we spoke about things that truly matterd to me.</FormLabel>
                                <RadioGroup aria-label="quiz" name="quiz" value={ansSix} onChange={e => setAnsSix(e.target.value)}>
                                    <Paper className={classes.paper} style={{ background: ansSix === "I agree" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I agree" control={<Radio />} label="I agree" />
                                    </Paper>
                                    <Paper className={classes.paper} style={{ background: ansSix === "I believe so" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I believe so" control={<Radio />} label="I believe so" />
                                    </Paper>
                                    <Paper className={classes.paper} style={{ background: ansSix === "I disagree" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I disagree" control={<Radio />} label="I disagree" />
                                    </Paper>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            case 6:
                return (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl component="fieldset" style={{ display: 'contents' }}>
                                <FormLabel component="legend" className={classes.label}>In my last session, we spoke about things that truly matterd to me.</FormLabel>
                                <RadioGroup aria-label="quiz" name="quiz" value={ansSeven} onChange={e => setAnsSeven(e.target.value)}>
                                    <Paper className={classes.paper} style={{ background: ansSeven === "I agree" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I agree" control={<Radio />} label="I agree" />
                                    </Paper>
                                    <Paper className={classes.paper} style={{ background: ansSeven === "I believe so" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I believe so" control={<Radio />} label="I believe so" />
                                    </Paper>
                                    <Paper className={classes.paper} style={{ background: ansSeven === "I disagree" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I disagree" control={<Radio />} label="I disagree" />
                                    </Paper>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            case 7:
                return (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl component="fieldset" style={{ display: 'contents' }}>
                                <FormLabel component="legend" className={classes.label}>In my last session, we spoke about things that truly matterd to me.</FormLabel>
                                <RadioGroup aria-label="quiz" name="quiz" value={ansEight} onChange={e => setAnsEight(e.target.value)}>
                                    <Paper className={classes.paper} style={{ background: ansEight === "I agree" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I agree" control={<Radio />} label="I agree" />
                                    </Paper>
                                    <Paper className={classes.paper} style={{ background: ansEight === "I believe so" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I believe so" control={<Radio />} label="I believe so" />
                                    </Paper>
                                    <Paper className={classes.paper} style={{ background: ansEight === "I disagree" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I disagree" control={<Radio />} label="I disagree" />
                                    </Paper>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            case 8:
                return (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl component="fieldset" style={{ display: 'contents' }}>
                                <FormLabel component="legend" className={classes.label}>In my last session, we spoke about things that truly matterd to me.</FormLabel>
                                <RadioGroup aria-label="quiz" name="quiz" value={ansNaine} onChange={e => setAnsNaine(e.target.value)}>
                                    <Paper className={classes.paper} style={{ background: ansNaine === "I agree" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I agree" control={<Radio />} label="I agree" />
                                    </Paper>
                                    <Paper className={classes.paper} style={{ background: ansNaine === "I believe so" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I believe so" control={<Radio />} label="I believe so" />
                                    </Paper>
                                    <Paper className={classes.paper} style={{ background: ansNaine === "I disagree" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I disagree" control={<Radio />} label="I disagree" />
                                    </Paper>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            case 9:
                return (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl component="fieldset" style={{ display: 'contents' }}>
                                <FormLabel component="legend" className={classes.label}>In my last session, we spoke about things that truly matterd to me.</FormLabel>
                                <RadioGroup aria-label="quiz" name="quiz" value={ansTen} onChange={e => setAnsTen(e.target.value)}>
                                    <Paper className={classes.paper} style={{ background: ansTen === "I agree" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I agree" control={<Radio />} label="I agree" />
                                    </Paper>
                                    <Paper className={classes.paper} style={{ background: ansTen === "I believe so" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I believe so" control={<Radio />} label="I believe so" />
                                    </Paper>
                                    <Paper className={classes.paper} style={{ background: ansTen === "I disagree" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I disagree" control={<Radio />} label="I disagree" />
                                    </Paper>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            case 10:
                return (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl component="fieldset" style={{ display: 'contents' }}>
                                <FormLabel component="legend" className={classes.label}>In my last session, we spoke about things that truly matterd to me.</FormLabel>
                                <RadioGroup aria-label="quiz" name="quiz" value={ansEleven} onChange={e => setAnsEleven(e.target.value)}>
                                    <Paper className={classes.paper} style={{ background: ansEleven === "I agree" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I agree" control={<Radio />} label="I agree" />
                                    </Paper>
                                    <Paper className={classes.paper} style={{ background: ansEleven === "I believe so" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I believe so" control={<Radio />} label="I believe so" />
                                    </Paper>
                                    <Paper className={classes.paper} style={{ background: ansEleven === "I disagree" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I disagree" control={<Radio />} label="I disagree" />
                                    </Paper>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            case 11:
                return (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl component="fieldset" style={{ display: 'contents' }}>
                                <FormLabel component="legend" className={classes.label}>In my last session, we spoke about things that truly matterd to me.</FormLabel>
                                <RadioGroup aria-label="quiz" name="quiz" value={ansTwelve} onChange={e => setAnsTwelve(e.target.value)}>
                                    <Paper className={classes.paper} style={{ background: ansTwelve === "I agree" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I agree" control={<Radio />} label="I agree" />
                                    </Paper>
                                    <Paper className={classes.paper} style={{ background: ansTwelve === "I believe so" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I believe so" control={<Radio />} label="I believe so" />
                                    </Paper>
                                    <Paper className={classes.paper} style={{ background: ansTwelve === "I disagree" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I disagree" control={<Radio />} label="I disagree" />
                                    </Paper>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            case 12:
                return (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl component="fieldset" style={{ display: 'contents' }}>
                                <FormLabel component="legend" className={classes.label}>In my last session, we spoke about things that truly matterd to me.</FormLabel>
                                <RadioGroup aria-label="quiz" name="quiz" value={ansThirteen} onChange={e => setAnsThirteen(e.target.value)}>
                                    <Paper className={classes.paper} style={{ background: ansThirteen === "I agree" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I agree" control={<Radio />} label="I agree" />
                                    </Paper>
                                    <Paper className={classes.paper} style={{ background: ansThirteen === "I believe so" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I believe so" control={<Radio />} label="I believe so" />
                                    </Paper>
                                    <Paper className={classes.paper} style={{ background: ansThirteen === "I disagree" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I disagree" control={<Radio />} label="I disagree" />
                                    </Paper>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            case 13:
                return (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl component="fieldset" style={{ display: 'contents' }}>
                                <FormLabel component="legend" className={classes.label}>In my last session, we spoke about things that truly matterd to me.</FormLabel>
                                <RadioGroup aria-label="quiz" name="quiz" value={ansFourteen} onChange={e => setAnsFourteen(e.target.value)}>
                                    <Paper className={classes.paper} style={{ background: ansFourteen === "I agree" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I agree" control={<Radio />} label="I agree" />
                                    </Paper>
                                    <Paper className={classes.paper} style={{ background: ansFourteen === "I believe so" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I believe so" control={<Radio />} label="I believe so" />
                                    </Paper>
                                    <Paper className={classes.paper} style={{ background: ansFourteen === "I disagree" ? cardColor : '#DEE4EC' }}>
                                        <FormControlLabel value="I disagree" control={<Radio />} label="I disagree" />
                                    </Paper>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            default:
                return 'Unknown stepIndex';
        }
    }

    useEffect(() => {
        if (formStyle) {
            setInitialValues({
                clinic_name: formStyle.clinic_name ?? '',
                main_color: formStyle.main_color ?? '',
                setMainColorAsContrast: formStyle.setMainColorAsContrast ?? false,
                logo: formStyle.logo ?? '',
            });
            cardColor = formStyle.main_color;
            setLogo(formStyle.logo ?? '')
            setHexColor(formStyle.main_color)
            setColor(formStyle.main_color)
            if (formStyle.logo !== "") {
                const data1 = {
                    input_files: [formStyle.logo]
                }
                var config1 = {
                    method: 'post',
                    url: `${url}/user/auth/getSignedDownloadUrls`,
                    headers: _getHeaders({ "Content-Type": "application/json" }),
                    data: data1,
                };

                axios(config1)
                    .then(function (res: any) {
                        setLoading(false);
                        setProfile(res.data.url && res.data.url.length > 0 && res.data.url[0]);
                    })
                    .catch(function (error: any) {
                        setLoading(false);
                    });
            }
        }
    }, [formStyle]);

    const _getHeaders = (inputHeaders?: { [key: string]: string }) => {
        let headers = inputHeaders || {};
        let token = localStorage.getItem('token');
        headers["Accept"] = "application/json";
        const headersWithAuth = token
            ? {
                ...headers,
                Authorization: `Bearer ${token}`,
            }
            : headers;
        return headersWithAuth;
    };
    const uploadProfile = (info: any) => {
        if (!info.name.match(/\.(jpg|jpeg|png|gif)$/)) {
            setError(true);
        } else {
            setError(false);
            setLoading(true);
            let file = info;
            var data = new FormData();
            data.append("input_file", file);

            var config = {
                method: 'post',
                url: `${url}/user/auth/uploadFileOnS3`,
                headers: _getHeaders({ "Content-Type": "multipart/form-data" }),
                data: data,
            };

            axios(config)
                .then(function (response: any) {
                    setLoading(false);
                    setLogo(response.data.url);
                    const data1 = {
                        input_files: [response.data.url]
                    }
                    var config1 = {
                        method: 'post',
                        url: `${url}/user/auth/getSignedDownloadUrls`,
                        headers: _getHeaders({ "Content-Type": "application/json" }),
                        data: data1,
                    };

                    axios(config1)
                        .then(function (res: any) {
                            setLoading(false);
                            setProfile(res.data.url && res.data.url.length > 0 && res.data.url[0]);
                        })
                        .catch(function (error: any) {
                            setLoading(false);
                        });
                })
                .catch(function (error: any) {
                    setLoading(false);
                });
        }
    };

    const profileSchema = Yup.object().shape({
        clinic_name: Yup.string().required("Required")
            .max(100, '')
    });

    const handleChanges = (value: any, no: any) => {
        if (no === 0 && value.length > 100) {
            setIsError(true); setInput(no);
        } else {
            setIsError(false); setInput(no);
        }
    }

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSaveChanges}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={profileSchema}
        >
            {({ errors, isSubmitting, touched, resetForm, setSubmitting, values }: any) => {
                return (
                    <Form>
                        <Grid container>
                            <Grid item xs={12} style={{ textAlign: 'right', marginTop: '10px', marginRight: '20px' }}>
                            </Grid>
                        </Grid>
                        <Box display="flex" flexDirection="column" minWidth="14rem" px={2.5} py={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={5}>
                                    <TitledTextInput style={{ background: error && input === 0 ? '#fff1f1' : 'unset' }}
                                        onInput={(e: any) => handleChanges(e.target.value, 0)}
                                        inputProps={{ maxLength: 101 }}
                                        disabled={!editing}
                                        fullWidth
                                        name="clinic_name"
                                        size="small"
                                        title="Clinic Name"
                                        variant="outlined"
                                    />
                                    <Box display='flex' justifyContent='space-between'>
                                        <Typography color={"error"} variant="subtitle2">
                                            {errors.clinic_name && touched.clinic_name && errors.clinic_name}
                                        </Typography>
                                        <Typography style={{ color: values?.clinic_name?.length > 100 ? '#911717' : '#0000008a' }} variant="subtitle2">
                                            {values.clinic_name?.length}/100
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={4}>
                                    <Typography variant="h5" className={classes.font} component="h1" gutterBottom>
                                        Clinic Main Color
                                    </Typography>
                                    <Paper>
                                        <ColorPicker value={color} onChange={handleChangeColor} />
                                    </Paper>
                                </Grid>
                                <Grid item xs={3} style={{ textAlign: 'right', alignItems: 'center', display: 'flex', justifyContent: 'flex-end' }}>
                                    <LoadingButton
                                        className={classes.primaryButton}
                                        color="primary"
                                        variant="contained"
                                        type='submit'
                                    >
                                        Save
                                    </LoadingButton>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="h5" className={classes.font} component="h1" gutterBottom>
                                        Logo displayed in header
                                    </Typography>
                                    <div>
                                        <input
                                            accept="image/*"
                                            className={classes.input}
                                            id="contained-button-file"
                                            onChange={(e: any) => uploadProfile(e.target.files[0])}
                                            type="file"
                                        />
                                        <label htmlFor="contained-button-file">
                                            <Button variant="contained" component="span">
                                                Select Resource(s)
                                            </Button>
                                        </label>
                                    </div>
                                </Grid>
                                {isError &&
                                    <Grid item xs={9} className={classes.grid} style={{ display: 'flex', alignItems: 'center' }}>
                                        <span className={classes.removeColor}>Please select valid image.</span>
                                    </Grid>}
                                {profile !== '' &&
                                    <Grid item xs={9} className={classes.grid} style={{ display: 'flex', alignItems: 'center' }}>
                                        <Avatar src={profile} alt='logo' variant="rounded" style={{ width: 'auto' }} />
                                        <span className={classes.removeColor} onClick={removeLogo}>Remove</span>
                                    </Grid>}
                                <Grid item xs={12}>
                                    <Divider className={classes.divider} />
                                </Grid>
                                <Grid item xs={12}>
                                    <span className={classes.font}>
                                        Styling Preview
                                    </span>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box display="flex" flexDirection="column" minWidth="14rem" px={2.5} py={3} className={classes.box}>
                            <Card>
                                <CardHeader className={classes.background} style={{ backgroundColor: cardColor }}
                                    avatar={
                                        <img src={profile} alt='Clinic Logo' className={classes.avatar} />
                                        // <Avatar aria-label="Clinic Logo" style={{ width: 'auto' }} className={classes.avatar} src={profile} />
                                    }
                                />
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} style={{ textAlign: 'center', padding: '10px' }}>
                                            <Typography><b>{activeStep} of 14 questions completed</b></Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{ textAlign: 'center', padding: '0px 20px 20px' }}>
                                            {/* <Stepper activeStep={activeStep} alternativeLabel>
                                                    {steps.map((label) => (
                                                        <Step key={label}>
                                                            <StepLabel></StepLabel>
                                                        </Step>
                                                    ))}
                                                </Stepper> */}
                                            <LinearProgress
                                                classes={{
                                                    barColorPrimary: classes.barColor,
                                                    root: classes.barRoot
                                                }}
                                                variant={'determinate'}
                                                color={'primary'}
                                                value={progressValue()}
                                            />
                                        </Grid>
                                        <Grid item xs={12} style={{ padding: '0px 16px' }}>
                                            <Divider className={classes.divider} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            {activeStep === steps.length ? (
                                                <div style={{ textAlign: 'center' }}>
                                                    <Typography>All steps completed</Typography>
                                                    <Button variant="contained" disabled={isSubmitting}
                                                        style={{ background: cardColor, fontSize: '18px', padding: '20px 40px', color: '#fff', margin: '20px' }}>
                                                        {isSubmitting ? 'Submiting...' : 'Submit'}
                                                    </Button>
                                                </div>
                                            ) : (
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                                                        {getStepContent(activeStep)}
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Button
                                                            disabled={activeStep === 0}
                                                            onClick={handleBack}
                                                            style={{ border: activeStep === 0 ? '#00000042' : ('1px solid ' + cardColor), color: activeStep === 0 ? '#00000042' : cardColor, fontSize: '18px', padding: '20px 40px' }}
                                                        ><ArrowBack style={{ color: activeStep === 0 ? '#00000042' : cardColor, paddingRight: '10px' }} />Back</Button>
                                                    </Grid>
                                                    <Grid item xs={6} className={classes.butGrid}>
                                                        <Button variant="contained" onClick={handleNext} style={{ background: cardColor, fontSize: '18px', padding: '20px 40px', color: '#fff' }}>
                                                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}<ArrowForward style={{ paddingLeft: '10px' }} />
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};
interface ComponentsProps {
    customColor: string;
}
const useStyles = makeStyles((theme) => ({
    barColor: (props: ComponentsProps) => ({
        backgroundColor: props.customColor
    }),
    barRoot: {
        backgroundColor: theme.palette.grey[300],
        height: 10,
        borderRadius: theme.shape.borderRadius
    },
    cardHeader: {
        borderBottom: '1px solid #c4c4c4',
        color: '#374151',
        fontSize: '18px',
        padding: '24px 32px',
        background: '#f9f9f9'
    },
    textField: {
        width: '100%'
    },
    divider: {
        backgroundColor: '#c4c4c4',
        marginBottom: '20px'
    },
    grid: {
        alignSelf: 'flex-end'
    },
    butGrid: {
        textAlign: 'right'
    },
    input: {
        display: 'none',
    },
    avatar: {
        height: '40px',
        borderRadius: 'unset',
        backgroundColor: 'transparent'
    },
    background: {
        paddingLeft: '48%',
        padding: '10px',
        backgroundColor: cardColor
    },
    font: {
        fontSize: '14px',
        fontFamily: 'Inter,sans-serif',
        fontWeight: 500,
        lineHeight: 1.334
    },
    removeColor: {
        color: 'red',
        fontSize: '14px',
        fontFamily: 'Inter,sans-serif',
        fontWeight: 500,
        lineHeight: 1.334,
        marginLeft: '20px',
        cursor: 'pointer'
    },
    box: {
        padding: '0px 20px 20px'
    },
    paper: {
        textAlign: 'left', paddingLeft: '20px', margin: '10px',
    },
    label: {
        fontSize: '18px', fontWeight: 600, paddingLeft: '15px', textAlign: 'left', marginBottom: '10px'
    },
    primaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#fff',
        minWidth: '120px'
    },
}));

