import { Box, Grid, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardHeader } from '../../../../components/LegacyCardHeader';
import { RootState } from '../../../../redux/redux-store';
import { getReferralsThunk, getReferralsFilterThunk, getPatientReferralsThunk } from '../../../../redux/thunk/thunks';
import { ReferralFiltersDialog } from './ReferralFilters';
import { ReferralsTable } from './ReferralsTable';
import { setSuccessAction } from '../../../../redux/display/display-actions';
import { useRouteMatch } from 'react-router-dom';
import { assignmentInfoAction, getReferralsAction } from '../../../../redux/data/data-actions';
import { Patients } from '../../../Resources/AssignPatient/Patients';

export const Referrals = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedReferrals, setSelectedReferrals] = useState<any[]>([]);
  const [searchPattern, setSearchPattern] = useState('');
  const logedUser: any = localStorage.getItem('formFlash');
  const role: any = sessionStorage.getItem('role');
  const organization_id: any = sessionStorage.getItem('pat_org_id');
  const loggedInUser = JSON.parse(logedUser);
  const { path } = useRouteMatch();
  const split = path.split('/');
  const shared_role_id: any = split && split.length > 1 && split[2];
  const referrals: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.referralsSearchResults.referrals
  );
  const [openFilter, setFilterDialog] = useState(false);
  const [onTab, setOnTab] = useState('');
  const [viewReferralDialogueOpen, setViewReferralDialogueOpen] = useState(false);
  const [openPatient, setOpenPatient] = useState(false);
  const [info, setReferralInfo] = useState<any>(null);

  const user: any = useSelector((state: RootState) => state.DATA_REDUCER.patientInfo);
  const success: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.saveReferralSuccess)
  useEffect(() => {
    if (success) {
      setViewReferralDialogueOpen(false);
      dispatch(setSuccessAction('saveReferralSuccess', false));
      onTab === 'Referral Library' ?
        dispatch(getReferralsThunk({ search: searchPattern, sort: referrals?.sort, order: referrals?.order, pageSize: referrals?.pageSize, page: referrals?.page, favourites: referrals?.favourites, filters: referrals?.filters, referral_type: referrals?.referral_type, organization_id: organization_id, user_role_id: shared_role_id })) :
        dispatch(getPatientReferralsThunk({ search: searchPattern, sort: referrals?.sort, order: referrals?.order, pageSize: referrals?.pageSize, page: referrals?.page, favourites: referrals?.favourites, filters: referrals?.filters, referral_type: referrals?.referral_type, organization_id: organization_id, user_role_id: shared_role_id }));
    }
  })

  const viewReferralOpen = (open: any) => {
    setViewReferralDialogueOpen(open);
  };

  const filterReferralOpen = () => {
    setFilterDialog(true);
  };
  const filterReferralClose = () => {
    setFilterDialog(false);
  };

  const handleSearch = (value: string) => {
    setSearchPattern(value);
    if (referrals) {
      onTab === 'Referral Library' ?
        dispatch(
          getReferralsThunk({
            favourites: referrals?.favourites,
            order: referrals?.order,
            pageSize: referrals?.pageSize,
            search: value,
            sort: referrals?.sort,
            filters: referrals?.filters,
            referral_type: referrals?.referral_type,
            organization_id: organization_id,
            user_role_id: shared_role_id,
          })
        ) :
        dispatch(
          getPatientReferralsThunk({
            favourites: referrals?.favourites,
            order: referrals?.order,
            pageSize: referrals?.pageSize,
            search: value,
            sort: referrals?.sort,
            filters: referrals?.filters,
            referral_type: referrals?.referral_type,
            organization_id: organization_id,
            user_role_id: shared_role_id,
          })
        );
      setSelectedReferrals([]);
    }
  };

  // We need async/await over here as it is used for some state loading task
  const loadReferrals = async (getReferralsProps: any) => {
    getReferralsProps.organization_id = organization_id; getReferralsProps.user_role_id = shared_role_id;
    if (onTab === 'Referral Library') {
      await dispatch(getReferralsThunk(getReferralsProps));
    } else {
      await dispatch(getPatientReferralsThunk(getReferralsProps));
    }

  };

  useEffect(() => {
    dispatch(getReferralsFilterThunk({ type: 'ALL' }));

    return function CleanUp() {
      dispatch(getReferralsAction('referrals', null));
    };
  }, []);

  const onReferralTabChange = async (e: any) => {
    if (e === 'Referral Library' && (onTab !== e || onTab === '')) {
      setOnTab(e); selectedReferrals?.length > 0 && setSelectedReferrals([]);
      await dispatch(getReferralsThunk({ search: searchPattern, favourites: false, filters: referrals?.filters, referral_type: referrals?.referral_type, organization_id: organization_id, user_role_id: shared_role_id }));
    }
  }

  const patientReferralTabChange = async (e: any) => {
    if ((e === 'Assigned Referrals' || e === 'Shared Referrals') && (onTab !== e || onTab === '')) {
      setOnTab(e); selectedReferrals?.length > 0 && setSelectedReferrals([]);
      await dispatch(getPatientReferralsThunk({ search: searchPattern, favourites: false, filters: referrals?.filters, referral_type: referrals?.referral_type, organization_id: organization_id, user_role_id: shared_role_id }));
    }
  }

  const assignReferralToPatient = async () => {
    setOpenPatient(true);
    const arr: any = [];
    for (let i = 0; i < selectedReferrals?.length; i++) {
      const check = referrals && referrals?.data.filter((item: any) => item.id === selectedReferrals[i]);
      check && check.length > 0 && arr.push(check[0]);
    }
    setReferralInfo(arr);
    dispatch(assignmentInfoAction(arr));
  }
  const assignReferralToPatientClose = () => {
    setOpenPatient(false);
    setSelectedReferrals([]);
  }

  const ReferralsTableContainer = (
    <Box pb={4} pt={1} px={4}>
      <ReferralsTable
        referralsData={referrals}
        loadReferrals={loadReferrals}
        viewReferralOpen={viewReferralOpen}
        viewReferralDialogueOpen={viewReferralDialogueOpen}
        tab={onTab}
        setSelectedReferrals={(selectedReferrals: any[]) =>
          setSelectedReferrals(selectedReferrals)
        }
        selectedReferrals={selectedReferrals}
      />
    </Box>
  );

  return (
    <>
      {openFilter &&
        <ReferralFiltersDialog open={openFilter} handleClose={filterReferralClose} loadReferrals={loadReferrals} />
      }

      <Grid container>
        <Grid item xs={12}>
          <Paper>
            <Box display="flex" flexDirection="column">
              <CardHeader
                tabComponents={[
                  {
                    component: ReferralsTableContainer,
                    label: 'Shared Referrals',
                    style: {
                      background: true,
                      spacing: { pb: 0, pt: 3, px: 4 }
                    },
                    title: 'All Referral',
                    handleSearch: handleSearch,
                    onTabChange: patientReferralTabChange,
                    filter: filterReferralOpen
                  },
                  {
                    component: ReferralsTableContainer,
                    label: 'Referral Library',
                    selected: {
                      primaryButton: {
                        text: 'Assign to Patient',
                        handleClick: assignReferralToPatient
                      },
                      selectedContents: selectedReferrals
                    },
                    style: {
                      background: true,
                      spacing: { pb: 0, pt: 3, px: 4 }
                    },
                    title: 'All Referral',
                    handleSearch: handleSearch,
                    onTabChange: onReferralTabChange,
                    filter: filterReferralOpen
                  },
                ]}
              />
            </Box>
          </Paper>
          {openPatient && <Patients handelClose={assignReferralToPatientClose} />}
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles({
  filterSection: {
    overflowY: 'auto',
    maxHeight: '35rem'
  }
});
