import {
  Box, makeStyles, Paper, Theme, Typography, Dialog, Grid, Toolbar, AppBar, Button, Divider, Select, MenuItem, FormControl, TextField,
  FormControlLabel, Radio
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/redux-store';
import { colors } from '../../../../styling/colors';
import { ArrowBack } from '@material-ui/icons';
import { LoadingButton } from '../../../../components/LoadingButton';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { getPatientInfoThunk, getFormRecurrenceInfoThunk, getSharedUserInfoThunk, unassignFormToUserThunk, getFormInstanceMetricDetailsThunk, unassignCollectionToUserThunk } from '../../../../redux/thunk/thunks';
import LineChart from './graph';
import moment from 'moment';
import { UpdateRecurrence } from './UpdateRecurrence';
import { getFormMetricGraphsAction, getFormInstanceDetailsAction, getCompletedCollectionResultsAction, getCompletedFormResultsAction } from '../../../../redux/data/data-actions';
import { setSuccessAction } from '../../../../redux/display/display-actions';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { FormResultTable } from './CompletResult/CompleteFormResults';
import { CollectionResultTable } from './CompletResult/CompleteCollectionResult';

export const ViewResultPage = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const location: any = useLocation();
  const organization_id: any = sessionStorage.getItem('pat_org_id');
  const role: any = sessionStorage.getItem('role');
  const infoItem: any = sessionStorage.getItem('res_item');
  const resultInfo = JSON.parse(infoItem);
  const split = location?.pathname.split('/');
  const patient_role_id: any = split && split.length > 1 && split[2];
  const shared_role_id: any = split && split.length > 3 && split[4];
  const form_instance_id: any = split && split.length > 2 && split[3];
  const item_type: any = split && split.length > 4 && split[5];
  const [isOpen, setOpenDialog] = React.useState(false);
  const [confirm, setConfirm] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const user: any = useSelector((state: RootState) => state.DATA_REDUCER.patientInfo);
  const sharedUser: any = useSelector((state: RootState) => state.DATA_REDUCER.sharedUser);
  const formRecurrenceInfo: any = useSelector((state: RootState) => state.DATA_REDUCER.formRecurrenceInfo);
  const formMetricGraphs: any = useSelector((state: RootState) => state.DATA_REDUCER.formMetricGraphs);
  const formInstanceDetails: any = useSelector((state: RootState) => state.DATA_REDUCER.formInstanceDetails);
  const success: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.updateFormRecurrence);

  useEffect(() => {
    if (success) {
      dispatch(setSuccessAction('updateFormRecurrence', false));
      if (role === 'SHARED_USER') {
        history.push(`/patient-/${patient_role_id}/sharedUser/${shared_role_id}/result`)
      } else {
        history.push(`/patient/${patient_role_id}/result`);
      }
    }
  })

  useEffect(() => {
    !user && patient_role_id && dispatch(getPatientInfoThunk(patient_role_id));
    !sharedUser && shared_role_id && role === 'SHARED_USER' && dispatch(getSharedUserInfoThunk(shared_role_id));
    dispatch(getFormRecurrenceInfoThunk((item_type === 'FORM' || shared_role_id === 'FORM') ? form_instance_id : resultInfo?.recurrence_id, (item_type === 'FORM' || shared_role_id === 'FORM') ? '' : (role === 'SHARED_USER' ? shared_role_id : patient_role_id)));
    if (item_type === 'FORM' || shared_role_id === 'FORM') {
      !formMetricGraphs && dispatch(getFormInstanceMetricDetailsThunk(form_instance_id, role === 'SHARED_USER' ? shared_role_id : patient_role_id));
    }
  }, [])

  const handleClose = () => {
    // history.push(`${location.pathname.slice(0, location.pathname.lastIndexOf('/'))}`)
    dispatch(getFormInstanceDetailsAction(null));
    dispatch(getFormMetricGraphsAction(null));
    dispatch(getCompletedCollectionResultsAction(null));
    dispatch(getCompletedFormResultsAction(null));
    if (role === 'SHARED_USER') {
      history.push(`/patient-/${patient_role_id}/sharedUser/${shared_role_id}/result`)
    } else {
      history.push(`/patient/${patient_role_id}/result`);
    }
  }

  const handleOpenRecurrence = () => {
    setOpenDialog(true);
  }

  const handelClose = () => {
    setOpenDialog(false);
    setConfirm(false);
  }

  const unassignForm = async () => {
    setLoading(true);
    if (item_type === 'FORM' || shared_role_id === 'FORM') {
      const data = {
        "user_role_id": role === 'PATIENT' ? patient_role_id : shared_role_id,
        "form_instance_id": form_instance_id
      }
      await dispatch(unassignFormToUserThunk(data));
    } else {
      const data = {
        "user_role_id": role === 'PATIENT' ? patient_role_id : shared_role_id,
        "collection_id": resultInfo?.item_id,
        "recurrence_id": resultInfo?.recurrence_id
      }
      await dispatch(unassignCollectionToUserThunk(data));
    }
    setLoading(false);
  }

  return (
    <>
      <Dialog fullScreen open={true}>
        <Box className={classes.main} display="flex" flexDirection="column" p={3}>
          <Box pb={4}>
            <AppBar square elevation={0} classes={{ root: classes.headerRoot }}>
              <Toolbar classes={{ root: classes.headerToolbar }}>
                <Button
                  classes={{ text: classes.closeBtnText }}
                  startIcon={<ArrowBack style={{ color: 'gray', fontSize: '25px' }} />}
                  onClick={() => handleClose()}
                >
                  Return
                </Button>
                <Box alignSelf="stretch" display="flex" pr={3} py={2.75}>
                  <Divider flexItem orientation="vertical" />
                </Box>

                <Box
                  display="flex"
                  alignItems='center'
                  justifyContent="flex-start"
                  minHeight="5rem"
                  width="100%"
                >
                  <Box>
                    <Typography className={classes.subtitle}>
                      {`${role} > ${role !== 'PATIENT' ? (sharedUser?.first_name + ' ' + sharedUser?.last_name) : user?.first_name + ' ' + user?.last_name} >`}
                    </Typography>
                    <Typography color="textSecondary" variant="h2">
                      <span style={{ color: 'gray' }}>{!formInstanceDetails ? 'Recurring Collection:' : 'Recurring Form:'}</span> {formInstanceDetails ? formInstanceDetails?.title : resultInfo?.item_name}
                    </Typography>
                  </Box>
                </Box>
                <Box display='flex'>
                  <LoadingButton style={{ marginRight: '10px' }}
                    className={classes.primaryButton} onClick={handleOpenRecurrence}
                    color="primary" variant="contained"
                  >
                    Edit
                  </LoadingButton>
                  <LoadingButton disabled={loading}
                    className={classes.primaryButton} onClick={() => setConfirm(true)}
                    color="primary" variant="contained"
                  >
                    Unassign
                  </LoadingButton>
                </Box>
              </Toolbar>
            </AppBar>
          </Box>
          <Grid container spacing={4} className={classes.body}>
            <Grid item xs={12} sm={5} md={3}>
              <Paper style={{ borderRadius: '10px', padding: '20px' }}>
                <Box pb={2.5}>
                  <Box pb={0.75}>
                    <Typography color="textSecondary" style={{ fontSize: '20px' }}>
                      <b>Form Settings</b>
                    </Typography>
                  </Box>
                </Box>
                <Box pb={2.5}>
                  <Box pb={0.75}>
                    <Typography color="textSecondary" variant="subtitle2">
                      Start Date
                    </Typography>
                  </Box>
                  <TextField fullWidth variant='outlined' size='small' autoFocus
                    value={formRecurrenceInfo?.start_date && moment(formRecurrenceInfo?.start_date).format('LL')} disabled />
                </Box>
                <Box pb={2.5} pl={2} pr={2} display='flex' alignItems='center' justifyContent='space-between'>
                  <FormControlLabel checked={formRecurrenceInfo?.no_of_recurrences ? false : true} control={<Radio color="primary" checked={formRecurrenceInfo?.no_of_recurrences ? false : true} />} label="Set End Date" disabled />
                </Box>
                <Box pb={2.5} pl={2} pr={2} display='flex' alignItems='center' justifyContent='space-between'>
                  <Typography color="textSecondary" variant="subtitle2">- OR -</Typography>
                </Box>
                <Box pb={2.5} pl={2} pr={2} display='flex' alignItems='center' justifyContent='space-between'>
                  <FormControlLabel checked={formRecurrenceInfo?.no_of_recurrences ? true : false} control={<Radio color="primary" checked={formRecurrenceInfo?.no_of_recurrences ? true : false} />} label="Set Recurrences" disabled />
                </Box>
                <Box pb={2.5}>
                  <Box pb={0.75}>
                    <Typography color="textSecondary" variant="subtitle2">
                      End Date
                    </Typography>
                  </Box>
                  <TextField fullWidth variant='outlined' size='small' disabled
                    value={formRecurrenceInfo?.end_date && moment(formRecurrenceInfo?.end_date).format('LL')} />
                </Box>
                <Box pb={2.5}>
                  <Box pb={0.75}>
                    <Typography color="textSecondary" variant="subtitle2">
                      Number of Recurrences
                    </Typography>
                  </Box>
                  <TextField fullWidth variant='outlined' size='small' type='tel' disabled
                    value={formRecurrenceInfo?.no_of_recurrences} style={{ background: '#8080801c' }} />
                </Box>
                <Box pb={2.5}>
                  <Box pb={0.75}>
                    <Typography color="textSecondary" variant="subtitle2">
                      Recurrence Schedule
                    </Typography>
                  </Box>
                  <FormControl size="small" fullWidth>
                    <Select variant={'outlined'} value={formRecurrenceInfo?.recurrence_schedule} disabled
                      style={{ background: '#8080801c' }}>
                      <MenuItem value={'DAILY'}>DAILY</MenuItem>
                      <MenuItem value={'WEEKLY'}>WEEKLY</MenuItem>
                      <MenuItem value={'MONTHLY'}>MONTHLY</MenuItem>
                      <MenuItem value={'YEARLY'}>YEARLY</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box pb={2.5}>
                  <Box pb={0.75}>
                    <Typography color="textSecondary" variant="subtitle2">
                      Reminder Emails - Number of days after due date
                    </Typography>
                  </Box>
                  <TextField fullWidth variant='outlined' size='small' value={formRecurrenceInfo?.days_for_reminder} disabled />
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={7} md={9}>
              <Paper style={{ background: 'transparent', boxShadow: 'unset', border: 'unset' }}>
                <Box mb={4} style={{ borderRadius: '10px', padding: '20px', background: '#fff', boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)' }}>
                  <Box>
                    <Typography className={classes.subtitle}>
                      {'RESULTS'}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography color="textSecondary" style={{ fontSize: '20px' }}>
                      <b>Results for {formInstanceDetails ? formInstanceDetails?.title : resultInfo?.item_name}</b>
                    </Typography>
                    {(item_type === 'FORM' || shared_role_id === 'FORM') ? null : <Typography color="textSecondary" variant="subtitle2">
                      {resultInfo?.description}
                    </Typography>}
                  </Box>
                  <Box>
                    {(item_type === 'FORM' || shared_role_id === 'FORM') ? <FormResultTable /> : <CollectionResultTable />}
                  </Box>
                </Box>
                {(item_type === 'FORM' || shared_role_id === 'FORM') && <Box style={{ borderRadius: '10px', padding: '20px', background: '#fff', boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)' }}>
                  <Box>
                    <Typography className={classes.subtitle}>
                      {'RESULTS GRAPH'}
                    </Typography>
                  </Box>
                  {formMetricGraphs && formMetricGraphs?.map((item: any) => {
                    return <Box>
                      <Typography color="textSecondary" style={{ fontSize: '20px' }}>
                        <b>{item.metric_name}</b>
                      </Typography>
                      <LineChart info={item} />
                    </Box>
                  })}
                </Box>}
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
      {< ConfirmationDialog
        loading={loading}
        message={`Are you sure you want to unassign ${(item_type === 'FORM' || shared_role_id === 'FORM') ? 'form' : 'collection'}?`}
        open={confirm}
        title="Calculation"
        handleClose={handelClose}
        handleConfirmClick={() => unassignForm()}
      />}
      {isOpen && <UpdateRecurrence handelClose={handelClose} type={resultInfo} />}
    </>
  );
};

const useStyle = makeStyles((theme: Theme) => ({
  main: {
    backgroundColor: colors.background1,
    minHeight: 'auto',
    boxSizing: 'border-box',
    overflowX: 'hidden'
  },
  body: {
    margin: '50px 0',
    // width: '100%',
    // display: 'flex',
    // flexWrap: 'wrap'
  },
  referralInfo: {
    [theme.breakpoints.down('xs')]: {
      paddingRight: '0',
      width: '100%'
    }
  },
  contentContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  contentContainer1: {
    [theme.breakpoints.down('xs')]: {
      flexGrow: 0,
      flexBasis: '100%',
      maxWidth: '100%',
      display: 'flex',
      flexWrap: 'wrap'
    },
    [theme.breakpoints.down('sm')]: {
      flexGrow: 0,
      flexBasis: '50%',
      maxWidth: '50%',
      display: 'flex',
      flexWrap: 'wrap'
    },
    [theme.breakpoints.down('md')]: {
      flexGrow: 0,
      flexBasis: '60%',
      maxWidth: '60%',
      display: 'flex',
      flexWrap: 'wrap'
    },
    [theme.breakpoints.down('lg')]: {
      flexGrow: 0,
      flexBasis: '70%',
      maxWidth: '70%',
      display: 'flex',
      flexWrap: 'wrap'
    }
  },
  heading: {
    marginBottom: '1rem'
  },
  editIcon: {
    marginLeft: '10px',
    fontSize: '12px',
    border: '1px solid #c1c1c1',
    color: '#c1c1c1',
    borderRadius: '3px',
    cursor: 'pointer'
  },
  deleteButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: 'red',
    minWidth: '120px'
  },
  subtitle: {
    color: colors.text6,
    fontWeight: 600
  },
  primaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: '#fff',
    minWidth: '120px'
  },
  headerRoot: {
    backgroundColor: 'white',
    borderBottom: `2px solid ${theme.palette.divider}`,
    padding: 0
  },
  headerToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 0
  },
  closeBtnText: {
    color: 'gray',
    marginRight: 18,
    fontSize: '16px',
    '&:hover': {
      backgroundColor: 'unset'
    }
  },
}));
