import React from 'react';

import { HeadCell, Pagination, RowCell, Selection, Table } from '../../../../components/Table';
import { User, UserSearchResults } from '../../../../shared/domain';
import { concatName } from '../../../../shared/utils';

interface UsersTableProps {
  selection: Selection;
  usersData?: UserSearchResults | null;
  handleNextButton: () => Promise<void>;
  handlePrevButton: () => Promise<void>;
}

export const UsersTable = ({
  selection,
  usersData,
  handleNextButton,
  handlePrevButton
}: UsersTableProps) => {
  const headCells: HeadCell[] = [
    {
      id: 'name',
      label: 'name'
    }
  ];
  const rowCells = usersData?.users.map((user: User) => {
    const rowCell: RowCell = {
      name: {
        value: concatName({
          firstName: user.first_name,
          lastName: user.last_name,
          fallback: user.email ?? '<No Patient Name Available>'
        })
      }
    };

    return Object.assign(rowCell, { content: user });
  });

  const pagination: Pagination = {
    contentName: 'User',
    page: usersData?.page ?? 0,
    pageSize: usersData?.pageSize ?? 0,
    total: usersData?.total ?? 0,
    handleNextButton,
    handlePrevButton
  };

  return (
    <Table
      data={usersData?.users}
      headCells={headCells}
      hideHead
      rowCells={rowCells}
      pagination={pagination}
      selection={selection}
    />
  );
};
