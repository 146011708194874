import React, { useEffect } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import { userAuthLoginSSOUserThunk, ssoSignupThunk } from '../../redux/thunk/thunks';
import { RootState } from '../../redux/redux-store';
import { useHistory } from 'react-router-dom';

export const RedirectPage = () => {
    const dispatch = useDispatch();
    const history: any = useHistory();
    const location = history?.location?.pathname;
    // const location = window.location.pathname;
    const split = location.split('/');
    const token = split[3];
    const user_role_id = sessionStorage.getItem('userRoleID');

    useEffect(() => {
        if (window.location.pathname === '/login') {
            localStorage.removeItem('token');
            localStorage.removeItem('formFlash');
        } else if (token) {
            if (user_role_id) {
                const data = {
                    token: token,
                    user_role_id: user_role_id
                }
                dispatch(ssoSignupThunk(data));
            } else if (token !== 'null') {
                token && dispatch(userAuthLoginSSOUserThunk(token));
            }
        } else {
            history.push('/login');
        }
    }, [])

    return (
        <>
            <Box style={{ position: 'fixed', left: 0, top: 0, width: '100%', height: '100%' }}
                display="flex" alignItems="center" justifyContent="center" >
                <CircularProgress size={64} disableShrink thickness={3} />
            </Box>
        </>
    );
};