import React from 'react';
import { Box, makeStyles, Modal, Paper, Theme } from '@material-ui/core';

interface DialogProps {
  children: React.ReactNode;
  open: boolean;
  customClass?: string;
  handleClose?: () => void;
}

export const Dialog = ({ children, open, handleClose, customClass }: DialogProps) => {
  const classes = useStyles();

  return (
    <Modal onClose={handleClose} open={open}>
      <Box alignItems="center" className={classes.main} display="flex" justifyContent="center">
        <Paper className={(classes.dialogPaper, customClass)}>{children}</Paper>
      </Box>
    </Modal>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    minHeight: '100vh'
  },
  dialogPaper: {
    borderRadius: '0.625rem',
    overflow: 'auto'
  }
}));
