import React, { useEffect, useState } from 'react';
import { Box, Theme, Typography, makeStyles, Grid, Divider, TextField } from '@material-ui/core';
import { Dialog } from '../../../components/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { updateFormThunk } from '../../../redux/thunk/thunks';
import { LoadingButton } from '../../../components/LoadingButton';
import { RootState } from '../../../redux/redux-store';

interface FormTitleDialogProps {
    formId: any,
    open: boolean;
    handleClose: () => void;
}

export const FormTitleDialog = ({ formId, open, handleClose }: FormTitleDialogProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const initialValues: any = {
        title: formId.title,
        description: formId.description,
    };
    const [isError, setError] = useState(false);
    const [input, setInput] = useState(null);
    const [domain, setDomain] = useState<string[]>([]);
    const [tagIds, setTagIds] = useState<any>([]);
    const [collection, setCollection] = useState<string[]>([]);
    useEffect(() => {
        for (let i = 0; i < formId.domains.length; i++) {
            domain.push(formId.domains[i].id);
        }
        for (let i = 0; i < formId.tags.length; i++) {
            tagIds.push(formId.tags[i].id);
        }
        for (let i = 0; i < formId.collections.length; i++) {
            collection.push(formId.collections[i].id);
        }
    }, [])

    const success: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.updateFormFieldSuccess)
    useEffect(() => {
        if (success) {
            handleClose();
        }
    })

    const formSchema = Yup.object().shape({
        title: Yup.string().required('Required').max(100, ""),
        description: Yup.string().required('Required').max(500, ""),
    });

    const handleSubmit = async (values: any, actions: any) => {
        actions.setSubmitting(true);
        const data = {
            title: values.title,
            description: values.description.replace('/\r?\n|\r/g'),
            entry_page_header: formId.entry_page_header,
            entry_page_body: formId.entry_page_body,
            entry_page_video_url: formId.entry_page_video_url,
            tags: tagIds,
            domains: domain,
            collections: collection
        }
        await dispatch(updateFormThunk(formId.id, data));
        actions.setSubmitting(false);
    }

    const handleChange = (value: any, no: any) => {
        if (no === 0 && value.length > 100) {
            setError(true); setInput(no);
        } else if (no === 1 && value.length > 500) {
            setError(true); setInput(no);
        } else {
            setError(false); setInput(no);
        }
    }

    return (
        <Dialog handleClose={handleClose} open={open}>
            <Box display="flex" flexDirection="column">
                <Box pt={3} px={3}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validateOnBlur={false}
                        validateOnChange={false}
                        validationSchema={formSchema}
                    >
                        {({ errors, isSubmitting, setFieldValue, setSubmitting, touched, values }) => {
                            return (
                                <Form style={{ width: '500px' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography color="textSecondary" variant="h2">Title & Description</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box pb={1.0}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Title
                                                    </Typography>
                                                </Box>

                                                <Field fullWidth style={{ background: isError && input === 0 ? '#fff1f1' : 'unset' }}
                                                    onInput={(e: any) => handleChange(e.target.value, 0)}
                                                    as={TextField}
                                                    inputProps={{ maxLength: 101 }}
                                                    className={classes.textField}
                                                    name="title"
                                                    size="small"
                                                    variant="outlined"
                                                />
                                                <Box display='flex' justifyContent='space-between'>
                                                    <Typography color={"error"} variant="subtitle2">
                                                        {errors.title && touched.title && errors.title}
                                                    </Typography>
                                                    <Typography style={{ color: values.title?.length > 100 ? '#911717' : '#0000008a' }} variant="subtitle2">
                                                        {values.title?.length === 101 ? 100 : values.title?.length}/100
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box pb={1.0}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Description
                                                    </Typography>
                                                </Box>

                                                <Field fullWidth multiline rows={4} style={{ background: isError && input === 1 ? '#fff1f1' : 'unset' }}
                                                    onInput={(e: any) => handleChange(e.target.value, 1)}
                                                    as={TextField}
                                                    inputProps={{ maxLength: 501 }}
                                                    className={classes.textField}
                                                    name="description"
                                                    size="small"
                                                    variant="outlined"
                                                />
                                                <Box display='flex' justifyContent='space-between'>
                                                    <Typography color="error" variant="subtitle2">
                                                        {errors.description && touched.description && errors.description}
                                                    </Typography>
                                                    <Typography style={{ color: values.description?.length > 500 ? '#911717' : '#0000008a' }} variant="subtitle2">
                                                        {values.description?.length === 501 ? 500 : values.description?.length}/500
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box display="flex" justifyContent="space-between">
                                                <LoadingButton
                                                    className={classes.secondaryButton}
                                                    onClick={handleClose}
                                                    variant="contained"
                                                >
                                                    Cancel
                                                </LoadingButton>
                                                <LoadingButton
                                                    className={classes.primaryButton}
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    loading={isSubmitting}
                                                    type="submit"
                                                    variant="contained"
                                                >
                                                    Save
                                                </LoadingButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                </Box>
            </Box>
        </Dialog >
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    topBox: {
        borderRadius: '0.625rem 0.625rem 0 0'
    },
    bottomBox: {
        borderRadius: '0 0 0.625rem 0.625rem'
    },
    textField: {
        width: '100%'
    },
    primaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#fff',
        minWidth: '120px',
        marginBottom: '20px'
    },
    secondaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#0060B2',
        marginBottom: '20px',
        minWidth: '120px'
    },
    deleteButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: 'red',
        minWidth: '120px'
    },
    createCollectionTextField: {
        maxWidth: '40rem',
        width: '80vw'
    }
}));
