import {
  ExitPage,
  FieldOptionInput,
  FieldOptionUpdate,
  FormFieldInput,
  FormFieldUpdate,
  FormSearchResults,
  UuidType,
  FormField
} from '../../shared/domain';
import { FlowproThunkDispatch, RootState } from '../redux-store';
import {
  FormsType, setCalculationPagesAction, getDuplicatedFormAction, setDisplayFormAction, setCalculationMetricFieldsAction, getFormResponseDetailsAction,
  setEditingExitPageAction, setEditingExitPageCalculationsAction, setEditingExitPageFieldLogicRulesAction, calculationMetricFieldsAction,
  setEditingExitPagesAction, setEditingFieldLogicRulesAction, setEditingFormAction, setEditingFormFieldAction, getSharingUserAction, getFormInstanceDetailsAction,
  setEditingFormFieldsAction, addNewTag, getAllCollectionListAction, getFormVersionsAction, getFormsCollectionsAction, usersAssignmentAction,
  getFormRecurrenceInfoAction, getFormMetricGraphsAction, getFormResultsAction, getCompletedFormResultsAction, getCompletedCollectionResultsAction,
  getFormStatusCountAction
} from '../data/data-actions';
import {
  creatingNewFormSuccessAction, deletingFormFieldAction, deletingFormFieldSuccessAction, setErrorAction, setLoadingAction,
  setSuccessAction, setUpdatingExitPageLogicRuleIdAction, snackbarAlertAction, updatingFieldLogicRuleAction,
  updatingFieldLogicRuleFinishedAction, updatingFormFieldAction, updatingFormFieldSuccessAction,
} from '../display/display-actions';
import { deleteHttp, getHttp, postHttp, putHttp } from '../api';
import { getFormsAction } from '../data/data-actions';
import { push } from 'connected-react-router';
import { updateCollectionsThunk } from './collection-thunks';

export interface GetFormsProps {
  collectionId?: UuidType;
  formsType: FormsType;
  allForms?: boolean;
  library?: boolean;
  order?: string;
  page?: number;
  pageSize?: number;
  search?: string;
  sort?: string;
  favourites?: boolean;
}
export const getFormsThunk = ({
  collectionId = '',
  formsType,
  allForms,
  library,
  order = 'DESC',
  page = 0,
  pageSize = 10,
  search = '',
  sort = 'updated_at',
  favourites = true,
}: any) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const forms: any = await getHttp(
      `/form?domain_id=${collectionId}&allForms=${allForms ? 'true' : 'false'}&library=${library ? 'true' : 'false'
      }&search=${search}&page=${page}&pageSize=${pageSize}&sort=${sort}&order=${order}&favourites=${favourites}&organization_id=${loggedInUser && loggedInUser.organization_id ? loggedInUser.organization_id : ''}&form_version_id=${form_version_id}`
    );
    if (forms) {
      forms.collectionId = collectionId;
      forms.favourites = favourites;
      forms.order = order;
      forms.page = page;
      forms.pageSize = pageSize;
      forms.sort = sort;
      forms.search = search;
      dispatch(getFormsAction(formsType, forms));
    } else {
      dispatch(snackbarAlertAction(`Error loading forms`, 'error'));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error loading forms: ${e.message}`, 'error'));
    }
  }
};

export const formUpdateThunk = (formUpdate: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const check: any = sessionStorage.getItem('check');
    const form_version_id = sessionStorage.getItem('version');
    formUpdate.form_version_id = form_version_id;
    dispatch(setLoadingAction('updateFormLoading', true, 'updateFormError'));
    const updateForm = await putHttp(`/form/${formUpdate.id}`, formUpdate);
    check && check === 'SAVE' && dispatch(setSuccessAction('autoSaveSuccess', true));
    dispatch(setLoadingAction('updateFormLoading', false, 'updateFormError'));
    // dispatch(snackbarAlertAction(`Update form Successfully`, 'success'));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setLoadingAction('updateFormLoading', false, 'updateFormError'));
      dispatch(snackbarAlertAction(`Error updating form: ${e.message}`, 'error'));
    }
  }
};
export const formCreationThunk = () => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const newForm = await postHttp(`/form/`, {
      //sets the default values for new forms
      title: 'Untitled Form',
      description: 'Untitled Form Description',
      published: false,
      parentFormId: null,
      organization_id: loggedInUser && loggedInUser.organization_id
    });
    if (newForm) {
      await dispatch(getFormVersionsThunk(newForm.id));
      sessionStorage.setItem('version', newForm.form_version_id);
      sessionStorage.setItem('type_id', newForm.form_type);
      await dispatch(creatingNewFormSuccessAction());
      dispatch(push({ pathname: `/form-builder/${newForm.id}/fields`, state: true }));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(e.message, 'error'));
      dispatch(setErrorAction('createFormError', e.message));
    }
  }
};

export const updateFormThunk = (form_id: any, data: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    dispatch(addNewTag(null));
    const form_version_id = sessionStorage.getItem('version');
    const newForm = await putHttp(`/form/${form_id}`, {
      title: data.title,
      description: data.description,
      entry_page_header: data.entry_page_header,
      entry_page_body: data.entry_page_body,
      entry_page_video_url: data.entry_page_video_url,
      tags: data.tags,
      domains: data.domains,
      collections: data.collections,
      form_version_id: form_version_id
    });
    dispatch(fetchFormToEditByIdThunk(form_id));
    dispatch(setSuccessAction('updateFormFieldSuccess', true));
    // dispatch(push({ pathname: `/form-builder/${form_id}/fields`, state: true }));
    // dispatch(snackbarAlertAction(`Form Updated Successfully`, 'success'));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(e.message, 'error'));
      dispatch(setErrorAction('createFormError', e.message));
    }
  }
};

export const deleteFormsThunk = (formIds: UuidType[]) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    dispatch(setLoadingAction('deleteFormsLoading', true, 'deleteFormsError'));
    for (const formId of formIds) {
      await deleteHttp(`/form/${formId}?form_version_id=${form_version_id}`);
    }
    dispatch(updateCollectionsThunk());
    dispatch(updateFormsThunk());
    dispatch(setSuccessAction('deleteFormsSuccess', true));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error deleting form: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('deleteFormsLoading', false, 'deleteFormsError'));
  }
};

export const deleteFormThunk = (data: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    data.form_version_id = form_version_id;
    await postHttp(`/form/deleteForms`, data);
    dispatch(push({ pathname: `/forms` }));
    dispatch(setSuccessAction('getForms', true));
    dispatch(snackbarAlertAction(`Deleted form successfully`, 'success'));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const deleteNewFormThunk = (data: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    data.form_version_id = form_version_id;
    await postHttp(`/form/deleteForms`, data);
    dispatch(push({ pathname: `/forms` }));
    dispatch(setSuccessAction('getForms', true));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

/**Remove fav. forms */
export const removeFevFormThunk = (data: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    data.user_role_id = loggedInUser.id;
    await postHttp(`/form/removeFromFavourite`, data);
    dispatch(setSuccessAction('getForms', true));
    dispatch(snackbarAlertAction(`Favorite removed successfully`, 'success'));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

/**Remove fav. forms & collection */
export const removeFevFormCollectionThunk = (data: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    data.user_role_id = loggedInUser.id;
    await postHttp(`/form/removeFormsAndColFromFavourites`, data);
    dispatch(setSuccessAction('getForms', true));
    dispatch(snackbarAlertAction(`Favorite removed successfully`, 'success'));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

// updates all loaded form lists
interface UpdateFormsProps {
  resetPage?: boolean;
}
export const updateFormsThunk = (props?: UpdateFormsProps) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    const formSearchResults = Object.entries(getState().DATA_REDUCER.formSearchResults);
    for (const result of formSearchResults) {
      if (result[1]) {
        const forms: FormSearchResults = await getHttp(
          `/form?collection=${result[1].collectionId}&allForms=${result[1].library && result[1].collectionId === '' ? true : false
          }&library=${result[1].library ? 'true' : 'false'}&search=${result[1].search}&page=${props?.resetPage ? 0 : result[1].page
          }&pageSize=${result[1].pageSize}&sort=${result[1].sort}&order=${result[1].order}&form_version_id=${form_version_id}`
        );
        dispatch(getFormsAction(result[0] as FormsType, forms));
      }
    }
    dispatch(setSuccessAction('updateFormsSuccess', true));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error updating form lists: ${e.message}`, 'error'));
    }
  }
};

export const fetchFormToEditByIdThunk = (formId: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    dispatch(addNewTag(null));
    dispatch(setSuccessAction('updateFormFieldSuccess', false));
    dispatch(setLoadingAction('getFormLoading', true, 'createFormError'));
    const form = await getHttp(`/form/${formId}?form_version_id=${form_version_id}`);
    dispatch(setSuccessAction('updateFormFieldSuccess', false));
    if (form) {
      dispatch(setLoadingAction('getFormLoading', false, 'createFormError'));
      dispatch(setEditingFormAction(form));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('getFormError', e.message));
    }
  }
};

export const fetchFormToDisplayByIdThunk = (formId: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    dispatch(setLoadingAction('getFormToDisplayLoading', true, 'getFormToDisplayError'));
    const form = await getHttp(`/form/${formId}?form_version_id=${form_version_id}`);
    if (form) {
      dispatch(setLoadingAction('getFormToDisplayLoading', false, 'getFormToDisplayError'));
      dispatch(setDisplayFormAction(form));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error retrieving form: ${e.message}`, 'error'));
      dispatch(setErrorAction('getFormToDisplayError', e.message));
    }
  }
};

export const getFormFieldsByFormIdThunk = (formId: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    // dispatch(setLoadingAction('getFormFieldsLoading', true, 'getFormFieldsError'));
    const formFields: FormField[] = await getHttp(`/form/${formId}/fields?form_version_id=${form_version_id}`);
    if (formFields) {
      await dispatch(setLoadingAction('getFormFieldsLoading', false, 'getFormFieldsError'));
      await dispatch(setEditingFormFieldsAction(formFields));
      //if a form field is saved as the editingFormField in redux update it as well
      const editingFormField = await getState().DATA_REDUCER.editingFormField;
      if (editingFormField) {
        const updatedFfo = await formFields.filter((formField) => formField.id === editingFormField.id);
        await dispatch(setEditingFormFieldAction(updatedFfo[0]));
      }
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('getFormFieldsError', e.message));
    }
  }
};

export const createFormFieldThunk = (
  formId: UuidType,
  fieldInput: FormFieldInput,
  fieldOptionsInput: FieldOptionInput[] | null
) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    dispatch(setLoadingAction('createFormFieldLoading', true, 'createFormFieldError'));
    const formField = await postHttp(`/form/${formId}/field`, {
      //sets default values for new form fields
      field_input: fieldInput,
      field_options_input: fieldOptionsInput,
      form_version_id: form_version_id
    });
    if (formField) {
      //If successful refetch fields
      dispatch(setLoadingAction('createFormFieldLoading', false, 'createFormFieldError'));
      dispatch(getFormFieldsByFormIdThunk(formId));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('createFormFieldError', e.message));
    }
  }
};

export const updateFormFieldThunk = (
  fieldUpdate: FormFieldUpdate,
  formId: UuidType,
  fieldOptionsUpdate?: FieldOptionUpdate[] | null,
  fieldOptionsCreate?: FieldOptionInput[] | null,
  fieldOptionsDelete?: UuidType[] | null
) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  dispatch(setLoadingAction('updateFormFieldLoading', true, 'updateFormFieldError'));
  dispatch(updatingFormFieldAction(fieldUpdate.id));
  delete fieldUpdate?.form_field_options;
  delete (fieldUpdate as any)?.resource_ids;
  delete (fieldUpdate as any)?.resources;
  //restructure updates
  //this restructuring should probably be handled on the backend
  const updates = fieldOptionsUpdate?.map((option: FieldOptionUpdate) => ({
    id: option?.id,
    option_text: option?.option_text,
    option_value: option?.option_value,
    allow_user_input: option?.allow_user_input
  }));

  try {
    const form_version_id = sessionStorage.getItem('version');
    const updateField = await putHttp(`/form/field/${fieldUpdate?.id}`, {
      field_update: fieldUpdate,
      field_options_update: updates,
      field_options_create: fieldOptionsCreate,
      field_options_delete: fieldOptionsDelete,
      form_version_id: form_version_id
    });
    if (updateField) {
      //if update is successfull refetch fields
      dispatch(setLoadingAction('updateFormFieldLoading', false, 'updateFormFieldError'));
      dispatch(updatingFormFieldSuccessAction());
      formId && dispatch(getFormFieldsByFormIdThunk(formId));
      dispatch(snackbarAlertAction(`Form field changes saved successfully`, 'success'));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('updateFormFieldError', e.message));
    }
  }
};

export const putResourceFilesFormFieldThunk = (data: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    data.form_version_id = form_version_id;
    await putHttp(`/form/field/${data.form_field_id}/resource`, data);
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('deleteFormFieldError', e.message));
    }
  }
};

export const deleteFormFieldThunk = (fieldId: UuidType, formId: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  dispatch(setLoadingAction('deleteFormFieldLoading', true, 'deleteFormFieldError'));
  dispatch(deletingFormFieldAction(fieldId));
  try {
    const form_version_id = sessionStorage.getItem('version');
    const deleteField = await deleteHttp(`/form/field/${fieldId}?form_version_id=${form_version_id}`);
    if (deleteField) {
      //if delete is successfull refetch fields
      dispatch(getFormFieldsByFormIdThunk(formId));
      dispatch(setLoadingAction('deleteFormFieldLoading', false, 'deleteFormFieldError'));
      dispatch(deletingFormFieldSuccessAction());
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('deleteFormFieldError', e.message));
    }
  }
};

export const getFieldLogicRulesThunk = (formFieldId: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    dispatch(setLoadingAction('getFieldLogicRulesLoading', true, 'getFieldLogicRulesError'));
    const fieldLogicRules = await getHttp(`/form/field/${formFieldId}/logic?form_version_id=${form_version_id}`);
    if (fieldLogicRules) {
      dispatch(setEditingFieldLogicRulesAction(fieldLogicRules));
      dispatch(setLoadingAction('getFieldLogicRulesLoading', false, 'getFieldLogicRulesError'));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('getFieldLogicRulesError', e.message));
    }
  }
};

export const createFieldLogicRuleThunk = (
  formFieldId: UuidType,
  targetFormFieldId: UuidType
) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    dispatch(setLoadingAction('createFieldLogicRuleLoading', true, 'createFieldLogicRuleError'));
    const fieldLogicRule = await postHttp(`/form/field/${formFieldId}/logic`, {
      target_form_field_id: targetFormFieldId,
      form_version_id: form_version_id
    });
    if (fieldLogicRule) {
      dispatch(setLoadingAction('createFieldLogicRuleLoading', false, 'createFieldLogicRuleError'));
      dispatch(getFieldLogicRulesThunk(formFieldId));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('createFieldLogicRuleError', e.message));
    }
  }
};
export const updateFieldLogicRuleThunk = (
  formFieldId: UuidType,
  fieldLogicId: UuidType,
  fieldLogicUpdate: any
) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  dispatch(updatingFieldLogicRuleAction(fieldLogicId));
  try {
    const form_version_id = sessionStorage.getItem('version');
    fieldLogicUpdate.form_version_id = form_version_id;
    dispatch(setLoadingAction('updateFieldLogicRuleLoading', true, 'updateFieldLogicRuleError'));
    const fieldLogicRuleUpdate = await putHttp(
      `/form/field/${formFieldId}/logic/${fieldLogicId}`,
      fieldLogicUpdate
    );
    if (fieldLogicRuleUpdate) {
      dispatch(setLoadingAction('updateFieldLogicRuleLoading', false, 'updateFieldLogicRuleError'));
      dispatch(getFieldLogicRulesThunk(formFieldId));
      dispatch(updatingFieldLogicRuleFinishedAction());
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(e.message, 'error'));
      dispatch(setErrorAction('updateFieldLogicRuleError', e.message));
      dispatch(updatingFieldLogicRuleFinishedAction());
    }
  }
};
export const deleteFieldLogicRuleThunk = (fieldLogicId: UuidType, fieldId: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    dispatch(setLoadingAction('deleteFieldLogicRuleLoading', true, 'deleteFieldLogicRuleError'));
    const fieldLogicRuleDelete = await deleteHttp(`/form/field/logic/${fieldLogicId}?form_version_id=${form_version_id}`);
    if (fieldLogicRuleDelete) {
      dispatch(setLoadingAction('deleteFieldLogicRuleLoading', false, 'deleteFieldLogicRuleError'));
      dispatch(getFieldLogicRulesThunk(fieldId));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(e.message, 'error'));
      dispatch(setErrorAction('deleteFieldLogicRuleError', e.message));
    }
  }
};

export const getExitPagesByFormIdThunk = (formId: UuidType, form_version_id: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    dispatch(setLoadingAction('getExitPagesLoading', true, 'getExitPagesError'));
    const exitPages = await getHttp(`/form/${formId}/exit-page?form_version_id=${form_version_id}`);
    if (exitPages) {
      dispatch(setLoadingAction('getExitPagesLoading', false, 'getExitPagesError'));
      dispatch(setEditingExitPagesAction(exitPages.exit_pages));
      //find the default exitpage and set in editing exit page if a page is not already in "editingExitPage"
      // if (exitPages) { //!getState().DATA_REDUCER.editingExitPage
      //   const defaultExitPage = exitPages.exit_pages.find(
      //     (exitPage: ExitPage) => exitPage.is_default === true
      //   );
      //   dispatch(setEditingExitPageAction(defaultExitPage));
      // }
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('getExitPagesError', e.message));
    }
  }
};

/**Calculation CRUD operations */
export const getCalculationPagesByFormIdThunk = (formId: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    dispatch(setLoadingAction('getExitPagesLoading', true, 'getExitPagesError'));
    const calculationPages = await getHttp(`/metrics/${formId}?form_version_id=${form_version_id}`);
    if (calculationPages) {
      dispatch(setLoadingAction('getExitPagesLoading', false, 'getExitPagesError'));
      dispatch(setCalculationPagesAction(calculationPages.data));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('getExitPagesError', e.message));
    }
  }
};

export const saveCalculationThunk = (data: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    data.form_version_id = form_version_id;
    const newCalculation = await postHttp(`/metrics/addMetricToForm`, data);
    if (newCalculation) {
      dispatch(setErrorAction('createMetricError', null));
      dispatch(getCalculationPagesByFormIdThunk(data.form_id));
      // dispatch(snackbarAlertAction(`Save calculation successfully`, 'success'));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction('Cannot update! This metric name is already in use!', 'error'));
      dispatch(setErrorAction('createMetricError', e.message));
    }
  }
};

export const editCalculationThunk = (metric_id: any, data: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    const check: any = sessionStorage.getItem('check');
    data.form_version_id = form_version_id;
    const editCalculation = await putHttp(`/metrics/form/${metric_id}`, data);
    if (editCalculation) {
      dispatch(setErrorAction('createMetricError', null));
      sessionStorage.removeItem('onChange');
      dispatch(getCalculationPagesByFormIdThunk(data.form_id));
      // dispatch(snackbarAlertAction(`Update calculation successfully`, 'success'));
      check && check === 'SAVE' && dispatch(setSuccessAction('autoSaveSuccess', true));
      return 200;
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction('Cannot update! This metric name is already in use!', 'error'));
      dispatch(setErrorAction('createMetricError', e.message));
      return 500;
    }
  }
};

export const deleteCalculationsThunk = (metricId: any, form_id: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    await deleteHttp(`/metrics/form/${metricId}?form_version_id=${form_version_id}`);
    dispatch(getCalculationPagesByFormIdThunk(form_id));
    // dispatch(snackbarAlertAction(`Delete calculation successfully`, 'success'));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(e.message, 'error'));
      dispatch(setErrorAction('createFormError', e.message));
    }
  }
};

/**Metric CRUD operations */
export const getRemainingMetricByFormIdMetricIdThunk = (formId: UuidType, metricId: string) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    dispatch(setSuccessAction('saveCalculationMetricSuccess', false));
    const metricFields = await getHttp(`/form/${formId}/fields/${metricId}?form_version_id=${form_version_id}`);
    if (metricFields) {
      dispatch(setSuccessAction('saveCalculationMetricSuccess', false));
      dispatch(setCalculationMetricFieldsAction(metricFields.data));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('getExitPagesError', e.message));
    }
  }
};

export const getMetricByFormIdMetricIdThunk = (formId: UuidType, metricId: string) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    dispatch(setErrorAction('createMetricError', null));
    dispatch(setSuccessAction('saveCalculationMetricSuccess', false));
    const addedMetricFields = await getHttp(`/calculation/getMetricCalculationData?metric_id=${metricId}&form_id=${formId}&form_version_id=${form_version_id}`);
    if (addedMetricFields) {
      dispatch(setSuccessAction('saveCalculationMetricSuccess', false));
      dispatch(calculationMetricFieldsAction(addedMetricFields.data));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('getExitPagesError', e.message));
    }
  }
};

export const addMetricQuestionThunk = (form_id: any, data: any) => async (
  dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    data.form_version_id = form_version_id;
    await postHttp(`/calculation/createCalculationForFormField`, data);
    dispatch(setSuccessAction('saveCalculationMetricSuccess', true));
    dispatch(calculationMetricFieldsAction(null));
    dispatch(getMetricByFormIdMetricIdThunk(form_id, data.metric_id));
    dispatch(getRemainingMetricByFormIdMetricIdThunk(form_id, data.metric_id));
    dispatch(snackbarAlertAction(`Calculation question added successfully`, 'success'));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('createFieldLogicRuleError', e.message));
    }
  }
};

export const editMetricQuestionThunk = (form_id: any, data: any) => async (
  dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    data.form_version_id = form_version_id;
    await putHttp(`/calculation/editCalculationForFormField`, data);
    dispatch(setSuccessAction('saveCalculationMetricSuccess', true));
    dispatch(calculationMetricFieldsAction(null));
    dispatch(getMetricByFormIdMetricIdThunk(form_id, data.metric_id));
    dispatch(getRemainingMetricByFormIdMetricIdThunk(form_id, data.metric_id));
    // dispatch(snackbarAlertAction(`Update calculation question successfully`, 'success'));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('createFieldLogicRuleError', e.message));
    }
  }
};

export const editMultiMetricQuestionThunk = (form_id: any, data: any, metric_id: string) => async (
  dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    const check: any = sessionStorage.getItem('check');
    await putHttp(`/calculation/editMultipleCalculationFields`, { calculation_data: data, form_version_id: form_version_id });
    check && check === 'SAVE' && dispatch(setSuccessAction('autoSaveSuccess', true));
    dispatch(setSuccessAction('saveCalculationMetricSuccess', true));
    // await dispatch(calculationMetricFieldsAction(null));
    // dispatch(getMetricByFormIdMetricIdThunk(form_id, metric_id));
    dispatch(getRemainingMetricByFormIdMetricIdThunk(form_id, metric_id));
    // dispatch(snackbarAlertAction(`Update calculation question successfully`, 'success'));
    sessionStorage.removeItem('calculation');
    sessionStorage.removeItem('metric_id');
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('createFieldLogicRuleError', e.message));
    }
  }
};

export const autoEditMultiMetricQuestionThunk = (form_id: any, data: any, metric_id: string) => async (
  dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    const check: any = sessionStorage.getItem('check');
    await putHttp(`/calculation/editMultipleCalculationFields`, { calculation_data: data, form_version_id: form_version_id });
    dispatch(getRemainingMetricByFormIdMetricIdThunk(form_id, metric_id));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('createFieldLogicRuleError', e.message));
    }
  }
};

export const deleteMetricFieldThunk = (fieldId: any, metricId: any, form_id: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    await deleteHttp(`/calculation/field/${fieldId}/${metricId}?form_version_id=${form_version_id}`);
    dispatch(setSuccessAction('saveCalculationMetricSuccess', true));
    await dispatch(getMetricByFormIdMetricIdThunk(form_id, metricId));
    await dispatch(getRemainingMetricByFormIdMetricIdThunk(form_id, metricId));
    dispatch(snackbarAlertAction(`Deleted calculation question successfully`, 'success'));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(e.message, 'error'));
      dispatch(setErrorAction('createFormError', e.message));
    }
  }
};

export const createExitPageThunk = (formId: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    dispatch(setLoadingAction('createExitPageLoading', true, 'createExitPageError'));
    const exitPage = await postHttp(`/form/exit-page`, { form_id: formId, form_version_id: form_version_id });
    if (exitPage) {
      //after creation fetch all exit pages
      await dispatch(getExitPagesByFormIdThunk(formId, form_version_id));
      await dispatch(setLoadingAction('createExitPageLoading', false, 'createExitPageError'));
      await dispatch(setSuccessAction('saveExitPage', true));
      //set the newly created exit page as the currently editing exitpage
      dispatch(setEditingExitPageAction(exitPage));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('createExitPageError', e.message));
      dispatch(snackbarAlertAction(`Error creating exit page: ${e.message}`, 'error'));
    }
  }
};

export const updateExitPageThunk = (exitPageUpdate: any, formId: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    const check: any = sessionStorage.getItem('check');
    exitPageUpdate.form_version_id = form_version_id;
    dispatch(setLoadingAction('updateExitPageLoading', true, 'updateExitPageError'));
    const exitPage = await putHttp(`/form/exit-page/${exitPageUpdate.id}`, exitPageUpdate);
    if (exitPage) {
      sessionStorage.removeItem('onChangeExit');
      dispatch(setLoadingAction('updateExitPageLoading', false, 'updateExitPageError'));
      check && check === 'SAVE' && dispatch(setSuccessAction('autoSaveSuccess', true));
      // dispatch(snackbarAlertAction(`Update exit page successfully`, 'success'));
      dispatch(getExitPagesByFormIdThunk(formId, form_version_id));
      return 200;
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('updateExitPageError', e.message));
      dispatch(snackbarAlertAction(e.message, 'error'));
    }
  }
};

export const getAllCollectionListThunk = () => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    const collectionList = await getHttp(`/collection?library=true&search=&page=0&pageSize=0&form_version_id=${form_version_id}`);
    dispatch(getAllCollectionListAction(collectionList.collections));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('getExitPageFieldLogicRulesError', e.message));
      dispatch(snackbarAlertAction(e.message, 'error'));
    }
  }
};

export const getAllCollectionsThunk = () => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const collectionList = await getHttp(`/collection/getCustomOrgCollections?organization_id=${loggedInUser && loggedInUser.organization_id}`);
    dispatch(getAllCollectionListAction(collectionList.data));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('getExitPageFieldLogicRulesError', e.message));
      dispatch(snackbarAlertAction(e.message, 'error'));
    }
  }
};

export const getExitPageLogicRulesThunk = (exitPageId: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    dispatch(setLoadingAction('getExitPageFieldLogicRulesLoading', true, 'getExitPageFieldLogicRulesError'));
    const exitPageLogicRules = await getHttp(`/form/exit-page/${exitPageId}/field-logic?form_version_id=${form_version_id}`);
    if (exitPageLogicRules) {
      //after creation fetch all exit page logic
      dispatch(setEditingExitPageFieldLogicRulesAction(exitPageLogicRules.exit_page_field_logic));
      dispatch(
        setLoadingAction(
          'getExitPageFieldLogicRulesLoading',
          false,
          'getExitPageFieldLogicRulesError'
        )
      );
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('getExitPageFieldLogicRulesError', e.message));
      dispatch(snackbarAlertAction(e.message, 'error'));
    }
  }
};

export const createExitPageLogicThunk = (exitPageId: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    dispatch(
      setLoadingAction('createExitPageFieldLogicLoading', true, 'createExitPageFieldLogicError')
    );
    const exitPageLogic = await postHttp(`/form/exit-page/${exitPageId}/field-logic`, { form_version_id: form_version_id });
    if (exitPageLogic) {
      //after creation fetch all exit page logic
      dispatch(getExitPageLogicRulesThunk(exitPageId));
      dispatch(
        setLoadingAction('createExitPageFieldLogicLoading', false, 'createExitPageFieldLogicError')
      );
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('createExitPageFieldLogicError', e.message));
      dispatch(snackbarAlertAction(e.message, 'error'));
    }
  }
};

export const updateExitPageLogicThunk = (
  fieldLogicId: UuidType,
  logicUpdate: any,
  exitPageId: UuidType
) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    logicUpdate.form_version_id = form_version_id;
    dispatch(setUpdatingExitPageLogicRuleIdAction(fieldLogicId));
    dispatch(
      setLoadingAction('updateExitPageFieldLogicLoading', true, 'updateExitPageFieldLogicError')
    );
    const exitPageLogic = await putHttp(`/form/exit-page/field-logic/${fieldLogicId}`, logicUpdate);
    if (exitPageLogic) {
      //Refetch logic after update
      dispatch(getExitPageLogicRulesThunk(exitPageId));
      dispatch(
        setLoadingAction('updateExitPageFieldLogicLoading', false, 'updateExitPageFieldLogicError')
      );
      dispatch(setUpdatingExitPageLogicRuleIdAction(null));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setUpdatingExitPageLogicRuleIdAction(null));
      dispatch(setErrorAction('updateExitPageFieldLogicError', e.message));
      dispatch(snackbarAlertAction(e.message, 'error'));
    }
  }
};

export const deleteExitPageThunk = (exitPageId: UuidType, formId: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    dispatch(setLoadingAction('deleteExitPageLoading', true, 'deleteExitPageError'));
    const resp = await deleteHttp(`/form/exit-page/${exitPageId}?form_version_id=${form_version_id}`);
    if (resp) {
      dispatch(setLoadingAction('deleteExitPageLoading', false, 'deleteExitPageError'));
      //Refetch exit pages after delete
      dispatch(getExitPagesByFormIdThunk(formId, form_version_id));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('deleteExitPageError', e.message));
      dispatch(snackbarAlertAction(e.message, 'error'));
      dispatch(setLoadingAction('deleteExitPageLoading', false, 'deleteExitPageError'));
    }
  }
};

export const deleteExitPageLogicThunk = (fieldLogicId: UuidType, exitPageId: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    dispatch(
      setLoadingAction('deleteExitPageFieldLogicLoading', true, 'deleteExitPageFieldLogicError')
    );
    const resp = await deleteHttp(`/form/exit-page/field-logic/${fieldLogicId}?form_version_id=${form_version_id}`);
    if (resp) {
      dispatch(
        setLoadingAction('deleteExitPageFieldLogicLoading', false, 'deleteExitPageFieldLogicError')
      );
      //Refetch logic after delete
      dispatch(getExitPageLogicRulesThunk(exitPageId));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('deleteExitPageFieldLogicError', e.message));
      dispatch(snackbarAlertAction(e.message, 'error'));
    }
  }
};

export const getExitPageCalculationsThunk = (exitPageId: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    dispatch(
      setLoadingAction('getExitPageCalculationsLoading', true, 'getExitPageCalculationsError')
    );
    const exitPageCalculations = await getHttp(`/form/exit-page/${exitPageId}/metric-logic?form_version_id=${form_version_id}`);
    if (exitPageCalculations) {
      dispatch(setEditingExitPageCalculationsAction(exitPageCalculations.exit_page_metric_logic));
      dispatch(
        setLoadingAction('getExitPageCalculationsLoading', false, 'getExitPageCalculationsError')
      );
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('getExitPageCalculationsError', e.message));
      dispatch(snackbarAlertAction(e.message, 'error'));
    }
  }
};

export const createExitPageCalculationsThunk = (exitPageId: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    dispatch(
      setLoadingAction('createExitPageCalculationLoading', true, 'createExitPageCalculationError')
    );
    const exitPageCalculations = await postHttp(`/form/exit-page/${exitPageId}/metric-logic`, { form_version_id: form_version_id });
    if (exitPageCalculations) {
      //refetch calculations
      dispatch(getExitPageCalculationsThunk(exitPageId));
      dispatch(
        setLoadingAction(
          'createExitPageCalculationLoading',
          false,
          'createExitPageCalculationError'
        )
      );
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(
        setLoadingAction('createExitPageCalculationLoading', false, 'createExitPageCalculationError')
      );
      dispatch(setErrorAction('createExitPageCalculationError', e.message));
      dispatch(snackbarAlertAction(e.message, 'error'));
    }
  }
};

export const updateExitPageCalculationThunk = (
  exitPageId: UuidType,
  calculationId: UuidType,
  calculationUpdate: any
) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    calculationUpdate.form_version_id = form_version_id;
    dispatch(
      setLoadingAction('updateExitPageCalculationLoading', true, 'updateExitPageCalculationError')
    );
    const updateExitPageCalculation = await putHttp(
      `/form/exit-page/metric-logic/${calculationId}`,
      calculationUpdate
    );
    if (updateExitPageCalculation) {
      dispatch(getExitPageCalculationsThunk(exitPageId));
      dispatch(
        setLoadingAction(
          'updateExitPageCalculationLoading',
          false,
          'updateExitPageCalculationError'
        )
      );
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('updateExitPageCalculationError', e.message));
      dispatch(snackbarAlertAction(e.message, 'error'));
    }
  }
};

export const deleteExitPageCalculationThunk = (
  exitPageId: UuidType,
  calculationId: UuidType
) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    dispatch(
      setLoadingAction('deleteExitPageCalculationLoading', true, 'deleteExitPageCalculationError')
    );
    const deleteExitPageCalculation = await deleteHttp(
      `/form/exit-page/metric-logic/${calculationId}?form_version_id=${form_version_id}`
    );
    if (deleteExitPageCalculation) {
      dispatch(getExitPageCalculationsThunk(exitPageId));
      dispatch(
        setLoadingAction(
          'deleteExitPageCalculationLoading',
          false,
          'deleteExitPageCalculationError'
        )
      );
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(setErrorAction('deleteExitPageCalculationError', e.message));
      dispatch(snackbarAlertAction(e.message, 'error'));
    }
  }
};

export const duplicateFormThunk = (formId: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    setLoadingAction('duplicateFormLoading', true, 'duplicateFormError');
    const duplicatedForm = await postHttp(`/form/${formId}/duplicate`, { form_version_id: form_version_id });
    dispatch(getDuplicatedFormAction(duplicatedForm));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error creating form: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('duplicateFormLoading', false, 'duplicateFormError'));
  }
};

export const markFavoriteFormThunk = (formId: UuidType, form_version_id: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    await getHttp(`/form/${formId}/mark-as-favourite?form_version_id=${form_version_id}`);
    dispatch(fetchFormToEditByIdThunk(formId));
    dispatch(setSuccessAction('updateFormFieldSuccess', true));
    dispatch(snackbarAlertAction(`Favorite added successfully`, 'success'));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error update form: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('duplicateFormLoading', false, 'duplicateFormError'));
  }
};

export const removeFavoriteFormThunk = (formId: UuidType, form_version_id: any) => async (
  dispatch: FlowproThunkDispatch, getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    await deleteHttp(`/form/${formId}/remove-from-favourites?form_version_id=${form_version_id}`);
    dispatch(fetchFormToEditByIdThunk(formId));
    dispatch(setSuccessAction('updateFormFieldSuccess', true));
    dispatch(snackbarAlertAction(`Favorite removed successfully`, 'success'));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error update form: ${e.message}`, 'error'));
    }
  }
};

export const cancelEditFormThunk = (formId: UuidType, form_version_id: any) => async (
  dispatch: FlowproThunkDispatch, getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    await deleteHttp(`/form/${formId}/cancel?form_version_id=${form_version_id}`);
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    }
  } finally {
    dispatch(setLoadingAction('duplicateFormLoading', false, 'duplicateFormError'));
  }
};

export const allowEditWithNewTokenFormThunk = (formId: UuidType, form_version_id: any) => async (
  dispatch: FlowproThunkDispatch, getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    await deleteHttp(`/form/${formId}/allowEditWithNewToken?form_version_id=${form_version_id}`);
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error update form: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('duplicateFormLoading', false, 'duplicateFormError'));
  }
};

export const checkEditFormPermissionThunk = (formId: UuidType, form_version_id: any) => async (
  dispatch: FlowproThunkDispatch, getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    const data: any = await getHttp(`/form/${formId}/edit?form_version_id=${form_version_id}`);
    return data;
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error update form: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('duplicateFormLoading', false, 'duplicateFormError'));
  }
};

export const saveFormInfoThunk = (formId: any) => async (
  dispatch: FlowproThunkDispatch, getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    sessionStorage.removeItem('check');
    dispatch(setSuccessAction('autoSaveSuccess', false));
    const data: any = await postHttp(`/form/save`, { form_id: formId, form_version_id: form_version_id });
    dispatch(getFormVersionThunk(formId));
    dispatch(setSuccessAction('getForms', true));
    dispatch(snackbarAlertAction(`Form Updated Successfully`, 'success'));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    }
  } finally {
    dispatch(setLoadingAction('duplicateFormLoading', false, 'duplicateFormError'));
  }
};

export const saveOnFlagTrueFormThunk = (formId: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const data: any = await postHttp(`/form/replicateBaseFormForOrgs`, { form_id: formId });
    dispatch(getFormVersionThunk(formId));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error update form: ${e.message}`, 'error'));
    }
  }
};

export const duplicateFormsThunk = (forms_id: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const form_version_id = sessionStorage.getItem('version');
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    await postHttp(`/form/duplicateBaseForm`, {
      "base_form_ids": forms_id,
      "organization_id": loggedInUser && loggedInUser.organization_id,
      "form_version_id": form_version_id
    });
    dispatch(setSuccessAction('createCollectionSuccess', true));
    dispatch(snackbarAlertAction(`Forms add successfully`, 'success'));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error creating form: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('duplicateFormLoading', false, 'duplicateFormError'));
  }
};

export const getFormVersionsThunk = (formId: UuidType) => async (
  dispatch: FlowproThunkDispatch, getState: () => RootState
) => {
  try {
    const data: any = await getHttp(`/form/${formId}/versions`);
    if (data) {
      const version_id = sessionStorage.getItem('version');
      !version_id && sessionStorage.setItem('version', data.data[0].id);
      dispatch(getFormVersionsAction(data.data));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error getting form versions`, 'error'));
    }
  }
};

export const newVersionFormThunk = (formId: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    sessionStorage.removeItem('check');
    const form_version_id = sessionStorage.getItem('version');
    dispatch(setSuccessAction('autoSaveSuccess', false));
    const data: any = await postHttp(`/form/saveAsNewVersion`, { form_id: formId, form_version_id: form_version_id });
    dispatch(getFormVersionThunk(formId));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error update form: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('duplicateFormLoading', false, 'duplicateFormError'));
  }
};

export const getFormVersionThunk = (formId: UuidType) => async (
  dispatch: FlowproThunkDispatch, getState: () => RootState
) => {
  try {
    const data: any = await getHttp(`/form/${formId}/versions`);
    if (data) {
      sessionStorage.setItem('version', data.data[0].id);
      dispatch(getFormVersionsAction(data.data));
      dispatch(setSuccessAction('getForms', true));
      // dispatch(snackbarAlertAction(`Form Updated Successfully`, 'success'));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error getting form versions`, 'error'));
    }
  }
};

export const downloadFormsThunk = (form_obj_array: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const data: any = await postHttp(`/form/download/getS3FormPdfUrl`, {
      form_obj_array: form_obj_array
    });
    return data.data;
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error download form: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('duplicateFormLoading', false, 'duplicateFormError'));
  }
};
/** Forms & Collection Clinician portal */
export const getFormsCollectionThunk = ({
  collectionId = '',
  order = 'DESC',
  page = 0,
  pageSize = 10,
  search = '',
  sort = 'title',
  favourites = true,
}: any) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    await dispatch(setLoadingAction('getDataLoading', true, 'formsLoadError'))
    const form_version_id = sessionStorage.getItem('version');
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const forms: any = await getHttp(
      `/form/getFormsAndCollections?domain_id=${collectionId}&search=${search}&skip=${page * pageSize}&limit=${pageSize}&sortBy=${sort}&order=${order}&favourites=${favourites}&organization_id=${loggedInUser && loggedInUser.organization_id ? loggedInUser.organization_id : ''}`
    );
    await dispatch(setLoadingAction('getDataLoading', false, 'formsLoadError'))
    if (forms) {
      forms.collectionId = collectionId;
      forms.favourites = favourites;
      forms.page = page;
      forms.pageSize = pageSize;
      forms.sort = sort;
      forms.search = search;
      forms.order = order
      dispatch(getFormsCollectionsAction(forms));
    } else {
      dispatch(snackbarAlertAction(`Error loading forms`, 'error'));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error loading forms: ${e.message}`, 'error'));
    }
  }
};

export const checkUserFormsAssignmentThunk = (data: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const res: any = await postHttp(`/patient/checkUserFormAssignment`, {
      "form_id": data.id,
      "form_version_id": data.form_version_id,
      "user_role_ids": data.user_role_ids
    });
    dispatch(usersAssignmentAction(res.user_assignment_array));
    if (res) {
      return res.user_assignment_array;
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const assignFormsThunk = (data: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const res: any = await postHttp(`/patient/assignFormToUsers`, {
      "form_id": data.id,
      "form_version_id": data.form_version_id,
      "user_role_ids": data.user_role_ids,
      "recurrence": data.recurrence
    });
    dispatch(setSuccessAction('saveDataSuccess', true));
    dispatch(snackbarAlertAction(`Sharing settings updated successfully`, 'success'));
    dispatch(getSharingUserAction(null));
    if (res) {
      return res;
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: Failed to assigned form`, 'error'));
    }
  }
};

export const assignFormsToUsersThunk = (data: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const res: any = await postHttp(`/patient/assignFormToUsers`, {
      "form_id": data.id,
      "form_version_id": data.form_version_id,
      "user_role_ids": data.user_role_ids,
      "recurrence": data.recurrence
    });
    dispatch(setSuccessAction('getForms', true));
    dispatch(snackbarAlertAction(`Sharing settings updated successfully`, 'success'));
    dispatch(getSharingUserAction(null));
    if (res) {
      return res;
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: Failed to assigned form`, 'error'));
    }
  }
};

export const checkUserCollectionsAssignmentThunk = (data: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const res: any = await postHttp(`/patient/checkUserCollectionAssignment`, {
      "collection_id": data.id,
      "user_role_ids": data.user_role_ids
    });
    dispatch(usersAssignmentAction(res.user_assignment_array));
    if (res) {
      return res.user_assignment_array;
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const assignCollectionsThunk = (data: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const res: any = await postHttp(`/patient/assignCollectionToUsers`, {
      "collection_id": data.id,
      "user_role_ids": data.user_role_ids,
      "recurrence": data.recurrence
    });
    dispatch(setSuccessAction('saveDataSuccess', true));
    dispatch(snackbarAlertAction(`Collection assigned successfully`, 'success'));
    dispatch(getSharingUserAction(null));
    if (res) {
      return res;
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: Failed to assigned collection`, 'error'));
    }
  }
};

/**Patient proxy get forms API */
export const getPatientFormsThunk = ({
  access_type = '',
  status_type,
  order = 'DESC',
  page = 0,
  pageSize = 10,
  search = '',
  sort = 'form_title',
}: any) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const forms: any = await getHttp(`/patient/getAssignedForms?user_role_id=${loggedInUser.id}&access_type=${access_type}&status_type=${status_type}&search=${search}&skip=${page}&limit=${pageSize}&sort=${sort}&order=${order}`
    );
    if (forms) {
      forms.access_type = access_type;
      forms.status_type = status_type;
      forms.forms = forms.data;
      forms.total = forms.totalCount;
      forms.page = page;
      forms.pageSize = pageSize;
      forms.search = search;
      forms.order = order;
      forms.sort = sort;
      dispatch(getFormsAction('custom', forms));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

/**Update form sharing settings */
export const updateFormSharingSettingThunk = (data: any) => async (
  dispatch: FlowproThunkDispatch
) => {
  try {
    const res: any = await putHttp(`/form/updateSharingSettings`, {
      "form_instance_id": data.form_instance_id,
      "user_role_ids": data.user_role_ids,
    });
    dispatch(setSuccessAction('getForms', true));
    dispatch(snackbarAlertAction(`Sharing settings updated successfully`, 'success'));
    if (res) {
      return res;
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

/**Update collection sharing settings */
export const updateCollectionSharingSettingThunk = (data: any) => async (
  dispatch: FlowproThunkDispatch
) => {
  try {
    const res: any = await putHttp(`/collection/updateSharingSettings`, {
      "collection_id": data.collection_id,
      "recurrence_id": data.recurrence_id,
      "patient_id": data.patient_id,
      "user_role_ids": data.user_role_ids,
    });
    dispatch(setSuccessAction('getForms', true));
    dispatch(snackbarAlertAction(`Sharing settings updated successfully`, 'success'));
    if (res) {
      return res;
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

/**Get form instance details */
export const getFormInstanceDetailsThunk = (formInstanceId: UuidType) => async (
  dispatch: FlowproThunkDispatch, getState: () => RootState
) => {
  try {
    const data: any = await getHttp(`/response/form/instance/${formInstanceId}/getFormInstanceDetails`);
    if (data) {
      dispatch(getFormInstanceDetailsAction(data.data));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    }
  }
};

/**Get form instance details */
export const getFormInstanceMetricDetailsThunk = (formInstanceId: UuidType, user_role_id: string) => async (
  dispatch: FlowproThunkDispatch, getState: () => RootState
) => {
  try {
    const data: any = await getHttp(`/response/form/instance/${formInstanceId}/getFormInstanceDetails`);
    if (data?.data?.metrics?.length > 0) {
      const graphs: any = [];
      for (let i = 0; i < data.data.metrics.length; i++) {
        const info: any = await getHttp(`/response/getDataForMetricGraph?metric_id=${data.data.metrics[i].id}&user_role_id=${user_role_id}`);
        info && graphs.push(info.data);
      }
      dispatch(getFormMetricGraphsAction(graphs));
      dispatch(getFormInstanceDetailsAction(data.data));
    } else {
      dispatch(getFormInstanceDetailsAction(data.data));
      dispatch(getFormMetricGraphsAction(null));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    }
  }
};

/**Get form response details */
export const getFormInstanceResponseThunk = (formInstanceId: UuidType) => async (
  dispatch: FlowproThunkDispatch, getState: () => RootState
) => {
  try {
    const data: any = await getHttp(`/response/form/instance/${formInstanceId}/get-form-responses`);
    if (data) {
      dispatch(getFormResponseDetailsAction(data.responses));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    }
  }
};

/**Get form recurrence setting info */
export const getFormRecurrenceInfoThunk = (formInstanceId: UuidType, user_role_id: any) => async (
  dispatch: FlowproThunkDispatch, getState: () => RootState
) => {
  try {
    const data: any = await getHttp(`/form/getRecurrenceSettings/${formInstanceId}?user_role_id=${user_role_id}`);
    if (data) {
      dispatch(getFormRecurrenceInfoAction(data.data));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    }
  }
};

/**Update form recurrence setting info */
export const updateFormRecurrenceThunk = (data: any) => async (dispatch: FlowproThunkDispatch) => {
  try {
    const res: any = await putHttp(`/patient/updateFormRecurrence`, data);
    dispatch(setSuccessAction('updateFormRecurrence', true));
    dispatch(snackbarAlertAction(`Recurrence updated successfully`, 'success'));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

/**Update collection recurrence setting info */
export const updateCollectionRecurrenceThunk = (data: any) => async (dispatch: FlowproThunkDispatch) => {
  try {
    const res: any = await putHttp(`/collection/updateCollectionRecurrence`, data);
    dispatch(setSuccessAction('updateFormRecurrence', true));
    dispatch(snackbarAlertAction(`Recurrence updated successfully`, 'success'));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

/**Unassign form to user*/
export const unassignFormToUserThunk = (data: any) => async (dispatch: FlowproThunkDispatch) => {
  try {
    const res: any = await postHttp(`/patient/unassignForm`, data);
    dispatch(setSuccessAction('updateFormRecurrence', true));
    dispatch(snackbarAlertAction(`Unassigned form successfully`, 'success'));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

/**Unassign collection to user*/
export const unassignCollectionToUserThunk = (data: any) => async (dispatch: FlowproThunkDispatch) => {
  try {
    const res: any = await postHttp(`/collection/unassignCollection`, data);
    dispatch(setSuccessAction('updateFormRecurrence', true));
    dispatch(snackbarAlertAction(`Unassigned collection successfully`, 'success'));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

/**Get form metric graph info */
export const getFormMetricGraphThunk = (metric_id: string, user_role_id: string) => async (
  dispatch: FlowproThunkDispatch
) => {
  try {
    const data: any = await getHttp(`/response/getDataForMetricGraph?metric_id=${metric_id}&user_role_id=${user_role_id}`);
    if (data) {
      dispatch(getFormMetricGraphsAction(data.data));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    }
  }
};

/**Get form result for clinician portal - nested section */
export const getResultsThunk = ({
  search = '',
  order = 'desc',
  page = 0,
  pageSize = 10,
  sort = 'item_name',
  status = 'COMPLETE',
  user_role_id = null
}) => async (
  dispatch: FlowproThunkDispatch
) => {
    try {
      const data: any = await getHttp(`/form/getAssignedItems?search=${search}&skip=${page}&limit=${pageSize}&status=${status}&sortBy=${sort}&order=${order}&user_role_id=${user_role_id}`);
      if (data) {
        data.total = data.totalCount;
        data.page = page;
        data.pageSize = pageSize;
        data.search = search;
        data.order = order;
        data.sort = sort;
        data.status = status
        dispatch(getFormResultsAction(data));
      }
    } catch (e) {
      if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
        dispatch(push('/login'));
      }
    }
  };

/**Get Completed form result for clinician portal - nested section */
export const getCompletedFormResultsThunk = ({
  page = 0,
  pageSize = 10,
  user_role_id = null,
  form_instance_id = null
}) => async (
  dispatch: FlowproThunkDispatch
) => {
    try {
      const data: any = await getHttp(`/patient/getFormInstanceHistory?form_instance_id=${form_instance_id}&skip=${page * pageSize}&limit=${pageSize}&user_role_id=${user_role_id}`);
      if (data) {
        data.total = data.totalCount;
        data.page = page;
        data.pageSize = pageSize;
        data.form_instance_id = form_instance_id
        dispatch(getCompletedFormResultsAction(data));
      }
    } catch (e) {
      if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
        dispatch(push('/login'));
      }
    }
  };

/**Get form result for clinician portal - nested section */
export const getCompletedCollectionResultsThunk = ({
  search = '',
  order = 'desc',
  sort = 'form_title',
  collection_id = null,
  user_role_id = null,
  recurrence_id = null,
}) => async (
  dispatch: FlowproThunkDispatch
) => {
    try {
      const data: any = await getHttp(`/collection/getCollectionFormsWithRecurrence?collection_id=${collection_id}&search=${search}&recurrence_id=${recurrence_id}&sortBy=${sort}&order=${order}&user_role_id=${user_role_id}`);
      if (data) {
        data.collection_id = collection_id;
        data.search = search;
        data.order = order;
        data.sort = sort;
        data.recurrence_id = recurrence_id
        dispatch(getCompletedCollectionResultsAction(data));
      }
    } catch (e) {
      if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
        dispatch(push('/login'));
      }
    }
  };

/**Get form status count data */
export const getFormStatusCount = (data: any) => async (dispatch: FlowproThunkDispatch) => {
  try {
    const res: any = await postHttp(`/patient/getFormStatusCountData`, data);
    await dispatch(getFormStatusCountAction(res.data));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};