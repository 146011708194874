import { Overrides as CoreOverrides } from '@material-ui/core/styles/overrides';
import { createMuiTheme } from '@material-ui/core/styles';
import { TabPanelClassKey } from '@material-ui/lab';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { colors } from './colors';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    background: {
      main: React.CSSProperties['color'];
    };
  }
  interface ThemeOptions {
    background: {
      main: React.CSSProperties['color'];
    };
  }
}

interface Overrides extends CoreOverrides {
  MuiTabPanel?:
    | Partial<Record<TabPanelClassKey, CSSProperties | (() => CSSProperties)>>
    | undefined;
}

const overrides: Overrides = {
  MuiButton: {
    root: {
      borderRadius: '0.375rem',
      height: '2rem'
    }
  },
  MuiInputBase: {
    input: {
      fontSize: 13,
      fontWeight: 500,
      height: '0.6875rem'
    }
  },
  MuiPaper: {
    root: {
      border: '1px solid rgba(0, 0, 0, 0.05)',
      borderRadius: '0.5rem'
    }
  },
  MuiTab: {
    root: {
      fontSize: 14,
      fontWeight: 500,
      minWidth: '0 !important',
      marginRight: '2rem',
      padding: '0',
      '&:last-child': {
        marginRight: 0
      }
    }
  },
  MuiTabPanel: {
    root: {
      padding: '0'
    }
  },
  MuiTableCell: {
    root: {
      padding: '0.75rem'
    },
    stickyHeader: {
      backgroundColor: colors.background3
    }
  },
  MuiTextField: {
    root: {
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderRadius: '0.375rem',
          boxShadow: '0 1px 1px 0 rgba(209, 214, 220, 0.2)'
        }
      }
    }
  },
  MuiToolbar: {
    root: {
      padding: '.6rem'
    }
  }
};

export const theme = createMuiTheme({
  overrides: overrides,
  background: {
    //faint blue
    main: '#F1F5F9'
  },
  palette: {
    primary: {
      //Blue
      main: '#0060B2',
      //Light Blue
      light: '#65B1FF',
      //Dark Blue
      dark: '#1E0CB2'
    },
    secondary: {
      //White
      main: '#FFFFFF',
      //Light Gray
      light: '#F9FAFB'
    },
    success: {
      //Light Green
      light: '#DEF7EC',
      //Green
      main: '#15B200',
      //Dark Green
      dark: '#04543F'
    },
    error: {
      main: '#911717'
    },
    text: {
      secondary: '#374151'
    }
  },
  props: {
    MuiPaper: {
      square: true
    },
    MuiTab: {
      disableRipple: true
    }
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    h1: {
      fontSize: 24,
      fontWeight: 600
    },
    h2: {
      fontSize: 18,
      fontWeight: 600
    },
    h3: {
      fontSize: 16,
      fontWeight: 600
    },
    h4: {
      fontSize: 14,
      fontWeight: 600
    },
    h5: {
      fontSize: 13,
      fontWeight: 500
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 500
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 500
    },
    body1: {
      fontSize: 14,
      fontWeight: 400
    },
    body2: {
      fontSize: 12,
      fontWeight: 400
    },
    button: {
      fontSize: 13,
      fontWeight: 500,
      textTransform: 'none'
    }
  }
});
