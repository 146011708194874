import React, { useState } from 'react';
import { Box, Divider, makeStyles, Select, MenuItem, Theme, Typography } from '@material-ui/core';
import pluralize from 'pluralize';

import { LoadingButton } from './LoadingButton';
import { colors } from '../styling/colors';

interface PaginationBarProps {
  contentName: string;
  page: number;
  pageSize: number;
  total: number;
  handleNextButton: () => Promise<void>;
  handlePrevButton: () => Promise<void>;
  handlePageSize?: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
}

export const PaginationBar = ({
  contentName,
  page,
  pageSize,
  total,
  handleNextButton,
  handlePrevButton,
  handlePageSize
}: PaginationBarProps) => {
  const classes = useStyles();

  const [prevButtonLoading, setPrevButtonLoading] = useState(false);
  const [nextButtonLoading, setNextButtonLoading] = useState(false);

  const handlePrevButtonClick = async () => {
    setPrevButtonLoading(true);
    await handlePrevButton();
    setPrevButtonLoading(false);
  };

  const handleNextButtonClick = async () => {
    setNextButtonLoading(true);
    await handleNextButton();
    setNextButtonLoading(false);
  };

  return (
    <Box alignItems="center" display="flex" flexWrap="wrap" justifyContent="space-between" pt={2}>
      <Box alignItems="center" display="flex" flexWrap="wrap" mb={1}>
        <Box alignItems="center" display="flex" pr={2}>
          <Box pr={0.5}>
            <Typography variant="subtitle2">{total}</Typography>
          </Box>

          <Typography>{`Total ${pluralize(contentName, total)}`}</Typography>
        </Box>

        <Divider flexItem orientation="vertical" />

        <Box alignItems="center" display="flex" px={2}>
          <Box pr={0.5}>
            <Typography>page</Typography>
          </Box>
          <Typography variant="subtitle2">
            {' '}
            {page + 1} of {Math.ceil(total / pageSize)}
          </Typography>
        </Box>

        <Divider flexItem orientation="vertical" />

        {handlePageSize && (
          <Box display="flex" alignItems={'center'} pl={2}>
            <Box pr={1}>
              <Typography>show</Typography>
            </Box>

            <Select
              className={classes.pageSizeInput}
              onChange={handlePageSize}
              value={pageSize}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
            <Box pl={1}>
              <Typography>per page</Typography>
            </Box>
          </Box>
        )}
      </Box>

      <Box display="flex">
        <Box mr={1}>
          <LoadingButton
            className={classes.pageButton}
            disabled={page <= 0}
            loading={prevButtonLoading}
            onClick={handlePrevButtonClick}
            spinnerColor={colors.blue1}
            aria-label={'previous'}
          >
            Previous
          </LoadingButton>
        </Box>

        <LoadingButton
          className={classes.pageButton}
          disabled={page >= total / pageSize - 1}
          loading={nextButtonLoading}
          onClick={handleNextButtonClick}
          spinnerColor={colors.blue1}
          aria-label={'next'}
        >
          Next
        </LoadingButton>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  pageSizeInput: {
    maxWidth: '3rem'
  },
  pageButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)'
  }
}));
