import React from 'react';
import { Box, makeStyles, Theme, Grid } from '@material-ui/core';

import { RoleUsers } from './components/RoleUsers';
import { SideBar } from '../../../components/SideBar';
import { TopBar } from '../../../components/TopBar';
import { ROLES } from '../../../shared/globals';

export const RoleUsersPage = () => {
  const classes = useStyles();
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);

  return (
    loggedInUser && <Box className={classes.main} display="flex" flexDirection="column" p={4}>
      <Box mb={4}>
        <TopBar />
      </Box>
      {loggedInUser && loggedInUser.role === ROLES.REFERRAL ?
        <Grid container>
          <Grid item xs={12}>
            <RoleUsers />
          </Grid>
        </Grid> :
        <Grid container>
          <Grid item xs={12} sm={2} md={2}>
            <SideBar />
          </Grid>
          <Grid item xs={12} sm={10} md={10}>
            <RoleUsers />
          </Grid>
        </Grid>}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    [theme.breakpoints.down('xs')]: {
      padding: '1rem'
    },
    backgroundColor: theme.background.main,
    minHeight: '100vh'
  },
  contentContainer: {
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  }
}));
