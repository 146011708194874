import React, { useEffect, useState } from 'react';
import { Box, makeStyles, Paper, Theme, Typography, Select, TextField, MenuItem, FormControl, FormControlLabel, Radio, DialogActions } from '@material-ui/core';
import MUIDialog from '@material-ui/core/Dialog'
import { useDispatch, useSelector } from 'react-redux';
import { updateFormRecurrenceThunk, updateCollectionRecurrenceThunk } from '../../../../redux/thunk/thunks';
import { RootState } from '../../../../redux/redux-store';
import { LoadingButton } from '../../../../components/LoadingButton';
import { useParams, useLocation } from 'react-router-dom';

export const UpdateRecurrence = ({ handelClose, type }: any) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const param: any = useParams();
    const [disDate, setDisabledDate] = useState<any>();
    const [disDate1, setDisabledDate1] = useState<any>();

    const formRecurrenceInfo: any = useSelector((state: RootState) => state.DATA_REDUCER.formRecurrenceInfo);
    const role: any = sessionStorage.getItem('role');
    const [recurrence, setRecurrence] = useState(formRecurrenceInfo?.no_of_recurrences ? 'Recurrence' : 'Date');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [noRecurrence, setNoRecurrence] = useState(formRecurrenceInfo?.no_of_recurrences || '');
    const [remEmails, setRemEmails] = useState(formRecurrenceInfo?.days_for_reminder || '');
    const [recurrenceSchedule, setRecurrenceSchedule] = useState(formRecurrenceInfo?.recurrence_schedule || '');
    const [openRecurrence, setOpenRecurrence] = useState(false);
    const [dateDiff, setDateDiff] = useState<any>(null);
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const location = useLocation();
    const split = location?.pathname.split('/');
    const patient_role_id: any = split && split.length > 1 && split[2];
    const shared_role_id: any = split && split.length > 3 && split[4];

    useEffect(() => {
        if (formRecurrenceInfo) {
            let newDate: any = Date.now();
            setDisabledDate(new Date(newDate).getFullYear() + '-' + (("0" + (new Date(newDate).getMonth() + 1)).slice(-2)) + '-' + ("0" + (new Date(newDate).getDate())).slice(-2));
            setStartDate(new Date(formRecurrenceInfo?.start_date).getFullYear() + '-' + (("0" + (new Date(formRecurrenceInfo?.start_date).getMonth() + 1)).slice(-2)) + '-' + ("0" + (new Date(formRecurrenceInfo?.start_date).getDate())).slice(-2));
            let newDate1: any = new Date(newDate);
            newDate1.setDate(newDate1.getDate() + 1);
            setDisabledDate1(new Date(newDate1).getFullYear() + '-' + (("0" + (new Date(newDate1).getMonth() + 1)).slice(-2)) + '-' + ("0" + (new Date(newDate1).getDate())).slice(-2));
            formRecurrenceInfo?.end_date && setEndDate(new Date(formRecurrenceInfo?.end_date).getFullYear() + '-' + (("0" + (new Date(formRecurrenceInfo?.end_date).getMonth() + 1)).slice(-2)) + '-' + ("0" + (new Date(formRecurrenceInfo?.end_date).getDate())).slice(-2));
        }
    }, [formRecurrenceInfo]);

    const removeRecurrence = () => {
        let newDate: any = Date.now();
        setStartDate(new Date(newDate).getFullYear() + '-' + (("0" + (new Date(newDate).getMonth() + 1)).slice(-2)) + '-' + ("0" + (new Date(newDate).getDate())).slice(-2)); setEndDate(''); setNoRecurrence(''); setRemEmails(''); setRecurrenceSchedule('');
    }

    const handelSave = async () => {
        setOpenRecurrence(false);
        const date1 = new Date(startDate);
        const date2 = endDate !== '' ? new Date(endDate) : null;
        if (type.item_type === 'FORM') {
            const data = {
                "form_instance_id": param?.instance,
                'recurrence': {
                    "start_date": date1.toISOString(),
                    "end_date": date2 ? date2.toISOString() : date2,
                    "no_of_recurrences": noRecurrence !== '' ? parseInt(noRecurrence) : null,
                    "recurrence_schedule": recurrenceSchedule !== '' ? recurrenceSchedule : null,
                    "days_for_reminder": remEmails !== '' ? parseInt(remEmails) : null
                }
            }
            await dispatch(updateFormRecurrenceThunk(data));
        } else {
            const data = {
                "collection_id": type?.item_id,
                "recurrence_id": type?.recurrence_id,
                'recurrence': {
                    "start_date": date1.toISOString(),
                    "end_date": date2 ? date2.toISOString() : date2,
                    "no_of_recurrences": noRecurrence !== '' ? parseInt(noRecurrence) : null,
                    "recurrence_schedule": recurrenceSchedule !== '' ? recurrenceSchedule : null,
                    "days_for_reminder": remEmails !== '' ? parseInt(remEmails) : null
                },
                "user_role_id": role === 'SHARED_USER' ? shared_role_id : patient_role_id
            }
            await dispatch(updateCollectionRecurrenceThunk(data));
        }
    }


    const handelNoRecurrence = (value: any) => {
        const re = /^[0-9\b]+$/;
        if (parseInt(value) !== 0 && re.test(value) && value.length < 3) {
            setNoRecurrence(value)
        } else {
            value.length > 3 || value.length == 0 && setNoRecurrence('')
        }
    }

    const handelRemEmails = (value: any) => {
        const re = /^[0-9\b]+$/;
        if (parseInt(value) !== 0 && re.test(value) && value.length < 6) {
            setRemEmails(value)
        } else {
            value.length > 6 || value.length == 0 && setRemEmails('')
        }
    }

    const handelStartDate = (value: any) => {
        setStartDate(value);
        let newDate: any = new Date(value);
        newDate.setDate(newDate.getDate() + 1)
        setDisabledDate1(new Date(newDate).getFullYear() + '-' + (("0" + (new Date(newDate).getMonth() + 1)).slice(-2)) + '-' + ("0" + (new Date(newDate).getDate())).slice(-2));
        setEndDate('');
        if (endDate !== '') {
            const date1: any = new Date(value);
            const date2: any = new Date(endDate);
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            setDateDiff(diffDays);
        }
    }

    const handelEndDate = (value: any) => {
        setEndDate(value);
        if (startDate !== '') {
            const date1: any = new Date(startDate);
            const date2: any = new Date(value);
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            setDateDiff(diffDays);
        }
    }

    return (
        <>
            <MUIDialog open={true} fullWidth maxWidth={'sm'}>
                <Box p={3} style={{ background: '#f9fafb', borderBottom: '0.0625rem solid #d1d6dc' }} >
                    <Box display='flex' justifyContent='space-between' alignItems="center">
                        <Typography style={{ fontSize: '18px', fontWeight: 600 }}>Settings</Typography>
                        <Typography style={{ fontSize: '15px', fontWeight: 600, color: '#d20b0b', cursor: 'pointer' }} onClick={(e) => removeRecurrence()}>Remove</Typography>
                    </Box>
                </Box>
                <Paper className={classes.paper1}>
                    <Box pb={2.5}>
                        <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                                Start Date
                            </Typography>
                        </Box>
                        <TextField fullWidth variant='outlined' size='small' autoFocus InputProps={{ inputProps: { min: disDate } }}
                            value={startDate} onChange={(e: any) => handelStartDate(e.target.value)} type="date" />
                    </Box>
                    <Box pb={2.5} pl={2} pr={2} display='flex' alignItems='center' justifyContent='space-between'>
                        <FormControlLabel onChange={(e: any) => { setRecurrence('Date'); setNoRecurrence(''); setRecurrenceSchedule(''); }} checked={recurrence === 'Date' ? true : false} control={<Radio color="primary" checked={recurrence === 'Date' ? true : false} />} label="Set End Date" />
                        <Typography color="textSecondary" variant="subtitle2">- OR -</Typography>
                        <FormControlLabel onChange={(e: any) => { setRecurrence('Recurrence'); setEndDate(''); setRecurrenceSchedule(''); }} checked={recurrence === 'Recurrence' ? true : false} control={<Radio color="primary" checked={recurrence === 'Recurrence' ? true : false} />} label="Set Recurrences" />
                    </Box>
                    <Box pb={2.5}>
                        <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                                End Date
                            </Typography>
                        </Box>
                        <TextField fullWidth variant='outlined' size='small' disabled={recurrence === 'Date' && startDate !== '' ? false : true}
                            value={endDate} onChange={(e: any) => handelEndDate(e.target.value)} type="date" InputProps={{ inputProps: { min: disDate1 } }} />
                    </Box>
                    <Box pb={2.5}>
                        <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                                Number of Recurrences
                            </Typography>
                        </Box>
                        <TextField fullWidth variant='outlined' size='small' type='tel' disabled={recurrence === 'Recurrence' ? false : true}
                            value={noRecurrence} onChange={(e: any) => handelNoRecurrence(e.target.value)} style={{ background: recurrence !== 'Recurrence' ? '#8080801c' : 'transparent' }} />
                    </Box>
                    <Box pb={2.5}>
                        <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                                Recurrence Schedule
                            </Typography>
                        </Box>
                        <FormControl size="small" fullWidth>
                            {endDate === '' ? <Select variant={'outlined'} value={recurrenceSchedule} disabled={noRecurrence === '' ? true : false}
                                onChange={(e: any) => setRecurrenceSchedule(e.target.value)} style={{ background: noRecurrence === '' ? '#8080801c' : 'transparent' }}
                            >
                                <MenuItem value={'DAILY'}>DAILY</MenuItem>
                                <MenuItem value={'WEEKLY'}>WEEKLY</MenuItem>
                                <MenuItem value={'MONTHLY'}>MONTHLY</MenuItem>
                                <MenuItem value={'YEARLY'}>YEARLY</MenuItem>
                            </Select> :
                                <Select variant={'outlined'} value={recurrenceSchedule}
                                    onChange={(e: any) => setRecurrenceSchedule(e.target.value)}
                                >
                                    {<MenuItem value={'DAILY'}>DAILY</MenuItem>}
                                    {dateDiff > 7 && <MenuItem value={'WEEKLY'}>WEEKLY</MenuItem>}
                                    {dateDiff > 29 && <MenuItem value={'MONTHLY'}>MONTHLY</MenuItem>}
                                    {dateDiff > 365 && <MenuItem value={'YEARLY'}>YEARLY</MenuItem>}
                                </Select>}
                        </FormControl>
                    </Box>
                    <Box pb={2.5}>
                        <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                                Reminder Emails - Number of days after due date
                            </Typography>
                        </Box>
                        <TextField fullWidth variant='outlined' size='small' type='tel'
                            value={remEmails} onChange={(e: any) => handelRemEmails(e.target.value)} />
                    </Box>
                </Paper>
                <DialogActions style={{ padding: '15px 20px', background: '#f9fafb', display: 'unset', justifyContent: 'unset' }}>
                    <Box display='flex' justifyContent='space-between'>
                        <LoadingButton
                            className={classes.secondaryButton}
                            variant="contained"
                            onClick={() => handelClose()}
                        >
                            Cancel
                        </LoadingButton>
                        <LoadingButton disabled={startDate === '' ? true : false}
                            className={classes.primaryButton}
                            variant="contained"
                            onClick={() => handelSave()}
                        >
                            Save
                        </LoadingButton>
                    </Box>
                </DialogActions>
            </MUIDialog>
        </>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    topBox: {
        borderRadius: '0.625rem 0.625rem 0 0'
    },
    bottomBox: {
        borderRadius: '0 0 0.625rem 0.625rem'
    },
    primaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#fff',
        minWidth: '120px',
        background: '#0060B2',
        '&:hover': {
            backgroundColor: '#0060B2',
        }
    },
    secondaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#0060B2',
        minWidth: '120px',
        background: '#fff'
    },
    paper: {
        width: '580px',
        overflow: 'hidden'
    },
    paper1: {
        padding: '30px',
        overflow: 'auto'
    },
    searchTextField: {
        backgroundColor: theme.palette.secondary.main,
        fontSize: theme.typography.h5.fontSize,
        height: '2rem',
        width: '11rem',
        zIndex: 1
    },
    searchIcon: {
        fontSize: 17
    },
    paginationButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: 'black',
        marginLeft: '20px',
        minWidth: '120px',
        background: '#fff'
    },
}));
