import { Box, makeStyles, Paper, IconButton, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { Add, MoreHoriz } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmationDialog } from '../../../components/ConfirmationDialog';
import Popover from '@material-ui/core/Popover';
import { NavTab, NavTabs } from '../../../components/NavTabs';
import { RootState } from '../../../redux/redux-store';
import { useLocation } from 'react-router-dom';
import { createExitPageThunk, deleteExitPageThunk } from '../../../redux/thunk/thunks';
import { setEditingExitPageAction } from '../../../redux/data/data-actions';
import { ExitPage } from '../../../shared/domain';

interface ExitPageSubHeaderProps {
  handleSelectPage: (event: React.ChangeEvent<{}>, value: any) => void;
  selectedExitPageId?: string | null;
}

export const ExitPageSubHeader: React.FC<ExitPageSubHeaderProps> = ({
  selectedExitPageId,
  handleSelectPage
}) => {
  const location: any = useLocation();
  const isEdit = location.state;
  const [deleteDialog, setDeleteDialog] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const editingExitPages = useSelector((state: RootState) => state.DATA_REDUCER.editingExitPages);

  const deleteExitPageLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.deleteExitPageLoading
  );
  const editingForm = useSelector((state: RootState) => state.DATA_REDUCER.editingForm);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, info: any) => {
    setAnchorEl(event.currentTarget);
    handleSelectPage(event, info.id)
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const addExitPage = () => {
    if (editingForm) {
      dispatch(createExitPageThunk(editingForm.id));
    }
  };

  const showDeleteDialog = () => {
    setDeleteDialog(true);
    handleClose()
  };
  const closeDeleteDialog = () => {
    setDeleteDialog(false);
  };
  const deleteExitPage = async () => {
    if (editingExitPages && editingForm && selectedExitPageId) {
      closeDeleteDialog();
      await dispatch(deleteExitPageThunk(selectedExitPageId, editingForm?.id));
      const defaultExitPage: any = editingExitPages.find(
        (exitPage: any) => exitPage.is_default === true
      );
      dispatch(setEditingExitPageAction(defaultExitPage));
    }
  };

  return (
    <Paper className={classes.container} square elevation={2}>
      {deleteDialog && (
        <ConfirmationDialog
          loading={deleteExitPageLoading}
          message='Are you sure you want to delete the currently selected exit page? Please type "DELETE" to confirm.'
          open={deleteDialog}
          title="Delete Exit Page"
          handleClose={closeDeleteDialog}
          handleConfirmClick={deleteExitPage}
        />
      )}
      <Box display="flex" alignItems="center">
        <NavTabs value={selectedExitPageId}>
          {editingExitPages?.map((exitPage: ExitPage, i: number) => {
            return (<Box display="flex" alignItems="center" mr={2} onClick={(e: any) => handleSelectPage(e, exitPage.id)} style={{ borderBottom: selectedExitPageId === exitPage.id ? '3px solid #0062b2' : 'unset' }}>
              <NavTab key={i} label={<span style={{ color: selectedExitPageId === exitPage.id ? '#01227e' : 'unset' }}>{exitPage.name}</span>} value={exitPage.id} />
              {isEdit && <MoreHoriz onClick={(e: any) => handleClick(e, exitPage)} className={classes.moreIcon} />}
            </Box>
            )
          })}
          {isEdit && <IconButton
            className={classes.addBtn}
            size="small"
            onClick={addExitPage}
          >
            <Add fontSize={'small'} />
          </IconButton>
          }
        </NavTabs>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography style={{ color: 'red', cursor: 'pointer', width: '70px', padding: '5px', textAlign: 'center' }} onClick={showDeleteDialog}>Delete</Typography>
        </Popover>
      </Box>
      <Box>


        {/* hide delete button if exitpage is default */}
        {/* {editingExitPage?.is_default || getExitPagesLoading ? null : (
          <LoadingButton
            loading={createExitPageLoading}
            variant="contained"
            color={'secondary'}
            onClick={showDeleteDialog}
            aria-label={'add exit page'}
          >
            Delete Exit Page
          </LoadingButton>
        )} */}
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#ffffff',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
    paddingBottom: 0
  },
  leftButton: {
    marginRight: '1rem'
  },
  addBtn: {
    padding: 0,
    marginTop: '.6rem',
    marginLeft: '.6rem',
    borderRadius: '1rem',
    paddingLeft: '.2rem',
    paddingRight: '.2rem',
    height: '30px',
    backgroundColor: theme.background.main,
    color: theme.palette.text.primary
  },
  moreIcon: {
    marginLeft: '-10px',
    marginTop: '5px',
    cursor: 'pointer'
  }
}));
