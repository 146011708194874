import { Box, makeStyles, Theme, Typography, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { HeadCell, Pagination, RowCell, Table } from '../../../../../components/Table';
import { getCompletedFormResultsThunk, getFormInstanceResponseThunk } from '../../../../../redux/thunk/thunks';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/redux-store';
import moment from 'moment';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { ViewResponse } from '../../../../Forms/components/PatientViewForm';
import { getFormResponseDetailsAction } from '../../../../../redux/data/data-actions';

export const FormResultTable = ({ id }: any) => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch()
    const organization_id: any = sessionStorage.getItem('pat_org_id');
    const [resultInfo, setDataInfo] = useState<any>();
    const [openForm, setOpenForm] = useState(false);
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const role: any = sessionStorage.getItem('role');
    const split = location?.pathname.split('/');
    const patient_role_id: any = split && split.length > 1 && split[2];
    const shared_role_id: any = split && split.length > 3 && split[4];
    const form_instance_id: any = id ? id : split && split.length > 2 && split[3];

    const completedFormResults: any = useSelector((state: RootState) => state.DATA_REDUCER.completedFormResults);
    const loading: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.getDataLoading);

    useEffect(() => {
        dispatch(getCompletedFormResultsThunk({
            user_role_id: role === 'SHARED_USER' ? shared_role_id : patient_role_id,
            form_instance_id: form_instance_id,
        }));
    }, [])

    const headCells: HeadCell[] = [
        {
            id: 'completed_on',
            label: 'COMPLETION',
            isSortDisabled: true
        },
        {
            id: 'completed_by_name',
            label: 'ANSWERED BY',
            isSortDisabled: true
        },
        {
            id: 'status',
            label: 'STATUS',
            isSortDisabled: true
        }
    ];

    const rowCells = completedFormResults?.data?.map((item: any) => {
        const nameLink = (<Typography variant="h5" style={{ cursor: item?.status === 'COMPLETE' ? 'pointer' : 'unset', color: item?.status === 'COMPLETE' ? '#0060B2' : '#374151' }} className={classes.itemName} onClick={(e) => { handleViewComponentDialogueOpen(item); }}>
            {item?.status === 'COMPLETE' ? (`View ${moment(item.start_date).format('DD MMMM YYYY')} Form`) : moment(item.start_date).format('DD MMMM YYYY')}
        </Typography>
        );

        let rowCell: RowCell = {};
        rowCell = {
            completed_on: {
                component: nameLink,
                value: '-'
            },
            completed_by_name: {
                value: item?.completed_by_name || 'N/A',
            },
            status: { value: item?.status === 'COMPLETE' ? 'COMPLETED' : item?.status.replace(/_/g, " ") }
        }
        return Object.assign(rowCell, { content: item });
    });

    const handleNextButton = async () => {
        if (
            completedFormResults &&
            completedFormResults.page >= 0 &&
            completedFormResults.page < completedFormResults.totalCount / completedFormResults.pageSize - 1
        ) {
            await dispatch(getCompletedFormResultsThunk({
                page: completedFormResults.page + 1,
                pageSize: completedFormResults.pageSize,
                user_role_id: role === 'SHARED_USER' ? shared_role_id : patient_role_id,
                form_instance_id: form_instance_id,
            }));
        }
    };
    const handlePrevButton = async () => {
        if (completedFormResults && completedFormResults.page > 0) {
            await dispatch(getCompletedFormResultsThunk({
                page: completedFormResults.page - 1,
                pageSize: completedFormResults.pageSize,
                user_role_id: role === 'SHARED_USER' ? shared_role_id : patient_role_id,
                form_instance_id: form_instance_id,
            }));
        }
    };

    const handlePageSize = (
        e: React.ChangeEvent<{
            name?: string;
            value: unknown;
        }>
    ) => {
        const pageSize = e.target.value as number;

        if (completedFormResults && pageSize > 0) {
            dispatch(getCompletedFormResultsThunk({
                page: 0,
                pageSize: pageSize,
                user_role_id: role === 'SHARED_USER' ? shared_role_id : patient_role_id,
                form_instance_id: form_instance_id,
            }));
        }
    };

    const pagination: Pagination | undefined = completedFormResults
        ? {
            contentName: 'Results',
            page: completedFormResults.page,
            pageSize: completedFormResults.pageSize,
            total: completedFormResults.totalCount,
            handleNextButton: handleNextButton,
            handlePageSize: handlePageSize,
            handlePrevButton: handlePrevButton
        }
        : undefined;

    const handleViewComponentDialogueOpen = async (info: any) => {
        await dispatch(getFormInstanceResponseThunk(form_instance_id));
        setDataInfo(info);
        setOpenForm(true);
    };

    const handleClose = () => {
        setOpenForm(false);
        dispatch(getFormResponseDetailsAction(null));
    }

    return (
        loading ? <Box display='flex' justifyContent='center'><CircularProgress /></Box> : <>
            <Table
                data={completedFormResults?.data}
                headCells={headCells}
                loading={!completedFormResults?.data}
                pagination={pagination}
                rowCells={rowCells}
            />
            {openForm && <ViewResponse handleClose={handleClose} instance_id={id}/>}
        </>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    nameLink: {
        textDecoration: 'none'
    },
    itemName: {
        display: 'flex'
    },
    launchIcon: {
        fontSize: '2rem',
        cursor: 'pointer'
    },
    InfoIcon: {
        fontSize: 16,
        marginLeft: 6
    },
    color: {
        color: '#0060B2'
    },
    tableBox: {
        padding: '2px 8px',
        marginRight: '5px',
        minWidth: '80px',
    },
    actionTypography: {
        padding: '10px',
        cursor: 'pointer',
        fontWeight: 'bold'
    },
}));