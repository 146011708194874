import React, { useEffect, useState } from 'react';
import { Box, Theme, Typography, makeStyles, Grid, Divider, TextField, Select, MenuItem, CircularProgress } from '@material-ui/core';
import { Dialog } from '../../../components/Dialog';
import { ArrowBack, Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { RootState } from '../../../redux/redux-store';
import { Checkbox } from '../../../components/Checkbox';
import { updateOrganizationThunk, getStateThunk, inviteOrganizationThunk, cancelInviteOrganizationThunk, deleteOrganizationThunk, deActivateOrganizationThunk, activateOrganizationThunk } from '../../../redux/thunk/thunks';
import { LoadingButton } from '../../../components/LoadingButton';

interface ViewOrganizationProps {
    viewInfo: any
}

export const ViewOrganization = ({ viewInfo }: ViewOrganizationProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [input, setInput] = useState('');
    const [msg, setMsg] = useState('');
    const [error, setError] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [inviteOpen, setInviteOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [inputs, setInputs] = useState(null);
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const [zip_code, setZip] = useState(viewInfo ? viewInfo.zip_code : '');
    const initialValues: any = {
        id: viewInfo ? viewInfo.id : '',
        organization_name: viewInfo ? viewInfo.organization_name : '',
        site_admin_first_name: viewInfo ? viewInfo.site_admin_first_name : '',
        site_admin_last_name: viewInfo ? viewInfo.site_admin_last_name : '',
        point_of_contact_first_name: viewInfo ? viewInfo.point_of_contact_first_name : '',
        site_admin_email: viewInfo ? viewInfo.site_admin_email : '',
        point_of_contact_email: viewInfo ? viewInfo.point_of_contact_email : '',
        point_of_contact_last_name: viewInfo ? viewInfo.point_of_contact_last_name : '',
        point_of_contact_phone: viewInfo ? viewInfo.point_of_contact_phone : '',
        address: viewInfo ? (viewInfo.address ? viewInfo.address : '') : '',
        building: viewInfo ? (viewInfo.building ? viewInfo.building : '') : '',
        city: viewInfo ? (viewInfo.city ? viewInfo.city : '') : '',
        state: viewInfo ? viewInfo.state : '',
        zip_code: viewInfo ? viewInfo.zip_code : '',
        site_admin_phone: viewInfo ? viewInfo.site_admin_phone : '',
        notes: viewInfo ? (viewInfo.notes ? viewInfo.notes : '') : '',
        status: viewInfo ? viewInfo.status : '',
        poc_same_as_site_admin: viewInfo ? viewInfo.poc_same_as_site_admin : false
    };

    const organizationSchema = Yup.object().shape({
        organization_name: Yup.string().required('Required').min(1, "Too Short!").max(100, ''),
        site_admin_first_name: Yup.string().required('Required').min(1, "Too Short!").max(50, ''),
        site_admin_last_name: Yup.string().required('Required').min(1, "Too Short!").max(50, ''),
        point_of_contact_first_name: Yup.string().required('Required').min(1, "Too Short!").max(50, ''),
        point_of_contact_last_name: Yup.string().required('Required').min(1, "Too Short!").max(50, ''),
        site_admin_email: Yup.string().email('Email is invalid').required('Required'),
        point_of_contact_email: Yup.string().email('Email is invalid').required('Required'),
        address: Yup.string().max(100, ''),
        building: Yup.string().max(100, ''),
        city: Yup.string().max(50, ''),
        notes: Yup.string().max(250, ''),
    });

    const stateList: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.stateList
    );
    useEffect(() => {
        dispatch(getStateThunk())
    }, [])

    const handleSubmit = async (values: any, actions: any) => {
        if (loading) {
            actions.setSubmitting(true);
            const data = values;
            data.zip_code = zip_code;
            if (values.poc_same_as_site_admin) {
                data.point_of_contact_first_name = values.site_admin_first_name;
                data.point_of_contact_last_name = values.site_admin_last_name;
                data.point_of_contact_email = values.site_admin_email;
                data.point_of_contact_phone = values.site_admin_phone;
            }
            await dispatch(updateOrganizationThunk(data));
            setIsEdit(false);
            setLoading(false);
            actions.setSubmitting(false);
            if (viewInfo.status !== values.status) {
                if (values.status === "DISABLED") {
                    await dispatch(deActivateOrganizationThunk(data.id, 0, 10));
                } else {
                    await dispatch(activateOrganizationThunk(data.id, 0, 10));
                }
            }
        }
    }

    const deleteOrganization = () => {
        if (input === '' || input.toLowerCase() !== viewInfo.organization_name.toLowerCase()) {
            setError(true);
            setMsg(input === '' ? 'Please type DELETE' : 'Please enter the right word')
        } else {
            dispatch(deleteOrganizationThunk(viewInfo.id, 'delete'))
        }
    }

    const handelEdit = (value: boolean) => {
        setIsEdit(value);
    }

    const deletePopup = () => {
        setIsOpen(true);
    }

    const handleClose = () => {
        setIsOpen(false);
    }

    const handelChange = (value: any) => {
        const re = /^[0-9\b]+$/;
        if (re.test(value) && value.length < 6) {
            setZip(value)
        } else {
            value.length > 6 || value.length == 0 && setZip('')
        }
    }

    const sendInvite = () => {
        viewInfo.status = 'AWAITING_ACCEPTANCE';
        initialValues.status = 'AWAITING_ACCEPTANCE';
        dispatch(inviteOrganizationThunk(viewInfo.id, 0, 10));
        setInviteOpen(true)
    }
    const cancelInvite = () => {
        viewInfo.status = 'INVITE_CANCELLED';
        initialValues.status = 'INVITE_CANCELLED';
        dispatch(cancelInviteOrganizationThunk(viewInfo.id, 0, 10));
    }
    const handleCloseDialog = () => {
        setInviteOpen(false)
    }

    const handleChange = (value: any, no: any) => {
        if (no === 0 && value.length > 100) {
            setIsError(true); setInputs(no);
        } else if (no === 1 && value.length > 50) {
            setIsError(true); setInputs(no);
        } else if (no === 2 && value.length > 50) {
            setIsError(true); setInputs(no);
        } else if (no === 3 && value.length > 50) {
            setIsError(true); setInputs(no);
        } else if (no === 4 && value.length > 50) {
            setIsError(true); setInputs(no);
        } else if (no === 5 && value.length > 250) {
            setIsError(true); setInputs(no);
        } else if (no === 6 && value.length > 100) {
            setIsError(true); setInputs(no);
        } else if (no === 7 && value.length > 100) {
            setIsError(true); setInputs(no);
        } else if (no === 8 && value.length > 50) {
            setIsError(true); setInputs(no);
        } else {
            setIsError(false); setInputs(no);
        }
    }

    return (
        <Box display="flex" flexDirection="column" style={{ background: '#fff', borderRadius: '10px 10px 0px 0px' }}>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={organizationSchema}
            >
                {({ errors, isSubmitting, setFieldValue, setSubmitting, touched, values }) => {

                    return (
                        <Form>
                            <Box p={3} style={{ background: '#F9FAFB', borderRadius: '10px 10px 0px 0px' }}>
                                <Grid container>
                                    <Grid item xs={12} sm={8} md={8} style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="h2">Organization Profile</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4} style={{ textAlign: 'right' }} >
                                        <Box display="flex" justifyContent="end" className={classes.contentContainer}>
                                            {isEdit && <LoadingButton
                                                className={classes.secondaryButton}
                                                color="secondary"
                                                variant="contained"
                                                onClick={(e) => handelEdit(false)}
                                            >
                                                Cancel
                                            </LoadingButton>
                                            }
                                            {isEdit ? <LoadingButton onClick={(e) => setLoading(true)}
                                                className={classes.primaryButton}
                                                color="primary"
                                                variant="contained"
                                                type="submit"
                                                disabled={isSubmitting}
                                                loading={isSubmitting}
                                            >
                                                Save
                                            </LoadingButton> :
                                                <LoadingButton
                                                    className={classes.primaryButton}
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={(e) => handelEdit(true)}
                                                >
                                                    Edit
                                                </LoadingButton>}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Divider />
                            {viewInfo ?
                                <Box px={3} py={2.5}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={5} md={5}>
                                            <Box pb={1.0}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Organization Name
                                                    </Typography>
                                                </Box>
                                                <Field fullWidth disabled={isEdit ? false : true}
                                                    style={{ background: isError && inputs === 0 ? '#fff1f1' : 'unset' }}
                                                    onInput={(e: any) => handleChange(e.target.value, 0)}
                                                    as={TextField}
                                                    inputProps={{ maxLength: 101 }}
                                                    className={classes.textField}
                                                    name="organization_name"
                                                    size="small"
                                                    variant="outlined"
                                                />
                                                <Box display='flex' justifyContent='space-between'>
                                                    <Typography color={"error"} variant="subtitle2">
                                                        {errors.organization_name && touched.organization_name && errors.organization_name}
                                                    </Typography>
                                                    <Typography style={{ color: values.organization_name?.length > 100 ? '#911717' : '#0000008a' }} variant="subtitle2">
                                                        {values.organization_name?.length === 101 ? 100 : values.organization_name?.length}/100
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={5} md={5}>
                                            <Box pb={1.0}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Status
                                                    </Typography>
                                                </Box>
                                                <Field as={Select}
                                                    renderValue={() => {
                                                        return values.status === "DISABLED" ? 'DISABLED' : (values.status === "ACTIVE" ? 'ACTIVE' : values.status.replace(/_/g, " "));
                                                    }}
                                                    disabled={isEdit ? false : true} name="status" variant="outlined" className={classes.textField1}>
                                                    {(viewInfo.status === "INVITE_NOT_SENT" || viewInfo.status === "AWAITING_ACCEPTANCE" || viewInfo.status === "INVITE_EXPIRED" || viewInfo.status === "INVITE_CANCELLED" || viewInfo.status === "ACTIVE") && <MenuItem value="DISABLED">De-Activate</MenuItem>}
                                                    {viewInfo.status === "DISABLED" && <MenuItem value="ACTIVE">Activate</MenuItem>}
                                                </Field>
                                                {errors.status && touched.status ? (
                                                    <Typography color="error" variant="subtitle2">
                                                        {errors.status}
                                                    </Typography>
                                                ) : null}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={2} md={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                        </Grid>
                                        <Grid item xs={12} sm={10} md={10}>
                                            <Box pb={1.0}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Address
                                                    </Typography>
                                                </Box>
                                                <Field fullWidth disabled={isEdit ? false : true} style={{ background: isError && inputs === 6 ? '#fff1f1' : 'unset' }}
                                                    onInput={(e: any) => handleChange(e.target.value, 6)}
                                                    as={TextField}
                                                    inputProps={{ maxLength: 101 }}
                                                    className={classes.textField}
                                                    name="address"
                                                    size="small"
                                                    variant="outlined"
                                                />
                                                <Box display='flex' justifyContent='space-between'>
                                                    <Typography color={"error"} variant="subtitle2">
                                                        {errors.address && touched.address && errors.address}
                                                    </Typography>
                                                    <Typography style={{ color: values.address?.length > 100 ? '#911717' : '#0000008a' }} variant="subtitle2">
                                                        {values.address?.length === 101 ? 100 : values.address?.length}/100
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={10} md={10}>
                                            <Box pb={1.0}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Building/Suite/Other
                                                    </Typography>
                                                </Box>
                                                <Field fullWidth disabled={isEdit ? false : true} style={{ background: isError && inputs === 7 ? '#fff1f1' : 'unset' }}
                                                    onInput={(e: any) => handleChange(e.target.value, 7)}
                                                    as={TextField}
                                                    inputProps={{ maxLength: 101 }}
                                                    className={classes.textField}
                                                    name="building"
                                                    size="small"
                                                    variant="outlined"
                                                />
                                                <Box display='flex' justifyContent='space-between'>
                                                    <Typography color={"error"} variant="subtitle2">
                                                        {errors.building && touched.building && errors.building}
                                                    </Typography>
                                                    <Typography style={{ color: values.building?.length > 100 ? '#911717' : '#0000008a' }} variant="subtitle2">
                                                        {values.building?.length === 101 ? 100 : values.building?.length}/100
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={5} md={5}>
                                            <Box pb={1.0}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        City
                                                    </Typography>
                                                </Box>
                                                <Field fullWidth disabled={isEdit ? false : true} style={{ background: isError && inputs === 8 ? '#fff1f1' : 'unset' }}
                                                    onInput={(e: any) => handleChange(e.target.value, 8)}
                                                    as={TextField}
                                                    inputProps={{ maxLength: 51 }}
                                                    className={classes.textField}
                                                    name="city"
                                                    size="small"
                                                    variant="outlined"
                                                />
                                                <Box display='flex' justifyContent='space-between'>
                                                    <Typography color={"error"} variant="subtitle2">
                                                        {errors.city && touched.city && errors.city}
                                                    </Typography>
                                                    <Typography style={{ color: values.city?.length > 50 ? '#911717' : '#0000008a' }} variant="subtitle2">
                                                        {values.city?.length === 51 ? 50 : values.city?.length}/50
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={5} md={5}>
                                            <Box pb={1.0}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        State
                                                    </Typography>
                                                </Box>
                                                <Field as={Select} disabled={isEdit ? false : true} name="state" variant="outlined" className={classes.textField1}>
                                                    <MenuItem value={''}>Select</MenuItem>
                                                    {stateList && stateList.length > 0 && stateList.map((item: any) => {
                                                        return <MenuItem value={item.state}>{item.state}</MenuItem>
                                                    })}
                                                </Field>
                                                {errors.state && touched.state ? (
                                                    <Typography color="error" variant="subtitle2">
                                                        {errors.state}
                                                    </Typography>
                                                ) : null}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={5} md={5}>
                                            <Box pb={2}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Zip Code
                                                    </Typography>
                                                </Box>
                                                <TextField disabled={isEdit ? false : true}
                                                    value={zip_code} onChange={(e: any) => handelChange(e.target.value)}
                                                    className={classes.textField}
                                                    name="zip_code"
                                                    size="small"
                                                    variant="outlined"
                                                />
                                                {errors.zip_code && touched.zip_code ? (
                                                    <Typography color="error" variant="subtitle2">
                                                        {errors.zip_code}
                                                    </Typography>
                                                ) : null}
                                            </Box>
                                        </Grid>
                                        {isEdit === false && <Grid item xs={12} sm={5} md={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                            {(viewInfo.status === "INVITE_NOT_SENT" || viewInfo.status === "INVITE_EXPIRED" || viewInfo.status === "INVITE_CANCELLED") ?
                                                <LoadingButton
                                                    className={classes.primaryButton}
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={(e) => sendInvite()}
                                                >
                                                    Send Invite
                                                </LoadingButton> :
                                                (viewInfo.status === "AWAITING_ACCEPTANCE" ? <LoadingButton
                                                    className={classes.secondaryButton}
                                                    variant="contained"
                                                    onClick={(e) => cancelInvite()}
                                                >
                                                    Cancel Invite
                                                </LoadingButton> : '')
                                            }
                                        </Grid>}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} style={{ background: '#f6f7f9', padding: '15px 10px 0px' }}>
                                        <Box pb={1}>
                                            <Typography color="textSecondary" variant="h2">
                                                Site Admin
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Divider />
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={5} md={5}>
                                            <Box pb={1.0} pt={2}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Site Admin First Name
                                                    </Typography>
                                                </Box>
                                                <Field fullWidth disabled={true} style={{ background: isError && inputs === 1 ? '#fff1f1' : 'unset' }}
                                                    onInput={(e: any) => handleChange(e.target.value, 1)}
                                                    as={TextField}
                                                    inputProps={{ maxLength: 51 }}
                                                    className={classes.textField}
                                                    name="site_admin_first_name"
                                                    size="small"
                                                    variant="outlined"
                                                />
                                                <Box display='flex' justifyContent='space-between'>
                                                    <Typography color={"error"} variant="subtitle2">
                                                        {errors.site_admin_first_name && touched.site_admin_first_name && errors.site_admin_first_name}
                                                    </Typography>
                                                    <Typography style={{ color: values.site_admin_first_name?.length > 50 ? '#911717' : '#0000008a' }} variant="subtitle2">
                                                        {values.site_admin_first_name?.length === 51 ? 50 : values.site_admin_first_name?.length}/50
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={5} md={5}>
                                            <Box pb={1.0} pt={2}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Site Admin Last Name
                                                    </Typography>
                                                </Box>
                                                <Field fullWidth disabled={true} style={{ background: isError && inputs === 2 ? '#fff1f1' : 'unset' }}
                                                    onInput={(e: any) => handleChange(e.target.value, 2)}
                                                    as={TextField}
                                                    inputProps={{ maxLength: 51 }}
                                                    className={classes.textField}
                                                    name="site_admin_last_name"
                                                    size="small"
                                                    variant="outlined"
                                                />
                                                <Box display='flex' justifyContent='space-between'>
                                                    <Typography color={"error"} variant="subtitle2">
                                                        {errors.site_admin_last_name && touched.site_admin_last_name && errors.site_admin_last_name}
                                                    </Typography>
                                                    <Typography style={{ color: values.site_admin_last_name?.length > 50 ? '#911717' : '#0000008a' }} variant="subtitle2">
                                                        {values.site_admin_last_name?.length === 51 ? 50 : values.site_admin_last_name?.length}/50
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={5} md={5}>
                                            <Box pb={1.0}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Site Admin Email Address
                                                    </Typography>
                                                </Box>
                                                <Field fullWidth disabled={true}
                                                    as={TextField}
                                                    className={classes.textField}
                                                    name="site_admin_email"
                                                    size="small"
                                                    variant="outlined"
                                                />
                                                {errors.site_admin_email && touched.site_admin_email ? (
                                                    <Typography color="error" variant="subtitle2">
                                                        {errors.site_admin_email}
                                                    </Typography>
                                                ) : null}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={5} md={5}>
                                            <Box pb={2}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Site Admin Phone Number
                                                    </Typography>
                                                </Box>
                                                <Field fullWidth disabled={isEdit ? false : true}
                                                    as={TextField}
                                                    onInput={(e: any) => {
                                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 12)
                                                    }}
                                                    className={classes.textField}
                                                    name="site_admin_phone"
                                                    size="small"
                                                    variant="outlined"
                                                />
                                                {errors.site_admin_phone && touched.site_admin_phone ? (
                                                    <Typography color="error" variant="subtitle2">
                                                        {errors.site_admin_phone}
                                                    </Typography>
                                                ) : null}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} style={{ background: '#f6f7f9', padding: '15px 10px 0px' }}>
                                        <Grid container>
                                            <Grid item xs={12} sm={5} md={5}>
                                                <Box pb={1}>
                                                    <Typography color="textSecondary" variant="h2">
                                                        Point of Contact
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={5} md={5} style={{ textAlign: 'right' }}>
                                                <Box mb={-1} ml={-1.1} mt={-1} pb={1}>
                                                    <Checkbox
                                                        disableRipple disabled={isEdit ? false : true}
                                                        checked={values.poc_same_as_site_admin}
                                                        color="primary"
                                                        onClick={() => setFieldValue('poc_same_as_site_admin', !values.poc_same_as_site_admin)}
                                                    />

                                                    <Typography
                                                        display="inline"
                                                        variant="body1"
                                                    >
                                                        Same as Site Admin
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={5} md={5}>
                                            <Box pb={1.0} pt={2}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Point of Contact First Name
                                                    </Typography>
                                                </Box>
                                                <Field fullWidth disabled={(isEdit && values.poc_same_as_site_admin === false) ? false : true}
                                                    style={{ background: isError && inputs === 3 ? '#fff1f1' : 'unset' }}
                                                    onInput={(e: any) => handleChange(e.target.value, 3)}
                                                    as={TextField}
                                                    inputProps={{ maxLength: 51 }}
                                                    className={classes.textField}
                                                    name="point_of_contact_first_name"
                                                    size="small"
                                                    variant="outlined"
                                                />
                                                <Box display='flex' justifyContent='space-between'>
                                                    <Typography color={"error"} variant="subtitle2">
                                                        {errors.point_of_contact_first_name && touched.point_of_contact_first_name && errors.point_of_contact_first_name}
                                                    </Typography>
                                                    <Typography style={{ color: values.point_of_contact_first_name?.length > 50 ? '#911717' : '#0000008a' }} variant="subtitle2">
                                                        {values.point_of_contact_first_name?.length === 51 ? 50 : values.point_of_contact_first_name?.length}/50
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={5} md={5}>
                                            <Box pb={1.0} pt={2}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Point of Contact Last Name
                                                    </Typography>
                                                </Box>
                                                <Field fullWidth disabled={(isEdit && values.poc_same_as_site_admin === false) ? false : true}
                                                    style={{ background: isError && inputs === 4 ? '#fff1f1' : 'unset' }}
                                                    onInput={(e: any) => handleChange(e.target.value, 4)}
                                                    as={TextField}
                                                    inputProps={{ maxLength: 51 }}
                                                    className={classes.textField}
                                                    name="point_of_contact_last_name"
                                                    size="small"
                                                    variant="outlined"
                                                />
                                                <Box display='flex' justifyContent='space-between'>
                                                    <Typography color={"error"} variant="subtitle2">
                                                        {errors.point_of_contact_last_name && touched.point_of_contact_last_name && errors.point_of_contact_last_name}
                                                    </Typography>
                                                    <Typography style={{ color: values.point_of_contact_last_name?.length > 50 ? '#911717' : '#0000008a' }} variant="subtitle2">
                                                        {values.point_of_contact_last_name?.length === 51 ? 50 : values.point_of_contact_last_name?.length}/50
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={5} md={5}>
                                            <Box pb={1.0}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Point of Contact Email Address
                                                    </Typography>
                                                </Box>
                                                <Field fullWidth disabled={(isEdit && values.poc_same_as_site_admin === false) ? false : true}
                                                    as={TextField}
                                                    className={classes.textField}
                                                    name="point_of_contact_email"
                                                    size="small"
                                                    variant="outlined"
                                                />
                                                {errors.point_of_contact_email && touched.point_of_contact_email ? (
                                                    <Typography color="error" variant="subtitle2">
                                                        {errors.point_of_contact_email}
                                                    </Typography>
                                                ) : null}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={5} md={5}>
                                            <Box pb={2}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Point of Contact Phone Number
                                                    </Typography>
                                                </Box>
                                                <Field fullWidth disabled={(isEdit && values.poc_same_as_site_admin === false) ? false : true}
                                                    as={TextField}
                                                    onInput={(e: any) => {
                                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 12)
                                                    }}
                                                    className={classes.textField}
                                                    name="point_of_contact_phone"
                                                    size="small"
                                                    variant="outlined"
                                                />
                                                {errors.point_of_contact_phone && touched.point_of_contact_phone ? (
                                                    <Typography color="error" variant="subtitle2">
                                                        {errors.point_of_contact_phone}
                                                    </Typography>
                                                ) : null}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={10} md={10}>
                                            <Box pb={2}>
                                                <Box pb={0.75}>
                                                    <Typography color="textSecondary" variant="subtitle2">
                                                        Notes
                                                    </Typography>
                                                </Box>
                                                <Field fullWidth multiline rows={4} disabled={isEdit ? false : true}
                                                    style={{ background: isError && inputs === 5 ? '#fff1f1' : 'unset' }}
                                                    onInput={(e: any) => handleChange(e.target.value, 5)}
                                                    as={TextField}
                                                    inputProps={{ maxLength: 251 }}
                                                    className={classes.textField}
                                                    name="notes"
                                                    size="small"
                                                    variant="outlined"
                                                />
                                                <Box display='flex' justifyContent='space-between'>
                                                    <Typography color={"error"} variant="subtitle2">
                                                        {errors.notes && touched.notes && errors.notes}
                                                    </Typography>
                                                    <Typography style={{ color: values.notes?.length > 250 ? '#911717' : '#0000008a' }} variant="subtitle2">
                                                        {values.notes?.length === 251 ? 250 : values.notes?.length}/250
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        {isEdit &&
                                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                {loggedInUser && (loggedInUser.primary_site_admin) && <LoadingButton
                                                    className={classes.deleteButton}
                                                    color="secondary"
                                                    variant="contained"
                                                    onClick={() => deletePopup()}
                                                >
                                                    Delete
                                                </LoadingButton>}
                                            </Grid>
                                        }
                                    </Grid>
                                </Box> :
                                <Box display='flex' justifyContent='center'><CircularProgress size={30} style={{ marginTop: '20px' }} /></Box>
                            }
                        </Form>
                    );
                }}
            </Formik>
            {isOpen &&
                <Dialog handleClose={handleClose} open={isOpen}>
                    <Box style={{ width: '400px' }}>
                        <Box pb={3} pt={2} px={4} display="flex" justifyContent="space-between">
                            <Typography color="textSecondary" variant="h3" style={{ textAlign: 'center' }}>This organization currently has {viewInfo.total_user_count} active users. Are you sure you want to delete this organization? Please type "{viewInfo.organization_name}" to confirm.</Typography>
                            {/* <Close onClick={() => setIsOpen(false)} /> */}
                        </Box>
                        {/* <Box pb={4} pt={2} display="flex" justifyContent="center">
                        <Typography color="textSecondary" variant="h4">This organization is currently assigned to {viewInfo.total_user_count} users.</Typography>
                    </Box> */}
                        <Box px={4} pb={2}>
                            <TextField fullWidth placeholder='[name of organization]' name="input" variant='outlined' size='small' value={input} onChange={(e) => setInput(e.target.value)} />
                            {error && (input === '' || input.toLowerCase() !== "delete") ? (
                                <Typography color="error" variant="subtitle2">
                                    {msg}
                                </Typography>
                            ) : null}
                        </Box>
                        <Box
                            className={classes.bottomBox}
                            display="flex"
                            justifyContent="space-between"
                            px={3}
                            py={2}
                        >
                            <LoadingButton
                                className={classes.secondaryButton}
                                color="secondary"
                                onClick={handleClose}
                                variant="contained"
                                aria-label="cancel"
                            >
                                Cancel
                            </LoadingButton>

                            <LoadingButton
                                className={classes.deleteButton}
                                color="secondary"
                                onClick={deleteOrganization}
                                variant="contained"
                                aria-label="confirm"
                            >
                                Delete
                            </LoadingButton>
                        </Box>
                    </Box>
                </Dialog>
            }
            {
                inviteOpen &&
                <Dialog handleClose={handleCloseDialog} open={inviteOpen}>
                    <Box style={{ width: '400px' }}>
                        <Box pb={3} pt={2} px={4} display="flex" justifyContent="space-between">
                            <Typography color="textSecondary" variant="h2">Invitation Success</Typography>
                            <Close onClick={() => setInviteOpen(false)} />
                        </Box>
                        <Box pb={6} pt={2} display="flex" justifyContent="center">
                            <Typography color="textSecondary" variant="h2">Invitation Sent</Typography>
                        </Box>
                    </Box>
                </Dialog>
            }
        </Box >
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    topBox: {
        borderRadius: '0.625rem 0.625rem 0 0'
    },
    bottomBox: {
        borderRadius: '0 0 0.625rem 0.625rem'
    },
    textField: {
        width: '100%'
    },
    primaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#fff',
        minWidth: '120px'
    },
    secondaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        height: '2rem',
        color: 'blue',
        marginRight: '10px',
        minWidth: '120px'
    },
    deleteButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        height: '2rem',
        color: 'red',
        minWidth: '120px'
    },
    createCollectionTextField: {
        maxWidth: '40rem',
        width: '80vw'
    },
    contentContainer: {
        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap'
        }
    },
    textField1: {
        width: '100%',
        height: '32px',
        padding: '0px'
    }
}));
