import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LoadingButton } from '../../../components/LoadingButton';
import { HeadCell, LastSorted, Pagination, RowCell, Table } from '../../../components/Table';
import { RootState } from '../../../redux/redux-store';
import { getPatientsIncompleteTasksThunk } from '../../../redux/thunk/thunks';
import { PatientTask } from '../../../shared/domain';

export const OutstandingTasksTable: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const getIncompleteTasksLoading = useSelector(
    (state: RootState) =>
      state.DISPLAY_STATE_REDUCER.displayLoading.getPatientFormInstancesIncompleteLoading
  );
  const incompleteTasksResult = useSelector(
    (state: RootState) => state.DATA_REDUCER.patientFormInstances.incomplete
  );

  useEffect(() => {
    //fetch patients outstanding tasks if they are not already in redux
    if (!incompleteTasksResult?.tasks) {
      dispatch(getPatientsIncompleteTasksThunk());
    }
  }, []);

  const [lastSorted, setLastSorted] = useState<LastSorted>({
    column: 'created_at',
    order: 'desc'
  });

  const headCells: HeadCell[] = [
    {
      id: 'form_title',
      label: 'FORM NAME'
    },
    {
      id: 'clinician_first_name',
      label: 'ASSIGNED BY'
    },
    {
      align: 'left',
      id: 'created_at',
      label: 'ASSIGNED ON'
    },
    {
      id: 'start_form_btn',
      label: ''
    }
  ];

  const rowCells = incompleteTasksResult?.tasks.map((task: PatientTask) => {
    const TakeFormButton = () => {
      return (
        //add route for patient to take form
        <LoadingButton
          onClick={() => history.push(`/form/${task.id}`)}
          variant="contained"
          color={'primary'}
          loading={false}
          aria-label="start form"
        >
          Start Form
        </LoadingButton>
      );
    };
    const rowCell: RowCell = {
      form_title: { value: task.form_title },
      clinician_first_name: {
        value: `${task.clinician_first_name} ${task.clinician_last_name}`
      },
      created_at: {
        align: 'left',
        value: new Date(task.created_at).toDateString()
      },
      start_form_btn: {
        component: <TakeFormButton />,
        value: 'test'
      }
    };

    return Object.assign(rowCell, { content: task });
  });

  const handleNextButton = async () => {
    if (
      incompleteTasksResult &&
      incompleteTasksResult.page >= 0 &&
      incompleteTasksResult.page < incompleteTasksResult.total / incompleteTasksResult.pageSize - 1
    ) {
      dispatch(
        getPatientsIncompleteTasksThunk(
          incompleteTasksResult.page + 1,
          incompleteTasksResult.pageSize
        )
      );
    }
  };
  const handlePrevButton = async () => {
    if (incompleteTasksResult && incompleteTasksResult.page > 0) {
      dispatch(
        getPatientsIncompleteTasksThunk(
          incompleteTasksResult.page - 1,
          incompleteTasksResult.pageSize
        )
      );
    }
  };

  const handlePageSize = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    const pageSize: number = e.target.value as number;

    if (incompleteTasksResult && pageSize > 0 && pageSize <= incompleteTasksResult.total) {
      dispatch(getPatientsIncompleteTasksThunk(0, pageSize));
    }
  };

  const handleSort = (sort: string) => {
    const nextDirection = lastSorted.order === 'desc' ? 'asc' : 'desc';
    const order = sort === lastSorted.column ? nextDirection : 'desc';

    dispatch(getPatientsIncompleteTasksThunk(0, incompleteTasksResult?.pageSize, sort, order));

    setLastSorted(
      sort === lastSorted.column
        ? { column: sort, order: nextDirection }
        : { column: sort, order: 'desc' }
    );
  };

  const pagination: Pagination | undefined = incompleteTasksResult
    ? {
        contentName: 'Task',
        page: incompleteTasksResult.page,
        pageSize: incompleteTasksResult.pageSize,
        total: incompleteTasksResult.total,
        handleNextButton: handleNextButton,
        handlePageSize: handlePageSize,
        handlePrevButton: handlePrevButton
      }
    : undefined;

  return (
    <Box pb={4} pt={1} px={4}>
      <Table
        data={incompleteTasksResult?.tasks}
        headCells={headCells}
        loading={getIncompleteTasksLoading}
        pagination={pagination}
        rowCells={rowCells}
        sort={{
          lastSorted: lastSorted,
          handleSort: handleSort
        }}
      />
    </Box>
  );
};
