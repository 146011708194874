import { Box, Card, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { LoadingOrError } from '../../components/LoadingOrError';
import { RootState } from '../../redux/redux-store';
import { getCalculationPagesByFormIdThunk, getMetricByFormIdMetricIdThunk, getRemainingMetricByFormIdMetricIdThunk, editCalculationThunk, editMultiMetricQuestionThunk } from '../../redux/thunk/thunks';
import { UuidType } from '../../shared/domain';
import { CalculationPageSubHeader } from './components/CalculationPageSubHeader';
import { CalculationPageViewer } from './components/CalculationPageViewer';
interface CalculationPageBuilderProps {
    formId: UuidType;
}

export const CalculationPageBuilder: React.FC<CalculationPageBuilderProps> = ({ formId }: any) => {
    const classes = useStyles();
    const [selectedCalPageId, setSelectedCalPageId] = useState<UuidType | null>();
    const dispatch = useDispatch();
    const [calculationPage, setCalPage] = useState<any>(null);
    const location: any = useLocation();
    const isEdit = location.state;

    const getExitPagesLoading = useSelector(
        (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.getExitPagesLoading
    );
    const calculationPages: any = useSelector((state: RootState) => state.DATA_REDUCER.calculationPages);
    const addedMetricFields: any = useSelector((state: RootState) => state.DATA_REDUCER.addedMetricFields);
    const editingForm: any = useSelector((state: RootState) => state.DATA_REDUCER.editingForm);
    const getExitPagesError = useSelector(
        (state: RootState) => state.DISPLAY_STATE_REDUCER.displayErrors.getExitPagesError
    );

    const handleSelectPage = async (event: React.ChangeEvent<{}>, value: string) => {
        const change: any = sessionStorage.getItem('onChange');
        const metric_id: any = sessionStorage.getItem('metric_id');
        const form_version_id = sessionStorage.getItem('version');
        const getChange = JSON.parse(change);
        if (getChange && getChange?.id) {
            const data = {
                "metric_name": getChange.code,
                "description": getChange.description,
                "form_id": formId
            }
            const result: any = await dispatch(editCalculationThunk(getChange?.id, data))
            if (result === 200) {
                if (calculationPages) {
                    let calPage = null;
                    for (var i = 0; i < calculationPages.length; i++) {
                        if (calculationPages[i].id === value) {
                            calPage = calculationPages[i];
                        }
                    }
                    dispatch(getMetricByFormIdMetricIdThunk(formId, value))
                    dispatch(getRemainingMetricByFormIdMetricIdThunk(formId, value))
                    setSelectedCalPageId(value);
                    setCalPage(calPage);
                    sessionStorage.removeItem('calculation');
                    sessionStorage.removeItem('metric_id');
                }
            }
            if (addedMetricFields && addedMetricFields.length > 0 && metric_id) {
                const calculation_data: any = [];
                for (let i = 0; i < addedMetricFields.length; i++) {
                    const array: any = [];
                    for (let j = 0; j < addedMetricFields[i].fields.length; j++) {
                        let regexPattern = /^-?[0-9]+$/;
                        let result = regexPattern.test(addedMetricFields[i].fields[j].calculation_point_amount);
                        const info = {
                            "comparison_option_id": addedMetricFields[i].fields[j].comparison_option_id,
                            "comparison_value": addedMetricFields[i].fields[j].comparison_value,
                            "operator_type": addedMetricFields[i].fields[j].operator_type,
                            "calculation_operator": addedMetricFields[i].fields[j].calculation_operator,
                            "calculation_point_amount": addedMetricFields[i].fields[j].calculation_point_amount
                        }
                        array.push(info);
                    }
                    const data = {
                        "form_field_id": addedMetricFields[i].id,
                        "metric_id": metric_id,
                        "calc_data": array,
                        "form_version_id": form_version_id
                    }
                    calculation_data.push(data);
                }
                await dispatch(editMultiMetricQuestionThunk(editingForm?.id, calculation_data, metric_id));
            }
        } else {
            if (calculationPages) {
                let calPage = null;
                for (var i = 0; i < calculationPages.length; i++) {
                    if (calculationPages[i].id === value) {
                        calPage = calculationPages[i];
                    }
                }
                dispatch(getMetricByFormIdMetricIdThunk(formId, value))
                dispatch(getRemainingMetricByFormIdMetricIdThunk(formId, value))
                setSelectedCalPageId(value);
                setCalPage(calPage);
                sessionStorage.removeItem('calculation');
                sessionStorage.removeItem('metric_id');
            }
            if (addedMetricFields && addedMetricFields.length > 0 && metric_id) {
                const calculation_data: any = [];
                for (let i = 0; i < addedMetricFields.length; i++) {
                    const array: any = [];
                    for (let j = 0; j < addedMetricFields[i].fields.length; j++) {
                        let regexPattern = /^-?[0-9]+$/;
                        let result = regexPattern.test(addedMetricFields[i].fields[j].calculation_point_amount);
                        const info = {
                            "comparison_option_id": addedMetricFields[i].fields[j].comparison_option_id,
                            "comparison_value": addedMetricFields[i].fields[j].comparison_value,
                            "operator_type": addedMetricFields[i].fields[j].operator_type,
                            "calculation_operator": addedMetricFields[i].fields[j].calculation_operator,
                            "calculation_point_amount": addedMetricFields[i].fields[j].calculation_point_amount
                        }
                        array.push(info);
                    }
                    const data = {
                        "form_field_id": addedMetricFields[i].id,
                        "metric_id": metric_id,
                        "calc_data": array,
                        "form_version_id": form_version_id
                    }
                    calculation_data.push(data);
                }
                await dispatch(editMultiMetricQuestionThunk(editingForm?.id, calculation_data, metric_id));
            }
        }
    };

    useEffect(() => {
        dispatch(getCalculationPagesByFormIdThunk(formId));
    }, []);

    useEffect(() => {
        if (calculationPages && calculationPages.length > 0) {
            setSelectedCalPageId(calculationPages[0].id);
            setCalPage(calculationPages[0]);
            dispatch(getMetricByFormIdMetricIdThunk(formId, calculationPages[0].id))
            dispatch(getRemainingMetricByFormIdMetricIdThunk(formId, calculationPages[0].id))
            sessionStorage.removeItem('calculation');
            sessionStorage.removeItem('metric_id');
        } else {
            setCalPage(null);
        }
    }, [calculationPages]);

    return (
        <Box className={classes.main} display="flex" flexDirection="column">
            <CalculationPageSubHeader
                selectedCalPageId={selectedCalPageId}
                handleSelectPage={handleSelectPage}
                formId={formId}
            />
            <Container style={{ padding: '0px' }}>
                {getExitPagesLoading ? (
                    <Box m={6} className={classes.placeHolder}>
                        <Box className={classes.loadingContainer}>
                            <LoadingOrError
                                loading={getExitPagesLoading}
                                loadingMsg={'Loading Calculation Page...'}
                                errorMsg={getExitPagesError}
                            />
                        </Box>
                    </Box>
                ) : (
                    <Card className={classes.content}>
                        <Grid container>
                            <Grid item xs={12}>
                                <CalculationPageViewer calculationPage={calculationPage} />
                            </Grid>
                        </Grid>
                    </Card>
                )}
            </Container>
        </Box>
    );
};

const useStyles = makeStyles((theme) => ({
    main: {
        padding: '35px 0px 20px'
    },
    content: {
        display: 'flex',
        backgroundColor: '#ffffff',
        marginTop: '30px'
    },
    loadingContainer: {
        maxWidth: '30rem'
    },
    placeHolder: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));
