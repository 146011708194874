import { Box, Grid, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardHeader } from '../../../../components/LegacyCardHeader';
import { RootState } from '../../../../redux/redux-store';
import { getSharedUserInfoThunk, getPatientInfoThunk, getResultsThunk } from '../../../../redux/thunk/thunks';
import { setSuccessAction } from '../../../../redux/display/display-actions';
import { ResultTable } from './ResultTable';
import { useParams, useRouteMatch } from 'react-router-dom';

export const Results = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const param: any = useParams();
    const { path } = useRouteMatch();
    const role: any = sessionStorage.getItem('role');
    const split = path.split('/');
    const patient_role_id: any = split && split.length > 1 && split[2];
    const shared_role_id: any = split && split.length > 3 && split[4];
    const [searchPattern, setSearchPattern] = useState('');
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const [onTab, setOnTab] = useState('');
    const [viewCompDialogueOpen, setViewComponentDialogueOpen] = useState(false);

    const patient: any = useSelector((state: RootState) => state.DATA_REDUCER.patientInfo);
    const user: any = useSelector((state: RootState) => state.DATA_REDUCER.sharedUser);
    const formResults: any = useSelector((state: RootState) => state.DATA_REDUCER.formResults);

    const success: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.getForms)
    useEffect(() => {
        if (success) {
            setViewComponentDialogueOpen(false);
            dispatch(setSuccessAction('getForms', false));
            dispatch(getResultsThunk({ search: searchPattern, sort: formResults.sort, order: formResults.order, user_role_id: role === 'PATIENT' ? patient_role_id : shared_role_id, status: formResults?.status, page: formResults?.page, pageSize: formResults?.pageSize }))
        }
    })

    useEffect(() => {
        !patient && patient_role_id && dispatch(getPatientInfoThunk(patient_role_id));
        !user && shared_role_id && dispatch(getSharedUserInfoThunk(shared_role_id));
    }, []);

    const viewComponentOpen = (open: any) => {
        setViewComponentDialogueOpen(open);
    };

    const handleSearch = (value: string) => {
        setSearchPattern(value);
        if (formResults) {
            dispatch(
                getResultsThunk({
                    user_role_id: role === 'PATIENT' ? patient_role_id : shared_role_id,
                    order: formResults.order,
                    search: value,
                    sort: formResults.sort,
                    pageSize: formResults?.pageSize,
                    status: formResults?.status
                })
            )
        }
    };

    // We need async/await over here as it is used for some state loading task
    const loadData = async (props: any) => {
        props.user_role_id = role === 'PATIENT' ? patient_role_id : shared_role_id
        await dispatch(getResultsThunk(props));
    };

    const onTabOneChange = async (e: any) => {
        if (e === 'Completed Items' && (onTab !== e || onTab === '')) {
            setOnTab(e);
            await dispatch(getResultsThunk({ search: searchPattern, status: 'COMPLETE', user_role_id: role === 'PATIENT' ? patient_role_id : shared_role_id }));
        }
    }

    const onTabTwoChange = async (e: any) => {
        if (e === 'Outstanding Items' && (onTab !== e || onTab === '')) {
            setOnTab(e);
            await dispatch(getResultsThunk({ search: searchPattern, status: 'IN_PROGRESS', user_role_id: role === 'PATIENT' ? patient_role_id : shared_role_id }));
        }
    }

    const TabTableContainer = (
        <Box pb={4} pt={1} px={4}>
            <ResultTable
                tableData={formResults}
                loadData={loadData}
                viewComponentOpen={viewComponentOpen}
                viewCompDialogueOpen={viewCompDialogueOpen}
                tab={onTab}
            />
        </Box>
    );

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Paper>
                        <Box display="flex" flexDirection="column">
                            <CardHeader
                                tabComponents={[
                                    {
                                        component: TabTableContainer,
                                        label: 'Completed Items',
                                        style: {
                                            background: true, spacing: { pb: 0, pt: 3, px: 4 }
                                        },
                                        title: 'Results',
                                        handleSearch: handleSearch,
                                        onTabChange: onTabOneChange
                                    },
                                    {
                                        component: TabTableContainer,
                                        label: 'Outstanding Items',
                                        style: {
                                            background: true, spacing: { py: 3, px: 4, pb: 0 }
                                        },
                                        title: 'Results',
                                        handleSearch: handleSearch,
                                        onTabChange: onTabTwoChange
                                    },
                                ]}
                            />
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

const useStyles = makeStyles({
    filterSection: {
        overflowY: 'auto',
        maxHeight: '35rem'
    }
});
