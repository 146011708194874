import React, { useEffect, useState } from 'react';
import { Box, makeStyles, Paper, Theme, Typography, Popover, Divider, Card, TextField, Button, Tabs, Tab, DialogActions, DialogTitle, DialogContent, CircularProgress } from '@material-ui/core';
import MUIDialog from '@material-ui/core/Dialog'
import { Search } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { CardHeader } from '../../../components/LegacyCardHeader';
import { getPatientsThunk, getSiteAdminPatientsThunk, deletePatientThunk, removeFavoritePatientThunk } from '../../../redux/thunk/thunks';
import { ConfirmationDialog } from '../../../components/ConfirmationDialog';
import { PatientsTable } from './PatientsTable';
import { RootState } from '../../../redux/redux-store';
import { NewPatient } from './NewPatient';
import { LoadingButton } from '../../../components/LoadingButton';
import { UpdatePatient } from './UpdatePatient';
import { ROLES } from '../../../shared/globals';
import { url } from '../../../redux/url';
import { snackbarAlertAction } from '../../../redux/display/display-actions';
import { getPatientsAction } from '../../../redux/data/data-actions';

var axios = require("axios");
var FormData = require("form-data");

export const Patients = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedPatients, setSelectedPatients] = useState<any[]>([]);
  const [newPatientDialogueOpen, setNewPatientDialogueOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [openUploadPopup, setOpenUploadPopup] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [search, setSearch] = useState('');
  const [deletePatientConfirmationDialogOpen, setDeletePatientConfirmationDialogOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isView, setView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isTab, setTab] = useState(0);
  const [patientInfo, setPatientInfo] = useState<any>(null);
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);

  const patientSearchResults: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.patientSearchResults
  );

  const success: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.createCollectionSuccess)
  useEffect(() => {
    if (success) {
      if (loggedInUser && [ROLES.SITE_ADMIN, ROLES.CLINICIAN].includes(loggedInUser.role)) {
        dispatch(getSiteAdminPatientsThunk({
          order: patientSearchResults?.order,
          page: patientSearchResults.page,
          pageSize: patientSearchResults?.pageSize,
          search: patientSearchResults?.search,
          sort: patientSearchResults?.sort,
          favourites: patientSearchResults?.favourites
        }));
      } else {
        dispatch(getPatientsThunk({}));
      }
      setNewPatientDialogueOpen(false);
      setView(false);
    }
  })

  const handleSearch = (value: string) => {
    setSearch(value);
    if (loggedInUser && [ROLES.SITE_ADMIN, ROLES.CLINICIAN].includes(loggedInUser.role)) {
      if (patientSearchResults) {
        dispatch(getSiteAdminPatientsThunk({
          order: patientSearchResults?.order,
          page: 0,
          pageSize: patientSearchResults?.pageSize,
          search: value,
          sort: patientSearchResults?.sort,
          favourites: patientSearchResults?.favourites
        }));
      }
    }
  };

  const loadPatients = async (getPatientsProps: any) => {
    if (loggedInUser && [ROLES.SITE_ADMIN, ROLES.CLINICIAN].includes(loggedInUser.role)) {
      dispatch(getSiteAdminPatientsThunk(getPatientsProps));
    }
  };

  useEffect(() => {
    if (loggedInUser && [ROLES.SITE_ADMIN, ROLES.CLINICIAN].includes(loggedInUser.role)) {
      dispatch(getSiteAdminPatientsThunk({}));
    }

    return function CleanUp() {
      dispatch(getPatientsAction(null));
    };
  }, []);

  const handleNewPatientButton = () => {
    setNewPatientDialogueOpen(true);
    setAnchorEl(null)
  };
  const handleNewPatientDialogueClose = () => {
    setNewPatientDialogueOpen(false);
  };

  const PatientsTableContainer = (
    <Box pb={4} pt={1} px={4}>
      <PatientsTable
        isTab={isTab}
        patientsData={patientSearchResults}
        selectedPatients={selectedPatients}
        setSelectedPatients={(selectedPatients: any[]) =>
          setSelectedPatients(selectedPatients)
        }
        loadPatients={loadPatients}
        setPatientInfo={setPatientInfo}
        setView={setView}
      />
    </Box>
  );

  const handleDeletePatientConfirmationDialogClose = () => {
    setDeletePatientConfirmationDialogOpen(false);
  };
  const handleDeletePatientConfirmationDialogOpen = () => {
    setDeletePatientConfirmationDialogOpen(true);
  };
  const handleDeletePatient = async (library?: boolean) => {
    setDeleteLoading(true);

    const delete_ids = selectedPatients;

    await dispatch(deletePatientThunk(delete_ids));

    setSelectedPatients([]);
    setDeleteLoading(false);
    setDeletePatientConfirmationDialogOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  }
  const handlePopover = (e: any) => {
    setAnchorEl(e.currentTarget)
  }

  const handleChange = async (event: any, newValue: any) => {
    setSelectedPatients([]);
    setTab(newValue);
    if (newValue === 0) {
      dispatch(getSiteAdminPatientsThunk({}));
      setSearch('')
    } else if (newValue === 1) {
      dispatch(getSiteAdminPatientsThunk({ favourites: false, sort: 'last_login', order: 'DESC' }));
      setSearch('')
    } else if (newValue === 2) {
      dispatch(getSiteAdminPatientsThunk({ favourites: false }));
      setSearch('')
    }
  }

  const _getHeaders = (inputHeaders?: { [key: string]: string }) => {
    let headers = inputHeaders || {};
    let token = localStorage.getItem('token');
    headers["Accept"] = "application/json";
    const headersWithAuth = token
      ? {
        ...headers,
        Authorization: `Bearer ${token}`,
      }
      : headers;
    return headersWithAuth;
  };

  const uploadCSV = async (file: any) => {
    setIsUpload(true);
    var data = new FormData();
    data.append("input_file", file[0]);
    data.append("organization_id", loggedInUser.organization_id);
    const config1 = {
      method: 'post',
      url: `${url}/patient/addPatientsUsingCSV`,
      headers: _getHeaders({ "Content-Type": "multipart/form-data" }),
      data: data,
    };

    await axios(config1)
      .then(function (res: any) {
        dispatch(getSiteAdminPatientsThunk({
          order: patientSearchResults?.order,
          page: 0,
          pageSize: patientSearchResults?.pageSize,
          search: patientSearchResults?.search,
          sort: patientSearchResults?.sort,
          favourites: patientSearchResults?.favourites
        }));
        setOpenUploadPopup(false);
        setIsUpload(false);
        dispatch(snackbarAlertAction(`${res.data.message}`, 'success'));
      }).catch((e: any) => {
        setIsUpload(false);
        dispatch(snackbarAlertAction(`Error: ${e.response.data.message}`, 'error'));
      })
  }

  const handleClick = async () => {
    setLoading(true);
    await dispatch(removeFavoritePatientThunk(selectedPatients));
    setLoading(false);
    if (loggedInUser && [ROLES.SITE_ADMIN, ROLES.CLINICIAN].includes(loggedInUser.role)) {
      dispatch(getSiteAdminPatientsThunk({
        order: patientSearchResults?.order,
        page: patientSearchResults?.page,
        pageSize: patientSearchResults?.pageSize,
        search: patientSearchResults?.search,
        sort: patientSearchResults?.sort,
        favourites: patientSearchResults?.favourites
      }));
    }
    setSelectedPatients([]);
  }

  const handleChangeFavorite = (value: boolean) => {
    patientInfo.isFavourite = value;
    setPatientInfo(patientInfo);
  }

  return (
    <>

      {newPatientDialogueOpen && (
        <NewPatient open={newPatientDialogueOpen} handleClose={handleNewPatientDialogueClose} />
      )}
      {isView ? <UpdatePatient handleChangeFavorite={handleChangeFavorite} patient={patientInfo} setView={setView} /> :
        <Paper>
          <Box display="flex" flexDirection="column">
            {loggedInUser && [ROLES.SITE_ADMIN, ROLES.CLINICIAN].includes(loggedInUser.role) ?
              <Card>
                <Box p={4} style={{ background: '#f9fafb', borderBottom: '0.0625rem solid #d1d6dc', paddingBottom: 0 }} >
                  <Box display='flex' justifyContent='space-between'>
                    <TextField className={classes.searchTextField} value={search}
                      inputProps={{
                        maxLength: 150,
                      }}
                      InputProps={{
                        startAdornment: (
                          <Box ml={-0.5} mr={1} mt={0.5}>
                            <Search className={classes.searchIcon} color="disabled" />
                          </Box>
                        )
                      }} size='small' variant='outlined' placeholder='Search...' onChange={(e) => handleSearch(e.target.value)} />
                    {selectedPatients?.length > 0 ?
                      <Box alignItems="center" display="flex">
                        <Typography
                          className={classes.selectedText}
                          variant="h5"
                        >{`${selectedPatients?.length} selected`}</Typography>

                        <Box alignSelf="stretch" display="flex" px={2}>
                          <Divider flexItem orientation="vertical" />
                        </Box>
                        <Box pr={2}>
                          <LoadingButton
                            className={classes.primaryButton}
                            color="primary"
                            disabled={loading}
                            loading={loading}
                            onClick={handleClick}
                            variant="contained"
                          >
                            Remove
                          </LoadingButton>
                        </Box>
                      </Box> :
                      <><Button color='primary' className={classes.primaryButton} onClick={(e) => handlePopover(e)}>Add</Button>
                        <Popover
                          open={Boolean(anchorEl)}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >
                          <Paper style={{
                            boxShadow: 'unset',
                          }}>
                            <Typography className={classes.actionTypography} onClick={() => handleNewPatientButton()}>Add Patient</Typography>
                            <Divider />
                            <Typography style={{ color: '#0060B2' }} className={classes.actionTypography} onClick={(e) => { setOpenUploadPopup(true); setAnchorEl(null) }}>Upload CSV File</Typography>
                          </Paper>
                        </Popover>
                      </>
                    }
                  </Box>
                  <Box>
                    <Tabs
                      value={isTab}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={handleChange}
                      aria-label="disabled tabs example"
                    >
                      <Tab label="Saved Patients" value={0} />
                      <Tab label="Recent Patients" value={1} />
                      <Tab label="All Patients" value={2} />
                    </Tabs>
                  </Box>
                </Box>
                <Box pb={4} pt={1} px={4}>
                  <PatientsTable
                    isTab={isTab}
                    patientsData={patientSearchResults}
                    selectedPatients={selectedPatients}
                    setSelectedPatients={(selectedPatients: any[]) =>
                      setSelectedPatients(selectedPatients)
                    }
                    loadPatients={loadPatients}
                    setPatientInfo={setPatientInfo}
                    setView={setView}
                  />
                </Box>
              </Card>
              :
              <CardHeader
                tabComponents={[
                  {
                    component: PatientsTableContainer,
                    label: 'Patients',
                    primaryButton: {
                      text: 'Add',
                      handleClick: handleNewPatientButton
                    },
                    selected: {
                      selectedContents: selectedPatients,
                      deleteButton: {
                        text: 'Delete',
                        handleClick: handleDeletePatientConfirmationDialogOpen
                      }
                    },
                    style: {
                      background: true,
                      spacing: { py: 3, px: 4 }
                    },
                    title: 'Patients',
                    handleSearch: handleSearch
                  }
                ]}
              />
            }
          </Box>

        </Paper>
      }
      {deletePatientConfirmationDialogOpen &&
        <ConfirmationDialog
          loading={deleteLoading}
          message={`Are you sure you want to delete? Please type "DELETE" to confirm.`}
          open={deletePatientConfirmationDialogOpen}
          title=""
          handleClose={handleDeletePatientConfirmationDialogClose}
          handleConfirmClick={() => handleDeletePatient()}
        />
      }
      {openUploadPopup &&
        <MUIDialog open={openUploadPopup} >
          <DialogTitle style={{ paddingBottom: '0px' }}>Upload patient CSV</DialogTitle>
          <DialogContent>
            <h6 style={{ fontSize: '11px', color: '#a5a5a5', fontWeight: 400, margin: '-10px 0px 10px' }}>Supported formats: .CSV</h6>
            <Box>
              <div>
                <input
                  accept=".csv"
                  className={classes.input}
                  id="button-file"
                  onChange={(e) => uploadCSV(e.target.files)}
                  type="file"
                />
                <label htmlFor="button-file">
                  <Button variant="contained" component="span" color='primary' style={{ width: '100%', cursor: isUpload ? '' : 'pointer' }} className={classes.primaryButton} disabled={isUpload}>
                    Upload File {isUpload && <CircularProgress
                      size={16}
                      style={{ color: '#fff' }}
                    />}
                  </Button>
                </label>
              </div>
              {error ? (
                <Typography color="error" variant="subtitle2" style={{ marginBottom: '10px' }}>
                  Something went wrong!
                </Typography>
              ) : null}
            </Box>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center', padding: '0px 22px 20px' }}>
            <LoadingButton
              className={classes.secondaryButton}
              variant="contained"
              style={{ marginBottom: '0px', width: '-webkit-fill-available' }}
              onClick={(e) => setOpenUploadPopup(false)}
            >
              Cancel
            </LoadingButton>
          </DialogActions>
        </MUIDialog>
      }
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  topBox: {
    borderRadius: '0.625rem 0.625rem 0 0'
  },
  bottomBox: {
    borderRadius: '0 0 0.625rem 0.625rem'
  },
  primaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: '#fff',
    minWidth: '120px',
    marginBottom: '5px',
    background: '#0060B2',
    '&:hover': {
      backgroundColor: '#0060B2',
    }
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)'
  },
  actionTypography: {
    padding: '10px',
    fontWeight: 'bold',
    cursor: 'pointer'
  },
  searchTextField: {
    backgroundColor: theme.palette.secondary.main,
    fontSize: theme.typography.h5.fontSize,
    height: '2rem',
    width: '14rem'
  },
  searchIcon: {
    fontSize: 17
  },
  input: {
    display: 'none'
  },
  selectedText: {
    color: '#575757',
    fontWeight: 400
  },
}));
