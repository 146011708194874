import { Box, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { FieldOption, UuidType } from '../../../shared/domain';
import { TitleInfo } from './TitleInfo';

interface SingleChoiceQuestionProps {
  fieldTitle: string;
  options: FieldOption[];
  color: string;
  handleInput: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  handleChangeInput: (value: string) => void;
  handleCustomTextInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  textInput?: string;
  responseValue?: UuidType;
  clinicianInfo?: string;
  patientInfo?: string;
  index: any;
}

interface ComponentProps {
  customColor: string;
}
export const SingleChoiceQuestion: React.FC<SingleChoiceQuestionProps> = ({
  fieldTitle: title,
  options,
  color,
  handleInput,
  handleChangeInput,
  handleCustomTextInput,
  textInput,
  responseValue,
  clinicianInfo,
  patientInfo,
  index
}) => {
  const props = { customColor: color };
  const classes = useStyles(props);

  return (
    <>
      <Box p={1}>
        <TitleInfo patientInfo={patientInfo} clinicianInfo={clinicianInfo} title={title} index={index} />
      </Box>
      <RadioGroup className={classes.radioGroup}>
        {options.map((option, i) => {
          const checked = responseValue === option.id;
          return (
            <Box className={checked ? classes.selected : classes.root} onClick={() => handleChangeInput(option.id)}>
              <Radio checked={checked} onChange={handleInput} value={option.id} />
              <Typography color={checked ? 'secondary' : 'textPrimary'} variant={'subtitle1'}>
                {option.option_text}
              </Typography>
              {/* if option allows user input show text field*/}
              {option.allow_user_input && (
                <TextField
                  color={checked ? 'secondary' : undefined}
                  inputProps={{ className: classes.customInputText }}
                  disabled={!checked}
                  className={checked ? classes.selectedCustomInput : classes.customInput}
                  placeholder="Input your answer here"
                  variant="standard"
                  size="small"
                  onChange={handleCustomTextInput}
                  value={textInput}
                  fullWidth
                />
              )}
            </Box>
          );
        })}
      </RadioGroup>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  customInput: {
    marginLeft: '2rem'
  },
  selectedCustomInput: {
    'marginLeft': '2rem',
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white'
    },
    '& .MuiOutlinedInput-input': {
      color: 'white'
    },
    '& .MuiInputLabel-outlined': {
      color: 'white'
    }
  },
  customInputText: {
    color: 'white',
    fontColor: 'white'
  },
  test: {
    color: 'black'
  },
  radioGroup: {
    width: '100%'
  },
  selectRoot: {
    padding: '.6rem'
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '2rem',
    margin: '.4rem',
    padding: '.3rem',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[300]}`,
    cursor: 'pointer'
  },
  selected: (props: ComponentProps) => ({
    backgroundColor: props.customColor,
    display: 'flex',
    alignItems: 'center',
    height: '2rem',
    margin: '.4rem',
    padding: '.3rem',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[300]}`,
    cursor: 'pointer'
  })
}));
