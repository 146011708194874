import { Box, makeStyles, Theme, Typography, CircularProgress, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { HeadCell, LastSorted, Pagination, RowCell, Table } from '../../../../../components/Table';
import { getCompletedCollectionResultsThunk } from '../../../../../redux/thunk/thunks';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/redux-store';
import moment from 'moment';
import { colors } from '../../../../../styling/colors';
import { useHistory, useLocation } from 'react-router-dom';
import { ViewFormPage } from './ViewForm';
import { getFormResponseDetailsAction } from '../../../../../redux/data/data-actions';

export const CollectionResultTable = () => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch()
    const organization_id: any = sessionStorage.getItem('pat_org_id');
    const infoItem: any = sessionStorage.getItem('res_item');
    const resInfo = JSON.parse(infoItem);
    const [resultInfo, setDataInfo] = useState<any>('');
    const [value, setValue] = useState<any>();
    const [openForm, setOpenForm] = useState(false);
    const [lastSorted, setLastSorted] = useState<LastSorted>({
        column: 'form_title',
        order: 'desc'
    });
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const role: any = sessionStorage.getItem('role');
    const split = location.pathname.split('/');
    const patient_role_id: any = split && split.length > 1 && split[2];
    const shared_role_id: any = split && split.length > 3 && split[4];

    const completedCollectionResults: any = useSelector((state: RootState) => state.DATA_REDUCER.completedCollectionResults);
    const loading: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.getDataLoading);

    useEffect(() => {
        dispatch(getCompletedCollectionResultsThunk({
            user_role_id: role === 'SHARED_USER' ? shared_role_id : patient_role_id,
            collection_id: resInfo?.item_id, recurrence_id: resInfo?.recurrence_id
        }));
    }, [])

    const headCells: HeadCell[] = [
        {
            id: 'form_title',
            label: 'FORM NAME'
        },
        {
            align: 'center',
            id: 'completed_by_name',
            label: 'ANSWERED BY',
        },
        {
            align: 'center',
            id: 'recurrence_count',
            label: 'COUNT',
        },
        {
            align: 'center',
            id: 'form_sequence',
            label: 'SEQUENCE',
        }
    ];

    const rowCells = completedCollectionResults?.forms?.map((item: any) => {
        const nameLink = (<Typography color="primary" variant="h5" style={{ cursor: 'pointer' }} className={classes.itemName} onClick={(e) => { handleViewComponentDialogueOpen(item); }}>
            {item.form_title}
        </Typography>
        );

        let rowCell: RowCell = {};
        rowCell = {
            form_title: { component: nameLink, value: (item.form_title) },
            completed_on: {
                component: moment(item.completed_on).format('DD MMMM YYYY'),
                value: '-',
                align: 'center',
            },
            completed_by_name: {
                align: 'center',
                value: item.completed_by_name || 'NA',
            },
            recurrence_count: { align: 'center', value: `${item?.recurrence_count || 0} of ${item?.total_recurrence_count}` },
            form_sequence: {
                align: 'center',
                value: item?.form_sequence
            }
        }
        return Object.assign(rowCell, { content: item });
    });

    const handleSort = (sort: string) => {
        const nextDirection = lastSorted.order === 'desc' ? 'asc' : 'desc';

        dispatch(getCompletedCollectionResultsThunk({
            order: sort === lastSorted.column ? nextDirection : 'desc',
            user_role_id: role === 'SHARED_USER' ? shared_role_id : patient_role_id,
            search: completedCollectionResults?.search,
            collection_id: resInfo?.item_id,
            recurrence_id: resInfo?.recurrence_id,
            sort,
        }));

        setLastSorted(
            sort === lastSorted.column
                ? { column: sort, order: nextDirection }
                : { column: sort, order: 'desc' }
        );
    };

    const handleViewComponentDialogueOpen = async (info: any) => {
        setDataInfo(info);
        setOpenForm(true);
    };

    const handleSearch = async (value: any) => {
        setValue(value)
        value?.length > 1 ? await dispatch(getCompletedCollectionResultsThunk({
            user_role_id: role === 'SHARED_USER' ? shared_role_id : patient_role_id,
            search: value,
            collection_id: resInfo?.item_id,
            recurrence_id: resInfo?.recurrence_id,
        })) : value?.length === 0 && await dispatch(getCompletedCollectionResultsThunk({
            user_role_id: role === 'SHARED_USER' ? shared_role_id : patient_role_id,
            search: '',
            collection_id: resInfo?.item_id,
            recurrence_id: resInfo?.recurrence_id,
        }));
    }

    const handleClose = () => {
        setOpenForm(false);
        setDataInfo(null);
        dispatch(getFormResponseDetailsAction(null));
    }

    return (
        loading ? <Box display='flex' justifyContent='center'><CircularProgress /></Box> : <>
            <Box mt={3} display='flex' alignItems='center'>
                <Box>
                    <Box>
                        <Typography className={classes.subtitle}>
                            {'FORMS IN'}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography color="textSecondary" style={{ fontSize: '20px' }}>
                            <b>{resInfo?.item_name}</b>
                        </Typography>
                    </Box>
                </Box>
                <Box ml={20}>
                    <TextField className={classes.searchTextField}
                        inputProps={{
                            maxLength: 150,
                        }}
                        InputProps={{
                            startAdornment: (
                                <Box ml={-0.5} mr={1} mt={0.5}>
                                    <Search className={classes.searchIcon} color="disabled" />
                                </Box>
                            )
                        }} size='small' variant='outlined' placeholder='Search...' onChange={(e) => handleSearch(e.target.value)} />
                </Box>
            </Box>
            <Table
                data={completedCollectionResults?.forms}
                headCells={headCells}
                loading={!completedCollectionResults?.forms}
                rowCells={rowCells}
                sort={{
                    lastSorted: lastSorted,
                    handleSort: handleSort
                }}
            />
            {openForm && <ViewFormPage handleClose={handleClose} formInfo={resultInfo} />}
        </>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    nameLink: {
        textDecoration: 'none'
    },
    itemName: {
        display: 'flex'
    },
    launchIcon: {
        fontSize: '2rem',
        cursor: 'pointer'
    },
    InfoIcon: {
        fontSize: 16,
        marginLeft: 6
    },
    color: {
        color: '#0060B2'
    },
    searchTextField: {
        backgroundColor: theme.palette.secondary.main,
        fontSize: theme.typography.h5.fontSize,
        height: '2rem',
        width: '14rem'
    },
    searchIcon: {
        fontSize: 17
    },
    tableBox: {
        padding: '2px 8px',
        marginRight: '5px',
        minWidth: '80px',
    },
    actionTypography: {
        padding: '10px',
        cursor: 'pointer',
        fontWeight: 'bold'
    },
    subtitle: {
        color: colors.text6,
        fontWeight: 600
    },
}));