import React, { useEffect, useState } from 'react';
import { Box, makeStyles, Paper, Theme, Typography, ListItem, Divider, Card, TextField, CircularProgress, Tabs, Tab, FormControlLabel, Checkbox, DialogActions } from '@material-ui/core';
import MUIDialog from '@material-ui/core/Dialog'
import { Search, Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientsThunk, getSiteAdminPatientsThunk, getSharingUserThunk, checkUserResourceAssignmentThunk, assignResourceThunk, assignReferralThunk, checkUserReferralAssignmentThunk } from '../../../redux/thunk/thunks';
import { RootState } from '../../../redux/redux-store';
import { LoadingButton } from '../../../components/LoadingButton';
import { ROLES } from '../../../shared/globals';
import { assignmentInfoAction } from '../../../redux/data/data-actions';
import { setSuccessAction, snackbarAlertAction } from '../../../redux/display/display-actions';
import moment from 'moment'
import { useRouteMatch } from 'react-router-dom';

export const Patients = ({ handelClose }: any) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [selectedPatients, setSelectedPatients] = useState<any[]>([]);
    const [checkPatient, setCheckPatient] = useState<any[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
    const [selectedSharedUser, setSelectedSharedUser] = useState<any[]>([]);
    const [newPatientDialogueOpen, setNewPatientDialogueOpen] = useState(false);
    const [openSharing, setOpenSharing] = useState(false);
    const [search, setSearch] = useState('');
    const [isAdd, setIsAdd] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [okButton, setOkButton] = useState<any>([]);
    const [check, setCheck] = useState(false);
    const [isTab, setTab] = useState(0);
    const [patientInfo, setPatientInfo] = useState<any>(null);
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const location = window.location.pathname;
    const { path } = useRouteMatch();
    const split = path.split('/');
    const patient_role_id: any = split && split.length > 1 && split[2];
    const section: any = split && split.length > 2 && split[3];
    const section1: any = split && split.length > 4 && split[5];

    const info: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.assignmentList
    );
    const patient: any = useSelector((state: RootState) => state.DATA_REDUCER.patientInfo);
    const checkUsersAssignment: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.checkUsersAssignment
    );
    const patientSearchResults: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.patientSearchResults
    );
    const sharedUsers: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.sharingUser
    );
    const success: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.createCollectionSuccess)
    useEffect(() => {
        if (success) {
            if (loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER, ROLES.CLINICIAN].includes(loggedInUser.role)) {
                dispatch(getSiteAdminPatientsThunk({
                    order: patientSearchResults.order,
                    page: 0,
                    pageSize: patientSearchResults.pageSize,
                    search: patientSearchResults.search,
                    sort: patientSearchResults.sort,
                    favourites: patientSearchResults.favourites
                }));
            } else {
                dispatch(getPatientsThunk({}));
            }
            setNewPatientDialogueOpen(false);
        }
    })

    const handleSearch = (value: string) => {
        setSearch(value);
        if (loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER, ROLES.CLINICIAN].includes(loggedInUser.role)) {
            if (patientSearchResults) {
                dispatch(getSiteAdminPatientsThunk({
                    order: patientSearchResults.order,
                    page: 0,
                    pageSize: patientSearchResults.pageSize,
                    search: value,
                    sort: patientSearchResults.sort,
                    favourites: patientSearchResults.favourites
                }));
            }
        }
    };

    useEffect(() => {
        if (loggedInUser && [ROLES.CLINICIAN].includes(loggedInUser.role)) {
            dispatch(getSiteAdminPatientsThunk({}));
        }
    }, []);

    const handleChange = async (event: any, newValue: any) => {
        setTab(newValue);
        if (newValue === 0) {
            dispatch(getSiteAdminPatientsThunk({}));
            setSearch('')
        } else if (newValue === 1) {
            dispatch(getSiteAdminPatientsThunk({ favourites: false, sort: 'last_login', order: 'DESC' }));
            setSearch('')
        } else if (newValue === 2) {
            dispatch(getSiteAdminPatientsThunk({ favourites: false }));
            setSearch('')
        }
    }

    const handlePrevButton = () => {
        dispatch(getSiteAdminPatientsThunk({
            order: patientSearchResults.order,
            page: patientSearchResults.page - 1,
            pageSize: patientSearchResults.pageSize,
            search: patientSearchResults.search,
            sort: patientSearchResults.sort,
            favourites: patientSearchResults.favourites
        }))
    }

    const handleNextButton = () => {
        dispatch(getSiteAdminPatientsThunk({
            order: patientSearchResults.order,
            page: patientSearchResults.page + 1,
            pageSize: patientSearchResults.pageSize,
            search: patientSearchResults.search,
            sort: patientSearchResults.sort,
            favourites: patientSearchResults.favourites
        }))
    }

    const handelCancel = () => {
        handelClose();
        setSelectedPatients([]);
    }
    const handleChangeChecked = (checked: boolean, info: any) => {
        if (!checked) {
            selectedPatients.push(info.user_role_id);
            selectedUsers.push(info);
            setIsAdd(!isAdd)
        } else {
            const patients = selectedPatients.filter((e: any) => e !== info.user_role_id);
            setSelectedPatients(patients);
            const users = selectedUsers.filter((e: any) => e.user_role_id !== info.user_role_id);
            setSelectedUsers(users);
            setIsAdd(!isAdd)
        }
    }
    const handleChangeChecked1 = (checked: boolean, info: any) => {
        if (!checked) {
            selectedPatients.push(info.user_role_id);
            selectedUsers.push(info);
            patientInfo.checked = true;
            setPatientInfo(info);
            setIsAdd(!isAdd);
        } else {
            const patients = selectedPatients.filter((e: any) => e !== info.user_role_id);
            setSelectedPatients(patients);
            const users = selectedUsers.filter((e: any) => e.user_role_id !== info.user_role_id);
            setSelectedUsers(users);
            patientInfo.checked = false;
            setPatientInfo(info);
            setIsAdd(!isAdd)
        }
    }
    const handleSharedChecked = (checked: boolean, info: any) => {
        if (!checked) {
            selectedPatients.push(info.user_role_id);
            selectedSharedUser.push(info.user_role_id);
            selectedUsers.push(info);
            setIsAdd(!isAdd)
        } else {
            if (!selectedSharedUser.includes(info.user_role_id)) {
                const patients = selectedPatients.filter((e: any) => e !== info.user_role_id);
                setSelectedPatients(patients);
                const users = selectedUsers.filter((e: any) => e.user_role_id !== info.user_role_id);
                setSelectedUsers(users);
                selectedSharedUser.push(info.user_role_id);
                setCheck(true);
            } else {
                const sharedUser = selectedSharedUser.filter((e: any) => e !== info.user_role_id);
                setSelectedSharedUser(sharedUser);
                const patients = selectedPatients.filter((e: any) => e !== info.user_role_id);
                setSelectedPatients(patients);
                const users = selectedUsers.filter((e: any) => e.user_role_id !== info.user_role_id);
                setSelectedUsers(users);
            }
            setIsAdd(!isAdd)
        }
    }

    const handelOpenSharing = async (info: any) => {
        dispatch(getSharingUserThunk({ patientId: info.user_role_id })); //OLD info.id
        const checked = selectedPatients.filter((id: any) => id === info.user_role_id);
        info.checked = checked && checked.length === 0 ? false : true;
        setPatientInfo(info);
        setOpenSharing(true);
        const res = await selectedUsers.filter((userId: any) => userId.associated_patient_id === info.user_role_id);
        if (res && res.length > 0) {
            for (let i = 0; i < res.length; i++) {
                selectedSharedUser.push(res[i].user_role_id);
            }
        }
    }
    const handelCloseDialog = async () => {
        setOpenSharing(false);
        setPatientInfo(null);
        if (check) {
            for (let i = 0; i < selectedSharedUser.length; i++) {
                selectedPatients.push(selectedSharedUser[i]);
            }
            setCheck(false);
        } else {
            for (let i = 0; i < selectedSharedUser.length; i++) {
                const index = selectedPatients.indexOf(selectedSharedUser[i]);
                if (index > -1) {
                    selectedPatients.splice(index, 1);
                }
            }
        }
        setSelectedSharedUser([]);
    }
    const addSharedUser = async () => {
        if (selectedSharedUser && selectedSharedUser.length > 0) {
            const findSharedUser = await selectedUsers.filter((e: any) => e.user_role_id === selectedSharedUser[0]);
            if (findSharedUser && findSharedUser.length > 0) {
                const findPatient = await selectedUsers.filter((e: any) => e.user_role_id === findSharedUser[0].associated_patient_id);
                if (findPatient && findPatient.length > 0) { } else {
                    checkPatient.push(findSharedUser[0].associated_patient_id);
                    setIsAdd(!isAdd);
                }
            }
        } else { }
        setLoading(true);
        setOpenSharing(false);
        setSelectedSharedUser([]);
        setLoading(false);
    }

    const checkUsersAssignments = async () => {
        setLoading(true);
        const data = {
            "resource_id": (location === '/referrals' || section === 'referral' || section1 === 'referral') ? info[0]?.referral_id : info[0]?.id,
            "user_role_ids": selectedPatients
        }
        if (location === '/referrals' || section === 'referral' || section1 === 'referral') {
            const result: any = await dispatch(checkUserReferralAssignmentThunk(data));
            if (result) {
                for (let i = 0; i < result.length; i++) {
                    result[i]?.referral_can_be_assigned == false && await okButton.push(i);
                }
                setOpenConfirm(true);
            }
        } else {
            const result: any = await dispatch(checkUserResourceAssignmentThunk(data));
            if (result) {
                for (let i = 0; i < result.length; i++) {
                    result[i]?.resource_can_be_assigned == false && await okButton.push(i);
                }
                setOpenConfirm(true);
            }
        }
        setLoading(false);
    }

    const saveToPatient = async () => {
        setLoading(true);
        const user_role_ids = [];
        for (let i = 0; i < checkUsersAssignment?.length; i++) {
            if (location === '/referrals' || section === 'referral' || section1 === 'referral') {
                checkUsersAssignment[i]?.referral_can_be_assigned && await user_role_ids.push(checkUsersAssignment[i]?.user_role_id);
            } else {
                checkUsersAssignment[i]?.resource_can_be_assigned && await user_role_ids.push(checkUsersAssignment[i]?.user_role_id);
            }
        }
        if (user_role_ids && user_role_ids.length > 0) {
            const data = {
                "resource_id": (location === '/referrals' || section === 'referral' || section1 === 'referral') ? info[0]?.referral_id : info[0]?.id,
                "user_role_ids": user_role_ids
            }
            const res: any = (location === '/referrals' || section === 'referral' || section1 === 'referral') ? await dispatch(assignReferralThunk(data)) : await dispatch(assignResourceThunk(data));
            if (res) {
                if (info.length > 1) {
                    const data = {
                        "resource_id": (location === '/referrals' || section === 'referral' || section1 === 'referral') ? info[1].referral_id : info[1].id,
                        "user_role_ids": selectedPatients
                    }
                    if (location === '/referrals' || section === 'referral' || section1 === 'referral') {
                        const result: any = await dispatch(checkUserReferralAssignmentThunk(data));
                        if (result) {
                            for (let i = 0; i < result.length; i++) {
                                result[i]?.referral_can_be_assigned == false && await okButton.push(i);
                            }
                        }
                        const arr = await info.filter((item: any) => item.referral_id !== info[0]?.referral_id);
                        await dispatch(assignmentInfoAction(arr && arr.length === 0 ? null : arr));
                    } else {
                        const result: any = await dispatch(checkUserResourceAssignmentThunk(data));
                        if (result) {
                            for (let i = 0; i < result.length; i++) {
                                result[i]?.resource_can_be_assigned == false && await okButton.push(i);
                            }
                        }
                        const arr = await info.filter((item: any) => item.id !== info[0]?.id);
                        await dispatch(assignmentInfoAction(arr && arr.length === 0 ? null : arr));
                    }

                }
                setLoading(false);
                if (info.length === 1) {
                    setOpenConfirm(false);
                    setIsOpen(true);
                    setSelectedSharedUser([]);
                    setSelectedPatients([]);
                }
            }
            setLoading(false);
        } else {
            setLoading(false);
            dispatch(snackbarAlertAction(`Patient already assigned!`, 'warning'));
        }
    }

    const handelCancelConfirm = async () => {
        if (info.length > 1) {
            const data = {
                "resource_id": (location === '/referrals' || section === 'referral' || section1 === 'referral') ? info[1].referral_id : info[1].id,
                "user_role_ids": selectedPatients
            }
            if (location === '/referrals' || section === 'referral' || section1 === 'referral') {
                const result: any = await dispatch(checkUserReferralAssignmentThunk(data));
                if (result) {
                    const okArray = [];
                    for (let i = 0; i < result.length; i++) {
                        result[i]?.referral_can_be_assigned == false && await okArray.push(i);
                    }
                    setOkButton(okArray);
                }
                const arr = await info.filter((item: any) => item.referral_id !== info[0]?.referral_id);
                await dispatch(assignmentInfoAction(arr && arr.length === 0 ? null : arr));
            } else {
                const result: any = await dispatch(checkUserResourceAssignmentThunk(data));
                if (result) {
                    const okArray = [];
                    for (let i = 0; i < result.length; i++) {
                        result[i]?.resource_can_be_assigned == false && await okArray.push(i);
                    }
                    setOkButton(okArray);
                }
                const arr = await info.filter((item: any) => item.id !== info[0]?.id);
                await dispatch(assignmentInfoAction(arr && arr.length === 0 ? null : arr));
            }
        } else if (info.length === 1) {
            setOpenConfirm(false);
            handelClose();
        }
    }

    const onClose = async () => {
        await dispatch(setSuccessAction('saveReferralSuccess', false));
        await dispatch(setSuccessAction('uploadStaticResourceSuccess', false));
        setIsOpen(false); handelClose();
    }

    const countSelectedUsers = (user_role_id: string) => {
        const res = selectedUsers.filter((info: any) => (info.role === ROLES.PATIENT ? info.user_role_id : info.associated_patient_id) === user_role_id);
        return res ? res.length : 0;
    }

    return (
        <>
            {(!openConfirm && !isOpen) && <MUIDialog open={true} style={{ overflow: 'hidden' }}>
                <Paper className={classes.paper} style={{ overflow: 'auto' }}>
                    <Box display="flex" flexDirection="column">
                        <Card>
                            {!patient_role_id ? <Box p={2} style={{ background: '#f9fafb', borderBottom: '0.0625rem solid #d1d6dc', paddingBottom: 0 }} >
                                <Box display='flex' justifyContent='flex-end'>
                                    <TextField className={classes.searchTextField} value={search}
                                        inputProps={{
                                            maxLength: 150,
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <Box ml={-0.5} mr={1} mt={0.5}>
                                                    <Search className={classes.searchIcon} color="disabled" />
                                                </Box>
                                            )
                                        }} size='small' variant='outlined' placeholder='Search...' onChange={(e) => handleSearch(e.target.value)} />
                                </Box>
                                <Box style={{ marginTop: '-35px' }}>
                                    <Tabs
                                        value={isTab}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        onChange={handleChange}
                                        aria-label="disabled tabs example"
                                    >
                                        <Tab label="Saved Patients" value={0} />
                                        <Tab label="Recent Patients" value={1} />
                                        <Tab label="All Patients" value={2} />
                                    </Tabs>
                                </Box>
                            </Box> :
                                <Box p={2} style={{ background: '#f9fafb', borderBottom: '0.0625rem solid #d1d6dc' }} >
                                    <Typography style={{ fontSize: '18px', fontWeight: 600 }}>Users</Typography>
                                </Box>}
                            {!patient_role_id ? <Box pt={1} px={4}>
                                {patientSearchResults && patientSearchResults.data.map((item: any, i: number) => {
                                    const checked = selectedPatients.filter((id: any) => id === item.user_role_id);
                                    const check = checkPatient.filter((id: any) => id === item.user_role_id);
                                    return (
                                        <ListItem style={{ borderBottom: '2px solid #dddddd', padding: '4px 0px', justifyContent: 'space-between' }}>
                                            <Box>
                                                <Typography style={{ fontSize: '16px' }}>{item.first_name + ' ' + item.last_name}</Typography>
                                                <Typography style={{ fontSize: '12px', color: 'gray' }}>{`${countSelectedUsers(item.user_role_id)} users assigned`}</Typography>
                                            </Box>
                                            <Typography style={{ color: '#0060B2', fontSize: '16px', cursor: 'pointer' }} onClick={() => handelOpenSharing(item)}>Assign</Typography>
                                        </ListItem>
                                    )
                                })}
                                <Box mt={2} mb={1} display="flex" justifyContent="space-between" alignItems="center">
                                    <Box><Typography>{patientSearchResults ? patientSearchResults.totalCount : 0} Total Patients</Typography></Box>
                                    <Box>
                                        <LoadingButton disabled={patientSearchResults && patientSearchResults.page > 0 ? false : true}
                                            className={classes.paginationButton}
                                            variant="contained"
                                            style={{ marginBottom: '0px' }}
                                            onClick={handlePrevButton}
                                        >
                                            Previous
                                        </LoadingButton>
                                        <LoadingButton disabled={patientSearchResults && patientSearchResults.page >= 0 &&
                                            patientSearchResults.page > patientSearchResults.totalCount / patientSearchResults.pageSize - 1}
                                            className={classes.paginationButton}
                                            variant="contained"
                                            style={{ marginBottom: '0px' }}
                                            onClick={handleNextButton}
                                        >
                                            Next
                                        </LoadingButton>
                                    </Box>
                                </Box>
                            </Box> :
                                <Box pb={4} pt={1} px={4}>
                                    <ListItem style={{ borderBottom: '2px solid #dddddd', padding: '4px 0px', justifyContent: 'space-between' }}>
                                        <Box>
                                            <Typography style={{ fontSize: '16px' }}>{patient?.first_name + ' ' + patient?.last_name}</Typography>
                                            <Typography style={{ fontSize: '12px', color: 'gray' }}>{`${countSelectedUsers(patient?.user_role_id)} users assigned`}</Typography>
                                        </Box>
                                        <Typography style={{ color: '#0060B2', fontSize: '16px', cursor: 'pointer' }} onClick={() => handelOpenSharing(patient)}>Assign</Typography>
                                    </ListItem>
                                </Box>
                            }
                        </Card>
                    </Box>
                </Paper>
                <DialogActions style={{ justifyContent: 'space-between', padding: '15px 20px', background: '#f9fafb' }}>
                    <LoadingButton
                        className={classes.secondaryButton}
                        variant="contained"
                        onClick={handelCancel}
                    >
                        Cancel
                    </LoadingButton>
                    <Box display='flex' alignItems='center'>
                        {selectedPatients.length > 0 && <><span>{selectedPatients.length} selected</span><Divider orientation="vertical" flexItem style={{ margin: '0 20px' }} /></>}
                        <LoadingButton disabled={selectedUsers && selectedUsers.length > 0 ? false : true}
                            className={classes.primaryButton}
                            variant="contained"
                            onClick={checkUsersAssignments}
                        >
                            Next
                        </LoadingButton>
                    </Box>
                </DialogActions>
            </MUIDialog>}
            {openSharing && <MUIDialog open={openSharing} style={{ overflow: 'hidden' }}>
                <Paper className={classes.paper}>
                    <Box display="flex" flexDirection="column">
                        <Card>
                            <Box p={2} style={{ background: '#f9fafb', borderBottom: '0.0625rem solid #d1d6dc' }} >
                                <Box display='flex' justifyContent='space-between' alignItems="center">
                                    <Typography style={{ fontSize: '18px', fontWeight: 600 }}>Assign {(location === '/referrals' || section === 'referral' || section1 === 'referral') ? 'Referral' : 'Resource'}</Typography>
                                    <TextField className={classes.searchTextField} value={search}
                                        inputProps={{
                                            maxLength: 150,
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <Box ml={-0.5} mr={1} mt={0.5}>
                                                    <Search className={classes.searchIcon} color="disabled" />
                                                </Box>
                                            )
                                        }} size='small' variant='outlined' placeholder='Search...' onChange={(e) => handleSearch(e.target.value)} />
                                </Box>
                            </Box>
                            <Box pb={4} pt={1} px={4}>
                                <ListItem style={{ borderBottom: '2px solid #dddddd', padding: '4px 0px', background: '#f9fafb' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox size='medium' checked={patientInfo.checked} onChange={(e) => handleChangeChecked1(patientInfo.checked, patientInfo)} name={patientInfo.id} color="primary" />
                                        }
                                        label={<Box><Typography style={{ fontSize: '16px' }}>{patientInfo.first_name + ' ' + patientInfo.last_name}</Typography>
                                            <Typography style={{ fontSize: '12px', color: 'gray' }}>{patientInfo.role}</Typography></Box>} />
                                </ListItem>
                                {sharedUsers && sharedUsers.data.length > 0 && sharedUsers.data.map((item: any, i: number) => {
                                    const checked = selectedPatients.filter((id: any) => id === item.user_role_id);
                                    return (
                                        <ListItem style={{ borderBottom: '2px solid #dddddd', padding: '4px 0px' }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox size='medium' checked={checked && checked.length === 0 ? false : true} onChange={(e) => handleSharedChecked(checked && checked.length === 0 ? false : true, item)} name={item.id} color="primary" />
                                                }
                                                label={<Box><Typography style={{ fontSize: '16px' }}>{item.first_name + ' ' + item.last_name}</Typography>
                                                    <Typography style={{ fontSize: '12px', color: 'gray' }}>{item.role}</Typography></Box>} />
                                        </ListItem>
                                    )
                                })
                                }
                                <Box mt={2} mb={1} display="flex" justifyContent="space-between" alignItems="center">
                                    <Box><Typography>{sharedUsers ? (sharedUsers.totalCount + 1) : 0} Total Users</Typography></Box>
                                    <Box>
                                        <LoadingButton disabled={sharedUsers && sharedUsers.page > 0 ? false : true}
                                            className={classes.paginationButton}
                                            variant="contained"
                                            style={{ marginBottom: '0px' }}
                                            onClick={handlePrevButton}
                                        >
                                            Previous
                                        </LoadingButton>
                                        <LoadingButton disabled={sharedUsers && sharedUsers.page >= 0 &&
                                            sharedUsers.page > sharedUsers.totalCount / sharedUsers.pageSize - 1}
                                            className={classes.paginationButton}
                                            variant="contained"
                                            style={{ marginBottom: '0px' }}
                                            onClick={handleNextButton}
                                        >
                                            Next
                                        </LoadingButton>
                                    </Box>
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                </Paper>
                <DialogActions style={{ justifyContent: 'space-between', padding: '15px 20px', background: '#f9fafb' }}>
                    <LoadingButton
                        className={classes.secondaryButton}
                        variant="contained"
                        onClick={handelCloseDialog}
                    >
                        Back
                    </LoadingButton>
                    <Box display='flex' alignItems='center'>
                        {(selectedSharedUser.length > 0 || patientInfo.checked) && <><span>{patientInfo.checked ? (selectedSharedUser.length + 1) : selectedSharedUser.length} selected</span><Divider orientation="vertical" flexItem style={{ margin: '0 20px' }} /></>}
                        <LoadingButton disabled={loading}
                            className={classes.primaryButton}
                            variant="contained"
                            onClick={addSharedUser}
                        >
                            Save
                        </LoadingButton>
                    </Box>
                </DialogActions>
            </MUIDialog>
            }
            {openConfirm && <MUIDialog open={openConfirm} style={{ overflow: 'hidden' }}>
                <Paper className={classes.paper}>
                    <Box display="flex" flexDirection="column">
                        {(location === '/referrals' || section === 'referral' || section1 === 'referral') ?
                            <Card>
                                <Box p={2} style={{ background: '#f9fafb', borderBottom: '0.0625rem solid #d1d6dc' }} >
                                    <Box display='flex' justifyContent='space-between' alignItems="center">
                                        <Typography style={{ fontSize: '18px', fontWeight: 600 }}>You are about to send a referral!</Typography>
                                    </Box>
                                </Box>
                                <Box pb={4} pt={1} px={4} style={{ maxHeight: '500px', overflow: 'auto' }}>
                                    <Box pb={2} pt={2}><Typography style={{ fontSize: '16px' }}>You are about to send <b>{info && (info[0]?.first_name + ' ' + info[0]?.last_name)}</b> to:</Typography></Box>
                                    {checkUsersAssignment && checkUsersAssignment?.map((item: any, i: number) => {
                                        // !item.referral_can_be_assigned && okButton.push(i);
                                        const res = selectedUsers.filter((info: any) => info.user_role_id === item.user_role_id);
                                        // const res1 = patientSearchResults && patientSearchResults.data.filter((info: any) => info.user_role_id === item.user_role_id);
                                        return ((res && res.length > 0) &&
                                            <ListItem style={{ borderBottom: '2px solid #dddddd', padding: '4px 0px' }}>
                                                <Box style={{ width: '-webkit-fill-available' }}>
                                                    <Box display='flex' style={{ width: '-webkit-fill-available' }}>
                                                        <Box style={{ width: '60%' }}><Typography style={{ fontSize: '16px' }}>{res && res.length > 0 && (res[0].first_name + ' ' + res[0].last_name)}</Typography>
                                                            <Typography style={{ fontSize: '12px', color: 'gray' }}>{res && res.length > 0 && res[0].email}</Typography></Box>
                                                        <Box mt={2}><Typography style={{ fontSize: '12px', color: 'gray' }}>Contact no.: {res && res.length > 0 && res[0].mobile_phone}</Typography>
                                                            <Typography style={{ fontSize: '12px', color: 'gray' }}>DOB: {res && res.length > 0 && res[0]?.birth_date ? moment(res[0].birth_date).format("D MMM, YYYY") : ''}</Typography>
                                                        </Box>
                                                    </Box>
                                                    <Typography style={{ fontSize: '11px', color: 'red' }}>{item.referral_can_be_assigned ? '' : 'This user has already been assigned this referral.'}</Typography>
                                                </Box>
                                            </ListItem>
                                        )
                                    })}
                                </Box>
                            </Card> :
                            <Card>
                                <Box p={2} style={{ background: '#f9fafb', borderBottom: '0.0625rem solid #d1d6dc' }} >
                                    <Box display='flex' justifyContent='space-between' alignItems="center">
                                        <Typography style={{ fontSize: '18px', fontWeight: 600 }}>You are about to send a resource!</Typography>
                                    </Box>
                                </Box>
                                <Box pb={4} pt={1} px={4} style={{ maxHeight: '500px', overflow: 'auto' }}>
                                    <Box pb={2} pt={2}><Typography style={{ fontSize: '16px' }}>You are about to send <b>{info && info[0]?.title}</b> to:</Typography></Box>
                                    {checkUsersAssignment && checkUsersAssignment?.map((item: any, i: number) => {
                                        // !item.resource_can_be_assigned && okButton.push(i);
                                        const res = selectedUsers.filter((info: any) => info.user_role_id === item.user_role_id);
                                        // const res1 = patientSearchResults && patientSearchResults.data.filter((info: any) => info.user_role_id === item.user_role_id);
                                        return ((res && res.length > 0) &&
                                            <ListItem style={{ borderBottom: '2px solid #dddddd', padding: '4px 0px' }}>
                                                <Box style={{ width: '-webkit-fill-available' }}>
                                                    <Box display='flex' style={{ width: '-webkit-fill-available' }}>
                                                        <Box style={{ width: '60%' }}><Typography style={{ fontSize: '16px' }}>{res && res.length > 0 && (res[0].first_name + ' ' + res[0].last_name)}</Typography>
                                                            <Typography style={{ fontSize: '12px', color: 'gray' }}>{res && res.length > 0 && res[0].email}</Typography></Box>
                                                        <Box mt={2}><Typography style={{ fontSize: '12px', color: 'gray' }}>Contact no.: {res && res.length > 0 && res[0].mobile_phone}</Typography>
                                                            <Typography style={{ fontSize: '12px', color: 'gray' }}>DOB: {res && res.length > 0 && res[0]?.birth_date ? moment(res[0].birth_date).format("D MMM, YYYY") : ''}</Typography></Box>
                                                    </Box>
                                                    <Typography style={{ fontSize: '11px', color: 'red' }}>{item.resource_can_be_assigned ? '' : 'This user has already been assigned this resource.'}</Typography>
                                                </Box>
                                            </ListItem>
                                        )
                                    })}
                                </Box>
                            </Card>}
                    </Box>
                </Paper>
                <DialogActions style={{ padding: '15px 20px', background: '#f9fafb', display: 'unset', justifyContent: 'unset' }}>
                    {checkUsersAssignment && checkUsersAssignment?.length !== okButton.length && <Box display='flex' justifyContent='space-between'>
                        <LoadingButton
                            className={classes.secondaryButton}
                            variant="contained"
                            onClick={() => handelCancelConfirm()}
                        >
                            Cancel
                        </LoadingButton>
                        <LoadingButton disabled={loading}
                            className={classes.primaryButton}
                            variant="contained"
                            onClick={saveToPatient}
                        >
                            Send to Patient
                        </LoadingButton>
                    </Box>}
                    {checkUsersAssignment && checkUsersAssignment?.length === okButton.length && <Box display='flex' justifyContent='center'><LoadingButton
                        className={classes.secondaryButton}
                        variant="contained"
                        onClick={() => handelCancelConfirm()}
                    >
                        Ok
                    </LoadingButton></Box>}
                </DialogActions>
            </MUIDialog>
            }
            {isOpen && <MUIDialog open={isOpen} onClose={onClose}>
                <Box style={{ width: '600px' }}>
                    <Box pb={3} pt={2} px={4} display="flex" justifyContent="space-between" style={{ background: '#f9fafb', borderBottom: '0.0625rem solid #d1d6dc' }}>
                        <Typography color="textSecondary" variant="h2">Assignment Success</Typography>
                        <Close onClick={() => { setIsOpen(false); onClose() }} />
                    </Box>
                    <Box pb={3} pt={3} display="flex" justifyContent="center">
                        {(location === '/referrals' || section === 'referral' || section1 === 'referral') ? <Typography color="textSecondary" variant="h2">The referral has been successfully assigned to the patient.</Typography> :
                            <Typography color="textSecondary" variant="h2">The resource has been successfully assigned to the patient.</Typography>}
                    </Box>
                    <Box pb={3} pt={3} display="flex" justifyContent="space-between" style={{ background: '#f9fafb', borderBottom: '0.0625rem solid #d1d6dc' }}>
                    </Box>
                </Box>
            </MUIDialog>
            }
        </>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    topBox: {
        borderRadius: '0.625rem 0.625rem 0 0'
    },
    bottomBox: {
        borderRadius: '0 0 0.625rem 0.625rem'
    },
    primaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#fff',
        minWidth: '120px',
        background: '#0060B2',
        '&:hover': {
            backgroundColor: '#0060B2',
        }
    },
    secondaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#0060B2',
        minWidth: '120px',
        background: '#fff'
    },
    paper: {
        width: '580px',
        overflow: 'hidden'
    },
    searchTextField: {
        backgroundColor: theme.palette.secondary.main,
        fontSize: theme.typography.h5.fontSize,
        height: '2rem',
        width: '11rem',
        zIndex: 1
    },
    searchIcon: {
        fontSize: 17
    },
    paginationButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: 'black',
        marginLeft: '20px',
        minWidth: '120px',
        background: '#fff'
    },
}));
