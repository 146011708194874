import React from 'react';
import clsx from 'clsx';
import { Checkbox as MuiCheckbox } from '@material-ui/core';
import { CheckboxProps } from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

//optinal props for custom colors
interface ComponentProps {
  bgColor?: string;
}
export const Checkbox = (props: CheckboxProps & ComponentProps) => {
  const classes = useStyles({ bgColor: props.bgColor });

  return (
    <MuiCheckbox
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      className={classes.root}
      color="primary"
      checked={props.checked}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
};

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  icon: (props: ComponentProps) => ({
    borderRadius: 3,
    width: 16,
    height: 16,
    border: '1px solid rgba(100,100,100,0.5)',
    backgroundColor: '#ffffff',
    '$root.Mui-focusVisible &': {
      outline: '2px solid #000000',
      outlineOffset: 2
    },
    'input:hover ~ &': {
      backgroundColor: props.bgColor
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)'
    }
  }),
  checkedIcon: (props: ComponentProps) => ({
    backgroundColor: props.bgColor || '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    border: '1x solid #137cbd',
    'input:hover ~ &': {
      backgroundColor: props.bgColor
    }
  })
});
