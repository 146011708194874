import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import { Avatar, Link as MuiLink, withStyles, Theme, Typography, makeStyles, Tooltip, Popover, Paper, Divider, Backdrop, CircularProgress } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { ROLES } from '../../../shared/globals';
import { UuidType } from '../../../shared/domain';
import { FormsType } from '../../../redux/data/data-actions';
import { GetFormsProps, getFormVersionsThunk, getFormInstanceDetailsThunk, getFormInstanceResponseThunk, getPatientFormsThunk, downloadFormDataThunk, downloadFormPDFThunk } from '../../../redux/thunk/thunks';
import {
  HeadCell,
  LastSorted,
  Pagination,
  RowCell,
  Table
} from '../../../components/Table';
import { colors } from '../../../styling/colors';
import { ShareToUser } from './AssignSharedUsers';
import { ViewResponse } from './PatientViewForm';

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    padding: '0px 10px',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

interface FormsTableProps {
  collectionId?: any
  library?: boolean;
  formsData: any | null;
  formsType: FormsType;
  selectedForms?: UuidType[];
  loadForms: (getFormsProps: GetFormsProps) => void;
  setSelectedForms?: (selectedForms: UuidType[]) => void;
  tab?: any;
}

export const FormsTable = ({
  collectionId,
  library = false,
  formsData,
  formsType,
  selectedForms,
  loadForms,
  setSelectedForms,
  tab
}: FormsTableProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  let history = useHistory();
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);
  const [anchorEl, setAnchorEl] = useState(null);
  const [instance_id, setInstance] = useState(null);
  const [data, setPopuerInfo] = useState<any>(null);
  const [openModal, setModal] = useState<any>(false);
  const [openResponse, setOpenResponse] = useState<any>(false);
  const [isBackdrop, setIsBackdrop] = useState(false);
  const [lastSorted, setLastSorted] = useState<LastSorted>({
    column: 'updated_at',
    order: 'desc'
  });

  const headCells: HeadCell[] = [
    {
      id: 'title',
      label: 'FORM NAME'
    },
    {
      id: 'domains',
      label: 'DOMAINS',
      align: 'center',
      isSortDisabled: true
    },
    {
      id: 'tags',
      label: 'TAGS',
      align: 'center',
      isSortDisabled: true
    },
    {
      align: 'right',
      id: 'updated_at',
      label: 'LAST UPDATED'
    }
  ];
  const headCells1: HeadCell[] = [
    {
      id: 'title',
      label: 'FORM NAME'
    },
    {
      id: 'domains',
      label: 'DOMAINS',
      align: 'center',
      isSortDisabled: true
    },
    {
      id: 'tags',
      label: 'TAGS',
      align: 'center',
      isSortDisabled: true
    },
    {
      id: 'form_type',
      label: 'TYPE'
    },
    {
      align: 'right',
      id: 'updated_at',
      label: 'LAST UPDATED'
    }
  ];
  const headCells2: HeadCell[] = [
    {
      id: 'form_title',
      label: 'FORM NAME'
    },
    {
      id: 'assigned_by_name',
      label: 'ASSIGNED BY',
    },
    {
      id: 'created_at',
      label: 'ASSIGNED ON',
    },
    {
      id: 'due_date',
      label: 'DUE IN',
    },
    {
      id: 'status',
      label: 'STATUS',
    },
    {
      id: 'sharedUsers',
      label: 'SHARING',
      isSortDisabled: true
    },
    {
      align: 'right',
      id: 'action',
      label: '',
      isSortDisabled: true
    }
  ];
  const headCells3: HeadCell[] = [
    {
      id: 'form_title',
      label: 'FORM NAME'
    },
    {
      id: 'assigned_by_name',
      label: 'ASSIGNED BY',
    },
    {
      id: 'completed_at',
      label: 'COMPLETED ON',
    },
    {
      id: 'sharedUsers',
      label: 'SHARING',
      isSortDisabled: true
    },
    {
      align: 'right',
      id: 'action',
      label: '',
      isSortDisabled: true
    }
  ];
  const headCells4: HeadCell[] = [
    {
      id: 'form_title',
      label: 'FORM NAME'
    },
    {
      id: 'assigned_by_name',
      label: 'ASSIGNED BY',
    },
    {
      id: 'status',
      label: 'STATUS',
    },
    {
      id: 'sharedUsers',
      label: 'SHARING',
      isSortDisabled: true
    },
    {
      align: 'right',
      id: 'action',
      label: '',
      isSortDisabled: true
    }
  ];
  const headCells5: HeadCell[] = [
    {
      id: 'form_title',
      label: 'FORM NAME'
    },
    {
      id: 'assigned_by_name',
      label: 'ASSIGNED BY',
    },
    {
      id: 'created_at',
      label: 'ASSIGNED ON',
    },
    {
      id: 'due_date',
      label: 'DUE IN',
    },
    {
      id: 'status',
      label: 'STATUS',
    },
    {
      align: 'right',
      id: 'action',
      label: '',
      isSortDisabled: true
    }
  ];
  const headCells6: HeadCell[] = [
    {
      id: 'form_title',
      label: 'FORM NAME'
    },
    {
      id: 'assigned_by_name',
      label: 'ASSIGNED BY',
    },
    {
      id: 'completed_at',
      label: 'COMPLETED ON',
    },
    {
      align: 'right',
      id: 'action',
      label: '',
      isSortDisabled: true
    }
  ];
  const headCells7: HeadCell[] = [
    {
      id: 'form_title',
      label: 'FORM NAME'
    },
    {
      id: 'assigned_by_name',
      label: 'ASSIGNED BY',
    },
    {
      id: 'status',
      label: 'STATUS',
    },
    {
      align: 'right',
      id: 'action',
      label: '',
      isSortDisabled: true
    }
  ];
  const setSession = async (value: any, formId: any) => {
    sessionStorage.removeItem('edit')
    sessionStorage.removeItem('version');
    sessionStorage.removeItem('new');
    await dispatch(getFormVersionsThunk(formId));
    sessionStorage.setItem('type_id', value);
    history.push({ pathname: `/form-builder/${formId}/fields`, state: false });
  }
  const refActions = (event: any, amtInfo: any) => {
    setAnchorEl(event.currentTarget);
    setPopuerInfo(amtInfo)
  }

  const handleClose = () => {
    loadForms({
      collectionId: formsData?.collectionId,
      formsType,
      library,
      search: formsData?.search,
      page: formsData?.page,
      pageSize: formsData?.pageSize,
      sort: formsData?.sort,
      order: formsData?.order,
      favourites: formsData?.favourites
    });
    setAnchorEl(null);
    setModal(false);
    setOpenResponse(false);
    tab === 'View-Only Forms' && dispatch(getPatientFormsThunk({ access_type: 'VIEW', status_type: 'COMPLETE' }));
  }

  const dateDiff = (date: any) => {
    const date1: any = new Date();
    const date2: any = new Date(date);
    const diffTime = Math.abs(date1 - date2);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays + " days"
  }
  const openSharedUser = (info: any) => {
    setPopuerInfo(info);
    setModal(true);
  }
  const handlePreview = async (id: any) => {
    sessionStorage.removeItem('edit')
    if (tab === 'View-Only Forms') {
      setInstance(id?.id);
      await dispatch(getFormInstanceDetailsThunk(id?.id));
      await dispatch(getFormInstanceResponseThunk(id?.id));
      await setOpenResponse(true);
    } else {
      sessionStorage.setItem('version', id?.form_version_id);
      sessionStorage.setItem('instance', id?.id);
      history.push(`/form-preview/${id?.form_id}`);
    }
  }

  const handleDownloadData = async () => {
    setAnchorEl(null);
    setIsBackdrop(true);
    await dispatch(downloadFormDataThunk(data?.id));
    setIsBackdrop(false);
  }
  const handleDownloadPDF = async () => {
    setAnchorEl(null);
    setIsBackdrop(true);
    await dispatch(downloadFormPDFThunk(data?.id));
    setIsBackdrop(false);
  }

  const rowCells = formsData?.forms.map((form: any) => {
    const TitleLink = (
      <Typography onClick={() => setSession(form?.form_type, form?.id)} color="primary" variant="h5" style={{ maxWidth: '500px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', cursor: 'pointer' }}>
        {form?.title}
      </Typography>
    );
    const FormLink = (
      <Typography onClick={() => handlePreview(form)} color="primary" variant="h5" style={{ maxWidth: '500px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', cursor: 'pointer' }}>
        {form?.form_title}
      </Typography>
    );
    const SharedUsers = (
      <Typography onClick={() => openSharedUser(form)} color="primary" variant="h5" style={{ maxWidth: '500px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', cursor: 'pointer' }}>
        {form?.sharedUsers ? (form?.sharedUsers?.length + ' Users') : '0 Users'}
      </Typography>
    );
    const tagsComponent =
      <HtmlTooltip
        title={<React.Fragment>
          {form?.tags?.map((tag: any, i: number) => {
            return (
              <Typography style={{ padding: '5px 0px', borderBottom: (i + 1) === form?.tags.length ? 'unset' : '1px solid #00000026' }}>{tag.title}</Typography>
            )
          })}
        </React.Fragment>}
      >
        <div>
          <Typography color="primary" variant="h5" >
            {form?.tags && form?.tags?.length}
          </Typography>
          <Typography color="primary" variant="h5">
            Tags
          </Typography>
        </div>
      </HtmlTooltip>;
    const domainComponent = <HtmlTooltip
      title={<React.Fragment>
        {form?.domains?.map((domain: any, i: number) => {
          return (
            <Typography style={{ padding: '5px 0px', borderBottom: (i + 1) === form?.domains.length ? 'unset' : '1px solid #00000026' }}>{domain.title}</Typography>
          )
        })}
      </React.Fragment>}
    ><div>
        <Typography color="primary" variant="h5" >
          {form?.domains && form?.domains.length}
        </Typography>
        <Typography color="primary" variant="h5">
          Domains
        </Typography>
      </div>
    </HtmlTooltip>;
    var options: any = { weekday: 'long' };
    let newDate = new Date(form?.created_at);
    let updatedDate = new Date(form?.updated_at);
    let completed_at = new Date(form?.completed_at);
    const type = form?.form_type === 'CUSTOM' ? <Avatar style={{ width: 32, height: 32 }}>C</Avatar> : <Avatar style={{ width: 32, height: 32, background: '#0060b2' }}>B</Avatar>;
    const rowCell: RowCell = [ROLES.SITE_ADMIN, ROLES.BUILDER, ROLES.CLINICIAN].includes(loggedInUser.role) ? {
      title: { component: TitleLink, value: form?.title },
      domains: {
        align: 'center',
        component: domainComponent,
        value: form?.tag_count?.toString()
      },
      tags: {
        align: 'center',
        component: tagsComponent,
        value: form?.tag_count?.toString()
      },
      form_type: { component: type, value: '-' },
      updated_at: {
        align: 'right',
        value: new Intl.DateTimeFormat('en-US', options).format(updatedDate) + ', ' + (("0" + (updatedDate.getMonth() + 1)).slice(-2)) + '/' + ("0" + updatedDate.getDate()).slice(-2) + '/' + updatedDate.getFullYear()
      }
    } : [ROLES.PATIENT].includes(loggedInUser.role) && tab === 'To Be Completed' ? {
      form_title: { component: FormLink, value: form?.form_title },
      assigned_by_name: {
        value: form?.assigned_by_name
      },
      created_at: {
        value: new Intl.DateTimeFormat('en-US', options).format(newDate) + ', ' + (("0" + (newDate.getMonth() + 1)).slice(-2)) + '/' + ("0" + newDate.getDate()).slice(-2) + '/' + newDate.getFullYear()
      },
      due_date: {
        value: form?.due_date ? dateDiff(form?.due_date) : 'No Due Date'
      },
      status: {
        value: form?.status === 'COMPLETE' ? 'COMPLETED' : form?.status?.replace(/_/g, " ")
      },
      sharedUsers: {
        component: SharedUsers,
        value: form?.sharedUsers ? (form?.sharedUsers?.length + ' Users') : '0 Users'
      },
      action: {
        align: 'right',
        component: <><MoreHoriz className={classes.launchIcon} onClick={(e) => refActions(e, form)} />
          <Popover className='paper'
            id={'simple-popover'}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Paper style={{ boxShadow: 'unset' }}>
              <Typography className={classes.actionTypography} onClick={handleDownloadPDF}>Download Form</Typography>
              <Divider />
              <Typography className={classes.actionTypography} onClick={handleDownloadData}>Download Data</Typography>
            </Paper>
          </Popover>
        </>
      }
    } : [ROLES.PATIENT].includes(loggedInUser.role) && tab === 'Completed Forms' ? {
      form_title: { component: FormLink, value: form?.form_title },
      assigned_by_name: {
        value: form?.assigned_by_name
      },
      completed_at: {
        value: form?.completed_at ? (new Intl.DateTimeFormat('en-US', options).format(completed_at) + ', ' + (("0" + (completed_at.getMonth() + 1)).slice(-2)) + '/' + ("0" + completed_at.getDate()).slice(-2) + '/' + completed_at.getFullYear()) : ''
      },
      sharedUsers: {
        component: SharedUsers,
        value: form?.sharedUsers ? (form?.sharedUsers?.length + ' Users') : '0 Users'
      },
      action: {
        align: 'right',
        component: <><MoreHoriz className={classes.launchIcon} onClick={(e) => refActions(e, form)} />
          <Popover className='paper'
            id={'simple-popover'}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Paper style={{ boxShadow: 'unset' }}>
              <Typography className={classes.actionTypography} onClick={handleDownloadPDF}>Download Form</Typography>
              <Divider />
              <Typography className={classes.actionTypography} onClick={handleDownloadData}>Download Data</Typography>
            </Paper>
          </Popover>
        </>
      }
    } : [ROLES.PATIENT].includes(loggedInUser.role) ? {
      form_title: { component: FormLink, value: form?.form_title },
      assigned_by_name: {
        value: form?.assigned_by_name
      },
      status: {
        value: form?.status === 'COMPLETE' ? 'COMPLETED' : form?.status?.replace(/_/g, " ")
      },
      sharedUsers: {
        component: SharedUsers,
        value: form?.sharedUsers ? (form?.sharedUsers?.length + ' Users') : '0 Users'
      },
      action: {
        align: 'right',
        component: <><MoreHoriz className={classes.launchIcon} onClick={(e) => refActions(e, form)} />
          <Popover className='paper'
            id={'simple-popover'}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Paper style={{ boxShadow: 'unset' }}>
              <Typography className={classes.actionTypography} onClick={handleDownloadPDF}>Download Form</Typography>
              <Divider />
              <Typography className={classes.actionTypography} onClick={handleDownloadData}>Download Data</Typography>
            </Paper>
          </Popover>
        </>
      }
    } : [ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role) && tab === 'To Be Completed' ? {
      form_title: { component: FormLink, value: form?.form_title },
      assigned_by_name: {
        value: form?.assigned_by_name
      },
      created_at: {
        value: new Intl.DateTimeFormat('en-US', options).format(newDate) + ', ' + (("0" + (newDate.getMonth() + 1)).slice(-2)) + '/' + ("0" + newDate.getDate()).slice(-2) + '/' + newDate.getFullYear()
      },
      due_date: {
        value: form?.due_date ? dateDiff(form?.due_date) : 'No Due Date'
      },
      status: {
        value: form?.status === 'COMPLETE' ? 'COMPLETED' : form?.status?.replace(/_/g, " ")
      },
      action: {
        align: 'right',
        component: <><MoreHoriz className={classes.launchIcon} onClick={(e) => refActions(e, form)} />
          <Popover className='paper'
            id={'simple-popover'}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Paper style={{ boxShadow: 'unset' }}>
              <Typography className={classes.actionTypography} onClick={handleDownloadPDF}>Download Form</Typography>
              <Divider />
              <Typography className={classes.actionTypography} onClick={handleDownloadData}>Download Data</Typography>
            </Paper>
          </Popover>
        </>
      }
    } : [ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role) && tab === 'Completed Forms' ? {
      form_title: { component: FormLink, value: form?.form_title },
      assigned_by_name: {
        value: form?.assigned_by_name
      },
      completed_at: {
        value: form?.completed_at ? (new Intl.DateTimeFormat('en-US', options).format(completed_at) + ', ' + (("0" + (completed_at.getMonth() + 1)).slice(-2)) + '/' + ("0" + completed_at.getDate()).slice(-2) + '/' + completed_at.getFullYear()) : ''
      },
      action: {
        align: 'right',
        component: <><MoreHoriz className={classes.launchIcon} onClick={(e) => refActions(e, form)} />
          <Popover className='paper'
            id={'simple-popover'}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Paper style={{ boxShadow: 'unset' }}>
              <Typography className={classes.actionTypography} onClick={handleDownloadPDF}>Download Form</Typography>
              <Divider />
              <Typography className={classes.actionTypography} onClick={handleDownloadData}>Download Data</Typography>
            </Paper>
          </Popover>
        </>
      }
    } : [ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role) ? {
      form_title: { component: FormLink, value: form?.form_title },
      assigned_by_name: {
        value: form?.assigned_by_name
      },
      status: {
        value: form?.status === 'COMPLETE' ? 'COMPLETED' : form?.status?.replace(/_/g, " ")
      },
      action: {
        align: 'right',
        component: <><MoreHoriz className={classes.launchIcon} onClick={(e) => refActions(e, form)} />
          <Popover className='paper'
            id={'simple-popover'}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Paper style={{ boxShadow: 'unset' }}>
              <Typography className={classes.actionTypography} onClick={handleDownloadPDF}>Download Form</Typography>
              <Divider />
              <Typography className={classes.actionTypography} onClick={handleDownloadData}>Download Data</Typography>
            </Paper>
          </Popover>
        </>
      }
    } : {
      title: { component: TitleLink, value: form?.title },
      domains: {
        align: 'center',
        component: domainComponent,
        value: form?.tag_count?.toString()
      },
      tags: {
        align: 'center',
        component: tagsComponent,
        value: form?.tag_count?.toString()
      },
      updated_at: {
        align: 'right',
        value: new Intl.DateTimeFormat('en-US', options).format(updatedDate) + ', ' + (("0" + (updatedDate.getMonth() + 1)).slice(-2)) + '/' + ("0" + updatedDate.getDate()).slice(-2) + '/' + updatedDate.getFullYear()
      }
    };
    return Object.assign(rowCell, { content: form });
  });

  const handleNextButton = async () => {
    if (
      formsData &&
      formsData?.page >= 0 &&
      formsData?.page < formsData?.total / formsData?.pageSize - 1
    ) {
      await loadForms({
        collectionId: formsData?.collectionId,
        formsType,
        library,
        search: formsData?.search,
        page: formsData?.page + 1,
        pageSize: formsData?.pageSize,
        sort: formsData?.sort,
        order: formsData?.order,
        favourites: formsData?.favourites
      });
    }
  };
  const handlePrevButton = async () => {
    if (formsData && formsData?.page > 0) {
      await loadForms({
        collectionId: formsData?.collectionId,
        formsType,
        library,
        search: formsData?.search,
        page: formsData?.page - 1,
        pageSize: formsData?.pageSize,
        sort: formsData?.sort,
        order: formsData?.order,
        favourites: formsData?.favourites
      });
    }
  };

  const handlePageSize = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    const pageSize: number = e.target.value as number;

    if (formsData && pageSize > 0) {
      loadForms({
        collectionId: formsData?.collectionId,
        formsType,
        library,
        page: 0,
        pageSize: pageSize,
        search: formsData?.search,
        sort: formsData?.sort,
        order: formsData?.order,
        favourites: formsData?.favourites
      });
    }
  };

  const pagination: Pagination | undefined = formsData
    ? {
      contentName: 'Forms',
      page: formsData?.page,
      pageSize: formsData?.pageSize,
      total: formsData?.total,
      handleNextButton: handleNextButton,
      handlePageSize: handlePageSize,
      handlePrevButton: handlePrevButton
    }
    : undefined;

  const handleSort = (sort: string) => {
    const nextDirection = lastSorted.order === 'desc' ? 'asc' : 'desc';

    loadForms({
      collectionId: formsData?.collectionId,
      formsType,
      library,
      order: sort === lastSorted.column ? nextDirection : 'desc',
      page: 0,
      pageSize: formsData?.pageSize,
      search: formsData?.search,
      favourites: formsData?.favourites,
      sort
    });

    setLastSorted(
      sort === lastSorted.column
        ? { column: sort, order: nextDirection }
        : { column: sort, order: 'desc' }
    );
  };

  return (
    <>
      {isBackdrop && <Backdrop className={classes.backdrop} open={isBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>}
      {[ROLES.PATIENT].includes(loggedInUser.role) ?
        <Table
          data={formsData?.forms}
          headCells={tab === 'To Be Completed' ? headCells2 : tab === 'Completed Forms' ? headCells3 : headCells4}
          pagination={pagination}
          rowCells={rowCells}
          sort={{
            lastSorted: lastSorted,
            handleSort: handleSort
          }}
        /> : [ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role) ?
          <Table
            data={formsData?.forms}
            headCells={tab === 'To Be Completed' ? headCells5 : tab === 'Completed Forms' ? headCells6 : headCells7}
            pagination={pagination}
            rowCells={rowCells}
            sort={{
              lastSorted: lastSorted,
              handleSort: handleSort
            }}
          /> :
          <Table
            data={formsData?.forms}
            headCells={[ROLES.SITE_ADMIN, ROLES.BUILDER, ROLES.CLINICIAN].includes(loggedInUser.role) ? headCells1 : headCells}
            // loading={formsData?.collectionId !== collectionId}
            pagination={pagination}
            rowCells={rowCells}
            selection={
              selectedForms && setSelectedForms
                ? {
                  selectedContents: selectedForms,
                  setSelectedContents: (selected: UuidType[]) => setSelectedForms(selected)
                }
                : undefined
            }
            sort={{
              lastSorted: lastSorted,
              handleSort: handleSort
            }}
          />}
      {openModal && <ShareToUser handleClose={handleClose} data={data} />}
      {openResponse && <ViewResponse handleClose={handleClose} instance_id={instance_id} />}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  collectionBubble: {
    borderRadius: '0.75rem'
  },
  clearIcon: {
    color: colors.text6,
    fontSize: 14,
    marginLeft: '0.25rem'
  },
  titleLink: {
    textDecoration: 'none'
  },
  launchIcon: {
    fontSize: '2rem',
    cursor: 'pointer'
  },
  actionTypography: {
    padding: '10px',
    fontWeight: 'bold',
    cursor: 'pointer'
  },
}));
