import { Color } from '@material-ui/lab/Alert';
import { GET_COLLECTION_ASSIGNMENTS_A } from '../data/data-actions';

import {
  DisplayAction,
  SNACKBAR_ALERT_A,
  SnackbarAlertAction,
  CREATING_NEW_FORM_A,
  CREATING_NEW_FORM_SUCCESS_A,
  DELETING_FORM_FIELD_A,
  DeletingFormFieldAction,
  DELETING_FORM_FIELD_SUCCESS_A,
  UPDATING_FORM_FIELD_A,
  UpdatingFormFieldAction,
  UPDATING_FORM_FIELD_SUCCESS_A,
  SET_GENERIC_ERROR_A,
  SetErrorAction,
  SET_GENERIC_SUCCESS_A,
  SetSuccessAction,
  SET_GENERIC_LOADING_A,
  SetLoadingAction,
  UPDATING_FIELD_LOGIC_RULE_A,
  UPDATING_FIELD_LOGIC_RULE_FINISHED_A,
  UpdatingFieldLogicRuleAction,
  UPDATING_CALCULATION_A,
  UPDATING_CALCULATION_FINISHED_A,
  UpdatingCalculationAction,
  SET_UPDATING_EXIT_PAGE_LOGIC_RULE_ID_A,
  SetUpdatingExitPageLogicRuleIdAction,
  SET_UPDATING_EXIT_PAGE_CALCULATION_ID_A,
  SetUpdatingExitPageCalculationIdAction,
  SEND_REMINDER_FORM_INSTANCE_ID_A,
  SendReminderFormInstanceIdAction
} from './display-actions';
import { DisplayReduxState } from './display-reducer-type';

// manage transient states like loading and success responses

const defaultDisplayState = {
  displayErrors: {
    loginError: null,
    formsLoadError: null,
    signupError: null,
    createFormError: null,
    getFormError: null,
    getFormToDisplayError: null,
    getFormFieldsError: null,
    createFormFieldError: null,
    getCollectionsError: null,
    createCollectionError: null,
    deleteFormFieldError: null,
    updateFormFieldError: null,
    updateFormError: null,
    getFieldLogicRulesError: null,
    createFieldLogicRuleError: null,
    updateFieldLogicRuleError: null,
    deleteFieldLogicRuleError: null,
    getCalculationMetricsError: null,
    getCalculationsError: null,
    updateCalculationError: null,
    createCalculationError: null,
    deleteCalculationError: null,
    getExitPagesError: null,
    updateExitPageError: null,
    duplicateFormError: null,
    createExitPageError: null,
    deleteExitPageError: null,
    updatePatientError: null,
    updateProfileError: null,
    getExitPageFieldLogicRulesError: null,
    createExitPageFieldLogicError: null,
    deleteExitPageFieldLogicError: null,
    updateExitPageFieldLogicError: null,
    getExitPageCalculationsError: null,
    createExitPageCalculationError: null,
    updateExitPageCalculationError: null,
    deleteExitPageCalculationError: null,
    deleteFormsError: null,
    deleteCollectionError: null,
    editCollectionError: null,
    getCollectionAssignmentsError: null,
    getUnitsError: null,
    getNonPatientsError: null,
    assignCollectionError: null,
    assignCollectionToFormsError: null,
    assignFormToCollectionsError: null,
    unassignCollectionFromFormsError: null,
    unassignUnitFromCollectionError: null,
    unassignUserFromCollectionError: null,
    createFormPreviewError: null,
    getNextQuestionError: null,
    submitFieldResponseError: null,
    getFormInstancesByPatientError: null,
    assignFormToPatientError: null,
    deleteFormInstanceError: null,
    getPatientFormsError: null,
    getExitPageError: null,
    getFormInstanceResponsesError: null,
    getPatientFormInstancesIncompleteError: null,
    getPatientFormInstancesCompleteError: null,
    updateUserRoleError: null,
    getLoggedInUserError: null,
    changePasswordError: null,
    downloadingFormResponsePdfError: null,
    inviteUserError: null,
    getFormInstanceError: null,
    sendIncompleteFormReminderError: null,
    createStaticResourceError: null,
    uploadStaticResourceError: null,
    deleteUserError: null,
    getStaticResourceTagsError: null,
    searchTagsError: null,
    editProfileError: null,
    getProfileError: null,
    createMetricError: null,
    getDataLoadingError: null
  },
  displaySuccess: {
    signupSuccess: false,
    updateFormsSuccess: false,
    deleteFormsSuccess: false,
    getCollectionsSuccess: false,
    updateCollectionsSuccess: false,
    createCollectionSuccess: false,
    deleteCollectionSuccess: false,
    editCollectionSuccess: false,
    assignCollectionSuccess: false,
    assignCollectionToFormsSuccess: false,
    assignFormToCollectionsSuccess: false,
    unassignCollectionFromFormsSuccess: false,
    unassignUnitFromCollectionSuccess: false,
    unassignUserFromCollectionSuccess: false,
    getFormInstancesByPatientSuccess: false,
    assignFormToPatientSuccess: false,
    deleteFormInstanceSuccess: false,
    changePasswordSuccess: false,
    inviteUserSuccess: false,
    uploadStaticResourceSuccess: false,
    organizationSuccess: false,
    deleteUserSuccess: false,
    editProfileSuccess: false,
    getProfileSuccess: false,
    updateFormFieldSuccess: false,
    saveCalculationMetricSuccess: false,
    autoSaveSuccess: false,
    saveReferralSuccess: false,
    getForms: false,
    newPrimarySiteAdmin: false,
    appointmentSuccess: false,
    saveDataSuccess: false,
    updateFormRecurrence: false,
    saveExitPage: false,
  },
  displayLoading: {
    // createFormLoading: false
    getFormLoading: false,
    getFormToDisplayLoading: false,
    getFormFieldsLoading: false,
    createFormFieldLoading: false,
    deleteFormFieldLoading: false,
    updateFormFieldLoading: false,
    updateFormLoading: false,
    createFieldLogicRuleLoading: false,
    getFieldLogicRulesLoading: false,
    updateFieldLogicRuleLoading: false,
    deleteFieldLogicRuleLoading: false,
    getCalculationMetricsLoading: false,
    getCalculationsLoading: false,
    updateCalculationLoading: false,
    createCalculationLoading: false,
    deleteCalculationLoading: false,
    getExitPagesLoading: false,
    updateExitPageLoading: false,
    duplicateFormLoading: false,
    createExitPageLoading: false,
    deleteExitPageLoading: false,
    updatePatientLoading: false,
    updateProfileLoading: false,
    getExitPageFieldLogicRulesLoading: false,
    createExitPageFieldLogicLoading: false,
    deleteExitPageFieldLogicLoading: false,
    updateExitPageFieldLogicLoading: false,
    getExitPageCalculationsLoading: false,
    createExitPageCalculationLoading: false,
    updateExitPageCalculationLoading: false,
    deleteExitPageCalculationLoading: false,
    deleteFormsLoading: false,
    deleteCollectionLoading: false,
    editCollectionLoading: false,
    getCollectionAssignmentsLoading: false,
    getUnitsLoading: false,
    getNonPatientsLoading: false,
    assignCollectionLoading: false,
    assignCollectionToFormsLoading: false,
    assignFormToCollectionsLoading: false,
    unassignCollectionFromFormsLoading: false,
    unassignUnitFromCollectionLoading: false,
    unassignUserFromCollectionLoading: false,
    createFormPreviewLoading: false,
    getNextQuestionLoading: false,
    submitFieldResponseLoading: false,
    getFormInstancesByPatientLoading: false,
    assignFormToPatientLoading: false,
    deleteFormInstanceLoading: false,
    getPatientFormsLoading: false,
    getExitPageLoading: false,
    getFormInstanceResponsesLoading: false,
    getPatientFormInstancesIncompleteLoading: false,
    getPatientFormInstancesCompleteLoading: false,
    updateUserRoleLoading: false,
    getLoggedInUserLoading: false,
    downloadingFormResponsePdfLoading: false,
    inviteUserLoading: false,
    getFormInstanceLoading: false,
    sendIncompleteFormReminderLoading: false,
    deleteUserLoading: false,
    getStaticResourceTagsLoading: false,
    searchTagsLoading: false,
    getDataLoading: false
  },

  createFormLoading: false,
  snackbarAlertMessage: null,
  snackbarAlertSeverity: 'info' as Color,
  snackbarAutoHideDuration: null,

  // Display ids for showing loading in a list correctly
  deleteFormFieldId: null,
  updateFormFieldId: null,
  updateFieldLogicRuleId: null,
  updateCalculationId: null,
  updateExitPageId: null,
  updateExitPageLogicRuleId: null,
  updateExitPageCalculationId: null,
  sendReminderFormInstanceId: null
};

export const displayReducer = (
  state: DisplayReduxState = defaultDisplayState,
  action: DisplayAction
): DisplayReduxState => {
  switch (action.type) {
    case SET_GENERIC_ERROR_A:
      const errorResult = { ...state };
      const errorAction = action as SetErrorAction;
      errorResult.displayErrors[errorAction.reduxField] = errorAction.errorMsg;
      return errorResult;
    case SET_GENERIC_SUCCESS_A:
      const successResult = { ...state };
      const successAction = action as SetSuccessAction;
      successResult.displaySuccess[successAction.reduxField] = successAction.success;
      return successResult;
    case SET_GENERIC_LOADING_A:
      const loadingResult = { ...state };
      const loadingAction = action as SetLoadingAction;
      loadingResult.displayLoading[loadingAction.reduxField] = loadingAction.loading;
      if (loadingAction.errorFieldToNull) {
        loadingResult.displayErrors[loadingAction.errorFieldToNull] = null;
      }
      return loadingResult;
    case SNACKBAR_ALERT_A:
      return {
        ...state,
        snackbarAlertMessage: (action as SnackbarAlertAction).snackbarAlertMessage,
        snackbarAlertSeverity: (action as SnackbarAlertAction).snackbarAlertSeverity,
        snackbarAutoHideDuration: (action as SnackbarAlertAction).snackbarAutoHideDuration
      };
    case CREATING_NEW_FORM_A:
      return {
        ...state,
        createFormLoading: true,
        displayErrors: {
          ...state.displayErrors,
          createFormError: null
        }
      };
    case CREATING_NEW_FORM_SUCCESS_A:
      return {
        ...state,
        createFormLoading: false,
        displayErrors: {
          ...state.displayErrors,
          createFormError: null
        }
      };
    case DELETING_FORM_FIELD_A:
      return {
        ...state,
        deleteFormFieldId: (action as DeletingFormFieldAction).fieldId
      };
    case DELETING_FORM_FIELD_SUCCESS_A:
      return {
        ...state,
        deleteFormFieldId: null
      };
    case UPDATING_FORM_FIELD_A:
      return {
        ...state,
        updateFormFieldId: (action as UpdatingFormFieldAction).fieldId
      };
    case UPDATING_FORM_FIELD_SUCCESS_A:
      return {
        ...state,
        updateFormFieldId: null
      };
    case UPDATING_FIELD_LOGIC_RULE_A:
      return {
        ...state,
        updateFieldLogicRuleId: (action as UpdatingFieldLogicRuleAction).fieldLogicRuleId
      };
    case UPDATING_FIELD_LOGIC_RULE_FINISHED_A:
      return {
        ...state,
        updateFieldLogicRuleId: null
      };
    case UPDATING_CALCULATION_A:
      return {
        ...state,
        updateCalculationId: (action as UpdatingCalculationAction).calculationId
      };
    case SEND_REMINDER_FORM_INSTANCE_ID_A:
      return {
        ...state,
        sendReminderFormInstanceId: (action as SendReminderFormInstanceIdAction).formInstanceId
      };
    case UPDATING_CALCULATION_FINISHED_A:
      return {
        ...state,
        updateCalculationId: null
      };
    case SET_UPDATING_EXIT_PAGE_LOGIC_RULE_ID_A:
      return {
        ...state,
        updateExitPageLogicRuleId: (action as SetUpdatingExitPageLogicRuleIdAction).logicId
      };
    case SET_UPDATING_EXIT_PAGE_CALCULATION_ID_A:
      return {
        ...state,
        updateExitPageCalculationId: (action as SetUpdatingExitPageCalculationIdAction)
          .calculationId
      };
    default:
      return state;
  }
};
