import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Theme, Typography, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { getSharedUserInfoThunk } from '../../../redux/thunk/thunks';
import {
    HeadCell,
    LastSorted,
    Pagination,
    RowCell,
    Table
} from '../../../components/Table';
import { colors } from '../../../styling/colors';

interface SharedUserTableProps {
    dataList: any | null;
    loadSharedUsers: (getUserProps: any) => void;
}

export const SharedUserTable = ({ dataList, loadSharedUsers }: SharedUserTableProps) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [lastSorted, setLastSorted] = useState<LastSorted>({
        column: 'first_name',
        order: 'desc'
    });

    const headCells: HeadCell[] = [
        {
            id: 'first_name',
            label: 'NAME'
        },
        {
            id: 'role',
            label: 'ROLE'
        },
        {
            id: 'status',
            label: 'STATUS'
        }
    ];

    const handleSection = async (user: any) => {
        await dispatch(getSharedUserInfoThunk(user?.user_role_id));
        history.push({ pathname: `/patient-shared-user/${user?.user_role_id}/profile`, state: user });
    }

    const rowCells = dataList && dataList?.data.map((user: any, index: any) => {
        const TitleLink = (
            <Typography color="primary" variant="h5" style={{ cursor: 'pointer' }} onClick={() => handleSection(user)}>
                {user.first_name + ' ' + user.last_name}
            </Typography>
        );
        const strReplace = (
            <Typography variant="h5">{user.status.replace(/_/g, " ")}</Typography>
        )
        const rowCell: RowCell = {
            first_name: { component: TitleLink, value: user.organization_name },
            role: {
                component: user.role
            },
            status: {
                align: 'left',
                component: strReplace
            }
        };

        return Object.assign(rowCell, { content: user });
    });

    const handleNextButton = async () => {
        if (
            dataList &&
            dataList?.page >= 0 &&
            dataList?.page < dataList?.totalCount / dataList?.pageSize - 1
        ) {
            await loadSharedUsers({
                search: dataList?.search,
                page: dataList?.page + 1,
                pageSize: dataList?.pageSize,
                sort: dataList?.sort,
                order: dataList?.order
            });
        }
    };
    const handlePrevButton = async () => {
        if (dataList && dataList?.page > 0) {
            await loadSharedUsers({
                search: dataList?.search,
                page: dataList?.page - 1,
                pageSize: dataList?.pageSize,
                sort: dataList?.sort,
                order: dataList?.order
            });
        }
    };

    const handlePageSize = (
        e: React.ChangeEvent<{
            name?: string; value: unknown;
        }>
    ) => {
        const pageSize: number = e.target.value as number;

        if (dataList && pageSize > 0) {
            loadSharedUsers({
                page: 0,
                pageSize: pageSize,
                search: dataList?.search,
                sort: dataList?.sort,
                order: dataList?.order
            });
        }
    };

    const pagination: Pagination | undefined = dataList
        ? {
            contentName: 'Shared Users',
            page: dataList?.page,
            pageSize: dataList?.pageSize,
            total: dataList?.totalCount,
            handleNextButton: handleNextButton,
            handlePageSize: handlePageSize,
            handlePrevButton: handlePrevButton
        }
        : undefined;

    const handleSort = (sort: string) => {
        const nextDirection = lastSorted.order === 'desc' ? 'asc' : 'desc';

        loadSharedUsers({
            order: sort === lastSorted.column ? nextDirection : 'desc',
            page: 0,
            pageSize: dataList?.pageSize,
            search: dataList?.search,
            sort
        });

        setLastSorted(
            sort === lastSorted.column
                ? { column: sort, order: nextDirection }
                : { column: sort, order: 'desc' }
        );
    };

    return (
        <>
            <Table
                data={dataList}
                headCells={headCells}
                // loading={dataList?.collectionId !== collectionId}
                pagination={pagination}
                rowCells={rowCells}
                // selection={
                //     selectedUsers && setSelectedUsers
                //         ? {
                //             selectedContents: selectedUsers,
                //             setSelectedContents: (selected: UuidType[]) => setSelectedUsers(selected)
                //         }
                //         : undefined
                // }
                sort={{
                    lastSorted: lastSorted,
                    handleSort: handleSort
                }}
            />
        </>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    collectionBubble: {
        borderRadius: '0.75rem'
    },
    clearIcon: {
        color: colors.text6,
        fontSize: 14,
        marginLeft: '0.25rem'
    },
    launchIcon: {
        fontSize: '2rem',
        cursor: 'pointer'
    },
    actionTypography: {
        padding: '10px',
        fontWeight: 'bold',
        cursor: 'pointer'
    },
}));
