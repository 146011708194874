import { Box, makeStyles, Paper, TextField, Theme, Typography, MenuItem, Select, Grid, DialogActions } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { requestSupportThunk, sendCommentThunk, getAdminListThunk } from '../../../../../redux/thunk/thunks';
import { RootState } from '../../../../../redux/redux-store';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import * as Yup from "yup";
import { LoadingButton } from '../../../../../components/LoadingButton';
import { colors } from '../../../../../styling/colors';
import { LoadingOrError } from '../../../../../components/LoadingOrError';
import { Dialog } from '../../../../../components/Dialog';

export const ContactUs = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [inputs, setInputs] = useState(null);
  const [isDirectSupport, setIsDirectSupport] = useState(false);
  const [disDate, setDisabledDate] = useState<any>();
  const [initialValues, setInitialValues] = useState({
    comment: '',
  });
  const [initialValue, setInitialValue] = useState({
    support_start_date: '',
    support_end_date: '',
    admin_support_id: ''
  })
  const loadingUser = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.getLoggedInUserLoading
  );
  const loadingUserError = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayErrors.getLoggedInUserError
  );
  const adminList: any = useSelector((state: RootState) => state.DATA_REDUCER.adminList);
  useEffect(() => {
    dispatch(getAdminListThunk())
  }, [])

  useEffect(() => {
    let newDate: any = Date.now();
    setDisabledDate(new Date(newDate).getFullYear() + '-' + (("0" + (new Date(newDate).getMonth() + 1)).slice(-2)) + '-' + ("0" + (new Date(newDate).getDate())).slice(-2));
  }, []);

  const handleSubmit = async (values: any, actions: any) => {
    actions.setSubmitting(true);
    await dispatch(sendCommentThunk(values.comment));
    actions.setSubmitting(false);
    actions.resetForm();
  };

  const handleSave = async (values: any, actions: any) => {
    setLoading(true);
    const email = adminList.filter((item: any) => item.user_role_id === values.admin_support_id)
    const data = {
      "admin_support_id": values.admin_support_id,
      "admin_support_email": email && email.length > 0 && email[0].email,
      "support_start_date": values.support_start_date,
      "support_end_date": values.support_end_date
    }
    await dispatch(requestSupportThunk(data));
    setIsDirectSupport(false);
    setLoading(false);
  }

  const contactSchema = Yup.object().shape({
    comment: Yup.string().min(10, "Too Short!").max(500, '').required('Required')
  });

  const supportSchema = Yup.object().shape({
    support_start_date: Yup.string().required('Required'),
    support_end_date: Yup.string().required('Required'),
    admin_support_id: Yup.string().required('Required'),
  });

  const handleChange = (value: any, no: any) => {
    if (no === 0 && value.length > 500) {
      setIsError(true); setInputs(no);
    } else {
      setIsError(false); setInputs(no);
    }
  }

  return (
    <>
      <Paper>
        <Box
          className={classes.topBox1}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          py={2.5}
        >
          <Box alignItems="center" display="flex" justifyContent="space-between" px={4}>
            <Typography variant="h2">Contact FormFlash Administration</Typography>
            <Box display="flex" justifyContent="space-between">
              <LoadingButton onClick={(e) => setIsDirectSupport(true)}
                className={classes.primaryButton}
                color="primary"
                variant="contained"
              >
                Direct Support
              </LoadingButton>
            </Box>
          </Box>
        </Box>
        {loadingUser || loadingUserError ? (
          <LoadingOrError
            errorMsg={loadingUserError}
            loading={true}
            loadingMsg="Loading User Profile"
            noElevation
          />
        ) :
          <Box pb={4} pt={3} px={4} display="flex">
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={contactSchema}
            >
              {({ errors, touched, isSubmitting, values }) => {
                return (
                  <Box>
                    <Form className={classes.formCont}>
                      <Grid container spacing={1}>
                        <Grid item xs={8} md={8}>
                          <Box mb={2}>
                            <Typography style={{ fontSize: '16px', margin: '10px 0px' }}>Please contact FormFlash administration at the email address below, or use the form below to send us a message.</Typography>
                          </Box>
                          <Box mb={2}>
                            <Typography style={{ fontSize: '16px', margin: '10px 0px 0px' }}>Dr. Edwin Boudreaux</Typography>
                            <Typography style={{ fontSize: '16px', margin: '0px 0px 30px' }}>Edwin.Boudreaux@umassmed.edu</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={8} md={8}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Box flexGrow="1">
                                <Box pb={0.75}>
                                  <Typography color="textSecondary" variant="subtitle2">
                                    Comments
                                  </Typography>
                                </Box>

                                <Field multiline rows={6} fullWidth
                                  style={{ background: isError && inputs === 0 ? '#fff1f1' : 'unset' }}
                                  onInput={(e: any) => handleChange(e.target.value, 0)}
                                  as={TextField}
                                  inputProps={{ maxLength: 501 }}
                                  name="comment"
                                  size="small"
                                  type="text"
                                  variant="outlined"
                                />
                                <Box display='flex' justifyContent='space-between'>
                                  <Typography color={"error"} variant="subtitle2">
                                    {''}
                                  </Typography>
                                  <Typography style={{ color: values.comment?.length > 500 ? '#911717' : '#0000008a' }} variant="subtitle2">
                                    {values.comment?.length === 501 ? 500 : values.comment?.length}/500
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                              <LoadingButton
                                className={classes.primaryButton}
                                color="primary"
                                variant="contained"
                                type='submit'
                                disabled={isSubmitting}
                                loading={isSubmitting}
                              >
                                Submit
                              </LoadingButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Form>
                  </Box>
                );
              }}
            </Formik>
          </Box>
        }
      </Paper>
      {isDirectSupport &&
        <Dialog open={isDirectSupport}>
          <Box style={{ width: '600px' }}>
            <Box pb={3} pt={2} px={4} style={{ background: '#f9fafb', borderRadius: '10px 10px 0px 0px' }}>
              <Typography variant="h3">Request Support</Typography>
            </Box>
            <Box pb={3} pt={2} px={4}>
              <Typography variant="h4" style={{ color: 'gray' }}>If direct assistance is required, please fill out the Contact Us form with a description of your issue and state that you would like an admin to use your account. The admin
                team will send you the email address of your designated tech support personnel. After you receive this email address, please fill in the below information to grant access to your organization.</Typography>
            </Box>
            <Formik
              enableReinitialize={true}
              initialValues={initialValue}
              onSubmit={handleSave}
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={supportSchema}
            >
              {({ errors, touched, isSubmitting }) => {
                return (
                  <Form className={classes.formCont}>
                    <Box pt={2} px={4} pb={2}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Box flexGrow="1">
                            <Box pb={0.75}>
                              <Typography color="textSecondary" variant="subtitle2">
                                Tech Support Email address*
                              </Typography>
                            </Box>
                            <Field as={Select} name="admin_support_id" variant="outlined" size="small" className={classes.textField1}>
                              <MenuItem value={''}>Select</MenuItem>
                              {adminList && adminList.length > 0 && adminList.map((item: any) => {
                                return (
                                  <MenuItem value={item.user_role_id}>{item.email}</MenuItem>
                                )
                              })}
                            </Field>
                            {errors.admin_support_id && touched.admin_support_id ? (
                              <Typography color="error" variant="subtitle2">
                                {errors.admin_support_id}
                              </Typography>
                            ) : null}
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box flexGrow="1">
                            <Box pb={0.75}>
                              <Typography color="textSecondary" variant="subtitle2">
                                Support Start Date*
                              </Typography>
                            </Box>

                            <Field
                              as={TextField}
                              name="support_start_date"
                              size="small"
                              type="date"
                              variant="outlined"
                              style={{ width: '100%' }}
                              InputProps={{ inputProps: { min: disDate } }}
                            />
                            {errors.support_start_date && touched.support_start_date ? (
                              <Typography color="error" variant="subtitle2">
                                {errors.support_start_date}
                              </Typography>
                            ) : null}
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box flexGrow="1">
                            <Box pb={0.75}>
                              <Typography color="textSecondary" variant="subtitle2">
                                Support End Date*
                              </Typography>
                            </Box>

                            <Field
                              as={TextField}
                              name="support_end_date"
                              size="small"
                              type="date"
                              variant="outlined"
                              style={{ width: '100%' }}
                              InputProps={{ inputProps: { min: disDate } }}
                            />
                            {errors.support_end_date && touched.support_end_date ? (
                              <Typography color="error" variant="subtitle2">
                                {errors.support_end_date}
                              </Typography>
                            ) : null}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box display='flex' justifyContent='space-between' style={{ width: '-webkit-fill-available', background: '#f9fafb', borderRadius: '0px 0px 10px 10px' }} p={3}>
                      <LoadingButton className={classes.secondaryButton}
                        variant="contained"
                        onClick={() => setIsDirectSupport(false)}
                      >
                        Cancel
                      </LoadingButton>
                      <LoadingButton className={classes.primaryButton}
                        variant="contained" color="primary" loading={isLoading}
                        type='submit' disabled={isLoading}
                      >
                        Save
                      </LoadingButton>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Dialog>
      }
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  topBox: {
    backgroundColor: theme.palette.secondary.light,
    borderBottom: '0.0625rem solid #D1D6DC',
    borderRadius: '0.625rem 0.625rem 0 0',
    paddingLeft: '32px',
    paddingRight: '32px',
    marginBottom: '30px',
    marginLeft: '-32px',
    marginRight: '-32px',
    marginTop: '-25px',
    paddingTop: '22px',
    width: '-webkit-fill-available'
  },
  topBox1: {
    backgroundColor: theme.palette.secondary.light,
    borderBottom: '0.0625rem solid #D1D6DC',
    borderRadius: '0.625rem 0.625rem 0 0'
  },
  primaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: '#fff',
    minWidth: '120px'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    height: '2rem',
    color: '#0060B2',
    minWidth: '120px',
    marginRight: '20px'
  },
  textFieldContainer: {
    maxWidth: '18rem',
    width: '65vw'
  },
  textField: {
    width: '100%'
  },
  link: {
    textDecoration: 'none'
  },
  rememberLabel: {
    color: colors.text4
  },
  openDialogoeBtn: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    marginLeft: '1rem'
  },
  formCont: {
    width: '100%'
  },
  boldFont: {
    fontWeight: 600
  },
  textField1: {
    width: '100%',
    height: '35px',
    padding: '0px'
  }
}));
