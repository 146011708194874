import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/redux-store';
import { useRouteMatch } from 'react-router-dom';
import { MyProfile } from './Profile'

export const SharedUsers = () => {
    const dispatch = useDispatch();
    const { path } = useRouteMatch();
    const split = path.split('/');

    const success: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.deleteUserSuccess);
    useEffect(() => {
        if (success) {
        }
    })

    return (
        <>
            <MyProfile />
        </>
    );
};
