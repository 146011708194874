import { Box, makeStyles, Paper, Theme, Typography, Dialog, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/redux-store';
import { colors } from '../../../../styling/colors';
import { ReferralDetails } from './components/ReferralDetails';
import { ReferralTopBar } from './components/ReferralTopBar';
import { LoadingButton } from '../../../../components/LoadingButton';
import { ROLES } from '../../../../shared/globals';

interface ReferralProfileProps {
  open: boolean,
  viewInfo: any,
  handleClose: () => void;
  tab: any
}

export const ReferralProfile = ({ open, handleClose, viewInfo, tab }: ReferralProfileProps) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = React.useState(false);
  const [isDelete, setDeleteOpen] = React.useState(false);
  const [isType, setType] = React.useState(null);;
  const [referralDialogueOpen, setReferralDialogueOpen] = React.useState(false);
  const [referral, setReferral] = useState<any>({ about: 'about' });
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);
  const referralFilterList: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.referralFilterListResults
  );

  const editReferral = viewInfo;

  const edit = (open: boolean) => {
    setIsEdit(open)
  }

  const handleReferralDialog = () => {
    setReferralDialogueOpen(true)
  }

  const handleReferralTypeDialog = (type: any) => {
    setType(type);
  }

  return (
    <>
      <Dialog fullScreen open={open}>
        <Box className={classes.main} display="flex" flexDirection="column" p={4}>
          <Box pb={5}>
            <ReferralTopBar handleClose={handleClose} Edit={edit} referralInfo={editReferral} tab={tab} />
          </Box>
          <Grid container className={classes.body}>
            <Grid item xs={12} sm={5} md={3} className={classes.contentContainer}>
              <ReferralDetails isEdit={isEdit} referralInfo={editReferral} />
            </Grid>
            <Grid item xs={12} sm={7} md={9} className={classes.contentContainer}>
              <Paper style={{ width: '100%', borderRadius: '0px 10px 10px 0px' }}>
                <Box px={2.5} pt={3}>
                  <Typography noWrap variant="h2" className={classes.heading}>
                    About {isEdit && <img src={process.env.PUBLIC_URL + '/icons/EditIcon.svg'} alt="Edit Icon" onClick={() => handleReferralDialog()} style={{ marginLeft: '20px', cursor: 'pointer' }} />}
                  </Typography>
                  <Typography>{editReferral.about}</Typography>
                </Box>
                <Box px={2.5} pt={3}>
                  <Typography noWrap variant="h2" className={classes.heading}>
                    Insurance {isEdit && <img src={process.env.PUBLIC_URL + '/icons/EditIcon.svg'} alt="Edit Icon" onClick={() => handleReferralTypeDialog('INSURANCE')} style={{ marginLeft: '20px', cursor: 'pointer' }} />}
                  </Typography>
                  <Grid container>
                    {editReferral.insurances && editReferral.insurances.map((id: any) => {
                      const checked = referralFilterList && referralFilterList.filter((item: any) => item.id === id);
                      return (
                        <Grid item xs={12} md={6}>
                          <li><Typography style={{ display: 'contents' }}>{checked && checked.length > 0 && checked[0].title}</Typography></li>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Box>
                <Box px={2.5} pt={3}>
                  <Typography noWrap variant="h2" className={classes.heading}>
                    Types of Therapy {isEdit && <img src={process.env.PUBLIC_URL + '/icons/EditIcon.svg'} alt="Edit Icon" onClick={() => handleReferralTypeDialog('THERAPY_TYPE')} style={{ marginLeft: '20px', cursor: 'pointer' }} />}
                  </Typography>
                  <Grid container>
                    {editReferral.therapy_types && editReferral.therapy_types.map((id: any) => {
                      const checked = referralFilterList && referralFilterList.filter((item: any) => item.id === id);
                      return (
                        <Grid item xs={12} md={6}>
                          <li><Typography style={{ display: 'contents' }}>{checked && checked.length > 0 && checked[0].title}</Typography></li>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Box>
                <Box px={2.5} pt={3}>
                  <Typography noWrap variant="h2" className={classes.heading}>
                    Issue {isEdit && <img src={process.env.PUBLIC_URL + '/icons/EditIcon.svg'} alt="Edit Icon" onClick={() => handleReferralTypeDialog('ISSUE')} style={{ marginLeft: '20px', cursor: 'pointer' }} />}
                  </Typography>
                  <Grid container>
                    {editReferral.issues && editReferral.issues.map((id: any) => {
                      const checked = referralFilterList && referralFilterList.filter((item: any) => item.id === id);
                      return (
                        <Grid item xs={12} md={6}>
                          <li><Typography style={{ display: 'contents' }}>{checked && checked.length > 0 && checked[0].title}</Typography></li>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Box>
                <Box px={2.5} pt={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Typography noWrap variant="h2" className={classes.heading}>
                        Age {isEdit && <img src={process.env.PUBLIC_URL + '/icons/EditIcon.svg'} alt="Edit Icon" onClick={() => handleReferralTypeDialog('AGE')} style={{ marginLeft: '20px', cursor: 'pointer' }} />}
                      </Typography>
                      <Grid container>
                        {editReferral.ages_served && editReferral.ages_served.map((id: any) => {
                          const checked = referralFilterList && referralFilterList.filter((item: any) => item.id === id);
                          return (
                            <Grid item xs={12}>
                              <li><Typography style={{ display: 'contents' }}>{checked && checked.length > 0 && checked[0].title}</Typography></li>
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography noWrap variant="h2" className={classes.heading}>
                        Language {isEdit && <img src={process.env.PUBLIC_URL + '/icons/EditIcon.svg'} alt="Edit Icon" onClick={() => handleReferralTypeDialog('LANGUAGE')} style={{ marginLeft: '20px', cursor: 'pointer' }} />}
                      </Typography>
                      <Grid container>
                        {editReferral.languages && editReferral.languages.map((id: any) => {
                          const checked = referralFilterList && referralFilterList.filter((item: any) => item.id === id);
                          return (
                            <Grid item xs={12}>
                              <li><Typography style={{ display: 'contents' }}>{checked && checked.length > 0 && checked[0].title}</Typography></li>
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Box px={2.5} pt={3} pb={3}>
                  <Typography noWrap variant="h2" className={classes.heading}>
                    Sexuality {isEdit && <img src={process.env.PUBLIC_URL + '/icons/EditIcon.svg'} alt="Edit Icon" onClick={() => handleReferralTypeDialog('SEXUALITY')} style={{ marginLeft: '20px', cursor: 'pointer' }} />}
                  </Typography>
                  <Grid container>
                    {editReferral.sexuality && editReferral.sexuality.map((id: any) => {
                      const checked = referralFilterList && referralFilterList.filter((item: any) => item.id === id);
                      return (
                        <Grid item xs={12} md={6}>
                          <li><Typography style={{ display: 'contents' }}>{checked && checked.length > 0 && checked[0].title}</Typography></li>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Box>
                {isEdit && loggedInUser && [ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(loggedInUser.role) && <Box pt={3} pb={3} px={2.5} display='flex' justifyContent='flex-end'>
                  <LoadingButton
                    className={classes.deleteButton}
                    color="secondary"
                    variant="contained"
                    onClick={() => setDeleteOpen(true)}
                  >
                    Delete
                  </LoadingButton>
                </Box>
                }
                {isEdit && loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role) && editReferral.org_id && <Box pt={3} pb={3} px={2.5} display='flex' justifyContent='flex-end'>
                  <LoadingButton
                    className={classes.deleteButton}
                    color="secondary"
                    variant="contained"
                    onClick={() => setDeleteOpen(true)}
                  >
                    Delete
                  </LoadingButton>
                </Box>
                }
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};

const useStyle = makeStyles((theme: Theme) => ({
  main: {
    backgroundColor: colors.background1,
    minHeight: 'auto',
    boxSizing: 'border-box'
  },
  body: {
    margin: '50px 0',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap'
  },
  referralInfo: {
    [theme.breakpoints.down('xs')]: {
      paddingRight: '0',
      width: '100%'
    }
  },
  contentContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  contentContainer1: {
    [theme.breakpoints.down('xs')]: {
      flexGrow: 0,
      flexBasis: '100%',
      maxWidth: '100%',
      display: 'flex',
      flexWrap: 'wrap'
    },
    [theme.breakpoints.down('sm')]: {
      flexGrow: 0,
      flexBasis: '50%',
      maxWidth: '50%',
      display: 'flex',
      flexWrap: 'wrap'
    },
    [theme.breakpoints.down('md')]: {
      flexGrow: 0,
      flexBasis: '60%',
      maxWidth: '60%',
      display: 'flex',
      flexWrap: 'wrap'
    },
    [theme.breakpoints.down('lg')]: {
      flexGrow: 0,
      flexBasis: '70%',
      maxWidth: '70%',
      display: 'flex',
      flexWrap: 'wrap'
    }
  },
  heading: {
    marginBottom: '1rem'
  },
  editIcon: {
    marginLeft: '10px',
    fontSize: '12px',
    border: '1px solid #c1c1c1',
    color: '#c1c1c1',
    borderRadius: '3px',
    cursor: 'pointer'
  },
  deleteButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: 'red',
    minWidth: '120px'
  }
}));
