import { Box, Button, IconButton, makeStyles, TextField, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/redux-store';
import { FieldOptionUpdate, UuidType } from '../../../../shared/domain';
interface OptionsSettingsProps {
  handleOptionsChange: (options: FieldOptionUpdate[]) => void;
  handleOptionsToDelete: (ids: UuidType[]) => void;
  options: FieldOptionUpdate[];
  titleClass: string;
}

//This component is used to create and delete options in a form field that require them
export const OptionsSettings: React.FC<OptionsSettingsProps> = ({
  handleOptionsChange,
  handleOptionsToDelete,
  options,
  titleClass
}) => {
  const optionsToDelete = useSelector(
    (state: RootState) => state.DATA_REDUCER.formFieldOptionsToDelete
  );
  const classes = useStyles();
  const maxOptions = 25;
  const minOptions = 1;
  const changeOptionText = ({ event, index }: { event: any; index: any }) => {
    let updateOptions = options;
    updateOptions[index].option_text = event.target.value;
    handleOptionsChange(updateOptions);
  };
  const addOption = (allowUserInput: boolean) => {
    if (options.length < maxOptions) {
      //for field options to be created set the id to 1
      let newOption: FieldOptionUpdate = {
        id: '1',
        option_text: allowUserInput ? 'Other' : `Option ${options.length + 1}`,
        option_value: options.length + 1,
        allow_user_input: allowUserInput
      };
      handleOptionsChange(options.concat(newOption));
    }
  };
  const removeOption = (optionToRemove: FieldOptionUpdate) => {
    let deleteIdsArr = optionsToDelete || [];
    if (options.length > minOptions) {
      //if option has Id AND it is not "1", add to deletion array
      if (Object.keys(optionToRemove).includes('id') && optionToRemove.id) {
        handleOptionsToDelete(deleteIdsArr.concat(optionToRemove.id));
      }
      handleOptionsChange(options.filter((option) => option !== optionToRemove));
    }
  };

  const checkIfOtherOptionExists = () => {
    let otherOptionExists = false;

    for (let i = 0; i < options.length; i++) {
      if (options[i].allow_user_input) {
        otherOptionExists = true;
        break;
      }
    }
    return otherOptionExists;
  };

  return (
    <>
      <Box className={classes.fieldOptionsHeader}>
        <Typography color={'textSecondary'} variant="subtitle2" className={titleClass}>
          Field Options
        </Typography>
      </Box>

      {options.map((option, i) => (
        <Box className={classes.row}>
          <TextField
            // if option is input by the patient while taking the form disable input here
            disabled={option.allow_user_input}
            classes={{ root: classes.root }}
            id={`Option-${1}`}
            variant="outlined"
            size={'small'}
            fullWidth
            onChange={(e) => changeOptionText({ event: e, index: i })}
            value={option.option_text}
          />
          <IconButton onClick={() => removeOption(option)} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
      ))}
      <Button
        variant="contained"
        fullWidth
        color={'secondary'}
        className={classes.addButton}
        onClick={() => addOption(false)}
        aria-label="add option"
      >
        Add Option
      </Button>
      <Button
        disabled={checkIfOtherOptionExists()}
        variant="contained"
        fullWidth
        color={'secondary'}
        className={classes.addButton}
        onClick={() => addOption(true)}
        aria-label="add user defined option"
      >
        Add User Defined Option
      </Button>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    width: '100%',
    marginRight: theme.spacing(1)
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    color: theme.palette.grey[500]
  },
  addButton: {
    marginTop: theme.spacing(1)
  },
  fieldOptionsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  icon: { marginLeft: '.4rem', color: theme.palette.grey[500], fontSize: '1rem' }
}));
