import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '../../../components/LoadingButton';
import { HeadCell, LastSorted, Pagination, RowCell, Table } from '../../../components/Table';
import { RootState } from '../../../redux/redux-store';
import { getPatientsCompletedTasksThunk } from '../../../redux/thunk/thunks';
import { PatientTask } from '../../../shared/domain';

export const CompletedTasksTable: React.FC = () => {
  const dispatch = useDispatch();

  const getCompleteTasksLoading = useSelector(
    (state: RootState) =>
      state.DISPLAY_STATE_REDUCER.displayLoading.getPatientFormInstancesCompleteLoading
  );
  const completeTasksResult = useSelector(
    (state: RootState) => state.DATA_REDUCER.patientFormInstances.complete
  );

  useEffect(() => {
    //fetch patients outstanding tasks if they are not already in redux
    if (!completeTasksResult?.tasks) {
      dispatch(getPatientsCompletedTasksThunk());
    }
  }, []);

  const [lastSorted, setLastSorted] = useState<LastSorted>({
    column: 'created_at',
    order: 'desc'
  });

  const headCells: HeadCell[] = [
    {
      id: 'form_title',
      label: 'FORM NAME'
    },
    {
      id: 'completed_at',
      label: 'COMPLETED ON'
    },
    {
      id: 'clinician_first_name',
      label: 'ASSIGNED BY'
    },
    {
      align: 'left',
      id: 'created_at',
      label: 'ASSIGNED ON'
    },
    {
      id: 'start_form_btn',
      label: ''
    }
  ];

  const rowCells = completeTasksResult?.tasks.map((task: PatientTask) => {
    const TakeFormButton = () => {
      return (
        //add route for patient to view response
        <LoadingButton
          onClick={() => {}}
          variant="contained"
          color={'primary'}
          loading={false}
          aria-label="review response"
        >
          Review Response
        </LoadingButton>
      );
    };
    const rowCell: RowCell = {
      form_title: { value: task.form_title },
      clinician_first_name: {
        value: `${task.clinician_first_name} ${task.clinician_last_name}`
      },
      completed_at: {
        align: 'left',
        value: new Date(task.completed_at).toDateString()
      },
      created_at: {
        align: 'left',
        value: new Date(task.created_at).toDateString()
      },
      start_form_btn: {
        component: <TakeFormButton />,
        value: 'test'
      }
    };

    return Object.assign(rowCell, { content: task });
  });

  const handleNextButton = async () => {
    if (
      completeTasksResult &&
      completeTasksResult.page >= 0 &&
      completeTasksResult.page < completeTasksResult.total / completeTasksResult.pageSize - 1
    ) {
      dispatch(
        getPatientsCompletedTasksThunk(completeTasksResult.page + 1, completeTasksResult.pageSize)
      );
    }
  };
  const handlePrevButton = async () => {
    if (completeTasksResult && completeTasksResult.page > 0) {
      dispatch(
        getPatientsCompletedTasksThunk(completeTasksResult.page - 1, completeTasksResult.pageSize)
      );
    }
  };

  const handlePageSize = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    const pageSize: number = e.target.value as number;

    if (completeTasksResult && pageSize > 0) {
      dispatch(getPatientsCompletedTasksThunk(0, pageSize));
    }
  };

  const handleSort = (sort: string) => {
    const nextDirection = lastSorted.order === 'desc' ? 'asc' : 'desc';
    const order = sort === lastSorted.column ? nextDirection : 'desc';

    dispatch(getPatientsCompletedTasksThunk(0, completeTasksResult?.pageSize, sort, order));

    setLastSorted(
      sort === lastSorted.column
        ? { column: sort, order: nextDirection }
        : { column: sort, order: 'desc' }
    );
  };

  const pagination: Pagination | null = completeTasksResult
    ? {
        contentName: 'Task',
        page: completeTasksResult.page,
        pageSize: completeTasksResult.pageSize,
        total: completeTasksResult.total,
        handleNextButton: handleNextButton,
        handlePageSize: handlePageSize,
        handlePrevButton: handlePrevButton
      }
    : null;

  return (
    <Box pb={4} pt={1} px={4}>
      <Table
        data={completeTasksResult?.tasks}
        headCells={headCells}
        loading={getCompleteTasksLoading}
        pagination={pagination}
        rowCells={rowCells}
        sort={{
          lastSorted: lastSorted,
          handleSort: handleSort
        }}
      />
    </Box>
  );
};
