import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { LoadingOrError } from '../../components/LoadingOrError';
import { RootState } from '../../redux/redux-store';
import {
  createFormPreviewThunk, patientFormPreviewThunk,
  fetchFormToDisplayByIdThunk,
  getFormInstanceByIdThunk,
  getFormStyleThunk
} from '../../redux/thunk/thunks';
import { setDisplayFormInstanceAction, setDisplayFormAction, getFormStyleAction, setDisplayFormFieldPayloadAction, setDisplayExitPageAction } from '../../redux/data/data-actions';
import { setLoadingAction, setErrorAction } from '../../redux/display/display-actions';
import { UuidType } from '../../shared/domain';
import { theme } from '../../styling/ThemeConfig';
import { DisplayCard } from './DisplayCard';
import { ROLES } from '../../shared/globals';

//pass the form Id and whether the form should be a preview or not
interface RouterProps {
  formId: UuidType;
  formInstanceId: UuidType;
}

interface TakeFormProps extends RouteComponentProps<RouterProps> {
  preview: boolean;
}

const TakeFormComponent: React.FC<TakeFormProps> = ({ match, preview }) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const form_instance_id: any = sessionStorage.getItem('instance');
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);
  //This color is currently hardcoded, but in the future this color may be user defined and pulled from the db.
  const formThemeColor = theme.palette.primary.main;
  const { formId, formInstanceId } = match.params;
  const split = history?.location?.pathname.split('/');
  const path = split && split.length > 0 && split[1];
  const form_id = split && split.length > 1 && split[2];
  const instance_id = split && split.length > 2 && split[3];

  useEffect(() => {
    if (loggedInUser && (path === 'view-form' || path === 'form-preview') && [ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser?.role)) {

    } else if (loggedInUser && path === 'view-form' && ![ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser?.role)) {
      history.push('/422');
    } else if (!loggedInUser && path === 'view-form') {
      history.push('/login');
      sessionStorage.setItem('form_path', history?.location?.pathname)
    }
  }, []);

  const createFormPreviewLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.createFormPreviewLoading
  );
  const getFormInstanceLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.getFormInstanceLoading
  );
  const getFormInstanceError = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayErrors.getFormInstanceError
  );
  const createFormPreviewError = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayErrors.createFormPreviewError
  );
  const getFormToDisplayLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.getFormToDisplayLoading
  );
  const getFormToDisplayError = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayErrors.getFormToDisplayError
  );
  const displayFormInstance = useSelector(
    (state: RootState) => state.DATA_REDUCER.displayFormInstance
  );
  const formStyle: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.formStyle
  );
  //On mount fetch and set form and fields data into displayForm
  useEffect(() => {
    //generate preview and get first question
    if (loggedInUser) {
      if (preview) {
        if ([ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser?.role)) {
          dispatch(patientFormPreviewThunk(path === 'form-preview' ? form_instance_id : instance_id, path === 'form-preview' ? formId : form_id));
        } else {
          dispatch(createFormPreviewThunk(path === 'form-preview' ? formId : form_id));
        }
        //fetch the form data
        dispatch(fetchFormToDisplayByIdThunk(path === 'form-preview' ? formId : form_id));
      } else if (formInstanceId) {
        //fetch non-preview form instance
        !form_instance_id && dispatch(getFormInstanceByIdThunk(formInstanceId));
      } else if (path === 'view-form' && form_id) {
        dispatch(getFormInstanceByIdThunk(form_id));
      }
      if (!formStyle) {
        dispatch(getFormStyleThunk(loggedInUser ? loggedInUser?.id : ''));
      }
    }

    return function CleanUp() {
      dispatch(setDisplayFormInstanceAction(null));
      dispatch(setDisplayFormAction(null));
      dispatch(setLoadingAction('getExitPageLoading', false, 'getExitPageError'));
      dispatch(setErrorAction('getExitPageError', null));
      dispatch(getFormStyleAction(null));
      dispatch(setDisplayFormFieldPayloadAction(null));
      dispatch(setDisplayExitPageAction(null));
    };
  }, []);

  useEffect(() => {
    if (loggedInUser && displayFormInstance) {
      !form_instance_id && dispatch(fetchFormToDisplayByIdThunk(displayFormInstance?.form_id));
    }
  }, [displayFormInstance]);

  const isLoading = getFormInstanceLoading || createFormPreviewLoading || getFormToDisplayLoading;

  const isError = createFormPreviewError || getFormInstanceError || getFormToDisplayError;
  return (
    <Box className={classes.main}>
      {loggedInUser ? <Box className={classes.cardContainer}>
        {isLoading || isError ? (
          <Box p={4}>
            <LoadingOrError
              loading={isLoading}
              loadingMsg={'Loading Questions...'}
              errorMsg={isError}
            />
          </Box>
        ) : (
          <DisplayCard themeColor={formThemeColor} formStyle={formStyle} />
        )}
      </Box> : <Box display='flex' justifyContent='center' alignItems='center'><CircularProgress /></Box>}
    </Box>
  );
};

export const TakeForm = withRouter(TakeFormComponent);

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: theme.background.main,
    minHeight: '100vh'
  },
  topButtons: {
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: theme.spacing(3)
  },
  cardContainer: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    flexDirection: 'column',
    flexGrow: 1
  },
  exitArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  exitText: {
    marginRight: theme.spacing(1)
  }
}));
