import React, { useEffect } from "react";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Line } from "react-chartjs-2";
import moment from 'moment';
import { Typography } from "@material-ui/core";

Chart.register(CategoryScale);

const LineChart = ({ info }: any) => {
  const [labels, setLabels] = React.useState<any>([]);
  const [dataSet, setDataSet] = React.useState<any>([])
  const [isUpdate, setIsUpdate] = React.useState<any>(false);

  useEffect(() => {
    if (info) {
      labels.push(info?.graph_data?.map((item: any) => moment(item?.completed_at_date).format('L')));
      dataSet.push(info?.graph_data?.map((item: any) => item?.score));
      setIsUpdate(!isUpdate);
    }
  }, [])

  const data = {
    labels: labels[0],
    datasets: [
      {
        label: info?.metric_name,
        backgroundColor: "#0060b25e",
        borderColor: "#0060B2",
        data: dataSet[0],
        fill: true,
      },
    ],
  };

  return (
    <div>
      <Line
        data={data}
        options={{
          plugins: {
            title: {
              display: true,
              text: ''
            },
            legend: {
              display: false
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              steps: 5,
              stepValue: 25,
              ticks: {
                stepSize: 25
              },
              min: 0,
              max: 100,
            }
          }
        }} type={null} />
      <Typography color="textSecondary" variant="h4" style={{ textAlign: 'center', marginTop: '10px' }}>Dates Form Taken</Typography>
    </div>
  );
};

export default LineChart;