import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Grid, Divider, TextField, Chip, DialogTitle, ListItemIcon, Checkbox,
  DialogContentText, DialogContent, DialogActions, Button, ListItem, ListItemText, Fade, Paper
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MUIDialog from '@material-ui/core/Dialog';
import { ArrowBack, Add, Clear, Search, StarOutline, Star } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { UuidType } from '../../../shared/domain';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import {
  getAllPrimaryDomain, getAllTags, createStaticResourcesThunk, deleteResourceThunk, createTagThunk, updateStaticResourcesThunk,
  getResourceFilesThunk, addFavoriteResourceThunk, removeFavoriteResourceThunk
} from '../../../redux/thunk/thunks';
import { ConfirmationDialog } from '../../../components/ConfirmationDialog';
import { LoadingButton } from '../../../components/LoadingButton';
import Popper, { PopperPlacementType } from '@material-ui/core/Popper';
import { ConfirmationDelete } from '../../../components/DeleteResource';
import { RootState } from '../../../redux/redux-store';
import { ResourcesFilesTable } from './ResourceFilesTable'
import { url } from '../../../redux/url';
import { addNewTag } from '../../../redux/data/data-actions';

var axios = require("axios");
var FormData = require("form-data");

interface NewResourceDialogProps {
  handleClose: () => void;
  resourceFiles: any,
  isEdit: boolean,
  editInfo: any
}

interface FilmOptionType {
  title: string;
  id: string;
}

interface selectedFile {
  data: any[];
  page: number;
  pageSize: number;
  totalCount: number;
}

export const NewResourceDialog = ({ handleClose, resourceFiles, isEdit, editInfo }: NewResourceDialogProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedResourceFiles, setSelectedResourceFiles] = useState<UuidType[]>([]);
  const [domainList, setDomainList] = useState<any>([]);
  const [selectedDomains, setSelectedDomains] = useState<any>([]);
  const [domain, setDomain] = useState<string[]>([]);
  const [tags, setTags] = useState<any>([]);
  const [tag, setTag] = useState<any>('');
  const [tagId, setTagId] = useState<any>('');
  const [selectedTags, setSelectedTagId] = useState<any>([]);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isView, setIsView] = useState(true);
  const [isDisabled, setDisabled] = useState(false);
  const [isTagDomain, setIsTagDomain] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isFileDelete, setIsFileDelete] = useState(false);
  const [isError, setIsError] = useState<any>(null);
  const [checkError, setCheckError] = useState<any>(null);
  const [isMsg, setIsMsg] = useState<any>('');
  const [isNumber, setIsNumber] = useState<any>();
  const [open, toggleOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isTextError, setTextError] = useState(false);
  const [inputs, setInputs] = useState(null);
  const [openUpload, setOpenUpload] = useState(false);
  const [logo, setLogo] = useState<any>([]);
  const [tagList, setTagList] = useState<any>([]);
  const [selectedFiles, setFiles] = useState<any>([]);
  const [selectedEditFiles, setEditFiles] = useState<selectedFile>({
    data: isEdit ? resourceFiles.data : [],
    page: isEdit ? resourceFiles.page : 0,
    pageSize: isEdit ? resourceFiles.pageSize : 10,
    totalCount: isEdit ? resourceFiles.totalCount : 0
  });
  const [deleteFormsConfirmationDialogOpen, setDeleteFormsConfirmationDialogOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState({
    title: '',
    id: '',
  });
  const [tagValue, setTagValue] = useState('');
  const initialValues: any = {
    title: isEdit ? editInfo.title : '',
    description: isEdit ? editInfo.description : '',
  };
  const [primaryDomains, setPrimaryDomainList] = useState<any>(isEdit ? editInfo?.domains : []);
  const [tempDomain, setTempDomain] = useState<any>([]);
  const [isCheck, setIsCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl1, setAnchorEl1] = React.useState<HTMLButtonElement | null>(null);
  const [open1, setOpen1] = React.useState(false);
  const [placement, setPlacement] = React.useState<PopperPlacementType>();
  const [anchorEl2, setAnchorEl2] = React.useState<HTMLButtonElement | null>(null);
  const [open2, setOpen2] = React.useState(false);
  const [placement2, setPlacement2] = React.useState<PopperPlacementType>();
  const [searchTag, setSearchTag] = useState('');
  const [sortTagList, setSortTagList] = useState<any>();

  const handleClickOpen = (newPlacement: PopperPlacementType) => (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl1(event.currentTarget);
    setOpen1((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const deleteData: any = useSelector((state: RootState) => state.DATA_REDUCER.resourceStatusCount);

  const newlyAddedTag: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.tag
  );
  useEffect(() => {
    if (newlyAddedTag) {
      const isCheckedTag = tags.filter((item: any) => item === newlyAddedTag.id);
      if (isCheckedTag && isCheckedTag.length === 0) {
        tags.push(newlyAddedTag.id);
        setTags(tags);
        setIsAdd(isAdd ? false : true);
      }
    }
  })
  const deleteUserLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.deleteUserLoading
  );
  const getResourceFils: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.resourceFiles
  );
  useEffect(() => {
    if (getResourceFils && getResourceFils.length > 0) {
      setEditFiles(getResourceFils)
    }
  })

  const primaryDomainList: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.primaryDomains
  );
  useEffect(() => {
    if (primaryDomainList) {
      setPrimaryDomainList(primaryDomainList);
    }
  }, [primaryDomainList]);

  useEffect(() => {
    editInfo && setIsFavorite(editInfo.isFavourite);
    dispatch(getAllPrimaryDomain());
    dispatch(getAllTags());
  }, [])

  useEffect(() => {
    if (isEdit) {
      for (let i = 0; i < editInfo?.domains?.length; i++) {
        const ids = editInfo?.domains[i]?.domain_id
        domainList.push(ids);
        domain.push(ids);
        tempDomain.push(ids);
      }
      setDomainList(domainList);
      for (let i = 0; i < editInfo?.tags?.length; i++) {
        const tag = editInfo?.tags[i]?.tag_id
        tags.push(tag);
      }
      setTags(tags);
      for (let i = 0; i < editInfo?.tags?.length; i++) {
        const tag = editInfo?.tags[i]?.tag_id
        selectedTags.push(tag);
      }
      // setSelectedTagId(tags)
    }
  }, [isEdit])

  const tagLists: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.tagList
  );
  useEffect(() => {
    if (tagLists) {
      setTagList(tagLists);
    }
  })
  useEffect(() => {
    if (newlyAddedTag) {
      // selectedTags.push(newlyAddedTag?.id);
      // // setSelectedTagId(selectedTags);
      setSearchTag('')
      setIsAdd(!isAdd);
      dispatch(addNewTag(null));
      setAnchorEl2(null);
      setOpen2(false);
    }
  })

  const handleDeleteResourceConfirmationDialogOpen = () => {
    setDeleteFormsConfirmationDialogOpen(true);
  };
  const handleDeleteResourceConfirmationDialogClose = () => {
    setDeleteFormsConfirmationDialogOpen(false);
  };

  const handleDeleteFilesClose = () => {
    setIsFileDelete(false);
  };

  const handleDeleteResource = async () => {
    const data = {
      resourceIds: [editInfo.id]
    }
    await dispatch(deleteResourceThunk(data));

    setDeleteFormsConfirmationDialogOpen(false);
    handleClose();
  };

  const handelDeleteFiles = () => {
    const arr = selectedEditFiles && selectedEditFiles.data.filter((item: any) => !selectedResourceFiles.includes(item.id));
    const data: any = selectedEditFiles;
    data.data = arr;
    setEditFiles(data);
    setIsFileDelete(false);
    setSelectedResourceFiles([]);
  }

  const loginSchema = Yup.object().shape({
    title: Yup.string().required('Required').max(100, ''),
    description: Yup.string().required('Required').max(500, ''),
  });

  const handleSubmit = async (values: any, actions: any) => {
    setDisabled(true);
    const array = [];
    if (selectedEditFiles && selectedEditFiles.data && selectedEditFiles.data.length > 0) {
      for (let i = 0; i < selectedEditFiles.data.length; i++) {
        const info: any = {
          file_url: selectedEditFiles.data[i].file_url,
          file_name: selectedEditFiles.data[i].file_name,
          format: selectedEditFiles.data[i].format
        }
        array.push(info)
      }
    }
    const data: any = {
      "title": values.title,
      "description": values.description,
      "domains": domainList,
      "tags": tags,
      "files": array
    }
    if (isEdit) { data.resource_id = editInfo.id }
    isEdit ? await dispatch(updateStaticResourcesThunk(data)) : await dispatch(createStaticResourcesThunk(data))
    if (editInfo && editInfo.isFavourite === isFavorite) {
    } else {
      isFavorite ? await dispatch(addFavoriteResourceThunk(editInfo.id)) : isEdit && await dispatch(removeFavoriteResourceThunk([editInfo.id]));
    }
    setDisabled(false);
  }

  const handelOpenTagDomain = (value: number) => {
    setIsTagDomain(true);
    setIsNumber(value);
    value === 1 && setSelectedDomains(domainList);
    value === 1 && setDomain(domainList);
    value === 1 && setTempDomain(domainList);
  }

  const handelClose = async () => {
    setDomainList(tempDomain);
    setDomain([]);
    setSelectedDomains([]);
    setIsTagDomain(false);
    setIsError(null);
    setTag('')
    setTagId('')
    setAnchorEl1(null);
    setOpen1(false);
    setIsNumber(null);
  }

  const handelCloseDialog = () => {
    setDialogValue({
      title: '',
      id: '',
    });
    toggleOpen(false);
  }

  const addDomain = async () => {
    setDomainList(domain);
    setIsError(null)
    setIsTagDomain(false);
    setDomain([]);
    setAnchorEl1(null);
    setOpen1(false);
  }

  const addTag = () => {
    if (selectedTags && selectedTags.length === 11) {
      setIsError(3)
    } else {
      setTags(selectedTags);
    }
    setAnchorEl2(null);
    setOpen2(false);
    setIsTagDomain(false);
  }

  const handleClickTag = (event: React.MouseEvent<HTMLButtonElement>, newPlacement: PopperPlacementType) => {
    setSortTagList(tagLists);
    setAnchorEl2(event.currentTarget);
    setOpen2((prev) => placement2 !== newPlacement || !prev);
    setPlacement2(newPlacement);
  };

  const handleChangeTag = async (e: any) => {
    setSearchTag(e.target.value);
    const filtered = await tagList.filter((str: any) => str?.title.toLowerCase().includes(e.target.value.toLowerCase()));
    setSortTagList(filtered);
    if (e?.target?.value[e?.target?.value?.length - 1] === " ") {
      setOpen2(true);
    }
    if (!open1) {
      setOpen2(true);
    }
    setIsAdd(!isAdd);
  }
  const handleCheckTag = async (value: any, check: boolean) => {
    if (selectedTags?.length > 10 && !check) { setIsError(3) } else if (!check) {
      selectedTags.push(value);
      // setSelectedTagId(selectedTags);
      setIsCheck(!isCheck);
      setIsError(null);
    } else {
      const array = await selectedTags.filter((id: any) => id !== value);
      setSelectedTagId(array);
      setIsCheck(!isCheck);
      setIsError(null);
    }
  };

  const saveNewTag = async () => {
    if (tagValue.trim() === '') {

    } else {
      setIsLoading(true);
      await dispatch(createTagThunk(tagValue))
      handelCloseDialog();
      setTagValue('');
      setIsLoading(false);
    }
  }

  const _getHeaders = (inputHeaders?: { [key: string]: string }) => {
    let headers = inputHeaders || {};
    let token = localStorage.getItem('token');
    headers["Accept"] = "application/json";
    const headersWithAuth = token
      ? {
        ...headers,
        Authorization: `Bearer ${token}`,
      }
      : headers;
    return headersWithAuth;
  };

  const handelCloseUpload = () => {
    setOpenUpload(false);
    setCheckError(false);
    setFiles([]);
  }

  const addMultiFiles = (e: any) => {
    setCheckError(false)
    for (let i = 0; i < e.length; i++) {
      const size = e[i].size / 1024 / 1024;
      const convertSize: any = parseInt(size.toFixed(0));
      if (!e[i].name.match(/\.(jpg|jpeg|png|gif|ppt|doc|docx|pdf|tiff)$/)) {
        setError(true);
        setIsMsg('Please check file type')
      } else {
        if (convertSize > 100) { setError(true); setIsMsg('Please check file size') } else {
          selectedFiles.push(e[i])
          setError(false);
          setIsMsg('');
        }
      }
    }
    setFiles(selectedFiles)
    setIsAdd(isAdd ? false : true)
  }

  const handleRemoveEvent = (event: any) => {
    const { target = {} } = event || {};
    target.value = "";
  }

  const removeFile = async (index: any) => {
    const files = selectedFiles.filter((e: any, i: number) => i !== index);
    setFiles(files)
    setIsAdd(isAdd ? false : true);
    if (files && files.length === 0) {
      await setOpenUpload(false);
      setOpenUpload(true);
    }
  }

  const handleChangeDomain = async (value: any, check: boolean) => {
    if (domain?.length > 2 && !check) { setIsError(3) } else if (!check) {
      await domain.push(value);
      setIsError(null); setIsAdd(!isAdd);
    } else {
      const array = await domain.filter((id: any) => id !== value);
      setDomain(array);
      const array1 = await selectedDomains.filter((id: any) => id !== value);
      setSelectedDomains(array1);
      setIsError(null); setIsAdd(!isAdd);
    }
  };

  const removeSelectedDomains = (id: any) => {
    const ids = selectedDomains.filter((e: any) => e !== id);
    const ids1 = domain.filter((e: any) => e !== id);
    setSelectedDomains(ids)
    setDomain(ids1)
    setIsAdd(!isAdd);
    setIsError(null);
  }

  const removeDomains = (id: any) => {
    const ids = domainList.filter((e: any) => e !== id);
    setDomainList(ids)
    setIsAdd(!isAdd);
    setIsError(null);
  }

  const removeTag = (id: any) => {
    const tag = tags.filter((e: any) => e !== id);
    setTags(tag)
    console.log('check2')
    setIsAdd(isAdd ? false : true)
  }

  const uploadProfile = () => {
    if (selectedFiles.length > 0) {
      setLoading(true);
      var data = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        data.append("input_files", selectedFiles[i]);
      }
      var config = {
        method: 'post',
        url: `${url}/user/auth/uploadMultipleFilesOnS3/${selectedFiles.length}`,
        headers: _getHeaders({ "Content-Type": "multipart/form-data" }),
        data: data,
      };
      axios(config)
        .then(function (response: any) {
          setLogo(response.data.url);

          for (let i = 0; i < selectedFiles.length; i++) {
            const type = selectedFiles[i].type.split('/');
            const info: any = {
              id: selectedFiles[i].name,
              file_url: response.data.url[i],
              file_name: selectedFiles[i].name,
              format: type.length === 2 ? type[1] : selectedFiles[i].type
            }
            selectedEditFiles.data.push(info)
          }
          selectedEditFiles.page = 0;
          selectedEditFiles.pageSize = 10;
          selectedEditFiles.totalCount = selectedEditFiles.data.length;
          setEditFiles(selectedEditFiles);
          setLoading(false);
          setOpenUpload(false)
          setIsView(false);
          setFiles([])
        })
        .catch(function (error: any) {
          setLoading(false);
        });
    } else {
      setCheckError(true);
    }
  };

  const handleSearch = (value: any) => {
    if (editInfo) {
      dispatch(
        getResourceFilesThunk({
          resourceId: editInfo.id,
          order: 'DESC',
          page: 0,
          pageSize: 10,
          search: value
        })
      );
    }
  };

  const handleNextButton = async () => {
    if (
      selectedEditFiles &&
      selectedEditFiles.page >= 0 &&
      selectedEditFiles.page < selectedEditFiles.totalCount / selectedEditFiles.pageSize - 1
    ) {
      dispatch(
        getResourceFilesThunk({
          resourceId: editInfo.id,
          order: 'DESC',
          page: selectedEditFiles.page + 1,
          pageSize: 10,
          search: ''
        })
      );
    }
  };
  const handlePrevButton = async () => {
    if (selectedEditFiles && selectedEditFiles.page > 0) {
      dispatch(
        getResourceFilesThunk({
          resourceId: editInfo.id,
          order: 'DESC',
          page: selectedEditFiles.page - 1,
          pageSize: 10,
          search: ''
        })
      );
    }
  };

  const handelFavoriteResource = (value: boolean) => {
    setIsFavorite(value);
  }

  const handleChanges = (value: any, no: any) => {
    if (no === 0 && value.length > 100) {
      setTextError(true); setInputs(no);
    } else if (no === 1 && value.length > 500) {
      setTextError(true); setInputs(no);
    } else {
      setTextError(false); setInputs(no);
    }
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box pt={3} px={3}>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={loginSchema}
        >
          {({ errors, touched, values }: any) => {

            return (
              <Form>
                <Grid container>
                  <Grid item xs={12} sm={9} md={10} style={{ display: 'flex' }}>
                    <Grid container style={{ marginRight: '15px' }}>
                      <Grid item xs={2} sm={1} md={1} style={{ display: 'flex' }}>
                        <ArrowBack style={{ fontSize: '30px', color: 'gray', cursor: 'pointer' }} onClick={handleClose} />
                        <Divider orientation="vertical" style={{ margin: '0px 40px 0px 10px', height: '35px' }} />
                      </Grid>
                      <Grid item xs={10} sm={11} md={11}>
                        <Box pb={2.5}>
                          <Box display='flex' alignItems="center" pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              Resource Title
                            </Typography>
                            {isEdit && (isFavorite ? <Star color='primary' style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => handelFavoriteResource(false)} /> : <StarOutline color='primary' style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => handelFavoriteResource(true)} />)}
                          </Box>

                          <Field fullWidth style={{ background: isTextError && inputs === 0 ? '#fff1f1' : 'unset' }}
                            onInput={(e: any) => handleChanges(e.target.value, 0)}
                            as={TextField}
                            inputProps={{ maxLength: 101 }}
                            className={classes.textField}
                            name="title"
                            size="small"
                            variant="outlined"
                          />
                          <Box display='flex' justifyContent='space-between'>
                            <Typography color={"error"} variant="subtitle2">
                              {errors.title && touched.title && errors.title}
                            </Typography>
                            <Typography style={{ color: values.title?.length > 100 ? '#911717' : '#0000008a' }} variant="subtitle2">
                              {values.title?.length === 101 ? 100 : values.title?.length}/100
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box pb={2.5}>
                          <Box pb={0.75}>
                            <Typography color="textSecondary" variant="subtitle2">
                              Resource Description
                            </Typography>
                          </Box>

                          <Field fullWidth multiline rows={4} style={{ background: isTextError && inputs === 1 ? '#fff1f1' : 'unset' }}
                            onInput={(e: any) => handleChanges(e.target.value, 1)}
                            as={TextField}
                            inputProps={{ maxLength: 501 }}
                            className={classes.textField}
                            name="description"
                            size="small"
                            variant="outlined"
                          />
                          <Box display='flex' justifyContent='space-between'>
                            <Typography color={"error"} variant="subtitle2">
                              {errors.description && touched.description && errors.description}
                            </Typography>
                            <Typography style={{ color: values.description?.length > 500 ? '#911717' : '#0000008a' }} variant="subtitle2">
                              {values.description?.length === 501 ? 500 : values.description?.length}/500
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={3} md={2} style={{ textAlign: 'right', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                    <Box mt={4}>
                      <LoadingButton
                        className={classes.primaryButton}
                        color="primary"
                        disabled={isDisabled}
                        loading={isDisabled}
                        type="submit"
                        variant="contained"
                        aria-label="sign in"
                      >
                        Save
                      </LoadingButton>
                    </Box>
                    <Box>
                      <LoadingButton
                        className={classes.secondaryButton}
                        onClick={handleClose}
                        variant="contained"
                      >
                        Cancel
                      </LoadingButton>
                    </Box>
                    {isEdit && <Box pb={1} ml={7}>
                      <Divider />
                    </Box>}
                    {isEdit && <Box mt={1}>
                      <LoadingButton
                        className={classes.deleteButton}
                        variant="contained"
                        onClick={(e) => handleDeleteResourceConfirmationDialogOpen()}
                      >
                        Delete
                      </LoadingButton>
                    </Box>}
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Box>
      <Divider variant="middle" />
      <Box
        className={classes.topBox}
        px={3}
        py={2.5}
      >
        <Typography variant="h4" style={{ fontWeight: 500 }}>Primary Domains</Typography>
        <Box pt={2} display='flex'>
          <div className={classes.otherActions}>
            {domainList && domainList.map((item: any) => {
              let info = primaryDomains?.filter((e: any) => e.id === item);
              return info?.length > 0 ?
                <Chip
                  key={''}
                  classes={{ root: classes.collectionChip }
                  }
                  size="small"
                  label={
                    < div className={classes.chipAlign}>
                      {info[0].title ? info[0].title : info[0].domain_name}
                      <Clear className={classes.clearIcon} onClick={(e) => removeDomains(info[0].id)} />
                    </div>
                  }
                /> : ''
            })}
          </div>
          <Add style={{ background: '#f1f5f9', padding: '2px 5px', borderRadius: '10px', cursor: 'pointer' }} onClick={(e) => handelOpenTagDomain(1)} />
        </Box>
      </Box>
      <Divider variant="middle" />
      <Box
        className={classes.bottomBox}
        px={3}
        py={2}
      >
        <Typography variant="h4" style={{ fontWeight: 500 }}>Tags</Typography>
        <Box pt={2} display='flex'>
          <div className={classes.otherActions}>
            {tags && tags.map((item: any) => {
              let info = tagList.filter((e: any) => e.id === item);
              return info?.length > 0 ?
                <Chip
                  key={''}
                  classes={{ root: classes.collectionChip }
                  }
                  size="small"
                  label={
                    <div className={classes.chipAlign}>
                      {info[0].title ? info[0].title : info[0].tag_name}
                      <Clear className={classes.clearIcon} onClick={(e) => removeTag(info[0].id)} />
                    </div>
                  }
                /> : ''
            })}
          </div>
          <Add style={{ background: '#f1f5f9', padding: '2px 5px', borderRadius: '10px', cursor: 'pointer' }} onClick={(e) => handelOpenTagDomain(0)} />
        </Box>
      </Box>
      <Divider variant="middle" />
      <Box display="flex" justifyContent="space-between" alignItems='center' px={3} py={2} className={classes.contentContainer}>
        <Box display="flex" alignItems='end' className={classes.contentContainer}>
          <Box mr={5}>
            <Typography variant="h4" style={{ fontWeight: 500, color: 'gray', marginBottom: '6px' }}>FILES IN</Typography>
            <Typography variant="h2">Mental Health Resource</Typography>
          </Box>
          <Box mt={1}>
            <TextField className={classes.searchTextField}
              inputProps={{
                maxLength: 150,
              }}
              InputProps={{
                startAdornment: (
                  <Box ml={-0.5} mr={1} mt={0.5}>
                    <Search className={classes.searchIcon} color="disabled" />
                  </Box>
                )
              }} size='small' variant='outlined' placeholder='Search...' onChange={(e) => handleSearch(e.target.value)} />
          </Box>
        </Box>
        <Box display='flex' alignItems='center' mt={1}>
          {selectedResourceFiles && selectedResourceFiles.length > 0 &&
            <> <Typography style={{ color: '#575757', fontWeight: 400, fontSize: '13px' }}>{selectedResourceFiles.length} Selected</Typography>
              <Divider orientation="vertical" style={{ margin: '0px 20px 0px 20px', height: '30px' }} />
              <LoadingButton style={{ marginBottom: '0px' }}
                className={classes.deleteButton}
                variant="contained" color="secondary"
                onClick={(e) => setIsFileDelete(true)}
              >
                Delete
              </LoadingButton>
            </>
          }
        </Box>
        {selectedResourceFiles && selectedResourceFiles.length === 0 &&
          <Box mt={1}>
            <LoadingButton style={{ marginBottom: '0px' }}
              className={classes.primaryButton}
              color="primary"
              variant="contained"
              onClick={(e) => setOpenUpload(true)}
            >
              Add Files
            </LoadingButton>
          </Box>
        }
      </Box>
      <Box pb={2} px={4}>
        <ResourcesFilesTable resourceFiles={selectedEditFiles} selectedResourceFiles={selectedResourceFiles} setSelectedResourceFiles={setSelectedResourceFiles} isView={isView} handleNextButton={handleNextButton} handlePrevButton={handlePrevButton} />
      </Box>

      {isTagDomain && <MUIDialog open={isTagDomain} classes={{ paper: classes.paper }}>
        <Box style={{ background: '#F9FAFB', borderRadius: '0.625rem 0.625rem 0 0' }}>
          <Box px={4} py={3}>
            <Typography color="textSecondary" variant="h2">
              {isNumber === 1 ? 'Select Primary Domain' : 'Select Tags'}
            </Typography>
          </Box>
        </Box>
        <Grid container className={classes.box} spacing={2}>
          <Grid item xs={12} md={6}>
            {isNumber === 1 ?
              <Box pb={2.5}>
                <Box pb={0.75}>
                  <Typography color="textSecondary" variant="subtitle2">
                    Select Primary Domains
                  </Typography>
                </Box>
                <LoadingButton onClick={handleClickOpen('bottom')} style={{ display: 'flex', justifyContent: 'flex-start', overflow: 'hidden', width: '-webkit-fill-available', height: '35px', border: '1px solid #c1c1c1' }}>
                  {domain && domain.map((id: any) => {
                    const info = primaryDomains.filter((item: any) => item.id === id);
                    return (
                      <Chip key={info[0]?.id} label={info[0]?.title} className={classes.chip} />
                    )
                  })}
                </LoadingButton>
                <Popper open={open1} anchorEl={anchorEl1} placement={'bottom'} transition style={{ zIndex: 2000 }}>
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper style={{ maxHeight: '300px', minHeight: '200px', minWidth: '300px', maxWidth: '330px', overflow: 'auto' }}>
                        {primaryDomains && primaryDomains.map((item: any) => {
                          const checked = domain.filter((id: any) => id === item.id);
                          return (
                            <ListItem key={item.id} role={undefined} dense button onClick={(e) => handleChangeDomain(item.id, checked?.length > 0 ? true : false)}>
                              <ListItemIcon style={{ minWidth: 'auto' }}>
                                <Checkbox color='primary'
                                  edge="start"
                                  checked={checked?.length > 0 ? true : false}
                                />
                              </ListItemIcon>
                              <ListItemText id={item.id} primary={<Typography color="textSecondary" variant="subtitle2">{item.title}</Typography>} />
                            </ListItem>
                          )
                        })}
                      </Paper>
                    </Fade>
                  )}
                </Popper>
                {isError ? (
                  <Typography color="error" variant="subtitle2">
                    {isError === 1 ? 'Domain already selected' : (isError === 3 ? 'Add only 3 domains' : 'Please select domain')}
                  </Typography>
                ) : null}
              </Box>
              :
              <Box pb={2.5} mr={2}>
                <Box pb={0.75}>
                  <Typography color="textSecondary" variant="subtitle2">
                    Select Tag / Add Tag
                  </Typography>
                </Box>
                <button style={{ background: 'transparent', border: 'unset', width: '100%', padding: '0px', margin: '0px' }} onClick={(e: any) => handleClickTag(e, 'bottom')}><TextField id="searchTag" variant="outlined" size='small' value={searchTag} fullWidth onInput={(e) => handleChangeTag(e)} /></button>
                <Popper open={open2} anchorEl={anchorEl2} placement={'bottom'} transition style={{ zIndex: 2000 }}>
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper style={{ maxHeight: '300px', minHeight: '200px', minWidth: '370px', maxWidth: '370px', overflow: 'auto' }}>
                        {sortTagList && sortTagList.length > 0 ? sortTagList.map((item: any) => {
                          const checked = selectedTags.filter((id: any) => id === item.id);
                          return (
                            <ListItem key={item.id} role={undefined} dense button onClick={() => handleCheckTag(item.id, checked?.length > 0 ? true : false)}>
                              <ListItemIcon style={{ minWidth: 'auto' }}>
                                <Checkbox color='primary'
                                  edge="start"
                                  checked={checked?.length > 0 ? true : false}
                                />
                              </ListItemIcon>
                              <ListItemText id={item.id} primary={<Typography color="textSecondary" variant="subtitle2">{item.title}</Typography>} />
                            </ListItem>
                          )
                        }) :
                          <LoadingButton
                            className={classes.secondaryButton}
                            variant="contained"
                            style={{ margin: '10px' }}
                            onClick={() => { toggleOpen(true); setOpen2(false); setAnchorEl2(null); setTagValue(searchTag) }}
                          >
                            Add New Tag: {searchTag}
                          </LoadingButton>
                        }
                      </Paper>
                    </Fade>
                  )}
                </Popper>
                {isError ? (
                  <Typography color="error" variant="subtitle2">
                    {isError === 1 ? 'Tag already selected' : (isError === 3 ? 'Add only 10 tags' : 'Please select tag')}
                  </Typography>
                ) : null}
              </Box>
            }
          </Grid>
          <Grid item xs={12} md={6}>
            <Box pb={0.5}>
              <Typography color="textSecondary" variant="h4">
                {isNumber === 1 ? 'Current Primary Domain' : 'Current Tags'}
              </Typography>
            </Box>
            {isNumber === 1 ?
              <div className={classes.otherActions}>
                {selectedDomains && selectedDomains.map((item: any) => {
                  let info = primaryDomains?.filter((e: any) => e.id === item);
                  return info.length > 0 ?
                    <Chip
                      key={''}
                      classes={{ root: classes.collectionChip }
                      }
                      size="small"
                      label={
                        < div className={classes.chipAlign}>
                          {info[0].title ? info[0].title : info[0].domain_name}
                          <Clear className={classes.clearIcon} onClick={(e) => removeSelectedDomains(info[0].id)} />
                        </div>
                      }
                    /> : ''
                })}
              </div> :
              <div className={classes.otherActions}>
                {tags && tags.map((item: any) => {
                  let info = tagList.filter((e: any) => e.id === item);
                  return info.length > 0 ?
                    <Chip
                      key={''}
                      classes={{ root: classes.collectionChip }
                      }
                      size="small"
                      label={
                        <div className={classes.chipAlign}>
                          {info[0].title ? info[0].title : info[0].tag_name}
                          <Clear className={classes.clearIcon} onClick={(e) => removeTag(info[0].id)} />
                        </div>
                      }
                    /> : ''
                })}
              </div>
            }
          </Grid>
        </Grid>
        <Box mt={2} px={4} py={2} style={{ background: '#F9FAFB', borderRadius: '0 0 0.625rem 0.625rem' }}>
          <Grid container>
            <Grid item xs={6} style={{ textAlign: 'left' }}>
              <LoadingButton
                className={classes.secondaryButton}
                variant="contained"
                style={{ marginBottom: '0px' }}
                onClick={handelClose}
              >
                Cancel
              </LoadingButton>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <LoadingButton color="primary"
                className={classes.primaryButton}
                onClick={(e) => { isNumber === 1 ? addDomain() : addTag() }}
                variant="contained"
                style={{ marginBottom: '0px' }}
              >
                Save
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
        {/* <Box display='flex' flexDirection={isNumber === 0 ? 'row-reverse' : 'column'} className={classes.box}>
          <Box pb={5} style={{ width: 'inherit' }}>
            <Box pb={0.5}>
              <Typography color="textSecondary" variant="h4">
                {isNumber === 1 ? 'Current Primary Domain' : 'Current Tags'}
              </Typography>
            </Box>
            {isNumber === 1 ?
              <div className={classes.otherActions}>
                {selectedDomains && selectedDomains.map((item: any) => {
                  let info = primaryDomainList?.filter((e: any) => e.id === item);
                  return info?.length > 0 ?
                    <Chip
                      key={''}
                      classes={{ root: classes.collectionChip }
                      }
                      size="small"
                      label={
                        < div className={classes.chipAlign}>
                          {info[0].title ? info[0].title : info[0].domain_name}
                          <Clear className={classes.clearIcon} onClick={(e) => removeSelectedDomains(info[0].id)} />
                        </div>
                      }
                    /> : ''
                })}
              </div> :
              <div className={classes.otherActions}>
                {tags && tags.map((item: any) => {
                  let info = tagList.filter((e: any) => e.id === item);
                  return info?.length > 0 ?
                    <Chip
                      key={''}
                      classes={{ root: classes.collectionChip }
                      }
                      size="small"
                      // onClick={() => unassignCollection()}
                      label={
                        <div className={classes.chipAlign}>
                          {info[0].title ? info[0].title : info[0].tag_name}
                          <Clear className={classes.clearIcon} onClick={(e) => removeTag(info[0].id)} />
                        </div>
                      }
                    /> : ''
                })}
              </div>
            }
          </Box>
          <Box style={{ width: 'inherit' }}>
            {isNumber === 1 ?
              <Box pb={2.5}>
                <Box pb={0.75}>
                  <Typography color="textSecondary" variant="subtitle2">
                    Select Primary Domains
                  </Typography>
                </Box>

                <LoadingButton onClick={handleClickOpen('right')} style={{ display: 'flex', justifyContent: 'flex-start', overflow: 'hidden', width: '-webkit-fill-available', height: '35px', border: '1px solid #c1c1c1' }}>
                  {domain && domain.map((id: any) => {
                    const info = primaryDomainList?.filter((item: any) => item.id === id);
                    return (
                      <Chip key={info[0]?.id} label={info[0]?.title} className={classes.chip} />
                    )
                  })}
                </LoadingButton>
                <Popper open={open1} anchorEl={anchorEl1} placement={'right'} transition style={{ zIndex: 2000 }}>
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper style={{ maxHeight: '540px', minHeight: '200px', overflow: 'auto' }}>
                        {primaryDomainList && primaryDomainList.map((item: any) => {
                          const checked = domain.filter((id: any) => id === item.id);
                          return (
                            <ListItem onClick={(e) => handleChange(item.id, checked?.length > 0 ? true : false)}>
                              <ListItemIcon>
                                <Checkbox color='primary'
                                  edge="start"
                                  checked={checked?.length > 0 ? true : false}
                                />
                              </ListItemIcon>
                              <ListItemText id={item.id} primary={<Typography color="textSecondary" variant="subtitle2">{item.title}</Typography>} />
                            </ListItem>
                          )
                        })}
                      </Paper>
                    </Fade>
                  )}
                </Popper>
                {isError ? (
                  <Typography color="error" variant="subtitle2">
                    {isError === 1 ? 'Domain already selected' : (isError === 3 ? 'Add only 3 domains' : 'Please select domain')}
                  </Typography>
                ) : null}
              </Box>
              :
              <Box pb={2.5} mr={2}>
                <Box pb={0.75}>
                  <Typography color="textSecondary" variant="subtitle2">
                    Select Tag / Add Tag
                  </Typography>
                </Box>

                <FormControl fullWidth size="small">
                  <Autocomplete fullWidth
                    value={tag}
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {
                        // timeout to avoid instant validation of the dialog's form.
                        setTimeout(() => {
                          toggleOpen(true);
                          setDialogValue({
                            title: newValue,
                            id: '',
                          });
                        });
                      } else if (newValue && newValue.id === 'true') {
                        toggleOpen(true);
                        setDialogValue({
                          title: newValue.inputValue,
                          id: '',
                        });
                      } else {
                        handleSetTag(newValue);
                      }
                    }}
                    filterOptions={(options, params: any) => {
                      const filtered = filter(options, params) as FilmOptionType[];

                      if (params.inputValue !== '') {
                        filtered.push({
                          id: 'true',
                          title: `Add "${params.inputValue}"`,
                        });
                        setTagValue(params.inputValue)
                      }

                      return filtered;
                    }}
                    id="free-solo-dialog-demo"
                    options={tagList}
                    getOptionLabel={(option) => {
                      // e.g value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.title) {
                        return option.title;
                      }
                      return option.title;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    renderOption={(option) => option.title}
                    freeSolo
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" size="small" fullWidth placeholder='Type tag to create' />
                    )}
                  />
                </FormControl>
                {isError ? (
                  <Typography color="error" variant="subtitle2">
                    {isError === 1 ? 'Tag already selected' : (isError === 3 ? 'Add only 10 tags' : 'Please select tag')}
                  </Typography>
                ) : null}
              </Box>
            }
          </Box>
        </Box> */}
      </MUIDialog>
      }
      {
        <MUIDialog open={open}>
          <DialogTitle id="form-dialog-title">Add a new tag</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Did you miss any tag in tag list? Please, add it!
            </DialogContentText>
            <Box pb={2.5}>
              <Box pb={0.75}>
                <Typography color="textSecondary" variant="subtitle2">
                  Tag
                </Typography>
              </Box>
              <TextField fullWidth variant='outlined' size='small'
                autoFocus
                value={tagValue}
                onChange={(event) => setTagValue(event.target.value)}
                type="text"
              />
            </Box>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-around' }}>
            <LoadingButton
              className={classes.secondaryButton}
              variant="contained"
              style={{ marginBottom: '0px' }}
              onClick={handelCloseDialog}
            >
              Cancel
            </LoadingButton>
            <LoadingButton color="primary"
              className={classes.primaryButton}
              variant="contained"
              style={{ marginBottom: '0px' }}
              onClick={(e) => saveNewTag()}
            >
              Add
            </LoadingButton>
          </DialogActions>
        </MUIDialog>
      }
      {openUpload &&
        <MUIDialog open={openUpload} >
          <DialogTitle style={{ paddingBottom: '0px' }}>Add Files to Resource</DialogTitle>
          <DialogContent>
            <h6 style={{ fontSize: '11px', color: '#a5a5a5', fontWeight: 400, margin: '-10px 0px 10px' }}>Supported formats: .DOC, .PDF, .PPT, .JPEG, .GIF, .TIFF, .PNG</h6>
            <h6 style={{ fontSize: '11px', color: '#a5a5a5', fontWeight: 400, margin: '-10px 0px 40px' }}>Maximum size 100MB per file</h6>
            <Box>
              <div>
                <input
                  accept=".gif, .jpeg, image/*, .tiff, .doc, .docx, .docx, .ppt, .pptx, .txt, .pdf"
                  className={classes.input}
                  id="button-file"
                  onChange={(e) => addMultiFiles(e.target.files)} onClick={handleRemoveEvent}
                  type="file"
                  multiple
                />
                <label htmlFor="button-file">
                  <Button variant="contained" component="span" color='primary' style={{ width: '100%' }} className={classes.primaryButton}>
                    Upload File(s)
                  </Button>
                </label>
              </div>
              {error ? (
                <Typography color="error" variant="subtitle2" style={{ marginBottom: '10px' }}>
                  {isMsg}
                </Typography>
              ) : null}
            </Box>
            <Box pb={2.5}>
              <Box className={classes.paperBox}>
                {selectedFiles && !selectedFiles.data && selectedFiles.map((file: any, i: number) => {
                  return (<ListItem style={{ padding: '0px 16px 8px' }}>
                    <ListItemText style={{ margin: '0px' }} primary={file.name} secondary={<span style={{ color: '#c50000', cursor: 'pointer' }} onClick={(e) => removeFile(i)}>Remove</span>} />
                  </ListItem>
                  )
                })}
              </Box>
            </Box>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between', padding: '0px 22px 20px' }}>
            <LoadingButton
              className={classes.secondaryButton}
              variant="contained"
              style={{ marginBottom: '0px' }}
              onClick={handelCloseUpload}
            >
              Cancel
            </LoadingButton>
            <LoadingButton color="primary"
              className={classes.primaryButton}
              variant="contained"
              disabled={loading}
              loading={loading}
              style={{ marginBottom: '0px' }}
              onClick={(e) => uploadProfile()}
            >
              Add
            </LoadingButton>
          </DialogActions>
          {checkError && selectedFiles.length === 0 ? (
            <Typography color="error" variant="subtitle2" style={{ marginBottom: '10px', textAlign: 'center' }}>
              Please upload a file!
            </Typography>
          ) : null}
        </MUIDialog>
      }
      {deleteFormsConfirmationDialogOpen &&
        <ConfirmationDelete
          loading={deleteUserLoading}
          data={deleteData}
          handleClose={handleDeleteResourceConfirmationDialogClose}
          handleConfirmClick={() => handleDeleteResource()}
        />
      }
      {isFileDelete &&
        <ConfirmationDialog
          loading={deleteUserLoading}
          message={`Are you sure you want to delete? Please type "DELETE" to confirm.`}
          open={isFileDelete}
          title=""
          handleClose={handleDeleteFilesClose}
          handleConfirmClick={() => handelDeleteFiles()}
        />
      }
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  paperBox: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    height: '180px',
    width: '100%',
    borderRadius: '10px',
    background: '#F1F5F9',
    overflow: 'auto'
  },
  paper: { minWidth: "850px", overflowX: 'hidden' },
  topBox: {
    borderRadius: '0.625rem 0.625rem 0 0'
  },
  bottomBox: {
    borderRadius: '0 0 0.625rem 0.625rem'
  },
  textField: {
    width: '100%'
  },
  primaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: '#fff',
    minWidth: '120px',
    marginBottom: '20px'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: '#0060B2',
    marginBottom: '20px',
    minWidth: '120px',
    background: '#fff'
  },
  deleteButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: 'red',
    minWidth: '120px',
    background: '#fff'
  },
  createCollectionTextField: {
    maxWidth: '40rem',
    width: '80vw'
  },
  box: {
    padding: '30px',
    width: '-webkit-fill-available'
  },
  chipAlign: {
    display: 'flex',
    alignItems: 'center'
  },
  otherActions: {
    marginLeft: '.6rem',
    alignItems: 'center'
  },
  collectionChip: {
    marginRight: theme.spacing(1),
    marginBottom: '4px',
    backgroundColor: theme.background.main
  },
  closeBtnText: {
    color: theme.palette.text.secondary
  },
  clearIcon: {
    fontSize: 14,
    marginLeft: '0.25rem',
    cursor: 'pointer'
  },
  textField1: {
    width: '100%',
    height: '35px',
    padding: '0px'
  },
  input: {
    display: 'none'
  },
  searchTextField: {
    backgroundColor: theme.palette.secondary.main,
    fontSize: theme.typography.h5.fontSize,
    height: '2rem',
    width: '14rem'
  },
  searchIcon: {
    fontSize: 17
  },
  chips: {
    textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden',

  },
  chip: {
    margin: 2, height: '25px', backgroundColor: theme.background.main
  },
  contentContainer: {
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  }
}));
