import React from 'react';

import { Box } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';

import { CollectionDetail } from './components/CollectionDetail';
import { SideBar } from '../../../components/SideBar';
import { TopBar } from '../../../components/TopBar';
import { colors } from '../../../styling/colors';

export const CollectionDetailPage = () => {
  const classes = useStyles();

  return (
    <Box className={classes.main} display="flex" flexDirection="column" p={4}>
      <Box mb={4}>
        <TopBar />
      </Box>

      <Box className={classes.contentContainer} display="flex">
        <SideBar />

        <Box display="flex" flexDirection="column" width="100%">
          <Box pb={5}>
            <CollectionDetail />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    [theme.breakpoints.down('xs')]: {
      padding: '1rem'
    },
    backgroundColor: colors.background1,
    minHeight: '100vh'
  },
  contentContainer: {
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  }
}));
