import { Box, Grid, Paper, TextField, Typography, CircularProgress, InputAdornment, IconButton } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Checkbox } from '../../components/Checkbox';
import { LoadingButton } from '../../components/LoadingButton';
import { Logo } from '../../components/Logo';
import { setErrorAction } from '../../redux/display/display-actions';
import { RootState } from '../../redux/redux-store';
import { loginThunk } from '../../redux/thunk/thunks';
import { colors } from '../../styling/colors';
import img from '../../styling/key.png';
import { ROLES } from '../../shared/globals';

interface FormValues {
  email: string;
  password: string;
  remember: boolean;
}

export const Login = () => {
  const getInfo: any = localStorage.getItem('remember');
  const getLoginInfo = JSON.parse(getInfo);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [check, setCheck] = useState(false);
  const user: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(user);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  useEffect(() => {
    const token: any = localStorage.getItem('token');
    if (loggedInUser && token) {
      if ([ROLES.PATIENT, ROLES.BUILDER].includes(loggedInUser.role)) {
        history.push(`/forms`);
      } else if (loggedInUser.role === ROLES.CLINICIAN) {
        history.push(`/forms-collections`);
      } else if (loggedInUser.role === ROLES.REFERRAL) {
        history.push(`/referral-profile`);
      } else {
        history.push(`/forms`);
      }
    }
  }, [])

  useEffect(() => {
    const newDate: any = new Date();
    if (getLoginInfo) {
      const date2: any = new Date(getLoginInfo);
      const diffTime = Math.abs(date2 - newDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 7) {
        setCheck(true);
      } else {
        const token: any = localStorage.getItem('token');
        if (loggedInUser && token) {
          if ([ROLES.PATIENT, ROLES.BUILDER].includes(loggedInUser.role)) {
            history.push(`/forms`);
          } else if (loggedInUser.role === ROLES.CLINICIAN) {
            history.push(`/forms-collections`);
          } else if (loggedInUser.role === ROLES.REFERRAL) {
            history.push(`/referral-profile`);
          } else {
            history.push(`/forms`);
          }
        }
      }
    } else {
      setCheck(true);
    }
  }, [])

  const loginError = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayErrors.loginError
  );

  const handleSubmit = async (values: any, actions: any) => {
    actions.setSubmitting(true);
    dispatch(setErrorAction('loginError', null));
    await dispatch(loginThunk(values.email.trim(), values.password.trim()));
    actions.setSubmitting(false);
    if (values.remember) {
      let date = new Date();
      localStorage.setItem('remember', JSON.stringify(date));
    }
  };

  const initialValues: FormValues = {
    email: '',
    password: '',
    remember: false
  };

  const loginSchema = Yup.object().shape({
    email: Yup.string().email('Email is invalid').required('Required'),
    password: Yup.string().required('Please Enter your password')
  });

  const handelSSO = () => {
    history.push('/choose-organization');
  }

  return (
    <Grid alignItems="center" className={classes.main} container justify="center" spacing={0}>
      {check ?
        <Grid>
          <Box mb={4} ml={4}>
            <Logo />
          </Box>

          <Paper className={classes.loginPaper}>
            <Box p={4.5}>
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={loginSchema}
              >
                {({ errors, isSubmitting, setFieldValue, setSubmitting, touched, values }) => {

                  return (
                    <Form>
                      <Box pb={2.5}>
                        <Typography color="textSecondary" variant="h1">
                          Sign in to FormFlash
                        </Typography>
                      </Box>

                      <Box pb={2.5}>
                        <Box pb={0.75}>
                          <Typography color="textSecondary" variant="subtitle2">
                            Email Address
                          </Typography>
                        </Box>

                        <Field
                          as={TextField}
                          className={classes.textField}
                          name="email"
                          placeholder="your@email.com"
                          size="small"
                          variant="outlined"
                        />

                        {errors.email && touched.email ? (
                          <Typography color="error" variant="subtitle2">
                            {errors.email}
                          </Typography>
                        ) : null}
                      </Box>

                      <Box pb={2.5}>
                        <Box pb={0.75}>
                          <Grid alignItems="flex-end" container justify="space-between">
                            <Typography color="textSecondary" variant="subtitle2">
                              Password
                            </Typography>

                            <Link className={classes.link} to="/forgot-password">
                              <Typography color="primary" variant="body1">
                                I forgot my password
                              </Typography>
                            </Link>
                          </Grid>
                        </Box>

                        <Field
                          as={TextField}
                          className={classes.textField}
                          name="password"
                          size="small"
                          variant="outlined"
                          autoComplete='off'
                          type={showPassword ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  edge="end"
                                >
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />

                        {errors.password && touched.password ? (
                          <Typography color="error" variant="subtitle2">
                            {errors.password}
                          </Typography>
                        ) : null}
                      </Box>

                      {/* Commented For now */}
                      <Box mb={-1} ml={-1.1} mt={-1} pb={3.5}>
                        <Checkbox
                          disableRipple
                          checked={values.remember}
                          color="primary"
                          onClick={() => setFieldValue('remember', !values.remember)}
                        />

                        <Typography
                          className={classes.rememberLabel}
                          display="inline"
                          variant="body1"
                        >
                          Remember me for 7 days
                        </Typography>
                      </Box>

                      <Box pb={0.5} style={{ textAlign: 'center' }}>
                        <LoadingButton
                          className={classes.submitButton}
                          color="primary"
                          disabled={isSubmitting}
                          loading={isSubmitting}
                          type="submit"
                          variant="contained"
                          aria-label="sign in"
                        >
                          Sign In
                        </LoadingButton>
                      </Box>
                      <Box pt={2} style={{ textAlign: 'center' }}>
                        <LoadingButton
                          className={classes.ssoButton}
                          onClick={(e) => handelSSO()}
                          variant="contained"
                          aria-label="sign in"
                        >
                          <div className={classes.buttonDiv}><div className={classes.buttonDiv1}><img style={{ height: '20px', width: '20px', marginTop: '2px' }} src={img} alt='img' /></div><div style={{ marginLeft: '-20px' }}>Sign in with Single Sign-On</div><div></div></div>
                        </LoadingButton>

                        {loginError ? (
                          <Typography color="error" variant="subtitle2">
                            {loginError}
                          </Typography>
                        ) : null}
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            </Box>
          </Paper>
          {/* <Box pt={2}>
          <Grid alignItems="flex-end" justify={'center'} container>
            <Link className={classes.link} to="/signup">
              <Typography color="primary" variant="subtitle1">
                I don't have account yet
              </Typography>
            </Link>
          </Grid>
        </Box> */}
        </Grid> :
        <Grid><CircularProgress /></Grid>
      }
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    backgroundColor: colors.background1,
    minHeight: '100vh'
  },
  loginPaper: {
    margin: '0 2rem',
    maxWidth: '30rem',
    width: '80vw'
  },
  textField: {
    width: '100%'
  },
  link: {
    textDecoration: 'none'
  },
  rememberLabel: {
    color: colors.text4
  },
  submitButton: {
    width: '100%',
    '&:hover': {
      background: '#0060B2',
      color: '#fff',
    }
  },
  ssoButton: {
    width: '100%',
    background: '#374151',
    color: '#fff',
    padding: '0px 10px',
    '&:hover': {
      background: '#374151',
      color: '#fff',
    }
  },
  buttonDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'inherit'
  },
  buttonDiv1: {
    display: 'flex',
    borderRight: '1px solid #fff',
    paddingRight: '10px',
  }
}));
