import * as Yup from 'yup';
import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, TextField, Typography, CircularProgress, InputAdornment, IconButton } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '../../components/LoadingButton';
import { Logo } from '../../components/Logo';
import { RootState } from '../../redux/redux-store';
import { colors } from '../../styling/colors';
import { verifyForgotPasswordUserThunk, setUserPasswordThunk } from '../../redux/thunk/thunks';

interface FormValues {
    password: string;
    confirmPassword: string;
    agree: boolean;
}

export const ResetPasswordByID = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

    const signupError = useSelector(
        (state: RootState) => state.DISPLAY_STATE_REDUCER.displayErrors.signupError
    );
    const verifyUser: any = useSelector((state: RootState) => state.DATA_REDUCER.verifyUser);

    const initialValues: any = {
        password: '',
        confirmPassword: '',
        email: verifyUser ? verifyUser.email : '',
    };

    useEffect(() => {
        dispatch(verifyForgotPasswordUserThunk());
    }, []);

    const handleSubmit = (values: any, actions: any) => {
        if (verifyUser) {
            dispatch(setUserPasswordThunk(values.password, verifyUser));
            actions.setSubmitting(false);
        }
    };

    const gotoForgotPage = () => {
        history.push('/forgot-password');
    }

    const signupSchema = Yup.object().shape({
        password: Yup.string().matches(
            /^(?=.*[A-Z])(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{10,}$/,
            "Must Contain 10 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
        )
            .min(10, 'Password must be at least 10 characters')
            .max(64, 'Password must be less than 64 characters')
            .required('Required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password')], 'Passwords do not match')
            .required('Required')
    });

    return (
        <Grid
            alignItems="center"
            className={classes.main}
            container
            justify="center"
            spacing={0}
            style={{ minHeight: '100vh' }}
        >
            {verifyUser ?
                <Grid>
                    <Box mb={4} ml={4}>
                        <Logo />
                    </Box>
                    {verifyUser.isVerifiedUser ?
                        <Paper className={classes.signupPaper}>
                            <Box p={4.5}>
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={initialValues}
                                    onSubmit={handleSubmit}
                                    validateOnBlur={false}
                                    validateOnChange={false}
                                    validationSchema={signupSchema}
                                >
                                    {({ errors, isSubmitting, setFieldValue, setSubmitting, touched, values }) => {
                                        if (isSubmitting && signupError) {
                                            setSubmitting(false);
                                        }

                                        return (
                                            <Form>
                                                <Box pb={2.5}>
                                                    <Typography color="textSecondary" variant="h1">
                                                        Reset your password
                                                    </Typography>
                                                </Box>

                                                <Box pb={2.5}>
                                                    <Box pb={0.75}>
                                                        <Typography color="textSecondary" variant="subtitle2">
                                                            Email Address
                                                        </Typography>
                                                    </Box>

                                                    <Field fullWidth
                                                        as={TextField}
                                                        disabled
                                                        className={classes.textField}
                                                        name="email"
                                                        size="small"
                                                        variant="outlined"
                                                    />

                                                    {errors.email && touched.email ? (
                                                        <Typography color="error" variant="subtitle2">
                                                            {errors.email}
                                                        </Typography>
                                                    ) : null}
                                                </Box>

                                                <Box pb={2.5}>
                                                    <Box pb={0.75}>
                                                        <Typography color="textSecondary" variant="subtitle2">
                                                            Password
                                                        </Typography>
                                                    </Box>

                                                    <Field
                                                        as={TextField}
                                                        className={classes.textField}
                                                        name="password"
                                                        size="small"
                                                        variant="outlined"
                                                        autoComplete='off'
                                                        type={showPassword ? 'text' : 'password'}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPassword}
                                                                        edge="end"
                                                                    >
                                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />

                                                    {errors.password && touched.password ? (
                                                        <Typography color="error" variant="subtitle2">
                                                            {errors.password}
                                                        </Typography>
                                                    ) : null}
                                                </Box>
                                                <Box pb={2.5}>
                                                    <Typography style={{ fontSize: '12px', color: 'gray' }}>
                                                        Must contain:
                                                    </Typography>
                                                    <Typography style={{ fontSize: '12px', color: 'gray' }}>
                                                        <span style={{ marginRight: '25px' }}>An uppercase letter</span> <span>A lowercase letter</span>
                                                    </Typography>
                                                    <Typography style={{ fontSize: '12px', color: 'gray' }}>
                                                        <span style={{ marginRight: '27px' }}>A special character</span> <span>A number</span>
                                                    </Typography>
                                                </Box>

                                                <Box pb={2.5}>
                                                    <Box pb={0.75}>
                                                        <Typography color="textSecondary" variant="subtitle2">
                                                            Repeat Password
                                                        </Typography>
                                                    </Box>

                                                    <Field
                                                        as={TextField}
                                                        className={classes.textField}
                                                        name="confirmPassword"
                                                        size="small"
                                                        variant="outlined"
                                                        type={showPassword1 ? 'text' : 'password'}
                                                        autoComplete='off'
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPassword1}
                                                                        edge="end"
                                                                    >
                                                                        {showPassword1 ? <Visibility /> : <VisibilityOff />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />

                                                    {errors.confirmPassword && touched.confirmPassword ? (
                                                        <Typography color="error" variant="subtitle2">
                                                            {errors.confirmPassword}
                                                        </Typography>
                                                    ) : null}
                                                </Box>

                                                <Box pb={0.5}>
                                                    {signupError ? (
                                                        <Typography color="error" variant="subtitle2">
                                                            {signupError}
                                                        </Typography>
                                                    ) : null}

                                                    <LoadingButton
                                                        className={classes.submitButton}
                                                        color="primary"
                                                        disabled={isSubmitting}
                                                        loading={isSubmitting}
                                                        type="submit"
                                                        variant="contained"
                                                    >
                                                        Reset Password
                                                    </LoadingButton>
                                                </Box>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </Box>
                        </Paper> :
                        <Paper className={classes.expirePaper}>
                            <Box style={{ padding: '30px' }}>
                                <Box pb={3} pt={2} px={4} display="flex" justifyContent="center">
                                    <Typography color="textSecondary" variant="h2" style={{ textAlign: 'center', fontSize: '30px' }}>Reset Password Link Expired</Typography>
                                </Box>
                                <Box pb={4} pt={2} display="flex" justifyContent="center">
                                    <Typography color="textSecondary" variant="h4" style={{ textAlign: 'center', color: '#555555', fontWeight: 500, fontSize: '18px' }}>The reset password link has expired. Please contact your
                                        <a target="_blank" href={`mailto:${verifyUser && verifyUser.email}`} style={{ color: '#0060B2' }}> administrator</a> for further information or request another reset password link <span style={{ color: '#0060B2', cursor: 'pointer', textDecoration: 'underline' }} onClick={() => gotoForgotPage()}>here</span>.</Typography>
                                </Box>
                            </Box>
                        </Paper>
                    }
                </Grid> :
                <Grid><CircularProgress /></Grid>
            }
        </Grid >
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    main: {
        backgroundColor: colors.background1,
        minHeight: '100vh'
    },
    signupPaper: {
        margin: '0 2rem',
        maxWidth: '30rem',
        width: '80vw'
    },
    expirePaper: {
        margin: '0 2rem',
        width: '40rem'
    },
    textField: {
        width: '100%'
    },
    link: {
        textDecoration: 'none'
    },
    agreeLabel: {
        color: colors.text4
    },
    submitButton: {
        width: '100%'
    }
}));
