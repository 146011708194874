import { Action } from 'redux';
import { Color } from '@material-ui/lab/Alert';
import { UuidType } from '../../shared/domain';
import {
  DisplaySuccessState,
  DisplayErrorState,
  DisplayLoadingState
} from './display-reducer-type';

// Very helpful article for derived types: https://dev.to/shakyshane/2-ways-to-create-a-union-from-an-array-in-typescript-1kd6
export const SET_GENERIC_ERROR_A = 'SET_GENERIC_ERROR_A';
export interface SetErrorAction extends Action<typeof SET_GENERIC_ERROR_A> {
  reduxField: keyof DisplayErrorState;
  errorMsg: string | null;
}
export const setErrorAction = (reduxField: keyof DisplayErrorState, errorMsg: string | null) => {
  return {
    type: SET_GENERIC_ERROR_A,
    reduxField,
    errorMsg
  };
};
export const SET_GENERIC_SUCCESS_A = 'SET_GENERIC_SUCCESS_A';
export interface SetSuccessAction extends Action<typeof SET_GENERIC_SUCCESS_A> {
  reduxField: keyof DisplaySuccessState;
  success: boolean;
}
export const setSuccessAction = (reduxField: keyof DisplaySuccessState, success: boolean) => {
  return {
    type: SET_GENERIC_SUCCESS_A,
    reduxField,
    success
  };
};
export const SET_GENERIC_LOADING_A = 'SET_GENERIC_LOADING_A';
export interface SetLoadingAction extends Action<typeof SET_GENERIC_LOADING_A> {
  reduxField: keyof DisplayLoadingState;
  loading: boolean;
  errorFieldToNull: keyof DisplayErrorState;
}
export const setLoadingAction = (
  reduxField: keyof DisplayLoadingState,
  loading: boolean,
  errorFieldToNull: keyof DisplayErrorState
) => {
  return {
    type: SET_GENERIC_LOADING_A,
    reduxField,
    loading,
    errorFieldToNull
  };
};

export const SNACKBAR_ALERT_A = 'SNACKBAR_ALERT_A';
export interface SnackbarAlertAction extends Action<typeof SNACKBAR_ALERT_A> {
  snackbarAlertMessage: string | null;
  snackbarAlertSeverity: Color;
  snackbarAutoHideDuration: number | null;
}
export const snackbarAlertAction = (
  snackbarAlertMessage: string | null,
  snackbarAlertSeverity: Color,
  snackbarAutoHideDuration?: number | null
) => {
  return {
    type: SNACKBAR_ALERT_A,
    snackbarAlertMessage,
    snackbarAlertSeverity,
    snackbarAutoHideDuration: snackbarAutoHideDuration || null
  };
};

export const API_LOGIN_ERROR_A = 'API_LOGIN_ERROR_A';
export interface LoginErrorAction extends Action<typeof API_LOGIN_ERROR_A> {
  loginError: string | null;
}
export const loginErrorAction = (loginError: string | null) => {
  return {
    type: API_LOGIN_ERROR_A,
    loginError
  };
};

export const CREATING_NEW_FORM_A = 'CREATING_NEW_FORM_A';

export interface CreatingNewFormAction extends Action<typeof CREATING_NEW_FORM_A> { }

export const creatingNewFormAction = () => {
  return {
    type: CREATING_NEW_FORM_A
  };
};

export const CREATING_NEW_FORM_SUCCESS_A = 'CREATING_NEW_FORM_SUCCESS_A';

export interface CreatingNewFormSuccessAction extends Action<typeof CREATING_NEW_FORM_SUCCESS_A> { }

export const creatingNewFormSuccessAction = () => {
  return {
    type: CREATING_NEW_FORM_SUCCESS_A
  };
};

export const DELETING_FORM_FIELD_A = 'DELETING_FORM_FIELD_A';
export interface DeletingFormFieldAction extends Action<typeof DELETING_FORM_FIELD_A> {
  fieldId: UuidType;
}
export const deletingFormFieldAction = (fieldId: UuidType) => {
  return {
    type: DELETING_FORM_FIELD_A,
    fieldId
  };
};

export const DELETING_FORM_FIELD_SUCCESS_A = 'DELETING_FORM_FIELD_SUCCESS_A';
export interface DeletingFormFieldSuccessAction
  extends Action<typeof DELETING_FORM_FIELD_SUCCESS_A> { }
export const deletingFormFieldSuccessAction = () => {
  return {
    type: DELETING_FORM_FIELD_SUCCESS_A
  };
};
export const UPDATING_FORM_FIELD_A = 'UPDATING_FORM_FIELD_A';
export interface UpdatingFormFieldAction extends Action<typeof UPDATING_FORM_FIELD_A> {
  fieldId: UuidType;
}
export const updatingFormFieldAction = (fieldId: UuidType) => {
  return {
    type: UPDATING_FORM_FIELD_A,
    fieldId
  };
};
export const UPDATING_FORM_FIELD_SUCCESS_A = 'UPDATING_FORM_FIELD_SUCCESS_A';
export interface UpdatingFormFieldSuccessAction
  extends Action<typeof UPDATING_FORM_FIELD_SUCCESS_A> { }
export const updatingFormFieldSuccessAction = () => {
  return {
    type: UPDATING_FORM_FIELD_SUCCESS_A
  };
};
export const UPDATING_FIELD_LOGIC_RULE_A = 'UPDATING_FIELD_LOGIC_RULE_A';
export interface UpdatingFieldLogicRuleAction extends Action<typeof UPDATING_FIELD_LOGIC_RULE_A> {
  fieldLogicRuleId: UuidType;
}
export const updatingFieldLogicRuleAction = (fieldLogicRuleId: UuidType) => {
  return {
    type: UPDATING_FIELD_LOGIC_RULE_A,
    fieldLogicRuleId
  };
};
export const UPDATING_FIELD_LOGIC_RULE_FINISHED_A = 'UPDATING_FIELD_LOGIC_RULE_SUCCESS_A';
export interface UpdatingFieldLogicRuleFinishedAction
  extends Action<typeof UPDATING_FIELD_LOGIC_RULE_FINISHED_A> { }
export const updatingFieldLogicRuleFinishedAction = () => {
  return {
    type: UPDATING_FIELD_LOGIC_RULE_FINISHED_A
  };
};
export const UPDATING_CALCULATION_A = 'UPDATING_CALCULATION_A';
export interface UpdatingCalculationAction extends Action<typeof UPDATING_CALCULATION_A> {
  calculationId: UuidType;
}
export const updatingCalculationAction = (calculationId: UuidType) => {
  return {
    type: UPDATING_CALCULATION_A,
    calculationId
  };
};
export const UPDATING_CALCULATION_FINISHED_A = 'UPDATING_CALCULATION_FINISHED_A';
export interface UpdatingCalculationFinishedAction
  extends Action<typeof UPDATING_CALCULATION_FINISHED_A> { }
export const updatingCalculationFinishedAction = () => {
  return {
    type: UPDATING_CALCULATION_FINISHED_A
  };
};

export const SET_UPDATING_EXIT_PAGE_LOGIC_RULE_ID_A = 'SET_UPDATING_EXIT_PAGE_LOGIC_RULE_ID_A';
export interface SetUpdatingExitPageLogicRuleIdAction
  extends Action<typeof SET_UPDATING_EXIT_PAGE_LOGIC_RULE_ID_A> {
  logicId: string | null;
}
export const setUpdatingExitPageLogicRuleIdAction = (logicId: string | null) => {
  return {
    type: SET_UPDATING_EXIT_PAGE_LOGIC_RULE_ID_A,
    logicId
  };
};

export const SET_UPDATING_EXIT_PAGE_CALCULATION_ID_A = 'SET_UPDATING_EXIT_PAGE_CALCULATION_ID_A';
export interface SetUpdatingExitPageCalculationIdAction
  extends Action<typeof SET_UPDATING_EXIT_PAGE_CALCULATION_ID_A> {
  calculationId: UuidType | null;
}
export const setUpdatingExitPageCalculationIdAction = (calculationId: UuidType | null) => {
  return {
    type: SET_UPDATING_EXIT_PAGE_LOGIC_RULE_ID_A,
    calculationId
  };
};

export const SEND_REMINDER_FORM_INSTANCE_ID_A = 'SEND_REMINDER_FORM_INSTANCE_ID_A';
export interface SendReminderFormInstanceIdAction
  extends Action<typeof SEND_REMINDER_FORM_INSTANCE_ID_A> {
  formInstanceId: UuidType | null;
}
export const sendReminderFormInstanceIdAction = (formInstanceId: UuidType | null) => {
  return {
    type: SEND_REMINDER_FORM_INSTANCE_ID_A,
    formInstanceId
  };
};

export const DOWNLOADING_FORM_RESPONSE_PDF_A = 'DOWNLOADING_FORM_RESPONSE_PDF_A';
export interface DownloadingFormResponsePdfA
  extends Action<typeof DOWNLOADING_FORM_RESPONSE_PDF_A> { }
export const downloadingFormResponsePdfA = () => {
  return {
    type: DOWNLOADING_FORM_RESPONSE_PDF_A
  };
};

//TODO: change action pattern to simplify from "UpdatingSomethingAction" & "UpdatingSomethingFinishedAction"
//to just "SetUpdatingSomething"

export type DisplayAction =
  | SnackbarAlertAction
  | LoginErrorAction
  | CreatingNewFormAction
  | CreatingNewFormSuccessAction
  | DeletingFormFieldAction
  | DeletingFormFieldSuccessAction
  | UpdatingFormFieldAction
  | UpdatingFormFieldSuccessAction
  | SetErrorAction
  | SetSuccessAction
  | SetLoadingAction
  | UpdatingFieldLogicRuleAction
  | UpdatingFieldLogicRuleFinishedAction
  | UpdatingCalculationAction
  | UpdatingCalculationFinishedAction
  | SetUpdatingExitPageLogicRuleIdAction
  | SetUpdatingExitPageCalculationIdAction
  | SendReminderFormInstanceIdAction
  | DownloadingFormResponsePdfA;
