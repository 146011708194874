import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/redux-store';
import { Box, Typography } from '@material-ui/core';
import { Link, matchPath, withRouter } from 'react-router-dom';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { isLoggedIn } from '../redux/api';

import { colors } from '../styling/colors';
import { ROLES } from '../shared/globals';

interface MatchParams {
  id: string;
}

export const SideBar = withRouter(({ match }: any) => {
  const classes = useStyles();
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);

  const location = matchPath<MatchParams>(match.path, {
    path: '/:id'
  })?.params.id;

  const removeTab = () => {
    sessionStorage.removeItem('tab');
  }

  return (
    <Box className={classes.sideBar} display="flex" flexDirection="column" pr={4}>
      {loggedInUser && ![ROLES.CLINICIAN].includes(loggedInUser.role) && <Box mb={3}>
        <Link
          className={
            location === 'forms'
              ? classes.activeSideBarText
              : classes.sideBarText
          }
          to="/forms"
        >
          <Box alignItems="center" display="flex" onClick={() => removeTab()}>
            {(location === 'forms') && (
              <Box alignItems="center" display="flex" pr={0.5}>
                <ArrowForwardIosIcon className={classes.arrowIcon} />
              </Box>
            )}

            <Typography variant="subtitle2">Forms</Typography>
          </Box>
        </Link>
      </Box>}
      {loggedInUser && [ROLES.CLINICIAN].includes(loggedInUser.role) && <Box mb={3}>
        <Link
          className={location === 'forms-collections' || location === 'view-collection' ? classes.activeSideBarText : classes.sideBarText}
          to="/forms-collections"
        >
          <Box alignItems="center" display="flex" onClick={() => removeTab()}>
            {(location === 'forms-collections' || location === 'view-collection') && (
              <Box alignItems="center" display="flex" pr={0.5}>
                <ArrowForwardIosIcon className={classes.arrowIcon} />
              </Box>
            )}
            <Typography variant="subtitle2">Forms & Collections</Typography>
          </Box>
        </Link>
      </Box>
      }
      {loggedInUser && ![ROLES.PATIENT, ROLES.CLINICIAN, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role) && <Box mb={3}>
        <Link
          className={(location === 'collections' || location == 'collection') ? classes.activeSideBarText : classes.sideBarText}
          to="/collections"
        >
          <Box alignItems="center" display="flex" onClick={() => removeTab()}>
            {(location === 'collections' || location == 'collection') && (
              <Box alignItems="center" display="flex" pr={0.5}>
                <ArrowForwardIosIcon className={classes.arrowIcon} />
              </Box>
            )}

            <Typography variant="subtitle2">Collections</Typography>
          </Box>
        </Link>
      </Box>
      }
      <Box mb={3}>
        <Link
          className={location === 'resources' ? classes.activeSideBarText : classes.sideBarText}
          to="/resources"
        >
          <Box alignItems="center" display="flex" onClick={() => removeTab()}>
            {location === 'resources' && (
              <Box alignItems="center" display="flex" pr={0.5}>
                <ArrowForwardIosIcon className={classes.arrowIcon} />
              </Box>
            )}

            <Typography variant="subtitle2">Resources</Typography>
          </Box>
        </Link>
      </Box>
      {loggedInUser && [ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role) ? <Box mb={3}>
        <Link
          className={location === 'referral-list' ? classes.activeSideBarText : classes.sideBarText}
          to="/referral-list"
        >
          <Box alignItems="center" display="flex" onClick={() => removeTab()}>
            {location === 'referral-list' && (
              <Box alignItems="center" display="flex" pr={0.5}>
                <ArrowForwardIosIcon className={classes.arrowIcon} />
              </Box>
            )}

            <Typography variant="subtitle2">Referrals</Typography>
          </Box>
        </Link>
      </Box> :
        <Box mb={3}>
          <Link
            className={location === 'referrals' ? classes.activeSideBarText : classes.sideBarText}
            to="/referrals"
          >
            <Box alignItems="center" display="flex" onClick={() => removeTab()}>
              {location === 'referrals' && (
                <Box alignItems="center" display="flex" pr={0.5}>
                  <ArrowForwardIosIcon className={classes.arrowIcon} />
                </Box>
              )}

              <Typography variant="subtitle2">Referrals</Typography>
            </Box>
          </Link>
        </Box>}
      {loggedInUser && ![ROLES.PATIENT, ROLES.SITE_ADMIN, ROLES.BUILDER, ROLES.CLINICIAN, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role) && <Box mb={3}>
        <Link
          className={location === 'organizations' ? classes.activeSideBarText : classes.sideBarText}
          to="/organizations"
        >
          <Box alignItems="center" display="flex" onClick={() => removeTab()}>
            {location === 'organizations' && (
              <Box alignItems="center" display="flex" pr={0.5}>
                <ArrowForwardIosIcon className={classes.arrowIcon} />
              </Box>
            )}

            <Typography variant="subtitle2">Organizations</Typography>
          </Box>
        </Link>
      </Box>}
      {loggedInUser && ![ROLES.PATIENT, ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.BUILDER, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role) && <Box mb={3}>
        <Link
          className={location === 'patients' ? classes.activeSideBarText : classes.sideBarText}
          to="/patients"
        >
          <Box alignItems="center" display="flex" onClick={() => removeTab()}>
            {location === 'patients' && (
              <Box alignItems="center" display="flex" pr={0.5}>
                <ArrowForwardIosIcon className={classes.arrowIcon} />
              </Box>
            )}

            <Typography variant="subtitle2">Patients</Typography>
          </Box>
        </Link>
      </Box>}
      {loggedInUser && [ROLES.PATIENT].includes(loggedInUser.role) && <Box mb={3}>
        <Link
          className={location === 'shared-user' ? classes.activeSideBarText : classes.sideBarText} to="/shared-user"
        >
          <Box alignItems="center" display="flex" onClick={() => removeTab()}>
            {location === 'shared-user' && (
              <Box alignItems="center" display="flex" pr={0.5}>
                <ArrowForwardIosIcon className={classes.arrowIcon} />
              </Box>
            )}
            <Typography variant="subtitle2">Shared Users</Typography>
          </Box>
        </Link>
      </Box>}
      {loggedInUser && ![ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.BUILDER, ROLES.SITE_ADMIN].includes(loggedInUser.role) && <Box mb={3}>
        <Link
          className={location === 'appointments' ? classes.activeSideBarText : classes.sideBarText}
          to="/appointments"
        >
          <Box alignItems="center" display="flex" onClick={() => removeTab()}>
            {location === 'appointments' && (
              <Box alignItems="center" display="flex" pr={0.5}>
                <ArrowForwardIosIcon className={classes.arrowIcon} />
              </Box>
            )}

            <Typography variant="subtitle2">Appointments</Typography>
          </Box>
        </Link>
      </Box>}
      <Box mb={3} style={{ bottom: 0, position: 'fixed' }}>
        {loggedInUser && [ROLES.SITE_ADMIN, ROLES.SUPER_ADMIN].includes(loggedInUser.role) &&
          <Box mb={3} alignItems="center" display="flex">
            <Link
              className={location === 'account-users' ? classes.activeSideBarText : classes.sideBarText}
              to="/account-users"
            >
              <Box alignItems="center" display="flex" onClick={() => removeTab()}>
                {location === 'account-users' && (
                  <Box alignItems="center" display="flex" pr={0.5}>
                    <ArrowForwardIosIcon className={classes.arrowIcon} />
                  </Box>
                )}

                <Typography variant="subtitle2">Manage Users</Typography>
              </Box>
            </Link>
          </Box>}
        {loggedInUser && ![ROLES.PATIENT, ROLES.CLINICIAN, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role) && <Box mb={3} alignItems="center" display="flex">
          <Link
            className={location === 'flowsheet' ? classes.activeSideBarText : classes.sideBarText}
            to="/flowsheet"
          >
            <Box alignItems="center" display="flex" onClick={() => removeTab()}>
              {location === 'flowsheet' && (
                <Box alignItems="center" display="flex" pr={0.5}>
                  <ArrowForwardIosIcon className={classes.arrowIcon} />
                </Box>
              )}

              <Typography variant="subtitle2">Settings</Typography>
            </Box>
          </Link>
        </Box>
        }
        {loggedInUser && [ROLES.PATIENT, ROLES.SITE_ADMIN, ROLES.BUILDER, ROLES.CLINICIAN, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role) &&
          <Box alignItems="center" display="flex">
            <Link
              className={location === 'contacts' ? classes.activeSideBarText : classes.sideBarText}
              to="/contacts"
            >
              <Box alignItems="center" display="flex" onClick={() => removeTab()}>
                {location === 'contacts' && (
                  <Box alignItems="center" display="flex" pr={0.5}>
                    <ArrowForwardIosIcon className={classes.arrowIcon} />
                  </Box>
                )}
                <Typography variant="subtitle2">Contact Us</Typography>
              </Box>
            </Link>
          </Box>}
      </Box>
    </Box>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    [theme.breakpoints.down('xs')]: {
      padding: '1rem'
    },
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh'
  },
  userIcon: {
    borderRadius: '6px',
    backgroundColor: colors.background5,
    height: '34px',
    width: '34px'
  },
  userIconText: {
    color: theme.palette.secondary.main,
    textShadow: '0 1px 1px rgba(0, 0, 0, 0.25)'
  },
  sideBar: {
    minWidth: '11rem'
  },
  sideBarText: {
    color: colors.text4,
    textDecoration: 'none'
  },
  activeSideBarText: {
    color: colors.blue1,
    textDecoration: 'none'
  },
  arrowIcon: {
    fontSize: 14
  },
  contentContainer: {
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  }
}));
