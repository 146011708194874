import React, { useEffect, useState } from 'react';
import { Box, Paper } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { CardHeader } from '../../../components/LegacyCardHeader';
import { RootState } from '../../../redux/redux-store';
import { StyleInfo } from './style';
import { Tags } from './Tags';
import { useHistory } from 'react-router-dom';
import {
    getSettingTagsThunk
} from '../../../redux/thunk/thunks';

export const Settings = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);

    const tags: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.settingTags
    );

    const loadTags = (getTagsProps: any) => {
        dispatch(getSettingTagsThunk(getTagsProps));
    };

    useEffect(() => {
        dispatch(getSettingTagsThunk({}));
    }, []);

    const FormStyle = (
        <Box pb={2} pt={1} px={2}>
            <StyleInfo />
        </Box>
    );

    const TagComponent = (
        <Box pb={4} pt={1} px={4}>
            <Tags tags={tags} loadTags={loadTags} />
        </Box>
    );

    return (
        <>
            <Paper>
                <Box display="flex" flexDirection="column">
                    {loggedInUser && (loggedInUser.role === 'SITE_ADMIN' || loggedInUser.role === 'BUILDER' ) ?
                        <CardHeader
                            tabComponents={[
                                {
                                    component: FormStyle,
                                    label: 'Form Style',
                                    style: {
                                        background: true,
                                        spacing: { pb: 0, pt: 3, px: 4 }
                                    },
                                    title: 'Settings'
                                },
                                {
                                    component: <></>,
                                    label: '',
                                    title: ''
                                }
                            ]}
                        /> :
                        <CardHeader
                            tabComponents={[
                                {
                                    component: FormStyle,
                                    label: 'Form Style',
                                    style: {
                                        background: true,
                                        spacing: { pb: 0, pt: 3, px: 4 }
                                    },
                                    title: 'Settings'
                                },
                                {
                                    component: TagComponent,
                                    label: 'Tag Management',
                                    style: {
                                        background: true,
                                        spacing: { pb: 0, pt: 3, px: 4 }
                                    },
                                    title: 'Settings'
                                },
                            ]}
                        />
                    }
                </Box>
            </Paper>
        </>
    );
};
