import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React from 'react';
import { Box, ButtonBase, Divider, Theme, Typography, makeStyles } from '@material-ui/core';
import { Dialog } from '../../../../components/LegacyDialog';
import { colors } from '../../../../styling/colors';

interface AssignFormDialogProps {
  open: boolean;
  handleAssignCollectionFormDialogOpen: () => void;
  handleAssignLibraryFormDialogOpen: () => void;
  handleClose: () => void;
}

export const AssignFormDialog = ({
  open,
  handleAssignCollectionFormDialogOpen,
  handleAssignLibraryFormDialogOpen,
  handleClose
}: AssignFormDialogProps) => {
  const classes = useStyles();

  return (
    <Dialog
      handleClose={handleClose}
      open={open}
      secondaryButton={{ text: 'Cancel', handleClick: handleClose }}
      title="Assigning a new form"
    >
      <Box className={classes.main} display="flex" flexDirection="column">
        <ButtonBase
          className={classes.buttonRoot}
          component="div"
          onClick={handleAssignLibraryFormDialogOpen}
          aria-label="pick from library"
        >
          <Box alignItems="center" display="flex" justifyContent="space-between" width="100%">
            <Box display="flex" flexDirection="column">
              <Typography color="textSecondary" variant="subtitle1">
                Pick a form from a Library
              </Typography>

              <Typography className={classes.descText} variant="body1">
                Assign a form from one of FlowPRO's in-house curated form libraries
              </Typography>
            </Box>

            <Box ml={8}>
              <ArrowForwardIcon />
            </Box>
          </Box>
        </ButtonBase>

        <Box py={3}>
          <Divider />
        </Box>

        <ButtonBase
          className={classes.buttonRoot}
          component="div"
          onClick={handleAssignCollectionFormDialogOpen}
          aria-label="pick form from collection"
        >
          <Box alignItems="center" display="flex" justifyContent="space-between" width="100%">
            <Box display="flex" flexDirection="column">
              <Typography color="textSecondary" variant="subtitle1">
                Pick a form from your collections
              </Typography>

              <Typography className={classes.descText} variant="body1">
                Assign a form that was added to one of your collections
              </Typography>
            </Box>

            <Box ml={8}>
              <ArrowForwardIcon />
            </Box>
          </Box>
        </ButtonBase>
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    maxWidth: '32rem',
    width: '80vw'
  },
  descText: {
    color: colors.text5
  },
  buttonRoot: {
    display: 'flex',
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0
  }
}));
