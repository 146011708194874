import { push } from 'connected-react-router';
import { setSuccessAction, setErrorAction } from './../display/display-actions';
import { postHttp, updateToken } from './../api';
import { FlowproThunkDispatch, RootState } from '../redux-store';
import {
  Patient,
  PatientInput,
  PatientSearchResults,
  UnitSearchResults,
  User,
  UserInput,
  UserSearchResults,
  UuidType
} from '../../shared/domain';
import {
  UnitsType, getDepartmentListAction, getStateAction, getUnitAction, getFormStyleAction, getRoleAction, getAccountRoleAction,
  UsersType, getSecondarySiteAdminAction, getSharingUserAction, setPatientInfo, setSharedUserInfo, otpVerificationAction,
  getPatientAction,
  getPatientsAction,
  getUnitsAction,
  getUsersAction,
  setLoggedInUser,
  setInvitedUser,
  getAccountUsersAction,
  addNewUnit
} from '../data/data-actions';
import { getHttp, putHttp, deleteHttp } from '../api';
import { setLoadingAction, snackbarAlertAction } from '../display/display-actions';
import { ROLES } from '../../shared/globals';

export interface GetPatientsProps {
  order?: string;
  page?: number;
  pageSize?: number;
  search?: string;
  sort?: string;
}
export const getLoggedInUserThunk = () => async (dispatch: FlowproThunkDispatch) => {
  try {
    const logedUser: any = localStorage.getItem('formFlash');
    const userRoleId = JSON.parse(logedUser);
    dispatch(setLoadingAction('getLoggedInUserLoading', true, 'getLoggedInUserError'));
    const user: any = await getHttp(`/user/getUserInfo/${userRoleId.id}`);
    userRoleId.first_name = user.data && user.data.length > 0 && user?.data[0]?.first_name;
    userRoleId.last_name = user.data && user.data.length > 0 && user?.data[0]?.last_name;
    localStorage.setItem('formFlash', JSON.stringify(userRoleId));
    dispatch(setLoggedInUser(user.data && user.data.length > 0 ? user.data[0] : null));
    dispatch(setLoadingAction('getLoggedInUserLoading', false, 'getLoggedInUserError'));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      sessionStorage.removeItem('role');
      sessionStorage.removeItem('tab');
      sessionStorage.removeItem('pat_org_id');
      sessionStorage.removeItem('metric_id');
      sessionStorage.removeItem('version');
      sessionStorage.removeItem('item');
      sessionStorage.removeItem('check');
      sessionStorage.removeItem('formTab');
      sessionStorage.removeItem('calculation');
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      sessionStorage.removeItem('role');
      sessionStorage.removeItem('tab');
      sessionStorage.removeItem('pat_org_id');
      sessionStorage.removeItem('metric_id');
      sessionStorage.removeItem('version');
      sessionStorage.removeItem('item');
      sessionStorage.removeItem('check');
      sessionStorage.removeItem('formTab');
      sessionStorage.removeItem('calculation');
      dispatch(setErrorAction('getLoggedInUserError', e.message));
    }
  }
};

export const getPatientInfoThunk = (userRoleId: any) => async (dispatch: FlowproThunkDispatch) => {
  try {
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedRoleId = JSON.parse(logedUser);
    dispatch(setLoadingAction('getDataLoading', true, 'getLoggedInUserError'));
    const user: any = await getHttp(`/user/getUserInfo/${userRoleId}?clinician_id=${loggedRoleId.id}`);
    dispatch(setPatientInfo(user.data && user.data.length > 0 ? user.data[0] : null));
    dispatch(setLoadingAction('getDataLoading', false, 'getLoggedInUserError'));
  } catch (e) {
    dispatch(setLoadingAction('getDataLoading', false, 'getLoggedInUserError'));
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const getSharedUserInfoThunk = (userRoleId: any) => async (dispatch: FlowproThunkDispatch) => {
  try {
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedRoleId = JSON.parse(logedUser);
    dispatch(setLoadingAction('getDataLoading', true, 'getLoggedInUserError'));
    const user: any = await getHttp(`/user/getUserInfo/${userRoleId}?clinician_id=${loggedRoleId.id}`);
    dispatch(setSharedUserInfo(user.data && user.data.length > 0 ? user.data[0] : null));
    dispatch(setLoadingAction('getDataLoading', false, 'getLoggedInUserError'));
  } catch (e) {
    dispatch(setLoadingAction('getDataLoading', false, 'getLoggedInUserError'));
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    }
  }
};

export const getUserByInviteTokenThunk = (inviteToken: UuidType | null) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const user: User | null = await getHttp(`/user/invite-token/${inviteToken ?? ''}`);
    if (!user) {
      throw 'Error getting user';
    } else {
      dispatch(setInvitedUser(user));
      dispatch(push('/login'));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const changeUserPasswordThunk = (id: string, currentPassword: string, newPassword: string) => async (
  dispatch: FlowproThunkDispatch
) => {  //user/auth/change-password
  try {
    const res = await putHttp('/my-profile/changePassword', {
      id,
      currentPassword,
      newPassword
    });
    dispatch(snackbarAlertAction(`Success: Password change successfully`, 'success'));
    dispatch(setSuccessAction('changePasswordSuccess', true));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const getPatientsThunk = ({
  order = 'DESC',
  page = 0,
  pageSize = 10,
  search = '',
  sort = 'created_at'
}) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    const patientSearchResults: PatientSearchResults = await getHttp(
      `/user/patient?search=&page=${page}&pageSize=${pageSize}&sort=${sort}&order=${order}`
    );
    dispatch(getPatientsAction(patientSearchResults));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export interface GetNonPatientsProps {
  usersType: UsersType;
  page?: number;
  pageSize?: number;
  search?: string;
  sort?: string;
  order?: string;
}
export const getNonPatientsThunk = ({
  usersType,
  page = 0,
  pageSize = 10,
  search = '',
  sort = 'first_name',
  order = 'ASC'
}: GetNonPatientsProps) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    dispatch(setLoadingAction('getNonPatientsLoading', true, 'getNonPatientsError'));
    const userSearchResults: UserSearchResults = await getHttp(
      `/user/non-patients?search=${search}&page=${page}&pageSize=${pageSize}&sort=${sort}&order=${order}`
    );
    if (userSearchResults) {
      dispatch(getUsersAction(usersType, userSearchResults));
    } else {
      dispatch(snackbarAlertAction(`Error loading users`, 'error'));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('getNonPatientsLoading', false, 'getNonPatientsError'));
  }
};

export const getAccountsThunk = ({
  id,
  page,
  pageSize,
  search,
  nameSequence,
  lastLoginSequence,
  roleSequence,
  statusSequence,
}: any) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    dispatch(setSuccessAction('deleteUserSuccess', false));
    dispatch(setSuccessAction('createCollectionSuccess', false));
    dispatch(setLoadingAction('getNonPatientsLoading', true, 'getNonPatientsError'));
    const logedUser: any = localStorage.getItem('formFlash');
    const userRoleId = JSON.parse(logedUser);
    if (userRoleId && userRoleId.id) {
      const result: any = await postHttp(`/my-profile/listAllAdminUsers`, {
        "skip": page * pageSize,
        "limit": pageSize,
        "id": id,
        "searchString": search,
        "nameSequence": nameSequence,
        "lastLoginSequence": lastLoginSequence,
        "roleSequence": roleSequence,
        "statusSequence": statusSequence
      }
      );
      dispatch(setSuccessAction('createCollectionSuccess', false));
      if (result) {
        const data = {
          users: result.data,
          page: page,
          pageSize: pageSize,
          search: search,
          total: result.totalCount,
          sort: 'first_name',
          order: 'ASC'
        }
        dispatch(getAccountUsersAction(data));
      } else {
        dispatch(snackbarAlertAction(`Error loading users`, 'error'));
      }
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('getNonPatientsLoading', false, 'getNonPatientsError'));
  }
};

export interface GetUnitsProps {
  unitsType: UnitsType;
  page?: number;
  pageSize?: number;
  search?: string;
  sort?: string;
  order?: string;
}
export const getUnitsThunk = ({
  unitsType,
  page = 0,
  pageSize = 10,
  search = '',
  sort = 'name',
  order = 'ASC'
}: GetUnitsProps) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    dispatch(setLoadingAction('getUnitsLoading', true, 'getUnitsError'));
    const unitSearchResults: UnitSearchResults = await getHttp(
      `/unit?search=${search}&page=${page}&pageSize=${pageSize}&sort=${sort}&order=${order}`
    );
    if (unitSearchResults) {
      dispatch(getUnitsAction(unitsType, unitSearchResults));
    } else {
      dispatch(snackbarAlertAction(`Error loading units`, 'error'));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('getUnitsLoading', false, 'getUnitsError'));
  }
};

export const getPatientThunk = (id: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const patient: Patient = await getHttp(`/user/patient/${id}`);
    dispatch(getPatientAction(patient));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const updateProfileThunk = (userInput: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    dispatch(setLoadingAction('updateProfileLoading', true, 'updateProfileError'));
    const updatedProfile: User = await postHttp(`/my-profile/updateProfileInfo`, {
      "role": userInput.role,
      "role_id": userInput.role_id,
      "email": userInput.email,
      "first_name": userInput.first_name,
      "last_name": userInput.last_name,
      "mobile_phone": userInput.mobile_phone.toString(),
      "birth_date": userInput.birth_date,
      "home_address": userInput.home_address,
      "city": userInput.city,
      "state": userInput.state,
      "zip": userInput.zip.toString(),
      "id": userInput.id,
      "notes": userInput.notes ? userInput.notes : '',
      "department_id": userInput.department_id ? userInput.department_id : null,
      "unit_id": userInput.unit_id ? userInput.unit_id : null,
      "status": userInput.status ? userInput.status : '',
      "userId": userInput.userId ? userInput.userId : null,
      "user_role_id": userInput.user_role_id ? userInput.user_role_id : null,
      "organization_id": loggedInUser.organization_id,
      "patient_id": userInput.patient_id ? userInput.patient_id : null
    });
    if (userInput.email === loggedInUser.email) {
      loggedInUser.first_name = userInput.first_name;
      loggedInUser.last_name = userInput.last_name;
      localStorage.setItem('formFlash', JSON.stringify(loggedInUser))
      dispatch(setLoggedInUser(loggedInUser));
    }
    dispatch(addNewUnit(null));
    dispatch(snackbarAlertAction(`Updated information successfully`, 'success'));
    dispatch(setSuccessAction('createCollectionSuccess', true));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      snackbarAlertAction(`Error: ${e.message}`, 'error');
    }
  } finally {
    dispatch(setLoadingAction('updateProfileLoading', false, 'updateProfileError'));
  }
};

export const updateProfile = (userInput: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const role: any = sessionStorage.getItem('role');
    dispatch(setLoadingAction('updateProfileLoading', true, 'updateProfileError'));
    const updatedProfile: any = await postHttp(`/my-profile/updateProfileInfo`, {
      "role": userInput.role,
      "email": userInput.email,
      "first_name": userInput.first_name,
      "last_name": userInput.last_name,
      "mobile_phone": userInput.mobile_phone.toString(),
      "birth_date": userInput.birth_date,
      "home_address": userInput.home_address,
      "city": userInput.city,
      "state": userInput.state,
      "zip": userInput.zip.toString(),
      "id": userInput.id,
      "userId": userInput.userId,
      "user_role_id": userInput.user_role_id,
      "notes": userInput.notes ? userInput.notes : '',
      "status": userInput.status ? userInput.status : '',
      "department_id": userInput.department_id ? userInput.department_id : null,
      "unit_id": userInput.unit_id ? userInput.unit_id : null,
    });
    if (role) {
      dispatch(snackbarAlertAction(`User updated successfully`, 'success'));
    } else {
      dispatch(getLoggedInUserThunk());
      updatedProfile.data.user_role.first_name = updatedProfile.data.user.first_name;
      updatedProfile.data.user_role.last_name = updatedProfile.data.user.last_name;
      updatedProfile.data.user_role.is_sso_user = updatedProfile.data.user.is_sso_user;
      await localStorage.setItem('formFlash', JSON.stringify(updatedProfile.data.user_role))
      await dispatch(setLoggedInUser(updatedProfile.data.user_role));
      dispatch(snackbarAlertAction(`Updated profile information successfully`, 'success'));
      dispatch(setSuccessAction('createCollectionSuccess', true));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      snackbarAlertAction(`Error: ${e.message}`, 'error');
    }
  } finally {
    dispatch(setLoadingAction('updateProfileLoading', false, 'updateProfileError'));
  }
};

export const updatePatientThunk = (id: UuidType, patientInput: PatientInput) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    dispatch(setLoadingAction('updatePatientLoading', true, 'updatePatientError'));
    const updatedPatient: Patient = await putHttp(`/user/patient/${id}`, {
      patientInput
    });
    dispatch(snackbarAlertAction(`Updated patient information successfully`, 'success'));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      snackbarAlertAction(`Error: ${e.message}`, 'error');
    }
  } finally {
    dispatch(setLoadingAction('updatePatientLoading', false, 'updatePatientError'));
  }
};

export const updateUserRoleThunk = (userId: UuidType, role: ROLES, usersType: UsersType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    dispatch(setLoadingAction('updateUserRoleLoading', true, 'updateUserRoleError'));
    const updatedUser: User = await putHttp(`/user/${userId}/role`, { role });

    const userSearchResults = getState().DATA_REDUCER.userSearchResults[usersType];
    if (userSearchResults) {
      dispatch(
        getUsersAction(usersType, {
          ...userSearchResults,
          users: userSearchResults.users.map((user: User) =>
            user.id === userId ? updatedUser : user
          )
        })
      );
    } else {
      snackbarAlertAction(`Error updating user's role`, 'error');
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      snackbarAlertAction(`Error: ${e.message}`, 'error');
    }
  } finally {
    dispatch(setLoadingAction('updateUserRoleLoading', false, 'updateUserRoleError'));
  }
};

export const getDepartmentThunk = () => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const result: any = await getHttp(`/my-profile/getDepartmentsList`);
    dispatch(getDepartmentListAction(result));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const getStateThunk = () => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const result: any = await getHttp(`/my-profile/getStatesList`);
    dispatch(getStateAction(result));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const getUnitThunk = () => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const result: any = await getHttp(`/my-profile/getUnitsList`);
    dispatch(getUnitAction(result));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const getFormStyleThunk = (id: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const result: any = await getHttp(`/my-profile/getFormStyle?user_role_id=${id}&org_id=${loggedInUser.organization_id ? loggedInUser.organization_id : ''}`);
    dispatch(getFormStyleAction(result && result.data));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const updateFormStyleThunk = (formStyle: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    dispatch(setLoadingAction('updatePatientLoading', true, 'updatePatientError'));
    const result = await postHttp(`/my-profile/updateFormStyle`, {
      "org_id": loggedInUser.organization_id,
      "clinic_name": formStyle.clinic_name,
      "main_color": formStyle.main_color,
      "setMainColorAsContrast": formStyle.setMainColorAsContrast,
      "logo": formStyle.logo,
      "updateDefaultFormStyle": formStyle.updateDefaultFormStyle
    });
    dispatch(getFormStyleThunk(formStyle.user_role_id))
    dispatch(setSuccessAction('createCollectionSuccess', true));
    dispatch(snackbarAlertAction(`Updated form style successfully`, 'success'));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      snackbarAlertAction(`Error: ${e.message}`, 'error');
    }
  }
};

export const saveAccountInfoThunk = (userInput: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    dispatch(setLoadingAction('updateProfileLoading', true, 'updateProfileError'));
    const updatedProfile: User = await postHttp(`/user/auth/new_register`, {
      "role": userInput.role,
      "role_id": userInput.role_id,
      "email": userInput.email,
      "first_name": userInput.first_name,
      "last_name": userInput.last_name,
      "mobile_phone": userInput.mobile_phone.toString(),
      "birth_date": userInput.birth_date,
      "home_address": userInput.home_address,
      "city": userInput.city,
      "state": userInput.state,
      "zip": userInput.zip.toString(),
      "department_id": userInput.department_id ? userInput.department_id : null,
      "unit_id": userInput.unit_id ? userInput.unit_id : null,
      "organization_id": loggedInUser.organization_id,
      "patient_id": userInput.patient_id ? userInput.patient_id : null
    });
    dispatch(addNewUnit(null));
    dispatch(snackbarAlertAction(`Account user saved successfully`, 'success'));
    dispatch(setSuccessAction('createCollectionSuccess', true));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else if (e.status === 500) {
      dispatch(snackbarAlertAction(`Error: Account already exists for this email/patient ID!`, 'error'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('updateProfileLoading', false, 'updateProfileError'));
  }
};

export const deleteUsersThunk = (user_role_id: UuidType, pass: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    dispatch(setLoadingAction('deleteUserLoading', true, 'deleteUserError'));
    const url: any = pass ? `${user_role_id}?password=${pass}` : user_role_id;
    const deleteUserRes = await deleteHttp(`/my-profile/deleteUser/${url}`);
    if (deleteUserRes && pass) {
      dispatch(push(`/login`));
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
    }
    dispatch(snackbarAlertAction(`User deleted successfully`, 'success'));
    dispatch(setSuccessAction('deleteUserSuccess', true));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('deleteUserLoading', false, 'deleteUserError'));
  }
};

export const deleteUsersAndLogoutThunk = (user_role_id: UuidType, pass: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    dispatch(setLoadingAction('deleteUserLoading', true, 'deleteUserError'));
    const url: any = pass ? `${user_role_id}?password=${pass}` : user_role_id;
    const deleteUserRes = await deleteHttp(`/my-profile/deleteUser/${url}`);
    if (deleteUserRes) {
      await localStorage.removeItem('token');
      await localStorage.removeItem('formFlash');
      await localStorage.removeItem('remember');
      await dispatch(push(`/login`));
    }
    dispatch(snackbarAlertAction(`User deleted successfully`, 'success'));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('deleteUserLoading', false, 'deleteUserError'));
  }
};

export const deleteMultiUserThunk = (userId: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    await deleteHttp(`/user/auth/delete/${userId}`);
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const getRolesThunk = ({
  user_role_id,
  page,
  pageSize,
  sort,
  order
}: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
    try {
      const result: any = await postHttp(`/my-profile/getAllRolesInfo`, {
        "user_role_id": user_role_id,
        "skip": page * pageSize,
        "limit": pageSize,
        "sortBy": sort,
        "order": order
      });
      result.page = page;
      result.pageSize = pageSize;
      result.sort = sort;
      result.order = order;
      dispatch(getRoleAction(result));
    } catch (e) {
      if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
        dispatch(push('/login'));
      } else {
        dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
      }
    }
  };

export const switchRoleAccountThunk = ({
  loged_user_role_id,
  switch_user_role_id,
  is_shared_user
}: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
    try {
      const tokenResp: any = await postHttp(`/user/auth/switchAccount`, {
        "switch_from_role_id": loged_user_role_id,
        "switch_to_role_id": switch_user_role_id
      });

      if (tokenResp.status === "DISABLED" || tokenResp.status === "DELETED") {
        dispatch(push(`/account-status`));
        dispatch(setLoggedInUser(tokenResp));
      } else {

        updateToken(tokenResp.accessToken);
        tokenResp.user_role.first_name = tokenResp.user.first_name;
        tokenResp.user_role.last_name = tokenResp.user.last_name;
        tokenResp.user_role.is_sso_user = tokenResp.user.is_sso_user;
        tokenResp.user_role.referral_id = tokenResp.referral_id;
        tokenResp.user_role.organization_name = tokenResp.organization_name;
        is_shared_user && (tokenResp.user_role.is_shared_user = true)
        localStorage.setItem('formFlash', JSON.stringify(tokenResp.user_role))
        dispatch(setLoggedInUser(tokenResp.user_role));

        if ([ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(tokenResp.user_role.role)) {
          dispatch(push(`/forms`));
        } else if (tokenResp.user_role.role === ROLES.CLINICIAN) {
          dispatch(push(`/forms-collections`));
        } else if (tokenResp.user_role.role === ROLES.REFERRAL) {
          dispatch(push(`/referral-profile`));
        } else {
          dispatch(push(`/forms`));
        }
      }
    } catch (e) {
      if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
        dispatch(push('/login'));
      } else {
        dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
      }
    }
  };

export const sendInviteToUser = (user_role_id: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const sendInvite: any = await getHttp(`/my-profile/inviteAdmin/${user_role_id}`);
    dispatch(setSuccessAction('deleteUserSuccess', true));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const cancelInviteToUser = (user_role_id: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const cancelInvite: any = await getHttp(`/my-profile/cancelAdminInvite/${user_role_id}`);
    dispatch(snackbarAlertAction(`Cancelled invite successfully`, 'success'));
    dispatch(setSuccessAction('deleteUserSuccess', true));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const getAccountRolesThunk = () => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const result: any = await getHttp(`/user/getRolesList`);
    dispatch(getAccountRoleAction(result));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const createUnitThunk = (unit: string) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const results: any = await postHttp('/my-profile/addUnit', {
      unit_name: unit
    });
    dispatch(addNewUnit(results.data));
    dispatch(getUnitThunk());
    dispatch(snackbarAlertAction(`Unit saved successfully`, 'success'));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
      dispatch(setErrorAction('createStaticResourceError', e.message));
    }
  }
};
/**Site Admin users accounts API */
export const getSiteAdminAccountsThunk = ({
  page,
  pageSize,
  search,
  sortBy,
  order
}: any) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    dispatch(setSuccessAction('deleteUserSuccess', false));
    dispatch(setSuccessAction('createCollectionSuccess', false));
    dispatch(setLoadingAction('getNonPatientsLoading', true, 'getNonPatientsError'));
    const logedUser: any = localStorage.getItem('formFlash');
    const userRoleId = JSON.parse(logedUser);
    if (userRoleId && userRoleId.id) {
      const result: any = await postHttp(`/my-profile/listUsersForSiteAdmin`, {
        "skip": page * pageSize,
        "limit": pageSize,
        "search": search,
        "sortBy": sortBy,
        "order": order,
        "organization_id": userRoleId.organization_id
      }
      );
      dispatch(setSuccessAction('createCollectionSuccess', false));
      if (result) {
        const data = {
          users: result.data,
          page: page,
          pageSize: pageSize,
          search: search,
          total: result.totalCount,
          sort: sortBy,
          order: order
        }
        dispatch(getAccountUsersAction(data));
      } else {
        dispatch(snackbarAlertAction(`Error loading users`, 'error'));
      }
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('getNonPatientsLoading', false, 'getNonPatientsError'));
  }
};

export const sendInviteToSiteUser = (user_role_id: any, role: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const sendInvite: any = await getHttp(`/my-profile/sendInvite/${user_role_id}?role=${role}`);
    dispatch(setSuccessAction('deleteUserSuccess', true));
    dispatch(setSuccessAction('createCollectionSuccess', true));
    dispatch(setSuccessAction('saveDataSuccess', true));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const sendInviteToReachCareUser = (user_role_id: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const sendInvite: any = await getHttp(`/reachcare/sendInviteAndFirebaseInit?user_role_id=${user_role_id}`);
    dispatch(setSuccessAction('deleteUserSuccess', true));
    dispatch(setSuccessAction('createCollectionSuccess', true));
    dispatch(setSuccessAction('saveDataSuccess', true));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const cancelInviteToSiteUser = (user_role_id: any, role: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const cancelInvite: any = await getHttp(`/my-profile/cancelAdminInvite/${user_role_id}?role=${role}`);
    dispatch(snackbarAlertAction(`Cancelled invite successfully`, 'success'));
    dispatch(setSuccessAction('deleteUserSuccess', true));
    dispatch(setSuccessAction('saveDataSuccess', true));
    dispatch(setSuccessAction('createCollectionSuccess', true));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const getSiteAdminPatientsThunk = ({
  order = 'DESC',
  page = 0,
  pageSize = 10,
  search = '',
  sort = 'first_name',
  favourites = true
}) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    dispatch(setSuccessAction('createCollectionSuccess', false));
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const patientSearchResults: any = await getHttp(
      `/patient/getAllPatients?search=${search}&skip=${page * pageSize}&limit=${pageSize}&sortBy=${sort}&order=${order}&organization_id=${loggedInUser.organization_id}&favourites=${favourites}`
    );
    if (patientSearchResults) {
      patientSearchResults.order = order;
      patientSearchResults.page = page;
      patientSearchResults.pageSize = pageSize;
      patientSearchResults.search = search;
      patientSearchResults.sort = sort;
      patientSearchResults.favourites = favourites;
      dispatch(getPatientsAction(patientSearchResults));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const deletePatientThunk = (user_role_ids: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    dispatch(setLoadingAction('deleteUserLoading', true, 'deleteUserError'));
    await postHttp(`/my-profile/deleteMultipleUsers`, {
      user_role_ids
    });
    dispatch(snackbarAlertAction(`Deleted patient successfully`, 'success'));
    dispatch(setSuccessAction('createCollectionSuccess', true));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else if (e.status === 400) {
      dispatch(snackbarAlertAction(`Error: Something went wrong`, 'error'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('deleteUserLoading', false, 'deleteUserError'));
  }
};

export const getSecondarySiteAdmin = () => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const secondarySiteAdmins: any = await getHttp(`/my-profile/getSecondarySiteAdminList/${loggedInUser && loggedInUser.organization_id}`);
    dispatch(getSecondarySiteAdminAction(secondarySiteAdmins.data));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const newSiteAdminThunk = (userInput: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {

    const updatedProfile: any = await postHttp(`/my-profile/designateNewPrimarySiteAdmin`, {
      "organization_id": userInput.organization_id,
      "new_primary_site_admin_id": userInput.new_primary_site_admin_id,
      "current_site_admin_password": userInput.current_site_admin_password
    });
    if (updatedProfile) {
      const logedUser: any = localStorage.getItem('formFlash');
      const userRoleId = JSON.parse(logedUser);
      userRoleId.primary_site_admin = false;
      localStorage.setItem('formFlash', JSON.stringify(userRoleId));
    }
    dispatch(snackbarAlertAction(`Updated information successfully`, 'success'));
    dispatch(setSuccessAction('newPrimarySiteAdmin', true));

  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const newSiteAdminSSOThunk = (userInput: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const updatedProfile: any = await postHttp(`/my-profile/designateNewPrimarySiteAdmin`, {
      "organization_id": userInput.organization_id,
      "new_primary_site_admin_id": userInput.new_primary_site_admin_id
    });
    if (updatedProfile) {
      const logedUser: any = localStorage.getItem('formFlash');
      const userRoleId = JSON.parse(logedUser);
      userRoleId.primary_site_admin = false;
      localStorage.setItem('formFlash', JSON.stringify(userRoleId));
    }
    dispatch(snackbarAlertAction(`Updated information successfully`, 'success'));
    dispatch(setSuccessAction('newPrimarySiteAdmin', true));

  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const getSharingUserThunk = ({
  order = 'DESC',
  page = 0,
  pageSize = 10,
  search = '',
  sort = 'first_name',
  patientId = '',
  giveOnlyActiveUsers = true
}) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const sharingUsers: any = await getHttp(
      `/patient/shared-users/${patientId}?search=${search}&skip=${page * pageSize}&limit=${pageSize}&sortBy=${sort}&order=${order}&organization_id=${loggedInUser.organization_id}&giveOnlyActiveUsers=${giveOnlyActiveUsers}`
    );
    if (sharingUsers) {
      sharingUsers.order = order;
      sharingUsers.page = page;
      sharingUsers.pageSize = pageSize;
      sharingUsers.search = search;
      sharingUsers.sort = sort;
      dispatch(getSharingUserAction(sharingUsers));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const sendInviteToSharedUser = (user_role_id: any, role: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const role: any = sessionStorage.getItem('role');
    const sendInvite: any = await getHttp(`/my-profile/sendInvite/${user_role_id}?role=${role}`);
    if (role === 'SHARED_USER') {
    } else {
      dispatch(setSuccessAction('deleteUserSuccess', true));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    }
  } finally {
  }
};

export const cancelInviteToSharedUser = (user_role_id: any, role: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const role: any = sessionStorage.getItem('role');
    const cancelInvite: any = await getHttp(`/my-profile/cancelAdminInvite/${user_role_id}?role=${role}`);
    dispatch(snackbarAlertAction(`Cancelled invite successfully`, 'success'));
    if (role === 'SHARED_USER') {
    } else {
      dispatch(setSuccessAction('deleteUserSuccess', true));
    }
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

/**Sent OTP on email */
export const sentOTPThunk = (userInput: any) => async (
  dispatch: FlowproThunkDispatch, getState: () => RootState
) => {
  try {
    await postHttp(`/my-profile/sendOTPonEmail`, {
      "email": userInput.email,
      "user_role_id": userInput.user_role_id,
      "type": userInput.type
    });
    dispatch(snackbarAlertAction(`The OTP has been successfully sent to your registered email address`, 'success'));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

/**Sent OTP on email */
export const verifyOTPThunk = (userInput: any) => async (
  dispatch: FlowproThunkDispatch, getState: () => RootState
) => {
  try {
    const res: any = await postHttp(`/my-profile/verifyOTP`, {
      "otp": userInput.otp,
      "user_role_id": userInput.user_role_id,
      "type": userInput.type
    });
    if (res && res.isVerified && userInput.type === 'DELETE_ACCOUNT') {
      dispatch(deleteUsersAndLogoutThunk(userInput.user_role_id, null))
    } else if (res && res.isVerified && userInput.type === 'DESIGNATE_PSITEADMIN') {
      dispatch(newSiteAdminSSOThunk(userInput));
    }
    dispatch(otpVerificationAction(res));
  } catch (e) {
    if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};