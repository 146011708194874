import { Box, makeStyles, Theme, Typography, Chip, Grid, Divider, FormControlLabel, FormGroup, Checkbox, DialogActions } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import { RootState } from '../../../redux/redux-store';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from '../../../components/Dialog';
import { LoadingButton } from '../../../components/LoadingButton';
import { updateReferralThunk } from '../../../redux/thunk/referral-thunks';
import { setEditReferralAction, getReferralInfoAction } from '../../../redux/data/data-actions';

interface ReferralTypesDialogProps {
    open: boolean;
    handleClose: (type: any) => void;
    edit: any
}

export const ReferralTypesDialog = ({ open, handleClose, edit }: ReferralTypesDialogProps) => {
    const classes = useStyles();
    const [isUpdate, setUpdate] = useState(false);
    const [isSave, setIsSave] = useState(false);
    const dispatch = useDispatch();
    const loginUser: any = localStorage.getItem('formFlash')
    const referralId = JSON.parse(loginUser);

    const referralFilterList: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.referralFilterListResults
    );
    const editReferral: any = useSelector(
        (state: RootState) => referralId?.role === 'REFERRAL' ? state.DATA_REDUCER.referralInfo : state.DATA_REDUCER.editReferral
    );
    const message = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.snackbarAlertMessage);

    useEffect(() => {
        if (message && message.length > 0) {
            if (message == 'Error creating referral: Internal Server Error') {
                setIsSave(false);
            } else {
                setIsSave(false);
                handleClose(null);
            }
        }
    }, [message]);

    const handelCloseDialog = () => {
        handleClose(null);
    }

    const handelCheckInsurance = async (checked: boolean, id: any) => {
        if (checked) {
            const data: Array<any> = editReferral?.insurances ? editReferral?.insurances : [];
            data.push(id);
            referralId?.role === 'REFERRAL' ? await dispatch(getReferralInfoAction({ ...editReferral, insurances: data })) : await dispatch(setEditReferralAction({ ...editReferral, insurances: data }));
        } else {
            const filterArray = editReferral?.insurances.filter((item: any) => item !== id);
            referralId?.role === 'REFERRAL' ? await dispatch(getReferralInfoAction({ ...editReferral, insurances: filterArray })) : await dispatch(setEditReferralAction({ ...editReferral, insurances: filterArray }));
        }
        setUpdate(!isUpdate);
    }
    const handelCheckTherapy = async (checked: boolean, id: any) => {
        if (checked) {
            const data: Array<any> = editReferral?.therapy_types ? editReferral?.therapy_types : [];
            data.push(id);
            referralId?.role === 'REFERRAL' ? await dispatch(getReferralInfoAction({ ...editReferral, therapy_types: data })) : await dispatch(setEditReferralAction({ ...editReferral, therapy_types: data }));
        } else {
            const filterArray = editReferral?.therapy_types.filter((item: any) => item !== id);
            referralId?.role === 'REFERRAL' ? await dispatch(getReferralInfoAction({ ...editReferral, therapy_types: filterArray })) : await dispatch(setEditReferralAction({ ...editReferral, therapy_types: filterArray }));
        }
        setUpdate(!isUpdate);
    }
    const handelCheckIssue = async (checked: boolean, id: any) => {
        if (checked) {
            const data: Array<any> = editReferral?.issues ? editReferral?.issues : [];
            data.push(id);
            referralId?.role === 'REFERRAL' ? await dispatch(getReferralInfoAction({ ...editReferral, issues: data })) : await dispatch(setEditReferralAction({ ...editReferral, issues: data }));
        } else {
            const filterArray = editReferral?.issues.filter((item: any) => item !== id);
            referralId?.role === 'REFERRAL' ? await dispatch(getReferralInfoAction({ ...editReferral, issues: filterArray })) : await dispatch(setEditReferralAction({ ...editReferral, issues: filterArray }));
        }
        setUpdate(!isUpdate);
    }
    const handelCheckAge = async (checked: boolean, id: any) => {
        if (checked) {
            const data: Array<any> = editReferral?.ages_served ? editReferral?.ages_served : [];
            data.push(id);
            referralId?.role === 'REFERRAL' ? await dispatch(getReferralInfoAction({ ...editReferral, ages_served: data })) : await dispatch(setEditReferralAction({ ...editReferral, ages_served: data }));
        } else {
            const filterArray = editReferral?.ages_served.filter((item: any) => item !== id);
            referralId?.role === 'REFERRAL' ? await dispatch(getReferralInfoAction({ ...editReferral, ages_served: filterArray })) : await dispatch(setEditReferralAction({ ...editReferral, ages_served: filterArray }));
        }
        setUpdate(!isUpdate);
    }
    const handelCheckLanguage = async (checked: boolean, id: any) => {
        if (checked) {
            const data: Array<any> = editReferral?.languages ? editReferral?.languages : [];
            data.push(id);
            referralId?.role === 'REFERRAL' ? await dispatch(getReferralInfoAction({ ...editReferral, languages: data })) : await dispatch(setEditReferralAction({ ...editReferral, languages: data }));
        } else {
            const filterArray = editReferral?.languages.filter((item: any) => item !== id);
            referralId?.role === 'REFERRAL' ? await dispatch(getReferralInfoAction({ ...editReferral, languages: filterArray })) : await dispatch(setEditReferralAction({ ...editReferral, languages: filterArray }));
        }
        setUpdate(!isUpdate);
    }
    const handelCheckSexuality = async (checked: boolean, id: any) => {
        if (checked) {
            const data: Array<any> = editReferral?.sexuality ? editReferral?.sexuality : [];
            data.push(id);
            referralId?.role === 'REFERRAL' ? await dispatch(getReferralInfoAction({ ...editReferral, sexuality: data })) : await dispatch(setEditReferralAction({ ...editReferral, sexuality: data }));
        } else {
            const filterArray = editReferral?.sexuality.filter((item: any) => item !== id);
            referralId?.role === 'REFERRAL' ? await dispatch(getReferralInfoAction({ ...editReferral, sexuality: filterArray })) : await dispatch(setEditReferralAction({ ...editReferral, sexuality: filterArray }));
        }
        setUpdate(!isUpdate);
    }
    const removeInsurance = async (id: any) => {
        const filterArray = editReferral?.insurances.filter((item: any) => item !== id);
        referralId?.role === 'REFERRAL' ? await dispatch(getReferralInfoAction({ ...editReferral, insurances: filterArray })) : await dispatch(setEditReferralAction({ ...editReferral, insurances: filterArray }));
    }
    const removeTherapy = async (id: any) => {
        const filterArray = editReferral?.therapy_types.filter((item: any) => item !== id);
        referralId?.role === 'REFERRAL' ? await dispatch(getReferralInfoAction({ ...editReferral, therapy_types: filterArray })) : await dispatch(setEditReferralAction({ ...editReferral, therapy_types: filterArray }));
    }
    const removeIssue = async (id: any) => {
        const filterArray = editReferral?.issues.filter((item: any) => item !== id);
        referralId?.role === 'REFERRAL' ? await dispatch(getReferralInfoAction({ ...editReferral, issues: filterArray })) : await dispatch(setEditReferralAction({ ...editReferral, issues: filterArray }));
    }
    const removeAge = async (id: any) => {
        const filterArray = editReferral?.ages_served.filter((item: any) => item !== id);
        referralId?.role === 'REFERRAL' ? await dispatch(getReferralInfoAction({ ...editReferral, ages_served: filterArray })) : await dispatch(setEditReferralAction({ ...editReferral, ages_served: filterArray }));
    }
    const removeLanguage = async (id: any) => {
        const filterArray = editReferral?.languages.filter((item: any) => item !== id);
        referralId?.role === 'REFERRAL' ? await dispatch(getReferralInfoAction({ ...editReferral, languages: filterArray })) : await dispatch(setEditReferralAction({ ...editReferral, languages: filterArray }));
    }
    const removeSexuality = async (id: any) => {
        const filterArray = editReferral?.sexuality.filter((item: any) => item !== id);
        referralId?.role === 'REFERRAL' ? await dispatch(getReferralInfoAction({ ...editReferral, sexuality: filterArray })) : await dispatch(setEditReferralAction({ ...editReferral, sexuality: filterArray }));
    }
    const clearAll = async () => {
        if (referralId?.role === 'REFERRAL') {
            edit === 'INSURANCE' && await dispatch(getReferralInfoAction({ ...editReferral, insurances: [] }));
            edit === 'THERAPY_TYPE' && await dispatch(getReferralInfoAction({ ...editReferral, therapy_types: [] }));
            edit === 'ISSUE' && await dispatch(getReferralInfoAction({ ...editReferral, issues: [] }));
            edit === 'AGE' && await dispatch(getReferralInfoAction({ ...editReferral, ages_served: [] }));
            edit === 'LANGUAGE' && await dispatch(getReferralInfoAction({ ...editReferral, languages: [] }));
            edit === 'SEXUALITY' && await dispatch(getReferralInfoAction({ ...editReferral, sexuality: [] }));
        } else {
            edit === 'INSURANCE' && await dispatch(setEditReferralAction({ ...editReferral, insurances: [] }));
            edit === 'THERAPY_TYPE' && await dispatch(setEditReferralAction({ ...editReferral, therapy_types: [] }));
            edit === 'ISSUE' && await dispatch(setEditReferralAction({ ...editReferral, issues: [] }));
            edit === 'AGE' && await dispatch(setEditReferralAction({ ...editReferral, ages_served: [] }));
            edit === 'LANGUAGE' && await dispatch(setEditReferralAction({ ...editReferral, languages: [] }));
            edit === 'SEXUALITY' && await dispatch(setEditReferralAction({ ...editReferral, sexuality: [] }));
        }
    }

    const submitReferralType = async () => {
        setIsSave(true);
        if (referralId?.role === 'REFERRAL' && editReferral && editReferral?.specialities) {
            const specialities: any = [];
            for (let i = 0; i < editReferral?.specialities.length; i++) {
                specialities.push(editReferral?.specialities[i]?.id ? editReferral?.specialities[i].id : editReferral?.specialities[i]);
            }
            editReferral.specialities = specialities;
        } else if (editReferral && editReferral?.specialities) {
            const specialities: any = [];
            for (let i = 0; i < editReferral?.specialities.length; i++) {
                specialities.push(editReferral?.specialities[i]?.id ? editReferral?.specialities[i].id : editReferral?.specialities[i]);
            }
            editReferral.specialities = specialities;
        }
        editReferral.referral_id = referralId?.role === 'REFERRAL' ? editReferral?.id : editReferral?.referral_id;
        // await dispatch(updateReferralThunk(editReferral));
        handleClose(null);
        setIsSave(false);
    }

    return (
        <Dialog open={open}>
            <Box display="flex" flexDirection="column" className={classes.box}>
                <Box pb={4} className={classes.topBox}>
                    <Box pb={0.5} p={3}>
                        <Typography color="textSecondary" variant="h2">
                            Edit {edit === 'INSURANCE' ? 'Insurance' : edit === 'THERAPY_TYPE' ? 'Types of Therapy' : edit === 'ISSUE' ? 'Issue' : edit === 'AGE' ? 'Age' : edit === 'LANGUAGE' ? 'Language' : 'Sexuality'}
                        </Typography>
                    </Box>
                </Box>
                <Box p={3}>
                    <Box pb={0.5}>
                        <Typography color="textSecondary" variant="h4">
                            Current {edit === 'INSURANCE' ? 'Insurance' : edit === 'THERAPY_TYPE' ? 'Types of Therapy' : edit === 'ISSUE' ? 'Issue' : edit === 'AGE' ? 'Age' : edit === 'LANGUAGE' ? 'Language' : 'Sexuality'}
                        </Typography>
                    </Box>
                    {edit === 'INSURANCE' &&
                        <div className={classes.otherActions}>
                            {editReferral && editReferral?.insurances?.length > 0 && editReferral?.insurances?.map((id: any) => {
                                const checked = referralFilterList.filter((items: any) => items.id === id)
                                return (
                                    <Chip classes={{ root: classes.collectionChip }} size="small"
                                        label={
                                            <div className={classes.chipAlign}>
                                                {checked && checked.length > 0 && checked[0]?.title}
                                                <Clear className={classes.clearIcon} onClick={(e) => removeInsurance(id)} />
                                            </div>
                                        }
                                    />)
                            })}
                        </div>
                    }
                    {edit === 'THERAPY_TYPE' &&
                        <div className={classes.otherActions}>
                            {editReferral && editReferral?.therapy_types?.map((id: any) => {
                                const checked = referralFilterList.filter((items: any) => items.id === id)
                                return (
                                    <Chip classes={{ root: classes.collectionChip }} size="small"
                                        label={
                                            <div className={classes.chipAlign}>
                                                {checked && checked.length > 0 && checked[0]?.title}
                                                <Clear className={classes.clearIcon} onClick={(e) => removeTherapy(id)} />
                                            </div>
                                        }
                                    />)
                            })}
                        </div>
                    }
                    {edit === 'ISSUE' &&
                        <div className={classes.otherActions}>
                            {editReferral && editReferral?.issues?.map((id: any) => {
                                const checked = referralFilterList.filter((items: any) => items.id === id)
                                return (
                                    <Chip classes={{ root: classes.collectionChip }} size="small"
                                        label={
                                            <div className={classes.chipAlign}>
                                                {checked && checked.length > 0 && checked[0]?.title}
                                                <Clear className={classes.clearIcon} onClick={(e) => removeIssue(id)} />
                                            </div>
                                        }
                                    />)
                            })}
                        </div>
                    }
                    {edit === 'AGE' &&
                        <div className={classes.otherActions}>
                            {editReferral && editReferral?.ages_served?.map((id: any) => {
                                const checked = referralFilterList.filter((items: any) => items.id === id)
                                return (
                                    <Chip classes={{ root: classes.collectionChip }} size="small"
                                        label={
                                            <div className={classes.chipAlign}>
                                                {checked && checked.length > 0 && checked[0]?.title}
                                                <Clear className={classes.clearIcon} onClick={(e) => removeAge(id)} />
                                            </div>
                                        }
                                    />)
                            })}
                        </div>
                    }
                    {edit === 'LANGUAGE' &&
                        <div className={classes.otherActions}>
                            {editReferral && editReferral?.languages?.map((id: any) => {
                                const checked = referralFilterList.filter((items: any) => items.id === id)
                                return (
                                    <Chip classes={{ root: classes.collectionChip }} size="small"
                                        label={
                                            <div className={classes.chipAlign}>
                                                {checked && checked.length > 0 && checked[0]?.title}
                                                <Clear className={classes.clearIcon} onClick={(e) => removeLanguage(id)} />
                                            </div>
                                        }
                                    />)
                            })}
                        </div>
                    }
                    {edit === 'SEXUALITY' &&
                        <div className={classes.otherActions}>
                            {editReferral && editReferral?.sexuality?.map((id: any) => {
                                const checked = referralFilterList.filter((items: any) => items.id === id)
                                return (
                                    <Chip classes={{ root: classes.collectionChip }} size="small"
                                        label={
                                            <div className={classes.chipAlign}>
                                                {checked && checked.length > 0 && checked[0]?.title}
                                                <Clear className={classes.clearIcon} onClick={(e) => removeSexuality(id)} />
                                            </div>
                                        }
                                    />)
                            })}
                        </div>
                    }
                    <LoadingButton className={classes.secondaryButton}
                        variant="contained" onClick={clearAll}
                        style={{ marginBottom: '0px' }}>
                        Clear All
                    </LoadingButton>
                    <Divider style={{ height: '2px', margin: '20px 0 0' }} />
                </Box>
                <Box p={3} pt={0} style={{ overflow: 'auto' }}>
                    <Box pb={2}>
                        <Typography color="textSecondary" variant="h4">
                            {edit === 'INSURANCE' ? 'Insurance' : edit === 'THERAPY_TYPE' ? 'Types of Therapy' : edit === 'ISSUE' ? 'Issue' : edit === 'AGE' ? 'Age' : edit === 'LANGUAGE' ? 'Language' : 'Sexuality'}
                        </Typography>
                    </Box>
                    <Box>
                        {edit === 'INSURANCE' && <Grid container>
                            {referralFilterList && referralFilterList?.map((item: any) => {
                                const checked = editReferral?.insurances ? editReferral?.insurances.filter((id: any) => id === item.id) : [];
                                return (item.type === edit &&
                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel control={<Checkbox style={{ color: checked && checked.length === 0 ? '#a4a4a4' : '#0060B2' }} size='medium' checked={checked && checked.length === 0 ? false : true} onChange={(e) => handelCheckInsurance(checked && checked.length === 0 ? true : false, item.id)} name={item.id} color="primary" />} label={item.title} />
                                    </Grid>
                                )
                            })}
                        </Grid>}
                        {edit === 'THERAPY_TYPE' && <Grid container>
                            {referralFilterList && referralFilterList?.map((item: any) => {
                                const checked = editReferral?.therapy_types ? editReferral?.therapy_types.filter((id: any) => id === item.id) : [];
                                return (item.type === edit &&
                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel control={<Checkbox style={{ color: checked && checked.length === 0 ? '#a4a4a4' : '#0060B2' }} size='medium' checked={checked && checked.length === 0 ? false : true} onChange={(e) => handelCheckTherapy(checked && checked.length === 0 ? true : false, item.id)} name={item.id} color="primary" />} label={item.title} />
                                    </Grid>
                                )
                            })}
                        </Grid>}
                        {edit === 'ISSUE' && <Grid container>
                            {referralFilterList && referralFilterList?.map((item: any) => {
                                const checked = editReferral?.issues ? editReferral?.issues.filter((id: any) => id === item.id) : [];
                                return (item.type === edit &&
                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel control={<Checkbox style={{ color: checked && checked.length === 0 ? '#a4a4a4' : '#0060B2' }} size='medium' checked={checked && checked.length === 0 ? false : true} onChange={(e) => handelCheckIssue(checked && checked.length === 0 ? true : false, item.id)} name={item.id} color="primary" />} label={item.title} />
                                    </Grid>
                                )
                            })}
                        </Grid>}
                        {edit === 'AGE' && <Grid container>
                            {referralFilterList && referralFilterList?.map((item: any) => {
                                const checked = editReferral?.ages_served ? editReferral?.ages_served.filter((id: any) => id === item.id) : [];
                                return (item.type === edit &&
                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel control={<Checkbox style={{ color: checked && checked.length === 0 ? '#a4a4a4' : '#0060B2' }} size='medium' checked={checked && checked.length === 0 ? false : true} onChange={(e) => handelCheckAge(checked && checked.length === 0 ? true : false, item.id)} name={item.id} color="primary" />} label={item.title} />
                                    </Grid>
                                )
                            })}
                        </Grid>}
                        {edit === 'LANGUAGE' && <Grid container>
                            {referralFilterList && referralFilterList?.map((item: any) => {
                                const checked = editReferral?.languages ? editReferral?.languages.filter((id: any) => id === item.id) : [];
                                return (item.type === edit &&
                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel control={<Checkbox style={{ color: checked && checked.length === 0 ? '#a4a4a4' : '#0060B2' }} size='medium' checked={checked && checked.length === 0 ? false : true} onChange={(e) => handelCheckLanguage(checked && checked.length === 0 ? true : false, item.id)} name={item.id} color="primary" />} label={item.title} />
                                    </Grid>
                                )
                            })}
                        </Grid>}
                        {edit === 'SEXUALITY' && <Grid container>
                            {referralFilterList && referralFilterList?.map((item: any) => {
                                const checked = editReferral?.sexuality ? editReferral?.sexuality.filter((id: any) => id === item.id) : [];
                                return (item.type === edit &&
                                    <Grid item xs={12} md={6}>
                                        <FormControlLabel control={<Checkbox style={{ color: checked && checked.length === 0 ? '#a4a4a4' : '#0060B2' }} size='medium' checked={checked && checked.length === 0 ? false : true} onChange={(e) => handelCheckSexuality(checked && checked.length === 0 ? true : false, item.id)} name={item.id} color="primary" />} label={item.title} />
                                    </Grid>
                                )
                            })}
                        </Grid>}
                    </Box>
                </Box>
            </Box>
            <DialogActions style={{ justifyContent: 'space-between', borderRadius: '0 0 10px 10px', padding: '20px', background: '#f9fafb' }}>
                <LoadingButton
                    className={classes.cancelButton}
                    variant="contained"
                    style={{ marginBottom: '0px' }}
                    onClick={handelCloseDialog}
                >
                    Cancel
                </LoadingButton>
                <LoadingButton color="primary" loading={isSave} disabled={isSave}
                    className={classes.primaryButton}
                    variant="contained"
                    style={{ marginBottom: '0px' }}
                    onClick={(e) => submitReferralType()}
                >
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog >
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    topBox: {
        borderRadius: '0.625rem 0.625rem 0 0',
        background: '#f9fafb'
    },
    box: {
        width: '700px',
        overflow: 'auto',
        height: '600px'
    },
    clearIcon: {
        fontSize: 14,
        marginLeft: '0.25rem',
        cursor: 'pointer'
    },
    chips: {
        textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden',

    },
    chip: {
        margin: 2, height: '25px', backgroundColor: theme.background.main
    },
    chipAlign: {
        display: 'flex',
        alignItems: 'center'
    },
    otherActions: {
        marginLeft: '.6rem',
        alignItems: 'center'
    },
    collectionChip: {
        marginRight: theme.spacing(1),
        marginBottom: '4px',
        backgroundColor: theme.background.main
    },
    primaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#fff',
        minWidth: '120px'
    },
    secondaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: 'black',
        width: '100%',
        background: '#fff',
        margin: '20px 0'
    },
    cancelButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        minWidth: '120px',
        background: '#fff',
    },
}));
