import LaunchIcon from '@material-ui/icons/Launch';
import React, { useState } from 'react';
import {
  Box,
  ButtonBase,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Radio,
  Theme,
  Typography
} from '@material-ui/core';

import { DbModel, UuidType } from '../../../shared/domain';
import { LoadingOrError } from '../../../components/LoadingOrError';
import { Link } from 'react-router-dom';

interface FormsRadioListData extends DbModel {
  description: string;
  name: string;
}

interface FormsRadioListProps<T> {
  library?: boolean;
  listData?: T[];
  selected: string | null;
  handleRadioSelect: (id: UuidType) => void;
}

export function FormsRadioList<T extends FormsRadioListData>({
  library,
  listData,
  selected,
  handleRadioSelect
}: FormsRadioListProps<T>) {
  const classes = useStyles();

  return (
    <List>
      {listData ? (
        listData.map((v: T, i: number) => (
          <div key={v.id}>
            <ListItem>
              <ListItemIcon>
                <Radio
                  checked={selected === v.id}
                  color="primary"
                  onChange={() => handleRadioSelect(v.id)}
                  value={v.id}
                />
              </ListItemIcon>

              <ListItemText primary={v.name} secondary={v.description} />

              <Box pl={2} width="5rem">
                <Link to={`/form-preview/${v.id}`} target="_blank" aria-label="Preview form">
                  <Box alignItems="center" display="flex">
                    <Typography variant="button">Preview</Typography>
                    <Box alignItems="center" display="flex" pl={1}>
                      <LaunchIcon className={classes.launchIcon} />
                    </Box>
                  </Box>
                </Link>
              </Box>
            </ListItem>

            {i < listData.length - 1 && (
              <Box px={3}>
                <Divider component="li" />
              </Box>
            )}
          </div>
        ))
      ) : (
        <LoadingOrError errorMsg={null} loading={true} loadingMsg="" noElevation />
      )}
    </List>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  buttonRoot: {
    display: 'flex',
    height: '100%',
    justifyContent: 'flex-start',
    margin: 0,
    padding: 0,
    width: '100%'
  },
  launchIcon: {
    fontSize: 16
  }
}));
