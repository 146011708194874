import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { Box, Divider, Paper, Typography, Grid, CardHeader } from '@material-ui/core';
import { ArrowBack, Close, StarOutline, Star } from '@material-ui/icons';
import { Form, Formik } from 'formik';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { ROLES } from '../../../shared/globals';
import { RootState } from '../../../redux/redux-store';
import { LoadingButton } from '../../../components/LoadingButton';
import { LoadingOrError } from '../../../components/LoadingOrError';
import { TitledTextInput } from '../../../components/TitledTextInput';
import { colors } from '../../../styling/colors';
import { updateProfileThunk, sendInviteToSiteUser, addFavoritePatientThunk, removeFavoritePatientThunk, getReachCareOrgIDThunk, sendInviteToReachCareUser } from '../../../redux/thunk/thunks';
import { Dialog } from '../../../components/Dialog';

interface PatientInformationProps {
  handleChangeFavorite: (value: boolean) => void;
  patient: any;
  setView: (value: any) => void;
}

interface PatientInput {
  email: string,
  first_name: string,
  last_name: string,
  mobile_phone: string,
  home_address: string,
  birth_date: string,
  patient_id: string,
  zip: string,
  city: string,
  state: string,
  notes: string,
}

export const UpdatePatient = ({ handleChangeFavorite, patient, setView }: PatientInformationProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);
  const [isEdit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [disDate, setDisabledDate] = useState<any>();
  const [zip, setZip] = useState<any>(patient ? patient.zip : '');
  const [initialValues, setInitialValues] = useState<PatientInput>({
    email: '',
    first_name: '',
    last_name: '',
    mobile_phone: '',
    home_address: '',
    birth_date: '',
    patient_id: '',
    zip: '',
    city: '',
    state: '',
    notes: '',
  });
  const [isFieldShow, setIsFieldShow] = useState(false);

  const message = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.snackbarAlertMessage
  );

  const phonePattern = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const informationSchema = Yup.object().shape({
    email: Yup.string().email('Email is invalid').required('Required'),
    mobile_phone: Yup.string().matches(phonePattern, 'Phone number is invalid')
  });

  const reachCareOrgId = useSelector(
    (state: RootState) => state.DATA_REDUCER.reachCareOrgId
  );

  useEffect(() => {
    dispatch(getReachCareOrgIDThunk());
  }, []);

  useEffect(() => {
    if (reachCareOrgId) {
      if (loggedInUser?.organization_id === reachCareOrgId) { setIsFieldShow(true); }
    }
  }, [reachCareOrgId]);

  useEffect(() => {
    if (message && message.length > 0) {
      setEditing(false);
    }
  }, [message]);

  useEffect(() => {
    let newDate: any = Date.now();
    setDisabledDate(new Date(newDate).getFullYear() + '-' + (("0" + (new Date(newDate).getMonth() + 1)).slice(-2)) + '-' + ("0" + (new Date(newDate).getDate() - 1)).slice(-2));
  }, []);

  const handelEdit = () => {
    setEdit(true);
  }
  const handelClose = () => {
    setEdit(false);
  }

  const handleSaveChanges = async (values: any, actions: any) => {
    if (patient) {
      setEditing(true);
      // const birth = values.birth_date !== '' ? (new Date(values.birth_date).getFullYear() + '-' + (("0" + (new Date(values.birth_date).getMonth() + 1)).slice(-2)) + '-' + ("0" + (new Date(values.birth_date).getDate())).slice(-2)) : ''
      let data: any = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        mobile_phone: values.mobile_phone,
        home_address: values.home_address,
        birth_date: values.birth_date,
        patient_id: values.patient_id,
        city: values.city,
        state: values.state,
        notes: values.notes,
        zip: zip,
        user_role_id: patient.user_role_id,
        userId: patient.user_id,
        role: patient.role,
        role_id: patient.role_id,
      }
      await dispatch(updateProfileThunk(data));
      setEditing(false);
      actions.resetForm();
      actions.setSubmitting(false);
    }
  };

  useEffect(() => {
    if (patient) {
      // if (patient.birth_date) {
      //   var date = new Date(patient.birth_date),
      //     month = ("0" + (date.getMonth() + 1)).slice(-2),
      //     day = ("0" + date.getDate()).slice(-2);
      //   var newDate = [date.getFullYear(), month, day].join("-");
      // } else {
      //   var newDate = ''
      // }
      setInitialValues({
        first_name: patient.first_name ?? '',
        last_name: patient.last_name ?? '',
        email: patient.email ?? '',
        mobile_phone: patient.mobile_phone ?? '',
        home_address: patient.home_address ?? '',
        birth_date: patient.birth_date ?? '',
        patient_id: patient.patient_id ?? '',
        zip: patient.zip ?? '',
        city: patient.city ?? '',
        state: patient.state ?? '',
        notes: patient.notes ?? '',
      });
    }
  }, [patient]);

  const handelChange = (value: any) => {
    const re = /^[0-9\b]+$/;
    if (re.test(value) && value.length < 6) {
      setZip(value)
    } else {
      value.length > 6 || value.length == 0 && setZip('')
    }
  }
  const isnumber = (value: any) => {
    const test = parseInt(value);
    if (test) {
      return Math.max(0, parseInt(value)).toString().slice(0, 12)
    } else {
      return null
    }
  }

  const sendInvite = async () => {
    if (loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER, ROLES.CLINICIAN].includes(loggedInUser.role)) {
      if (isFieldShow) {
        await dispatch(sendInviteToReachCareUser(patient.user_role_id));
      } else { await dispatch(sendInviteToSiteUser(patient.user_role_id, patient.role)); }
    }
    setIsOpen(true);
    setView(false)
  }
  const handleCloseDialog = () => {
    setIsOpen(false)
  }

  const handelFavoriteResources = async (value: boolean) => {
    setLoading(true);
    if (value) {
      await dispatch(addFavoritePatientThunk([patient.user_role_id]));
      handleChangeFavorite(true);
    } else {
      await dispatch(removeFavoritePatientThunk([patient.user_role_id]));
      handleChangeFavorite(false);
    }
    setLoading(false);
  }

  const handelIsFavourite = async (value: boolean) => {
    !loading && await handelFavoriteResources(value);
  }

  return (
    <Paper>
      {patient ? (isEdit ?
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSaveChanges}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={informationSchema}
        >
          {({ errors, isSubmitting, touched, resetForm, setSubmitting }) => {
            return (
              <Form>
                <Grid container>
                  <Grid item xs={12}>
                    <CardHeader className={classes.cardHeader} title={
                      <Grid container>
                        <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                          {[ROLES.SITE_ADMIN, ROLES.BUILDER, ROLES.CLINICIAN].includes(loggedInUser.role) &&
                            <Grid item xs={2} sm={1} md={1} style={{ display: 'flex', marginRight: '20px' }}>
                              <ArrowBack style={{ fontSize: '30px', color: 'gray', cursor: 'pointer', marginTop: '10px' }} onClick={() => setView(false)} />
                              <Divider orientation="vertical" style={{ margin: '3px 40px 0px 15px', height: '40px' }} />
                            </Grid>}
                          <Typography noWrap variant="h2">Patient Information</Typography>
                          {patient && patient?.isFavourite ? <Star color='primary' style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => handelIsFavourite(false)} /> : <StarOutline color='primary' style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => handelIsFavourite(true)} />}
                        </Grid>
                        <Grid item xs={6} className={classes.grid}>
                          <LoadingButton onClick={(e) => handelClose()}
                            className={classes.secondaryButton}
                            color="secondary"
                            variant="contained"
                          >
                            Cancel
                          </LoadingButton>
                          <LoadingButton
                            disabled={editing} loading={editing}
                            className={classes.primaryButton}
                            color="primary"
                            type="submit"
                            variant="contained"
                          >
                            Save
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    } />
                  </Grid>
                </Grid>

                <Box display="flex" flexDirection="column" minWidth="14rem" px={2.5} py={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <TitledTextInput
                        fullWidth
                        name="first_name"
                        size="small"
                        title="First Name*"
                        variant="outlined"
                      />

                      {errors.first_name && touched.first_name ? (
                        <Typography color="error" variant="subtitle2">
                          {errors.first_name}
                        </Typography>
                      ) : null}
                    </Grid>

                    <Grid item xs={4}>
                      <TitledTextInput
                        fullWidth
                        name="last_name"
                        size="small"
                        title="Last Name*"
                        variant="outlined"
                      />

                      {errors.last_name && touched.last_name ? (
                        <Typography color="error" variant="subtitle2">
                          {errors.last_name}
                        </Typography>
                      ) : null}
                    </Grid>

                    <Grid item xs={4}>
                      <TitledTextInput disabled
                        fullWidth
                        name="patient_id"
                        size="small"
                        title="Patient ID*"
                        variant="outlined"
                      />
                      {errors.patient_id && touched.patient_id ? (
                        <Typography color="error" variant="subtitle2">
                          {errors.patient_id}
                        </Typography>
                      ) : null}
                    </Grid>

                    <Grid item xs={4}>
                      <TitledTextInput
                        fullWidth
                        onInput={(e: any) => {
                          e.target.value = e.target.value.length === 0 ? '' : isnumber(e.target.value)
                        }}
                        name="mobile_phone"
                        size="small"
                        title="Phone Number"
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TitledTextInput disabled
                        fullWidth
                        name="email"
                        size="small"
                        title="Email Address*"
                        variant="outlined"
                      />
                      {errors.email && touched.email ? (
                        <Typography color="error" variant="subtitle2">
                          {errors.email}
                        </Typography>
                      ) : null}
                    </Grid>

                    <Grid item xs={4}>
                      <TitledTextInput
                        fullWidth type='date'
                        name="birth_date"
                        format="MM/dd/yyyy"
                        size="small"
                        title="Date of Birth"
                        variant="outlined"
                        InputProps={{ inputProps: { max: disDate } }}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TitledTextInput
                        fullWidth
                        name="home_address"
                        size="small"
                        title="Address"
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TitledTextInput
                        fullWidth
                        name="city"
                        size="small"
                        title="City"
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TitledTextInput
                        fullWidth
                        name="state"
                        size="small"
                        title="State"
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TitledTextInput value={zip} onChange={(e: any) => handelChange(e.target.value)}
                        fullWidth
                        name="zip"
                        size="small"
                        title="ZIP"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={8} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                      {patient && (patient.status === "INVITE_NOT_SENT" || patient.status === "INVITE_EXPIRED" || patient.status === "INVITE_CANCELLED") && <LoadingButton
                        className={classes.primaryButton}
                        color="primary"
                        disabled={editing}
                        variant="contained"
                        onClick={(e) => sendInvite()}
                      >
                        Invite
                      </LoadingButton>
                      }
                    </Grid>
                    {/* {[ROLES.SITE_ADMIN, ROLES.BUILDER, ROLES.CLINICIAN].includes(loggedInUser.role) &&
                      <Grid item xs={12}>
                        <TitledTextInput multiline rows={3}
                          fullWidth
                          name="notes"
                          size="small"
                          title="Notes"
                          variant="outlined"
                        />
                      </Grid>
                    } */}
                  </Grid>
                </Box>
              </Form>
            );
          }}
        </Formik> :
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handelEdit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={informationSchema}
        >
          {({ errors, isSubmitting, touched, resetForm, setSubmitting }) => {
            return (
              <Form>

                <Grid container>
                  <Grid item xs={12}>
                    <CardHeader className={classes.cardHeader} title={
                      <Grid container>
                        <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                          {[ROLES.SITE_ADMIN, ROLES.BUILDER, ROLES.CLINICIAN].includes(loggedInUser.role) &&
                            <Grid item xs={2} sm={1} md={1} style={{ display: 'flex', marginRight: '20px' }}>
                              <ArrowBack style={{ fontSize: '30px', color: 'gray', cursor: 'pointer', marginTop: '10px' }} onClick={() => setView(false)} />
                              <Divider orientation="vertical" style={{ margin: '3px 40px 0px 15px', height: '40px' }} />
                            </Grid>}
                          <Typography noWrap variant="h2">Patient Information</Typography>
                          {patient && patient?.isFavourite ? <Star color='primary' style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => handelIsFavourite(false)} /> : <StarOutline color='primary' style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => handelIsFavourite(true)} />}
                        </Grid>
                        <Grid item xs={6} className={classes.grid}>
                          <LoadingButton onClick={(e) => handelEdit()}
                            className={classes.primaryButton}
                            color="primary"
                            disabled={editing}
                            variant="contained"
                          >
                            Edit
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    } />
                  </Grid>
                </Grid>

                <Box display="flex" flexDirection="column" minWidth="14rem" px={2.5} py={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <TitledTextInput disabled={true}
                        fullWidth
                        name="first_name"
                        size="small"
                        title="First Name*"
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TitledTextInput disabled={true}
                        fullWidth
                        name="last_name"
                        size="small"
                        title="Last Name*"
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TitledTextInput disabled={true}
                        fullWidth
                        name="patient_id"
                        size="small"
                        title="Patient ID*"
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TitledTextInput disabled={true}
                        fullWidth
                        name="mobile_phone"
                        size="small"
                        title="Phone Number"
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TitledTextInput disabled={true}
                        fullWidth
                        name="email"
                        size="small"
                        title="Email Address*"
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TitledTextInput disabled={true}
                        fullWidth
                        name="birth_date"
                        format="MM/dd/yyyy"
                        size="small"
                        title="Date of Birth"
                        variant="outlined"
                        type='date'
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TitledTextInput disabled={true}
                        fullWidth
                        name="home_address"
                        size="small"
                        title="Address"
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TitledTextInput disabled={true}
                        fullWidth
                        name="city"
                        size="small"
                        title="City"
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TitledTextInput disabled={true}
                        fullWidth
                        name="state"
                        size="small"
                        title="State"
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TitledTextInput disabled={true}
                        fullWidth
                        name="zip"
                        size="small"
                        title="ZIP"
                        variant="outlined"
                      />
                    </Grid>
                    {/* {(loggedInUser.role === ROLES.SITE_ADMIN || loggedInUser.role === ROLES.BUILDER) &&
                      <Grid item xs={12}>
                        <TitledTextInput multiline rows={3}
                          fullWidth
                          name="notes"
                          size="small"
                          title="Notes"
                          variant="outlined"
                        />
                      </Grid> }*/
                    }
                  </Grid>
                </Box>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <LoadingOrError errorMsg={null} loading={true} loadingMsg="" noElevation />
      )}
      {isOpen &&
        <Dialog handleClose={handleCloseDialog} open={isOpen}>
          <Box style={{ width: '400px' }}>
            <Box pb={3} pt={2} px={4} display="flex" justifyContent="space-between">
              <Typography color="textSecondary" variant="h2">Invitation Success</Typography>
              <Close onClick={() => setIsOpen(false)} />
            </Box>
            <Box pb={6} pt={2} display="flex" justifyContent="center">
              <Typography color="textSecondary" variant="h2">Invitation Sent</Typography>
            </Box>
          </Box>
        </Dialog>
      }
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  editIcon: {
    color: colors.text6,
    fontSize: 16
  },
  cardHeader: {
    borderBottom: '1px solid #c4c4c4',
    color: '#374151',
    fontSize: '18px',
    padding: '24px 32px',
    background: '#f9f9f9'
  },
  grid: {
    textAlign: 'right'
  },
  buttonRoot: {
    display: 'flex',
    height: '100%',
    margin: 0,
    padding: 0
  },
  primaryButton: {
    boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)',
    width: '120px'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: '#0060B2',
    marginRight: '10px',
    width: '120px'
  }
}));
