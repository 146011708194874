import React from 'react'
import flowpro from './flowpro.png'
import './Policy.css'

const Policy = () => {
    return (
        <>
        <nav className="nav-container fixed-top portfolio-scroll">
                <img src={flowpro} className="navbar-images" alt="Logo" height={40}/>
            </nav>
        <div className="policy-container">
            <h1 className="policy-heading">FormFlash PRIVACY POLICY</h1>
            <p className="paragraph">This Privacy Policy is designed to inform users of FormFlash software and services (collectively, the "Service") about how we gather and use personal information collected by us in connection with use of the Service. You may have been invited to use, download or install the Service from a third party as part of a research or clinical care initiative (such third party, the "Institutional Client"), or you may have subscribed or downloaded and installed the Service for your personal use. This policy covers how FormFlash treats your Personally Identifiable Information ("PII") and Protected Health Information ("PHI") (collectively hereafter "PHI") that FormFlash collects, receives, maintains, stores or transmits, including information you transmit or submit to our website (the "Site"). Your PHI includes information that individually identifies you or is information about you that can be traced back to you, your IP address, but not your location. It may also include, but is not limited to your name, email address, phone number, and information about your health and/or other types of protected health information. </p>
            <h3 className="side-heading">Agreement</h3>
            <p className="paragraph">
                By using the Services you accept and hereby expressly consent to the terms
                of this Privacy Policy. This Privacy Policy may change from time to time, so
                please check back periodically to ensure that you are aware of any changes.
                Your continued use of the Site, FormFlash products or services signifies your
                acceptance of any such changes.
            </p>
            <h3 className="side-heading">Information Collection and Use</h3>
            <p className="paragraph">
                FormFlash collects personal information when you register with FormFlash or
                through its Site, or when you use FormFlash’s products or services. When
                registering with FormFlash, we will ask you to provide us with your name,
                email address, and other related information that FormFlash may ask of you
                from time to time, and you may submit additional information and comments
                through the Site. It is always your choice whether or not to provide us with
                such information. If you choose not to provide the requested information you
                may not be able to use certain features or functions of this Site, or
                FormFlash’s products or services. Whenever you visit our Site, FormFlash
                also receives and records information on our server logs from your browser,
                including your IP address and the pages you request, and relates it to the PHI
                you provide. FormFlash collects and uses information you provide for the
                following purposes:
            </p>
            <ol className="order-list1">
                <li className="registration para">
                    Registration — When you register for the Service, we may collect your information as part of the registration process, including but not limited to your name, email address, and program preferences.
                </li>
                <li className="registration para">
                    Self-Reported Health-Related Information — We may collect the information that you enter during the course of using the Service, such as information regarding your health and/or medical condition and related behaviors.
                </li>
                <li className="registration para">
                    Provider-Reported Health-Related Information — We may collect the information about you that is submitted with your permission by your healthcare provider during the course of using the Service, such as information regarding your health and/or medical condition, including information that is considered protected health information under the HIPAA privacy and security regulations.
                </li>
                <li className="registration para">
                    Social Information — We collect information that you provide to us pertaining to the people with whom you consent to share your protected health information (such as a family member, another patient or doctor), as well as communications between you and such individuals.
                </li>
                <li className="registration para">
                    Communications with a Health Professional — We collect communications that take place through the Service between you and your healthcare provider, health manager or health coach.
                </li>
                <li className="registration para">
                    Demographic Information — We may also collect demographic information, such as age and gender as part of your profile in the Service.
                </li>
                <li className="registration para">
                    Surveys — From time to time, we may send you survey questions to provide us with feedback on our Service. We collect any responses that you provide.
                </li>
            </ol>
            <p className="paragraph">
                You may affirmatively opt-out of receiving future messages and announcements and remove your name from our mailing lists. The opt-out provisions do not apply to information used internally to recognize you and/or facilitate your visits to the Site, or information we may retain to comply with legal requirements. You may dis-enroll from the Service at any time by emailing Gola customer service at Gola@umassmed.edu. "Web beacons" are small pieces of code placed on a web page to monitor the behavior and collect data about the visitors viewing a web page. For example, web beacons can be used to count the users who visit a web page. We may use web beacons on our Site from time to time for this purpose.
            </p>
            <h3 className="side-heading">Information Sharing and Disclosure</h3>
            <p className="paragraph">
                FormFlash does not rent, sell, or share your information with other people or
                nonaffiliated companies. We may be required to disclose your information in
                response to a legal process, for example, in response to a court order or a
                subpoena to comply with its applicable legal and regulatory reporting
                requirements. We also may disclose your information in response to a law
                enforcement agency's request, or where we believe it is necessary to
                investigate, prevent, or take action regarding illegal activities, suspectedfraud, situations involving potential threats to the physical safety of any
                person, violations of our End User License Agreement, or to verify or enforce
                compliance with the policies governing our products and services and with
                applicable laws, or as otherwise required or permitted by law or consistent
                with legal requirements. In addition, we may transfer your information to an
                entity or individual that acquires, buys, or merges with FormFlash, or our
                other business units.
            </p>
            <h3 className="side-heading">Confidentiality and Security</h3>
            <p className="paragraph">
                We have taken reasonable and necessary steps to ensure that all information
                collected will remain secure and in its original form, i.e., free from any
                alteration. We have put in place appropriate physical, electronic, and
                administrative procedures in an effort to safeguard and help prevent
                unauthorized access, maintain data security, and correctly use the
                information we collect online. FormFlash is not responsible for and will not be
                a party to any transactions between you and a third-party provider of
                products, information or services. FormFlash does not monitor such
                interactions to ensure the confidentiality of your PHI and PII, including credit
                card information. Any separate charges, data records or obligations you incur
                in your dealings with third parties linked to or in conjunction with
                FormFlash’s Service are solely your responsibility.
            </p>
            <h3 className="side-heading">General Information</h3>
            <p className="paragraph">
                FormFlash generally reviews the content of health news articles or
                publications linked through third party websites, but is not responsible for
                such content, the privacy practices, or any advertisements on third party
                websites. Users should be aware of this when they leave our Service and are
                encouraged to review the privacy statements of each third-party website.
                Moreover, FormFlash is not responsible for any disclosures to third parties to
                whom you may disclose your PHI directly, including family members and/or
                friends. This Privacy Policy applies solely to information collected by
                FormFlash in relation to the Service. Amendments — FormFlash may modify
                or amend this Privacy Policy from time to time. If we make any material
                changes, as determined by FormFlash, in the way in which Personal
                Information is collected, used or transferred, we will notify you of these
                changes by modification of this Privacy Policy, which will be available for
                review by you at the Service. Termination — FormFlash reserves the right to
                decide, at its sole discretion, to no longer offer you the Service. If FormFlash
                decides to terminate your account, FormFlash will either return or
                permanently destroy any copies it maintains of your PHI in accordance with
                its obligations under federal or state law. Children — FormFlash can collect
                PHI from children under the age of 13, and our protections described above
                apply to children as well.
            </p>
            <h3 className="side-heading">Independent Recourse Mechanism</h3>
            <p className="paragraph">
                FormFlash commits to resolve complaints about your privacy and our
                collection or use of your personal information. FormFlash will respond to the
                inquiry or complaint within forty-five (45) days.
            </p>
            <h3 className="side-heading">Questions and Suggestions</h3>
            <p className="paragraph">
                If you have questions or suggestions, or wish to correct your profile information, please email FormFlash at <a className="mailto" href="mailto:Edwin.Boudreaux@umassmed.edu">Edwin.Boudreaux@umassmed.edu</a> or write to us at LA-189, Department of Emergency Medicine, 55 North Lake Ave, Worcester MA 01655. This Privacy Policy was last updated on March 29th, 2022.
            </p>
            <div className="adress">
                <h4>FORMFLASH Privacy Policy<br />Revised 3/29/2022</h4>
            </div>
        </div>
        </>
    )
}
export default Policy