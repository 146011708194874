import { TextField, Typography } from '@material-ui/core';
import React from 'react';

interface TitleSettingsProps {
  titleClass: string;
  fieldTitle: string;
  handleTitleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export const TitleSettings: React.FC<TitleSettingsProps> = ({
  titleClass,
  fieldTitle,
  handleTitleChange
}) => {
  return (
    <>
      <Typography color={'textSecondary'} variant="subtitle2" className={titleClass}>
        Field Title
      </Typography>
      <TextField
        variant="outlined"
        size={'small'}
        //if edit mode is true show the field_prop from the editingFormField obj in redux
        value={fieldTitle}
        multiline
        fullWidth
        rows={2}
        onChange={handleTitleChange}
      />
    </>
  );
};
