import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { TopBar } from '../../components/TopBar';
import { colors } from '../../styling/colors';
import { TasksCard } from './components/TasksCard';

export const PatientDashboard = () => {
  const classes = useStyles();

  return (
    <Box className={classes.main} display="flex" flexDirection="column" p={4}>
      <Box mb={4}>
        <TopBar />
      </Box>
      <Box display="flex" flexDirection="column" width="100%">
        <TasksCard />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    [theme.breakpoints.down('xs')]: {
      padding: '1rem'
    },
    backgroundColor: colors.background1,
    minHeight: '100vh'
  },
  contentContainer: {
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  }
}));
