import { Collection, CollectionAssignments, UuidType } from '../../shared/domain';
import { FlowproThunkDispatch, RootState } from '../redux-store';
import {
  getCollectionStatusCountAction, getCollectionForms, getAllCollectionForms,
  CollectionsType,
  getCollectionAction,
  getCollectionAssignmentsAction,
} from '../data/data-actions';
import { deleteHttp, getHttp, postHttp, putHttp } from '../api';
import { getCollectionsAction, addNewTag } from '../data/data-actions';
import {
  setErrorAction,
  setLoadingAction,
  setSuccessAction,
  snackbarAlertAction
} from '../display/display-actions';
import { updateFormsThunk } from './form-thunks';
import { push } from 'connected-react-router';

export const getDomainsThunk = ({
  collectionsType
}: any) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    const catalogues: any = await getHttp(
      `/domain/getAllDomains`
    );
    if (catalogues) {
      const data: any = {
        collections: catalogues.domains,
        page: 0,
        pageSize: 0,
        search: "",
        total: 9,
        library: true,
      }
      dispatch(getCollectionsAction(collectionsType, data));
      dispatch(setSuccessAction('getCollectionsSuccess', true));
    } else {
      dispatch(snackbarAlertAction(`Error loading collections`, 'error'));
      dispatch(setSuccessAction('getCollectionsSuccess', false));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error loading collections: ${e.message}`, 'error'));
      dispatch(setErrorAction('getCollectionsError', e.message));
      dispatch(setSuccessAction('getCollectionsSuccess', false));
    }
  }
};

export interface GetCollectionProps {
  domainId?: UuidType;
  collectionType: any;
  library?: boolean;
  order?: string;
  page?: number;
  pageSize?: number;
  search?: string;
  sort?: string;
  favourites?: boolean;
}
export const getCollectionsThunk = ({
  domainId = '',
  collectionType,
  library,
  order = 'DESC',
  page = 0,
  pageSize = 10,
  search = '',
  sort = 'updated_at',
  favourites = true,
}: any) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    dispatch(setSuccessAction('createCollectionSuccess', false));
    const collections: any = await getHttp(
      `/collection?domain_id=${domainId}&library=${library ? 'true' : 'false'
      }&search=${search}&page=${page}&pageSize=${pageSize}&sort=${sort}&order=${order}&favourites=${favourites}&organization_id=${loggedInUser && loggedInUser.organization_id ? loggedInUser.organization_id : ''}`
    );
    dispatch(addNewTag(null));
    dispatch(setSuccessAction('createCollectionSuccess', false));
    if (collections) {
      collections.domainId = domainId;
      collections.search = search;
      collections.favourites = favourites;
      collections.sort = sort;
      collections.order = order;
      collections.page = page;
      collections.pageSize = pageSize;
      dispatch(getCollectionsAction(collectionType, collections));
      dispatch(setSuccessAction('getCollectionsSuccess', true));
    } else {
      dispatch(snackbarAlertAction(`Error loading collections`, 'error'));
      dispatch(setSuccessAction('getCollectionsSuccess', false));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
      dispatch(setErrorAction('getCollectionsError', e.message));
      dispatch(setSuccessAction('getCollectionsSuccess', false));
      dispatch(setSuccessAction('createCollectionSuccess', false));
    }
  }
};

export const getCollectionThunk = (collectionId: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const collection = await getHttp(`/collection/${collectionId}`);
    if (collection) {
      dispatch(getCollectionAction(collection));
    } else {
      dispatch(snackbarAlertAction(`Error loading collection`, 'error'));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};


export const createCollectionThunk = (collectionInput: any) => async (
  dispatch: FlowproThunkDispatch, getState: () => RootState
) => {
  try {
    const results: any = await postHttp('/collection', collectionInput);
    dispatch(setSuccessAction('createCollectionSuccess', true));
    dispatch(addNewTag(null));
    dispatch(snackbarAlertAction(`Collection saved successfully`, 'success'));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error collection: ${e.message}`, 'error'));
      dispatch(setErrorAction('createCollectionError', e.message));
      dispatch(setSuccessAction('createCollectionSuccess', false));
    }
  }
};

export const editCollectionThunk = (collectionId: string, collectionInput: any) => async (
  dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    await putHttp(`/collection/${collectionId}`, collectionInput);
    dispatch(addNewTag(null));
    dispatch(setSuccessAction('createCollectionSuccess', true));
    // dispatch(getCollectionsThunk({}));
    // dispatch(getCollectionsAction('collections', null));
    dispatch(snackbarAlertAction(`Updated collection successfully`, 'success'));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error updating collection: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('editCollectionLoading', false, 'editCollectionError'));
  }
};

export const deleteCollectionThunk = (delete_ids: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    await postHttp(`/collection/deleteCollections`, delete_ids);
    dispatch(setSuccessAction('createCollectionSuccess', true));
    dispatch(snackbarAlertAction(`Deleted collections successfully`, 'success'));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  } finally {
    setLoadingAction('deleteCollectionLoading', false, 'deleteCollectionError');
  }
};

export const removeFevCollectionThunk = (data: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    data.user_role_id = loggedInUser.id;
    await postHttp(`/collection/removeFromFavourites`, data);
    dispatch(setSuccessAction('createCollectionSuccess', true));
    dispatch(snackbarAlertAction(`Favorite removed successfully`, 'success'));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

// updates all loaded collections
interface UpdateCollectionsProps {
  resetPage?: boolean;
}
export const updateCollectionsThunk = (props?: UpdateCollectionsProps) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const collectionSearchResults = Object.entries(getState().DATA_REDUCER.collectionSearchResults);
    for (const result of collectionSearchResults) {
      if (result[1]) {
        const collections = await getHttp(
          `/collection?search=${result[1].search}&page=${props?.resetPage ? 0 : result[1].page
          }&pageSize=${result[1].pageSize}&library=${result[1].library}`
        );
        dispatch(getCollectionsAction(result[0] as CollectionsType, collections));
      }
    }
    dispatch(setSuccessAction('updateCollectionsSuccess', true));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const getCollectionAssignmentsThunk = (collectionId: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    dispatch(
      setLoadingAction('getCollectionAssignmentsLoading', true, 'getCollectionAssignmentsError')
    );
    const assignments: CollectionAssignments = await getHttp(
      `/collection/${collectionId}/assignments`
    );
    if (assignments) {
      dispatch(getCollectionAssignmentsAction(assignments));
    }
    dispatch(
      setLoadingAction('getCollectionAssignmentsLoading', false, 'getCollectionAssignmentsError')
    );
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const getCollectionFormsThunk = ({
  collectionId = '',
  domain_id = '',
  sort_by = '',
  order = ''
}: any) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    const forms: any = await getHttp(
      `/collection/${collectionId}/forms?domain_id=${domain_id}&sort_by=${sort_by}&order=${order}`
    );
    if (forms) {
      dispatch(getCollectionForms(forms.data));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const getAllCollectionFormsThunk = ({
  collectionId = '',
  search = '',
  skip = 0,
  limit = 10
}) => async (
  dispatch: FlowproThunkDispatch, getState: () => RootState
) => {
    try {
      const logedUser: any = localStorage.getItem('formFlash');
      const loggedInUser = JSON.parse(logedUser);
      const forms: any = await postHttp(`/collection/forms-not-in-collection`, {
        "collection_id": collectionId,
        "search": search,
        "skip": skip * 10,
        "limit": limit,
        "organization_id": loggedInUser && loggedInUser.organization_id
      });

      forms.skip = skip;
      forms.limit = limit;
      forms.search = search;
      dispatch(getAllCollectionForms(forms));
    } catch (e) {
      if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
        dispatch(push('/login'));
      } else {
        dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
      }
    }
  };

export const assignToCollectionThunk = (
  collectionId: UuidType,
  unitIds: UuidType[],
  userIds: UuidType[]
) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    dispatch(setLoadingAction('assignCollectionLoading', true, 'assignCollectionError'));
    await putHttp(`/collection/${collectionId}/assign`, {
      units: unitIds,
      users: userIds
    });
    dispatch(getCollectionAssignmentsThunk(collectionId));
    dispatch(updateCollectionsThunk());
    dispatch(setSuccessAction('assignCollectionSuccess', true));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('assignCollectionLoading', false, 'assignCollectionError'));
  }
};

export const unassignUnitFromCollectionThunk = (collectionId: UuidType, unitId: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    dispatch(
      setLoadingAction('unassignUnitFromCollectionLoading', true, 'unassignUserFromCollectionError')
    );
    await deleteHttp(`/collection/${collectionId}/assign/unit/${unitId}`);
    dispatch(getCollectionAssignmentsThunk(collectionId));
    dispatch(updateCollectionsThunk());
    dispatch(setSuccessAction('unassignUnitFromCollectionSuccess', true));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(
      setLoadingAction(
        'unassignUnitFromCollectionLoading',
        false,
        'unassignUserFromCollectionError'
      )
    );
  }
};

export const unassignUserFromCollectionThunk = (collectionId: UuidType, userId: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    dispatch(
      setLoadingAction('unassignUserFromCollectionLoading', true, 'unassignUserFromCollectionError')
    );
    await deleteHttp(`/collection/${collectionId}/assign/user/${userId}`);
    dispatch(getCollectionAssignmentsThunk(collectionId));
    dispatch(updateCollectionsThunk());
    dispatch(setSuccessAction('unassignUserFromCollectionSuccess', true));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(
      setLoadingAction(
        'unassignUserFromCollectionLoading',
        false,
        'unassignUserFromCollectionError'
      )
    );
  }
};

export const assignCollectionToFormsThunk = (collectionId: UuidType, formIds: UuidType[]) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    dispatch(
      setLoadingAction('assignCollectionToFormsLoading', true, 'assignCollectionToFormsError')
    );
    await putHttp(`/collection/${collectionId}/forms`, {
      formIds,
      operation: 'ADD'
    });
    dispatch(updateCollectionsThunk());
    dispatch(updateFormsThunk());
    dispatch(setSuccessAction('assignCollectionToFormsSuccess', true));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(
      setLoadingAction('assignCollectionToFormsLoading', false, 'assignCollectionToFormsError')
    );
  }
};

export const assignFormToCollectionsThunk = (collectionIds: UuidType[], formId: UuidType, form_version_id: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    dispatch(
      setLoadingAction('assignFormToCollectionsLoading', true, 'assignFormToCollectionsError')
    );
    await putHttp(`/collection/${formId}/assignMany?form_version_id=${form_version_id}`, {
      collectionIds,
      operation: 'ADD'
    });
    //update editing form in redux
    dispatch(updateCollectionsThunk());
    dispatch(updateFormsThunk());
    dispatch(setSuccessAction('assignFormToCollectionsSuccess', true));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(
      setLoadingAction('assignFormToCollectionsLoading', false, 'assignFormToCollectionsError')
    );
  }
};

export const unassignCollectionFromFormsThunk = (
  collectionId: UuidType,
  formIds: UuidType[]
) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    dispatch(
      setLoadingAction(
        'unassignCollectionFromFormsLoading',
        true,
        'unassignCollectionFromFormsError'
      )
    );
    await putHttp(`/collection/${collectionId}/forms`, {
      formIds,
      operation: 'DELETE'
    });
    dispatch(updateCollectionsThunk());
    dispatch(updateFormsThunk());
    dispatch(setSuccessAction('unassignCollectionFromFormsSuccess', true));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(
      setLoadingAction(
        'unassignCollectionFromFormsLoading',
        false,
        'unassignCollectionFromFormsError'
      )
    );
  }
};

export const removeFavoriteCollectionThunk = (collectionId: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    await deleteHttp(`/collection/${collectionId}/remove-from-favourites`);
    dispatch(snackbarAlertAction(`Favorite removed successfully`, 'success'));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('duplicateFormLoading', false, 'duplicateFormError'));
  }
};

export const markFavoriteCollectionThunk = (collectionId: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    await getHttp(`/collection/${collectionId}/mark-as-favourite`);
    dispatch(snackbarAlertAction(`Favorite added successfully`, 'success'));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('duplicateFormLoading', false, 'duplicateFormError'));
  }
};

export const duplicateCollectionsThunk = (base_collection_ids: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    await postHttp(`/collection/duplicateBaseCollection`, {
      "base_collection_ids": base_collection_ids,
      "organization_id": loggedInUser && loggedInUser.organization_id
    });
    dispatch(setSuccessAction('createCollectionSuccess', true));
    dispatch(snackbarAlertAction(`Collections added successfully`, 'success'));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(setLoadingAction('duplicateFormLoading', false, 'duplicateFormError'));
  }
};

/**Get collection status count data */
export const getCollectionStatusCount = (data: any) => async (dispatch: FlowproThunkDispatch) => {
  try {
    const res: any = await postHttp(`/patient/getCollectionStatusCountData`, data);
    dispatch(getCollectionStatusCountAction(res.data));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};
