import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/redux-store';
import { ExitPageLogicList } from '../exitPageSidebarLogic/ExitPageLogicList';

export const ExitPageLogic: React.FC<{}> = ({}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const editingExitPageFieldLogicRules = useSelector(
    (state: RootState) => state.DATA_REDUCER.editingExitPageFieldLogicRules
  );

  const saveChanges = () => {};

  //update redux editingExitPage when changes are made
  //textfield handlers
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {};

  return (
    <Box p={3} className={classes.mainContainer}>
      <ExitPageLogicList />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  mainContainer: {},
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  errorContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '.5rem'
  },
  hintText: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.hint
  },
  fieldTitle: {
    marginBottom: theme.spacing(1)
  }
}));
