import {
  AppBar,
  Box,
  Button,
  Divider,
  makeStyles,
  Theme,
  Toolbar,
  Typography
} from '@material-ui/core';
import React from 'react';
import { colors } from '../../../../styling/colors';
import { Close, Star, StarOutline } from '@material-ui/icons';
import { LoadingButton } from '../../../../components/LoadingButton';
import { NewReferralDialog } from '../../components/NewReferralDialogue';
import { updateReferralThunk, addFavoriteReferralThunk, removeFavoriteReferralThunk } from '../../../../redux/thunk/thunks';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/redux-store';
import { Patients } from '../../../Resources/AssignPatient/Patients';
import { assignmentInfoAction, setEditReferralAction } from '../../../../redux/data/data-actions';
import { ROLES } from '../../../../shared/globals';

interface ReferralTopBarProps {
  handleClose: () => void;
  Edit: (open: boolean) => void;
  referralInfo: any;
}

export const ReferralTopBar = ({ handleClose, Edit, referralInfo }: ReferralTopBarProps) => {
  const classes = useStyles();
  let dispatch = useDispatch();
  const [isEdit, setIsEdit] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [openPatient, setOpenPatient] = React.useState(false);
  const [loadings, setLoadings] = React.useState(false);
  const [referralDialogueOpen, setReferralDialogueOpen] = React.useState(false);
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);

  const data: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.editReferral
  );

  const editReferral = data ? data : referralInfo

  const handelEdit = async (open: boolean) => {
    setIsEdit(open);
    Edit(open);
    if (!open) {
      const info: any = sessionStorage.getItem('referral');
      const data = JSON.parse(info);
      await dispatch(setEditReferralAction(data));
      sessionStorage.removeItem('referral');
    } else {
      sessionStorage.setItem('referral', JSON.stringify(editReferral));
    }
  }

  const handleReferralDialog = () => {
    setReferralDialogueOpen(true)
  }

  const handleReferralDialogClose = () => {
    setReferralDialogueOpen(false)
  }

  // const handleSaveChanges = async () => {
  //   setLoading(true)
  //   const specialities: any = [];
  //   if (editReferral.specialities) {
  //     for (let i = 0; i < editReferral.specialities.length; i++) {
  //       specialities.push(editReferral.specialities[i]?.id ? editReferral.specialities[i].id : editReferral.specialities[i]);
  //     }
  //   }
  //   editReferral.specialities = specialities;
  //   await dispatch(updateReferralThunk(editReferral));
  //   setLoading(false);
  // }

  const handelAddFavorite = async () => {
    setLoadings(true);
    await dispatch(addFavoriteReferralThunk(editReferral.referral_id));
    await dispatch(setEditReferralAction({ ...editReferral, isFavourite: true }));
    setLoadings(false);
  }
  const handelFavoriteCall = () => { }

  const handelRemoveFavorite = async () => {
    setLoadings(true);
    await dispatch(removeFavoriteReferralThunk(editReferral.referral_id));
    await dispatch(setEditReferralAction({ ...editReferral, isFavourite: false }));
    setLoadings(false);
  }

  const assignReferralToPatientClose = () => {
    setOpenPatient(false);
  }

  return (
    <>
      <AppBar square elevation={0} classes={{ root: classes.headerRoot }}>
        <Toolbar classes={{ root: classes.headerToolbar }}>
          <Button
            classes={{ text: classes.closeBtnText }}
            startIcon={<Close />}
            onClick={() => handleClose()}
          >
            Close
          </Button>
          <Box alignSelf="stretch" display="flex" pr={3} py={2.75}>
            <Divider flexItem orientation="vertical" />
          </Box>

          <Box
            display="flex"
            alignItems='center'
            justifyContent="flex-start"
            minHeight="5rem"
            width="100%"
          >
            <Box>
              <Typography color="textSecondary" variant="h2">
                {editReferral.first_name + ' ' + editReferral.last_name} {isEdit &&
                  <img src={process.env.PUBLIC_URL + '/icons/EditIcon.svg'} alt="Edit Icon" onClick={() => handleReferralDialog()} style={{ marginLeft: '20px', cursor: 'pointer' }} />}
              </Typography>
              <Typography className={classes.subtitle}>
                {editReferral.title ? editReferral.title : '-'}
              </Typography>
            </Box>
            {(editReferral && editReferral.isFavourite ? <Star color='primary' style={{ marginLeft: '30px', cursor: 'pointer' }} onClick={() => { loadings ? handelFavoriteCall() : handelRemoveFavorite() }} /> : <StarOutline color='primary' style={{ marginLeft: '30px', cursor: 'pointer' }} onClick={() => { loadings ? handelFavoriteCall() : handelAddFavorite() }} />)}
          </Box>
          {/* <Box display="flex" justifyContent="space-between">
            {isEdit && <LoadingButton
              className={classes.secondaryButton}
              color="secondary"
              variant="contained"
              onClick={(e) => handelEdit(false)}
            >
              Cancel
            </LoadingButton>
            }
            {isEdit ? <LoadingButton loading={loading} disabled={loading}
              className={classes.primaryButton}
              color="primary"
              variant="contained"
              onClick={handleSaveChanges}
            >
              Save
            </LoadingButton> :
              loggedInUser && [ROLES.CLINICIAN].includes(loggedInUser.role) ? <LoadingButton
                className={classes.primaryButton} style={{ minWidth: '140px' }}
                color="primary"
                variant="contained"
                onClick={(e) => assignReferralToPatient()}
              >
                Assign to Patient
              </LoadingButton> :
                <LoadingButton
                  className={classes.primaryButton}
                  color="primary"
                  variant="contained"
                  onClick={(e) => handelEdit(true)}
                >
                  Edit
                </LoadingButton>}
          </Box> */}
        </Toolbar>
        {referralDialogueOpen && (
          <NewReferralDialog open={referralDialogueOpen} handleClose={handleReferralDialogClose}
            info={editReferral} edit={true} />
        )}
      </AppBar>
      {openPatient && <Patients handelClose={assignReferralToPatientClose} />}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  headerRoot: {
    backgroundColor: 'white',
    borderBottom: `2px solid ${theme.palette.divider}`,
    padding: 0
  },
  headerToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 0
  },
  closeBtnText: {
    color: theme.palette.text.secondary,
    marginRight: 18
  },
  editIcon: {
    marginLeft: '10px',
    fontSize: '12px',
    border: '1px solid #c1c1c1',
    color: '#c1c1c1',
    borderRadius: '3px',
  },
  subtitle: {
    color: colors.text6,
    fontWeight: 400
  },
  primaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: '#fff',
    minWidth: '120px'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    marginRight: '1rem',
    color: 'blue',
    minWidth: '120px'
  }
}));
