import { Checkbox, FormControlLabel, FormGroup, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

interface MultipleChoiceFieldBlockProps {
  fieldTitle: any;
  options: { option_text: string }[];
}

export const MultipleChoiceFieldBlock: React.FC<MultipleChoiceFieldBlockProps> = ({
  fieldTitle,
  options
}) => {
  const classes = useStyles();
  return (
    <>
      {fieldTitle && fieldTitle.map((queName: any) => {
        return <Typography variant="subtitle2"><b>{queName ? queName : ''}</b></Typography>
      })}
      <FormGroup>
        {options && options.length > 0 && options.map((option, i) => (
          <FormControlLabel
            key={i}
            classes={{ root: classes.root }}
            disabled={true}
            control={<Checkbox className={classes.checkBox} />}
            label={<span style={{ color: '#000000de' }}>{option.option_text}</span>}
          />
        ))}
      </FormGroup>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  selectRoot: {
    padding: '.6rem'
  },
  checkBox: {
    color: theme.palette.grey[500]
  },
  root: {
    height: '2rem'
  }
}));
