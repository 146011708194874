import {
    getOrganizationsAction, getResourceFileAction, getOrganizationProfileAction
} from './../data/data-actions';
import {
    setErrorAction,
    setSuccessAction,
    snackbarAlertAction
} from './../display/display-actions';
import { getHttp, postHttp } from './../api';
import { RootState, FlowproThunkDispatch } from './../redux-store';
import { push } from 'connected-react-router';

export interface GetOrganizationProps {
    order?: string;
    page?: number;
    pageSize?: number;
    search?: string;
    sort?: string;
}
export const getOrgnizationsThunk = ({
    order = 'DESC',
    page = 0,
    pageSize = 10,
    search = '',
    sort = 'name'
}: GetOrganizationProps) => async (
    dispatch: FlowproThunkDispatch,
    getState: () => RootState
) => {
        try {
            dispatch(setSuccessAction('organizationSuccess', false));
            const organizationList: any = await postHttp(`/org/getOrganizationsList`, {
                "search": search,
                "skip": page * pageSize,
                "limit": pageSize,
                "orgNameSequence": sort == 'name' ? order : '',
                "contactNameSequence": sort == 'contact_name' ? order : '',
                "contactEmailSequence": sort == 'contact_email' ? order : '',
                "statusSequence": sort == 'status' ? order : '',
                "createdAtSequence": sort == "created_at" ? order : '',
            });

            dispatch(setSuccessAction('organizationSuccess', false));
            dispatch(getResourceFileAction([]));
            if (organizationList) {
                organizationList.page = page;
                organizationList.pageSize = pageSize;
                organizationList.sort = sort;
                organizationList.order = order;
                organizationList.search = search;
                dispatch(getOrganizationsAction(organizationList));
            } else {
                dispatch(snackbarAlertAction(`Error loading Resources`, 'error'));
            }
        } catch (e) {
            if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
                dispatch(push('/login'));
            } else {
                dispatch(setSuccessAction('organizationSuccess', false));
                dispatch(snackbarAlertAction(`Error loading Resources: ${e.message}`, 'error'));
            }
        }
    };

export const createOrganizationThunk = (organizationInput: any) => async (
    dispatch: FlowproThunkDispatch,
    getState: () => RootState
) => {
    try {
        const results: any = await postHttp('/org/createOrganization', organizationInput);
        dispatch(setSuccessAction('organizationSuccess', true));
        dispatch(getOrgnizationsThunk({}));
        dispatch(snackbarAlertAction(`Organization saved successfully`, 'success'));
    } catch (e) {
        if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
            dispatch(push('/login'));
        } else {
            dispatch(snackbarAlertAction(`Error creating organization: ${e.message}`, 'error'));
            dispatch(setErrorAction('createStaticResourceError', e.message));
        }
    }
};

export const updateOrganizationThunk = (organizationInput: any) => async (
    dispatch: FlowproThunkDispatch,
    getState: () => RootState
) => {
    try {
        const results: any = await postHttp('/org/updateOrganization', organizationInput);
        dispatch(setSuccessAction('organizationSuccess', true));
        dispatch(getOrgnizationsThunk({}));
        dispatch(snackbarAlertAction(`Updated organization successfully`, 'success'));
    } catch (e) {
        if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
            dispatch(push('/login'));
        } else {
            dispatch(snackbarAlertAction(`Error updating organization: ${e.message}`, 'error'));
            dispatch(setErrorAction('createStaticResourceError', e.message));
        }
    }
};

export const deleteOrganizationThunk = (organization_id: any, check: any) => async (
    dispatch: FlowproThunkDispatch,
    getState: () => RootState
) => {
    try {
        const results: any = await getHttp(`/org/deleteOrganization/${organization_id}`);
        if (results && check) {
            dispatch(push(`/login`));
            localStorage.removeItem('token');
            localStorage.removeItem('formFlash');
            localStorage.removeItem('remember');
        } else {
            dispatch(setSuccessAction('organizationSuccess', true));
            dispatch(getOrgnizationsThunk({}));
            dispatch(snackbarAlertAction(`Delete organization successfully`, 'success'));
        }
    } catch (e) {
        if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
            dispatch(push('/login'));
        } else {
            dispatch(snackbarAlertAction(`Error deleting organization: ${e.message}`, 'error'));
            dispatch(setErrorAction('createStaticResourceError', e.message));
        }
    }
};

export const inviteOrganizationThunk = (organization_id: any, page: any, pageSize: any) => async (
    dispatch: FlowproThunkDispatch,
    getState: () => RootState
) => {
    try {
        const results: any = await getHttp(`/org/inviteOrganization/${organization_id}`);
        dispatch(getOrgnizationsThunk({ page: page, pageSize: pageSize }))
    } catch (e) {
        if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
            dispatch(push('/login'));
        } else {
            dispatch(setErrorAction('createStaticResourceError', e.message));
        }
    }
};

export const cancelInviteOrganizationThunk = (organization_id: any, page: any, pageSize: any) => async (
    dispatch: FlowproThunkDispatch,
    getState: () => RootState
) => {
    try {
        const results: any = await getHttp(`/org/cancelOrganizationInvite/${organization_id}`);
        dispatch(getOrgnizationsThunk({ page: page, pageSize: pageSize }))
        dispatch(snackbarAlertAction(`Cancel organization invite successfully`, 'success'));
    } catch (e) {
        if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
            dispatch(push('/login'));
        } else {
            dispatch(setErrorAction('createStaticResourceError', e.message));
            dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
        }
    }
};

export const activateOrganizationThunk = (organization_id: any, page: any, pageSize: any) => async (
    dispatch: FlowproThunkDispatch,
    getState: () => RootState
) => {
    try {
        const results: any = await getHttp(`/org/activateOrganization/${organization_id}`);
        dispatch(getOrgnizationsThunk({ page: page, pageSize: pageSize }))
        dispatch(snackbarAlertAction(`Activate organization successfully`, 'success'));
    } catch (e) {
        if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
            dispatch(push('/login'));
        } else {
            dispatch(setErrorAction('createStaticResourceError', e.message));
            dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
        }
    }
};

export const deActivateOrganizationThunk = (organization_id: any, page: any, pageSize: any) => async (
    dispatch: FlowproThunkDispatch,
    getState: () => RootState
) => {
    try {
        const results: any = await getHttp(`/org/deactivateOrganization/${organization_id}`);
        dispatch(getOrgnizationsThunk({ page: page, pageSize: pageSize }))
        dispatch(snackbarAlertAction(`De-activate organization successfully`, 'success'));
    } catch (e) {
        if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
            dispatch(push('/login'));
        } else {
            dispatch(setErrorAction('createStaticResourceError', e.message));
            dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
        }
    }
};

export const getOrganizationThunk = (organization_id: any) => async (
    dispatch: FlowproThunkDispatch,
    getState: () => RootState
) => {
    try {
        const results: any = await getHttp(`/org/${organization_id}`);
        dispatch(getOrganizationProfileAction(results.data));
    } catch (e) {
        if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
            dispatch(push('/login'));
        } else {
            dispatch(setErrorAction('createStaticResourceError', e.message));
            dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
        }
    }
};
