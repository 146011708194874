import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as MuiLink, Theme, Typography, makeStyles, Popover, Divider, Paper, Box } from '@material-ui/core';
import { MoreHoriz, Close } from '@material-ui/icons';
import { UuidType } from '../../../../shared/domain';
import { sendInviteToSharedUser, cancelInviteToSharedUser, deleteUsersThunk, getReachCareOrgIDThunk, getSharedUserInfoThunk, sendInviteToReachCareUser } from '../../../../redux/thunk/thunks';
import {
    HeadCell,
    LastSorted,
    Pagination,
    RowCell,
    Table
} from '../../../../components/Table';
import { colors } from '../../../../styling/colors';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { RootState } from '../../../../redux/redux-store';
import { Dialog } from '../../../../components/Dialog';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

interface SharedUserTableProps {
    dataList: any | null;
    selectedUsers?: UuidType[];
    handleDeleteUserButton: (user: any) => void;
    loadSharedUsers: (getUserProps: any) => void;
    setSelectedUsers?: (selectedUsers: UuidType[]) => void;
    viewSharedUserOpen: (info: any, isOpen: boolean) => void;
}

export const SharedUserTable = ({
    dataList, selectedUsers, handleDeleteUserButton,
    loadSharedUsers, setSelectedUsers, viewSharedUserOpen
}: SharedUserTableProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    let history = useHistory();
    const { path } = useRouteMatch();
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const [isFieldShow, setIsFieldShow] = useState(false);
    const split = path.split('/');
    const patient_role_id: any = split && split.length > 1 && split[2];
    const [anchorEl, setAnchorEl] = useState(null);
    const [popuerInfo, setPopuerInfo] = useState<any>(null);
    const [isDelete, setIsDelete] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [lastSorted, setLastSorted] = useState<LastSorted>({
        column: 'first_name',
        order: 'desc'
    });

    const deleteUserLoading = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.deleteUserLoading);

    const headCells: HeadCell[] = [
        {
            id: 'first_name',
            label: 'NAME'
        },
        {
            id: 'role',
            label: 'ROLE'
        },
        {
            id: 'status',
            label: 'STATUS'
        },
        {
            align: 'right',
            id: 'action',
            label: '',
            isSortDisabled: true
        }
    ];

    const reachCareOrgId = useSelector(
        (state: RootState) => state.DATA_REDUCER.reachCareOrgId
    );

    useEffect(() => {
        dispatch(getReachCareOrgIDThunk());
    }, []);

    useEffect(() => {
        if (reachCareOrgId) {
            if (loggedInUser?.organization_id === reachCareOrgId) { setIsFieldShow(true); }
        }
    }, [reachCareOrgId]);

    const handleCloseDialog = () => {
        setIsOpen(false)
    }

    const refActions = (event: any, userInfo: any) => {
        setAnchorEl(event.currentTarget);
        setPopuerInfo(userInfo)
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const patientDelete = () => {
        setIsDelete(true)
        setAnchorEl(null)
    }

    const handleDeleteUser = async () => {
        if (popuerInfo) {
            await dispatch(deleteUsersThunk(popuerInfo.user_role_id, null));
        }
        setIsDelete(false);
    };

    const sendInvite = async () => {
        if (isFieldShow) {
            await dispatch(sendInviteToReachCareUser(popuerInfo.user_role_id));
        } else {
            await dispatch(sendInviteToSharedUser(popuerInfo.user_role_id, popuerInfo.role));
        }
        setIsOpen(true);
        setAnchorEl(null);
    }
    const cancelInvite = () => {
        dispatch(cancelInviteToSharedUser(popuerInfo.user_role_id, popuerInfo.role));
        setAnchorEl(null)
    }

    const handleNavigate = async (value: any) => {
        await dispatch(getSharedUserInfoThunk(value.user_role_id));
        await sessionStorage.setItem('pat_org_id', value.organization_id);
        await sessionStorage.setItem('role', 'SHARED_USER');
        await history.push(`/patient-/${patient_role_id}/sharedUser/${value.user_role_id}/profile`);
    }

    const rowCells = dataList && dataList?.data.map((user: any, index: any) => {
        const TitleLink = (
            <Typography color="primary" variant="h5" style={{ cursor: 'pointer' }} onClick={() => handleNavigate(user)}>
                {user.first_name + ' ' + user.last_name}
            </Typography>
        );
        const strReplace = (
            <Typography variant="h5">{user.status.replace(/_/g, " ")}</Typography>
        )
        const rowCell: RowCell = {
            first_name: { component: TitleLink, value: user.organization_name },
            role: {
                component: user.role
            },
            status: {
                component: strReplace
            },
            action: {
                align: 'right',
                component: <><MoreHoriz className={classes.launchIcon} onClick={(e) => refActions(e, user)} />
                    <Popover className='paper'
                        id={'simple-popover'}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Paper style={{ boxShadow: 'unset' }}>
                            {/* <Typography className={classes.actionTypography} onClick={() => editPatient()}>Edit User</Typography> */}
                            {/* <Divider /> */}
                            {popuerInfo && popuerInfo.status === "AWAITING_ACCEPTANCE" &&
                                <><Typography className={classes.actionTypography} onClick={(e) => cancelInvite()}>Cancel Invite</Typography>
                                    <Divider /></>}
                            {popuerInfo && (popuerInfo.status === "INVITE_NOT_SENT" || popuerInfo.status === "INVITE_EXPIRED" || popuerInfo.status === "INVITE_CANCELLED") &&
                                <><Typography className={classes.actionTypography} onClick={(e) => sendInvite()}>Send Invite</Typography>
                                    <Divider /></>}
                            <Typography color='error' className={classes.actionTypography} onClick={(e) => patientDelete()}>Delete User</Typography>

                        </Paper>
                    </Popover>
                </>
            }
        };

        return Object.assign(rowCell, { content: user });
    });

    const handleNextButton = async () => {
        if (
            dataList &&
            dataList?.page >= 0 &&
            dataList?.page < dataList?.totalCount / dataList?.pageSize - 1
        ) {
            await loadSharedUsers({
                search: dataList?.search,
                page: dataList?.page + 1,
                pageSize: dataList?.pageSize,
                sort: dataList?.sort,
                order: dataList?.order
            });
        }
    };
    const handlePrevButton = async () => {
        if (dataList && dataList?.page > 0) {
            await loadSharedUsers({
                search: dataList?.search,
                page: dataList?.page - 1,
                pageSize: dataList?.pageSize,
                sort: dataList?.sort,
                order: dataList?.order
            });
        }
    };

    const handlePageSize = (
        e: React.ChangeEvent<{
            name?: string; value: unknown;
        }>
    ) => {
        const pageSize: number = e.target.value as number;

        if (dataList && pageSize > 0) {
            loadSharedUsers({
                page: 0,
                pageSize: pageSize,
                search: dataList?.search,
                sort: dataList?.sort,
                order: dataList?.order
            });
        }
    };

    const pagination: Pagination | undefined = dataList
        ? {
            contentName: 'Shared Users',
            page: dataList?.page,
            pageSize: dataList?.pageSize,
            total: dataList?.totalCount,
            handleNextButton: handleNextButton,
            handlePageSize: handlePageSize,
            handlePrevButton: handlePrevButton
        }
        : undefined;

    const handleSort = (sort: string) => {
        const nextDirection = lastSorted.order === 'desc' ? 'asc' : 'desc';

        loadSharedUsers({
            order: sort === lastSorted.column ? nextDirection : 'desc',
            page: 0,
            pageSize: dataList?.pageSize,
            search: dataList?.search,
            sort
        });

        setLastSorted(
            sort === lastSorted.column
                ? { column: sort, order: nextDirection }
                : { column: sort, order: 'desc' }
        );
    };

    return (
        <>
            <Table
                data={dataList}
                headCells={headCells}
                // loading={dataList?.collectionId !== collectionId}
                pagination={pagination}
                rowCells={rowCells}
                // selection={
                //     selectedUsers && setSelectedUsers
                //         ? {
                //             selectedContents: selectedUsers,
                //             setSelectedContents: (selected: UuidType[]) => setSelectedUsers(selected)
                //         }
                //         : undefined
                // }
                sort={{
                    lastSorted: lastSorted,
                    handleSort: handleSort
                }}
            />
            {isDelete &&
                <ConfirmationDialog
                    loading={deleteUserLoading}
                    message={`Are you sure you want to delete? Please type "DELETE" to confirm.`}
                    open={isDelete}
                    title=""
                    handleClose={() => setIsDelete(false)}
                    handleConfirmClick={handleDeleteUser}
                />
            }
            {isOpen &&
                <Dialog handleClose={handleCloseDialog} open={isOpen}>
                    <Box style={{ width: '400px' }}>
                        <Box pb={3} pt={2} px={4} display="flex" justifyContent="space-between">
                            <Typography color="textSecondary" variant="h2">Invitation Success</Typography>
                            <Close onClick={() => setIsOpen(false)} />
                        </Box>
                        <Box pb={6} pt={2} display="flex" justifyContent="center">
                            <Typography color="textSecondary" variant="h2">Invitation Sent</Typography>
                        </Box>
                    </Box>
                </Dialog>
            }
        </>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    collectionBubble: {
        borderRadius: '0.75rem'
    },
    clearIcon: {
        color: colors.text6,
        fontSize: 14,
        marginLeft: '0.25rem'
    },
    launchIcon: {
        fontSize: '2rem',
        cursor: 'pointer'
    },
    actionTypography: {
        padding: '10px',
        fontWeight: 'bold',
        cursor: 'pointer'
    },
}));
