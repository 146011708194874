import { Box, TableBody, TableRow, TableCell, Table, InputBase, MenuItem, Select, FormControl, Button, Typography } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ConfirmationDialog } from '../../../components/ConfirmationDialog';
import { LoadingButton } from '../../../components/LoadingButton';
import { TitledTextInput } from '../../../components/TitledTextInput';
import { RootState } from '../../../redux/redux-store';
import { deleteMetricFieldThunk, autoEditMultiMetricQuestionThunk } from '../../../redux/thunk/thunks';
import { AddNewQuestion } from './AddNewMetricQuestion';

const scaleArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const CalculationPageDisplay = ({ calculationPage, selectedCalPageId }: any) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isUpdate, setUpdate] = useState(false);
    const [addNew, setAddNew] = useState(false);
    const [info, setInfo] = useState<any>(null);
    const [unset, SetUnset] = useState<any>(null);
    const [fieldId, setFieldId] = useState<any>(null);
    const location: any = useLocation();
    const isEdit = location.state;

    const createMetricError = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displayErrors.createMetricError);

    const updateExitPageLoading = useSelector(
        (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.updateExitPageLoading
    );
    const saveCalculationMetricSuccess = useSelector(
        (state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.saveCalculationMetricSuccess
    );
    const editingForm = useSelector((state: RootState) => state.DATA_REDUCER.editingForm);
    const addedMetricFields: any = useSelector((state: RootState) => state.DATA_REDUCER.addedMetricFields);

    useEffect(() => {
        if ((info && info.id) !== selectedCalPageId) {
            setInfo(calculationPage);
            setUpdate(!isUpdate);
        } else if (saveCalculationMetricSuccess) {
            setFieldId(null);
            setAddNew(false);
        }
    })

    if (!calculationPage || !editingForm) {
        return null;
    }

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        info.code = event.target.value;
        calculationPage.code = event.target.value;
        setInfo(info);
        setUpdate(!isUpdate);
        sessionStorage.setItem('onChange', JSON.stringify(calculationPage));
        sessionStorage.setItem('formTab', 'calculation');
    };
    const handleTitleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        info.description = event.target.value;
        calculationPage.description = event.target.value;
        setInfo(info);
        setUpdate(!isUpdate);
        sessionStorage.setItem('onChange', JSON.stringify(calculationPage));
        sessionStorage.setItem('formTab', 'calculation');
    };

    const deleteQuestion = (fieldId: any) => {
        dispatch(deleteMetricFieldThunk(fieldId, info?.id, editingForm?.id))
    }

    const handleClose = () => {
        setAddNew(false);
    }

    const handleChange = (value: any, i: number, comparison_option_id: string) => {
        let regexPattern = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
        const conValue = value.charAt(0) === '-' ? value.slice(1) : value;
        const result = (conValue === '' || conValue === '-') ? true : regexPattern.test(conValue);
        if (result) {
            if ((parseFloat(value) <= 10000.00 && parseFloat(value) >= -10000.00) || (value === '' || value === '-')) {
                const index = addedMetricFields[i]?.fields?.findIndex((item: any) => item.comparison_option_id === comparison_option_id)
                if (index !== -1) {
                    const newValue = (value === '' || value === '-') ? 0 : parseInt(value);
                    addedMetricFields[i].fields[index].calculation_point_amount = value;
                    addedMetricFields[i].fields[index].calculation_operator = 'ADD';
                    // addedMetricFields[i].fields[index].calculation_operator = newValue >= 0 ? 'ADD' : 'SUBTRACT'
                    SetUnset(i);
                    setUpdate(!isUpdate);
                    sessionStorage.setItem('calculation', JSON.stringify(addedMetricFields));
                    sessionStorage.setItem('metric_id', info?.id);
                    sessionStorage.setItem('formTab', 'calculation');
                    sessionStorage.setItem('onChange', JSON.stringify(calculationPage));
                }
            }
        }
    }

    const handleChangeInput = (value: any, i: number, comparison_option_id: string) => {
        const index = addedMetricFields[i].fields.findIndex((item: any) => item.comparison_option_id === comparison_option_id)
        if (index !== -1) { addedMetricFields[i].fields[index].operator_type = value }
        setUpdate(!isUpdate);
        sessionStorage.setItem('calculation', JSON.stringify(addedMetricFields));
        sessionStorage.setItem('metric_id', info?.id);
        sessionStorage.setItem('formTab', 'calculation');
        sessionStorage.setItem('check', 'UPDATE');
        sessionStorage.setItem('onChange', JSON.stringify(calculationPage));
    }

    const handleChangeScale = (value: any, i: number, id: string, queIndex: number) => {
        const index = addedMetricFields[queIndex]?.fields.findIndex((item: any) => item.id === id)
        if (index !== -1) {
            addedMetricFields[queIndex].fields[index].operator_type = value
        }
        setUpdate(!isUpdate);
        sessionStorage.setItem('calculation', JSON.stringify(addedMetricFields));
        sessionStorage.setItem('metric_id', info?.id);
        sessionStorage.setItem('formTab', 'calculation');
        sessionStorage.setItem('check', 'UPDATE');
        sessionStorage.setItem('onChange', JSON.stringify(calculationPage));
    }

    const handleChangeScaleValue = (value: any, i: number, id: string, queIndex: number) => {
        let regexPattern = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
        const conValue = value.charAt(0) === '-' ? value.slice(1) : value;
        const result = (conValue === '' || conValue === '-') ? true : regexPattern.test(conValue);
        if (result) {
            if ((parseFloat(value) <= 10000.00 && parseFloat(value) >= -10000.00) || (value === '' || value === '-')) {
                const index = addedMetricFields[queIndex]?.fields.findIndex((item: any) => item.id === id)
                if (index !== -1) {
                    const newValue = (value === '' || value === '-') ? 0 : parseInt(value);
                    addedMetricFields[queIndex].fields[index].calculation_point_amount = value;
                    addedMetricFields[queIndex].fields[index].calculation_operator = 'ADD';
                    // addedMetricFields[i].fields[index].calculation_operator = newValue >= 0 ? 'ADD' : 'SUBTRACT'
                    SetUnset(i);
                    setUpdate(!isUpdate);
                    sessionStorage.setItem('calculation', JSON.stringify(addedMetricFields));
                    sessionStorage.setItem('metric_id', info?.id);
                    sessionStorage.setItem('formTab', 'calculation');
                    sessionStorage.setItem('onChange', JSON.stringify(calculationPage));
                }
            }
        }
    }

    const autoSaveAnswer = async () => {
        const change: any = sessionStorage.getItem('onChange');
        const metric_id: any = sessionStorage.getItem('metric_id');
        const form_version_id = sessionStorage.getItem('version');
        const getChange = JSON.parse(change);
        if (getChange && getChange?.id) {
            if (addedMetricFields && addedMetricFields.length > 0 && metric_id) {
                const calculation_data: any = [];
                for (let i = 0; i < addedMetricFields.length; i++) {
                    const array: any = [];
                    for (let j = 0; j < addedMetricFields[i].fields.length; j++) {
                        let regexPattern = /^-?[0-9]+$/;
                        let result = regexPattern.test(addedMetricFields[i].fields[j].calculation_point_amount);
                        const info = {
                            "comparison_option_id": addedMetricFields[i].fields[j].comparison_option_id,
                            "comparison_value": addedMetricFields[i].fields[j].comparison_value,
                            "operator_type": addedMetricFields[i].fields[j].operator_type,
                            "calculation_operator": addedMetricFields[i].fields[j].calculation_operator,
                            "calculation_point_amount": addedMetricFields[i].fields[j].calculation_point_amount
                        }
                        array.push(info);
                    }
                    const data = {
                        "form_field_id": addedMetricFields[i].id,
                        "metric_id": metric_id,
                        "calc_data": array,
                        "form_version_id": form_version_id
                    }
                    calculation_data.push(data);
                }
                await dispatch(autoEditMultiMetricQuestionThunk(editingForm?.id, calculation_data, metric_id));
            }
        } else {
            if (addedMetricFields && addedMetricFields.length > 0 && metric_id) {
                const calculation_data: any = [];
                for (let i = 0; i < addedMetricFields.length; i++) {
                    const array: any = [];
                    for (let j = 0; j < addedMetricFields[i].fields.length; j++) {
                        let regexPattern = /^-?[0-9]+$/;
                        let result = regexPattern.test(addedMetricFields[i].fields[j].calculation_point_amount);
                        const info = {
                            "comparison_option_id": addedMetricFields[i].fields[j].comparison_option_id,
                            "comparison_value": addedMetricFields[i].fields[j].comparison_value,
                            "operator_type": addedMetricFields[i].fields[j].operator_type,
                            "calculation_operator": addedMetricFields[i].fields[j].calculation_operator,
                            "calculation_point_amount": addedMetricFields[i].fields[j].calculation_point_amount
                        }
                        array.push(info);
                    }
                    const data = {
                        "form_field_id": addedMetricFields[i].id,
                        "metric_id": metric_id,
                        "calc_data": array,
                        "form_version_id": form_version_id
                    }
                    calculation_data.push(data);
                }
                await dispatch(autoEditMultiMetricQuestionThunk(editingForm?.id, calculation_data, metric_id));
            }
        }
    }

    return (
        <Box p={3} className={classes.mainContainer}>
            <Box pb={2} display='flex' justifyContent='space-between'>
                <Box style={{ width: '100%' }}>
                    <TitledTextInput disabled={isEdit ? false : true}
                        noValidation
                        variant="outlined"
                        size={'small'}
                        title={'Metric Name'}
                        value={info?.code || ''}
                        fullWidth
                        onChange={handleNameChange}
                    />
                    {createMetricError && <Typography color="error" variant="subtitle2">
                        Cannot update! This metric name is already in use!
                    </Typography>}
                </Box>
            </Box>
            <Box pb={4} display='flex' justifyContent='space-between'>
                <Box style={{ width: '100%' }}>
                    <TitledTextInput disabled={isEdit ? false : true}
                        noValidation
                        variant="outlined"
                        size={'small'}
                        multiline
                        rows={5}
                        title={'Interpretation'}
                        fullWidth
                        onChange={handleTitleChange}
                        value={info?.description || ''}
                        inputProps={{
                            maxLength: 2000,
                        }}
                    />
                </Box>
            </Box>
            <Box pb={0}>
                {addedMetricFields && addedMetricFields.map((item: any, index: number) => {
                    return (
                        <Box pb={4}>
                            <Table className={classes.table}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className={classes.tableCell}>
                                            QUESTION: {index + 1}
                                        </TableCell>
                                        <TableCell style={{ width: 160, color: '#848484' }} className={classes.tableCell}>
                                            IS/IS NOT
                                        </TableCell>
                                        <TableCell style={{ width: 160, color: '#848484' }} className={classes.tableCell}>
                                            VALUE
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.tableCell}>
                                            <b>{item.field_prompt}</b>
                                        </TableCell>
                                        <TableCell style={{ width: 160, color: '#848484' }} className={classes.tableCell}>

                                        </TableCell>
                                        <TableCell style={{ width: 160, color: '#848484' }} className={classes.tableCell}>

                                        </TableCell>
                                    </TableRow>
                                    {item.field_type === 'DROPDOWN' && item.form_field_options && item.form_field_options.map((opt: any) => {
                                        const response = item.fields.filter((item: any) => item.comparison_option_id === opt.id);
                                        return (
                                            <TableRow>
                                                <TableCell className={classes.tableCell} style={{ color: '#848484' }}>
                                                    {opt.option_text}
                                                </TableCell>
                                                <TableCell style={{ width: 160, color: '#848484' }} className={classes.tableCell}>
                                                    {isEdit ? <FormControl size="small" fullWidth>
                                                        <Select
                                                            value={response.length > 0 ? response[0].operator_type : ''}
                                                            onChange={(e) => handleChangeInput(e.target.value, index, response.length > 0 ? response[0].comparison_option_id : '')}
                                                        >
                                                            <MenuItem value={'EQUAL_TO'}>IS</MenuItem>
                                                            <MenuItem value={'NOT_EQUAL_TO'}>IS NOT</MenuItem>
                                                        </Select>
                                                    </FormControl> : (response.length > 0 && response[0].operator_type === 'NOT_EQUAL_TO' ? 'IS NOT' : 'IS')}
                                                </TableCell>
                                                <TableCell style={{ width: 160, color: '#848484' }} className={classes.tableCell}>
                                                    {isEdit ? <InputBase disabled={isEdit ? false : true} onChange={(e) => handleChange(e.target.value, index, response.length > 0 ? response[0].comparison_option_id : '')}
                                                        placeholder="Enter Value" value={response.length > 0 ? response[0].calculation_point_amount : ''}
                                                    /> : response.length > 0 ? response[0].calculation_point_amount : ''}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                    {item.field_type === 'MULTIPLE_CHOICE' && item.form_field_options && item.form_field_options.map((opt: any) => {
                                        const response = item.fields.filter((item: any) => item.comparison_option_id === opt.id)
                                        return (
                                            <TableRow>
                                                <TableCell className={classes.tableCell} style={{ color: '#848484' }}>
                                                    {opt.option_text}
                                                </TableCell>
                                                <TableCell style={{ width: 160, color: '#848484' }} className={classes.tableCell}>
                                                    {isEdit ? <FormControl size="small" fullWidth>
                                                        <Select
                                                            value={response.length > 0 ? response[0].operator_type : ''}
                                                            onChange={(e) => handleChangeInput(e.target.value, index, response.length > 0 ? response[0].comparison_option_id : '')}
                                                        >
                                                            <MenuItem value={'EQUAL_TO'}>IS</MenuItem>
                                                            <MenuItem value={'NOT_EQUAL_TO'}>IS NOT</MenuItem>
                                                        </Select>
                                                    </FormControl> : (response.length > 0 && response[0].operator_type === 'NOT_EQUAL_TO' ? 'IS NOT' : 'IS')}
                                                </TableCell>
                                                <TableCell style={{ width: 160, color: '#848484' }} className={classes.tableCell}>
                                                    {isEdit ? <InputBase disabled={isEdit ? false : true} onChange={(e) => handleChange(e.target.value, index, response.length > 0 ? response[0].comparison_option_id : '')}
                                                        placeholder="Enter Value" value={response.length > 0 ? response[0].calculation_point_amount : ''}
                                                    /> : response.length > 0 ? response[0].calculation_point_amount : ''}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                    {item.field_type === 'SINGLE_CHOICE' && item.form_field_options && item.form_field_options.map((opt: any) => {
                                        const response = item.fields.filter((item: any) => item.comparison_option_id === opt.id)
                                        return (
                                            <TableRow>
                                                <TableCell className={classes.tableCell} style={{ color: '#848484' }}>
                                                    {opt.option_text}
                                                </TableCell>
                                                <TableCell style={{ width: 160, color: '#848484' }} className={classes.tableCell}>
                                                    {isEdit ? <FormControl size="small" fullWidth>
                                                        <Select
                                                            value={response.length > 0 ? response[0].operator_type : ''}
                                                            onChange={(e) => handleChangeInput(e.target.value, index, response.length > 0 ? response[0].comparison_option_id : '')}
                                                        >
                                                            <MenuItem value={'EQUAL_TO'}>IS</MenuItem>
                                                            <MenuItem value={'NOT_EQUAL_TO'}>IS NOT</MenuItem>
                                                        </Select>
                                                    </FormControl> : (response.length > 0 && response[0].operator_type === 'NOT_EQUAL_TO' ? 'IS NOT' : 'IS')}
                                                </TableCell>
                                                <TableCell style={{ width: 160, color: '#848484' }} className={classes.tableCell}>
                                                    {isEdit ? <InputBase disabled={isEdit ? false : true} onChange={(e) => handleChange(e.target.value, index, response.length > 0 ? response[0].comparison_option_id : '')}
                                                        placeholder="Enter Value" value={response.length > 0 ? response[0].calculation_point_amount : ''}
                                                    /> : response.length > 0 ? response[0].calculation_point_amount : ''}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                    {item.field_type === 'SCALE' && scaleArray.map((opt: any, i: number) => {
                                        const response = item.fields.filter((val: any) => val.comparison_value === opt)
                                        return (item.option_scale_max < opt ? '' :
                                            <TableRow>
                                                <TableCell className={classes.tableCell} style={{ color: '#848484' }}>
                                                    {opt}
                                                </TableCell>
                                                <TableCell style={{ width: 160, color: '#848484' }} className={classes.tableCell}>
                                                    {isEdit ? <FormControl size="small" fullWidth>
                                                        <Select
                                                            value={response.length > 0 ? response[0].operator_type : ''}
                                                            onChange={(e) => handleChangeScale(e.target.value, i, response.length > 0 ? response[0].id : '', index)}
                                                        >
                                                            <MenuItem value={'EQUAL_TO'}>IS</MenuItem>
                                                            <MenuItem value={'NOT_EQUAL_TO'}>IS NOT</MenuItem>
                                                        </Select>
                                                    </FormControl> : (response.length > 0 && response[0].operator_type === 'NOT_EQUAL_TO' ? 'IS NOT' : 'IS')}
                                                </TableCell>
                                                <TableCell style={{ width: 160, color: '#848484' }} className={classes.tableCell}>
                                                    {isEdit ? <InputBase disabled={isEdit ? false : true} onChange={(e) => handleChangeScaleValue(e.target.value, i, response.length > 0 ? response[0].id : '', index)}
                                                        placeholder="Enter Value" value={response.length > 0 ? response[0].calculation_point_amount : ''}
                                                    /> : response.length > 0 ? response[0].calculation_point_amount : ''}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                    {isEdit && <TableRow>
                                        <TableCell className={classes.tableCell1} style={{ textAlign: 'center' }} colSpan={3}>
                                            <Button style={{ color: 'red', padding: 0, height: 'auto' }} onClick={() => { autoSaveAnswer(); deleteQuestion(item.id) }}>Remove Question</Button>
                                        </TableCell>
                                        {/* <TableCell className={classes.tableCell1} style={{ textAlign: 'center' }}>
                                            <Button style={{ color: '#0060B2', padding: 0, height: 'auto' }} onClick={() => saveChanges(item.id)}>Save Changes</Button>
                                        </TableCell> */}
                                    </TableRow>}
                                </TableBody>
                            </Table>
                        </Box>
                    )
                })}
            </Box>
            {isEdit && <LoadingButton
                onClick={() => { autoSaveAnswer(); setAddNew(true) }}
                variant="contained"
                fullWidth
                disabled={updateExitPageLoading}
                color={'primary'}
                loading={updateExitPageLoading}
                aria-label={'save changes'}
            >
                Add New Question to Metric
            </LoadingButton>}
            {/* {fieldId && (
                <ConfirmationDialog
                    loading={saveCalculationMetricSuccess}
                    message='Are you sure you want to delete the selected calculation question.'
                    open={fieldId ? true : false}
                    title="Calculation"
                    handleClose={closeDeleteDialog}
                    handleConfirmClick={deleteQuestion}
                />
            )} */}
            {addNew &&
                <AddNewQuestion metric_id={info?.id} handleClose={handleClose} />
            }
        </Box>
    );
};

const useStyles = makeStyles((theme) => ({
    mainContainer: {},
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    title: {
        marginBottom: theme.spacing(1)
    },
    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    errorContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '.5rem'
    },
    hintText: {
        marginTop: theme.spacing(1),
        color: theme.palette.text.hint
    },
    fieldTitle: {
        marginBottom: theme.spacing(1)
    },
    table: {
        border: '1px solid #cdcdcd',
        borderRadius: '10px'
    },
    tableCell: {
        border: '1px solid #cdcdcd',
        borderRadius: '10px',
        padding: '0.40rem',
        height: '25px',
        fontSize: '14px'
    },
    tableCell1: {
        border: 0,
        fontWeight: 600,
        borderRadius: '10px',
        padding: '0.40rem',
        height: '25px'
    },
    primaryButton: {
        boxShadow:
            '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)',
        marginBottom: '40px'
    },
}));
