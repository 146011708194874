import React, { ChangeEvent, useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Box, TextField, Theme, debounce, makeStyles, CircularProgress } from '@material-ui/core';

interface SearchBarProps {
  initialValue?: string;
  handleSearch: (value: string) => void;
  setInitialValue?: (value: string) => void;
}

export const SearchBar = ({ initialValue, handleSearch, setInitialValue }: SearchBarProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [handleSearch]);

  useEffect(() => {
    if (initialValue) {
      // Need this timeout to make the loading work properly when we have initialValue sent by parent
      setTimeout(() => {
        setLoading(true);
      }, 0);
    }
  }, [initialValue]);

  const onSearch = (value: string) => {
    if (setInitialValue) {
      setInitialValue(value);
    }
    setLoading(true);
    handleSearch(value);
  };

  const debouncedSearch = debounce(onSearch, 500);

  const onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    debouncedSearch(e.target.value);
  };

  return (
    <TextField
      inputProps={{
        maxLength: 150,
      }}
      InputProps={{
        startAdornment: (
          <Box ml={-0.5} mr={1} mt={0.5}>
            {!loading ? (
              <SearchIcon className={classes.searchIcon} color="disabled" />
            ) : (
              <CircularProgress size={18} />
            )}
          </Box>
        )
      }}
      className={classes.searchTextField}
      defaultValue={initialValue}
      onChange={onChange}
      placeholder="Search..."
      size="small"
      variant="outlined"
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  searchTextField: {
    backgroundColor: theme.palette.secondary.main,
    fontSize: theme.typography.h5.fontSize,
    height: '2rem',
    width: '14rem'
  },
  searchIcon: {
    fontSize: 17
  }
}));
