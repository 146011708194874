import React, { useState, useEffect } from 'react';
import {
    Box, Typography, Grid, TextField, Chip,
    DialogTitle, DialogContent, DialogContentText, DialogActions, ListItem, ListItemIcon, Checkbox, ListItemText
} from '@material-ui/core';
import Popper, { PopperPlacementType } from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import MUIDialog from '@material-ui/core/Dialog'
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Clear } from '@material-ui/icons';
import { LoadingButton } from '../../../../../components/LoadingButton';
import { createTagThunk, updateFormThunk } from '../../../../../redux/thunk/thunks';
import { RootState } from '../../../../../redux/redux-store';
import { addNewTag } from '../../../../../redux/data/data-actions';

interface openDialogProps {
    form: any,
    openTagDomain: boolean;
    handleClose: () => void;
    info: number;
}

interface FilmOptionType {
    title: string;
    id: string;
}

export const TagDomain = ({ form, openTagDomain, handleClose, info }: openDialogProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isCheck, setIsCheck] = useState(false);
    const [domains, setDomains] = useState<any>(form ? form.domains : []);
    const [collections, setCollections] = useState<any>(form ? form.collections : []);
    const [domain, setDomain] = useState<string[]>([]);
    const [collection, setCollection] = useState<string[]>([]);
    const [tags, setTags] = useState<any>(form ? form.tags : []);
    const [tagIds1, setTagIds1] = useState<any>([]);
    const [tagIds, setTagIds] = useState<any>([]);
    const [isAdd, setIsAdd] = useState(false);
    const [isRemove, setIsRemove] = useState(false);
    const [isError, setIsError] = useState<any>(null);
    const [isNumber, setIsNumber] = useState<any>(info);
    const [tagValue, setTagValue] = useState('');
    const [searchTag, setSearchTag] = useState('');
    const [open, toggleOpen] = useState(false);
    const [dialogValue, setDialogValue] = useState({
        title: '',
        id: '',
    });
    const [anchorEl1, setAnchorEl1] = React.useState<HTMLButtonElement | null>(null);
    const [open1, setOpen1] = React.useState(false);
    const [placement, setPlacement] = React.useState<PopperPlacementType>();
    const [anchorEl2, setAnchorEl2] = React.useState<HTMLButtonElement | null>(null);
    const [open2, setOpen2] = React.useState(false);
    const [placement2, setPlacement2] = React.useState<PopperPlacementType>();


    const primaryDomainList = useSelector(
        (state: RootState) => state.DATA_REDUCER.primaryDomains
    );
    const tagLists: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.tagList
    );
    const allCollection: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.collectionList
    );

    const [tagList, setTagList] = useState<any>(tagLists ? tagLists : []);
    const [sortTagList, setSortTagList] = useState<any>();
    const [primaryDomains, setPrimaryDomainList] = useState<any>(primaryDomainList ? primaryDomainList : []);
    const [collectionList, setCollectionList] = useState<any>(allCollection ? allCollection : []);

    const newlyAddedTag: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.tag
    );
    const success: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.updateFormFieldSuccess)
    useEffect(() => {
        if (success) {
            handleClose();
        }
    })
    useEffect(() => {
        for (let i = 0; i < form.domains.length; i++) {
            domain.push(form.domains[i].id);
        }
        for (let i = 0; i < form.tags.length; i++) {
            tagIds.push(form.tags[i].id);
        }
        for (let i = 0; i < form.collections.length; i++) {
            collection.push(form.collections[i].id);
        }
    }, [])
    useEffect(() => {
        if (newlyAddedTag) {
            tagIds.push(newlyAddedTag?.id);
            setTagIds(tagIds);
            setSortTagList(tagLists);
            setTagList(tagLists);
            setIsAdd(!isAdd);
            dispatch(addNewTag(null));
            setAnchorEl2(null);
            setOpen2(false);
        }
    })

    const handelCloseDialog = () => {
        setDialogValue({
            title: '',
            id: '',
        });
        toggleOpen(false);
    }

    const handleChange = async (value: any, check: boolean) => {
        if (domain?.length > 2 && !check) { setIsError(3) } else if (!check) {
            domain.push(value);
            setDomain(domain);
            setIsCheck(!isCheck);
            setIsError(null);
        } else {
            const array = await domain.filter((id: any) => id !== value);
            const array1 = await domains.filter((id: any) => id.id !== value);
            setDomain(array);
            setDomains(array1);
            setIsCheck(!isCheck);
            setIsError(null);
        }
    };

    const handleClickOpen = (newPlacement: PopperPlacementType) => (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        setAnchorEl1(event.currentTarget);
        setOpen1((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const handleClickTag = (event: React.MouseEvent<HTMLButtonElement>, newPlacement: PopperPlacementType) => {
        setSortTagList(tagLists);
        setAnchorEl2(event.currentTarget);
        setOpen2((prev) => placement2 !== newPlacement || !prev);
        setPlacement2(newPlacement);
    };

    const handleChangeTag = async (e: any) => {
        setSearchTag(e.target.value);
        const filtered = await tagList.filter((str: any) => str?.title.toLowerCase().includes(e.target.value.toLowerCase()));
        setSortTagList(filtered);
        if (e?.target?.value[e?.target?.value?.length - 1] === " ") {
            setOpen2(true);
        }
        if (!open1) {
            setOpen2(true);
        }
        setIsAdd(!isAdd);
    }

    const handleChangeCollection = async (value: any, check: boolean) => {
        if (!check) {
            collection.push(value);
            setCollection(collection);
            setIsCheck(!isCheck);
        } else {
            const array = await collection.filter((id: any) => id !== value);
            const array1 = await collections.filter((e: any) => e.id !== value);
            setCollection(array);
            setCollections(array1);
            setIsCheck(!isCheck);
        }
    };

    const addDomain = () => {
        const data = {
            title: form.title,
            description: form.description,
            entry_page_header: form.entry_page_header,
            entry_page_body: form.entry_page_body,
            entry_page_video_url: form.entry_page_video_url,
            tags: tagIds,
            domains: domain,
            collections: collection
        }
        dispatch(updateFormThunk(form.id, data));
    }

    const addCollection = () => {
        const data = {
            title: form.title,
            description: form.description,
            entry_page_header: form.entry_page_header,
            entry_page_body: form.entry_page_body,
            entry_page_video_url: form.entry_page_video_url,
            tags: tagIds,
            domains: domain,
            collections: collection
        }
        dispatch(updateFormThunk(form.id, data));
    }

    const addTag = () => {
        if (tagIds && tagIds.length === 11) {
            setIsError(3);
        } else {
            const data = {
                title: form.title,
                description: form.description,
                entry_page_header: form.entry_page_header,
                entry_page_body: form.entry_page_body,
                entry_page_video_url: form.entry_page_video_url,
                tags: tagIds,
                domains: domain,
                collections: collection
            }
            dispatch(updateFormThunk(form.id, data));
        }
    }

    const handleCheckTag = async (value: any, check: boolean) => {
        if (tagIds?.length > 10 && !check) { setIsError(3) } else if (!check) {
            tagIds.push(value);
            setTagIds(tagIds);
            setIsCheck(!isCheck);
            setIsError(null);
        } else {
            const array = await tagIds.filter((id: any) => id !== value);
            setTagIds(array);
            setIsCheck(!isCheck);
            setIsError(null);
        }
    };

    const saveNewTag = async () => {
        if (tagValue.trim() === '') {

        } else {
            await dispatch(createTagThunk(tagValue))
            handelCloseDialog();
            setTagValue('');
            setSearchTag('');
        }
    }

    const removeDomain = (id: any) => {
        const domain1 = domains.filter((e: any) => e.id !== id);
        const domain2 = domain.filter((e: any) => e !== id);
        setDomains(domain1)
        setDomain(domain2)
        setIsAdd(!isAdd)
    }

    const removeCollection = (id: any) => {
        const collection1 = collections.filter((e: any) => e.id !== id);
        const collection2 = collection.filter((e: any) => e !== id);
        setCollections(collection1)
        setCollection(collection2)
        setIsAdd(!isAdd)
    }

    const removeTag = (id: any) => {
        setIsRemove(true);
        const tag = tags.filter((e: any) => e.id !== id);
        const tag1 = tagIds.filter((e: any) => e !== id);
        setTagIds1(tag1);
        setTagIds(tag1);
        setTags(tag);
        setIsAdd(isAdd ? false : true);
    }

    const removeTags = () => {
        const data = {
            title: form.title,
            description: form.description,
            entry_page_header: form.entry_page_header,
            entry_page_body: form.entry_page_body,
            entry_page_video_url: form.entry_page_video_url,
            tags: tagIds1,
            domains: domain
        }
        dispatch(updateFormThunk(form.id, data));
        setIsAdd(isAdd ? false : true);
    }

    return (
        <>
            <MUIDialog open={openTagDomain} classes={{ paper: classes.paper }}>
                <Box style={{ background: '#F9FAFB', borderRadius: '0.625rem 0.625rem 0 0' }}>
                    <Box px={4} py={3}>
                        <Typography color="textSecondary" variant="h2">
                            {isNumber === 1 ? 'Select Primary Domain' : (isNumber === 2 ? 'Select Tags' : 'Add to Collections')}
                        </Typography>
                    </Box>
                </Box>
                <Grid container className={classes.box}>
                    <Grid item xs={12} md={6}>
                        {isNumber === 1 ?
                            <Box pb={2.5} mr={2}>
                                <Box pb={0.75}>
                                    <Typography color="textSecondary" variant="subtitle2">
                                        Select Primary Domains
                                    </Typography>
                                </Box>
                                <LoadingButton onClick={handleClickOpen('bottom')} style={{ display: 'flex', justifyContent: 'flex-start', overflow: 'hidden', width: '-webkit-fill-available', height: '35px', border: '1px solid #c1c1c1' }}>
                                    {domain && domain.map((id: any) => {
                                        const info = primaryDomains.filter((item: any) => item.id === id);
                                        return (
                                            <Chip key={id} label={info[0].title} className={classes.chip} />
                                        )
                                    })}
                                    {domain && domain.length === 0 && domains && domains.map((info: any) => {
                                        return (
                                            <Chip key={info.id} label={info.title} className={classes.chip} />
                                        )
                                    })}
                                </LoadingButton>
                                <Popper open={open1} anchorEl={anchorEl1} placement={'bottom'} transition style={{ zIndex: 2000 }}>
                                    {({ TransitionProps }) => (
                                        <Fade {...TransitionProps} timeout={350}>
                                            <Paper style={{ maxHeight: '300px', minHeight: '200px', minWidth: '370px', maxWidth: '370px', overflow: 'auto' }}>
                                                {primaryDomains && primaryDomains.map((item: any) => {
                                                    const checked = domain.filter((id: any) => id === item.id);
                                                    return (
                                                        <ListItem key={item.id} role={undefined} dense button onClick={() => handleChange(item.id, checked?.length > 0 ? true : false)}>
                                                            <ListItemIcon style={{ minWidth: 'auto' }}>
                                                                <Checkbox color='primary'
                                                                    edge="start"
                                                                    checked={checked?.length > 0 ? true : false}
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText id={item.id} primary={<Typography color="textSecondary" variant="subtitle2">{item.title}</Typography>} />
                                                        </ListItem>
                                                    )
                                                })}
                                            </Paper>
                                        </Fade>
                                    )}
                                </Popper>
                                {isError ? (
                                    <Typography color="error" variant="subtitle2">
                                        {isError === 1 ? 'Domain already selected' : (isError === 3 ? 'Add only 3 domains' : 'Please select domain')}
                                    </Typography>
                                ) : null}
                            </Box>
                            :
                            (isNumber === 2 ? <Box pb={2.5} mr={2}>
                                <Box pb={0.75}>
                                    <Typography color="textSecondary" variant="subtitle2">
                                        Select Tag / Add Tag
                                    </Typography>
                                </Box>
                                <button style={{ background: 'transparent', border: 'unset', width: '100%', padding: '0px', margin: '0px' }} onClick={(e: any) => handleClickTag(e, 'bottom')}><TextField id="searchTag" variant="outlined" size='small' value={searchTag} fullWidth onInput={(e) => handleChangeTag(e)} /></button>
                                <Popper open={open2} anchorEl={anchorEl2} placement={'bottom'} transition style={{ zIndex: 2000 }}>
                                    {({ TransitionProps }) => (
                                        <Fade {...TransitionProps} timeout={350}>
                                            <Paper style={{ maxHeight: '300px', minHeight: '200px', minWidth: '370px', maxWidth: '370px', overflow: 'auto' }}>
                                                {sortTagList && sortTagList.length > 0 ? sortTagList.map((item: any) => {
                                                    const checked = tagIds.filter((id: any) => id === item.id);
                                                    return (
                                                        <ListItem key={item.id} role={undefined} dense button onClick={() => handleCheckTag(item.id, checked?.length > 0 ? true : false)}>
                                                            <ListItemIcon style={{ minWidth: 'auto' }}>
                                                                <Checkbox color='primary'
                                                                    edge="start"
                                                                    checked={checked?.length > 0 ? true : false}
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText id={item.id} primary={<Typography color="textSecondary" variant="subtitle2">{item.title}</Typography>} />
                                                        </ListItem>
                                                    )
                                                }) :
                                                    <LoadingButton
                                                        className={classes.secondaryButton}
                                                        variant="contained"
                                                        style={{ margin: '10px' }}
                                                        onClick={() => { toggleOpen(true); setOpen2(false); setAnchorEl2(null); setTagValue(searchTag) }}
                                                    >
                                                        Add New Tag: {searchTag}
                                                    </LoadingButton>
                                                }
                                            </Paper>
                                        </Fade>
                                    )}
                                </Popper>
                                {isError ? (
                                    <Typography color="error" variant="subtitle2">
                                        {isError === 1 ? 'Tag already selected' : (isError === 3 ? 'Add only 10 tags' : 'Please select tag')}
                                    </Typography>
                                ) : null}
                            </Box> :
                                <Box pb={2.5} mr={2}>
                                    <Box pb={0.75}>
                                        <Typography color="textSecondary" variant="subtitle2">
                                            Add to Collections
                                        </Typography>
                                    </Box>
                                    <LoadingButton onClick={handleClickOpen('bottom')} style={{ display: 'flex', justifyContent: 'flex-start', overflow: 'hidden', width: '-webkit-fill-available', height: '35px', border: '1px solid #c1c1c1' }}>
                                        {collection && collection.map((id: any) => {
                                            const info = collectionList.filter((item: any) => item.id === id);
                                            return (
                                                <Chip key={id} label={info[0].name} className={classes.chip} />
                                            )
                                        })}
                                        {collection && collection.length === 0 && collections && collections.map((info: any) => {
                                            return (
                                                <Chip key={info.id} label={info.name} className={classes.chip} />
                                            )
                                        })}
                                    </LoadingButton>
                                    <Popper open={open1} anchorEl={anchorEl1} placement={'bottom'} transition style={{ zIndex: 2000 }}>
                                        {({ TransitionProps }) => (
                                            <Fade {...TransitionProps} timeout={350}>
                                                <Paper style={{ maxHeight: '300px', minHeight: '200px', minWidth: '370px', maxWidth: '370px', overflow: 'auto' }}>
                                                    {collectionList && collectionList.map((item: any) => {
                                                        const checked = collection.filter((id: any) => id === item.id);
                                                        return (
                                                            <ListItem key={item.id} role={undefined} dense button onClick={() => handleChangeCollection(item.id, checked?.length > 0 ? true : false)}>
                                                                <ListItemIcon style={{ minWidth: 'auto' }}>
                                                                    <Checkbox color='primary'
                                                                        edge="start"
                                                                        checked={checked?.length > 0 ? true : false}
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText id={item.id} primary={<Typography color="textSecondary" variant="subtitle2">{item.name}</Typography>} />
                                                            </ListItem>
                                                        )
                                                    })}
                                                </Paper>
                                            </Fade>
                                        )}
                                    </Popper>
                                    {isError ? (
                                        <Typography color="error" variant="subtitle2">
                                            {isError === 1 ? 'Collection already selected' : (isError === 3 ? 'Add only 3 collection' : 'Please select collection')}
                                        </Typography>
                                    ) : null}
                                </Box>)
                        }
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box pb={0.5}>
                            <Typography color="textSecondary" variant="h4">
                                {isNumber === 1 ? 'Current Primary Domain' : (isNumber === 2 ? 'Current Tags' : 'Current Collections')}
                            </Typography>
                        </Box>
                        {isNumber === 1 ?
                            <div className={classes.otherActions}>
                                {domains && domains.map((item: any) => {
                                    return (
                                        <Chip
                                            key={''}
                                            classes={{ root: classes.collectionChip }
                                            }
                                            size="small"
                                            label={
                                                < div className={classes.chipAlign}>
                                                    {item.title}
                                                    <Clear className={classes.clearIcon} onClick={(e) => removeDomain(item.id)} />
                                                </div>
                                            }
                                        />)
                                })}
                            </div> :
                            (isNumber === 2 ? <div className={classes.otherActions}>
                                {tags && tags.map((item: any) => {
                                    return (
                                        <Chip
                                            key={''}
                                            classes={{ root: classes.collectionChip }
                                            }
                                            size="small"
                                            label={
                                                <div className={classes.chipAlign}>
                                                    {item.title}
                                                    <Clear className={classes.clearIcon} onClick={(e) => removeTag(item.id)} />
                                                </div>
                                            }
                                        />)
                                })}
                            </div> :
                                <div className={classes.otherActions}>
                                    {collections && collections.map((item: any) => {
                                        return (
                                            <Chip
                                                key={''}
                                                classes={{ root: classes.collectionChip }
                                                }
                                                size="small"
                                                label={
                                                    <div className={classes.chipAlign}>
                                                        {item.name}
                                                        <Clear className={classes.clearIcon} onClick={(e) => removeCollection(item.id)} />
                                                    </div>
                                                }
                                            />)
                                    })}
                                </div>)
                        }
                    </Grid>
                </Grid>
                <Box mt={2} px={4} py={2} style={{ background: '#F9FAFB', borderRadius: '0 0 0.625rem 0.625rem' }}>
                    <Grid container>
                        <Grid item xs={6} style={{ textAlign: 'left' }}>
                            <LoadingButton
                                className={classes.secondaryButton}
                                variant="contained"
                                style={{ marginBottom: '0px' }}
                                onClick={handleClose}
                            >
                                Cancel
                            </LoadingButton>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: 'right' }}>
                            {isRemove ? <LoadingButton color="primary"
                                className={classes.primaryButton}
                                onClick={(e) => removeTags()}
                                variant="contained"
                                style={{ marginBottom: '0px' }}
                            >
                                Save
                            </LoadingButton> :
                                <LoadingButton color="primary"
                                    className={classes.primaryButton}
                                    onClick={(e) => { isNumber === 1 ? addDomain() : (isNumber === 2 ? addTag() : addCollection()) }}
                                    variant="contained"
                                    style={{ marginBottom: '0px' }}
                                >
                                    Save
                                </LoadingButton>}
                        </Grid>
                    </Grid>
                </Box>
            </MUIDialog>
            {
                <MUIDialog open={open}>
                    <DialogTitle id="form-dialog-title">Add a new tag</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Did you miss any tag in tag list? Please, add it!
                        </DialogContentText>
                        <Box pb={2.5}>
                            <Box pb={0.75}>
                                <Typography color="textSecondary" variant="subtitle2">
                                    Tag
                                </Typography>
                            </Box>
                            <TextField fullWidth variant='outlined' size='small'
                                autoFocus
                                value={tagValue}
                                onChange={(event) => setTagValue(event.target.value)}
                                type="text"
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'space-around' }}>
                        <LoadingButton
                            className={classes.secondaryButton}
                            variant="contained"
                            style={{ marginBottom: '0px' }}
                            onClick={handelCloseDialog}
                        >
                            Cancel
                        </LoadingButton>
                        <LoadingButton color="primary"
                            className={classes.primaryButton}
                            variant="contained"
                            style={{ marginBottom: '0px' }}
                            onClick={(e) => saveNewTag()}
                        >
                            Add
                        </LoadingButton>
                    </DialogActions>
                </MUIDialog>
            }
        </>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    topBox: {
        borderRadius: '0.625rem 0.625rem 0 0'
    },
    paper: { minWidth: "850px" },
    bottomBox: {
        borderRadius: '0 0 0.625rem 0.625rem'
    },
    primaryButton: {
        boxShadow:
            '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)'
    },
    secondaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)'
    },
    inputField: {
        maxWidth: '28rem',
        width: '80vw',
        paddingBottom: '0.5rem'
    },
    textField: {
        width: '100%'
    },
    textAlign: {
        textAlign: 'center'
    },
    box: {
        padding: '30px',
        width: '-webkit-fill-available',
        height: '200px'
    },
    chipAlign: {
        display: 'flex',
        alignItems: 'center'
    },
    otherActions: {
        marginLeft: '.6rem',
        alignItems: 'center'
    },
    collectionChip: {
        marginRight: theme.spacing(1),
        marginBottom: '4px',
        backgroundColor: theme.background.main
    },
    closeBtnText: {
        color: theme.palette.text.secondary
    },
    clearIcon: {
        fontSize: 14,
        marginLeft: '0.25rem',
        cursor: 'pointer'
    },
    chips: {
        textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden',

    },
    chip: {
        margin: 2, height: '25px', backgroundColor: theme.background.main
    },
    textField1: {
        width: '100%',
        height: '35px',
        padding: '0px'
    },
}));
