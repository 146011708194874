import React, { useState } from 'react';
import { Theme, Typography, Box, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { switchRoleAccountThunk } from '../../../../../redux/thunk/thunks';
import { HeadCell, LastSorted, Pagination, RowCell, Table } from '../../../../../components/Table';
import { ROLES } from '../../../../../shared/globals';
import { RootState } from '../../../../../redux/redux-store';
import { Dialog } from '../../../../../components/Dialog';
import { LoadingButton } from '../../../../../components/LoadingButton';
import { colors } from '../../../../../styling/colors';

interface RolesUsersTableProps {
  usersData: any;
  loadUsers: (roleAccountProps: any) => void;
}

export const RolesUsersTable = ({
  usersData,
  loadUsers
}: RolesUsersTableProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [switchAccount, setSwitchAccount] = useState(false);
  const [accountInfo, setAccountInfo] = useState<any>(null);
  const logedUser: any = localStorage.getItem('formFlash');
  const userRoleId = JSON.parse(logedUser);

  const [lastSorted, setLastSorted] = useState<any>({
    column: 'role',
    order: 'DESC'
  });

  const headCells: HeadCell[] = [
    {
      id: 'role',
      label: 'ROLE'
    },
    {
      id: 'organization_name',
      label: 'Institution'
    },
    {
      id: 'associated_user',
      label: 'Associated User'
    }
  ];
  const rowCells = usersData && usersData.data.map((user: any) => {
    const TitleLink = (userRoleId.id === user.id ?
      <Typography style={{ color: '#6B7281' }} variant="h5">
        {user.role && user.role.replace(/_/g, " ")}
      </Typography> :
      <Typography color="primary" variant="h5" style={{ cursor: 'pointer' }} onClick={() => viewAccountsOpen(user)}>
        {user.role && user.role.replace(/_/g, " ")}
      </Typography>
    );
    const rowCell: RowCell = {
      role: {
        component: TitleLink,
        value: user.role && user.role.replace(/_/g, " ")
      },
      organization_name: {
        value: user.organization_name ? user.organization_name : '-'
      },
      associated_user: {
        value: user.associated_user ? user.associated_user : '-'
      }
    };
    return Object.assign(rowCell, { content: user });
  });

  const handleNextButton = async () => {
    if (
      usersData &&
      usersData.page >= 0 &&
      usersData.page < usersData.totalCount / usersData.pageSize - 1
    ) {
      await loadUsers({
        page: usersData.page + 1,
        pageSize: usersData.pageSize,
        sort: usersData.sort,
        order: usersData.order
      });
    }
  };
  const handlePrevButton = async () => {
    if (usersData && usersData.page > 0) {
      await loadUsers({
        page: usersData.page - 1,
        pageSize: usersData.pageSize,
        sort: usersData.sort,
        order: usersData.order
      });
    }
  };

  const handlePageSize = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>
  ) => {
    const pageSize: number = e.target.value as number;

    if (usersData && pageSize > 0) {
      loadUsers({
        page: 0,
        pageSize,
        sort: usersData.sort,
        order: usersData.order
      });
    }
  };

  const pagination: Pagination | undefined = usersData
    ? {
      contentName: 'Roles',
      page: usersData.page,
      pageSize: usersData.pageSize,
      total: usersData.totalCount,
      handleNextButton: handleNextButton,
      handlePageSize: handlePageSize,
      handlePrevButton: handlePrevButton
    }
    : undefined;

  const handleSort = (sort: string) => {
    if (usersData) {
      const nextDirection = lastSorted.order === 'desc' ? 'asc' : 'desc';

      loadUsers({
        page: 0,
        pageSize: usersData.pageSize,
        sort,
        order: sort === lastSorted.column ? nextDirection : 'desc'
      });

      setLastSorted(
        sort === lastSorted.column
          ? { column: sort, order: nextDirection }
          : { column: sort, order: 'desc' }
      );
    }
  };

  const viewAccountsOpen = (accountInfo: any) => {
    setSwitchAccount(true);
    setAccountInfo(accountInfo);
  }

  const switchAccountRole = () => {
    history.push('/switch-role');
    dispatch(switchRoleAccountThunk({ loged_user_role_id: userRoleId.id, switch_user_role_id: accountInfo.id }));
  }

  const handleClose = () => {
    setSwitchAccount(false)
  }

  return (
    <>
      {switchAccount &&
        <Dialog open={switchAccount} handleClose={handleClose}>
          <Box display="flex" flexDirection="column" style={{ textAlign: 'center' }} width={500}>
            <Box
              alignItems="center"
              bgcolor={colors.background2}
              className={classes.topBox}
              px={3}
              py={2.5}
            >
              <Typography variant="h3">Switching Role</Typography>
            </Box>

            <Box px={3} py={2.5}>
              <Typography>{`You are about to switch to your ${accountInfo && accountInfo.role.replace(/_/g, " ")} role ${accountInfo && accountInfo.institution ? ` at ${accountInfo.institution}` : ''}. Please confirm below.`}</Typography>
            </Box>

            <Box
              bgcolor={colors.background2}
              className={classes.bottomBox}
              display="flex"
              justifyContent="space-between"
              px={3}
              py={2}
            >
              <LoadingButton
                className={classes.secondaryButton}
                color="secondary"
                onClick={handleClose}
                variant="contained"
                aria-label="cancel"
              >
                Cancel
              </LoadingButton>

              <LoadingButton
                className={classes.primaryButton}
                onClick={switchAccountRole}
                color="primary"
                variant="contained"
                aria-label="confirm"
              >
                Ok
              </LoadingButton>
            </Box>
          </Box>
        </Dialog>
      }
      <Table
        data={usersData && usersData.data ? usersData.data : []}
        headCells={headCells}
        loading={false}
        pagination={pagination}
        rowCells={rowCells}
        sort={{ lastSorted, handleSort }}
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    minHeight: '100vh'
  },
  dialogPaper: {
    borderRadius: '0.625rem'
  },
  topBox: {
    borderRadius: '0.625rem 0.625rem 0 0'
  },
  searchTextField: {
    backgroundColor: colors.background3,
    fontSize: '13px',
    height: '2rem',
    width: '8rem'
  },
  searchIcon: {
    fontSize: 17
  },
  bottomBox: {
    borderRadius: '0 0 0.625rem 0.625rem'
  },
  primaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: '#fff',
    minWidth: '100px'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: '#0060B2',
    minWidth: '100px'
  }
}));
