import React, { useState } from 'react';
import { Link as MuiLink, Checkbox, Theme, Typography, makeStyles, Box, TextField, Divider, DialogTitle, DialogActions, DialogContent } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
    HeadCell,
    LastSorted,
    Pagination,
    RowCell,
    Table
} from '../../../components/Table';
import { Dialog } from '../../../components/Dialog';
import { ConfirmationDialog } from '../../../components/ConfirmationDialog';
import { LoadingButton } from '../../../components/LoadingButton';
import { colors } from '../../../styling/colors';
import { deleteTagThunk, createTagThunk, editTagThunk } from '../../../redux/thunk/thunks';
import { setErrorAction } from '../../../redux/display/display-actions';

interface TagsTableProps {
    tags: any;
    loadTags: (getTagsProps: any) => void;
}

export const Tags = ({
    tags, loadTags
}: TagsTableProps) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [tag, setTagValue] = useState('');
    const [selectedTag, setSelectedTag] = useState<any>(null);
    const [isDelete, setDeleteDialog] = useState(false);
    const [error, setError] = useState(false);
    const [msg, setMsg] = useState('');
    const [isCreate, setCreateDialog] = useState(false);
    const [isEdit, setEditDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [lastSorted, setLastSorted] = useState<LastSorted>({
        column: 'title',
        order: 'desc'
    });

    const handelClickEditDialog = () => {

    }

    const handelClickDeleteDialog = () => {

    }

    const handleClose = () => {
        setDeleteDialog(false);
        setCreateDialog(false);
        setEditDialog(false);
    }

    const handleChange = (info: any) => {
        const checked: any = selectedTag ? selectedTag.id === info.id : false;
        if (!checked) {
            setSelectedTag(info);
        } else {
            setSelectedTag(null)
        }
    }

    const handleDeleteTag = async () => {
        setLoading(true)
        await dispatch(deleteTagThunk(selectedTag.id));
        setLoading(false);
        setDeleteDialog(false);
        setSelectedTag(null);
    }

    const headCells: HeadCell[] = [
        {
            id: 'title',
            label: 'TAGS'
        }
    ];
    const rowCells = tags?.data.map((tag: any) => {
        const checked: any = selectedTag ? selectedTag.id === tag.id : false;
        const TitleLink = (
            <Box display='flex' alignItems='center'>
                <Checkbox checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                    className={classes.root}
                    color="primary"
                    checked={checked}
                    icon={<span className={classes.icon} />}
                    onChange={() => handleChange(tag)}
                />
                <Typography variant="h5">
                    {tag.title}
                </Typography>
            </Box>
        );
        // let options: any = { weekday: 'long' };
        // let newDate = new Date(tag.updated_at);
        const rowCell: RowCell = {
            title: { component: TitleLink, value: tag.title },
            // updated_at: {
            //     align: 'right',
            //     value: new Intl.DateTimeFormat('en-US', options).format(newDate) + ', ' + (("0" + (newDate.getMonth() + 1)).slice(-2)) + '/' + ("0" + newDate.getDate()).slice(-2) + '/' + newDate.getFullYear()
            // }
        };

        return Object.assign(rowCell, { content: tag });
    });

    const handleSearch = (value: string) => {
        loadTags({
            skip: tags.skip,
            limit: tags.limit,
            sort: tags.sort,
            order: tags.order,
            search: value
        })
    };

    const handleNextButton = async () => {
        if (
            tags &&
            tags.skip >= 0 &&
            tags.skip < tags.total_count / tags.limit - 1
        ) {
            await loadTags({
                search: tags.search,
                skip: (tags.skip + 1),
                limit: tags.limit,
                sort: tags.sort,
                order: tags.order
            });
        }
    };
    const handlePrevButton = async () => {
        if (tags && tags.skip > 0) {
            await loadTags({
                search: tags.search,
                skip: (tags.skip - 1),
                limit: tags.limit,
                sort: tags.sort,
                order: tags.order
            });
        }
    };

    const handlePageSize = (
        e: React.ChangeEvent<{
            name?: string;
            value: unknown;
        }>
    ) => {
        const pageSize: number = e.target.value as number;

        if (tags && pageSize > 0) {
            loadTags({
                skip: 0,
                limit: pageSize,
                search: tags.search,
                sort: tags.sort,
                order: tags.order
            });
        }
    };

    const pagination: Pagination | undefined = tags
        ? {
            contentName: 'Tags',
            page: tags.skip,
            pageSize: tags.limit,
            total: tags.total_count,
            handleNextButton: handleNextButton,
            handlePageSize: handlePageSize,
            handlePrevButton: handlePrevButton
        }
        : undefined;

    const handleSort = (sort: string) => {
        const nextDirection = lastSorted.order === 'desc' ? 'asc' : 'desc';

        loadTags({
            order: sort === lastSorted.column ? nextDirection : 'desc',
            skip: 0,
            limit: tags?.limit,
            search: tags?.search,
            sort
        });

        setLastSorted(
            sort === lastSorted.column
                ? { column: sort, order: nextDirection }
                : { column: sort, order: 'desc' }
        );
    };

    const addNewTag = async () => {
        if (tag.trim() === '') {
            setError(true);
            setMsg('Please enter tag')
        } else if (tag.length > 50) {
            setError(true);
            setMsg('To Long!')
        } else {
            setLoading(true);
            setError(false);
            await dispatch(createTagThunk(tag));
            loadTags({});
            setLoading(false);
            setCreateDialog(false);
            setTagValue('');
        }
    }

    const editTag = async () => {
        if (tag.trim() === '') {
            setError(true);
            setMsg('Please enter tag')
        } else if (tag.length > 50) {
            setError(true);
            setMsg('To Long!')
        } else {
            setLoading(true);
            setError(false);
            const data = {
                id: selectedTag.id,
                tag: tag
            }
            await dispatch(editTagThunk(data));
            loadTags({
                skip: tags.skip,
                limit: tags.limit,
                search: tags.search,
                sort: tags.sort,
                order: tags.order
            });
            setLoading(false);
            setEditDialog(false);
            setSelectedTag(null);
            setTagValue('');
        }
    }

    return (
        <>
            <Box display="flex" justifyContent="space-between" alignItems='center' px={3} py={2}>
                <Box display="flex" alignItems='end' className={classes.contentContainer}>
                    <Box mt={1}>
                        <TextField className={classes.searchTextField}
                            inputProps={{
                                maxLength: 150,
                            }}
                            InputProps={{
                                startAdornment: (
                                    <Box ml={-0.5} mr={1} mt={0.5}>
                                        <Search className={classes.searchIcon} color="disabled" />
                                    </Box>
                                )
                            }} size='small' variant='outlined' placeholder='Search...' onChange={(e) => handleSearch(e.target.value)} />
                    </Box>
                </Box>
                <Box display='flex' alignItems='center'>
                    {selectedTag === null &&
                        <LoadingButton
                            className={classes.primaryButton}
                            color="primary"
                            onClick={() => setCreateDialog(true)}
                            variant="contained"
                        >
                            Create
                        </LoadingButton>}
                    {selectedTag &&
                        <>
                            <LoadingButton
                                className={classes.primaryButton}
                                color="primary"
                                onClick={() => { setEditDialog(true); setTagValue(selectedTag.title) }}
                                variant="contained"
                            >
                                Edit
                            </LoadingButton>
                            <Divider orientation="vertical" style={{ margin: '0px 20px 0px 20px', height: '30px' }} />
                            <LoadingButton
                                className={classes.deleteButton}
                                color="secondary"
                                onClick={() => setDeleteDialog(true)}
                                variant="contained"
                            >
                                Delete
                            </LoadingButton>
                        </>
                    }
                </Box>
            </Box>
            <Table
                data={tags?.data}
                headCells={headCells}
                pagination={pagination}
                rowCells={rowCells}
                sort={{
                    lastSorted: lastSorted,
                    handleSort: handleSort
                }}
            />
            {isDelete &&
                <ConfirmationDialog
                    loading={loading}
                    message={`Are you sure you want to delete? Please type "DELETE" to confirm.`}
                    open={isDelete}
                    title=""
                    handleClose={handleClose}
                    handleConfirmClick={() => handleDeleteTag()}
                />
            }
            {isCreate &&
                <Dialog handleClose={handleClose} open={isCreate}>
                    <DialogTitle id="form-dialog-title">Create New Tag</DialogTitle>
                    <DialogContent style={{ width: '400px' }}>
                        <Box pb={2.5}>
                            <TextField fullWidth variant='outlined' size='small'
                                autoFocus
                                value={tag}
                                onChange={(event) => setTagValue(event.target.value)}
                                type="text"
                            />
                            {error ? (
                                <Typography color="error" variant="subtitle2">
                                    {msg}
                                </Typography>
                            ) : null}
                        </Box>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'space-between', padding: '8px 24px 24px' }}>
                        <LoadingButton
                            className={classes.secondaryButton}
                            variant="contained"
                            style={{ marginBottom: '0px' }}
                            onClick={() => setCreateDialog(false)}
                        >
                            Cancel
                        </LoadingButton>
                        <LoadingButton color="primary" disabled={loading}
                            loading={loading}
                            className={classes.primaryButton}
                            variant="contained"
                            style={{ marginBottom: '0px' }}
                            onClick={(e) => addNewTag()}
                        >
                            Add
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            }
            {isEdit &&
                <Dialog handleClose={handleClose} open={isEdit}>
                    <DialogTitle id="form-dialog-title">Update Tag Copy</DialogTitle>
                    <DialogContent style={{ width: '400px' }}>
                        <Box pb={2.5}>
                            <Box pb={2}>
                                <Typography color="textSecondary" variant="subtitle2">
                                    Please note that changing the copy of the tag will update everywhere.
                                </Typography>
                            </Box>
                            <TextField fullWidth variant='outlined' size='small'
                                autoFocus
                                value={tag}
                                onChange={(event) => setTagValue(event.target.value)}
                                type="text"
                            />
                            {error ? (
                                <Typography color="error" variant="subtitle2">
                                    {msg}
                                </Typography>
                            ) : null}
                        </Box>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'space-between', padding: '8px 24px 24px' }}>
                        <LoadingButton
                            className={classes.secondaryButton}
                            variant="contained"
                            style={{ marginBottom: '0px' }}
                            onClick={() => setEditDialog(false)}
                        >
                            Cancel
                        </LoadingButton>
                        <LoadingButton color="primary" disabled={loading}
                            loading={loading}
                            className={classes.primaryButton}
                            variant="contained"
                            style={{ marginBottom: '0px' }}
                            onClick={(e) => editTag()}
                        >
                            Save
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            }
        </>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    collectionBubble: {
        borderRadius: '0.75rem'
    },
    clearIcon: {
        color: colors.text6,
        fontSize: 14,
        marginLeft: '0.25rem'
    },
    titleLink: {
        textDecoration: 'none'
    },
    searchTextField: {
        backgroundColor: theme.palette.secondary.main,
        fontSize: theme.typography.h5.fontSize,
        height: '2rem',
        width: '14rem'
    },
    searchIcon: {
        fontSize: 17
    },
    contentContainer: {
        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap'
        }
    },
    icon: () => ({
        borderRadius: 3,
        width: 16,
        height: 16,
        border: '1px solid rgba(100,100,100,0.5)',
        backgroundColor: '#ffffff',
        '$root.Mui-focusVisible &': {
            outline: '2px solid #000000',
            outlineOffset: 2
        },
        'input:hover ~ &': {
            backgroundColor: 'transparent'
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)'
        }
    }),
    checkedIcon: () => ({
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""'
        },
        border: '1x solid #137cbd',
        'input:hover ~ &': {
            backgroundColor: '#137cbd'
        }
    }),
    primaryButton: {
        boxShadow:
            '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)',
        height: '2rem',
        minWidth: '100px'
    },
    deleteButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        height: '2rem',
        color: 'red',
        minWidth: '100px'
    },
    secondaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        height: '2rem',
        color: 'blue',
        minWidth: '100px',
        background: '#fff'
    }
}));
