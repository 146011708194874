import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import React from 'react';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '../../components/LoadingButton';
import { Logo } from '../../components/Logo';
import { colors } from '../../styling/colors';

export const RolePage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSubmit = (actions: any) => {
        history.push('/login');
    };

    return (
        <Grid alignItems="center" className={classes.main} container justify="center" spacing={0}>
            <Grid>
                <Box mb={4} ml={4}>
                    <Logo />
                </Box>

                <Paper className={classes.loginPaper}>
                    <Box p={4.5}>
                        <Box pb={2.5}>
                            <Typography color="textSecondary" variant="h1">
                                Welcome to FormFlash!
                            </Typography>
                            <Typography color="textSecondary" variant="h1">
                                Please select your role:
                            </Typography>
                        </Box>
                        <Box pb={1.6}>
                            <LoadingButton
                                className={classes.submitButton}
                                color="primary"
                                onClick={(e) => handleSubmit(1)}
                                variant="contained"
                                aria-label="sign in"
                            >
                                Super Admin
                            </LoadingButton>
                        </Box>
                        <Box pb={1.6}>
                            <LoadingButton
                                className={classes.submitButton}
                                color="primary"
                                onClick={(e) => handleSubmit(2)}
                                variant="contained"
                                aria-label="sign in"
                            >
                                Site Admin
                            </LoadingButton>
                        </Box>
                        <Box pb={1.6}>
                            <LoadingButton
                                className={classes.submitButton}
                                color="primary"
                                onClick={(e) => handleSubmit(3)}
                                variant="contained"
                                aria-label="sign in"
                            >
                                Site builder
                            </LoadingButton>
                        </Box>
                        <Box pb={1.6}>
                            <LoadingButton
                                className={classes.submitButton}
                                color="primary"
                                onClick={(e) => handleSubmit(4)}
                                variant="contained"
                                aria-label="sign in"
                            >
                                Clinician
                            </LoadingButton>
                        </Box>
                        <Box pb={1.6}>
                            <LoadingButton
                                className={classes.submitButton}
                                color="primary"
                                onClick={(e) => handleSubmit(5)}
                                variant="contained"
                                aria-label="sign in"
                            >
                                Patient
                            </LoadingButton>
                        </Box>
                        <Box pb={1.6}>
                            <LoadingButton
                                className={classes.submitButton}
                                color="primary"
                                onClick={(e) => handleSubmit(6)}
                                variant="contained"
                                aria-label="sign in"
                            >
                                Shared User
                            </LoadingButton>
                        </Box>
                        <Box>
                            <LoadingButton
                                className={classes.submitButton}
                                color="primary"
                                onClick={(e) => handleSubmit(7)}
                                variant="contained"
                                aria-label="sign in"
                            >
                                Referral
                            </LoadingButton>
                        </Box>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    main: {
        backgroundColor: colors.background1,
        minHeight: '100vh'
    },
    loginPaper: {
        margin: '0 2rem',
        maxWidth: '30rem',
        width: '80vw'
    },
    textField: {
        width: '100%'
    },
    link: {
        textDecoration: 'none'
    },
    rememberLabel: {
        color: colors.text4
    },
    submitButton: {
        width: '100%',
        fontSize: '13px'
    }
}));
