import * as Yup from 'yup';
import React, { useEffect } from 'react';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Logo } from '../../components/Logo';
import { Link } from 'react-router-dom';
import { colors } from '../../styling/colors';

export const SendLink = () => {
    const classes = useStyles();

    return (
        <Grid alignItems="center" className={classes.main} container justify="center" spacing={0}>
            <Grid>
                <Box mb={4} ml={4}>
                    <Logo />
                </Box>

                <Paper className={classes.loginPaper}>
                    <Box p={4.5} display="flex" alignItems="center" flexDirection='column'>
                        <Box pb={2.5}>
                            <CheckCircleOutline style={{ fontSize: '45px', color: 'gray' }} />
                        </Box>
                        <Box pb={1}>
                            <Typography color="textSecondary" variant="h2">
                                Reset password link sent successfully
                            </Typography>
                        </Box>
                        <Box pb={2.5}>
                            <Typography color="textSecondary" variant="subtitle2">
                                Reset Password link has been sent to the registered email address
                            </Typography>
                        </Box>
                    </Box>
                </Paper>
                <Box pt={2}>
                    <Grid alignItems="flex-end" justify={'center'} container>
                        <Link className={classes.link} to="/login">
                            <Typography color="primary" variant="subtitle1">
                                Go back to login
                            </Typography>
                        </Link>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    main: {
        backgroundColor: colors.background1,
        minHeight: '100vh'
    },
    loginPaper: {
        margin: '0 2rem',
        maxWidth: '30rem',
        width: '80vw'
    },
    textField: {
        width: '100%'
    },
    link: {
        textDecoration: 'none'
    },
    rememberLabel: {
        color: colors.text4
    },
    submitButton: {
        width: '100%'
    }
}));
