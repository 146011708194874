import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/redux-store';
import { Box, Typography } from '@material-ui/core';
import { Link, matchPath, withRouter, useRouteMatch } from 'react-router-dom';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { isLoggedIn } from '../redux/api';

import { colors } from '../styling/colors';
import { ROLES } from '../shared/globals';

interface MatchParams {
  id: string;
  page: string;
}

export const SideBar = withRouter(({ match }: any) => {
  const classes = useStyles();
  let { url } = useRouteMatch();
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);
  const role: any = sessionStorage.getItem('role');
  const nestedPath = window.location.pathname;
  var lastIndex = url.lastIndexOf("/");
  url = url.substring(0, lastIndex);
  const locationPath = match.path.split('/');
  const location = role === 'PATIENT' ? (locationPath && locationPath.length > 2 && locationPath[3]) : (locationPath && locationPath.length > 4 && locationPath[5]);

  const removeTab = () => {
    sessionStorage.removeItem('tab');
  }

  return (
    <Box className={classes.sideBar} display="flex" flexDirection="column" pr={4}>
      <Box mb={3}>
        <Link
          className={
            location === 'result'
              ? classes.activeSideBarText
              : classes.sideBarText
          }
          to={`${url}/result`}
        >
          <Box alignItems="center" display="flex" onClick={() => removeTab()}>
            {(location === 'result') && (
              <Box alignItems="center" display="flex" pr={0.5}>
                <ArrowForwardIosIcon className={classes.arrowIcon} />
              </Box>
            )}

            <Typography variant="subtitle2">Results</Typography>
          </Box>
        </Link>
      </Box>
      <Box mb={3}>
        <Link
          className={
            (location === 'forms' || nestedPath === '/patient-view-collection')
              ? classes.activeSideBarText
              : classes.sideBarText
          }
          to={`${url}/forms`}
        >
          <Box alignItems="center" display="flex" onClick={() => removeTab()}>
            {(location === 'forms' || nestedPath === '/patient-view-collection') && (
              <Box alignItems="center" display="flex" pr={0.5}>
                <ArrowForwardIosIcon className={classes.arrowIcon} />
              </Box>
            )}

            <Typography variant="subtitle2">Forms & Collections</Typography>
          </Box>
        </Link>
      </Box>
      <Box mb={3}>
        <Link
          className={
            location === 'resource'
              ? classes.activeSideBarText
              : classes.sideBarText
          }
          to={`${url}/resource`}
        >
          <Box alignItems="center" display="flex" onClick={() => removeTab()}>
            {(location === 'resource') && (
              <Box alignItems="center" display="flex" pr={0.5}>
                <ArrowForwardIosIcon className={classes.arrowIcon} />
              </Box>
            )}

            <Typography variant="subtitle2">Resources</Typography>
          </Box>
        </Link>
      </Box>
      <Box mb={3}>
        <Link
          className={
            location === 'referral'
              ? classes.activeSideBarText
              : classes.sideBarText
          }
          to={`${url}/referral`}
        >
          <Box alignItems="center" display="flex" onClick={() => removeTab()}>
            {(location === 'referral') && (
              <Box alignItems="center" display="flex" pr={0.5}>
                <ArrowForwardIosIcon className={classes.arrowIcon} />
              </Box>
            )}

            <Typography variant="subtitle2">Referrals</Typography>
          </Box>
        </Link>
      </Box>
      <Box mb={3}>
        <Link
          className={
            location === 'appointment'
              ? classes.activeSideBarText
              : classes.sideBarText
          }
          to={`${url}/appointment`}
        >
          <Box alignItems="center" display="flex" onClick={() => removeTab()}>
            {(location === 'appointment') && (
              <Box alignItems="center" display="flex" pr={0.5}>
                <ArrowForwardIosIcon className={classes.arrowIcon} />
              </Box>
            )}

            <Typography variant="subtitle2">Appointments</Typography>
          </Box>
        </Link>
      </Box>
      <Box mb={3}>
        <Link
          className={
            location === (role === 'PATIENT' ? 'sharedUser' : 'profile')
              ? classes.activeSideBarText
              : classes.sideBarText
          }
          to={`${url}/${role === 'PATIENT' ? 'sharedUser' : 'profile'}`}
        >
          <Box alignItems="center" display="flex" onClick={() => removeTab()}>
            {(location === (role === 'PATIENT' ? 'sharedUser' : 'profile')) && (
              <Box alignItems="center" display="flex" pr={0.5}>
                <ArrowForwardIosIcon className={classes.arrowIcon} />
              </Box>
            )}

            <Typography variant="subtitle2">{role === 'PATIENT' ? 'Shared Users' : 'Shared User Info'}</Typography>
          </Box>
        </Link>
      </Box>
      {role === 'PATIENT' && <Box mb={3}>
        <Link
          className={
            location === 'profile'
              ? classes.activeSideBarText
              : classes.sideBarText
          }
          to={`${url}/profile`}
        >
          <Box alignItems="center" display="flex" onClick={() => removeTab()}>
            {(location === 'profile') && (
              <Box alignItems="center" display="flex" pr={0.5}>
                <ArrowForwardIosIcon className={classes.arrowIcon} />
              </Box>
            )}

            <Typography variant="subtitle2">Patient Profile</Typography>
          </Box>
        </Link>
      </Box>}
    </Box>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    [theme.breakpoints.down('xs')]: {
      padding: '1rem'
    },
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh'
  },
  userIcon: {
    borderRadius: '6px',
    backgroundColor: colors.background5,
    height: '34px',
    width: '34px'
  },
  userIconText: {
    color: theme.palette.secondary.main,
    textShadow: '0 1px 1px rgba(0, 0, 0, 0.25)'
  },
  sideBar: {
    minWidth: '11rem'
  },
  sideBarText: {
    color: colors.text4,
    textDecoration: 'none'
  },
  activeSideBarText: {
    color: colors.blue1,
    textDecoration: 'none'
  },
  arrowIcon: {
    fontSize: 14
  },
  contentContainer: {
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  }
}));
