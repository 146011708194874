import { Box, Typography } from '@material-ui/core';
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/redux-store';
import { ROLES } from '../../../shared/globals';
interface TitleInfoProps {
  clinicianInfo?: string;
  patientInfo?: string;
  title: string;
  index: any;
}

export const TitleInfo: React.FC<TitleInfoProps> = ({ clinicianInfo, patientInfo, title, index }) => {
  const userRole = useSelector((state: RootState) => state.DATA_REDUCER.loggedInUser?.role);
  const classes = useStyles();
  const infoText = userRole === ROLES.PATIENT ? patientInfo : clinicianInfo;
  const fieldTitle = title.split("\n") || null;
  return (
    <Box className={classes.titleContainer}>
      <Box className={classes.title}>
        {fieldTitle && fieldTitle.map((queName: any, i: number) => {
          return <Typography variant="h3">{i === 0 && (index + '. ')}{queName ? queName : ''}</Typography>
        })}
      </Box>
      {/* {infoText && (
        <HtmlTooltip title={infoText} aria-label="more-info" placement="top">
          <InfoIcon fontSize={'small'} color={'primary'} className={classes.icon} />
        </HtmlTooltip>
      )} */}
    </Box>
  );
};
const useStyles = makeStyles((theme) => ({
  root: {},
  icon: { marginLeft: '.6rem' },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  title: {}
}));

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    fontSize: '.8rem',
    border: '1px solid #dadde9'
  }
}))(Tooltip);
