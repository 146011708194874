import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import React, { useState } from 'react';
import { FieldOption, UuidType } from '../../../shared/domain';
import { TitleInfo } from './TitleInfo';

interface DropdownQuestionProps {
  title: string;
  options: FieldOption[];
  handleInput: (
    event: React.ChangeEvent<{
      value: unknown;
    }>
  ) => void;
  handleCustomTextInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  textInput?: string;
  responseValue?: UuidType;
  clinicianInfo?: string;
  patientInfo?: string;
  index: any;
}

export const DropdownQuestion: React.FC<DropdownQuestionProps> = ({
  title,
  options,
  handleInput,
  handleCustomTextInput,
  textInput,
  responseValue,
  clinicianInfo,
  patientInfo,
  index
}) => {
  const classes = useStyles();

  const allowCustomUserInput = options.find((option) => option.id === responseValue)
    ?.allow_user_input;
  return (
    <>
      <TitleInfo clinicianInfo={clinicianInfo} patientInfo={patientInfo} title={title} index={index} />
      <Box py={2}>
        {responseValue ?
          <FormControl variant="outlined" fullWidth size="small">
            <InputLabel id="demo-select-small" margin={'dense'}>Select Answer</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={responseValue}
              label="Select Answer"
              onChange={handleInput}
            >
              {options.map((ffo, i) => {
                return (<MenuItem key={i} value={ffo.id}>
                  {ffo.option_text}
                </MenuItem>
                )
              })}
            </Select>
          </FormControl> :
          <FormControl variant="outlined" fullWidth>
            <InputLabel margin={'dense'}>Select Answer</InputLabel>
            <Select
              label="Select Answer"
              IconComponent={UnfoldMoreIcon}
              classes={{ root: classes.selectRoot }}
              onChange={handleInput}
              value={responseValue}
            >
              {options.map((ffo, i) => {
                return (<MenuItem key={i} value={ffo.id}>
                  {ffo.option_text}
                </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        }
        {allowCustomUserInput && (
          <Box mt={2}>
            <TextField
              label="Input your answer here"
              variant="outlined"
              size="small"
              onChange={handleCustomTextInput}
              value={textInput}
              fullWidth
            />
          </Box>
        )}
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  selectRoot: {
    padding: '.6rem'
  }
}));
