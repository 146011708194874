import { createStore, applyMiddleware, combineReducers, Action } from 'redux';
import { createLogger } from 'redux-logger';
import { dataReducer } from './data/data-reducer';
import { displayReducer } from './display/display-reducer';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import thunk, { ThunkDispatch, ThunkMiddleware } from 'redux-thunk';
import { rootReducer } from './root/root-reducer';

export const history = createBrowserHistory();

const reducers = {
  router: connectRouter(history),
  DISPLAY_STATE_REDUCER: displayReducer,
  DATA_REDUCER: dataReducer
};

let middlewares: (
  | (ThunkMiddleware & { withExtraArgument<E>(extraArgument: E): ThunkMiddleware })
  | ThunkMiddleware
)[];

if (process.env.IS_PRODUCTION) {
  middlewares = [thunk, routerMiddleware(history)];
} else {
  middlewares = [thunk, createLogger(), routerMiddleware(history)];
}

export const combinedReducers = combineReducers(reducers);

export const reduxStore = createStore(rootReducer, applyMiddleware(...middlewares));

export type RootState = ReturnType<typeof combinedReducers>;
export type FlowproThunkDispatch = ThunkDispatch<RootState, void, Action>;
