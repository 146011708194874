import React, { ChangeEvent, useEffect, useState } from 'react';
import pluralize from 'pluralize';
import { debounce } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { CollectionSearchResults, UuidType } from '../../../shared/domain';
import { Dialog } from '../../../components/LegacyDialog';
import { HeadCell, Pagination, RowCell, Table } from '../../../components/Table';
import { RootState } from '../../../redux/redux-store';
import { getCollectionsThunk } from '../../../redux/thunk/thunks';

interface AddToCollectionDialogProps {
  library?: boolean;
  open: boolean;
  selectedForms: UuidType[];
  handleAssignCollections: (selectedCollections: UuidType[], selectedForms: UuidType[]) => void;
  handleClose: () => void;
}

export const AddToCollectionDialog = ({
  library,
  open,
  selectedForms,
  handleAssignCollections,
  handleClose
}: AddToCollectionDialogProps) => {
  const dispatch = useDispatch();

  const collectionsData = useSelector(
    (state: RootState) => state.DATA_REDUCER.collectionSearchResults.addDialog
  );

  const [selectedCollections, setSelectedCollections] = useState<UuidType[]>([]);

  const headCells: HeadCell[] = [
    {
      id: 'name',
      label: 'name'
    }
  ];
  const rowCells = collectionsData?.collections.map((collection) => {
    const rowCell: RowCell = {
      name: { value: collection.name }
    };

    return Object.assign(rowCell, { content: collection });
  });

  const handleNextButton = async () => {
    if (
      collectionsData &&
      collectionsData.page >= 0 &&
      collectionsData.page < collectionsData.total / collectionsData.pageSize - 1
    ) {
      await dispatch(
        getCollectionsThunk({
          collectionsType: 'addDialog',
          library,
          page: collectionsData.page + 1,
          pageSize: collectionsData.pageSize,
          search: collectionsData.search
        })
      );
    }
  };
  const handlePrevButton = async () => {
    if (collectionsData && collectionsData.page > 0) {
      await dispatch(
        getCollectionsThunk({
          collectionsType: 'addDialog',
          library,
          page: collectionsData.page - 1,
          pageSize: collectionsData.pageSize,
          search: collectionsData.search
        })
      );
    }
  };

  const pagination: Pagination | undefined = collectionsData
    ? {
        contentName: library ? 'Library' : 'Collection',
        page: collectionsData.page,
        pageSize: collectionsData.pageSize,
        total: collectionsData.total,
        handleNextButton: handleNextButton,
        handlePrevButton: handlePrevButton
      }
    : undefined;

  const searchCollections = (collectionsData: CollectionSearchResults, value: string) =>
    debounce(
      () =>
        dispatch(
          getCollectionsThunk({
            collectionsType: 'addDialog',
            library,
            page: 0,
            pageSize: collectionsData.pageSize,
            search: value
          })
        ),
      500
    );
  const handleSearch = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (collectionsData && e.target) {
      searchCollections(collectionsData, e.target.value)();
    }
  };

  useEffect(() => {
    if (collectionsData === null || collectionsData.library !== library) {
      dispatch(getCollectionsThunk({ collectionsType: 'addDialog', library, pageSize: 8 }));
    }
  }, [collectionsData, library]);

  return (
    <Dialog
      open={open}
      primaryButton={{
        text: `Add to ${selectedCollections.length} ${pluralize(
          'Collection',
          selectedCollections.length
        )}`,
        handleClick: () => handleAssignCollections(selectedCollections, selectedForms)
      }}
      secondaryButton={{ text: 'Cancel', handleClick: handleClose }}
      title={`Add to ${library ? 'library' : 'collection'}`}
      handleClose={handleClose}
      handleSearch={handleSearch}
    >
      <Table
        data={collectionsData?.collections}
        headCells={headCells}
        hideHead
        rowCells={rowCells}
        pagination={pagination}
        selection={{
          selectedContents: selectedCollections,
          setSelectedContents: (selected: UuidType[]) => setSelectedCollections(selected)
        }}
      />
    </Dialog>
  );
};
