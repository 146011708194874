import { Box, Card, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { UuidType } from '../../shared/domain';
import { EntryPageSettingsView } from './components/EntryPageSettingsView';
import { EntryPageViewer } from './components/EntryPageViewer';

interface EntryPageBuilderProps {
  formId: UuidType;
}

export const EntryPageBuilder: React.FC<EntryPageBuilderProps> = ({ formId }) => {
  const classes = useStyles();
  const location: any = useLocation();
  const isEdit = location.state;

  return (
    <Box className={classes.main} display="flex" flexDirection="column">
      <Container style={{ padding: '0px' }}>
        <Card className={classes.content}>
          {isEdit ? <Grid container>
            <Grid item xs={4}>
              <EntryPageSettingsView />
            </Grid>
            <Grid item xs={8}>
              <EntryPageViewer />
            </Grid>
          </Grid> :
            <Grid container>
              <Grid item xs={12} style={{ padding: '0 10%' }}>
                <EntryPageViewer />
              </Grid>
            </Grid>}
        </Card>
      </Container>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    padding: '100px 0px 20px'
  },
  content: {
    display: 'flex',
    backgroundColor: '#ffffff',
    margin: '0px'
  }
}));
