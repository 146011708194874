import { Box, Grid, makeStyles, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardHeader } from '../../components/LegacyCardHeader';
import { RootState } from '../../redux/redux-store';
import { GetReferralsProps, getReferralsThunk, getReferralsFilterLibThunk } from '../../redux/thunk/referral-thunks';
import { UuidType } from '../../shared/domain';
import ReferralsFilter from '../Referrals/components/ReferralsFilter';
import { ReferralsTable } from '../Referrals/components/ReferralsTable';
import { useHistory } from 'react-router-dom';

export const ReferralLibrary = () => {
  const classes = useStyles();
  const history = useHistory();

  const dispatch = useDispatch();
  const [selectedReferrals, setSelectedReferrals] = useState<UuidType[]>([]);

  const referrals = useSelector(
    (state: RootState) => state.DATA_REDUCER.referralsSearchResults.referrals
  );

  const referralFilterList = useSelector(
    (state: RootState) => state.DATA_REDUCER.referralFilterLists
  );

  const handleSearch = (value: string) => {
    if (referrals) {
      dispatch(
        getReferralsThunk({
          order: referrals.order,
          page: referrals.page,
          pageSize: referrals.pageSize,
          search: value,
          sort: referrals.sort
        })
      );
    }
  };

  // We need async/await over here as it is used for some state loading task
  const loadReferrals = async (getReferralsProps: GetReferralsProps) => {
    await dispatch(getReferralsThunk(getReferralsProps));
  };

  useEffect(() => {
    dispatch(getReferralsThunk({}));
    dispatch(getReferralsFilterLibThunk());
  }, []);

  const ReferralsTableContainer = (
    <Box pb={4} pt={1} px={4}>
      <ReferralsTable
        referralsData={referrals}
        selectedReferrals={selectedReferrals}
        loadReferrals={loadReferrals}
        setSelectedReferrals={(selectedReferrals: UuidType[]) =>
          setSelectedReferrals(selectedReferrals)
        }
        isReferralLibrary={false}
        filtersList={null}
        handleDeleteReferral={() => loadReferrals}
        viewReferralOpen={loadReferrals}
        viewReferralDialogueOpen={false}
      />
    </Box>
  );

  const handleAddReferralButton = () =>
    history.push('/patients/e6cbe4cd-d3e8-456a-9e0a-520c020133b7');

  return (
    <>
      <Paper>
        <Box display="flex" flexDirection="column" minWidth="14rem" px={8} py={8}>
          <Grid container className={classes.root} justify="center">
            <Grid item xs={9}>
              <Paper>
                <Box display="flex" flexDirection="column">
                  <CardHeader
                    tabComponents={[
                      {
                        component: ReferralsTableContainer,
                        label: 'Referrals',
                        primaryButton: {
                          text: 'Add Referral',
                          handleClick: handleAddReferralButton
                        },
                        style: {
                          background: true,
                          spacing: { py: 3, px: 4 }
                        },
                        title: 'All Referral Providers',
                        handleSearch: handleSearch
                      }
                    ]}
                  />
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={3} className={classes.filterSection}>
              <ReferralsFilter filtersList={referralFilterList} loadReferrals={loadReferrals} />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </>
  );
};

const useStyles = makeStyles({
  root: {
    paddngLeft: '2rem',
    paddngRight: '2rem'
  },
  filterSection: {
    overflowY: 'auto',
    maxHeight: '35rem'
  }
});
