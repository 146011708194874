import React, { useState } from 'react';
import { Box, Theme, Typography, makeStyles, TextField } from '@material-ui/core';
import { Dialog } from './Dialog';
import { LoadingButton } from './LoadingButton';
import { colors } from '../styling/colors';

interface ConfirmationDialogProps {
  loading: boolean;
  message: string;
  open: boolean;
  title: string;
  handleClose: () => void;
  handleConfirmClick: () => void;
}

export const ConfirmationDialog = ({
  loading,
  message,
  open,
  title,
  handleClose,
  handleConfirmClick
}: ConfirmationDialogProps) => {
  const classes = useStyles();
  const [input, setInput] = useState('');
  const [msg, setMsg] = useState('');
  const [error, setError] = useState(false);

  const onDelete = () => {
    if ((input === '' || input.toLowerCase() !== "delete") && title !== 'Calculation') {
      setError(true);
      setMsg(input === '' ? 'Please type DELETE' : 'Please enter the correct word')
    } else {
      handleConfirmClick()
    }
  }

  return (
    <Dialog open={open} handleClose={handleClose}>
      <Box display="flex" flexDirection="column" style={{ textAlign: 'center' }} width={500}>
        <Box
          alignItems="center"
          bgcolor={colors.background2}
          className={classes.topBox}
          px={3}
          py={2.5}
        >
          <Typography variant="h3">{title === 'Calculation' ? '' : title}</Typography>
          <Typography variant="h3">{message}</Typography>
        </Box>
        {title === 'Calculation' ? '' :
          <Box px={3} py={2.5}>
            <TextField name="input" variant='outlined' size='small' value={input} onChange={(e) => setInput(e.target.value)} />
            {error && (input === '' || input.toLowerCase() !== "delete") ? (
              <Typography color="error" variant="subtitle2">
                {msg}
              </Typography>
            ) : null}
          </Box>
        }
        <Box
          bgcolor={colors.background2}
          className={classes.bottomBox}
          display="flex"
          justifyContent="space-between"
          px={3}
          py={2}
        >
          <LoadingButton
            className={classes.secondaryButton}
            color="secondary"
            onClick={handleClose}
            variant="contained"
            aria-label="cancel"
          >
            {title === 'Calculation' ? 'No' : 'Cancel'}
          </LoadingButton>

          <LoadingButton
            className={classes.primaryButton}
            color="secondary"
            disabled={loading}
            loading={loading}
            onClick={onDelete}
            variant="contained"
            aria-label="confirm"
          >
            {title === 'Calculation' ? 'Yes' : 'Delete'}
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    minHeight: '100vh'
  },
  dialogPaper: {
    borderRadius: '0.625rem'
  },
  topBox: {
    borderRadius: '0.625rem 0.625rem 0 0'
  },
  searchTextField: {
    backgroundColor: colors.background3,
    fontSize: '13px',
    height: '2rem',
    width: '8rem'
  },
  searchIcon: {
    fontSize: 17
  },
  bottomBox: {
    borderRadius: '0 0 0.625rem 0.625rem'
  },
  primaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: 'red',
    minWidth: '100px'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: '#0060B2',
    minWidth: '100px'
  }
}));
