import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, Divider } from '@material-ui/core';
import { Link, matchPath, withRouter, useHistory } from 'react-router-dom';
import { RootState } from '../redux/redux-store';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { colors } from '../styling/colors';

interface MatchParams {
    id: string;
}

export const ReferralSideBar = withRouter(({ match }: any) => {
    const classes = useStyles();
    const history = useHistory();
    const locationPath = match.path;
    const location = matchPath<MatchParams>(match.path, {
        path: '/:id'
    })?.params.id;
    const loggedInUser = useSelector((state: RootState) => state.DATA_REDUCER.loggedInUser);

    return (
        <Box className={classes.sideBar} display="flex" flexDirection="column" pr={4}>

            <Box mb={3} style={{ marginTop: '28px' }}>
                <Link
                    className={locationPath === '/referral-profile' ? classes.activeSideBarText : classes.sideBarText}
                    to={{ pathname: `/referral-profile` }}
                >
                    <Box alignItems="center" display="flex">
                        {locationPath === '/referral-profile' && (
                            <Box alignItems="center" display="flex" pr={0.5}>
                                <ArrowForwardIosIcon className={classes.arrowIcon} />
                            </Box>
                        )}
                        <Typography variant="subtitle2">Back to Profile</Typography>
                    </Box>
                </Link>
            </Box>
            <Divider style={{ marginBottom: '20px' }} />
            <Box mb={3}>
                <Link
                    className={locationPath === '/contacts' ? classes.activeSideBarText : classes.sideBarText}
                    to={{ pathname: `/contacts` }}
                >
                    <Box alignItems="center" display="flex">
                        {locationPath === '/contacts' && (
                            <Box alignItems="center" display="flex" pr={0.5}>
                                <ArrowForwardIosIcon className={classes.arrowIcon} />
                            </Box>
                        )}

                        <Typography variant="subtitle2">Contact Us</Typography>
                    </Box>
                </Link>
            </Box>
            <Box mb={3}>
                <Link
                    className={locationPath === '/user-roles' ? classes.activeSideBarText : classes.sideBarText}
                    to={{ pathname: `/user-roles` }}
                >
                    <Box alignItems="center" display="flex">
                        {locationPath === '/user-roles' && (
                            <Box alignItems="center" display="flex" pr={0.5}>
                                <ArrowForwardIosIcon className={classes.arrowIcon} />
                            </Box>
                        )}

                        <Typography variant="subtitle2">Roles</Typography>
                    </Box>
                </Link>
            </Box>

        </Box>
    );
});

const useStyles = makeStyles((theme: Theme) => ({
    main: {
        [theme.breakpoints.down('xs')]: {
            padding: '1rem'
        },
        backgroundColor: theme.palette.background.default,
        minHeight: '100vh'
    },
    userIcon: {
        borderRadius: '6px',
        backgroundColor: colors.background5,
        height: '34px',
        width: '34px'
    },
    userIconText: {
        color: theme.palette.secondary.main,
        textShadow: '0 1px 1px rgba(0, 0, 0, 0.25)'
    },
    sideBar: {
        minWidth: '11rem'
    },
    sideBarText: {
        color: colors.text4,
        textDecoration: 'none'
    },
    activeSideBarText: {
        color: colors.blue1,
        textDecoration: 'none'
    },
    arrowIcon: {
        fontSize: 14
    },
    contentContainer: {
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap'
        }
    }
}));