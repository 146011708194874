import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { FormPageDisplay } from '../../../components/FormPageDisplay';
import { RootState } from '../../../redux/redux-store';
import { theme } from '../../../styling/ThemeConfig';

export const ExitPageViewer: React.FC<{}> = () => {
  const classes = useStyles();
  const formThemeColor = theme.palette.primary.main;
  const editingExitPage: any = useSelector((state: RootState) => state.DATA_REDUCER.editingExitPage);

  if (!editingExitPage) {
    return null;
  }

  if (!editingExitPage.title || !editingExitPage.description) {
    return (
      <Box className={classes.placeholder}>
        <Typography variant={'h3'}>Add a title and description to get started</Typography>
      </Box>
    );
  } else {
    return (
      <Box m={5} p={6} flexGrow className={classes.container}>
        <FormPageDisplay
          header={editingExitPage.title}
          body={editingExitPage.description}
          buttonLink={editingExitPage.button_url}
          buttonText={editingExitPage.button_text}
          videoUrl={editingExitPage.exit_page_video_url}
          showReferralButton={editingExitPage.show_referral_btn}
          themeColor={formThemeColor}
        />
      </Box>
    );
  }
};

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.background.main
  },
  placeholder: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));
