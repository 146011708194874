import React, { useEffect, useState } from 'react';
import { Box, makeStyles, Paper, Theme, Typography, ListItem, Divider, Card, FormControlLabel, Checkbox, DialogActions, TextField } from '@material-ui/core';
import MUIDialog from '@material-ui/core/Dialog';
import { Search } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getSharedUsersThunk, assignResourceToUsersThunk, assignReferralToUsersThunk, assignAppointmentToUsersThunk } from '../../../redux/thunk/thunks';
import { RootState } from '../../../redux/redux-store';
import { LoadingButton } from '../../../components/LoadingButton';
import { useRouteMatch } from 'react-router-dom';

export const ShareToUser = ({ handelClose, data, section }: any) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [selectedUsers, setSelectedUsers] = useState<any[]>(data?.sharedUsers || []);
    const [selectedSharedUser, setSelectedSharedUser] = useState<any[]>([]);
    const [isAdd, setIsAdd] = useState(false);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [check, setCheck] = useState(false);
    const organization_id: any = sessionStorage.getItem('pat_org_id');
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const { path } = useRouteMatch();
    const split = path.split('/');
    const patient_role_id: any = split && split.length > 1 && split[2];

    const sharedUsers: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.sharedUsers
    );
    const user: any = useSelector((state: RootState) => state.DATA_REDUCER.patientInfo);
    useEffect(() => {
        if (data?.sharedUsers && data?.sharedUsers?.length > 0) {
            for (let i = 0; i < data.sharedUsers.length; i++) {
                selectedSharedUser.push(data.sharedUsers[i].user_role_id);
            }
        }
        const checked = selectedUsers.filter((id: any) => id.user_role_id === (patient_role_id ? patient_role_id : loggedInUser.id));
        checked && checked.length > 0 && setCheck(true);
    }, [])

    const handlePrevButton = () => {
        dispatch(getSharedUsersThunk({
            page: sharedUsers.page - 1, user_role_id: patient_role_id, organization_id: organization_id, giveOnlyActiveUsers: true
        }))
    }

    const handleNextButton = () => {
        dispatch(getSharedUsersThunk({
            page: sharedUsers.page + 1, user_role_id: patient_role_id, organization_id: organization_id, giveOnlyActiveUsers: true
        }))
    }

    const handleSearch = (value: string) => {
        setSearch(value);
        if (value.length > 2) {
            dispatch(getSharedUsersThunk({ search: value, user_role_id: patient_role_id, organization_id: organization_id, giveOnlyActiveUsers: true }));
        }
    };

    const handleChangeChecked1 = (value: boolean) => {
        if (!value) {
            const id = patient_role_id ? patient_role_id : loggedInUser.id;
            const sharedUser = selectedSharedUser.filter((e: any) => e !== id);
            setSelectedSharedUser(sharedUser);
            const users = selectedUsers.filter((e: any) => e.user_role_id !== id);
            setSelectedUsers(users);
            setCheck(value);
        } else {
            const id = patient_role_id ? patient_role_id : loggedInUser.id;
            selectedUsers.push({ user_role_id: id });
            selectedSharedUser.push(id);
            setCheck(value);
        }
    }

    const handleSharedChecked = (checked: boolean, info: any) => {
        if (!checked) {
            selectedUsers.push({ user_role_id: info.user_role_id });
            selectedSharedUser.push(info.user_role_id);
            setIsAdd(!isAdd)
        } else {
            const sharedUser = selectedSharedUser.filter((e: any) => e !== info.user_role_id);
            setSelectedSharedUser(sharedUser);
            const users = selectedUsers.filter((e: any) => e.user_role_id !== info.user_role_id);
            setSelectedUsers(users);
            setIsAdd(!isAdd)
        }
    }

    const handelCloseDialog = async () => {
        handelClose();
        setSelectedSharedUser([]);
    }

    const addSharedUser = async () => {
        setLoading(true);
        const info = {
            "id": section === 'referral' ? data.referral_id : data.id,
            "user_role_ids": selectedSharedUser,
            "patient_id": data.patient_id ? data.patient_id : data.patientId ? data.patientId : patient_role_id ? patient_role_id : loggedInUser.id,
            "clinician_id": data.clinicianId ? data.clinicianId : data.assigned_by,
        }
        if (section === 'resource') {
            await dispatch(assignResourceToUsersThunk(info));
        } else if (section === 'referral') {
            await dispatch(assignReferralToUsersThunk(info));
        } else if (section === 'appointment') {
            await dispatch(assignAppointmentToUsersThunk(info));
        } else {

        }
        handelClose();
        setSelectedSharedUser([]);
        setSelectedUsers([]);
        setLoading(false);
    }

    return (
        <>
            <MUIDialog open={true} style={{ overflow: 'hidden' }}>
                <Paper className={classes.paper}>
                    <Box display="flex" flexDirection="column">
                        <Card>
                            <Box p={2} style={{ background: '#f9fafb', borderBottom: '0.0625rem solid #d1d6dc' }} >
                                <Box display='flex' justifyContent='space-between' alignItems="center">
                                    <Typography style={{ fontSize: '18px', fontWeight: 600 }}>Share</Typography>
                                    <TextField className={classes.searchTextField} value={search}
                                        inputProps={{
                                            maxLength: 150,
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <Box ml={-0.5} mr={1} mt={0.5}>
                                                    <Search className={classes.searchIcon} color="disabled" />
                                                </Box>
                                            )
                                        }} size='small' variant='outlined' placeholder='Search...' onChange={(e) => handleSearch(e.target.value)} />
                                </Box>
                            </Box>
                            <Box pb={4} pt={1} px={4}>
                                <ListItem style={{ borderBottom: '2px solid #dddddd', padding: '4px 0px', background: '#f9fafb' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox size='medium' disabled={user ? ((loggedInUser?.id === user?.patient_id) ? true : false) : true} checked={check} onChange={(e) => handleChangeChecked1(!check)} name={loggedInUser?.id} color="primary" />
                                        }
                                        label={<Box><Typography style={{ fontSize: '16px' }}>{user ? (user?.first_name + ' ' + user?.last_name) : (loggedInUser?.first_name + ' ' + loggedInUser?.last_name)}</Typography>
                                            <Typography style={{ fontSize: '12px', color: 'gray' }}>{user ? user?.role : loggedInUser?.role}</Typography></Box>} />
                                </ListItem>
                                {sharedUsers && sharedUsers.data.length > 0 && sharedUsers.data.map((item: any, i: number) => {
                                    const checked = selectedUsers.filter((id: any) => id.user_role_id === item.user_role_id);
                                    return (
                                        <ListItem style={{ borderBottom: '2px solid #dddddd', padding: '4px 0px' }}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox size='medium' checked={checked && checked.length === 0 ? false : true} onChange={(e) => handleSharedChecked(checked && checked.length === 0 ? false : true, item)} name={item.id} color="primary" />
                                                }
                                                label={<Box><Typography style={{ fontSize: '16px' }}>{item.first_name + ' ' + item.last_name}</Typography>
                                                    <Typography style={{ fontSize: '12px', color: 'gray' }}>{item.role}</Typography></Box>} />
                                        </ListItem>
                                    )
                                })
                                }
                                <Box mt={2} mb={1} display="flex" justifyContent="space-between" alignItems="center">
                                    <Box><Typography>{sharedUsers ? (sharedUsers.totalCount + 1) : 0} Total Shared Users</Typography></Box>
                                    <Box>
                                        <LoadingButton disabled={sharedUsers && sharedUsers.page > 0 ? false : true}
                                            className={classes.paginationButton}
                                            variant="contained"
                                            style={{ marginBottom: '0px' }}
                                            onClick={handlePrevButton}
                                        >
                                            Previous
                                        </LoadingButton>
                                        <LoadingButton disabled={sharedUsers && sharedUsers.page >= 0 &&
                                            sharedUsers.page > sharedUsers.totalCount / sharedUsers.pageSize - 1}
                                            className={classes.paginationButton}
                                            variant="contained"
                                            style={{ marginBottom: '0px' }}
                                            onClick={handleNextButton}
                                        >
                                            Next
                                        </LoadingButton>
                                    </Box>
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                </Paper>
                <DialogActions style={{ justifyContent: 'space-between', padding: '15px 20px', background: '#f9fafb' }}>
                    <LoadingButton
                        className={classes.secondaryButton}
                        variant="contained"
                        onClick={handelCloseDialog}
                    >
                        Cancel
                    </LoadingButton>
                    <Box display='flex' alignItems='center'>
                        {selectedUsers.length > 0 && <><span>{selectedUsers.length} selected</span><Divider orientation="vertical" flexItem style={{ margin: '0 20px' }} /></>}
                        <LoadingButton disabled={loading}
                            className={classes.primaryButton}
                            variant="contained"
                            onClick={addSharedUser}
                        >
                            Save
                        </LoadingButton>
                    </Box>
                </DialogActions>
            </MUIDialog>
        </>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    topBox: {
        borderRadius: '0.625rem 0.625rem 0 0'
    },
    bottomBox: {
        borderRadius: '0 0 0.625rem 0.625rem'
    },
    primaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#fff',
        minWidth: '120px',
        background: '#0060B2',
        '&:hover': {
            backgroundColor: '#0060B2',
        }
    },
    secondaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#0060B2',
        minWidth: '120px',
        background: '#fff'
    },
    paper: {
        width: '580px',
        overflow: 'hidden'
    },
    searchTextField: {
        backgroundColor: theme.palette.secondary.main,
        fontSize: theme.typography.h5.fontSize,
        height: '2rem',
        width: '11rem',
        zIndex: 1
    },
    searchIcon: {
        fontSize: 17
    },
    paginationButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: 'black',
        marginLeft: '20px',
        minWidth: '120px',
        background: '#fff'
    },
}));
