import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorNotice } from '../../../../components/ErrorNotice';
import { WarningNotice } from '../../../../components/WarningNotice';
import { RootState } from '../../../../redux/redux-store';
import {
  deleteFieldLogicRuleThunk,
  updateFieldLogicRuleThunk
} from '../../../../redux/thunk/thunks';
import {
  FormField,
  FormFieldLogic,
  FormFieldLogicUpdate,
  UuidType
} from '../../../../shared/domain';
import { FIELD_TYPES, LOGIC_OPERATOR_TYPE } from '../../../../shared/globals';
import { allowedLogicalOperators, logicalOperatorText } from '../InputUtilities';
interface LogicBlockProps {
  logicRule: FormFieldLogic;
  fieldsThatAllowLogic: FormField[];
  ruleNumber: number;
}

export const LogicBlock: React.FC<LogicBlockProps> = ({
  logicRule,
  fieldsThatAllowLogic,
  ruleNumber
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [editingLogicRule, setEditingLogicRule] = useState<FormFieldLogicUpdate>(logicRule);

  const selectedField = fieldsThatAllowLogic.find(
    (field: FormField) => field.id === editingLogicRule.target_form_field_id
  );

  const editingFormField = useSelector((state: RootState) => state.DATA_REDUCER.editingFormField);
  const editingFormFields = useSelector((state: RootState) => state.DATA_REDUCER.editingFormFields);
  const updateFieldLogicRuleId = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.updateFieldLogicRuleId
  );

  const checkAndUpdateFieldLogic = (logicUpdate: FormFieldLogicUpdate) => {
    //if all options have a valid input then
    //update the fieldLogicRule
    if (
      editingFormField &&
      selectedField &&
      logicUpdate.operator_type &&
      (logicUpdate.comparison_value || logicUpdate.comparison_option_id)
    ) {
      dispatch(updateFieldLogicRuleThunk(editingFormField.id, logicRule.id, logicUpdate));
    } else {
      //if all criteria is not met for the logic to be update set pending changes in state
      setEditingLogicRule(logicUpdate);
    }
  };

  const fieldScaleMax = selectedField?.option_scale_max;
  const logicRuleValue = editingLogicRule.comparison_value;

  const invalidScaleError = fieldScaleMax && logicRuleValue && (fieldScaleMax < logicRuleValue || logicRuleValue < 1);

  const invalidOptionError = () => {
    const fieldOptions = selectedField?.form_field_options;
    const optionId = editingLogicRule.comparison_option_id;

    if (fieldOptions && optionId) {
      const optionIds = fieldOptions.map((ffo) => ffo.id);
      return !optionIds.includes(optionId);
    }
  };

  const unreachableLogicError = () => {
    //check if target field comes before the selected field in the current form order
    if (!editingFormFields || !editingFormField || !selectedField) {
      return false;
    }
    const idList = editingFormFields.map((field) => field.id);

    const position1 = idList.indexOf(editingFormField.id);
    const position2 = idList.indexOf(selectedField.id);
    //check if selected field comes after the currently editing field
    return position1 < position2;
  };

  const deleteFieldLogic = () => {
    if (editingFormField) {
      dispatch(deleteFieldLogicRuleThunk(logicRule.id, editingFormField.id));
    }
  };
  if (editingFormField) {
    const handleChangeTargetField = (event: React.ChangeEvent<{ value: unknown }>) => {
      checkAndUpdateFieldLogic({
        target_form_field_id: event.target.value as UuidType,
        operator_type: undefined,
        comparison_option_id: undefined,
        comparison_value: undefined
      });
    };
    const handleChangeLogicalOperator = (event: React.ChangeEvent<{ value: unknown }>) => {
      checkAndUpdateFieldLogic({
        ...editingLogicRule,
        operator_type: event.target.value as LOGIC_OPERATOR_TYPE
      });
    };
    const handleChangeFieldOption = (event: React.ChangeEvent<{ value: unknown }>) => {
      checkAndUpdateFieldLogic({
        ...editingLogicRule,
        comparison_value: undefined,
        comparison_option_id: event.target.value as UuidType
      });
    };
    const handleChangeScaleOption = (event: React.ChangeEvent<{ value: unknown }>) => {
      checkAndUpdateFieldLogic({
        ...editingLogicRule,
        comparison_option_id: undefined,
        comparison_value: event.target.value as number
      });
    };

    const isLoading = updateFieldLogicRuleId === logicRule.id;
    const renderScaleOptions = (scaleMax?: number) => {
      let scaleOptions = [];
      if (scaleMax) {
        for (let i = 0; i < scaleMax; i++) {
          scaleOptions.push(
            <MenuItem key={i} value={i + 1}>
              {i + 1}
            </MenuItem>
          );
        }
      }
      return scaleOptions;
    };

    return (
      <Box mt={1}>
        <Box className={classes.blockHeader}>
          <Box>
            <Typography>Rule #{ruleNumber + 1}</Typography>
          </Box>
          {isLoading ? (
            <CircularProgress className={classes.updatingIcon} size={20} />
          ) : (
            <Button disabled={isLoading} onClick={deleteFieldLogic} aria-label="remove logic">
              <Typography variant={'subtitle2'} color={'error'}>
                Remove
              </Typography>
            </Button>
          )}
        </Box>
        <FormControl fullWidth>
          <Select
            IconComponent={UnfoldMoreIcon}
            labelId="selected-target-field"
            classes={{ root: classes.selectRoot }}
            displayEmpty
            value={editingLogicRule.target_form_field_id}
            onChange={handleChangeTargetField}
            variant={'outlined'}
          >
            <MenuItem value={undefined} disabled>
              Select a Field
            </MenuItem>
            {fieldsThatAllowLogic.map((field, i) => (
              <MenuItem key={field.id} value={field.id}>
                {field.field_prompt}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {editingLogicRule.target_form_field_id && (
          <Box my={1} className={classes.dropdownRow}>
            <FormControl fullWidth>
              <Select
                IconComponent={UnfoldMoreIcon}
                classes={{ root: classes.selectRoot }}
                variant={'outlined'}
                value={editingLogicRule.operator_type}
                displayEmpty
                onChange={handleChangeLogicalOperator}
                className={classes.dropdownLeft}
              >
                <MenuItem value={undefined} disabled>
                  Select an Operator
                </MenuItem>
                {allowedLogicalOperators(selectedField?.field_type).map(
                  (operator: LOGIC_OPERATOR_TYPE, i: number) => (
                    <MenuItem key={i} value={operator}>
                      {logicalOperatorText(operator)}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              {selectedField?.field_type === FIELD_TYPES.SCALE ? (
                <Select
                  IconComponent={UnfoldMoreIcon}
                  classes={{ root: classes.selectRoot }}
                  onChange={handleChangeScaleOption}
                  displayEmpty
                  value={editingLogicRule.comparison_value}
                  variant={'outlined'}
                >
                  <MenuItem value={undefined} disabled>
                    Select a value
                  </MenuItem>
                  {renderScaleOptions(selectedField?.option_scale_max)}
                </Select>
              ) : (
                <Select
                  IconComponent={UnfoldMoreIcon}
                  classes={{ root: classes.selectRoot }}
                  onChange={handleChangeFieldOption}
                  displayEmpty
                  value={editingLogicRule.comparison_option_id}
                  variant={'outlined'}
                >
                  <MenuItem value={undefined} disabled>
                    Select an option
                  </MenuItem>
                  {selectedField?.form_field_options?.map((option, i) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.option_text}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
          </Box>
        )}
        {invalidScaleError ? (
          <ErrorNotice error={'This rule has an invalid comparison value'} />
        ) : null}
        {invalidOptionError() ? (
          <ErrorNotice error={'This rule has an invalid comparison option'} />
        ) : null}
        {unreachableLogicError() ? (
          <WarningNotice
            warning={'Unreachable logic. Target field should come before selected field'}
          />
        ) : null}
      </Box>
    );
  } else {
    return null;
  }
};

const useStyles = makeStyles((theme) => ({
  mainContainer: {},
  blockHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  selectRoot: {
    padding: '.6rem'
  },
  dropdownRow: {
    display: 'flex'
  },
  dropdownLeft: {
    marginRight: theme.spacing(1)
  },
  updatingIcon: {
    margin: '.4rem'
  }
}));
