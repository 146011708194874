export enum ROLES {
  ADMIN = 'ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
  BUILDER = 'BUILDER',
  CLINICIAN = 'CLINICIAN',
  PATIENT = 'PATIENT',
  FAMILY = 'FAMILY',
  REFERRAL = 'REFERRAL',
  PROXY = 'PROXY',
  RATER = 'RATER',
  SITE_ADMIN = 'SITE_ADMIN',
}

export enum LOGIC_OPERATOR_TYPE {
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
  EQUAL_TO = 'EQUAL_TO',
  NOT_EQUAL_TO = 'NOT_EQUAL_TO',
  INCLUDES = 'INCLUDES',
  EXCLUDES = 'EXCLUDES'
}

export enum METRIC_COMPARISON_OPERATOR_TYPE {
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
  EQUAL_TO = 'EQUAL_TO',
  NOT_EQUAL_TO = 'NOT_EQUAL_TO'
}

export enum FIELD_TYPES {
  INFO_TEXT = 'INFO_TEXT',
  SHORT_TEXT = 'SHORT_TEXT',
  LONG_TEXT = 'LONG_TEXT',
  DROPDOWN = 'DROPDOWN',
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  SCALE = 'SCALE',
  RESOURCE = 'RESOURCE'
}

export enum CALCULATION_OPERATORS {
  ADD = 'ADD',
  SUBTRACT = 'SUBTRACT'
}

export enum MIME_TYPES {
  PDF = 'PDF'
}

export enum TAG_TYPES {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY'
}

export enum GENDERS {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NON_BINARY = 'NON_BINARY'
}

export enum PRICE_RANGE {
  LOW = 'LOW',
  MID = 'MID',
  HIGH = 'HIGH',
  SLIDING_SCALE = 'SLIDING_SCALE'
}
export enum REFERRAL_TYPE {
  INDIVIDUAL = 'INDIVIDUAL',
  TREATMENT_CENTER = 'TREATMENT_CENTER',
  SUPPORT_GROUP = 'SUPPORT_GROUP'
}

export const ALLOWED_OPERATORS: {
  [key in FIELD_TYPES]: LOGIC_OPERATOR_TYPE[];
} = {
  [FIELD_TYPES.SCALE]: [
    LOGIC_OPERATOR_TYPE.EQUAL_TO,
    LOGIC_OPERATOR_TYPE.NOT_EQUAL_TO,
    LOGIC_OPERATOR_TYPE.GREATER_THAN,
    LOGIC_OPERATOR_TYPE.LESS_THAN
  ],
  [FIELD_TYPES.DROPDOWN]: [LOGIC_OPERATOR_TYPE.EQUAL_TO, LOGIC_OPERATOR_TYPE.NOT_EQUAL_TO],
  [FIELD_TYPES.SINGLE_CHOICE]: [LOGIC_OPERATOR_TYPE.EQUAL_TO, LOGIC_OPERATOR_TYPE.NOT_EQUAL_TO],
  [FIELD_TYPES.MULTIPLE_CHOICE]: [LOGIC_OPERATOR_TYPE.INCLUDES, LOGIC_OPERATOR_TYPE.EXCLUDES],
  [FIELD_TYPES.SHORT_TEXT]: [],
  [FIELD_TYPES.LONG_TEXT]: [],
  [FIELD_TYPES.INFO_TEXT]: [],
  [FIELD_TYPES.RESOURCE]: []
};

export const MIME_TYPE_HEADERS: { [key in MIME_TYPES]: string } = {
  PDF: 'application/pdf'
};

export enum FAMILY_ACCESS {
  SAFETYPLAN = 'SAFETYPLAN',
  LIFEPLAN = 'LIFEPLAN',
  BHAP = 'BHAP',
  APPOINMENT = 'APPOINMENT'
}

export enum LOG_TYPE {
  FORMUPDATE = 'FORMUPDATE',
  USERLOGIN = 'USERLOGIN'
}