import { Box, FormControl, MenuItem, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/redux-store';
import { updateExitPageThunk } from '../../../../redux/thunk/thunks';
import { ExitPageUpdate } from '../../../../shared/domain';

const _anyAllToBool = (logic_is_all?: null | boolean) => {
  if (typeof logic_is_all === 'boolean') {
    return logic_is_all ? 'all' : 'any';
  }
};
export const ExitPageLogicIsAllSelect = () => {
  type LogicIsAll = 'any' | 'all' | undefined;
  const classes = useStyles();
  const editingExitPage = useSelector((state: RootState) => state.DATA_REDUCER.editingExitPage);
  const [anyAll, setAnyAll] = useState<LogicIsAll>(_anyAllToBool(editingExitPage?.logic_is_all));
  const dispatch = useDispatch();

  const editingForm = useSelector((state: RootState) => state.DATA_REDUCER.editingForm);

  if (!editingExitPage || !editingForm) {
    return null;
  }
  const changeAnyAll = (
    event: React.ChangeEvent<{
      value: unknown;
    }>
  ) => {
    const val = event.target.value as LogicIsAll;
    //create update
    const exitPageUpdate: ExitPageUpdate = {
      ...editingExitPage,
      logic_is_all: val === 'all'
    };
    //update exitpage logic_is_all
    dispatch(updateExitPageThunk(exitPageUpdate, editingForm.id));
    setAnyAll(val);
  };

  return (
    <Box>
      <Box className={classes.topOptions}>
        <Box mr={1}>
          <Typography
            className={classes.fieldInstruction}
            variant={'subtitle2'}
            color={'textSecondary'}
          >
            Show this exit page when
          </Typography>
        </Box>
        <FormControl>
          <Select
            value={anyAll}
            onChange={changeAnyAll}
            IconComponent={UnfoldMoreIcon}
            className={classes.option}
          >
            <MenuItem disabled>Select An Option</MenuItem>
            <MenuItem value="any">Any</MenuItem>
            <MenuItem value="all">All</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box>
        <Typography variant={'subtitle2'} color={'textSecondary'}>
          of the following rules are met:
        </Typography>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: '.6rem'
  },
  topOptions: {
    display: 'flex',
    alignItems: 'center'
  },
  option: {
    maxHeight: '1.5rem',
    border: 'none'
  },
  fieldInstruction: {
    whiteSpace: 'nowrap'
  }
}));
