import {
  AppBar,
  Box,
  Button,
  Divider,
  makeStyles,
  Theme,
  Toolbar,
  Typography
} from '@material-ui/core';
import React from 'react';
import { colors } from '../../../../../styling/colors';
import { ArrowBack, Star, StarOutline } from '@material-ui/icons';
import { LoadingButton } from '../../../../../components/LoadingButton';
import { updateReferralThunk, unassignReferralToUsersThunk } from '../../../../../redux/thunk/thunks';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/redux-store';
import { ConfirmationDialog } from '../../../../../components/ConfirmationDialog';
import { useRouteMatch } from 'react-router-dom';

interface ReferralTopBarProps {
  handleClose: () => void;
  Edit: (open: boolean) => void;
  referralInfo: any;
  tab: any
}

export const ReferralTopBar = ({ handleClose, Edit, referralInfo, tab }: ReferralTopBarProps) => {
  const classes = useStyles();
  let dispatch = useDispatch();
  const { path } = useRouteMatch();
  const split = path.split('/');
  const shared_role_id: any = split && split.length > 1 && split[2];
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);
  const [confirm, setConfirm] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const data: any = useSelector((state: RootState) => state.DATA_REDUCER.editReferral);
  const editReferral = data ? data : referralInfo;
  const user: any = useSelector((state: RootState) => state.DATA_REDUCER.patientInfo);
  const sharedUser: any = useSelector((state: RootState) => state.DATA_REDUCER.sharedUser);

  const handleUnassign = async () => {
    setLoading(true);
    const data = {
      id: editReferral.referral_id,
      user_role_id: shared_role_id
    }
    await dispatch(unassignReferralToUsersThunk(data))
    setLoading(false);
    setConfirm(false);
  }

  const handelClose = () => {
    setConfirm(false);
  }

  return (
    <>
      <AppBar square elevation={0} classes={{ root: classes.headerRoot }}>
        <Toolbar classes={{ root: classes.headerToolbar }}>
          <Button
            classes={{ text: classes.closeBtnText }}
            startIcon={<ArrowBack style={{ color: 'gray', fontSize: '25px' }} />}
            onClick={() => handleClose()}
          >
            Return
          </Button>
          <Box alignSelf="stretch" display="flex" pr={3} py={2.75}>
            <Divider flexItem orientation="vertical" />
          </Box>

          <Box
            display="flex"
            alignItems='center'
            justifyContent="flex-start"
            minHeight="5rem"
            width="100%"
          >
            <Box>
              <Typography className={classes.subtitle}>
                {'SHARED_USER > ' + sharedUser?.first_name + ' ' + sharedUser?.last_name + ' >'}
              </Typography>
              <Typography color="textSecondary" variant="h2">
                <span style={{ color: 'gray' }}>Referrals:</span> {editReferral.first_name + ' ' + editReferral.last_name}
              </Typography>
            </Box>
            {/* {(editReferral && editReferral.isFavourite ? <Star color='primary' style={{ marginLeft: '30px', cursor: 'pointer' }} onClick={() => { loadings ? handelFavoriteCall() : handelRemoveFavorite() }} /> : <StarOutline color='primary' style={{ marginLeft: '30px', cursor: 'pointer' }} onClick={() => { loadings ? handelFavoriteCall() : handelAddFavorite() }} />)} */}
          </Box>
          {tab !== 'Referral Library' && <LoadingButton disabled={loading}
            className={classes.primaryButton} color="primary" variant="contained"
            onClick={() => setConfirm(true)}
          >
            Unassign
          </LoadingButton>}
        </Toolbar>
      </AppBar>
      {<ConfirmationDialog
        loading={loading}
        message='Are you sure you want to unassign referral?'
        open={confirm}
        title="Calculation"
        handleClose={handelClose}
        handleConfirmClick={() => handleUnassign()}
      />}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  headerRoot: {
    backgroundColor: 'white',
    borderBottom: `2px solid ${theme.palette.divider}`,
    padding: 0
  },
  headerToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 0
  },
  closeBtnText: {
    color: 'gray',
    marginRight: 18,
    fontSize: '16px',
    '&:hover': {
      backgroundColor: 'unset'
    }
  },
  editIcon: {
    marginLeft: '10px',
    fontSize: '12px',
    border: '1px solid #c1c1c1',
    color: '#c1c1c1',
    borderRadius: '3px',
  },
  subtitle: {
    color: colors.text6,
    fontWeight: 400
  },
  primaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: '#fff',
    minWidth: '120px'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    marginRight: '1rem',
    color: 'blue',
    minWidth: '120px'
  }
}));
