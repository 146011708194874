import { PatientTasksResults } from '../../shared/domain';
import { getHttp, putHttp, postHttp } from '../api';
import { setPatientFormInstancesAction } from '../data/data-actions';
import { setLoadingAction, snackbarAlertAction, setSuccessAction } from '../display/display-actions';
import { FlowproThunkDispatch, RootState } from '../redux-store';
import { getFormResponsesThunk } from './form-response-thunks';
import { push } from 'connected-react-router';

export const getPatientsCompletedTasksThunk = (
  page = 0,
  pageSize = 10,
  sort = 'created_at',
  order = 'DESC'
) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    dispatch(
      setLoadingAction(
        'getPatientFormInstancesCompleteLoading',
        true,
        'getPatientFormInstancesCompleteError'
      )
    );
    const patientTasks: any = await getHttp(
      `/patient/tasks/complete?page=${page}&sort=${sort}&order=${order}&pageSize=${pageSize}`
    );
    //set forminstances in redux as appropriate completedness
    patientTasks.order = order;
    patientTasks.sort = sort;
    patientTasks.pageSize = pageSize;
    patientTasks.page = page;
    dispatch(setPatientFormInstancesAction(patientTasks, 'complete'));
  } catch (e) {
    //if error throw an error for the correct completendness in redux
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error getting tasks: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(
      setLoadingAction(
        'getPatientFormInstancesCompleteLoading',
        false,
        'getPatientFormInstancesCompleteError'
      )
    );
  }
};

export const getPatientsIncompleteTasksThunk = (
  page = 0,
  pageSize = 10,
  sort = 'created_at',
  order = 'DESC'
) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    dispatch(
      setLoadingAction('getPatientFormInstancesIncompleteLoading', true, 'getPatientFormInstancesIncompleteError')
    );
    const form_version_id = sessionStorage.getItem('version');
    const patientTasks: PatientTasksResults = await getHttp(
      `/patient/tasks/incomplete?page=${page}&sort=${sort}&order=${order}&pageSize=${pageSize}&form_version_id=${form_version_id}`
    );
    //set forminstances in redux as appropriate completedness
    dispatch(setPatientFormInstancesAction(patientTasks, 'incomplete'));
  } catch (e) {
    //if error throw an error for the correct completendness in redux
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error getting tasks: ${e.message}`, 'error'));
    }
  } finally {
    dispatch(
      setLoadingAction(
        'getPatientFormInstancesIncompleteLoading',
        false,
        'getPatientFormInstancesIncompleteError'
      )
    );
  }
};

export const putChatbotAnswerThunk =
  (ans: any, inst_id: any, field_id: string, plan: any) =>
    async (dispatch: FlowproThunkDispatch) => {
      try {
        dispatch(setSuccessAction("inviteUserSuccess", false));
        const form_version_id = sessionStorage.getItem('version');
        const res = await putHttp(
          `/response/form/instance/${inst_id}/field/${field_id}?form_version_id=${form_version_id}`,
          {
            response_text: ans,
          }
        );
        dispatch(getFormResponsesThunk({ formInstanceId: inst_id, plan }));
        dispatch(setSuccessAction('inviteUserSuccess', true));
        dispatch(snackbarAlertAction(`Updated answer successfully`, 'success'));
      } catch (e) {
        if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
          dispatch(push('/login'));
        } else {
          dispatch(
            snackbarAlertAction(
              `Error update environment safety plan: ${e.message}`,
              "error"
            )
          );
        }
      }
    };

export const putChoiceAnswerThunk =
  (ans: any, inst_id: any, field_id: string, plan: any) =>
    async (dispatch: FlowproThunkDispatch) => {
      try {
        dispatch(setSuccessAction("inviteUserSuccess", false));
        const form_version_id = sessionStorage.getItem('version');
        const res = await putHttp(
          `/response/form/instance/${inst_id}/field/${field_id}?form_version_id=${form_version_id}`,
          {
            response_option: ans,
          }
        );
        dispatch(getFormResponsesThunk({ formInstanceId: inst_id, plan }));
        dispatch(setSuccessAction('inviteUserSuccess', true));
        dispatch(snackbarAlertAction(`Updated answer successfully`, 'success'));
      } catch (e) {
        if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
          dispatch(push('/login'));
        } else {
          dispatch(
            snackbarAlertAction(
              `Error update environment safety plan: ${e.message}`,
              "error"
            )
          );
        }
      }
    };

export const addFavoritePatientThunk = (patient_ids: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const sendInvite = await postHttp(`/patient/markAsFavourite`, {
      "patient_ids": patient_ids
    });
    if (sendInvite) {
      // dispatch(setSuccessAction('saveDataSuccess', true));
      dispatch(snackbarAlertAction(`Favorite added successfully`, 'success'));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error creating referral: ${e.message}`, 'error'));
    }
  }
};

export const removeFavoritePatientThunk = (patient_ids: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const sendInvite = await postHttp(`/patient/removeFromFavourites`, {
      patient_ids: patient_ids
    });
    if (sendInvite) {
      // dispatch(setSuccessAction('saveDataSuccess', true));
      dispatch(snackbarAlertAction(`Favorite removed successfully`, 'success'));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error creating referral: ${e.message}`, 'error'));
    }
  }
};