import React, { useEffect, useState } from 'react';
import { Box, Theme, Typography, makeStyles, Grid, Checkbox, TextField } from '@material-ui/core';
import { Dialog } from '../../../components/Dialog';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { createOrganizationThunk } from '../../../redux/thunk/thunks';
import { LoadingButton } from '../../../components/LoadingButton';
import { TitledTextInput } from '../../../components/TitledTextInput';
import { colors } from '../../../styling/colors';

interface organizationDialogProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const OrganizationDialog = ({ isOpen, handleClose }: organizationDialogProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isSet, setIsSet] = useState(false);
  const [isError, setError] = useState(false);
  const [inputs, setInputs] = useState(null);
  const [sameAsSiteAdmin, setSameAsSiteAdmin] = useState(false);
  const [initialValues, setInitialValues] = useState({
    organization_name: '',
    site_admin_first_name: '',
    site_admin_last_name: '',
    point_of_contact_first_name: '',
    site_admin_email: '',
    point_of_contact_email: '',
    point_of_contact_last_name: '',
    poc_same_as_site_admin: false
  });

  const handelSetValue = (values: any, poc_same_as_site_admin: boolean) => {
    setInitialValues({
      organization_name: values.organization_name,
      site_admin_first_name: values.site_admin_first_name,
      site_admin_last_name: values.site_admin_last_name,
      point_of_contact_first_name: poc_same_as_site_admin ? values.site_admin_first_name : '',
      site_admin_email: values.site_admin_email,
      point_of_contact_email: poc_same_as_site_admin ? values.site_admin_email : '',
      point_of_contact_last_name: poc_same_as_site_admin ? values.site_admin_last_name : '',
      poc_same_as_site_admin: poc_same_as_site_admin
    });
    setSameAsSiteAdmin(poc_same_as_site_admin);
    setIsSet(!isSet)
  }

  const organizationSchema = Yup.object().shape({
    organization_name: Yup.string().required('Required').max(100, ''),
    site_admin_first_name: Yup.string().required('Required').max(50, ''),
    site_admin_last_name: Yup.string().required('Required').max(50, ''),
    point_of_contact_first_name: Yup.string().required('Required').max(50, ''),
    point_of_contact_last_name: Yup.string().required('Required').max(50, ''),
    site_admin_email: Yup.string().email('Email is invalid').required('Required'),
    point_of_contact_email: Yup.string().email('Email is invalid').required('Required'),
  });

  const handleSubmit = async (values: any, actions: any) => {
    const data = values;
    if (values.poc_same_as_site_admin) {
      data.point_of_contact_first_name = values.site_admin_first_name;
      data.point_of_contact_last_name = values.site_admin_last_name;
      data.point_of_contact_email = values.site_admin_email;
      data.point_of_contact_phone = values.site_admin_phone;
    }
    actions.setSubmitting(true);
    await dispatch(createOrganizationThunk(values));
    actions.setSubmitting(false);
  }

  const handleChange = (value: any, no: any) => {
    if (no === 0 && value.length > 100) {
      setError(true); setInputs(no);
    } else if (no === 1 && value.length > 50) {
      setError(true); setInputs(no);
    } else if (no === 2 && value.length > 50) {
      setError(true); setInputs(no);
    } else if (no === 3 && value.length > 50) {
      setError(true); setInputs(no);
    } else if (no === 4 && value.length > 50) {
      setError(true); setInputs(no);
    } else {
      setError(false); setInputs(no);
    }
  }

  return (
    <Dialog handleClose={handleClose} open={isOpen}>
      <Box display="flex" flexDirection="column">
        <Box alignItems="center" display="flex" justifyContent="space-between" p={3} style={{ background: '#f9fafb', borderRadius: '10px 10px 0px 0px' }}>
          <Typography variant="h2">Inviting a New Organization</Typography>
        </Box>
        <Box pt={3} px={3}>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={organizationSchema}
          >
            {({ errors, isSubmitting, setFieldValue, setSubmitting, touched, values }) => {

              return (
                <Form style={{ width: '500px' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box pb={1.0}>
                        <Box pb={0.75}>
                          <Typography color="textSecondary" variant="subtitle2">
                            Organization Name*
                          </Typography>
                        </Box>

                        <Field fullWidth style={{ background: isError && inputs === 0 ? '#fff1f1' : 'unset' }}
                          onInput={(e: any) => handleChange(e.target.value, 0)}
                          as={TextField}
                          inputProps={{ maxLength: 101 }}
                          className={classes.textField}
                          name="organization_name"
                          size="small"
                          variant="outlined"
                        />
                        <Box display='flex' justifyContent='space-between'>
                          <Typography color={"error"} variant="subtitle2">
                            {errors.organization_name && touched.organization_name && errors.organization_name}
                          </Typography>
                          <Typography style={{ color: values.organization_name?.length > 100 ? '#911717' : '#0000008a' }} variant="subtitle2">
                            {values.organization_name?.length === 101 ? 100 : values.organization_name?.length}/100
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box pb={1}>
                        <Typography color="textSecondary" variant="h2">
                          Site Admin
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box pb={1.0}>
                        <Box pb={0.75}>
                          <Typography color="textSecondary" variant="subtitle2">
                            Site Admin First Name*
                          </Typography>
                        </Box>

                        <Field fullWidth style={{ background: isError && inputs === 1 ? '#fff1f1' : 'unset' }}
                          onInput={(e: any) => handleChange(e.target.value, 1)}
                          as={TextField}
                          inputProps={{ maxLength: 51 }}
                          className={classes.textField}
                          name="site_admin_first_name"
                          size="small"
                          variant="outlined"
                        />
                        <Box display='flex' justifyContent='space-between'>
                          <Typography color={"error"} variant="subtitle2">
                            {errors.site_admin_first_name && touched.site_admin_first_name && errors.site_admin_first_name}
                          </Typography>
                          <Typography style={{ color: values.site_admin_first_name?.length > 50 ? '#911717' : '#0000008a' }} variant="subtitle2">
                            {values.site_admin_first_name?.length === 51 ? 50 : values.site_admin_first_name?.length}/50
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box pb={1.0}>
                        <Box pb={0.75}>
                          <Typography color="textSecondary" variant="subtitle2">
                            Site Admin Last Name*
                          </Typography>
                        </Box>

                        <Field fullWidth style={{ background: isError && inputs === 2 ? '#fff1f1' : 'unset' }}
                          onInput={(e: any) => handleChange(e.target.value, 2)}
                          as={TextField}
                          inputProps={{ maxLength: 51 }}
                          className={classes.textField}
                          name="site_admin_last_name"
                          size="small"
                          variant="outlined"
                        />
                        <Box display='flex' justifyContent='space-between'>
                          <Typography color={"error"} variant="subtitle2">
                            {errors.site_admin_last_name && touched.site_admin_last_name && errors.site_admin_last_name}
                          </Typography>
                          <Typography style={{ color: values.site_admin_last_name?.length > 50 ? '#911717' : '#0000008a' }} variant="subtitle2">
                            {values.site_admin_last_name?.length === 51 ? 50 : values.site_admin_last_name?.length}/50
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box pb={1.0}>
                        <Box pb={0.75}>
                          <Typography color="textSecondary" variant="subtitle2">
                            Site Admin Email Address*
                          </Typography>
                        </Box>

                        <Field fullWidth
                          as={TextField}
                          className={classes.textField}
                          name="site_admin_email"
                          size="small"
                          variant="outlined"
                        />

                        {errors.site_admin_email && touched.site_admin_email ? (
                          <Typography color="error" variant="subtitle2">
                            {errors.site_admin_email}
                          </Typography>
                        ) : null}
                      </Box>
                    </Grid>
                    <Grid item xs={6} style={{ background: '#f9fafb' }}>
                      <Box pb={1}>
                        <Typography color="textSecondary" variant="h2">
                          Point of Contact
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'right', background: '#f9fafb' }}>
                      <Box mb={-1} ml={-1.1} mt={-1} pb={1}>
                        <Checkbox
                          disableRipple
                          checked={values.poc_same_as_site_admin}
                          color="primary"
                          onClick={() => { setFieldValue('poc_same_as_site_admin', !values.poc_same_as_site_admin); handelSetValue(values, !values.poc_same_as_site_admin) }}
                        />

                        <Typography style={{ color: 'black', fontWeight: 600 }}
                          display="inline"
                        >
                          Same as Site Admin
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box pb={1.0}>
                        <Box pb={0.75}>
                          <Typography color="textSecondary" variant="subtitle2">
                            Point of Contact First Name*
                          </Typography>
                        </Box>

                        <Field disabled={sameAsSiteAdmin} fullWidth style={{ background: isError && inputs === 3 ? '#fff1f1' : 'unset' }}
                          onInput={(e: any) => handleChange(e.target.value, 3)}
                          as={TextField}
                          inputProps={{ maxLength: 51 }}
                          className={classes.textField}
                          name="point_of_contact_first_name"
                          size="small"
                          variant="outlined"
                        />
                        <Box display='flex' justifyContent='space-between'>
                          <Typography color={"error"} variant="subtitle2">
                            {errors.point_of_contact_first_name && touched.point_of_contact_first_name && errors.point_of_contact_first_name}
                          </Typography>
                          <Typography style={{ color: values.point_of_contact_first_name?.length > 50 ? '#911717' : '#0000008a' }} variant="subtitle2">
                            {values.point_of_contact_first_name?.length === 51 ? 50 : values.point_of_contact_first_name?.length}/50
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box pb={1.0}>
                        <Box pb={0.75}>
                          <Typography color="textSecondary" variant="subtitle2">
                            Point of Contact Last Name*
                          </Typography>
                        </Box>

                        <Field disabled={sameAsSiteAdmin} fullWidth style={{ background: isError && inputs === 4 ? '#fff1f1' : 'unset' }}
                          onInput={(e: any) => handleChange(e.target.value, 4)}
                          as={TextField}
                          inputProps={{ maxLength: 51 }}
                          className={classes.textField}
                          name="point_of_contact_last_name"
                          size="small"
                          variant="outlined"
                        />
                        <Box display='flex' justifyContent='space-between'>
                          <Typography color={"error"} variant="subtitle2">
                            {errors.point_of_contact_last_name && touched.point_of_contact_last_name && errors.point_of_contact_last_name}
                          </Typography>
                          <Typography style={{ color: values.point_of_contact_last_name?.length > 50 ? '#911717' : '#0000008a' }} variant="subtitle2">
                            {values.point_of_contact_last_name?.length === 51 ? 50 : values.point_of_contact_last_name?.length}/50
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box pb={1.0}>
                        <Box pb={0.75}>
                          <Typography color="textSecondary" variant="subtitle2">
                            Point of Contact Email Address*
                          </Typography>
                        </Box>

                        <Field fullWidth disabled={sameAsSiteAdmin}
                          as={TextField}
                          className={classes.textField}
                          name="point_of_contact_email"
                          size="small"
                          variant="outlined"
                        />

                        {errors.point_of_contact_email && touched.point_of_contact_email ? (
                          <Typography color="error" variant="subtitle2">
                            {errors.point_of_contact_email}
                          </Typography>
                        ) : null}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="space-between">
                        <LoadingButton
                          className={classes.secondaryButton}
                          onClick={handleClose}
                          variant="contained"
                        >
                          Cancel
                        </LoadingButton>
                        <LoadingButton
                          className={classes.primaryButton}
                          color="primary"
                          disabled={isSubmitting}
                          loading={isSubmitting}
                          type="submit"
                          variant="contained"
                        >
                          Save
                        </LoadingButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </Dialog >
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  topBox: {
    borderRadius: '0.625rem 0.625rem 0 0'
  },
  bottomBox: {
    borderRadius: '0 0 0.625rem 0.625rem'
  },
  textField: {
    width: '100%'
  },
  primaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: '#fff',
    minWidth: '120px',
    marginBottom: '20px'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: '#0060B2',
    background: '#fff',
    marginBottom: '20px',
    minWidth: '120px'
  },
  deleteButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)',
    color: 'red',
    minWidth: '120px'
  },
  createCollectionTextField: {
    maxWidth: '40rem',
    width: '80vw'
  }
}));
