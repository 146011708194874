import { Box, MenuItem, makeStyles, Paper, Theme, Typography, Select } from '@material-ui/core';
import { Form, Formik, Field } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/redux-store';
import * as Yup from 'yup';
import { colors } from '../../../../styling/colors';
import { LoadingOrError } from '../../../../components/LoadingOrError';
import { TitledTextInput } from '../../../../components/TitledTextInput';
import { setEditReferralAction } from '../../../../redux/data/data-actions';
import { getStateThunk } from '../../../../redux/thunk/thunks';

interface ReferralDetailsProp {
  isEdit: boolean;
}

export const ReferralDetails = ({ isEdit }: ReferralDetailsProp) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState<any>({});

  const referralFilterList: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.referralFilterListResults
  );
  const editReferral: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.editReferral
  );

  const [specialitie, setSpecialitie] = useState(editReferral.specialities && editReferral.specialities.length > 0 ? (editReferral.specialities[0]?.id ? editReferral.specialities[0].id : editReferral.specialities[0]) : '');
  const [specialitie1, setSpecialitie1] = useState(editReferral.specialities && editReferral.specialities.length > 1 ? (editReferral.specialities[1]?.id ? editReferral.specialities[1].id : editReferral.specialities[1]) : '');
  const [specialitie2, setSpecialitie2] = useState(editReferral.specialities && editReferral.specialities.length > 2 ? (editReferral.specialities[2]?.id ? editReferral.specialities[2].id : editReferral.specialities[2]) : '');

  const stateList: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.stateList
  );
  useEffect(() => {
    dispatch(getStateThunk())
  }, [])

  const handleSaveChanges = async (values: any, actions: any) => {
  };

  const handelChangeZip = async (value: any) => {
    const re = /^[0-9\b]+$/;
    if (re.test(value) && value.length < 6) {
      await dispatch(setEditReferralAction({ ...editReferral, zip: value }));
    } else {
      value.length > 6 || value.length == 0 && dispatch(setEditReferralAction({ ...editReferral, zip: '' }));
    }
  }
  const handelSetSpecilitie = async (value: any, i: number) => {
    const check = editReferral.specialities ? editReferral.specialities.filter((item: any) => (item.id ? item.id : item) === value) : [];
    if (value !== '' && check && check.length === 0) {
      i === 0 && setSpecialitie(value);
      i === 1 && setSpecialitie1(value);
      i === 2 && setSpecialitie2(value);
      if (editReferral.specialities && editReferral.specialities.length >= (i + 1)) {
        editReferral.specialities[i]?.id ? editReferral.specialities[i].id = value : editReferral.specialities[i] = value;
      } else {
        editReferral.specialities = editReferral.specialities ? editReferral.specialities : [];
        const refId = { id: value };
        editReferral.specialities.push(refId);
      }
      await dispatch(setEditReferralAction({ ...editReferral, specialities: editReferral.specialities }));
    } else {
      const id = editReferral.specialities && editReferral.specialities.length > 0 ? editReferral.specialities.splice(i, 1) : [];
      const check = editReferral.specialities ? editReferral.specialities.filter((item: any) => (item.id ? item.id : item) !== (id[0]?.id ? id[0].id : id[0])) : [];
      await dispatch(setEditReferralAction({ ...editReferral, specialities: check }));
      i === 0 && setSpecialitie(value);
      i === 1 && setSpecialitie1(value);
      i === 2 && setSpecialitie2(value);
    }
    // const check = editReferral.specialities ? editReferral.specialities.filter((item: any) => (item.id ? item.id : item) === value) : [];
    // if (check && check.length === 0) {
    //   i === 0 && setSpecialitie(value);
    //   i === 1 && setSpecialitie1(value);
    //   i === 2 && setSpecialitie2(value);
    //   if (editReferral.specialities && editReferral.specialities.length >= (i + 1)) {
    //     editReferral.specialities[i].id = value;
    //   } else {
    //     editReferral.specialities = editReferral.specialities ? editReferral.specialities : [];
    //     const refId = { id: value };
    //     editReferral.specialities.push(refId);
    //   }
    //   await dispatch(setEditReferralAction({ ...editReferral, specialities: editReferral.specialities }));
    // }
  }
  const handelChangeEmail = async (value: any) => {
    await dispatch(setEditReferralAction({ ...editReferral, email: value }));
  }
  const handelChangePronouns = async (value: any) => {
    await dispatch(setEditReferralAction({ ...editReferral, pronouns: value }));
  }
  const handelChangePhone = async (value: any) => {
    await dispatch(setEditReferralAction({ ...editReferral, mobile_phone: value }));
  }
  const handelChangeAddress = async (value: any) => {
    await dispatch(setEditReferralAction({ ...editReferral, home_address: value }));
  }
  const handelChangeCity = async (value: any) => {
    await dispatch(setEditReferralAction({ ...editReferral, city: value }));
  }
  const handelChangeGender = async (value: any) => {
    await dispatch(setEditReferralAction({ ...editReferral, gender: value }));
  }
  const handelChangeYear = async (value: any) => {
    await dispatch(setEditReferralAction({ ...editReferral, years_in_practice: value }));
  }
  const handelChangeState = async (value: any) => {
    await dispatch(setEditReferralAction({ ...editReferral, state: value }));
  }
  const handelChangeCost = async (value: any) => {
    await dispatch(setEditReferralAction({ ...editReferral, cost_per_session: value }));
  }
  const handelChangeHealth = async (value: any) => {
    await dispatch(setEditReferralAction({ ...editReferral, telehealth: value }));
  }

  const isnumber = (value: any) => {
    const test = parseInt(value);
    if (test) {
      return Math.max(0, parseInt(value)).toString().slice(0, 15)
    } else {
      return null
    }
  }
  const isYear = (value: any) => {
    const test = parseInt(value);
    if (test) {
      return Math.max(0, parseInt(value)).toString().slice(0, 3)
    } else {
      return test === 0 ? 0 : null
    }
  }
  const validateEmail = (value: any) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const test = re.test(String(value).toLowerCase());
    if (test) {
      return re
    } else {
      return null
    }
  }

  return (
    <Paper style={{ width: '100%', borderRadius: '10px 0px 0px 10px' }}>
      <Box display="flex" flexDirection="column" minWidth="14rem" px={2.5} py={3}>
        {editReferral ? (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSaveChanges}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={false}
          >
            {({ errors, isSubmitting, touched, resetForm, setSubmitting }) => {
              return (
                <Form>
                  <Box alignItems="center" display="flex" justifyContent="space-between" pb={2.5}>
                    <Box pr={4}>
                      <Typography noWrap variant="h2">
                        General Information
                      </Typography>
                    </Box>
                  </Box>
                  <Box pb={2}>
                    <TitledTextInput value={editReferral && editReferral.pronouns} onChange={(e: any) => handelChangePronouns(e.target.value)}
                      disabled={!isEdit}
                      fullWidth
                      name="pronouns"
                      size="small"
                      title="Pronouns"
                      variant="outlined"
                      inputProps={{ maxLength: 20 }}
                    />

                    {errors.pronouns && touched.pronouns ? (
                      <Typography color="error" variant="subtitle2">
                        {errors.pronouns}
                      </Typography>
                    ) : null}
                  </Box>
                  <Box pb={2}>
                    <TitledTextInput value={editReferral && editReferral.email} onChange={(e: any) => handelChangeEmail(e.target.value)}
                      disabled={true}
                      fullWidth
                      name="email"
                      size="small"
                      title="Email Address"
                      variant="outlined"
                    />

                    {errors.email && touched.email ? (
                      <Typography color="error" variant="subtitle2">
                        {errors.email}
                      </Typography>
                    ) : null}
                  </Box>
                  <Box pb={2}>
                    <TitledTextInput value={editReferral && editReferral.mobile_phone} onChange={(e: any) => handelChangePhone(e.target.value)}
                      disabled={!isEdit}
                      fullWidth
                      name="phone"
                      size="small"
                      title="Phone Number"
                      variant="outlined"
                      onInput={(e: any) => {
                        e.target.value = e.target.value.length === 0 ? '' : isnumber(e.target.value)
                      }}
                      type="tel"
                    />

                    {errors.phone && touched.phone ? (
                      <Typography color="error" variant="subtitle2">
                        {errors.phone}
                      </Typography>
                    ) : null}
                  </Box>
                  <Box pb={2}>
                    <TitledTextInput value={editReferral && editReferral.home_address} onChange={(e: any) => handelChangeAddress(e.target.value)}
                      disabled={!isEdit}
                      fullWidth
                      name="address"
                      size="small"
                      title="Address"
                      variant="outlined"
                      inputProps={{ maxLength: 100 }}
                    />
                  </Box>
                  <Box pb={2}>
                    <TitledTextInput value={editReferral && editReferral.city} onChange={(e: any) => handelChangeCity(e.target.value)}
                      disabled={!isEdit}
                      fullWidth
                      name="city"
                      size="small"
                      title="City"
                      variant="outlined"
                      inputProps={{ maxLength: 50 }}
                    />
                  </Box>
                  <Box pb={2}>
                    <Box flexGrow="1">
                      <Box pb={0.75}>
                        <Typography color="textSecondary" variant="subtitle2">
                          State
                        </Typography>
                      </Box>

                      <Field disabled={!isEdit} as={Select} name="state" variant="outlined" className={classes.textField1}
                        value={editReferral && editReferral.state} onChange={(e: any) => handelChangeState(e.target.value)}>
                        <MenuItem value={''}>Select</MenuItem>
                        {stateList && stateList.length > 0 && stateList.map((item: any) => {
                          return <MenuItem value={item.state}>{item.state}</MenuItem>
                        })}
                      </Field>

                      {errors.state && touched.state ? (
                        <Typography color="error" variant="subtitle2">
                          {errors.state}
                        </Typography>
                      ) : null}
                    </Box>
                  </Box>
                  <Box pb={2}>
                    <TitledTextInput onChange={(e: any) => handelChangeZip(e.target.value)}
                      disabled={!isEdit} value={editReferral && editReferral.zip}
                      fullWidth
                      name="zip"
                      size="small"
                      title="Zipcode"
                      variant="outlined"
                    />
                  </Box>
                  <Box pb={2}>
                    <Box flexGrow="1">
                      <Box pb={0.75}>
                        <Typography color="textSecondary" variant="subtitle2">
                          Gender
                        </Typography>
                      </Box>

                      <Field disabled={!isEdit} as={Select} name="gender" variant="outlined" className={classes.textField1}
                        value={editReferral && editReferral.gender} onChange={(e: any) => handelChangeGender(e.target.value)}>
                        <MenuItem value={''}>Select</MenuItem>
                        {referralFilterList && referralFilterList.length > 0 && referralFilterList.map((item: any) => {
                          return (item.type === 'GENDER' && <MenuItem value={item.id}>{item.title}</MenuItem>)
                        })}
                      </Field>

                      {errors.gender && touched.gender ? (
                        <Typography color="error" variant="subtitle2">
                          {errors.gender}
                        </Typography>
                      ) : null}
                    </Box>
                  </Box>

                  <Box pb={2}>
                    <TitledTextInput onChange={(e: any) => handelChangeYear(e.target.value)}
                      disabled={!isEdit} value={editReferral && editReferral.years_in_practice}
                      fullWidth
                      name="yearInPractice"
                      size="small"
                      title="Years in Practice"
                      variant="outlined"
                      onInput={(e: any) => {
                        e.target.value = e.target.value.length === 0 ? '' : isYear(e.target.value)
                      }}
                    />
                  </Box>
                  <Box pb={2}>
                    <Box flexGrow="1">
                      <Box pb={0.75}>
                        <Typography color="textSecondary" variant="subtitle2">
                          Specialities
                        </Typography>
                      </Box>

                      <Field disabled={!isEdit} value={specialitie} onChange={(e: any) => { handelSetSpecilitie(e.target.value, 0) }} as={Select} name="specialitie" variant="outlined" className={classes.textField1}>
                        <MenuItem value={''}>Select</MenuItem>
                        {referralFilterList && referralFilterList.length > 0 && referralFilterList.map((item: any) => {
                          return ((item.type === 'SPECIALITY') && <MenuItem value={item.id}>{item.title}</MenuItem>)
                        })}
                      </Field>

                      {errors.cost_per_session && touched.cost_per_session ? (
                        <Typography color="error" variant="subtitle2">
                          {errors.cost_per_session}
                        </Typography>
                      ) : null}
                    </Box>
                    <Box pt={1}>
                      <Field disabled={!isEdit} value={specialitie1} onChange={(e: any) => { handelSetSpecilitie(e.target.value, 1) }} as={Select} name="specialitie1" variant="outlined" className={classes.textField1}>
                        <MenuItem value={''}>Select</MenuItem>
                        {referralFilterList && referralFilterList.length > 0 && referralFilterList.map((item: any) => {
                          return ((item.type === 'SPECIALITY') && <MenuItem value={item.id}>{item.title}</MenuItem>)
                        })}
                      </Field>
                    </Box>
                    <Box pt={1}>
                      <Field disabled={!isEdit} value={specialitie2} onChange={(e: any) => { handelSetSpecilitie(e.target.value, 2) }} as={Select} name="specialitie2" variant="outlined" className={classes.textField1}>
                        <MenuItem value={''}>Select</MenuItem>
                        {referralFilterList && referralFilterList.length > 0 && referralFilterList.map((item: any) => {
                          return ((item.type === 'SPECIALITY') && <MenuItem value={item.id}>{item.title}</MenuItem>)
                        })}
                      </Field>
                    </Box>
                  </Box>
                  <Box pb={2}>
                    <Box flexGrow="1">
                      <Box pb={0.75}>
                        <Typography color="textSecondary" variant="subtitle2">
                          Cost Per Session
                        </Typography>
                      </Box>

                      <Field disabled={!isEdit} as={Select} name="cost_per_session" variant="outlined" className={classes.textField1}
                        value={editReferral && editReferral.cost_per_session} onChange={(e: any) => handelChangeCost(e.target.value)}>
                        <MenuItem value={''}>Select</MenuItem>
                        {referralFilterList && referralFilterList.length > 0 && referralFilterList.map((item: any) => {
                          return (item.type === 'SESSION_COST' && <MenuItem value={item.id}>{item.title}</MenuItem>)
                        })}
                      </Field>

                      {errors.cost_per_session && touched.cost_per_session ? (
                        <Typography color="error" variant="subtitle2">
                          {errors.cost_per_session}
                        </Typography>
                      ) : null}
                    </Box>
                  </Box>
                  <Box pb={2}>
                    <Box flexGrow="1">
                      <Box pb={0.75}>
                        <Typography color="textSecondary" variant="subtitle2">
                          Telehealth Available
                        </Typography>
                      </Box>

                      <Field disabled={!isEdit} as={Select} name="teleHealth" variant="outlined" className={classes.textField1}
                        value={editReferral && editReferral.telehealth} onChange={(e: any) => handelChangeHealth(e.target.value)}>
                        <MenuItem value={''}>Select</MenuItem>
                        {referralFilterList && referralFilterList.length > 0 && referralFilterList.map((item: any) => {
                          return (item.type === 'TELEHEALTH' && <MenuItem value={item.id}>{item.title}</MenuItem>)
                        })}
                      </Field>

                      {errors.teleHealth && touched.teleHealth ? (
                        <Typography color="error" variant="subtitle2">
                          {errors.teleHealth}
                        </Typography>
                      ) : null}
                    </Box>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        ) : (
          <LoadingOrError errorMsg={null} loading={true} loadingMsg="" noElevation />
        )}
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  editIcon: {
    color: colors.text6,
    fontSize: 16
  },
  textField: {
    width: '100%'
  },
  buttonRoot: {
    display: 'flex',
    height: '100%',
    margin: 0,
    padding: 0
  },
  primaryButton: {
    boxShadow:
      '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)'
  },
  textField1: {
    width: '100%',
    height: '34px',
    padding: '0px'
  }
}));
