import { Box, makeStyles, Toolbar, Typography, Divider, AppBar, Button } from '@material-ui/core';
import { ArrowBack, Close } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, RouteComponentProps, Switch, useRouteMatch, withRouter, useHistory } from 'react-router-dom';
import { Dialog } from '../../components/Dialog';
import { RootState } from '../../redux/redux-store';
import { UuidType } from '../../shared/domain';
import { FormPage } from './Forms/FormPage';
import { ResultPage } from './Result/ResultPage';
import { ReferralPage } from './Referrals/ReferralPage';
import { ResourcePage } from './Resources/ResourcePage';
import { AppointmentPage } from './Appointments/AppointmentPage';
import { SharedUserPage } from './SharedUser/SharedUserPage';
import { getSharedUserInfoThunk, sendInviteToSharedUser, cancelInviteToSharedUser } from '../../redux/thunk/thunks';
import { setSuccessAction } from '../../redux/display/display-actions';
import { LoadingButton } from '../../components/LoadingButton';
import { colors } from '../../styling/colors';

interface RouterProps {
    formId: UuidType;
}

interface PatientRoutesProps extends RouteComponentProps<RouterProps> { }

const Routes: React.FC<PatientRoutesProps> = ({ match }) => {
    let { url } = useRouteMatch();
    const classes = useStyles();
    const dispatch = useDispatch();
    const history: any = useHistory();
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const split = url.split('/');
    const shared_role_id: any = split && split.length > 1 && split[2];

    const user: any = useSelector((state: RootState) => state.DATA_REDUCER.sharedUser);
    const success: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.saveDataSuccess);
    useEffect(() => {
        if (success) {
            dispatch(setSuccessAction('saveDataSuccess', false));
            history.push('/shared-user');
        }
    })

    useEffect(() => {
        !user && dispatch(getSharedUserInfoThunk(shared_role_id));
    }, []);

    const handleClose = () => {
        history.push(`/shared-user`)
    }

    const sendInvite = async () => {
        await dispatch(sendInviteToSharedUser(user?.user_role_id, user?.role));
        await dispatch(getSharedUserInfoThunk(shared_role_id));
        setIsOpen(true);
    }
    const cancelInvite = async () => {
        await dispatch(cancelInviteToSharedUser(user?.user_role_id, user?.role));
        await dispatch(getSharedUserInfoThunk(shared_role_id));
    }

    const handleCloseDialog = () => {
        setIsOpen(false);
    }

    return (
        <Box style={{ height: '100vh', background: '#F1F5F9' }}>
            <AppBar square elevation={0} classes={{ root: classes.headerRoot }}>
                <Toolbar classes={{ root: classes.headerToolbar }}>
                    <Button classes={{ text: classes.closeBtnText }} startIcon={<ArrowBack style={{ color: 'gray', fontSize: '25px' }} />}
                        onClick={() => handleClose()}>
                        Return
                    </Button>
                    <Box alignSelf="stretch" display="flex" pr={3} py={2}>
                        <Divider flexItem orientation="vertical" />
                    </Box>
                    <Box display="flex" alignItems='center' justifyContent="flex-start" minHeight="5rem" width="100%">
                        <Box>
                            <Typography className={classes.subtitle}>
                                {`PATIENT > ${loggedInUser && (loggedInUser?.first_name + ' ' + loggedInUser?.last_name)} >`}
                            </Typography>
                            <Typography color="textSecondary" variant="h2" style={{ marginTop: '6px', fontSize: '20px' }}>
                                <span style={{ color: 'gray' }}>Shared User: </span>{user && (' ' + user?.first_name + ' ' + user?.last_name)}
                            </Typography>
                        </Box>
                    </Box>
                    {(user?.status === "INVITE_NOT_SENT" || user?.status === "INVITE_EXPIRED" || user?.status === "INVITE_CANCELLED") ? <LoadingButton loading={loading} disabled={loading} className={classes.primaryButton} color="primary" variant="contained" onClick={(e) => sendInvite()}>
                        Invite
                    </LoadingButton> :
                        user?.status === "AWAITING_ACCEPTANCE" && <LoadingButton loading={loading} disabled={loading} className={classes.primaryButton} color="primary" variant="contained" onClick={(e) => cancelInvite()}>
                            Cancel Invite
                        </LoadingButton>}
                </Toolbar>
            </AppBar>
            <Box style={{ marginTop: '100px' }}>
                <Switch>
                    <Route path={`${url}/result`}>
                        <ResultPage />
                    </Route>
                    <Route path={`${url}/forms`}>
                        <FormPage />
                    </Route>
                    <Route path={`${url}/resource`}>
                        <ResourcePage />
                    </Route>
                    <Route path={`${url}/referral`}>
                        <ReferralPage />
                    </Route>
                    <Route path={`${url}/appointment`}>
                        <AppointmentPage />
                    </Route>
                    <Route path={`${url}/profile`}>
                        <SharedUserPage />
                    </Route>
                </Switch>
            </Box>
            {isOpen &&
                <Dialog handleClose={handleCloseDialog} open={isOpen}>
                    <Box style={{ width: '400px' }}>
                        <Box pb={3} pt={2} px={4} display="flex" justifyContent="space-between">
                            <Typography color="textSecondary" variant="h2">Invitation Success</Typography>
                            <Close onClick={() => setIsOpen(false)} />
                        </Box>
                        <Box pb={6} pt={2} display="flex" justifyContent="center">
                            <Typography color="textSecondary" variant="h2">Invitation Sent</Typography>
                        </Box>
                    </Box>
                </Dialog>
            }
        </Box>
    );
};

export const PatientSharedUserRoutes = withRouter(Routes);

const useStyles = makeStyles((theme) => ({
    main: {
        backgroundColor: theme.background.main,
        minHeight: '100vh'
    },
    subtitle: {
        color: colors.text6,
        fontWeight: 600,
        fontSize: '16px'
    },
    primaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#fff',
        minWidth: '120px'
    },
    headerRoot: {
        backgroundColor: 'white',
        borderBottom: `2px solid ${theme.palette.divider}`,
        padding: 0
    },
    headerToolbar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    closeBtnText: {
        color: 'gray',
        marginRight: 18,
        fontSize: '18px',
        '&:hover': {
            backgroundColor: 'unset'
        }
    },
}));
