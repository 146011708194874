import { Referral, ReferralSearchResults, UuidType } from '../../shared/domain';
import { getHttp, postHttp, putHttp, patchHttp, deleteHttp } from '../api';
import {
  getReferralAction, getReferralsAction, getReferralFilterAction, ReferralsType, getReferralInfoAction, getReferralFilterLibAction,
  getSharingUserAction, usersAssignmentAction
} from '../data/data-actions';
import { snackbarAlertAction, setSuccessAction, setLoadingAction } from '../display/display-actions';
import { FlowproThunkDispatch, RootState } from '../redux-store';
import { push } from 'connected-react-router';
import { logoutThunk } from './auth-thunks';

export interface GetReferralsProps {
  referralType?: ReferralsType;
  referral_type?: string;
  order?: string;
  page?: number;
  pageSize?: number;
  search?: string;
  sort?: string;
  favourites?: boolean;
  org_id?: any;
  filters?: {
    insurances: Array<any>;
    issues: Array<any>;
    sexuality: Array<any>;
    gender: Array<any>;
    specialities: Array<any>;
    cost_per_session: Array<any>;
    telehealth: Array<any>;
    therapy_types: Array<any>;
    ages_served: Array<any>;
    languages: Array<any>;
  }
}
export const getReferralsThunk = ({
  referralType = 'referrals', order = 'DESC', referral_type = 'ALL', page = 0, pageSize = 10, search = '', sort = 'city', favourites = true, filters = {},
  organization_id = null
}: any) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    dispatch(setLoadingAction('getDataLoading', true, 'getDataLoadingError'));
    const logedUser: any = localStorage.getItem('formFlash');
    const userRoleId = JSON.parse(logedUser);
    const referrals: any = await postHttp(`/referral/getReferrals`, {
      "referral_type": userRoleId && (userRoleId.role === 'SUPER_ADMIN' || userRoleId.role === 'ADMIN') ? 'BASE' : referral_type,
      "org_id": organization_id ? organization_id : userRoleId ? userRoleId.organization_id : null,
      "search": search,
      "limit": pageSize,
      "skip": page * pageSize,
      "sortBy": sort,
      "order": order,
      "favourites": favourites,
      "insurances": filters.insurances ? filters.insurances : [],
      "issues": filters.issues ? filters.issues : [],
      "sexuality": filters.sexuality ? filters.sexuality : [],
      "gender": filters.gender ? filters.gender : [],
      "specialities": filters.specialities ? filters.specialities : {},
      "cost_per_session": filters.cost_per_session ? filters.cost_per_session : [],
      "telehealth": filters.telehealth ? filters.telehealth : [],
      "therapy_types": filters.therapy_types ? filters.therapy_types : [],
      "ages_served": filters.ages_served ? filters.ages_served : [],
      "languages": filters.languages ? filters.languages : []
    });
    if (referrals) {
      referrals.referral_type = userRoleId && (userRoleId.role === 'SUPER_ADMIN' || userRoleId.role === 'ADMIN') ? 'BASE' : referral_type;
      referrals.page = page;
      referrals.pageSize = pageSize;
      referrals.search = search;
      referrals.sort = sort;
      referrals.order = order;
      referrals.favourites = favourites;
      referrals.filters = filters;
      dispatch(getReferralsAction(referralType, referrals));
    }
    dispatch(setLoadingAction('getDataLoading', false, 'getDataLoadingError'));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
    dispatch(setLoadingAction('getDataLoading', false, 'getDataLoadingError'));
  }
};

export const getTagReferralsThunk = ({
  referralType = 'referrals',
  order = 'DESC',
  page = 0,
  pageSize = 10,
  search = '',
  sort = 'created_at'
}: GetReferralsProps) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    const referrals: ReferralSearchResults = await getHttp(
      `/referral?tags=`
    );
    if (referrals) {
      dispatch(getReferralsAction(referralType, referrals));
    } else {
      dispatch(snackbarAlertAction(`Error loading Referrals`, 'error'));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error loading Referrals: ${e.message}`, 'error'));
    }
  }
};

export const getReferralThunk = (id: UuidType) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const referral: Referral = await getHttp(`/user/referral/${id}`);
    dispatch(getReferralAction(referral));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error getting referral information: ${e.message}`, 'error'));
    }
  }
};

export const getReferralsFilterLibThunk = () => async (
  dispatch: FlowproThunkDispatch, getState: () => RootState
) => {
  try {
    const referralFilterList: any = await getHttp(`/referral/referralFilterList`);
    dispatch(getReferralFilterLibAction(referralFilterList));
  } catch (e) {
    dispatch(snackbarAlertAction(`Error getting referral information: ${e.message}`, 'error'));
  }
};

export const getReferralsFilterThunk = ({ type = 'ALL' }: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const referralFilterList: any = await getHttp(`/referral/getReferralList/${type}`);
    dispatch(getReferralFilterAction(referralFilterList.data));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const createReferralThunk = (values: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const logedUser: any = localStorage.getItem('formFlash');
    const userRoleId = JSON.parse(logedUser);
    const saveReferral = await postHttp(`/referral`, {
      "first_name": values.firstName,
      "last_name": values.lastName,
      "email": values.email,
      "org_id": userRoleId ? userRoleId.organization_id : null,
    });
    if (saveReferral) {
      // dispatch(getReferralsThunk({}));
      dispatch(setSuccessAction('saveReferralSuccess', true));
      dispatch(snackbarAlertAction(`Add referral successfully`, 'success'));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const updateReferralThunk = (values: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const updateReferral = await putHttp(`/referral/updateReferral `, {
      "referral_id": values.referral_id,
      "title": values.title,
      "email": values.email,
      "address": values.home_address,
      "zip": values.zip,
      "city": values.city,
      "state": values.state,
      "phone": values.mobile_phone,
      "gender": values.gender,
      "org_id": null,
      "first_name": values.first_name,
      "last_name": values.last_name,
      "pronouns": values.pronouns,
      "years_in_practice": parseInt(values.years_in_practice),
      "about": values.about,
      "specialities": values.specialities ? values.specialities : [],
      "insurances": values.insurances ? values.insurances : [],
      "therapy_types": values.therapy_types ? values.therapy_types : [],
      "issues": values.issues ? values.issues : [],
      "languages": values.languages ? values.languages : [],
      "sexuality": values.sexuality ? values.sexuality : [],
      "cost_per_session": values.cost_per_session,
      "telehealth": values.telehealth,
      "ages_served": values.ages_served ? values.ages_served : []
    });
    if (updateReferral) {
      // dispatch(getReferralsThunk({}));
      dispatch(setSuccessAction('saveReferralSuccess', true));
      dispatch(snackbarAlertAction(`Updated referral successfully`, 'success'));
      sessionStorage.removeItem('referral');
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const deleteReferralThunk = (data: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const deleteReferral = await patchHttp(`/referral/delete`, { data });
    if (deleteReferral) {
      // dispatch(getReferralsThunk({}));
      dispatch(setSuccessAction('saveReferralSuccess', true));
      dispatch(snackbarAlertAction(`Delete referral successfully`, 'success'));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const removeFevReferralThunk = (data: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    data.user_role_id = loggedInUser.id;
    const removeFevReferral = await postHttp(`/referral/removeFromFavourite`, data);
    if (removeFevReferral) {
      dispatch(setSuccessAction('saveReferralSuccess', true));
      dispatch(snackbarAlertAction(`Favorite removed successfully`, 'success'));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const sentReferralInviteThunk = (id: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const sendInvite = await getHttp(`/referral/${id}/resend-invite`);
    if (sendInvite) {
      // dispatch(getReferralsThunk({}));
      dispatch(setSuccessAction('saveReferralSuccess', true));
      dispatch(snackbarAlertAction(`Invite sent successfully`, 'success'));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error creating referral: Referral does not exist!`, 'error'));
    }
  }
};

export const addFavoriteReferralThunk = (id: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const sendInvite = await getHttp(`/referral/${id}/mark-as-favourite`);
    if (sendInvite) {
      // dispatch(setSuccessAction('saveReferralSuccess', true));
      dispatch(snackbarAlertAction(`Favorite added successfully`, 'success'));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error creating referral: ${e.message}`, 'error'));
    }
  }
};

export const removeFavoriteReferralThunk = (id: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const sendInvite = await deleteHttp(`/referral/${id}/remove-from-favourite`);
    if (sendInvite) {
      // dispatch(setSuccessAction('saveReferralSuccess', true));
      dispatch(snackbarAlertAction(`Favorite removed successfully`, 'success'));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error creating referral: ${e.message}`, 'error'));
    }
  }
};

export const getReferralInfoThunk = (id: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const referralInfo: any = await getHttp(`/referral/${id}`);
    dispatch(getReferralInfoAction(referralInfo.data));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error getting referral information: ${e.message}`, 'error'));
    }
  }
};

export const checkUserReferralAssignmentThunk = (data: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const res: any = await postHttp(`/patient/checkUserReferralAssignment`, {
      "referral_id": data.resource_id,
      "user_role_ids": data.user_role_ids
    });
    dispatch(usersAssignmentAction(res.data));
    if (res) {
      return res.data;
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const assignReferralThunk = (data: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const role: any = sessionStorage.getItem('role');
    const res: any = await postHttp(`/patient/assignReferralToUsers`, {
      "referral_id": data.resource_id,
      "user_role_ids": data.user_role_ids
    });
    if (role === 'PATIENT' || role === 'SHARED_USER') {
      dispatch(setSuccessAction('saveReferralSuccess', true));
      dispatch(setSuccessAction('uploadStaticResourceSuccess', true));
    } else {
      dispatch(setSuccessAction('saveDataSuccess', true));
    }
    dispatch(snackbarAlertAction(`Sharing settings updated successfully`, 'success'));
    dispatch(getSharingUserAction(null));
    if (res) {
      return res;
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

export const deleteReferralProfileThunk = (data: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const deleteReferral = await patchHttp(`/referral/delete`, { data });
    if (deleteReferral) {
      dispatch(snackbarAlertAction(`Delete referral successfully`, 'success'));
      dispatch(logoutThunk());
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error creating referral: ${e.message}`, 'error'));
    }
  }
};

export const validatePasswordThunk = (data: any) => async (
  dispatch: FlowproThunkDispatch, getState: () => RootState
) => {
  try {
    const validate = await postHttp(`/user/auth/validate-password`, data);
    if (validate.validPassword) {
      return validate;
    } else {
      dispatch(snackbarAlertAction(`Invalid password entered!`, 'error'));
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};

/**Patient portal related API's */
export const assignReferralToUsersThunk = (data: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const res: any = await putHttp(`/referral/updateSharingSettings`, {
      "referral_id": data.id,
      "user_role_ids": data.user_role_ids,
      "patient_id": data.patient_id,
      "clinician_id": data.clinician_id
    });
    dispatch(setSuccessAction('saveReferralSuccess', true));
    dispatch(snackbarAlertAction(`Sharing settings updated successfully`, 'success'));
    if (res) {
      return res;
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: Failed to assigned referral`, 'error'));
    }
  }
};

export const getPatientReferralsThunk = ({
  order = 'DESC', page = 0, pageSize = 10, search = '', sort = 'city', favourites = true, filters = {}, organization_id = null, user_role_id = null
}: any) => async (dispatch: FlowproThunkDispatch, getState: () => RootState) => {
  try {
    dispatch(setLoadingAction('getDataLoading', true, 'getDataLoadingError'));
    const logedUser: any = localStorage.getItem('formFlash');
    const userRoleId = JSON.parse(logedUser);
    const referrals: any = await postHttp(`/referral/getAssignedReferrals`, {
      "org_id": organization_id ? organization_id : userRoleId ? userRoleId.organization_id : null,
      "search": search,
      "limit": pageSize,
      "skip": page * pageSize,
      "sortBy": sort,
      "order": order,
      "favourites": favourites,
      "user_role_id": user_role_id ? user_role_id : userRoleId.id,
      "insurances": filters.insurances ? filters.insurances : [],
      "issues": filters.issues ? filters.issues : [],
      "sexuality": filters.sexuality ? filters.sexuality : [],
      "gender": filters.gender ? filters.gender : [],
      "specialities": filters.specialities ? filters.specialities : {},
      "cost_per_session": filters.cost_per_session ? filters.cost_per_session : [],
      "telehealth": filters.telehealth ? filters.telehealth : [],
      "therapy_types": filters.therapy_types ? filters.therapy_types : [],
      "ages_served": filters.ages_served ? filters.ages_served : [],
      "languages": filters.languages ? filters.languages : []
    });
    if (referrals) {
      referrals.page = page;
      referrals.pageSize = pageSize;
      referrals.search = search;
      referrals.sort = sort;
      referrals.order = order;
      referrals.favourites = favourites;
      referrals.filters = filters;
      dispatch(getReferralsAction('referrals', referrals));
    }
    dispatch(setLoadingAction('getDataLoading', false, 'getDataLoadingError'));
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    }
    dispatch(setLoadingAction('getDataLoading', false, 'getDataLoadingError'));
  }
};

/**Unassign patient to referral API's */
export const unassignReferralToUsersThunk = (data: any) => async (
  dispatch: FlowproThunkDispatch,
  getState: () => RootState
) => {
  try {
    const res: any = await postHttp(`/referral/${data.id}/unassignReferral`, {
      "user_role_id": data.user_role_id
    });
    dispatch(setSuccessAction('saveReferralSuccess', true));
    dispatch(snackbarAlertAction(`Referral unassigned successfully`, 'success'));
    if (res) {
      return res;
    }
  } catch (e) {
     if (e.message == `Unexpected token 'F', "Forbidden" is not valid JSON`) {
      localStorage.removeItem('token');
      localStorage.removeItem('formFlash');
      localStorage.removeItem('remember');
      dispatch(push('/login'));
      dispatch(snackbarAlertAction(`Error: Your session has expired!`, 'warning'));
    } else {
      dispatch(snackbarAlertAction(`Error: ${e.message}`, 'error'));
    }
  }
};