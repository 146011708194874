import { Box, Card, Container, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Droppable, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { LoadingOrError } from '../../../components/LoadingOrError';
import { RootState } from '../../../redux/redux-store';
import { FieldOptionInput, FormField, UuidType } from '../../../shared/domain';
import { FieldBlockContainer } from './fieldBlocks/FieldBlockContainer';

interface FormViewerProps {
  formId: UuidType;
  createNewFieldObject: (
    fieldType: string,
    nextFormField: UuidType | null,
    fieldPrompt?: string,
    fieldOptions?: FieldOptionInput[],
    fieldScaleMax?: number,
    formField?: any
  ) => void;
}

export const FormViewer: React.FC<FormViewerProps> = ({ formId, createNewFieldObject }) => {
  const classes = useStyles();

  const loadingFields = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.getFormFieldsLoading
  );
  const loadingFieldsError = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayErrors.getFormFieldsError
  );
  const editingFormFields = useSelector((state: RootState) => state.DATA_REDUCER.editingFormFields);

  const displayLoadingOrFields = () => {
    if ((loadingFields || loadingFieldsError) && !editingFormFields) {
      return (
        <LoadingOrError
          loading={loadingFields}
          loadingMsg={'Fetching Fields...'}
          errorMsg={loadingFieldsError}
          noElevation
        />
      );
    } else {
      return editingFormFields?.map((field: FormField, i: number) => (
        <FieldBlockContainer
          formId={formId}
          loading={false}
          loadingError={null}
          formField={field}
          index={i}
          key={field.id}
          createNewFieldObject={createNewFieldObject}
        />
      ));
    }
  };
  return (
    <Container maxWidth="md" className={classes.formContainer}>
      <Droppable isDropDisabled={false} droppableId={'formViewerDroppable'}>
        {(provided, snapshot) => (
          <Paper ref={provided.innerRef} elevation={1} classes={{ root: classes.paperRoot }}>
            {displayLoadingOrFields()}
            {editingFormFields?.length === 0 && !loadingFields ? (
              <Box className={classes.placeholderCard}>No Form Fields</Box>
            ) : null}
            {provided.placeholder}
          </Paper>
        )}
      </Droppable>
    </Container>
  );
};
interface DropFieldAreaProps {
  droppableSnapshot: DroppableStateSnapshot;
}
//TO DO: display this componenet as the drop area when a new field chip is hovering over the form builder view
const DropFieldArea: React.FC<DropFieldAreaProps> = ({ droppableSnapshot }) => {
  const classes = useStyles();
  return (
    <Card variant="outlined" elevation={0} className={classes.placeholderCard}>
      <Typography variant="subtitle2">Drop a field Here</Typography>
      <Typography variant="subtitle2">{droppableSnapshot.draggingOverWith}</Typography>
    </Card>
  );
};
const useStyles = makeStyles(() => ({
  formContainer: {
    flex: 4,
    display: 'grid',
    gridGap: 24,
    marginRight: '-20px'
  },
  hide: {
    display: 'none'
  },
  placeholderCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '.4rem'
  },
  paperRoot: {
    padding: '1.2rem',
    marginLeft: '1rem',
    marginRight: '1rem',
    display: 'grid',
    gridGap: '1rem'
  }
}));
