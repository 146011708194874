import React, { useEffect, useState } from 'react';
import { Box, Paper } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { CardHeader } from '../../../components/LegacyCardHeader';
import { deleteAppointmentsThunk } from '../../../redux/thunk/thunks';
import { RootState } from '../../../redux/redux-store';
import { AppointmentsTable } from './AppointmentsTable';
import { UuidType } from '../../../shared/domain';
import { NewAppointment } from './NewAppointment';
import { getAllAppointmentThunk, getPatientAppointmentsThunk } from '../../../redux/thunk/appointment-thunks';
import { ConfirmationDialog } from '../../../components/ConfirmationDialog';
import { setSuccessAction } from '../../../redux/display/display-actions';
import { getAppointmentsAction } from '../../../redux/data/data-actions';
import { ROLES } from '../../../shared/globals';

export const Appointments = () => {
  const dispatch = useDispatch();
  const logedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(logedUser);
  const [selectedAppointments, setSelectedAppointment] = useState<UuidType[]>([]);
  const [newAppointmentDialogueOpen, setNewAppointmentDialogueOpen] = useState(false);
  const [deleteFormsConfirmationDialogOpen, setDeleteFormsConfirmationDialogOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [onTab, setOnTab] = useState('');

  const appointments: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.appointmentSearchResults.appointments
  );

  const success: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.appointmentSuccess);
  useEffect(() => {
    if (success) {
      dispatch(setSuccessAction('appointmentSuccess', false));
      if ([ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role)) {
        dispatch(getPatientAppointmentsThunk({
          order: appointments.order,
          page: appointments.page,
          pageSize: appointments.pageSize,
          givePatientAppointments: appointments.givePatientAppointments,
          sort: appointments.sort
        }))
      } else {
        dispatch(getAllAppointmentThunk({
          order: appointments.order,
          page: appointments.page,
          pageSize: appointments.pageSize,
          search: appointments.search,
          sort: appointments.sort
        }))
      }
    }
  })

  const handleSearch = (value: string) => {
    if (appointments) {
      dispatch(
        getAllAppointmentThunk({
          order: appointments.order,
          page: appointments.page,
          pageSize: appointments.pageSize,
          search: value,
          sort: appointments.sort
        })
      );
    }
  };

  const handleDeleteFormsConfirmationDialogClose = () => {
    setDeleteFormsConfirmationDialogOpen(false);
  };
  const handleDeleteCollections = async (library?: boolean) => {
    setDeleteLoading(true);

    const delete_ids = { collection_ids: selectedAppointments };

    await dispatch(deleteAppointmentsThunk(delete_ids));
    dispatch(getAllAppointmentThunk({
      search: appointments.search,
      order: appointments.order,
      page: appointments.page,
      pageSize: appointments.pageSize,
    }));
    setSelectedAppointment([]);
    setDeleteLoading(false);
    setDeleteFormsConfirmationDialogOpen(false);
  };

  // We need async/await over here as it is used for some state loading task
  const loadAppointments = async (GetAppointmentsProps: any) => {
    if ([ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role)) {
      dispatch(getPatientAppointmentsThunk(GetAppointmentsProps));
    } else {
      await dispatch(getAllAppointmentThunk(GetAppointmentsProps));
    }
  };

  const handleNewAppointmentButton = () => {
    setNewAppointmentDialogueOpen(true);
  };
  const handleNewAppointmentDialogueClose = () => {
    setNewAppointmentDialogueOpen(false);
  };

  useEffect(() => {
    if ([ROLES.PATIENT].includes(loggedInUser.role)) {
      dispatch(getPatientAppointmentsThunk({}));
    } else if ([ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role)) {
    } else {
      dispatch(getAllAppointmentThunk({}));
    }

    return function CleanUp() {
      dispatch(getAppointmentsAction('appointments', null));
    };
  }, []);

  const onTabChange = async (e: any) => {
    if (e === 'Patient Appointments' && (onTab !== e || onTab === '')) {
      setOnTab(e);
      await dispatch(getPatientAppointmentsThunk({ givePatientAppointments: true }));
    }
  }

  const onTab1Change = async (e: any) => {
    if (e === 'My Appointments' && (onTab !== e || onTab === '')) {
      setOnTab(e);
      await dispatch(getPatientAppointmentsThunk({ givePatientAppointments: false }));
    }
  }

  const AppointmentsTableContainer = (
    <Box pb={4} pt={1} px={4}>
      <AppointmentsTable
        appointmentsData={appointments}
        selectedAppointments={selectedAppointments}
        loadAppointments={loadAppointments}
        setSelectedAppointment={(selectedAppointments: UuidType[]) => setSelectedAppointment(selectedAppointments)}
      />
    </Box>
  );

  return (
    <>
      {deleteFormsConfirmationDialogOpen &&
        <ConfirmationDialog
          loading={deleteLoading}
          message='type "DELETE" to confirm.'
          open={deleteFormsConfirmationDialogOpen}
          title='Are you sure you want to delete? Please'
          handleClose={handleDeleteFormsConfirmationDialogClose}
          handleConfirmClick={() => handleDeleteCollections(false)}
        />
      }
      {newAppointmentDialogueOpen && (
        <NewAppointment open={newAppointmentDialogueOpen} handleClose={handleNewAppointmentDialogueClose}
          patient={null} appointmentInfo={null} edit={false} />
      )}

      <Paper>
        <Box display='flex' flexDirection='column'>
          {[ROLES.PATIENT].includes(loggedInUser.role) ?
            <CardHeader
              tabComponents={[
                {
                  component: AppointmentsTableContainer,
                  label: 'Appointments',
                  style: {
                    background: true,
                    spacing: { py: 3, px: 4 }
                  },
                  title: 'Appointments',
                }
              ]}
            /> :
            [ROLES.PATIENT, ROLES.RATER, ROLES.PROXY].includes(loggedInUser.role) ? <CardHeader
              tabComponents={[
                {
                  component: AppointmentsTableContainer,
                  label: 'Patient Appointments',
                  style: {
                    background: true,
                    spacing: { py: 3, px: 4, pb: 0 }
                  },
                  title: '',
                  onTabChange: onTabChange,
                },
                {
                  component: AppointmentsTableContainer,
                  label: 'My Appointments',
                  style: {
                    background: true,
                    spacing: { pb: 0, pt: 3, px: 4 }
                  },
                  title: '',
                  onTabChange: onTab1Change,
                }
              ]} /> :
              <CardHeader
                tabComponents={[
                  {
                    component: AppointmentsTableContainer,
                    label: 'Upcoming Appointments',
                    primaryButton: {
                      text: 'Add',
                      handleClick: handleNewAppointmentButton
                    },
                    style: {
                      background: true,
                      spacing: { py: 3, px: 4 }
                    },
                    title: 'Upcoming Appointments',
                    handleSearch: handleSearch
                  }
                ]}
              />
          }
        </Box>
      </Paper>
    </>
  );
};
