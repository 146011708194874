import React, { useEffect, useState } from 'react';
import { Box, Paper } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { NewSharedUserDialog } from './NewSharedUser';
import { CardHeader } from '../../../../components/LegacyCardHeader';
import { UuidType } from '../../../../shared/domain';
import { ConfirmationDialog } from '../../../../components/ConfirmationDialog';
import { RootState } from '../../../../redux/redux-store';
import { SharedUserTable } from './SharedUserTable';
import {
    deleteOrganizationThunk, getSharedUsersThunk, getPatientInfoThunk
} from '../../../../redux/thunk/thunks';
import { setSuccessAction } from '../../../../redux/display/display-actions';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { MyProfile } from './Profile'
import { getSharedUsersAction } from '../../../../redux/data/data-actions';

export const SharedUsers = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const organization_id: any = sessionStorage.getItem('pat_org_id');
    const [searchPattern, setSearchPattern] = useState('');
    const [selectedUsers, setSelectedUsers] = useState<UuidType[]>([]);
    const [selectedLibraryForms, setSelectedLibraryForms] = useState<UuidType[]>([]);
    const [deleteUserDialogOpen, setDeleteUserDialogOpen] = useState<{
        open: boolean;
        user: any | null;
    }>({ open: false, user: null });
    const [createSharedUserOpen, setCreateSharedUserOpen] = useState(false);
    const [viewOrganization, setViewOrganization] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [editInfo, setEditInfo] = useState<any>();
    const [addToCollectionDialogOpen, setAddToCollectionDialogOpen] = useState(false);
    const [deleteFormsConfirmationDialogOpen, setDeleteFormsConfirmationDialogOpen] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const { path } = useRouteMatch();
    const split = path.split('/');
    const patient_role_id: any = split && split.length > 1 && split[2];
    const edit: any = split && split.length > 3 && split[4];

    const user: any = useSelector((state: RootState) => state.DATA_REDUCER.patientInfo);

    const sharedUsers: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.sharedUsers
    );
    const success: any = useSelector((state: RootState) => state.DISPLAY_STATE_REDUCER.displaySuccess.deleteUserSuccess);
    useEffect(() => {
        if (success) {
            setEditInfo(null);
            setEdit(false);
            setCreateSharedUserOpen(false);
            dispatch(setSuccessAction('deleteUserSuccess', false));
            dispatch(getSharedUsersThunk({ user_role_id: patient_role_id, organization_id: organization_id, search: searchPattern, pageSize: sharedUsers?.pageSize, page: sharedUsers?.page, sort: sharedUsers?.sort, order: sharedUsers?.order, }));
        }
    })

    const viewSharedUserOpen = (info: any) => {
        setViewOrganization(true);
        setEditInfo(info);
    };
    const handleCreateSharedUserClose = () => {
        setCreateSharedUserOpen(false);
    };
    const handleCreateSharedUserOpen = () => {
        setCreateSharedUserOpen(true);
        setEdit(false);
    };
    const handleAddToCollectionDialogClose = () => {
        setAddToCollectionDialogOpen(false);
    };
    const handleDeleteUserConfirmationDialogOpen = () => {
        setDeleteFormsConfirmationDialogOpen(true);
    };
    const handleDeleteFormsConfirmationDialogClose = () => {
        setDeleteFormsConfirmationDialogOpen(false);
    };

    const handleCustomSearch = (value: string) => {
        setSearchPattern(value);

        if (sharedUsers) {
            dispatch(
                getSharedUsersThunk({
                    page: 0,
                    pageSize: sharedUsers?.pageSize,
                    search: value,
                    user_role_id: patient_role_id,
                    organization_id: organization_id,
                    sort: sharedUsers?.sort,
                    order: sharedUsers?.order
                })
            );
        }
    };

    const handleDeleteForms = async (library?: boolean) => {
        setDeleteLoading(true);

        const selectedForms = library ? selectedLibraryForms : selectedUsers;

        await Promise.all(
            selectedForms.map((form: UuidType) => {
                dispatch(deleteOrganizationThunk(form, null));
            })
        );

        setSelectedUsers([]);
        setSelectedLibraryForms([]);
        setDeleteLoading(false);
        setDeleteFormsConfirmationDialogOpen(false);
    };

    const loadSharedUsers = async (getProps: any) => {
        getProps.user_role_id = patient_role_id;
        getProps.organization_id = organization_id;

        await dispatch(getSharedUsersThunk(getProps));
    };

    useEffect(() => {
        !user && dispatch(getPatientInfoThunk(patient_role_id));
        dispatch(getSharedUsersThunk({ user_role_id: patient_role_id, organization_id: organization_id }));

        return function CleanUp() {
            dispatch(getSharedUsersAction(null));
        };
    }, []);

    const CustomForms = (
        <>
            {addToCollectionDialogOpen && sharedUsers && (
                <NewSharedUserDialog
                    isOpen={addToCollectionDialogOpen}
                    // selectedForms={selectedUsers}
                    // handleAssignCollections={handleAssignCollections}
                    handleClose={handleAddToCollectionDialogClose}
                />
            )}

            {deleteFormsConfirmationDialogOpen && selectedUsers.length > 0 && (
                <ConfirmationDialog
                    loading={deleteLoading}
                    message='type "DELETE" to confirm.'
                    open={deleteFormsConfirmationDialogOpen}
                    title='Are you sure you want to delete? Please'
                    handleClose={handleDeleteFormsConfirmationDialogClose}
                    handleConfirmClick={() => handleDeleteForms(false)}
                />
            )}

            <Box pb={4} pt={1} px={4}>
                <SharedUserTable
                    dataList={sharedUsers}
                    selectedUsers={selectedUsers}
                    handleDeleteUserButton={(user: any) => setDeleteUserDialogOpen({ open: true, user })}
                    loadSharedUsers={loadSharedUsers}
                    setSelectedUsers={(selectedForms: UuidType[]) => setSelectedUsers(selectedForms)}
                    viewSharedUserOpen={viewSharedUserOpen}
                />
            </Box>
        </>
    );

    return (
        <>
            {createSharedUserOpen &&
                <NewSharedUserDialog isOpen={createSharedUserOpen}
                    handleClose={handleCreateSharedUserClose}
                />}
            {edit ? <MyProfile /> :
                <Paper>
                    <Box display="flex" flexDirection="column">
                        <CardHeader
                            tabComponents={[
                                {
                                    component: CustomForms,
                                    label: '',
                                    primaryButton: {
                                        text: 'Add',
                                        handleClick: handleCreateSharedUserOpen
                                    },
                                    selected: {
                                        selectedContents: selectedUsers,
                                        handleSelectedDelete: handleDeleteUserConfirmationDialogOpen
                                    },
                                    style: {
                                        background: true,
                                        spacing: { py: 3, px: 4 }
                                    },
                                    handleSearch: handleCustomSearch
                                }
                            ]}
                        />
                    </Box>
                </Paper>
            }
        </>
    );
};
