import React, { ChangeEvent, useEffect, useState } from 'react';
import pluralize from 'pluralize';
import { debounce } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { FormSearchResults, UuidType } from '../../../../shared/domain';
import { Dialog } from '../../../../components/LegacyDialog';
import { HeadCell, Pagination, RowCell, Table } from '../../../../components/Table';
import { RootState } from '../../../../redux/redux-store';
import { getFormsThunk } from '../../../../redux/thunk/thunks';
import { getFormsAction } from '../../../../redux/data/data-actions';

interface AddFormsToCollectionDialogProps {
  open: boolean;
  library?: boolean;
  handleAssignForms: (selectedForms: UuidType[]) => void;
  handleClose: () => void;
}

export const AddFormsToCollectionDialog = ({
  open,
  library,
  handleAssignForms,
  handleClose
}: AddFormsToCollectionDialogProps) => {
  const dispatch = useDispatch();

  const formsData = useSelector(
    (state: RootState) => state.DATA_REDUCER.formSearchResults.collectionDetailAdd
  );
  const assignCollectionToFormsLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.assignCollectionToFormsLoading
  );

  const [selectedForms, setSelectedForms] = useState<UuidType[]>([]);

  const headCells: HeadCell[] = [
    {
      id: 'name',
      label: 'name'
    }
  ];
  const rowCells = formsData?.forms.map((form) => {
    const rowCell: RowCell = {
      name: { value: form.title }
    };

    return Object.assign(rowCell, { content: form });
  });

  const handleNextButton = async () => {
    if (
      formsData &&
      formsData.page >= 0 &&
      formsData.page < formsData.total / formsData.pageSize - 1
    ) {
      await dispatch(
        getFormsThunk({
          formsType: 'collectionDetailAdd',
          allForms: true,
          page: formsData.page + 1,
          pageSize: formsData.pageSize,
          search: formsData.search
        })
      );
    }
  };
  const handlePrevButton = async () => {
    if (formsData && formsData.page > 0) {
      await dispatch(
        getFormsThunk({
          formsType: 'collectionDetailAdd',
          allForms: true,
          page: formsData.page - 1,
          pageSize: formsData.pageSize,
          search: formsData.search
        })
      );
    }
  };

  const pagination: Pagination | undefined = formsData
    ? {
        contentName: 'Form',
        page: formsData.page,
        pageSize: formsData.pageSize,
        total: formsData.total,
        handleNextButton,
        handlePrevButton
      }
    : undefined;

  const searchForms = (formsData: FormSearchResults, value: string) =>
    debounce(
      () =>
        dispatch(
          getFormsThunk({
            formsType: 'collectionDetailAdd',
            allForms: true,
            page: 0,
            pageSize: formsData.pageSize,
            search: value
          })
        ),
      500
    );
  const handleSearch = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (formsData && e.target) {
      searchForms(formsData, e.target.value)();
    }
  };

  useEffect(() => {
    if (formsData === null) {
      dispatch(getFormsThunk({ formsType: 'collectionDetailAdd', allForms: true, pageSize: 8 }));
    }
  }, [formsData]);

  useEffect(() => {
    return () => {
      dispatch(getFormsAction('collectionDetailAdd', null));
    };
  }, []);

  return (
    <Dialog
      open={open}
      primaryButton={{
        disabled: selectedForms.length === 0,
        loading: assignCollectionToFormsLoading,
        text:
          selectedForms.length === 0
            ? 'Add form(s)'
            : `Add ${selectedForms.length} ${pluralize('form', selectedForms.length)} to ${
                library ? 'library' : 'collection'
              }`,

        handleClick: () => handleAssignForms(selectedForms)
      }}
      secondaryButton={{ text: 'Cancel', handleClick: handleClose }}
      title={`Add form(s) to ${library ? 'library' : 'collection'}`}
      handleClose={handleClose}
      handleSearch={handleSearch}
    >
      <Table
        data={formsData?.forms}
        headCells={headCells}
        hideHead
        rowCells={rowCells}
        pagination={pagination}
        selection={{
          selectedContents: selectedForms,
          setSelectedContents: (selected: UuidType[]) => setSelectedForms(selected)
        }}
      />
    </Dialog>
  );
};
