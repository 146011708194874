import { Box, makeStyles, Theme, Typography, Chip, Grid, Divider, FormControlLabel, AccordionSummary, AccordionDetails, Accordion, Checkbox, DialogActions } from '@material-ui/core';
import { Clear, ExpandMore } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import { RootState } from '../../../redux/redux-store';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from '../../../components/Dialog';
import { LoadingButton } from '../../../components/LoadingButton';
import { GetReferralsProps } from '../../../redux/thunk/thunks';

interface ReferralTypesDialogProps {
    open: boolean;
    handleClose: () => void;
    loadReferrals: (getBundlesProps: GetReferralsProps) => void;
}

export const ReferralFiltersDialog = ({ open, handleClose, loadReferrals }: ReferralTypesDialogProps) => {
    const classes = useStyles();
    const [filters, setFilters] = useState<any>({
        insurances: [],
        issues: [],
        sexuality: [],
        gender: [],
        specialities: [],
        cost_per_session: [],
        telehealth: [],
        therapy_types: [],
        ages_served: [],
        languages: [],
    });
    const [selectedFilter, setSelectedFilter] = useState<any>([]);
    const [isUpdate, setUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const referrals: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.referralsSearchResults.referrals
    );
    const referralFilterList: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.referralFilterListResults
    );

    useEffect(() => {
        if (referrals.filters && referrals.filters?.gender) {
            setFilters(referrals.filters);
            const array = [];
            for (let i = 0; i < referrals.filters?.insurances.length; i++) {
                array.push(referrals.filters?.insurances[i]);
            }
            for (let i = 0; i < referrals.filters?.therapy_types.length; i++) {
                array.push(referrals.filters?.therapy_types[i]);
            }
            for (let i = 0; i < referrals.filters?.issues.length; i++) {
                array.push(referrals.filters?.issues[i]);
            }
            for (let i = 0; i < referrals.filters?.ages_served.length; i++) {
                array.push(referrals.filters?.ages_served[i]);
            }
            for (let i = 0; i < referrals.filters?.languages.length; i++) {
                array.push(referrals.filters?.languages[i]);
            }
            for (let i = 0; i < referrals.filters?.sexuality.length; i++) {
                array.push(referrals.filters?.sexuality[i]);
            }
            for (let i = 0; i < referrals.filters?.gender.length; i++) {
                array.push(referrals.filters?.gender[i]);
            }
            for (let i = 0; i < referrals.filters?.specialities.length; i++) {
                array.push(referrals.filters?.specialities[i]);
            }
            for (let i = 0; i < referrals.filters?.cost_per_session.length; i++) {
                array.push(referrals.filters?.cost_per_session[i]);
            }
            for (let i = 0; i < referrals.filters?.telehealth.length; i++) {
                array.push(referrals.filters?.telehealth[i]);
            }
            setSelectedFilter(array);
            sessionStorage.setItem('key', array.length.toString());
        } else {
            sessionStorage.removeItem('key');
        }
    }, [])

    const handelCheckFilters = async (checked: boolean, id: any, type: string) => {
        if (checked) {
            selectedFilter.push(id);
            setSelectedFilter(selectedFilter);
            sessionStorage.setItem('key', selectedFilter.length.toString());
            if (type === 'INSURANCE') {
                filters.insurances.push(id);
            } else if (type === 'THERAPY_TYPE') {
                filters.therapy_types.push(id);
            } else if (type === 'ISSUE') {
                filters.issues.push(id);
            } else if (type === 'AGE') {
                filters.ages_served.push(id);
            } else if (type === 'LANGUAGE') {
                filters.languages.push(id);
            } else if (type === 'SEXUALITY') {
                filters.sexuality.push(id);
            } else if (type === 'GENDER') {
                filters.gender.push(id);
            } else if (type === 'SPECIALITY') {
                filters.specialities.push(id);
            } else if (type === 'SESSION_COST') {
                filters.cost_per_session.push(id);
            } else if (type === 'TELEHEALTH') {
                filters.telehealth.push(id);
            }
        } else {
            const filterArray = selectedFilter.filter((item: any) => item !== id);
            setSelectedFilter(filterArray);
            sessionStorage.setItem('key', filterArray.length.toString());
            if (type === 'INSURANCE') {
                const filter = filters.insurances.filter((info: any) => info !== id);
                setFilters({ ...filters, insurances: filter })
            } else if (type === 'THERAPY_TYPE') {
                const filter = filters.therapy_types.filter((info: any) => info !== id);
                setFilters({ ...filters, therapy_types: filter })
            } else if (type === 'ISSUE') {
                const filter = filters.issues.filter((info: any) => info !== id);
                setFilters({ ...filters, issues: filter })
            } else if (type === 'AGE') {
                const filter = filters.ages_served.filter((info: any) => info !== id);
                setFilters({ ...filters, ages_served: filter })
            } else if (type === 'LANGUAGE') {
                const filter = filters.languages.filter((info: any) => info !== id);
                setFilters({ ...filters, languages: filter })
            } else if (type === 'SEXUALITY') {
                const filter = filters.sexuality.filter((info: any) => info !== id);
                setFilters({ ...filters, sexuality: filter })
            } else if (type === 'GENDER') {
                const filter = filters.gender.filter((info: any) => info !== id);
                setFilters({ ...filters, gender: filter })
            } else if (type === 'SPECIALITY') {
                const filter = filters.specialities.filter((info: any) => info !== id);
                setFilters({ ...filters, specialities: filter })
            } else if (type === 'SESSION_COST') {
                const filter = filters.cost_per_session.filter((info: any) => info !== id);
                setFilters({ ...filters, cost_per_session: filter })
            } else if (type === 'TELEHEALTH') {
                const filter = filters.telehealth.filter((info: any) => info !== id);
                setFilters({ ...filters, telehealth: filter })
            }
        }
        setUpdate(!isUpdate);
    }

    const removeSelectedFilter = (value: any) => {
        const filter = selectedFilter.filter((id: any) => id !== value);
        setSelectedFilter(filter);
        sessionStorage.setItem('key', filter.length.toString());
        if (value && filters.insurances.length > 0) {
            const filter = filters.insurances.filter((info: any) => info !== value);
            setFilters({ ...filters, insurances: filter });
        } if (value && filters.therapy_types.length > 0) {
            const filter = filters.therapy_types.filter((info: any) => info !== value);
            setFilters({ ...filters, therapy_types: filter });
        } if (value && filters.issues.length > 0) {
            const filter = filters.issues.filter((info: any) => info !== value);
            setFilters({ ...filters, issues: filter });
        } if (value && filters.ages_served.length > 0) {
            const filter = filters.ages_served.filter((info: any) => info !== value);
            setFilters({ ...filters, ages_served: filter });
        } if (value && filters.languages.length > 0) {
            const filter = filters.languages.filter((info: any) => info !== value);
            setFilters({ ...filters, languages: filter });
        } if (value && filters.sexuality.length > 0) {
            const filter = filters.sexuality.filter((info: any) => info !== value);
            setFilters({ ...filters, sexuality: filter });
        } if (value && filters.gender.length > 0) {
            const filter = filters.gender.filter((info: any) => info !== value);
            setFilters({ ...filters, gender: filter });
        } if (value && filters.specialities.length > 0) {
            const filter = filters.specialities.filter((info: any) => info !== value);
            setFilters({ ...filters, specialities: filter });
        } if (value && filters.cost_per_session.length > 0) {
            const filter = filters.cost_per_session.filter((info: any) => info !== value);
            setFilters({ ...filters, cost_per_session: filter });
        } if (value && filters.telehealth.length > 0) {
            const filter = filters.telehealth.filter((info: any) => info !== value);
            setFilters({ ...filters, telehealth: filter });
        }
        setUpdate(!isUpdate);
    }

    const clearAll = async () => {
        setSelectedFilter([]);
        setFilters({
            insurances: [], issues: [], sexuality: [], gender: [], specialities: [], cost_per_session: [],
            telehealth: [], therapy_types: [], ages_served: [], languages: [],
        });
        sessionStorage.removeItem('key');
    }

    const submitReferralType = async () => {
        setLoading(true)
        await loadReferrals({ favourites: referrals.favourites, filters: filters, search: referrals.search, referral_type: referrals?.referral_type });
        handleClose();
        setLoading(false);
    }

    const handelCloseFilter = async () => {
        handleClose();
        if (referrals.filters) {
            setFilters(referrals.filters);
            const array = [];
            for (let i = 0; i < referrals.filters?.insurances?.length; i++) {
                array.push(referrals.filters?.insurances[i]);
            }
            for (let i = 0; i < referrals.filters?.therapy_types?.length; i++) {
                array.push(referrals.filters?.therapy_types[i]);
            }
            for (let i = 0; i < referrals.filters?.issues?.length; i++) {
                array.push(referrals.filters?.issues[i]);
            }
            for (let i = 0; i < referrals.filters?.ages_served?.length; i++) {
                array.push(referrals.filters?.ages_served[i]);
            }
            for (let i = 0; i < referrals.filters?.languages?.length; i++) {
                array.push(referrals.filters?.languages[i]);
            }
            for (let i = 0; i < referrals.filters?.sexuality?.length; i++) {
                array.push(referrals.filters?.sexuality[i]);
            }
            for (let i = 0; i < referrals.filters?.gender?.length; i++) {
                array.push(referrals.filters?.gender[i]);
            }
            for (let i = 0; i < referrals.filters?.specialities?.length; i++) {
                array.push(referrals.filters?.specialities[i]);
            }
            for (let i = 0; i < referrals.filters?.cost_per_session?.length; i++) {
                array.push(referrals.filters?.cost_per_session[i]);
            }
            for (let i = 0; i < referrals.filters?.telehealth?.length; i++) {
                array.push(referrals.filters?.telehealth[i]);
            }
            await setSelectedFilter(array);
            await sessionStorage.setItem('key', array?.length.toString());
        }
    }

    return (
        <Dialog open={open}>
            <Box display="flex" flexDirection="column" className={classes.box}>
                <Box pb={4} className={classes.topBox}>
                    <Box pb={0.5} p={3}>
                        <Typography color="textSecondary" variant="h2">
                            Filter
                        </Typography>
                    </Box>
                </Box>
                <Box p={3}>
                    <div className={classes.otherActions}>
                        {selectedFilter.map((id: any) => {
                            const checked = referralFilterList.filter((items: any) => items.id === id)
                            return (
                                <Chip classes={{ root: classes.collectionChip }} size="small"
                                    label={
                                        <div className={classes.chipAlign}>
                                            {checked && checked?.length > 0 && checked[0].title}
                                            <Clear className={classes.clearIcon} onClick={(e) => removeSelectedFilter(id)} />
                                        </div>
                                    }
                                />)
                        })}
                    </div>
                    <LoadingButton className={classes.secondaryButton}
                        variant="contained" onClick={clearAll}
                        style={{ marginBottom: '0px' }}>
                        Clear All
                    </LoadingButton>
                    <Divider style={{ height: '2px', margin: '20px 0 0' }} />
                </Box>
                <Box p={3} pt={0} style={{ overflow: 'auto' }}>
                    <Box>
                        <Accordion style={{ boxShadow: 'unset', border: 'unset', margin: 0 }}>
                            <AccordionSummary style={{ height: '30px' }} expandIcon={<ExpandMore style={{ padding: 0 }} />} id="INSURANCE">
                                <Typography style={{ fontWeight: 600 }}>Insurance</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    {referralFilterList && referralFilterList.map((item: any) => {
                                        const checked = selectedFilter.filter((id: any) => id === item.id);
                                        return (item.type === 'INSURANCE' &&
                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel control={<Checkbox style={{ color: checked && checked?.length === 0 ? '#a4a4a4' : '#0060B2' }} size='medium' checked={checked && checked?.length === 0 ? false : true} onChange={(e) => handelCheckFilters(checked && checked?.length === 0 ? true : false, item.id, 'INSURANCE')} name={item.id} color="primary" />} label={item.title} />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </AccordionDetails>
                            <Divider />
                        </Accordion>
                        <Accordion style={{ boxShadow: 'unset', border: 'unset', margin: 0 }}>
                            <AccordionSummary style={{ height: '30px' }} expandIcon={<ExpandMore style={{ padding: 0 }} />} id="THERAPY_TYPE">
                                <Typography style={{ fontWeight: 600 }}>Types of Therapy</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    {referralFilterList && referralFilterList.map((item: any) => {
                                        const checked = selectedFilter.filter((id: any) => id === item.id);
                                        return (item.type === 'THERAPY_TYPE' &&
                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel control={<Checkbox style={{ color: checked && checked?.length === 0 ? '#a4a4a4' : '#0060B2' }} size='medium' checked={checked && checked?.length === 0 ? false : true} onChange={(e) => handelCheckFilters(checked && checked?.length === 0 ? true : false, item.id, 'THERAPY_TYPE')} name={item.id} color="primary" />} label={item.title} />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion style={{ boxShadow: 'unset', border: 'unset', margin: 0 }}>
                            <AccordionSummary style={{ height: '30px' }} expandIcon={<ExpandMore style={{ padding: 0 }} />} id="ISSUE">
                                <Typography style={{ fontWeight: 600 }}>Issue</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    {referralFilterList && referralFilterList.map((item: any) => {
                                        const checked = selectedFilter.filter((id: any) => id === item.id);
                                        return (item.type === 'ISSUE' &&
                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel control={<Checkbox style={{ color: checked && checked?.length === 0 ? '#a4a4a4' : '#0060B2' }} size='medium' checked={checked && checked?.length === 0 ? false : true} onChange={(e) => handelCheckFilters(checked && checked?.length === 0 ? true : false, item.id, 'ISSUE')} name={item.id} color="primary" />} label={item.title} />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion style={{ boxShadow: 'unset', border: 'unset', margin: 0 }}>
                            <AccordionSummary style={{ height: '30px' }} expandIcon={<ExpandMore style={{ padding: 0 }} />} id="AGE">
                                <Typography style={{ fontWeight: 600 }}>Ages Served</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    {referralFilterList && referralFilterList.map((item: any) => {
                                        const checked = selectedFilter.filter((id: any) => id === item.id);
                                        return (item.type === 'AGE' &&
                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel control={<Checkbox style={{ color: checked && checked?.length === 0 ? '#a4a4a4' : '#0060B2' }} size='medium' checked={checked && checked?.length === 0 ? false : true} onChange={(e) => handelCheckFilters(checked && checked?.length === 0 ? true : false, item.id, 'AGE')} name={item.id} color="primary" />} label={item.title} />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion style={{ boxShadow: 'unset', border: 'unset', margin: 0 }}>
                            <AccordionSummary style={{ height: '30px' }} expandIcon={<ExpandMore style={{ padding: 0 }} />} id="LANGUAGE">
                                <Typography style={{ fontWeight: 600 }}>Language</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    {referralFilterList && referralFilterList.map((item: any) => {
                                        const checked = selectedFilter.filter((id: any) => id === item.id);
                                        return (item.type === 'LANGUAGE' &&
                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel control={<Checkbox style={{ color: checked && checked?.length === 0 ? '#a4a4a4' : '#0060B2' }} size='medium' checked={checked && checked?.length === 0 ? false : true} onChange={(e) => handelCheckFilters(checked && checked?.length === 0 ? true : false, item.id, 'LANGUAGE')} name={item.id} color="primary" />} label={item.title} />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion style={{ boxShadow: 'unset', border: 'unset', margin: 0 }}>
                            <AccordionSummary style={{ height: '30px' }} expandIcon={<ExpandMore style={{ padding: 0 }} />} id="SEXUALITY">
                                <Typography style={{ fontWeight: 600 }}>Sexuality</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    {referralFilterList && referralFilterList.map((item: any) => {
                                        const checked = selectedFilter.filter((id: any) => id === item.id);
                                        return (item.type === 'SEXUALITY' &&
                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel control={<Checkbox style={{ color: checked && checked?.length === 0 ? '#a4a4a4' : '#0060B2' }} size='medium' checked={checked && checked?.length === 0 ? false : true} onChange={(e) => handelCheckFilters(checked && checked?.length === 0 ? true : false, item.id, 'SEXUALITY')} name={item.id} color="primary" />} label={item.title} />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion style={{ boxShadow: 'unset', border: 'unset', margin: 0 }}>
                            <AccordionSummary style={{ height: '30px' }} expandIcon={<ExpandMore style={{ padding: 0 }} />} id="GENDER">
                                <Typography style={{ fontWeight: 600 }}>Gender</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    {referralFilterList && referralFilterList.map((item: any) => {
                                        const checked = selectedFilter.filter((id: any) => id === item.id);
                                        return (item.type === 'GENDER' &&
                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel control={<Checkbox style={{ color: checked && checked?.length === 0 ? '#a4a4a4' : '#0060B2' }} size='medium' checked={checked && checked?.length === 0 ? false : true} onChange={(e) => handelCheckFilters(checked && checked?.length === 0 ? true : false, item.id, 'GENDER')} name={item.id} color="primary" />} label={item.title} />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion style={{ boxShadow: 'unset', border: 'unset', margin: 0 }}>
                            <AccordionSummary style={{ height: '30px' }} expandIcon={<ExpandMore style={{ padding: 0 }} />} id="SPECIALITY">
                                <Typography style={{ fontWeight: 600 }}>Specialities</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    {referralFilterList && referralFilterList.map((item: any) => {
                                        const checked = selectedFilter.filter((id: any) => id === item.id);
                                        return (item.type === 'SPECIALITY' &&
                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel control={<Checkbox style={{ color: checked && checked?.length === 0 ? '#a4a4a4' : '#0060B2' }} size='medium' checked={checked && checked.length === 0 ? false : true} onChange={(e) => handelCheckFilters(checked && checked.length === 0 ? true : false, item.id, 'SPECIALITY')} name={item.id} color="primary" />} label={item.title} />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion style={{ boxShadow: 'unset', border: 'unset', margin: 0 }}>
                            <AccordionSummary style={{ height: '30px' }} expandIcon={<ExpandMore style={{ padding: 0 }} />} id="SESSION_COST">
                                <Typography style={{ fontWeight: 600 }}>Cost Per Session</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    {referralFilterList && referralFilterList.map((item: any) => {
                                        const checked = selectedFilter.filter((id: any) => id === item.id);
                                        return (item.type === 'SESSION_COST' &&
                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel control={<Checkbox style={{ color: checked && checked.length === 0 ? '#a4a4a4' : '#0060B2' }} size='medium' checked={checked && checked.length === 0 ? false : true} onChange={(e) => handelCheckFilters(checked && checked.length === 0 ? true : false, item.id, 'SESSION_COST')} name={item.id} color="primary" />} label={item.title} />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion style={{ boxShadow: 'unset', border: 'unset', margin: 0 }}>
                            <AccordionSummary style={{ height: '30px' }} expandIcon={<ExpandMore style={{ padding: 0 }} />} id="TELEHEALTH">
                                <Typography style={{ fontWeight: 600 }}>Telehealth</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    {referralFilterList && referralFilterList.map((item: any) => {
                                        const checked = selectedFilter.filter((id: any) => id === item.id);
                                        return (item.type === 'TELEHEALTH' &&
                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel control={<Checkbox style={{ color: checked && checked.length === 0 ? '#a4a4a4' : '#0060B2' }} size='medium' checked={checked && checked.length === 0 ? false : true} onChange={(e) => handelCheckFilters(checked && checked.length === 0 ? true : false, item.id, 'TELEHEALTH')} name={item.id} color="primary" />} label={item.title} />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Box>
            </Box>
            <DialogActions style={{ justifyContent: 'space-between', borderRadius: '0 0 10px 10px', padding: '20px', background: '#f9fafb' }}>
                <LoadingButton
                    className={classes.cancelButton}
                    variant="contained"
                    style={{ marginBottom: '0px' }}
                    onClick={handelCloseFilter}
                >
                    Cancel
                </LoadingButton>
                <Box display='flex' alignItems='center'>
                    {selectedFilter.length > 0 && <><span>{selectedFilter.length} selected</span><Divider orientation="vertical" flexItem style={{ margin: '0 20px' }} /></>}
                    <LoadingButton color="primary" loading={loading} disabled={loading}
                        className={classes.primaryButton}
                        variant="contained"
                        style={{ marginBottom: '0px' }}
                        onClick={(e) => submitReferralType()}
                    >
                        Save
                    </LoadingButton>
                </Box>
            </DialogActions>
        </Dialog >
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    topBox: {
        borderRadius: '0.625rem 0.625rem 0 0',
        background: '#f9fafb'
    },
    box: {
        width: '700px',
        overflow: 'auto',
        height: '600px'
    },
    clearIcon: {
        fontSize: 14,
        marginLeft: '0.25rem',
        cursor: 'pointer'
    },
    chips: {
        textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden',

    },
    chip: {
        margin: 2, height: '25px', backgroundColor: theme.background.main
    },
    chipAlign: {
        display: 'flex',
        alignItems: 'center'
    },
    otherActions: {
        marginLeft: '.6rem',
        alignItems: 'center'
    },
    collectionChip: {
        marginRight: theme.spacing(1),
        marginBottom: '4px',
        backgroundColor: theme.background.main
    },
    primaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#fff',
        minWidth: '120px'
    },
    secondaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: 'black',
        width: '100%',
        background: '#fff',
        margin: '20px 0'
    },
    cancelButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        minWidth: '120px',
        background: '#fff',
    },
}));
