import {
    Box, makeStyles, Paper, Theme, Typography, Dialog, Grid, Toolbar, AppBar, Button, Divider,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/redux-store';
import { colors } from '../../../../../styling/colors';
import { ArrowBack } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';
import { getFormInstanceMetricDetailsThunk } from '../../../../../redux/thunk/thunks';
import LineChart from '../graph';
import { FormResultTable } from '../CompletResult/CompleteFormResults';

export const ViewFormPage = ({ handleClose, formInfo }: any) => {
    const classes = useStyle();
    const dispatch = useDispatch();
    const location: any = useLocation();
    const role: any = sessionStorage.getItem('role');
    const infoItem: any = sessionStorage.getItem('res_item');
    const resultInfo = JSON.parse(infoItem);
    const split = location?.pathname.split('/');
    const patient_role_id: any = split && split.length > 1 && split[2];
    const shared_role_id: any = split && split.length > 3 && split[4];

    const user: any = useSelector((state: RootState) => state.DATA_REDUCER.patientInfo);
    const sharedUser: any = useSelector((state: RootState) => state.DATA_REDUCER.sharedUser);
    const formMetricGraphs: any = useSelector((state: RootState) => state.DATA_REDUCER.formMetricGraphs);
    const formInstanceDetails: any = useSelector((state: RootState) => state.DATA_REDUCER.formInstanceDetails);

    useEffect(() => {
        dispatch(getFormInstanceMetricDetailsThunk(formInfo?.form_instance_id, role === 'SHARED_USER' ? shared_role_id : patient_role_id));
    }, [])

    return (
        <>
            <Dialog fullScreen open={true}>
                <Box className={classes.main} display="flex" flexDirection="column" p={3}>
                    <Box pb={4}>
                        <AppBar square elevation={0} classes={{ root: classes.headerRoot }}>
                            <Toolbar classes={{ root: classes.headerToolbar }}>
                                <Button
                                    classes={{ text: classes.closeBtnText }}
                                    startIcon={<ArrowBack style={{ color: 'gray', fontSize: '25px' }} />}
                                    onClick={() => handleClose()}
                                >
                                    Return
                                </Button>
                                <Box alignSelf="stretch" display="flex" pr={3} py={2.75}>
                                    <Divider flexItem orientation="vertical" />
                                </Box>

                                <Box
                                    display="flex"
                                    alignItems='center'
                                    justifyContent="flex-start"
                                    minHeight="5rem"
                                    width="100%"
                                >
                                    <Box>
                                        <Typography className={classes.subtitle}>
                                            {`${role} > ${role !== 'PATIENT' ? (sharedUser?.first_name + ' ' + sharedUser?.last_name) : user?.first_name + ' ' + user?.last_name} > RECURRING COLLECTION >`}
                                        </Typography>
                                        <Typography color="textSecondary" variant="h2">
                                            <span style={{ color: 'gray' }}>{'Recurring Form:'}</span> {formInstanceDetails?.title}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Toolbar>
                        </AppBar>
                    </Box>
                    <Grid container className={classes.body}>
                        <Grid item xs={12}>
                            <Paper style={{ background: 'transparent', boxShadow: 'unset', border: 'unset', margin: '10px' }}>
                                <Box mb={4} style={{ borderRadius: '10px', padding: '20px', background: '#fff', boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)' }}>
                                    <Box>
                                        <Typography className={classes.subtitle}>
                                            {'RESULTS'}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography color="textSecondary" style={{ fontSize: '20px' }}>
                                            <b>Results for {formInstanceDetails?.title}</b>
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <FormResultTable id={formInfo?.form_instance_id} />
                                    </Box>
                                </Box>
                                <Box style={{ borderRadius: '10px', padding: '20px', background: '#fff', boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)' }}>
                                    <Box>
                                        <Typography className={classes.subtitle}>
                                            {'RESULTS GRAPH'}
                                        </Typography>
                                    </Box>
                                    {formMetricGraphs && formMetricGraphs?.map((item: any) => {
                                        return <Box>
                                            <Typography color="textSecondary" style={{ fontSize: '20px' }}>
                                                <b>{item.metric_name}</b>
                                            </Typography>
                                            <LineChart info={item} />
                                        </Box>
                                    })}
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
        </>
    );
};

const useStyle = makeStyles((theme: Theme) => ({
    main: {
        backgroundColor: colors.background1,
        minHeight: 'auto',
        boxSizing: 'border-box',
        overflowX: 'hidden'
    },
    body: {
        margin: '60px 0',
    },
    referralInfo: {
        [theme.breakpoints.down('xs')]: {
            paddingRight: '0',
            width: '100%'
        }
    },
    contentContainer: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    contentContainer1: {
        [theme.breakpoints.down('xs')]: {
            flexGrow: 0,
            flexBasis: '100%',
            maxWidth: '100%',
            display: 'flex',
            flexWrap: 'wrap'
        },
        [theme.breakpoints.down('sm')]: {
            flexGrow: 0,
            flexBasis: '50%',
            maxWidth: '50%',
            display: 'flex',
            flexWrap: 'wrap'
        },
        [theme.breakpoints.down('md')]: {
            flexGrow: 0,
            flexBasis: '60%',
            maxWidth: '60%',
            display: 'flex',
            flexWrap: 'wrap'
        },
        [theme.breakpoints.down('lg')]: {
            flexGrow: 0,
            flexBasis: '70%',
            maxWidth: '70%',
            display: 'flex',
            flexWrap: 'wrap'
        }
    },
    heading: {
        marginBottom: '1rem'
    },
    editIcon: {
        marginLeft: '10px',
        fontSize: '12px',
        border: '1px solid #c1c1c1',
        color: '#c1c1c1',
        borderRadius: '3px',
        cursor: 'pointer'
    },
    deleteButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: 'red',
        minWidth: '120px'
    },
    subtitle: {
        color: colors.text6,
        fontWeight: 600
    },
    primaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#fff',
        minWidth: '120px'
    },
    headerRoot: {
        backgroundColor: 'white',
        borderBottom: `2px solid ${theme.palette.divider}`,
        padding: 0
    },
    headerToolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: 0
    },
    closeBtnText: {
        color: 'gray',
        marginRight: 18,
        fontSize: '16px',
        '&:hover': {
            backgroundColor: 'unset'
        }
    },
}));
