import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import { SessionPopup } from './SessionPopup';

export default function ReactTimer() {
    const dispatch = useDispatch();
    const timeout = 10800000;  //900000
    const [remaining, setRemaining] = useState(timeout);
    const [open, setOpen] = useState(false);
    const loggedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(loggedUser);
    const location = window.location.pathname;

    const handelClose = () => {
        setOpen(false);
    }

    const handleOnIdle = () => {
        if (loggedInUser && location !== '/login') {
            setOpen(true);
        }
    };

    const { getRemainingTime } = useIdleTimer({
        timeout,
        onIdle: handleOnIdle
    });

    useEffect(() => {
        setRemaining(getRemainingTime());

        setInterval(() => {
            setRemaining(getRemainingTime());
        }, 1000);
    }, []);

    return (
        <div>
            {open &&
                <SessionPopup open={open} handelClose={handelClose} />
            }
        </div>
    );
}
