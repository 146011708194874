import { Box, Divider, Checkbox, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '../../../../components/LoadingButton';
import { TitledTextInput } from '../../../../components/TitledTextInput';
import { setEditingExitPageAction } from '../../../../redux/data/data-actions';
import { RootState } from '../../../../redux/redux-store';
import { updateExitPageThunk } from '../../../../redux/thunk/thunks';

export const ExitPageSettings: React.FC<{}> = ({ }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const updateExitPageLoading = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.updateExitPageLoading
  );
  const editingForm = useSelector((state: RootState) => state.DATA_REDUCER.editingForm);
  const editingExitPage: any = useSelector((state: RootState) => state.DATA_REDUCER.editingExitPage);

  if (!editingExitPage || !editingForm) {
    return null;
  }

  const saveChanges = () => {
    dispatch(updateExitPageThunk(editingExitPage, editingForm.id));
  };

  //update redux editingExitPage when changes are made
  //textfield handlers
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setEditingExitPageAction({
        ...editingExitPage,
        name: event.target.value as string
      })
    );
    sessionStorage.setItem('formTab', 'exit');
    sessionStorage.setItem('onChangeExit', 'true');
  };
  const handleTitleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch(
      setEditingExitPageAction({
        ...editingExitPage,
        title: event.target.value as string
      })
    );
    sessionStorage.setItem('formTab', 'exit');
    sessionStorage.setItem('onChangeExit', 'true');
  };
  const handleDescriptionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch(
      setEditingExitPageAction({
        ...editingExitPage,
        description: event.target.value as string
      })
    );
    sessionStorage.setItem('formTab', 'exit');
    sessionStorage.setItem('onChangeExit', 'true');
  };
  const handleButtonTextChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch(
      setEditingExitPageAction({
        ...editingExitPage,
        button_text: event.target.value as string
      })
    );
    sessionStorage.setItem('formTab', 'exit');
    sessionStorage.setItem('onChangeExit', 'true');
  };
  const handleButtonURLChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch(
      setEditingExitPageAction({
        ...editingExitPage,
        button_url: event.target.value as string
      })
    );
    sessionStorage.setItem('formTab', 'exit');
    sessionStorage.setItem('onChangeExit', 'true');
  };
  const handleVideoUrlChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch(
      setEditingExitPageAction({
        ...editingExitPage,
        exit_page_video_url: event.target.value as string
      })
    );
    sessionStorage.setItem('formTab', 'exit');
    sessionStorage.setItem('onChangeExit', 'true');
  };
  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setEditingExitPageAction({
        ...editingExitPage,
        show_referral_btn: event.target.checked
      })
    );
    sessionStorage.setItem('formTab', 'exit');
    sessionStorage.setItem('onChangeExit', 'true');
  };
  //TODO: add validation to inputs
  return (
    <Box p={3} className={classes.mainContainer}>
      <TitledTextInput
        noValidation
        variant="outlined"
        size={'small'}
        title={'Exit page name'}
        value={editingExitPage.name || ''}
        fullWidth
        onChange={handleNameChange}
        hintText={'This is not visible to the patient'}
      />
      <Divider className={classes.divider} />
      <TitledTextInput
        noValidation
        variant="outlined"
        size={'small'}
        title={'Title'}
        fullWidth
        onChange={handleTitleChange}
        value={editingExitPage.title || ''}
      />
      <Divider className={classes.divider} />
      <TitledTextInput
        noValidation
        variant="outlined"
        size={'small'}
        multiline
        rows={6}
        fullWidth
        title={'Description'}
        onChange={handleDescriptionChange}
        value={editingExitPage.description || ''}
      />
      <Divider className={classes.divider} />
      <TitledTextInput
        noValidation
        variant="outlined"
        size={'small'}
        fullWidth
        title={'Video URL'}
        onChange={handleVideoUrlChange}
        value={editingExitPage?.exit_page_video_url || ''}
      />
      <Divider className={classes.divider} />
      <TitledTextInput
        noValidation
        variant="outlined"
        fullWidth
        size={'small'}
        title={'Button Text'}
        value={editingExitPage.button_text || ''}
        onChange={handleButtonTextChange}
        style={{ marginBottom: '20px' }}
      />
      <TitledTextInput
        noValidation
        disabled={(editingExitPage.button_text?.length > 0) ? false : true}
        fullWidth
        variant="outlined"
        size={'small'}
        onChange={handleButtonURLChange}
        value={editingExitPage.button_url || ''}
        title={'Button URL'}
      />
      <Divider className={classes.divider} />
      <Box display="flex" alignItems='center'>
        <Checkbox color="primary"
          checked={editingExitPage?.show_referral_btn}
          onChange={(e) => handleCheckChange(e)}
        />
        <Typography color={'textSecondary'} variant="subtitle2" style={{ marginLeft: '10px' }}>Add Search Referral</Typography>
      </Box>
      <Divider className={classes.divider} />
      <LoadingButton
        onClick={saveChanges}
        variant="contained"
        fullWidth
        disabled={updateExitPageLoading}
        color={'primary'}
        loading={updateExitPageLoading}
        aria-label={'save changes'}
      >
        Save Changes
      </LoadingButton>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  mainContainer: {},
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  errorContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '.5rem'
  },
  hintText: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.hint
  },
  fieldTitle: {
    marginBottom: theme.spacing(1)
  }
}));
