import React, { useEffect, useState } from 'react';
import { Box, Theme, Typography, makeStyles, TextField, Grid, FormControl, FormLabel, FormControlLabel, Radio, RadioGroup, Paper } from '@material-ui/core';
import { BorderColor } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormInstanceResultRow,
  FormInstanceFieldResponse,
  FormInstanceResponses,
  FormResponseChangeLogs
} from '../../../../shared/domain';
import { Dialog } from '../../../../components/Dialog';
import { LoadingButton } from '../../../../components/LoadingButton';
import { colors } from '../../../../styling/colors';
import {
  downloadFormResponsePDFThunk, putChoiceAnswerThunk,
  getFormResponsesThunk, putChatbotAnswerThunk, getChangeLogsThunk
} from '../../../../redux/thunk/thunks';
import { RootState } from '../../../../redux/redux-store';
import { FIELD_TYPES } from '../../../../shared/globals';
import { LoadingOrError } from '../../../../components/LoadingOrError';
import { setFormResponsesAction } from '../../../../redux/data/data-actions';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

interface ViewFormResponsesDialogueDialogProps {
  open: boolean;
  handleClose: () => void;
  formInstanceResultRow: FormInstanceResultRow | null;
}

const filterArray = [
  "6ecb8671-53c0-4e61-9c1c-1b2780f60d42", "d6070acd-3c40-4c7d-9556-fc3807e32959", "642014a2-6699-480c-a090-32985d4fd916", "642014a2-6699-480c-a090-32985d4fd916", "25ff25f2-b80c-4340-a408-c207c1b08d6d", "dd17ae39-f7d8-433e-8013-975d92281a52", "283887ca-1701-42bd-be9d-b19097ff38e8",
  "b19f975c-0340-4c82-a5ff-15c0ee840c2c", "f972f78c-62e8-4d34-b8e4-9fb6baf3f273", "9560dd12-0911-409a-8d65-1bb6d32d94c1", "31793548-68e2-4420-83e8-e262effcb5d6", "53a15559-c38c-4214-9869-3622b36e606d", "e57a12f0-f579-4732-aa71-3d0bf8632364", "81c682af-471c-40f9-bf8d-72d0704098ab",
  "7fa1a93c-aba9-422a-9259-86da8ce1e535", "ae21778e-6593-4731-ab0a-d787b1743234", "3709ddb5-12de-49e4-8a2a-6735bc16ec59", "305f8b7a-d8d0-4a03-83f2-b16328b01e42", "111a041d-2a3f-4897-8997-f026fd47b7c2", "c6cfd10d-1b70-41ba-8a51-fe6edbb170af", "bf2168fa-b06b-4a3d-a6e5-e5d9b1f67920",
  "cb0035fc-b7ad-4a3a-a6d6-57e90ad0a4b5", "a9801d79-ee7c-4888-9822-4a1ff98adef4", "cbf41c73-4b0d-4635-aa88-7ed0dc563689", "94fd4cd1-0565-44e9-b537-ae4652bd92d2", "e61e1b1c-a865-4927-b375-d4ed6a14ec26", "a6e059cd-33de-40d1-b863-2407f7a414fa", "e8966f2a-2ce0-4c61-80bf-8e0db9b71791", "c875793d-09a5-4914-bee0-f385117ea85e", "4b02eab0-691b-4d3e-8e12-f47adb0f622d",
  "1d655d1a-2943-4b47-a2a7-efa2b289b39f", "e1ccbd9c-7fe4-43a5-b7dd-bca4e64aae66", "b5defe4a-5a60-44fe-8321-445eaf96797d", "69610d6b-3e85-4b2f-9d28-9b3f250847bb", "0e25637c-4ec2-4826-a580-832e845bdd15", "d964fab4-278f-4cd1-9822-207907f1c746", "2c64c92b-b78a-4f6e-b638-c29dcb70827d", "918003f2-d506-42b2-bc97-fe660258d367", "22905c15-46e1-497e-b468-6a1dfc0c4922",
  "f7666c64-aaea-49a7-b196-9c1d5a9e4510", "c84c08a8-db67-4a08-ace4-bb8dc38ea2b0", "cbfe9715-9b66-48d7-8b6a-000f96bc00e7", "73aac3b3-a9fe-4d1e-97c5-75c0163d95e4", "b39f711a-9a97-46c8-af54-86db9e1ebeef", "b5bf2e9e-bb8f-4371-854a-a15a9545abbe", "733ab7d1-5ff7-494f-9519-a8c5d65497fc", "a834bcae-9af4-4ba6-a98d-655bc55bd2d9", "8f2a2ffd-6029-4bd3-a59b-9492031f90b0", "74723f19-2a74-4f67-bf4c-0e71a6f1298c",
  "03ffe5e2-fc07-4872-bdd0-56791bbb11c9", "00e02e3a-eb17-465b-968c-41ae7bb7c5fd", "8e8cfc7c-0808-4ad8-b480-ba8c5f3c8566", "6ce7457d-c080-444e-a7ca-0c0c833ca35b", "830fe637-7148-4733-87f3-eebf242c663f", "0ba928c5-fd23-4894-8400-25530ec3a3fd", "4bfcf409-2f55-48d9-a5a0-38523e20c9b0", "7a39eb34-0bf4-40b8-acbe-9bc2a60ebea6", "dbf318ae-db03-40e6-ae25-5bcca7eb86e1",
]

export const ViewFormResponsesDialogue = ({
  open,
  handleClose,
  formInstanceResultRow
}: ViewFormResponsesDialogueDialogProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [queAnswer, setQueAnswer] = useState<any>();
  const [textChoice, setChoice] = useState(0);
  const [openAnswer, setOpenAnswer] = useState(false);
  const [openLogs, setOpenLogs] = useState(false);
  const [changeQue, setQueName] = useState<any>();
  const [initialValues, setInitialValues] = useState({
    answer: ''
  })

  const formResponsesData: FormInstanceResponses | null = useSelector(
    (state: RootState) => state.DATA_REDUCER.formInstanceResponses
  );

  const formRespChangeLogs: FormResponseChangeLogs | null = useSelector(
    (state: RootState) => state.DATA_REDUCER.formRespChangeLogs
  );

  const loadingResponses = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayLoading.getFormInstanceResponsesLoading
  );
  const loadingResponsesError = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayErrors.getFormInstanceResponsesError
  );

  const downloadPdfLoading = useSelector(
    (state: RootState) =>
      state.DISPLAY_STATE_REDUCER.displayLoading.downloadingFormResponsePdfLoading
  );

  const message = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.snackbarAlertMessage
  );

  useEffect(() => {
    if (message && message.length > 0) {
      setOpenAnswer(false);
    }
  }, [message]);

  useEffect(() => {
    if (formInstanceResultRow && formInstanceResultRow.id) {
      dispatch(
        getFormResponsesThunk({
          formInstanceId: formInstanceResultRow.id, plan: formInstanceResultRow.title
        })
      );
      dispatch(
        getChangeLogsThunk({
          formInstanceId: formInstanceResultRow.id,
          patientId: formInstanceResultRow.patient_id
        })
      );
    }
  }, []);

  const closeHandler = () => {
    dispatch(setFormResponsesAction(null));
    handleClose();
  };
  const handleDownloadPDF = async () => {
    if (formInstanceResultRow) {
      dispatch(downloadFormResponsePDFThunk(formInstanceResultRow.id, formInstanceResultRow.title));
    }
  };

  const updateAnswer = (values: any) => {
    let plan: any = formInstanceResultRow && formInstanceResultRow.title;
    if (textChoice === 1) {
      dispatch(putChoiceAnswerThunk(values.answer, formInstanceResultRow && formInstanceResultRow.id, queAnswer.id, plan))
    } else {
      dispatch(putChatbotAnswerThunk(values.answer, formInstanceResultRow && formInstanceResultRow.id, queAnswer.id, plan));
    }
  };

  const answerSchema = Yup.object().shape({
    answer: (queAnswer && queAnswer.field_type) === 'LONG_TEXT' ? Yup.string().max(2500, "Required max. 2500 characters").required('Required') : Yup.string().required('Required')
  });

  const handelCloseAnswerDialog = () => {
    setOpenAnswer(false);
  }

  const changeChoice = (answer: any) => {
    setInitialValues({ answer: answer });
  }

  const editAnswer = (answer: any) => {
    setQueName(answer.field_prompt.split("\n"));
    let selectedOpt = answer.form_field_options?.filter(
      (option: any) => option.option_text === answer.response
    );
    setOpenAnswer(true);
    setChoice(answer.field_type == "SINGLE_CHOICE" ? 1 : 0)
    if (answer.field_type == "SINGLE_CHOICE") {
      setInitialValues({ answer: selectedOpt[0].id })
    } else {
      setInitialValues({ answer: answer.response })
    }
    setQueAnswer(answer);
  }

  const viewLogs = (openLog: boolean) => {
    setOpenLogs(openLog);
  }

  const JSONToCSVConvertor = (JSONData: any, ReportTitle: string, ShowLabel: boolean) => {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData =
      typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;
    var CSV = "";
    //This condition will generate the Label/Header
    if (ShowLabel) {
      var row = "";
      //This loop will extract the label from 1st index of on array
      for (var index in arrData[0]) {
        //Now convert each value to string and comma-seprated
        if (index == "updatedByName" || index == "updated_at" || index == "patientName" || index == "form_name" || index == "form_field" || index == "change_made" || index == "created_at") {
          row += index + ",";
        } else { }
      }
      row = row.slice(0, -1);
      //append Label row with line break
      CSV += row + "\r\n";
    }
    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      var row = "";
      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in arrData[i]) {
        if (index == "updatedByName" || index == "updated_at" || index == "patientName" || index == "form_name" || index == "form_field" || index == "change_made" || index == "created_at") {
          row += '"' + arrData[i][index] + '",';
        } else { }
      }
      row.slice(0, row.length - 1);
      //add a line break after each row
      CSV += row + "\r\n";
    }
    if (CSV === "") {
      alert("Invalid data");
      return;
    }
    //Generate a file name
    var fileName = "ChangeLogReport_";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    // link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  var options: any = { weekday: 'long' };

  const showQuestion = (info: any) => {
    return info.split("\n")
  }

  return (
    <>
      <Dialog open={open} handleClose={closeHandler}>
        {formInstanceResultRow && (
          <Box className={classes.main} display="flex" flexDirection="column">
            <Box className={classes.topBox} bgcolor={colors.background2} px={3} py={2}>
              <Box
                bgcolor={colors.background2}
                className={classes.bottomBox}
                display="flex"
                justifyContent="space-between"
              >
                <Typography variant="h3">Responses on</Typography>
                <LoadingButton
                  className={classes.primaryButton}
                  color="primary"
                  onClick={(e: any) => viewLogs(true)}
                  variant="contained"
                  aria-label="View logs"
                >
                  View Change logs
                </LoadingButton>
              </Box>
              <Box py={1}>
                <Typography variant="h3">{formInstanceResultRow.title}</Typography>
              </Box>

              {formResponsesData && formResponsesData.patientName && (
                <Box display="flex">
                  <Typography variant="body1">Completed on</Typography>
                  <Box px={1}>
                    <Typography variant="subtitle2">
                      {new Intl.DateTimeFormat('en-US', options).format(new Date(formInstanceResultRow.created_at)) + ', ' + (("0" + (new Date(formInstanceResultRow.created_at).getMonth() + 1)).slice(-2)) + '/' + ("0" + new Date(formInstanceResultRow.created_at).getDate()).slice(-2) + '/' + new Date(formInstanceResultRow.created_at).getFullYear()}
                      {/* {new Date(formInstanceResultRow.created_at).toDateString()} */}
                    </Typography>
                  </Box>
                  <Typography variant="body1">by</Typography>
                  <Box pl={1}>
                    <Typography variant="subtitle2"> {formResponsesData.patientName} </Typography>
                  </Box>
                </Box>
              )}
            </Box>
            <Box display="flex" flexDirection="column" className={classes.body}>
              {/* {loadingResponses || loadingResponsesError ? (
                <LoadingOrError
                  errorMsg={loadingResponsesError}
                  loading={true}
                  loadingMsg="Loading Responses"
                  noElevation
                />
              ) : ( */}
              <React.Fragment>
                {formResponsesData &&
                  formResponsesData.responses &&
                  formResponsesData.responses.map(
                    (responseItem: any, index: number) => {
                      let hide = filterArray.filter(id => id == responseItem.id)
                      let que = showQuestion(responseItem.field_prompt)
                      return (
                        <Box key={index} px={3} py={2} display="flex" flexDirection="column">
                          {(formInstanceResultRow.title != "BHAP-TASCS" && formInstanceResultRow.title != "Life Plan - TASCS") ? null :
                            <Typography variant="body1"> Question No {index + 1} </Typography>}
                          {(formInstanceResultRow.title == "Safety Plan - TASCS" && (hide && hide.length === 0)) && <Typography variant="body1"> Question: </Typography>}
                          {que && que.map((queName: any) => {
                            return <Typography variant="subtitle2"> {queName} </Typography>
                          })}
                          <Box pt={1}>
                            <Typography variant="h3">
                              {(hide && hide.length > 0) ? null :
                                <BorderColor onClick={(e: any) => editAnswer(responseItem)} color='primary' style={{ cursor: 'pointer', paddingRight: '20px', fontSize: '22px' }} />
                              }
                              <b>
                                {responseItem.response}
                                {responseItem.field_type === FIELD_TYPES.SCALE
                                  ? ` of  ${responseItem.option_scale_max}`
                                  : null}
                              </b>
                            </Typography>
                          </Box>
                        </Box>
                      );
                    }
                  )}
              </React.Fragment>
              {/* )} */}
            </Box>
            <Box
              bgcolor={colors.background2}
              className={classes.bottomBox}
              display="flex"
              justifyContent="space-between"
              px={3}
              py={2}
            >
              <LoadingButton
                className={classes.secondaryButton}
                color="secondary"
                onClick={handleClose}
                variant="contained"
                aria-label="return"
              >
                Return
              </LoadingButton>
              <LoadingButton
                className={classes.primaryButton}
                color="primary"
                disabled={downloadPdfLoading}
                loading={downloadPdfLoading}
                onClick={handleDownloadPDF}
                variant="contained"
                aria-label="download pdf"
              >
                Download as .PDF
              </LoadingButton>
            </Box>
          </Box>
        )}
      </Dialog>
      <Dialog open={openAnswer} handleClose={handelCloseAnswerDialog}>
        <Box display='flex' flexDirection='column' className={classes.box}>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={updateAnswer}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={answerSchema}
          >
            {({ errors, isSubmitting, setSubmitting, touched }) => {

              return (
                <Form style={{ width: '400px' }}>
                  <Box pb={5}>
                    <Box pb={0.5}>
                      <Typography color="textSecondary" variant="h1">
                        Edit {formInstanceResultRow && formInstanceResultRow.title} Response
                      </Typography>
                    </Box>
                  </Box>

                  {textChoice === 1 ?
                    <Box pb={2.5}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <FormControl component="fieldset" style={{ display: 'contents' }}>
                            {changeQue && changeQue.map((queName: any) => {
                              return <FormLabel component="legend" className={classes.label}>{queName}</FormLabel>
                            })}

                            {queAnswer.form_field_options.length === 2 ?
                              <RadioGroup name={queAnswer.response} value={initialValues.answer} onChange={e => changeChoice(e.target.value)}>
                                <Paper className={classes.paper} style={{ backgroundColor: initialValues.answer == queAnswer.form_field_options[0].id ? '#5D6AC4' : '#fff' }}>
                                  <FormControlLabel value={queAnswer.form_field_options[0].id} control={<Radio />} label={queAnswer.id == 'c35ac88f-746d-40ba-b542-7e9f304817d2' ? "Community Health Link 1-800-977-5555" : "National Suicide Prevention Lifeline 1-800-273-8255"} />
                                </Paper>
                                <Paper className={classes.paper} style={{ backgroundColor: initialValues.answer == queAnswer.form_field_options[1].id ? '#5D6AC4' : '#fff' }}>
                                  <FormControlLabel value={queAnswer.form_field_options[1].id} control={<Radio />} label="NA" />
                                </Paper>
                              </RadioGroup> :
                              <RadioGroup name={queAnswer.response} value={initialValues.answer} onChange={e => changeChoice(e.target.value)}>
                                <Paper className={classes.paper} style={{ backgroundColor: initialValues.answer == queAnswer.form_field_options[0].id ? '#5D6AC4' : '#fff' }}>
                                  <FormControlLabel value={queAnswer.form_field_options[0].id} control={<Radio />} label={queAnswer.form_field_options[0].option_text} />
                                </Paper>
                                <Paper className={classes.paper} style={{ backgroundColor: initialValues.answer == queAnswer.form_field_options[1].id ? '#5D6AC4' : '#fff' }}>
                                  <FormControlLabel value={queAnswer.form_field_options[1].id} control={<Radio />} label={queAnswer.form_field_options[1].option_text} />
                                </Paper>
                                <Paper className={classes.paper} style={{ backgroundColor: initialValues.answer == queAnswer.form_field_options[2].id ? '#5D6AC4' : '#fff' }}>
                                  <FormControlLabel value={queAnswer.form_field_options[2].id} control={<Radio />} label={queAnswer.form_field_options[2].option_text} />
                                </Paper>
                              </RadioGroup>
                            }
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                    :
                    // (queAnswer.field_type === 'LONG_TEXT' ?
                    <Box pb={2.5}>
                      <Box pb={0.75}>
                        {changeQue && changeQue.map((queName: any) => {
                          return <Typography color="textSecondary" variant="subtitle2">{queName}</Typography>
                        })}
                        <span style={{ fontSize: '12px' }}>Type max 2500 characters</span>
                      </Box>
                      <Field as={TextField} multiline rows={15} inputProps={{ maxLength: 2500 }}
                        className={classes.textArea}
                        name="answer"
                        type=""
                        variant="outlined"
                      />
                      {errors.answer && touched.answer ? (
                        <Typography color="error" variant="subtitle2">
                          {errors.answer}
                        </Typography>
                      ) : null}
                    </Box>
                    //    :
                    //   <Box pb={2.5}>
                    //     <Box pb={0.75}>
                    //       {changeQue && changeQue.map((queName: any) => {
                    //         return <Typography color="textSecondary" variant="subtitle2">{queName}</Typography>
                    //       })}

                    //     </Box>
                    //     <Field
                    //       as={TextField}
                    //       className={classes.textField}
                    //       name="answer"
                    //       size="small"
                    //       type=""
                    //       variant="outlined"
                    //     />
                    //     {errors.answer && touched.answer ? (
                    //       <Typography color="error" variant="subtitle2">
                    //         {errors.answer}
                    //       </Typography>
                    //     ) : null}
                    //   </Box>
                    // )
                  }

                  <Box pb={2}>
                    <Grid container>
                      <Grid item xs={6} style={{ textAlign: 'left' }}>
                        <LoadingButton
                          className={classes.secondaryButton}
                          variant="contained"
                          aria-label="Submit"
                          onClick={(e: any) => handelCloseAnswerDialog()}
                        >
                          Cancel
                        </LoadingButton>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: 'right' }}>
                        <LoadingButton color="primary"
                          className={classes.primaryButton}
                          type="submit"
                          variant="contained"
                          aria-label="sign in"
                        >
                          Save
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Dialog>
      {
        openLogs && <Dialog open={openLogs}>
          {formInstanceResultRow && (
            <Box className={classes.main} display="flex" flexDirection="column">
              <Box className={classes.topBox} bgcolor={colors.background2} px={3} py={2}>
                <Typography variant="h3">Response Change logs</Typography>
                <Box py={1}>
                  <Typography variant="h3">{formInstanceResultRow.title}</Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" className={classes.body}>
                {loadingResponses || loadingResponsesError ? (
                  <LoadingOrError
                    errorMsg={loadingResponsesError}
                    loading={true}
                    loadingMsg="Loading Responses"
                    noElevation
                  />
                ) : (
                  <React.Fragment>
                    {formRespChangeLogs &&
                      formRespChangeLogs.userChangeLog &&
                      formRespChangeLogs.userChangeLog.map(
                        (responseItem: any, index: number) => {
                          let que = showQuestion(responseItem.form_field)
                          return (
                            <Box key={index} px={3} py={2} display="flex" flexDirection="column">
                              <Grid container spacing={3}>
                                <Grid item xs={6}><Typography variant="body1"> Question No {index + 1} </Typography></Grid>
                                <Grid item xs={6} style={{ textAlign: 'right' }}>
                                  <Typography variant="body2"> Updated On:
                                    {' ' + new Intl.DateTimeFormat('en-US', options).format(new Date(responseItem.updated_at)) + ', ' + (("0" + (new Date(responseItem.updated_at).getMonth() + 1)).slice(-2))
                                      + '/' + ("0" + new Date(responseItem.updated_at).getDate()).slice(-2) + '/' + new Date(responseItem.updated_at).getFullYear() + ' - ' +
                                      (("0" + new Date(responseItem.updated_at).getHours()).slice(-2)) + ':' + (("0" + new Date(responseItem.updated_at).getMinutes()).slice(-2))}
                                  </Typography></Grid>
                              </Grid>
                              <Grid container spacing={3}>
                                <Grid item xs={9}>
                                  {que && que.map((queName: any) => {
                                    return <Typography variant="subtitle2"> {queName} </Typography>
                                  })}
                                </Grid>
                                <Grid item xs={3} style={{ textAlign: 'right' }}>
                                  <Typography variant="body2"> {responseItem.updatedByName} </Typography>
                                </Grid>
                              </Grid>
                              <Box pt={1}>
                                <Typography variant="h3">
                                  <b>
                                    {responseItem.change_made}
                                  </b>
                                </Typography>
                              </Box>
                            </Box>
                          );
                        }
                      )}
                  </React.Fragment>
                )}
              </Box>
              <Box
                bgcolor={colors.background2}
                className={classes.bottomBox}
                display="flex"
                justifyContent="space-between"
                px={3}
                py={2}
              >
                <LoadingButton
                  className={classes.secondaryButton}
                  color="secondary"
                  onClick={(e: any) => viewLogs(false)}
                  variant="contained"
                  aria-label="return"
                >
                  Return
                </LoadingButton>
                <LoadingButton
                  className={classes.primaryButton}
                  color="primary"
                  disabled={downloadPdfLoading}
                  loading={downloadPdfLoading}
                  onClick={() => JSONToCSVConvertor(formRespChangeLogs && formRespChangeLogs.userChangeLog, formInstanceResultRow.title, true)}
                  variant="contained"
                  aria-label="download pdf"
                >
                  Download as CSV
                </LoadingButton>
              </Box>
            </Box>
          )}
        </Dialog>
      }
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '80vw',
    maxWidth: '50rem'
  },

  topBox: {
    borderRadius: '0.625rem 0.625rem 0 0'
  },
  bottomBox: {
    borderRadius: '0 0 0.625rem 0.625rem'
  },
  body: {
    maxHeight: '60vh',
    overflow: 'auto'
  },
  primaryButton: {
    boxShadow:
      '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)'
  },
  box: {
    padding: '30px'
  },
  textField: {
    width: '100%'
  },
  textArea: {
    padding: 5,
    width: '100%'
  },
  paper: {
    textAlign: 'left', paddingLeft: '20px', margin: '10px',
  },
  label: {
    fontSize: '18px', fontWeight: 600, paddingLeft: '15px', textAlign: 'left'
  }
}));