import React from 'react';
import { Box, makeStyles, Theme, Grid } from '@material-ui/core';

import { RoleUsers } from './components/RoleUsers';
import { ReferralSideBar } from '../../../../components/ReferralSideBar';
import { TopBar } from '../../../../components/TopBar';

export const ReferralRolePage = () => {
  const classes = useStyles();

  return (
    <Box className={classes.main} display="flex" flexDirection="column" p={4}>
      <Box mb={4}>
        <TopBar />
      </Box>
      <Grid container>
        <Grid item xs={12} sm={2} md={2}>
          <ReferralSideBar />
        </Grid>
        <Grid item xs={12} sm={10} md={10}>
          <RoleUsers />
        </Grid>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    [theme.breakpoints.down('xs')]: {
      padding: '1rem'
    },
    backgroundColor: theme.background.main,
    minHeight: '100vh'
  },
  contentContainer: {
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  }
}));
