import { Typography, Box, Paper } from '@material-ui/core';
import SaveAltOutlined from '@material-ui/icons/SaveAltOutlined';
import React from 'react';
import { url } from '../../../../redux/url';

var axios = require("axios");
var FormData = require("form-data");

interface ResourceBlockProps {
    infoText: any;
    files: any;
    index: any
}

export const ResourceBlock: React.FC<ResourceBlockProps> = ({ infoText, files, index }) => {

    const type: any = typeof infoText;

    const _getHeaders = (inputHeaders?: { [key: string]: string }) => {
        let headers = inputHeaders || {};
        let token = localStorage.getItem('token');
        headers["Accept"] = "application/json";
        const headersWithAuth = token
            ? {
                ...headers,
                Authorization: `Bearer ${token}`,
            }
            : headers;
        return headersWithAuth;
    };

    const downloadFile = (link: any) => {
        const data1 = {
            input_files: [link]
        }
        var config1 = {
            method: 'post',
            url: `${url}/user/auth/getSignedDownloadUrls`,
            headers: _getHeaders({ "Content-Type": "application/json" }),
            data: data1,
        };

        axios(config1)
            .then(function (res: any) {
                const url = res?.data?.url && res.data.url?.length > 0 && res.data.url[0];
                window.open(url, "_blank");
            })
    }

    return (infoText && (type == 'array') ? (infoText.map((queName: any) => {
        return (
            <>
                <Typography variant="subtitle2"><b>{index && (index + '. ')} {queName ? queName : ''}</b></Typography>
                <Box mt={2}>
                    {files && files.length > 0 && files.map((item: any) => (
                        <Paper style={{ boxShadow: 'unset', border: '1px solid #cbcbcb', padding: '5px 15px', width: '50%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                            <Typography>{item.file_name}</Typography>
                            <a href={item.resource_link} target='_blank'><SaveAltOutlined style={{
                                color: "gray",
                                cursor: 'pointer',
                                padding: '0px 20px'
                            }} /></a>
                        </Paper>
                    ))
                    }
                </Box>
            </>
        )
    })) :
        <>
            <Typography variant="subtitle2"><b>{infoText ? infoText : ''}</b></Typography>
            <Box mt={2}>
                {files.map((item: any) => (
                    <Paper style={{ boxShadow: 'unset', border: '1px solid #cbcbcb', padding: '5px 15px', width: '50%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                        <Typography>{item.file_name}</Typography>
                        <a href={item.resource_link} target='_blank'><SaveAltOutlined style={{
                            color: "gray",
                            cursor: 'pointer',
                            padding: '0px 20px'
                        }} /></a>
                    </Paper>
                ))
                }
            </Box>
        </>
    )
};
