import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { FormPageDisplay } from '../../../components/FormPageDisplay';
import { RootState } from '../../../redux/redux-store';
import { theme } from '../../../styling/ThemeConfig';

export const EntryPageViewer: React.FC<{}> = () => {
  const classes = useStyles();
  const formThemeColor = theme.palette.primary.main;
  const editingForm = useSelector((state: RootState) => state.DATA_REDUCER.editingForm);

  if (!editingForm) {
    return null;
  }

  if (!editingForm.entry_page_header || !editingForm.entry_page_body) {
    return (
      <Box p={2} className={classes.placeholder}>
        <Typography variant={'h3'}>Add a header and body to get started</Typography>
      </Box>
    );
  } else {
    return (
      <Box m={5} p={6} flexGrow className={classes.container}>
        <FormPageDisplay
          header={editingForm.entry_page_header}
          body={editingForm.entry_page_body}
          videoUrl={editingForm.entry_page_video_url}
          themeColor={formThemeColor}
        />
      </Box>
    );
  }
};

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.background.main
  },
  placeholder: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));
