import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/redux-store';
import { createFormFieldThunk, updateFormFieldThunk } from '../../redux/thunk/thunks';
import { FieldOptionInput, FormField, FormFieldInput, UuidType } from '../../shared/domain';
import { FIELD_TYPES } from '../../shared/globals';
import { FieldsSideBar } from './components/FieldsSidebar';
import { FormViewer } from './components/FormViewer';

export const FormBuilder = () => {
  let { url } = useRouteMatch();
  const classes = useStyles();
  const dispatch = useDispatch();
  const split = url.split('/');
  const formId: any = split && split.length > 1 && split[2];

  const editingFormFields = useSelector((state: RootState) => state.DATA_REDUCER.editingFormFields);

  const onDragEnd = (result: DropResult) => {
    const fields: FormField[] = editingFormFields || [];
    if (!result.destination) {
      return;
    }
    //handle field Reorder
    if (
      result.source.droppableId === 'formViewerDroppable' &&
      result.destination.droppableId === 'formViewerDroppable' &&
      result.source.index !== result.destination.index
    ) {
      let dragFieldId = result.draggableId;
      let nextIndex = result.destination.index;
      let nextId: UuidType | null = null;
      if (fields.length - 1 > nextIndex) {
        nextId = fields[nextIndex].id;
      }
      dispatch(updateFormFieldThunk({ id: dragFieldId, next_form_field: nextId }, formId));
    } else if (
      result.source.droppableId === 'sideBarDroppable' &&
      result.destination.droppableId === 'formViewerDroppable'
    ) {
      let nextIndex = result.destination.index;
      let nextId: UuidType | null = null;
      if (fields.length > nextIndex) {
        nextId = fields[nextIndex].id;
      }

      createNewFieldObject(result.draggableId, nextId);
    }
    sessionStorage.setItem('formTab', 'field');
  };

  const createNewFieldObject = (
    fieldType: string,
    nextFormField: UuidType | null,
    fieldPrompt?: string,
    fieldOptions?: FieldOptionInput[],
    fieldScaleMax?: number,
    formField?: any,
  ) => {
    let fieldInput: FormFieldInput | null = null;
    let fieldOptionsInput: FieldOptionInput[] | null = null;
    const defaultOptions: FieldOptionInput[] = [
      { option_value: 1, option_text: 'Option 1', allow_user_input: false },
      { option_value: 2, option_text: 'Option 2', allow_user_input: false },
      { option_value: 3, option_text: 'Option 3', allow_user_input: false }
    ];
    switch (fieldType) {
      case FIELD_TYPES.SHORT_TEXT:
        fieldInput = {
          field_type: FIELD_TYPES.SHORT_TEXT,
          field_prompt: fieldPrompt || 'NEW SHORT TEXT FIELD',
          form_id: formId,
          next_form_field: nextFormField
        };
        break;
      case FIELD_TYPES.LONG_TEXT:
        fieldInput = {
          field_type: FIELD_TYPES.LONG_TEXT,
          field_prompt: fieldPrompt || 'NEW LONG TEXT FIELD',
          form_id: formId,
          next_form_field: nextFormField
        };
        break;
      case FIELD_TYPES.DROPDOWN:
        fieldInput = {
          field_type: FIELD_TYPES.DROPDOWN,
          field_prompt: fieldPrompt || 'NEW DROPDOWN FIELD',
          form_id: formId,
          next_form_field: nextFormField
        };
        fieldOptionsInput = fieldOptions || defaultOptions;
        break;
      case FIELD_TYPES.SINGLE_CHOICE:
        fieldInput = {
          field_type: FIELD_TYPES.SINGLE_CHOICE,
          field_prompt: fieldPrompt || 'NEW SINGLE CHOICE FIELD',
          form_id: formId,
          next_form_field: nextFormField
        };
        fieldOptionsInput = fieldOptions || defaultOptions;
        break;
      case FIELD_TYPES.MULTIPLE_CHOICE:
        fieldInput = {
          field_type: FIELD_TYPES.MULTIPLE_CHOICE,
          field_prompt: fieldPrompt || 'NEW MULTIPLE CHOICE FIELD',
          form_id: formId,
          next_form_field: nextFormField
        };
        fieldOptionsInput = fieldOptions || defaultOptions;
        break;
      case FIELD_TYPES.SCALE:
        fieldInput = {
          field_type: FIELD_TYPES.SCALE,
          field_prompt: fieldPrompt || 'NEW SCALE FIELD',
          form_id: formId,
          next_form_field: nextFormField,
          option_scale_max: fieldScaleMax || 10
        };
        break;
      case FIELD_TYPES.RESOURCE:
        fieldInput = {
          field_type: FIELD_TYPES.RESOURCE,
          field_prompt: fieldPrompt || 'NEW RESOURCE FIELD',
          form_id: formId,
          next_form_field: nextFormField
        };
        break;
      case FIELD_TYPES.INFO_TEXT:
        fieldInput = {
          field_type: FIELD_TYPES.INFO_TEXT,
          field_prompt: fieldPrompt || 'NEW INFO TEXT BLOCK',
          form_id: formId,
          next_form_field: nextFormField
        };
        break;
      default:
        break;
    }
    if (fieldInput) {
      if (formField) {
        fieldInput.info_for_clinician = formField.info_for_clinician;
        fieldInput.info_for_patient = formField.info_for_patient;
        fieldInput.logic_is_all = formField.logic_is_all;
        fieldInput.logic_is_show = formField.logic_is_show
      }
      dispatch(createFormFieldThunk(formId, fieldInput, fieldOptionsInput));
    }
    sessionStorage.setItem('formTab', 'field');
  };

  return (
    <Box className={classes.main} display="flex" flexDirection="column" p={4}>
      {
        //DragDropContext is the root for all Drag and Drop functionlity
        //There are two droppable surfaces, one in FieldSidebar and one in Formviewer
      }
      <DragDropContext onDragEnd={onDragEnd}>
        <Container maxWidth="lg" className={classes.content}>
          <FieldsSideBar formId={formId} />
          <FormViewer formId={formId} createNewFieldObject={createNewFieldObject} />
        </Container>
      </DragDropContext>
      {/* <Modal
        open={getFormLoading || getFormError !== null}
        className={classes.modal}
        closeAfterTransition={true}
      >
        <LoadingOrError loading={true} loadingMsg={'Loading Form...'} errorMsg={getFormError} />
      </Modal> */}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  content: {
    display: 'flex',
    justifyContent: 'center',

    padding: '30px 0px'
  },
  main: {
    padding: '70px 0px 20px'
  }
}));
