import React, { useState } from 'react';
import { Box, Theme, Typography, makeStyles, TextField } from '@material-ui/core';

import { Dialog } from './Dialog';
import { LoadingButton } from './LoadingButton';
import { colors } from '../styling/colors';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

interface DatepickerDialogProps {
  open: boolean;
  title: string;
  disablePastDates?: boolean;
  loading?: boolean;
  handleClose: () => void;
  handleConfirmClick: (selectedDate: Date) => void;
}

export const DatepickerDialog = ({
  loading,
  open,
  title,
  disablePastDates,
  handleClose,
  handleConfirmClick
}: DatepickerDialogProps) => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  const handleConfirmOnClick = () => {
    handleConfirmClick(selectedDate);
  };

  return (
    <Dialog open={open} handleClose={handleClose}>
      <Box display="flex" flexDirection="column">
        <Box
          alignItems="center"
          bgcolor={colors.background2}
          className={classes.topBox}
          display="flex"
          justifyContent="space-between"
          px={3}
          py={2.5}
        >
          <Typography variant="h3">{title}</Typography>
        </Box>

        <Box px={3} py={2.5}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="Date picker dialog"
              format="MM/dd/yyyy"
              value={selectedDate}
              onChange={handleDateChange}
              disablePast={disablePastDates}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
          </MuiPickersUtilsProvider>
        </Box>

        <Box
          bgcolor={colors.background2}
          className={classes.bottomBox}
          display="flex"
          justifyContent="space-between"
          px={3}
          py={2}
        >
          <LoadingButton
            className={classes.secondaryButton}
            color="secondary"
            onClick={handleClose}
            variant="contained"
            aria-label="cancel"
          >
            Cancel
          </LoadingButton>

          <LoadingButton
            className={classes.primaryButton}
            color="primary"
            disabled={loading}
            loading={loading}
            onClick={handleConfirmOnClick}
            variant="contained"
            aria-label="confirm"
          >
            Confirm
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    minHeight: '100vh'
  },
  dialogPaper: {
    borderRadius: '0.625rem'
  },
  topBox: {
    borderRadius: '0.625rem 0.625rem 0 0'
  },
  searchTextField: {
    backgroundColor: colors.background3,
    fontSize: '13px',
    height: '2rem',
    width: '8rem'
  },
  searchIcon: {
    fontSize: 17
  },
  bottomBox: {
    borderRadius: '0 0 0.625rem 0.625rem'
  },
  primaryButton: {
    boxShadow:
      '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)'
  }
}));
