import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { logoutThunk, cancelEditFormThunk } from '../redux/thunk/thunks';
import { LoadingButton } from './LoadingButton';
import { Dialog } from './Dialog';
import { colors } from '../styling/colors';

export const SessionPopup = ({ open, handelClose }: any) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const Ref: any = useRef(null);
    const [timer, setTimer] = useState('00:00:00');
    const formId = sessionStorage.getItem('id');
    const version = sessionStorage.getItem('version');

    const logOut = async () => {
        localStorage.removeItem('remember');
        formId && await dispatch(cancelEditFormThunk(formId, version));
        await dispatch(logoutThunk());
        sessionStorage.removeItem('id');
        handelClose();
    }

    const MINUTE_MS = 120000;

    useEffect(() => {
        const interval = setInterval(() => {
            logOut();
        }, MINUTE_MS);
        // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
        return () => clearInterval(interval);
    }, []);


    const getTimeRemaining = (e: any) => {
        const date: any = new Date();
        const total = Date.parse(e) - Date.parse(date);
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return {
            total, hours, minutes, seconds
        };
    }


    const startTimer = (e: any) => {
        let { total, hours, minutes, seconds }
            = getTimeRemaining(e);
        if (total >= 0) {
            setTimer(
                (minutes > 9 ? minutes : '0' + minutes) + ':'
                + (seconds > 9 ? seconds : '0' + seconds)
            )
        }
    }
    const clearTimer = (e: any) => {
        setTimer('02:00');
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }

    const getDeadTime = () => {
        let deadline = new Date();
        deadline.setSeconds(deadline.getSeconds() + 120);
        return deadline;
    }
    useEffect(() => {
        clearTimer(getDeadTime());
    }, []);

    return (
        open ?
            <Dialog open={open}>
                <Box display="flex" flexDirection="column" style={{ textAlign: 'center' }} width={500}>
                    <Box alignItems="center" bgcolor={colors.background2} className={classes.topBox} p={3} >
                        <Typography style={{ fontSize: '20px', fontWeight: 600 }}>Are you still there?</Typography>
                    </Box>
                    <Box alignItems="center" className={classes.topBox} p={3} >
                        <Typography style={{ fontSize: '16px', textAlign: 'justify', lineHeight: 1.4 }}>Your session will expire in <b>{timer}</b> due to inactivity. Please choose to stay logged in or logout. Otherwise, you will automatically be logged off.</Typography>
                    </Box>
                    <Box bgcolor={colors.background2} className={classes.bottomBox} display="flex" justifyContent="space-between" px={3} py={2} >
                        <LoadingButton className={classes.secondaryButton} variant="contained" onClick={(e) => logOut()}>
                            Logout
                        </LoadingButton>
                        <LoadingButton className={classes.primaryButton} color="primary" variant="contained" onClick={(e) => handelClose()}>
                            I'm Here
                        </LoadingButton>
                    </Box>
                </Box>
            </Dialog> :
            <Box mt={2} display="flex" justifyContent="center">
                <span>Something went wrong!</span>
            </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    primaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#fff',
        minWidth: '120px'
    },
    secondaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#d20f0f',
        background: '#fff',
        minWidth: '120px'
    },
    bottomBox: {
        borderRadius: '0 0 0.625rem 0.625rem'
    },
    topBox: {
        borderRadius: '0.625rem 0.625rem 0 0'
    },
}));