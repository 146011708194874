import React, { useEffect, useState } from 'react';
import { Box, Theme, Typography, makeStyles, Grid, Divider, Chip, DialogActions } from '@material-ui/core';
import { Dialog } from '../../../components/Dialog';
import { ArrowBack, GetApp, Star, StarOutline } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { UuidType } from '../../../shared/domain';
import { RootState } from '../../../redux/redux-store';
import { getCollectionFormsThunk, removeFavoriteCollectionThunk, markFavoriteCollectionThunk, downloadFormsThunk, getCollectionStatusCount } from '../../../redux/thunk/thunks';
import { CollectionFormTable } from './CollectionFormTable';
import { LoadingButton } from '../../../components/LoadingButton';
import { ROLES } from '../../../shared/globals';

interface ViewCollectionProps {
    handleChangeFavorite: (value: boolean) => void;
    handleClose: () => void;
    editCollection: (info: any) => void;
    editInfo: any;
    handelSet: () => void;
}

export const ViewCollection = ({ handleChangeFavorite, handleClose, editCollection, editInfo, handelSet }: ViewCollectionProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [selectedCollectionForms, setSelectedCollectionForms] = useState<UuidType[]>([]);
    const logedUser: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(logedUser);
    const [isConfirm, setIsConfirmOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const collectionForms: any = useSelector(
        (state: RootState) => state.DATA_REDUCER.collectionForms
    );

    const deleteData: any = useSelector((state: RootState) => state.DATA_REDUCER.collectionStatusCount);

    useEffect(() => {
        if (editInfo) {
            dispatch(getCollectionFormsThunk({ collectionId: editInfo.id }))
        }
    }, [])

    const setEditCollection = async () => {
        if (loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role) && editInfo.collectionType === 'CUSTOM') {
            const data = { collection_ids: [editInfo.id] }
            await dispatch(getCollectionStatusCount(data));
            setIsConfirmOpen(true);
        } else if (loggedInUser && [ROLES.SUPER_ADMIN, ROLES.ADMIN].includes(loggedInUser.role)) {
            const data = { collection_ids: [editInfo.id] }
            await dispatch(getCollectionStatusCount(data));
            setIsConfirmOpen(true);
        } else {
            await dispatch(getCollectionFormsThunk({ collectionId: editInfo.id }));
            editCollection(collectionForms);
        }
    }

    const handelConfirmed = async () => {
        setIsConfirmOpen(false);
        await dispatch(getCollectionFormsThunk({ collectionId: editInfo.id }));
        editCollection(collectionForms);
    }

    const loadMore = async (getCollectionFormsProps: any) => {
        await dispatch(getCollectionFormsThunk({ collectionId: editInfo.id, domain_id: getCollectionFormsProps.domainId, sort_by: getCollectionFormsProps.sort, order: getCollectionFormsProps.order }))
    }

    const handelFavoriteForm = async (value: boolean) => {
        setLoading(true);
        if (value) {
            await dispatch(removeFavoriteCollectionThunk(editInfo.id));
            await handleChangeFavorite(!value);
        } else {
            await dispatch(markFavoriteCollectionThunk(editInfo.id));
            await handleChangeFavorite(!value);
        }
        setLoading(false);
    }
    const handelFavoriteCall = () => { }

    const downloadForms = async () => {
        const data: any = []
        for (let i = 0; i < selectedCollectionForms.length; i++) {
            const version_id = collectionForms.filter((item: any) => item.id === selectedCollectionForms[i])
            const info = {
                "form_id": selectedCollectionForms[i],
                "form_version_id": version_id[0].form_version_id
            }
            data.push(info);
        }
        const links: any = await dispatch(downloadFormsThunk(data));
        if (links) {
            for (let i = 0; i < links.length; i++) {
                window.open(links[i].file_url, "_blank");
            }
        }
    }

    return (
        <Box display="flex" flexDirection="column">
            <Box pt={3} px={3}>
                <Grid container>
                    <Grid item xs={12} sm={10} md={10} style={{ display: 'flex' }}>
                        <Grid container>
                            <Grid item xs={2} sm={1} md={1} style={{ display: 'flex' }}>
                                <ArrowBack style={{ fontSize: '30px', color: 'gray', cursor: 'pointer', marginTop: '10px' }} onClick={handleClose} />
                                <Divider orientation="vertical" style={{ margin: '3px 40px 0px 15px', height: '40px' }} />
                            </Grid>
                            <Grid item xs={10} sm={11} md={11}>
                                <Box pb={2.5}>
                                    <Box pb={0.75}>
                                        <Typography style={{ color: 'gray' }} variant="subtitle2">COLLECTION</Typography>
                                        <Box display='flex' alignItems="center" mt={0.3}>
                                            <Typography variant="h2">{editInfo.name}</Typography>
                                            {editInfo && editInfo.isFavourite ? <Star color='primary' style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => { loading ? handelFavoriteCall() : handelFavoriteForm(true) }} /> : <StarOutline color='primary' style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => { loading ? handelFavoriteCall() : handelFavoriteForm(false) }} />}
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box pb={2}>
                                    <Box pt={1}>
                                        <Typography color="textSecondary" variant="subtitle2">
                                            {editInfo.description}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} style={{ textAlign: 'right' }}>
                        <Box>

                            {(loggedInUser && [ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role) && editInfo.collectionType === 'CUSTOM') ? <LoadingButton
                                className={classes.primaryButton}
                                color="primary"
                                variant="contained"
                                onClick={(e) => setEditCollection()}
                            >
                                Edit
                            </LoadingButton> :
                                (loggedInUser && ![ROLES.SITE_ADMIN, ROLES.BUILDER].includes(loggedInUser.role)) && <LoadingButton
                                    className={classes.primaryButton}
                                    color="primary"
                                    variant="contained"
                                    onClick={(e) => setEditCollection()}
                                >
                                    Edit
                                </LoadingButton>}
                        </Box>
                    </Grid>
                </Grid>

            </Box>

            <Divider variant="middle" />

            <Box
                className={classes.topBox}
                px={3}
                py={2.5}
            >
                <Typography variant="h4" style={{ fontWeight: 500 }}>Primary Domains</Typography>

                <Box pt={2} display='flex'>
                    <div className={classes.otherActions}>
                        {editInfo && editInfo.domains.map((item: any) => {
                            return (
                                <Chip
                                    key={''}
                                    classes={{ root: classes.collectionChip }
                                    }
                                    size="small"
                                    // onClick={() => unassignCollection()}
                                    label={
                                        < div className={classes.chipAlign}>
                                            {item.title}
                                        </div>
                                    }
                                />)
                        })}
                    </div>
                </Box>
            </Box>

            <Divider variant="middle" />

            <Box
                className={classes.bottomBox}
                px={3}
                py={2}
            >
                <Typography variant="h4" style={{ fontWeight: 500 }}>Tags</Typography>
                <Box pt={2} display='flex'>
                    <div className={classes.otherActions}>
                        {editInfo && editInfo.tags.map((item: any) => {
                            return (
                                <Chip
                                    key={''}
                                    classes={{ root: classes.collectionChip }
                                    }
                                    size="small"
                                    label={
                                        < div className={classes.chipAlign}>
                                            {item.title}
                                        </div>
                                    }
                                />)
                        })}
                    </div>
                </Box>
            </Box>

            <Divider variant="middle" />
            <Box display="flex" justifyContent="space-between" alignItems='center' px={3} py={2}>
                <Box display="flex" alignItems='end' className={classes.contentContainer}>
                    <Box mr={5}>
                        <Typography variant="h4" style={{ fontWeight: 500, color: 'gray', marginBottom: '6px' }}>FORMS IN</Typography>
                        <Typography variant="h2">{editInfo.name}</Typography>
                    </Box>
                </Box>
                <Box display='flex' alignItems='center'>
                    {selectedCollectionForms && selectedCollectionForms.length > 0 &&
                        <> <Typography style={{ color: '#575757', fontWeight: 400, fontSize: '13px' }}>{selectedCollectionForms.length} Selected</Typography>
                            <Divider orientation="vertical" style={{ margin: '0px 20px 0px 20px', height: '30px' }} />
                            <GetApp style={{ color: '#0c84c0', cursor: 'pointer' }} onClick={(e) => downloadForms()} />
                        </>
                    }
                </Box>
            </Box>
            <Box pb={2} px={4}>
                <CollectionFormTable collectionForms={collectionForms} selectedFormFiles={selectedCollectionForms} setSelectedFormFiles={setSelectedCollectionForms} isView={false} loadMore={loadMore} handelSet={handelSet} />
            </Box>
            {isConfirm &&
                <Dialog open={isConfirm}>
                    <Box style={{ width: '500px', background: '#f9fafb', borderRadius: '10px 10px 0px 0px' }}>
                        <Box p={3} display="flex" justifyContent="space-between">
                            <Typography variant="h3">Are you sure you want to edit this collection?</Typography>
                        </Box>
                    </Box>
                    <Box p={3}>
                        <Typography>This collection is currently assigned to {deleteData && deleteData.length > 0 && (deleteData[0].assigned_to_count + ' ' + (deleteData[0].assigned_to_count === '1' ? 'user' : 'users'))}.</Typography>
                        <Typography>This collection is currently in progress by {deleteData && deleteData.length > 0 && (deleteData[0].in_progress_count + ' ' + (deleteData[0].in_progress_count === '1' ? 'user' : 'users'))}.</Typography>
                        <Typography>This collection has been completed by {deleteData && deleteData.length > 0 && (deleteData[0].completed_count + ' ' + (deleteData[0].completed_count === '1' ? 'user' : 'users'))}.</Typography>
                    </Box>
                    <DialogActions style={{ justifyContent: 'space-between', background: '#f9fafb', borderRadius: '0px 0px 10px 10px', padding: '20px 24px' }}>
                        <LoadingButton style={{ border: '1px solid rgba(0, 0, 0, 0.15)', width: '120px', color: '#0060B2' }}
                            variant="contained" color="secondary"
                            onClick={() => setIsConfirmOpen(false)}
                        >
                            Cancel
                        </LoadingButton>
                        <LoadingButton color="primary"
                            variant="contained"
                            style={{ boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)', width: '120px' }}
                            onClick={(e) => handelConfirmed()}
                        >
                            Edit
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            }
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    topBox: {
        borderRadius: '0.625rem 0.625rem 0 0'
    },
    bottomBox: {
        borderRadius: '0 0 0.625rem 0.625rem'
    },
    textField: {
        width: '100%'
    },
    primaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#fff',
        minWidth: '120px',
        marginBottom: '20px'
    },
    secondaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: '#0060B2',
        marginBottom: '20px',
        minWidth: '120px'
    },
    deleteButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: 'red',
        minWidth: '120px'
    },
    createCollectionTextField: {
        maxWidth: '40rem',
        width: '80vw'
    },
    chipAlign: {
        display: 'flex',
        alignItems: 'center'
    },
    otherActions: {
        marginLeft: '.6rem',
        alignItems: 'center'
    },
    collectionChip: {
        marginRight: theme.spacing(1),
        backgroundColor: theme.background.main
    },
    searchTextField: {
        backgroundColor: theme.palette.secondary.main,
        fontSize: theme.typography.h5.fontSize,
        height: '2rem',
        width: '14rem'
    },
    searchIcon: {
        fontSize: 17
    },
    contentContainer: {
        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap'
        }
    }
}));
