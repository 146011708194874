import React from 'react';
import { TitleInfo } from './TitleInfo';
interface InfoTextBlockProps {
  infoText: string;
  clinicianInfo?: string;
  patientInfo?: string;
  index: any
}

export const InfoTextBlock: React.FC<InfoTextBlockProps> = ({
  infoText,
  clinicianInfo,
  patientInfo,
  index
}) => {
  return <TitleInfo title={infoText} patientInfo={patientInfo} clinicianInfo={clinicianInfo} index={index} />;
};
