import React from 'react';

import { Box, Grid } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Referrals } from './components/Referrals';
import { SideBar } from '../../../components/PatientSharedUserSidebar';
import { colors } from '../../../styling/colors';

export const ReferralPage = () => {
    const classes = useStyles();
    const user: any = localStorage.getItem('formFlash');
    const loggedInUser = JSON.parse(user);

    return (
        loggedInUser && (
            <Box className={classes.main} display="flex" flexDirection="column" p={4}>
                <Grid container>
                    <Grid item xs={12} sm={2} md={2}>
                        <SideBar />
                    </Grid>
                    <Grid item xs={12} sm={10} md={10}>
                        <Referrals />
                    </Grid>
                </Grid>
            </Box>
        )
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    main: {
        [theme.breakpoints.down('xs')]: {
            padding: '1rem'
        },
        backgroundColor: colors.background1,
        minHeight: '100vh',
        marginTop: '20px',
        marginLeft: '10px'
    },
    contentContainer: {
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap'
        }
    }
}));
