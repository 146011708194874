import { ListItemText, ListItem, Button, Box, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { boolean } from 'yup';
import { url } from '../../../../redux/url';

var axios = require("axios");
var FormData = require("form-data");

interface ResourceFilesSettingsProps {
    handleUploadFileChange: (options: any) => void;
    handleUploadFileToDelete: (ids: any) => void;
    files: any[];
    titleClass: string;
    loading: boolean;
    error: boolean;
    isMsg: string
}

export const ResourceFilesSettings: React.FC<ResourceFilesSettingsProps> = ({
    handleUploadFileChange,
    handleUploadFileToDelete,
    files,
    titleClass,
    loading, isMsg, error
}) => {
    const classes = useStyles();

    return (
        <>
            <Typography color={'textSecondary'} variant="subtitle2" className={titleClass}>
                Resources
            </Typography>
            <Box mt={2}>
                <div>
                    <input disabled={loading || files.length !== 0}
                        accept=".gif, .jpeg, .jpg, .png, image/*, .tiff, .doc, .docx, .docx, .ppt, .pptx, .txt, .pdf"
                        className={classes.input}
                        id="contained-button-file"
                        onChange={(e) => handleUploadFileChange(e.target.files)}
                        type="file"
                    />
                    <label htmlFor="contained-button-file">
                        <Button variant="outlined" disabled={loading || files.length !== 0} component="span" style={{ width: '100%' }} className={classes.primaryButton}>
                            Upload File
                            {loading && (
                                <CircularProgress
                                    className={classes.loadingCircle}
                                    size={16}
                                    style={{ color: 'blue' }}
                                />
                            )}
                        </Button>
                    </label>
                </div>
                {error ? (
                    <Typography color="error" variant="subtitle2" style={{ marginBottom: '10px' }}>
                        {isMsg}
                    </Typography>
                ) : null}
                <Box pb={2.5}>
                    {files && files.map((file: any, i: number) => {
                        return (<ListItem style={{ padding: '0px 16px 8px' }}>
                            <ListItemText style={{ margin: '0px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} primary={<span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{file.file_name}</span>} secondary={<span style={{ color: '#c50000', cursor: 'pointer' }} onClick={(e) => handleUploadFileToDelete(i)}>Remove</span>} />
                        </ListItem>
                        )
                    })}
                </Box>
            </Box>
        </>
    );
};

const useStyles = makeStyles((theme) => ({
    selectRoot: {
        padding: '.6rem'
    },
    input: {
        display: 'none'
    },
    primaryButton: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        color: 'black',
        minWidth: '120px',
        marginBottom: '20px'
    },
    paperBox: {
        border: '1px solid rgba(0, 0, 0, 0.15)',
        height: '180px',
        width: '100%',
        borderRadius: '10px',
        background: '#F1F5F9',
        overflow: 'auto'
    },
    loadingCircle: {
        marginLeft: '0.5rem'
    }
}));
