import React, { useRef, useState, useEffect } from 'react';
import {
  Box,
  ButtonBase,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  ListItemText
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { setLogoAction } from '../redux/data/data-actions';
import { logoutThunk, getLoggedInUserThunk, getFormStyleThunk } from '../redux/thunk/thunks';
import { Logo } from './Logo';
import { colors } from '../styling/colors';
import { RootState } from '../redux/redux-store';
import { matchPath, withRouter } from 'react-router-dom';
import { url } from '../redux/url';
import { ROLES } from '../shared/globals';

var axios = require("axios");
var FormData = require("form-data");

interface MatchParams {
  id: string;
}

export const TopBar = withRouter(({ history, match }: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = match.path;
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [orgLogo, setLogo] = useState('');
  const userMenuAnchorRef = useRef(null);
  const loggedUser: any = localStorage.getItem('formFlash');
  const loggedInUser = JSON.parse(loggedUser);
  // const loggedInUser = useSelector((state: RootState) => state.DATA_REDUCER.loggedInUser);
  const formStyle: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.formStyle
  );
  const org_logo: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.org_logo
  );
  useEffect(() => {
    !org_logo && dispatch(getFormStyleThunk(loggedInUser ? loggedInUser.id : ''));
    if (!loggedInUser) {
      dispatch(getLoggedInUserThunk());
    }
  }, []);

  useEffect(() => {
    if (formStyle) {
      if (formStyle.logo !== "" && !org_logo) {
        const data1 = {
          input_files: [formStyle.logo]
        }
        var config1 = {
          method: 'post',
          url: `${url}/user/auth/getSignedDownloadUrls`,
          headers: _getHeaders({ "Content-Type": "application/json" }),
          data: data1,
        };

        axios(config1)
          .then(function (res: any) {
            dispatch(setLogoAction(res.data.url && res.data.url.length > 0 && res.data.url[0]));
          })
      }
    }
  }, [formStyle]);
  const _getHeaders = (inputHeaders?: { [key: string]: string }) => {
    let headers = inputHeaders || {};
    let token = localStorage.getItem('token');
    headers["Accept"] = "application/json";
    const headersWithAuth = token
      ? {
        ...headers,
        Authorization: `Bearer ${token}`,
      }
      : headers;
    return headersWithAuth;
  };

  const handleUserMenuToggle = () => {
    setUserMenuOpen(!userMenuOpen);
  };
  const handleUserMenuClose = () => {
    setUserMenuOpen(false);
  };

  const handleProfile = () => {
    history.push('/profile');
  };

  const handleOrgProfile = () => {
    history.push('/organization-profile');
  }
  const handleContactUs = () => {
    history.push('/contacts');
  }
  const handleRefProfile = () => {
    history.push('/referral-profile');
  }
  const handleUserRole = () => {
    history.push('/roles');
  };

  // TODO: clear token on sign out
  const handleSignOut = async () => {
    await localStorage.removeItem('remember');
    await sessionStorage.removeItem('userRoleID');
    await dispatch(logoutThunk());
  };

  if (!loggedInUser) {
    return null;
  }

  return (
    <Box alignItems="flex-start" display="flex" flexWrap="wrap" justifyContent="space-between">
      <Box display="flex">
        <Logo />
      </Box>

      <Box display="flex">
        <ButtonBase
          className={classes.buttonRoot}
          component="div"
          aria-label={'user menu'}
          onClick={handleUserMenuToggle}
          ref={userMenuAnchorRef}
        >
          <Box alignItems="center" display="flex">
            {/* <Box display="flex"
              alignItems="center"
              className={classes.userIcon}
              justifyContent="center"
            >
              <Typography className={classes.userIconText} variant="subtitle2">
                {loggedInUser?.first_name?.charAt(0)}
                {loggedInUser?.last_name?.charAt(0)}
              </Typography>
              {!loggedInUser?.first_name && !loggedInUser?.last_name && (
                <PersonIcon style={{ color: 'white' }} />
              )}
            </Box> */}

            <Box ml={1.5} style={{ textAlign: 'left' }}>
              <ListItemText primary={<span style={{ fontWeight: 500, fontSize: '14px' }}>{loggedInUser?.first_name + ' ' + loggedInUser?.last_name}</span>} secondary={<span style={{ fontSize: '10px', color: 'gray' }}>{loggedInUser?.role.replace(/_/g, " ")}</span>} />
              {/* <Typography color="textSecondary" variant="subtitle2">
                {concatName({
                  firstName: loggedInUser.first_name,
                  lastName: loggedInUser.last_name,
                  fallback: loggedInUser.email || ''
                })}
              </Typography> */}
            </Box>
            <Box alignItems="center" display="flex" ml={6}><ExpandMore style={{ color: '#0062b2', fontSize: '18px' }} /></Box>
          </Box>
        </ButtonBase>

        <Popper
          anchorEl={userMenuAnchorRef.current}
          className={classes.popper}
          disablePortal
          open={userMenuOpen}
          placement="bottom-end"
        >
          <Box pt={1}>
            <Paper>
              <ClickAwayListener onClickAway={handleUserMenuClose}>
                <MenuList autoFocusItem={userMenuOpen} style={{ padding: '0px' }}>
                  {loggedInUser &&
                    (loggedInUser.role === ROLES.REFERRAL) && (
                      <MenuItem style={{ background: location == '/referral-profile' ? '#0062b2' : '#fff', color: location == '/referral-profile' ? '#fff' : 'black' }} className={classes.menuItem} onClick={handleRefProfile}>Referral Profile</MenuItem>
                    )}
                  {/* {loggedInUser &&
                    (loggedInUser.role === ROLES.ADMIN || loggedInUser.role == ROLES.BUILDER) && ( */}
                  <MenuItem style={{ background: location == '/profile' ? '#0062b2' : '#fff', color: location == '/profile' ? '#fff' : 'black' }} className={classes.menuItem} onClick={handleProfile}>My Profile</MenuItem>
                  {loggedInUser && !loggedInUser.is_shared_user && <MenuItem style={{ background: location == '/roles' ? '#0062b2' : '#fff', color: location == '/roles' ? '#fff' : 'black' }} className={classes.menuItem} onClick={handleUserRole}>Switch Account</MenuItem>}
                  {/* )} */}

                  {/* Commented For now
                  {loggedInUser &&
                    (loggedInUser.role === ROLES.ADMIN || loggedInUser.role == ROLES.BUILDER) && (
                      <MenuItem className={classes.menuItem} onClick={handleFromStyle}>Form Styling</MenuItem>
                    )}

                  {loggedInUser && loggedInUser.role === ROLES.ADMIN && (
                    <MenuItem className={classes.menuItem} onClick={handleUserRole}>Account Users</MenuItem>
                  )} */}

                  {loggedInUser &&
                    (loggedInUser.role === ROLES.SITE_ADMIN) && (
                      <MenuItem style={{ background: location == '/organization-profile' ? '#0062b2' : '#fff', color: location == '/organization-profile' ? '#fff' : 'black' }} className={classes.menuItem} onClick={handleOrgProfile}>Organization Profile</MenuItem>
                    )}
                  {loggedInUser &&
                    (loggedInUser.role === ROLES.REFERRAL) && (
                      <MenuItem style={{ background: location == '/contacts' ? '#0062b2' : '#fff', color: location == '/contacts' ? '#fff' : 'black' }} className={classes.menuItem} onClick={handleContactUs}>Contact Us</MenuItem>
                    )}
                  <MenuItem className={classes.menuItem} onClick={handleSignOut}>Sign out</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Box>
        </Popper>
      </Box>
    </Box>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  popper: {
    zIndex: 100
  },
  logoLink: {
    textDecoration: 'none'
  },
  buttonRoot: {
    display: 'flex',
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0
  },
  userIcon: {
    borderRadius: '6px',
    backgroundColor: colors.background5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '2rem',
    width: '2rem'
  },
  userIconText: {
    color: colors.background3,
    textShadow: '0 1px 1px rgba(0, 0, 0, 0.25)'
  },
  menuItem: {
    padding: '8px 55px 8px 20px',
    color: 'black',
    borderRadius: '5px'
  },
  avatar: {
    height: '40px',
    marginLeft: '10px',
    borderRadius: 'unset',
    backgroundColor: 'transparent'
  },
}));
