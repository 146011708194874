import React, { ChangeEvent } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import {
  Box,
  Grid,
  makeStyles,
  Modal,
  Paper,
  TextField,
  Theme,
  Typography
} from '@material-ui/core';

import { ButtonProps } from '../styling/types';
import { LoadingButton } from './LoadingButton';
import { colors } from '../styling/colors';

interface DialogProps {
  children?: React.ReactNode;
  open: boolean;
  primaryButton?: ButtonProps;
  secondaryButton?: ButtonProps;
  title?: string;
  handleClose?: () => void;
  handleSearch?: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

export const Dialog = ({
  children,
  open,
  primaryButton,
  secondaryButton,
  title,
  handleClose,
  handleSearch
}: DialogProps) => {
  const classes = useStyles();

  return (
    <Modal onClose={handleClose} open={open}>
      <Grid alignItems="center" className={classes.main} container justify="center" spacing={0}>
        <Paper className={classes.dialogPaper}>
          <Grid container direction="column">
            {title && (
              <Box
                alignItems="center"
                bgcolor={colors.background2}
                className={classes.topBox}
                display="flex"
                justifyContent="space-between"
                px={3}
                py={handleSearch ? 1.5 : 2.5}
              >
                <Typography variant="h3">{title}</Typography>

                {handleSearch && (
                  <Box ml={2}>
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <Box ml={-0.5} mr={1} mt={0.5}>
                            <SearchIcon className={classes.searchIcon} color="disabled" />
                          </Box>
                        )
                      }}
                      className={classes.searchTextField}
                      onChange={handleSearch}
                      placeholder="Search..."
                      size="small"
                      variant="outlined"
                    />
                  </Box>
                )}
              </Box>
            )}

            {children && (
              <Box px={3} py={2.5}>
                {children}
              </Box>
            )}

            <Box bgcolor={colors.background2} className={classes.bottomBox} px={3} py={2}>
              <Grid container justify="space-between">
                {secondaryButton ? (
                  <LoadingButton
                    className={classes.secondaryButton}
                    disabled={secondaryButton.disabled}
                    loading={secondaryButton.loading}
                    color="secondary"
                    onClick={secondaryButton.handleClick}
                    variant="contained"
                    aria-label={secondaryButton.text}
                  >
                    {secondaryButton.text}
                  </LoadingButton>
                ) : (
                  <div />
                )}

                {primaryButton ? (
                  <LoadingButton
                    className={classes.primaryButton}
                    color="primary"
                    disabled={primaryButton.disabled}
                    loading={primaryButton.loading}
                    onClick={primaryButton.handleClick}
                    variant="contained"
                    aria-label={primaryButton.text}
                  >
                    {primaryButton.text}
                  </LoadingButton>
                ) : (
                  <div />
                )}
              </Grid>
            </Box>
          </Grid>
        </Paper>
      </Grid>
    </Modal>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    minHeight: '100vh'
  },
  dialogPaper: {
    borderRadius: '0.625rem'
  },
  topBox: {
    borderRadius: '0.625rem 0.625rem 0 0'
  },
  searchTextField: {
    backgroundColor: colors.background3,
    fontSize: '13px',
    height: '2rem',
    width: '8rem'
  },
  searchIcon: {
    fontSize: 17
  },
  bottomBox: {
    borderRadius: '0 0 0.625rem 0.625rem'
  },
  primaryButton: {
    boxShadow:
      '0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 4px 4px 0 rgba(0, 0, 0, 0.06)'
  },
  secondaryButton: {
    border: '1px solid rgba(0, 0, 0, 0.15)'
  }
}));
