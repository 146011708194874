import React, { useEffect, useState } from 'react';
import Close from '@material-ui/icons/Close';
import { Box, Paper, Dialog, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { CardHeader } from '../../../../components/LegacyCardHeader';
import { GetAppointmentsProps } from '../../../../redux/thunk/thunks';
import { RootState } from '../../../../redux/redux-store';
import { AppointmentsTable } from './AppointmentsTable';
import { UuidType } from '../../../../shared/domain';
import { makeStyles } from '@material-ui/core/styles';
import { NewAppointment } from './NewAppointment';
import { getPatientAppointmentThunk, getPatientFamilyThunk } from '../../../../redux/thunk/appointment-thunks';
import { getAppointmentsAction } from '../../../../redux/data/data-actions';
import { useHistory } from 'react-router-dom';

var cardColor = '#5D6AC4';

export const Appointments = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const patientInfo: any = history.location && history.location.state;
  const [selectedAppointment, setSelectedAppointment] = useState<UuidType[]>([]);
  const [newAppointmentDialogueOpen, setNewAppointmentDialogueOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [createNew, setCreateNew] = useState(false);
  const [ansOne, setAnsOne] = React.useState(null);
  const [patientFamilyDialog, setPatientFamilyDialog] = useState(false);

  const appointments = useSelector(
    (state: RootState) => state.DATA_REDUCER.appointmentSearchResults.appointments
  );

  const patientFamilyInfo: any = useSelector(
    (state: RootState) => state.DATA_REDUCER.patientFamilyInfo
  );

  const getCollectionsError = useSelector(
    (state: RootState) => state.DISPLAY_STATE_REDUCER.displayErrors.getCollectionsError
  );

  useEffect(() => {
    dispatch(getPatientAppointmentThunk({}, patientInfo.patient.id));

    return function CleanUp() {
      dispatch(getAppointmentsAction('appointments', null));
    };
  }, []);


  useEffect(() => {
    if (patientFamilyInfo && createNew) {
      setPatientFamilyDialog(true);
      setEdit(false);
    } else if (patientFamilyInfo == null && createNew) {
      setNewAppointmentDialogueOpen(true);
      setEdit(false);
    }
  }, [patientFamilyInfo]);

  useEffect(() => {
    if (getCollectionsError == '' && createNew) {
      setNewAppointmentDialogueOpen(true);
      setEdit(false);
    }
  }, [getCollectionsError]);

  // We need async/await over here as it is used for some state loading task
  const loadAppointments = async (GetAppointmentsProps: GetAppointmentsProps) => {
    await dispatch(getPatientAppointmentThunk(GetAppointmentsProps, patientInfo.patient.id));
  };

  const handleNewAppointmentButton = () => {
    dispatch(getPatientFamilyThunk(patientInfo.patient.id));
    // setNewAppointmentDialogueOpen(true);
    setPatientFamilyDialog(true);
    setCreateNew(true);
    setEdit(false);
    setAnsOne(null);
  };
  const handleNewAppointmentDialogueClose = () => {
    setNewAppointmentDialogueOpen(false);
    setEdit(false);
    setCreateNew(false);
  };

  const handelChangeAns = (e: any) => {
    setAnsOne(e.target.value);
    setNewAppointmentDialogueOpen(true);
    setEdit(false);
    setPatientFamilyDialog(false);
  }

  const closePatFamilyDialog = (value: boolean) => {
    setPatientFamilyDialog(false);
  }

  const AppointmentsTableContainer = (
    <Box pb={4} pt={1} px={4}>
      <AppointmentsTable
        appointmentData={appointments}
        selectedAppointment={selectedAppointment}
        loadAppointments={loadAppointments}
        setSelectedAppointment={(selectedAppointment: UuidType[]) => setSelectedAppointment(selectedAppointment)}
      />
    </Box>
  );

  return (
    <>
      {newAppointmentDialogueOpen && ansOne && (
        <NewAppointment open={newAppointmentDialogueOpen} handleClose={handleNewAppointmentDialogueClose}
          patient={ansOne == 'FAMILY' ? patientFamilyInfo : patientInfo && patientInfo.patient} appointmentInfo={null} edit={edit} />
      )}

      {patientFamilyDialog &&
        <Dialog open={patientFamilyDialog} >
          <Box display='flex' flexDirection='column' className={classes.box}>
            <FormControl component="fieldset" style={{ display: 'contents' }}>
              <Box style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Box style={{ textAlign: 'left' }}>
                  <FormLabel component="legend" className={classes.label}>Create Appointment For -</FormLabel>
                </Box>
                <Box style={{ textAlign: 'right' }}><Close onClick={(e) => closePatFamilyDialog(false)} style={{ color: 'red' }} /></Box>
              </Box>
              <RadioGroup aria-label="quiz" name="quiz" value={ansOne} onChange={e => handelChangeAns(e)}>
                <Paper className={classes.paper} style={{ background: ansOne === "PATIENT" ? cardColor : '#DEE4EC' }}>
                  <FormControlLabel value="PATIENT" control={<Radio />} label={"Patient - " + patientInfo.patient.first_name + ' ' + patientInfo.patient.last_name} />
                </Paper>
                {patientFamilyInfo ?
                  <Paper className={classes.paper} style={{ background: ansOne === "FAMILY" ? cardColor : '#DEE4EC' }}>
                    <FormControlLabel value="FAMILY" control={<Radio />} label={"Family Member - " + patientFamilyInfo.first_name + ' ' + patientFamilyInfo.last_name} />
                  </Paper> :
                  <Paper className={classes.paper} style={{ background: ansOne === "FAMILY" ? cardColor : '#DEE4EC' }}>
                    <Typography>Family Member - not available</Typography>
                  </Paper>
                }
              </RadioGroup>
            </FormControl>
          </Box>
        </Dialog>
      }

      <Paper>
        <Box display='flex' flexDirection='column'>
          <CardHeader
            tabComponents={[
              {
                component: AppointmentsTableContainer,
                label: 'Upcoming Appointments',
                primaryButton: {
                  text: 'Create New Appointment',
                  handleClick: handleNewAppointmentButton
                },
                style: {
                  background: true,
                  spacing: { py: 3, px: 4 }
                },
                title: 'Upcoming Appointments',
                // handleSearch: handleSearch
              }
            ]}
          />
        </Box>
      </Paper>
    </>
  );
};

const useStyles = makeStyles((color) => ({
  background: {
    paddingLeft: '37%',
    padding: '10px',
    backgroundColor: cardColor
  },
  paper: {
    textAlign: 'left', paddingLeft: '20px', margin: '10px',
  },
  label: {
    fontSize: '18px', fontWeight: 600, textAlign: 'left'
  },
  box: {
    padding: '30px',
    width: '300px'
  },
}));
